import { AxiosResponse } from 'axios';
import { selectUserContext, UserContext } from '@innovyze/stylovyze';
import { takeEvery, retry, put, select } from '@redux-saga/core/effects';

import { patternDataService } from '../services';
import { Pattern, RawPatternMeasureData } from '../types/patternChart.types';
import {
  getPatternMeasureData,
  getPatternMeasureDataRejected,
  getPatternMeasureDataResolved,
} from '../actions/patternMeasureData.actions';

export const patternMeasureDataSagas = [
  (function* getMeasureDataSaga() {
    yield takeEvery(getPatternMeasureData, function* ({ payload }) {
      const { id, patternId } = payload;
      try {
        const userContext: UserContext = yield select(selectUserContext);

        const response: AxiosResponse<Pattern> = yield retry(
          3,
          500,
          patternDataService.getPatternMeasureDatum,
          userContext.defaultSite,
          patternId
        );

        let patternData: RawPatternMeasureData[] = [];
        let unit = '';

        if (response.status >= 200 && response.status < 300) {
          patternData = response.data.data || [];
          unit = response.data.unit;
        }

        yield put(
          getPatternMeasureDataResolved({
            id,
            data: patternData,
            unit,
          })
        );
      } catch (error) {
        yield put(getPatternMeasureDataRejected({ id }));
      }
    });
  })(),
];
