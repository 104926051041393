import assetsApi from '@Apis/asset.management.api';
import facilityApi from '@Apis/facility.api';
import {
	FacilityImagesResponse,
	FacilitySensorGroupsResponse,
	FacilitySensorsResponse,
	SensorIdMap,
} from '@Types/facility.types';
import { getImageDownloadUrlService, getImageKeyService } from './image';

export const getFacilitySensors = async (
	facilityId: string,
): Promise<string[]> => {
	try {
		const address = `/sensors?liftStationConfig=${facilityId}&limit=20`;
		const response = await facilityApi.get<FacilitySensorsResponse>(
			address,
		);
		return response.data.sensors.map(sensor => sensor.sensorId);
	} catch {
		return [];
	}
};

export const getFacilitySensorGroups = async (
	facilityId: string,
): Promise<string[]> => {
	try {
		const address = `/sensor-groups?liftStationConfig=${facilityId}&limit=20`;
		const response = await facilityApi.get<FacilitySensorGroupsResponse>(
			address,
		);
		return response.data.sensorGroups.map(
			sensorGroup => sensorGroup.sensorGroupId,
		);
	} catch {
		return [];
	}
};

const getFacilityFirstImageId = async (
	facilityId: string,
): Promise<string | undefined> => {
	try {
		const address = `/lift-station-config/${facilityId}/image`;
		const response = await facilityApi.get<FacilityImagesResponse>(address);
		const allImages = response.data.images;
		if (allImages && allImages.length) return allImages[0];
	} catch {
		return undefined;
	}
};

export const getFacilityImage = async (
	facilityId: string,
): Promise<string | undefined> => {
	const firstImage = await getFacilityFirstImageId(facilityId);
	if (!firstImage) return undefined;
	const imageKey = await getImageKeyService(firstImage);
	if (imageKey) return getImageDownloadUrlService(imageKey);
};

export const getConfigurations = (
	sensorIds: string,
	configType = 'sensor',
	limit = 1000,
): Promise<{ configurations: SensorIdMap[] }> =>
	assetsApi.get('/configurations', {
		params: { sensorIds, configType, limit },
	});

export const getSensorsById = (
	sensorIds: string,
	isPhysical = false,
	configType = 'sensor',
): Promise<{ sensors: SensorIdMap[] }> => {
	return assetsApi.get('/v2/sensors', {
		params: { sensorId: sensorIds, isPhysical, configType },
	});
};
