import { IdCaption } from 'types/general.types';

export enum FilterOperator {
	Equal = 'eq',
	GreaterOrEqual = 'gte',
	LessThan = 'lt',
}

export interface Filter {
	colKey: string;
	value: string | string[];
	operator?: FilterOperator;
	multiple?: boolean;
}

export interface ColumnFilterOptions {
	colKey: string;
	options: IdCaption[];
}

export interface ColumnFilters {
	colKey: string;
	filters: Filter[];
}

export interface ColumnFilterValue {
	colKey: string;
	value: string | string[];
}

export type filterBySelect = (
	option: ColumnFilterValue,
	idCaptions: IdCaption[],
) => Filter[];

export type onFilterChange = (selected: ColumnFilterValue) => void;

export interface SelectProps {
	column: IdCaption;
	options: IdCaption[];
	onFilterChange?: onFilterChange;
	filterBy?: filterBySelect;
	dataCy?: string;
	value?: string | string[];
	fullWidth?: boolean;
	multiple?: boolean;
}

export type FilterDict = { [colKey: string]: Filter[] };
export type SelectDict = { [colKey: string]: SelectProps };

export interface FilterStoreState {
	query: string;
	selects: SelectDict;
	filters: FilterDict;
}

export enum FilterDateTime {
	Today = 'today',
	LastWeek = 'lastweek',
	LastMonth = 'lastmonth',
	LastYear = 'lastyear',
}

export enum FilterObservationGradeType {
	Struct = 'struct',
	OM = 'o&m',
	Service = 'service',
}
