import * as inspectionActions from '@Actions/inspection';

import { put, retry, select, takeLatest } from 'redux-saga/effects';

import { AxiosResponse } from 'axios';
import { InspectionResolvedResponse } from '@Types/inspection.types';
import { PayloadAction } from '@reduxjs/toolkit';
import { getAssetIdAndType } from '@Utils/helpers';
import { getInspections } from '@Services/inspection';
import { selectInspectionsByAssetId } from '../selectors/inspections';

function* getInspectionsSaga(action: PayloadAction<string>) {
	// check if inpsections have already been retrieved before requesting it
	const inspections: ReturnType<typeof selectInspectionsByAssetId> = yield select(
		state => selectInspectionsByAssetId(state, action.payload),
	);
	if (inspections) return;
	const {
		getInspectionsResolved,
		getInspectionsRejected,
	} = inspectionActions;
	const { assetId } = getAssetIdAndType(action.payload);
	try {
		const payload: AxiosResponse<InspectionResolvedResponse> = yield retry(
			5,
			1500,
			getInspections,
			assetId,
		);
		if (payload.data) {
			yield put(
				getInspectionsResolved({
					assetId: action.payload,
					inspections: payload.data.data,
				}),
			);
		} else {
			yield put(getInspectionsRejected());
		}
	} catch (e) {
		yield put(getInspectionsRejected());
	}
}

function* watchGetInspections() {
	yield takeLatest(inspectionActions.getInspections, getInspectionsSaga);
}

export default [watchGetInspections()];
