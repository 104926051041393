import styled, { css } from 'styled-components';
import { rem } from '../styles';

export const Wrapper = styled.div<{ selected: boolean }>`
	cursor: pointer;
	display: grid;
	grid-template-columns: ${rem(60)} auto;
	grid-template-rows: auto auto;
	column-gap: ${rem(10)};
	padding: 0 ${rem(15)};
	margin-top: ${rem(12)};
	width: 100%;
	text-align: left;
	border-bottom: ${rem(1)} solid #e5e5e5;
	outline: none;
	&:focus,
	&:hover {
		background: #eaf4f9;
	}
	${({ selected }) =>
		selected &&
		css`
			background: #eaf4f9;
		`}
`;

export const Image = styled.div<{ background: string }>`
	display: block;
	width: ${rem(60)};
	height: ${rem(60)};
	background: url(${({ background }) => background}) 50% 50%;
	grid-column: 1 / span 1;
	grid-row: 1 / span 2;
`;

export const Title = styled.h3`
	font-size: ${rem(14)};
	font-weight: 500;
	margin: 0;
	grid-column: 2 / span 1;
	grid-row: 1 / span 1;
	align-self: end;
`;

export const Description = styled.p`
	font-size: ${rem(12)};
	margin: 0;
	grid-column: 2 / span 1;
	grid-row: 2 / span 1;
`;
