import { AxiosError, AxiosResponse } from 'axios';
import { ChartType } from '@Types';
import { getCharts, getChartsRejected, getChartsResolved } from '@Actions';
import { put, retry, takeLatest } from 'redux-saga/effects';
import { t } from 'i18next';
import { AnyAction } from 'redux';
import {
	getInspectionOverviewChartsApi,
	getInspectionAssetChartsQueryApi,
	getRiskChartsApi,
	getRiskChartsQueryApi,
	getInspectionMonthChartsQueryApi,
} from '@Services/charts.services';

function* getChartsSaga(action: AnyAction) {
	try {
		switch (action.payload.chartType as ChartType) {
			case ChartType.InspectionOverview:
				{
					const payload: AxiosResponse<unknown> = yield retry(
						5,
						15000,
						getInspectionOverviewChartsApi,
						action.payload.standard,
					);

					yield put(
						getChartsResolved({
							chartId: action.payload.chartId,
							result: payload.data,
						}),
					);
				}
				break;
			case ChartType.InspectionAssetDrilldown:
				{
					const payload: AxiosResponse<unknown> = yield retry(
						5,
						15000,
						getInspectionAssetChartsQueryApi,
						action.payload.standard,
						action.payload.query,
						action.payload.assetFields,
					);

					yield put(
						getChartsResolved({
							chartId: action.payload.chartId,
							result: payload.data,
						}),
					);
				}
				break;
			case ChartType.InspectionMonthDrilldown:
				{
					const payload: AxiosResponse<unknown> = yield retry(
						5,
						15000,
						getInspectionMonthChartsQueryApi,
						action.payload.standard,
						action.payload.query,
						action.payload.month,
					);

					yield put(
						getChartsResolved({
							chartId: action.payload.chartId,
							result: payload.data,
						}),
					);
				}
				break;
			case ChartType.RiskOverview:
				{
					const payload: AxiosResponse<unknown> = yield retry(
						5,
						15000,
						getRiskChartsApi,
						action.payload.configId,
					);

					yield put(
						getChartsResolved({
							chartId: action.payload.chartId,
							result: payload.data,
						}),
					);
				}
				break;
			case ChartType.RiskDrilldown:
				{
					const payload: AxiosResponse<unknown> = yield retry(
						5,
						15000,
						getRiskChartsQueryApi,
						action.payload.configId,
						action.payload.query,
						action.payload.assetFields,
					);

					yield put(
						getChartsResolved({
							chartId: action.payload.chartId,
							result: payload.data,
						}),
					);
				}
				break;
		}
	} catch (e) {
		const err = e as AxiosError;
		const rejection = {
			code: err?.response?.status ?? -1,
			text:
				err?.response?.statusText ??
				t('No additional information') ??
				'',
		};

		yield put(
			getChartsRejected({
				chartId: action.payload.chartId,
				result: rejection,
			}),
		);
	}
}

function* watchGetCharts() {
	yield takeLatest(getCharts, getChartsSaga);
}

export default [watchGetCharts()];
