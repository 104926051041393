import * as React from 'react';

export type Status = 'idle' | 'loading' | 'resolved' | 'rejected';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useRetriever<T>() {
  const [result, setResult] = React.useState<T>();
  const [error, setError] = React.useState<Error>();
  const [status, setStatus] = React.useState<Status>('idle');

  const retrieve = React.useCallback(
    async (retriever: () => Promise<T>) => {
      setStatus('loading');
      try {
        const result = await retriever();
        setResult(result);
        setStatus('resolved');
      } catch (cause) {
        setResult(undefined);
        setError(new Error('Failed to retrieve data.', { cause }));
        setStatus('rejected');
      }
    },
    [setResult, setStatus]
  );

  return { result, error, status, retrieve };
}
