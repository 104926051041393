import {
  useIsFeatureEnabled,
  useSelectSensors,
  useSettings,
} from '@innovyze/stylovyze';
import * as GaugeChart from '../../modules/gauge-chart';
import * as InsightChart from '../../core/_insight-chart';
import * as React from 'react';
import * as SeriesData from '../../core/series-data';
import * as TimeSeriesData from '../../core/time-series-data';
import * as TimeSeriesDataOld from '../../core/time-series-data-old';
import { fixCollectionInterval } from '../../core/time-series-data/utils';

type EdgeSource = {
  sensorId: string;
  resolution: TimeSeriesDataOld.Resolution;
  reading: TimeSeriesDataOld.Reading;
};

function edgeSourceStringifier(source: EdgeSource): string {
  return `${source.sensorId}:${source.resolution}:${source.reading}`;
}

function limit(value: string | null | undefined, defaultValue = 500): number {
  if (typeof value === 'string' || value === null) return defaultValue;
  if (isNaN(Number(value))) return defaultValue;
  return Number(value);
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Chart Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
interface ParentInsightGaugeChartSeriesProps
  extends Omit<InsightGaugeChartSeriesProps, 'sources'> {
  sources: Omit<
    InsightGaugeChartSeriesSourceProps,
    'dataEntry' | 'status' | 'error'
  >[];
}

interface InsightGaugeChartProps
  extends Omit<GaugeChart.GaugeChartRootProps, 'children'> {
  timeRangeSelection?: InsightChart.TimeRangeSelection;
  series: ParentInsightGaugeChartSeriesProps;
  customData: GaugeChart.GaugeChartSeriesSourceOptions[];
}

const InsightGaugeChart = React.forwardRef<
  GaugeChart.ChartInstanceRef,
  InsightGaugeChartProps
>((props, ref): React.ReactElement => {
  const { companySettings } = useSettings();
  const { sensors, initialized: sensorsInitialized } = useSelectSensors();
  const useV3 = useIsFeatureEnabled('info-360-edge-analytics-parquet-files');
  const dataLimit = useIsFeatureEnabled('info-360-analytics-hp2-charts-limit');
  const edge = useIsFeatureEnabled('info-360-edge-analytics-charts-gauge');

  const allSources = React.useMemo(() => {
    if (props.customData) return [];

    const s: {
      sensorId: string;
      resolution: InsightChart.Resolution;
    }[] = [];

    for (const source of props.series.sources) {
      s.push({
        sensorId: source.sensorId,
        resolution: source.resolution ?? '15-MINUTE',
      });
    }

    return s;
  }, [props.series.sources, props.customData]);

  const seriesData = InsightChart.useSensorDataDisplay(allSources);

  const [edgeSources] = SeriesData.useSources<EdgeSource>(() => {
    if (props.customData || !props.series?.sources?.length) return;
    return props.series.sources.map(({ sensorId, resolution, reading }) => ({
      sensorId,
      resolution,
      reading,
    }));
  }, [props.series]);

  const [edgeData, edgeStatus, retrieveEdgeData] = SeriesData.useRetriever<
    TimeSeriesData.ResponseBody,
    { timeSelection?: TimeSeriesData.PartialTimeSelection }
  >(
    async (signal, params) => {
      if (!edgeSources?.length || !sensorsInitialized) return;

      const timeSelection = {
        from: { hours: 24 },
        to: params.timeSelection?.to ?? 'latest',
      } as TimeSeriesData.TimeSelection;

      const response = await TimeSeriesData.retrieve(signal, {
        order: 'desc',
        timeSelection,
        limit: limit(dataLimit),
        timeZone: companySettings.timeZoneIANA,
        data_version: useV3 ? 'v3.0' : 'v2',
        sources: edgeSources.map((s) => {
          const _sensor = sensors.find((_s) => _s.sensorId === s.sensorId);
          const seconds = fixCollectionInterval(_sensor?.collectionInterval);

          return {
            key: edgeSourceStringifier(s),
            sensorId: s.sensorId,
            collectionInterval: { seconds },
            analytic: TimeSeriesData.makeAnalytic(
              s.resolution ?? '15-MINUTE',
              s.reading ?? 'Close'
            ),
          };
        }),
      });

      return {
        ...response.data,
        results:
          Object.entries(response?.data?.results ?? {})?.reduce(
            (results, [sourceKey, resultEntry]) => {
              results[sourceKey] = {
                ...resultEntry,
                data: resultEntry.data?.reverse() ?? [],
              };
              return results;
            },
            {} as Record<string, TimeSeriesData.ResponseResultEntry>
          ) ?? {},
      };
    },
    [
      companySettings.timeZoneIANA,
      dataLimit,
      edgeSources,
      sensors,
      sensorsInitialized,
      useV3,
    ]
  );

  // Handle data fetching with old API
  const { retrieve } = seriesData;
  React.useEffect(() => {
    if (edge) return;
    retrieve({
      start: props.timeRangeSelection?.min,
      end: props.timeRangeSelection?.max,
      limitFrom: 'end',
    });
  }, [
    retrieve,
    edge,
    props.timeRangeSelection?.min,
    props.timeRangeSelection?.max,
  ]);

  // Handle data fetching with edge analytics' API
  React.useEffect(() => {
    if (!edge) return;
    if (!sensorsInitialized) return;

    retrieveEdgeData({
      timeSelection: {
        from: props.timeRangeSelection?.min,
        to: props.timeRangeSelection?.max,
      },
    });
  }, [
    edge,
    sensorsInitialized,
    props.timeRangeSelection?.max,
    props.timeRangeSelection?.min,
    retrieveEdgeData,
  ]);

  const sourcesProps = React.useMemo(() => {
    return props.series.sources.map((seriesEntry, index) => {
      let dataEntry = seriesData?.getEntry(allSources[index]);

      if (edge) {
        const { sensorId, resolution, reading } = seriesEntry;
        const key = edgeSourceStringifier({ sensorId, resolution, reading });
        const edgeDataEntry = edgeData?.results?.[key];

        if (edgeDataEntry) {
          const d: Map<number, Map<InsightChart.Reading, number>> = new Map();

          for (const [t, v] of edgeDataEntry.data) {
            d.set(t, new Map([[reading ?? 'Close', v]]));
          }

          dataEntry = { data: d, unit: edgeDataEntry.unit };
        } else {
          dataEntry = undefined;
        }
      }

      return {
        ...seriesEntry,
        dataEntry: dataEntry ?? {},
      };
    });
  }, [props.series.sources, edge, edgeData, seriesData, allSources]);

  return (
    <GaugeChart.GaugeChartRoot ref={ref} {...props}>
      {props.customData ? (
        <GaugeChart.GaugeChartSeries
          {...props.series}
          sources={props.customData}
          selectedTheme={props.selectedTheme}
        />
      ) : (
        <InsightGaugeChartSeries
          {...props.series}
          sources={sourcesProps}
          edge={!!edge}
          edgeStatus={edgeStatus}
          selectedTheme={props.selectedTheme}
        />
      )}
    </GaugeChart.GaugeChartRoot>
  );
});

InsightGaugeChart.displayName = 'InsightGaugeChart';

interface InsightGaugeChartSeriesSourceProps
  extends GaugeChart.GaugeChartSeriesSourceOptions {
  sensorId: string;
  reading?: InsightChart.Reading;
  resolution?: InsightChart.Resolution;
  dataEntry: InsightChart.SensorDataEntry;
  lastTimestamp?: number;
}

interface InsightGaugeChartSeriesProps
  extends GaugeChart.GaugeChartSeriesProps {
  sources: InsightGaugeChartSeriesSourceProps[];
  edge?: boolean;
  edgeStatus?: SeriesData.Status;
}

const InsightGaugeChartSeries = (
  props: InsightGaugeChartSeriesProps
): React.ReactElement => {
  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Data
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
  const updatedSources = React.useMemo(() => {
    return props.sources.map((source) => {
      let data;
      if (source.dataEntry.data !== undefined) {
        const _data = Array.from(source.dataEntry.data.entries());
        data = _data.map<[number, number | null]>(([timestamp, measures]) => {
          return [timestamp, measures.get(source.reading ?? 'Close') ?? null];
        });
        if (source.lastTimestamp !== undefined) {
          const sourceTimestamp = source.lastTimestamp;
          data = data.filter(([timestamp]) => timestamp <= sourceTimestamp);
        }
      }
      return {
        ...source,
        data,
        unit: source.dataEntry.unit,
        status: props.edge ? props.edgeStatus : source.dataEntry.status,
        error: source.dataEntry.error,
      };
    });
  }, [props.edgeStatus, props.edge, props.sources]);

  return <GaugeChart.GaugeChartSeries {...props} sources={updatedSources} />;
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export { InsightGaugeChart };

export type { InsightGaugeChartProps };
