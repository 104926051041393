import { MaterialDesignContent } from 'notistack';
import { rem } from '@Components/styles';
import styled from 'styled-components';

export const StyledMaterialDesignContent = styled(MaterialDesignContent)(p => ({
	'&.notistack-MuiContent': {
		minWidth: `${rem(330)} !important`,
		flexWrap: 'nowrap',
	},
	'&.notistack-MuiContent-success': {
		backgroundColor: p.theme.palette.success.dark,
	},
	'&.notistack-MuiContent-error': {
		backgroundColor: p.theme.palette.error.dark,
	},
	'&.notistack-MuiContent-warning': {
		backgroundColor: p.theme.palette.warning.dark,
	},
	'&.notistack-MuiContent-info': {
		backgroundColor: p.theme.palette.secondary.dark,
	},
	'&.notistack-MuiContent-default': {
		backgroundColor: p.theme.palette.secondary.dark,
	},
}));
