import * as mapActions from '@Actions/map';

import { put, retry, select, takeLatest } from 'redux-saga/effects';

import { AxiosResponse } from 'axios';
import { Layout } from '@Types/map.types';
import { getLayout } from '@Services/map';
import { selectMapLayout } from '../selectors/map';

function* getLayoutSaga() {
	// check if layout has already been fetched before requesting it
	const layout: ReturnType<typeof selectMapLayout> = yield select(
		selectMapLayout,
	);
	if (layout) return;

	const { getLayoutResolved, getLayoutRejected } = mapActions;
	try {
		const payload: AxiosResponse<Layout> = yield retry(5, 1500, getLayout);
		if (payload.data) {
			yield put(getLayoutResolved(payload.data));
		} else {
			yield put(getLayoutRejected());
		}
	} catch (e) {
		yield put(getLayoutRejected());
	}
}

function* watchGetAction() {
	yield takeLatest(mapActions.getLayout, getLayoutSaga);
}

export default [watchGetAction()];
