import {
	getCharts,
	getChartsResolved,
	getChartsRejected,
	clearCharts,
	clearChart,
} from '@Actions';
import * as AM_COMMON from '.';

import { AnyAction, createReducer } from '@reduxjs/toolkit';
import { ChartsStoreState, Status } from '@Types';

export const initialState: ChartsStoreState = {
	charts: [],
	isLoading: false,
};

interface ChartsReducer {
	[x: string]: (
		state: ChartsStoreState,
		action: AnyAction,
	) => ChartsStoreState | undefined;
}

const updateIsLoading = (
	state: ChartsStoreState,
	isLoading: boolean,
): ChartsStoreState => {
	state.isLoading = isLoading;
	return state;
};

const updateChart = (
	state: ChartsStoreState,
	chartId: string,
	status: Status,
	data?: unknown,
): ChartsStoreState => {
	const charts = state.charts;
	const chart = charts.find(chart => {
		return chart.chartId === chartId;
	});

	if (chart) {
		chart.data = data;
		chart.status = status;
	} else {
		charts.push({
			chartId: chartId,
			data: data,
			status: status,
		});
	}

	state.isLoading = false;

	return state;
};

const doClearChart = (
	state: ChartsStoreState,
	chartId: string,
): ChartsStoreState => {
	state.charts = state.charts.filter(chart => {
		return chart.chartId !== chartId;
	});

	return state;
};

const doClearCharts = (state: ChartsStoreState): ChartsStoreState => {
	state.charts = [];
	return state;
};

const reducer: ChartsReducer = {
	[getCharts.toString()]: state => updateIsLoading(state, true),
	[getChartsResolved.toString()]: (state, action) =>
		updateChart(
			state,
			action.payload.chartId,
			AM_COMMON.status200(),
			action.payload.result,
		),
	[getChartsRejected.toString()]: (state, action) =>
		updateChart(
			state,
			action.payload.chartId,
			action.payload.result,
			undefined,
		),
	[clearChart.toString()]: (state, action) =>
		doClearChart(state, action.payload),
	[clearCharts.toString()]: state => doClearCharts(state),
};

export default createReducer(initialState, reducer);
