import { StylovyzeCard, StylovyzeCardProps } from '@innovyze/stylovyze';
import React from 'react';
import * as Styled from './KpiDashlet.styles';
import { Skeleton } from '@mui/material';

export interface KpiDashletProps extends StylovyzeCardProps {
  loading?: boolean;
}

const KpiDashlet = (props: KpiDashletProps): JSX.Element => {
  const { loading = false, topText, ...cardProps } = props;
  return loading ? (
    <StylovyzeCard
      countText={''}
      topText={<Skeleton animation="wave" height={35} width={200} />}
      topSubText={<Skeleton animation="wave" height={20} width={150} />}
      topColor={props.topColor}
    />
  ) : (
    <StylovyzeCard
      topText={<Styled.TitleContent>{topText}</Styled.TitleContent>}
      {...cardProps}
    />
  );
};

export default KpiDashlet;
