import { StylovyzeCard } from '@innovyze/stylovyze';
import { Grid, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useGlobalization } from '@Translations';
import { InspectionStandard } from '@Types/inspection.types';
import { InspectionRating } from '@Types/inspectionAttributes.types';
import React from 'react';

const useStyle = makeStyles(() => ({
	ratingRoot: {
		overflow: 'hidden',
		padding: '15px',
	},
	card: (props: {
		mainScoreCardHeight: number;
		topCardColors: string;
		mainCardColor: string;
	}) => ({
		marginTop: '5px',
		'&>div>div:nth-child(n+2):nth-child(-n+4)': {
			paddingTop: '7px',
			gridTemplateColumns: 'auto',
			gridTemplateRows: 'auto',
			// eslint-disable-next-line prettier/prettier
			// eslint-disable-next-line quotes
			gridTemplateAreas: "'title''subtitle'",
			justifyContent: 'center',
			'&>p:nth-child(1)': {
				color: props.topCardColors,
				gridArea: 'title',
				display: 'flex',
				justifyContent: 'center',
				fontSize: '32px',
				lineHeight: '40px',
			},
			'&>p:nth-child(2)': {
				color: props.mainCardColor,
				gridArea: 'subtitle',
				display: 'flex',
				justifyContent: 'center',
			},
		},
	}),
	extraTopMargin: {
		marginTop: '10px',
		// height: '189px',
	},
	grayCard: (props: {
		mainScoreCardHeight: number;
		topCardColors: string;
		mainCardColor: string;
	}) => ({
		height: `${props.mainScoreCardHeight}px`,
	}),
	topBar: {
		background: '#A5BDC6',
		height: '0.5rem',
		width: '100%',
	},
}));

export const Ratings = ({
	standard,
	ratings,
	mainScoreCardHeight = 189,
	topCardColors = '#01ABD1',
	mainCardColor = '#4A6067',
}: {
	standard: InspectionStandard;
	ratings: InspectionRating | undefined;
	mainScoreCardHeight?: number;
	topCardColors?: string;
	mainCardColor?: string;
}): JSX.Element => {
	const { t } = useGlobalization();
	const classes = useStyle({
		mainScoreCardHeight: mainScoreCardHeight,
		topCardColors: topCardColors,
		mainCardColor: mainCardColor,
	});
	switch (standard) {
		case InspectionStandard.MSCC:
			return (
				<Grid className={classes.ratingRoot} container spacing={2}>
					<Grid item xs={6} className={classes.card}>
						<StylovyzeCard
							countText={ratings?.structuralGradeRating}
							height={92}
							topColor="#01ABD1"
							topText={
								<div>
									<div
										style={{
											textAlign: 'center',
											fontSize: '14px',
											lineHeight: '20px',
											color: '#4A6067',
											paddingBottom: '10px',
										}}>
										{t('Structural')}
									</div>
									<div>
										<Typography
											variant="body2"
											align="center"
											style={{
												fontSize: '14px',
												lineHeight: '15px',
												color: '#4A6067',
												paddingBottom: '15px',
												whiteSpace: 'pre-wrap',
											}}>
											{t(
												'Total: {{total}} | Mean: {{mean}} | Peak: {{peak}} | Quick: {{quick}}',
												{
													total:
														ratings?.structuralTotalRating ??
														'-',
													mean:
														ratings?.structuralMeanRating ??
														'-',
													peak:
														ratings?.structuralPeakRating ??
														'-',
													quick:
														ratings?.structuralQuickRating ??
														'-',
												},
											)}
										</Typography>
									</div>
								</div>
							}
						/>
					</Grid>
					<Grid item xs={6} className={classes.card}>
						<StylovyzeCard
							countText={ratings?.serviceGradeRating}
							height={92}
							topColor="#01ABD1"
							topText={
								<div>
									<div
										style={{
											textAlign: 'center',
											fontSize: '14px',
											lineHeight: '20px',
											color: '#4A6067',
											paddingBottom: '10px',
										}}>
										{t('Service')}
									</div>
									<div>
										<Typography
											variant="body2"
											align="center"
											style={{
												fontSize: '14px',
												lineHeight: '15px',
												color: '#4A6067',
												paddingBottom: '10px',
												whiteSpace: 'pre-wrap',
											}}>
											{t(
												'Total: {{total}} | Mean: {{mean}} | Peak: {{peak}} | Quick: {{quick}}',
												{
													total:
														ratings?.serviceTotalRating ??
														'-',
													mean:
														ratings?.serviceMeanRating ??
														'-',
													peak:
														ratings?.servicePeakRating ??
														'-',
													quick:
														ratings?.serviceQuickRating ??
														'-',
												},
											)}
										</Typography>
									</div>
								</div>
							}
						/>
					</Grid>
				</Grid>
			);
		case InspectionStandard.WSA05:
			return (
				<Grid className={classes.ratingRoot} container spacing={2}>
					<Grid item xs={6} className={classes.card}>
						<StylovyzeCard
							countText={ratings?.structuralGradeRating}
							height={92}
							topColor="#01ABD1"
							topText={
								<div>
									<div
										style={{
											textAlign: 'center',
											fontSize: '14px',
											lineHeight: '20px',
											color: '#4A6067',
											paddingBottom: '10px',
										}}>
										{t('Structural')}
									</div>
									<div>
										<Typography
											variant="body2"
											align="center"
											style={{
												fontSize: '14px',
												lineHeight: '15px',
												color: '#4A6067',
												paddingBottom: '15px',
												whiteSpace: 'pre-wrap',
											}}>
											{t(
												'Total: {{total}} | Number: {{number}} | Mean: {{mean}} | Peak: {{peak}} | Average: {{average}}',
												{
													total:
														ratings?.structuralTotalRating ??
														'-',
													number:
														ratings?.structuralNumRating ??
														'-',
													mean:
														ratings?.structuralMeanRating ??
														'-',
													peak:
														ratings?.structuralPeakRating ??
														'-',
													average:
														ratings?.structuralAverageRating ??
														'-',
												},
											)}
										</Typography>
									</div>
								</div>
							}
						/>
					</Grid>
					<Grid item xs={6} className={classes.card}>
						<StylovyzeCard
							countText={ratings?.serviceGradeRating}
							height={92}
							topColor="#01ABD1"
							topText={
								<div>
									<div
										style={{
											textAlign: 'center',
											fontSize: '14px',
											lineHeight: '20px',
											color: '#4A6067',
											paddingBottom: '10px',
										}}>
										{t('Service')}
									</div>
									<div>
										<Typography
											variant="body2"
											align="center"
											style={{
												fontSize: '14px',
												lineHeight: '15px',
												color: '#4A6067',
												paddingBottom: '10px',
												whiteSpace: 'pre-wrap',
											}}>
											{t(
												'Total: {{total}} | Number: {{number}} | Mean: {{mean}} | Peak: {{peak}} | Average: {{average}}',
												{
													total:
														ratings?.serviceTotalRating ??
														'-',
													number:
														ratings?.serviceNumRating ??
														'-',
													mean:
														ratings?.serviceMeanRating ??
														'-',
													peak:
														ratings?.servicePeakRating ??
														'-',
													average:
														ratings?.serviceAverageRating ??
														'-',
												},
											)}
										</Typography>
									</div>
								</div>
							}
						/>
					</Grid>
				</Grid>
			);
		case InspectionStandard.MACP:
		case InspectionStandard.PACP:
		default:
			return (
				<Grid className={classes.ratingRoot} container spacing={2}>
					<Grid item xs={4} className={classes.card}>
						<StylovyzeCard
							countText={ratings?.overallRating}
							height={35}
							topColor="#01ABD1"
							topText={
								<span
									style={{
										fontSize: '14px',
										lineHeight: '20px',
										color: '#4A6067',
									}}>
									{t('Overall Rating')}
								</span>
							}
						/>
					</Grid>
					<Grid item xs={4} className={classes.card}>
						<StylovyzeCard
							countText={ratings?.quickRating}
							height={35}
							topColor="#01ABD1"
							topText={
								<span
									style={{
										fontSize: '14px',
										lineHeight: '20px',
										color: '#4A6067',
									}}>
									{t('Overall Quick Rating')}
								</span>
							}
						/>
					</Grid>
					<Grid item xs={4} className={classes.card}>
						<StylovyzeCard
							countText={ratings?.ratingIndex}
							height={35}
							topColor="#01ABD1"
							topText={
								<span
									style={{
										fontSize: '14px',
										lineHeight: '20px',
										color: '#4A6067',
									}}>
									{t('Overall Rating Index')}
								</span>
							}
						/>
					</Grid>
					<Grid item xs={12} className={classes.extraTopMargin}>
						<Paper square className={classes.grayCard}>
							<div className={classes.topBar}></div>
							<Grid
								container
								spacing={4}
								style={{ paddingTop: '26px' }}>
								<Grid
									item
									xs={6}
									style={{ paddingLeft: '40px' }}>
									<Typography
										variant="h5"
										gutterBottom
										align="center"
										style={{
											fontSize: '18px',
											lineHeight: '24px',
											color: '#252F33',
										}}>
										{t('Structural Score')}
									</Typography>
									<Typography
										variant="body2"
										gutterBottom
										align="center"
										style={{
											fontSize: '14px',
											lineHeight: '15px',
											color: '#4A6067',
											whiteSpace: 'pre-wrap',
										}}>
										{t(
											'Rating: {{rating}} | Quick Rating: {{quick}} | Rating Index: {{index}}',
											{
												rating:
													ratings?.structuralOverallRating ??
													'-',
												quick:
													ratings?.structuralQuickRating ??
													'-',
												index:
													ratings?.structuralRatingIndex ??
													'-',
											},
										)}
									</Typography>
								</Grid>
								<Grid
									item
									xs={6}
									style={{ paddingLeft: '40px' }}>
									<Typography
										variant="h5"
										gutterBottom
										align="center"
										style={{
											fontSize: '18px',
											lineHeight: '24px',
											color: '#252F33',
										}}>
										{t('O&M')}
									</Typography>
									<Typography
										variant="body2"
										gutterBottom
										align="center"
										style={{
											fontSize: '14px',
											lineHeight: '15px',
											color: '#4A6067',
											whiteSpace: 'pre-wrap',
										}}>
										{t(
											'Rating: {{rating}} | Quick Rating: {{quick}} | Rating Index: {{index}}',
											{
												rating:
													ratings?.serviceOverallRating ??
													'-',
												quick:
													ratings?.serviceQuickRating ??
													'-',
												index:
													ratings?.serviceRatingIndex ??
													'-',
											},
										)}
									</Typography>
								</Grid>
							</Grid>
						</Paper>
					</Grid>
				</Grid>
			);
	}
};

export default Ratings;
