import { FormatDateOptions } from '@innovyze/stylovyze';
import {
	formatDateTimeWithFormatDate,
	formatDateWithFormatDate,
	formatTimeWithFormatDate,
} from './date.utils';

export const enum ExpectedStringType {
	String,
	DateTime,
	Date,
	Time,
	Currency,
}

/** @param formatDate pass useCompanyDateTime() for normal datetimes or useTzDateTime({ timeZone: 'UTC' }) for naive datetime */
export const parseServerObjectToFormattedString = (
	value: string | Record<string, string>,
	stringType: ExpectedStringType,
	formatDate: (options: FormatDateOptions) => string,
): string => {
	try {
		if (typeof value === 'string') {
			switch (stringType) {
				case ExpectedStringType.DateTime: {
					const date = new Date(Date.parse(value));

					if (date) {
						return (
							formatDateTimeWithFormatDate(
								date.toISOString(),
								formatDate,
							) ?? ''
						);
					} else return '';
				}
				case ExpectedStringType.Date: {
					const date = new Date(Date.parse(value));

					if (date) {
						return (
							formatDateWithFormatDate(
								date.toISOString(),
								formatDate,
							) ?? ''
						);
					} else return '';
				}
				case ExpectedStringType.Time: {
					const date = new Date(Date.parse(value));

					if (date) {
						return (
							formatTimeWithFormatDate(
								date.toISOString(),
								formatDate,
							) ?? ''
						);
					} else return '';
				}
				case ExpectedStringType.Currency: {
					return value;
				}
				default: {
					return value;
				}
			}
		} else {
			const val = value;

			switch (val?.type) {
				case 'date': {
					const date = new Date(Date.parse(val.value));

					if (date) {
						switch (stringType) {
							case ExpectedStringType.Date:
								return (
									formatDateWithFormatDate(
										date.toISOString(),
										formatDate,
									) ?? ''
								);
							case ExpectedStringType.Time:
								return (
									formatTimeWithFormatDate(
										date.toISOString(),
										formatDate,
									) ?? ''
								);
							default:
								return (
									formatDateTimeWithFormatDate(
										date.toISOString(),
										formatDate,
									) ?? ''
								);
						}
					} else return '';
				}
				case 'currency': {
					return val.displayValue;
				}
			}
		}
	} catch {
		return '';
	}
	return '';
};
