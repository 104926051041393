import chroma, { Scale } from 'chroma-js';

import { santizeString } from '@Map/utils';

export default class Gradient {
	private _colors: string[];
	private _scale: Scale;
	private _minSteps = 2;

	constructor(colors: string[], minSteps = 2) {
		this._colors = colors;
		this._scale = chroma.scale(colors);
		this._minSteps = minSteps;
	}

	css(): string {
		return `linear-gradient(90deg, ${this._colors.join(', ')})`;
	}

	svg(name: string): string {
		return `%3ClinearGradient id='${santizeString(name)}'%3E${this._colors
			.map((colorHex, i) => {
				const offset = (i / this._colors.length) * 100;
				const color = colorHex.replace('#', '%23');
				return `%3Cstop offset='${offset}%25' stop-color='${color}' /%3E`;
			})
			.join('')}%3C/linearGradient%3E`;
	}

	colors(count: number): string[] {
		return this._scale.colors(Math.max(count, this._minSteps));
	}

	static create(colors: string[] | Gradient): Gradient {
		if (Array.isArray(colors)) {
			return new Gradient(colors);
		}
		return colors;
	}
}
