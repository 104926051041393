import { TextField } from '@mui/material';
import styled from 'styled-components';

export const Wrapper = styled.div`
	.react-colorful {
		width: 100%;
	}
`;

export const ButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	button {
		text-transform: none;
	}
`;

export const InputGrid = styled.div`
	margin: 0rem 0.25rem;
`;

export const TexFieldGrid = styled(TextField)<{ type: 'hex' | 'rgb' }>`
	margin: 0.25rem 0.18rem;

	width: ${props => (props.type === 'hex' ? 5.2 : 3.3)}rem;
	& p {
		text-align: center;
	}
	& div {
		font-size: 0.875rem;
	}
`;
