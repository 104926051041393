import {
  DataGridDensity,
  GlobalPassthroughs,
  Readings,
  TableDashletItem,
  TableDashletMultiSeries,
  TableDashletSingleSeries,
  TableDensity,
  TableSortState,
} from '../../../types';
import {
  SensorDataRecords,
  SensorMeasureData,
} from '../../../core/types/data.types';
import { readingIndex } from '../../../core/utils/data.utils';
import * as Styled from './TableDashlet.styles';
import React, { useMemo } from 'react';
import DataGridDashlet from '../../atoms/DataGrid/DataGrid';
import { GridCellSelectionModel } from '@mui/x-data-grid-premium';
import { DataGridProps } from '@innovyze/stylovyze';

export interface TableDashletProps extends GlobalPassthroughs {
  series: TableDashletSingleSeries | TableDashletMultiSeries;
  data?: SensorDataRecords;
  readings?: Readings[];
  loading?: boolean;
  page?: number;
  onPageChange?: (page: number) => void;
  sort?: TableSortState;
  onSortChange?: (sort?: TableSortState) => void;
  useMostRecentReading?: boolean;
  showUnits?: boolean;
  showDateTime?: boolean;
  density?: TableDensity;
  onSelectionChange?: (model: GridCellSelectionModel) => void;
  conditionalFormatting?: DataGridProps['conditionalFormatting'];
  columnConditionalFormatting?: DataGridProps['columnFormatting'];
  onColumnSelectedChange?: (column: string) => void;
  showSelection?: boolean;
  showColumnSelection?: boolean;
  showSensorAlias?: boolean;
  clearSelection?: boolean;
}

const checkIfHasMultipleSensors = (dataRecords?: SensorDataRecords) => {
  return Object.keys(dataRecords ?? {}).length > 1 ? true : false;
};

const checkIfDataIsLoading = (
  series: TableDashletSingleSeries | TableDashletMultiSeries,
  dataRecords: SensorDataRecords | undefined
) => {
  const seriesArray = Array.isArray(series) ? series : [series];

  return seriesArray.some((_series) => {
    if (_series.customData) return false;
    if (!dataRecords) return true;
    const asyncData = dataRecords[_series.sensorId]?.[_series.resolution];
    return ['idle', 'loading'].includes(asyncData?.status ?? 'idle');
  });
};

const getReadingsValues = (
  measurements: SensorMeasureData[number],
  readings: Readings[]
) => {
  const values: Partial<Record<Readings, number>> = {};

  for (const reading of readings) {
    values[reading] = measurements[readingIndex[reading]];
  }

  return values;
};

const makeTableDashletItem = (
  timestamp: number,
  readings: Readings[],
  series: TableDashletSingleSeries,
  data: {
    measurements: SensorMeasureData;
    timestamps: number[];
    unit?: string | undefined;
  }
) => {
  return {
    sensorName: series.alias ?? series.sensorId,
    unit: data.unit ?? '',
    timestamp,
    ...getReadingsValues(data.measurements[timestamp], readings),
  };
};

const makeTableDashletItems = (
  series: TableDashletSingleSeries | TableDashletMultiSeries,
  dataRecords: SensorDataRecords | undefined,
  readings: Readings[],
  isLoading: boolean,
  useMostRecentReading?: boolean
): TableDashletItem[] => {
  if (!dataRecords || isLoading) return [];

  const items: TableDashletItem[] = [];
  const seriesArray = Array.isArray(series) ? series : [series];

  for (const _series of seriesArray) {
    if (_series.customData) {
      const timestamps = Object.keys(_series.customData);
      for (const timestamp of timestamps) {
        items.push(
          makeTableDashletItem(parseInt(timestamp), readings, _series, {
            measurements: _series.customData,
            timestamps: [],
            unit: _series.customUnit,
          })
        );
      }
      continue;
    }
    const { data } = dataRecords[_series.sensorId]?.[_series.resolution] ?? {};

    if (data && data.timestamps.length > 0) {
      if (useMostRecentReading) {
        const timestamp = data.timestamps[data.timestamps.length - 1];
        items.push(makeTableDashletItem(timestamp, readings, _series, data));
      } else {
        for (const timestamp of data.timestamps) {
          items.push(makeTableDashletItem(timestamp, readings, _series, data));
        }
      }
    }
  }

  return items;
};

const TableDashlet = (props: TableDashletProps): JSX.Element => {
  const readings = props.readings ?? [Readings.Close];
  const loading = checkIfDataIsLoading(props.series, props.data);
  const items = makeTableDashletItems(
    props.series,
    props.data,
    readings,
    loading,
    props.useMostRecentReading
  );

  const density = useMemo(() => {
    switch (props.density) {
      case TableDensity.dense:
        return DataGridDensity.dense;
      case TableDensity.regular:
        return DataGridDensity.regular;
      default:
        DataGridDensity.regular;
    }
  }, [props.density]);

  return (
    <Styled.TableDashletWrapper>
      <Styled.TableContainer>
        <DataGridDashlet
          density={density ?? DataGridDensity.regular}
          items={items}
          readings={readings}
          page={props.page}
          sort={props.sort}
          dataCy={props.dataCy}
          showUnits={props.showUnits}
          showDateTime={props.showDateTime}
          onPageChange={props.onPageChange}
          onSortChange={props.onSortChange}
          hasMultipleSensors={checkIfHasMultipleSensors(props.data)}
          onSelectionChange={props.onSelectionChange}
          conditionalFormatting={props.conditionalFormatting}
          columnConditionalFormatting={props.columnConditionalFormatting}
          onColumnSelectedChange={props.onColumnSelectedChange}
          showSelection={props.showSelection}
          showColumnSelection={props.showColumnSelection}
          showSensorAlias={props.showSensorAlias}
          clearSelection={props.clearSelection}
          loading={loading}
        />
      </Styled.TableContainer>
    </Styled.TableDashletWrapper>
  );
};

export default TableDashlet;
