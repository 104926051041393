import * as React from 'react';
import type {
  Analytic,
  AnalyticOption,
  Reading,
  SensorData,
  SensorDataMap,
  TimeSeriesData,
  TimeSeriesDataMap,
} from './types';
import { useReading } from './reading';
import { useGlobalization } from '../../../i18n';

export function useAnalytic(): {
  options: AnalyticOption[];
  getLabels: () => string[];
  getOption: (value: Analytic) => AnalyticOption | undefined;
  getProcessedData: (
    rawSensorData: SensorData | SensorDataMap,
    analytic: Analytic,
    params?: Record<string, unknown>,
    dataOverride?: TimeSeriesData
  ) => TimeSeriesData;
  getValues: () => Analytic[];
  isValid: (analytic: string) => analytic is Analytic;
} {
  const { t } = useGlobalization();
  const reading = useReading();

  const options = React.useMemo((): AnalyticOption[] => {
    return [
      { value: 'Constant', label: t('Constant') },
      { value: 'Last', label: t('Last') },
      { value: 'MovingSum', label: t('Moving Sum') },
      { value: 'Movingaverage', label: t('Moving Average') },
      { value: 'Sqrt', label: t('Sqrt') },
    ];
  }, [t]);

  const _lookupTableByValue = React.useMemo((): Map<Analytic, number> => {
    const map = new Map<Analytic, number>();
    for (let index = 0; index < options.length; index++) {
      map.set(options[index].value, index);
    }
    return map;
  }, [options]);

  const getValues = React.useCallback((): Analytic[] => {
    return options.map((option) => option.value);
  }, [options]);

  const getLabels = React.useCallback((): string[] => {
    return options.map((option) => option.label);
  }, [options]);

  const getOption = React.useCallback(
    (value: Analytic): AnalyticOption | undefined => {
      const entry = _lookupTableByValue.get(value);
      if (entry === undefined) return undefined;
      return options[entry];
    },
    [_lookupTableByValue, options]
  );

  const isValid = React.useCallback(
    (analytic: string): analytic is Analytic => {
      return !!_lookupTableByValue.has(analytic as Analytic);
    },
    [_lookupTableByValue]
  );

  const getProcessedData = React.useCallback(
    (
      rawSensorData: SensorData | SensorDataMap,
      analytic: Analytic,
      params?: Record<string, unknown>,
      dataOverride?: TimeSeriesData
    ): TimeSeriesData => {
      let dataArray: [number, Map<Reading, number | null>][] = [];
      let dataOverrideMap: TimeSeriesDataMap | undefined = undefined;

      if (rawSensorData instanceof Map) {
        dataArray = Array.from(rawSensorData.entries());
      } else {
        for (const [timestamp, ...measures] of rawSensorData) {
          const measuresMap: Map<Reading, number | null> = new Map();

          for (let i = 0; i < measures.length; i++) {
            const value = measures[i];
            const key = reading.at(i);
            if (key !== undefined) measuresMap.set(key, value);
          }

          dataArray.push([timestamp, measuresMap]);
        }
      }

      if (dataOverride && dataOverride.length > 0) {
        dataOverrideMap = new Map();
        for (let i = 0; i < dataOverride.length; i++) {
          const [timestamp, value] = dataOverride[i];
          dataOverrideMap.set(timestamp, value);
        }
      }

      return processingFunctions[analytic](dataArray, params, dataOverrideMap);
    },
    []
  );

  return {
    options,
    getLabels,
    getOption,
    getValues,
    isValid,
    getProcessedData,
  };
}

// TODO: Make this processing functions work like readings.
// They must handle both raw sensor data and sensor data maps.
const processingFunctions: Record<
  Analytic,
  (
    data: [number, Map<Reading, number | null>][],
    params?: Record<string, unknown>,
    dataOverrideMap?: TimeSeriesDataMap
  ) => TimeSeriesData
> = {
  Sqrt: (data) => {
    if (data === undefined || data.length === 0) return [];
    return data.map(([timestamp, measures]) => {
      const value = measures.get('Average') ?? null;
      return [timestamp, value === null ? null : Math.sqrt(value)];
    });
  },
  Constant: (data, params) => {
    if (data === undefined || data.length === 0) return [];
    return data.map(([timestamp]) => [timestamp, Number(params?.value)]);
  },
  Movingaverage: (data, params) => {
    if (data === undefined || data.length === 0) return [];
    const period = Number(params?.number_of_periods);
    const processedData: TimeSeriesData = [];
    let sum = 0;

    for (let i = data.length - 1; i >= 0; i--) {
      if (i > period) {
        sum = 0;
        for (let j = 0; j < period; j++) {
          const [_, measures] = data[i - j];
          sum += measures.get('Average') ?? 0;
        }

        const [timestamp] = data[i];
        if (Number.isNaN(period) || period === 0) {
          processedData.unshift([timestamp, null]);
        } else {
          processedData.unshift([timestamp, sum / Number(period)]);
        }
      } else {
        sum = 0;
        for (let j = i; j >= 0; j--) {
          const [_, measures] = data[i - j];
          sum += measures.get('Average') ?? 0;
        }

        const [timestamp] = data[i];
        if (Number.isNaN(period) || i + 1 === 0) {
          processedData.unshift([timestamp, null]);
        } else {
          processedData.unshift([timestamp, sum / (i + 1)]);
        }
      }
    }
    return processedData;
  },
  MovingSum: (data, params): TimeSeriesData => {
    if (data === undefined || data.length === 0) return [];
    const period = Number(params?.number_of_periods);
    const processedData: TimeSeriesData = [];
    let sum = 0;

    for (let i = data.length - 1; i >= 0; i--) {
      if (i > period) {
        sum = 0;
        for (let j = 0; j < period; j++) {
          const [_, measures] = data[i - j];
          sum += measures.get('Average') ?? 0;
        }

        const [timestamp] = data[i];
        processedData.unshift([timestamp, sum]);
      } else {
        sum = 0;
        for (let j = i; j >= 0; j--) {
          const [_, measures] = data[i - j];
          sum += measures.get('Average') ?? 0;
        }

        const [timestamp] = data[i];
        processedData.unshift([timestamp, sum]);
      }
    }

    return processedData;
  },
  Last: (data) => {
    if (data === undefined || data.length === 0) return [];

    const firstValidIndex = data.findIndex(([_, measures]) =>
      measures.get('Average')
    );

    let last = data[firstValidIndex][1].get('Average');

    return data.map(([timestamp, measures], index) => {
      const averageValue = measures.get('Average');

      if (
        index > firstValidIndex &&
        (averageValue === undefined || averageValue !== 0)
      ) {
        last = averageValue;
        return [timestamp, averageValue ?? null];
      } else {
        return [timestamp, last ?? null];
      }
    });
  },
};
