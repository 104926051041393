import { useSelector } from 'react-redux';

import {
  PatternMeasureDataReducerChart,
  PatternMeasureDataStoreState,
} from '../reducers/patternMeasureData.reducer';

export const selectPatternChart = (id: string) =>
  useSelector<PatternMeasureDataStoreState, PatternMeasureDataReducerChart>(
    (state) =>
      state.patternMeasureData[id] || {
        initialized: false,
        loading: true,
      }
  );
