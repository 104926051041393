import {
	Box,
	ClickAwayListener,
	ClickAwayListenerProps,
	Grow,
	Popper,
	PopperProps,
} from '@mui/material';
import React, { JSXElementConstructor, ReactElement } from 'react';

interface PopperClickAwayProps extends Omit<PopperProps, 'children'> {
	onClickAway: ClickAwayListenerProps['onClickAway'];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	children: ReactElement<any, string | JSXElementConstructor<any>>;
}

const PopperClickAway = ({
	// prop types come from interface that is extended, eslint doesn't like this
	// eslint-disable-next-line react/prop-types
	open,
	// eslint-disable-next-line react/prop-types
	anchorEl,
	onClickAway,
	children,
	...props
}: PopperClickAwayProps): JSX.Element => {
	return (
		<Popper
			style={{ zIndex: 950 }}
			open={open}
			placement="bottom-start"
			anchorEl={anchorEl}
			transition
			{...props}>
			{({ TransitionProps }) => (
				<Grow {...TransitionProps}>
					<Box>
						<ClickAwayListener onClickAway={onClickAway}>
							{children}
						</ClickAwayListener>
					</Box>
				</Grow>
			)}
		</Popper>
	);
};

export default PopperClickAway;
