import * as mapUiI18nSettings from '@innovyze/map-components/Translations';

import {
	Asset,
	AssetTypeInfo,
	NamespacedStoreState as MapUIStoreState,
	selectAssetById,
	selectMapLayoutForAssetDetails,
} from '@innovyze/map-components';
import { AssetDetailsSummary, AssetType } from '@Types';
import {
	InfoCard,
	Property,
	PropertyCard,
	useIsFeatureEnabled,
} from '@innovyze/stylovyze';
import React, { useMemo } from 'react';
import { Tab, TabWrapper, Tabs } from '@Components/Tabs';
import {
	extractFieldType,
	extractValue,
	getValue,
	mapSegments,
	mapUserTables,
	segmentFields,
} from './ValueMapping';

import { Grid } from '@mui/material';
import ManholeAttributes from './ManholeAttributes';
import PipeAttributes from './PipeAttributes';
import { TabPanel } from '../../Tabs/TabPanel';
import { a11yProps } from '@Utils';
import { useGlobalization } from '@Translations';
import { useSelector } from 'react-redux';

const getType = (type?: string, unit?: string): Property['type'] => {
	if (unit === '$') return 'currency';
	if (unit) return 'unit';
	if (type) {
		if (type === 'string') return 'text';
		if (type === 'date') return 'dateonlyUTC';
		if (type === 'datetime' || type === 'time') return 'dateUTC';
		return type as Property['type'];
	}
	return 'text';
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const DetailsOverview = ({
	assetType,
	assetId,
}: {
	assetType: string;
	assetId: string;
}): JSX.Element => {
	// Keep the static wwPipe and wwManhole for the moment
	const dynamicProps =
		useIsFeatureEnabled('info-360-dynamic-props') &&
		assetType !== 'wwPipe' &&
		assetType !== 'wwManhole';

	const { t } = useGlobalization();
	const [value, setValue] = React.useState(0);
	const sections = useSelector<
		MapUIStoreState,
		AssetTypeInfo[] | undefined
		// @ts-ignore
	>(store => selectMapLayoutForAssetDetails(store, assetType));
	const id = `${assetType}#${assetId}`;
	const details = useSelector<MapUIStoreState, Asset | undefined>(store =>
		selectAssetById(store, id),
	);

	const segments = useMemo(() => {
		const segments = (details as AssetDetailsSummary | undefined)?.segments;
		if (!segments) return;
		return mapSegments(segments, t);
	}, [details]);

	const userDefinedTables = useMemo(() => {
		const userDefined = (details as AssetDetailsSummary | undefined)
			?.userDefined;
		if (!userDefined) return;
		return mapUserTables(userDefined);
	}, [details]);

	const handleChange = (event: unknown, newValue: number) => {
		setValue(newValue);
	};

	if (dynamicProps) {
		// Section headings for generating translations
		t('Cost');
		t('Criticality');
		t('Date');
		t('Definition');
		t('Details');
		t('Location');
		const segmentsTabIndex = sections?.length ?? 0;
		const customTablesIndex = segments
			? segmentsTabIndex + 1
			: segmentsTabIndex;

		return (
			<InfoCard title={t('Properties')}>
				<TabWrapper>
					<Tabs
						orientation="vertical"
						variant="scrollable"
						value={value}
						onChange={handleChange}>
						{sections?.map(({ section }, i) => (
							<Tab key={i} label={t(section)} {...a11yProps(i)} />
						))}
						{segments && (
							<Tab
								label={t('Segments')}
								{...a11yProps(segmentsTabIndex)}
							/>
						)}
						{userDefinedTables && (
							<Tab
								label={t('Custom Tables')}
								{...a11yProps(customTablesIndex)}
							/>
						)}
					</Tabs>
					{sections?.map(({ section, fields }, i) => (
						<TabPanel value={value} index={i} key={i}>
							<PropertyCard
								title={t(section)}
								properties={fields.map(
									({
										name,
										fieldType,
										fieldKey,
										units,
										precision,
									}) => ({
										title: t(name, {
											context: 'Asset Property',
											// the translations come from the map components library
											// so need to use the namespace from that
											ns: mapUiI18nSettings.ns,
										}),
										value: getValue(
											t,
											details,
											fieldKey,
											units,
										),
										cy: fieldKey,
										type: getType(fieldType, units),
										decimalPlaces: precision,
									}),
								)}
								columns={4}
							/>
						</TabPanel>
					))}
					{segments && (
						<TabPanel value={value} index={segmentsTabIndex}>
							{segments.map(segment => (
								<PropertyCard
									key={segment.segmentId}
									title={t('Segment: {{segmentId}}', {
										segmentId: segment.segmentId,
									})}
									properties={segmentFields(t).map(
										({
											name,
											fieldType,
											fieldKey,
											units,
											precision,
										}) => ({
											title: name,
											value: getValue(
												t,
												segment,
												fieldKey,
												units,
											),
											cy: fieldKey,
											type: getType(fieldType, units),
											decimalPlaces: precision,
										}),
									)}
									columns={4}
								/>
							))}
						</TabPanel>
					)}
					{userDefinedTables && (
						<TabPanel value={value} index={customTablesIndex}>
							{userDefinedTables.map(({ section, fields }) => (
								<PropertyCard
									key={section}
									title={section}
									properties={fields.map(
										({ name, value }) => ({
											title: name,
											value: extractValue(value),
											cy: name,
											type: getType(
												extractFieldType(value),
											),
										}),
									)}
									columns={4}
								/>
							))}
						</TabPanel>
					)}
				</TabWrapper>
			</InfoCard>
		);
	}

	return (
		<InfoCard title={t('Properties')}>
			{assetType === 'wwPipe' ? (
				<Grid container>
					<Grid item xs={12}>
						<PipeAttributes />
					</Grid>
				</Grid>
			) : (
				<Grid container>
					<Grid item xs={12}>
						<ManholeAttributes />
					</Grid>
				</Grid>
			)}
		</InfoCard>
	);
};
