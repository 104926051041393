import {
	convertLanguageToIso639,
	getLanguageIso639,
} from '@Translations/utils';

import Events from './events/Events';
import MapboxLanguage from '@mapbox/mapbox-gl-language';
import { StyleSpecification } from 'mapbox-gl';
import i18n from 'i18next';

export const LANGUAGE_CHANGE = 'language-change';

export default class LanguageControl extends Events {
	private _map: mapboxgl.Map;
	private _control: MapboxLanguage;

	constructor(map: mapboxgl.Map, enable = true) {
		super();
		this._map = map;
		this._control = new MapboxLanguage({
			defaultLanguage: getLanguageIso639(),
		});
		if (enable) {
			this._map.addControl(this._control);
		}
		this.attachEvents();
	}

	protected attachEvents(): void {
		i18n.on('languageChanged', this._languageChanged);
	}

	protected _languageChanged = (lng: string): void => {
		const asIso639 = convertLanguageToIso639(lng);
		this.fire(LANGUAGE_CHANGE, { language: lng });
		this._setLanguageOnMap(asIso639);
	};

	protected _setLanguageOnMap(language: string, attempt = 0): void {
		try {
			const newStyle = this._control.setLanguage(
				this._map.getStyle() as StyleSpecification,
				language,
			);
			this._map.setStyle(newStyle);
		} catch (e) {
			if (attempt > 2) return;
			setTimeout(() => {
				this._setLanguageOnMap(language, attempt + 1);
			}, 200);
		}
	}

	public addControl(): void {
		if (!this._map.hasControl(this._control)) {
			this._map.addControl(this._control);
		}
	}

	public removeControl(): void {
		if (this._map.hasControl(this._control)) {
			this._map.removeControl(this._control);
		}
	}
}
