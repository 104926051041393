import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createContext = <Context extends Record<string, unknown> | null>(
  rootComponentName: string,
  defaultContext?: Context
) => {
  const _Context = React.createContext<Context | undefined>(defaultContext);

  const Provider = (props: Context & { children: React.ReactNode }) => {
    const { children, ...contextProps } = props;

    const value = React.useMemo(() => {
      return contextProps as unknown as Context;
    }, Object.values(contextProps));

    return <_Context.Provider value={value}>{children}</_Context.Provider>;
  };

  const useContext = (consumerName: string) => {
    const context = React.useContext(_Context);
    if (context) return context;
    if (defaultContext !== undefined) return defaultContext;
    // if a defaultContext wasn't specified, it's a required context.
    throw new Error(
      `\`${consumerName}\` must be used within \`${rootComponentName}\``
    );
  };

  Provider.displayName = rootComponentName + 'Provider';
  return [Provider, useContext] as const;
};
