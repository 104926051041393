import { all } from 'redux-saga/effects';
import assetDetailSagas from './assetDetail.sagas';
import assetManholeSaga from './assetManhole.sagas';
import assetPipeSaga from './assetPipe.sagas';
import assetRiskHistorySaga from './assetRiskHistory.sagas';
import assetRiskSaga from './assetRisk.sagas';
import chartsSagas from './charts.sagas';
import configurationSaga from './configuration.sagas';
import defectSagas from './observations.sagas';
import fullInspSagas from './fullInsp.sagas';
import inspectionSaga from './inspection.sagas';
import mapRiskLayersSaga from './map.sagas';
import observationsSagas from './observations.sagas';
import rehabSagas from './rehab.sagas';
import riskConfigsListSagas from './riskConfigsList.sagas';
import riskConfigsSagas from './riskConfigs.sagas';
import spatialSagas from './spatial.sagas';

export default function* rootSaga() {
	yield all([
		...assetRiskSaga,
		...assetDetailSagas,
		...assetPipeSaga,
		...assetManholeSaga,
		...inspectionSaga,
		...defectSagas,
		...fullInspSagas,
		...assetRiskHistorySaga,
		...spatialSagas,
		...rehabSagas,
		...observationsSagas,
		...riskConfigsSagas,
		...mapRiskLayersSaga,
		...riskConfigsListSagas,
		...chartsSagas,
		...configurationSaga,
	]);
}
