import { IconButton, Slider } from '@mui/material';
import { StyledPropsExpression, rem } from '../styles';

import { FastForwardIcon } from '@Components/icons/FastForward';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { RewindIcon } from '@Components/icons/Rewind';
import styled from 'styled-components';

export const AnimationControlWrapper = styled.div`
	display: flex;
	padding: ${rem(10)} ${rem(25)};
	gap: ${rem(25)};
	align-items: center;
	color: ${({ theme }): StyledPropsExpression =>
		theme.palette.secondary.dark};
`;

export default AnimationControlWrapper;

export const SliderWrapper = styled.div`
	width: 100%;
`;

export const StyledSlider = styled(Slider)`
	color: ${({ theme }): StyledPropsExpression => theme.palette.primary.dark};
	&.MuiSlider-marked {
		margin-top: ${rem(20)};
		margin-bottom: 0;
	}
	.MuiSlider-markLabel {
		top: ${rem(-8)};
		font-size: ${rem(10)};
		text-transform: uppercase;
		&[data-index='0'] {
			transform: translateX(0%);
		}
		&[data-index='1'] {
			transform: translateX(-100%);
		}
	}
`;

export const Rewind30 = styled(RewindIcon)`
	width: ${rem(25)};
	height: ${rem(25)};
`;

export const FastForward30 = styled(FastForwardIcon)`
	width: ${rem(25)};
	height: ${rem(25)};
`;

export const Play = styled(PlayArrowIcon)`
	width: ${rem(25)};
	height: ${rem(25)};
`;

export const Pause = styled(PauseIcon)`
	width: ${rem(25)};
	height: ${rem(25)};
`;

export const CurrentTime = styled.div`
	white-space: nowrap;
	text-transform: uppercase;
`;

export const AnimationButton = styled(IconButton)`
	color: inherit;
	margin: ${rem(-12)};
`;
