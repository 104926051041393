/* eslint-disable @typescript-eslint/no-explicit-any */
import { AnyAction } from 'redux';
import { createReducer } from '@reduxjs/toolkit';
import { RehabStoreState } from '@Types/rehab.types';
import {
	getRehabResult,
	getRehabResultRejected,
	getRehabResultResolved,
	getRehabTrees,
	getRehabTreesRejected,
	getRehabTreesResolved,
} from '@Actions/Rehab.action';
import {
	paginationInitialState,
	updateGet,
	updateRejected,
	updateResolved,
} from './paginationUtils';

export const initState: RehabStoreState = {
	isDetailLoading: false,
	rehabDetails: {},
	rehabTrees: [],
};
interface RehabReducer {
	[x: string]: (
		state: RehabStoreState,
		action: AnyAction,
	) => RehabStoreState | undefined;
}

export const reducer: RehabReducer = {
	[getRehabResult.toString()]: (state, action) => ({
		...state,
		rehabDetails: {
			...state.rehabDetails,
			[action.payload.treeId]: {
				...updateGet(
					state.rehabDetails[action.payload.treeId],
					state.rehabDetails[action.payload.treeId]?.pagination ??
						paginationInitialState,
				),
				loading: true,
			},
		},
	}),
	[getRehabResultResolved.toString()]: (state, action) => ({
		...state,
		rehabDetails: {
			...state.rehabDetails,
			[action.payload.treeId]: {
				...updateResolved(
					state.rehabDetails[action.payload.treeId],
					action.payload,
				),
				loading: false,
			},
		},
	}),
	[getRehabResultRejected.toString()]: (state, action) => ({
		...state,
		rehabDetails: {
			...state.rehabDetails,
			[action.payload.treeId]: {
				...updateRejected(
					state.rehabDetails[action.payload.treeId],
					action.payload,
				),
				loading: false,
			},
		},
	}),
	[getRehabTrees.toString()]: state => ({
		...state,
		isDetailLoading: true,
		rehabDetails: {},
		rehabTrees: [],
	}),
	[getRehabTreesResolved.toString()]: (state, action) => ({
		...state,
		rehabTrees: action.payload,
		rehabDetails: action.payload.reduce(
			(obj: any, tree: any) => ({
				...obj,
				[tree._id]: { ...paginationInitialState, loading: false },
			}),
			{},
		),
		isDetailLoading: false,
	}),
	[getRehabTreesRejected.toString()]: state => ({
		...state,
		isDetailLoading: false,
	}),
};

export const rehabReducer = createReducer(initState, reducer);
