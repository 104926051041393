import {
	BackgroundTypes,
	BasemapType,
	CBAttributes,
	CBAttributesPerLayer,
	CBHasValuesPerAttributePerLayer,
	ConfigLayerInternal,
	EditorModes,
	EditorState,
	LayerToggle,
	ThemeList,
	ThemeProperties,
	ThemeSettings,
} from './types';
import {
	DefaultTheme,
	ThemeProvider as StyledComponentsThemeProvider,
} from 'styled-components';
import {
	PANEL_BACKGROUND_CHANGED,
	PANEL_EDITOR_CANCEL,
	PANEL_LAYER_TOGGLE,
	PANEL_VISIBILITY_CHANGED,
} from './constants';
import React, { Suspense } from 'react';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';

import { BackgroundRegistry } from './BackgroundRegistry';
import { CONFIG_AND_SERVICES_LOADED } from '@Map/constants';
import { CONFIG_SAVED } from '@Map/layers/constants';
import ConfigAdaptor from '@Map/layers/ConfigAdaptor';
import { ConfigSaveEvent } from '@Map/layers/types';
import { EditorProvider } from '@Components/Editor';
import Events from '../events/Events';
import { IControl } from 'mapbox-gl';
import { LayerPanel } from '@Components/LayerPanel';
import ReactDOM from 'react-dom';
import { UnitSystem } from '@Components/Inputs/units';
import { debounce } from '@Map/utils';
import { fallbackDefaultThemeId } from '@Map/services/ThemeDefaults';

export default class PanelControl extends Events implements IControl {
	private _classNames = {
		button: 'mapboxgl-background-switcher',
		panelOpen: 'layer-panel-open',
		panelEditorOpen: 'layer-panel-editor-open',
	};
	private _buttonTitle = 'Layers to show on the map';
	private _allControlsContainer: HTMLElement | null = null;
	private _container: HTMLElement | null = null;
	private _layerPanel: HTMLElement | null = null;
	private _map: mapboxgl.Map | null = null;
	private _layers: ConfigLayerInternal[] = [];
	private _open: boolean;
	private _theme: Partial<DefaultTheme> = {};
	private _themeName: ThemeProperties['name'] = '';
	private _configAdaptor: ConfigAdaptor | null = null;
	private _themeEditorMode: EditorModes;
	private _enableColorByAttributeRange: boolean;
	private _enableCBRangeHeatmap: boolean;
	private _dispatchEvent;
	private _background: BackgroundTypes;
	private _attributes: CBAttributesPerLayer = {};
	private _debouncedCreatePanel = debounce(this.createPanel, 200);
	private _colorByMaxValues: number;
	private _showMultiThemes: boolean;
	private _themeList: ThemeList | undefined;
	private _selectedThemeId: string;
	private _defaultThemeId: string;
	private _unitSystem: UnitSystem;
	private _arcGISBasemapStylesToken: string | null;
	private _basemapOrigin: BasemapType | null;
	private _editorOpen?: boolean;

	constructor(
		dispatchEvent: <T>(eventName: string, detail: T) => void,
		background: BackgroundTypes,
		colorByMaxValues: number,
		unitSystem: UnitSystem,
		open = false,
		themeEditorMode = EditorModes.noEdit,
		enableColorByAttributeRange = false,
		enableCBRangeHeatmap = false,
		showMultiThemes = false,
		defaultThemeId: string | null = fallbackDefaultThemeId,
		arcGISBasemapStylesToken: string | null = null,
		basemapOrigin: BasemapType | null = 'mapbox',
	) {
		super();
		this._open = open;
		this._themeEditorMode = themeEditorMode;
		this._dispatchEvent = dispatchEvent;
		this._background = background;
		this.attachOutboundEvents();
		this._colorByMaxValues = colorByMaxValues;
		this._enableColorByAttributeRange = enableColorByAttributeRange;
		this._enableCBRangeHeatmap = enableCBRangeHeatmap;
		this._showMultiThemes = showMultiThemes;
		this._unitSystem = unitSystem;
		this._defaultThemeId = defaultThemeId ?? fallbackDefaultThemeId;
		this._selectedThemeId = this._defaultThemeId;
		this._arcGISBasemapStylesToken = arcGISBasemapStylesToken;
		this._basemapOrigin = basemapOrigin;
	}

	set configAdaptor(configAdaptor: ConfigAdaptor) {
		this._configAdaptor = configAdaptor;
	}

	set layers(layers: ConfigLayerInternal[]) {
		this._layers = layers;
		this._debouncedCreatePanel();
	}

	get layers(): ConfigLayerInternal[] {
		return this._layers;
	}

	set themeName(themeName: ThemeProperties['name']) {
		this._themeName = themeName;
		this._debouncedCreatePanel();
	}

	set themeList(themeList: ThemeList | undefined) {
		this._themeList = themeList;
		this._debouncedCreatePanel();
	}

	set selectedThemeId(themeId: string) {
		this._selectedThemeId = themeId;
		this._debouncedCreatePanel();
	}

	get panelOpen(): boolean {
		return this._open;
	}

	get editorOpen(): boolean {
		return this._editorOpen ?? false;
	}

	set layerPanel(element: HTMLDivElement | null) {
		this._layerPanel = element;
		this.createPanel();
	}

	get editorState(): EditorState {
		const canEditAll = this._themeEditorMode === EditorModes.editAll;
		const canEditOwn =
			this._themeEditorMode === EditorModes.editOwn &&
			this._configAdaptor?.isUserTheme;

		return canEditAll || canEditOwn
			? EditorState.edit
			: EditorState.readonly;
	}

	get showThemeSettings(): boolean {
		return this._themeEditorMode === EditorModes.editAll;
	}

	getThemeSettings(): ThemeSettings {
		return {
			shared: !(this._configAdaptor?.isUserTheme ?? true),
		};
	}

	/**
	 * Function called by mapbox when it adds the control to the map
	 * @param map mpabox instance
	 * @returns controls container element
	 */
	onAdd(map: mapboxgl.Map): HTMLElement {
		this._map = map;
		this._container = this.createControl();
		this.attachEventListeners();
		if (this._open) {
			this._allControlsContainer?.classList.add(
				this._classNames.panelOpen,
			);
		}
		return this._container;
	}

	/**
	 * Function called by mapbox when it removes the control from the map
	 */
	onRemove(): void {
		this._container?.remove();
		this._map = null;
	}

	/**
	 * Creates the button which controls the layer panel component being shown/hidden
	 * @returns element containing the control button
	 */
	createControl(): HTMLElement {
		const mapContainer = this._map?.getContainer();

		this._allControlsContainer = mapContainer?.getElementsByClassName(
			'mapboxgl-control-container',
		)[0] as HTMLElement;

		const container = document.createElement('div');
		container.classList.add('mapboxgl-ctrl');
		container.classList.add('mapboxgl-ctrl-group');

		container.innerHTML = this.createButton();

		return container;
	}

	createPanel(): void {
		if (!this._layerPanel) return;
		ReactDOM.render(
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={this._theme}>
					<StyledComponentsThemeProvider theme={this._theme}>
						<Suspense fallback="">
							<EditorProvider
								mode={this._themeEditorMode}
								state={this.editorState}
								onToggleOpen={this._onEditorToggle}
								onLayerEdit={this._onEditLayer}
								onEditorSave={this._onEditorSave}
								onEditorCancel={this._onEditorCancel}
								onEditorReset={this._onEditorReset}
								fetchAttributesList={this._fetchAttributesList}
								attributesPerLayer={this._attributes}
								attributeValuesPerLayer={this._attributeValuesPerLayer()}
								getSelected={this._getSelected}
								onCreateTheme={this._onCreateTheme}
								onThemeEdit={this._onEditTheme}
								onDeleteTheme={this._onDeleteThemeHandler()}
								onThemeChange={this._onChangeTheme}>
								<LayerPanel
									config={this.layers}
									open={this._open}
									onLayerToggle={this._onLayerToggle}
									background={this._background}
									basemapOrigin={
										this._basemapOrigin ?? 'mapbox'
									}
									backgroundChanged={
										this._onBackgroundChanged
									}
									arcGISBasemapStylesToken={
										this._arcGISBasemapStylesToken
									}
									colorByMaxValues={this._colorByMaxValues}
									showMultiThemes={this._showMultiThemes}
									themeName={this._themeName}
									defaultThemeId={this._defaultThemeId}
									themes={this._themeList}
									selectedTheme={this._selectedThemeId}
									unitSystem={this._unitSystem}
									showThemeSettings={this.showThemeSettings}
									themeSettings={this.getThemeSettings()}
									onThemeSettingsChange={
										this._onThemeSettingsChange
									}
									enableCBRangeHeatmap={
										this._enableCBRangeHeatmap
									}
								/>
							</EditorProvider>
						</Suspense>
					</StyledComponentsThemeProvider>
				</ThemeProvider>
			</StyledEngineProvider>,
			this._layerPanel,
		);
	}

	/**
	 * html for button
	 * @returns button html
	 */
	createButton(): string {
		return /* HTML */ `
			<button
				class="${this._classNames.button} loading"
				title="${this._buttonTitle}"
				aria-label="${this._buttonTitle}"
				aria-pressed="false"
				data-cy="background-switcher"
			></button>
		`;
	}

	attachEventListeners(): void {
		const menuButton = this._container?.getElementsByClassName(
			'mapboxgl-background-switcher',
		)[0];

		menuButton?.addEventListener('click', this.toggleMenu);
	}

	setTheme(theme: DefaultTheme): void {
		this._theme = theme;
		this.createPanel();
	}

	/**
	 * Shows/hides the layer panel as well as added classes
	 * to the maps container to shrink/expand the width
	 */
	toggleMenu = (): void => {
		this._open = !this._open;
		this.createPanel();
		if (this._open) {
			this._allControlsContainer?.classList.add(
				this._classNames.panelOpen,
			);
		} else {
			this._allControlsContainer?.classList.remove(
				this._classNames.panelOpen,
			);
		}

		this._dispatchEvent(PANEL_VISIBILITY_CHANGED, {
			open: this.panelOpen,
			editorOpen: this.editorOpen,
		});
	};

	/**
	 * Events dispatched out to layer panel
	 */
	attachOutboundEvents(): void {
		this.on(CONFIG_AND_SERVICES_LOADED, this._onAllLoaded);
		this.on<ConfigSaveEvent>(
			CONFIG_SAVED,
			({ layers, selectedThemeId, themeList }) => {
				this.layers = layers;
				this.selectedThemeId = selectedThemeId;
				this.themeList = themeList;
			},
		);
	}

	/**
	 * Event triggered when all services and config have loaded to remove the
	 * loading animation from the layer panel toggle button
	 */
	private _onAllLoaded(): void {
		const menuButton = this._container?.getElementsByClassName(
			'mapboxgl-background-switcher',
		)[0];

		menuButton?.classList.remove('loading');
	}

	/**
	 * Emits event to toggle the layer
	 */
	_onLayerToggle = (params: LayerToggle): void => {
		this._dispatchEvent(PANEL_LAYER_TOGGLE, params);
		if (!this._configAdaptor) return;
		this._configAdaptor.setVisbilityOfLayers(params);
		this.fire(PANEL_LAYER_TOGGLE, {
			layerVisibility: this._configAdaptor.layerVisibility,
		});
	};

	/**
	 * Updates the mapbox style when the background event is fired
	 */
	_onBackgroundChanged = (background: BackgroundTypes): void => {
		if (this._arcGISBasemapStylesToken)
			BackgroundRegistry.ArcGISBasemapsToken = this._arcGISBasemapStylesToken;
		const backgroundInfo = BackgroundRegistry.getStyleWithFallback(
			background,
			this._arcGISBasemapStylesToken,
		);
		this._map?.setStyle(backgroundInfo.uri);
		this._background = backgroundInfo.key;
		this.fire(PANEL_BACKGROUND_CHANGED, { background, backgroundInfo });
		this._dispatchEvent(PANEL_BACKGROUND_CHANGED, {
			background,
			backgroundInfo,
		});
	};

	/**
	 * Adds class to all controls container as to wether
	 * the editor is open or not
	 */
	_onEditorToggle = (open: boolean): void => {
		this._editorOpen = open;

		if (open) {
			this._allControlsContainer?.classList.add(
				this._classNames.panelEditorOpen,
			);
		} else {
			this._allControlsContainer?.classList.remove(
				this._classNames.panelEditorOpen,
			);
		}

		this._dispatchEvent(PANEL_VISIBILITY_CHANGED, {
			open: this.panelOpen,
			editorOpen: this.editorOpen,
		});
	};

	_onEditLayer = (layerId: string, edits: ConfigLayerInternal): void => {
		if (!this._configAdaptor) return;
		this._configAdaptor.editLayer(layerId, edits);
		this.layers = this._configAdaptor.convertForPanel();
	};

	_onEditTheme = (name: string): void => {
		if (!this._configAdaptor) return;
		this._configAdaptor.themeName = name;
		this.themeName = name;
	};

	_onEditorSave = async (): Promise<void> => {
		await this._configAdaptor?.saveEditorChanges();
		this.createPanel();
	};

	_onEditorCancel = async (): Promise<void> => {
		if (!this._configAdaptor) return;
		await this._configAdaptor.cancelChanges();
		this.themeName = this._configAdaptor.themeName;
		this.layers = this._configAdaptor.convertForPanel();
		this.fire(PANEL_EDITOR_CANCEL);
	};

	_onEditorReset = (): void => {
		if (!this._configAdaptor) return;
		this._configAdaptor.resetChanges();
		this.themeName = this._configAdaptor.themeName;
		this.layers = this._configAdaptor.convertForPanel();
	};

	_onDeleteThemeHandler = () => {
		if (!this._configAdaptor?.isThemeDeletable()) return;
		return this._onDeleteTheme;
	};

	_onDeleteTheme = async () => {
		if (!this._configAdaptor) return;
		await this._configAdaptor?.deleteTheme();
		this.themeName = this._configAdaptor.themeName;
		this.layers = this._configAdaptor.convertForPanel();
	};

	_onChangeTheme = async (themeId: string) => {
		if (!this._configAdaptor) return;
		await this._configAdaptor?.changeTheme(themeId);
		this.themeName = this._configAdaptor.themeName;
		this.layers = this._configAdaptor.convertForPanel();
	};

	_onThemeSettingsChange = (settings: ThemeSettings) => {
		if (!this._configAdaptor) return;
		this._configAdaptor.isUserTheme = !settings.shared;
		this._debouncedCreatePanel();
	};

	_fetchAttributesList = (layerId: string): void => {
		this._getAttributeList(layerId);
	};

	_getSelected = (layerId: string): ConfigLayerInternal | undefined => {
		return this._configAdaptor?.getLayer(layerId)?.layerConfig;
	};

	_onCreateTheme = (): void => {
		this._configAdaptor?.createTheme();
	};

	private async _getAttributeList(layerId: string) {
		const attributes = await this._configAdaptor?.getAttributesList(
			layerId,
		);
		if (!attributes) return;
		this._setAttributes(layerId, attributes);
	}

	private _setAttributes(layerId: string, attributes: CBAttributes[]) {
		// filter out non-discrete attributes as currently not supporting range
		this._attributes[layerId] = this._enableColorByAttributeRange
			? attributes
			: attributes.filter(({ fieldType }) => fieldType === 'discrete');
		this.createPanel();
	}

	private _attributeValuesPerLayer():
		| CBHasValuesPerAttributePerLayer
		| undefined {
		return this._configAdaptor?.cbHasValuesPerAttributePerLayer;
	}
}
