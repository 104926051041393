import {
	BBox,
	MapOptions,
	MapRehabLayer,
	MapRiskLayer,
	SourceOptions,
} from '@Types';
import { MapPosition, ReactMapProps, SourceType } from '@innovyze/inno-map';
import { assetsBaseUrl, riskBaseUrl, tilesBaseUrl } from '@Apis';
import { defaultInspectionSources, otherOptions } from './index';
import {
	propertyLookupRehabResults,
	propertyLookupRiskScoreGroup,
	propertyLookupRiskScoreVersion,
} from './mapDefinitions.utils';

// Geojson layers need query params based on MapPosition so to only return minimum required
export interface QueryOptions {
	radiusDefault: number; // m
	boundMargin: number; // %
	useBBox: boolean;
	singlePage: boolean;
	precisionLonLat: number;
	precisionRadius: number;
}

export const queryOptionsDefault: QueryOptions = {
	radiusDefault: 5000, // m
	boundMargin: 1.02, // %
	useBBox: true,
	singlePage: true,
	precisionLonLat: 6,
	precisionRadius: 0,
};

const queryBBox = (opts: QueryOptions, bbox: BBox | undefined): string =>
	opts.useBBox && bbox && bbox.length === 4
		? `&bbox=${bbox[0].toFixed(opts.precisionLonLat)}` +
		  `,${bbox[1].toFixed(opts.precisionLonLat)}` +
		  `,${bbox[2].toFixed(opts.precisionLonLat)}` +
		  `,${bbox[3].toFixed(opts.precisionLonLat)}`
		: '';

const queryLLR = (
	opts: QueryOptions,
	position: MapPosition | undefined,
	radius: number | undefined,
): string =>
	position && radius
		? `&lon=${position.center[0].toFixed(opts.precisionLonLat)}` +
		  `&lat=${position.center[1].toFixed(opts.precisionLonLat)}` +
		  `&rad=${radius.toFixed(opts.precisionRadius)}`
		: '';

const queryOthers = (opts: QueryOptions): string =>
	opts.singlePage ? '&singlePage=1' : '';

const query = (
	opts: QueryOptions,
	position: MapPosition | undefined,
	radius: number | undefined,
	bbox: BBox | undefined,
): string =>
	queryLLR(opts, position, radius) +
	queryBBox(opts, bbox) +
	queryOthers(opts);

const url = (
	source: SourceOptions,
	opts: QueryOptions,
	position: MapPosition | undefined,
	radius: number | undefined,
	bbox: BBox | undefined,
): string => `${source.url}?${query(opts, position, radius, bbox).slice(1)}`;

// Would like to use this rather than propertyLookupIndex
// but currently need to set propertyLookup to valid calls
// on first dataServices load - subsequent changes are
// ignored in inno-map
const propertyLookupVersion = (layers: MapRiskLayer[] = []) => {
	const p: Record<string, string> = {};
	for (let n = 0; n < layers.length; ++n) {
		p[`risk${n}`] = propertyLookupRiskScoreVersion(layers[n].versionId);
	}
	return p;
};

const propertyLookupIndex = (riskGroupCount = 0) => {
	const p: Record<string, string> = {};
	for (let n = 0; n < riskGroupCount; ++n) {
		p[`risk${n}`] = propertyLookupRiskScoreGroup(n);
	}
	return p;
};

const propertyLookupRehab = (layers: MapRehabLayer[] = []) => {
	const p: Record<string, string> = {};
	for (let n = 0; n < layers.length; ++n) {
		p[`rehab${n}`] = propertyLookupRehabResults(
			layers[n].id,
			layers[n].lastRun,
			layers[n].assetType,
		);
	}
	return p;
};

export const dataServicesMainMap = (
	options: MapOptions,
	token: string | undefined,
	position: MapPosition | undefined,
	radius: number | undefined,
	bbox: BBox | undefined,
	riskGroupCountLimit = -1,
	isTiledInspectionsEnabled = false,
): ReactMapProps['dataServices'] => {
	if (!token) {
		return [];
	}

	// Would like to use version rather than index
	// but currently need to set propertyLookup to valid calls
	// on first dataServices load - as subsequent changes are
	// ignored in inno-map
	const propertyLookup =
		riskGroupCountLimit > 0
			? propertyLookupIndex(riskGroupCountLimit)
			: propertyLookupVersion(options.riskLayers ?? []);

	const rehabPropertyLookup = propertyLookupRehab(options.rehabLayers ?? []);

	const tiles = options.tilesSource
		? [
				{
					id: options.tilesSource.dataServiceId,
					type: options.tilesSource.type,
					url: options.tilesSource.url,
					token: token,
					propertyLookup: {
						...propertyLookup,
						...rehabPropertyLookup,
					},
					attributesUrl: assetsBaseUrl,
				},
		  ]
		: [];

	const inspections: ReactMapProps['dataServices'] = [];

	if (position && !isTiledInspectionsEnabled) {
		const inspectionSources =
			options.inspectionSources ?? defaultInspectionSources;
		const inspectionQueryOptions =
			options.generalOptions ?? queryOptionsDefault;
		inspectionSources.forEach(s => {
			if ((s?.minZoom ?? 0) <= position.zoom) {
				inspections?.push({
					type: s.type,
					id: s.dataServiceId,
					displayName: s.layerName,
					token: token,
					url: url(s, inspectionQueryOptions, position, radius, bbox),
				});
			}
		});
	}

	const otherSource = options.otherSource ?? otherOptions;

	const other = otherSource
		? [
				{
					id: otherSource.dataServiceId,
					type: otherSource.type,
					url: otherSource.url,
					token: token,
					attributesUrl: riskBaseUrl,
				},
		  ]
		: [];

	const tiledInspections = isTiledInspectionsEnabled
		? [
				{
					id: 'tiled-inspections',
					type: SourceType.TILES,
					url: `${tilesBaseUrl}/v1/tile-json/Inspections`,
					token: token,
				},
		  ]
		: [];

	return [tiles, inspections, other, tiledInspections].flat(2);
};
