import React from 'react';
import { InfoCard } from '@innovyze/stylovyze';
import { ItemList } from './ItemList';

interface ItemListWrapperProps {
	title: string;
	items: string[];
	cy?: string;
}

export const ItemListWrapper = ({
	title,
	items,
	cy,
}: ItemListWrapperProps): JSX.Element => {
	if (items.length) {
		return (
			<InfoCard title={title} dataCy={cy} applyBodyPadding={false}>
				{items.map((value, index) => (
					<ItemList cy={`${cy}-${index}`} value={value} key={index} />
				))}
			</InfoCard>
		);
	}
	return <></>;
};
