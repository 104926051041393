import { Environments, PartialServiceMap } from '@innovyze/stylovyze';

export const apiConfig: PartialServiceMap = {
  // getApiEnvironment returns DEV instead of LOCAL for some reason
  [Environments.LOCAL]: {
    sensorDataV2: 'https://saas-info360-sensor-data-dev.info360-dev.com/dev',
    goals: 'https://saas-goal-management.info360-dev.com/dev',
    assets: 'https://saas-asset-management.info360-dev.com/dev',
    edgeAnalytics: 'https://saas-edge-analytics.info360-dev.com/dev',
  },
};
