import React from 'react';
import { useGlobalization } from '../../../i18n';
import * as Styled from './NoResults.styled';

const NoResults = (): JSX.Element => {
  const { t } = useGlobalization();
  return <Styled.NoResults>{t('No data available')}</Styled.NoResults>;
};

export default NoResults;
