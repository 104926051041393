import {
  ChartInstanceRef,
  StackableInstanceWrapperProps,
  StackableSeriesProps,
  Status,
  createContext,
  createSlots,
  createStackableChart,
  useStableEventHandlers,
} from '../../core/_summaryze-chart';
import * as Options from './mass-balance-chart-options';
import { useGlobalization } from '../../../i18n';
import * as Collapsible from '../../core/collapsible';
import * as React from 'react';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Highcharts from 'highcharts/highstock';
import { produce } from 'immer';
import styled from 'styled-components';
import { nanoid } from 'nanoid';
import { Resolution } from '../../core/_insight-chart';
import { resolutionToZoomButtons } from '../../presets/insight-historical-chart/insight-historical-chart.utils';
import { useIsFeatureEnabled } from '@innovyze/stylovyze';
import { getTheme, Theme } from '../../core/utils/theme-utils';
import { getThemeColorSchema } from '../../../utils';

const DAILY_GAP_SIZE = 86_400_000;

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Chart
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const Chart = createStackableChart('MassBalanceChart', 10);

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Slots
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const Slots = createSlots<'series-group'>('MassBalanceChart');

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Snapshot Provider
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

type Stats = Record<
  MassBalanceChartSeriesId,
  { value: number | null | undefined; unit: string | undefined }
>;

const [SnapshotProvider, useSnapshot] = createContext<{
  setStat: (
    seriesId: MassBalanceChartSeriesId,
    value: number | null | undefined,
    unit: string | undefined
  ) => void;
}>('MassBalanceChartRoot');

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Chart Root
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

interface MassBalanceChartRootProps {
  children: React.ReactNode;
  enableChartStack?: boolean;
  enableHorizontalGrids?: boolean;
  enableVerticalGrids?: boolean;
  enableMarkers?: boolean;
  openSnapshot?: boolean;
  defaultLimit?: number;
  lowestResolution?: Resolution;
  snapSelection?: { start?: number; end?: number };
  onOpenSnapshotChange?: (open: boolean) => void;
  onSeriesVisibilityChange?: (seriesId: string, type: 'show' | 'hide') => void;
  onXAxisExtremesChange?: (
    min: number | undefined,
    max: number | undefined,
    endEdgeTouched?: boolean
  ) => void;
  status?: string;
  selectedTheme?: Theme;
}

type MassBalanceChartRootContext = Pick<
  MassBalanceChartRootProps,
  | 'enableChartStack'
  | 'enableHorizontalGrids'
  | 'enableVerticalGrids'
  | 'enableMarkers'
  | 'snapSelection'
  | 'defaultLimit'
  | 'lowestResolution'
  | 'status'
  | 'selectedTheme'
>;

type MassBalanceChartEventHandlers = Pick<
  MassBalanceChartRootProps,
  'onSeriesVisibilityChange' | 'onOpenSnapshotChange' | 'onXAxisExtremesChange'
> & {
  syncDateTimeRangePicker: (dateRange: [number | null, number | null]) => void;
};

const [RootContextProvider, useRootContext] = createContext<
  MassBalanceChartRootContext &
    Pick<MassBalanceChartEventHandlers, 'onXAxisExtremesChange'>
>('RootContextProvider');

const MassBalanceChartRoot = React.forwardRef<
  ChartInstanceRef,
  MassBalanceChartRootProps
>((props, ref): React.ReactElement => {
  return (
    <Chart.ChartRoot>
      <_MassBalanceChartRoot {...props} ref={ref} />
    </Chart.ChartRoot>
  );
});

MassBalanceChartRoot.displayName = 'MassBalanceChartRoot';

const _MassBalanceChartRoot = React.forwardRef<
  ChartInstanceRef,
  MassBalanceChartRootProps
>((props, ref): React.ReactElement => {
  const [stats, setStats] = React.useState<Stats>({
    inflow: { value: null, unit: undefined },
    outflow: { value: null, unit: undefined },
    usage: { value: null, unit: undefined },
    storage: { value: null, unit: undefined },
    billing: { value: null, unit: undefined },
    nrw: { value: null, unit: undefined },
    nrwRatio: { value: null, unit: undefined },
  });

  const setStat = React.useCallback(
    (
      seriesId: MassBalanceChartSeriesId,
      value: number | null | undefined,
      unit: string | undefined
    ) => {
      setStats(
        produce((_stats) => {
          _stats[seriesId] = { value, unit };
        })
      );
    },
    [setStats]
  );

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Options & Event Handlers
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  const enableNewHeader = useIsFeatureEnabled(
    'info-360-analytics-charts-date-time-picker'
  );

  const eventHandlersRef =
    useStableEventHandlers<MassBalanceChartEventHandlers>({
      onSeriesVisibilityChange: props.onSeriesVisibilityChange,
      onXAxisExtremesChange: props.onXAxisExtremesChange,
      syncDateTimeRangePicker: Chart.useRootContext('MassBalanceChartRoot')
        .syncDateTimeRangePicker,
    });

  const stackedHeaderInstanceInitialOptions = React.useMemo(() => {
    const options = Options.stackedHeaderInstanceInitialOptions;
    options.rangeSelector.inputEnabled = !enableNewHeader;
    return options;
  }, [eventHandlersRef, enableNewHeader]);

  const stackedFooterInstanceInitialOptions = React.useMemo(() => {
    return Options.makeOptionsWithEventHandlers(
      Options.stackedFooterInstanceInitialOptions,
      eventHandlersRef
    );
  }, [eventHandlersRef]);

  const overlayInstanceInitialOptions = React.useMemo(() => {
    const options = Options.overlayInstanceInitialOptions;
    options.rangeSelector.inputEnabled = !enableNewHeader;
    return Options.makeOptionsWithEventHandlers(options, eventHandlersRef);
  }, [eventHandlersRef, enableNewHeader]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  return (
    <RootContextProvider
      lowestResolution={props.lowestResolution}
      defaultLimit={props.defaultLimit}
      enableChartStack={props.enableChartStack}
      enableHorizontalGrids={props.enableHorizontalGrids}
      enableVerticalGrids={props.enableVerticalGrids}
      enableMarkers={props.enableMarkers}
      snapSelection={props.snapSelection}
      onXAxisExtremesChange={props.onXAxisExtremesChange}
      status={props.status}
      selectedTheme={props.selectedTheme}>
      <RootStyles.SnapshotContainer>
        <Collapsible.CollapsibleRoot
          open={props.openSnapshot}
          onOpenChange={props.onOpenSnapshotChange}>
          <Collapsible.CollapsibleTrigger side="right">
            <SnapshotTrigger />
          </Collapsible.CollapsibleTrigger>
          <Collapsible.CollapsibleContent>
            <Snapshot stats={stats} />
          </Collapsible.CollapsibleContent>
        </Collapsible.CollapsibleRoot>
      </RootStyles.SnapshotContainer>
      <SnapshotProvider setStat={setStat}>
        <RootStyles.ChartContainer>
          {props.enableChartStack ? (
            <Slots.Slottable slottableChildren={props.children}>
              {(slots) => {
                const series = slots.getSlot('series-group');

                return (
                  <Chart.ChartStackedInstances
                    instanceWrapperComponent={StackableInstanceWrapper}
                    headerInstanceInitialOptions={
                      stackedHeaderInstanceInitialOptions
                    }
                    seriesInstanceInitialOptions={
                      Options.stackedSeriesInstanceInitialOptions
                    }
                    footerInstanceInitialOptions={
                      stackedFooterInstanceInitialOptions
                    }
                    constructorFunction={Highcharts.stockChart}>
                    {series}
                  </Chart.ChartStackedInstances>
                );
              }}
            </Slots.Slottable>
          ) : (
            <Chart.ChartOverlayInstance
              ref={ref}
              instanceWrapperComponent={StackableInstanceWrapper}
              initialOptions={overlayInstanceInitialOptions}
              constructorFunction={Highcharts.stockChart}>
              {props.children}
            </Chart.ChartOverlayInstance>
          )}
        </RootStyles.ChartContainer>
        {props.enableChartStack ? (
          <div className="ref-holder" style={{ display: 'none' }}>
            <Chart.ChartOverlayInstance
              ref={ref}
              instanceWrapperComponent={StackableInstanceWrapper}
              initialOptions={overlayInstanceInitialOptions}
              constructorFunction={Highcharts.stockChart}>
              {props.children}
            </Chart.ChartOverlayInstance>
          </div>
        ) : null}
      </SnapshotProvider>
    </RootContextProvider>
  );
});

_MassBalanceChartRoot.displayName = '_MassBalanceChartRoot';

const RootStyles = {
  SnapshotContainer: styled.div``,
  ChartContainer: styled.div`
    position: relative;
    flex: 1 1 auto;
    min-height: 0;
  `,
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Series Group Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
interface MassBalanceChartSeriesGroupProps {
  children: React.ReactNode;
}

const MassBalanceChartSeriesGroup = (
  props: MassBalanceChartSeriesGroupProps
): React.ReactElement => {
  return <Slots.Slot id="series-group">{props.children}</Slots.Slot>;
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Series Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

interface MassBalanceChartSeriesProps {
  data?: MassBalanceChartSeriesData;
  error?: unknown;
  hidden?: boolean;
  id: MassBalanceChartSeriesId;
  navigatorData?: MassBalanceChartSeriesData;
  status?: Status;
  unit?: string;
  zIndex?: number;
}

type MassBalanceChartSeriesData = [timestamp: number, value: number | null][];

type MassBalanceChartSeriesId =
  | 'inflow'
  | 'outflow'
  | 'storage'
  | 'usage'
  | 'billing'
  | 'nrw'
  | 'nrwRatio';

const MassBalanceChartSeries = (
  props: MassBalanceChartSeriesProps & StackableSeriesProps
): null => {
  const { t } = useGlobalization();
  const snapshot = useSnapshot('MassBalanceChartSeries');
  const seriesRef = Chart.useSeries(props, 'IliChartSeries');
  const instanceRef = Chart.useInstance('IliChartSeries');
  const rootContext = useRootContext('MassBalanceChartSeries');

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets NRW Ratio separate Y axis
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  // t function changes reference sometimes, so using it directly as the effect
  // dependency will generate multiple Y axes
  const nrwRatioYAxisTitle = React.useMemo(() => {
    return t('NRW %');
  }, [t]);

  React.useEffect(() => {
    if (props.stackedInstanceType === undefined && props.id === 'nrwRatio') {
      const yAxisId = nanoid();
      instanceRef.current?.addAxis({
        id: yAxisId,
        opposite: true,
        gridLineWidth: 0,
        title: { text: nrwRatioYAxisTitle },
      });
      seriesRef.current?.update({
        yAxis: yAxisId,
      } as Highcharts.SeriesOptionsType);

      return () => {
        instanceRef.current?.get(yAxisId)?.remove();
      };
    }
  }, [seriesRef, props.id, props.stackedInstanceType, nrwRatioYAxisTitle]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets series name
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    const names: Record<MassBalanceChartSeriesId, string> = {
      inflow: t('Inflow Volume'),
      outflow: t('Outflow Volume'),
      usage: t('Usage Volume'),
      storage: t('Storage Volume'),
      billing: t('Billing Volume'),
      nrw: t('NRW Volume'),
      nrwRatio: t('NRW %'),
    };

    seriesRef.current?.update({
      name: names[props.id],
    } as Highcharts.SeriesOptionsType);
  }, [seriesRef, props.id]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets series color
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    const theme = rootContext.selectedTheme;
    const _colors = getThemeColorSchema(theme);
    const colors: Record<MassBalanceChartSeriesId, string> = {
      inflow: theme === 'Default' ? '#00ABD1' : _colors[0],
      outflow: theme === 'Default' ? '#758D95' : _colors[1],
      usage: theme === 'Default' ? '#60DDFF' : _colors[2],
      storage: theme === 'Default' ? '#E56C43' : _colors[3],
      billing: theme === 'Default' ? '#83BD41' : _colors[4],
      nrw: theme === 'Default' ? '#497F03' : _colors[5],
      nrwRatio: theme === 'Default' ? '#EFB105' : _colors[6],
    };

    seriesRef.current?.update({
      color: colors[props.id],
    } as Highcharts.SeriesOptionsType);
  }, [seriesRef, props.id, rootContext.selectedTheme]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets series visibility
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    seriesRef.current?.update({
      visible: !props.hidden,
    } as Highcharts.SeriesOptionsType);
  }, [seriesRef, props.hidden]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets overlay separate Y axis / stacked label
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    if (props.stackedInstanceType === 'stacked-series') {
      let title: string | undefined = undefined;

      const labels: Record<MassBalanceChartSeriesId, string> = {
        inflow: t('Inflow Volume'),
        outflow: t('Outflow Volume'),
        usage: t('Usage Volume'),
        storage: t('Storage Volume'),
        billing: t('Billing Volume'),
        nrw: t('NRW Volume'),
        nrwRatio: t('NRW %'),
      };

      title = `${labels[props.id]}`;

      if (props.unit) {
        title += ` (${props.unit})`;
      }

      instanceRef.current?.yAxis[0]?.update({
        title: { text: title },
      });
    }
  }, [instanceRef, props.id, props.unit, props.stackedInstanceType]);

  /* * * * * * * * * * * *
   * Sets series data
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    if (
      props.stackedInstanceType === 'stacked-header' ||
      props.stackedInstanceType === 'stacked-footer'
    ) {
      seriesRef.current?.update({
        data: props.navigatorData,
        gapSize: DAILY_GAP_SIZE,
      } as Highcharts.SeriesOptionsType);
    } else {
      seriesRef.current?.update({
        data: props.data,
      } as Highcharts.SeriesOptionsType);
    }

    /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
     * Set snapshot value
     * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

    if (
      props.stackedInstanceType === undefined ||
      props.stackedInstanceType === 'stacked-series'
    ) {
      let sum: number | null | undefined = undefined;

      if (props.status === undefined || props.status === 'resolved') {
        sum = props.data?.reduce<null | number>((sum, [timestamp, value]) => {
          if (
            (rootContext.snapSelection?.start === undefined ||
              rootContext.snapSelection.start <= timestamp) &&
            (rootContext.snapSelection?.end === undefined ||
              rootContext.snapSelection.end >= timestamp) &&
            value !== null
          ) {
            if (sum === null) {
              return value;
            } else {
              return sum + value;
            }
          } else {
            return sum;
          }
        }, null);
      } else if (props.status === 'rejected') {
        sum = null;
      }

      snapshot.setStat(props.id, sum, props.unit);
    }
  }, [
    props.data,
    props.navigatorData,
    props.stackedInstanceType,
    props.status,
    rootContext.snapSelection,
    seriesRef,
    snapshot.setStat,
  ]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Adds navigator data to series
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    if (props.stackedInstanceType === undefined) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      seriesRef.current?.update({
        navigatorOptions: {
          data: props.navigatorData,
        },
      } as Highcharts.SeriesOptionsType);
    }
  }, [seriesRef, props.navigatorData, props.stackedInstanceType]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Set stacked mode series status
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    if (props.stackedInstanceType === 'stacked-series') {
      if (props.status === 'loading') {
        instanceRef.current?.showLoading();
      } else if (props.status === 'rejected') {
        instanceRef.current?.showLoading(t('Failed to retrieve data'));
      } else {
        if (props.data === undefined || props.data.length === 0) {
          instanceRef.current?.showLoading(t('No Data'));
        } else {
          instanceRef.current?.hideLoading();
        }
      }
    }
  }, [instanceRef, t, props.stackedInstanceType, props.status, props.data]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  return null;
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Stackable Instance Wrapper Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const StackableInstanceWrapper = (
  props: StackableInstanceWrapperProps
): React.ReactElement => {
  const { t } = useGlobalization();
  const rootContext = useRootContext('StackableInstanceWrapper');
  const instanceRef = Chart.useInstance('StackableInstanceWrapper');
  const instanceSeriesProps =
    Chart.useInstanceSeriesProps<MassBalanceChartSeriesProps>(
      'StackableInstanceWrapper'
    );

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets Zoom Options
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    if (
      props.stackedInstanceType === undefined ||
      props.stackedInstanceType === 'stacked-header'
    ) {
      const lowestResolution = rootContext.lowestResolution ?? 'RAW';
      const defaultLimit = rootContext.defaultLimit ?? 180;
      const zoomButtons = resolutionToZoomButtons(
        lowestResolution,
        defaultLimit
      );

      instanceRef.current?.update({
        rangeSelector: { buttons: zoomButtons },
      });
    }
  }, [
    instanceRef,
    props.stackedInstanceType,
    rootContext.lowestResolution,
    rootContext.defaultLimit,
  ]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets overlay main Y axis label
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  const usageSeriesUnit = React.useMemo(() => {
    let unit: string | undefined = undefined;
    const usageSeries = instanceSeriesProps.find(
      (_props) => _props.id === 'usage'
    );
    if (usageSeries && usageSeries.unit) {
      unit = usageSeries.unit;
    }

    return unit;
  }, [instanceSeriesProps]);

  React.useEffect(() => {
    if (props.stackedInstanceType === undefined) {
      let title = t('Volume');

      if (usageSeriesUnit) {
        title += ` (${usageSeriesUnit})`;
      }

      instanceRef.current?.update({
        yAxis: { title: { text: title } },
      });
    }
  }, [instanceRef, usageSeriesUnit, props.stackedInstanceType]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets horizontal gridlines
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    if (
      props.stackedInstanceType === undefined ||
      props.stackedInstanceType === 'stacked-series'
    ) {
      instanceRef.current?.yAxis[0]?.update({
        gridLineWidth: rootContext.enableHorizontalGrids ? 1 : 0,
      });
    }
  }, [
    instanceRef,
    props.stackedInstanceType,
    rootContext.enableHorizontalGrids,
  ]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets vertical gridlines
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    if (
      props.stackedInstanceType === undefined ||
      props.stackedInstanceType === 'stacked-series'
    ) {
      instanceRef.current?.xAxis[0]?.update({
        gridLineWidth: rootContext.enableVerticalGrids ? 1 : 0,
      });
    }
  }, [instanceRef, props.stackedInstanceType, rootContext.enableVerticalGrids]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Set snap selection
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    const min = rootContext.snapSelection?.start;
    const max = rootContext.snapSelection?.end;
    const currentMin = instanceRef.current?.xAxis[0]?.min ?? undefined;
    const currentMax = instanceRef.current?.xAxis[0]?.max ?? undefined;

    if (min !== undefined || max !== undefined) {
      instanceRef.current?.xAxis[0]?.setExtremes(
        min ?? currentMin,
        max ?? currentMax
      );
    }
  }, [
    instanceRef,
    rootContext.snapSelection?.start,
    rootContext.snapSelection?.end,
  ]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Set overlay mode series status
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  const { status, noData } = React.useMemo(() => {
    const status = instanceSeriesProps[0]?.status;
    const noData =
      (status === undefined || status === 'resolved') &&
      instanceSeriesProps.every(
        ({ data }) => data === undefined || data.length === 0
      );

    return { status, noData };
  }, [instanceSeriesProps]);

  React.useEffect(() => {
    if (props.stackedInstanceType === undefined) {
      if (status === 'loading') {
        instanceRef.current?.showLoading();
      } else if (status === 'rejected') {
        instanceRef.current?.showLoading(t('Failed to retrieve data'));
      } else {
        if (noData) {
          instanceRef.current?.showLoading(t('No Data'));
          instanceRef.current?.update({
            chart: {
              events: {
                render: function (this) {
                  const container = document.getElementById(
                    this?.container?.id
                  );
                  container
                    ?.getElementsByClassName('highcharts-scrollbar-thumb')[0]
                    ?.setAttribute('display', 'none');
                  container
                    ?.getElementsByClassName('highcharts-scrollbar-rifles')[0]
                    ?.setAttribute('display', 'none');
                },
              },
            },
          });
        } else {
          instanceRef.current?.hideLoading();
          instanceRef.current?.update({
            chart: {
              events: {
                render: function (this) {
                  const container = document.getElementById(
                    this?.container?.id
                  );
                  container
                    ?.getElementsByClassName('highcharts-scrollbar-thumb')[0]
                    ?.removeAttribute('display');
                  container
                    ?.getElementsByClassName('highcharts-scrollbar-rifles')[0]
                    ?.removeAttribute('display');
                },
              },
            },
          });
        }
      }
    }
  }, [instanceRef, t, props.stackedInstanceType, status, noData]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets data markers
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    if (
      props.stackedInstanceType === undefined ||
      props.stackedInstanceType === 'stacked-series'
    ) {
      instanceRef.current?.update({
        plotOptions: {
          series: {
            marker: {
              enabled: rootContext.enableMarkers,
              radius: rootContext.enableMarkers ? 4 : 0,
            },
          },
        },
      });
    }
  }, [instanceRef, rootContext.enableMarkers, props.stackedInstanceType]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Set Theme
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    const theme = rootContext.selectedTheme;
    const themeOptions = getTheme(theme ?? 'Default');
    instanceRef.current?.update({ ...themeOptions });
  }, [instanceRef, rootContext.selectedTheme]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  return <>{props.children}</>;
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Snapshot
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const Snapshot = (props: { stats: Stats }): React.ReactElement => {
  const { t } = useGlobalization();

  const labels: Record<MassBalanceChartSeriesId, string> = React.useMemo(() => {
    return {
      inflow: t('Inflow'),
      outflow: t('Outflow'),
      usage: t('Usage'),
      storage: t('Storage'),
      billing: t('Billing'),
      nrw: t('NRW'),
      nrwRatio: t('NRW Ratio'),
    };
  }, [t]);

  return (
    <SnapshotStyles.Root>
      {Object.entries(props.stats).map(([massBalanceId, { value, unit }]) => {
        const id = massBalanceId as MassBalanceChartSeriesId;
        const label = labels[id];

        let _value = t('Loading');

        if (id !== 'nrwRatio') {
          if (value === null) {
            _value = t('No Data');
          } else if (value !== undefined) {
            _value = Highcharts.numberFormat(value, 2);
            if (unit !== undefined) {
              _value += ` (${unit})`;
            }
          }
        } else {
          const nrw = props.stats['nrw'];
          const usage = props.stats['usage'];

          if (nrw.value !== undefined && usage.value !== undefined) {
            if (nrw.value !== null && usage.value !== null) {
              const nrwP =
                usage.value !== 0 ? (nrw.value / usage.value) * 100 : 0;
              _value = Highcharts.numberFormat(nrwP, 2) + '%';
            } else {
              _value = t('No Data');
            }
          }
        }

        return (
          <SnapshotStyles.Stat key={massBalanceId}>
            <SnapshotStyles.StatLabel>{label}</SnapshotStyles.StatLabel>
            <SnapshotStyles.StatValue>{_value}</SnapshotStyles.StatValue>
          </SnapshotStyles.Stat>
        );
      })}
    </SnapshotStyles.Root>
  );
};

const SnapshotStyles = {
  Root: styled.div`
    align-items: stretch;
    background-color: #f9fafb;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 20px;
    width: 100%;
    font-family:
      Roboto,
      Helvetica Neue,
      San Francisco,
      Segoe UI,
      sans-serif;
  `,
  Stat: styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    text-align: center;
    font-size: 0.85rem;

    &:not(:last-child) {
      margin-right: 20px;
    }
  `,
  StatLabel: styled.div`
    font-weight: bolder;
    margin-bottom: 10px;
  `,
  StatValue: styled.div`
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
  `,
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Snapshot Trigger
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const SnapshotTrigger = (): React.ReactElement => {
  const { t } = useGlobalization();
  const context = Collapsible.useCollapsibleContext('SnapshotTrigger');
  const icon = context.open ? <ArrowUpIcon /> : <ArrowDownIcon />;
  const text = t('Snapshot');

  return (
    <SnapshotTriggerStyles.Root>
      <SnapshotTriggerStyles.Text>{text}</SnapshotTriggerStyles.Text>
      <SnapshotTriggerStyles.Icon>{icon}</SnapshotTriggerStyles.Icon>
    </SnapshotTriggerStyles.Root>
  );
};

const SnapshotTriggerStyles = {
  Root: styled.div`
    align-items: center;
    background-color: #f9fafb;
    display: flex;
    gap: 6px;
    padding: 6px 8px 6px 15px;
  `,
  Icon: styled.div`
    color: #758e94;
  `,
  Text: styled.div`
    color: #758e94;
    font-family:
      Roboto,
      Helvetica Neue,
      San Francisco,
      Segoe UI,
      sans-serif;
    font-size: 0.8rem;
    font-weight: 500;
    text-transform: uppercase;
  `,
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export {
  MassBalanceChartRoot,
  MassBalanceChartSeriesGroup,
  MassBalanceChartSeries,
};

export type {
  MassBalanceChartRootProps,
  MassBalanceChartSeriesGroupProps,
  MassBalanceChartSeriesProps,
  MassBalanceChartRootContext,
  MassBalanceChartEventHandlers,
  MassBalanceChartSeriesId,
  MassBalanceChartSeriesData,
};
