import {
	Environments,
	getApiEnvironment,
	getService,
} from '@innovyze/stylovyze';

import axios from 'axios';
import config from './config.api';
import cookies from 'browser-cookies';
import { formatUrlTrailing } from '@Utils/urlFormatting';

const apiEnv =
	(localStorage.getItem('apiEnv') as keyof typeof Environments) || undefined;

export const inspectionBaseUrl = formatUrlTrailing(
	getService('amInspection', config, apiEnv),
);

const options = {
	// Config will allow you to override centralized endpoints
	// If you want to develop pointing at a different env swap out
	// the 'getApiEnvironment()'
	baseURL: getService('amInspection', config, getApiEnvironment()),
	timeout: 15000,
};

const service = axios.create(options);

service.interceptors.request.use(
	conf => {
		conf.headers.set(
			'Authorization',
			'Bearer ' + cookies.get('auth0.user.token'),
		);
		return conf;
	},
	error => {
		Promise.reject(error);
	},
);

export default service;
