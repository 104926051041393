import Highcharts from 'highcharts';
import type { HiddenSeries } from '../../types/chartState';

export const isSeriesVisible = (
  hiddenSeries: HiddenSeries,
  seriesIndex: number,
  seriesId?: string,
  seriesName?: string
): boolean => {
  if (seriesId !== undefined && hiddenSeries.includes(seriesId)) {
    return false;
  }

  if (seriesIndex !== undefined && hiddenSeries.includes(seriesIndex)) {
    return false;
  }

  if (seriesName !== undefined && hiddenSeries.includes(seriesName)) {
    return false;
  }

  return true;
};

export const getHiddenSeries = (series: Highcharts.Series[]): HiddenSeries =>
  series.reduce<HiddenSeries>((hiddenSeries, serie, index) => {
    const hiddenSeriesCopy = [...hiddenSeries];

    if (serie.visible === false) {
      hiddenSeriesCopy.push(serie.options.id ?? index ?? serie.name);
    }

    return hiddenSeriesCopy;
  }, []);

export const stringifyHiddenSeries = (hiddenSeries?: HiddenSeries): string => {
  return hiddenSeries?.join('') ?? '';
};
