import { createContext } from '../react-context';

import * as React from 'react';
import { useControlledRender } from '../lib-utils';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const createSlots = <SlotName extends string>(
  chartComponentName: string,
  slotNames: SlotName[]
) => {
  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Internals Provider
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  const [InternalsProvider, useInternals] = createContext<{
    checkIn: (slotName: SlotName, content: React.ReactNode) => void;
    checkOut: (slotName: SlotName) => void;
    enabled?: boolean;
  }>(chartComponentName, {
    checkIn: () => null,
    checkOut: () => null,
    enabled: false,
  });

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Slots Provider
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  const [SlotsProvider, useSlots] = createContext<{
    getSlot: (slotName: SlotName) => React.ReactNode;
    getSlotsMap: () => Map<SlotName, React.ReactNode>;
  }>(chartComponentName, {
    getSlot: () => null,
    getSlotsMap: () => new Map(),
  });

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Slots Component
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  const Slots = (props: {
    _children: React.ReactNode;
    children: React.ReactNode;
  }): React.ReactElement => {
    const [renderKey, render] = useControlledRender();
    const hasChildrenMounted = React.useRef(false);

    const slotsRef = React.useRef<Map<SlotName, React.ReactNode>>(new Map());

    /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
     * Slots Internals
     * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

    const checkIn = React.useCallback(
      (slotName: SlotName, children: React.ReactNode) => {
        slotsRef.current.set(slotName, children);
        if (hasChildrenMounted.current) render();
      },
      []
    );

    const checkOut = React.useCallback((slotName: SlotName) => {
      slotsRef.current.delete(slotName);
      render();
    }, []);

    React.useLayoutEffect(() => {
      hasChildrenMounted.current = true;
      render();
    }, []);

    /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
     * Slots Context
     * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

    const getSlot = React.useCallback(
      (slotName: SlotName) => {
        if (!slotsRef.current.has(slotName)) return null;
        return slotsRef.current.get(slotName);
      },
      [renderKey]
    );

    const getSlotsMap = React.useCallback(() => {
      return slotsRef.current;
    }, [renderKey]);

    return (
      <>
        <InternalsProvider enabled={true} checkIn={checkIn} checkOut={checkOut}>
          {props._children}
        </InternalsProvider>
        <SlotsProvider getSlot={getSlot} getSlotsMap={getSlotsMap}>
          {props.children}
        </SlotsProvider>
      </>
    );
  };

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Slot Component
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  const Slot = (props: {
    name: SlotName;
    children: React.ReactNode;
  }): React.ReactElement | null => {
    const slots = useInternals('Slot');

    React.useLayoutEffect(() => {
      slots.checkIn(props.name, props.children);

      return () => {
        slots.checkOut(props.name);
      };
    }, [props.name, props.children]);

    return slots.enabled ? null : <>{props.children}</>;
  };

  return [Slots, Slot, useSlots] as const;
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Module exports
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export { createSlots };
