/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { ColorType, Options, SeriesOptionsType } from 'highcharts/highstock';
import type { MutableRefObject } from 'react';
import type { PieChartRootProps } from './pie-chart.components';
import Highcharts from 'highcharts';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const Rainbow = require('rainbowvis.js');

export type EventHandlers = PieChartRootProps['events'] | undefined;

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Pie Chart Options
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const makeOptions = (
  eventHandlers: MutableRefObject<EventHandlers>
): Options => {
  return {
    chart: {
      plotShadow: false,
      type: 'pie',
    },
    credits: { enabled: false },
    navigator: { enabled: false },
    title: {
      text: undefined,
      margin: 40,
      y: 30,
    },
    legend: {
      enabled: true,
      symbolHeight: 10,
      symbolRadius: 0,
    },
    rangeSelector: { enabled: false },
    tooltip: {
      pointFormat: '<b style="font-size: 16px">{point.percentage:.1f}%</b>',
    },
    accessibility: {
      point: {
        valueSuffix: '%',
      },
    },
    exporting: { enabled: false },
    plotOptions: {
      series: {
        events: {
          hide: function (event) {
            // TODO: Check this error
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const target = event.target as SeriesOptionsType | null;
            if (
              target &&
              'baseSeries' in target &&
              target.baseSeries === undefined
            ) {
              eventHandlers.current?.series?.onVisibilityChange?.(
                target.index!,
                event.type as 'hide' | 'show'
              );
            }
          },
          show: function (event) {
            // TODO: Check this error
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const target = event.target as SeriesOptionsType | null;
            if (
              target &&
              'baseSeries' in target &&
              target.baseSeries === undefined
            ) {
              eventHandlers.current?.series?.onVisibilityChange?.(
                target.index!,
                event.type as 'hide' | 'show'
              );
            }
          },
        },
      },
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false,
          format:
            '<span style="font-size: 14px">{point.percentage:.1f}%</span>',
        },
        showInLegend: true,
        borderWidth: 0,
      },
    },
  };
};

export const makeColorSchema = (length: number, primaryColor: string): ColorType[] => {
  if (length <= 1) return [primaryColor];
  const colors = Highcharts.getOptions().colors.map((c, i) =>
    Highcharts.color(Highcharts.getOptions().colors[0])
        .brighten((i - 3) / length)
        .get()
);
return colors;
};
