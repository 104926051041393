import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

import React from 'react';

export const DistributeVerticalIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon {...props}>
			<path d="M2 16H5V14H19V16H22V18H19V20H5V18H2V16M2 8H7V10H17V8H22V6H17V4H7V6H2V8Z" />
		</SvgIcon>
	);
};
