/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
import { Reading } from '../core/types/reading.types';
import { ANALYTIC_FUNCTIONS } from '../utils/analyticFunctions';

export interface AnalyticFunctionParam {
  name: string;
  param: string;
  type: 'string' | 'integer';
  default: string | number;
}

export interface AnalyticFunction {
  name: string;
  description: string;
  function: string;
  params: AnalyticFunctionParam[];
}

export type AnalyticFunctions = (typeof ANALYTIC_FUNCTIONS)[number]['function'];

export const AnalyticFunctionToReading = {
  Open: Reading.Open,
  Close: Reading.Close,
  High: Reading.High,
  Low: Reading.Low,
  Average: Reading.Average,
  Sqrt: Reading.Average,
  Movingaverage: Reading.Average,
  Constant: Reading.Average,
  Last: Reading.Average,
  Sum: Reading.Sum,
  MovingSum: Reading.Sum,
};

export enum dataGroupingFunctions {
  Open = 'open',
  Close = 'close',
  High = 'high',
  Low = 'low',
  Average = 'average',
  Sqrt = 'average',
  Constant = 'average',
  Movingaverage = 'average',
  Last = 'average',
  Sum = 'sum',
  MovingSum = 'sum',
}
