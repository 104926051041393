import * as React from 'react';
import Highcharts from 'highcharts';

declare module 'highcharts' {
  interface Point {
    highlight?: (e: PointerEvent | MouseEvent | TouchEvent) => void;
  }

  interface Options {
    synchronize?: { enabled?: boolean };
  }
}

type SynchronizedInstancesEventHandlers = {
  synchronizeInstanceExtremes: (
    this: Highcharts.Axis,
    e: Highcharts.AxisSetExtremesEventObject
  ) => void;
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Synchronized Module
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const SynchronizedInstances = (H: typeof Highcharts): void => {
  H.Pointer.prototype.reset = function () {
    return undefined;
  };

  H.Point.prototype.highlight = function (event) {
    const _event = this.series.chart.pointer.normalize(event);
    this.onMouseOver();
    this.series.chart.tooltip.refresh(this);
    this.series.chart.xAxis[0].drawCrosshair(_event, this);
  };
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Synchronized Hook
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const synchronizedEventTypes = [
  'mousemove',
  'touchmove',
  'touchstart',
] as const;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useSynchronizedInstances = (
  containerElementRef: React.RefObject<HTMLDivElement>,
  instancesMapRef: React.MutableRefObject<Map<string, Highcharts.Chart>>
) => {
  const synchronizeInstanceExtremes = React.useCallback(function (
    this: Highcharts.Axis,
    e: Highcharts.AxisSetExtremesEventObject
  ): void {
    if (
      e.trigger !== undefined &&
      !e.trigger.startsWith('sync') &&
      this.chart.options.synchronize?.enabled
    ) {
      instancesMapRef.current.forEach((instance) => {
        if (
          instance &&
          instance !== this.chart &&
          instance.options.synchronize?.enabled
        ) {
          if (e.trigger === 'pan') {
            instance.tooltip?.hide?.(0);
            instance.xAxis[0]?.hideCrosshair?.();
            instance.series?.forEach(function (series) {
              series.setState('normal');
              series.points?.forEach((point) => {
                point.setState?.('normal');
              });
            });
          }

          instance.xAxis[0]?.setExtremes?.(e.min, e.max, undefined, false, {
            trigger: `sync:${e.trigger}`,
            triggerOp: e.trigger,
            source: this.chart,
          });
        }
      });
    }
  }, []);

  React.useLayoutEffect(() => {
    const synchronizedEventsHandler = (
      event: PointerEvent | MouseEvent | TouchEvent
    ) => {
      instancesMapRef.current.forEach((instance) => {
        if (!!instance?.options && !!instance?.pointer) {
          const _event = instance.pointer.normalize(event);
          const point = instance.series[0]?.searchPoint?.(_event, true);

          point?.highlight?.(_event);
        }
      });
    };

    synchronizedEventTypes.forEach((eventType) => {
      containerElementRef.current?.addEventListener(
        eventType,
        synchronizedEventsHandler
      );
    });

    return () => {
      synchronizedEventTypes.forEach((eventType) => {
        containerElementRef.current?.removeEventListener(
          eventType,
          synchronizedEventsHandler
        );
      });
    };
  });

  return { synchronizeInstanceExtremes };
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Make options with synchronization events
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const makeOptionsWithSynchronizedEvents = (
  options: Highcharts.Options,
  eventHandlersRef: React.MutableRefObject<SynchronizedInstancesEventHandlers>
): Highcharts.Options => {
  // TODO: This will throw if chart has multiple options.xAxis entries.
  // For now, any of the charts using synchronized instances have multiple
  // X axes. If yours does, you'll need to fix this one.

  return {
    ...options,
    xAxis: {
      ...options.xAxis,
      events: {
        ...(options.xAxis as Highcharts.XAxisOptions)?.events,
        setExtremes: function (event) {
          eventHandlersRef.current.synchronizeInstanceExtremes?.call(
            this,
            event
          );

          (options.xAxis as Highcharts.XAxisOptions)?.events?.setExtremes?.call(
            this,
            event
          );
        },
      },
    },
  };
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export {
  SynchronizedInstances,
  useSynchronizedInstances,
  makeOptionsWithSynchronizedEvents,
};

export type { SynchronizedInstancesEventHandlers };
