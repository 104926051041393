import { ExtractTrans, e } from '@Translations/extraction';

import Events from '@Map/events/Events';

export type MessageState =
	| 'none'
	| 'loading'
	| 'error'
	| 'no-select'
	| 'hidden-assets';

type Message = string | ExtractTrans;

export interface MessageEvent {
	message: ExtractTrans;
	persist: boolean;
	key: MessageState;
	variant: 'info' | 'error';
}

export const NEW_MESSAGE = 'new-message';
export const DISMISS_MESSAGE = 'dismiss-message';
export const MESSAGE_CLOSED = 'message-closed';

export default class Messages extends Events {
	static LOADING_MESSAGE = e('Loading map...', {
		context: 'Map notification',
	});
	static LOADING_TRACE_MESSAGE = e('Performing trace...', {
		context: 'Map notification',
	});
	static ERROR_MESSAGE = e('Could not load all the layers', {
		context: 'Map notification',
	});
	static CANNOT_SELECT_MESSAGE = e(
		'Too many assets to select. Will zoom instead.',
		{ context: 'Map notification' },
	);
	static HIDDEN_ASSETS_MESSAGE = e(
		'Not all assets are visible at this zoom level.',
		{ context: 'Map notification' },
	);
	static DISMISS_BUTTON = 'Dismiss message';
	private _message: Message = '';
	private _container: HTMLElement | null = null;
	private _state: MessageState = 'none';
	private _hiddenAssetsDismissed = false;

	set message(msg: Message) {
		this._message = msg;
	}

	set messageElement(element: HTMLElement | null) {
		this._container = element;
		this.attachOutboundEvents();
		this.attachInboundEvents();
	}

	/**
	 * Forward events to message component
	 */
	attachOutboundEvents(): void {
		if (!this._container) return;
		this._forwardToTarget(this._container, [NEW_MESSAGE, DISMISS_MESSAGE]);
	}

	/**
	 * Listen to events from message component
	 */

	attachInboundEvents(): void {
		if (!this._container) return;
		this._container.addEventListener(
			MESSAGE_CLOSED,
			({ detail: { key } }: { detail: { key: MessageState } }) => {
				if (key === 'hidden-assets') {
					this._hiddenAssetsDismissed = true;
				}
			},
		);
	}

	loading(): void {
		this.message = Messages.LOADING_MESSAGE;
		this._state = 'loading';
		this._updateMessageStack();
	}

	loadingTrace(): void {
		this.message = Messages.LOADING_TRACE_MESSAGE;
		this._state = 'loading';
		this._updateMessageStack();
	}

	error(): void {
		this.message = Messages.ERROR_MESSAGE;
		this._state = 'error';
		this._updateMessageStack();
	}

	noSelect(): void {
		this.message = Messages.CANNOT_SELECT_MESSAGE;
		this._state = 'no-select';
		this._updateMessageStack(true);
	}

	hiddenAssets(): void {
		if (this._state === 'error' || this._hiddenAssetsDismissed) return;
		this.message = Messages.HIDDEN_ASSETS_MESSAGE;
		this._state = 'hidden-assets';
		this._updateMessageStack();
	}

	clear(state?: MessageState): void {
		if ((state && this._state === state) || !state) {
			this.fire(DISMISS_MESSAGE, { key: this._state });
		}
	}

	refresh(): void {
		if (this._state === 'none') return;
		this._updateMessageStack();
	}

	private _updateMessageStack(autoHide?: boolean): void {
		this.fire(NEW_MESSAGE, {
			message: this._message,
			persist: !autoHide,
			key: this._state,
			variant: this._state === 'error' ? 'error' : 'info',
		});
	}
}
