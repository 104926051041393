import {
	createObservations,
	createObservationsRejected,
	createObservationsResolved,
	deleteObservations,
	deleteObservationsRejected,
	deleteObservationsResolved,
	editObservations,
	editObservationsRejected,
	getObservations,
	getObservationsRejected,
	getObservationsResolved,
} from '@Actions/observations.actions';
import {
	createObservationsApi,
	deleteObservationsApi,
	editObservationsApi,
	getObservationsApi,
} from '@Services';
import { GetObservationsResolvedResponse } from '@Types/observation.types';
import { AxiosResponse } from 'axios';
import { AnyAction } from 'redux';
import { put, retry, takeLatest } from 'redux-saga/effects';

function* getObservationsSaga(action: AnyAction) {
	try {
		const payload: AxiosResponse<GetObservationsResolvedResponse> =
			yield retry(5, 15000, getObservationsApi, action.payload);
		yield put(getObservationsResolved(payload.data));
	} catch (e) {
		yield put(getObservationsRejected());
	}
}

function* watchGetObservations() {
	yield takeLatest(getObservations, getObservationsSaga);
}

function* editObservationsSaga(action: AnyAction) {
	try {
		yield retry(5, 15000, editObservationsApi, action.payload.params);
	} catch (e) {
		yield put(editObservationsRejected());
	} finally {
		if (action.payload.history) action.payload.history.goBack();
	}
}

function* watchEditObservations() {
	yield takeLatest(editObservations, editObservationsSaga);
}

function* createObservationsSaga(action: AnyAction) {
	try {
		yield retry(5, 15000, createObservationsApi, action.payload.params);
		yield put(createObservationsResolved());
	} catch (e) {
		yield put(createObservationsRejected());
	} finally {
		if (action.payload.history) action.payload.history.goBack();
	}
}

function* watchCreateObservation() {
	yield takeLatest(createObservations, createObservationsSaga);
}

function* deleteObservationsSaga(action: AnyAction) {
	try {
		yield retry(
			5,
			15000,
			deleteObservationsApi,
			action.payload.inspectionId,
			action.payload.observationIds,
		);
		yield put(deleteObservationsResolved(action.payload.observationIds));
	} catch (e) {
		yield put(deleteObservationsRejected());
	}
}

function* watchDeleteObservation() {
	yield takeLatest(deleteObservations, deleteObservationsSaga);
}

export default [
	watchGetObservations(),
	watchEditObservations(),
	watchCreateObservation(),
	watchDeleteObservation(),
];
