import { AnimatePresence, motion } from 'framer-motion';
import { createSlots } from '../react-slots';
import * as React from 'react';
import styled from 'styled-components';
import { createContext } from '../react-context';
import { useControlledState } from '../lib-utils';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Context
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

type CollapsibleContext = {
  open: boolean;
  onTriggerClick: () => void;
};

const [_CollapsibleContextProvider, useCollapsibleContext] =
  createContext<CollapsibleContext>('Collapsible');

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Slots
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const [_Slots, _Slot, _useSlots] = createSlots('Collapsible', [
  'trigger',
  'content',
]);

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Collapsible Root Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

interface CollapsibleRootProps {
  children: React.ReactNode;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

const CollapsibleRoot = (props: CollapsibleRootProps): React.ReactElement => {
  const [open, setOpen] = useControlledState({
    defaultState: false,
    controlledState: props.open,
    onChange: props.onOpenChange,
  });

  const onTriggerClick = React.useCallback(() => {
    setOpen((_open) => {
      return !_open;
    });
  }, [setOpen]);

  return (
    <_CollapsibleContextProvider open={open} onTriggerClick={onTriggerClick}>
      <_Slots _children={props.children}>
        <_CollapsibleRoot />
      </_Slots>
    </_CollapsibleContextProvider>
  );
};

const _CollapsibleRoot = (): React.ReactElement => {
  const slots = _useSlots('_CollapsibleRoot');

  return (
    <_CollapsibleRootLayout.Root>
      <_CollapsibleRootLayout.Header>
        {slots.getSlot('trigger')}
      </_CollapsibleRootLayout.Header>
      <_CollapsibleRootLayout.Content>
        {slots.getSlot('content')}
      </_CollapsibleRootLayout.Content>
    </_CollapsibleRootLayout.Root>
  );
};

const _CollapsibleRootLayout = {
  Root: styled.div`
    display: flex;
    width: 100%;
    align-items: stretch;
    flex-direction: column;
    justify-content: space-between;
  `,
  Header: styled.div`
    display: grid;
  `,
  Content: styled.div``,
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Collapsible Trigger Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

interface CollapsibleTriggerProps {
  children: React.ReactNode;
  side?: 'left' | 'right';
}

const CollapsibleTrigger = (
  props: CollapsibleTriggerProps
): React.ReactElement => {
  const context = useCollapsibleContext('CollapsibleTrigger');

  return (
    <_Slot name="trigger">
      <_CollapsibleTrigger.Button
        type="button"
        side={props.side}
        onClick={context.onTriggerClick}>
        {props.children}
      </_CollapsibleTrigger.Button>
    </_Slot>
  );
};

const _CollapsibleTrigger = {
  Button: styled.button<{ side?: 'left' | 'right' }>`
    all: unset;
    cursor: pointer;
    justify-self: ${(props) =>
      props.side === 'right' ? 'flex-end' : 'flex-start'};
  `,
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Collapsible Content Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

interface CollapsibleContentProps {
  children: React.ReactNode;
}

const CollapsibleContent = (
  props: CollapsibleContentProps
): React.ReactElement => {
  const context = useCollapsibleContext('CollapsibleContent');

  return (
    <_Slot name="content">
      <AnimatePresence>
        {context.open ? (
          <motion.div
            initial={{ height: 0 }}
            exit={{ height: 0 }}
            animate={{ height: 'auto' }}
            transition={{ ease: 'easeInOut' }}
            onAnimationComplete={() => {
              window.dispatchEvent(new Event('resize'));
            }}>
            {props.children}
          </motion.div>
        ) : null}
      </AnimatePresence>
    </_Slot>
  );
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Module Exports
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export {
  CollapsibleRoot,
  CollapsibleContent,
  CollapsibleTrigger,
  useCollapsibleContext,
};

export type {
  CollapsibleRootProps,
  CollapsibleContentProps,
  CollapsibleTriggerProps,
};
