import * as Highcharts from 'highcharts/highstock';

import { DEFAULT_CHART_COLORS } from '../types/chart.types';

export default (
  series: Array<Highcharts.SeriesOptionsType>
): Array<Highcharts.SeriesOptionsType> =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  series.map((singleSeries, index) => ({
    ...singleSeries,
    navigatorOptions: {
      color: {
        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
        stops: [
          [
            0,
            singleSeries.color ||
              DEFAULT_CHART_COLORS[index % DEFAULT_CHART_COLORS.length],
          ],
          [1, '#ffffff'],
        ],
      },
    },
  }));
