import styled, { css } from 'styled-components';

import { EditorModes } from '@Map/types';
import { ListSubheader } from '@mui/material';
import { rem } from '../styles';

const transformPosition = (
	open: boolean,
	editorOpen: boolean,
	mode?: EditorModes,
) => {
	if (!open) return;
	if (mode === EditorModes.noEdit || editorOpen)
		return css`
			transform: translate(0%, 0);
		`;
	return css`
		transform: translate(50%, 0);
	`;
};

export const Wrapper = styled.div<{
	open: boolean;
	editorOpen: boolean;
	mode?: EditorModes;
}>`
	background: #fff;
	font-size: ${rem(14)};
	box-sizing: border-box;
	height: 100%;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 3;
	box-shadow: 0 ${rem(4)} ${rem(4)} rgba(0, 0, 0, 0.25);
	transform: translate(100%, 0);
	transition: transform 0.5s;
	outline: none;
	color: #43575d;
	display: flex;
	${({ open, editorOpen, mode }) => transformPosition(open, editorOpen, mode)}
	* {
		box-sizing: border-box;
	}
	h2 {
		font-size: ${rem(24)};
		font-weight: 600;
		margin-left: ${rem(16)};
	}
`;

export const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	button {
		margin-right: ${rem(10)};
	}
`;

export const LayersListPanel = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: ${rem(350)};
	height: 100%;
	overflow-x: hidden;
`;

// Although empty this is used to style the LayerItem when its a direct descendant
export const LayersWrapper = styled.div``;

export const BackgroundWrapper = styled.div`
	padding-bottom: ${rem(20)};
`;

export const ThemeListWrapper = styled.div`
	margin: 0 ${rem(16)} ${rem(10)};
`;

export const AddThemeLink = styled.span<{ disabled?: boolean }>`
	color: ${({ theme }) => theme.palette.primary.main};
	cursor: pointer;
	${({ disabled }) =>
		disabled &&
		css`
			color: ${({ theme }) => theme.palette.secondary.main};
			cursor: default;
		`}
`;

export const ThemeListHeader = styled(ListSubheader)`
	text-transform: uppercase;
	font-size: ${rem(12)};
	li + & {
		border-top: 1px solid #e5e5e5;
	}
`;
