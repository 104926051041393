import { stringifyDataSource, useDataSources } from './use-data-sources';
import { getApiEnvironment, getService } from '@innovyze/stylovyze';
import { useDataRetriever } from './use-data-retriever';
import * as React from 'react';
import axios from 'axios';
import cookies from 'browser-cookies';

import type { AxiosResponse } from 'axios';
import type { DataSource } from './use-data-sources';
import type { Reading, Resolution } from './series';
import type { Status } from '../_chart';
import { apiConfig } from '../../../apis/config.api';

type SensorDataSource = DataSource<{
  sensorId: string;
  resolution: Resolution;
}>;

type SensorDataApiResponse = {
  unit?: string | null;
  total?: number;
  earliestTimestamp?: number;
  latestTimestamp?: number;
  data: [
    timestamp: number,
    close: number,
    open: number,
    low: number,
    high: number,
    average: number,
    sum: number,
  ][];
};

type SensorData = {
  data: SensorDataMap;
  unit?: string;
  earliestTimestamp?: Timestamp;
  latestTimestamp?: Timestamp;
};

type SensorDataEntry = {
  status?: Status | undefined;
  error?: Error | undefined;
  data?: SensorDataMap | undefined;
  unit?: string | undefined;
  earliestTimestamp?: number | undefined;
  latestTimestamp?: number | undefined;
};

type SensorDataMap = Map<Timestamp, Map<Reading, Measure>>;

type Timestamp = number;
type Measure = number | null;

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Utils
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const readingAtIndex: Reading[] = [
  'Open',
  'High',
  'Low',
  'Close',
  'Average',
  'Sum',
];

const makeSensorDataFromResponse = (
  response: SensorDataApiResponse
): SensorData => {
  const sensorData: SensorData = {
    data: new Map(),
    unit: response.unit ?? undefined,
    earliestTimestamp: response.earliestTimestamp ?? undefined,
    latestTimestamp: response.latestTimestamp ?? undefined,
  };

  for (const [timestamp, ...measures] of response.data) {
    const _measures: Map<Reading, Measure> = new Map();
    measures.forEach((measure, index) => {
      _measures.set(readingAtIndex[index], measure);
    });

    sensorData.data.set(timestamp, _measures);
  }

  return sensorData;
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Sensor Data API
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const api = axios.create({
  baseURL: getService('sensorDataV2', apiConfig, getApiEnvironment()),
  timeout: 10000,
});

api.interceptors.request.use(
  (config) => {
    config.headers.Authorization = 'Bearer ' + cookies.get('auth0.user.token');
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

const getSensorDataDisplay = (
  sensorId: string,
  resolution: string,
  params?: {
    start?: number;
    end?: number;
    limit?: number;
    limitFrom?: 'start' | 'end';
  }
): Promise<AxiosResponse<SensorDataApiResponse>> => {
  return api.get(`/v1/data/display/${sensorId}/${resolution}`, {
    params: { ...params },
  });
};

const getSensorDataSummary = (
  sensorId: string
): Promise<AxiosResponse<SensorDataApiResponse>> => {
  return api.get(`/v1/data/summary/${sensorId}`);
};

type Data = [number, number | null][];

const searchSensorData = (criteria: {
  dataSources: { sensorId: string; resolution: string }[];
  endDate?: string | number;
  startDate?: string | number;
  limit?: number;
  limitFrom?: 'start' | 'end';
}) => {
  return api.post<{
    sensors: Record<string, { data: Data; summary: Data }>;
    earliestTimestamp?: number;
    latestTimestamp?: number;
  }>('v2/data/search', criteria);
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Sensor Data Display Hook
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useSensorDataDisplay = <S extends SensorDataSource>(series: S[]) => {
  const { dataSources, getDataSource } = useDataSources<SensorDataSource>(
    series,
    ['sensorId', 'resolution']
  );

  const data = useDataRetriever<SensorData>(dataSources);

  const minMaxRef = React.useRef<{
    min: number | undefined;
    max: number | undefined;
  }>({ min: undefined, max: undefined });

  const minmax = React.useMemo(() => {
    let min: number | undefined = undefined;
    let max: number | undefined = undefined;

    if (data.globalStatus.status === 'resolved') {
      const dataEntries = Array.from(data.dataEntries.values());

      for (const sensorData of dataEntries) {
        if (sensorData) {
          if (
            min === undefined ||
            (sensorData.earliestTimestamp !== undefined &&
              sensorData.earliestTimestamp < min)
          ) {
            min = sensorData.earliestTimestamp;
          }

          if (
            max === undefined ||
            (sensorData.latestTimestamp !== undefined &&
              sensorData.latestTimestamp > max)
          ) {
            max = sensorData.latestTimestamp;
          }
        }
      }
    } else {
      return minMaxRef.current;
    }

    minMaxRef.current.min = min;
    minMaxRef.current.max = max;

    return { min, max };
  }, [minMaxRef, data.dataEntries, data.globalStatus.status]);

  const resolutions = React.useMemo(() => {
    let resolution = '';

    for (const dataSource of dataSources) {
      if (dataSource) {
        resolution += dataSource.resolution;
      }
    }

    return resolution;
  }, [dataSources]);

  const getEntry = React.useCallback(
    (source: S): SensorDataEntry => {
      const entryKey = stringifyDataSource(getDataSource(source));
      const dataEntry = data.dataEntries.get(entryKey);
      const statusEntry = data.statusEntries.get(entryKey);

      return { ...dataEntry, ...statusEntry };
    },
    [data]
  );

  const retrieve = React.useCallback(
    (params?: {
      start?: number;
      end?: number;
      limit?: number;
      limitFrom?: 'start' | 'end';
    }) => {
      for (const dataSource of dataSources) {
        data.retrieve(dataSource, async () => {
          const response = await getSensorDataDisplay(
            dataSource.sensorId,
            dataSource.resolution.toLowerCase(),
            params
          );

          return makeSensorDataFromResponse(response.data);
        });
      }
    },
    [dataSources]
  );

  return {
    ...data.globalStatus,
    ...minmax,
    resolutions,
    retrieve,
    getEntry,
  };
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Sensor Data Summary Hook
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useSensorDataSummary = <S extends SensorDataSource>(series: S[]) => {
  const { dataSources, getDataSource } = useDataSources<SensorDataSource>(
    series,
    ['sensorId']
  );

  const data = useDataRetriever<SensorData>(dataSources);

  const minMaxRef = React.useRef<{
    min: number | undefined;
    max: number | undefined;
  }>({ min: undefined, max: undefined });

  const minmax = React.useMemo(() => {
    let min: number | undefined = undefined;
    let max: number | undefined = undefined;

    if (data.globalStatus.status === 'resolved') {
      const dataEntries = Array.from(data.dataEntries.values());

      for (const sensorData of dataEntries) {
        if (sensorData) {
          if (
            min === undefined ||
            (sensorData.earliestTimestamp !== undefined &&
              sensorData.earliestTimestamp < min)
          ) {
            min = sensorData.earliestTimestamp;
          }

          if (
            max === undefined ||
            (sensorData.latestTimestamp !== undefined &&
              sensorData.latestTimestamp > max)
          ) {
            max = sensorData.latestTimestamp;
          }
        }
      }
    } else {
      return minMaxRef.current;
    }

    minMaxRef.current.min = min;
    minMaxRef.current.max = max;

    return { min, max };
  }, [minMaxRef, data.dataEntries, data.globalStatus.status]);

  const getEntry = React.useCallback(
    (source: S): SensorDataEntry => {
      const entryKey = stringifyDataSource(getDataSource(source));
      const dataEntry = data.dataEntries.get(entryKey);
      const statusEntry = data.statusEntries.get(entryKey);

      return { ...dataEntry, ...statusEntry };
    },
    [data]
  );

  const retrieve = React.useCallback(() => {
    for (const source of dataSources) {
      data.retrieve(source, async () => {
        const response = await getSensorDataSummary(source.sensorId);

        return makeSensorDataFromResponse(response.data);
      });
    }
  }, [dataSources]);

  return { ...data.globalStatus, ...minmax, retrieve, getEntry };
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Sensor Data Errors
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

class SensorDataError extends Error {
  public dataSource: DataSource | undefined;

  constructor(dataSource?: DataSource, message?: string) {
    super(message ?? 'Something went wrong when fetching data.');
    this.name = 'SensorDataError';
    this.dataSource = dataSource;
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Data Source Not Found
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

class SensorDataNotFoundError extends SensorDataError {
  constructor(dataSource: DataSource) {
    super(dataSource, 'Retrieving data resulted in HTTP 404 status.');
    this.name = 'SensorDataNotFoundError';
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Data Source Not Found
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

type TimeRange = { start?: number; end?: number };

class SensorDataTimeRangeError extends SensorDataError {
  public timeRange: TimeRange | undefined;

  constructor(dataSource: DataSource, timeRange?: TimeRange) {
    super(dataSource, 'No data found for selected time range.');
    this.name = 'SensorDataNotFoundError';
    this.timeRange = timeRange;
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export {
  api,
  useSensorDataDisplay,
  useSensorDataSummary,
  SensorDataError,
  SensorDataNotFoundError,
  SensorDataTimeRangeError,
};

export type {
  SensorData,
  SensorDataMap,
  SensorDataSource,
  SensorDataEntry,
  SensorDataApiResponse,
  Measure,
};
