import React, { forwardRef, ReactElement, useState } from 'react';

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { AnimatePresence, motion } from 'framer-motion';

import { GaugeChartDisplayOptions } from '../../../types/gaugeChart.types';

import * as Styled from './GaugeChart.styles';
import AtomicGaugeChart from '../../atoms/AtomicGaugeChart';
import {
  GlobalPassthroughs,
  HiddenElements,
  RangeSelection,
} from '../../../types';
import { useGlobalization } from '../../../i18n/useGlobalization';
import { GaugeSeries } from '../../../core/types/gauge.types';
import { ChartProps } from '../../../core/types/chart.types';
import {
  generateGaugeOptions,
  generateGaugeStockOptions,
} from './GaugeChart.utils';
import { isFeatureEnabled } from '@innovyze/stylovyze';
import StackableChart, {
  StackableChartRef,
} from '../../../core/components/StackableChart';
import Highcharts from 'highcharts';

export interface GaugeChartProps extends ChartProps<GaugeSeries> {
  displayOptions?: GaugeChartDisplayOptions;
  hiddenElements?: HiddenElements;
  onHiddenElementsChange?: (hiddenElements: HiddenElements) => void;
  rangeSelection?: RangeSelection;
  dataRangeSelection?: RangeSelection;
  ref?: React.Ref<StackableChartRef>;
}

const GaugeChart = (
  props: GaugeChartProps,
  chartControllerRef: React.Ref<unknown> | undefined
): ReactElement => {
  const { t } = useGlobalization();
  const isBoostModuleEnabled = isFeatureEnabled(
    'info-360-analytics-boost-module'
  );

  const { hiddenElements, onHiddenElementsChange, ...passThroughProps } = props;

  const [isStockChartShown, setIsStockChartShown] = useState<boolean>(() => {
    return hiddenElements ? !hiddenElements.includes('snapshot') : false;
  });

  const gaugeHighchartOptions = generateGaugeOptions(
    props.series[0],
    props.data
  );

  const stockOptions = generateGaugeStockOptions(
    props.series[0],
    props.data,
    !!isBoostModuleEnabled,
    t,
    props.displayOptions
  );

  const { isReadOnly } = passThroughProps;

  return (
    <Styled.GaugeChartWrapper>
      <Styled.AtomicChartWrapper>
        <AtomicGaugeChart
          {...passThroughProps}
          ref={chartControllerRef}
          options={gaugeHighchartOptions}
        />
      </Styled.AtomicChartWrapper>
      <Styled.StockChartWrapper noPadding={!!isReadOnly && !isStockChartShown}>
        {!isReadOnly && (
          <Styled.StockChartToggleButton
            onClick={() => {
              const nextIsVisible = !isStockChartShown;
              let nextHiddenElements = hiddenElements
                ? [...hiddenElements]
                : [];

              if (!nextIsVisible) {
                nextHiddenElements.push('snapshot');
              } else {
                nextHiddenElements = nextHiddenElements.filter(
                  (element) => element !== 'snapshot'
                );
              }

              onHiddenElementsChange?.(nextHiddenElements);
              setIsStockChartShown(nextIsVisible);
            }}>
            <div className="lctb-icon">
              {isStockChartShown ? (
                <KeyboardArrowDownIcon />
              ) : (
                <KeyboardArrowUpIcon />
              )}
            </div>
            <div className="lctb-label">
              {isStockChartShown ? t('Collapse') : t('Last 24 Hours')}
            </div>
          </Styled.StockChartToggleButton>
        )}
        <AnimatePresence>
          {isStockChartShown && (
            <motion.div
              initial={{ height: 0 }}
              exit={{ height: 0 }}
              animate={{ height: 'auto' }}
              transition={{ ease: 'easeInOut' }}
              onAnimationComplete={() => {
                window.dispatchEvent(new Event('resize'));
              }}>
              <StackableChart
                constructorType="chart"
                data={props.data}
                highcharts={Highcharts}
                options={stockOptions}
                ref={props.ref}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </Styled.StockChartWrapper>
    </Styled.GaugeChartWrapper>
  );
};

export default forwardRef(GaugeChart);
