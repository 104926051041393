import { AxiosResponse } from 'axios';
import { measureData } from '../apis';
import { MeasureDataResponse } from '../types/measureData.types';

export const getMeasureDatum = (
  tenant: string,
  sensorId: string,
  resolution: string
): Promise<AxiosResponse<MeasureDataResponse>> =>
  measureData.get<MeasureDataResponse>(
    `${tenant}/${sensorId}/${resolution}/data.json`
  );
