import { useEffect, useState } from 'react';

import type { AsyncData } from '../../types/async.types';
import { t } from 'i18next';
import { getGoal } from '../../../services/goals.service';
import { Goal } from '../../types/goals.types';

const useGoal = (goalId: string): AsyncData<Goal> => {
  /* This will store the exposed goal */
  const [asyncGoal, setAsyncGoal] = useState<AsyncData<Goal>>({
    status: 'idle',
    data: null,
  });

  /**
   * Here we take whatever is in the store and set it as the exposed value
   * or we fetch the goal if it's no there.
   */
  useEffect(() => {
    const fetch = async () => {
      setAsyncGoal({
        data: null,
        status: 'loading',
        message: t('Loading goal'),
      });
      try {
        if (goalId) {
          const response = await getGoal(goalId);
          setAsyncGoal({
            data: response.data,
            status: 'resolved',
          });
        } else {
          setAsyncGoal({
            data: null,
            status: 'idle',
          });
        }
      } catch (error) {
        setAsyncGoal({
          data: null,
          status: 'rejected',
          message: t('Failed to retrieve goal'),
        });
      }
    };

    fetch();
  }, [goalId]);

  return asyncGoal;
};

export default useGoal;
