import { AnyAction, createReducer } from '@reduxjs/toolkit';
import { RiskSetting, RiskSettingState } from '@Types/asset.types';
import {
	getRiskSetting,
	getRiskSettingRejected,
	getRiskSettingResolved,
} from '@Actions/assetRisk.actions';

export const initRiskSetting: RiskSetting = {
	RiskMethod: 'multiplied',
	LOFWeighting: 50,
	COFWeighting: 50,
	Grades: {
		Low: -1,
		Medium: -1,
		High: -1,
		Extreme: -1,
		Max: -1,
	},
	LastRun: '',
};

export const initState: RiskSettingState = {
	...initRiskSetting,
	rejected: false,
};

interface RiskSetttingReducer {
	[x: string]: (
		state: RiskSettingState,
		action: AnyAction,
	) => RiskSettingState | undefined;
}

const reducer: RiskSetttingReducer = {
	[getRiskSetting.toString()]: () => ({ ...initState }),
	[getRiskSettingRejected.toString()]: () => ({
		...initState,
		rejected: true,
	}),
	[getRiskSettingResolved.toString()]: (state, action) => ({
		...action.payload,
		rejected: false,
	}),
};

export const riskSettingReducer = createReducer(initState, reducer);

export default createReducer(initState, reducer);
