import { AdaptorConverters, AdaptorData, ConfigLayer } from '../panel/types';

import CancellableFetch from '../services/CancellableFetch';
import { DataServiceRequestError } from './DataServiceRequestError';
import { extractErrorContext } from '@Map/utils';
import logger from '@Map/logger/Logger';

export default class ConfigService {
	private _url: string;
	private _headers: [string, string][] | undefined;
	private _request: CancellableFetch | undefined;
	private _adaptor: AdaptorConverters | undefined;

	constructor(url: string, token?: string, adaptor?: AdaptorConverters) {
		this._url = url;
		this._headers = token
			? [['Authorization', `Bearer ${token}`]]
			: undefined;
		this._adaptor = adaptor;
	}

	async fetchData(): Promise<Partial<ConfigLayer>> {
		this.cancelPendingDataRequests();
		this._request = new CancellableFetch({
			url: this._url,
			headers: this._headers,
		});
		if (this._adaptor) {
			try {
				const response = await this._request.getPage<AdaptorData>();
				return this._adaptor(response);
			} catch (e) {
				this._errorLogging(e as DataServiceRequestError);
				return {};
			}
		}
		try {
			return await this._request.getPage<ConfigLayer>();
		} catch (e) {
			this._errorLogging(e as DataServiceRequestError);
			return {};
		}
	}

	cancelPendingDataRequests(): void {
		if (this._request) {
			this._request.cancel();
			this._request = undefined;
		}
	}

	private _errorLogging(error: DataServiceRequestError) {
		logger.error('Config could not be loaded', {
			messageId: 'map_exception_loadData',
			error: `The request ${this._url} did not return any data`,
			...extractErrorContext(error),
		});
	}
}
