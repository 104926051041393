import { ImageItem, SensorGroupConfig } from '@innovyze/stylovyze';
import { FACILITIES_TYPES_LIST, LiftStationType } from '@innovyze/shared-utils';

export const getSensorGroupName = (
	sensorGroupList: SensorGroupConfig[],
	sensorGroupId: string,
): string => {
	const sensorGroup = sensorGroupList.find(
		item => item._id === sensorGroupId,
	);
	return sensorGroup?.name ?? sensorGroupId;
};

export const getImageKeyPath = (
	imageList: ImageItem[],
	imageId: string,
): string | undefined => {
	const image = imageList.find(item => item._id === imageId);
	return image?.imageKey;
};

export const getWorkspaceType = (
	facilityType?: string,
): 'Pump Station' | 'Facility' => {
	if (facilityType) {
		const typedFacilityType = facilityType as LiftStationType;
		if (
			FACILITIES_TYPES_LIST.includes(typedFacilityType) &&
			typedFacilityType !== 'Pump Station'
		)
			return 'Facility';
	}
	return 'Pump Station';
};
