import {
  generateCustomAnalyticOptions,
  generateCustomAnalyticStackOptions,
} from './customAnalyticChart.utils';
import {
  CustomAnalyticDisplayOptions,
  RangeSelection,
  HiddenSeries,
} from '../../../types';
import React, { forwardRef, ReactElement, Ref } from 'react';
import { CustomAnalyticSeries } from '../../../core/types/analytic.types';
import { ChartProps } from '../../../core/types/chart.types';
import useRangeSelection from '../../../core/hooks/useRangeSelection';
import useHiddenSeries from '../../../core/hooks/useHiddenSeries';
import StackableChart, {
  StackableChartRef,
} from '../../../core/components/StackableChart';
import Highcharts from 'highcharts/highstock';
import { isFeatureEnabled } from '@innovyze/stylovyze';
import useHighchartsOptions from '../../../core/hooks/useHighchartsOptions';
import useStackOptions from '../../../core/hooks/useStackOptions';

export interface CustomAnalyticChartProps
  extends ChartProps<CustomAnalyticSeries> {
  displayOptions: CustomAnalyticDisplayOptions;
  hiddenSeries?: HiddenSeries;
  dataRangeSelection?: RangeSelection;
  rangeSelection?: RangeSelection;
  onRangeSelectionChange?: (rangeSelection: RangeSelection) => void;
  onHiddenSeriesChange?: (hiddenSeries: HiddenSeries) => void;
}

const CustomAnalyticChart = (
  props: CustomAnalyticChartProps,
  ref?: Ref<StackableChartRef>
): ReactElement => {
  const isBoostModuleEnabled = isFeatureEnabled(
    'info-360-analytics-boost-module'
  );

  const { rangeSelectionOptions } = useRangeSelection(
    props.rangeSelection,
    props.dataRangeSelection,
    props.onRangeSelectionChange
  );

  const { hiddenSeries, hiddenSeriesOptions } = useHiddenSeries(
    props.hiddenSeries,
    props.onHiddenSeriesChange
  );

  const options = useHighchartsOptions(() => {
    return [
      generateCustomAnalyticOptions(
        props.data,
        !!isBoostModuleEnabled,
        props.series,
        hiddenSeries,
        props.displayOptions
      ),
      hiddenSeriesOptions,
      rangeSelectionOptions,
    ];
  }, [
    hiddenSeries,
    hiddenSeriesOptions,
    isBoostModuleEnabled,
    props.data,
    props.displayOptions,
    props.series,
    rangeSelectionOptions,
  ]);

  const stackOptions = useStackOptions(() => {
    return {
      enabled: !(props.displayOptions?.overlay ?? false),
      options: generateCustomAnalyticStackOptions(
        props.data,
        props.series,
        props.displayOptions
      ),
    };
  }, [props.data, props.series, props.displayOptions]);

  return (
    <StackableChart
      constructorType="stockChart"
      data={props.data}
      highcharts={Highcharts}
      options={options}
      ref={ref}
      stack={stackOptions}
    />
  );
};

export default forwardRef(CustomAnalyticChart);
