import {
	ActionButtons,
	CancelButton,
	DialogActions,
	DialogContent,
	EditIcon,
	ResetButton,
	RestoreIcon,
	SaveButton,
	StyleEditorButton,
} from './PanelHeader.styles';
import { Dialog, DialogTitle, IconButton, Tooltip } from '@mui/material';

import { EditorState } from '@Map/types';
import React from 'react';
import { useEditor } from '@Components/Editor/Editor';
import { useGlobalization } from '@Translations/useGlobalization';

interface PanelHeaderProps {
	showMultiThemes?: boolean;
	saveDisabled?: boolean;
	restoreHidden?: boolean;
}

const PanelHeader = ({
	showMultiThemes,
	saveDisabled,
	restoreHidden,
}: PanelHeaderProps): JSX.Element => {
	const { t } = useGlobalization();
	const {
		state,
		open,
		resetDialogOpen,
		startEditing,
		cancelEditing,
		saveAndFinishEditing,
		resetToDefaults,
		confirmReset,
		cancelReset,
	} = useEditor();
	return (
		<>
			{state === EditorState.edit && !open && (
				<StyleEditorButton
					startIcon={<EditIcon />}
					onClick={startEditing}>
					{showMultiThemes
						? t('Theme Editor', {
								context:
									'Button to enter into editing of map layer styles mode',
						  })
						: t('Style Editor', {
								context:
									'Button to enter into editing of map layer styles mode',
						  })}
				</StyleEditorButton>
			)}
			{open && (
				<ActionButtons>
					{!restoreHidden && (
						<IconButton
							title={t('Reset layer properties to default', {
								context: 'Style editor reset button',
							})}
							onClick={resetToDefaults}
							data-cy="reset-button">
							<RestoreIcon />
						</IconButton>
					)}
					<CancelButton
						variant="contained"
						color="secondary"
						onClick={cancelEditing}>
						{t('Cancel', { context: 'Style editor cancel button' })}
					</CancelButton>
					<Tooltip
						title={
							saveDisabled
								? t('Theme name is required to save', {
										context:
											'Tooltip message for disabled style editor save button',
								  })
								: ''
						}>
						<SaveButton
							variant="contained"
							color="primary"
							onClick={
								!saveDisabled ? saveAndFinishEditing : undefined
							}
							$disabled={!!saveDisabled}>
							{t('Save', { context: 'Style editor save button' })}
						</SaveButton>
					</Tooltip>
				</ActionButtons>
			)}
			<Dialog
				open={resetDialogOpen}
				PaperProps={{
					square: true,
				}}
				data-cy="reset-dialog">
				<DialogTitle>
					{t('Reset all layer properties', {
						context: 'Style editor reset dialog heading',
					})}
				</DialogTitle>
				<DialogContent>
					{t(
						'Do you really want to reset all layer properties for the map? This action cannot be undone.',
						{ context: 'Style editor reset dialog content' },
					)}
				</DialogContent>
				<DialogActions>
					<ResetButton
						data-cy="confirm-reset"
						variant="contained"
						onClick={confirmReset}>
						{t('Reset', {
							context: 'Style editor reset dialog confirm button',
						})}
					</ResetButton>
					<CancelButton
						data-cy="cancel-reset"
						variant="contained"
						color="secondary"
						onClick={cancelReset}>
						{t('Cancel', {
							context: 'Style editor reset dialog cancel button',
						})}
					</CancelButton>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default PanelHeader;
