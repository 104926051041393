/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Readings } from '../types/sensor.types';
import { AnalyticFunctions } from '../types/analyticFunction.types';
import { SensorMeasureData } from '../core/types/data.types';
import { readingIndex } from '../core/utils/data.utils';
import { RawMeasureDataMap, RawMeasureDatum } from '../types/measureData.types';

export const ANALYTIC_FUNCTIONS = [
  {
    name: 'Average',
    function: 'Average',
    description:
      'Mean value of available data; https://help.innovyze.com/display/info360/Average',
    params: [],
  },
  {
    name: 'Close',
    function: 'Close',
    description:
      'Outputs the final value within a given sampling interval. https://help.innovyze.com/display/info360/Close',
    params: [],
  },
  {
    name: 'High',
    function: 'High',
    description:
      'Outputs the highest value within a given sampling interval; https://help.innovyze.com/display/info360/High',
    params: [],
  },
  {
    name: 'Last',
    function: 'Last',
    description:
      'Outputs the most recent valid data point. https://help.innovyze.com/display/info360/Last',
    params: [],
  },
  {
    name: 'Low',
    function: 'Low',
    description:
      'Outputs the lowest value within a given sampling interval; https://help.innovyze.com/display/info360/Low',
    params: [],
  },
  {
    name: 'Sum',
    function: 'Sum',
    description:
      'Outputs the sum of all values within a given sampling interval; https://help.innovyze.com/display/info360/Low',
    params: [],
  },
  {
    name: 'Constant',
    function: 'Constant',
    description: 'Creates a horizontal reference line at the given value.',
    params: [
      {
        name: 'Value',
        param: 'value',
        type: 'integer',
        default: 10,
      },
    ],
  },
  {
    name: 'Movingaverage',
    function: 'Movingaverage',
    description:
      'Simple Moving Average Sums the values of last period N then divides it by N. https://help.innovyze.com/display/info360/MA+-+Moving+Average',
    params: [
      {
        name: 'Number Of Periods',
        param: 'number_of_periods',
        type: 'integer',
        default: 10,
      },
    ],
  },
  {
    name: 'Moving Sum',
    function: 'MovingSum',
    description:
      'Sums the values of last period N; https://help.innovyze.com/display/info360/MA+-+Moving+Average',
    params: [
      {
        name: 'Number Of Periods',
        param: 'number_of_periods',
        type: 'integer',
        default: 10,
      },
    ],
  },
  {
    name: 'Open',
    function: 'Open',
    description:
      'Outputs the first value within a given sampling interval. https://help.innovyze.com/display/info360/Open',
    params: [],
  },
  {
    name: 'Sqrt',
    function: 'Sqrt',
    description:
      'The SQRT function simply returns the square root of whatever input data is given; https://help.innovyze.com/display/info360/SQRT+-+Square+Root',
    params: [],
  },
] as const;

export const analyticFunctionMap: Record<
  AnalyticFunctions,
  (
    timestamps: number[],
    measurements: SensorMeasureData,
    params?: Record<string, unknown>
  ) => [number, number | null][]
> = {
  Open: (timestamps, measurements) =>
    timestamps.map((timestamp) => [
      timestamp,
      measurements[timestamp]?.[readingIndex[Readings.Open]] ?? null,
    ]),
  Close: (timestamps, measurements) =>
    timestamps.map((timestamp) => [
      timestamp,
      measurements[timestamp]?.[readingIndex[Readings.Close]] ?? null,
    ]),
  High: (timestamps, measurements) =>
    timestamps.map((timestamp) => [
      timestamp,
      measurements[timestamp]?.[readingIndex[Readings.High]] ?? null,
    ]),
  Low: (timestamps, measurements) =>
    timestamps.map((timestamp) => [
      timestamp,
      measurements[timestamp]?.[readingIndex[Readings.Low]] ?? null,
    ]),
  Average: (timestamps, measurements) =>
    timestamps.map((timestamp) => [
      timestamp,
      measurements[timestamp]?.[readingIndex[Readings.Average]] ?? null,
    ]),
  Sum: (timestamps, measurements) =>
    timestamps.map((timestamp) => [
      timestamp,
      measurements[timestamp]?.[readingIndex[Readings.Sum]] ?? null,
    ]),
  Sqrt: (timestamps, measurements) =>
    timestamps.map((timestamp) => [
      timestamp,
      Math.sqrt(
        measurements[timestamp]?.[
          readingIndex[Readings.Average]
        ] as unknown as number
      ) || null,
    ]),
  //@ts-ignore
  Constant: (timestamps, _, params: { value: string }) =>
    timestamps.map((timestamp) => [timestamp, parseInt(params.value)]),
  //@ts-ignore
  Movingaverage: (
    timestamps,
    measurements,
    params: { number_of_periods: number }
  ) => {
    const period = params.number_of_periods;
    const processedData: [number, number][] = [];
    let sum = 0;
    // eslint-disable-next-line for-direction
    for (let i = timestamps.length - 1; i >= 0; i--) {
      if (i > period) {
        sum = 0;
        for (let j = 0; j < period; j++)
          sum +=
            measurements[timestamps[i - j]]?.[readingIndex[Readings.Average]] ??
            0;
        processedData.unshift([timestamps[i], sum / period]);
      } else {
        sum = 0;
        for (let j = i; j >= 0; j--)
          sum +=
            measurements[timestamps[i - j]]?.[readingIndex[Readings.Average]] ??
            0;
        processedData.unshift([timestamps[i], sum / (i + 1)]);
      }
    }
    return processedData;
  },
  //@ts-ignore
  MovingSum: (
    timestamps,
    measurements,
    params: { number_of_periods: number }
  ) => {
    const period = params.number_of_periods;
    const processedData: [number, number][] = [];
    let sum = 0;
    // eslint-disable-next-line for-direction
    for (let i = timestamps.length - 1; i >= 0; i--) {
      if (i > period) {
        sum = 0;
        for (let j = 0; j < period; j++)
          sum +=
            measurements[timestamps[i - j]]?.[readingIndex[Readings.Average]] ??
            0;
        processedData.unshift([timestamps[i], sum]);
      } else {
        sum = 0;
        for (let j = i; j >= 0; j--)
          sum +=
            measurements[timestamps[i - j]]?.[readingIndex[Readings.Average]] ??
            0;
        processedData.unshift([timestamps[i], sum]);
      }
    }
    return processedData;
  },
  Last: (timestamps, measurements) => {
    if (timestamps.length === 0) return [];
    const firstValidIndex = timestamps.findIndex(
      (timestamp) => measurements[timestamp]?.[readingIndex[Readings.Average]]
    );

    let last =
      measurements[timestamps[firstValidIndex]]?.[
        readingIndex[Readings.Average]
      ] ?? null;

    return timestamps.map((timestamp, index) => {
      if (
        index > firstValidIndex &&
        (measurements[timestamp]?.[readingIndex[Readings.Average]] !== 0 ||
          typeof measurements[timestamp]?.[readingIndex[Readings.Average]] ===
            'undefined')
      ) {
        last = measurements[timestamp]?.[readingIndex[Readings.Average]];
        return [
          timestamp,
          measurements[timestamp]?.[readingIndex[Readings.Average]],
        ];
      } else {
        return [timestamp, last];
      }
    });
  },
};

export const analyticFunctionMapOld: Record<
  AnalyticFunctions,
  (x: RawMeasureDatum[], params?: Record<string, unknown>) => [number, number][]
> = {
  Open: (data) =>
    data.map((datum) => [datum[0], datum[RawMeasureDataMap[Readings.Open]]]),
  Close: (data) =>
    data.map((datum) => [datum[0], datum[RawMeasureDataMap[Readings.Close]]]),
  High: (data) =>
    data.map((datum) => [datum[0], datum[RawMeasureDataMap[Readings.High]]]),
  Low: (data) =>
    data.map((datum) => [datum[0], datum[RawMeasureDataMap[Readings.Low]]]),
  Average: (data) =>
    data.map((datum) => [datum[0], datum[RawMeasureDataMap[Readings.Average]]]),
  Sum: (data) =>
    data.map((datum) => [datum[0], datum[RawMeasureDataMap[Readings.Sum]]]),
  Sqrt: (data) =>
    data.map((datum) => [
      datum[0],
      Math.sqrt(datum[RawMeasureDataMap[Readings.Average]]),
    ]),
  //@ts-ignore
  Constant: (data, params: { value: string }) =>
    data.map((datum) => [datum[0], parseInt(params.value)]),
  //@ts-ignore
  Movingaverage: (data, params: { number_of_periods: number }) => {
    const period = params.number_of_periods;
    const processedData: [number, number][] = [];
    let sum = 0;
    // eslint-disable-next-line for-direction
    for (let i = data.length - 1; i >= 0; i--) {
      if (i > period) {
        sum = 0;
        for (let j = 0; j < period; j++)
          sum += data[i - j][RawMeasureDataMap[Readings.Average]];
        processedData.unshift([data[i][0], sum / period]);
      } else {
        sum = 0;
        for (let j = i; j >= 0; j--)
          sum += data[i - j][RawMeasureDataMap[Readings.Average]];
        processedData.unshift([data[i][0], sum / (i + 1)]);
      }
    }
    return processedData;
  },
  //@ts-ignore
  MovingSum: (data, params: { number_of_periods: number }) => {
    const period = params.number_of_periods;
    const processedData: [number, number][] = [];
    let sum = 0;
    // eslint-disable-next-line for-direction
    for (let i = data.length - 1; i >= 0; i--) {
      if (i > period) {
        sum = 0;
        for (let j = 0; j < period; j++)
          sum += data[i - j][RawMeasureDataMap[Readings.Average]];
        processedData.unshift([data[i][0], sum]);
      } else {
        sum = 0;
        for (let j = i; j >= 0; j--)
          sum += data[i - j][RawMeasureDataMap[Readings.Average]];
        processedData.unshift([data[i][0], sum]);
      }
    }
    return processedData;
  },
  Last: (data) => {
    if (data.length === 0) return [];
    const firstValidIndex = data.findIndex(
      (datum) => datum[RawMeasureDataMap[Readings.Average]]
    );

    let last = data[firstValidIndex][RawMeasureDataMap[Readings.Average]];
    return data.map((datum, index) => {
      if (
        index > firstValidIndex &&
        (datum[RawMeasureDataMap[Readings.Average]] !== 0 ||
          typeof datum[RawMeasureDataMap[Readings.Average]] === 'undefined')
      ) {
        last = datum[RawMeasureDataMap[Readings.Average]];
        return [datum[0], datum[RawMeasureDataMap[Readings.Average]]];
      } else {
        return [datum[0], last];
      }
    });
  },
};
