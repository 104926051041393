/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	InfoCard,
	StylovyzeTable,
	StylovyzeTableHeaderProps,
	useSettings,
	useCompanyDateTime,
} from '@innovyze/stylovyze';
import { useGlobalization } from '@Translations';
import { formatDateTimeWithFormatDate } from '@Utils';
import { getCurrency } from '@Utils/currency';
import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const RehabSummary = ({
	rehabTrees,
	rehabResults,
}: {
	rehabTrees: any[];
	rehabResults: any;
}) => {
	const { t } = useGlobalization();
	const { formatDate } = useCompanyDateTime();

	const { companySettings } = useSettings();

	const defaultHeaders: StylovyzeTableHeaderProps[] = [
		{
			cell: t('Decision Tree Name'),
			dataCy: 'col-Decision_Tree_Name',
			key: 'Decision_Tree_Name',
			width: 'auto',
			padding: '0 10px',
		},
		{
			cell: t('Final Rehab Action'),
			dataCy: 'col-Final_Rehab_Action',
			key: 'Final_Rehab_Action',
			width: 'auto',
			padding: '0 10px',
		},
		{
			cell: t('Rehab Cost'),
			dataCy: 'col-Rehab_Cost',
			key: 'Rehab_Cost',
			width: 'auto',
			padding: '0 10px',
		},
		{
			cell: t('Draft Rehab Action'),
			dataCy: 'col-Draft_Rehab_Action',
			key: 'Draft_Rehab_Action',
			width: 'auto',
			padding: '0 10px',
		},
		{
			cell: t('Last Run'),
			dataCy: 'col-Last_Run',
			key: 'Last_Run',
			width: 'auto',
			padding: '0 10px',
		},
		{
			cell: t('Run By'),
			dataCy: 'col-Run_By',
			key: 'Run_By',
			width: 'auto',
			padding: '0 10px',
		},
	];

	return (
		<InfoCard
			fullHeight
			title={t('Rehab Summary')}
			applyBodyPadding={false}>
			<StylovyzeTable
				initialized={
					rehabTrees.length > 0 &&
					rehabResults &&
					Object.keys(rehabResults).length > 0
				}
				className="ScrollTable"
				dataCy="defect-table"
				headers={defaultHeaders}
				rows={
					rehabTrees
						.filter(
							tree =>
								rehabResults[tree._id] &&
								rehabResults[tree._id].rows.length > 0,
						)
						.map((tree, index) => ({
							key: index,
							cells: [
								tree.name,
								rehabResults[tree._id].rows[0]
									.FINAL_RRHAB_ACTION,
								getCurrency(
									+rehabResults[tree._id].rows[0].TOTAL_COST,
									companySettings,
								),
								rehabResults[tree._id].rows[0]
									.DRAFT_RRHAB_ACTION,
								formatDateTimeWithFormatDate(
									tree.lastRun ?? '',
									formatDate,
								),
								tree.runBy,
							],
						})) || []
				}
			/>
		</InfoCard>
	);
};

export default RehabSummary;
