import {
  getHiddenSeries,
  stringifyHiddenSeries,
} from '../../utils/hiddenSeries.utils';
import { useLayoutEffect, useRef, useState } from 'react';
import useHighchartsOptions from '../useHighchartsOptions';

import type { HiddenSeries } from '../../../types/chartState';

const emptyHiddenSeries: HiddenSeries = [];

const useHiddenSeries = (
  controlledHiddenSeries?: HiddenSeries,
  onHiddenSeriesChange?: (hiddenSeries: HiddenSeries) => void
) => {
  /**
   * We store here the hidden series so that we can restore the
   * state when user changes stack / overlay mode.
   * The guys (or girls) using the lib can also manage the
   * state on their own and that's why we have the
   * controlledHiddenSeries, but if they don't we still want to
   * be able to restore the state.
   */
  const [hiddenSeries, setHiddenSeries] = useState<HiddenSeries>(
    () => controlledHiddenSeries ?? emptyHiddenSeries
  );

  /**
   * If the hidden series is being controlled, anytime it changes
   * we override our local state. (They should be keeping in
   * sync both internal and external values using the
   * onHiddenSeriesChange callback if they don't want to loose
   * state)
   */
  useLayoutEffect(() => {
    setHiddenSeries(controlledHiddenSeries ?? emptyHiddenSeries);
  }, [stringifyHiddenSeries(controlledHiddenSeries)]);

  /**
   * Usually people don't memo their callbacks, so...
   * we store the onHiddenSeriesChange callback on a ref,
   * so that we don't regenerate options when the
   * function changes it's referential equality...
   * otherwise, the chart will have REALLY BA~D!! performance
   * as there'll be an infinite loop of renders.
   */
  const onChangeCallbackRef = useRef(onHiddenSeriesChange);

  useLayoutEffect(() => {
    onChangeCallbackRef.current = onHiddenSeriesChange;
  }, [onHiddenSeriesChange]);

  /**
   * Here we set the options related to the hidden series
   * callback. Ideally we'd also set the visibility of each
   * series, but that requires making this hook dependent
   * on the options being generated already, which goes against
   * the general flow. So, instead... we expose the hiddenSeries
   * state and then we can use that on our options generator to
   * set the visibility of each series.
   *
   * We also use the callback through the ref so that we can
   * generate these options just once.
   */
  const hiddenSeriesOptions = useHighchartsOptions(() => {
    return generateHiddenSeriesOptions((hiddenSeries: HiddenSeries) => {
      setHiddenSeries(hiddenSeries);
      onChangeCallbackRef.current?.(hiddenSeries);
    });
  }, []);

  return { hiddenSeries, hiddenSeriesOptions };
};

const generateHiddenSeriesOptions = (
  onChange?: (hiddenSeries: HiddenSeries) => void
): Highcharts.Options => {
  return {
    plotOptions: {
      series: {
        events: {
          show: function (this: Highcharts.Series) {
            const hiddenSeries = getHiddenSeries(this.chart.series);
            onChange?.(hiddenSeries);
          },
          hide: function (this: Highcharts.Series) {
            const hiddenSeries = getHiddenSeries(this.chart.series);
            onChange?.(hiddenSeries);
          },
        },
      },
    },
  };
};

export default useHiddenSeries;
