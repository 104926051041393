import { Resolution } from './resolution.types';
import type { AnalyticFunctions } from '../../types/analyticFunction.types';
import type { ReadingType } from './reading.types';
import { ChartSeries } from './chart.types';
import { ConstantDataSource, SensorDataSource } from './data.types';

export interface sourceNode {
  channelId: string;
  name: string;
  tenantId: string;
  interval: keyof typeof CustomAnalyticInterval;
  _id: string;
  seriesType?: keyof typeof CustomAnalyticSeriesType;
  createTimeSeries?: boolean;
  value?: number;
}

export interface sourceLink {
  source: string;
  target: string;
}

export interface CustomAnalytic {
  _id: string;
  name: string;
  startDate: string;
  endDate: string;
  configGraph: {
    nodes: sourceNode[];
    links: sourceLink[];
  };
}

export interface CustomAnalyticNode {
  id: string;
  name: string;
  channelId: string;
  interval: Resolution;
  func?: ReadingType | AnalyticFunctions;
  params?: Record<string, unknown>;
}

export interface OverriddenCustomAnalyticNode {
  id: string;
  interval?: Resolution;
  func?: ReadingType | AnalyticFunctions;
  params?: Record<string, unknown>;
}

export enum CustomAnalyticInterval {
  'raw' = 'Raw',
  '15 minute' = '15 Minutes',
  '30 minute' = '30 Minutes',
  '1 hour' = 'Hourly',
  '1 day' = 'Daily',
  '1 week' = 'Weekly',
}

export enum CustomAnalyticSeriesType {
  'average' = 'Average',
  'open' = 'Open',
  'high' = 'High',
  'low' = 'Low',
  'close' = 'Close',
}

export interface CustomAnalyticSeries
  extends ChartSeries<SensorDataSource | ConstantDataSource> {
  node: CustomAnalyticNode;
}
