import { CompanySettings } from '@innovyze/stylovyze';

import { DateTime } from 'luxon';

// have converted this function from moment to luxon which
// reduces module sizes quite drastically. However, luxon
// is still quite a big overhead for just this usage here.
// 2do there should be a luxon implementation of datetime functions
// in shared_ui from Donnie soon. This method could sensibly be moved
// there or replaced with equivalent from there.
export const getTimeDifferenceText = (
	date: Date,
	companySettings: CompanySettings,
): string => {
	// Default locale set to current hard code default in stylovyze
	const locale = companySettings?.language ?? 'America/New_York';
	return (
		DateTime.fromJSDate(date).toRelativeCalendar({ locale: locale }) ?? ''
	);
};
