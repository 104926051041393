import { LayerDebugInfo } from '@Map/layers/LayerManager';
import React, { createContext, ReactNode, useContext } from 'react';
import { ReactMapConfig } from './types';

export type MapGlobalProps = ReactMapConfig & {
	onLayersDebug?: (data: LayerDebugInfo[]) => void;
};

const MapGlobalContext = createContext<MapGlobalProps>({});

export const useMapGlobals = (): MapGlobalProps => useContext(MapGlobalContext);

interface MapGlobalProviderProps {
	children: ReactNode;
	value: MapGlobalProps;
}

/**
 * Provides context for the Maps. The component `<MapGlobalProvider />` will be built into the `<ApplicationWrapper />` component in Stylovyze, so doesn't need to be imported by individual applications.
 */
export const MapGlobalProvider = ({
	children,
	value,
}: MapGlobalProviderProps): JSX.Element => {
	return (
		<MapGlobalContext.Provider value={value}>
			{children}
		</MapGlobalContext.Provider>
	);
};

export default MapGlobalProvider;
