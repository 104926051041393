export const hexColorToRGB = (hexColor: string, opacity = 0.8) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor);
  const rgbMap = result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;

  return !rgbMap
    ? hexColor
    : `rgba(${rgbMap.r},${rgbMap.g},${rgbMap.b},${opacity})`;
};
