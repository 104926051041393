import React from 'react';
import { Wrapper, Image, Title, Description } from './BackgroundItem.styles';

export interface BackgroundItemProps {
	title: string;
	description: string;
	background: string;
	onClick: () => void;
	tabIndex?: number;
	selected: boolean;
}

export const BackgroundItem = ({
	title,
	description,
	background,
	onClick,
	tabIndex,
	selected,
}: BackgroundItemProps): JSX.Element => {
	return (
		<Wrapper onClick={onClick} tabIndex={tabIndex} selected={selected}>
			<Image background={background} />
			<Title>{title}</Title>
			<Description>{description}</Description>
		</Wrapper>
	);
};

export default React.memo(BackgroundItem);
