import { BackgroundTypes, MapModes, ReactMap } from '@innovyze/inno-map';
import React from 'react';
import {
	STYLOVYZE_FLAGS,
	useAuth0,
	useIsFeatureEnabled,
	useSettings,
} from '@innovyze/stylovyze';
import { useSelectMapInsertAsset } from '@Selectors';
import { MapStatusType } from '@Types';
import { useReactMapConfig } from '@innovyze/stylovyze/lib/hooks/map/useReactMapConfig';

interface Props {
	logLevel?: MapStatusType;
}

const MapInsertAsset: React.FC<Props> = ({
	logLevel = MapStatusType.WARN,
}: Props) => {
	const { token } = useAuth0();
	const { companySettings } = useSettings();

	const inputs = useSelectMapInsertAsset(token);

	const showArcGISBasemap = !!useIsFeatureEnabled(
		STYLOVYZE_FLAGS.ARCGIS_BASEMAPS,
	);
	const basemapOrigin = showArcGISBasemap
		? companySettings.mapSettings?.basemapOrigin ?? 'mapbox'
		: 'mapbox';
	const background =
		basemapOrigin == 'arcgis'
			? BackgroundTypes.OSMStandard
			: BackgroundTypes.Streets;

	const reactMapConfig = useReactMapConfig();

	return (
		<ReactMap
			config={reactMapConfig}
			mode={MapModes.Navigation}
			dataServices={inputs.sources}
			layerConfig={inputs.layers}
			autoResize={false}
			position={inputs.location?.position ?? undefined}
			selectedAssets={inputs.selected}
			showMinZoomWarning={false}
			logLevel={logLevel ?? MapStatusType.WARN}
			background={background}
		/>
	);
};

export default MapInsertAsset;
