import Events from './events/Events';

export const PRINT_PREVIEW = 'print-preview';

export default class PrintControl extends Events {
	private _map: mapboxgl.Map;
	private _container: HTMLElement | null = null;

	constructor(map: mapboxgl.Map) {
		super();
		this._map = map;
		this.on(PRINT_PREVIEW, this.generatePreview);
	}

	set previewElement(element: HTMLDivElement | null) {
		this._container = element;
	}

	generatePreview = () => {
		if (!this._container) return;
		const canvas = this._map.getCanvas();
		const imageData = canvas.toDataURL('image/png');
		const image = document.createElement('img');
		image.src = imageData;
		image.crossOrigin = 'Anonymous';
		this._container.replaceChildren(image);
	};
}
