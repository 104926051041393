import { Grid, Typography } from '@mui/material';
import { useGlobalization } from '@Translations';
import React from 'react';
import { WorkHistoryIcon } from './WorkHistoryIcon';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const NoRatings = () => {
	const { t } = useGlobalization();
	return (
		<Grid container>
			<Grid
				container
				item
				xs={12}
				justifyContent="center"
				style={{ paddingTop: '40px' }}>
				<WorkHistoryIcon />
				{/* <img alt="summary" src={require('./workHistory.svg')} /> */}
			</Grid>
			<Grid container item xs={12} justifyContent="center">
				<Typography variant="h6">{t('No ratings to show')}</Typography>
			</Grid>
			<Grid container item xs={12} justifyContent="center">
				<Typography variant="caption">
					{t('You must have inspections to view ratings.')}
				</Typography>
			</Grid>
			<Grid
				container
				item
				xs={12}
				justifyContent="center"
				style={{
					paddingTop: '20px',
					paddingBottom: '20px',
				}}></Grid>
		</Grid>
	);
};
