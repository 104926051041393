import { StringMap, TOptions } from 'i18next';

import { ExtractTrans } from './extraction';
import { ns } from './settings.json';
import { useTranslation } from 'react-i18next';

/**
 * Translate text with react-i18next useTranslate with
 * predefined namespace for the mini-app
 * @param i18nNs overide namespace
 * @returns string
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useGlobalization = (i18nNs = ns) => {
	const hook = useTranslation<string, undefined>(i18nNs, {
		useSuspense: true,
	});
	const t = (
		input: string | ExtractTrans,
		options?: TOptions<StringMap>,
	): string => {
		if (typeof input === 'string') return hook.t(input, options);
		return hook.t(input.text, { ...input.options, ...options });
	};
	return { ...hook, t };
};
