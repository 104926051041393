import type { RequestBodyType, Source } from './types';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Source Utils
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export function stringifySource(
  source: Source,
  options?: { type?: RequestBodyType; ignoreSourceKey?: boolean }
): string {
  const { key, sensorId, resolution, type, periods } = source;
  if (options?.ignoreSourceKey !== true && key) return key;

  const _type = ['measured', 'forecast', undefined].includes(type)
    ? undefined
    : type;

  return options?.type !== 'snapshot' || _type === 'trendline'
    ? [sensorId, resolution, _type, periods].filter(Boolean).join()
    : [sensorId, _type, periods].filter(Boolean).join();
}
