import { PopupWrapper, StyledCard } from './Popup.styles';
import React, { useMemo, useState } from 'react';

import { CardContent } from '@mui/material';
import { Point } from 'mapbox-gl';
import ReactResizeDetector from 'react-resize-detector';
import { usePopupPositioning } from './usePopupPositioning';

interface Size {
	width: number;
	height: number;
}

export enum Anchor {
	top,
	bottom,
	left,
	right,
}

export interface PopupProps<T> {
	features?: T;
	containerSize?: Size;
	point?: Point;
	height?: number | 'auto';
	width?: number;
	children: (features: T) => JSX.Element | null;
	anchor?: Anchor;
	dataCy?: string;
	contentDataCy?: string;
	open?: boolean;
	removeContentPadding?: boolean;
	cancelPopupUpdate?: () => void;
}

export const Popup = <T extends unknown[]>({
	features,
	point = new Point(-1, -1),
	height = 'auto',
	width = 400,
	containerSize,
	children,
	anchor,
	dataCy = 'popup-wrapper',
	contentDataCy = 'popup-content',
	open = true,
	removeContentPadding = false,
	cancelPopupUpdate,
}: PopupProps<T>): JSX.Element | null => {
	const [size, setSize] = useState<Size>({
		width: 0,
		height: 0,
	});
	const popupStyles = usePopupPositioning({
		size,
		containerSize,
		point,
		anchor,
		open,
		removeContentPadding,
	});

	const content = useMemo(() => {
		if (!features || features.length === 0) {
			return null;
		}

		const content = children(features);

		if (!content) {
			return null;
		}

		return (
			<CardContent data-cy={contentDataCy}>
				{children(features)}
			</CardContent>
		);
	}, [children, contentDataCy, features]);

	return (
		<PopupWrapper
			$height={height}
			$width={width}
			style={{ ...popupStyles }}
			onMouseEnter={cancelPopupUpdate}
			data-cy={dataCy}>
			<ReactResizeDetector
				handleWidth
				handleHeight
				onResize={(resizeWidth, resizeHeight): void =>
					setSize({
						width: resizeWidth ?? 0,
						height: resizeHeight ?? 0,
					})
				}
			/>
			{content && !removeContentPadding ? (
				<StyledCard raised square>
					{content}
				</StyledCard>
			) : (
				content
			)}
		</PopupWrapper>
	);
};
