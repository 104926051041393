import { extractValue } from '@Components/AssetDetails/DetailsOverview/ValueMapping';
import { TFunc } from '@Translations';
import { AssetAttrbuteField, FullInspection, SegmentInfo } from '@Types';

export function a11yProps(index: number): {
	id: string;
	'aria-controls': string;
} {
	return {
		id: `vertical-tab-${index}`,
		'aria-controls': `vertical-tabpanel-${index}`,
	};
}

export const fixupGrid = (
	fields: { name: string; alias: string }[],
	details: {
		[key: string]: string | number;
	},
): void => {
	// Fix up any missing asset fields so it displays '-'
	fields.forEach(field => {
		if (
			details[field.name] == null ||
			details[field.name] == undefined ||
			details[field.name].toString().trim().length == 0
		)
			details[field.name] = '-';
	});
};

export const getObjectValues = (
	t: TFunc,
	// eslint-disable-next-line @typescript-eslint/ban-types
	inspection: object | undefined,
	getDomainValues: (t: TFunc, fieldName: string) => { [key: string]: string },
): {
	[key: string]: string;
} => {
	const values: { [key: string]: string } = {};
	if (inspection) {
		for (const [key, value] of Object.entries(inspection)) {
			if (
				value != null &&
				value != undefined &&
				typeof value === 'string' &&
				value.trim().length != 0 &&
				value.trim() != '-'
			) {
				const domainValues = getDomainValues(t, key.trim());
				if (domainValues && value.trim() in domainValues) {
					values[key] = `${value.trim()} - ${
						domainValues[value.trim()]
					}`;
					continue;
				}
			}
			values[key] = value;
		}
	}
	return values;
};

export const getJustValues = (
	inspection: FullInspection | undefined,
	forView: boolean,
): {
	[key: string]: string;
} => {
	const values: { [key: string]: string } = {};
	if (inspection) {
		for (const [key, value] of Object.entries(inspection)) {
			if (typeof value !== 'object')
				values[key] = forView ? value : value === '-' ? '' : value;
		}
	}
	return values;
};

export const getSegmentValues = (
	t: TFunc,
	inspection: SegmentInfo | undefined,
	getDomainValues: (t: TFunc, fieldName: string) => { [key: string]: string },
): SegmentInfo => {
	const values: SegmentInfo = {};
	if (inspection) {
		for (const [key, value] of Object.entries(inspection)) {
			if (
				value != null &&
				value != undefined &&
				typeof value === 'string' &&
				value.trim().length != 0 &&
				value.trim() != '-'
			) {
				const domainValues = getDomainValues(t, key.trim());
				if (domainValues && value.trim() in domainValues) {
					values[key] = `${value.trim()} - ${
						domainValues[value.trim()]
					}`;
					continue;
				}
			}
			values[key] = value;
		}
	}
	return values;
};

export const getAssetValues = (
	t: TFunc,
	details: AssetAttrbuteField[] | undefined,
	getDomainValues: (t: TFunc, fieldName: string) => { [key: string]: string },
): {
	[key: string]: string;
} => {
	const values: { [key: string]: string } = {};
	if (details && details.length > 0) {
		for (const detail of details) {
			if (
				detail.FieldValue != null &&
				detail.FieldValue != undefined &&
				detail.FieldValue.trim().length != 0
			) {
				const domainValues = getDomainValues(
					t,
					detail.FieldName.trim(),
				);
				if (domainValues && detail.FieldValue.trim() in domainValues) {
					values[detail.FieldName] = `${detail.FieldValue.trim()} - ${
						domainValues[detail.FieldValue.trim()]
					}`;
					continue;
				}
			}
			values[detail.FieldName] = detail.FieldValue;
		}
	}
	return values;
};

export const getTaskValues = (
	inspection: FullInspection | undefined,
	forView: boolean,
): {
	[key: string]: string;
} => {
	const values: { [key: string]: string } = {};
	if (inspection) {
		for (const [key, value] of Object.entries(inspection)) {
			const field = extractValue(value) ?? '';
			values[key] = forView ? field : field === '-' ? '' : field;
		}
	}
	return values;
};
