import { AnimationProps, IconSet } from '../services/types';
import { CompositeLayerProps, Filter } from './types';

import { AssetFocusLabelLayer } from './creators/AssetFocusLabelLayer';
import { AssetFocusMarkerLayer } from './creators/AssetFocusMarkerLayer';
import ClusterCreatorLayer from './creators/ClusterCreatorLayer';
import Events from '../events/Events';
import FlowCreatorLayer from './creators/FlowCreatorLayer';
import HighlightBackgroundLayer from './creators/HighlightBackgroundLayer';
import HighlightForegroundLayer from './creators/HighlightForegroundLayer';
import { LAYER_UPDATED } from './constants';
import LineSymboCreatorlLayer from './creators/LineSymbolCreatorLayer';
import MainLayer from './creators/MainLayer';
import SelectedLayer from './creators/SelectedLayer';
import logger from '@Map/logger/Logger';

type LayerCreators =
	| MainLayer
	| SelectedLayer
	| HighlightBackgroundLayer
	| HighlightForegroundLayer
	| ClusterCreatorLayer
	| LineSymboCreatorlLayer
	| FlowCreatorLayer;

export default class CompositeLayer extends Events {
	private _map: mapboxgl.Map;
	private _layer!: MainLayer;
	private _selectedLayer: SelectedLayer | undefined = undefined;
	private _highlightBackgroundLayer!: HighlightBackgroundLayer;
	private _highlightForegroundLayer!: HighlightForegroundLayer;
	private _clusterLayer: ClusterCreatorLayer | undefined = undefined;
	private _lineSymbolLayer: LineSymboCreatorlLayer | undefined = undefined;
	private _flowLayer: FlowCreatorLayer | undefined = undefined;
	private _composite!: LayerCreators[];
	private _layerInfo: CompositeLayerProps;
	private _iconSet: IconSet = {};
	private _selectedItems: string[] = [];
	private _highlightItems: string[] = [];
	private _filter: Filter | undefined = undefined;
	private _assetFilter: boolean | string[] = false;
	private _invalid = false;

	constructor(layerInfo: CompositeLayerProps, map: mapboxgl.Map) {
		super();
		this._map = map;
		this._layerInfo = { ...layerInfo };

		if (!this._isValidLayer(layerInfo)) {
			this._invalid = true;
			return;
		}

		this._createAllLayers(layerInfo);

		this.draw();
	}

	get invalid(): boolean {
		return this._invalid;
	}

	get layerInfo(): CompositeLayerProps {
		return this._layerInfo;
	}

	set layerInfo(layerInfo: CompositeLayerProps) {
		let hasChanged = false;
		Object.entries(layerInfo).forEach(([key, value]) => {
			if (
				JSON.stringify(
					this.layerInfo[key as keyof CompositeLayerProps],
				) !== JSON.stringify(value)
			) {
				hasChanged = true;
			}
		});
		if (layerInfo.assetFilter === undefined) {
			layerInfo.assetFilter = this._assetFilter;
		}
		this._layerInfo = { ...layerInfo };
		if (hasChanged) {
			this.delete();
			this._createAllLayers(layerInfo);
			this.fire(LAYER_UPDATED);
			this.draw();
		}
	}

	get id(): CompositeLayerProps['id'] {
		return this._layer.id;
	}

	get idPath(): CompositeLayerProps['idPath'] {
		return this._layerInfo.idPath;
	}

	get selectedId(): CompositeLayerProps['id'] {
		return this._selectedLayer?.id || '';
	}

	get clusterId(): CompositeLayerProps['id'] | undefined {
		return this._clusterLayer?.id;
	}

	get displayName(): CompositeLayerProps['displayName'] {
		return this.layerInfo.displayName;
	}

	get icon(): CompositeLayerProps['icon'] {
		return this.layerInfo.icon;
	}

	set loaded(loaded: CompositeLayerProps['loaded']) {
		if (this.layerInfo.loaded !== loaded) {
			this.fire(LAYER_UPDATED);
		}
		this.layerInfo.loaded = loaded;
		this.draw();
	}

	get loaded(): CompositeLayerProps['loaded'] {
		return this.layerInfo.loaded;
	}

	set errored(errored: CompositeLayerProps['errored']) {
		if (this.layerInfo.errored !== errored) {
			this.fire(LAYER_UPDATED);
		}
		this.layerInfo.errored = errored;
	}

	get errored(): CompositeLayerProps['errored'] {
		return this.layerInfo.errored;
	}

	get serviceId(): CompositeLayerProps['serviceId'] {
		return this.layerInfo.serviceId;
	}

	get source(): string {
		if (
			this.layerInfo.source &&
			typeof this.layerInfo.source === 'string'
		) {
			return this.layerInfo.source;
		}
		return this.serviceId;
	}

	get sourceLayer(): CompositeLayerProps['sourceLayer'] {
		return this.layerInfo.sourceLayer;
	}

	get color(): CompositeLayerProps['color'] {
		return this.layerInfo.color;
	}

	get selectedColor(): CompositeLayerProps['selectedColor'] {
		return this.layerInfo.selectedColor;
	}

	get type(): CompositeLayerProps['type'] {
		return this.layerInfo.type;
	}

	get active(): CompositeLayerProps['visible'] {
		return this._layerInfo.visible ?? true;
	}

	set active(active: CompositeLayerProps['visible']) {
		this._layerInfo.visible = active;
	}

	get selectedItems(): string[] {
		return this._selectedItems;
	}

	set selectedItems(items: string[]) {
		this._selectedItems = items;
		this._layer.selectedItems = items;
		if (this._selectedLayer) this._selectedLayer.selectedItems = items;
		if (this._flowLayer) this._flowLayer.selectedItems = items;
		if (this._lineSymbolLayer) this._lineSymbolLayer.selectedItems = items;
	}

	get highlightedItems(): string[] {
		return this._highlightItems;
	}

	set highlightedItems(items: string[]) {
		this._highlightItems = items;
		this._highlightBackgroundLayer.highlightedItems = items;
		this._highlightForegroundLayer.highlightedItems = items;
	}

	set iconSet(iconSet: IconSet) {
		this._iconSet = iconSet;
		this._composite.forEach(layer => {
			layer.iconSet = this._iconSet;
		});
	}

	get properties(): CompositeLayerProps['properties'] {
		return this.layerInfo.properties;
	}

	get hasClustering(): CompositeLayerProps['cluster'] {
		return this.layerInfo.cluster;
	}

	set background(background: CompositeLayerProps['background']) {
		this.layerInfo = { ...this.layerInfo, background };
	}

	get showFlow(): CompositeLayerProps['showFlow'] {
		return this.layerInfo.showFlow;
	}

	get flowMinZoom(): CompositeLayerProps['flowMinZoom'] {
		return this.layerInfo.flowMinZoom;
	}

	get onMap(): boolean {
		return this._composite.every(layer => layer.onMap);
	}

	get selectable(): boolean {
		return this.layerInfo.selectable ?? true;
	}

	get minZoom(): CompositeLayerProps['minZoom'] {
		return this.layerInfo.minZoom;
	}

	get maxZoom(): CompositeLayerProps['maxZoom'] {
		return this.layerInfo.maxZoom;
	}

	clearSelectedItems(): void {
		this.selectedItems = [];
	}

	clearHighlightedItems(): void {
		this.highlightedItems = [];
	}

	toggleLayerVisibility(newState = !this.active): void {
		if (newState === this.active) return;
		this.active = newState;
		this.refreshLayerVisibility();
	}

	setFilter(filter: boolean | string[]): void {
		this._assetFilter = filter;
		this.layerInfo = { ...this.layerInfo, assetFilter: filter };
	}

	draw(): void {
		if (!this.loaded || this.errored) return;
		this._composite.forEach(layer => {
			layer.draw();
		});
		this.refreshLayerVisibility();
	}

	refreshLayerVisibility(): void {
		if (this.active) {
			this.show();
		} else {
			this.hide();
		}
	}

	hide(): void {
		this._composite.forEach(layer => layer.hide());
	}

	show(): void {
		this._composite.forEach(layer => layer.show());
	}

	delete(): void {
		this._composite.forEach(layer => layer.delete());
	}

	setTimelineFilter(
		currentTime: number,
		animatedProps: AnimationProps,
	): void {
		if (!animatedProps?.inView || !animatedProps?.outView) return;
		const onset = ['<=', ['get', animatedProps.inView], currentTime];
		const offset = ['>=', ['get', animatedProps.outView], currentTime];
		this._filter = ['all', onset, offset];
		this._layer.filter = this._filter;
	}

	private _isValidLayer(layerInfo: CompositeLayerProps): boolean {
		if (layerInfo.tileLayerName && !layerInfo.sourceLayer) return false;
		return true;
	}

	private _createAllLayers(layerInfo: CompositeLayerProps): void {
		this._layer = new MainLayer(layerInfo, this._map);
		this._layer.selectedItems = this.selectedItems;
		this._createSelectedLayer(layerInfo);
		this._highlightBackgroundLayer = new HighlightBackgroundLayer(
			layerInfo,
			this._map,
		);
		this._highlightBackgroundLayer.highlightedItems = this.highlightedItems;
		this._highlightForegroundLayer = new HighlightForegroundLayer(
			layerInfo,
			this._map,
		);
		this._highlightForegroundLayer.highlightedItems = this.highlightedItems;
		this._createClusterLayers(layerInfo);
		this._createFlowLayer(layerInfo);
		this._createLineSymbolLayer(layerInfo);

		this._composite = [
			this._layer,
			...(this._selectedLayer ? [this._selectedLayer] : []),
			this._highlightBackgroundLayer,
			this._highlightForegroundLayer,
			...(this.hasClustering ? [this._clusterLayer] : []),
			...(this.showFlow ? [this._flowLayer] : []),
			...(this.layerInfo.type === 'line' && this.layerInfo.icon
				? [this._lineSymbolLayer]
				: []),
			new AssetFocusLabelLayer(layerInfo, this._map),
			new AssetFocusMarkerLayer(layerInfo, this._map),
		] as LayerCreators[];
		logger.debug(
			`layers composite created for ${this.id}`,
			this._composite,
		);
	}

	private _createSelectedLayer(layerInfo: CompositeLayerProps): void {
		if (layerInfo.type === 'symbol') {
			this._selectedLayer = new SelectedLayer(layerInfo, this._map);
			this._selectedLayer.selectedItems = this.selectedItems;
		} else if (layerInfo.type === 'line' && layerInfo.pattern) {
			// need to create selected layer if a pattern is set to the line
			// as a line pattern cannot be state driven
			this._selectedLayer = new SelectedLayer(
				{
					...layerInfo,
					selected: true,
					pattern: layerInfo.selectedPattern ?? layerInfo.pattern,
				},
				this._map,
			);
			this._selectedLayer.selectedItems = this.selectedItems;
		} else {
			this._selectedLayer = undefined;
		}
	}

	private _createClusterLayers(layerInfo: CompositeLayerProps): void {
		if (this.hasClustering) {
			this._clusterLayer = new ClusterCreatorLayer(layerInfo, this._map);
		} else {
			this._clusterLayer = undefined;
		}
	}

	private _createLineSymbolLayer(layerInfo: CompositeLayerProps): void {
		if (this.layerInfo.type === 'line' && this.layerInfo.icon) {
			this._lineSymbolLayer = new LineSymboCreatorlLayer(
				layerInfo,
				this._map,
			);
		} else {
			this._lineSymbolLayer = undefined;
		}
	}

	private _createFlowLayer(layerInfo: CompositeLayerProps): void {
		if (this.showFlow) {
			this._flowLayer = new FlowCreatorLayer(layerInfo, this._map);
			this._flowLayer.selectedItems = this.selectedItems;
		} else {
			this._flowLayer = undefined;
		}
	}
}
