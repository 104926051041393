import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export interface ObservationSearchBarProps {
	label: string;
	codes: string[];
	handleSearchSubmit: (val: string) => void;
	width?: number;
}

const ObservationSearchBar = ({
	width = 250,
	...props
}: ObservationSearchBarProps): JSX.Element => {
	const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (event.key === 'Enter') {
			const target = event?.target as HTMLInputElement;
			props.handleSearchSubmit(target?.value);
		}
	};

	return (
		<div style={{ width: width, paddingLeft: 14 }}>
			<Autocomplete
				id="codeSearch"
				freeSolo
				options={props.codes}
				renderInput={params => (
					<TextField
						{...params}
						label={props.label}
						variant="outlined"
					/>
				)}
				onKeyDown={handleKeyDown}
			/>
		</div>
	);
};

export default ObservationSearchBar;
