import * as React from 'react';

import type { Chart } from 'highcharts';
import type { MutableRefObject, RefObject } from 'react';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Root Container Element Ref
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const RootContainerElementRef = React.createContext<
  RefObject<HTMLDivElement>
>({
  current: null,
});

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Root Time Range
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

type DateRangeMillis = [number | null, number | null];

export const RootTimeRange = React.createContext<{
  syncedDateTimeRange: DateRangeMillis;
  syncDateTimeRangePicker: (dateRange: DateRangeMillis) => void;
  chartWidth: number | null;
}>({
  syncedDateTimeRange: [null, null],
  syncDateTimeRangePicker: () => undefined,
  chartWidth: null,
});

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Root Instance Record Ref
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export type InstanceRecord = Record<string, Chart | undefined>;

export const RootInstanceRecordRef = React.createContext<
  MutableRefObject<InstanceRecord>
>({
  current: {},
});

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Instance Ref
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const InstanceRef = React.createContext<
  MutableRefObject<Chart | undefined>
>({ current: undefined });
