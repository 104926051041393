import {
  CompanySettings,
  DataGrid,
  GridColDef,
  SensorType,
  useSelectSensors,
  useSettings,
} from '@innovyze/stylovyze';
import {
  ComparisonTableDisplayOptions,
  ComparisonTableGroup,
  ComparisonTableSeriesConnected,
  Resolutions,
} from '../../../types';
import { nanoid } from 'nanoid';
import { AsyncData } from '../../../core/types/async.types';
import { Tooltip } from '@mui/material';
import { DateTime } from 'luxon';
import {
  StyledCellContainer,
  StyledDataGrid,
} from './insight-comparison-table.styles';
import { useEffect, useMemo, useRef, useState } from 'react';

const formatDateTime = (date: number, companySettings: CompanySettings) => {
  if (!date) return;
  const timeZone = companySettings.timeZoneIANA || 'Etc/UTC';
  const _dateFormat = companySettings.dateFormat ?? 'MM/DD/YYYY';
  const _hourCycle12 = companySettings.hourCycle12 ?? true;
  const dateFormat = _dateFormat.replace('DD', 'dd').replace('YYYY', 'yyyy');
  const timeFormat = _hourCycle12 ? 'hh:mm a' : 'HH:mm';
  return DateTime.fromMillis(date)
    .setZone(timeZone)
    .toFormat(`${dateFormat} - ${timeFormat}`);
};

type AsyncSensorData = AsyncData<{
  measurement: number;
  timestamp: number;
  unit?: string;
}>;

const getSeriesData = (
  type: SensorType,
  groupId: string,
  series: ComparisonTableSeriesConnected[],
  sensorData: Record<string, AsyncSensorData>
) => {
  const seriesData = [];
  series.forEach((s) => {
    if (s.type === type && s.sensorGroupId === groupId) {
      const data = sensorData[s.sensorId]?.data;
      if (data)
        seriesData.push({
          ...data,
          series: s,
        });
    }
  });
  return seriesData;
};

interface InsightComparisonTableProps {
  sensorGroups: ComparisonTableGroup[];
  series: ComparisonTableSeriesConnected[];
  resolution: Resolutions;
  sensorData: Record<string, AsyncSensorData>;
  edgeStatus: string;
  displayOptions?: Partial<ComparisonTableDisplayOptions>;
  onCellClick?: (
    row: ComparisonTableSeriesConnected & { resolution: Resolutions }
  ) => void;
}

export const InsightComparisonTable = (props: InsightComparisonTableProps) => {
  const { companySettings } = useSettings();
  const { initialized: sensorsInitialized } = useSelectSensors();
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerHeight, setcontainerHeight] = useState<number>(0);

  useEffect(() => {
    if (!containerRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      setcontainerHeight(containerRef.current?.clientHeight - 56 ?? 0);
    });
    resizeObserver.observe(containerRef.current);
    return () => resizeObserver.disconnect(); // clean up
  }, []);

  const isLoading =
    props.edgeStatus === 'idle' ||
    props.edgeStatus === 'loading' ||
    !sensorsInitialized;

  const columns = useMemo(() => {
    const _columns: GridColDef[] = [
      {
        field: 'sensorType',
        headerName: '',
        align: 'left',
        flex: 1,
        sortable: false,
        cellClassName: 'type--cell',
      },
    ];

    props.sensorGroups.forEach((group) => {
      _columns.push({
        field: group.id,
        headerName: group.alias,
        align: 'left',
        flex: 1,
        sortable: false,
        renderCell: (params) => {
          const seriesData = params?.value;
          const rowsCount = params.api.getRowsCount();
          const cellHeight =
            containerHeight && rowsCount
              ? containerHeight / rowsCount - 20
              : 52;
          return (
            <StyledCellContainer $cellHeight={cellHeight}>
              {seriesData?.map((s) => (
                <Tooltip
                  followCursor
                  placement="top"
                  arrow
                  title={`${
                    s.series.alias ?? s.series.sensorId
                  }: ${formatDateTime(s.timestamp, companySettings)}`}>
                  <span
                    onClick={() =>
                      props.onCellClick?.({
                        resolution: props.resolution,
                        ...s.series,
                      })
                    }>
                    {s.measurement ?? ''}
                  </span>
                </Tooltip>
              ))}
            </StyledCellContainer>
          );
        },
      });
    });

    if (props.displayOptions?.showUnits)
      _columns.push({
        field: 'uom',
        headerName: 'UoM',
        align: 'left',
        flex: 1,
        sortable: false,
        cellClassName: 'unit--cell',
      });
    return _columns;
  }, [
    props.sensorGroups,
    props.displayOptions,
    props.resolution,
    containerHeight,
  ]);

  const sensorTypes: SensorType[] = useMemo(() => {
    if (isLoading) return [];
    const _sensorTypes = [];
    props.series.forEach((series) => {
      if (!_sensorTypes.includes(series.type)) _sensorTypes.push(series.type);
    });
    return _sensorTypes;
  }, [props.series, isLoading]);

  const rows = useMemo(() => {
    if (isLoading) return [];
    if (!props.sensorData) return [];
    const _rows = [];
    sensorTypes.forEach((typeRow) => {
      const row = { id: nanoid(), sensorType: typeRow, uom: '' };
      props.sensorGroups.forEach((group) => {
        const seriesData = getSeriesData(
          typeRow,
          group.id,
          props.series,
          props.sensorData
        );
        row[group.id] = seriesData;
        if (!row.uom) row['uom'] = seriesData?.[0]?.unit ?? '';
      });
      _rows.push(row);
    });
    return _rows;
  }, [
    props.series,
    props.sensorGroups,
    props.sensorData,
    sensorTypes,
    isLoading,
  ]);

  return (
    <StyledDataGrid ref={containerRef}>
      <DataGrid
        columns={columns}
        rows={rows ?? []}
        rowSelection={false}
        disableColumnMenu={true}
        loading={isLoading}
        getRowHeight={() => 'auto'}
        height='100'
        showCellVerticalBorder={props?.displayOptions?.showVerticalLines}
      />
    </StyledDataGrid>
  );
};
