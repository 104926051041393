import React, { forwardRef, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { getPatternMeasureData } from '../../../actions/patternMeasureData.actions';
import { selectPatternChart } from '../../../selectors/';
import { GlobalPassthroughs } from '../../../types/global.types';

import { PatternChartConnected, RawPatternMeasureData } from '../../../types/patternChart.types';

import { ChartingErrorBoundary, NoResults } from '../../atoms';

import { PatternChart } from '../../molecules';

export type ConnectedPatternChartProps = PatternChartConnected &
  GlobalPassthroughs & {
    customData?: {
      series: RawPatternMeasureData[];
      unit?: string;
    }
  };

const ConnectedPatternChart = (
  props: ConnectedPatternChartProps,
  chartControllerRef: React.Ref<unknown> | undefined
) => {
  const { className, cy, dataCy, patternId, id } = props;

  const dispatch = useDispatch();
  const generatedId = useMemo(() => id + uuidv4(), [id]);
  const { loading, data, unit } = selectPatternChart(generatedId);

  useEffect(() => {
    if (props.customData)
      return;
    dispatch(
      getPatternMeasureData({
        id: generatedId,
        patternId: patternId,
      })
    );
  }, [props.customData, patternId]);

  if (!loading && !data && !props.customData) {
    return <NoResults />;
  }

  return (
    <ChartingErrorBoundary chartProps={props}>
      <PatternChart
        ref={chartControllerRef}
        className={className}
        cy={cy}
        dataCy={dataCy}
        series={props.customData?.series ?? data ?? []}
        unit={props.customData?.unit ?? unit}
        selectedTheme={props.selectedTheme}
      />
    </ChartingErrorBoundary>
  );
};

export default forwardRef(ConnectedPatternChart);
