import { AnyAction, createReducer } from '@reduxjs/toolkit';

import { SpatialStoreState } from '@Types';
import { getSpatialLayersResolved, getSpatialLayersRejected } from '@Actions';
import { status200, statusDefault } from '../reducers/paginationUtils';

export const spatialInitialState: SpatialStoreState = {
	spatialLayers: [],
	status: statusDefault(),
};

interface SpatialReducer {
	[x: string]: (
		state: SpatialStoreState,
		action: AnyAction,
	) => SpatialStoreState | undefined;
}

const spatialReducerLocal: SpatialReducer = {
	[getSpatialLayersResolved.toString()]: (state, action) => {
		return {
			...state,
			spatialLayers: action.payload,
			status: status200(),
		};
	},
	[getSpatialLayersRejected.toString()]: (state, action) => {
		return {
			...state,
			spatialLayers: [],
			status: action.payload,
		};
	},
};

export const spatialReducer = createReducer(
	spatialInitialState,
	spatialReducerLocal,
);

export default createReducer(spatialInitialState, spatialReducerLocal);
