import Events from './events/Events';
import { IControl } from 'mapbox-gl';
import { SERVICES_ALL_LOADED } from './services/constants';

export const GRID_CONTROL_ON = 'grid-control-on';
export const GRID_CONTROL_OFF = 'grid-control-off';
export const GRID_TOGGLED = 'grid-toggled';
export enum GRID_TOGGLE_STATE {
	on = 'on',
	off = 'off',
}

export default class GridControl extends Events implements IControl {
	static gridControlClass = 'mapboxgl-grid-toggle';
	/** html element for button */
	private _container: HTMLElement | null = null;
	/** tracking state of grid */
	private _gridActive = false;
	/** whether button is disabled */
	private _disabled = true;
	/** event emitter to <ReactMap /> wrapper */
	private _dispatchEvent;

	constructor(dispatchEvent: <T>(eventName: string, detail: T) => void) {
		super();
		this.attachOutboundEvents();
		this._dispatchEvent = dispatchEvent;
	}

	get buttonTitle(): string {
		return 'Toggle grid';
	}

	get active(): boolean {
		return this._gridActive;
	}

	/**
	 * Function called by mapbox when it adds the control to the map
	 * @returns controls container element
	 */
	onAdd(): HTMLElement {
		this._container = this.createControl();
		this.attachEventListeners();
		return this._container;
	}

	/**
	 * Function called by mapbox when it removes the control from the map
	 */
	onRemove(): void {
		this._container?.remove();
	}

	/**
	 * Creates the button which controls the layer panel component being shown/hidden
	 * @returns element containing the control button
	 */
	createControl(): HTMLElement {
		const container = document.createElement('div');
		container.classList.add('mapboxgl-ctrl');
		container.classList.add('mapboxgl-ctrl-group');

		container.innerHTML = this.createButton();

		return container;
	}

	/**
	 * html for button
	 * @returns button html
	 */
	createButton(): string {
		return /* HTML */ `
			<button
				class="${GridControl.gridControlClass} disabled"
				disabled="true"
				title="${this.buttonTitle}"
				aria-label="${this.buttonTitle}"
				aria-pressed="false"
				data-cy="grid-toggle"
			></button>
		`;
	}

	/**
	 * Attach event listeners to the toggle button
	 */
	attachEventListeners(): void {
		const menuButton = this._container?.getElementsByClassName(
			GridControl.gridControlClass,
		)[0];

		menuButton?.addEventListener('click', this.toggleGrid);
	}

	/**
	 * Toggle showing grid on/off
	 */
	toggleGrid = (): void => {
		if (this._gridActive) {
			this.hideGrid();
		} else {
			this.showGrid();
		}
	};

	/**
	 * Show the assets grid and add active class to button
	 */
	showGrid() {
		if (this._disabled) return;
		const menuButton = this._container?.getElementsByClassName(
			GridControl.gridControlClass,
		)[0];
		this._gridActive = true;
		menuButton?.classList.add('active');
		// fire event to main map class
		this.fire(GRID_CONTROL_ON);
		// fire event to <ReactMap /> wrapper component
		this._dispatchEvent(GRID_TOGGLED, GRID_TOGGLE_STATE.on);
	}

	/**
	 * hide the assets grid and remove active class to button
	 */
	hideGrid() {
		if (this._disabled) return;
		const menuButton = this._container?.getElementsByClassName(
			GridControl.gridControlClass,
		)[0];
		this._gridActive = false;
		menuButton?.classList.remove('active');
		// fire event to main map class
		this.fire(GRID_CONTROL_OFF);
		// fire event to <ReactMap /> wrapper component
		this._dispatchEvent(GRID_TOGGLED, GRID_TOGGLE_STATE.off);
	}

	attachOutboundEvents(): void {
		// listen for event that all services have loaded
		this.on(SERVICES_ALL_LOADED, this._onAllServicesLoaded);
	}

	/**
	 * Event triggered when all services have loaded to remove the
	 * disabled class and attribute from the toggle button
	 */
	private _onAllServicesLoaded(): void {
		const menuButton = this._container?.getElementsByClassName(
			GridControl.gridControlClass,
		)[0];

		menuButton?.classList.remove('disabled');
		menuButton?.removeAttribute('disabled');
		this._disabled = false;
	}
}
