import { useSelector } from 'react-redux';

import { KpiValueStoreState } from '../reducers/kpiValue.reducer';

interface KpiResults {
  value: string | number;
  loading: boolean;
}

export const selectKpiValue = (type: string): KpiResults =>
  useSelector<KpiValueStoreState, KpiResults>((state) => {
    return state.kpiValueMap[type];
  });
