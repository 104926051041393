import { CompanySettings } from '@innovyze/stylovyze';

export interface Goal {
  _id: string;
  name: string;
  description: string;
  isRecurring: boolean;
  inputSensorId: string;
  benchmarkSensorId?: string;
  benchmarkConstant?: string;
  goalSensorId?: string;
  goalConstant?: string;
  isLessOrEqualToGoalSensor: boolean;
  isLessOrEqualToBenchmarkSensor: boolean;
  startDate: string;
  periodInterval: string;
  createdBy: string;
  createdByName: string;
  modifiedBy: string;
  modifiedByName: string;
  createdTimeStamp: string;
  modifiedTimestamp: string;
}

export interface GoalPointInTime {
  inputSensorLastValue: number;
  inputSensorTimeStamp: string;
  goalLastValue?: number;
  benchmarkLastValue?: number;
  benchmarkPercentage?: number;
  goalPercentage?: number;
}

export interface GoalQueryRequestBody {
  goalConfigId: string;
  inputSensorId: string;
  inputSensorInterval: string;
  goalSensorId?: string;
  goalSensorInterval?: string;
  benchmarkSensorId?: string;
  benchmarkSensorInterval?: string;
  goalConstant?: number;
  benchmarkConstant?: number;
  startDate: string;
  period: number;
  periodInterval: string;
  recurring: boolean;
}

export interface GoalPointInTimeRequestBody {
  companySettings: CompanySettings;
  query: GoalQueryRequestBody[];
}

export interface GetGoalPastResultsPeriods {
  period: number;
  periodInterval: string;
}

export enum GoalPeriodInterval {
  MINUTE = 'MINUTE',
  HOUR = 'HOUR',
  DAY = 'DAY',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}
export interface GoalPointInTimeResponse {
  query: GoalPointInTime[];
}

export interface GoalDummyUI {
  goalConstant?: {
    value: number;
    unit: string;
  };
  benchmarkConstant?: {
    value: number;
    unit: string;
  };
}

export interface GoalPointInTimeResponse {
  query: GoalPointInTime[];
}

export interface GoalDummyUI {
  goalConstant?: {
    value: number;
    unit: string;
  };
  benchmarkConstant?: {
    value: number;
    unit: string;
  };
}

export interface GoalDummyUI {
  goalConstant?: {
    value: number;
    unit: string;
  };
  benchmarkConstant?: {
    value: number;
    unit: string;
  };
}
