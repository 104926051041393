import { SpatialProperty } from './risk.types';

export interface InspectionStoreState {
	inspectionAttributes: { [key: string]: InspectionScoredAttributes[] };
}

export interface InspectionResolved {
	assetId: string;
	inspections: InspectionScoredAttributes[];
}

export interface InspectionScoredAttributes {
	_id: string;
	inspectionId: string;
	upstreamManholeId: string;
	downstreamManholeId: string;
	direction: string;
	uploadDateTime: string;
	project: string;
	surveyDateTime: string;
	inspectionStatus: string;
	submittedBy: string;
	ratings?: InspectionRating;
	componentConditions?: {
		name: string;
		value: string;
		serviceScore: number;
		structuralScore: number;
	}[];
	videoUrl: string;
	inspectionVersion: string;
	task?: boolean;
	taskId?: string;
	taskTypeName?: string;
	completedDate: SpatialProperty;
	scheduledDate: SpatialProperty;
	event?: boolean;
	eventId?: string;
	eventTypeName?: string;
	openedDate?: SpatialProperty;
	reportedDate?: SpatialProperty;
	verifiedDate?: SpatialProperty;
	resolvedDate?: SpatialProperty;
	closedDate?: SpatialProperty;
}

export interface InspectionRating {
	structuralOverallRating: number;
	structuralQuickRating: string;
	structuralRatingIndex: number;
	serviceOverallRating: number;
	serviceQuickRating: string;
	serviceRatingIndex: number;
	overallRating: number;
	quickRating: string;
	ratingIndex: number;
	structuralGradeRating?: number;
	serviceGradeRating?: number;
}

export interface InspectionParams {
	assetId: string;
	assetType: string;
}

export interface InspectionResolvedResponse {
	data: InspectionScoredAttributes[];
}

export interface GetInspListParams {
	data: InspectionScoredAttributes[];
}

export enum InspectionStandard {
	PACP = 'PACP',
	MACP = 'MACP',
	MSCC = 'MSCC',
	WSA05 = 'WSA05',
}
