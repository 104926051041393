import { CompositeLayerProps, Filter, MapboxLayerType } from '../types';

import ClusterCountLayer from '../mapbox/ClusterCountLayer';
import ClusterLayer from '../mapbox/ClusterLayer';
import LayerBase from './LayerBase';

export default class ClusterCreatorLayer extends LayerBase {
	private _countLayer: MapboxLayerType;

	constructor(layerInfo: CompositeLayerProps, map: mapboxgl.Map) {
		super(layerInfo, map, {
			idPostfix: '-cluster',
		});
		const clusterFilter = this._getFilter();
		this._layer = new ClusterLayer({
			...layerInfo,
			filter: clusterFilter,
			id: this.id,
			zIndex: this._getZIndex(layerInfo.zIndex) + 1,
		});
		this._countLayer = new ClusterCountLayer({
			...layerInfo,
			filter: clusterFilter,
			id: this.countId,
			zIndex: this._getZIndex(layerInfo.zIndex) + 2,
		});
	}

	get countId(): CompositeLayerProps['id'] {
		return this.layerInfo.id + '-cluster-count';
	}

	draw = (): void => {
		super.draw();
		this._mapboxUtils.addLayer(this._countLayer);
		this._resetCountFilterOnMap();
	};

	show(): void {
		super.show();
		this._mapboxUtils.showLayer(this.countId);
	}

	hide(): void {
		super.hide();
		this._mapboxUtils.hideLayer(this.countId);
	}

	delete(): void {
		super.delete();
		this._mapboxUtils.removeLayer(this.countId);
	}

	protected _getFilter = (): Filter | undefined => {
		return ['has', 'point_count'];
	};

	protected _resetCountFilterOnMap(): void {
		this._mapboxUtils.setFilter(this.countId, this._getFilter());
	}
}
