import { AssetRiskAndCondition, SpatialRecord } from '@Types';
import { PaginatedAssets, PaginatedAssetsResponse } from '@Utils/types';

import { AxiosResponse } from 'axios';
import service from '@Apis/risk.management.api';

export interface GetSpatialRecordResponse {
	record: SpatialRecord;
}

export type SpatialLayer = { LAYER_NAME: string };
export type SpatialMetaRecord = {
	property: string;
	type: string;
};

export type SpatialMetaData = {
	[layerName: string]: SpatialMetaRecord[];
};

export const getSpatialLayers = (): Promise<AxiosResponse<SpatialLayer[]>> =>
	service.get('/spatial/layerlist', { timeout: 30000 });

export const getSpatialRecord = (
	recordId: string,
): Promise<AxiosResponse<GetSpatialRecordResponse>> => {
	return service.get<GetSpatialRecordResponse>(`/spatial/${recordId}`);
};

export const getSpatialDataPaginated = (
	layerName: string,
	limit: number,
	offset?: number,
	sort?: string,
	sortDescending?: boolean,
	filterModel?: PaginatedAssets['filterModel'],
	exclude?: PaginatedAssets['exclude'],
): Promise<PaginatedAssetsResponse> => {
	return service.post(`/spatial/layer/${layerName}`, {
		pagination: {
			limit,
			offset,
		},
		sort: {
			field: sort,
			direction: sortDescending ? 'desc' : 'asc',
		},
		filter: filterModel,
		exclude,
	});
};

export const getRiskScore = (
	id: string,
): Promise<AxiosResponse<AssetRiskAndCondition>> => {
	return service.get(`/risk/singlescore/${encodeURIComponent(id)}`);
};

export const getSpatialMetadata = (): Promise<AxiosResponse<
	SpatialMetaData
>> => {
	return service.get('/spatial/meta');
};
