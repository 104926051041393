// import * as InsightData from '../../core/insight-data';

import { Duration, DurationLikeObject } from 'luxon';
import * as InsightChart from '../../core/_insight-chart';
import * as TimeSeriesDataOld from '../../core/time-series-data-old';
import * as TimeSeriesData from '../../core/time-series-data';
import Highcharts from 'highcharts';

// import type {
// 	AnalyticFunction,
// 	Measures,
// 	Reading,
// 	Resolution,
// } from '../../core/insight-data';
// import type {
// 	CandlestickSeriesData,
// 	ColumnSeriesData,
// 	LineSeriesData,
// 	SeriesOptions,
// 	ZoomOptions,
// } from '../../modules/historical-chart';

// /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
//  * Zoom Options
//  * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const typeUnitOrder = {
  minutes: 0,
  hours: 1,
  days: 2,
  weeks: 3,
  months: 4,
  years: 5,
};

const resolutionTimeCount = {
  RAW: { count: 5, type: 'minutes', order: 0 },
  '15-MINUTE': { count: 15, type: 'minutes', order: 1 },
  '30-MINUTE': { count: 30, type: 'minutes', order: 2 },
  HOURLY: { count: 1, type: 'hours', order: 3 },
  DAILY: { count: 1, type: 'days', order: 4 },
  WEEKLY: { count: 1, type: 'weeks', order: 5 },
  MONTHLY: { count: 1, type: 'months', order: 6 },
};

const buttons = {
  '1 Hour': { count: 1, type: 'hour', text: '1H' },
  '6 Hours': { count: 6, type: 'hour', text: '6H' },
  '12 Hours': { count: 12, type: 'hour', text: '12H' },
  '1 Day': { count: 1, type: 'day', text: '1D' },
  '3 Days': { count: 3, type: 'day', text: '3D' },
  '1 Week': { count: 1, type: 'week', text: '1W' },
  '2 Weeks': { count: 2, type: 'week', text: '2W' },
  '1 Month': { count: 1, type: 'month', text: '1M' },
  '3 Months': { count: 3, type: 'month', text: '3M' },
  '6 Months': { count: 6, type: 'month', text: '6M' },
  '1 Year': { count: 1, type: 'year', text: '1Y' },
  '3 Years': { count: 3, type: 'year', text: '3Y' },
  // YTD: { type: 'ytd', text: 'YTD' },
} as const;

const use = (keys: string[]) => {
  return keys.map((key) => buttons[key as keyof typeof buttons]);
};

export const resolutionToZoomButtons = (
  resolution: InsightChart.Resolution,
  limit: number
): Highcharts.RangeSelectorButtonsOptions[] | undefined => {
  const resDuration = resolution ? resolutionTimeCount[resolution] : undefined;
  if (resolution === 'AUTO' || !resDuration) {
    const keys = ['1 Day', '1 Week', '1 Month', '1 Year'];
    return use(keys);
  }

  const keys = Object.keys(buttons).filter((key) => {
    const duration = Duration.fromObject({
      [resDuration.type]: resDuration.count * limit,
    });
    const btn = buttons[key as keyof typeof buttons];
    const btnType = `${btn.type}s` as keyof DurationLikeObject;
    const btnDuration = Duration.fromObject({
      [btn.type]: btn.count,
    });

    if (
      typeUnitOrder[btnType as keyof typeof typeUnitOrder] >
      typeUnitOrder[resDuration.type as keyof typeof typeUnitOrder]
    )
      if (duration.as(btnType) > btnDuration.as(btnType)) {
        return key;
      }
  });

  return use(keys);
};

export const getLowestResolution = (
  sources: (TimeSeriesDataOld.ResponseDataEntry | undefined)[]
): InsightChart.Resolution => {
  const hasAuto = sources.find((s) => s?.resolution === 'AUTO');
  if (hasAuto) {
    return 'AUTO';
  }
  const resolutions = sources.map(
    (s) =>
      (s?.resolution ?? 'DAILY') as Exclude<InsightChart.Resolution, 'AUTO'>
  );
  let lowest = resolutions[0];
  for (let i = 1; i <= resolutions.length; i++) {
    if (
      resolutionTimeCount[resolutions[i]]?.order <
      resolutionTimeCount[lowest]?.order
    ) {
      lowest = resolutions[i];
    }
  }

  return lowest;
};

export function getEdgeLowestResolution(
  resolutions: InsightChart.Resolution[]
): InsightChart.Resolution | undefined {
  if (!resolutions.length) return undefined;
  if (resolutions.includes('AUTO')) return 'AUTO';
  return resolutions.reduce((l, r) => {
    const ro = resolutionTimeCount[r]?.order;
    const lo = resolutionTimeCount[l]?.order;
    if (ro === undefined) return l;
    if (lo === undefined) return r;
    if (ro < lo) return r;
    return l;
  }, resolutions[0]);
}

const AUTO_RESOLUTION_RANGES = {
  [Duration.fromObject({ days: 7 }).toMillis()]: '15-MINUTE',
  [Duration.fromObject({ weeks: 2 }).toMillis()]: '30-MINUTE',
  [Duration.fromObject({ months: 1 }).toMillis()]: 'HOURLY',
  [Duration.fromObject({ months: 12 }).toMillis()]: 'DAILY',
};

export function getEdgeAutoResolution(
  resolutions: InsightChart.Resolution[] | undefined,
  timeSelection: TimeSeriesData.TimeSelection
): InsightChart.Resolution {
  if (!resolutions?.length) return 'RAW';
  const middleResolution = resolutions[Math.trunc(resolutions.length / 2)];

  if (
    typeof timeSelection.from === 'number' &&
    typeof timeSelection.to === 'number'
  ) {
    const range = timeSelection.to - timeSelection.from;
    for (const key of Object.keys(AUTO_RESOLUTION_RANGES)) {
      const millis = Number(key);
      if (range <= millis) {
        const r = AUTO_RESOLUTION_RANGES[key];
        if (resolutions.includes(r)) return r;
      }
    }
    return resolutions.includes('WEEKLY') ? 'WEEKLY' : middleResolution;
  } else {
    return middleResolution;
  }
}

export function edgeSourceStringifier(
  source: TimeSeriesDataOld.Source
): string {
  const analyticString = TimeSeriesData.stringifyAnalytic(source.analytic);
  return `${source.type}:${source.sensorId}:${source.resolution}:${source.periods}:${analyticString}`;
}

export function fixEdgeResponseResults(
  sources: TimeSeriesDataOld.Source[],
  results: Record<string, TimeSeriesData.ResponseResultEntry>
): Record<string, TimeSeriesData.ResponseResultEntry> {
  for (const s of sources) {
    if (InsightChart.isAnalyticFunction(s.analytic)) {
      const { type, params } = s.analytic;
      const key = edgeSourceStringifier(s);
      if (!results[key]) return;

      if (type === 'Constant') {
        results[key].data = results[key].data.map((d) => [
          d[0],
          Number(params.value),
        ]);
      }

      if (type === 'Last' || type === 'MovingSum' || type === 'Movingaverage') {
        const d: [number, Map<InsightChart.Reading, number>][] = results[
          key
        ].data.map((d) => [d[0], new Map([['Average', d[1]]])]);

        results[key].data = InsightChart.processDataWithAnalyticFunction(
          d,
          s.analytic
        );
      }
    }
  }

  return results;
}

// export const makeZoomOptions = (resolution: Resolution): ZoomOptions[] => {
// 	return resolutionZoomButtonsMap[resolution];
// };

// // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
// export const getAnalyticType = (analytic: Reading | AnalyticFunction) => {
// 	return typeof analytic === 'string' ? analytic : analytic.type;
// };

// // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
// export const getAnalyticParams = (analytic: Reading | AnalyticFunction) => {
// 	return typeof analytic === 'string' ? undefined : analytic.params;
// };

// export const makeSeriesData = (
// 	seriesType: SeriesOptions['type'],
// 	analytic: AnalyticFunction | Reading,
// 	data: { measures: Measures; timestamps: number[] } | undefined,
// ): CandlestickSeriesData | LineSeriesData | ColumnSeriesData => {
// 	if (data === undefined || data.timestamps.length === 0) return [];

// 	const analyticType = getAnalyticType(analytic);
// 	const analyticParams = getAnalyticParams(analytic);

// 	switch (seriesType) {
// 		case 'candlestick':
// 			return data.timestamps.map(timestamp => [
// 				timestamp,
// 				data.measures.get(timestamp)?.get('Open') ?? null,
// 				data.measures.get(timestamp)?.get('High') ?? null,
// 				data.measures.get(timestamp)?.get('Low') ?? null,
// 				data.measures.get(timestamp)?.get('Close') ?? null,
// 			]);
// 		case 'line':
// 			return InsightData.analyticFunctionMap[analyticType](
// 				data,
// 				analyticParams,
// 			);
// 		case 'column':
// 			return InsightData.analyticFunctionMap[analyticType](
// 				data,
// 				analyticParams,
// 			);
// 		default:
// 			return [];
// 	}
// };
