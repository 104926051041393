import React, { forwardRef, useMemo } from 'react';

import {
  RangeSelection,
  HiddenSeries,
  PumpPerformanceChartDisplayOptions,
  ManufacturerCurve,
} from '../../../types';

import { ChartProps } from '../../../core/types/chart.types';
import { PumpPerformanceSeries } from '../../../core/types/pumpPerformance.types';
import Chart, { ChartRef } from '../../../core/components/Chart';
import { isFeatureEnabled, selectSettings } from '@innovyze/stylovyze';
import useRangeSelection from '../../../core/hooks/useRangeSelection';
import useHiddenSeries from '../../../core/hooks/useHiddenSeries';
import useHighchartsOptions from '../../../core/hooks/useHighchartsOptions';
import { generatePumpPerformanceOptions } from './PumpPerformanceChart.utils';
import Highcharts from 'highcharts';

import { AsyncData } from '../../../core/types/async.types';
import LoadingData from '../../../core/components/LoadingData';
import { getUnifiedDataRecord } from '../../../core/utils/data.utils';
import Styled from './PumpPerformanceChart.styles';
import { useGlobalization } from '../../../i18n/useGlobalization';

export interface PumpPerformanceChartProps
  // TODO: Check this error
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  extends ChartProps<PumpPerformanceSeries> {
  dataRangeSelection?: RangeSelection;
  displayOptions?: PumpPerformanceChartDisplayOptions;
  hiddenSeries?: HiddenSeries;
  manufacturerCurves?: AsyncData<ManufacturerCurve[]>;
  onHiddenSeriesChange?: (hiddenSeries: HiddenSeries) => void;
  onRangeSelectionChange?: (rangeSelection: RangeSelection) => void;
  rangeSelection?: RangeSelection;
}

/**
 * Add a description for your component here
 */
const PumpPerformanceChart = (
  props: PumpPerformanceChartProps,
  ref?: React.Ref<ChartRef>
): JSX.Element => {
  const { t } = useGlobalization();
  const isBoostModuleEnabled = isFeatureEnabled(
    'info-360-analytics-boost-module'
  );

  const {
    companySettings: { dateFormat, hourCycle12, UOM },
  } = selectSettings();

  const { rangeSelectionOptions } = useRangeSelection(
    props.rangeSelection,
    props.dataRangeSelection,
    props.onRangeSelectionChange
  );

  const { hiddenSeries, hiddenSeriesOptions } = useHiddenSeries(
    props.hiddenSeries,
    props.onHiddenSeriesChange
  );

  const options = useHighchartsOptions(() => {
    return [
      generatePumpPerformanceOptions(
        props.data,
        !!isBoostModuleEnabled,
        props.series,
        hiddenSeries,
        props.displayOptions,
        props.manufacturerCurves?.data ?? [],
        dateFormat,
        hourCycle12,
        UOM
      ),
      hiddenSeriesOptions,
      rangeSelectionOptions,
    ];
  }, [
    hiddenSeries,
    hiddenSeriesOptions,
    isBoostModuleEnabled,
    props.data,
    props.displayOptions,
    props.manufacturerCurves,
    props.series,
    rangeSelectionOptions,
    dateFormat,
    hourCycle12,
    UOM,
  ]);

  const computedDataLengths =
    options.series?.map((series) => {
      if ('data' in series) {
        return series.data?.length ?? 0;
      }
      return 0;
    }) ?? [];

  const loadingState = useMemo(() => {
    return getUnifiedDataRecord(props.data, computedDataLengths);
  }, [props.data, computedDataLengths.join('')]);

  return (
    <LoadingData key="pump-performance-chart" data={loadingState}>
      <Styled.Wrapper>
        <Chart
          constructorType="chart"
          highcharts={Highcharts}
          options={options}
          ref={ref}
          forceResetButtonOnLoad={
            !!props.rangeSelection?.min || !!props.rangeSelection?.max
          }
        />
        <Styled.ColorLegend>
          <Styled.LegendText>{t('Latest')}</Styled.LegendText>
          <Styled.LegendGradient />
          <Styled.LegendText>{t('Oldest')}</Styled.LegendText>
        </Styled.ColorLegend>
      </Styled.Wrapper>
    </LoadingData>
  );
};

export default forwardRef(PumpPerformanceChart);
