import { CircularProgress, Grid } from '@mui/material';
import {
	DateTimeInputMode,
	StylovyzeFormV2,
	useIsFeatureEnabled,
	useUnits,
	InfoCard,
	useTzDateTime,
	useCompanyDateTime,
	useSettings,
} from '@innovyze/stylovyze';
import { TFunc, useGlobalization } from '@Translations';
import { Tab, TabPanel, TabWrapper, Tabs } from '@Components/Tabs';
import {
	a11yProps,
	fixupDateTimeForInputDateTime,
	getJustValues,
} from '@Utils';
import {
	refreshInsp,
	selectFullInspection,
	selectFullInspectionIsWaiting,
} from '@Selectors/fullInspection.selectors';
import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import {
	DateTimeProps,
	getChangedFormikFields,
	getDetailGrid,
	getDomainItems,
	NumericProps,
	processFormSubmit,
	UnitProps,
} from '@Utils/common.utils';
import {
	refreshInspection,
	updateInspection,
} from '@Actions/inspection.actions';
import ModifiedInfoCard from '@Components/ModifiedInfoCard/ModifiedInfoCard';
import { FormikValues } from 'formik';
import { InspectionStandard } from '@Types/inspection.types';

const EDITABLE_FIELDS = ['manholeNumber', 'northing', 'easting'];

const NON_EDITABLE_FIELDS = ['uploadedBy', 'uploadDateTime'];

const isEditableField = (fieldName: string, fullEdit: boolean) => {
	return fullEdit
		? !NON_EDITABLE_FIELDS.includes(fieldName)
		: EDITABLE_FIELDS.includes(fieldName);
};

const HIDDEN_FIELDS = ['uploadDateTime', 'scheduledDate'];

const isHiddenField = (fieldName: string, hideFields: boolean) => {
	return hideFields ? HIDDEN_FIELDS.includes(fieldName) : false;
};

const surveyFields = (t: TFunc): { name: string; alias: string }[] => [
	{ name: 'uploadedBy', alias: t('Uploaded By') },
	{ name: 'uploadDateTime', alias: t('Upload Date') },
	{ name: 'scheduledDate', alias: t('Scheduled Date') },
	{ name: 'inspectionId', alias: t('Inspection ID') },
	{ name: 'date', alias: t('Inspection Date') },
	{ name: 'time', alias: t('Inspection Time') },
	{ name: 'preCleaning', alias: t('Pre Cleaning') },
	{ name: 'dateCleaned', alias: t('Date Cleaned') },
	{ name: 'inspectionLevel', alias: t('Inspection Level') },
	{ name: 'drainageArea', alias: t('Drainage Area') },
	{ name: 'manholeNumber', alias: t('Manhole Number') },
	{ name: 'mhUse', alias: t('Manhole Use') },
	{ name: 'accessType', alias: t('Access Type') },
	{ name: 'rimToInvert', alias: t('Rim to Invert') },
	{ name: 'rimToGrade', alias: t('Rim to Grade') },
	{ name: 'gradeToInvert', alias: t('Grade to Invert') },
	{ name: 'rimToGradeExposed', alias: t('Rim to Grade Exposed') },
	{ name: 'northing', alias: t('Northing') },
	{ name: 'easting', alias: t('Easting') },
	{ name: 'elevation', alias: t('Elevation') },
	{ name: 'isImperial', alias: t('Is Imperial') },
];

const adminFields = (t: TFunc): { name: string; alias: string }[] => [
	{ name: 'surveyedBy', alias: t('Survey By') },
	{ name: 'certificateNumber', alias: t('Certificate Number') },
	{ name: 'reviewedBy', alias: t('Reviewed By') },
	{
		name: 'reviewerCertificateNumber',
		alias: t('Reviewer Certificate Number'),
	},
	{ name: 'owner', alias: t('Owner') },
	{ name: 'customer', alias: t('Customer') },
	{ name: 'poNumber', alias: t('PO Number') },
	{ name: 'workOrder', alias: t('Work Order') },
	{ name: 'project', alias: t('Project') },
	{ name: 'sheetNumber', alias: t('Sheet Number') },
	{ name: 'purpose', alias: t('Purpose') },
];

const detailsFields = (t: TFunc): { name: string; alias: string }[] => [
	{ name: 'coverShape', alias: t('Shape') },
	{ name: 'coverSize', alias: t('Size') },
	{ name: 'centerCoverSize', alias: t('Center Size') },
	{ name: 'coverSizeWidth', alias: t('Width') },
	{ name: 'coverMaterial', alias: t('Material') },
	{ name: 'holeDiameter', alias: t('Hole Diameter') },
	{ name: 'holeNumber', alias: t('Hole Number') },
	{ name: 'coverBearingSurfaceDia', alias: t('Bearing Surface Diameter') },
	{ name: 'coverBearingSurfaceDiaWidth', alias: t('Bearing Surface Width') },
	{ name: 'coverFrameFit', alias: t('Frame Fit') },
	{ name: 'coverInsertType', alias: t('Insert Type') },
];

const typeFields = (t: TFunc): { name: string; alias: string }[] => [
	{ name: 'coverTypeBolted', alias: t('Bolted') },
	{ name: 'coverTypeGasketed', alias: t('Gasketed') },
	{ name: 'coverTypeHatchDouble', alias: t('Hatch Double') },
	{ name: 'coverTypeHatchSingle', alias: t('Hatch Single') },
	{ name: 'coverTypeInnerCover', alias: t('Inner Cover') },
	{ name: 'coverTypeLamphole', alias: t('Lamphole') },
	{ name: 'coverTypeLocking', alias: t('Locking') },
	{ name: 'coverTypeRemovableCenter', alias: t('Removable Center') },
	{ name: 'coverTypeSolid', alias: t('Solid') },
	{ name: 'coverTypeVented', alias: t('Vented') },
];

const benchFields = (t: TFunc): { name: string; alias: string }[] => [
	{ name: 'benchPresent', alias: t('Present') },
	{ name: 'benchMaterial', alias: t('Material') },
	{ name: 'benchLining', alias: t('Lining') },
	{ name: 'benchCondition', alias: t('Condition') },
];

const channelFields = (t: TFunc): { name: string; alias: string }[] => [
	{ name: 'channelInstalled', alias: t('Installed') },
	{ name: 'channelMaterial', alias: t('Material') },
	{ name: 'channelType', alias: t('Type') },
	{ name: 'channelExposure', alias: t('Exposure') },
	{ name: 'channelCondition', alias: t('Condition') },
];

const locationFields = (t: TFunc): { name: string; alias: string }[] => [
	{ name: 'street', alias: t('Street') },
	{ name: 'city', alias: t('City') },
	{ name: 'locationCode', alias: t('Location Code') },
	{ name: 'locationDetails', alias: t('Location Details') },
	{ name: 'coordinateSystem', alias: t('Coordinate System') },
	{ name: 'verticalDatum', alias: t('Vertical Datum') },
	{ name: 'gpsAccuracy', alias: t('GPS Accuracy') },
];

const coverConditionFields = (t: TFunc): { name: string; alias: string }[] => [
	{ name: 'coverConditionBoltsMissing', alias: t('Bolts Missing') },
	{ name: 'coverConditionBroken', alias: t('Broken') },
	{ name: 'coverConditionCorroded', alias: t('Corroded') },
	{ name: 'coverConditionCracked', alias: t('Cracked') },
	{ name: 'coverConditionMissing', alias: t('Missing') },
	{
		name: 'coverConditionRestraintDefective',
		alias: t('Restraint Defective'),
	},
	{ name: 'coverConditionRestraintMissing', alias: t('Restraint Missing') },
	{ name: 'coverConditionSound', alias: t('Sound') },
];

const ringConditionFields = (t: TFunc): { name: string; alias: string }[] => [
	{ name: 'ringConditionBroken', alias: t('Broken') },
	{ name: 'ringConditionCorroded', alias: t('Corroded') },
	{ name: 'ringConditionCracked', alias: t('Cracked') },
	{ name: 'ringConditionLeaking', alias: t('Leaking') },
	{ name: 'ringConditionPoorInstall', alias: t('Poor Install') },
	{ name: 'ringConditionSound', alias: t('Sound') },
];

const insertConditionFields = (t: TFunc): { name: string; alias: string }[] => [
	{ name: 'insertConditionCracked', alias: t('Cracked') },
	{ name: 'insertConditionCorroded', alias: t('Corroded') },
	{ name: 'insertConditionInsertFell', alias: t('Insert Fell') },
	{ name: 'insertConditionLeaking', alias: t('Leaking') },
	{ name: 'insertConditionPoorlyFitting', alias: t('Poorly Fitting') },
	{ name: 'insertConditionSound', alias: t('Sound') },
];

const frameConditionFields = (t: TFunc): { name: string; alias: string }[] => [
	{ name: 'frameConditionBroken', alias: t('Broken') },
	{ name: 'frameConditionCoated', alias: t('Coated') },
	{ name: 'frameConditionCorroded', alias: t('Corroded') },
	{ name: 'frameConditionCracked', alias: t('Cracked') },
	{ name: 'frameConditionMissing', alias: t('Missing') },
	{ name: 'frameConditionSound', alias: t('Sound') },
];

const sealConditionFields = (t: TFunc): { name: string; alias: string }[] => [
	{ name: 'sealConditionCracked', alias: t('Cracked') },
	{ name: 'sealConditionLoose', alias: t('Loose') },
	{ name: 'sealConditionMissing', alias: t('Missing') },
	{ name: 'sealConditionOffset', alias: t('Offset') },
	{ name: 'sealConditionSound', alias: t('Sound') },
];

const ringFields = (t: TFunc): { name: string; alias: string }[] => [
	{ name: 'adjustmentRingType', alias: t('Type') },
	{ name: 'adjustmentRingMaterial', alias: t('Material') },
	{ name: 'adjustmentRingHeight', alias: t('Height') },
];

const frameFields = (t: TFunc): { name: string; alias: string }[] => [
	{ name: 'frameMaterial', alias: t('Material') },
	{ name: 'frameBearingSurfaceWidth', alias: t('Bearing Surface Width') },
	{ name: 'frameBearingSurfaceDepth', alias: t('Bearing Surface Depth') },
	{ name: 'frameClearOpenDiameter', alias: t('Clear Open Diameter') },
	{ name: 'frameClearOpenWidth', alias: t('Clear Open Width') },
	{ name: 'frameOffsetDistance', alias: t('Offset Distance') },
	{ name: 'frameSealInflow', alias: t('Seal Inflow') },
	{ name: 'frameDepth', alias: t('Depth') },
];

const coneFields = (t: TFunc): { name: string; alias: string }[] => [
	{ name: 'coneType', alias: t('Type') },
	{ name: 'coneMaterial', alias: t('Material') },
	{ name: 'coneDepth', alias: t('Depth') },
	{ name: 'coneLiningInterior', alias: t('Lining Interior') },
	{ name: 'coneLiningExterior', alias: t('Lining Exterior') },
	{ name: 'coneCondition', alias: t('Condition') },
];

const chimneyFields = (t: TFunc): { name: string; alias: string }[] => [
	{ name: 'chimneyPresent', alias: t('Present') },
	{ name: 'chimneyMaterial1', alias: t('Material 1') },
	{ name: 'chimneyMaterial2', alias: t('Material 2') },
	{ name: 'chimneyIni', alias: t('Inflow & Infiltration') },
	{ name: 'chimneyClearOpening', alias: t('Clear Opening') },
	{ name: 'chimneyDepth', alias: t('Depth') },
	{ name: 'chimneyLiningInterior', alias: t('Lining Interior') },
	{ name: 'chimneyLiningExterior', alias: t('Lining Exterior') },
	{ name: 'chimneyCondition', alias: t('Condition') },
];

const stepFields = (t: TFunc): { name: string; alias: string }[] => [
	{ name: 'stepNumber', alias: t('Number') },
	{ name: 'stepMaterial', alias: t('Material') },
];

const surfaceTypeFields = (t: TFunc): { name: string; alias: string }[] => [
	{ name: 'surfaceTypeAsphalt', alias: t('Asphalt') },
	{ name: 'surfaceTypeConcretePavement', alias: t('Concrete Pavement') },
	{ name: 'surfaceTypeConcreteCollar', alias: t('Concrete Collar') },
	{ name: 'surfaceTypeGrassDirt', alias: t('Grass or Dirt') },
	{ name: 'surfaceTypeGravel', alias: t('Gravel') },
	{ name: 'surfaceTypeOther', alias: t('Other') },
];

const wallFields = (t: TFunc): { name: string; alias: string }[] => [
	{ name: 'wallDiameter', alias: t('Diameter') },
	{ name: 'wallBySize', alias: t('By Size') },
	{ name: 'wallMaterial', alias: t('Material') },
	{ name: 'wallDepth', alias: t('Depth') },
	{ name: 'wallLiningInterior', alias: t('Lining Inerior') },
	{ name: 'wallLiningExterior', alias: t('Lining Exterior') },
	{ name: 'wallCondition', alias: t('Condition') },
];

const otherFields = (t: TFunc): { name: string; alias: string }[] => [
	{ name: 'mediaLabel', alias: t('Media Label') },
	{ name: 'weather', alias: t('Weather') },
	{ name: 'consequenceOfFailure', alias: t('Consequence of Failure') },
	{ name: 'inflowPotentialFromRunoff', alias: t('Potential from Runoff') },
	{ name: 'yearConstructed', alias: t('Year Constructed') },
	{ name: 'yearRenewed', alias: t('Year Renewed') },
	{ name: 'evidenceSurcharge', alias: t('Evidence of Surcharge') },
	{ name: 'additionalInfo', alias: t('Additonal Information') },
	{ name: 'additionalComponentInfo', alias: t('Component Information') },
];

const hardCodeUnitFields: { [key: string]: UnitProps } = {
	rimToInvert: { unit: 'm', step: '1' },
	rimToGrade: { unit: 'm', step: '1' },
	gradeToInvert: { unit: 'm', step: '1' },
	elevation: { unit: 'm', step: '1' },
	rimToGradeExposed: { unit: 'm', step: '1' },
	coverSize: { unit: 'mm', min: 0, step: '1' },
	centerCoverSize: { unit: 'mm', min: 0, step: '1' },
	coverSizeWidth: { unit: 'mm', min: 0, step: '1' },
	coverBearingSurfaceDia: { unit: 'mm', min: 0, step: '1' },
	coverBearingSurfaceDiaWidth: { unit: 'mm', min: 0, step: '1' },
	adjustmentRingHeight: { unit: 'mm', min: 0, step: '1' },
	frameBearingSurfaceWidth: { unit: 'mm', min: 0, step: '1' },
	frameBearingSurfaceDepth: { unit: 'mm', min: 0, step: '1' },
	frameClearOpenDiameter: { unit: 'mm', min: 0, step: '1' },
	frameClearOpenWidth: { unit: 'mm', min: 0, step: '1' },
	frameOffsetDistance: { unit: 'mm', step: '1' },
	coneDepth: { unit: 'mm', step: '1' },
	chimneyClearOpening: { unit: 'mm', step: '1' },
	chimneyDepth: { unit: 'mm', step: '1' },
};

const hardCodeDateTimeFields: {
	[key: string]: DateTimeProps;
} = {
	uploadDateTime: {
		mode: DateTimeInputMode.DATE_TIME,
		field: 'uploadDateTime',
		useCompanyDateTime: true,
	},
	scheduledDate: {
		mode: DateTimeInputMode.DATE_TIME,
		field: 'scheduledDate',
	},
	date: { mode: DateTimeInputMode.DATE, field: 'surveyDateTime' },
	time: { mode: DateTimeInputMode.TIME, field: 'surveyDateTime' },
	dateCleaned: { mode: DateTimeInputMode.DATE, field: 'dateCleaned' },
};

const hardCodeCurrencyFields: {
	[key: string]: NumericProps;
} = {};

const hardCodeNumericFields: {
	[key: string]: NumericProps;
} = {
	northing: { step: 0.1 },
	easting: { step: 0.1 },
	yearConstructed: { min: 0 },
	yearRenewed: { min: 0 },
};

const hardCodeBooleanFields: {
	[key: string]: boolean;
} = {
	coverTypeBolted: true,
	coverTypeGasketed: true,
	coverTypeHatchDouble: true,
	coverTypeHatchSingle: true,
	coverTypeInnerCover: true,
	coverTypeLamphole: true,
	coverTypeLocking: true,
	coverTypeRemovableCenter: true,
	coverTypeSolid: true,
	coverTypeVented: true,
	coverConditionBoltsMissing: true,
	coverConditionBroken: true,
	coverConditionCorroded: true,
	coverConditionCracked: true,
	coverConditionMissing: true,
	coverConditionRestraintDefective: true,
	coverConditionRestraintMissing: true,
	coverConditionSound: true,
	ringConditionBroken: true,
	ringConditionCorroded: true,
	ringConditionCracked: true,
	ringConditionLeaking: true,
	ringConditionPoorInstall: true,
	ringConditionSound: true,
	insertConditionCracked: true,
	insertConditionCorroded: true,
	insertConditionInsertFell: true,
	insertConditionLeaking: true,
	insertConditionPoorlyFitting: true,
	insertConditionSound: true,
	isImperial: true,
	frameConditionBroken: true,
	frameConditionCoated: true,
	frameConditionCorroded: true,
	frameConditionCracked: true,
	frameConditionMissing: true,
	frameConditionSound: true,
	sealConditionCracked: true,
	sealConditionLoose: true,
	sealConditionMissing: true,
	sealConditionOffset: true,
	sealConditionSound: true,
	surfaceTypeAsphalt: true,
	surfaceTypeConcretePavement: true,
	surfaceTypeConcreteCollar: true,
	surfaceTypeGrassDirt: true,
	surfaceTypeGravel: true,
	surfaceTypeOther: true,
};

const getDomainValues = (t: TFunc, fieldName: string) => {
	let domainValueDic: { [key: string]: string } = {};
	switch (fieldName.toLowerCase()) {
		case 'mhUse'.toLowerCase():
			domainValueDic = {
				CB: t('Combined'),
				DP: t('Dam'),
				FM: t('Force Main'),
				LG: t('Levee Gravity'),
				LP: t('Levee Pressure'),
				PR: t('Processes'),
				SS: t('Sanitary'),
				SW: t('Stormwater'),
				XX: t('Not Known'),
				ZZ: t('Other'),
			};
			break;
		case 'purpose'.toLowerCase():
			domainValueDic = {
				A: t('Maintenance Related'),
				B: t('Infiltration and Inflow Investigation'),
				C: t('Post Rehabilitation Survey'),
				D: t('Pre Rehabilitation Survey'),
				E: t('Pre Acceptance - New Sewers'),
				F: t('Routine Assessment'),
				G: t('Capital Improvement Program Assessment'),
				H: t('Resurvey for any Reason'),
				I: t('Sewer System Evaluation Survey'),
				R: t('Pre-Existing Video or Photographs'),
				X: t('Not Known'),
			};
			break;
		case 'preCleaning'.toLowerCase():
			domainValueDic = {
				H: t('Heavy Cleaning'),
				L: t('Light Cleaning'),
				N: t('No Pre-Cleaning'),
				X: t('Not Known'),
				Z: t('Other'),
			};
			break;
		case 'weather'.toLowerCase():
			domainValueDic = {
				1: t('Dry - No precipitation During Survey'),
				2: t('Heavy Rain'),
				3: t('Light Rain'),
				4: t('Snow'),
				5: t('Dry Weather/Wet Ground'),
			};
			break;
		case 'locationCode'.toLowerCase():
			domainValueDic = {
				A: t('Primary Major Arterial Roads'),
				B: t('Secondary Roads'),
				C: t('Local Rural Streets'),
				D: t('Easement/Right of Way'),
				E: t('Woods'),
				F: t('Sidewalk'),
				G: t('Parking lot'),
				H: t('Alley'),
				I: t('Ditch'),
				J: t('Building'),
				K: t('Creek - Includes any waterway'),
				L: t('Railway'),
				M: t('Airport'),
				N: t('Levee/Floodwall'),
				O: t('Dam'),
				P: t('Levee Pump Station'),
				Y: t('Yard'),
				Z: t('Other'),
			};
			break;
		case 'inflowPotentialFromRunoff'.toLowerCase():
			domainValueDic = {
				I: t('Inundated'),
				N: t('None'),
				P: t('Ponding'),
				S: t('Sheeting'),
			};
			break;
		case 'gpsAccuracy'.toLowerCase():
			domainValueDic = {
				L: t('Survey Level'),
				M: t('Sub-Meter'),
				N: t('Nearest Meter'),
			};
			break;
		case 'inspectionLevel'.toLowerCase():
			domainValueDic = {
				1: t('Level 1'),
				2: t('Level 2'),
			};
			break;
		case 'inspectionStatus'.toLowerCase():
			domainValueDic = {
				DI: t('Descent Inspection'),
				SI: t('Surface Inspection'),
				RI: t('Remote Inspection'),
				BM: t('Buried or Marked'),
				NA: t('No Access'),
				NF: t('Not Found'),
				NI: t('Traffic'),
				NO: t('Not Opened'),
				SD: t('Surcharged/Debris'),
			};
			break;
		case 'accessType'.toLowerCase():
			domainValueDic = {
				ACB: t('Catch Basin'),
				ACOH: t('Clean Out House'),
				ACOM: t('Clean Out Mainline'),
				ACOP: t('Clean Out Property'),
				AJB: t('Junction Box'),
				AM: t('Meter'),
				AMH: t('Manhole'),
				AOC: t('Other Special Chamber'),
				AWA: t('Wastewater Access'),
				AWW: t('Wet Well'),
			};
			break;
		case 'coverShape'.toLowerCase():
			domainValueDic = {
				C: t('Circular'),
				O: t('Oval or Elliptical'),
				R: t('Rectangular'),
				S: t('Square'),
				T: t('Triangular'),
				Z: t('Other'),
			};
			break;
		case 'coverMaterial'.toLowerCase():
			domainValueDic = {
				AL: t('Aluminum'),
				CAS: t('Cast Iron'),
				CN: t('Concrete (cast in place)'),
				CR: t('Concrete (precast)'),
				DI: t('Ductile Iron'),
				FR: t('Fiberglass Reinforced'),
				PE: t('Polyethylene'),
				PSC: t('Plastic/Steel Composite'),
				PVC: t('Polyvinyl Chloride'),
				S: t('Steel'),
				XXX: t('Not Known'),
				ZZZ: t('Other'),
			};
			break;
		case 'holeDiameter'.toLowerCase():
			domainValueDic = {
				A: t('<= 1/2 inch (10mm)'),
				B: t('> 1/2 inch (10mm) <= 1 inch (25mm)'),
				C: t('> 1 inch (25mm) <= 1 1/2 inch (38mm)'),
				D: t('> 1 1/2 inch (38mm) <= 2 inch (50mm)'),
				E: t('> 2 inch (50mm) or grated/slotted'),
			};
			break;
		case 'coverFrameFit'.toLowerCase():
			domainValueDic = {
				G: t('Good'),
				O: t('Oversized'),
				R: t('Rocks/Wobbles'),
				U: t('Undersized'),
			};
			break;
		case 'coverInsertType'.toLowerCase():
			domainValueDic = {
				M: t('Metal'),
				N: t('None'),
				P: t('Plastic'),
				Z: t('Other'),
			};
			break;
		case 'adjustmentRingType'.toLowerCase():
			domainValueDic = {
				A: t('Adjustable'),
				N: t('None'),
				S: t('Solid'),
			};
			break;
		case 'adjustmentRingMaterial'.toLowerCase():
		case 'frameMaterial'.toLowerCase():
			domainValueDic = {
				CAS: t('Cast Iron'),
				CN: t('Concrete (cast in place)'),
				CR: t('Concrete (precast)'),
				DI: t('Ductile Iron'),
				FR: t('Fiberglass Reinforced'),
				PE: t('Polyethylene'),
				PSC: t('Plastic/Steel Composite'),
				PVC: t('Polyvinyl Chloride'),
				S: t('Steel'),
				XXX: t('Not Known'),
				ZZZ: t('Other'),
			};
			break;
		case 'frameSealInflow'.toLowerCase():
			domainValueDic = {
				ID: t('Dripper'),
				IG: t('Gusher'),
				IR: t('Runner'),
				IS: t('Stains'),
				IW: t('Weeper'),
				N: t('None'),
			};
			break;
		case 'chimneyMaterial1'.toLowerCase():
		case 'chimneyMaterial2'.toLowerCase():
			domainValueDic = {
				AC: t('Asbestos Cement'),
				BR: t('Brick'),
				CM: t('Corrugated Metal'),
				CN: t('Concrete (cast in place)'),
				CR: t('Concrete (precast)'),
				CT: t('Clay Tile'),
				FR: t('Fiberglass Reinforced'),
				PE: t('Polyethylene'),
				PP: t('Polypropylene'),
				PSC: t('Plastic/Steel Composite'),
				PVC: t('Polyvinyl Chloride'),
				R: t('Rubber'),
				S: t('Steel'),
				VC: t('Vitrified Clay'),
				XXX: t('Not Known'),
				ZZZ: t('Other'),
			};
			break;
		case 'chimneyLiningInterior'.toLowerCase():
		case 'chimneyLiningExterior'.toLowerCase():
		case 'coneLiningInterior'.toLowerCase():
		case 'coneLiningExterior'.toLowerCase():
		case 'wallLiningInterior'.toLowerCase():
		case 'wallLiningExterior'.toLowerCase():
		case 'benchLining'.toLowerCase():
			domainValueDic = {
				B: t('Bitumastic'),
				C: t('Cementitious'),
				CIP: t('Cured-in-Place'),
				E: t('Epoxy'),
				F: t('Fiberglass'),
				N: t('None'),
				P: t('Polymer'),
				PL: t('Plastic'),
				R: t('Rubber'),
				XX: t('Not Known'),
				ZZ: t('Other'),
			};
			break;
		case 'chimneyCondition'.toLowerCase():
		case 'coneCondition'.toLowerCase():
		case 'wallCondition'.toLowerCase():
		case 'benchCondition'.toLowerCase():
		case 'channelCondition'.toLowerCase():
			domainValueDic = {
				D: t('Defective'),
				S: t('Sound'),
				X: t('Not Known'),
			};
			break;
		case 'coneType'.toLowerCase():
			domainValueDic = {
				CC: t('Conical Centered'),
				CO: t('Conical Off Centered'),
				FT: t('Flat Top'),
				N: t('Not Present'),
				ZZ: t('Other'),
			};
			break;
		case 'coneMaterial'.toLowerCase():
		case 'wallMaterial'.toLowerCase():
		case 'channelMaterial'.toLowerCase():
			domainValueDic = {
				AC: t('Asbestos Cement'),
				BR: t('Brick'),
				CM: t('Corrugated Metal'),
				CN: t('Concrete (cast in place)'),
				CR: t('Concrete (precast)'),
				CT: t('Clay Tile'),
				FR: t('Fiberglass Reinforced'),
				PE: t('Polyethylene'),
				PP: t('Polypropylene'),
				PSC: t('Plastic/Steel Composite'),
				PVC: t('Polyvinyl Chloride'),
				S: t('Steel'),
				VC: t('Vitrified Clay'),
				XXX: t('Not Known'),
				ZZZ: t('Other'),
			};
			break;
		case 'benchPresent'.toLowerCase():
			domainValueDic = {
				N: t('None'),
				P: t('Partial - One side only'),
				Y: t('Yes'),
			};
			break;
		case 'benchMaterial'.toLowerCase():
			domainValueDic = {
				AC: t('Asbestos Cement'),
				AL: t('Aluminum'),
				BR: t('Brick'),
				CM: t('Corrugated Metal'),
				CN: t('Concrete (cast in place)'),
				CR: t('Concrete (precast)'),
				CT: t('Clay Tile'),
				FR: t('Fiberglass Reinforced'),
				PE: t('Polyethylene'),
				PP: t('Polypropylene'),
				PSC: t('Plastic/Steel Composite'),
				PVC: t('Polyvinyl Chloride'),
				S: t('Steel'),
				VC: t('Vitrified Clay'),
				XXX: t('Not Known'),
				ZZZ: t('Other'),
			};
			break;
		case 'channelType'.toLowerCase():
			domainValueDic = {
				F: t('Formed'),
				I: t('Insert'),
				N: t('None'),
				P: t('Pipe'),
				R: t('Precast'),
				Z: t('Other'),
			};
			break;
		case 'channelExposure'.toLowerCase():
			domainValueDic = {
				C: t('Closed'),
				F: t('Fully Open'),
				P: t('Partially Open'),
			};
			break;
		case 'stepMaterial'.toLowerCase():
			domainValueDic = {
				B: t('Brick'),
				M: t('Metal'),
				P: t('Plastic'),
				Z: t('Other'),
			};
			break;
		case 'chimneyPresent'.toLowerCase():
		case 'channelInstalled'.toLowerCase():
		case 'evidenceSurcharge'.toLowerCase():
			domainValueDic = {
				N: t('No'),
				Y: t('Yes'),
			};
			break;
		case 'coverTypeBolted'.toLowerCase():
		case 'coverTypeGasketed'.toLowerCase():
		case 'coverTypeHatchDouble'.toLowerCase():
		case 'coverTypeHatchSingle'.toLowerCase():
		case 'coverTypeInnerCover'.toLowerCase():
		case 'coverTypeLamphole'.toLowerCase():
		case 'coverTypeLocking'.toLowerCase():
		case 'coverTypeRemovableCenter'.toLowerCase():
		case 'coverTypeSolid'.toLowerCase():
		case 'coverTypeVented'.toLowerCase():
		case 'coverConditionBoltsMissing'.toLowerCase():
		case 'coverConditionBroken'.toLowerCase():
		case 'coverConditionCorroded'.toLowerCase():
		case 'coverConditionCracked'.toLowerCase():
		case 'coverConditionMissing'.toLowerCase():
		case 'coverConditionRestraintDefective'.toLowerCase():
		case 'coverConditionRestraintMissing'.toLowerCase():
		case 'coverConditionSound'.toLowerCase():
		case 'ringConditionBroken'.toLowerCase():
		case 'ringConditionCorroded'.toLowerCase():
		case 'ringConditionCracked'.toLowerCase():
		case 'ringConditionLeaking'.toLowerCase():
		case 'ringConditionPoorInstall'.toLowerCase():
		case 'ringConditionSound'.toLowerCase():
		case 'insertConditionCracked'.toLowerCase():
		case 'insertConditionCorroded'.toLowerCase():
		case 'insertConditionInsertFell'.toLowerCase():
		case 'insertConditionLeaking'.toLowerCase():
		case 'insertConditionPoorlyFitting'.toLowerCase():
		case 'insertConditionSound'.toLowerCase():
		case 'frameConditionBroken'.toLowerCase():
		case 'frameConditionCoated'.toLowerCase():
		case 'frameConditionCorroded'.toLowerCase():
		case 'frameConditionCracked'.toLowerCase():
		case 'frameConditionMissing'.toLowerCase():
		case 'frameConditionSound'.toLowerCase():
		case 'sealConditionCracked'.toLowerCase():
		case 'sealConditionLoose'.toLowerCase():
		case 'sealConditionMissing'.toLowerCase():
		case 'sealConditionOffset'.toLowerCase():
		case 'sealConditionSound'.toLowerCase():
		case 'surfaceTypeAsphalt'.toLowerCase():
		case 'surfaceTypeConcretePavement'.toLowerCase():
		case 'surfaceTypeConcreteCollar'.toLowerCase():
		case 'surfaceTypeGrassDirt'.toLowerCase():
		case 'surfaceTypeGravel'.toLowerCase():
		case 'surfaceTypeOther'.toLowerCase():
			domainValueDic = {
				No: '',
				Yes: '',
			};
			break;
		default:
			break;
	}
	return domainValueDic;
};

interface Props {
	onReRender?: () => void;
}

export const MACPInspectionProps = ({ onReRender }: Props): JSX.Element => {
	const { t } = useGlobalization();
	const { getSystemValueFormatted } = useUnits();
	const dispatch = useDispatch();
	const { currentDateTime } = useCompanyDateTime();
	const formatDateUTC = useTzDateTime({ timeZone: 'UTC' }).formatDate;
	const { companySettings } = useSettings();

	const [tabValue, setTabValue] = React.useState(0);
	const isLoading = selectFullInspectionIsWaiting();
	const [editHeaderDetails, setEditHeaderDetails] = React.useState(false);
	const [formSubmitted, setFormSubmitted] = React.useState(false);
	const inspection = selectFullInspection();

	const origDetails = useMemo(() => {
		setFormSubmitted(false);

		const values = getJustValues(inspection, false);

		const keys = Object.keys(values);
		keys.forEach(key => {
			// Adjust any date time fields for timezone/dst offsets caused by the datetime control
			const field = Object.entries(hardCodeDateTimeFields).find(
				value => value[1].field === key,
			);
			if (field) {
				values[key] = fixupDateTimeForInputDateTime(
					values[key],
					field[1].useCompanyDateTime ? currentDateTime() : undefined,
				);
			}
		});

		return values;
	}, [inspection]);

	const viewDetails = useMemo(() => {
		const values = getJustValues(inspection, true);

		const keys = Object.keys(values);
		keys.forEach(key => {
			// Adjust any date time fields for timezone/dst offsets caused by the datetime control
			const field = Object.entries(hardCodeDateTimeFields).find(
				value => value[1].field === key,
			);
			if (field) {
				values[key] = fixupDateTimeForInputDateTime(
					values[key],
					field[1].useCompanyDateTime ? currentDateTime() : undefined,
				);
			}
		});

		return values;
	}, [inspection]);

	const inspUpdatestatus = refreshInsp();

	//feature to be behind a LaunchDarkley flag
	const isEditAllowedFlag = useIsFeatureEnabled(
		'info-360-edit-inspections-header',
	)
		? true
		: false;

	const isFullEditAllowedFlag = useIsFeatureEnabled(
		'info-360-edit-full-inspections',
	)
		? true
		: false;

	const isCreateAllowedFlag = useIsFeatureEnabled(
		'info-360-create-inspections',
	);

	const handleChangeTab = (event: unknown, newValue: number) => {
		setTabValue(newValue);
	};

	const handleFormSubmit = (data: FormikValues) => {
		// We should really used the touched state of a field but I cant get the Formik stuff to work properly
		const changes = getChangedFormikFields(origDetails, data);

		if (changes && Object.entries(changes).length !== 0) {
			setFormSubmitted(true);

			const allFields: { name: string; alias: string }[] = [
				...surveyFields(t),
				...adminFields(t),
				...detailsFields(t),
				...typeFields(t),
				...benchFields(t),
				...channelFields(t),
				...locationFields(t),
				...coverConditionFields(t),
				...ringConditionFields(t),
				...insertConditionFields(t),
				...frameConditionFields(t),
				...sealConditionFields(t),
				...ringFields(t),
				...frameFields(t),
				...coneFields(t),
				...chimneyFields(t),
				...stepFields(t),
				...otherFields(t),
			];

			const editedFieldsList = processFormSubmit(
				t,
				InspectionStandard.PACP,
				origDetails,
				changes,
				allFields,
				hardCodeDateTimeFields,
				hardCodeUnitFields,
				hardCodeCurrencyFields,
				hardCodeNumericFields,
				hardCodeBooleanFields,
				getSystemValueFormatted,
				formatDateUTC,
				companySettings,
				t('Date/Time of inspection'),
				{ date: 'date', time: 'time' },
			);

			dispatch(refreshInspection({ status: -1 })); //reset update flag and hide the controls
			dispatch(updateInspection(editedFieldsList)); //trigger update
		}
	};

	//when inspection has been updated, page needs to be refreshed to display latest details
	useEffect(() => {
		if (inspUpdatestatus === 200 && onReRender) onReRender();
	}, [inspUpdatestatus]);

	const surveyAdminDetails = (
		<>
			<ModifiedInfoCard
				title={t('Survey')}
				dataCy="modified-info-card-survey"
				showEditBtn={
					origDetails['inspectionStatus'] !== 'approved' &&
					onReRender !== undefined
				}
				launchDarkleyFlag={isEditAllowedFlag}
				onEditClick={() => {
					setEditHeaderDetails(true);
				}}
				onCancelClick={() => {
					setEditHeaderDetails(false);
				}}
				inEditMode={editHeaderDetails}
				disabled={inspUpdatestatus === -1}>
				<Grid container>
					{getDetailGrid(
						t,
						surveyFields(t),
						hardCodeUnitFields,
						hardCodeDateTimeFields,
						hardCodeCurrencyFields,
						hardCodeNumericFields,
						hardCodeBooleanFields,
						isEditableField,
						isFullEditAllowedFlag,
						isHiddenField,
						!isCreateAllowedFlag,
						getDomainValues,
						getDomainItems,
					)}
				</Grid>
			</ModifiedInfoCard>
			<InfoCard fullHeight title={t('Admin')} dataCy="info-card-admin">
				<Grid container>
					{getDetailGrid(
						t,
						adminFields(t),
						hardCodeUnitFields,
						hardCodeDateTimeFields,
						hardCodeCurrencyFields,
						hardCodeNumericFields,
						hardCodeBooleanFields,
						isEditableField,
						isFullEditAllowedFlag,
						undefined,
						undefined,
						getDomainValues,
						getDomainItems,
					)}
				</Grid>
			</InfoCard>
		</>
	);

	const coverDetails = (
		<>
			<ModifiedInfoCard
				title={t('Details')}
				dataCy="modified-info-card-details"
				showEditBtn={
					origDetails['inspectionStatus'] !== 'approved' &&
					onReRender !== undefined
				}
				launchDarkleyFlag={isEditAllowedFlag}
				onEditClick={() => {
					setEditHeaderDetails(true);
				}}
				onCancelClick={() => {
					setEditHeaderDetails(false);
				}}
				inEditMode={editHeaderDetails}
				disabled={inspUpdatestatus === -1}>
				<Grid container>
					{getDetailGrid(
						t,
						detailsFields(t),
						hardCodeUnitFields,
						hardCodeDateTimeFields,
						hardCodeCurrencyFields,
						hardCodeNumericFields,
						hardCodeBooleanFields,
						isEditableField,
						isFullEditAllowedFlag,
						undefined,
						undefined,
						getDomainValues,
						getDomainItems,
					)}
				</Grid>
			</ModifiedInfoCard>
			<InfoCard fullHeight title={t('Type')} dataCy="info-card-type">
				<Grid container>
					{getDetailGrid(
						t,
						typeFields(t),
						hardCodeUnitFields,
						hardCodeDateTimeFields,
						hardCodeCurrencyFields,
						hardCodeNumericFields,
						hardCodeBooleanFields,
						isEditableField,
						isFullEditAllowedFlag,
						undefined,
						undefined,
						getDomainValues,
						getDomainItems,
					)}
				</Grid>
			</InfoCard>
		</>
	);

	const benchChannelDetails = (
		<>
			<ModifiedInfoCard
				title={t('Bench Details')}
				dataCy="modified-info-card-bench-details"
				showEditBtn={
					origDetails['inspectionStatus'] !== 'approved' &&
					onReRender !== undefined
				}
				launchDarkleyFlag={isEditAllowedFlag}
				onEditClick={() => {
					setEditHeaderDetails(true);
				}}
				onCancelClick={() => {
					setEditHeaderDetails(false);
				}}
				inEditMode={editHeaderDetails}
				disabled={inspUpdatestatus === -1}>
				<Grid container>
					{getDetailGrid(
						t,
						benchFields(t),
						hardCodeUnitFields,
						hardCodeDateTimeFields,
						hardCodeCurrencyFields,
						hardCodeNumericFields,
						hardCodeBooleanFields,
						isEditableField,
						isFullEditAllowedFlag,
						undefined,
						undefined,
						getDomainValues,
						getDomainItems,
					)}
				</Grid>
			</ModifiedInfoCard>
			<InfoCard
				fullHeight
				title={t('Channel Details')}
				dataCy="modified-info-card-channel-details">
				<Grid container>
					{getDetailGrid(
						t,
						channelFields(t),
						hardCodeUnitFields,
						hardCodeDateTimeFields,
						hardCodeCurrencyFields,
						hardCodeNumericFields,
						hardCodeBooleanFields,
						isEditableField,
						isFullEditAllowedFlag,
						undefined,
						undefined,
						getDomainValues,
						getDomainItems,
					)}
				</Grid>
			</InfoCard>
		</>
	);

	const locationDetails = (
		<ModifiedInfoCard
			title={t('Location')}
			dataCy="modified-info-card-location"
			showEditBtn={
				origDetails['inspectionStatus'] !== 'approved' &&
				onReRender !== undefined
			}
			launchDarkleyFlag={isEditAllowedFlag}
			onEditClick={() => {
				setEditHeaderDetails(true);
			}}
			onCancelClick={() => {
				setEditHeaderDetails(false);
			}}
			inEditMode={editHeaderDetails}
			disabled={inspUpdatestatus === -1}>
			<Grid container>
				{getDetailGrid(
					t,
					locationFields(t),
					hardCodeUnitFields,
					hardCodeDateTimeFields,
					hardCodeCurrencyFields,
					hardCodeNumericFields,
					hardCodeBooleanFields,
					isEditableField,
					isFullEditAllowedFlag,
					undefined,
					undefined,
					getDomainValues,
					getDomainItems,
				)}
			</Grid>
		</ModifiedInfoCard>
	);

	const conditions = (
		<>
			<ModifiedInfoCard
				title={t('Cover Condition')}
				dataCy="modified-info-card-cover-condition"
				showEditBtn={
					origDetails['inspectionStatus'] !== 'approved' &&
					onReRender !== undefined
				}
				launchDarkleyFlag={isEditAllowedFlag}
				onEditClick={() => {
					setEditHeaderDetails(true);
				}}
				onCancelClick={() => {
					setEditHeaderDetails(false);
				}}
				inEditMode={editHeaderDetails}
				disabled={inspUpdatestatus === -1}>
				<Grid container>
					{getDetailGrid(
						t,
						coverConditionFields(t),
						hardCodeUnitFields,
						hardCodeDateTimeFields,
						hardCodeCurrencyFields,
						hardCodeNumericFields,
						hardCodeBooleanFields,
						isEditableField,
						isFullEditAllowedFlag,
						undefined,
						undefined,
						getDomainValues,
						getDomainItems,
					)}
				</Grid>
			</ModifiedInfoCard>
			<InfoCard
				fullHeight
				title={t('Ring Condition')}
				dataCy="info-card-ring-condition">
				<Grid container>
					{getDetailGrid(
						t,
						ringConditionFields(t),
						hardCodeUnitFields,
						hardCodeDateTimeFields,
						hardCodeCurrencyFields,
						hardCodeNumericFields,
						hardCodeBooleanFields,
						isEditableField,
						isFullEditAllowedFlag,
						undefined,
						undefined,
						getDomainValues,
						getDomainItems,
					)}
				</Grid>
			</InfoCard>
			<InfoCard
				fullHeight
				title={t('Insert Condition')}
				dataCy="info-card-insert-condition">
				<Grid container>
					{getDetailGrid(
						t,
						insertConditionFields(t),
						hardCodeUnitFields,
						hardCodeDateTimeFields,
						hardCodeCurrencyFields,
						hardCodeNumericFields,
						hardCodeBooleanFields,
						isEditableField,
						isFullEditAllowedFlag,
						undefined,
						undefined,
						getDomainValues,
						getDomainItems,
					)}
				</Grid>
			</InfoCard>
			<InfoCard
				fullHeight
				title={t('Frame Condition')}
				dataCy="info-card-frame-condition">
				<Grid container>
					{getDetailGrid(
						t,
						frameConditionFields(t),
						hardCodeUnitFields,
						hardCodeDateTimeFields,
						hardCodeCurrencyFields,
						hardCodeNumericFields,
						hardCodeBooleanFields,
						isEditableField,
						isFullEditAllowedFlag,
						undefined,
						undefined,
						getDomainValues,
						getDomainItems,
					)}
				</Grid>
			</InfoCard>
			<InfoCard
				fullHeight
				title={t('Seal Condition')}
				dataCy="info-card-seal-condition">
				<Grid container>
					{getDetailGrid(
						t,
						sealConditionFields(t),
						hardCodeUnitFields,
						hardCodeDateTimeFields,
						hardCodeCurrencyFields,
						hardCodeNumericFields,
						hardCodeBooleanFields,
						isEditableField,
						isFullEditAllowedFlag,
						undefined,
						undefined,
						getDomainValues,
						getDomainItems,
					)}
				</Grid>
			</InfoCard>
		</>
	);

	const ringFrameDetails = (
		<>
			<ModifiedInfoCard
				title={t('Ring Details')}
				dataCy="modified-info-ring-details"
				showEditBtn={
					origDetails['inspectionStatus'] !== 'approved' &&
					onReRender !== undefined
				}
				launchDarkleyFlag={isEditAllowedFlag}
				onEditClick={() => {
					setEditHeaderDetails(true);
				}}
				onCancelClick={() => {
					setEditHeaderDetails(false);
				}}
				inEditMode={editHeaderDetails}
				disabled={inspUpdatestatus === -1}>
				<Grid container>
					{getDetailGrid(
						t,
						ringFields(t),
						hardCodeUnitFields,
						hardCodeDateTimeFields,
						hardCodeCurrencyFields,
						hardCodeNumericFields,
						hardCodeBooleanFields,
						isEditableField,
						isFullEditAllowedFlag,
						undefined,
						undefined,
						getDomainValues,
						getDomainItems,
					)}
				</Grid>
			</ModifiedInfoCard>

			<InfoCard fullHeight title={t('Frame Details')}>
				<Grid container>
					{getDetailGrid(
						t,
						frameFields(t),
						hardCodeUnitFields,
						hardCodeDateTimeFields,
						hardCodeCurrencyFields,
						hardCodeNumericFields,
						hardCodeBooleanFields,
						isEditableField,
						isFullEditAllowedFlag,
						undefined,
						undefined,
						getDomainValues,
						getDomainItems,
					)}
				</Grid>
			</InfoCard>
		</>
	);

	const coneChimneyDetails = (
		<>
			<ModifiedInfoCard
				title={t('Cone Details')}
				dataCy="modified-info-cone-details"
				showEditBtn={
					origDetails['inspectionStatus'] !== 'approved' &&
					onReRender !== undefined
				}
				launchDarkleyFlag={isEditAllowedFlag}
				onEditClick={() => {
					setEditHeaderDetails(true);
				}}
				onCancelClick={() => {
					setEditHeaderDetails(false);
				}}
				inEditMode={editHeaderDetails}
				disabled={inspUpdatestatus === -1}>
				<Grid container>
					{getDetailGrid(
						t,
						coneFields(t),
						hardCodeUnitFields,
						hardCodeDateTimeFields,
						hardCodeCurrencyFields,
						hardCodeNumericFields,
						hardCodeBooleanFields,
						isEditableField,
						isFullEditAllowedFlag,
						undefined,
						undefined,
						getDomainValues,
						getDomainItems,
					)}
				</Grid>
			</ModifiedInfoCard>
			<InfoCard fullHeight title={t('Chimney Details')}>
				<Grid container>
					{getDetailGrid(
						t,
						chimneyFields(t),
						hardCodeUnitFields,
						hardCodeDateTimeFields,
						hardCodeCurrencyFields,
						hardCodeNumericFields,
						hardCodeBooleanFields,
						isEditableField,
						isFullEditAllowedFlag,
						undefined,
						undefined,
						getDomainValues,
						getDomainItems,
					)}
				</Grid>
			</InfoCard>
		</>
	);

	const stepOtherDetails = (
		<>
			<ModifiedInfoCard
				title={t('Step Details')}
				dataCy="modified-info-step-details"
				showEditBtn={
					origDetails['inspectionStatus'] !== 'approved' &&
					onReRender !== undefined
				}
				launchDarkleyFlag={isEditAllowedFlag}
				onEditClick={() => {
					setEditHeaderDetails(true);
				}}
				onCancelClick={() => {
					setEditHeaderDetails(false);
				}}
				inEditMode={editHeaderDetails}
				disabled={inspUpdatestatus === -1}>
				<Grid container>
					{getDetailGrid(
						t,
						stepFields(t),
						hardCodeUnitFields,
						hardCodeDateTimeFields,
						hardCodeCurrencyFields,
						hardCodeNumericFields,
						hardCodeBooleanFields,
						isEditableField,
						isFullEditAllowedFlag,
						undefined,
						undefined,
						getDomainValues,
						getDomainItems,
					)}
				</Grid>
			</ModifiedInfoCard>
			<InfoCard fullHeight title={t('Surface Type')}>
				<Grid container>
					{getDetailGrid(
						t,
						surfaceTypeFields(t),
						hardCodeUnitFields,
						hardCodeDateTimeFields,
						hardCodeCurrencyFields,
						hardCodeNumericFields,
						hardCodeBooleanFields,
						isEditableField,
						isFullEditAllowedFlag,
						undefined,
						undefined,
						getDomainValues,
						getDomainItems,
					)}
				</Grid>
			</InfoCard>
			<InfoCard fullHeight title={t('Other Details')}>
				<Grid container>
					{getDetailGrid(
						t,
						otherFields(t),
						hardCodeUnitFields,
						hardCodeDateTimeFields,
						hardCodeCurrencyFields,
						hardCodeNumericFields,
						hardCodeBooleanFields,
						isEditableField,
						isFullEditAllowedFlag,
						undefined,
						undefined,
						getDomainValues,
						getDomainItems,
					)}
				</Grid>
			</InfoCard>
		</>
	);

	const wallDetails = (
		<ModifiedInfoCard
			title={t('Wall Details')}
			dataCy="modified-info-card-wall"
			showEditBtn={
				origDetails['inspectionStatus'] !== 'approved' &&
				onReRender !== undefined
			}
			launchDarkleyFlag={isEditAllowedFlag}
			onEditClick={() => {
				setEditHeaderDetails(true);
			}}
			onCancelClick={() => {
				setEditHeaderDetails(false);
			}}
			inEditMode={editHeaderDetails}
			disabled={inspUpdatestatus === -1}>
			<Grid container>
				{getDetailGrid(
					t,
					wallFields(t),
					hardCodeUnitFields,
					hardCodeDateTimeFields,
					hardCodeCurrencyFields,
					hardCodeNumericFields,
					hardCodeBooleanFields,
					isEditableField,
					isFullEditAllowedFlag,
					undefined,
					undefined,
					getDomainValues,
					getDomainItems,
				)}
			</Grid>
		</ModifiedInfoCard>
	);

	if ((!isLoading && origDetails) || formSubmitted) {
		return (
			<StylovyzeFormV2
				mode={editHeaderDetails ? 'edit' : 'view'}
				initialValues={editHeaderDetails ? origDetails : viewDetails}
				enableReinitialize
				onSubmit={(data: FormikValues) => {
					setEditHeaderDetails(false);
					handleFormSubmit(data);
				}}>
				{({ handleSubmit }) => {
					return (
						<form onSubmit={handleSubmit}>
							<TabWrapper $height={683}>
								<Tabs
									orientation="vertical"
									variant="scrollable"
									value={tabValue}
									onChange={handleChangeTab}
									aria-label="Vertical tabs example">
									<Tab
										label={t('Survey and Admin Details')}
										{...a11yProps(0)}
									/>
									<Tab
										label={t('Cover Details and Type')}
										{...a11yProps(1)}
									/>
									<Tab
										label={t('Bench and Channel Details')}
										{...a11yProps(2)}
									/>
									<Tab
										label={t('Location Details')}
										{...a11yProps(3)}
									/>
									<Tab
										label={t('Conditions')}
										{...a11yProps(4)}
									/>
									<Tab
										label={t('Ring and Frame Details')}
										{...a11yProps(5)}
									/>
									<Tab
										label={t('Cone and Chimney Details')}
										{...a11yProps(6)}
									/>
									<Tab
										label={t('Step and Other Details')}
										{...a11yProps(7)}
									/>
									<Tab
										label={t('Wall Details')}
										{...a11yProps(8)}
									/>
								</Tabs>
								<TabPanel value={tabValue} index={0}>
									{surveyAdminDetails}
								</TabPanel>
								<TabPanel value={tabValue} index={1}>
									{coverDetails}
								</TabPanel>
								<TabPanel value={tabValue} index={2}>
									{benchChannelDetails}
								</TabPanel>
								<TabPanel value={tabValue} index={3}>
									{locationDetails}
								</TabPanel>
								<TabPanel value={tabValue} index={4}>
									{conditions}
								</TabPanel>
								<TabPanel value={tabValue} index={5}>
									{ringFrameDetails}
								</TabPanel>
								<TabPanel value={tabValue} index={6}>
									{coneChimneyDetails}
								</TabPanel>
								<TabPanel value={tabValue} index={7}>
									{stepOtherDetails}
								</TabPanel>
								<TabPanel value={tabValue} index={8}>
									{wallDetails}
								</TabPanel>
							</TabWrapper>
						</form>
					);
				}}
			</StylovyzeFormV2>
		);
	} else {
		return (
			<div style={{ padding: '25px' }}>
				<CircularProgress />
			</div>
		);
	}
};

export default MACPInspectionProps;
