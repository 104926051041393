import Highcharts from 'highcharts';
import { TFunction } from 'i18next';
import { DateTime } from 'luxon';

export interface GaugeSeriesTitleArea {
  label?: string;
  title: string;
  subtitle?: string;
  color: string;
}

const formatDateTime = (date: Date, _timeZone: string) => {
  const timeZone = _timeZone || 'Etc/UTC';
  return DateTime.fromJSDate(date)
    .setZone(timeZone)
    .toFormat('MM/dd/yyyy HH:mm:ss');
};

const mapDashNameToDashArray = (dashStyle: Highcharts.DashStyleValue) => {
  switch (dashStyle) {
    case 'Dash':
      return '8,6';
    case 'DashDot':
      return '8,6,2,6';
    case 'Dot':
      return '2,6';
    case 'LongDash':
      return '16,6';
    case 'LongDashDot':
      return '16,6,2,6';
    case 'LongDashDotDot':
      return '16,6,2,6,2,6';
    case 'ShortDash':
      return '6,2';
    case 'ShortDashDot':
      return '6,2,2,2';
    case 'ShortDashDotDot':
      return '6,2,2,2,2,2';
    case 'ShortDot':
      return '2,2';
    case 'Solid':
    default:
      return 'none';
  }
};

const getSVGPathElement = (
  series: (Highcharts.Series & { group?: { element?: SVGElement } }) | undefined
) => {
  const svgElement = series?.group?.element as SVGElement | undefined;
  if (svgElement) {
    const pathsElements = svgElement.getElementsByClassName('highcharts-dial');
    const pathNode = pathsElements.length
      ? (pathsElements[0] as HTMLElement)
      : null;
    return pathNode;
  }
  return null;
};

export const updateGaugeChartDialDisplay = (
  series:
    | (Highcharts.Series & { group?: { element?: SVGElement } })
    | undefined,
  color: string,
  dashStyle: Highcharts.DashStyleValue
): void => {
  const pathElement = getSVGPathElement(series);
  if (pathElement) {
    pathElement.style.strokeWidth = '4';
    pathElement.style.strokeDasharray = mapDashNameToDashArray(dashStyle);
    pathElement.style.stroke = color;
    pathElement.style.fill = 'none';
    const oldPath = pathElement.getAttribute('d');
    if (oldPath) {
      const newPath = oldPath.split(' ').slice(0, 9);
      newPath[2] = '0';
      newPath[5] = '0';
      newPath[8] = '0';
      pathElement.setAttribute('d', newPath.join(' '));
    }
  }
};

export const updateGaugeChartDialDashStyle = (
  series: (Highcharts.Series & { group?: { element?: SVGElement } }) | undefined
): void => {
  const pathElement = getSVGPathElement(series);
  if (pathElement) {
    const oldPath = pathElement.getAttribute('d');
    if (oldPath) {
      const newPath = oldPath.split(' ').slice(0, 9);
      newPath[2] = '0';
      newPath[5] = '0';
      newPath[8] = '0';
      pathElement.setAttribute('d', newPath.join(' '));
    }
  }
};

export const makeGaugeSeriesTitle = (series: GaugeSeriesTitleArea): string => {
  const { label, title, subtitle, color } = series;
  return [
    `<div style="${[
      'font-size: 1rem',
      //'text-align: center',
      'line-height: 1',
      'margin-bottom: 20px',
    ].join(';')}" />`,
    ...(label !== undefined
      ? [
          `<span style="${[
            'color: #768D95',
            'display: block',
            'font-size: 1.1em',
          ].join(';')}">${label}</span>`,
        ]
      : []),
    `<span style="${[
      `color: ${color}`,
      'display: block',
      'font-size: 2em',
      'margin-bottom: 0.2em',
    ].join(';')}">${title}</span>`,
    ...(subtitle !== undefined
      ? [
          `<span style="${[
            'color: #3c3c3c',
            'display: block',
            'font-size: 1em',
          ].join(';')}">${subtitle}</span>`,
        ]
      : []),
    '</div>',
  ].join('');
};

export const makeGaugeInstanceTitle = (
  measuredSource: GaugeSeriesTitleArea,
  forecastSource?: GaugeSeriesTitleArea
): Highcharts.TitleOptions => {
  if (forecastSource) {
    return {
      useHTML: true,
      text: [
        `<div style="${[
          'display: grid',
          'grid-template-columns: 1fr 1fr',
          'grid-gap: 3rem',
        ].join(';')}" />`,
        makeGaugeSeriesTitle(measuredSource),
        makeGaugeSeriesTitle(forecastSource),
        '</div>',
      ].join(''),
    };
  }
  return {
    useHTML: true,
    text: makeGaugeSeriesTitle(measuredSource),
  };
};

export const isValueBetweenThresholds = (
  value: number | undefined | null,
  min: number | undefined,
  max: number | undefined
): boolean => {
  if (value === undefined || (min === undefined && max === undefined)) {
    return true;
  }

  if (value === null) {
    return false;
  }

  const satisfiesMin = min === undefined ? true : value >= min;
  const satisfiesMax = max === undefined ? true : value <= max;

  return satisfiesMin && satisfiesMax;
};

export const getTitleDisplay = (
  t: TFunction,
  value: number | null | undefined,
  timestamp: number | undefined,
  timeZoneIANA: string,
  unit: string | undefined
): { title: string; subtitle: string } => {
  let title = '';
  let subtitle = '';

  if (typeof value === 'number') {
    title = `${Highcharts.numberFormat(value, 2)}`;
    if (unit) {
      title += ` ${unit}`;
    }
  } else {
    title = t('No Data');
  }

  if (typeof timestamp === 'number') {
    const date = new Date(timestamp);
    subtitle = formatDateTime(date, timeZoneIANA);
  }
  return {
    title,
    subtitle,
  };
};

export const getChartThresholds = (
  minThreshold: number | undefined,
  maxThreshold: number | undefined,
  minChartValue: number | undefined,
  maxChartValue: number | undefined
): { minThreshold: number | undefined; maxThreshold: number | undefined } => {
  let newMinThreshold = minThreshold;
  let newMaxThreshold = maxThreshold;
  if (
    typeof minThreshold === 'number' &&
    typeof maxChartValue === 'number' &&
    minThreshold > maxChartValue
  )
    newMinThreshold = undefined;
  if (
    typeof maxThreshold === 'number' &&
    typeof minChartValue === 'number' &&
    maxThreshold < minChartValue
  )
    newMaxThreshold = undefined;

  return {
    minThreshold: newMinThreshold,
    maxThreshold: newMaxThreshold,
  };
};
