/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  useIsFeatureEnabled,
  useSelectSensors,
  useSettings,
} from '@innovyze/stylovyze';
import * as InsightChart from '../../core/_insight-chart';
import * as ScatterChart from '../../modules/scatter-chart';
import * as React from 'react';
import * as SeriesData from '../../core/series-data';
import * as TimeSeriesData from '../../core/time-series-data';
import * as TimeSeriesDataOld from '../../core/time-series-data-old';
import { fixCollectionInterval } from '../../core/time-series-data/utils';

type EdgeSource = {
  sensorId: string;
  category: string;
  resolution: TimeSeriesDataOld.Resolution;
  reading: TimeSeriesDataOld.Reading;
};

function edgeSourceStringifier(source: EdgeSource): string {
  return `${source.sensorId}:${source.resolution}:${source.reading}`;
}

function limit(value: string | null | undefined, defaultValue = 500): number {
  if (typeof value === 'string' || value === null) return defaultValue;
  if (isNaN(Number(value))) return defaultValue;
  return Number(value);
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Chart Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

interface InsightScatterChartProps
  extends Omit<ScatterChart.ScatterChartRootProps, 'children'> {
  series: InsightScatterChartSeriesProps[];
  timeRangeSelection?: InsightChart.TimeRangeSelection;
}

function makeSources(
  series: InsightScatterChartSeriesProps[],
  isEdgeSource?: boolean
) {
  const sources: (TimeSeriesDataOld.Source & {
    series?: InsightScatterChartSeriesProps;
    reading?: InsightChart.Reading;
    category?: string;
  })[] = [];

  for (const s of series) {
    if (s.type === 'scatter') {
      const { xSource, ySource } = s;

      if (xSource && !xSource.customData) {
        sources.push({
          series: isEdgeSource ? s : undefined,
          category: undefined,
          reading: isEdgeSource ? xSource.reading : undefined,
          sensorId: xSource.sensorId,
          resolution: xSource.resolution,
        });
      }

      if (ySource && !ySource.customData) {
        sources.push({
          series: isEdgeSource ? s : undefined,
          category: undefined,
          reading: isEdgeSource ? ySource.reading : undefined,
          sensorId: ySource.sensorId,
          resolution: ySource.resolution,
        });
      }
    }
  }
  return sources;
}

const InsightScatterChart = React.forwardRef<
  { chart: Highcharts.Chart | undefined },
  InsightScatterChartProps
>((props, ref): React.ReactElement => {
  const { companySettings } = useSettings();
  const { sensors, initialized: sensorsInitialized } = useSelectSensors();
  const useV3 = useIsFeatureEnabled('info-360-edge-analytics-parquet-files');
  const dataLimit = useIsFeatureEnabled(
    'info-360-analytics-hp2-charts-ppc-limit'
  );
  const edge = true;

  const sources = React.useMemo(
    () => makeSources(props.series),
    [props.series]
  );

  const seriesData = TimeSeriesDataOld.useRetriever(sources);

  const [edgeSources] = SeriesData.useSources<EdgeSource>(() => {
    if (!props.series) return;
    const _sources = makeSources(props.series, true);
    return _sources.map((s) => ({
      sensorId: s.sensorId,
      resolution: s.resolution ?? '15-MINUTE',
      reading: s.reading ?? 'Close',
      category: s.category!,
    }));
  }, [props.series]);
  const [edgeData, edgeStatus, retrieveEdgeData] = SeriesData.useRetriever<
    Map<string, { data: Map<number, number>; unit: string | null }>,
    { timeSelection?: TimeSeriesData.PartialTimeSelection }
  >(
    async (signal, params) => {
      if (!edgeSources?.length || !sensorsInitialized) return;

      const timeSelection = {
        from: params.timeSelection?.from ?? 'oldest',
        to: params.timeSelection?.to ?? 'latest',
      } as TimeSeriesData.TimeSelection;

      const response = await TimeSeriesData.retrieve(signal, {
        order: 'asc',
        timeSelection,
        limit: limit(dataLimit),
        timeZone: companySettings.timeZoneIANA,
        data_version: useV3 ? 'v3.0' : 'v2',
        snapping: 'oldest',
        sources: edgeSources.map((s) => {
          const _sensor = sensors.find((_s) => _s.sensorId === s.sensorId);
          const seconds = fixCollectionInterval(_sensor?.collectionInterval);

          return {
            key: edgeSourceStringifier(s),
            sensorId: s.sensorId,
            collectionInterval: { seconds },
            analytic: TimeSeriesData.makeAnalytic(
              s.resolution,
              s.reading ?? 'Close'
            ),
          };
        }),
      });

      const dataMap: Map<
        string,
        { data: Map<number, number>; unit: string | null }
      > = new Map();

      for (let i = 0; i < edgeSources.length; i++) {
        const s = edgeSources[i];
        const key = edgeSourceStringifier(s);
        const result = response?.data?.results[key];
        const m = new Map(result?.data.map(([t, v]) => [t, v]));
        dataMap.set(key, { data: m, unit: result?.unit });
      }

      return dataMap;
    },
    [
      companySettings.timeZoneIANA,
      dataLimit,
      edgeSources,
      sensors,
      sensorsInitialized,
      useV3,
    ]
  );

  // Handles data fetching with node's API
  const { retrieve } = seriesData;
  React.useEffect(() => {
    if (edge) return;
    retrieve({
      timeSelection: {
        start: props.timeRangeSelection?.min,
        end: props.timeRangeSelection?.max,
      },
      limit: { from: 'end' },
    });
  }, [
    retrieve,
    edge,
    props.timeRangeSelection?.min,
    props.timeRangeSelection?.max,
  ]);

  // Handles data fetching with edge analytics' API
  React.useEffect(() => {
    if (!edge) return;
    if (!sensorsInitialized) return;

    retrieveEdgeData({
      timeSelection: {
        from: props.timeRangeSelection?.min,
        to: props.timeRangeSelection?.max,
      },
    });
  }, [
    edge,
    sensorsInitialized,
    props.timeRangeSelection?.max,
    props.timeRangeSelection?.min,
    retrieveEdgeData,
  ]);

  const seriesDataStatus = React.useMemo(() => {
    for (const series of props.series) {
      if (series.type === 'scatter' && series.xSource.customData) {
        return 'resolved';
      }
    }
    return edge ? edgeStatus : seriesData.status;
  }, [edge, edgeStatus, seriesData.status, props.series]);

  const yAxis = React.useMemo(() => {
    if (!edgeData) return;
    const axis = new Map<string, ScatterChart.SeparateAxis>();
    props.series.forEach((series) => {
      const key = `${series.ySource.sensorId}:${series.ySource.resolution}:${series.ySource.reading}`;
      const yUnit = edgeData.get(key)?.unit;
      //will add here other props for custom y settings
      if (yUnit) {
        const title = axis.has(yUnit)
          ? yUnit
          : `${series.yAxisLabel ?? ''} (${yUnit})`;
        axis.set(yUnit, { id: yUnit, title: title });
      }
    });
    return axis;
  }, [edgeData, props.series]);

  return (
    <ScatterChart.ScatterChartRoot {...props} yAxis={yAxis} ref={ref}>
      <ScatterChart.ScatterChartSeriesGroup status={seriesDataStatus}>
        {props.series.map((seriesProps, seriesIndex) => {
          return (
            <InsightScatterChartScatterSeries
              {...seriesProps}
              key={'scatter-' + seriesIndex}
              yAxisMap={yAxis}
              edgeData={edgeData}
            />
          );
        })}
      </ScatterChart.ScatterChartSeriesGroup>
    </ScatterChart.ScatterChartRoot>
  );
});

InsightScatterChart.displayName = 'InsightScatterChart';

type InsightScatterChartSeriesProps = InsightScatterChartScatterSeriesProps;

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Scatter Series Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

type InsightScatterChartScatterSeriesProps =
  ScatterChart.ScatterChartScatterSeriesProps & {
    edgeData?: Map<string, { data: Map<number, number>; unit: string | null }>;
    yAxisMap: Map<string, { id: string; title: string }>;
    xSource: {
      sensorId: string;
      resolution: InsightChart.Resolution;
      reading?: InsightChart.Reading;
      customData?: TimeSeriesDataOld.ResponseDataEntry;
    };
    ySource: {
      sensorId: string;
      resolution: InsightChart.Resolution;
      reading?: InsightChart.Reading;
      customData?: TimeSeriesDataOld.ResponseDataEntry;
    };
  };

const InsightScatterChartScatterSeries = (
  props: InsightScatterChartScatterSeriesProps
): React.ReactElement => {
  const makeMap = (
    data: [timestamp: number, ...values: number[]][]
  ): TimeSeriesDataOld.TimeSeriesDataMap => {
    const map = new Map();
    data.forEach((item) => {
      map.set(item[0], item[1]);
    });
    return map;
  };

  const [xDataMap, xUnit] = React.useMemo((): [
    TimeSeriesDataOld.TimeSeriesDataMap | undefined,
    string | null | undefined,
  ] => {
    const { sensorId, resolution, customData } = props.xSource;
    const { reading = 'Close' } = props.xSource;
    if (customData) {
      return [makeMap(customData.data), null];
    } else {
      const s = { category: undefined, sensorId, resolution, reading };
      const k = edgeSourceStringifier(s);
      const e = props.edgeData?.get(k);
      return [e?.data, e?.unit];
    }
  }, [props.xSource, props.edgeData]);

  const [yDataMap, yUnit] = React.useMemo((): [
    TimeSeriesDataOld.TimeSeriesDataMap | undefined,
    string | null | undefined,
  ] => {
    const { sensorId, resolution, customData } = props.ySource;
    const { reading = 'Close' } = props.ySource;
    if (customData) {
      return [makeMap(customData.data), null];
    } else {
      const s = { category: undefined, sensorId, resolution, reading };
      const k = edgeSourceStringifier(s);
      const e = props.edgeData?.get(k);
      return [e?.data, e?.unit];
    }
  }, [props.ySource, props.edgeData]);

  const processedData = React.useMemo(() => {
    const d: ScatterChart.ScatterChartScatterSeriesData = [];
    if (
      xDataMap !== undefined &&
      xDataMap.size > 0 &&
      yDataMap !== undefined &&
      yDataMap.size > 0
    ) {
      for (const [xTimestamp, xValue] of xDataMap) {
        const yValue = yDataMap.get(xTimestamp);
        d.push({ timestamp: xTimestamp, x: xValue, y: yValue ?? 0 });
      }
      for (const [yTimestamp, yValue] of yDataMap) {
        const xValue = xDataMap.get(yTimestamp);
        d.push({ timestamp: yTimestamp, x: xValue ?? 0, y: yValue });
      }
    }

    return d;
  }, [xDataMap, yDataMap]);

  return (
    <ScatterChart.ScatterChartSeries
      {...props}
      type="scatter"
      data={processedData}
      xAxisUnit={xUnit}
      yAxisUnit={yUnit}
      yAxis={props?.yAxisMap?.get(yUnit)}
    />
  );
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export { InsightScatterChart };

export type {
  InsightScatterChartProps,
  InsightScatterChartScatterSeriesProps,
  InsightScatterChartSeriesProps,
};
