import type { Reading } from './series';
import type { SensorDataMap } from './sensor-data';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Reading Functions
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

type SensorData<T> = T extends Map<infer K, infer V>
  ? [K, V][] | undefined
  : never;

type TimeSeriesData = [number, number | null][];

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * "Close" | "Open" | "High" | "Low" | "Average" | "Sum"
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const processDataWithReading = (
  data: SensorData<SensorDataMap>,
  reading: Reading
): TimeSeriesData => {
  if (data === undefined || data.length === 0) return [];

  return data.map(([timestamp, measures]) => {
    const value = measures.get(reading) ?? null;
    return [timestamp, value === null ? null : value];
  });
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export { processDataWithReading };
