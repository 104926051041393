import {
	AttributeTypes,
	CBAttributes,
	CBResponseValues,
	CBValues,
} from '@Map/types';

import RequestService from './RequestService';

interface AttributesList {
	[key: string]: CBAttributes[];
}

interface AttributeValues {
	[key: string]: {
		[key: string]: CBResponseValues;
	};
}

export default class AttributesService extends RequestService {
	private _attributesList: AttributesList = {};
	private _attributeValues: AttributeValues = {};
	private _attributeForRange: string | undefined;

	constructor(url: string, token?: string, attributeForRange?: string) {
		super(url, token);
		this._attributeForRange = attributeForRange;
	}

	get url(): string {
		return this._baseUrl;
	}

	get attributeForRange() {
		return this._attributeForRange;
	}

	async getAttributesList(layerId: string): Promise<CBAttributes[]> {
		const requestId = `list-${layerId}`;
		this.setRequest(requestId, {
			url: `${this._baseUrl}/layers/${layerId}/attributes`,
		});
		if (this.attributeForRange) {
			return [
				{
					id: this.attributeForRange,
					fieldType: AttributeTypes.range,
				},
			];
		}
		if (this._attributesList[layerId]) {
			return this._attributesList[layerId];
		}
		const data = await this.getRequest<CBAttributes[]>(
			requestId,
			[],
			'map_exception_getAttributesList',
			'Failed to get attributes list',
		);
		if (data) {
			this._attributesList[layerId] = data;
		}
		return data;
	}

	async getAttributeValuesList(
		layerId: string,
		attributeId: string,
		limit: number,
	): Promise<CBResponseValues> {
		const requestId = `values-${layerId}`;
		this.setRequest(requestId, {
			url: `${this._baseUrl}/layers/${layerId}/attributes/${attributeId}/list?limit=${limit}`,
		});
		if (this._attributeValues[layerId]?.[attributeId]) {
			return this._attributeValues[layerId][attributeId];
		}
		const data = await this.getRequest<CBValues | CBResponseValues>(
			requestId,
			[],
			'map_exception_getAttributeValuesList',
			'Failed to get attribute values list',
		);
		if (data) {
			if (Array.isArray(data)) {
				this._attributeValues[layerId] = {
					...this._attributeValues[layerId],
					[attributeId]: {
						count: data.length,
						values: data,
					},
				};
			} else {
				this._attributeValues[layerId] = {
					...this._attributeValues[layerId],
					[attributeId]: data,
				};
			}
			return this._attributeValues[layerId][attributeId];
		}
		return { count: 0, values: [] };
	}

	getAttributeLookupEndpoint(layerId: string, attributeId: string): string {
		return `${this._baseUrl}/layers/${layerId}/attributes/${attributeId}/lookup`;
	}
}
