import { generatePatternStockSeries } from '../../../utils/patternChart.utils';
import { RawPatternMeasureData } from '../../../types/patternChart.types';
import { SeriesOptionsType } from 'highcharts';
import { Chart } from '../../atoms';
import * as Styled from './PatternChart.styles';
import React, { forwardRef, useEffect, useState } from 'react';
import { GlobalPassthroughs } from '../../../types/global.types';
import { useGlobalization } from '../../../i18n';
import { getTheme, Theme } from '../../../_next/core/utils/theme-utils';

export interface PatternChartProps extends GlobalPassthroughs {
  series: RawPatternMeasureData[];
  unit?: string;
  selectedTheme?: Theme;
}

const PatternChart = (
  { series: patternSeries, className, cy, dataCy, unit, selectedTheme }: PatternChartProps,
  chartControllerRef: React.Ref<unknown> | undefined
): JSX.Element | null => {
  const [stockSeries, setStockSeries] = useState<SeriesOptionsType[]>();
  const { t } = useGlobalization();

  useEffect(() => {
    const runGenerateStockSeries = async () => {
      const stockSeries = await generatePatternStockSeries(patternSeries, selectedTheme);

      setStockSeries([stockSeries]);
    };

    runGenerateStockSeries();

    runGenerateStockSeries();
  }, [patternSeries, selectedTheme]);

  const chartOptions: Highcharts.Options = {
    ...getTheme(selectedTheme ?? 'Default'),
    title: {
      text: '',
    },
    navigator: {
      enabled: false,
    },
    rangeSelector: {
      enabled: false,
    },
    boost: {
      enabled: true,
      useGPUTranslations: true,
    },
    legend: {
      enabled: false,
    },
    yAxis: [
      {
        title: {
          text: unit ?? t('Value'),
        },
        opposite: false,
      },
    ],
    xAxis: {
      type: 'category',
      title: {
        text: t('Period'),
      },
      labels: {
        formatter: function (
          this: Highcharts.AxisLabelsFormatterContextObject
        ) {
          return this.value + '';
        },
      },
    },
  };

  return stockSeries ? (
    <Styled.PatternChartWrapper>
      <Styled.ChartContainer>
        <Chart
          ref={chartControllerRef}
          className={className}
          cy={cy}
          dataCy={dataCy}
          series={stockSeries}
          options={chartOptions}
        />
      </Styled.ChartContainer>
    </Styled.PatternChartWrapper>
  ) : null;
};

export default forwardRef(PatternChart);
