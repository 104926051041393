import { ChartControllerRef } from '../../../types/chart.types';
import { generateStatusChartXrangeSeries } from '../../../utils/statusChart.utils';
import { StatusChartSeries } from '../../../types/statusChart.types';
import { v4 as uuid } from 'uuid';
import * as Styled from './StatusChart.styles';
import Highcharts, { Chart, SeriesXrangeOptions } from 'highcharts';
import React, {
  forwardRef,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
} from 'react';
import XrangeModule from 'highcharts/modules/xrange';
import {
  defaultExportingOptions,
  exportDataToCsv,
  exportDataToXls,
  getHiddenSeries,
  isSeriesHidden,
  useTimeZone,
} from '../../../utils';
import { HiddenSeries, RangeSelection } from '../../../types/chartState';

Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
  },
});

export interface StatusChartDisplayOptions {
  showXGrid: boolean;
  showYGrid: boolean;
}

export interface StatusChartProps {
  series: StatusChartSeries[];
  timeRange: number;
  displayOptions?: StatusChartDisplayOptions;
  dataRangeSelection?: RangeSelection;
  hiddenSeries?: HiddenSeries;
  onHiddenSeriesChange?: (hiddenSeries: HiddenSeries) => void;
}

const StatusChart = (
  props: StatusChartProps,
  chartControllerRef: ChartControllerRef
): JSX.Element => {
  const {
    series,
    timeRange,
    dataRangeSelection,
    displayOptions,
    hiddenSeries,
    onHiddenSeriesChange,
  } = props;

  useTimeZone(Highcharts);

  const chartInstance = useRef<Chart>();
  const containerElementId = useRef<string>(uuid());

  const [xrangeSeries, setXrangeSeries] = useState<SeriesXrangeOptions[]>();

  const seriesOptions = useMemo<Highcharts.Options>(() => {
    return {
      series:
        xrangeSeries?.map((serie, index) => {
          return {
            ...serie,
            visible: !isSeriesHidden(serie, index, hiddenSeries),
          };
        }) ??
        series.map(() => {
          return {
            type: 'xrange',
            data: [],
          };
        }),
    };
  }, [series, xrangeSeries, hiddenSeries]);

  const xAxisOptions = useMemo<Highcharts.Options>(() => {
    return {
      xAxis: {
        type: 'datetime',
        gridLineWidth: displayOptions?.showXGrid ? 1 : 0,
      },
    };
  }, [displayOptions?.showXGrid]);

  const yAxisOptions = useMemo<Highcharts.Options>(() => {
    return {
      yAxis: {
        reversed: true,
        title: { text: '' },
        categories: xrangeSeries?.map((series) => series.name as string),
        gridLineWidth: displayOptions?.showYGrid ? 1 : 0,
      },
    };
  }, [xrangeSeries, displayOptions?.showYGrid]);

  const eventsOptions = useMemo<Highcharts.Options>(() => {
    return {
      plotOptions: {
        series: {
          events: {
            show: function (this: Highcharts.Series) {
              onHiddenSeriesChange?.(getHiddenSeries(this.chart.series));
            },
            hide: function (this: Highcharts.Series) {
              onHiddenSeriesChange?.(getHiddenSeries(this.chart.series));
            },
          },
        },
      },
    };
  }, [onHiddenSeriesChange]);

  useLayoutEffect(() => {
    chartInstance.current = Highcharts.chart(
      containerElementId.current,
      Highcharts.merge(
        {
          exporting: defaultExportingOptions,
          chart: { type: 'xrange', height: null },
          title: { text: '' },
          credits: { enabled: false },
        },
        seriesOptions,
        xAxisOptions,
        yAxisOptions,
        eventsOptions
      )
    );

    return () => {
      chartInstance.current?.destroy();
      chartInstance.current = undefined;
    };
  }, []);

  useLayoutEffect(() => {
    chartInstance.current?.update(seriesOptions);
  }, [seriesOptions]);

  useLayoutEffect(() => {
    chartInstance.current?.update(xAxisOptions);
  }, [xAxisOptions]);

  useLayoutEffect(() => {
    chartInstance.current?.update(yAxisOptions);
  }, [yAxisOptions]);

  useLayoutEffect(() => {
    chartInstance.current?.update(eventsOptions);
  }, [eventsOptions]);

  /**
   * Takes every sensor data (StatusChartSeries) and generates
   * the highcharts options for each of them in parallel. Then
   * stores the result in xrangeSeries.
   * */
  useEffect(() => {
    const requestSeriesGeneration = async () => {
      const rangeSeries = await Promise.all(
        series.map((statusChartSeries, index) =>
          generateStatusChartXrangeSeries(
            statusChartSeries,
            index,
            timeRange,
            dataRangeSelection
          )
        )
      );
      setXrangeSeries(rangeSeries);
    };

    if (series) {
      requestSeriesGeneration();
    }
  }, [series, timeRange, dataRangeSelection]);

  useImperativeHandle(chartControllerRef, () => ({
    exportToCsv: () => {
      exportDataToCsv(chartInstance.current);
    },
    exportToXls: () => {
      exportDataToXls(chartInstance.current);
    },
  }));

  return (
    <Styled.StatusChartContainer
      id={containerElementId.current}
      size={series.length}
    />
  );
};

XrangeModule(Highcharts);

export default forwardRef(StatusChart);
