import { AnyAction, createReducer } from '@reduxjs/toolkit';
import {
	AssetManholeStoreState,
	AssetManholeSummary,
} from '@Types/asset.types';
import {
	getAssetManhole,
	getAssetManholeRejected,
	getAssetManholeResolved,
} from '@Actions/assetManhole.actions';

const initAssetManhole: AssetManholeSummary = {
	assetId: '',
	yearLaid: '',
	layerSystemType: '',
	location: '',
	street: '',
	assetType: '',
	owner: '',
	status: '',
	systemType: '',
};

export const initState: AssetManholeStoreState = {
	asset: initAssetManhole,
	isReady: false,
};

interface AssetManholeReducer {
	[x: string]: (
		state: AssetManholeStoreState,
		action: AnyAction,
	) => AssetManholeStoreState | undefined;
}

const reducer: AssetManholeReducer = {
	[getAssetManholeResolved.toString()]: (state, action) => ({
		...state,
		initialized: true,
		asset: action.payload.asset,
		isReady: true,
	}),
	[getAssetManhole.toString()]: state => ({
		...state,
		isReady: false,
	}),
	[getAssetManholeRejected.toString()]: state => ({
		...state,
		initialized: true,
	}),
};

export default createReducer(initState, reducer);
