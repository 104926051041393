import { createAction } from '@reduxjs/toolkit';
import { KpiType } from '../types/kpi.types';

export interface GetKpiValueParams {
  type: KpiType;
}

export interface GetKpiValueResponse {
  type: KpiType;
  value: string | number;
}

export interface GetKpiValueRejected {
  type: KpiType;
}

export const getKpiValue = createAction<GetKpiValueParams>(
  'summaryze/KpiValue/get'
);
export const getKpiValueResolved = createAction<GetKpiValueResponse>(
  'summaryze/KpiValue/get/resolved'
);
export const getKpiValueRejected = createAction<GetKpiValueRejected>(
  'summaryze/KpiValue/get/rejected'
);
