import {
	AdaptorConverters,
	AdaptorTypes,
	CBAttributes,
	CBHasValuesPerAttributePerLayer,
	ColorByOptions,
	ColorRange,
	ConfigEdits,
	ConfigLayer,
	ConfigLayerInternal,
	LayerEdits,
	LayerToggle,
	LayerVisibility,
	ThemeProperties,
} from '../panel/types';
import {
	CONFIG_LOADED,
	CONFIG_RESET,
	CONFIG_SAVED,
	CONFIG_UPDATED,
	MIN_ZOOM,
	RESET_MIN_ZOOM,
} from './constants';
import {
	GradientKey,
	defaultProperties,
	generateGradientSteps,
} from './settings';
import { LayerZindex, ThemeEndpoint } from '@Map/services/types';
import { PANEL_EDITOR_SAVE, PANEL_LAYER_UPDATE } from '@Map/panel/constants';
import ThemeManager, { THEME_NOT_EXIST } from '@Map/services/ThemeManager';
import { convertToImperial, convertToMetric } from '@innovyze/shared-utils';
import {
	defaultThemeProps,
	fallbackDefaultThemeId,
} from '@Map/services/ThemeDefaults';
import {
	getLayerIdFromIdPath,
	notUndefined,
	santizeString,
	shallowEqual,
} from '../utils';

import { CompositeLayerProps } from './types';
import ConfigAdaptorLayer from './ConfigAdaptorLayer';
import ConfigService from '../services/ConfigService';
import Events from '@Map/events/Events';
import { LOAD_ICON } from '@Map/services/constants';
import MapboxLayer from './mapbox/MapboxLayer';
import ServiceManager from '@Map/services/ServiceManager';
import SymbolLoader from '../symbols/SymbolLoader';
import TileConfigAdaptor from './TileConfigAdaptor';
import TileJsonConfigAdaptor from './TileJsonConfigAdaptor';
import { UnitSystem } from '@Components/Inputs/units';
import VirtualLayerManager from './VirtualLayerManager';
import { extractionAsText } from '@Translations/extraction';

interface ConfigLayerMap {
	[key: string]: ConfigAdaptorLayer;
}

type ThemePropertiesMinusConfig = Omit<ThemeProperties, 'configEdits'>;

export default class ConfigAdaptor extends Events {
	private _layerConfig: ConfigLayerInternal[] = [];
	private _configMap: ConfigLayerMap = {};
	private _configEdits: ConfigEdits = { ...defaultThemeProps.configEdits };
	private _themeProperties: ThemePropertiesMinusConfig = {};
	private _virtualLayerManager = new VirtualLayerManager();
	private _cbHasValuesPerAttributePerLayer: CBHasValuesPerAttributePerLayer = {};
	private _symbolLoader: SymbolLoader;
	private _serviceManager: ServiceManager;
	private _themeEndpoint: ThemeEndpoint | null = null;
	private _themeManager: ThemeManager | undefined;
	private _layerVisibility: LayerVisibility = {};
	private _colorByMaxValues: number;
	private _localStoragePrefix: string | null;
	private _unitSystem: UnitSystem;
	private _defaultThemeId: string;
	private _layerZIndex = 1;

	constructor(
		layerConfig: ConfigLayer[],
		symbolLoader: SymbolLoader,
		serviceManager: ServiceManager,
		themeEndpoint: ThemeEndpoint | null,
		colorByMaxValues: number,
		localStoragePrefix: string | null,
		unitSystem: UnitSystem,
		defaultThemeId: string | null,
	) {
		super();
		this._symbolLoader = symbolLoader;
		this._serviceManager = serviceManager;
		this._colorByMaxValues = colorByMaxValues;
		this._localStoragePrefix = localStoragePrefix;
		this._defaultThemeId = defaultThemeId ?? fallbackDefaultThemeId;
		this.themeEndpoint = themeEndpoint;
		this._layerConfig = this._generateInternalIds(layerConfig);
		this.loadExternalConfigs(layerConfig);
		this._unitSystem = unitSystem;
	}

	updateLayerConfig(layerConfig: ConfigLayer[]): void {
		this._layerConfig = this._generateInternalIds(layerConfig);
		this.loadExternalConfigs(layerConfig);
	}

	updateConfigItem<T extends keyof ConfigEdits>(
		key: T,
		value: ConfigEdits[T],
	): void {
		this._configEdits[key] = value;
	}

	getConfigItem<T extends keyof ConfigEdits>(key: T): ConfigEdits[T] {
		return this._configEdits[key];
	}

	destroy(): void {
		this._themeManager?.destroy();
	}

	set localStoragePrefix(localStoragePrefix: string | null) {
		this._localStoragePrefix = localStoragePrefix;
	}

	set themeEndpoint(themeEndpoint: ThemeEndpoint | null) {
		if (shallowEqual(this._themeEndpoint, themeEndpoint)) return;
		this._themeEndpoint = themeEndpoint;
		if (themeEndpoint) {
			this._themeManager = new ThemeManager(
				themeEndpoint.url,
				themeEndpoint.token,
				this._localStoragePrefix,
				this._defaultThemeId,
			);
			this._themeManager.on(THEME_NOT_EXIST, async () => {
				this._themeManager?.useDefaultTheme();
				await this._loadThemeEdits();
				this.fire(CONFIG_LOADED);
			});
		}
	}

	set layerVisibility(visibility: LayerVisibility) {
		this._layerVisibility = visibility;
		this._updateVisibility();
	}

	get layerVisibility(): LayerVisibility {
		return this._layerVisibility;
	}

	getLayer(layerId: string): ConfigAdaptorLayer | undefined {
		return this._configMap[layerId];
	}

	getVirtualLayer(layerId: string): ConfigAdaptorLayer | undefined {
		return this._virtualLayerManager.getById(layerId);
	}

	saveEditorChanges = async (): Promise<void> => {
		this._extractEdits();
		await this.saveThemeConfig();
		this.fire(CONFIG_SAVED, {
			layers: this.convertForPanel(),
			selectedThemeId: this.getSelectedThemeId(),
			themeList: await this.getThemeList(),
		});
		this.fire(PANEL_EDITOR_SAVE, { edits: this._configEdits });
	};

	cancelChanges = async (): Promise<void> => {
		this._themeManager?.cancelCreating();
		await this._loadThemeEdits();
		this._loadAllVirtualLayers();
	};

	resetChanges = (): void => {
		this._clearEdits();
		this._deleteAllVirtualLayers();
		this._themeManager?.resetTheme();
		this.fire(CONFIG_RESET);
	};

	createTheme() {
		this._clearEdits();
		this._deleteAllVirtualLayers();
		this._themeManager?.createTheme();
		this.themeProperties = {
			name: '',
			isUserTheme: true,
		};
		this.fire(CONFIG_UPDATED);
	}

	async deleteTheme() {
		this._themeManager?.deleteTheme();
		this._themeManager?.useDefaultTheme();
		await this._loadThemeEdits();
		this._loadAllVirtualLayers();
		this.fire(CONFIG_LOADED);
	}

	async changeTheme(themeId: string) {
		if (!this._themeManager) return;
		this._themeManager.cancelCreating();
		this._themeManager.themeId = themeId;
		await this._loadThemeEdits();
		this._loadAllVirtualLayers();
		this.fire(CONFIG_LOADED);
	}

	isThemeDeletable() {
		return this._themeManager?.isDeletable() ?? false;
	}

	async getThemeList() {
		return await this._themeManager?.getThemeList();
	}

	getSelectedThemeId() {
		return this._themeManager?.getSelectedThemeId() ?? this._defaultThemeId;
	}

	/**
	 * Checks if any of the layer configuration needs to be requested externally and
	 * if required fetches it from endpoint and merges response into configuration
	 */
	async loadExternalConfigs(config: ConfigLayer[]): Promise<void> {
		const updatedConfig = config.map(async layer => {
			const {
				layersEndpoint,
				requestToken,
				hideOnNoData,
				_adaptor,
			} = layer;
			if (layersEndpoint) {
				const adaptor = this._getAdaptor(_adaptor);
				const service = new ConfigService(
					layersEndpoint,
					requestToken,
					adaptor,
				);
				const response = await service.fetchData();
				if (JSON.stringify(response) === '{}' && hideOnNoData) {
					return;
				}
				return {
					...layer,
					...response,
				};
			}
			return layer;
		});
		const themeConfig = this.loadThemeConfig();
		const [themeProperties, resolvedConfig] = await Promise.all([
			themeConfig,
			Promise.all(updatedConfig),
		]);
		this._layerConfig = this._generateInternalIds(
			resolvedConfig.filter(notUndefined),
		);
		if (themeProperties) {
			const { configEdits, ...otherProperties } = themeProperties;
			this._configEdits = configEdits;
			this.themeProperties = otherProperties;
		}
		this._configMap = this._generateConfigMap(
			this._layerConfig,
			undefined,
			this._configEdits.layers,
		);
		this._loadAllVirtualLayers();
		this.fire(CONFIG_LOADED);
	}

	async loadThemeConfig(): Promise<ThemeProperties | undefined> {
		if (!this._themeManager) return;
		return this._themeManager.getTheme();
	}

	async saveThemeConfig(): Promise<void> {
		if (!this._themeManager) return;
		await this._themeManager.saveTheme(
			this._configEdits,
			this.themeName,
			this.isUserTheme,
		);
	}

	private _getAdaptor(
		_adaptor: ConfigLayer['_adaptor'],
	): AdaptorConverters | undefined {
		if (!_adaptor) return;
		return {
			[AdaptorTypes.tiles]: new TileConfigAdaptor(_adaptor).converter,
			[AdaptorTypes.tilesJson]: new TileJsonConfigAdaptor(_adaptor)
				.converter,
		}[_adaptor.type];
	}

	private _generateInternalIds(
		config: ConfigLayer[],
		parent?: ConfigLayer,
	): ConfigLayerInternal[] {
		return config.map(layer => {
			const id =
				layer.id ??
				this._generateLayerId(
					extractionAsText(layer.layerName),
					parent,
				);
			// the idPath is used to create the connection between the layer panel
			// and the mapbox layers and is based on the nesting structure
			const idPath = parent ? `${parent.idPath}/${id}` : id;
			const layers = layer.layers
				? this._generateInternalIds(layer.layers, {
						...layer,
						id,
						idPath,
				  })
				: undefined;
			return {
				...layer,
				id,
				idPath,
				layers,
			};
		});
	}

	private _generateConfigMap(
		config: ConfigLayer[],
		parent?: ConfigLayerInternal,
		edits?: LayerEdits,
	): ConfigLayerMap {
		let layerMap: ConfigLayerMap = {};
		config.forEach(layer => {
			const configAdaptorLayer = new ConfigAdaptorLayer(
				layer,
				this._serviceManager,
				this._symbolLoader,
				parent,
			);
			configAdaptorLayer.edits = edits?.[configAdaptorLayer.id];
			// check if there is a visibility edit for the layer
			// this is likely to be the case if it is set in the hiddenLayer parameter
			const visibility = this._layerVisibility[configAdaptorLayer.id];
			if (visibility != null) configAdaptorLayer.visible = visibility;
			layerMap[configAdaptorLayer.id] = configAdaptorLayer;
			const layers = layer.layers
				? this._generateConfigMap(
						layer.layers,
						configAdaptorLayer.layerConfig,
						edits,
				  )
				: {};
			layerMap = {
				...layerMap,
				...layers,
			};
		});
		return layerMap;
	}

	/**
	 * Converts the configuration into the structure required for the layers panel
	 */
	convertForPanel(): ConfigLayerInternal[] {
		return this._processForPanel(this._layerConfig);
	}

	/**
	 * Converts the configuration into the structure required for generating mapbox layers
	 */
	convertForLayers(): CompositeLayerProps[] {
		this.fire(RESET_MIN_ZOOM);
		this._layerZIndex = 1;
		return this._processForLayers(this._layerConfig);
	}

	/**
	 * Loops through the config and fills in any missing details
	 * @param layerConfig layer configuration
	 * @returns updated layer configuration
	 */
	private _processForPanel(
		layerConfig: ConfigLayerInternal[],
	): ConfigLayerInternal[] {
		return layerConfig.map(configLayer => {
			const layer = this.getLayer(configLayer.id)?.layerConfig;
			if (!layer) return configLayer;
			const virtualLayers = this._getVirtualConfigLayers(layer.id);
			return {
				...layer,
				layers: layer.layers
					? this._processForPanel(layer.layers)
					: undefined,
				virtualLayers,
			};
		});
	}

	/**
	 * Loops through the config to generate the mapbox layers
	 * @param layerConfig layer configuration
	 * @returns mpabox layers
	 */
	private _processForLayers(
		layerConfig: ConfigLayerInternal[],
	): CompositeLayerProps[] {
		return layerConfig.flatMap(layer => {
			const processed = [];

			const compositeLayer = this.getLayer(layer.id)?.asCompositeLayer;
			const lookup = this._getVirtualLookup(layer.id);
			const selectedLookup = this._getVirtualLookup(layer.id, true);
			const sort = this._getVirtualSort(layer.id);

			// only layers with a layer type will be rendered
			if (compositeLayer) {
				const zIndex = this._getZIndex(compositeLayer.zIndex);
				processed.push({
					...compositeLayer,
					colorLookup: lookup,
					selectedColorLookup: selectedLookup,
					zIndex,
					sort,
				});
				const {
					icon,
					color,
					iconBackground,
					pattern,
					selectedPattern,
					selectedColor,
					minZoom,
				} = compositeLayer;
				if (icon) {
					this.fire(LOAD_ICON, {
						id: SymbolLoader.createSymbolId(
							icon,
							color,
							undefined,
							undefined,
							!iconBackground,
						),
					});
				}
				if (pattern) {
					// to load coloured patterns
					// one pattern is loaded and coloured with
					// unselected and selected colours
					this.fire(LOAD_ICON, {
						id: SymbolLoader.createPatternId(
							pattern,
							color || MapboxLayer.UNSELECTED_COLOR,
						),
					});
					// if using a selectedPattern the assumption is that
					// it is already coloured, and that is loaded by default
					// otherwise you wish to colour the normal pattern with
					// the selected colour
					if (!selectedPattern) {
						this.fire(LOAD_ICON, {
							id: SymbolLoader.createPatternId(
								pattern,
								color || MapboxLayer.UNSELECTED_COLOR,
								true,
								selectedColor || MapboxLayer.SELECTED_COLOR,
							),
						});
					}
				}
				this.fire(MIN_ZOOM, { minZoom });
			}
			if (layer.layers) {
				if (layer.layers.length) {
					processed.push(...this._processForLayers(layer.layers));
				}
			}
			return processed;
		});
	}

	private _addZIndexIfDefined(value: number | undefined): number | undefined {
		return value ? value + this._layerZIndex : value;
	}

	private _getZIndex(layerZIndex?: LayerZindex): LayerZindex {
		this._layerZIndex -= 0.001;
		if (typeof layerZIndex === 'number') {
			return layerZIndex + this._layerZIndex;
		} else if (typeof layerZIndex === 'object') {
			return {
				main: this._addZIndexIfDefined(layerZIndex.main),
				selected: this._addZIndexIfDefined(layerZIndex.selected),
				highlight: this._addZIndexIfDefined(layerZIndex.highlight),
			};
		}
		return this._layerZIndex;
	}

	/**
	 * Generates a unique id from the concatenation of the
	 * parent layer name (if exists) and layer name and turning
	 * it into snake_case
	 * @param layerName name of layer
	 * @param parent parent layer configuration
	 * @returns snake_case id
	 */
	private _generateLayerId(layerName: string, parent?: ConfigLayer) {
		return parent
			? santizeString(`${parent?.id}_${layerName}`).replace(/^_/, '')
			: santizeString(layerName);
	}

	get themeName() {
		return this.themeProperties.name;
	}

	set themeName(name: ThemeProperties['name']) {
		this.themeProperties.name = name ?? '';
	}

	get isUserTheme() {
		return this.themeProperties.isUserTheme;
	}

	set isUserTheme(isUserTheme: ThemeProperties['isUserTheme']) {
		this.themeProperties.isUserTheme = isUserTheme;
	}

	get themeProperties(): ThemePropertiesMinusConfig {
		return this._themeProperties;
	}

	set themeProperties(themeProperties: ThemePropertiesMinusConfig) {
		this._themeProperties = { ...themeProperties };
	}

	async editLayer(
		layerId: string,
		edits: ConfigLayerInternal,
	): Promise<void> {
		const configLayer = this.getLayer(layerId);
		if (!configLayer) return;
		configLayer.edits = edits;
		if (configLayer.asCompositeLayer) {
			this.fire(PANEL_LAYER_UPDATE, {
				compositeLayer: configLayer.asCompositeLayer,
			});
		}
		this._checkForAttributes(configLayer);
	}

	private _checkForAttributes(configLayer: ConfigAdaptorLayer) {
		if (configLayer.colorByTheming === ColorByOptions.attribute) {
			this._getAttributeValueList(configLayer.id);
		} else if (configLayer.colorByTheming === ColorByOptions.range) {
			this._getAttributeRange(configLayer.id);
		} else {
			this._deleteVirtualLayers(configLayer.id);
		}
	}

	private _extractEdits(): void {
		// clone the edits that were stored from the theme, this will include
		// all layers whether they are on the map or not
		const edits: LayerEdits = { ...this._configEdits.layers };
		// loop through all the layers that are on the map
		Object.entries(this._configMap).forEach(([layerId, configLayer]) => {
			// get the changes/edits for the layer
			const { changes } = configLayer;
			// if there are not any edits for the layer remove any old edits
			if (!changes || !Object.keys(changes).length) {
				delete edits[layerId];
				return;
			}
			// update the edits object with the current layer edits
			edits[layerId] = { ...changes, id: layerId };
			if (changes.visible != null) configLayer.visible = changes.visible;
		});
		this._configEdits.layers = edits;
	}

	private _clearEdits(): void {
		Object.values(this._configMap).forEach(configLayer => {
			configLayer.edits = {};
		});
		this._configEdits = { ...defaultThemeProps.configEdits };
	}

	private async _loadThemeEdits() {
		const { configEdits, ...otherProperties } =
			(await this.loadThemeConfig()) ?? defaultThemeProps;
		Object.entries(this._configMap).forEach(([layerId, configLayer]) => {
			const edits = configEdits.layers[layerId] ?? {};
			configLayer.edits = edits;
		});
		// clone object to prevent mutation of original data
		this._configEdits = { ...configEdits };
		this.themeProperties = otherProperties;
	}

	private _setVisibilityOfLayer(
		layerId: string,
		visibility: boolean | undefined,
	) {
		const configLayer = this.getLayer(layerId);
		if (configLayer) {
			configLayer.visible = visibility;
		} else {
			this._setVisibilityOfVirtualLayer(layerId, visibility);
		}
	}

	private _setVisibilityOfVirtualLayer(
		layerId: string,
		visibility: boolean | undefined,
	) {
		const virtualLayer = this.getVirtualLayer(layerId);
		if (!virtualLayer) return;
		virtualLayer.visible = visibility;
	}

	private _setVisibilityOfChildLayers(layerId: string, state: boolean): void {
		const childLayers = this.getLayer(layerId)?.childLayerIds || [];
		const virtualChildLayers = this._getVirtualLayerIds(layerId) || [];
		for (let i = 0; i < childLayers.length; i++) {
			const id = childLayers[i];
			this._layerVisibility[id] = state;
			this._setVisibilityOfLayer(id, state);
			this._setVisibilityOfChildLayers(id, state);
		}
		for (let c = 0; c < virtualChildLayers.length; c++) {
			const id = virtualChildLayers[c];
			this._layerVisibility[id] = state;
			this._setVisibilityOfVirtualLayer(id, state);
		}
	}

	private _updateVisibility() {
		Object.entries(this._layerVisibility).forEach(
			([layerId, visibility]) => {
				this._setVisibilityOfLayer(layerId, visibility);
				if (visibility === false) {
					this._setVisibilityOfChildLayers(layerId, visibility);
				}
			},
		);
	}

	setVisbilityOfLayers({ layerPath, state }: LayerToggle): void {
		const id = getLayerIdFromIdPath(layerPath);
		if (!id) return;

		const layerParts = layerPath.split('/');
		layerParts.forEach(layerId => {
			delete this._layerVisibility[layerId];
			this._setVisibilityOfLayer(layerId, undefined);
		});
		this._layerVisibility[id] = state;
		this._setVisibilityOfLayer(id, state);
		this._setVisibilityOfChildLayers(id, state);
	}

	async getAttributesList(
		layerId: string,
	): Promise<CBAttributes[] | undefined> {
		const configLayer = this.getLayer(layerId);
		if (!configLayer) return;
		return this._serviceManager.getAttributesList(
			configLayer.dataServiceId,
			configLayer.attributesLayerId,
		);
	}

	get cbHasValuesPerAttributePerLayer(): CBHasValuesPerAttributePerLayer {
		return this._cbHasValuesPerAttributePerLayer;
	}

	private async _getAttributeValueList(layerId: string, silent = false) {
		const configLayer = this.getLayer(layerId);
		if (!configLayer) return;

		const attribute = configLayer.configWithEdits.attribute;
		if (!attribute) {
			configLayer.validColorBy = false;
			const virtualLayers = this._getVirtualConfigLayers(layerId);
			if (virtualLayers?.length) {
				this._deleteVirtualLayers(layerId);
			}
			return;
		}

		const list = await this._serviceManager.getAttributeValuesList(
			configLayer.dataServiceId,
			configLayer.attributesLayerId,
			attribute,
			this._colorByMaxValues,
		);

		this._cbHasValuesPerAttributePerLayer[layerId] = {
			...this._cbHasValuesPerAttributePerLayer[layerId],
			[attribute]: list?.count || 0,
		};

		if (list?.values?.length && list?.count < this._colorByMaxValues) {
			configLayer.validColorBy = true;
			const defaultColorRamp = defaultProperties(configLayer.type)
				.colorRamp;
			const colors = generateGradientSteps(
				(configLayer.colorRamp as GradientKey) ?? defaultColorRamp,
				list.values.length,
			);
			const layers = list.values.map((layer, i) => {
				const id = this._generateLayerId(
					layer,
					configLayer.layerConfig,
				);
				const color = colors[i];
				const visible =
					this._layerVisibility[id] ?? configLayer.visible;
				const config: ConfigLayerInternal = {
					id,
					idPath: `${configLayer.layerConfig.idPath}/${id}`,
					layerName: layer,
					color,
					type: configLayer.type,
					visible,
					properties: {
						[`${configLayer.attributesLayerId}~${attribute}`]: layer,
					},
				};
				return new ConfigAdaptorLayer(
					config,
					this._serviceManager,
					this._symbolLoader,
					configLayer.configWithEdits,
				);
			});
			this._virtualLayerManager.replaceMany(layerId, layers);
			if (!silent) this.fire(CONFIG_UPDATED);
		} else {
			configLayer.validColorBy = false;
			this._deleteVirtualLayers(layerId, silent);
		}
	}

	private async _getAttributeRange(layerId: string, silent = false) {
		const configLayer = this.getLayer(layerId);
		if (!configLayer) return;

		const range = configLayer.configWithEdits.colorRange;
		const attribute = configLayer.configWithEdits.attribute;
		const attributeForRange = configLayer.attributeForRange ?? attribute;

		if (!range || !attributeForRange) {
			configLayer.validColorBy = false;
			const virtualLayers = this._getVirtualConfigLayers(layerId);
			if (virtualLayers?.length) {
				this._deleteVirtualLayers(layerId);
			}
			return;
		}

		await this._serviceManager?.addAttributeLookup(
			configLayer.dataServiceId,
			configLayer.attributesLayerId,
			attributeForRange,
		);
		const layers = range.map(({ value, color }, i) => {
			const next = range[i + 1];
			const layerName = this._rangeLayerName(
				value,
				next,
				configLayer.configWithEdits.attributeUnit,
			);
			const id = santizeString(`${configLayer.id}_${i}`);
			const visible = this._layerVisibility[id] ?? configLayer.visible;
			const config: ConfigLayerInternal = {
				id,
				idPath: `${configLayer.layerConfig.idPath}/${id}`,
				layerName,
				color,
				type: configLayer.type,
				visible,
				properties: {
					[`${configLayer.attributesLayerId}~${attributeForRange}`]: [
						value,
						(next?.value ?? 999999999999) - 0.0001,
					],
				},
			};
			return new ConfigAdaptorLayer(
				config,
				this._serviceManager,
				this._symbolLoader,
				configLayer.configWithEdits,
			);
		});
		this._virtualLayerManager.replaceMany(layerId, layers);
		if (!silent) this.fire(CONFIG_UPDATED);
	}

	private _rangeLayerName(
		value: number,
		next?: ColorRange,
		unit?: string,
		system = this._unitSystem,
	) {
		const nextValue = next?.value;
		if (unit) {
			const formattingFunc =
				system === UnitSystem.Metric
					? convertToMetric
					: convertToImperial;
			const convertedValue = formattingFunc(`${value} ${unit}`, 2);
			if (nextValue) {
				return `${convertedValue.value} - ${
					formattingFunc(`${nextValue} ${unit}`, 2).formatted
				}`;
			}
			return `${convertedValue.value}+ ${convertedValue.unit}`;
		}
		if (nextValue) {
			return `${value} - ${nextValue}`;
		}
		return `${value}+`;
	}

	private _loadAllVirtualLayers() {
		Object.values(this._configMap).forEach(layer => {
			this._checkForAttributes(layer);
		});
		setTimeout(() => {
			this.fire(CONFIG_UPDATED);
		}, 0);
	}

	private _getVirtualLookup(layerId: string, selected = false) {
		const virtualLayers = this._virtualLayerManager.getByLayerId(layerId);
		const layer = this.getLayer(layerId);
		if (virtualLayers) {
			const lookup: unknown[] = ['case'];
			virtualLayers.forEach(layer => {
				const layerLookup = layer.lookup;
				if (layerLookup?.length) {
					lookup.push(layerLookup);
					if (layer.visible) {
						if (layer.type === 'symbol') {
							lookup.push(
								selected
									? layer.iconColoredSelected
									: layer.iconColored,
							);
						} else {
							lookup.push(
								selected
									? layer.layerConfig.selectedColor
									: layer.layerConfig.color,
							);
						}
					} else {
						lookup.push('rgba(0,0,0,0)');
					}
				}
			});
			if (lookup.length === 1) return;
			if (layer?.type === 'symbol') {
				lookup.push(
					selected ? layer.iconColoredSelected : layer.iconColored,
				);
			} else {
				lookup.push(layer?.layerConfig.color);
			}
			return lookup;
		}
	}

	private _getVirtualSort(layerId: string) {
		const virtualLayers = this._virtualLayerManager.getByLayerId(layerId);
		if (virtualLayers) {
			return virtualLayers[0]?.sort;
		}
	}

	private _getVirtualConfigLayers(layerId: string) {
		const virtualLayers = this._virtualLayerManager.getByLayerId(layerId);
		if (virtualLayers) {
			return virtualLayers.map(layer => layer.layerConfig);
		}
	}

	private _getVirtualLayerIds(layerId: string) {
		const virtualLayers = this._virtualLayerManager.getByLayerId(layerId);
		if (virtualLayers) {
			return virtualLayers.map(layer => layer.id);
		}
	}

	private _deleteVirtualLayers(layerId: string, silent = false) {
		this._virtualLayerManager.deleteMany(layerId);
		if (!silent) this.fire(CONFIG_UPDATED);
	}

	private _deleteAllVirtualLayers() {
		this._virtualLayerManager.deleteAll();
		this.fire(CONFIG_UPDATED);
	}
}
