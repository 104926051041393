import { LngLat, Marker } from 'mapbox-gl';

import { GEOCODE_POPUP } from './constants';
import SearchService from './SearchService';

export default class DropPin {
	private _map: mapboxgl.Map;
	private _reverseGeocoder: SearchService;
	private _dispatchEvent;
	private _pinShown = false;
	private _dropPin = new Marker({
		color: '#768d95',
		draggable: true,
		scale: 0.75,
	});
	private _previewMarker = new Marker({
		element: DropPin.markerElement(),
	});

	constructor(
		map: mapboxgl.Map,
		accessToken: string,
		dispatchEvent: <T>(eventName: string, detail: T) => void,
	) {
		this._map = map;
		this._reverseGeocoder = new SearchService(
			`https://api.mapbox.com/geocoding/v5/mapbox.places/{{query}}.json?access_token=${accessToken}&language={{language}}`,
		);
		this._dispatchEvent = dispatchEvent;
		this._dropPin.on('dragend', this._markerDragEnd);
	}

	static markerElement(): HTMLElement {
		const element = document.createElement('div');
		const html = /* HTML */ `
			<div
				style="width: 45px; height: 45px; background: #00ABD130;
				; border-radius: 100%; display: flex; justify-content: center; align-items: center;"
			>
				<div
					style="width: 30px; height: 30px; background: #00ABD130;
				; border-radius: 100%; display: flex; justify-content: center; align-items: center;"
				>
					<div
						style="width: 7px; height: 7px; background: #00ABD1;
					; border-radius: 100%;"
					></div>
				</div>
			</div>
		`;
		element.innerHTML = html;
		return element;
	}

	setPin(lngLat: mapboxgl.LngLat): void {
		const previousMarker = this._pinShown;
		this.clearPin();
		if (!previousMarker) {
			this._dropPin.setLngLat(lngLat).addTo(this._map);
			this._pinShown = true;
			this._reverseLookup(lngLat);
		}
	}

	clearPin(): void {
		this._dropPin.remove();
		this.clearPreview();
		this._pinShown = false;
		this._dispatchEvent(GEOCODE_POPUP, { features: undefined });
	}

	setPreview(location: number[]): void {
		this._previewMarker
			.setLngLat(LngLat.convert(location as [number, number]))
			.addTo(this._map);
	}

	clearPreview(): void {
		this._previewMarker.remove();
	}

	private async _reverseLookup(lngLat: mapboxgl.LngLat): Promise<void> {
		const data = await this._reverseGeocoder.fetchData(
			lngLat.toArray().join(','),
		);
		const lngLatFeature = {
			query: lngLat.toArray(),
			lngLat,
		};
		const detail = {
			features: data.length ? data : [lngLatFeature],
		};
		this._dispatchEvent(GEOCODE_POPUP, detail);
	}

	_markerDragEnd = (): void => {
		const lngLat = this._dropPin?.getLngLat();
		if (lngLat) {
			this._reverseLookup(lngLat);
		}
	};
}
