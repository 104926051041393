import { InputBase } from '@mui/material';
import { NativeSelect } from '@mui/material';
import { useGlobalization } from '../../../i18n';
import * as React from 'react';
import styled from 'styled-components';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Dropdown
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

interface DropdownProps<Value = string> {
  selected: Value;
  options: { value: Value; label: string }[];
  onChange?: (value: Value) => void;
  label?: string;
}

const Dropdown = <Value extends string = string>(
  props: DropdownProps<Value>
): React.ReactElement => {
  const { t } = useGlobalization();

  const selectRef = React.useRef<HTMLSelectElement>(null);
  const spanRef = React.useRef<HTMLSpanElement>(null);

  /**
   * Native select element will make the width of the dropdown button
   * equal to the largest option width. This will manually set the width
   * to the option selected instead.
   */
  const resizeDropdown = React.useRef((value?: Value) => {
    if (value && selectRef.current && spanRef.current) {
      const selectElement = selectRef.current.querySelector('select');
      if (selectElement) {
        let width = '100% !important';
        const optionElement = selectRef.current.querySelector(
          `option[value="${value}"]`
        );
        if (optionElement) {
          spanRef.current.innerHTML = (optionElement as HTMLElement).innerText;
          width = `${spanRef.current.offsetWidth}px !important`;
        }
        selectElement.setAttribute('style', `width: ${width}`);
      }
    }
  });

  const handleNativeSelectChange = React.useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      props.onChange?.(event.target.value as Value);
      resizeDropdown?.current(event.target.value as Value);
    },
    [props.onChange]
  );

  React.useLayoutEffect(() => {
    resizeDropdown?.current(props.selected);
  }, [props.selected]);

  return (
    <>
      <DropdownStyles.Helper ref={spanRef} aria-hidden="true" />
      <DropdownStyles.Root>
        {props.label ? (
          <DropdownStyles.Label>{props.label}</DropdownStyles.Label>
        ) : null}
        <NativeSelect
          ref={selectRef}
          input={<DropdownStyles.SelectInputBase />}
          value={props.selected}
          onChange={handleNativeSelectChange}>
          {props.options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </NativeSelect>
      </DropdownStyles.Root>
    </>
  );
};

const DropdownStyles = {
  Root: styled('div')<{ withTitle?: boolean }>`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: absolute;
    left: -60px;
    top: ${(props) => (props.withTitle ? '39px' : '9px')};
    z-index: 10;
  `,
  SelectInputBase: styled(InputBase).attrs(() => ({
    margin: 'none',
    size: 'small',
  }))`
    background-color: #e6ebf5;
    border-radius: 2px;
    border: none;
    color: #4a6067 !important;
    cursor: pointer !important;
    font-family: 'Lucida Grande', sans-serif !important;
    font-size: 12px !important;
    font-weight: 700 !important;
    padding: 4px 6px;
    padding-right: 12px;

    &:hover {
      background-color: #e1e4ea;
    }

    & .MuiNativeSelect-select {
      padding: 0 !important;
      background-color: transparent !important;
    }

    & .MuiSvgIcon-root {
      height: 0.8em !important;
      top: calc(50% - 0.4em) !important;
      width: 0.8em !important;
    }
  `,
  Label: styled('label')`
    color: #4a6067;
    font-family: 'Lucida Grande', sans-serif;
    font-size: 12px;
    font-weight: 400;
    margin-right: 6px;
    width: max-content;
  `,
  Helper: styled('span')`
    position: absolute;
    top: 0;
    left: -99999px;
  `,
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export { Dropdown };

export type { DropdownProps };
