import {
	getSubscriptions,
	InfoCard,
	useCompanyDateTime,
	useTzDateTime,
} from '@innovyze/stylovyze';
import {
	selectAssetDetail,
	selectAssetDetailIsWaiting,
} from '@Selectors/assetDetail.selectors';

import { AssetType } from '@Types/map.types';
import ObservationNotes from '@Components/ObservationNotes';
import React from 'react';
import { addAssetDetailNote } from '@Actions/assetDetail.actions';
import { selectUserContext } from '@Selectors/user.selectors';
import { useDispatch } from 'react-redux';
import { useGlobalization } from '@Translations';
import { useStyle } from '../AssetDetails.styles';
import { viewerAccess } from '@innovyze/shared-utils';

export const DetailsNotes = ({
	assetId,
	assetType,
	keepHeightWhenWaiting,
	bodyHeight,
	waitingHeight,
}: {
	assetId: string;
	assetType: string;
	keepHeightWhenWaiting: boolean;
	bodyHeight?: string;
	waitingHeight?: string;
}): JSX.Element => {
	const userContext = selectUserContext();
	const { t } = useGlobalization();
	const dispatch = useDispatch();
	const formatDateUTC = useTzDateTime({ timeZone: 'UTC' }).formatDate;

	const subscriptions = getSubscriptions();
	const hasViewerAccess = viewerAccess(subscriptions);

	const assetDetailStoreState = selectAssetDetail();
	const notes = assetDetailStoreState?.notes;
	const isLoading = selectAssetDetailIsWaiting();
	const classNames = useStyle();

	const onSubmitNote = (newNotes: string, userInitial: string) => {
		dispatch(
			addAssetDetailNote({
				assetDetailParams: {
					assetId: assetId,
					assetType: assetType,
					formatDateUTC: formatDateUTC,
					forNotes: true,
				},
				addNoteParams: {
					note: newNotes,
					userName: userContext.name,
					userInitial: userInitial,
					dateTime: new Date().toISOString(),
				},
			}),
		);
	};

	return (
		<InfoCard
			title={t('Notes')}
			applyBodyPadding={isLoading}
			fullHeight
			className={classNames.fullHeight}>
			<ObservationNotes
				Notes={notes ?? []}
				onSubmit={onSubmitNote}
				readOnly={hasViewerAccess}
				isWaiting={isLoading}
				keepHeightWhenWaiting={keepHeightWhenWaiting}
				waitingHeight={waitingHeight}
				bodyHeight={bodyHeight}
				entryHeight={hasViewerAccess ? '0px' : '90px'}
			/>
		</InfoCard>
	);
};
