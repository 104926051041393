import { Tooltip } from '@mui/material';
import React, { useCallback, useRef, useState } from 'react';
import { useEventListener } from '../../hooks';
import {
	CONTROL_CURRENT_TIME,
	CONTROL_FORWARDS,
	CONTROL_PAUSE,
	CONTROL_PLAY,
	CONTROL_REWIND,
	TIMELINE_CURRENT_TIME,
	TIMELINE_TIMINGS,
} from '../../map/timeline/constants';
import { Timings } from '../../map/timeline/types';
import {
	dispatchTargetedEvent,
	formatDateTime as formatDate,
	formatTime,
} from '../../map/utils';

import AnimationControlWrapper, {
	SliderWrapper,
	StyledSlider,
	Rewind30,
	FastForward30,
	Play,
	Pause,
	CurrentTime,
	AnimationButton,
} from './AnimationControl.styles';

export interface AnimationControlProps {
	/** id used to link the animation control with the map */
	id: string;
	/** class name */
	className?: string;
	/** date format */
	dateFormat?: string;
	/** 12 hour */
	hourCycle12?: boolean;
}

interface ValueLabelProps {
	children: React.ReactElement;
	open: boolean;
	value: number;
}

const ValueLabelComponent = ({
	children,
	open,
	value,
}: ValueLabelProps): JSX.Element => {
	return (
		<Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
			{children}
		</Tooltip>
	);
};

const AnimationControl = ({
	id,
	className,
	dateFormat,
	hourCycle12,
}: AnimationControlProps): JSX.Element => {
	const animationControl = useRef<HTMLDivElement>(null);
	const [timings, setTimings] = useState<Timings>({});
	const [currentTime, setCurrentTime] = useState<number | undefined>();
	const [playing, setPlaying] = useState<boolean>(false);
	const [value, setValue] = useState<number>(0);

	const timingsChanged = useCallback(e => {
		const result = e.detail;
		setTimings(result);
	}, []);

	useEventListener(TIMELINE_TIMINGS, timingsChanged, animationControl);

	const currentTimeChanged = useCallback(e => {
		const result = e.detail;
		setCurrentTime(result?.currentTime);
		setValue(result?.currentTime);
		setPlaying(result?.isPlaying);
	}, []);

	useEventListener(
		TIMELINE_CURRENT_TIME,
		currentTimeChanged,
		animationControl,
	);

	const handleChange = (
		_event: unknown,
		newValue: number | number[],
	): void => {
		setValue(newValue as number);
		dispatchTargetedEvent(
			animationControl.current,
			CONTROL_CURRENT_TIME,
			newValue,
		);
	};

	const onTogglePlay = (isPlaying: boolean) => (): void => {
		dispatchTargetedEvent(
			animationControl.current,
			isPlaying ? CONTROL_PAUSE : CONTROL_PLAY,
		);
	};

	const onRewind = (): void => {
		dispatchTargetedEvent(animationControl.current, CONTROL_REWIND);
	};

	const onForwards = (): void => {
		dispatchTargetedEvent(animationControl.current, CONTROL_FORWARDS);
	};

	const valueLabelFormat = (sliderValue: number): string => {
		return formatTime(sliderValue, hourCycle12);
	};

	const marks = [
		{
			value: timings.start ?? 0,
			label: formatTime(timings.start, hourCycle12),
		},
		{
			value: timings.end ?? 50,
			label: formatTime(timings.end, hourCycle12),
		},
	];
	return (
		<AnimationControlWrapper
			id={id}
			ref={animationControl}
			className={className}>
			<SliderWrapper>
				<StyledSlider
					value={value}
					step={600000}
					min={timings.start ?? 0}
					max={timings.end ?? 50}
					marks={marks}
					onChange={handleChange}
					data-cy="time-slider"
					valueLabelDisplay="auto"
					valueLabelFormat={valueLabelFormat}
					components={{
						ValueLabel: ValueLabelComponent,
					}}
				/>
			</SliderWrapper>
			<AnimationButton
				title="Rewind 30 minutes"
				onClick={onRewind}
				data-cy="rewind">
				<Rewind30 />
			</AnimationButton>
			<AnimationButton
				title={playing ? 'Pause animation' : 'Play animation'}
				onClick={onTogglePlay(playing)}
				data-cy="play-toggle">
				{playing ? <Pause /> : <Play />}
			</AnimationButton>
			<AnimationButton
				title="Fast forward 30 minutes"
				onClick={onForwards}
				data-cy="forwards">
				<FastForward30 />
			</AnimationButton>
			<CurrentTime data-cy="current-time">
				{formatDate(currentTime, dateFormat)} -{' '}
				{formatTime(currentTime, hourCycle12)}
			</CurrentTime>
		</AnimationControlWrapper>
	);
};

export default AnimationControl;
