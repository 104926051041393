import React, { forwardRef, memo } from 'react';

import { CustomContentProps } from 'notistack';
import { StyledMaterialDesignContent } from './MessageSnackbarContent.styles';

const MessageSnackbarContent = forwardRef<HTMLDivElement, CustomContentProps>(
	function MessageSnackbarContent(props, forwardedRef) {
		const { message, ...other } = props;

		return (
			<StyledMaterialDesignContent
				{...other}
				message={message}
				ref={forwardedRef}
			/>
		);
	},
);

export default memo(MessageSnackbarContent);
