import {
	InspectionCard,
	InspectionId,
	SurveyDate,
	EventWrapper,
	ViewDetails,
} from './InspectionProperties.styles';
import {
	ItemWithLabel,
	iso8601DateToLocal,
	useSettings,
} from '@innovyze/stylovyze';
import { InspectionScoredAttributes } from '@Types/inspection.types';
import React from 'react';
import { useGlobalization } from '@Translations/useGlobalization';
import { extractValue } from '@Utils/helpers';
import { TFunc } from '@Translations/types';

export interface EventPropertiesProps {
	inspection: InspectionScoredAttributes;
	viewEvent?: (inpsectionAttributes: InspectionScoredAttributes) => void;
}

const translateStatus = (t: TFunc, status: string) => {
	switch (status) {
		case 'verified':
			return t('Active');
		case 'resolved':
			return t('Resolved');
		case 'closed':
			return t('Closed');
		default:
			return t('Active');
	}
};

export const EventProperties = ({
	inspection,
	viewEvent,
}: EventPropertiesProps): JSX.Element => {
	const { t } = useGlobalization();
	const { companySettings } = useSettings();

	const onEventClick = () => {
		if (viewEvent) viewEvent(inspection);
	};

	return (
		<EventWrapper data-cy="inspection-properties">
			<InspectionId viewDetails={!!viewEvent}>
				{inspection.eventId}
			</InspectionId>
			<SurveyDate viewDetails={!!viewEvent}>
				{iso8601DateToLocal(
					extractValue(inspection.completedDate) ?? '',
					companySettings.dateFormat,
				)}
			</SurveyDate>
			{viewEvent && (
				<ViewDetails size="small" onClick={onEventClick}>
					{t('View Details', {
						context: 'Inspection details view further details',
					})}
				</ViewDetails>
			)}
			<InspectionCard>
				<ItemWithLabel
					label={t('Event Type', {
						context: 'Inspection properties',
					})}
					value={inspection.eventTypeName}
				/>
				<ItemWithLabel
					label={t('Opened Date', {
						context: 'Inspection properties',
					})}
					value={iso8601DateToLocal(
						inspection.openedDate
							? extractValue(inspection.openedDate) ?? ''
							: '',
						companySettings.dateFormat,
					)}
				/>
				<ItemWithLabel
					label={t('Status', {
						context: 'Inspection properties',
					})}
					value={translateStatus(t, inspection.inspectionStatus)}
				/>
			</InspectionCard>
		</EventWrapper>
	);
};
