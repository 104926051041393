export type ValidGeometry =
	| GeoJSON.Point
	| GeoJSON.MultiPoint
	| GeoJSON.LineString
	| GeoJSON.MultiLineString
	| GeoJSON.Polygon
	| GeoJSON.MultiPolygon;

type LngLatPosition = [number, number];

export const extractLngLatPositions = (
	geometries: ValidGeometry[],
): LngLatPosition[] => {
	return geometries
		.flatMap(geometry => {
			switch (geometry.type) {
				case 'Point':
					return [geometry.coordinates];
				case 'MultiPoint':
				case 'LineString':
					return geometry.coordinates;
				case 'MultiLineString':
				case 'Polygon':
					return geometry.coordinates.flat(1);
				case 'MultiPolygon':
					return geometry.coordinates.flat(2);
				default:
					const exhaustiveCheck: never = geometry;
					throw new Error(
						`Unsupported geometry type: ${JSON.stringify(
							exhaustiveCheck,
						)}`,
					);
			}
		})
		.map(([lng, lat]) => [lng, lat]);
};
