import {
	AssetsSearchResponse,
	Bbox,
	CarmenGeoJson,
	GeocoderResult,
} from './types';

import CancellableFetch from '../services/CancellableFetch';
import SearchDataConverter from './SearchDataConverter';
import { getLanguageIso639 } from '@Translations/utils';

export default class SearchService {
	private _url: string;
	private _headers: [string, string][] | undefined;
	private _request: CancellableFetch | undefined;
	private _serviceId: string | undefined;

	constructor(url: string, token?: string, serviceId?: string) {
		this._url = url;
		this._headers = token
			? [['Authorization', `Bearer ${token}`]]
			: undefined;
		this._serviceId = serviceId;
	}

	async fetchData(
		query: string,
		lnglat?: string,
		bbox?: Bbox,
	): Promise<GeocoderResult[]> {
		this.cancelPendingDataRequests();
		const queryBbox = bbox?.join(',') ?? '';
		const url = this._url
			.replace(/{{query}}/, encodeURIComponent(query))
			.replace(/{{lnglat}}/, encodeURIComponent(lnglat ?? ''))
			.replace(/{{bbox}}/, encodeURIComponent(queryBbox))
			.replace(/{{language}}/, encodeURIComponent(getLanguageIso639()));
		this._request = new CancellableFetch({
			url,
			headers: this._headers,
		});
		const data = await this._request.getPage<
			CarmenGeoJson | AssetsSearchResponse
		>();
		const assetTypeFirst = query.match(/([a-zA-Z]+)#([\S+])/) !== null;
		const dataConverter = new SearchDataConverter(
			data,
			assetTypeFirst,
			this._serviceId,
		);
		return dataConverter.convert();
	}

	cancelPendingDataRequests(): void {
		if (this._request) {
			this._request.cancel();
			this._request = undefined;
		}
	}
}
