import {
	CollapseIcon,
	ExpandIcon,
	StyledTreeItem,
} from './LayersTreeView.styles';
import { ConfigLayerInternal, LayerToggle, LayerType } from 'src/map/types';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import {
	Visibility,
	convertVisibilityToHiddenLayers,
	getDefaultVisibility,
	updateVisibility,
} from './utils';

import LayerItem from './LayerItem';
import { MapHiddenLayers } from '@Map/layers/LayerManager';
import { TreeView } from '@mui/x-tree-view';
import { useGlobalization } from '@Translations/useGlobalization';

const getDisplayType = (
	type: LayerType | undefined,
	iconImage: string | undefined,
) => {
	if (type === 'line' || type === 'polygon') {
		return type;
	}

	if (iconImage) {
		return 'image';
	}

	if (type) {
		return 'circle';
	}

	return 'none';
};

export interface LayersProps {
	config: ConfigLayerInternal[];
	getVisibility: (idPath: string) => boolean;
	onToggleVisibility: (idPath: string) => (state: boolean) => void;
	tabIndex?: number;
	canToggle?: boolean;
}

const NestedTreeItems = ({
	config,
	getVisibility,
	onToggleVisibility,
	tabIndex,
	canToggle = true,
}: LayersProps): JSX.Element => {
	const { t } = useGlobalization();
	return (
		<>
			{config.map(
				(
					{
						layerName,
						layers,
						virtualLayers,
						color,
						outlineColor,
						fillOpacity,
						pattern,
						lineDashArray,
						type,
						iconImage,
						count,
						idPath,
						id,
					},
					i,
				) => {
					const label = (
						<LayerItem
							key={`layerItem-${i}`}
							onToggleVisibility={onToggleVisibility(idPath)}
							layerId={id}
							config={config[i]}
							tabIndex={tabIndex}
							layerOn={getVisibility(idPath)}
							color={color}
							outlineColor={outlineColor}
							fillOpacity={fillOpacity}
							pattern={pattern}
							lineDashArray={lineDashArray}
							displayType={getDisplayType(type, iconImage)}
							iconImage={iconImage}
							count={count}
							canToggle={canToggle}>
							{`${t(layerName)}`}
						</LayerItem>
					);

					const layersToDisplay = layers || virtualLayers;
					if (layersToDisplay?.length) {
						return (
							<StyledTreeItem
								key={id}
								nodeId={id}
								label={label}
								data-cy={`tree-item--${id}`}
								data-nested="true">
								<NestedTreeItems
									config={layersToDisplay}
									getVisibility={getVisibility}
									onToggleVisibility={onToggleVisibility}
									tabIndex={tabIndex}
									canToggle={!virtualLayers?.length}
								/>
							</StyledTreeItem>
						);
					}

					return (
						<StyledTreeItem
							key={id}
							nodeId={id}
							label={label}
							data-cy={`tree-item--${id}`}
						/>
					);
				},
			)}
		</>
	);
};

interface LayersTreeViewProps {
	config: LayersProps['config'];
	hiddenLayers?: MapHiddenLayers;
	onLayerToggle?: (args: LayerToggle) => void;
	tabIndex: number;
	onSelect?: (layerId: string, config?: ConfigLayerInternal) => void;
	selectedId?: string;
}

const LayersTreeView = ({
	config,
	hiddenLayers,
	onLayerToggle,
	tabIndex,
	onSelect,
	selectedId,
}: LayersTreeViewProps): JSX.Element => {
	const [layerConfig, setLayerConfig] = useState(config);
	const [visibility, setVisibility] = useState<Visibility>(
		config ? getDefaultVisibility(config, hiddenLayers) : {},
	);

	const getVisibility = useCallback((idPath: string) => visibility[idPath], [
		visibility,
	]);

	const onToggleVisbility: LayersProps['onToggleVisibility'] = useCallback(
		idPath => state => {
			const updatedVisibility = updateVisibility(
				idPath,
				visibility,
				state,
			);
			setVisibility(updatedVisibility);
			const updatedHiddenLayers = convertVisibilityToHiddenLayers(
				updatedVisibility,
			);
			onLayerToggle?.({
				layerPath: idPath,
				state,
				hiddenLayers: updatedHiddenLayers,
			});
		},
		[visibility, onLayerToggle],
	);

	const onNodeSelect = (_: ChangeEvent<unknown>, layerId: string) => {
		onSelect?.(layerId);
	};

	useEffect(() => {
		setLayerConfig(config);
		const defaultVisibility = getDefaultVisibility(config, hiddenLayers);
		setVisibility(defaultVisibility);
	}, [config, hiddenLayers]);

	return (
		<TreeView
			defaultCollapseIcon={<CollapseIcon />}
			defaultExpandIcon={<ExpandIcon />}
			onNodeSelect={onNodeSelect}
			selected={selectedId}>
			<NestedTreeItems
				config={layerConfig}
				getVisibility={getVisibility}
				onToggleVisibility={onToggleVisbility}
				tabIndex={tabIndex}
			/>
		</TreeView>
	);
};

export default React.memo(LayersTreeView);
