import { SvgIcon } from '@mui/material';
import React from 'react';

const HistoryMenuIcon = () => {
	return (
		<SvgIcon>
			<path
				d="M7.29984 8.42499C8.0165 5.74999 8.58317 3.99999 9.83317 3.99999C11.0832 3.99999 11.6498 5.74999 12.3665 8.42499C13.1665 11.4333 14.0832 14.8333 17.3332 14.8333V13.1667C15.4998 13.1667 14.8332 11.1167 13.9748 7.99999C13.2332 5.21666 12.4582 2.33332 9.83317 2.33332C7.20817 2.33332 6.43317 5.21666 5.6915 7.99999C4.85817 11.1167 4.1665 13.1667 2.33317 13.1667V0.666656H0.666504V17.3333H17.3332V15.6667H2.33317V14.8333C5.58317 14.8333 6.49984 11.4333 7.29984 8.42499Z"
				fill="#768D95"
			/>
		</SvgIcon>
	);
};
export default HistoryMenuIcon;
