/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  dateFormat,
  numberFormat,
  Options,
  SeriesGaugeOptions,
  TitleOptions,
  YAxisOptions,
  YAxisPlotBandsOptions,
} from 'highcharts';

import {
  GaugeChartData,
  GaugeChartSeries,
  GaugeChartThresholds,
  GaugeHighchartOptions,
} from '../types/gaugeChart.types';

import { Readings } from '../types/sensor.types';
import { DataPoint } from '../types/dataPoints.types';
import { RawMeasureDataMap } from '../types/measureData.types';
import { defaultExportingOptions } from './defaultChartConfigs';

export const gaugeChartFont =
  'Roboto, Helvetica Neue, San Francisco, Segoe UI, sans-serif';

export const gaugeChartPalette = {
  text: '#3CA6C8',
  label: '#768D95',
  threshold: '#99DDF0',
  overflowedThreshold: '#AFEEFE',
  plotBand: '#D2DEE3',
  success: '#3CA6C8',
  error: '#AA0000',
  background: '#FFFFFF',
};

export const gaugeChartDefaultHighchartOptions: Options = {
  credits: { enabled: false },
  tooltip: { enabled: false },
  chart: {
    type: 'gauge',
    height: null,
    backgroundColor: gaugeChartPalette.background,
    plotBackgroundColor: gaugeChartPalette.background,
  },
  exporting: defaultExportingOptions,
  pane: {
    startAngle: -150,
    endAngle: 150,
    background: [
      {
        borderWidth: 0,
        borderColor: gaugeChartPalette.plotBand,
        backgroundColor: gaugeChartPalette.plotBand,
        innerRadius: '60%',
        outerRadius: '100%',
        shape: 'arc',
      },
    ],
  },
  yAxis: {
    minorTickInterval: undefined,
    tickWidth: 0,
    tickLength: 0,
    labels: {
      distance: 25,
      style: {
        color: gaugeChartPalette.label,
      },
    },
  },
  plotOptions: {
    gauge: {
      dial: {
        radius: '90%',
        baseWidth: 4,
        topWidth: 4,
      },
    },
  },
};

export const generateGaugeHighchartOptions = (
  series: GaugeChartSeries
): GaugeHighchartOptions => {
  const data = processGaugeSeriesToGaugeData(series);

  const statusColor = data?.isWithinThresholds
    ? gaugeChartPalette.success
    : gaugeChartPalette.error;

  const seriesOptions: SeriesGaugeOptions = {
    type: 'gauge',
    data: data ? [data.latest[1]] : [],
    dial: {
      backgroundColor: statusColor,
    },
    pivot: {
      backgroundColor: statusColor,
    },
    dataLabels: {
      enabled: false,
    },
  };

  const titleOptions: TitleOptions = {
    useHTML: true,
    text: [
      `<div style="${[
        `font-family: ${gaugeChartFont}`,
        'font-size: 1rem',
        'text-align: center',
        'line-height: 1',
        'margin-bottom: 20px',
      ].join(';')}" />`,
      `<span style="${[
        `color: ${statusColor}`,
        'display: block',
        'font-size: 2em',
        'margin-bottom: 0.2em',
      ].join(';')}">${parseMeasure(
        data?.latest[1],
        series?.sensor.unit
      )}</span>`,
      `<span style="${[
        `color: ${gaugeChartPalette.label}`,
        'display: block',
        'font-size: 1em',
      ].join(';')}">${parseTimestamp(data?.latest?.[0])}</span>`,
      '</div>',
    ].join(''),
  };

  const yAxisOption: YAxisOptions = {
    min: data?.min,
    max: data?.max,
    plotBands: [],
  };

  if (
    series.thresholds !== undefined &&
    series.thresholds.lower !== undefined &&
    series.thresholds.upper !== undefined
  ) {
    const { min, max } = yAxisOption;
    const { lower, upper } = series.thresholds;
    const overflowsMin = lower < min!;
    const overflowsMax = upper > max!;

    let plotBands: YAxisPlotBandsOptions[] = [];

    if (overflowsMin && overflowsMax) {
      yAxisOption.min = lower;
      yAxisOption.max = upper;

      plotBands = [
        {
          from: lower!,
          to: min!,
          color: gaugeChartPalette.overflowedThreshold,
        },
        {
          from: min!,
          to: max!,
          color: gaugeChartPalette.threshold,
        },
        {
          from: max!,
          to: upper!,
          color: gaugeChartPalette.overflowedThreshold,
        },
      ];
    } else if (overflowsMin) {
      yAxisOption.min = lower;

      plotBands = [
        {
          from: lower!,
          to: min!,
          color: gaugeChartPalette.overflowedThreshold,
        },
        {
          from: min!,
          to: upper!,
          color: gaugeChartPalette.threshold,
        },
      ];
    } else if (overflowsMax) {
      yAxisOption.max = upper;

      plotBands = [
        {
          from: lower!,
          to: max!,
          color: gaugeChartPalette.threshold,
        },
        {
          from: max!,
          to: upper!,
          color: gaugeChartPalette.overflowedThreshold,
        },
      ];
    } else {
      plotBands = [
        {
          from: lower!,
          to: upper!,
          color: gaugeChartPalette.overflowedThreshold,
        },
      ];
    }

    yAxisOption.plotBands = plotBands.map((pb) => ({
      ...pb,
      thickness: '40%',
    }));
  }

  return {
    series: [seriesOptions],
    title: titleOptions,
    yAxis: yAxisOption,
  };
};

const parseMeasure = (measure?: number, unit?: string): string => {
  if (measure === undefined || measure === null) return '';
  const formattedMeasure = numberFormat(measure, 2);
  if (unit === undefined || unit === null) return `${formattedMeasure}`;
  return `${formattedMeasure} ${unit}`;
};

const parseTimestamp = (timestamp?: number): string => {
  if (!timestamp) return '';
  return dateFormat('%m/%d/%Y %H:%M:%S', timestamp);
};

const checkIfMeasureIsWithinThreasholds = (
  measure?: number,
  thresholds?: GaugeChartThresholds
): boolean => {
  if (
    !thresholds ||
    thresholds.lower === undefined ||
    thresholds.upper === undefined
  )
    return true;
  if (!measure) return false;

  const { lower, upper } = thresholds;
  const isWithinThresholds = measure >= lower && measure <= upper;

  return isWithinThresholds;
};

const processGaugeSeriesToGaugeData = (
  series: GaugeChartSeries
): GaugeChartData | undefined => {
  const { data, thresholds } = series;
  const reading = series.reading || Readings.Close;

  const readingIndex: number = RawMeasureDataMap[reading];

  const dataPoints: DataPoint[] = data.map((rawMeasureData) => [
    rawMeasureData[0],
    rawMeasureData[readingIndex],
  ]);

  if (dataPoints.length === 0) {
    return undefined;
  }

  const latestDataPoint: DataPoint = dataPoints[dataPoints.length - 1];

  let minMeasure: number | undefined;
  let maxMeasure: number | undefined;

  [minMeasure, maxMeasure] = dataPoints.reduce<[number, number]>(
    ([min, max], [, measure]) => {
      const newMin = measure < min ? measure : min;
      const newMax = measure > max ? measure : max;
      return [newMin, newMax];
    },
    [dataPoints[0][1], dataPoints[0][1]]
  );

  /**
   * Generates a synthetic range when all data points have the same value
   * */
  if (minMeasure === maxMeasure) {
    const syntheticRangeFactor = 0.5;
    minMeasure = minMeasure - minMeasure * syntheticRangeFactor;
    maxMeasure = maxMeasure + maxMeasure * syntheticRangeFactor;
  }

  return {
    points: dataPoints,
    latest: latestDataPoint,
    min: minMeasure,
    max: maxMeasure,
    isWithinThresholds: checkIfMeasureIsWithinThreasholds(
      latestDataPoint[1],
      thresholds
    ),
  };
};
