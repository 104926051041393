import {
	Animated,
	AnimationProps,
	DataServiceType,
	GeoJsonDataType,
	IconSet,
	KVP,
	PropertyLookup,
	PropsUrl,
	ServiceLayer,
	SourceType,
} from './types';
import { CBAttributes, CBResponseValues } from '@Map/types';
import {
	LOOKUP_COMPLETE,
	SERVICE_ERROR,
	SERVICE_LOADED,
	SERVICE_LOADING,
	SERVICE_RELOADED,
} from './constants';

import AttributesService from './AttributesService';
import CancellableFetch from './CancellableFetch';
import { DataServiceRequestError } from './DataServiceRequestError';
import Events from '../events/Events';
import { LngLatBounds } from 'mapbox-gl';
import Lookup from './Lookup';
import MapboxDataSource from './MapboxDataSource';
import PaginatedFetch from './PaginatedFetch';
import { Timings } from '../timeline/types';
import { extractErrorContext } from '@Map/utils';
import logger from '../logger/Logger';

export default class DataService<TDataType> extends Events {
	static ERROR_MESSAGE = 'Failed to load data for {0}';
	static defaultAnimateProps: AnimationProps = {
		inView: 'dateOnset',
		outView: 'dateOffset',
	};
	protected _passOnEvents = { LOOKUP_COMPLETE };
	private _data: TDataType | null = null;
	private _valid = false;
	private _type: SourceType;
	private _id = '';
	private _displayName = '';
	private _requestPending = false;
	private _requestSent: Date | null = null;
	private _requestError = false;
	private _poll: number | undefined = undefined;
	private _endpoint: string | null = null;
	protected _requestHeaders: [string, string][] | undefined;
	protected _credentials: RequestInit['credentials'];
	protected _cluster: boolean | undefined;
	protected _dataSources: MapboxDataSource[] | undefined;
	protected _propsUrl: PropsUrl;
	protected _propertyLookUp: PropertyLookup | undefined;
	protected _zIndex: number | undefined;
	protected _propRequests: CancellableFetch[] = [];
	protected _propRequestHeaders: [string, string][] | undefined;
	protected _lookups: { [key: string]: Lookup } = {};
	protected _pollingInterval: number | undefined;
	protected _request: CancellableFetch | PaginatedFetch | undefined;
	protected _animationSupported = false;
	protected _animate: Animated = false;
	protected _attributesService: AttributesService | undefined;
	protected _ignoreExtents = false;

	constructor({
		url,
		data,
		type,
		id,
		displayName,
		cluster,
		propsUrl,
		propertyLookup,
		zIndex = 0,
		pollingInterval,
		animate = false,
		attributesUrl,
		attributeForRange,
		token,
		ignoreExtents = false,
	}: DataServiceType<TDataType>) {
		super();
		this._type = type;
		this._id = id;
		this._displayName = displayName;
		this._cluster = cluster;
		this._propsUrl = propsUrl;
		this._propertyLookUp = propertyLookup;
		this._zIndex = zIndex;
		this._pollingInterval = pollingInterval;
		this._animate = animate;
		this._attributesService = attributesUrl
			? new AttributesService(attributesUrl, token, attributeForRange)
			: undefined;
		this._ignoreExtents = ignoreExtents;

		if (this._animate && !this._animationSupported) {
			logger.warn(
				`Animation not supported for type "${this._type}" data service`,
				{
					messageId: 'map_metric_animationSupport',
					dataSourceId: this._id,
					dataSourceType: this._type,
				},
			);
		}

		if (url) {
			this._valid = this.validUrl(url);
			this._endpoint = this._valid ? url : null;
		} else if (data) {
			this._valid = this.validData(data);
			this.data = this._valid ? data : null;
		}
	}

	get errorDescription(): string {
		return DataService.ERROR_MESSAGE.replace(
			'{0}',
			this._displayName || this.id,
		);
	}

	get hasAttributeService(): boolean {
		return !!this._attributesService;
	}

	get isRangeAttributeService(): boolean {
		return !!this._attributesService?.attributeForRange;
	}

	get attributeForRange(): string | undefined {
		return this._attributesService?.attributeForRange;
	}

	get endpoint(): string | null {
		return this._endpoint;
	}

	get dataEndpoint(): string | null {
		return this._endpoint;
	}

	async fetchData(): Promise<TDataType> {
		this.cancelPendingDataRequests();
		this._request = new CancellableFetch({
			url: this.dataEndpoint as string,
			headers: this._requestHeaders,
			credentials: this._credentials,
		});
		return await this._request.getPage<TDataType>({
			responseParser: this.parseResponse,
		});
	}

	async loadData(reload = false): Promise<void> {
		if (this._requestPending) return;
		if ((!this._data || reload) && this.dataEndpoint) {
			this._requestPending = true;
			this._requestSent = new Date();
			this._requestError = false;
			if (!reload) this.fire(SERVICE_LOADING);
			// create initial layers to show while loading
			this.createDataSources({} as GeoJsonDataType);

			const sharedContext = {
				messageId: 'map_metric_loadData',
				dataSourceId: this._id,
				endpoint: this.dataEndpoint,
			};

			let succeeeded = false;
			try {
				const data = await logger.logAsync<TDataType>(
					'Data services request duration',
					sharedContext,
					this.fetchData.bind(this),
				);
				if (!this.validData(data)) {
					throw new DataServiceRequestError(
						'The API call did not return valid data.',
					);
				}

				this.data = data;
				succeeeded = true;
			} catch (e) {
				const dataServiceRequestError = e as DataServiceRequestError;
				this._requestError = true;

				const context = {
					...sharedContext,
					messageId: 'map_exception_loadData',
					error: this.errorDescription,
					...extractErrorContext(dataServiceRequestError),
				};

				// prevent the service error message firing if the fetch request aborted
				if (dataServiceRequestError.name !== 'AbortError') {
					logger.error('Data services request error', context);
					// error is only critical if http status 400 and above excluding 404
					const criticalError =
						!dataServiceRequestError.response?.status ||
						(dataServiceRequestError.response?.status >= 400 &&
							dataServiceRequestError.response?.status !== 404);
					this.fire(SERVICE_ERROR, {
						serviceId: this.id,
						criticalError,
					});
				} else {
					logger.info('Data services request aborted', context);
				}
			} finally {
				this._requestPending = false;

				if (succeeeded) {
					this._serviceLoaded(reload);
				}
			}
		} else if (this._data) {
			this._serviceLoaded(reload);
		}
	}

	private _serviceLoaded(reload = false): void {
		const animated = this._animationSupported && this._animate;
		const timings = animated ? this.animationTimings() : undefined;
		const event = reload ? SERVICE_RELOADED : SERVICE_LOADED;
		this.fire(event, {
			serviceId: this.id,
			animated,
			timings,
			iconSet: this.iconSet,
		});
	}

	pollForData(): void {
		if (!this._pollingInterval || this._poll) return;
		this._poll = window.setInterval(() => {
			this.loadData(true);
		}, this._pollingInterval);
	}

	cancelPolling(): void {
		if (!this._poll) return;
		clearInterval(this._poll);
		this._poll = undefined;
	}

	set data(data: TDataType | null) {
		this._data = this.remapProperties(
			this.reformStructure(data),
		) as TDataType;
		this.createDataSources(this._data);
	}

	get data(): TDataType | null {
		return this._data;
	}

	get dataSources(): MapboxDataSource[] | undefined {
		return this._dataSources;
	}

	get requiresCredentials(): boolean {
		return false;
	}

	get originalUrl(): string | null {
		return this._endpoint;
	}

	get baseUrl(): string | null {
		return this._endpoint;
	}

	get layers(): ServiceLayer[] | undefined {
		return [] as ServiceLayer[];
	}

	isValid(): boolean {
		return this._valid;
	}

	get type(): SourceType {
		return this._type;
	}

	get id(): string {
		return this._id;
	}

	get displayName(): string {
		return this._displayName;
	}

	get loaded(): boolean {
		return (
			!this._requestPending &&
			((this.endpoint && !!this._requestSent && !this._requestError) ||
				(!this.endpoint && !!this.data))
		);
	}

	get errored(): boolean {
		return this._requestError;
	}

	get propsUrl(): PropsUrl {
		return this._propsUrl;
	}

	get iconSet(): IconSet {
		return {};
	}

	get cluster(): boolean {
		return this._cluster ?? false;
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	get bounds(): LngLatBounds | undefined {
		return;
	}

	get ignoreExtents(): boolean {
		return this._ignoreExtents;
	}

	hasDataSource(sourceId: string): boolean {
		return !!this.dataSources?.find(({ id }) => id === sourceId);
	}

	validUrl(url: string): boolean {
		try {
			new URL(url, window.location.href);
			return true;
		} catch (_) {
			return false;
		}
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	validData(_: TDataType): boolean {
		return false;
	}

	reformStructure(data: TDataType | null): TDataType | null {
		return data;
	}

	remapProperties(data: TDataType | null): TDataType | null {
		return data;
	}

	parseResponse(data: unknown): TDataType | null {
		return data as TDataType;
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	createDataSources(_: unknown): void {
		this._dataSources = [];
	}

	getBaseUrl(assetType: string): string | undefined {
		if (!this.propsUrl) return;
		if (typeof this.propsUrl === 'string') return this.propsUrl;
		return this.propsUrl[assetType] || this.propsUrl.any;
	}

	cancelPendingDataRequests(): void {
		if (this._request) {
			this._request.cancel();
			this._request = undefined;
		}
	}

	cancelPendingAssetRequests(): void {
		this._propRequests.forEach(request => request.cancel());
		this._propRequests = [];
	}

	clearLookups(): void {
		Object.values(this._lookups).forEach(lookup => lookup.delete());
		this._lookups = {};
	}

	delete(): void {
		this.cancelPolling();
		this.cancelPendingAssetRequests();
		this.cancelPendingDataRequests();
		this.clearLookups();
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	fetchAssetProperties(_ids: string[]): void {
		// define in each data service
	}

	animationTimings(): Timings | undefined {
		return;
	}

	lookupProperties(): void {
		if (this._propertyLookUp) {
			Object.entries(this._propertyLookUp).forEach(([key, value]) => {
				if (this._lookups[key]) return;
				const lookup = new Lookup(key, value, this._requestHeaders);
				lookup.on(LOOKUP_COMPLETE, () => {
					this.fire(LOOKUP_COMPLETE, {
						serviceId: this.id,
						key: lookup.key,
					});
				});
				this._lookups[key] = lookup;
			});
		}
	}

	addLookup(key: string, urlOrData: string | KVP): void {
		if (!this._propertyLookUp) {
			this._propertyLookUp = {};
		}
		this._propertyLookUp[key] = urlOrData;
	}

	getLookup(key: string): Lookup | undefined {
		return this._lookups[key];
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	getTileSource(_regex: RegExp): string | undefined {
		return;
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	getTileLayerName(_regex: RegExp): string | undefined {
		return;
	}

	async getAttributesList(
		layerId: string,
	): Promise<CBAttributes[] | undefined> {
		return await this._attributesService?.getAttributesList(layerId);
	}

	async getAttributeValuesList(
		layerId: string,
		attributeId: string,
		limit: number,
	): Promise<CBResponseValues | undefined> {
		this.addAttributeLookup(layerId, attributeId);
		return await this._attributesService?.getAttributeValuesList(
			layerId,
			attributeId,
			limit,
		);
	}

	addAttributeLookup(layerId: string, attributeId: string): void {
		const endpoint = this._attributesService?.getAttributeLookupEndpoint(
			layerId,
			attributeId,
		);
		if (endpoint) {
			this.addLookup(`${layerId}~${attributeId}`, endpoint);
			this.lookupProperties();
		}
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	setTimelineCurrentTime(currentTime: number): void {
		return;
	}

	update({
		url,
		data,
		type,
		displayName,
		cluster,
		propertyLookup,
		pollingInterval,
		animate = false,
		attributesUrl,
		attributeForRange,
		token,
		ignoreExtents = false,
	}: DataServiceType<TDataType>): boolean {
		let changed = false;
		if (this.type !== type) {
			throw new Error(
				`Cannot update data service of type ${this.type} to type ${type}`,
			);
		}
		if (displayName !== this._displayName) {
			changed = true;
			this._displayName = displayName;
		}
		if (cluster !== this._cluster) {
			changed = true;
			this._cluster = cluster;
		}
		if (propertyLookup) {
			const differences = this._updatePropertyLookups(propertyLookup);
			this.lookupProperties();
			changed = differences ? true : changed;
		}
		if (pollingInterval !== this._pollingInterval) {
			this._pollingInterval = pollingInterval;
			this.cancelPolling();
			this.pollForData();
			changed = true;
		}
		if (animate !== this._animate) {
			this._animate = animate;
			changed = true;
		}
		if (
			attributesUrl &&
			(attributesUrl !== this._attributesService?.url ||
				attributeForRange !==
					this._attributesService?.attributeForRange)
		) {
			this._attributesService = new AttributesService(
				attributesUrl,
				token,
				attributeForRange,
			);
			changed = true;
		}
		if (ignoreExtents !== this._ignoreExtents) {
			changed = true;
			this._ignoreExtents = ignoreExtents;
		}
		if (url) {
			if (url !== this.originalUrl) {
				this._valid = this.validUrl(url);
				this._endpoint = this._valid ? url : null;
				this.data = null;
				changed = true;
			}
		} else if (data) {
			if (JSON.stringify(data) !== JSON.stringify(this.data)) {
				this._valid = this.validData(data);
				this.data = this._valid ? data : null;
				changed = true;
			}
		}
		return changed;
	}

	private _updatePropertyLookups(propertyLookup: PropertyLookup): boolean {
		let changed = false;
		Object.entries(propertyLookup).forEach(([key, value]) => {
			if (!this._propertyLookUp?.[key]) {
				this.addLookup(key, value);
				changed = true;
			} else if (this._propertyLookUp?.[key] !== value) {
				this._lookups[key].delete();
				delete this._lookups[key];
				this.addLookup(key, value);
				changed = true;
			}
		});
		return changed;
	}
}
