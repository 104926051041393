import {
  HistoricalSeries,
  RawMeasureDataMap,
  DEFAULT_CHART_COLORS,
} from '../../types';
import { sortByTimeAscending } from '../sortByTimeAscending';

export const processSensorChartData = (
  series: HistoricalSeries
): Highcharts.SeriesOptionsType => {
  const dataBuffer = [...series.data];
  const data = dataBuffer
    .sort(sortByTimeAscending)
    .slice(-288) // Number of 15 min intervals in a day
    .map((datum) => [datum[0], datum[RawMeasureDataMap.Average]]);
  return {
    type: 'line',
    data,
    name: series.alias,
    color: DEFAULT_CHART_COLORS[0],
    findNearestPointBy: 'x',
    marker: {
      enabled: true,
      radius: 4,
    },
  };
};
