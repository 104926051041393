import React, { ReactElement, forwardRef, useMemo } from 'react';
import {
  ComparisonChartConnected,
  GlobalPassthroughs,
  Resolutions,
} from '../../../types';

import { ChartingErrorBoundary } from '../../atoms';
import { ComparisonChart } from '../../molecules';
import { ComparisonSeries } from '../../../core/types/comparison.types';
import useSensorData from '../../../core/hooks/useSensorData';
import type { StackableChartRef } from '../../../core/components/StackableChart';
import { isFeatureEnabled } from '@innovyze/stylovyze';
import {
  InsightComparisonChart,
  InsightComparisonChartProps,
} from '../../../_next/presets/insight-comparison-chart';
import { TimeRangeSelection } from '../../../_next/core/_insight-chart';

export type ConnectedComparisonChartProps = ComparisonChartConnected &
  GlobalPassthroughs & {
    timeRangeSelection?: TimeRangeSelection;
  };

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Version Switch
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
const ConnectedComparisonChart = (
  props: ConnectedComparisonChartProps,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref?: any
): ReactElement => {
  const isV2Enabled = isFeatureEnabled('info-360-analytics-hp2-charts');
  if (!isV2Enabled) {
    return <ComparisonChartV1 {...props} ref={ref} />;
  }
  return <ComparisonChartV2 {...props} ref={ref} />;
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * New Chart
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
const ComparisonChartV2 = React.forwardRef<
  { chart: Highcharts.Chart | undefined },
  ConnectedComparisonChartProps
>((props, ref): React.ReactElement => {
  const series = useMemo<InsightComparisonChartProps['series']>(() => {
    const series =
      (props.series?.map((series) => {
        return {
          name: series.alias,
          sensorId: series.id,
          reading: props.reading,
          resolution: series.resolution ?? 'RAW',
          hidden: props.hiddenSeries?.includes(0),
          type: props.displayOptions?.horizontalBar ? 'bar' : 'column',
          customData: series.customData,
        };
      }) as InsightComparisonChartProps['series']) || [];
    const reference =
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      (props.referenceSeries?.map((series, seriesIndex) => {
        return {
          sensorId: `Static Value ${series.value}`,
          analytic: props.reading,
          hidden: props.hiddenSeries?.includes(seriesIndex + 1),
          type: 'line',
          referece: series.value,
          color: series.color,
          style: series.style,
        };
      }) as InsightComparisonChartProps['series']) || [];
    return [...series, ...reference];
  }, [
    props.reading,
    props.hiddenSeries?.join(''),
    props.referenceSeries?.map((s) => `${s.color}${s.style}${s.value}`).join(),
    props.displayOptions?.horizontalBar,
  ]);

  return (
    <ChartingErrorBoundary chartProps={props}>
      <InsightComparisonChart
        ref={ref}
        series={series}
        xAxis={{ enableGridlines: props.displayOptions?.showXGrid }}
        yAxis={{ enableGridlines: props.displayOptions?.showYGrid }}
        timeRangeSelection={props.timeRangeSelection}
        events={{
          series: {
            onVisibilityChange: (index, type) => {
              const _hiddenSeries = props.hiddenSeries
                ? [...props.hiddenSeries]
                : [];

              const newHiddenSeries =
                type === 'hide'
                  ? [..._hiddenSeries, index]
                  : _hiddenSeries.filter((series) => series !== index);

              props.onHiddenSeriesChange?.(newHiddenSeries);
            },
          },
        }}
        selectedTheme={props.selectedTheme}
      />
    </ChartingErrorBoundary>
  );
});

ComparisonChartV2.displayName = 'ComparisonChartV2';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Old Chart
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const ComparisonChartV1 = React.forwardRef<
  StackableChartRef,
  ConnectedComparisonChartProps
>((props, ref?): JSX.Element | null => {
  const comparisonSeries = useMemo<ComparisonSeries[]>(() => {
    const series: ComparisonSeries[] = [];
    props.series?.forEach((comparisonSeries) => {
      const { id: sensorId, resolution, ...restSeries } = comparisonSeries;

      series.push({
        ...restSeries,
        func: props.reading,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dataSource: {
          sensorId,
          resolution:
            props.groupBy === 'Month' ? Resolutions.Monthly : resolution,
        },
      });
    });

    return series;
  }, [props.series, props.reading, props.groupBy]);

  const dataSources = useMemo(() => {
    return comparisonSeries.map((series) => series.dataSource);
  }, [comparisonSeries]);

  const sensorDataRecords = useSensorData(
    dataSources,
    props.dataRangeSelection
  );

  return (
    <ChartingErrorBoundary chartProps={props}>
      <ComparisonChart
        data={sensorDataRecords}
        series={comparisonSeries}
        referenceSeries={props.referenceSeries}
        displayOptions={props.displayOptions}
        groupBy={props.groupBy}
        hiddenSeries={props.hiddenSeries}
        onHiddenSeriesChange={props.onHiddenSeriesChange}
        dataRangeSelection={props.dataRangeSelection}
        onClickPoint={props.onClickPoint}
        ref={ref}
      />
    </ChartingErrorBoundary>
  );
});

ComparisonChartV1.displayName = 'ComparisonChartV1';

export default forwardRef<StackableChartRef, ConnectedComparisonChartProps>(
  ConnectedComparisonChart
);
