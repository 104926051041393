import ConfigAdaptorLayer from './ConfigAdaptorLayer';
import { partition } from '@Map/utils';

interface VirtualLayerMap {
	[key: string]: ConfigAdaptorLayer;
}

interface LayersToVirtualMap {
	[key: string]: string[];
}

export default class VirtualLayerManager {
	private _virtualLayers: VirtualLayerMap = {};
	private _layersToVirtual: LayersToVirtualMap = {};

	add(
		layerId: string,
		virtualLayerId: string,
		virtualLayer: ConfigAdaptorLayer,
	): void {
		this._virtualLayers[virtualLayerId] = virtualLayer;
		this._layersToVirtual[layerId] = [
			...(this._layersToVirtual[layerId] ?? []),
			virtualLayerId,
		];
	}

	addMany(layerId: string, virtualLayers: ConfigAdaptorLayer[]): void {
		virtualLayers.forEach(layer => this.add(layerId, layer.id, layer));
	}

	replaceMany(layerId: string, virtualLayers: ConfigAdaptorLayer[]): void {
		if (!this._layersToVirtual[layerId]?.length) {
			this.addMany(layerId, virtualLayers);
			return;
		}
		const [existingLayers, newLayers] = partition(
			virtualLayers,
			layer =>
				this._layersToVirtual[layerId]?.includes(layer.id) ?? false,
		);
		existingLayers.forEach(layer => {
			const virtualLayer = this.getById(layer.id);
			virtualLayer.edits = layer.layerConfig;
		});
		const virtualLayerIds = virtualLayers.map(layer => layer.id);
		this.deleteMany(layerId, virtualLayerIds);
		this.addMany(layerId, newLayers);
	}

	deleteMany(layerId: string, virtualLayerIds?: string[]): void {
		this._layersToVirtual[layerId]?.forEach(id => {
			if (!virtualLayerIds?.includes(id)) {
				this.remove(layerId, id);
			}
		});
	}

	deleteAll(): void {
		this._layersToVirtual = {};
		this._layersToVirtual = {};
	}

	remove(layerId: string, virtualLayerId: string): void {
		delete this._virtualLayers[virtualLayerId];
		this._layersToVirtual[layerId] = this._layersToVirtual[layerId].filter(
			id => id !== virtualLayerId,
		);
	}

	getById(virtualLayerId: string): ConfigAdaptorLayer {
		return this._virtualLayers[virtualLayerId];
	}

	getByLayerId(layerId: string): ConfigAdaptorLayer[] {
		return (
			this._layersToVirtual[layerId]?.map(id => this.getById(id)) ?? []
		);
	}
}
