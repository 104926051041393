import { FeatureItem, ItemDetailsSelector } from '@Utils/types';
import React, { useEffect, useMemo, useReducer, useState } from 'react';

import { Action } from 'redux';
import { ActionFunction1 } from 'redux-actions';
import { BackLink } from './Tab.styles';
import { GenericProperties } from '@Components/GenericProperties';
import { InfoCard } from '@innovyze/stylovyze';
import { TabListItem } from './TabListItem';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { useItemDetails } from '@Hooks/useItemDetails';
import { useMap } from '../../context/Map';
import { useGlobalization } from '@Translations/useGlobalization';

interface TabProps {
	items: FeatureItem[];
	tabName: string;
	getItemName?: (item: FeatureItem) => string;
	detailView?: (item: FeatureItem) => JSX.Element;
	groupBy?: string;
	getItemDetailsSelector?: ItemDetailsSelector;
	getItemDetailsAction?: ActionFunction1<string, Action<string>>;
	getItemDetailsOnList?: boolean;
}

const getItemNameDefault = (item: FeatureItem): string =>
	`${item.name || item.NAME || item.Name || item.id}`;

const getItemNameSpatial = (item: FeatureItem): string =>
	item.layerName || getItemNameDefault(item);

type hoveredAction =
	| { type: 'add'; payload: string }
	| { type: 'delete'; payload: string }
	| { type: 'reset' };

const hoveredReducer = (state: string[], action: hoveredAction): string[] => {
	switch (action.type) {
		case 'add':
			return [...state, action.payload];
		case 'delete':
			return state.filter(item => item !== action.payload);
		case 'reset':
			return [];
		default:
			return state;
	}
};

export const Tab = ({
	items,
	tabName,
	getItemName = getItemNameDefault,
	detailView,
	groupBy = 'layerName',
	getItemDetailsSelector,
	getItemDetailsAction,
	getItemDetailsOnList,
}: TabProps): JSX.Element => {
	const { t } = useGlobalization();
	const [selected, setSelected] = useState<string | null>(null);
	const [hovered, setHovered] = useReducer(hoveredReducer, []);
	const { highlightAssets } = useMap();
	const dispatch = useDispatch();

	const selectedItem = useMemo(() => {
		if (!selected) return null;
		return items.find(({ id }) => id === selected);
	}, [selected]);

	const selectedItemDetails = useItemDetails(
		selectedItem,
		getItemDetailsSelector,
	);

	const groups = useMemo(() => {
		return _.groupBy(items, groupBy);
	}, [items]);

	const setSelectedItem = (itemId: string | null) => () => {
		setSelected(itemId);
		if (itemId && getItemDetailsAction)
			dispatch(getItemDetailsAction(itemId));
		if (!itemId) setHovered({ type: 'reset' });
	};

	const onMouseEnter = (itemId: string) => () => {
		setHovered({ type: 'add', payload: itemId });
	};

	const onMouseOut = (itemId: string) => () => {
		setHovered({ type: 'delete', payload: itemId });
	};

	useEffect(() => {
		if (items.length === 1) {
			setSelectedItem(items[0].id)();
			return;
		}
		setSelectedItem(null)();
		if (getItemDetailsOnList && getItemDetailsAction) {
			items.forEach(({ id }) => dispatch(getItemDetailsAction(id)));
		}
	}, [items]);

	useEffect(() => {
		highlightAssets?.(hovered);
	}, [hovered]);

	if (selectedItemDetails) {
		return (
			<div data-cy="item-details">
				{items.length > 1 && (
					<BackLink
						data-cy="item-details-back"
						onClick={setSelectedItem(null)}>
						{t('< Back to all {{tabName}}', {
							tabName: tabName,
							context: 'Back to all tab',
						})}
					</BackLink>
				)}
				{detailView?.(selectedItemDetails)}
				{!detailView && (
					<GenericProperties item={selectedItemDetails} />
				)}
			</div>
		);
	}

	return (
		<div data-cy="tab-item-list">
			{Object.entries(groups).map(([layerName, items], i) => (
				<InfoCard
					key={i}
					title={layerName}
					dataCy="item-list"
					marginBottom>
					{items.map(item => (
						<TabListItem
							key={item.id}
							item={item}
							getItemName={
								item.type === 'spatial'
									? getItemNameSpatial
									: getItemName
							}
							getItemDetailsSelector={
								getItemDetailsOnList
									? getItemDetailsSelector
									: undefined
							}
							onClick={setSelectedItem(item.id)}
							onMouseEnter={onMouseEnter(item.id)}
							onMouseOut={onMouseOut(item.id)}
						/>
					))}
				</InfoCard>
			))}
		</div>
	);
};
