import {
	AssetPipeParams,
	AssetPipeSummaryResolvedResponse,
} from '@Types/asset.types';

import { createAction } from '@reduxjs/toolkit';

export const getAssetPipe = createAction<AssetPipeParams>(
	'assets/getAssetPipe',
);
export const getAssetPipeResolved =
	createAction<AssetPipeSummaryResolvedResponse>(
		'assets/getAssetPipe/resolved',
	);
export const getAssetPipeRejected = createAction(
	'assets/getAssetPipe/rejected',
);
