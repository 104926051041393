import { InfoCard } from '@innovyze/stylovyze';
import { CircularProgress, Grid } from '@mui/material';
import selectRiskConfigsList, {
	selectRiskConfigsListIsLoading,
} from '@Selectors/riskConfigsList.selectors';
import { useGlobalization } from '@Translations';
import React from 'react';
import { Overall, Trend, RiskComponents, NoDetailsRisk } from '.';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const DetailsRisk = ({
	adminFullAccess,
}: {
	adminFullAccess: boolean;
}) => {
	const { t } = useGlobalization();
	const isLoading = selectRiskConfigsListIsLoading();
	const configs = selectRiskConfigsList();

	return (
		<InfoCard title={t('Risk')}>
			{!isLoading ? (
				configs.length != 0 ? (
					<Grid container>
						<Grid item xs={3}>
							<Overall />
						</Grid>
						<Grid item xs={9}>
							<Trend />
						</Grid>
						<Grid item xs={12}>
							<RiskComponents />
						</Grid>
					</Grid>
				) : (
					<NoDetailsRisk adminFullAccess={adminFullAccess} />
				)
			) : (
				<CircularProgress />
			)}
		</InfoCard>
	);
};
