import * as React from 'react';
import { useStableCallback } from './use-stable-callback';

const isStateSetter = <State>(
  nextValue: State | ((state: State) => State)
): nextValue is (state: State) => State => {
  return typeof nextValue === 'function';
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Controlled State Hook
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const useControlledState = <State>(params: {
  defaultState: State;
  controlledState?: State | undefined;
  onChange?: (state: State) => void;
}): [State, (state: State | ((state: State) => State)) => void] => {
  const onChange = useStableCallback(params.onChange);

  const [uncontrolledState, setUncontrolledState] = React.useState<State>(
    params.defaultState
  );
  const prevUncontrolledStateRef = React.useRef(uncontrolledState);

  const state = React.useMemo(() => {
    return params.controlledState === undefined
      ? uncontrolledState
      : params.controlledState;
  }, [uncontrolledState, params.controlledState]);

  const setState = React.useCallback(
    (value: State | ((state: State) => State)) => {
      if (params.controlledState === undefined) {
        setUncontrolledState(value);
      } else {
        const nextValue = isStateSetter(value)
          ? value(params.controlledState)
          : value;

        if (nextValue !== params.controlledState) {
          onChange(nextValue);
        }
      }
    },
    [params.controlledState, setUncontrolledState, onChange]
  );

  React.useEffect(() => {
    if (prevUncontrolledStateRef.current !== uncontrolledState) {
      onChange(uncontrolledState);
      prevUncontrolledStateRef.current = uncontrolledState;
    }
  }, [uncontrolledState, prevUncontrolledStateRef, onChange]);

  return [state, setState];
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Module Exports
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export { useControlledState };
