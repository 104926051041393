import Events, { EventNames, EventProps } from './Events';

export class EventBridge extends Events {
	private _services: Events[] = [];
	private nonPropagatingEvents: string[] = [];

	/**
	 * Add a service to the event bridge
	 * @param service an Events based class
	 */
	addService(service: Events): void {
		service.bridgeName = service.constructor.name;
		service.bridge = this;
		this._services.push(service);
	}

	/**
	 * Add a collection of services to the event bridge
	 * @param services an array of Events based classes
	 */
	addServices(services: Events[]): void {
		services.forEach(service => this.addService(service));
	}

	/**
	 * Set an event to be non propagating
	 * @param eventName name of the event that is emitted
	 */
	setNonPropagatingEvent(eventName: string): void {
		this.nonPropagatingEvents.push(eventName);
	}

	/**
	 * Set a number of events to be non propagating
	 * @param events an array of event names that are emitted
	 */
	setNonPropagatingEvents(events: string[]): void {
		events.forEach(eventName => this.setNonPropagatingEvent(eventName));
	}

	/**
	 * Send events to each service attached to event bridge
	 * except for non progapagating events which are
	 * returned to the origin service
	 * @param event string or event object
	 * @param properties additional information for the event
	 */
	fire(event: string | EventNames, properties?: EventProps): this {
		const originService =
			properties?.originService || (event as EventNames)?.originService;
		const eventProps = { ...(properties || {}), originService: 'Bridge' };
		const eventType = typeof event === 'string' ? event : event.type;

		if (!this.nonPropagatingEvents.includes(eventType)) {
			this._services.forEach(service => {
				service.fire(event, eventProps);
			});
		} else {
			const service = this._services.find(
				({ bridgeName }) => bridgeName === originService,
			);
			if (service) {
				service.fire(event, eventProps);
			}
		}
		return this;
	}
}
