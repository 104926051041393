import React, { ReactElement } from 'react';

import { TextField } from '@mui/material';
import { useGlobalization } from '@Translations/useGlobalization';

const SpatialFilterInput = (): ReactElement => {
	const { t } = useGlobalization();
	return (
		<TextField
			label="Value"
			defaultValue={t('Area Drawn', {
				context: 'Spatial filter filter input',
			})}
			InputProps={{
				readOnly: true,
			}}
			variant="standard"
		/>
	);
};

export default SpatialFilterInput;
