import Highcharts from 'highcharts';
import { DateTime } from 'luxon';
import { GoalChartRootOptions } from './goal-chart-types';
import { TFunction } from 'i18next';
import { getGoalProgress, getLimitProgress } from '@innovyze/stylovyze';

export interface SeriesSectionProps {
  label: string;
  value: string;
  date?: Date | string;
  progressLabel?: string;
  progressValue?: string;
  isNA?: boolean;
  isProgressHidden?: boolean;
  isDummy?: boolean;
  displayOnlyDate?: boolean;
}

export interface DateTimePreferences {
  timeZone: string;
  dateTimeFormat: string;
  dateFormat: string;
}

export interface KPIProgressArgs {
  isLimit: boolean;
  input: number;
  kpiValue: number;
  showDummy?: boolean;
  t: TFunction;
}

/************************** Reusable Display Elements ***************************** */

const goalChartFont =
  'Roboto, Helvetica Neue, San Francisco, Segoe UI, sans-serif';

const formatDateTime = (
  date: Date,
  dateTimePreferences: DateTimePreferences
) => {
  const { timeZone, dateTimeFormat } = dateTimePreferences;
  return DateTime.fromJSDate(date).setZone(timeZone).toFormat(dateTimeFormat);
};

const formatDate = (date: Date, dateTimePreferences: DateTimePreferences) => {
  const { timeZone, dateFormat } = dateTimePreferences;
  return DateTime.fromJSDate(date).setZone(timeZone).toFormat(dateFormat);
};

const makeLabelElement = (label: string) => {
  return `
  <span style="${['color: #3C3C3C', 'display: block', 'font-size: 0.8em'].join(
    ';'
  )}">
    ${label}
  </span>
  `;
};

const makeValueElement = (value: string) => {
  return `
  <span style="${[
    'color: black',
    'display: block',
    'font-size: 1.5em',
    'margin-block: 0.2em',
  ].join(';')}">
    ${value}
  </span>
  `;
};

const makeValueProgressElement = (progressValue: string) => {
  return `
  <span style="${[
    'color: black',
    'display: flex',
    'align-items: center',
    'font-size: 1.5em',
    'margin-top: 0.2em',
  ].join(';')}">
    ${progressValue}
  </span>
  `;
};

const makeDateTimeElement = (
  date: Date | string,
  dateTimePreferences: DateTimePreferences,
  displayOnlyDate: boolean
) => {
  let dateSection = date;
  if (date instanceof Date) {
    if (displayOnlyDate) dateSection = formatDate(date, dateTimePreferences);
    else dateSection = formatDateTime(date, dateTimePreferences);
  }
  return `
  <span style="${['color: black', 'display: block', 'font-size: 1em'].join(
    ';'
  )}">
  ${dateSection}
  </span>
`;
};

const makeDateRangeElement = (
  dateRange: GoalChartRootOptions['dateRange'],
  dateTimePreferences: DateTimePreferences
) => {
  return `
  <span style="${['color: black', 'display: block', 'font-size: 1em'].join(
    ';'
  )}">
  ${
    dateRange.startDate instanceof Date
      ? formatDate(dateRange.startDate, dateTimePreferences)
      : dateRange.startDate
  } - ${
    dateRange.endDate instanceof Date
      ? formatDate(dateRange.endDate, dateTimePreferences)
      : dateRange.endDate
  }
  </span>
`;
};

/***************************************************************************** */

const makeSeriesWithDateSection = (
  series: SeriesSectionProps,
  dateTimePreferences: DateTimePreferences
) => {
  const { label, value, date, displayOnlyDate } = series;
  const elementsArray: string[] = [];

  elementsArray.push(makeLabelElement(label), makeValueElement(value));
  if (date) {
    elementsArray.push(
      makeDateTimeElement(date, dateTimePreferences, !!displayOnlyDate)
    );
  }
  return elementsArray.join('');
};

export const makeSeriesWithProgressSection = (
  graphWidth: string,
  series: SeriesSectionProps
) => {
  return [
    `<div style="width: ${graphWidth};margin-top:5px;" />`,
    `<div style="${['float: left'].join(';')}" />`,
    makeLabelElement(series.label),
    '</div>',
    `<div style="${['float: right'].join(';')}" />`,
    series.isProgressHidden ? '' : makeLabelElement(series.progressLabel ?? ''),
    '</div>',
    `<div style="${['clear: both'].join(';')}" />`,
    '</div>',
    '</div>',
    `<div style="width: ${graphWidth}"; />`,
    `<div style="${['float: left'].join(';')}" />`,
    series.isNA ? makeValueElement('N/A') : makeValueElement(series.value),
    '</div>',
    `<div style="${['float: right'].join(';')}" />`,
    series.isProgressHidden
      ? ''
      : series.isNA
        ? makeValueElement('N/A')
        : makeValueProgressElement(series.progressValue),
    '</div>',
    `<div style="${['clear: both'].join(';')}" />`,
    '</div>',
    '</div>',
  ].join('');
};

/*****************************************************************************/

/*******************************Goal Chart Sections***************************/

export const makeGoalChartHeaderSection = (
  inputSensor: SeriesSectionProps,
  dateTimePreferences: DateTimePreferences
): Highcharts.TitleOptions => {
  return {
    useHTML: true,
    text: [
      `<div style="${[
        'font-size: 1rem',
        `font-family:${goalChartFont}`,
        'line-height: normal',
        'text-align: left',
      ].join(';')}" />`,
      makeSeriesWithDateSection(inputSensor, dateTimePreferences),
      '</div>',
    ].join(''),
  };
};

export const makeGoalChartBottomSection = (
  graphWidth: string,
  dateRange: GoalChartRootOptions['dateRange'],
  dateTimePreferences: DateTimePreferences,
  goal: SeriesSectionProps,
  benchmark: SeriesSectionProps
): Highcharts.TitleOptions => {
  return {
    useHTML: true,
    style: { width: 1200 },
    text: [
      `<div style="${[
        'font-size: 1rem',
        `font-family:${goalChartFont}`,
        'line-height: 1',
        'text-align: left',
      ].join(';')}" />`,
      makeDateRangeElement(dateRange, dateTimePreferences),
      makeSeriesWithProgressSection(graphWidth, goal),
      benchmark.isNA
        ? ''
        : makeSeriesWithProgressSection(graphWidth, benchmark),
      '</div>',
    ].join(''),
  };
};

/*****************************************************************************/
/*******************************Goal Chart Pointers***************************/

export const createDownArrow = (
  chart: Highcharts.Chart | undefined,
  onMouseOver: () => void,
  onMouseLeave: () => void
) => {
  return chart?.renderer
    .path()
    .attr({
      d: 'M15 10L10.6699 2.5L19.3301 2.5L15 10Z',
      fill: 'black',
      stroke: 'black',
      'stroke-width': 0.5,
      zIndex: 10,
    })
    .on('mouseover', onMouseOver)
    .on('mouseleave', onMouseLeave)
    .css({ cursor: 'pointer' });
};

export const createUpArrow = (
  chart: Highcharts.Chart | undefined,
  onMouseOver: () => void,
  onMouseLeave: () => void
) => {
  return chart?.renderer
    .path()
    .attr({
      d: 'M4.99951 43L9.32964 50.5H0.669384L4.99951 43Z',
      fill: 'black',
      stroke: 'black',
      'stroke-width': 0.5,
      zIndex: 10,
    })
    .on('mouseover', onMouseOver)
    .on('mouseleave', onMouseLeave)
    .css({ cursor: 'pointer' });
};

export const createRectangle = (
  chart: Highcharts.Chart | undefined,
  onMouseOver: () => void,
  onMouseLeave: () => void
) => {
  return chart?.renderer
    .rect()
    .attr({
      x: '3.99951',
      y: '48',
      width: 30,
      height: '2',
      transform: 'rotate(-90 3.99951 48) ',
      fill: 'black',
      stroke: 'black',
      'stroke-width': 0.5,
      zIndex: 10,
    })
    .on('mouseover', onMouseOver)
    .on('mouseleave', onMouseLeave)
    .css({ cursor: 'pointer' });
};

/*****************************************************************************/
/*******************************Goal Chart Tooltip****************************/

export const createTooltip = (
  chart: Highcharts.Chart | undefined,
  text: string
) => {
  return chart?.renderer
    .label(text, 10, 10)
    .attr({
      fill: 'black',
      zIndex: 20,
      align: 'center',
      padding: 10,
    })
    .css({
      color: 'white',
    })
    .shadow(true);
};

export const updateTooltipText = (
  tooltip: Highcharts.SVGElement | undefined,
  text: string
) => {
  const tooltipElement = tooltip?.element;
  if (tooltipElement) {
    const tooltipText = tooltipElement.querySelector('text');
    if (tooltipText?.textContent) {
      tooltipText.textContent = text;
    }
  }
};

/*****************************************************************************/
/*******************************Goal Chart Helpers****************************/

export const calculateGoalChartProgressBar = (
  sensorValue?: number,
  goalValue?: number,
  benchmarkValue?: number
) => {
  const percentage = (num: number) => num * 100;
  if (sensorValue !== undefined && goalValue !== undefined) {
    if (benchmarkValue !== undefined) {
      const maxValue = Math.max(sensorValue, goalValue, benchmarkValue);
      return {
        sensorProgress: percentage(sensorValue / maxValue),
        goalProgress: percentage(goalValue / maxValue),
        benchmarkProgress: percentage(benchmarkValue / maxValue),
      };
    } else {
      const maxValue = Math.max(sensorValue, goalValue);
      return {
        sensorProgress: percentage(sensorValue / maxValue),
        goalProgress: percentage(goalValue / maxValue),
      };
    }
  } else {
    return {
      sensorProgress: undefined,
      goalProgress: undefined,
      benchmarkProgress: undefined,
    };
  }
};

export const displayGoalChartPercentage = (num: number): string => {
  const percentageNumber = num * 100;
  if (percentageNumber % 1 === 0) return percentageNumber + '%';
  else return percentageNumber.toFixed(2) + '%';
};

const greenCheckMarkIcon =
  '<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill="#4a7e04" style="margin-right: 4px;"><path d="m424-296 282-282-56-56-226 226-114-114-56 56 170 170Zm56 216q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z"/></svg>';
const redCrossIcon =
  '<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill="#aa0000" style="margin-right: 4px;"><path d="m336-280 144-144 144 144 56-56-144-144 144-144-56-56-144 144-144-144-56 56 144 144-144 144 56 56ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z"/></svg>';

const getProgressIcon = (
  args: KPIProgressArgs & { progressLabel: string }
): string => {
  const { input, kpiValue, isLimit, progressLabel } = args;
  if (progressLabel === 'DIV/0') {
    return '';
  }
  if (!isLimit && kpiValue < 0 && input > kpiValue) {
    return redCrossIcon;
  }
  if (!isLimit && kpiValue < 0 && input <= kpiValue) {
    return greenCheckMarkIcon;
  }
  if (!isLimit && input < kpiValue) {
    // Input has not reached the goal
    return redCrossIcon;
  }

  if (!isLimit && input >= kpiValue) {
    // Input has reached or exceeded the goal
    return greenCheckMarkIcon;
  }
  if (isLimit && kpiValue < 0 && input >= kpiValue) {
    return greenCheckMarkIcon;
  }
  if (isLimit && kpiValue < 0 && input < kpiValue) {
    return redCrossIcon;
  }
  if (isLimit && input > kpiValue) {
    // Input has exceeded the limit
    return redCrossIcon;
  }

  if (isLimit && input <= kpiValue) {
    // Input has not reached the limit
    return greenCheckMarkIcon;
  }
};

export const getKPIProgress = (args: KPIProgressArgs): string => {
  const { input, kpiValue, isLimit, showDummy, t } = args;
  if (showDummy) {
    return '--%';
  }

  const progressPayload = { input, t };
  const progressLabel = isLimit
    ? getLimitProgress({ ...progressPayload, limitValue: kpiValue })
    : getGoalProgress({ ...progressPayload, goalValue: kpiValue });

  const progressIcon = getProgressIcon({ ...args, progressLabel });

  return `${progressIcon}${progressLabel}`;
};
