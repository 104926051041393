import { useLayoutEffect } from 'react';
import Highcharts from 'highcharts';

import type { MutableRefObject, RefObject } from 'react';

const useTooltipEvents = (
  containerRef: RefObject<HTMLDivElement | null>,
  chartsRef: MutableRefObject<Highcharts.Chart | Highcharts.Chart[] | null>,
  disabled?: boolean
): void => {
  useLayoutEffect(() => {
    const hideTooltipsEventHandler = createHideTooltipsEventHandler(chartsRef);

    if (!disabled) {
      containerRef.current?.addEventListener(
        'mouseleave',
        hideTooltipsEventHandler
      );
    }

    return () => {
      if (!disabled) {
        containerRef.current?.removeEventListener(
          'mouseleave',
          hideTooltipsEventHandler
        );
      }
    };
  }, [disabled]);
};

const createHideTooltipsEventHandler = (
  chartsRef: MutableRefObject<Highcharts.Chart | Highcharts.Chart[] | null>
) => {
  return (e: MouseEvent): void => {
    const hideTooltip = (chart?: Highcharts.Chart | null) => {
      if (chart?.options && chart?.pointer) {
        chart.series.forEach(function (series) {
          chart?.tooltip?.hide?.();
          chart?.xAxis[0]?.hideCrosshair?.();
          series?.points?.forEach((point) => {
            point.setState?.('');
          });
        });
      }
    };

    if (Array.isArray(chartsRef.current)) {
      chartsRef.current?.forEach((chart) => hideTooltip(chart));
    } else {
      hideTooltip(chartsRef.current);
    }
  };
};

export default useTooltipEvents;
