import {
	InspectionResolvedResponse,
	updateInspectionData,
} from '@Types/inspectionAttributes.types';
import { put, retry, takeLatest, call } from 'redux-saga/effects';
import {
	createInspection,
	getInspectionAttributes,
	getInspectionAttributesRejected,
	getInspectionAttributesResolved,
	refreshInspection,
	setCreateInspectionId,
	setCreatingInspection,
	updateInspection,
} from '@Actions/inspection.actions';
import { AnyAction } from 'redux';
import { AxiosError, AxiosResponse } from 'axios';
import {
	createInspectionApi,
	getInspectionApi,
	getUpdateInspectionApi,
} from '@Services';
import { error, success } from '@innovyze/stylovyze';
import { t } from 'i18next';
import {
	CreateInspectionParams,
	CreateInspectionResponse,
} from '@Types/inspectionAttributes.types';
import { PayloadAction } from '@reduxjs/toolkit';

function* getInspectionsSaga(action: AnyAction) {
	try {
		const payload: AxiosResponse<InspectionResolvedResponse> = yield retry(
			5,
			15000,
			getInspectionApi,
			action.payload.assetId,
		);
		yield put(getInspectionAttributesResolved(payload.data));
	} catch (e) {
		yield put(getInspectionAttributesRejected());
	}
}

function* watchGetInspections() {
	yield takeLatest(getInspectionAttributes, getInspectionsSaga);
}

function* updateInspectionsSaga(action: AnyAction) {
	try {
		const payload: AxiosResponse<updateInspectionData> = yield retry(
			5,
			15000,
			getUpdateInspectionApi,
			action.payload,
		);
		if (payload?.status === 200) {
			yield put(success(t('Inspection Updated Successfully')));
			yield put(refreshInspection(payload));
		}
	} catch (e) {
		const err = e as AxiosError;
		const rejection = {
			code: err?.response?.status ?? -1,
			text:
				err?.message ??
				err?.response?.statusText ??
				t('No additional information'),
		};
		yield put(
			error(
				t('{{message}} Failed: {{reason}}', {
					message: 'Inspection Update',
					reason: rejection.text,
				}),
			),
		);
	}
}

function* watchUpdateInspections() {
	yield takeLatest(updateInspection, updateInspectionsSaga);
}

function* createInspectionSaga(action: PayloadAction<CreateInspectionParams>) {
	try {
		yield put(setCreatingInspection(true));

		const payload: AxiosResponse<CreateInspectionResponse> = yield call(
			createInspectionApi,
			action.payload,
		);

		yield put(setCreateInspectionId(payload.data._id));
		yield put(setCreatingInspection(false));
	} catch (e) {
		console.log(e);
		const err = e as AxiosError;
		const rejection = {
			code: err?.response?.status ?? -1,
			text:
				err?.message ??
				err?.response?.statusText ??
				t('No additional information'),
		};

		yield put(setCreateInspectionId(undefined));
		yield put(setCreatingInspection(false));
		yield put(
			error(
				t('New {{type}} Failed: {{reason}}', {
					type: action.payload.options.task
						? t('Inspection')
						: t('Task'),
					reason: rejection.text,
				}),
			),
		);
	}
}

function* watchCreateInspection() {
	yield takeLatest(createInspection, createInspectionSaga);
}

export default [
	watchGetInspections(),
	watchUpdateInspections(),
	watchCreateInspection(),
];
