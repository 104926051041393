import { EventHandlers } from '../../core/_chart';
import {
  CompanySettings,
  useIsFeatureEnabled,
  useSelectSensors,
  useSettings,
} from '@innovyze/stylovyze';
import * as InsightChart from '../../core/_insight-chart';
import * as PieChart from '../../modules/pie-chart';
import * as React from 'react';
import * as SeriesData from '../../core/series-data';
import * as TimeSeriesData from '../../core/time-series-data';
import * as TimeSeriesDataOld from '../../core/time-series-data-old';
import {
  fixEdgeResponseResults,
  getEdgeAutoResolution,
} from '../insight-historical-chart/insight-historical-chart.utils';
import { fixCollectionInterval } from '../../core/time-series-data/utils';
import { DateTime } from 'luxon';

type EdgeSource = {
  sensorId: string;
  resolution: TimeSeriesDataOld.Resolution;
  reading: TimeSeriesDataOld.Reading;
};

function edgeSourceStringifier(source: EdgeSource): string {
  return `${source.sensorId}:${source.resolution}:${source.reading}`;
}

function limit(value: string | null | undefined, defaultValue = 500): number {
  if (typeof value === 'string' || value === null) return defaultValue;
  if (isNaN(Number(value))) return defaultValue;
  return Number(value);
}

const formatDateTime = (date: number, companySettings: CompanySettings) => {
  if (!date) return;
  const timeZone = companySettings.timeZoneIANA || 'Etc/UTC';
  const _dateFormat = companySettings.dateFormat ?? 'MM/DD/YYYY';
  const _hourCycle12 = companySettings.hourCycle12 ?? true;
  const dateFormat = _dateFormat.replace('DD', 'dd').replace('YYYY', 'yyyy');
  const timeFormat = _hourCycle12 ? 'hh:mm a' : 'HH:mm';
  return DateTime.fromMillis(date)
    .setZone(timeZone)
    .toFormat(`${dateFormat} ${timeFormat}`);
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 *	Pie Chart Preset Root Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

type PieChartRootProps = Omit<
  PieChart.PieChartRootProps,
  'children' | 'elements' | 'events' | 'status' | 'error'
>;

type InsightPieChartSeriesProps = InsightChart.InsightChartSeries<
  PieChart.PieChartSeriesProps & { name: string },
  {
    sensorId: string;
    resolution: InsightChart.Resolution;
    reading: InsightChart.Reading;
    customData?: InsightChart.SensorDataEntry;
  }
>;

interface InsightPieChartProps extends PieChartRootProps {
  series: InsightPieChartSeriesProps[];
  timeRangeSelection?: InsightChart.TimeRangeSelection;
  onSeriesVisibilityChange?: EventHandlers['onSeriesVisibilityChange'];
}

const InsightPieChart = React.forwardRef<
  { chart: Highcharts.Chart | undefined },
  InsightPieChartProps
>((props, ref): React.ReactElement => {
  const { companySettings } = useSettings();
  const { sensors, initialized: sensorsInitialized } = useSelectSensors();
  const dataLimit = useIsFeatureEnabled('info-360-analytics-hp2-charts-limit');

  const seriesData = TimeSeriesDataOld.useRetriever(
    props.series.filter((series) => !series.customData && series.sensorId)
  );

  const edgeSeries = React.useMemo(() => {
    const now = DateTime.now();
    const timeSelection = {
      from: props.timeRangeSelection?.min ?? now.minus({ days: 7 }).toMillis(),
      to: props.timeRangeSelection?.max ?? now.toMillis(),
    };
    return props.series.map((sensor) => {
      const _sensor = sensors.find((s) => s.sensorId === sensor.sensorId);
      const resolution =
        getEdgeAutoResolution(_sensor?.resolutions, timeSelection) ??
        '15-MINUTE';
      return { ...sensor, resolution };
    });
  }, props.series);

  const [edgeSources] = SeriesData.useSources<EdgeSource>(() => {
    const series = edgeSeries?.filter((s) => !s.customData && s.sensorId);
    if (!series?.length) return [];
    return series.map(({ sensorId, resolution, reading }) => ({
      sensorId,
      resolution,
      reading,
    }));
  }, [edgeSeries]);

  const [edgeData, edgeStatus, retrieveEdgeData] = SeriesData.useRetriever<
    TimeSeriesData.ResponseBody,
    { timeSelection?: TimeSeriesData.PartialTimeSelection }
  >(
    async (signal, params) => {
      if (!edgeSources?.length || !sensorsInitialized) return;

      const timeSelection = {
        from: params.timeSelection?.from ?? 'oldest',
        to: params.timeSelection?.to ?? 'latest',
      } as TimeSeriesData.TimeSelection;

      const response = await TimeSeriesData.retrieve(signal, {
        order: 'asc',
        timeSelection,
        limit: limit(dataLimit),
        timeZone: companySettings.timeZoneIANA,
        data_version: 'v3.0',
        snapping: 'oldest',
        sources: edgeSources.map((s) => {
          const _sensor = sensors.find((_s) => _s.sensorId === s.sensorId);
          const seconds = fixCollectionInterval(_sensor?.collectionInterval);

          return {
            key: edgeSourceStringifier(s),
            sensorId: s.sensorId,
            collectionInterval: { seconds },
            analytic: TimeSeriesData.makeAnalytic(
              s.resolution ?? '15-MINUTE',
              s.reading ?? 'Close'
            ),
          };
        }),
      });

      const result = { ...response.data };
      result.results = fixEdgeResponseResults(edgeSources, result.results);

      return result;
    },
    [
      companySettings.timeZoneIANA,
      dataLimit,
      edgeSources,
      sensors,
      sensorsInitialized,
    ]
  );

  const { getRawSensorDataMap } = seriesData;
  const data = React.useMemo(() => {
    return edgeSeries.map((series) => {
      const key = edgeSourceStringifier(series);
      const edgeDataEntry = edgeData?.results?.[key];

      let _data: TimeSeriesDataOld.TimeSeriesData | undefined = undefined;
      const reading = series.reading ?? 'Close';

      if (series.customData) {
        const d = series.customData.data?.size
          ? Array.from(series.customData.data.entries())
          : undefined;
        _data = InsightChart.processDataWithReading(d, reading);
      } else {
        _data = edgeDataEntry?.data as TimeSeriesDataOld.TimeSeriesData;
      }

      const processedData: NonNullable<
        PieChart.PieChartSeriesProps['data']
      >[number] = {
        name: series.name,
        y: null,
      };

      if (_data?.length) {
        processedData.y = readings[series.reading]?.(_data.map((d) => d[1]));
      }

      return processedData;
    });
  }, [edgeData, getRawSensorDataMap, edgeSeries]);

  // Handles data with edge analytics' API
  React.useEffect(() => {
    if (!sensorsInitialized) return;
    retrieveEdgeData({
      timeSelection: {
        from: props.timeRangeSelection?.min,
        to: props.timeRangeSelection?.max,
      },
    });
  }, [
    retrieveEdgeData,
    sensorsInitialized,
    companySettings.timeZoneIANA,
    props.timeRangeSelection?.max,
    props.timeRangeSelection?.min,
  ]);

  const timeRangeLabel = React.useMemo(() => {
    if (!props.showTimeRangeLabel) return '';
    const data = edgeData?.results ?? {};
    const dataKeys = Object.keys(data);
    if (!dataKeys.length) return '';
    const firsElement = data[dataKeys[0]].data;
    let minTimeRange = firsElement?.[0][0];
    let maxTimeRange = firsElement[firsElement?.length - 1][0];
    for (let i = 1; i < dataKeys.length; i++) {
      const currentElement = data[dataKeys[i]].data;
      if (currentElement?.[0][0] < minTimeRange)
        minTimeRange = currentElement?.[0][0];
      if (currentElement?.[currentElement?.length - 1][0] > maxTimeRange)
        maxTimeRange = currentElement?.[currentElement?.length - 1][0];
    }
    return `${formatDateTime(minTimeRange, companySettings)} - ${formatDateTime(
      maxTimeRange,
      companySettings
    )}`;
  }, [edgeData, props.showTimeRangeLabel, companySettings]);

  return (
    <PieChart.PieChartRoot
      ref={ref}
      error={seriesData.error}
      status={edgeStatus}
      selectedTheme={props.selectedTheme}
      elements={{
        series: (
          <PieChart.PieChartSeries
            showLabels={props.showLabels}
            timeRangeLabel={timeRangeLabel}
            selectedTheme={props.selectedTheme}
            data={data}
          />
        ),
      }}
      events={{
        series: {
          onVisibilityChange: props.onSeriesVisibilityChange,
        },
      }}
    />
  );
});

InsightPieChart.displayName = 'InsightPieChartRoot';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const readings: Record<
  Exclude<InsightChart.Reading, 'OHLC'>,
  (data: (number | null)[]) => number | null
> = {
  Open: (data) => {
    if (!data.length) return null;
    return data.at(0) ?? null;
  },
  High: (data) => {
    if (!data.length) return null;
    const values = data.filter((d): d is number => d !== null);
    if (!values.length) return null;
    return values.reduce((m, v) => Math.max(m, v), values.at(0)!);
  },
  Average: (data) => {
    if (!data.length) return null;
    const values = data.filter((d): d is number => d !== null);
    if (!values.length) return null;
    const sum = values.reduce((s, d) => s + d, 0);
    return sum / data.length;
  },
  Close: (data) => {
    if (!data.length) return null;
    return data.at(-1) ?? null;
  },
  Low: (data) => {
    if (!data.length) return null;
    const values = data.filter((d): d is number => d !== null);
    if (!values.length) return null;
    return values.reduce((m, v) => Math.min(m, v), values.at(0)!);
  },
  Sum: (data) => {
    if (!data.length) return null;
    const values = data.filter((d): d is number => d !== null);
    if (!values.length) return null;
    return values.reduce((s, d) => s + d, 0);
  },
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export { InsightPieChart };

export type { InsightPieChartProps, InsightPieChartSeriesProps };
