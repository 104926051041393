import { Button, IconButton, useTheme } from '@mui/material';
import {
	DeleteIcon,
	DistributeIcon,
	DistributeIconButton,
	GridItem,
	Heading,
	PaletteIcon,
	RangeValueHelperText,
	RangeWrapper,
	RowBorder,
} from './Ranges.styles';
import {
	GradientKey,
	defaultRangeValues,
	generateCustomGradientSteps,
	generateGradientSteps,
	getColorOptions,
	gradients,
} from '@Map/layers/settings';
import React, { Fragment, useEffect, useMemo, useState } from 'react';

import { ColorRange } from '@Map/types';
import { Picker } from '@Components/Inputs';
import { UnitInput } from '@Components/Inputs/UnitInput';
import { UnitSystem } from '@Components/Inputs/units';
import { useDebouncedCallback } from '@Hooks/useDebouncedCallback';
import { useGlobalization } from '@Translations/useGlobalization';

interface RangeProps {
	defaultRanges?: ColorRange[];
	onRangeChange?: (ranges: ColorRange[]) => void;
	unit?: string;
	unitSystem?: UnitSystem;
}

const nextValueInRange = (ranges: ColorRange[]) => {
	const lastValue = ranges.at(-1)?.value || 0;
	const secondLastValue = ranges.at(-2)?.value || 0;
	const diff = lastValue - secondLastValue;
	return lastValue + diff;
};

const nextColor = (ranges: ColorRange[], colors: string[]) => {
	const lastColor = ranges.at(-1)?.color || colors[0];
	const lastIndex = colors.indexOf(lastColor) + 1;
	return colors[lastIndex] || colors[0];
};

const colorRange = (ranges: ColorRange[], gradientKey: string | undefined) => {
	if (!gradientKey) return;
	const gradientSteps = generateGradientSteps(
		gradientKey as GradientKey,
		ranges.length,
	);
	return ranges.map((range, i) => ({ ...range, color: gradientSteps[i] }));
};

const addRowToRange = (
	ranges: ColorRange[],
	colors: string[],
	gradientKey: string | undefined,
): ColorRange[] => {
	const newRow = {
		value: nextValueInRange(ranges),
		color: nextColor(ranges, colors),
	};
	const newRange = [...ranges, newRow];
	return colorRange(newRange, gradientKey) || newRange;
};

const distributeRange = (ranges: ColorRange[]) => {
	const start = ranges.at(0)?.value;
	const end = ranges.at(-1)?.value;
	if (start == null || end == null) return;
	const diff = (end - start) / (ranges.length - 1);
	return ranges.map((range, i) => ({ ...range, value: start + diff * i }));
};

export const Ranges = ({
	defaultRanges,
	onRangeChange,
	unit,
	unitSystem,
}: RangeProps): JSX.Element => {
	const { t } = useGlobalization();
	const [ranges, setRanges] = useState<ColorRange[]>(
		defaultRanges || defaultRangeValues(unitSystem, unit),
	);
	const [gradient, setGradient] = useState<string | undefined>(undefined);

	const bouncedCallback = useDebouncedCallback(value => {
		onRangeChange?.(value);
	}, 300);

	const updateRanges = (range: ColorRange[]) => {
		setRanges(range);
		bouncedCallback(range);
	};

	const theme = useTheme();
	const colorOptions = getColorOptions(theme);
	const colors = useMemo(() => colorOptions.map(({ value }) => value), [
		colorOptions,
	]);

	const addRow = () => {
		updateRanges(addRowToRange(ranges, colors, gradient));
	};

	const updateValue = (index: number) => (value: number) => {
		updateRanges(
			ranges.map((range, i) =>
				i === index ? { ...range, value } : range,
			),
		);
	};

	const updateColor = (index: number) => (color: string) => {
		// if individual colour is set then disable colouring by gradient
		setGradient(undefined);
		updateRanges(
			ranges.map((range, i) =>
				i === index ? { ...range, color } : range,
			),
		);
	};

	const deleteRow = (index: number) => () => {
		const updatedRange = ranges.filter((range, i) => i !== index);
		updateRanges(colorRange(updatedRange, gradient) || updatedRange);
	};

	const onSelectGradient = (gradientKey: string | undefined) => {
		setGradient(gradientKey);
		const recolored = colorRange(ranges, gradientKey);
		if (recolored) updateRanges(recolored);
	};

	const onDistributeColors = () => {
		const start = ranges.at(0)?.color;
		const end = ranges.at(-1)?.color;
		if (start && end) {
			// if distribute colour is triggered set then disable colouring by gradient
			setGradient(undefined);
			const gradientSteps = generateCustomGradientSteps(
				[start, end],
				ranges.length,
			);
			updateRanges(
				ranges.map((range, i) => ({
					...range,
					color: gradientSteps[i],
				})),
			);
		}
	};

	const onDistributeRange = () => {
		const newRange = distributeRange(ranges);
		if (newRange) updateRanges(newRange);
	};

	useEffect(() => {
		if (defaultRanges) {
			updateRanges(defaultRanges);
		}
	}, [defaultRanges]);

	return (
		<RangeWrapper>
			<GridItem>
				<Heading>
					{t('Range', { context: 'Layers properties range heading' })}
				</Heading>
				<DistributeIconButton
					size="small"
					title={t('Distribute range', {
						context: 'Layers properties range component button',
					})}
					data-cy="distribute-range"
					onClick={onDistributeRange}>
					<DistributeIcon />
				</DistributeIconButton>
			</GridItem>
			<GridItem>
				<Heading>
					{t('Color', { context: 'Layers properties color heading' })}
				</Heading>
				<DistributeIconButton
					size="small"
					title={t('Distribute colors', {
						context: 'Layers properties range component button',
					})}
					data-cy="distribute-colors"
					onClick={onDistributeColors}>
					<DistributeIcon />
				</DistributeIconButton>
				<Picker
					options={gradients}
					onChange={onSelectGradient}
					value={gradient}
					data-cy="gradient-picker"
					allowEmpty>
					<IconButton
						size="small"
						title={t('Select gradient for range', {
							context: 'Layers properties range component button',
						})}
						color={gradient ? 'primary' : undefined}>
						<PaletteIcon />
					</IconButton>
				</Picker>
			</GridItem>
			<GridItem />
			<RowBorder />
			{ranges.map(({ value, color }, i) => (
				<Fragment key={i}>
					<GridItem>
						<UnitInput
							value={value}
							unit={unit}
							system={unitSystem}
							onChange={updateValue(i)}
							data-cy="range-color-value"
						/>
					</GridItem>
					<GridItem>
						<Picker
							options={colorOptions}
							value={color}
							onChange={updateColor(i)}
							type="color"
							data-cy="range-color-picker"
							IconButtonProps={{
								'data-cy': 'range-color-picker-button',
							}}
							TextFieldProps={{
								fullWidth: false,
								size: 'small',
								margin: 'none',
							}}
							InputProps={{
								style: {
									paddingRight: 0,
								},
							}}
							style={{
								width: 120,
							}}
						/>
					</GridItem>
					<GridItem>
						{ranges.length > 2 && (
							<IconButton
								size="small"
								onClick={deleteRow(i)}
								data-cy="delete-row"
								title={t('Delete row', {
									context:
										'Layers properties range component button',
								})}>
								<DeleteIcon />
							</IconButton>
						)}
					</GridItem>
					{value <= ranges[i - 1]?.value && (
						<RangeValueHelperText>
							{t(
								'Range value should be higher than previous value',
								{
									context:
										'Layer properties form field valiation message',
								},
							)}
						</RangeValueHelperText>
					)}
					<RowBorder />
				</Fragment>
			))}
			<GridItem>
				<Button size="small" onClick={addRow}>
					{t('Add row', {
						context:
							'Add additional row in range list in layer properties form',
					})}
				</Button>
			</GridItem>
		</RangeWrapper>
	);
};
