import { ConfigEdits, ThemeProperties, ThemeResponse } from '@Root';
import { reduceArrayToObject, reformConfig } from '@Map/utils';

import RequestService from './RequestService';
import { defaultThemeProps } from './ThemeDefaults';
import { t } from '@Translations/extraction';

export const appId = 'appInfo360';

export default class ThemeService extends RequestService {
	private _appId = appId;
	private _theme: ConfigEdits | undefined = undefined;
	private _themeId: string | undefined;
	private _name: ThemeProperties['name'] = undefined;
	private _isUserTheme: ThemeProperties['isUserTheme'] = true;
	private _isDefaultTheme = false;

	constructor(
		url: string,
		themeId?: string,
		token?: string,
		isDefault = false,
	) {
		super(url, token);
		this._themeId = themeId;
		this._isDefaultTheme = isDefault;
	}

	get isDefaultTheme() {
		return this._isDefaultTheme;
	}

	set name(name: ThemeProperties['name']) {
		this._name = name;
	}

	get name() {
		return this._name;
	}

	set isUserTheme(isUserTheme: ThemeProperties['isUserTheme']) {
		if (this.isDefaultTheme && isUserTheme) return;
		this._isUserTheme = isUserTheme;
	}

	get isUserTheme() {
		if (this.isDefaultTheme) return false;
		return this._isUserTheme;
	}

	async getName(): Promise<ThemeProperties['name']> {
		if (this.name) return this.name;
		await this.getTheme();
		return this.name;
	}

	async getTheme(): Promise<ThemeProperties> {
		if (this._theme)
			return {
				configEdits: this._theme,
				name: this.name,
				isUserTheme: this.isUserTheme,
			};
		this.setRequest('getTheme', {
			url: this._getUrl(this._themeId, !this.isUserTheme),
		});
		const data = await this.getRequest<Partial<ThemeProperties>>(
			'getTheme',
			{},
			'map_exception_themeGet',
			'Failed to get theme',
			{
				responseParser: this.responseParser,
			},
		);
		this._theme = data.configEdits;
		this.name = data.name;
		this.isUserTheme = data.isUserTheme;
		return {
			// clone object to prevent mutation of original data
			configEdits: this._theme ?? { ...defaultThemeProps.configEdits },
			name: this.name,
			isUserTheme: this.isUserTheme,
		};
	}

	async createTheme(
		edits: ConfigEdits,
		name: ThemeProperties['name'],
		isUserTheme: ThemeProperties['isUserTheme'] = true,
	): Promise<string | undefined> {
		this.setRequest('createTheme', {
			url: this._getUrl(this._themeId, !isUserTheme),
		});
		this._theme = edits;
		this.name = name;
		const data = await this.postRequest<
			ThemeResponse,
			Partial<ThemeProperties>
		>(
			'createTheme',
			'map_exception_themeCreate',
			'Failed to create theme',
			{
				body: this.processBody({
					configEdits: this._theme,
					name: this._name,
				}),
				responseParser: this.responseParser,
			},
		);
		if (data && data.id) {
			this._themeId = data.id;
		}
		return this._themeId;
	}

	async saveTheme(
		edits: ConfigEdits,
		name: ThemeProperties['name'],
		isUserTheme: ThemeProperties['isUserTheme'] = true,
	): Promise<void> {
		this.setRequest('updateTheme', {
			url: this._getUrl(this._themeId, !isUserTheme),
		});
		this._theme = edits;
		this.name = name;
		this.isUserTheme = isUserTheme;
		await this.putRequest<ThemeResponse, Partial<ThemeProperties>>(
			'updateTheme',
			'map_exception_themeSave',
			'Failed to save theme',
			{
				body: this.processBody({
					configEdits: this._theme,
					name: this._name,
				}),
				responseParser: this.responseParser,
			},
		);
	}

	async resetTheme(): Promise<void> {
		this.setRequest('updateTheme', {
			url: this._getUrl(this._themeId, !this.isUserTheme),
		});
		// clone object to prevent mutation of original data
		this._theme = { ...defaultThemeProps.configEdits };
		await this.putRequest<ThemeResponse, Partial<ThemeProperties>>(
			'updateTheme',
			'map_exception_themeReset',
			'Failed to reset theme',
			{
				body: this.processBody({
					configEdits: this._theme,
					name: this._name,
				}),
				responseParser: this.responseParser,
			},
		);
	}

	async deleteTheme(): Promise<void> {
		this.setRequest('deleteTheme', {
			url: this._getUrl(this._themeId, !this.isUserTheme),
		});
		await this.deleteRequest(
			'deleteTheme',
			'map_exception_themeDelete',
			'Failed to delete theme',
		);
	}

	processBody(data: ThemeProperties): ThemeResponse {
		const themeName = data.name && t(data.name);
		return {
			config: {
				...data.configEdits,
				layers: Object.entries(data.configEdits.layers).map(
					// exclude iconImage as we don't need to save it
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					([, { iconImage, ...layer }]) => ({
						...layer,
					}),
				),
			},
			themeName,
		};
	}

	responseParser = (data: unknown): ThemeProperties | null => {
		if (data && typeof data === 'object' && 'config' in data) {
			const {
				config,
				themeName,
				themeId,
				userId,
			} = data as ThemeResponse;
			const parsedConfig = reformConfig(config);
			const layers = reduceArrayToObject(
				parsedConfig.layers.map(layer => ({
					[`${layer.id}`]: layer,
				})),
			);
			return {
				configEdits: {
					...parsedConfig,
					layers,
				},
				name: themeName,
				id: themeId,
				isUserTheme: !!userId,
			};
		}
		return null;
	};

	private _getUrl(themeId: string | undefined, isTenantTheme: boolean) {
		const postfix = themeId ? `/${themeId}` : '';

		const searchParams = new URLSearchParams();
		searchParams.append('appId', this._appId);
		if (isTenantTheme) {
			searchParams.append('tenantTheme', 'true');
		}
		return `${
			this._baseUrl
		}/v2/themes${postfix}?${searchParams.toString()}`;
	}
}
