import { StylovyzeTable } from '@innovyze/stylovyze';
import { Grid } from '@mui/material';
import { selectAssetRisk } from '@Selectors';
import { useGlobalization } from '@Translations';
import React from 'react';

export const CLOFScore = ({ clof }: { clof: 'CoF' | 'LoF' }): JSX.Element => {
	const { t } = useGlobalization();
	const headers = [
		{
			cell: t('Component Name'),
			dataCy: `${clof}ComponentName`,
			key: `${clof}ComponentName`,
		},
		{
			cell: t('Score'),
			dataCy: `${clof}Score`,
			key: `${clof}Score`,
		},
		{
			cell: t('Weighted Score'),
			dataCy: `${clof}WeightedScore`,
			key: `${clof}WeightedScore`,
		},
	];

	const assetRisk = selectAssetRisk();

	const componentRows = [];

	if (clof == 'CoF') {
		if (assetRisk?.COF_CONFIG && assetRisk.COF_CONFIG.length > 0) {
			let index = 0;
			for (const category of assetRisk.COF_CONFIG) {
				if (category.components && category.components.length > 0) {
					for (const component of category.components) {
						const rawScore =
							assetRisk?.WEIGHTED_SCORES &&
							assetRisk?.WEIGHTED_SCORES[
								`COFRaw_${category.name}_${component.name}`
							] != null &&
							assetRisk?.WEIGHTED_SCORES[
								`COFRaw_${category.name}_${component.name}`
							] != undefined
								? assetRisk?.WEIGHTED_SCORES[
										`COFRaw_${category.name}_${component.name}`
								  ]
								: '-';
						const score =
							assetRisk?.WEIGHTED_SCORES &&
							assetRisk?.WEIGHTED_SCORES[
								`COF_${category.name}_${component.name}`
							] != null &&
							assetRisk?.WEIGHTED_SCORES[
								`COF_${category.name}_${component.name}`
							] != undefined
								? assetRisk?.WEIGHTED_SCORES[
										`COF_${category.name}_${component.name}`
								  ]
								: '-';
						componentRows.push({
							key: index++,
							cells: [
								{
									content: component.name,
								},
								{
									content: rawScore,
								},
								{
									content: score,
								},
							],
						});
					}
				}
			}
		}
	} else if (clof == 'LoF') {
		if (assetRisk?.LOF_CONFIG && assetRisk.LOF_CONFIG.length > 0) {
			let index = 0;
			for (const category of assetRisk.LOF_CONFIG) {
				if (category.components && category.components.length > 0) {
					for (const component of category.components) {
						const rawScore =
							assetRisk?.WEIGHTED_SCORES &&
							assetRisk?.WEIGHTED_SCORES[
								`LOFRaw_${category.name}_${component.name}`
							] != null &&
							assetRisk?.WEIGHTED_SCORES[
								`LOFRaw_${category.name}_${component.name}`
							] != undefined
								? assetRisk?.WEIGHTED_SCORES[
										`LOFRaw_${category.name}_${component.name}`
								  ]
								: '-';
						const score =
							assetRisk?.WEIGHTED_SCORES &&
							assetRisk?.WEIGHTED_SCORES[
								`LOF_${category.name}_${component.name}`
							] != null &&
							assetRisk?.WEIGHTED_SCORES[
								`LOF_${category.name}_${component.name}`
							] != undefined
								? assetRisk?.WEIGHTED_SCORES[
										`LOF_${category.name}_${component.name}`
								  ]
								: '-';

						componentRows.push({
							key: index++,
							cells: [
								{
									content: component.name,
								},
								{
									content: rawScore,
								},
								{
									content: score,
								},
							],
						});
					}
				}
			}
		}
	}
	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<StylovyzeTable
					className="ScrollTable"
					title={clof}
					dataCy={`${clof}ScoreTable`}
					headers={headers}
					rows={componentRows}
				/>
			</Grid>
		</Grid>
	);
};

export default CLOFScore;
