import { MutableRefObject, useEffect, useRef } from 'react';

export const useEventListener = (
	eventName: string,
	handler: EventListener,
	element: MutableRefObject<HTMLDivElement | null>,
): void => {
	// save event handler
	const savedHandler = useRef<EventListener>();

	// update ref with latest handler if it changes
	useEffect(() => {
		savedHandler.current = handler;
	}, [handler]);

	useEffect(() => {
		const eventListener = (event: Event): void =>
			savedHandler.current?.(event);

		element.current?.addEventListener(eventName, eventListener);

		// remove event listener on cleanup
		return (): void => {
			element.current?.removeEventListener(eventName, eventListener);
		};
	}, [eventName, element]);
};
