import { AssetRiskHistory } from '@Types/asset.types';
import {
	getAssetRiskHistory,
	getAssetRiskHistoryRejected,
	getAssetRiskHistoryResolved,
} from '@Actions/assetRisk.actions';
import { put, retry, takeLatest } from 'redux-saga/effects';

import { AnyAction } from 'redux';
import { AxiosResponse } from 'axios';
import { getRiskHistoryApi } from '@Services';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* getAssetRiskHistorySaga(action: AnyAction) {
	try {
		const initAssetRiskHistory: AssetRiskHistory[] = [];

		const payload: AxiosResponse<AssetRiskHistory[]> = yield retry(
			5,
			30000,
			getRiskHistoryApi,
			action.payload,
		);

		yield put(
			getAssetRiskHistoryResolved(
				payload.data ? payload.data : initAssetRiskHistory,
			),
		);
	} catch (e) {
		yield put(getAssetRiskHistoryRejected());
	}
}

function* watchGetAssetRiskHistory() {
	yield takeLatest(getAssetRiskHistory, getAssetRiskHistorySaga);
}

export default [watchGetAssetRiskHistory()];
