import { getTheme, Theme } from '../_next/core/utils/theme-utils';
import { RawPatternMeasureData } from '../types';
import { SeriesOptionsType } from 'highcharts/highstock';

export const generatePatternStockSeries = async (
  patternSeries: RawPatternMeasureData[],
  selectedTheme: Theme
): Promise<SeriesOptionsType> => {
  const data = patternSeries.map((measureData) => [
    measureData[1],
    measureData[2],
  ]);

  const theme = getTheme(selectedTheme ?? 'Default');

  return {
    name: '',
    type: 'column',
    color: theme.colors[0],
    maxPointWidth: undefined,
    groupPadding: 1,
    pointPadding: 1,
    pointWidth: undefined,
    grouping: false,
    dataGrouping: { enabled: false },
    boostThreshold: 20000,
    turboThreshold: 20000,
    data,
  };
};
