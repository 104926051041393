import { KVP, PaginatedKVP } from './types';
import PaginatedFetch, { PaginatedFetchParams } from './PaginatedFetch';
import { extractErrorContext, notUndefined } from '@Map/utils';

import { DataServiceRequestError } from './DataServiceRequestError';
import Events from '@Map/events/Events';
import { LOOKUP_COMPLETE } from './constants';
import logger from '@Map/logger/Logger';

export default class Lookup extends Events {
	private _key: string;
	private _data: KVP | undefined = undefined;
	private _fetch: PaginatedFetch | undefined = undefined;
	private _cacheRanged: Record<string, string[]> = {};
	private _cacheMatch: Record<string, string[]> = {};

	constructor(
		key: string,
		input: string | KVP,
		headers?: PaginatedFetchParams['headers'],
		overridePageOptions = false,
		body?: unknown,
	) {
		super();
		this._key = key;
		if (typeof input === 'string') {
			this.requestData(input, headers, overridePageOptions, body);
		} else {
			this._data = input;
		}
	}

	get key(): string {
		return this._key;
	}

	get data(): KVP | undefined {
		return this._data;
	}

	async requestData(
		url: string,
		headers?: PaginatedFetchParams['headers'],
		overridePageOptions = false,
		body?: unknown,
	): Promise<void> {
		this._fetch = new PaginatedFetch({
			url,
			headers,
			paramLimit: overridePageOptions ? 'limit' : 'slice',
			paramOffset: overridePageOptions ? 'offset' : 'page',
			startLimit: 10000,
		});
		try {
			const data = body
				? await this._fetch.fethPagesUsingPost<
						unknown,
						KVP | PaginatedKVP
				  >(body)
				: await this._fetch.fetchPages<KVP | PaginatedKVP>();
			if (data.length === 1) {
				if ('data' in data[0]) {
					this._data = data[0].data as KVP;
				} else {
					this._data = data[0] as KVP;
				}
			} else {
				this._data = (data as PaginatedKVP[]).reduce(
					(acc, val) => ({ ...acc, ...val.data }),
					{},
				);
			}
			this.fire(LOOKUP_COMPLETE);
		} catch (e) {
			this.errorCatch(
				e as DataServiceRequestError,
				'map_exception_lookup_requestData',
				'Cannot get lookup',
			);
		}
	}

	delete(): void {
		this._fetch?.cancel();
		this._data = undefined;
	}

	findInRange(min: number, max: number): string[] {
		if (!this.data) return [];

		const cacheKey = `${min}~${max}`;

		if (this._cacheRanged[cacheKey]) return this._cacheRanged[cacheKey];

		const ids = Object.entries(this.data)
			.map(([key, value]) => {
				const parsedValue = parseFloat(`${value}`);
				if (isNaN(parsedValue)) return;
				if (parsedValue >= min && parsedValue <= max) return key;
			})
			.filter(notUndefined);

		this._cacheRanged[cacheKey] = ids;
		return ids;
	}

	findMatch(find: number | string | boolean): string[] {
		if (!this.data) return [];

		const cacheKey = `${find}`;

		if (this._cacheMatch[cacheKey]) return this._cacheMatch[cacheKey];

		const ids = Object.entries(this.data)
			.map(([key, value]) => {
				if (value === find) return key;
			})
			.filter(notUndefined);

		this._cacheMatch[cacheKey] = ids;
		return ids;
	}

	getValueForId(id: string): string | number | boolean | undefined {
		return this.data?.[id];
	}

	protected errorCatch(
		e: DataServiceRequestError,
		messageId: string,
		errorMessage: string,
	): void {
		const context = {
			messageId,
			...extractErrorContext(e),
		};
		if (e.name !== 'AbortError') {
			logger.error(errorMessage, context);
		} else {
			logger.info('Data services request aborted', context);
		}
	}
}
