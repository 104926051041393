import { NamespacedStoreState } from '@Types/store.types';
import { createSelector } from '@reduxjs/toolkit';
import { moduleNamespace } from '@Store/config.store';
import { useSelector } from 'react-redux';

const parseNamespace = (state: NamespacedStoreState) => state[moduleNamespace];

const inspectionsSelector = createSelector(
	parseNamespace,
	state => state.inspections,
);

const inspectionsIsWaitingSelector = createSelector(
	parseNamespace,
	state => state.inspections?.isWaiting,
);

export const CreateInspectionIdSelector = createSelector(
	parseNamespace,
	state => state.inspections?.createInspectionId,
);

export const CreatingInspectionSelector = createSelector(
	parseNamespace,
	state => state.inspections?.creatingInspection,
);

export const selectInspections = () => useSelector(inspectionsSelector);

export const selectInspectionsIsWaiting = () =>
	useSelector(inspectionsIsWaitingSelector);

export const selectCreateInspectionId = () =>
	useSelector(CreateInspectionIdSelector);

export const selectCreatingInspection = () =>
	useSelector(CreatingInspectionSelector);

export default {
	selectInspectionsIsWaiting,
	selectInspections,
	selectCreateInspectionId,
	selectCreatingInspection,
};
