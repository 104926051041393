import {
	Avatar,
	Chip,
	CircularProgress,
	Grid,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { NotesWrapper } from './ObservationNotes.styles';
import { Note, NoteType, ObservationNotesProps } from '@Types';
import { formatDateTimeWithFormatDate } from '@Utils';
import { selectUserInitial } from '@Selectors/user.selectors';
import { useGlobalization } from '@Translations';
import { useCompanyDateTime } from '@innovyze/stylovyze';

const useStyles = makeStyles(() =>
	createStyles({
		messageBody: {
			height: (props: {
				bodyHeight: string;
				entryHeight: string;
				waitingHeight: string;
			}) => `${props.bodyHeight}`,
			overflow: 'auto',
		},
		waitingBody: {
			height: (props: {
				bodyHeight: string;
				entryHeight: string;
				waitingHeight: string;
			}) => `${props.waitingHeight}`,
			overflow: 'auto',
		},
		messageEntry: {
			padding: '0 25px 0 16px',
			height: (props: {
				bodyHeight: string;
				entryHeight: string;
				waitingHeight: string;
			}) => `${props.entryHeight}`,
		},
		listText: {
			display: 'grid',
			gridTemplateColumns: 'auto',
			gridTemplateRows: 'auto',
			gridTemplateAreas: '"date"	"content"',

			'&>.MuiListItemText-primary': {
				gridArea: 'content',
			},

			'&>.MuiListItemText-seconday': {
				gridArea: 'date',
			},
		},
		systemChip: {
			textTransform: 'capitalize',
			margin: '0 8px 0 0',
		},
	}),
);

export const ObservationNotes = ({
	Notes,
	dataCy,
	readOnly,
	isWaiting,
	keepHeightWhenWaiting,
	bodyHeight = '173px',
	entryHeight = '110px',
	waitingHeight = bodyHeight,
	onSubmit,
}: ObservationNotesProps): JSX.Element => {
	const classes = useStyles({
		bodyHeight: bodyHeight,
		entryHeight: entryHeight,
		waitingHeight: waitingHeight,
	});
	const { t } = useGlobalization();

	const [newNote, setNewNote] = useState('');
	const [validation, setValidation] = useState('');

	const userInitial = selectUserInitial();
	const { formatDate } = useCompanyDateTime();

	useEffect(() => {
		const obj = document.getElementsByClassName(classes.messageBody);
		if (obj.length > 0) {
			obj[0].scrollTop = obj[0].scrollHeight;
		}
	});

	const handleSystemNotes = (
		note: Note,
		formattedDateTime: string | null,
	) => {
		if (note.noteType === NoteType.SYSTEM)
			return (
				<>
					<Chip
						title={t('Automatically generated in Info360')}
						label={t(note.noteType)}
						size={'small'}
						className={classes.systemChip}
					/>
					<span>{formattedDateTime}</span>
				</>
			);
		return <span>{formattedDateTime}</span>;
	};

	const translateInspectionStatus = (note: Note): string => {
		if (note.noteType === NoteType.SYSTEM) {
			const systemNotes = {
				STATUS_CHANGE: 'Status Changed to',
			};
			// Handle Inspection Status Change note
			if (note.note.startsWith(systemNotes.STATUS_CHANGE)) {
				// Retrieve status from note text
				const status = (
					note.note.split('Status Changed to')[1] || ''
				).trim();
				return `${t(systemNotes.STATUS_CHANGE)} ${t(status)}`;
			} else {
				return t(note.note);
			}
		}
		return note.note;
	};

	if (isWaiting) {
		if (keepHeightWhenWaiting) {
			return (
				<Grid container>
					<Grid item xs={12} className={classes.waitingBody}>
						<CircularProgress />
					</Grid>
				</Grid>
			);
		} else {
			return <CircularProgress />;
		}
	} else {
		return (
			<NotesWrapper container>
				<Grid item xs={12} className={classes.messageBody}>
					<List data-cy={dataCy}>
						{Notes?.filter(
							note => note._id && note._id.length > 0,
						).map((Note, index) => (
							<ListItem
								alignItems="flex-start"
								key={Note._id}
								data-cy={dataCy + '-' + index}>
								<ListItemAvatar>
									<Avatar
										title={Note.userName}
										data-cy={
											dataCy + '-' + index + '-Avatar'
										}>
										{Note.userInitial.toUpperCase()}
									</Avatar>
								</ListItemAvatar>
								<ListItemText
									className={classes.listText}
									primary={
										<div
											style={{
												overflowWrap: 'anywhere',
											}}>
											{translateInspectionStatus(Note)}
										</div>
									}
									secondary={handleSystemNotes(
										Note,
										formatDateTimeWithFormatDate(
											Note.dateTime,
											formatDate,
										),
									)}
									data-cy={dataCy + '-' + index + '-Item'}
								/>
							</ListItem>
						))}
					</List>
				</Grid>
				{!readOnly ? (
					<Grid
						item
						xs={12}
						className={classes.messageEntry}
						container
						spacing={1}
						alignItems="center">
						<Grid item>
							<Avatar data-cy={dataCy + '-Avatar'}>
								{userInitial}
							</Avatar>
						</Grid>
						<Grid item xs style={{ padding: '12px' }}>
							<form
								noValidate
								autoComplete="off"
								onSubmit={event => {
									event.preventDefault();
									if (newNote.trimEnd().length == 0) {
										setValidation(t('Enter a note'));
									} else {
										setValidation('');

										if (onSubmit)
											onSubmit(newNote, userInitial);

										setNewNote('');
									}
								}}>
								<TextField
									error={validation.length != 0}
									helperText={validation}
									fullWidth
									value={newNote}
									onChange={event =>
										setNewNote(event.target.value)
									}
									id="input-with-icon-grid"
									label={t('Notes')}
									placeholder={t('Add Notes')}
									variant="outlined"
									data-cy={dataCy + '-Text'}
								/>
							</form>
						</Grid>
					</Grid>
				) : (
					[]
				)}
			</NotesWrapper>
		);
	}
};

export default ObservationNotes;
