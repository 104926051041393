import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import {
	selectAreDataTypesLoaded,
	selectDataTypes,
	selectSystemTypes,
} from '@Selectors/asset';
import { useSelector } from 'react-redux';
import { NamespacedStoreState } from '@Types/store.types';

const getDefaultSystem = (systemTypes: string[]) => {
	return systemTypes.length === 1 ? systemTypes[0] : '';
};

export const useSystemType = (
	isSpatialDataEnabled: boolean,
): [string, Dispatch<SetStateAction<string>>] => {
	const systemTypes = useSelector<NamespacedStoreState, string[]>(state =>
		isSpatialDataEnabled
			? selectDataTypes(state)
			: selectSystemTypes(state),
	);
	const areDataTypesLoaded = useSelector<NamespacedStoreState, boolean>(
		state => selectAreDataTypesLoaded(state, Boolean(isSpatialDataEnabled)),
	);

	const defaultSystem = getDefaultSystem(systemTypes);
	const [state, setState] = useState(defaultSystem);

	useEffect(() => {
		if (areDataTypesLoaded && !state) {
			setState(getDefaultSystem(systemTypes));
		}
	}, [systemTypes]);
	return [state, setState];
};
