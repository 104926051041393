import {
	AssetRiskAndCondition,
	AssetRiskHistory,
	AssetRiskParams,
	RiskSetting,
	RiskSummary,
} from '@Types/asset.types';

import { createAction } from '@reduxjs/toolkit';
import { AssetType } from '@Types/map.types';

export const getAssetRisk = createAction<AssetRiskParams>(
	'assets/getAssetRisk',
);
export const getAssetRiskResolved = createAction<AssetRiskAndCondition>(
	'assets/getAssetRisk/resolved',
);
export const getAssetRiskWaiting = createAction<boolean>(
	'assets/getAssetRisk/waiting',
);
export const getAssetRiskRejected = createAction(
	'assets/getAssetRisk/rejected',
);

export const getAssetRiskHistory = createAction<AssetRiskParams>(
	'assets/getAssetRiskHistory',
);
export const getAssetRiskHistoryResolved = createAction<AssetRiskHistory[]>(
	'assets/getAssetRiskHistory/resolved',
);
export const getAssetRiskHistoryRejected = createAction(
	'assets/getAssetRiskHistory/rejected',
);

export const getRiskSetting = createAction('assets/getRiskSetting');
export const getRiskSettingResolved = createAction<RiskSetting>(
	'assets/getRiskSetting/resolved',
);
export const getRiskSettingRejected = createAction(
	'assets/getRiskSetting/rejected',
);

export const setRiskSummaryIsLoading = createAction<boolean>(
	'assets/setRiskSummaryIsLoading',
);

export const getRiskSummary = createAction<{
	assetId: string;
	assetType: string;
}>('assets/getRiskSummary');
export const getRiskSummaryResolved = createAction<RiskSummary[]>(
	'assets/getRiskSummary/resolved',
);
export const getRiskSummaryRejected = createAction(
	'assets/getRiskSummary/rejected',
);
