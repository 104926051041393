import { InspectionStandard } from 'index';
import { MACPConnection, Note, Observation, SAASObject } from '.';
import { Geometry } from '@innovyze/shared-utils';
import { InspectionRating } from './inspectionAttributes.types';
import { TFunc } from '@Translations/types';

export interface FullInspectionStoreState {
	fullInspection: FullInspection;
	notes?: Note[];
	isWaiting: boolean;
	notesIsWaiting: boolean;
}

// Base interface for all inspections
export interface FullInspection {
	_id: string;
	inspectionId: string;
	assetId?: string;
	projectId?: string;
	uploadedBy: string;
	uploadDateTime: string;
	scheduledDate?: string | SAASObject;
	surveyDateTime?: string;
	inspectionDate?: string;
	inspectionTime?: string;
	surveyedBy?: string;
	submittedBy?: string;
	preCleaning?: string;
	dateCleaned?: string;
	missingMedia: boolean;
	isImperial: boolean;
	tenantId: string;
	inspectionStatus: string;
	inspectionVersion?: string;
	inspectionType?: string;
	validationState: string;
	notes?: Note[];
	validationData?: ValidationData[];
	mediaInspections?: [
		{
			mediaId: string;
			inspectionId: number;
			videoName: string;
			videoLocation: string;
		},
	];
	conditions?: Observation[];
	geometry?: Geometry | null;
	ratings?: InspectionRating;
	boundaryConditionId?: number | null;
}

export interface PACPFullInspection extends FullInspection {
	certificateNumber: string;
	owner: string;
	customer: string;
	reviewedBy: string;
	reviewerCertificateNumber: string;
	drainageArea: string;
	poNumber: string;
	pipelineRef: string;
	pipeSegmentReference?: string;
	date: string;
	time: string;
	street: string;
	city: string;
	locationDetails: string;
	upstreamManholeId: string;
	upRimToInvert: string;
	upGradeToInvert: string;
	upRimToGrade: string;
	downstreamManholeId: string;
	downRimToInvert: string;
	downGradeToInvert: string;
	downRimToGrade: string;
	sewerUse: string;
	direction: string;
	inspectionTechnologyUsedCCTV: boolean;
	inspectionTechnologyUsedLaser: boolean;
	inspectionTechnologyUsedSonar: boolean;
	inspectionTechnologyUsedSidewall: boolean;
	inspectionTechnologyUsedZoom: boolean;
	inspectionTechnologyUsedOther: boolean;
	flowControl: string;
	height: string;
	width: string;
	shape: string;
	material: string;
	liningMethod: string;
	coatingMethod: string;
	pipeJointLength: string;
	totalLength: string;
	lengthSurveyed: string;
	yearConstructed: string;
	yearRenewed: string;
	mediaLabel: string;
	purpose: string;
	sewerCategory: string;
	weather: string;
	locationCode: string;
	additionalInfo: string;
	reverseSetup: string;
	sheetNumber: string;
	pressureValue: string;
	workOrder: string;
	project: string;
	upNorthing: string;
	upEasting: string;
	upElevation: string;
	downNorthing: string;
	downEasting: string;
	downElevation: string;
	mhCoordinateSystem: string;
	verticalDatum: string;
	gpsAccuracy: string;
	status: string;
	exported: string;
	projectId: string;
	consequenceOfFailure: string;
	yearLaid: string;
	northing: string;
	easting: string;
	elevation: string;
	coordinateSystem: string;
}

export interface MACPComponentCondition {
	holeNumber: string;
	coverFit: string;
	coverCondition: string;
	coverInsertCondition: string;
	adjustPingCondition: string;
	frameCondition: string;
	sealCondition: string;
	frameOffset: string;
	frameSealInflow: string;
	chimney: string;
	pipeCondition: string;
}

// NOTE: At the moment all data is string so we can substitue the -- for a empty/null value.
// This will have to change when editing is allowed to go back to the correct types | undefined

export interface MACPFullInspection extends FullInspection {
	surveyedBy: string;
	certificateNumber: string;
	owner: string;
	customer: string;
	drainageArea: string;
	sheetNumber: string;
	poNumber: string;
	date: string;
	time: string;
	street: string;
	city: string;
	locationDetails: string;
	manholeNumber: string;
	rimToInvert: string;
	gradeToInvert: string;
	rimToGrade: string;
	mhUse: string;
	yearBuilt: string;
	yearRenewed: string;
	mediaLabel: string;
	purpose: string;
	category: string;
	weather: string;
	locationCode: string;
	additionalInfo: string;
	surfaceTypeAsphalt: string;
	surfaceTypeConcretePavement: string;
	surfaceTypeConcreteCollar: string;
	surfaceTypeGrassDirt: string;
	surfaceTypeGravel: string;
	surfaceTypeOther: string;
	potentialForRunoff: string;
	accessType: string;
	northing: string;
	easting: string;
	elevation: string;
	coordinateSystem: string;
	gpsAccuracy: string;
	inspectionStatus: string;
	evidenceSurcharge: string;
	coverShape: string;
	coverSize: string;
	coverSizeWidth: string;
	coverMaterial: string;
	coverTypeSolid: string;
	coverTypeVented: string;
	coverTypeGasketed: string;
	coverTypeBolted: string;
	coverTypeInnerCover: string;
	coverTypeLocking: string;
	coverTypeHatchSingle: string;
	coverTypeHatchDouble: string;
	coverTypeLamphole: string;
	holeDiameter: string;
	holeNumber: string;
	coverBearingSurfaceDia: string;
	coverBearingSurfaceDiaWidth: string;
	coverFrameFit: string;
	coverConditionSound: string;
	coverConditionCracked: string;
	coverConditionBroken: string;
	coverConditionCorroded: string;
	coverConditionBoltsMissing: string;
	coverConditionMissing: string;
	coverConditionRestraintMissing: string;
	coverConditionRestraintDefective: string;
	coverInsertType: string;
	insertConditionSound: string;
	insertConditionPoorlyFitting: string;
	insertConditionCracked: string;
	insertConditionLeaking: string;
	insertConditionInsertFell: string;
	insertConditionCorroded: string;
	adjustmentRingType: string;
	adjustmentRingMaterial: string;
	ringConditionSound: string;
	ringConditionCracked: string;
	ringConditionBroken: string;
	ringConditionCorroded: string;
	ringConditionLeaking: string;
	ringConditionPoorInstall: string;
	adjustmentRingHeight: string;
	frameMaterial: string;
	frameBearingSurfaceWidth: string;
	frameBearingSurfaceDepth: string;
	frameClearOpenDiam: string;
	frameConditionSound: string;
	frameConditionCracked: string;
	frameConditionBroken: string;
	frameConditionMissing: string;
	frameConditionCorroded: string;
	frameConditionCoated: string;
	sealConditionSound: string;
	sealConditionCracked: string;
	sealConditionLoose: string;
	sealConditionOffset: string;
	sealConditionMissing: string;
	frameOffsetDistance: string;
	frameSealInflow: string;
	frameDepth: string;
	chimneyMaterial1: string;
	chimneyMaterial2: string;
	chimneyInI: string;
	chimneyClearOpening: string;
	chimneyDepth: string;
	chimneyLiningInterior: string;
	chimneyLiningExterior: string;
	coneType: string;
	coneMaterial: string;
	coneDepth: string;
	coneLiningInterior: string;
	coneLiningExterior: string;
	wallDiam: string;
	wallBySize: string;
	wallMaterial: string;
	wallDepth: string;
	wallLiningInterior: string;
	wallLiningExterior: string;
	benchPresent: string;
	benchMaterial: string;
	benchLining: string;
	channelInstalled: string;
	channelMaterial: string;
	channelType: string;
	channelExposure: string;
	stepNumber: string;
	stepMaterial: string;
	workOrder: string;
	project: string;
	pressureValue: string;
	additionalComponentInfo: string;
	inspectionLevel: string;
	reviewedBy?: string;
	reviewerCertificateNumber?: string;
	consequenceOfFailure?: string;
	inflowPotentialFromRunoff?: string;
	yearConstructed?: string;
	rimToGradeExposed?: string;
	verticalDatum?: string;
	coverTypeRemovableCenter?: string;
	centerCoverSize?: string;
	coverBearingSurfaceDiameter?: string;
	coverBearingSurfaceWidth?: string;
	frameClearOpenDiameter?: string;
	frameClearOpenWidth?: string;
	chimneyPresent?: string;
	chimneyIni?: string;
	chimneyCondition?: string;
	coneCondition?: string;
	wallDiameter?: string;
	wallCondition?: string;
	benchCondition?: string;
	channelCondition?: string;
	status: string;
	exported: string;
	connections?: MACPConnection[];
	componentConditions?: {
		name: string;
		value: string;
		serviceScore: number;
		structuralScore: number;
	}[];
}

export interface MSCCFullInspection extends FullInspection {
	client: string;
	surveyedBy: string;
	clientsJobRef: string;
	contractorsJobRef: string;
	date: string;
	time: string;
	useOfDrainSewer: string;
	typeOfDrainSewer: string;
	direction: string;
	precleaned: string;
	criticalDrainSewer: string;
	purposeOfInspection: string;
	inspectionStage: string;
	flowControlMeasures: string;
	weather: string;
	temperature: string;
	expectedLength: string;
	methodOfInspection: string;
	standard: string;
	clientDefined1: string;
	clientDefined2: string;
	clientDefined3: string;
	clientDefined4: string;
	clientDefined5: string;
	clientDefined6: string;
	generalRemarks: string;
	drainageArea: string;
	divisionDistrict: string;
	locationStreet: string;
	locationTown: string;
	locationTypeCode: string;
	landOwner: string;
	upstreamManholeId: string;
	downstreamManholeId: string;
	pipelineLengthRef: string;
	heightDiameter: string;
	width: string;
	pipeUnitLength: string;
	shape: string;
	material: string;
	liningMaterial: string;
	liningType: string;
	yearConstructed: string;
	longLocStartLat: string;
	circLocStartLat: string;
	startNodeRef: string;
	depthAtStartNode: string;
	startNodeGridRef: { startNodeGridRefX: string; startNodeGridRefY: string };
	startNodeGridRefX: string; // Extracted from startNodeGridRef
	startNodeGridRefY: string; // Extracted from startNodeGridRef
	node1Ref: string;
	node1GridRef: { node1GridRefX: string; node1GridRefY: string };
	node1GridRefX: string; // Extracted from node1GridRef
	node1GridRefY: string; // Extracted from node1GridRef
	finishNodeRef: string;
	depthAtFinishNode: string;
	node2GridRef: { node2GridRefX: string; node2GridRefY: string };
	node2GridRefX: string; // Extracted from node2GridRef
	node2GridRefY: string; // Extracted from node2GridRef
	node3Ref: string;
	node3GridRef: { node3GridRefX: string; node3GridRefY: string };
	node3GridRefX: string; // Extracted from node3GridRef
	node3GridRefY: string; // Extracted from node3GridRef
	videoImageStorage: string;
	videoImageLocationSystem: string;
	videoImageFormat: string;
	videoImageFilename: string;
	videoImageVolume: string;
	photographicStorageFormat: string;
	photographicStorage: string;
	observations?: Observation[]; // THIS IS MOVED TO conditions WHEN FECTHED FOR SIMPLICITY
}

export interface WSA05FullInspection extends FullInspection {
	pipelineRef: string;
	startNodeRef: string;
	startNodeCoordinates: string;
	finishNodeRef: string;
	finishNodeCoordinates: string;
	longLocStartLat: string;
	circLocStartLat: string;
	location: string;
	direction: string;
	locationTypeCode: string;
	assetOwnerOrEngagingAgency: string;
	locationTown: string;
	divisionDistrict: string;
	nameOfConduitSystem: string;
	landOwner: string;
	mappingGridDatumSystem: string;
	mappingGridZone: string;
	standard: string;
	originalCodingStandard: string;
	longitudinalReferencePoint: string;
	conduitLocationRiskFactorObservedByOperator: string;
	methodOfInspection: string;
	operator: string;
	companyJobRef: string;
	assetOwnerJobRef: string;
	coderAssessor: string;
	videoImageLocationSystem: string;
	evidenceOfSurchargeInStartNode: string;
	purposeOfInspection: string;
	anticipatedLength: string;
	actualLength: string;
	nameOfCompanyResponsibleForInspection: string;
	shape: string;
	height: string;
	width: string;
	material: string;
	liningType: string;
	liningMaterial: string;
	conduitUnitLength: string;
	depthAtStartNode: string;
	depthAtFinishNode: string;
	operationOfConduit: string;
	useOfConduit: string;
	criticality: string;
	cleaning: string;
	yearCameIntoOperation: string;
	jointingMethod: string;
	precipitation: string;
	temperature: string;
	flowControlMeasures: string;
	tidalInfluence: string;
	generalComment: string;
	userDefined1: string;
	userDefined2: string;
	userDefined3: string;
	userDefined4: string;
	userDefined5: string;
}

// This is not technically true as it only has a few mandatory fields
export interface TaskInspection extends FullInspection {
	taskId: string;
	eventId: string;
	relatedAssetId: string;
	completedDate: unknown;
}

export interface ValidationData {
	target: string;
	type: string;
	errorCode: string;
	errorMessage: string;
}

export interface GetFullInspParams {
	inspectionId: string;
}

export interface GetFullInspResolvedParams {
	inspection: FullInspection[];
	notes?: Note[];
}

export interface InspectionOverview {
	standard: InspectionStandard;
	count?: number;
	missingMedia?: number;
	inReview?: number;
}

export interface GetInspectionOverviewResolvedParams {
	overview: InspectionOverview[];
}

export const taskIgnoreFields: string[] = [
	'_id',
	'taskId',
	'assetId',
	'relatedAssetId',
	'completedDate',
	'scheduledDate',
	'inspectionStatus',
	'inspectionVersion',
	'taskTypeName',
	'task',
	'tenantId',
	'uploadedById',
	'uploadedBy',
	'statusSetByUserId',
	'statusSetByUserName',
	'statusSetDate',
	'validationState',
];

export const eventIgnoreFields: string[] = [
	'_id',
	'eventId',
	'assetId',
	'relatedAssetId',
	'openedDate',
	'reportedDate',
	'verifiedDate',
	'resolvedDate',
	'closedDate',
	'status',
	'inspectionStatus',
	'inspectionVersion',
	'eventTypeName',
	'event',
	'tenantId',
	'uploadedById',
	'uploadedBy',
	'statusSetByUserId',
	'statusSetByUserName',
	'statusSetDate',
	'validationState',
];

export const getTaskMandatoryFields = (t: TFunc): { [key: string]: string } => {
	return {
		taskId: t('Task ID'),
		relatedAssetId: t('Asset ID'),
		scheduledDate: t('Scheduled Date'),
		completedDate: t('Completed Date'),
	};
};

export const getTaskOptionalFields = (t: TFunc): { [key: string]: string } => {
	return {
		distance: t('Distance'),
		lat: t('Lat (WGS84)'),
		long: t('Long (WGS84)'),
		startChainage: t('Start Chainage'),
		uploadedBy: t('Uploaded By'),
	};
};

export const getEventMandatoryFields = (
	t: TFunc,
): { [key: string]: string } => {
	return {
		eventId: t('Event ID'),
		relatedAssetId: t('Asset ID'),
		openedDate: t('Opened Date'),
		reportedDate: t('Reported Date'),
		verifiedDate: t('Verified Date'),
		resolvedDate: t('Resolved Date'),
		closedDate: t('Closed Date'),
	};
};

export const getEventOptionalFields = (t: TFunc): { [key: string]: string } => {
	return {
		lat: t('Lat (WGS84)'),
		long: t('Long (WGS84)'),
		uploadedBy: t('Uploaded By'),
	};
};
