import { InspectionStandard } from '@Types';

export const isPipeStandard = (standard: InspectionStandard): boolean =>
	[
		InspectionStandard.WSA05,
		InspectionStandard.PACP,
		InspectionStandard.MSCC,
	].includes(standard);
export const isManholeStandard = (standard: InspectionStandard): boolean =>
	standard === InspectionStandard.MACP;
