import { AnyAction, createReducer } from '@reduxjs/toolkit';
import {
	clearPagination,
	updateGet,
	updateIsBusyCards,
	updatePage,
	updateRejected,
	updateResolvedRiskConfigCard,
	updateSlice,
	updateSort,
} from '@Reducers';
import { PaginationState } from '@Types';
import {
	getRiskConfigs,
	getRiskConfigsResolved,
	getRiskConfigsRejected,
	changeRiskConfigsPage,
	changeRiskConfigsSlice,
	changeRiskConfigsSort,
	clearRiskConfigs,
	setRiskConfigsIsBusy,
} from '@Actions';

// I HAVE NO IDEA WHY I HAVE TO DUPLICATE THIS HERE... without it I get this error when runnin in a module
// Uncaught Error: The slice reducer for key "riskConfigs" returned undefined during initialization. If the state passed to the reducer is undefined, you must explicitly return the initial state.
// It seems the paginationInitialState is getting initilized AFTER the reducers but I cant see why
const paginationInitialState: PaginationState = {
	query: '',
	status: { code: 800, text: 'Uncalled' },
	pagination: {
		total: 0,
		subtotal: 0,
		slice: 10,
		pages: 1,
		page: 1,
	},
	rows: [],
	cards: [],
	selectedDbIds: [],
	currentDbId: '',
	isLoading: false,
	isWaiting: false,
};

interface RiskConfigsReducer {
	[x: string]: (
		state: PaginationState,
		action: AnyAction,
	) => PaginationState | undefined;
}

export const reducer: RiskConfigsReducer = {
	[getRiskConfigs.toString()]: (state, action) =>
		updateGet(state, action.payload),
	[clearRiskConfigs.toString()]: (state, action) =>
		clearPagination(state, action.payload),
	[getRiskConfigsResolved.toString()]: (state, action) =>
		updateResolvedRiskConfigCard(state, action.payload),
	[getRiskConfigsRejected.toString()]: (state, action) =>
		updateRejected(state, action.payload),
	[changeRiskConfigsPage.toString()]: (state, action) =>
		updatePage(state, action.payload),
	[changeRiskConfigsSlice.toString()]: (state, action) =>
		updateSlice(state, action.payload),
	[changeRiskConfigsSort.toString()]: (state, action) =>
		updateSort(state, action.payload),
	[setRiskConfigsIsBusy.toString()]: (state, action) =>
		updateIsBusyCards(state, action.payload),
};

export const riskConfigsReducer = createReducer(
	paginationInitialState,
	reducer,
);

export default createReducer(paginationInitialState, reducer);
