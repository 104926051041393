import { ConfigLayer, ConfigLayerInternal } from '@Map/types';
import {
	ConfigLayerInternalWithDefaults,
	addDefaults,
} from '@Map/layers/settings';
import { TFunction, useGlobalization } from '@Translations/useGlobalization';
import { useCallback, useEffect, useState } from 'react';

import { useDebouncedCallback } from './useDebouncedCallback';

const translateProps = (
	layer: ConfigLayerInternal | undefined,
	t: TFunction,
): ConfigLayerInternal | undefined => {
	if (layer == undefined) return;
	const { layerName, ...other } = layer;
	return {
		layerName: t(layerName) as string,
		...other,
	};
};

type SetPropCallback = <T extends keyof ConfigLayerInternal>(
	key: T,
	value: ConfigLayerInternal[T],
) => void;

export const useLayerProperties = (
	layer: ConfigLayerInternal | undefined,
	onChange?: (layer: Partial<ConfigLayer>) => void,
) => {
	const { t } = useGlobalization();
	const [config, setConfig] = useState<ConfigLayerInternalWithDefaults>(
		addDefaults(translateProps(layer, t)),
	);
	const debounceCallback = useDebouncedCallback(value => {
		onChange?.(value);
	}, 300);

	const setProp: SetPropCallback = useCallback((key, value) => {
		setConfig(currentConfig => {
			const newValue = { ...currentConfig, [key]: value };
			debounceCallback(newValue);
			return newValue;
		});
	}, []);

	const setMultiProps = useCallback((props: Partial<ConfigLayerInternal>) => {
		setConfig(currentConfig => {
			const newValue = { ...currentConfig, ...props };
			debounceCallback(newValue);
			return newValue;
		});
	}, []);

	useEffect(() => {
		setConfig(addDefaults(translateProps(layer, t)));
	}, [layer]);

	return { ...config, setProp, setMultiProps };
};
