import * as React from 'react';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Stable Ref Hook
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const useStableEventHandlers = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  EventHandlers extends Record<string, any>,
>(
  eventHandlers: EventHandlers
): React.MutableRefObject<EventHandlers> => {
  const eventHandlersRef = React.useRef<EventHandlers>(eventHandlers);
  eventHandlersRef.current = eventHandlers;

  return eventHandlersRef;
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Module Exports
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export { useStableEventHandlers };
