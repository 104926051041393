import {
	FormControl,
	FormControlLabel,
	FormHelperText,
	RadioGroup,
	RadioGroupProps,
} from '@mui/material';
import { SmallFormLegend, StyledRadioButton } from './LayerProperties.styles';

import { ColorByOptions } from '@Map/types';
import React from 'react';
import { useGlobalization } from '@Translations/useGlobalization';

interface ColorBySelectorProps {
	value: string;
	colorByOption: ColorByOptions;
	onChange: RadioGroupProps['onChange'];
	attributeDisabled?: boolean;
	attributesNotFound?: boolean;
}

export const ColorBySelector = ({
	value,
	colorByOption,
	onChange,
	attributeDisabled,
	attributesNotFound,
}: ColorBySelectorProps): JSX.Element => {
	const { t } = useGlobalization();

	return (
		<FormControl component="fieldset">
			<SmallFormLegend>
				{t('Color by', {
					context: 'Layer properties editor form field',
				})}
			</SmallFormLegend>
			<RadioGroup
				aria-label={t('Color by', {
					context: 'Layer properties editor form field',
				})}
				name="color_by"
				value={value}
				onChange={onChange}
				row>
				<FormControlLabel
					value={ColorByOptions.fixed}
					control={<StyledRadioButton data-cy="color-by-fixed" />}
					label={t('Fixed Color', {
						context: 'Layer properties editor form field',
					})}
				/>
				{colorByOption === ColorByOptions.attribute && (
					<FormControlLabel
						value={ColorByOptions.attribute}
						control={
							<StyledRadioButton data-cy="color-by-attribute" />
						}
						label={t('Attribute', {
							context: 'Layer properties editor form field',
						})}
						disabled={attributeDisabled}
					/>
				)}
				{colorByOption === ColorByOptions.range && (
					<FormControlLabel
						value={ColorByOptions.range}
						control={<StyledRadioButton data-cy="color-by-range" />}
						label={t('Range', {
							context: 'Layer properties editor form field',
						})}
					/>
				)}
			</RadioGroup>
			{attributesNotFound && (
				<FormHelperText
					variant="outlined"
					data-cy="color-by-attributes-helper-text">
					{t('No attributes found', {
						context:
							'Layer properties editor form field helper text',
					})}
				</FormHelperText>
			)}
		</FormControl>
	);
};
