import * as React from 'react';
import axios from 'axios';
import cookies from 'browser-cookies';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useAxios(baseURL: string, options?: { timeout?: number }) {
  const controller = React.useRef(new AbortController());
  const _axios = React.useRef(
    axios.create({
      baseURL,
      timeout: options?.timeout ?? 10_000,
      signal: controller.current.signal,
    })
  );

  React.useLayoutEffect(() => {
    const token = 'Bearer ' + cookies.get('auth0.user.token');

    _axios.current.interceptors.request.use((config) => {
      config.headers.Authorization = token;
      return config;
    }, Promise.reject);

    return () => controller.current.abort();
  }, []);

  return _axios;
}
