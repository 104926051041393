import { nanoid } from 'nanoid';
import * as Chart from '../../core/chart';
import * as Hooks from './pumpRuntime-chart.hooks';
import * as React from 'react';
import * as LibUtils from '../../core/lib-utils';
import * as Utils from './pumpRuntime-chart.utils';

import type { ReactElement } from 'react';
import type { EventHandlers } from './pumpRuntime-chart.utils';
import type { Status } from '../../core/_chart';
import { Theme } from '../../core/utils/theme-utils';

// const RootPropsContext = React.createContext<MutableRefObject<RootProps>>({
// 	current: { elements: { series: [] } },
// });

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 *	Root Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

type PumpRuntimeChartRootProps = {
  elements: { series: React.ReactNode[] };
  displayType: 'hours' | 'percentage';
  categories: string[];
  xAxis?: { enableGridlines?: boolean };
  yAxis?: { enableGridlines?: boolean };
  status?: Status;
  selectedTheme?: Theme;
  error?: Error;
  events?: {
    series?: {
      onVisibilityChange?: (index: number, type: 'hide' | 'show') => void;
    };
  };
};

const PumpRuntimeChartRoot = Chart.withRoot(
  // eslint-disable-next-line react/display-name
  React.forwardRef<
    { chart: Highcharts.Chart | undefined },
    PumpRuntimeChartRootProps
  >((props, ref): ReactElement => {
    // const propsRef = LibUtils.useStableRef(props);
    const eventHandlers = LibUtils.useStableRef<EventHandlers>(props.events);

    Hooks.useYAxis(props);
    Hooks.useCategories(props);

    Hooks.useXAxisGridlines(props);
    Hooks.useYAxisGridlines(props);
    Hooks.useTheme(props);
    Hooks.useStatus(props);

    return (
      <Chart.Instance
        id="overlay"
        ref={ref}
        initialOptions={Utils.makeRuntimeOptions(eventHandlers)}>
        {props.elements?.series}
      </Chart.Instance>
    );
  })
);

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Series Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

type PumpRuntimeChartSeriesProps = {
  id?: string;
  index: number;
  name: string;
  color?: string;
  data?: (number | string)[];
  hidden?: boolean;
  selectedTheme?: Theme;
};

const PumpRuntimeChartSeries = (props: PumpRuntimeChartSeriesProps): null => {
  const id = React.useRef(props.id ?? nanoid());

  Hooks.useSeriesCreateRemove(id, props);

  Hooks.useSeriesData(id, props);

  Hooks.useSeriesColor(id, props);
  Hooks.useSeriesName(id, props);
  Hooks.useSeriesVisibility(id, props);

  return null;
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export { PumpRuntimeChartRoot, PumpRuntimeChartSeries };

export type { PumpRuntimeChartRootProps, PumpRuntimeChartSeriesProps };
