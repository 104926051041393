export enum MEASUREMENT_SYSTEM {
	METRIC = 'Metric',
	IMPERIAL = 'Imperial',
}

export enum SYSTEM_UNIT_TYPE {
	LENGTH_LONG = 'LengthLong',
	LENGTH = 'Length',
	VOLUME = 'Volume',
	PRESSURE = 'Pressure',
	FLOW = 'Flow',
	ANGLE = 'Angle',
	AREA = 'Area',
	POWER = 'Power',
	ROTATION = 'Rotation',
	VELOCITY = 'Velocity',
	ELECTRIC_CURRENT = 'ElectricCurrent',
	STRING = 'String',
}

export enum UNITS {
	DEGREE = 'degree',
	CUBIC_METER_PER_SECOND = 'm3/s',
	CUBIC_FEET_PER_SECOND = 'ft3/s',
	MILLIMETER = 'mm',
	METER = 'm',
	METER_OF_HEAD = 'mH2O',
	CUBIC_METER = 'm3',
	FOOT = 'foot',
	INCH = 'inch',
	PSI = 'psi',
	METER_SQUARE = 'm2',
	KILO_WATT = 'kw',
	REVOLUTIONS_PER_MIN = 'rpm',
	METER_PER_SECOND = 'm/s',
	LITRE_PER_SECOND = 'l/s',
	CUBIC_FEET = 'ft3',
	FEET_SQUARE = 'ft2',
	FEET_PER_SECOND = 'ft/s',
	AMPS = 'A',
	RPM = 'rpm',
	NONE = '',
}

export const unitMapping = (fieldUnitType: string): string => {
	switch (fieldUnitType) {
		case SYSTEM_UNIT_TYPE.LENGTH_LONG:
			return UNITS.METER;
		case SYSTEM_UNIT_TYPE.LENGTH:
			return UNITS.MILLIMETER;
		case SYSTEM_UNIT_TYPE.VOLUME:
			return UNITS.CUBIC_METER;
		case SYSTEM_UNIT_TYPE.PRESSURE:
			return UNITS.METER_OF_HEAD;
		case SYSTEM_UNIT_TYPE.FLOW:
			return UNITS.CUBIC_METER_PER_SECOND;
		case SYSTEM_UNIT_TYPE.ANGLE:
			return UNITS.DEGREE;
		case SYSTEM_UNIT_TYPE.AREA:
			return UNITS.METER_SQUARE;
		case SYSTEM_UNIT_TYPE.POWER:
			return UNITS.KILO_WATT;
		case SYSTEM_UNIT_TYPE.VELOCITY:
			return UNITS.METER_PER_SECOND;
		case SYSTEM_UNIT_TYPE.ELECTRIC_CURRENT:
			return UNITS.AMPS;
		case SYSTEM_UNIT_TYPE.ROTATION:
			return UNITS.RPM;
		default:
			return UNITS.NONE;
	}
};
