import { getApiEnvironment, getService } from '@innovyze/stylovyze';

import axios from 'axios';
import config from './config.api';
import cookies from 'browser-cookies';
import { formatUrlTrailing } from '@Utils/urlFormatting';

export const rehabBaseUrl = formatUrlTrailing(
	getService('amRehab', config, getApiEnvironment()),
);

const options = {
	baseURL: getService('amRehab', config, getApiEnvironment()),
	timeout: 15000,
};

const rehabService = axios.create(options);

rehabService.interceptors.request.use(
	conf => {
		conf.headers.set(
			'Authorization',
			'Bearer ' + cookies.get('auth0.user.token'),
		);
		return conf;
	},
	error => {
		Promise.reject(error);
	},
);

export default rehabService;
