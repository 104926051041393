import { createStyles, makeStyles, withStyles } from '@mui/styles';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

export const useStyles = makeStyles(() =>
	createStyles({
		root: {
			width: '100%',
			paddingTop: '21px',
		},
		heading: {
			fontWeight: 600,
			fontSize: '18px',
			lineHeight: '24px',
			color: '#272833',
		},
		expandText: {
			fontWeight: 600,
			fontSize: '14px',
			lineHeight: '20px',
			color: '#4A6067',
		},
	}),
);

// eslint-disable-next-line @typescript-eslint/naming-convention
export const AccordionSummary = withStyles({
	root: {
		borderBottom: '1px solid #F1F4F4',
		//   marginBottom: -1,
		minHeight: '56px',
		'&$expanded': {
			minHeight: '56px',
		},
	},
	content: {
		'&$expanded': {
			margin: '12px 0',
		},
	},
	expanded: {},
})(MuiAccordionSummary);

export const AccordionDetails = withStyles({
	root: {
		paddingTop: '18px',
	},
})(MuiAccordionDetails);
