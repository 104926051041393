import {
	DataServiceType,
	Features,
	GeoJsonInputType,
	GeoJsonInputTypes,
} from '@Map/services/types';
import { notUndefined, santizeString } from '@Map/utils';

import AssetRegistry from '@Map/services/AssetRegistry';
import { ConfigLayer } from '@Root';
import { SystemTypes } from '@Map/services/Info360TileTypes';
import TileConfigAdaptor from './TileConfigAdaptor';
import { extractionAsText } from '@Translations/extraction';

export default class AssetConfigAdaptor {
	static creator(
		dataService: DataServiceType<GeoJsonInputTypes>,
		systemType = SystemTypes.WaterDistribution,
	): ConfigLayer {
		if (!dataService.data) {
			throw new Error(
				'This config adaptor can only be used with embedded data',
			);
		}
		const layerName = TileConfigAdaptor.systemTypeAsName(systemType);
		const data = dataService.data as GeoJsonInputType;
		if (!data) {
			return {
				layerName,
				id: santizeString(extractionAsText(layerName)),
			};
		}
		const features = data.type === 'Feature' ? [data] : data.features;
		const color = TileConfigAdaptor.getSystemColour(systemType);
		const layers = this._splitFeaturesIntoAssets(
			features as Features[],
			`${color}`,
			extractionAsText(layerName),
		);
		return {
			layerName,
			id: santizeString(extractionAsText(layerName)),
			layers,
			dataServiceId: dataService.id,
			color,
		};
	}

	private static _splitFeaturesIntoAssets(
		features: Features[],
		defaultColor: string,
		systemTypeName: string,
	): ConfigLayer[] {
		const assetTypes = features
			.map(({ properties = {} }) => properties.assetType as string)
			.filter(notUndefined);
		const uniqueTypes = [...new Set(assetTypes)];
		return uniqueTypes.map(assetType => ({
			layerName: TileConfigAdaptor.layernameFromType(assetType),
			id: TileConfigAdaptor.layerId(assetType, systemTypeName),
			type: AssetRegistry.getLayerType(assetType),
			properties: {
				assetType,
			},
			...this._getDefaults(assetType, systemTypeName, defaultColor),
		}));
	}

	private static _getDefaults(
		assetType: string,
		systemTypeName: string,
		defaultColor: string,
	): Partial<ConfigLayer> {
		let defaults:
			| Partial<ConfigLayer>
			| undefined = AssetRegistry.getConfigDefaults().find(
			defaultSetting => assetType === defaultSetting.assetType,
		);
		if (!defaults && systemTypeName === 'Sensors') {
			const {
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				layerName,
				...config
			} = AssetRegistry.getConfigDefaultsForAsset('sensor') as Partial<
				ConfigLayer
			>;
			defaults = config;
		}
		if (!defaults) return {};
		defaults.color = defaultColor;
		return defaults;
	}
}

export const assetConfigCreator = AssetConfigAdaptor.creator.bind(
	AssetConfigAdaptor,
);
