import { combineReducers } from 'redux';
import { kpiValuesReducer } from './kpiValue.reducer';
import { measureDataReducer } from './measureData.reducer';
import { patternMeasureDataReducer } from './patternMeasureData.reducer';
import sensorDataReducer from './sensorData.reducer';

export * from './sensorData.reducer';
export * from './measureData.reducer';
export * from './patternMeasureData.reducer';
export * from './kpiValue.reducer';

export const reducers = {
  measureData: measureDataReducer,
  patternMeasureData: patternMeasureDataReducer,
  kpiValueMap: kpiValuesReducer,
  charts: combineReducers({
    sensorData: sensorDataReducer,
  }),
};

export default reducers;
