import { AdaptorTypes, ConfigLayer } from '@Map/types';
import { ExtractTrans, e, extractionAsText } from '@Translations/extraction';
import {
	Info360TileMetadataV2,
	LayerV2,
	SystemTypes,
} from '@Map/services/Info360TileTypes';

import AssetRegistry from '@Map/services/AssetRegistry';
import ExternalConfigAdaptor from './ExternalConfigAdaptor';
import { TileJson } from '@Map/services/types';
import { santizeString } from '@Map/utils';

const UNKNOWN_SYSTEM_TYPE = 'Unknown';

export default class TileConfigAdaptor extends ExternalConfigAdaptor {
	static _type: AdaptorTypes = AdaptorTypes.tiles;
	static _endpointPathname = '/v2/tileset';

	/**
	 * Convert the data returned from the tiles endpoint into a layer configuration
	 * @param data info360 tile metadata v2
	 * @returns partial configuration object
	 */
	converter = (data: Info360TileMetadataV2): Partial<ConfigLayer> => {
		const systems = data.systems;
		const layers: ConfigLayer['layers'] = Object.entries(systems).map(
			([key, value]) => {
				const layerName = TileConfigAdaptor.systemTypeAsName(
					key as SystemTypes,
				);
				const color = TileConfigAdaptor.getSystemColour(
					key as SystemTypes,
				);
				const isOldLayers = Array.isArray(value);
				const layersToMap = isOldLayers
					? value
					: this._mapTileJsonToLayerInfo(value, key);
				const innerLayers = layersToMap.map(
					({ type, sourceType, systemType, minzoom, clustered }) => ({
						layerName: TileConfigAdaptor.layernameFromType(type),
						id: TileConfigAdaptor.layerId(
							type,
							extractionAsText(layerName),
						),
						tileSource: isOldLayers
							? `${systemType}-${type}`
							: systemType,
						tileLayerName: type,
						minZoom: minzoom,
						...this._getDefaults(
							type,
							sourceType,
							clustered,
							color,
						),
						systemType,
						...this._getOverride(type, sourceType, systemType),
					}),
				);
				return {
					layerName,
					id: santizeString(extractionAsText(layerName)),
					color,
					layers: innerLayers,
				};
			},
		);
		// if only one system type layer is returned then merge it into top level
		if (layers.length === 1) {
			return {
				...layers[0],
			};
		}
		return {
			layers,
		};
	};

	private _mapTileJsonToLayerInfo(
		data: TileJson,
		systemType: string,
	): LayerV2[] {
		return data.vector_layers.map(layer => ({
			type: layer.id,
			sourceType: layer.id,
			systemType,
			minzoom: layer.minzoom,
			maxzoom: layer.maxzoom,
			clustered: false,
			assetCount: 0,
			path: '',
		}));
	}

	/**
	 * Return any defaults for a given asset/source type and system type combination
	 * Default colour is used for layers that are not clustered
	 * @param assetType converted asset type
	 * @param sourceType original asset type
	 * @param clustered
	 * @param defaultColor
	 * @returns partial configuration object
	 */
	private _getDefaults(
		assetType: string,
		sourceType: string,
		clustered: boolean,
		defaultColor?: string,
	): Partial<ConfigLayer> {
		const defaults = AssetRegistry.getConfigDefaults().find(
			defaultSetting =>
				assetType === defaultSetting.assetType ||
				sourceType === defaultSetting.assetType,
		);
		if (!defaults)
			return {
				icon: 'unknown',
				type: 'symbol',
				color: defaultColor,
				minZoom: 16,
			};
		if (!clustered) {
			defaults.color = defaultColor;
		}
		return defaults;
	}

	/**
	 * Return any overrides for a given asset/source type and system type combination
	 * @param assetType converted asset type
	 * @param sourceType original asset type
	 * @param systemType system type
	 * @returns partial configuration object
	 */
	private _getOverride(
		assetType: string,
		sourceType: string,
		systemType: string,
	): Partial<ConfigLayer> {
		return (
			this._overrides.find(
				override =>
					systemType === override.systemType &&
					(assetType === override.assetType ||
						sourceType === override.assetType),
			) || {}
		);
	}

	/**
	 * Return colour for system type
	 * @param systemType
	 */
	static getSystemColour(systemType: SystemTypes): string | undefined {
		return (
			{
				[SystemTypes.CombinedSewer]: '#f49136',
				[SystemTypes.SanitarySewer]: '#a4978b',
				[SystemTypes.ForceMain]: '#db5d16',
				[SystemTypes.Stormwater]: '#4e9f39',
				[SystemTypes.WaterDistribution]: '#3960c6',
				[SystemTypes.TreatmentPlant]: '#7bb9e5',
				[SystemTypes.Sensors]: '#3960c6',
			}[systemType] || '#000000'
		);
	}

	/**
	 * Convert asset type into human readable layer name
	 */
	static layernameFromType(assetType: string): string {
		return assetType.replace(/^ww/, '').replaceAll(/([^a-z]+)/gi, ' ');
	}

	/** split the word where a capital letter proceeds a lowercase letter
	 * @example "SanitarySewer" becomes "Sanitary Sewer"
	 */
	static systemTypeAsName(systemType: SystemTypes): string | ExtractTrans {
		return (
			{
				[SystemTypes.CombinedSewer]: e('Combined Sewer', {
					context: 'System type',
				}),
				[SystemTypes.SanitarySewer]: e('Sanitary Sewer', {
					context: 'System type',
				}),
				[SystemTypes.ForceMain]: e('Force Main', {
					context: 'System type',
				}),
				[SystemTypes.Stormwater]: e('Stormwater', {
					context: 'System type',
				}),
				[SystemTypes.WaterDistribution]: e('Water Distribution', {
					context: 'System type',
				}),
				[SystemTypes.TreatmentPlant]: e('Treatment Plant', {
					context: 'System type',
				}),
				[SystemTypes.Sensors]: e('Sensors', {
					context: 'System type',
				}),
				[UNKNOWN_SYSTEM_TYPE]: e('Undefined', {
					context: 'System type',
				}),
			}[systemType] || systemType.replaceAll(/([a-z])([A-Z])/g, '$1 $2')
		);
	}

	/**
	 * Create unique layer id from asset type and system type name
	 */
	static layerId(assetType: string, systemTypeName: string): string {
		if (systemTypeName === SystemTypes.Sensors) {
			return assetType.replace(/[^A-z0-9]/g, '');
		}
		const layerName = TileConfigAdaptor.layernameFromType(assetType);
		return santizeString(`${systemTypeName}_${layerName}`);
	}
}

export const tilesConfigCreator = TileConfigAdaptor.creator.bind(
	TileConfigAdaptor,
);
