import { createAction } from '@reduxjs/toolkit';
import { SpatialLayer } from '@Types';
import { Status } from '@Types';

export const getSpatialLayers = createAction('amCommon/getSpatialLayers');

export const getSpatialLayersResolved = createAction<SpatialLayer[]>(
	'amCommon/getSpatialLayersResolved',
);
export const getSpatialLayersRejected = createAction<Status>(
	'amCommon/getSpatialLayersRejected',
);
