import { getApiEnvironment, getService } from '@innovyze/stylovyze';
import { apiConfig } from './config.api';
import axios, { AxiosRequestConfig } from 'axios';

import axiosRetry from 'axios-retry';
import cookies from 'browser-cookies';

const options: AxiosRequestConfig = {
  baseURL: getService('edgeAnalytics', apiConfig, getApiEnvironment()),
  timeout: 29000,
};

const edgeAnalytics = axios.create(options);

edgeAnalytics.interceptors.request.use(
  (conf) => {
    conf.headers.Authorization = 'Bearer ' + cookies.get('auth0.user.token');
    return conf;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosRetry(edgeAnalytics, {
  retries: 3,
  retryDelay: (retryCount) => {
    return retryCount * 1000;
  },
});

export default edgeAnalytics;
