import { HiddenSeries } from '../types/chartState';

export const getHiddenSeries = (series: Highcharts.Series[]): HiddenSeries =>
  series.reduce<HiddenSeries>((hiddenSeries, serie, index) => {
    const hiddenSeriesCopy = [...hiddenSeries];

    if (serie.visible === false) {
      hiddenSeriesCopy.push(serie.options.id ?? index ?? serie.name);
    }

    return hiddenSeriesCopy;
  }, []);

export const isSeriesHidden = (
  series: Highcharts.SeriesOptionsType,
  index: number,
  hiddenSeries?: HiddenSeries
): boolean => {
  return hiddenSeries
    ? hiddenSeries.includes(series.id as string) ||
        hiddenSeries.includes(index) ||
        hiddenSeries.includes(series.name as string)
    : false;
};
