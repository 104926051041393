import {
	AssetProperties,
	AssetPropertiesProps,
} from '@Components/AssetProperties';
import { FeatureItem, ItemDetailsSelector } from '@Utils/types';
import { getAsset, getAssetWithExtendedData } from '@Actions/asset';
import {
	selectAssetById,
	selectExtendedAssetById,
} from '../../selectors/asset';

import React from 'react';
import { Tab } from '@Components/Tab';
import { useGlobalization } from '@Translations/useGlobalization';

interface AssetsTabProps extends Omit<AssetPropertiesProps, 'item'> {
	/** array of feature item properties */
	items: FeatureItem[];
}

const getItemNameAsset = (item: FeatureItem): string => {
	return `${item.assetId || item.name || item.NAME || item.Name || item.id}`;
};

export const AssetsTab = ({ items, ...other }: AssetsTabProps): JSX.Element => {
	const { t } = useGlobalization();

	const { showInspections } = other;
	const getItemDetailsAction = showInspections
		? getAssetWithExtendedData
		: getAsset;
	const getItemDetailsSelector = showInspections
		? selectExtendedAssetById
		: selectAssetById;

	return (
		<Tab
			items={items}
			tabName={t('Assets', {
				context: 'Property pane tab heading',
			})}
			detailView={item => <AssetProperties item={item} {...other} />}
			getItemDetailsAction={getItemDetailsAction}
			getItemDetailsSelector={
				getItemDetailsSelector as ItemDetailsSelector
			}
			getItemName={getItemNameAsset}
		/>
	);
};
