import type { AsyncSensorData, SensorDataRecords } from '../types/data.types';

export const transformSensorDataRecordsToArray = (
  records: SensorDataRecords
): AsyncSensorData[] => {
  return Object.keys(records).flatMap((sensorId) => {
    const resolutions = Object.keys(records[sensorId]);
    return resolutions.map((resolution) => {
      return { ...records[sensorId]?.[resolution] };
    });
  });
};
