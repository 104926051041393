import { createContext } from './create-context';
import { useControlledRender } from './use-controlled-render';
import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const createSlots = <SlotId extends string>(chartComponentName: string) => {
  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Internals Provider
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  const [InternalsProvider, useInternals] = createContext<{
    checkIn: (slotId: SlotId, content: React.ReactNode) => void;
    checkOut: (slotId: SlotId) => void;
    enabled?: boolean;
  }>(chartComponentName, {
    checkIn: () => null,
    checkOut: () => null,
    enabled: false,
  });

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Slots Provider
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  const [SlotsProvider, useSlots] = createContext<{
    getSlot: (slotId: SlotId) => React.ReactNode;
    getSlotsMap: () => Map<SlotId, React.ReactNode>;
  }>(chartComponentName, {
    getSlot: () => null,
    getSlotsMap: () => new Map(),
  });

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Slottable Component
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  const Slottable = (props: {
    slottableChildren: React.ReactNode;
    children:
      | React.ReactNode
      | ((slots: {
          getSlot: (slotId: SlotId) => React.ReactNode;
          getSlotsMap: () => Map<SlotId, React.ReactNode>;
        }) => React.ReactNode);
  }): React.ReactElement => {
    const [renderKey, render] = useControlledRender();
    const hasChildrenMounted = React.useRef(false);

    const slotsRef = React.useRef<Map<SlotId, React.ReactNode>>(new Map());

    /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
     * Slots Internals
     * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

    const checkIn = React.useCallback(
      (slotId: SlotId, children: React.ReactNode) => {
        slotsRef.current.set(slotId, children);
        if (hasChildrenMounted.current) render();
      },
      []
    );

    const checkOut = React.useCallback((slotId: SlotId) => {
      slotsRef.current.delete(slotId);
      render();
    }, []);

    React.useLayoutEffect(() => {
      hasChildrenMounted.current = true;
      render();
    }, []);

    /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
     * Slots Context
     * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

    const getSlot = React.useCallback(
      (slotId: SlotId) => {
        if (!slotsRef.current.has(slotId)) return null;
        return slotsRef.current.get(slotId);
      },
      [renderKey]
    );

    const getSlotsMap = React.useCallback(() => {
      return slotsRef.current;
    }, [renderKey]);

    return (
      <>
        <InternalsProvider enabled={true} checkIn={checkIn} checkOut={checkOut}>
          {props.slottableChildren}
        </InternalsProvider>
        <SlotsProvider getSlot={getSlot} getSlotsMap={getSlotsMap}>
          {typeof props.children === 'function'
            ? props.children({ getSlot, getSlotsMap })
            : props.children}
        </SlotsProvider>
      </>
    );
  };

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Slot Component
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  const Slot = (props: {
    id: SlotId;
    children: React.ReactNode;
  }): React.ReactElement | null => {
    const slots = useInternals('Slot');

    React.useLayoutEffect(() => {
      slots.checkIn(props.id, props.children);

      return () => {
        slots.checkOut(props.id);
      };
    }, [props.id, props.children]);

    return slots.enabled ? null : <>{props.children}</>;
  };

  return { Slottable, Slot, useSlots };
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Module exports
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export { createSlots };
