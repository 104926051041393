import { FormControl } from '@mui/material';
import { rem } from '@Utils';
import styled from 'styled-components';

export const FormControlUnderline = styled(FormControl).attrs({
	variant: 'standard',
	margin: 'normal',
})`
	min-width: ${rem(90)};
`;

export const FormControlOutline = styled(FormControl).attrs({
	variant: 'outlined',
})`
	min-width: ${rem(250)};
	margin: ${rem(16)};
	.MuiFormLabel-root {
		background: #fff;
		padding: ${rem(5)};
		margin: ${rem(-5)};
	}
`;
