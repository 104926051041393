import Highcharts from 'highcharts';

import type { Chart } from 'highcharts';

declare module 'highcharts' {
  interface Point {
    highlight?: (e: SynchronizedEvent) => void;
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Synchronized Module
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export type SynchronizedChart = Chart | null;

export type SynchronizedEvent = PointerEvent | MouseEvent | TouchEvent;

export type SynchronizedEventType =
  | 'mousemove'
  | 'touchmove'
  | 'touchstart'
  | 'mouseleave';

export const Synchronized = (H: typeof Highcharts): void => {
  H.Pointer.prototype.reset = function () {
    return undefined;
  };

  H.Point.prototype.highlight = function (event) {
    const _event = this.series.chart.pointer.normalize(event);
    this.onMouseOver();
    this.series.chart.tooltip.refresh(this);
    this.series.chart.xAxis[0].drawCrosshair(_event, this);
  };
};
