import React, {
  ReactElement,
  forwardRef,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { selectSensorData } from '../../../selectors';
import { getMeasureData } from '../../../actions';
import {
  HistoricalSeriesConnected,
  Resolutions,
  GlobalPassthroughs,
  RangeSelection,
  HiddenSeries,
} from '../../../types';
import * as Styled from './ConnectedSensorChart.styles';
import { ChartingErrorBoundary, NoResults } from '../../atoms';

import { mapReduce } from '../../../utils/mapReduce';
import { LoadingPage, isFeatureEnabled } from '@innovyze/stylovyze';

import { InsightSensorChart } from '../../../_next/presets/insight-sensor-chart';

export type ConnectedSensorChartProps = {
  /**
   * Describe individual props like this
   */
  id?: string;
  series: HistoricalSeriesConnected;
  listeningArray?: unknown[];
  rangeSelection?: RangeSelection;
  hiddenSeries?: HiddenSeries;
  onRangeSelectionChange?: (rangeSelection: RangeSelection) => void;
  onHiddenSeriesChange?: (hiddenSeries: HiddenSeries) => void;
} & GlobalPassthroughs;

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Version Switch
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const ConnectedSensorChart = (
  props: ConnectedSensorChartProps,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref?: any
): ReactElement => {
  const isV2Enabled = isFeatureEnabled('info-360-analytics-hp2-charts');

  if (!isV2Enabled) {
    return <SensorChartV1 {...props} ref={ref} />;
  }

  return <SensorChartV2 {...props} ref={ref} />;
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * New Sensor Chart
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const SensorChartV2 = forwardRef<
  { chart: Highcharts.Chart | undefined },
  ConnectedSensorChartProps
>((props, ref) => {
  return (
    <ChartingErrorBoundary chartProps={props}>
      <InsightSensorChart
        ref={ref}
        series={{
          sensorId: props.series.id,
          name: props.series.alias || props.series.id,
          reading: 'Close',
          resolution: props.series.resolution ?? 'RAW',
        }}
      />
    </ChartingErrorBoundary>
  );
});

SensorChartV2.displayName = 'SensorChartV2';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Old Sensor Chart
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

/**
 * Add a description for your component here
 */
const SensorChartV1 = forwardRef(
  (props: ConnectedSensorChartProps): JSX.Element => {
    const { series, ...rest } = props;
    const dispatch = useDispatch();
    const [init, setInit] = useState(false);
    const [loading, setLoading] = useState(true);
    const fullSensorMap = selectSensorData();

    useEffect(() => {
      const getData = () => {
        dispatch(
          getMeasureData({
            sensors: [series.id],
            resolution: series.resolution ?? Resolutions['Raw'],
          })
        );
        setInit(true);
      };

      getData();
    }, [series]);

    const sensorMap = useMemo(() => {
      const map = mapReduce(
        [
          {
            id: series.id,
            resolution: series.resolution ?? Resolutions.Raw,
          },
        ],
        fullSensorMap
      );
      setLoading(false);
      setInit(false);

      return map;
    }, [fullSensorMap, series]);

    const seriesOptions = useMemo(() => {
      const record =
        sensorMap[series.id]?.[series.resolution ?? Resolutions.Raw];

      return {
        ...series,
        data: sensorMap ? record?.data ?? [] : [],
        unit: sensorMap ? record?.unit : '',
      };
    }, [sensorMap, series, init]);

    const noResults = useMemo(() => {
      let empty = true;

      for (const sensorId in sensorMap) {
        if (
          sensorMap[sensorId] &&
          Object.keys(sensorMap[sensorId]).length &&
          Object.keys(sensorMap[sensorId]).every(
            (key) => sensorMap[sensorId][key]?.data.length
          )
        )
          empty = false;
      }

      return empty;
    }, [sensorMap]);

    if (noResults && !loading && !init) {
      return <NoResults />;
    }

    if (noResults && !loading && init) {
      return <LoadingPage size="sm" />;
    }

    return (
      <ChartingErrorBoundary chartProps={props}>
        <Styled.Chart series={seriesOptions} {...rest} />
      </ChartingErrorBoundary>
    );
  }
);

SensorChartV1.displayName = 'SensorChartV1';

export default forwardRef(ConnectedSensorChart);
