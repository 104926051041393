import Chart from '../Chart';
import Highcharts from 'highcharts';
import React, { useLayoutEffect, useRef } from 'react';

import type { AsyncData, AsyncStatus } from '../../types/async.types';
import type { ChartRef } from '../Chart';
import type { ReactElement, ReactNode } from 'react';
import { TFunc, useGlobalization } from '../../../i18n';

export interface LoadingDataProps {
  children: ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: AsyncData<any> | Omit<AsyncData<any>, 'data'>;
  options?: Highcharts.Options;
}

const LoadingData = (props: LoadingDataProps): ReactElement => {
  const { t } = useGlobalization();
  const chartRef = useRef<ChartRef>(null);

  /**
   * If the status of the data being loaded is not resolved,
   * we show the loader with a message.
   *
   * Usually, it will be a loading message or a description
   * of what failed to be loaded.
   */
  useLayoutEffect(() => {
    if (props.data.status !== 'resolved') {
      chartRef.current?.chart?.showLoading(
        props.data.message || defaultMessage(t)[props.data.status]
      );
    }
  }, [props.data]);

  /**
   * If the data is not resolved, we render a placeholder chart
   * showing the loading state.
   *
   * DON'T REMOVE THE KEY PROP!!
   * The expected component being passed to props.children is
   * also a chart component. So when the data is resolved,
   * the placeholder Chart component will be replaced by another
   * Chart component andreact will take that as an update
   * instead of a mount/unmount operation.
   *
   * The key will tell react that the chart has to be
   * replaced instead of being updated.
   */
  const options = Highcharts.merge<Highcharts.Options>(
    false,
    loadingChartOptions,
    props.options
  );

  if (props.data.status !== 'resolved') {
    return (
      <Chart
        constructorType="chart"
        highcharts={Highcharts}
        key="loader"
        options={options}
        ref={chartRef}
      />
    );
  }

  return <>{props.children}</>;
};

const randomDataPoints = (): [number, number][] => {
  const dataPoints: [number, number][] = [];

  for (let x = 0; x < 100; x++) {
    dataPoints.push([x, Math.floor(Math.random() * (5 - 1)) + 1]);
  }

  return dataPoints;
};

const defaultMessage: (
  t: TFunc
) => Record<Exclude<AsyncStatus, 'resolved'>, string> = (t) => ({
  idle: '',
  loading: t('Loading data'),
  rejected: t('No data available'),
});

const loadingChartOptions: Highcharts.Options = {
  xAxis: {
    gridLineWidth: 0,
    labels: { enabled: false },
    lineWidth: 0,
    tickLength: 0,
  },
  yAxis: [
    {
      gridLineWidth: 0,
      labels: { enabled: false },
      title: { text: '' },
    },
  ],
  plotOptions: {
    series: {
      animation: false,
      color: '#E0E0E0',
      enableMouseTracking: false,
      showInLegend: false,
    },
    line: {
      lineWidth: 3,
      marker: {
        radius: 0,
      },
    },
  },
  series: [
    {
      data: randomDataPoints(),
      type: 'line',
    },
  ],
};

export default LoadingData;
