import { createSelector } from '@reduxjs/toolkit';
import { reduceToDataInRange } from '../core/utils/rangeSelection.utils';

import type {
  AsyncSensorData,
  SensorDataRecords,
  SensorDataSource,
} from '../core/types/data.types';
import type { StoreState } from '../store/store';
import type { RangeSelection } from '../types/chartState';

export const selectSensorData = (storeState: StoreState) =>
  storeState.charts.sensorData;

export const selectSensorDataRecords = createSelector(
  selectSensorData,
  (sensorData) => sensorData.records
);

export const selectSensorDataSources = createSelector(
  selectSensorData,
  (sensorData) => sensorData.sources
);

export const makeSelectSensorData = () => {
  return createSelector(
    selectSensorDataRecords,
    (_: unknown, dataSources: SensorDataSource[]) => dataSources,
    (_: unknown, __: unknown, rangeSelection?: RangeSelection) =>
      rangeSelection,
    (storedRecords, dataSources, rangeSelection) => {
      const sensorData = dataSources.reduce<SensorDataRecords>(
        (records, dataSource) => {
          const { sensorId, resolution } = dataSource;
          const storedRecord = storedRecords[sensorId]?.[resolution];

          let returnedRecord: AsyncSensorData;

          if (!storedRecord) {
            returnedRecord = { status: 'idle', data: null };
          } else if (storedRecord.status !== 'resolved') {
            returnedRecord = storedRecord;
          } else {
            returnedRecord = {
              ...storedRecord,
              data: reduceToDataInRange(storedRecord.data, rangeSelection),
            };
          }

          return {
            ...records,
            [dataSource.sensorId]: {
              ...records[dataSource.sensorId],
              [dataSource.resolution]: returnedRecord,
            },
          };
        },
        {}
      );

      return sensorData;
    }
  );
};
