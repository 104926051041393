import * as SensorChart from '../../modules/sensor-chart';
import * as InsightChart from '../../core/_insight-chart';
import * as React from 'react';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Chart Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

interface InsightSensorChartProps
  extends Omit<SensorChart.SensorChartRootProps, 'children'> {
  series: Omit<InsightSensorChartSeriesProps, 'dataEntry' | 'status' | 'error'>;
}

const InsightSensorChart = React.forwardRef<
  SensorChart.ChartInstanceRef,
  InsightSensorChartProps
>((props, ref): React.ReactElement => {
  const source = {
    sensorId: props.series.sensorId,
    resolution: props.series.resolution ?? 'RAW',
  };

  const seriesData = InsightChart.useSensorDataDisplay([source]);
  const dataEntry = seriesData.getEntry(source);

  React.useEffect(() => {
    seriesData.retrieve();
  }, [seriesData.retrieve]);

  return (
    <SensorChart.SensorChartRoot ref={ref}>
      <InsightSensorChartSeries {...props.series} dataEntry={dataEntry} />
    </SensorChart.SensorChartRoot>
  );
});

InsightSensorChart.displayName = 'InsightSensorChart';

interface InsightSensorChartSeriesProps
  extends SensorChart.SensorChartSeriesProps {
  sensorId: string;
  reading?: InsightChart.Reading;
  resolution?: InsightChart.Resolution;
  dataEntry: InsightChart.SensorDataEntry;
}

const InsightSensorChartSeries = (
  props: InsightSensorChartSeriesProps
): React.ReactElement => {
  const data = React.useMemo(() => {
    if (props.dataEntry.data === undefined) return undefined;

    const _data = Array.from(props.dataEntry.data.entries());
    return _data.map<[number, number | null]>(([timestamp, measures]) => {
      return [timestamp, measures.get(props.reading ?? 'Close') ?? null];
    });
  }, [props.reading, props.dataEntry.data]);

  return (
    <SensorChart.SensorChartSeries
      {...props}
      data={data}
      unit={props.dataEntry.unit}
      status={props.dataEntry.status}
      error={props.dataEntry.error}
    />
  );
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export { InsightSensorChart };

export type { InsightSensorChartProps };
