import { VideoObservation } from '@Types';
import { duration } from 'moment';

export const getTimeCounter = (observation: VideoObservation): number => {
	if (observation.counter) {
		return observation.counter;
	}
	const timeStringValue = (observation.videoPosition ??
		observation.VCRTime ??
		observation.videoRef ??
		observation.vcrTime) as string | undefined;
	if (timeStringValue) {
		try {
			return duration(timeStringValue).asSeconds();
		} catch (e) {
			console.error(e);
		}
	}
	return -1;
};
