import {
  setSensorDataRecordsAction,
  setSensorDataSourcesAction,
} from '../actions/sensorData.actions';
import { createReducer } from '@reduxjs/toolkit';

import type { SensorDataStore } from '../core/types/data.types';

export const initialSensorData: SensorDataStore = {
  records: {},
  sources: [],
};

const sensorDataReducer = createReducer<SensorDataStore>(
  initialSensorData,
  (builder) => {
    builder.addCase(setSensorDataSourcesAction, (state, action) => {
      action.payload.forEach((dataSource) => {
        const alreadyStored = state.sources.some(
          (storedDataSource) =>
            storedDataSource.resolution === dataSource.resolution &&
            storedDataSource.sensorId === dataSource.sensorId
        );

        if (!alreadyStored) {
          state.sources.push(dataSource);
        }
      });
    });
    builder.addCase(setSensorDataRecordsAction, (state, action) => {
      const { source, record } = action.payload;

      if (state.records[source.sensorId] === undefined) {
        state.records[source.sensorId] = {};
      }

      state.records[source.sensorId][source.resolution] = record;
    });
  }
);

export default sensorDataReducer;
