import {
	convertToImperial,
	convertToMetric,
	getImperialUnit,
} from '@innovyze/shared-utils/lib/utils/units';

type UnitValue = number | string;
type UnitMeasure = string;
export enum UnitSystem {
	Imperial = 'Imperial',
	Metric = 'Metric',
}

const ignoreUnit = ['$'];

export const defaultUnitSystem = UnitSystem.Metric;

export const getConvertedValue = (
	value: UnitValue,
	unit: UnitMeasure | undefined,
	system: UnitSystem = defaultUnitSystem,
	standardUnit?: UnitMeasure,
) => {
	if (!unit || ignoreUnit.includes(unit)) return value;
	const valueWithUnit = `${value} ${unit}`;
	if (system === UnitSystem.Metric)
		return convertToMetric(valueWithUnit, undefined, standardUnit).value;
	return convertToImperial(valueWithUnit, undefined, standardUnit).value;
};

export const getSystemUnit = (
	unit: string | undefined,
	system: UnitSystem = defaultUnitSystem,
) => {
	if (!unit || ignoreUnit.includes(unit)) return;
	if (system === UnitSystem.Metric) return unit;
	return getImperialUnit(unit);
};

export const getStandardValue = (
	value: string,
	unit: UnitMeasure | undefined,
	standardUnit: UnitMeasure | undefined,
	system: UnitSystem = defaultUnitSystem,
) => {
	if (!unit || system === UnitSystem.Metric || ignoreUnit.includes(unit))
		return value;
	return convertToMetric(`${value} ${unit}`, undefined, standardUnit).value;
};

export const generateDefaultValueForSystem = (
	inputValue: number,
	unit: UnitMeasure | undefined,
	unitSystem: UnitSystem,
) => {
	const convertedUnit = getSystemUnit(unit, unitSystem);
	return +getConvertedValue(
		inputValue,
		convertedUnit,
		UnitSystem.Metric,
		unit,
	);
};
