import * as Context from './context';
import * as React from 'react';
import styled from 'styled-components';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Root Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export type RootProps = {
  children: React.ReactNode;
  context?: Record<string, unknown>;
};

export type RootRef = HTMLDivElement;

export const Root = React.forwardRef<RootRef, RootProps>((props) => {
  const container = React.useRef<HTMLDivElement>(null);

  React.useLayoutEffect(() => {
    if (container.current) {
      const resizeObserver = new ResizeObserver(() => {
        window.dispatchEvent(new Event('resize'));
      });

      resizeObserver.observe(container.current);

      return () => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        resizeObserver.unobserve(container.current!);
        resizeObserver.disconnect();
      };
    }
  }, [container]);

  return (
    <Context.Provider {...props.context}>
      <Container ref={container}>{props.children}</Container>
    </Context.Provider>
  );
});

Root.displayName = 'Chart.Root';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Styled Components
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const Container = styled.div`
  align-items: stretch;
  background-color: #fff;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
  width: 100%;

  & * {
    box-sizing: border-box;
  }
`;
