import React, { createContext, useContext } from 'react';

import { MapSetState } from '@Map/types';

interface MapboxContextValue {
	map?: mapboxgl.Map;
	setMapState?: MapSetState;
}

const MapboxContext = createContext<MapboxContextValue | undefined>(undefined);

export function useMapboxContext() {
	const context = useContext(MapboxContext);
	if (!context) {
		throw new Error(
			'useMapboxContext must be used within a MapboxProvider',
		);
	}
	return context;
}

interface MapboxProviderProps extends MapboxContextValue {
	children: React.ReactNode;
}

export const MapboxProvider = ({ children, ...other }: MapboxProviderProps) => {
	return (
		<MapboxContext.Provider value={{ ...other }}>
			{children}
		</MapboxContext.Provider>
	);
};
