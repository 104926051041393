import * as React from 'react';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Stable Ref Hook
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const useStableRef = <Value = undefined>(
  value: Value
): React.MutableRefObject<Value> => {
  const valueRef = React.useRef<Value>(value);
  valueRef.current = value;

  return valueRef;
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Module Exports
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export { useStableRef };
