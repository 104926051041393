import {
	MapMain,
	MapRiskLayer,
	MapState,
	RehabDecisionTree,
	SpatialLayer,
	Status,
} from '@Types';
import { MapPosition, SelectedAsset } from '@innovyze/inno-map';

import { Extent } from '@innovyze/shared-utils';
import { createAction } from '@reduxjs/toolkit';

const namespace = 'libAmCommon/map/';
export const mapMain = createAction<MapMain>(namespace + 'map_main');

export interface SetMapState extends Omit<MapState, 'byTenant'> {
	tenantId: string;
}

export const mapStateUrl = createAction<SetMapState>(
	namespace + 'map_state_url',
);

export const mapSpatial = createAction<SpatialLayer | undefined>(
	namespace + 'map_spatial',
);
export const mapSpatialTilesSourceHide = createAction<boolean>(
	namespace + 'map_spatial_tiles_source_hide',
);
export const mapMainPosition = createAction<MapPosition>(
	namespace + 'map_main_position',
);
export const mapMainExtent = createAction<Extent>(
	namespace + 'map_main_extent',
);
export const mapMainSelection = createAction<SelectedAsset[]>(
	namespace + 'map_main_selection',
);
export const getMapRiskLayers = createAction('amCommon/getMapRiskLayers');

export const getMapRiskLayersResolved = createAction<MapRiskLayer[]>(
	'amCommon/MapRiskLayersResolved',
);
export const getMapRiskLayersRejected = createAction<Status>(
	'amCommon/getMapRiskLayersRejected',
);
export const getMapRehabLayers = createAction('amCommon/getMapRehabLayers');
export const getMapRehabLayersResolved = createAction<RehabDecisionTree[]>(
	'amCommon/getMapRehabLayersResolved',
);
export const getMapRehabLayersRejected = createAction<Status>(
	'amCommon/getMapRehabLayersRejected',
);
export const getMapLayers = createAction('amCommon/getMapLayers');
