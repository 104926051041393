import { generatePumpRuntimeStockSeries } from '../../../utils/pumpRuntimeChart.utils';
import { PumpRuntimeChartType } from '../../../types';
import { SeriesOptionsType, NavigatorXAxisPlotLinesOptions } from 'highcharts';
import { Chart } from '../../atoms';
import * as Styled from './PumpRuntimeChart.styles';
import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import { GlobalPassthroughs } from '../../../types/global.types';
import _ from 'lodash';
import { ChartingErrorBoundary } from '../../atoms';
import { useGlobalization } from '../../../i18n';

export type PumpRuntimeChartProps = GlobalPassthroughs & PumpRuntimeChartType;

const PumpRuntimeChart = (
  props: PumpRuntimeChartProps,
  chartControllerRef: React.Ref<unknown> | undefined
): JSX.Element | null => {
  const { t } = useGlobalization();
  const {
    series,
    displayOptions,
    displayType = 'hours',
    dataRangeSelection: rangeSelection,
    className,
    cy,
    dataCy,
    hiddenSeries,
    onHiddenSeriesChange,
  } = props;

  const [stockSeries, setStockSeries] = useState<SeriesOptionsType[]>();

  useEffect(() => {
    const runGenerateStockSeries = async () => {
      const stockSeries = await generatePumpRuntimeStockSeries(
        series,
        displayType,
        rangeSelection || {}
      );
      setStockSeries(stockSeries);
    };
    runGenerateStockSeries();
  }, [series, rangeSelection, displayType]);

  const categories: string[] = useMemo(
    () =>
      stockSeries && stockSeries.length
        ? stockSeries.map((series, index) =>
            series.name ? series.name : `series ${index}`
          )
        : [],

    [stockSeries]
  );

  const options = useMemo(() => {
    return {
      title: {
        text: '',
      },
      navigator: {
        enabled: false,
      },
      rangeSelector: {
        enabled: false,
      },
      plotOptions: {
        column: {
          states: {
            inactive: {
              opacity: 1,
            },
          },
        },
      },
      yAxis: [
        {
          title: {
            text: displayType === 'hours' ? t('Hours') : t('Percentage'),
          },
          gridLineWidth: displayOptions?.showYGrid ? 1 : 0,
          min: displayType === 'hours' ? undefined : 0,
          max: displayType === 'hours' ? undefined : 100,
        },
      ],
      xAxis: {
        type: 'category',
        categories: categories,
        title: {
          text: '',
        },
        gridLineWidth: displayOptions?.showXGrid ? 1 : 0,
      },
    } as Highcharts.Options;
  }, [displayOptions, categories]);

  return stockSeries ? (
    <ChartingErrorBoundary chartProps={props}>
      <Styled.PumpRuntimeChartWrapper>
        <Styled.ChartContainer>
          <Chart
            ref={chartControllerRef}
            className={className}
            cy={cy}
            dataCy={dataCy}
            series={stockSeries}
            hiddenSeries={hiddenSeries}
            onHiddenSeriesChange={onHiddenSeriesChange}
            options={options}
          />
        </Styled.ChartContainer>
      </Styled.PumpRuntimeChartWrapper>
    </ChartingErrorBoundary>
  ) : null;
};

export default forwardRef(PumpRuntimeChart);
