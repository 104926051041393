import { nanoid } from 'nanoid';
import * as Chart from '../../core/chart';
import * as Hooks from './pie-chart.hooks';
import * as React from 'react';
import * as LibUtils from '../../core/lib-utils';
import * as Utils from './pie-chart.utils';

import type { ReactElement } from 'react';
import type { EventHandlers } from './pie-chart.utils';
import { Status } from '../../core/_chart';
import { Theme } from '../../core/utils/theme-utils';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 *	Pie Chart Root Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export interface PieChartRootProps {
  elements?: { series: React.ReactNode };
  status?: 'loading' | 'idle' | 'resolved' | 'rejected';
  error?: Error;
  showLabels?: boolean;
  showTimeRangeLabel?: boolean;
  selectedTheme?: Theme;
  events?: {
    series?: {
      onVisibilityChange?: (index: number, type: 'hide' | 'show') => void;
    };
    chart?: {
      onSizeChange?: () => void;
    };
  };
}

export const PieChartRoot = Chart.withRoot(
  // eslint-disable-next-line react/display-name
  React.forwardRef<{ chart: Highcharts.Chart | undefined }, PieChartRootProps>(
    (props, ref): ReactElement => {
      const eventHandlers = LibUtils.useStableRef<EventHandlers>(props.events);

      Hooks.useStatus(props);
      Hooks.useLegendAlign();
      Hooks.useTheme(props);

      return (
        <Chart.Instance
          ref={ref}
          id="pieInstance"
          initialOptions={Utils.makeOptions(eventHandlers)}>
          {props.elements?.series}
        </Chart.Instance>
      );
    }
  )
);

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Pie Chart Series Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export interface PieChartSeriesProps {
  id?: string;
  color?: string;
  data?: { name: string; y: number | null }[];
  showLabels?: boolean,
  timeRangeLabel?: string,
  selectedTheme?: Theme;
  hidden?: boolean;
  status?: Status;
  error?: Error;
}

export const PieChartSeries = (props: PieChartSeriesProps): null => {
  const id = React.useRef(props.id ?? nanoid());

  Hooks.useSeriesCreateRemove(id, props);

  Hooks.useSeriesData(id, props);

  Hooks.useSeriesColor(id, props);
  // Hooks.useSeriesName(id, props);
  Hooks.useSeriesVisibility(id, props);

  Hooks.useDataLabel(id, props);
  Hooks.useTimeRangeLabel(id, props);

  return null;
};
