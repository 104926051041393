import type { PumpPerformanceSeries } from '../../../core/types/pumpPerformance.types';
import { Resolutions } from '../../../types';
import type { PumpPerformanceSeriesConnected } from '../../../types/pumpPerformanceChart.types';

export const generatePumpPerformanceSeries = (
  connectedSeries: PumpPerformanceSeriesConnected[],
  resolution?: Resolutions
): PumpPerformanceSeries[] => {
  const pumpPerformanceSeries: PumpPerformanceSeries[] = [];

  connectedSeries.forEach((series) => {
    pumpPerformanceSeries.push({
      displayOptions: series.displayOptions,
      downstreamCorrection: Number(series.downstreamData?.correction ?? 0),
      downstreamSensorAlias: series.downstreamData?.alias,
      name: series.alias,
      reading: series.reading,
      upstreamCorrection: Number(series.upstreamData?.correction ?? 0),
      upstreamSensorAlias: series.upstreamData?.alias,
      xSensorAlias: series.xData.alias,
      type: series.type,
      dataSource: {
        x: {
          sensorId: series.xData.id,
          resolution: resolution || Resolutions['15 Minutes'],
          customData: series.xData.customData,
        },
        downstream:
          series.downstreamData?.id || series.downstreamData?.customData
            ? {
                sensorId: series.downstreamData?.id ?? '',
                resolution: resolution || Resolutions['15 Minutes'],
                customData: series.downstreamData?.customData,
              }
            : null,
        upstream:
          series.upstreamData?.id || series.upstreamData?.customData
            ? {
                sensorId: series.upstreamData?.id ?? '',
                resolution: resolution || Resolutions['15 Minutes'],
                customData: series.upstreamData?.customData,
              }
            : null,
      },
    });
  });

  return pumpPerformanceSeries;
};
