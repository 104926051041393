import React, { useState } from 'react';
import { ComparisonTableConnected, GlobalPassthroughs } from '../../../types';
import { ChartingErrorBoundary } from '../../atoms';
import * as InsightChart from '../../../_next/core/_insight-chart';
import * as SeriesData from '../../../_next/core/series-data';
import * as TimeSeriesData from '../../../_next/core/time-series-data';
import {
  useIsFeatureEnabled,
  useSelectSensors,
  useSettings,
} from '@innovyze/stylovyze';
import { fixCollectionInterval } from '../../../_next/core/time-series-data/utils';
import { InsightComparisonTable } from '../../../_next/presets/insight-comparison-table';
import { AsyncData } from '../../../core/types/async.types';
import _ from 'lodash';

function limit(value: string | null | undefined, defaultValue = 500): number {
  if (typeof value === 'string' || value === null) return defaultValue;
  if (isNaN(Number(value))) return defaultValue;
  return Number(value);
}

function edgeSourceStringifier(source: EdgeSource): string {
  return `${source.sensorId}:${source.resolution}:${source.reading}`;
}

function splitSourcesIntoChunks(
  sources: EdgeSource[],
  chunkSize: number
): EdgeSource[][] {
  const chunks = [];
  for (let i = 0; i < sources.length; i += chunkSize) {
    const chunk = sources.slice(i, i + chunkSize);
    chunks.push(chunk);
  }
  return chunks;
}

type AsyncSensorData = AsyncData<{
  measurement: number;
  timestamp: number;
  unit?: string;
}>;

type EdgeSource = {
  sensorId: string;
  resolution: InsightChart.Resolution;
  reading: InsightChart.Reading;
};

export type ConnectedComparisonTableProps = ComparisonTableConnected &
  GlobalPassthroughs & {
    alwaysFetchData?: boolean;
  };

const ConnectedComparisonTable = (
  props: ConnectedComparisonTableProps
): React.ReactElement => {
  return <ComparisonTable {...props} />;
};

const ComparisonTable = (
  props: ConnectedComparisonTableProps
): JSX.Element | null => {
  const { companySettings } = useSettings();
  const { sensors, initialized: sensorsInitialized } = useSelectSensors();
  const dataLimit = useIsFeatureEnabled('info-360-analytics-hp2-charts-limit');
  const useV3 = useIsFeatureEnabled('info-360-edge-analytics-parquet-files');
  const [status, setStatus] = useState('idle');

  const uniqueSeries = _.uniqBy(props.series, 'sensorId');

  const [edgeSources] = SeriesData.useSources<EdgeSource>(() => {
    if (!uniqueSeries?.length) return;
    return uniqueSeries?.reduce((a, s) => {
      if (!s.customData) {
        a.push({
          sensorId: s.sensorId,
          resolution: props.resolution,
          reading: s.reading,
        });
      }
      return a;
    }, [] as EdgeSource[]);
  }, [uniqueSeries, props.resolution]);

  const [edgeData, edgeStatus, retrieveEdgeData] = SeriesData.useRetriever<
    TimeSeriesData.ResponseBody,
    { timeSelection?: TimeSeriesData.PartialTimeSelection }
  >(
    async (signal, params) => {
      if (!edgeSources?.length || !sensorsInitialized) return;

      const timeSelection = {
        from: params.timeSelection?.from ?? 'oldest',
        to: params.timeSelection?.to ?? 'latest',
      } as TimeSeriesData.TimeSelection;

      const sourceChunks = splitSourcesIntoChunks(edgeSources, 5);

      const batchedData = [];

      let i = 0;
      let batch: EdgeSource[][] = [];

      while (i < sourceChunks.length) {
        batch.push(sourceChunks[i]);
        i++;
        if (batch.length === 6 || i === sourceChunks.length) {
          const responses = await Promise.allSettled(
            batch.map((edegeBatch) => {
              return TimeSeriesData.retrieve(signal, {
                order: 'desc',
                timeSelection,
                limit: 1,
                timeZone: companySettings.timeZoneIANA,
                data_version: useV3 ? 'v3.0' : 'v2',
                sources: edegeBatch.map((s) => {
                  const _sensor = sensors.find(
                    (_s) => _s.sensorId === s.sensorId
                  );
                  const seconds = fixCollectionInterval(
                    _sensor?.collectionInterval
                  );

                  return {
                    key: edgeSourceStringifier(s),
                    sensorId: s.sensorId,
                    collectionInterval: { seconds },
                    analytic: TimeSeriesData.makeAnalytic(
                      s.resolution,
                      s.reading
                    ),
                  };
                }),
              });
            })
          );

          responses.forEach((response) => {
            if (response.status === 'fulfilled' && response.value) {
              batchedData.push(response.value.data?.results);
            }
          });
          batch = [];
        }
      }
      const results = batchedData.flat().reduce((acc, val) => {
        return { ...acc, ...val };
      }, {});
      return { results };
    },
    [
      companySettings.timeZoneIANA,
      dataLimit,
      edgeSources,
      sensors,
      sensorsInitialized,
      useV3,
    ]
  );

  const edgeDataRecords = React.useMemo(() => {
    const r: Record<string, AsyncSensorData> = {};

    for (const s of edgeSources) {
      const key = edgeSourceStringifier(s);
      const entry = edgeData?.results?.[key];

      const unit = entry?.unit ?? null;
      let timestamp = null;
      let measurement = null;

      if (entry?.data?.length) {
        const lastValues = entry.data[entry.data.length - 1];
        timestamp = lastValues[0];
        measurement = lastValues[1];
      }

      if (!r[s.sensorId]) r[s.sensorId] = { status: 'rejected', data: null };

      if (edgeStatus !== 'resolved') {
        r[s.sensorId] = {
          status: edgeStatus,
          data: null,
        };
      } else {
        r[s.sensorId] = {
          status: 'resolved',
          data: { timestamp, measurement, unit },
        };
      }
    }

    return r;
  }, [edgeData?.results, edgeSources, edgeStatus]);

  React.useEffect(() => {
    if (!sensorsInitialized) return;
    retrieveEdgeData({
      timeSelection: {
        from: props.timeRangeSelection?.min,
        to: props.timeRangeSelection?.max,
      },
    });
  }, [
    sensorsInitialized,
    retrieveEdgeData,
    props.timeRangeSelection?.min,
    props.timeRangeSelection?.max,
  ]);

  React.useEffect(() => {
    setTimeout(()=> setStatus(edgeStatus),3000);
  }, [edgeStatus]);

  return (
    <ChartingErrorBoundary chartProps={props}>
      <InsightComparisonTable
        series={props.series}
        sensorGroups={props.sensorGroups}
        sensorData={edgeDataRecords}
        resolution={props.resolution}
        displayOptions={props.displayOptions}
        onCellClick={props.onCellClick}
        edgeStatus={status}
      />
    </ChartingErrorBoundary>
  );
};

export default ConnectedComparisonTable;
