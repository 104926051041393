import { useSelector } from 'react-redux';

import {
  MeasureDataReducerChart,
  MeasureDataStoreState,
} from '../reducers/measureData.reducer';

export const selectSensorData = () =>
  useSelector<MeasureDataStoreState, MeasureDataReducerChart>(
    (state) => state.measureData.sensorData
  );
