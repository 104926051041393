import React, {
  forwardRef,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import {
  PumpRuntimeChartConnected,
  GlobalPassthroughs,
  PumpRuntimeSeries,
  Resolutions,
} from '../../../types';
import { getMeasureData } from '../../../actions/measureData.actions';
import { selectSensorData } from '../../../selectors/measureData.selectors';

import { ChartingErrorBoundary, NoResults } from '../../atoms';
import { PumpRuntimeChart } from '../../molecules';
import { mapReduce } from '../../../utils';
import { isFeatureEnabled } from '@innovyze/stylovyze';
import { ChartRef } from '../../../core/components/Chart';
import {
  InsightPumpRuntimeChartSeriesProps,
  InsightPumpRutimeChart,
} from '../../../_next/presets/insight-pumpRuntime-chart';
import { TimeRangeSelection } from '../../../_next/core/_insight-chart';
import { Theme } from '../../../_next/core/utils/theme-utils';

export type ConnectedPumpRuntimeChartProps = PumpRuntimeChartConnected &
  GlobalPassthroughs & {
    timeRangeSelection?: TimeRangeSelection;
    selectedTheme?: Theme;
  };

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Version Switch
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const ConnectedPumpRuntimeChart = (
  props: ConnectedPumpRuntimeChartProps,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref?: any
): ReactElement => {
  const isV2Enabled = isFeatureEnabled('info-360-analytics-hp2-charts');
  if (!isV2Enabled) {
    return <PumpRutimeChartV1 {...props} ref={ref} />;
  }
  return <PumpRutimeChartV2 {...props} ref={ref} />;
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * New Chart
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const PumpRutimeChartV2 = React.forwardRef<
  { chart: Highcharts.Chart | undefined },
  ConnectedPumpRuntimeChartProps
>((props, ref): React.ReactElement => {
  const series = React.useMemo(() => {
    if (props.series === undefined) return [];

    return props.series.map<InsightPumpRuntimeChartSeriesProps>(
      (_series, seriesIndex) => {
        return {
          sensorId: _series.id,
          name: _series.alias,
          resolution: 'RAW',
          reading: 'Close',
          hidden: props.hiddenSeries?.includes(seriesIndex),
          customData: _series.customData,
        };
      }
    );
  }, [
    props.series?.map(({ id, alias }) => `${id}${alias}`).join(''),
    props.hiddenSeries?.join(','),
  ]);

  return (
    <ChartingErrorBoundary chartProps={props}>
      <InsightPumpRutimeChart
        ref={ref}
        series={series}
        timeRangeSelection={props.timeRangeSelection}
        displayType={props.displayType ?? 'hours'}
        xAxis={{ enableGridlines: props.displayOptions?.showXGrid }}
        yAxis={{ enableGridlines: props.displayOptions?.showYGrid }}
        onSeriesVisibilityChange={(index, type) => {
          let _hiddenSeries: (string | number)[] = props.hiddenSeries
            ? [...props.hiddenSeries]
            : [];

          if (type === 'show') {
            _hiddenSeries = _hiddenSeries.filter(
              (_series) => _series !== index
            );
          } else {
            _hiddenSeries.push(index);
          }

          props.onHiddenSeriesChange?.(_hiddenSeries);
        }}
        selectedTheme={props.selectedTheme}
      />
    </ChartingErrorBoundary>
  );
});

PumpRutimeChartV2.displayName = 'PumpRuntimeChartV2';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Old Chart
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const PumpRutimeChartV1 = React.forwardRef<
  ChartRef,
  ConnectedPumpRuntimeChartProps
>((props, ref?): JSX.Element | null => {
  const {
    id = '',
    series,
    displayType,
    displayOptions,
    dataRangeSelection,
    hiddenSeries,
    onHiddenSeriesChange,
  } = props;
  const dispatch = useDispatch();
  const [formatSeries, setFormatSeries] = useState<PumpRuntimeSeries[]>([]);

  const [initialized, setInit] = useState(false);
  const [loading, setLoading] = useState(true);
  const fullSensorMap = selectSensorData();

  const shouldRefetchData = React.useMemo(() => {
    if (series) {
      return [
        ...series.map((sensor) => sensor.id),
        ...series.map((sensor) => sensor.alias),
      ].join('');
    }
  }, [series]);

  const sensorMap = useMemo(() => {
    const map = mapReduce(
      [
        ...(series?.map((s) => ({
          id: s.id,
          resolution: Resolutions.Raw,
        })) ?? []),
      ],
      fullSensorMap
    );
    return map;
  }, [fullSensorMap, series]);

  useEffect(() => {
    if (!series) return;

    dispatch(
      getMeasureData({
        sensors: series.map((sensor) => sensor.id),
        resolution: Resolutions.Raw,
      })
    );
    setInit(true);
  }, [shouldRefetchData]);

  useEffect(() => {
    if (!series) return;

    const formatSeries: PumpRuntimeSeries[] = series.map((s) => ({
      id: s.id,
      alias: s.alias,
      data: sensorMap ? sensorMap[s.id]?.[Resolutions.Raw]?.data : [],
    }));

    setFormatSeries(formatSeries);
    setLoading(false);
  }, [sensorMap, series]);

  const noResults = useMemo(() => {
    let empty = true;
    for (const sensorId in sensorMap) {
      if (
        sensorMap[sensorId] &&
        Object.keys(sensorMap[sensorId]).length &&
        Object.keys(sensorMap[sensorId]).every(
          (key) => sensorMap[sensorId][key]?.data.length
        )
      )
        empty = false;
    }
    if (!series) empty = true;
    return empty;
  }, [sensorMap]);

  return noResults && !loading && !initialized ? (
    <NoResults />
  ) : noResults && !loading && initialized ? (
    <></>
  ) : (
    <ChartingErrorBoundary chartProps={props}>
      <PumpRuntimeChart
        ref={ref}
        series={formatSeries}
        displayType={displayType}
        displayOptions={displayOptions}
        dataRangeSelection={dataRangeSelection}
        hiddenSeries={hiddenSeries}
        onHiddenSeriesChange={onHiddenSeriesChange}
      />
    </ChartingErrorBoundary>
  );
});

PumpRutimeChartV1.displayName = 'PumpRutimeChartV1';

export default forwardRef(ConnectedPumpRuntimeChart);
