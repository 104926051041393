import { edgeAnalyticsApi } from '../apis';
import {
  GoalPointInTimeRequestBody,
  GoalPointInTimeResponse,
} from '../core/types/goals.types';

export const getGoalPointInTime = (body: GoalPointInTimeRequestBody) => {
  return edgeAnalyticsApi.post<GoalPointInTimeResponse>(
    'goal-point-in-time',
    body
  );
};
