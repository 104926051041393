/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as Chart from '../../core/chart';
import * as React from 'react';
import {
  ChartDataError,
  UnknownError,
} from '../../core/chart-data/chart-data.utils';

import type {
  InstanceGetter,
  SynchronizedEvent,
  SynchronizedEventType,
} from '../../core/chart';
import type { MutableRefObject } from 'react';
import type { RootProps, SeriesProps } from './customAnalytic-chart.types';
import type { Axis, AxisOptions, Series } from 'highcharts';
import { getTheme } from '../../core/utils/theme-utils';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Stack
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
const synchronizedEventTypes: SynchronizedEventType[] = [
  'mousemove',
  'touchmove',
  'touchstart',
];

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useStackSynchronization = () => {
  const rootContainerElementRef = Chart.useRootContainerElementRef();
  const instances = Chart.useInstance();

  const sync = React.useCallback(
    function (
      this: Highcharts.Axis,
      e: Highcharts.AxisSetExtremesEventObject
    ): void {
      if (e.trigger !== 'sync' && e.trigger !== 'snapAdjustment') {
        const ids = ['stacked-footer'];

        instances.get(ids).forEach((instance) => {
          if (instance && instance !== this.chart) {
            instance.xAxis[0]?.setExtremes?.(e.min, e.max, true, false, {
              trigger: 'sync',
              source: this.chart,
            });
          }
        });
      }
    },
    [instances.get]
  );

  React.useEffect(() => {
    const synchronizedEventsHandler = (event: SynchronizedEvent) => {
      const ids = ['stacked-footer', /stacked-series/];

      instances.get(ids).forEach((instance) => {
        if (!!instance?.options && !!instance?.pointer) {
          const _event = instance.pointer.normalize(event);
          const point = instance.series[0]?.searchPoint?.(_event, true);

          point?.highlight?.(_event);
        }
      });
    };

    synchronizedEventTypes.forEach((eventType) => {
      rootContainerElementRef.current?.addEventListener(
        eventType,
        synchronizedEventsHandler
      );
    });

    return () => {
      synchronizedEventTypes.forEach((eventType) => {
        rootContainerElementRef.current?.removeEventListener(
          eventType,
          synchronizedEventsHandler
        );
      });
    };
  }, [rootContainerElementRef, instances.get]);

  return sync;
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Series
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const type = 'line';

export const useSeriesCreateRemove = (
  id: MutableRefObject<string>,
  props: SeriesProps
): void => {
  Chart.useInstanceEffect((instances) => {
    instances.get().forEach((instance) => {
      instance.addSeries({
        id: id.current,
        type: type,
        name: props.name,
        color: props.color,
        visible: !props.hidden,
      });
    });
  }, []);

  Chart.useInstanceLayoutEffect((instances) => {
    return () => {
      instances.get().forEach((instance) => {
        const series = instance.get(id.current) as Series | undefined;
        series?.remove();
      });
    };
  }, []);
};

export const useSeriesData = (
  id: MutableRefObject<string>,
  props: SeriesProps
): void => {
  Chart.useInstanceEffect(
    (instances) => {
      instances.get().forEach((instance) => {
        const series = instance.get(id.current) as Series | undefined;
        const data = props.data ?? [];

        series?.update({
          type: type,
          data,
        });
      });
    },
    [id, props.data]
  );
};

export const useSeriesNavigatorData = (
  id: MutableRefObject<string>,
  props: SeriesProps
): void => {
  Chart.useInstanceEffect(
    (instances) => {
      instances.get().forEach((instance) => {
        const series = instance.get(id.current) as Series | undefined;

        series?.update({
          type: type,
          navigatorOptions: {
            // @ts-ignore
            data: props.navigatorData ?? [],
          },
        });
      });
    },
    [id, props.navigatorData]
  );
};

export const useSeriesColor = (
  id: MutableRefObject<string>,
  props: SeriesProps
): void => {
  Chart.useInstanceEffect(
    (instances) => {
      instances.get().forEach((instance) => {
        const series = instance.get(id.current) as Series | undefined;
        series?.update({
          type: type,
          color: props.color ?? instance.options.colors?.[props.index],
        });
      });
    },
    [id, props.index, props.color, props.selectedTheme]
  );
};

export const useSeriesName = (
  id: MutableRefObject<string>,
  props: SeriesProps
): void => {
  Chart.useInstanceEffect(
    (instances) => {
      instances.get().forEach((instance) => {
        const series = instance.get(id.current) as Series | undefined;
        series?.update({
          type: type,
          name: props.name,
        });
      });
    },
    [id, props.name]
  );
};

export const useSeriesVisibility = (
  id: MutableRefObject<string>,
  props: SeriesProps
): void => {
  Chart.useInstanceEffect(
    (instances) => {
      const visible = !props.hidden;

      instances.get().forEach((instance) => {
        const series = instance.get(id.current) as Series | undefined;
        series?.update({
          type: type,
          visible,
        });
      });

      instances.get(`stacked-series-${props.index}`).forEach((instance) => {
        const height = visible ? undefined : 0;

        instance.update({
          xAxis: { height, visible },
          yAxis: { height, visible },
        });

        const parent = instance.container.parentElement?.parentElement;

        if (parent) {
          parent.style.position = visible ? 'relative' : 'absolute';
          parent.style.left = visible ? '0' : '-99999px';
          window.dispatchEvent(new Event('resize'));
        }
      });
    },
    [id, props.hidden]
  );
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Separate Y Axis
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
export const useSeriesYAxis = (
  id: MutableRefObject<string>,
  props: SeriesProps
): void => {
  const separateAxisId = `axis-${id.current}`;

  const removeAxis = (instances: { get: InstanceGetter }) => {
    instances.get('overlay').forEach((instance) => {
      (instance.get(separateAxisId) as Axis | undefined)?.remove();
    });
  };

  Chart.useInstanceEffect(
    (instances) => {
      const options: AxisOptions = {
        title: { text: props.yAxis?.label },
      };

      instances.get(`stacked-series-${props.index}`).forEach((instance) => {
        const axis = instance.yAxis[0];
        axis.update(options);
      });
    },
    [props.yAxis?.label]
  );

  Chart.useInstanceEffect((instances) => {
    return () => {
      removeAxis(instances);
    };
  }, []);
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Title
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const useTitle = (props: RootProps): void => {
  Chart.useInstanceEffect(
    (instances) => {
      instances.get(['overlay', 'stacked-header']).forEach((instance) => {
        instance.update({ title: { text: props.title } });
      });
    },
    [props.stacked, props.title]
  );
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * GridLines
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const useXAxisGridlines = (props: RootProps): void => {
  Chart.useInstanceEffect(
    (instances) => {
      instances.get(['overlay', /stacked-series/]).forEach((instance) => {
        instance.update({
          xAxis: {
            gridLineWidth: props.xAxis?.enableGridlines ? 1 : 0,
          },
        });
      });
    },
    [props.stacked, props.xAxis?.enableGridlines]
  );
};

export const useYAxisGridlines = (props: RootProps): void => {
  Chart.useInstanceEffect(
    (instances) => {
      instances.get(['overlay', /stacked-series/]).forEach((instance) => {
        instance.update({
          yAxis: {
            gridLineWidth: props.yAxis?.enableGridlines ? 1 : 0,
          },
        });
      });
    },
    [props.stacked, props.yAxis?.enableGridlines]
  );
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Axis Labels
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const useXAxisLabel = (props: RootProps): void => {
  Chart.useInstanceEffect(
    (instances) => {
      instances.get(['overlay', 'stacked-footer']).forEach((instance) => {
        instance.update({
          xAxis: { title: { text: props.xAxis?.label } },
        });
      });
    },
    [props.stacked, props.xAxis?.label]
  );
};

export const useYAxisLabel = (props: RootProps): void => {
  Chart.useInstanceEffect(
    (instances) => {
      const rootValue = props.yAxis?.label;

      instances.get('overlay').forEach((instance) => {
        instance.update({
          yAxis: { title: { text: rootValue } },
        });
      });
    },
    [props.stacked, props.yAxis?.label]
  );
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Sets data markers
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const useMarkers = (props: RootProps): void => {
  Chart.useInstanceEffect(
    (instances) => {
      instances.get(['overlay', /stacked-series/]).forEach((instance) => {
        instance.update({
          plotOptions: {
            series: {
              marker: {
                enabled: props.enableMarkers,
                radius: props.enableMarkers ? 4 : 0,
              },
            },
          },
        });
      });
    },
    [props.stacked, props.enableMarkers]
  );
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Range Selection Zoom
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const useZoom = (props: RootProps): void => {
  Chart.useInstanceEffect(
    (instances) => {
      instances.get(['overlay', 'stacked-header']).forEach((instance) => {
        instance.update({
          rangeSelector: {
            buttons: props.zoom,
          },
        });
      });
    },
    [props.stacked, props.zoom]
  );
};

export const useStatus = (props: RootProps): void => {
  Chart.useInstanceEffect(
    (instances) => {
      instances.get('overlay').forEach((instance) => {
        if (props.status === 'loading') {
          instance.showLoading('Loading');
          instance?.update({
            chart: {
              events: {
                render: function (this) {
                  const container = document.getElementById(
                    this?.container?.id
                  );
                  container
                    ?.getElementsByClassName('highcharts-scrollbar-thumb')[0]
                    ?.setAttribute('display', 'none');
                  container
                    ?.getElementsByClassName('highcharts-scrollbar-rifles')[0]
                    ?.setAttribute('display', 'none');
                },
              },
            },
          });
        } else if (props.status === 'resolved') {
          instance.hideLoading();
          instance?.update({
            chart: {
              events: {
                render: function (this) {
                  const container = document.getElementById(
                    this?.container?.id
                  );
                  container
                    ?.getElementsByClassName('highcharts-scrollbar-thumb')[0]
                    ?.removeAttribute('display');
                  container
                    ?.getElementsByClassName('highcharts-scrollbar-rifles')[0]
                    ?.removeAttribute('display');
                },
              },
            },
          });
        } else if (props.status === 'rejected') {
          const _error =
            props.error instanceof ChartDataError
              ? props.error
              : new UnknownError();

          instance.showLoading(_error.message);
        }
      });
    },
    [props.stacked, props.status, props.error]
  );
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Range Selection Zoom
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const useTheme = (props: RootProps): void => {
  Chart.useInstanceEffect(
    (instances) => {
      const theme = props.selectedTheme;
      const themeOptions = getTheme(theme ?? 'Default');
      instances.get(['overlay', /stacked-series/]).forEach((instance) => {
        instance?.update({ ...themeOptions });
      });
    },
    [props.selectedTheme]
  );
};
