import { AnyAction, createReducer } from '@reduxjs/toolkit';
import { AssetPipeStoreState, AssetPipeSummary } from '@Types/asset.types';
import {
	getAssetPipe,
	getAssetPipeRejected,
	getAssetPipeResolved,
} from '@Actions/assetPipe.actions';

const initAssetPipe: AssetPipeSummary = {
	assetId: '',
	pipeType: 'G',
	yearLaid: '',
	diameter: 0,
	usNodeId: '',
	dsNodeId: '',
	material: '',
	usInvert: 0,
	dsInvert: 0,
};

export const initState: AssetPipeStoreState = {
	asset: initAssetPipe,
	isReady: false,
};

interface AssetPipeReducer {
	[x: string]: (
		state: AssetPipeStoreState,
		action: AnyAction,
	) => AssetPipeStoreState | undefined;
}

const reducer: AssetPipeReducer = {
	[getAssetPipeResolved.toString()]: (state, action) => ({
		...state,
		initialized: true,
		asset: action.payload.asset,
		isReady: true,
	}),
	[getAssetPipe.toString()]: state => ({
		...state,
		isReady: false,
	}),
	[getAssetPipeRejected.toString()]: state => ({
		...state,
		initialized: true,
		isReady: true,
	}),
};

export default createReducer(initState, reducer);
