import {
	GridActionsCellItem,
	GridActionsCellItemProps,
	GridRowParams,
} from '@innovyze/stylovyze';
import { assetGridExcludeItem, assetGridIncludeItem } from '@Actions/asset';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import React from 'react';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useDispatch } from 'react-redux';
import { useFilter } from '../../context';
import { useGlobalization } from '@Translations/useGlobalization';

export enum GridMenuItems {
	exclude = 'exclude',
}

export const allGridMenuItems = Object.values(GridMenuItems);

export type ShowInMenu = GridMenuItems[];

type CustomActionProps = Omit<GridActionsCellItemProps, 'label'> & {
	assetId: string;
};

const IncludeAction = ({
	assetId,
	...props
}: CustomActionProps): React.ReactElement<GridActionsCellItemProps> => {
	const { t } = useGlobalization();
	const dispatch = useDispatch();

	return (
		<GridActionsCellItem
			{...props}
			icon={<AddCircleIcon />}
			label={t('Include asset in filter', {
				context: 'Asset Property',
			})}
			onClick={() => {
				dispatch(assetGridIncludeItem(assetId));
			}}
		/>
	);
};

const RemoveAction = ({
	assetId,
	...props
}: CustomActionProps): React.ReactElement<GridActionsCellItemProps> => {
	const { t } = useGlobalization();
	const { filter } = useFilter();
	const dispatch = useDispatch();

	return (
		<GridActionsCellItem
			{...props}
			icon={<RemoveCircleIcon />}
			label={t('Remove asset from filter', {
				context: 'Asset Property',
			})}
			onClick={() => {
				dispatch(assetGridExcludeItem(assetId));
			}}
			disabled={filter.items.length === 0}
		/>
	);
};

export const getActions = (showInMenu: ShowInMenu) => (
	params: GridRowParams,
): React.ReactElement<GridActionsCellItemProps>[] => {
	const actions: React.ReactElement<GridActionsCellItemProps>[] = [];

	if (showInMenu.includes(GridMenuItems.exclude)) {
		const isExcluded = params.row.excluded;
		if (isExcluded) {
			// NB: showInMenu has to be added here otherwise the action option will show in the column instead of in the menu
			actions.push(<IncludeAction assetId={params.row._id} showInMenu />);
		} else {
			actions.push(<RemoveAction assetId={params.row._id} showInMenu />);
		}
	}

	return actions;
};
