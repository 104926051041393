import { createSelector } from '@reduxjs/toolkit';
import { moduleNamespace } from '@Store/config.store';
import { NamespacedStoreState } from '@Types';
import { useSelector } from 'react-redux';

const parseNamespace = (state: NamespacedStoreState) => state[moduleNamespace];

export const ChartsStoreSelector = createSelector(
	parseNamespace,
	state => state.charts,
);

export const selectorCharts = (chartId: string) => {
	return createSelector(ChartsStoreSelector, state => {
		if (state.charts) {
			const chart = state.charts.find(chart => {
				return chart.chartId === chartId;
			});

			return chart?.data;
		}
	});
};

export const selectorChartsIsLoading = () =>
	createSelector(ChartsStoreSelector, state => {
		return state.isLoading;
	});

export const selectCharts = (chartId: string) =>
	useSelector(selectorCharts(chartId));
export const selectChartIsLoading = () =>
	useSelector(selectorChartsIsLoading());

export default selectCharts;
