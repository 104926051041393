import { Accordion, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import {
	AccordionDetails,
	AccordionSummary,
	useStyles,
} from './Segments.styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Segment } from './Segment/Segment.component';
import selectAssetPipe from '@Selectors/assetPipe.selectors';
import { ShowMoreOverlay } from '@Components';
import { useGlobalization } from '@Translations';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const Segments = (): JSX.Element | null => {
	const { t } = useGlobalization();
	const classes = useStyles();
	const assetPipeStore = selectAssetPipe();
	const segments = assetPipeStore?.asset?.segments;
	const [expanded, setExpanded] = useState(false);

	const showHideText = expanded ? (
		<Typography className={classes.expandText}>{t('Hide')}</Typography>
	) : (
		<Typography className={classes.expandText}>{t('Show')}</Typography>
	);

	return segments && segments.length > 0 ? (
		<div className={classes.root}>
			<Accordion
				square
				onChange={(event, isExpanded: boolean) => {
					setExpanded(isExpanded);
				}}>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1a-content"
					id="panel1a-header">
					<Grid container justifyContent="space-between">
						<Typography className={classes.heading}>
							{t('Segments')}
						</Typography>
						{showHideText}
					</Grid>
				</AccordionSummary>
				<AccordionDetails>
					<ShowMoreOverlay height={300}>
						<Grid container>
							{segments.map(segment => (
								<Segment
									key={segment.segmentId}
									id={segment.segmentId.toString()}
									segmentAttrs={segment}
								/>
							))}
						</Grid>
					</ShowMoreOverlay>
				</AccordionDetails>
			</Accordion>
		</div>
	) : null;
};
