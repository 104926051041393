import { ASSET_NOT_FOUND, ASSET_PROPS_CHANGED, LOAD_ICON } from './constants';
import AssetRegistry, { assetUnknown } from './AssetRegistry';
import {
	DataServiceType,
	Features,
	GeoJsonDataType,
	GeoJsonInputType,
	GeoJsonInputTypes,
	IconSet,
	Properties,
	ServiceLayer,
} from './types';

import DataService from './DataService';
import MapboxDataSource from './MapboxDataSource';
import PaginatedFetch from './PaginatedFetch';
import { Timings } from '../timeline/types';

export default class GeoJsonDataService extends DataService<GeoJsonInputTypes> {
	protected _animationSupported = true;

	constructor(dataService: DataServiceType<GeoJsonInputTypes>) {
		super(dataService);
		if (dataService.token) {
			this._requestHeaders = [
				['Authorization', `Bearer ${dataService.token}`],
				['Accept', 'application/geo+json'],
			];
		}
	}

	get layers(): ServiceLayer[] {
		const sources = this.dataSources;
		if (!sources || (sources && !sources.length)) return [];

		return sources.map(
			({ id, displayName, type, icon, color, zIndex }) => ({
				id,
				source: id,
				serviceId: this.id,
				displayName,
				type,
				icon,
				cluster: type !== 'line',
				color,
				loaded: this.loaded,
				errored: this.errored,
				zIndex,
			}),
		);
	}

	get iconSet(): IconSet {
		return AssetRegistry.getIconSet();
	}

	async fetchData(): Promise<GeoJsonInputTypes> {
		this.cancelPendingDataRequests();
		this._request = new PaginatedFetch({
			url: this.dataEndpoint as string,
			headers: this._requestHeaders,
		});

		const paginatedData = await this._request?.fetchPages<
			GeoJsonInputTypes
		>();

		if ('type' in paginatedData[0] && paginatedData[0].type === 'Feature') {
			return paginatedData[0];
		} else {
			const depaginatedFeatures = (paginatedData as GeoJsonDataType[])
				.map(({ features }) => features)
				.reduce((previous, current) => {
					return [...previous, ...current];
				}, []);
			return {
				type: 'FeatureCollection',
				features: depaginatedFeatures,
			};
		}
	}

	validData(data: GeoJsonInputType): boolean {
		if (typeof data !== 'object') return false;
		if (data.type === 'Feature') {
			if (
				typeof data.geometry === 'object' &&
				typeof data.properties === 'object'
			) {
				return true;
			}
		} else if (data.type === 'FeatureCollection') {
			if (typeof data.features === 'object') {
				return true;
			}
		}
		return false;
	}

	/**
	 * Convert a geojson Feature into a FeatureCollection to make it easier to remap the properties
	 * @param data geojson FeatureCollection
	 */
	reformStructure(data: GeoJsonInputType | null): GeoJsonDataType | null {
		if (!data) return null;
		const features = (data.type === 'Feature'
			? [data]
			: data.features) as Features[];
		return {
			type: 'FeatureCollection',
			features,
		};
	}

	/**
	 * Gets the asset name for display.
	 * @param properties geojson feature properties.
	 * @param groupBy value to group by from the DataService configuration.
	 */
	getDisplayName(
		properties: Properties | undefined,
		groupBy: string,
	): string {
		const value = properties?.[groupBy];
		if (groupBy === 'assetType') {
			return AssetRegistry.getLayerName(value as string);
		}
		return `${value}`;
	}

	/**
	 * Gets the asset icon for display.
	 * @param properties geojson feature properties.
	 */
	mapIcons(properties: Properties): string | undefined {
		const { assetType } = properties;
		return AssetRegistry.getIcon(assetType as string);
	}

	/**
	 * Gets the type of feature - asset, sensor or undefined.
	 * @param properties geojson feature properties.
	 */
	mapType(properties: Properties): string | undefined {
		const { assetType } = properties;
		if (assetType === 'sensor') {
			return 'sensor';
		}
		return 'asset';
	}

	/**
	 * Gets the color to be used for a geojson feature.
	 * @param properties geojson feature properties.
	 */
	getColor(properties: Properties): string | undefined {
		const { assetType } = properties;
		if (assetType) {
			return AssetRegistry.getColor(assetType as string);
		}

		return assetUnknown.color;
	}

	mapSensors(
		features: Features[],
		parent: string | number,
	): Properties[] | undefined {
		const filtered = features
			.filter(
				({ properties }) =>
					properties && `${properties.parent}` === parent,
			)
			.map(({ properties }) => ({
				...properties,
				id: properties._id || properties.sensorId || properties.assetId,
			}));
		return filtered.length ? filtered : undefined;
	}

	mapDisplayType(properties: Properties): string {
		const { assetType, _id } = properties;
		if (assetType === 'sensor') {
			// get sensor type from id when in the format: sensor#34739.Status
			const sensorType = (_id as string)?.split(/\./).pop();
			if (sensorType) return sensorType;
		}
		return AssetRegistry.getDisplayName(assetType as string);
	}

	/**
	 * Fix up the asset properties returned by the service to match those expected by the map.
	 * @param data asset data.
	 */
	remapProperties(data: GeoJsonDataType | null): GeoJsonDataType | null {
		if (!data) return null;
		const { features } = data;
		const mappedFeatures = features.map(({ properties, ...other }) => {
			const id = properties._id || properties.id;
			const sensors = this.mapSensors(features, id as string);
			const icon = properties.icon ?? this.mapIcons(properties);
			const type = this.mapType(properties);
			if (icon) {
				this.fire(LOAD_ICON, { id: icon });
			}
			return {
				...other,
				id,
				properties: {
					...properties,
					id,
					name:
						properties.name ||
						properties.assetId ||
						properties.sensorId ||
						properties.reference,
					// to get around customers not having asset ids
					assetId: properties.assetId || properties.reference,
					icon,
					sensors,
					type,
					displayType: this.mapDisplayType(properties),
				},
			};
		});
		return {
			type: 'FeatureCollection',
			features: mappedFeatures,
		};
	}

	/**
	 * Convert geojson to datasources with the option of splitting
	 * by group or a filter
	 * @param data data retrieved from geojson data service
	 */
	createDataSources(data: GeoJsonDataType): void {
		const features = data?.features;

		const { point, other } = this._splitFeaturesByType(features);

		const defaultSource = [
			new MapboxDataSource({
				id: this.id,
				displayName: this.displayName,
				type: 'symbol',
				data,
				icon:
					point && point.length
						? this._findFirstIcon(point)
						: assetUnknown.icon,
				color:
					point && point.length
						? this.getColor(point[0]?.properties)
						: assetUnknown.color,
				zIndex: this._zIndex,
				cluster: this._cluster,
			}),
		];

		if (!features) {
			this._dataSources = defaultSource;
			return;
		}

		if (other && other.length) {
			defaultSource.push(
				new MapboxDataSource({
					id: !point || !point.length ? this.id : `${this.id}_line`,
					displayName: this.displayName,
					type: 'line',
					data,
					icon: this._findFirstIcon(other),
					color: this.getColor(other[0]?.properties),
					zIndex: this._zIndex,
				}),
			);

			if (!point || !point.length) {
				// remove the symbol layer if there are no points to display
				defaultSource.shift();
			}
		}

		this._dataSources = defaultSource;
	}

	private _splitFeaturesByType(
		features: GeoJsonDataType['features'],
	): {
		point?: GeoJsonDataType['features'];
		other?: GeoJsonDataType['features'];
	} {
		if (!features) return {};
		const pointFeatures = features.filter(
			({ geometry }) =>
				geometry.type === 'Point' || geometry.type === 'MultiPoint',
		);
		const other = features.filter(
			({ geometry }) =>
				geometry.type !== 'Point' && geometry.type !== 'MultiPoint',
		);
		return { point: pointFeatures, other };
	}

	/**
	 * Split the geojson into group by grouping on a particular property
	 * @param features geojson features
	 * @param groupBy feature property to group on
	 * @param type layer type
	 *
	 * @returns an array of data sources for mapbox
	 */
	private _groupFeaturesForDataSources(
		features: GeoJsonDataType['features'],
		groupBy: string,
		type: ServiceLayer['type'],
		cluster: ServiceLayer['cluster'],
	): MapboxDataSource[] {
		return this._getUniqueValuesFromFeatureProperty(features, groupBy)
			.map(groupValue =>
				this._getFeaturesWithPropertyValue(
					features,
					groupBy,
					groupValue,
				),
			)
			.map(group => {
				return this._mapToDataSourceForAGroup(
					group,
					groupBy,
					type,
					cluster,
				);
			});
	}

	/**
	 * filtering the features to by a particular property
	 * @param features geojson features
	 * @param filterBy feature property to find
	 * @param type layer type
	 * @param displayName layer display name
	 *
	 * @returns an array of data sources for mapbox
	 */
	private _filterFeaturesForDataSources(
		features: GeoJsonDataType['features'],
		filterBy: string,
		type: ServiceLayer['type'],
		displayName: ServiceLayer['displayName'],
		cluster: ServiceLayer['cluster'],
	): MapboxDataSource[] {
		const dataFeatures = this._getFeaturesWithProperty(features, filterBy);
		if (!dataFeatures.length) return [];
		return [
			new MapboxDataSource({
				id: `${this.id}-${filterBy}`,
				displayName,
				data: {
					type: 'FeatureCollection',
					features: dataFeatures,
				},
				type,
				icon: this._findFirstIcon(dataFeatures),
				color: this.getColor(dataFeatures[0]?.properties),
				cluster,
				zIndex: this._zIndex,
			}),
		];
	}

	/**
	 * Get unique values from a particular property of a feature
	 * @param features geojson features
	 * @param property feature property to group on
	 *
	 * @returns an array of the unique groups found
	 */
	private _getUniqueValuesFromFeatureProperty(
		features: GeoJsonDataType['features'],
		property: string,
	): string[] {
		return Array.from(
			new Set(features.map(({ properties }) => properties[property])),
		).filter(Boolean) as string[];
	}

	/**
	 * Create a MapboxDataSource object from features
	 * @param features geojson features
	 * @param groupBy feature property to group on
	 * @param type layer type
	 */
	private _mapToDataSourceForAGroup(
		features: GeoJsonDataType['features'],
		groupBy: string,
		type: ServiceLayer['type'],
		cluster: ServiceLayer['cluster'],
	): MapboxDataSource {
		const groupValue = features[0]?.properties[groupBy];
		return new MapboxDataSource({
			id: `${this.id}-${groupBy}-${groupValue}`,
			displayName: this.getDisplayName(features[0]?.properties, groupBy),
			data: {
				type: 'FeatureCollection',
				features,
			},
			type,
			icon: this._findFirstIcon(features),
			color: this.getColor(features[0]?.properties),
			cluster,
			zIndex: this._zIndex,
		});
	}

	/**
	 * Get all features that have a particular property
	 * @param features geojson features
	 * @param property feature property to look for
	 */
	private _getFeaturesWithProperty(
		features: GeoJsonDataType['features'],
		property: string,
	): GeoJsonDataType['features'] {
		return features.filter(({ properties }) => property in properties);
	}

	/**
	 * Get all features that have a particular property with the value specified
	 * @param features geojson features
	 * @param property feature property to look for
	 * @param value value of property
	 */
	private _getFeaturesWithPropertyValue(
		features: GeoJsonDataType['features'],
		property: string,
		value: string,
	): GeoJsonDataType['features'] {
		return features.filter(
			({ properties }) => properties[property] === value,
		);
	}

	/**
	 * Find the first icon in the features
	 * @param features geojson features
	 */
	private _findFirstIcon(
		features: GeoJsonDataType['features'],
	): string | undefined {
		return `${features
			.map(({ properties }) => properties.icon)
			.filter(Boolean)[0] || assetUnknown.icon}`;
	}

	private _featuresAsProperties(): Properties[] | undefined {
		const features = (this.data as GeoJsonDataType)?.features;
		if (!features) return;
		return features.map(({ properties }) => properties);
	}

	fetchAssetProperties(ids: string[]): void {
		const features = (this.data as GeoJsonDataType)?.features;
		if (!features) return;
		ids.forEach(assetId => {
			const asset = features.find(
				({ properties }) => properties.id === assetId,
			);
			if (asset) {
				const { properties, geometry } = asset;
				this.fire(ASSET_PROPS_CHANGED, {
					id: assetId,
					data: {
						...properties,
						geometry,
					},
				});
			} else {
				this.fire(ASSET_NOT_FOUND, { id: assetId });
			}
		});
	}

	animationTimings(): Timings | undefined {
		if (!this._animate) return;
		const defaultAnimateProps = GeoJsonDataService.defaultAnimateProps;
		const animateProps =
			typeof this._animate === 'object' ? this._animate : {};
		const { inView, outView, stepDuration, jumpDuration } = {
			...defaultAnimateProps,
			...animateProps,
		};
		const featuresAsProperties = this._featuresAsProperties();
		if (!featuresAsProperties) return;
		let startTime = 0;
		let endTime = 0;
		featuresAsProperties.forEach(props => {
			const featureStartTime = +(
				(inView && props[inView]) ??
				999999999999999
			);
			const featureEndTime = +((outView && props[outView]) ?? 0);
			if (!startTime || featureStartTime < startTime) {
				startTime = featureStartTime;
			}
			if (!endTime || featureEndTime > endTime) {
				endTime = featureEndTime;
			}
		});
		return {
			start: startTime,
			end: endTime,
			step: stepDuration,
			jump: jumpDuration,
			animatedProps: { inView, outView },
		};
	}

	update(dataService: DataServiceType<GeoJsonInputTypes>): boolean {
		const changed = super.update(dataService);
		this._requestHeaders = [
			['Authorization', `Bearer ${dataService.token}`],
			['Accept', 'application/geo+json'],
		];
		return changed;
	}
}
