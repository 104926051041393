import { Environments, PartialServiceMap } from '@innovyze/stylovyze';

const config: PartialServiceMap = {
	[Environments.LOCAL]: {
		amRisk: 'https://saas-risk-management.info360-dev.com/dev',
		amInspection: 'https://saas-inspection-management.info360-dev.com/dev',
		assets: 'https://saas-asset-management.info360-dev.com/dev',
		liftStations: 'https://saas-lift-stations.info360-dev.com/dev',
		imageManagement: 'https://saas-image-management.info360-dev.com/dev',
		mapManagement: 'https://saas-map-management.info360-dev.com/dev',
	},
};

export default config;
