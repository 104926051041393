import styled from 'styled-components';
import { StockChart } from '../../atoms';

export const Chart = styled(StockChart)`
  width: 100%;
  height: 100%;
  padding: 1rem;
  box-sizing: border-box;
  background-color: white;
`;
