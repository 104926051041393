import styled from 'styled-components';

const StyledPumpPerformanceChart = {
  Wrapper: styled.div`
    height: 100%;
    position: relative;
    width: 100%;
  `,
  ColorLegend: styled.div`
    align-items: center;
    bottom: 6px;
    display: flex;
    position: absolute;
    right: 12px;
  `,
  LegendText: styled.div`
    font-size: 11px;
    color: #666666;
  `,
  LegendGradient: styled.div`
    background: linear-gradient(
      to right,
      rgba(196, 196, 196, 1),
      rgba(196, 196, 196, 0.1)
    );
    height: 8px;
    margin: 5px;
    width: 40px;
    border-radius: 4px;
  `,
};

export default StyledPumpPerformanceChart;
