import styled from 'styled-components';
import { TextField } from '@mui/material';
import { Search } from '@mui/icons-material';
import { rem } from '@Utils/styles';

export const StyledSearch = styled.div`
	position: relative;
	width: 90%;
`;

export const StyledSearchIcon = styled(Search)`
	position: absolute;
	top: ${rem(16)};
	left: ${rem(15)};
`;

export const StyledTextField = styled(TextField)`
	margin-top: 0px;
	margin-bottom: 0px;
	width: 100%;
	.MuiInputLabel-outlined {
		margin-left: ${rem(25)};
	}
	.MuiInputLabel-outlined.MuiInputLabel-shrink {
		margin-left: 0px;
	}
	.MuiOutlinedInput-input {
		margin-left: ${rem(25)};
	}
`;
