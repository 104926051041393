import * as Highcharts from 'highcharts/highstock';

export const defaultSensorChartConfig: Highcharts.Options = {
  navigator: {
    enabled: false,
  },
  chart: {
    zoomType: undefined,
  },
  rangeSelector: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
};
