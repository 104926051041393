import { AxiosResponse } from 'axios';
import { asset as assetApi } from '../apis';
import {
  AssetResponse,
  ConfigurationParams,
  CurveType,
} from '../core/types/asset.types';
import { GetManufacturerCurvesResponse } from '../types/asset.types';

export const fetchManufacturerCurves = (): Promise<
  AxiosResponse<AssetResponse>
> =>
  assetApi.get<AssetResponse>('assets', {
    params: { assetTypes: Object.values(CurveType).toString() },
  });

export const getManufacturerCurves = async (
  curveIds: string[]
): Promise<AxiosResponse<GetManufacturerCurvesResponse>> => {
  const response = await assetApi.get<GetManufacturerCurvesResponse>('assets', {
    params: {
      assetTypes: Object.values(CurveType).toString(),
      searchQuery: `curveId:^(${curveIds.join('|')})$`,
    },
  });

  return response;
};

export const getConfigurations = (params: ConfigurationParams) =>
  assetApi.get('/v2/sensors', {
    params,
  });
