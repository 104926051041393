import { Field } from '@Types/map.types';
import { GridFilterModel } from '@mui/x-data-grid';
import { removeInvalidEntries } from '@Utils/grid';
import { useFilter } from '../../context/FilterProvider';
import { useShallowEqlEffect } from '@Hooks/useShallowEqlEffect';
import { useUnits } from '@innovyze/stylovyze';

type UpdateModel = (model: GridFilterModel) => void;

const getUnitForField = (columns: Field[], fieldKey: string) => {
	return columns.find(field => field.fieldKey === fieldKey)?.units;
};

export const useFilterModel = (
	columns: Field[],
): [GridFilterModel, UpdateModel, GridFilterModel] => {
	const { rawFilter, filter, setFilter, setProcessFilterFunc } = useFilter();
	const { system, getStandardisedValue, getSystemUnit } = useUnits();

	const convertModel = (model: GridFilterModel): GridFilterModel => {
		if (system === 'Metric' || !model) {
			return model;
		}
		const items = model.items.map(item => {
			const unit = getUnitForField(columns, item.field);
			if (unit) {
				const systemUnit = getSystemUnit(unit);
				const value = item.value
					? getStandardisedValue(
							`${item.value}${systemUnit}`,
							undefined,
							unit,
					  )
					: item.value;
				return {
					...item,
					value,
				};
			}
			return item;
		});
		return {
			...model,
			items,
		};
	};

	const processFilters = (model: GridFilterModel) => {
		const updatedModel = model ? removeInvalidEntries(model) : model;
		if (updatedModel?.items?.length) {
			const convertedModel = convertModel(updatedModel);
			return convertedModel;
		} else {
			return updatedModel;
		}
	};

	useShallowEqlEffect(() => {
		setProcessFilterFunc(() => processFilters);
	}, [columns, system]);

	return [rawFilter, setFilter, filter];
};
