/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
	InspectionStandard,
	MACPObservation,
	MSCCObservation,
	NamespacedStoreState,
	PACPObservation,
	ValidationData,
	WSA05Observation,
} from '@Types';
import { createSelector } from '@reduxjs/toolkit';
import { moduleNamespace } from '@Store/config.store';
import { useSelector } from 'react-redux';
import { useUnits } from '@innovyze/stylovyze';
import { t } from 'i18next';

const parseNamespace = (state: NamespacedStoreState) => state[moduleNamespace];

const fullInspStoreSelector = createSelector(
	parseNamespace,
	state => state.fullInspection,
);
const inspectionStoreSelector = createSelector(
	parseNamespace,
	state => state.inspections,
);

export const fullInspSelector = createSelector(
	fullInspStoreSelector,
	state => state?.fullInspection,
);

const notesSelector = createSelector(
	fullInspStoreSelector,
	state => state?.notes,
);

const fullInspectionIsWaitingSelector = createSelector(
	fullInspStoreSelector,
	state => state?.isWaiting,
);

const notesIsWaitingSelector = createSelector(
	fullInspStoreSelector,
	state => state?.notesIsWaiting,
);

const validationIssuesSelector = createSelector(
	fullInspStoreSelector,
	state =>
		state?.fullInspection.validationData &&
		state?.fullInspection.validationData?.length != 0,
);

const fullInspectionConditionSelector = createSelector(
	fullInspStoreSelector,
	store => store.fullInspection.conditions,
);

const refreshInspectionSelector = createSelector(
	inspectionStoreSelector,
	state => state?.updateStatus,
);

export const refreshInsp = () => useSelector(refreshInspectionSelector);

export const selectFullInspection = () => useSelector(fullInspSelector);

export const selectNotes = () => useSelector(notesSelector);

export const selectFullInspectionIsWaiting = () =>
	useSelector(fullInspectionIsWaitingSelector);

export const selectNotesIsWaiting = () => useSelector(notesIsWaitingSelector);

export const hasValidationIssues = () => useSelector(validationIssuesSelector);

export const selectFullInspectionConditions = () =>
	useSelector(fullInspectionConditionSelector);

const mergedValidationSelector = (standard: InspectionStandard) => {
	return createSelector(fullInspStoreSelector, state => {
		const validationData: ValidationData[] = [];
		const { getSystemValueWithDecimalPlaces } = useUnits();

		if (
			state.fullInspection.validationData &&
			state.fullInspection.validationData.length > 0
		) {
			for (const each of state.fullInspection.validationData) {
				let message = each.errorMessage;
				if (
					each.target === 'lengthSurveyed' &&
					each.errorMessage.includes('asset.Length')
				) {
					message = each.errorMessage.replace(
						/-?\d+(\.\d+)?/g,
						match => {
							let convertedString =
								getSystemValueWithDecimalPlaces(`${match}m`, 2);
							if (!convertedString) {
								convertedString = `${match}m`;
							}
							return convertedString;
						},
					);
				}
				validationData.push({
					...each,
					errorMessage: message,
				});
			}
		}

		if (
			state.fullInspection.conditions &&
			state.fullInspection.conditions.length > 0
		) {
			switch (standard) {
				case InspectionStandard.MACP:
					for (const each of state.fullInspection.conditions) {
						const obs = each as MACPObservation;
						if (obs.validationData && obs.validationData.length) {
							validationData.push(
								...obs.validationData.map(condValidation => {
									return {
										...condValidation,
										errorMessage: t(
											'Distance: {{distance}}, MACP Code: {{code}}, Message: {{message}}',
											{
												distance:
													getSystemValueWithDecimalPlaces(
														`${obs.distance}m`,
														2,
													),
												code: obs.macpCode,
												message:
													condValidation.errorMessage,
											},
										),
									};
								}),
							);
						}
					}
					break;
				case InspectionStandard.MSCC:
					for (const each of state.fullInspection.conditions) {
						const obs = each as MSCCObservation;
						if (obs.validationData && obs.validationData.length) {
							validationData.push(
								...obs.validationData.map(condValidation => {
									return {
										...condValidation,
										errorMessage: t(
											'Distance: {{distance}}, MSCC Code: {{code}}, Message: {{message}}',
											{
												distance:
													getSystemValueWithDecimalPlaces(
														`${obs.distance}m`,
														2,
													),
												code: obs.code,
												message:
													condValidation.errorMessage,
											},
										),
									};
								}),
							);
						}
					}
					break;
				case InspectionStandard.WSA05:
					for (const each of state.fullInspection.conditions) {
						const obs = each as WSA05Observation;
						if (obs.validationData && obs.validationData.length) {
							validationData.push(
								...obs.validationData.map(condValidation => {
									return {
										...condValidation,
										errorMessage: t(
											'Distance: {{distance}}, WSA05 Code: {{code}}, Message: {{message}}',
											{
												distance:
													getSystemValueWithDecimalPlaces(
														`${obs.distance}m`,
														2,
													),
												code: obs.code,
												message:
													condValidation.errorMessage,
											},
										),
									};
								}),
							);
						}
					}
					break;
				default:
					for (const each of state.fullInspection.conditions) {
						const obs = each as PACPObservation;
						if (obs.validationData && obs.validationData.length) {
							validationData.push(
								...obs.validationData.map(condValidation => {
									return {
										...condValidation,
										errorMessage: t(
											'Distance: {{distance}}, PACP Code: {{code}}, Message: {{message}}',
											{
												distance:
													getSystemValueWithDecimalPlaces(
														`${obs.distance}m`,
														2,
													),
												code: obs.PACPCode,
												message:
													condValidation.errorMessage,
											},
										),
									};
								}),
							);
						}
					}
					break;
			}
		}

		return validationData;
	});
};

export const selectMergedValidation = (standard: InspectionStandard) =>
	useSelector(mergedValidationSelector(standard));

export default {
	selectNotes,
	selectFullInspection,
	selectFullInspectionIsWaiting,
	selectNotesIsWaiting,
	hasValidationIssues,
	selectFullInspectionConditions,
	selectMergedValidation,
};
