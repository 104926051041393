import React from 'react';

export const RiskIcon = () => (
	<svg
		width="94"
		height="100"
		viewBox="0 0 94 100"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M51.4725 72.2266L77.9055 42.3353L48.0153 68.7703C46.5124 68.4237 44.8718 68.8238 43.7022 69.9934C41.8938 71.8028 41.8949 74.7313 43.7022 76.5396C45.5105 78.348 48.4401 78.348 50.2485 76.5396C51.419 75.3701 51.8202 73.7294 51.4725 72.2266Z"
			fill="#768D95"
		/>
		<path
			d="M80.696 45.3917L75.8387 50.8836C80.6446 57.0667 83.5165 64.8237 83.5165 73.2431H90.717C90.717 62.6584 86.9553 52.9553 80.696 45.3917Z"
			fill="#768D95"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M52.0019 37.0681C58.4813 37.962 64.4207 40.566 69.3567 44.4015L74.8486 39.5443C67.3954 33.3784 57.8646 29.6356 47.462 29.5277L52.0019 37.0681ZM24.571 44.4437H14.1068C7.36704 52.1354 3.2821 62.2117 3.2821 73.2425H10.4826C10.4826 61.5552 16.0018 51.1323 24.571 44.4437Z"
			fill="#768D95"
		/>
		<path
			d="M26.1666 29.3904H22.2361V33.3205H26.1666V29.3904Z"
			fill="#768D95"
		/>
		<path
			d="M26.1666 15.6332H22.2361V27.4253H26.1666V15.6332Z"
			fill="#768D95"
		/>
		<path
			d="M47.3564 39.9353H1.24121L24.3005 0L47.3564 39.9353ZM8.05003 36.0043H40.5466L24.3005 7.86108L8.05003 36.0043Z"
			fill="#768D95"
		/>
	</svg>
);
