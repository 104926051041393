import MapboxLayer, { UniqueLayerProps } from './MapboxLayer';

import { ExpressionSpecification } from 'mapbox-gl';
import { ServiceLayer } from '@Map/services/types';
import SymbolLoader from '@Map/symbols/SymbolLoader';

export default class FillLayer extends MapboxLayer {
	protected _type: ServiceLayer['type'] = 'fill';

	layerProperties(): UniqueLayerProps {
		return {
			paint: {
				'fill-color': this._getFillColor(),
				'fill-opacity': this._fillOpacity ?? MapboxLayer.FILL_OPACITY,
				...(this._pattern
					? {
							'fill-pattern': SymbolLoader.createPatternId(
								this._pattern,
								this._color || MapboxLayer.UNSELECTED_COLOR,
								this._selected,
								this._selectedColor ||
									MapboxLayer.SELECTED_COLOR,
							),
					  }
					: {}),
			},
		};
	}

	private _getFillColor(): string | ExpressionSpecification {
		const selectedColor =
			this._selectedColor || MapboxLayer.SELECTED_COLOR_FILL;
		const color =
			this._colorLookup ||
			this._color ||
			MapboxLayer.UNSELECTED_COLOR_FILL;
		if (this._highlight) {
			return this._highlightColor || MapboxLayer.HIGHLIGHT_COLOR;
		}
		return [
			'case',
			['boolean', ['feature-state', 'selected'], false],
			selectedColor,
			color,
		];
	}
}
