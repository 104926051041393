import { ChartInstanceRef } from './create-chart';
import { createChart } from './create-chart';
import {
  SynchronizedInstances,
  SynchronizedInstancesEventHandlers,
  makeOptionsWithSynchronizedEvents,
  useSynchronizedInstances,
} from './synchronized-instances';
import * as React from 'react';
import Highcharts from 'highcharts';
import Highstock from 'highcharts/highstock';
import styled, { css } from 'styled-components';
import { useStableEventHandlers } from './use-stable-event-handlers';
import { useIsFeatureEnabled } from '@innovyze/stylovyze';

SynchronizedInstances(Highcharts);
SynchronizedInstances(Highstock);

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

interface StackableSeriesProps {
  stackedSeriesIndex?: number;
  stackedInstanceType?: 'stacked-header' | 'stacked-series' | 'stacked-footer';
}

interface StackableInstanceWrapperProps {
  children: React.ReactNode;
  stackedInstanceType?: 'stacked-header' | 'stacked-series' | 'stacked-footer';
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const createStackableChart = (
  chartName: string,
  headerTop?: number,
  enablePanning = false
) => {
  const Chart = createChart<
    | 'overlay-instance'
    | 'stacked-header'
    | 'stacked-footer'
    | `stacked-series-${string}`
  >(chartName, headerTop, enablePanning);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Root Component
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  const ChartRoot = React.forwardRef<
    HTMLDivElement,
    { children: React.ReactNode; enableStackedChart?: boolean }
  >((props, ref) => {
    /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
     * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
    return <Chart.ChartRoot ref={ref}>{props.children}</Chart.ChartRoot>;
  });

  ChartRoot.displayName = 'StackableChartRoot';

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Overlay Instance
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  const ChartOverlayInstance = React.forwardRef<
    ChartInstanceRef,
    {
      children: React.ReactNode;
      instanceWrapperComponent?: React.ComponentType<StackableInstanceWrapperProps>;
      initialOptions: Highcharts.Options;
      constructorFunction: typeof Highcharts.chart;
    }
  >((props, ref): React.ReactElement => {
    const InstanceWrapper = props.instanceWrapperComponent ?? React.Fragment;
    const enableNewHeader = useIsFeatureEnabled(
      'info-360-analytics-charts-date-time-picker'
    );

    /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
     * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
    return (
      <Chart.ChartInstance {...props} ref={ref} id="overlay-instance">
        {enableNewHeader ? <Chart.ChartHeader /> : null}
        <InstanceWrapper>{props.children}</InstanceWrapper>
      </Chart.ChartInstance>
    );
  });

  ChartOverlayInstance.displayName = 'ChartOverlayInstance';

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Stacked Instances
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  const ChartStackedInstances = React.forwardRef<
    ChartInstanceRef,
    {
      children: React.ReactNode;
      instanceWrapperComponent?: React.ComponentType<StackableInstanceWrapperProps>;
      headerInstanceInitialOptions: Highcharts.Options;
      seriesInstanceInitialOptions: Highcharts.Options;
      footerInstanceInitialOptions: Highcharts.Options;
      constructorFunction: typeof Highcharts.chart;
    }
  >((props, ref): React.ReactElement => {
    const InstanceWrapper = props.instanceWrapperComponent ?? React.Fragment;
    const enableNewHeader = useIsFeatureEnabled(
      'info-360-analytics-charts-date-time-picker'
    );

    const rootContext = Chart.useRootContext('ChartStackedInstances');
    const seriesElements = pickReactElements(props.children);
    const { synchronizeInstanceExtremes } = useSynchronizedInstances(
      rootContext.containerElementRef,
      rootContext.instanceMapRef
    );

    const eventHandlersRef =
      useStableEventHandlers<SynchronizedInstancesEventHandlers>({
        synchronizeInstanceExtremes,
      });

    const stackedHeaderInstanceInitialOptions = React.useMemo(() => {
      return makeOptionsWithSynchronizedEvents(
        props.headerInstanceInitialOptions,
        eventHandlersRef
      );
    }, [eventHandlersRef]);

    const stackedSeriesInstanceInitialOptions = React.useMemo(() => {
      return makeOptionsWithSynchronizedEvents(
        props.seriesInstanceInitialOptions,
        eventHandlersRef
      );
    }, [eventHandlersRef]);

    const stackedFooterInstanceInitialOptions = React.useMemo(() => {
      return makeOptionsWithSynchronizedEvents(
        props.footerInstanceInitialOptions,
        eventHandlersRef
      );
    }, [eventHandlersRef]);

    React.useLayoutEffect(() => {
      window.dispatchEvent(new Event('resize'));
    });

    /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
     * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
    return (
      <StackedInstances.Root>
        <StackedInstances.Header>
          <Chart.ChartInstance
            id="stacked-header"
            initialOptions={stackedHeaderInstanceInitialOptions}
            constructorFunction={props.constructorFunction}>
            {enableNewHeader ? <Chart.ChartHeader /> : null}
            <InstanceWrapper stackedInstanceType="stacked-header">
              {seriesElements.map((element, index) => {
                const id = element.props.id || index;

                return React.cloneElement(element, {
                  key: `stacked-series-${id}`,
                  stackedInstanceType: 'stacked-header',
                  stackedSeriesIndex: index,
                });
              })}
            </InstanceWrapper>
          </Chart.ChartInstance>
        </StackedInstances.Header>
        <StackedInstances.ScrollArea>
          {seriesElements.map((element, index) => {
            const id = element.props.id || index;
            // const hiddenByInternalState =
            // 	stackableContext.internalStateRef.current.hiddenSeries.includes(
            // 		index,
            // 	);

            // if (hiddenByControlledProp || hiddenByInternalState) {
            // 	return null;
            // }

            return (
              <StackedInstances.Series
                hidden={element.props.hidden}
                key={`stacked-series-layout-${id}`}>
                <Chart.ChartInstance
                  id={`stacked-series-${id}`}
                  initialOptions={stackedSeriesInstanceInitialOptions}
                  constructorFunction={props.constructorFunction}>
                  <InstanceWrapper stackedInstanceType="stacked-series">
                    {React.cloneElement(element, {
                      key: `stacked-series-${id}`,
                      stackedInstanceType: 'stacked-series',
                      stackedSeriesIndex: index,
                    })}
                  </InstanceWrapper>
                  {/* {childrenArray.map((child, index) => {
										return React.cloneElement(child, {
											...child.props,
											key: index,
										});
									})} */}
                </Chart.ChartInstance>
              </StackedInstances.Series>
            );
          })}
        </StackedInstances.ScrollArea>
        <StackedInstances.Footer>
          <Chart.ChartInstance
            id="stacked-footer"
            ref={ref}
            initialOptions={stackedFooterInstanceInitialOptions}
            constructorFunction={props.constructorFunction}>
            <InstanceWrapper stackedInstanceType="stacked-footer">
              {seriesElements.map((element, index) => {
                const id = element.props.id || index;

                return React.cloneElement(element, {
                  key: `stacked-series-${id}`,
                  stackedInstanceType: 'stacked-footer',
                  stackedSeriesIndex: index,
                });
              })}
            </InstanceWrapper>
            {/* {childrenArray.map((child, index) => {
							return React.cloneElement(child, {
								...child.props,
								key: index,
							});
						})}
						{seriesChildrenArray.map((child, index) => {
							return React.cloneElement(child, {
								...child.props,
								key: index,
							});
						})} */}
          </Chart.ChartInstance>
        </StackedInstances.Footer>
      </StackedInstances.Root>
    );
  });

  ChartStackedInstances.displayName = 'ChartStackedInstances';

  const StackedInstances = {
    Root: styled.div`
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
      width: 100%;
    `,
    Header: styled.div<{ withTitle?: boolean }>`
      height: ${(props) => (props.withTitle ? '80px' : '40px')};
      width: 100%;
    `,
    Footer: styled.div`
      height: 120px;
      width: 100%;
    `,
    Series: styled.div<{ hidden?: boolean }>`
      ${(props) =>
        props.hidden
          ? css`
              height: 0;
            `
          : css`
              flex: 1 1 0;
              min-height: 160px;
            `}
    `,
    ScrollArea: styled.div`
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      flex: 1 1 0;
      overflow: auto;
      width: 100%;
    `,
  };

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Instance Hook
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  const useInstance = (consumerName: string) => {
    const instance = Chart.useInstance(consumerName);
    return instance;
  };

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  return {
    ...Chart,
    ChartRoot,
    ChartOverlayInstance,
    ChartStackedInstances,
    useInstance,
  };
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Utils
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const pickReactElements = (node: React.ReactNode) => {
  const elements: React.ReactElement[] = [];
  const children = React.Children.toArray(node);

  for (const child of children) {
    if (React.isValidElement(child)) {
      elements.push(child);
    }
  }

  return elements;
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export { createStackableChart };

export type { StackableSeriesProps, StackableInstanceWrapperProps };
