import { getManufacturerCurves } from '../../../services/asset.service';
import { TFunc } from '../../../i18n';
import { useEffect, useState } from 'react';
import type { AsyncData } from '../../types/async.types';
import type {
  ConnectedManufacturerCurve,
  ManufacturerCurve,
} from '../../../types';

const useManufacturerCurves = (
  t: TFunc,
  curves?: ConnectedManufacturerCurve[]
): AsyncData<ManufacturerCurve[]> => {
  const curveIds = curves?.map((curve) => curve.id);
  const [asyncCurves, setAsyncCurves] = useState<
    AsyncData<ManufacturerCurve[]>
  >({
    status: 'idle',
    data: null,
  });

  useEffect(() => {
    const fetch = async () => {
      if (curveIds === undefined || curveIds.length === 0) {
        setAsyncCurves({
          data: [],
          status: 'resolved',
        });

        return;
      }

      setAsyncCurves({
        data: null,
        status: 'loading',
        message: t('Loading manufacturer curves'),
      });

      try {
        const response = await getManufacturerCurves(curveIds);
        const _curves =
          response.data.assets === undefined ||
          response.data.assets.length === 0
            ? []
            : response.data.assets.map<ManufacturerCurve>((asset) => {
                return {
                  ...asset,
                  data: asset.depthArray.map((x, index) => {
                    const y = asset.volumeArray[index];
                    return [x, y];
                  }),
                };
              });

        setAsyncCurves({
          data: _curves,
          status: 'resolved',
        });
      } catch (error) {
        setAsyncCurves({
          data: [],
          status: 'resolved',
          message: t('Failed to load manufacturer curves'),
        });
      }
    };

    fetch();
  }, [curveIds?.join('')]);

  return asyncCurves;
};

export default useManufacturerCurves;
