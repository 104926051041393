import {
  SynchronizedEvent,
  SynchronizedEventType,
} from '../../utils/synchronizedChartsModule';
import { MutableRefObject, useMemo, useLayoutEffect } from 'react';
import Highcharts from 'highcharts';

const useSynchronizedCharts = (
  containerRef: MutableRefObject<HTMLDivElement | null>,
  chartsRef: MutableRefObject<Highcharts.Chart[] | null>
): ((
  this: Highcharts.Axis,
  e: Highcharts.AxisSetExtremesEventObject
) => void) => {
  const syncExtremes = useMemo(() => {
    return createSyncExtremesFunction(chartsRef);
  }, [chartsRef]);

  useLayoutEffect(() => {
    const syncPointsEventHandler = createSyncPointsEventHandler(chartsRef);

    const syncPointsEvents: SynchronizedEventType[] = [
      'mousemove',
      'touchmove',
      'touchstart',
    ];

    syncPointsEvents.forEach((event) => {
      containerRef.current?.addEventListener(event, syncPointsEventHandler);
    });

    return () => {
      syncPointsEvents.forEach((event) => {
        containerRef.current?.removeEventListener(
          event,
          syncPointsEventHandler
        );
      });
    };
  }, [chartsRef]);

  return syncExtremes;
};

const createSyncPointsEventHandler = (
  chartsRef: MutableRefObject<Highcharts.Chart[] | null>
) => {
  return (e: SynchronizedEvent): void => {
    chartsRef.current?.forEach((chart) => {
      if (!!chart?.options && !!chart?.pointer) {
        const event = chart.pointer.normalize(e);
        const point = chart.series[0]?.searchPoint?.(event, true);

        point?.highlight?.(e);
      }
    });
  };
};

const createSyncExtremesFunction = (
  chartsRef: MutableRefObject<Highcharts.Chart[] | null>
) => {
  return function (
    this: Highcharts.Axis,
    e: Highcharts.AxisSetExtremesEventObject
  ): void {
    if (e.trigger !== 'syncExtremes') {
      chartsRef.current?.forEach((chart) => {
        if (chart !== this.chart && chart) {
          chart.xAxis[0]?.setExtremes?.(e.min, e.max, true, false, {
            trigger: 'syncExtremes',
          });
        }
      });
    }
  };
};

export default useSynchronizedCharts;
