import {
	AssetType,
	AssetTypeManhole,
	AssetTypePipe,
	AssetTypeWDPipe,
} from '@Types/map.types';

export const formatUrlTrailing = (url: string): string =>
	url.match(/\/$/) ? url : url + '/';

export const makeMapURL = (
	assetType: AssetType,
	assetId: string,
	position: GeoJSON.Position,
): string | undefined => {
	switch (assetType) {
		case AssetTypeManhole:
			return `/map#/map/streets/${position[0]},${position[1]}/18/sanitary_sewer_manhole:wwManhole=~${assetId}`;
		case AssetTypePipe:
			return `/map#/map/streets/${position[0]},${position[1]}/18/sanitary_sewer_pipe:wwPipe=~${assetId}`;
		case AssetTypeWDPipe:
			return `/map#/map/streets/${position[0]},${position[1]}/18/water_distribution_pipe:pipe=~${assetId}`;
	}
};
