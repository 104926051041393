import {
	getRiskConfigsList,
	getRiskConfigsListRejected,
	getRiskConfigsListResolved,
} from '@Actions';
import { AnyAction, createReducer } from '@reduxjs/toolkit';
import { RiskConfigsListState } from '@Types';

// I HAVE NO IDEA WHY I HAVE TO DUPLICATE THIS HERE... without it I get this error when runnin in a module
// Uncaught Error: The slice reducer for key "riskConfigs" returned undefined during initialization. If the state passed to the reducer is undefined, you must explicitly return the initial state.
// It seems the paginationInitialState is getting initilized AFTER the reducers but I cant see why
export const initialState: RiskConfigsListState = {
	configs: [],
	isLoading: false,
};

interface RiskConfigsListReducer {
	[x: string]: (
		state: RiskConfigsListState,
		action: AnyAction,
	) => RiskConfigsListState | undefined;
}

export const reducer: RiskConfigsListReducer = {
	[getRiskConfigsList.toString()]: () => ({
		...initialState,
		isLoading: true,
	}),
	[getRiskConfigsListResolved.toString()]: (_state, action) => ({
		configs: action.payload,
		isLoading: false,
	}),
	[getRiskConfigsListRejected.toString()]: () => ({
		...initialState,
	}),
};

export const riskConfigsListReducer = createReducer(initialState, reducer);

export default createReducer(initialState, reducer);
