import * as Highcharts from 'highcharts/highstock';
import { defaultExportingOptions } from './defaultStockChartConfig';

export const defaultPieChartConfig: Highcharts.Options = {
  chart: {
    plotShadow: false,
    type: 'pie',
  },
  exporting: defaultExportingOptions,
  credits: { enabled: false },
  title: {
    text: undefined,
  },
  tooltip: {
    pointFormat: '<b style="font-size: 16px">{point.percentage:.1f}%</b>',
  },

  accessibility: {
    point: {
      valueSuffix: '%',
    },
  },
  legend: {
    enabled: true,
    symbolHeight: 10,
    symbolRadius: 0,
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      dataLabels: {
        enabled: false,
      },
      showInLegend: true,
      borderWidth: 0,
    },
    // series: {
    // 	marker: {
    // 		enabled: false,
    // 	},
    // },
  },
};
