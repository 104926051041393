import { HistoricalSeries } from '../../../core/types/historical.types';
import { HistoricalSeriesConnected } from '../../../types/historicalChart.types';

export const generateHistoricalSeries = (
  primarySeries: HistoricalSeriesConnected,
  referenceSeries?: HistoricalSeriesConnected[]
): HistoricalSeries[] => {
  const series: HistoricalSeries[] = [];

  const { id: sensorId, resolution, ...restPrimarySeries } = primarySeries;

  series.push({
    ...restPrimarySeries,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dataSource: { sensorId, resolution },
  });

  referenceSeries?.forEach((referenceSeries) => {
    const { id: sensorId, resolution, ...restSeries } = referenceSeries;

    series.push({
      ...restSeries,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dataSource: { sensorId, resolution },
    });
  });

  return series;
};
