import { createStyles, makeStyles } from '@mui/styles';

export const useStyle = makeStyles(() =>
	createStyles({
		editBtn: {
			marginRight: '24px',
		},
		cancelBtn: {
			marginRight: '10px',
		},
		saveBtn: {
			marginRight: '24px',
		},
		contentWrapper: {
			alignItems: 'center',
			display: 'flex',
			webkitBoxPack: 'justify',
			justifyContent: 'space-between',
			fontSize: '1.125rem',
			padding: '0.5rem 0.9375rem 0.5rem 1.5rem',
			webKitBoxAlign: 'center',
			fontWeight: 600,
			height: '3.281rem',
		},
	}),
);
