import { createSelector } from '@reduxjs/toolkit';

import type { AsyncData } from '../types/async.types';
import type { Ili } from '../types/ili.types';
import type { StoreState } from '../../store/store';

export const selectIlisFromWorkspace = (state: StoreState) => {
  return state.workspaces?.externalResources?.ilis;
};

export const makeSelectIliByIdFromWorkspace = () => {
  return createSelector(
    selectIlisFromWorkspace,
    (_: unknown, iliId: string) => iliId,
    (ilis, iliId): AsyncData<Ili> => {
      if (ilis === undefined) {
        return { status: 'rejected', data: null };
      }

      const storedIli = ilis.find((ili) => ili.id === iliId);

      if (!storedIli) {
        return { status: 'rejected', data: null };
      }

      return { status: 'resolved', data: storedIli };
    }
  );
};
