import { all } from 'redux-saga/effects';
import assetSagas from './asset';
import facilitySagas from './facility';
import globalSagas from './global';
import inspectionSagas from './inspection';
import mapSagas from './map';
import riskSagas from './risk';
// plop - add saga import here

export default function* rootSaga(): Generator {
	yield all([
		...riskSagas,
		...assetSagas,
		...inspectionSagas,
		...globalSagas,
		...facilitySagas,
		...mapSagas,
		// plop - add saga usage here
	]);
}
