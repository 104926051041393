import { AnyAction, createReducer } from '@reduxjs/toolkit';
import {
	getInspectionAttributes,
	getInspectionAttributesRejected,
	getInspectionAttributesResolved,
	getInspectionAttributesWaiting,
	refreshInspection,
	setCreateInspectionId,
	setCreatingInspection,
} from '@Actions/inspection.actions';

import { InspectionAttributeStoreState } from '@Types/inspectionAttributes.types';

export const initState: InspectionAttributeStoreState = {
	inspectionAttributes: [],
	isWaiting: false,
	updateStatus: 0,
	creatingInspection: false,
};

interface InspectionReducer {
	[x: string]: (
		state: InspectionAttributeStoreState,
		action: AnyAction,
	) => InspectionAttributeStoreState | undefined;
}

const reducer: InspectionReducer = {
	[getInspectionAttributes.toString()]: state => ({
		...state,
		initialized: true,
		inspectionAttributes: [],
		isWaiting: true,
	}),
	[getInspectionAttributesWaiting.toString()]: (state, action) => ({
		...state,
		isWaiting: action.payload,
	}),
	[getInspectionAttributesResolved.toString()]: (state, action) => ({
		...state,
		initialized: true,
		inspectionAttributes: action.payload.data,
		isWaiting: false,
	}),
	[getInspectionAttributesRejected.toString()]: state => ({
		...state,
		initialized: true,
		isWaiting: false,
	}),
	[refreshInspection.toString()]: (state, action) => ({
		...state,
		initialized: true,
		updateStatus: action.payload.status,
	}),
	[setCreateInspectionId.toString()]: (state, action) => ({
		...state,
		createInspectionId: action.payload,
	}),
	[setCreatingInspection.toString()]: (state, action) => ({
		...state,
		creatingInspection: action.payload,
	}),
};

export default createReducer(initState, reducer);
