import { FeatureItem, ItemDetailsSelector } from '@Utils/types';

import { NamespacedStoreState } from '@Types/store.types';
import { useSelector } from 'react-redux';

type UseItemDetailsItem = FeatureItem | null | undefined;
export const useItemDetails = <T extends UseItemDetailsItem>(
	item: T,
	getItemDetailsSelector?: ItemDetailsSelector,
): T => {
	const extraDetails = useSelector<
		NamespacedStoreState,
		FeatureItem | undefined
	>(state => getItemDetailsSelector?.(state, item?.id ?? null));

	if (extraDetails) {
		return { ...item, ...extraDetails };
	}
	return item;
};
