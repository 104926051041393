import { isNumber } from 'highcharts';
import {
  CustomAnalytic,
  CustomAnalyticInterval,
  CustomAnalyticSeries,
  CustomAnalyticSeriesType,
  OverriddenCustomAnalyticNode,
  sourceLink,
  sourceNode,
} from '../../../core/types/analytic.types';
import { Reading } from '../../../core/types/reading.types';
import { Resolution } from '../../../core/types/resolution.types';
import { AnalyticFunctions } from '../../../types/analyticFunction.types';
import { DataSourceType } from '../../../core/types/data.types';

const resolutionIndex: Record<Resolution, number> = {
  [Resolution['15 Minutes']]: 0,
  [Resolution['30 Minutes']]: 1,
  [Resolution.Daily]: 2,
  [Resolution.Hourly]: 3,
  [Resolution.Raw]: 4,
  [Resolution.Weekly]: 5,
};

const getResolution = (
  node: sourceNode,
  list: sourceNode[],
  links: sourceLink[]
): Resolution => {
  const outputNodes = links
    .filter((link) => link.target === node.name)
    .map((link) => link.source);
  const resolutions = list
    .filter((n) => outputNodes.includes(n.name))
    .map((n) => Resolution[CustomAnalyticInterval[n.interval]]);
  const minResolution =
    resolutions.length > 0
      ? Math.min(...resolutions.map((r) => resolutionIndex[r]))
      : 2;
  return (
    (Object.keys(resolutionIndex).find(
      (key) => resolutionIndex[key as Resolution] === minResolution
    ) as Resolution) || Resolution.Daily
  );
};

export const generateCustomAnalyticSeries = (
  customAnalytic: CustomAnalytic,
  overriddenNodes?: OverriddenCustomAnalyticNode[]
): (CustomAnalyticSeries & { isAnalytic: boolean })[] => {
  const series: (CustomAnalyticSeries & { isAnalytic: boolean })[] = [];
  const nodes = customAnalytic.configGraph?.nodes ?? [];

  if (nodes && nodes.length > 0) {
    nodes.forEach((node) => {
      const overriddenNode = overriddenNodes?.find(
        (overriddenNode) => overriddenNode.id === node._id
      );

      let resolution = node.interval
        ? Resolution[CustomAnalyticInterval[node.interval]]
        : getResolution(
            node,
            customAnalytic.configGraph.nodes,
            customAnalytic.configGraph.links
          );

      let func: Reading | AnalyticFunctions = Reading.Close;
      let isAnalytic = false;
      const params = overriddenNode?.params;

      if (node.seriesType !== undefined) {
        func = Reading[CustomAnalyticSeriesType[node.seriesType]];
      }

      if (overriddenNode?.interval !== undefined) {
        resolution = overriddenNode.interval;
      }

      if (overriddenNode?.func !== undefined) {
        func = overriddenNode.func;
        isAnalytic = true;
      }

      const seriesNode = {
        id: node._id,
        name: node.name,
        channelId: node.channelId,
        interval: resolution,
        func,
        params,
      };

      if (node.channelId) {
        series.push({
          id: node._id,
          isAnalytic,
          dataSource: {
            sensorId: node.channelId,
            resolution,
            type: DataSourceType.SensorDataSource,
          },
          node: seriesNode,
        });
        return;
      }
      if (
        node.createTimeSeries &&
        node.value !== undefined &&
        isNumber(node.value)
      ) {
        series.push({
          id: node._id,
          isAnalytic,
          dataSource: {
            value: node.value,
            type: DataSourceType.ConstantDataSource,
          },
          node: seriesNode,
        });
      }
    });
  }

  return series;
};
