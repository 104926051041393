import { AssetTypeSelect, AssetTypeSelectProps } from './AssetTypeSelect';
import { Heading, Wrapper } from './SelectSystemAsset.styles';
import { SystemTypeSelect, SystemTypeSelectProps } from './SystemTypeSelect';

import { LayersIcon } from './LayersIcon';
import React from 'react';
import { useGlobalization } from '@Translations/useGlobalization';

type SelectSystemAssetProps = SystemTypeSelectProps & AssetTypeSelectProps;

export const SelectSystemAsset = ({
	assetType,
	systemType,
	onSystemTypeChange,
	onAssetTypeChange,
	isSpatialDataEnabled,
}: SelectSystemAssetProps): JSX.Element => {
	const { t } = useGlobalization();

	const headline = isSpatialDataEnabled
		? t('To see a list of assets, select a data type and layer')
		: t('To see a list of assets, select a system type and asset type');

	return (
		<Wrapper>
			<Heading>{headline}</Heading>
			<LayersIcon />
			<div>
				<SystemTypeSelect
					systemType={systemType}
					onSystemTypeChange={onSystemTypeChange}
					dataCy="system-type-select"
					isSpatialDataEnabled={isSpatialDataEnabled}
				/>
				<AssetTypeSelect
					systemType={systemType}
					assetType={assetType}
					onAssetTypeChange={onAssetTypeChange}
					dataCy="asset-type-select"
					isSpatialDataEnabled={isSpatialDataEnabled}
				/>
			</div>
		</Wrapper>
	);
};
