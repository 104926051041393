import { UnitDisplay, useTzDateTime } from '@innovyze/stylovyze';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
/* eslint-disable react/prop-types, react/display-name */
import { FieldType } from '@Types/map.types';
import React from 'react';

type RenderCell =
	| ((options: {
			value?: string | number | Date | null | boolean;
	  }) => JSX.Element)
	| undefined;

export const renderCell = (fieldType: FieldType, unit?: string): RenderCell => {
	if (unit) {
		return ({ value }) =>
			value ? <UnitDisplay value={`${value}${unit}`} /> : <>-</>;
	}

	if (fieldType === FieldType.Date) {
		return ({ value }) => {
			const { formatDate } = useTzDateTime({ timeZone: 'utc' });

			if (!value || typeof value === 'boolean') {
				return <>-</>;
			}
			const rawDate = new Date(value);

			return (
				<>
					{formatDate({
						date: new Date(
							Date.UTC(
								rawDate.getFullYear(),
								rawDate.getMonth(),
								rawDate.getDate(),
							),
						),
						format: 'date',
					})}
				</>
			);
		};
	} else if (fieldType === FieldType.Boolean) {
		return ({ value }) => {
			return value ? (
				<CheckCircleIcon sx={{ color: '#4a7e04' }} />
			) : (
				<CancelIcon sx={{ color: '#aa0000' }} />
			);
		};
	}
};
