import styled from 'styled-components';
import { Text } from '@innovyze/stylovyze';

export const StyledText = styled(Text)`
	margin-left: 16px;
`;

export const StyledDiv = styled.div`
	margin: -45px 0 0 7px;
`;
