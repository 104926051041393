import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const LayersIcon = (props: SvgIconProps): JSX.Element => {
	return (
		<SvgIcon
			viewBox="0 0 100 100"
			fill="none"
			style={{ fontSize: 100 }}
			{...props}>
			<path d="M70.6 24.3H44.7V25.8H70.6V24.3Z" fill="#4A6067" />
			<path d="M63.5 28.5H44.7V30H63.5V28.5Z" fill="#4A6067" />
			<path
				d="M41 21.6001H27.9V32.1001H41V21.6001ZM39.5 30.6001H29.4V23.1001H39.5V30.6001Z"
				fill="#4A6067"
			/>
			<path
				d="M21 17V36.8H78.2V17H21ZM76.7 35.2H22.5V18.5H76.7V35.2Z"
				fill="#4A6067"
			/>
			<path d="M70.6 47.1001H44.7V48.6001H70.6V47.1001Z" fill="#4A6067" />
			<path d="M63.5 51.3999H44.7V52.8999H63.5V51.3999Z" fill="#4A6067" />
			<path
				d="M27.9 54.9H41V44.5H27.9V54.9ZM29.4 46H39.5V53.5H29.4V46Z"
				fill="#4A6067"
			/>
			<path
				d="M21 59.6H78.2V39.8H21V59.6ZM22.5 41.3H76.7V58.1H22.5V41.3Z"
				fill="#4A6067"
			/>
			<path d="M70.6 70H44.7V71.5H70.6V70Z" fill="#4A6067" />
			<path d="M63.5 74.2H44.7V75.7H63.5V74.2Z" fill="#4A6067" />
			<path
				d="M27.9 77.8H41V67.3H27.9V77.8ZM29.4 68.8H39.5V76.3H29.4V68.8Z"
				fill="#4A6067"
			/>
			<path
				d="M21 82.5H78.2V62.7H21V82.5ZM22.5 64.2H76.7V81H22.5V64.2Z"
				fill="#4A6067"
			/>
		</SvgIcon>
	);
};
