import MapboxLayer, { UniqueLayerProps } from './MapboxLayer';

import { ExpressionSpecification } from 'mapbox-gl';
import SymbolLoader from '@Map/symbols/SymbolLoader';

export default class LineLayer extends MapboxLayer {
	layerProperties(): UniqueLayerProps {
		return {
			layout: {
				'line-join': 'round',
				'line-cap': 'round',
			},
			paint: {
				'line-color': this._getLineColor(),
				'line-width': this._getLineWidth(),
				'line-opacity': this._getLineOpacity(),
				...(this._lineDashArray
					? { 'line-dasharray': this._lineDashArray }
					: {}),
				...(this._pattern
					? {
							'line-pattern': SymbolLoader.createPatternId(
								this._pattern,
								this._color || MapboxLayer.UNSELECTED_COLOR,
								this._selected,
								this._selectedColor ||
									MapboxLayer.SELECTED_COLOR,
							),
					  }
					: {}),
			},
		};
	}

	private _getLineOpacity(): number {
		if (this._highlight) {
			return 0.7;
		}
		return 1;
	}

	private _getLineColor(): string | ExpressionSpecification {
		const selectedColor = this._selectedColor || MapboxLayer.SELECTED_COLOR;
		const color =
			this._colorLookup || this._color || MapboxLayer.UNSELECTED_COLOR;
		if (this._highlight) {
			return this._highlightColor || MapboxLayer.HIGHLIGHT_COLOR;
		}
		return [
			'case',
			['boolean', ['feature-state', 'selected'], false],
			selectedColor,
			color,
		];
	}

	private _getLineWidth(): number | ExpressionSpecification {
		const lineWidth = this._lineWidth || 3;
		if (this._pattern) {
			return lineWidth;
		} else if (this._highlight) {
			return lineWidth + 15;
		} else if (this._selected) {
			return lineWidth + 1;
		}
		return [
			'case',
			['boolean', ['feature-state', 'selected'], false],
			lineWidth + 1,
			lineWidth,
		];
	}
}
