import { useLayoutEffect, useState } from 'react';

import type { DependencyList } from 'react';
import type { StackableChartProps } from '../../components/StackableChart';

const useStackOptions = (
  stackOptionsGenerator: () => StackableChartProps['stack'],
  deps?: DependencyList
) => {
  const [stackOptions, setStackOptions] = useState<
    StackableChartProps['stack']
  >(stackOptionsGenerator());

  useLayoutEffect(() => {
    setStackOptions(stackOptionsGenerator());
  }, deps);

  return stackOptions;
};

export default useStackOptions;
