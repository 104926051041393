/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  createChart,
  createContext,
  useStableEventHandlers,
  Status,
  ChartInstanceRef,
} from '../../core/_summaryze-chart';
import { useGlobalization } from '../../../i18n';
import { useSelectSettings } from '@innovyze/stylovyze';
import * as Options from './scatter-chart-options';
import * as React from 'react';
import Color from 'color';
import Highcharts from 'highcharts';
import {
  MARKER_SIZE_MAP,
  MarkerSizes,
  MarkerTypes,
} from '../../../types/chart.types';
import styled from 'styled-components';
import { getTheme, getThemeColor, Theme } from '../../core/utils/theme-utils';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Chart
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const Chart = createChart('ScatterChartRoot');

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Chart Root
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
interface SeparateAxis {
  id: string;
  title: string;
  yAxisMin?: number;
  yAxisMax?: number;
  yAxisSide?: string;
}

interface ScatterChartRootProps {
  children: React.ReactNode;
  enableHorizontalGrids?: boolean;
  enableVerticalGrids?: boolean;
  xAxisMin?: number;
  xAxisMax?: number;
  xAxisLabel?: string;
  yAxis?: Map<string, SeparateAxis>;
  onSeriesVisibilityChange?: (id: string, type: 'show' | 'hide') => void;
  selectedTheme?: Theme;
}

type ScatterChartContext = Pick<
  ScatterChartRootProps,
  | 'enableHorizontalGrids'
  | 'enableVerticalGrids'
  | 'xAxisMin'
  | 'xAxisMax'
  | 'xAxisLabel'
  | 'yAxis'
  | 'selectedTheme'
>;

type ScatterChartEventHandlers = Pick<
  ScatterChartRootProps,
  'onSeriesVisibilityChange'
>;

const [RootContextProvider, useRootContext] =
  createContext<ScatterChartContext>('RootContextProvider');

const ScatterChartRoot = React.forwardRef<
  ChartInstanceRef,
  ScatterChartRootProps
>((props, ref): React.ReactElement => {
  const { t } = useGlobalization();
  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Event Handlers
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  const eventHandlersRef = useStableEventHandlers<ScatterChartEventHandlers>({
    onSeriesVisibilityChange: props.onSeriesVisibilityChange,
  });

  const initialOptions = React.useMemo(() => {
    return Options.makeOptionsWithEventHandlers(
      Options.initialOptions,
      eventHandlersRef
    );
  }, [eventHandlersRef]);
  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  return (
    <RootContextProvider
      enableHorizontalGrids={props.enableHorizontalGrids}
      enableVerticalGrids={props.enableVerticalGrids}
      yAxis={props.yAxis}
      xAxisMin={props.xAxisMin}
      xAxisMax={props.xAxisMax}
      xAxisLabel={props.xAxisLabel}
      selectedTheme={props.selectedTheme}>
      <Chart.ChartRoot>
        <Chart.ChartInstance
          ref={ref}
          initialOptions={initialOptions}
          constructorFunction={Highcharts.chart}>
          {props.children}
        </Chart.ChartInstance>
        <ColorLegend.Root>
          <ColorLegend.LegendText>{t('Latest')}</ColorLegend.LegendText>
          <ColorLegend.LegendGradient />
          <ColorLegend.LegendText>{t('Oldest')}</ColorLegend.LegendText>
        </ColorLegend.Root>
      </Chart.ChartRoot>
    </RootContextProvider>
  );
});

ScatterChartRoot.displayName = 'ScatterChartRoot';

const ColorLegend = {
  Root: styled.div`
    align-items: center;
    bottom: 6px;
    display: flex;
    position: absolute;
    right: 12px;
  `,
  LegendText: styled.div`
    font-size: 11px;
    color: #666666;
  `,
  LegendGradient: styled.div`
    background: linear-gradient(
      to right,
      rgba(196, 196, 196, 1),
      rgba(196, 196, 196, 0.1)
    );
    height: 8px;
    margin: 5px;
    width: 40px;
    border-radius: 4px;
  `,
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Series Group Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

interface ScatterChartSeriesGroupProps {
  children: React.ReactNode;
  status?: Status;
}

const ScatterChartSeriesGroup = (
  props: ScatterChartSeriesGroupProps
): React.ReactElement => {
  const { t } = useGlobalization();
  const rootContext = useRootContext('StackableInstanceWrapper');
  const instanceRef = Chart.useInstance('ScatterChartSeriesGroup');
  const instanceSeriesProps =
    Chart.useInstanceSeriesProps<ScatterChartSeriesProps>(
      'ScatterChartSeriesGroup'
    );
  const separateYAxisRef = React.useRef<Highcharts.Axis[]>();

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Set X axes labels
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  const xAxisUnit = React.useMemo(() => {
    return instanceSeriesProps[0]?.xAxisUnit ?? '';
  }, [instanceSeriesProps]);

  const xAxisLabel = React.useMemo(() => {
    if (instanceSeriesProps.length > 1) {
      return xAxisUnit;
    } else {
      return (
        instanceSeriesProps[0]?.xAxisSourceName ??
        instanceSeriesProps[0]?.xAxisUnit ??
        ''
      );
    }
  }, [instanceSeriesProps, xAxisUnit]);

  React.useEffect(() => {
    let _xAxisLabel = rootContext.xAxisLabel || xAxisLabel;

    if (xAxisUnit) {
      _xAxisLabel += ` (${xAxisUnit})`;
    }
    instanceRef.current?.xAxis[0]?.update({ title: { text: _xAxisLabel } });
  }, [instanceRef, rootContext.xAxisLabel, xAxisLabel, xAxisUnit, t]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Set Y axes labels
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    separateYAxisRef.current = [];
    rootContext?.yAxis?.forEach((value) => {
      const newAxis = instanceRef.current?.addAxis({
        id: value.id,
        title: { text: value.title },
      });
      separateYAxisRef.current.push(newAxis);
    });

    return () => {
      try {
        if (separateYAxisRef.current.length > 0)
          separateYAxisRef.current?.forEach((axis) => {
            axis?.remove();
            axis = undefined;
          });
      } catch (error) {
        console.log(error);
      }
    };
  }, [rootContext.yAxis, separateYAxisRef, instanceRef]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets horizontal gridlines
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    instanceRef.current?.yAxis[0]?.update({
      gridLineWidth: rootContext.enableHorizontalGrids ? 1 : 0,
    });
  }, [instanceRef, rootContext.enableHorizontalGrids]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets vertical gridlines
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    instanceRef.current?.xAxis[0]?.update({
      gridLineWidth: rootContext.enableVerticalGrids ? 1 : 0,
    });
  }, [instanceRef, rootContext.enableVerticalGrids]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets X axis min and max
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    instanceRef.current?.xAxis[0]?.setExtremes(
      numberify(rootContext.xAxisMin),
      numberify(rootContext.xAxisMax)
    );
  }, [instanceRef, rootContext.xAxisMin, rootContext.xAxisMax]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets status
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    if (props.status === 'loading') {
      instanceRef.current?.showLoading(t('Loading'));
    } else if (props.status === 'rejected') {
      instanceRef.current?.showLoading(t('Failed to retrieve data'));
    } else {
      const scatterSeries = instanceSeriesProps.filter(
        (s): s is ScatterChartScatterSeriesProps => s.type === 'scatter'
      );

      const noData = scatterSeries.every(
        (s) => s.data === undefined || s.data.length === 0
      );

      if (noData) {
        instanceRef.current?.showLoading(t('No Data'));
      } else {
        instanceRef.current?.hideLoading();
      }
    }
  }, [props.status, instanceRef, instanceSeriesProps, t]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets Theme
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    const theme = rootContext.selectedTheme;
    const themeOptions = getTheme(theme ?? 'Default');
    instanceRef.current?.update({ ...themeOptions });
  }, [instanceRef, rootContext.selectedTheme]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  return <>{props.children}</>;
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * ---SERIES Component----
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

type ScatterChartSeriesProps = ScatterChartScatterSeriesProps;

const ScatterChartSeries = (
  props: ScatterChartSeriesProps
): React.ReactElement | null => {
  if (props.type === 'scatter') {
    return <ScatterChartScatterSeries {...props} />;
  }

  return null;
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

interface ScatterChartScatterSeriesProps {
  type: 'scatter';
  color?: string;
  data?: ScatterChartScatterSeriesData;
  error?: unknown;
  hidden?: boolean;
  id?: string;
  uniqueId?: string;
  name?: string;
  status?: Status;
  xAxisSourceName?: string;
  xAxisUnit?: string;
  yAxisSourceName?: string;
  yAxisUnit?: string;
  yAxisMin?: number;
  yAxisMax?: number;
  yAxisLabel?: string;
  yAxisSide?: string;
  yAxis?: { id: string; title: string };
  enableHorizontal?: boolean;
  markerSize?: MarkerSizes | `${MarkerSizes}`;
  markerType?: MarkerTypes | `${MarkerTypes}`;
  zIndex?: number;
}

type ScatterChartScatterSeriesData = {
  timestamp: number;
  x: number;
  y: number | null;
}[];

const ScatterChartScatterSeries = (
  props: ScatterChartScatterSeriesProps
): null => {
  const { companySettings } = useSelectSettings();
  const seriesRef = Chart.useSeries(props, 'ScatterChartSeries');
  const instanceRef = Chart.useInstance('ScatterChartSeriesGroup');
  const rootContext = useRootContext('ScatterChartSeries');
  const extraSeriesRef = React.useRef<Highcharts.Series>();

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets series Y axis
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    if (!props.yAxis?.id) return;
    const chartAxis = instanceRef.current.yAxis.map((axis) => axis.options.id);
    const yAxisId = props.yAxis?.id;

    if (chartAxis.includes(yAxisId)) {
      seriesRef.current?.update({
        yAxis: yAxisId,
      } as Highcharts.SeriesOptionsType);
    }

    return () => {
      try {
        seriesRef.current?.update({
          yAxis: 0,
        } as Highcharts.SeriesOptionsType);
      } catch (error) {
        console.log(error);
      }
    };
  }, [seriesRef, props.yAxis, instanceRef, props.yAxisUnit]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets series name
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    seriesRef.current?.update({
      name: props.name,
    } as Highcharts.SeriesOptionsType);
  }, [seriesRef, props.name]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets series color
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    let newColor = props.color;
    if (newColor === undefined) {
      newColor = getThemeColor(
        rootContext.selectedTheme,
        seriesRef.current?.options?.index
      );
    }
    seriesRef.current?.update({
      color: newColor,
      colorAxis: newColor
    } as Highcharts.SeriesOptionsType);
  }, [seriesRef, props.color, rootContext.selectedTheme]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets series marker type
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    seriesRef.current?.update({
      marker: { symbol: props.markerType },
    } as Highcharts.SeriesOptionsType);
  }, [seriesRef, props.markerType]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets series marker size
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    seriesRef.current?.update({
      marker: {
        radius: props.markerSize
          ? MARKER_SIZE_MAP[props.markerSize]
          : undefined,
      },
    } as Highcharts.SeriesOptionsType);
  }, [seriesRef, props.markerSize]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets horizontal gridlines
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    seriesRef.current?.yAxis?.update({
      gridLineWidth: props.enableHorizontal ? 1 : 0,
    });
  }, [seriesRef, props.enableHorizontal]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets series visibility
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    seriesRef.current?.update({
      visible: !props.hidden,
    } as Highcharts.SeriesOptionsType);
  }, [seriesRef, props.hidden]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets series data
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    let data: Highcharts.PointOptionsObject[] = [];

    if (props.data) {
      let colors: Map<number, string> | undefined = undefined;

      const seriesColor =props.color ?? getThemeColor(
        rootContext.selectedTheme,
        seriesRef.current?.options?.index
      );

      if (seriesColor) {
        const timestamps = props.data.map(({ timestamp }) => timestamp);
        const gradient = makeColorGradient(seriesColor, 10, 0.1);
        colors = mapGradientToTimestamps(gradient, timestamps);
      }

      data = props.data.map(({ x, y, timestamp }) => {
        const color = colors?.get(timestamp);

        // Timestamp in custom is used in tooltip
        return {
          color,
          custom: { timestamp },
          x,
          y,
        };
      });
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    seriesRef.current?.update({
      data,
    } as Highcharts.SeriesOptionsType);
  }, [
    seriesRef,
    extraSeriesRef,
    props.color,
    props.data,
    rootContext.selectedTheme,
  ]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets tooltip
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    const dateFormat = (companySettings.dateFormat as string)
      .replace('DD', '%e')
      .replace('MM', '%m')
      .replace('YYYY', '%Y');

    const timeFormat = (companySettings.hourCycle12 as boolean)
      ? '%I:%M %p'
      : '%H:%M';

    const xAxisSourceName = props.xAxisSourceName ?? props.name;
    const yAxisSourceName = props.yAxisSourceName ?? props.name;

    seriesRef.current?.update({
      tooltip: {
        headerFormat: [
          '<span style="color:{point.color}">● </span>',
          `<b style="font-size: 10px">${props.name}</b><br />`,
        ].join(''),
        pointFormat: [
          `${xAxisSourceName}: {point.x:.2f} ${props.xAxisUnit}<br />`,
          `${yAxisSourceName}: {point.y:.2f} ${props.yAxisUnit}<br />`,
          `{point.custom.timestamp:${dateFormat} - ${timeFormat}}`,
        ].join(''),
      },
    } as Highcharts.SeriesScatterOptions);
  }, [
    seriesRef,
    props.name,
    props.yAxisSourceName,
    props.xAxisSourceName,
    companySettings.hourCycle12,
    companySettings.dateFormat,
    companySettings.UOM,
    props.xAxisUnit,
    props.yAxisUnit,
  ]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  return null;
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Generate color gradient
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const numberify = (something: unknown): number | undefined => {
  if (typeof something === 'number') return something;
  if (typeof something === 'string' && something.trim() !== '')
    return Number(something);

  return undefined;
};

const makeColorGradient = (color: string, shades: number, ratio = 0.8) => {
  return new Array<string>(shades)
    .fill(color)
    .map((_color, index) => new Color(_color).lighten(index * ratio).hex())
    .reverse();
};

const mapGradientToTimestamps = (gradient: string[], timestamps: number[]) => {
  const colors: Map<number, string> = new Map();

  timestamps.sort().forEach((timestamp, index) => {
    const colorIndex = Math.floor(
      (index * gradient.length) / timestamps.length
    );
    colors.set(timestamp, gradient[colorIndex]);
  });

  return colors;
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export { ScatterChartRoot, ScatterChartSeries, ScatterChartSeriesGroup };

export type {
  ScatterChartRootProps,
  ScatterChartScatterSeriesData,
  ScatterChartScatterSeriesProps,
  ScatterChartSeriesGroupProps,
  ScatterChartSeriesProps,
  ScatterChartContext,
  ScatterChartEventHandlers,
  SeparateAxis,
};
