import React, { ReactNode } from 'react';

import { Wrapper } from './LayerPanel.styles';
import { useEditor } from '@Components/Editor';

interface LayerPanelWrapperProps {
	open: boolean;
	tabIndex: number;
	children: ReactNode;
}

export const LayerPanelWrapper = ({
	open,
	tabIndex,
	children,
}: LayerPanelWrapperProps): JSX.Element => {
	const { mode: editorMode, open: editorOpen } = useEditor();

	return (
		<Wrapper
			id="layer-panel"
			data-cy="layer-panel"
			open={open}
			editorOpen={editorOpen}
			mode={editorMode}
			aria-hidden={!open}
			tabIndex={tabIndex}>
			{children}
		</Wrapper>
	);
};
