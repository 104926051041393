import {
	AssetsSearchResponse,
	CarmenGeoJson,
	FacilityResponse,
	GeocoderResult,
} from './types';
import { getCenterPoint, notUndefined, typeOf } from '@Map/utils';

import { GeometryPoint } from '@Map/services/types';
import { LngLat } from 'mapbox-gl';
import { isValidLocation } from '@Map/facility/facility.utils';
export default class SearchDataConverter {
	private _data: CarmenGeoJson | AssetsSearchResponse | FacilityResponse;
	private _serviceId: string | undefined;
	private assetTypeFirst = false;

	constructor(
		data: CarmenGeoJson | AssetsSearchResponse | FacilityResponse,
		assertTypeFirst = false,
		serviceId?: string,
	) {
		this._data = data;
		this.assetTypeFirst = assertTypeFirst;
		this._serviceId = serviceId;
	}

	convert(): GeocoderResult[] {
		if (Array.isArray(this._data)) {
			return this.convertFromAssetFormat(this._data);
		} else {
			if ('liftStations' in this._data)
				return this.convertFromFaciltyFormat(this._data);
			else return this.convertFromCarmenJson(this._data);
		}
	}

	convertFromCarmenJson(data: CarmenGeoJson): GeocoderResult[] {
		return data.features.map(feature => ({
			...feature,
			place_name: feature.place_name ?? feature.text,
			serviceId: this._serviceId,
			query: data.query,
			lngLat: this._convertTolngLat(data.query),
		}));
	}

	convertFromFaciltyFormat(data: FacilityResponse): GeocoderResult[] {
		return data.liftStations
			.map(({ _id, name, liftStationData }) => {
				if (
					!liftStationData?.location ||
					!isValidLocation(liftStationData.location)
				)
					return;
				const { longitude, latitude } = liftStationData.location;
				const searchText = `${name}, facility`;
				const geometry = {
					type: 'Point',
					coordinates: [longitude, latitude],
				} as GeometryPoint;
				return {
					id: _id,
					text: searchText,
					place_name: searchText,
					type: 'Feature',
					geometry,
					center: getCenterPoint(geometry),
					serviceId: this._serviceId,
				};
			})
			.filter(notUndefined);
	}

	convertFromAssetFormat(data: AssetsSearchResponse): GeocoderResult[] {
		return data
			.map(({ _id, assetId, sensorId, assetType, geometry }) => {
				const searchText = this._searchText(
					assetType,
					assetId,
					sensorId,
				);
				if (!geometry) return;
				return {
					id: _id,
					text: searchText,
					place_name: searchText,
					type: 'Feature',
					geometry,
					center: getCenterPoint(geometry),
					serviceId: this._serviceId,
				};
			})
			.filter(notUndefined);
	}

	private _searchText(
		assetType: string,
		assetId: string,
		sensorId?: string,
	): string {
		const featureId = assetType === 'sensor' ? sensorId : assetId;
		if (this.assetTypeFirst) {
			return `${assetType}#${featureId}`;
		}
		return `${featureId}, ${assetType}`;
	}

	/**
	 * Convert the mapbox query (place name or coordinates) to
	 * LngLat object if the query is coordinates and not a place name
	 * @param query array of strings or coordinates
	 * @returns LngLat object or undefined
	 */
	private _convertTolngLat(
		query: (string | number)[] | undefined,
	): LngLat | undefined {
		if (!query) return undefined;
		if (
			query.length === 2 &&
			typeOf(query[0]) === 'number' &&
			typeOf(query[1]) === 'number'
		) {
			return LngLat.convert(query as [number, number]);
		}
		return undefined;
	}
}
