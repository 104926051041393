import { AnyAction, createReducer } from '@reduxjs/toolkit';
import {
	clearRisk,
	getRiskScoreResolved,
	getSpatialLayers,
	getSpatialLayersRejected,
	getSpatialLayersResolved,
	getSpatialMetadataResolved,
	getSpatialRecordResolved,
} from '@Actions/risk';

import { RiskStoreState } from '@Types/risk.types';

export const initialState: RiskStoreState = {
	spatialLayers: [],
	spatialLayersStatus: 'idle',
	spatialRecords: [],
	riskCondition: {},
	spatialMetaData: {},
};

interface RiskReducer {
	[x: string]: (
		state: RiskStoreState,
		action: AnyAction,
	) => RiskStoreState | undefined;
}

const reducer: RiskReducer = {
	[getSpatialLayers.toString()]: state => ({
		...state,
		spatialLayersStatus: 'loading',
	}),
	[getSpatialLayersResolved.toString()]: (state, action) => ({
		...state,
		spatialLayers: action.payload,
		spatialLayersStatus: 'success',
	}),
	[getSpatialLayersRejected.toString()]: state => ({
		...state,
		spatialLayersStatus: 'error',
	}),
	[getSpatialRecordResolved.toString()]: (state, action) => ({
		...state,
		spatialRecords: [...state.spatialRecords, action.payload],
	}),
	[getRiskScoreResolved.toString()]: (state, action) => ({
		...state,
		riskCondition: {
			...state.riskCondition,
			[action.payload.assetId]: action.payload.risk,
		},
	}),
	[clearRisk.toString()]: () => ({
		...initialState,
	}),
	[getSpatialMetadataResolved.toString()]: (state, action) => ({
		...state,
		spatialMetaData: action.payload,
	}),
	[getSpatialLayersRejected.toString()]: state => ({
		...state,
		spatialMetaData: {},
	}),
};

export default createReducer(initialState, reducer);
