/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { GoalChartRoot } from '../../modules/goal-chart';
import * as React from 'react';
import { Goal } from '../../../core/types/goals.types';
import { AsyncData } from '../../../core/types/async.types';
import useGoalData from '../../../core/hooks/useGoalData';
import {
  GoalChartRootOptions,
  GoalChartSeriesOptions,
} from '../../modules/goal-chart/goal-chart-types';
import { DateTime } from 'luxon';

export interface InsightGoalChartProps {
  goal: AsyncData<Goal>;
  displayOptions?: {
    showBenchmarkProgress?: boolean;
    showGoalProgress?: boolean;
    isLiveMode?: boolean;
    isDummyUI?: boolean;
    isLimit?: boolean;
  };
}

export const Root = React.forwardRef<
  { chart: Highcharts.Chart | undefined },
  InsightGoalChartProps
>((props, ref): React.ReactElement => {
  const { overallStatus, asyncSensorsConfig, asyncGoalPointInTime } =
    useGoalData(props.goal.data, props.displayOptions?.isLiveMode);

  const getGoalType = (isLessThanOrEqual: boolean) => {
    return isLessThanOrEqual ? 'stay-or-below' : 'reach-or-above';
  };

  const rootStatus = React.useMemo(() => {
    const { status } = props.goal;
    if (props.displayOptions?.isDummyUI && !props.goal?.data) return 'resolved';
    else if (status === 'loading' || overallStatus === 'loading')
      return 'loading';
    else if (status === 'rejected' || overallStatus === 'rejected')
      return 'rejected';
    else if (status === 'resolved' && overallStatus === 'resolved')
      return 'resolved';
    else return 'idle';
  }, [props.goal, overallStatus, props.displayOptions]);

  const calculatedEndDate = React.useMemo<DateTime>(() => {
    const periodInterval = props.goal.data?.periodInterval;
    const startDate = props.goal.data?.startDate ?? new Date().toISOString();
    let endDate;
    switch (periodInterval) {
      case 'Yearly':
        endDate = DateTime.fromISO(startDate).plus({ year: 1 });
        break;
      case 'Monthly':
        endDate = DateTime.fromISO(startDate).plus({ month: 1 });
        break;
      case 'Weekly':
        endDate = DateTime.fromISO(startDate).plus({ day: 7 });
        break;
      case 'Daily':
      default:
        endDate = DateTime.fromISO(startDate).plus({ day: 1 });
    }
    return endDate;
  }, [props.goal.data?.startDate, props.goal.data?.periodInterval]);

  const chartRoot = React.useMemo<GoalChartRootOptions>(() => {
    let isLimit = true;
    if (
      props.displayOptions?.isLimit !== undefined &&
      props.displayOptions?.isLimit !== null
    ) {
      isLimit = props.displayOptions.isLimit;
    } else if (props.goal) {
      isLimit = props.goal.data?.isLessOrEqualToGoalSensor;
    }
    return {
      status: rootStatus,
      showBenchmarkProgress:
        props.displayOptions?.showBenchmarkProgress ?? false,
      showGoalProgress: props.displayOptions?.showGoalProgress ?? false,
      dateRange: {
        startDate: props.goal.data?.startDate
          ? new Date(props.goal.data?.startDate)
          : new Date(),
        endDate: calculatedEndDate.toJSDate(),
      },
      isDummy: !!props.displayOptions?.isDummyUI,
      isLimit,
    };
  }, [rootStatus, calculatedEndDate, props]);

  const chartSeries = React.useMemo<GoalChartSeriesOptions>(() => {
    if (asyncSensorsConfig.data && asyncGoalPointInTime.data) {
      const { inputSensor, goalSensor, benchmarkSensor } =
        asyncSensorsConfig.data;

      const {
        inputSensorLastValue,
        benchmarkLastValue,
        goalLastValue,
        inputSensorTimeStamp,
        goalPercentage,
        benchmarkPercentage,
      } = asyncGoalPointInTime.data;

      const { data: goal } = props.goal;
      const isPastDate = calculatedEndDate < DateTime.now();

      const isOutOfBoundary =
        goal?.isRecurring === false &&
        calculatedEndDate < DateTime.fromISO(inputSensorTimeStamp);

      return {
        sensor: {
          value: inputSensorLastValue,
          unit: inputSensor.unit ?? '',
          date: new Date(inputSensorTimeStamp),
          displayOnlyDate: isPastDate && goal?.isRecurring === false,
        },
        goal: {
          value: isOutOfBoundary
            ? undefined
            : goal?.goalConstant
              ? +goal.goalConstant
              : goalLastValue !== undefined
                ? +goalLastValue
                : undefined,
          unit: (goalSensor ? goalSensor.unit : inputSensor.unit) ?? '',
          goalType: getGoalType(goal?.isLessOrEqualToGoalSensor ?? true),
          percentage: isOutOfBoundary ? undefined : goalPercentage,
        },
        ...(goal?.benchmarkConstant || goal?.benchmarkSensorId
          ? {
              benchmark: {
                value: isOutOfBoundary
                  ? undefined
                  : goal?.benchmarkConstant
                    ? +goal.benchmarkConstant
                    : benchmarkLastValue !== undefined
                      ? +benchmarkLastValue
                      : undefined,
                unit:
                  (benchmarkSensor ? benchmarkSensor.unit : inputSensor.unit) ??
                  '',
                percentage: isOutOfBoundary ? undefined : benchmarkPercentage,
              },
            }
          : {}),
      };
    }

    return {
      sensor: {
        value: props.displayOptions?.isDummyUI ? undefined : 0,
        unit: '',
      },
      goal: {
        value: props.displayOptions?.isDummyUI ? undefined : 0,
        unit: '',
      },
    };
  }, [
    asyncSensorsConfig,
    asyncGoalPointInTime,
    props.goal,
    props.displayOptions,
    calculatedEndDate,
  ]);

  return <GoalChartRoot ref={ref} root={chartRoot} series={chartSeries} />;
});

Root.displayName = 'GoalChartRoot';
