import { DataServiceType, IconSet, Properties, ServiceLayer } from './types';
import {
	Info360TileMetadata,
	Info360TileMetadataV2,
	Layer,
} from './Info360TileTypes';

import AssetRegistry from './AssetRegistry';
import DataService from './DataService';
import { LngLatBounds } from 'mapbox-gl';
import MapboxDataSource from './MapboxDataSource';
import { reduceArrayToObject } from '@Map/utils';

export default class Info360TileService extends DataService<
	Info360TileMetadata
> {
	constructor(dataService: DataServiceType<Info360TileMetadata>) {
		super(dataService);
		if (!this.endpoint) {
			throw new Error('Required tiles url missing.');
		}

		this._requestHeaders = [
			['Authorization', `Bearer ${dataService.token}`],
			['Accept', 'application/json'],
		];

		this._credentials = 'include';

		this._propRequestHeaders = this._requestHeaders;
	}

	get dataEndpoint(): string | null {
		return `${this.endpoint}/v1/tileset`;
	}

	get tileHost(): string {
		return new URL(`${this.endpoint}`).origin;
	}

	get requiresCredentials(): boolean {
		return true;
	}

	validData(data: Info360TileMetadata): boolean {
		if (typeof data !== 'object') {
			return false;
		}

		if (data.layers == null) {
			return false;
		}

		return true;
	}

	get layers(): ServiceLayer[] {
		const sources = this._dataSources as MapboxDataSource[];
		if (!sources || (sources && !sources.length)) return [];

		return sources.map(
			({ id, displayName, type, icon, color, cluster, sourceLayer }) => ({
				id,
				source: id,
				serviceId: this.id,
				displayName,
				type,
				icon,
				cluster,
				color,
				loaded: this.loaded,
				errored: this.errored,
				sourceLayer,
			}),
		);
	}

	get iconSet(): IconSet {
		return AssetRegistry.getIconSet();
	}

	get bounds(): LngLatBounds | undefined {
		if (!this.data) return;
		const { bounds } = this.data;
		return new LngLatBounds(bounds.southWest, bounds.northEast);
	}

	createDataSources(data: Info360TileMetadata & Info360TileMetadataV2): void {
		const dataSources = data.layers?.map(l => this.mapTileLayer(l));

		this._dataSources = dataSources;
	}

	protected mapTileLayer(layer: Layer) {
		return new MapboxDataSource({
			id: this.getLayerId(layer),
			sourceLayer: layer.type,
			displayName: AssetRegistry.getDisplayName(layer.type),
			type: `${AssetRegistry.getLayerType(layer.type)}`,
			tileUrl: this.getTileUrl(layer),
			icon: AssetRegistry.getIcon(layer.type),
			color: AssetRegistry.getColor(layer.type),
			minZoom: layer.minzoom,
			maxZoom: layer.maxzoom,
		});
	}

	protected getLayerId(layer: Layer): string {
		return layer.type;
	}

	protected getTileUrl(layer: Layer): string {
		return `${this.tileHost}/${layer.path}/{z}/{x}/{y}.pbf`;
	}

	mapDisplayType(properties: Properties): string {
		const { assetType, _id } = properties;
		if (assetType === 'sensor') {
			// get sensor type from id when in the format: sensor#34739.Status
			const sensorType = (_id as string).split(/\./).pop();
			if (sensorType) return sensorType;
		}
		return AssetRegistry.getDisplayName(assetType as string);
	}

	getLookupValuesForId(id: string): Properties {
		return reduceArrayToObject(
			Object.values(this._lookups).map(lookup => ({
				[lookup.key]: lookup.getValueForId(id),
			})),
		);
	}

	getTileSource(regex: RegExp): string | undefined {
		const sources = this._dataSources as MapboxDataSource[];
		if (!sources || (sources && !sources.length)) return;
		return sources.find(({ id }) => regex.test(id))?.id;
	}

	getTileLayerName(regex: RegExp): string | undefined {
		const sources = this._dataSources as MapboxDataSource[];
		if (!sources || (sources && !sources.length)) return;
		return sources.find(
			({ sourceLayer }) => sourceLayer && regex.test(sourceLayer),
		)?.sourceLayer;
	}

	update(dataService: DataServiceType<Info360TileMetadata>): boolean {
		const updated = super.update(dataService);
		if (!this.endpoint) {
			throw new Error('Required tiles url missing.');
		}

		this._requestHeaders = [
			['Authorization', `Bearer ${dataService.token}`],
			['Accept', 'application/json'],
		];

		this._credentials = 'include';

		this._propRequestHeaders = this._requestHeaders;

		return updated;
	}
}
