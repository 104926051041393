export const defineElement = (selector: string) => (
	cls: CustomElementConstructor,
): void => {
	// polyfill is included at top of file
	if (!window.customElements.get(selector)) {
		window.customElements.define(selector, cls);
	}
};

export default defineElement;
