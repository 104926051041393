import { getCustomAnalytic } from '../../../services/customAnalytics.service';
import { makeSelectCustomAnalyticByIdFromWorkspace } from '../../selectors/customAnalytic.selectors';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import type { AsyncData } from '../../types/async.types';
import type { CustomAnalytic } from '../../types/analytic.types';
import type { StoreState } from '../../../store/store';
import { t } from 'i18next';

const useCustomAnalytic = (
  customAnalyticId: string
): AsyncData<CustomAnalytic> => {
  /* This will store the exposed custom analytic */
  const [asyncCustomAnalytic, setAsyncCustomAnalytic] = useState<
    AsyncData<CustomAnalytic>
  >({
    status: 'idle',
    data: null,
  });

  /**
   * This creates a memoized copy of the parametrized selector.
   *
   * See: https://react-redux.js.org/api/hooks#using-memoizing-selectors
   */
  const selectCustomAnalyticByIdFromWorkspace = useMemo(
    makeSelectCustomAnalyticByIdFromWorkspace,
    []
  );

  const storedAsyncCustomAnalytic = useSelector((state: StoreState) => {
    return selectCustomAnalyticByIdFromWorkspace(state, customAnalyticId);
  });

  /**
   * Here we take whatever is in the store and set it as the exposed value
   * or we fetch the custom analytic if it's no there.
   */
  useEffect(() => {
    if (!customAnalyticId) return;
    const fetch = async () => {
      setAsyncCustomAnalytic({
        data: null,
        status: 'loading',
        message: t('Loading custom analytic'),
      });
      try {
        const response = await getCustomAnalytic(customAnalyticId);
        setAsyncCustomAnalytic({
          data: response.data,
          status: 'resolved',
        });
      } catch (error) {
        setAsyncCustomAnalytic({
          data: null,
          status: 'rejected',
          message: t('Failed to retrieve custom analytic'),
        });
      }
    };

    fetch();

    // Stale data in redux cache is causing unnecessary double render / api calls on Charts V2 making the chart even slower

    /* if (storedAsyncCustomAnalytic.status === 'rejected') {
			fetch();
		} else {
			setAsyncCustomAnalytic(storedAsyncCustomAnalytic);
		} */
  }, [customAnalyticId]);

  return asyncCustomAnalytic;
};

export default useCustomAnalytic;
