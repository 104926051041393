/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { RangeSelection, RawMeasureData } from '../types';

export const filterDataOutOfRange = (
  data: RawMeasureData,
  range?: RangeSelection
): RawMeasureData => {
  if (!range || (range.min === undefined && range.max === undefined)) {
    return data;
  }

  const filteredData = data.filter((measure) => {
    const [timestamp] = measure;

    if (range.min === undefined) {
      return timestamp <= range.max!;
    } else if (range.max === undefined) {
      return timestamp >= range.min!;
    }

    return timestamp <= range.max && timestamp >= range.min;
  });

  return filteredData;
};
