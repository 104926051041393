import { ThemeProperties } from '@Map/types';
import { e } from '@Translations/extraction';

export const defaultThemeProps: ThemeProperties = {
	configEdits: {
		layers: {},
		pitchControl: false,
	},
	isUserTheme: true,
};

export const defaultThemeName = e('Default', {
	context: 'Name of the default theme',
});

export const fallbackDefaultThemeId = '1';
