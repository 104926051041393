import { CompanySettings, useSettings } from '@innovyze/stylovyze';
import { DateTime } from 'luxon';
import { useCallback, useRef } from 'react';

type Iso8601DateString = string;
type MillisecondsTimestamp = number;
type JsDateObject = Date;

export interface FormatConfig {
  dateTimeSeparator?: string;
}

const generateLuxonFormatToken = (
  companySettings: CompanySettings,
  config: FormatConfig
): string => {
  const { hourCycle12, dateFormat } = companySettings;

  const date =
    dateFormat && dateFormat[0] === 'D' ? 'dd/MM/yyyy' : 'MM/dd/yyyy';
  const time = hourCycle12 ? 'hh:mm:ss a' : 'HH:mm:ss';
  return date + config.dateTimeSeparator + time;
};

const generateFormatConfigObject = (
  globalConfig?: FormatConfig,
  localConfig?: FormatConfig
): FormatConfig => {
  return {
    dateTimeSeparator:
      localConfig?.dateTimeSeparator ?? globalConfig?.dateTimeSeparator ?? ' ',
  };
};

/**
 * A hook that configures a date formatting function with company settings.
 *
 * @param {Object} globalConfig - An optional configuration object to set
 * additional formatting configuration.
 *
 * @return {function} A date formatting function already configured with
 * the company settings.
 * */
const useCompanyDateTimeFormat = (globalConfig?: FormatConfig) => {
  const globalConfigRef = useRef<FormatConfig | undefined>(globalConfig);
  const { companySettings } = useSettings();

  /**
   * A date formatting function already configured with the company settings.
   *
   * @param {Object} date A date parameter that can be either of:
   * An ISO 8601 date string, a timestamp in milliseconds
   * or a normal JS date object.
   *
   * @param {Object} localConfig An optional configuration object to set
   * additional formatting configuration. This overrides the config passed
   * to the hook.
   *
   * @return {string} A date string formatted with the company settings.
   * */
  const formatDate = useCallback(
    (
      date: Iso8601DateString | MillisecondsTimestamp | JsDateObject,
      localConfig?: FormatConfig
    ) => {
      let luxonDateTime: DateTime;

      const config = generateFormatConfigObject(
        globalConfigRef.current,
        localConfig
      );

      const luxonFormat = generateLuxonFormatToken(companySettings, config);

      if (typeof date === 'string') {
        luxonDateTime = DateTime.fromISO(date);
      } else if (typeof date === 'number') {
        luxonDateTime = DateTime.fromMillis(date);
      } else {
        luxonDateTime = DateTime.fromJSDate(date);
      }

      return luxonDateTime
        .setZone(companySettings.timeZoneIANA)
        .toFormat(luxonFormat);
    },
    [companySettings, globalConfigRef]
  );

  return formatDate;
};

export default useCompanyDateTimeFormat;
