import {
	CBAttributes,
	CBAttributesPerLayer,
	CBHasValuesPerAttributePerLayer,
	ConfigLayer,
	ConfigLayerInternal,
} from '@Root';
import { EditorModes, EditorState } from '../../map/types';
import React, {
	ReactNode,
	createContext,
	useCallback,
	useContext,
	useState,
} from 'react';

interface EditorContextValues {
	mode: EditorModes;
	state: EditorState;
	open: boolean;
	resetDialogOpen: boolean;
	selectedLayer?: ConfigLayerInternal;
	startEditing?: () => void;
	cancelEditing?: () => void;
	saveAndFinishEditing?: () => void;
	resetToDefaults?: () => void;
	onSelect?: (layerId: string, config?: ConfigLayerInternal) => void;
	onChange?: (config: Partial<ConfigLayer>) => void;
	isSelected?: (layerId: string) => boolean;
	confirmReset?: () => void;
	cancelReset?: () => void;
	getAttributes?: (layerId: string) => CBAttributes[] | undefined;
	getAttributeValuesCount?: (
		layerId: string,
		attributeId: string,
	) => number | undefined;
	unsupported?: boolean;
	createTheme?: () => void;
	onThemeNameChange?: (name: string) => void;
	onShowThemeProps?: () => void;
	onChangeTheme?: (themedId: string) => void;
	onThemeDelete?: () => void;
}

const EditorContext = createContext<EditorContextValues>({
	mode: EditorModes.noEdit,
	state: EditorState.readonly,
	open: false,
	resetDialogOpen: false,
});

interface EditorProviderProps {
	/** Mode for editor to be in, can be 'noEdit', 'editOwn' or 'editAll'
	 * @default 'noEdit'
	 */
	mode: EditorModes;
	/** State of the editor 'readonly' or 'edit' */
	state: EditorState;
	/** react component to get editor context */
	children: ReactNode;
	/** callback for when editor has been open */
	onToggleOpen?: (state: boolean) => void;
	/** onChange event for layer edits */
	onLayerEdit?: (layerId: string, edits: ConfigLayerInternal) => void;
	/** onClick event for save button */
	onEditorSave?: () => void;
	/** onClick event for cancel button */
	onEditorCancel?: () => void;
	/** onClick event for reset button */
	onEditorReset?: () => void;
	/** function to get attributes for color by functionality for current layer */
	fetchAttributesList?: (layerId: string) => void;
	/** color by attributes for current layer */
	attributesPerLayer?: CBAttributesPerLayer;
	/** color by values for selected attribute */
	attributeValuesPerLayer?: CBHasValuesPerAttributePerLayer;
	/** get current selected layer */
	getSelected?: (layerId: string) => ConfigLayerInternal | undefined;
	/** onClick event for `Create new theme` button */
	onCreateTheme?: () => void;
	/** onChange event for theme name */
	onThemeEdit?: (name: string) => void;
	/** onClick event for delete button */
	onDeleteTheme?: () => void;
	/** onChange event for theme dropdown */
	onThemeChange?: (themeId: string) => void;
}

export const useEditor = (): EditorContextValues => useContext(EditorContext);

export const EditorProvider = ({
	mode,
	state,
	children,
	onToggleOpen,
	onLayerEdit,
	onEditorSave,
	onEditorCancel,
	onEditorReset,
	fetchAttributesList,
	attributesPerLayer,
	attributeValuesPerLayer,
	getSelected,
	onCreateTheme,
	onThemeEdit,
	onDeleteTheme,
	onThemeChange,
}: EditorProviderProps): JSX.Element => {
	const [open, setOpen] = useState(false);
	const [unsupported, setUnsupported] = useState(false);
	const [selectedLayer, setSelectedLayer] = useState<
		ConfigLayerInternal | undefined
	>(undefined);
	const [resetDialogOpen, setResetDialogOpen] = useState(false);

	const onSelect = useCallback(
		(layerId: string, config?: ConfigLayerInternal) => {
			if (mode === EditorModes.noEdit) return;
			const layerConfig = config ?? getSelected?.(layerId);
			if (!layerConfig) {
				setUnsupported(true);
				setSelectedLayer(undefined);
				return;
			}
			setUnsupported(false);
			setSelectedLayer({
				...layerConfig,
				id: layerId,
			});
			if (layerConfig.showColorBy) fetchAttributesList?.(layerId);
		},
		[mode, open],
	);

	const resetSelectedLayer = () => {
		if (!selectedLayer) return;
		onSelect(selectedLayer.id);
	};

	const onShowThemeProps = () => {
		setSelectedLayer(undefined);
		setUnsupported(false);
	};

	const setEditorOpen = useCallback(
		(state: boolean) => {
			setOpen(state);
			onToggleOpen?.(state);
		},
		[onToggleOpen],
	);

	const startEditing = useCallback(() => {
		setEditorOpen(true);
	}, []);

	const cancelEditing = useCallback(async () => {
		setEditorOpen(false);
		await onEditorCancel?.();
		resetSelectedLayer();
	}, [onEditorCancel, selectedLayer]);

	const saveAndFinishEditing = useCallback(() => {
		setEditorOpen(false);
		onEditorSave?.();
		resetSelectedLayer();
	}, [onEditorSave, selectedLayer]);

	const resetToDefaults = useCallback(() => {
		setResetDialogOpen(true);
	}, []);

	const confirmReset = useCallback(() => {
		setResetDialogOpen(false);
		setEditorOpen(false);
		onEditorReset?.();
		resetSelectedLayer();
	}, [onEditorReset, selectedLayer]);

	const cancelReset = useCallback(() => {
		setResetDialogOpen(false);
	}, []);

	const onChange = useCallback(
		(config: Partial<ConfigLayer>) => {
			if (!selectedLayer) return;
			const value = {
				...config,
				id: selectedLayer.id,
			} as ConfigLayerInternal;
			onLayerEdit?.(selectedLayer.id, value);
		},
		[selectedLayer],
	);

	const onThemeNameChange = useCallback(
		name => {
			onThemeEdit?.(name);
		},
		[onThemeEdit],
	);

	const onChangeTheme = useCallback(
		async themeId => {
			await onThemeChange?.(themeId);
			resetSelectedLayer();
		},
		[onThemeChange, selectedLayer],
	);

	const isSelected = useCallback(
		(layerId: string) => selectedLayer?.id === layerId,
		[selectedLayer],
	);

	const getAttributes = useCallback(
		(layerId: string) => attributesPerLayer?.[layerId],
		[attributesPerLayer],
	);

	const getAttributeValuesCount = useCallback(
		(layerId: string, attributeId: string) =>
			attributeValuesPerLayer?.[layerId]?.[attributeId],
		[attributeValuesPerLayer],
	);

	const createTheme = useCallback(() => {
		setSelectedLayer(undefined);
		onCreateTheme?.();
		setEditorOpen(true);
	}, []);

	const onThemeDelete = onDeleteTheme
		? async () => {
				await onDeleteTheme();
				setEditorOpen(false);
		  }
		: undefined;

	return (
		<EditorContext.Provider
			value={{
				mode,
				state,
				open,
				resetDialogOpen,
				startEditing,
				cancelEditing,
				saveAndFinishEditing,
				resetToDefaults,
				selectedLayer,
				onSelect,
				onChange,
				isSelected,
				confirmReset,
				cancelReset,
				getAttributes,
				getAttributeValuesCount,
				unsupported,
				createTheme,
				onThemeNameChange,
				onShowThemeProps,
				onThemeDelete,
				onChangeTheme,
			}}>
			{children}
		</EditorContext.Provider>
	);
};
