import withStyles from '@mui/styles/withStyles';
import MuiGrid from '@mui/material/Grid';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const SegmentTitle = withStyles({
	root: {
		height: '47px',
		backgroundColor: '#F7F7F7',
		padding: '11px 23px',
		fontWeight: 600,
		fontSize: '18px',
		lineHeight: '24px',
	},
})(MuiGrid);

// eslint-disable-next-line @typescript-eslint/naming-convention
export const SegmentBody = withStyles({
	root: {
		padding: '15px 0px',
		// borderLeft: '5px solid #007CA0',
	},
})(MuiGrid);

// eslint-disable-next-line @typescript-eslint/naming-convention
export const VertLine = withStyles({
	root: {
		width: '25px',
		borderLeft: '5px solid #007CA0',
	},
})(MuiGrid);
