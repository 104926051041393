import React, { useEffect } from 'react';
import { InfoCard, StylovyzeTable, UnitDisplay } from '@innovyze/stylovyze';
import { Grid, Link } from '@mui/material';
import { useDispatch } from 'react-redux';
import { selectValidAssetIds } from '@Selectors/assetDetail.selectors';
import { MACPFullInspection } from '@Types/fullInspection.types';
import { MACPConnection } from '@Types/observation.types';
import { CheckAssetIdsParams } from '@Types/asset.types';
import { checkAssetIds } from '@Actions/assetDetail.actions';
import { useGlobalization } from '@Translations';

export const MACPPipesTab = ({
	macpInsp,
	pipeIdClick,
}: {
	macpInsp: MACPFullInspection;
	pipeIdClick: (pipeId: string) => void;
}): JSX.Element => {
	const { t } = useGlobalization();
	const dispatch = useDispatch();

	useEffect(() => {
		if (macpInsp != undefined && macpInsp.connections != undefined) {
			const pipeIds = macpInsp.connections.map(
				(connection: MACPConnection) => connection.structureId,
			);
			pipeIds.filter(
				(id, index) => id != '' && pipeIds.indexOf(id) == index,
			);
			if (pipeIds.length > 0) {
				const payload: CheckAssetIdsParams = {
					assetType: 'wwPipe',
					assetId: pipeIds,
				};
				dispatch(checkAssetIds(payload));
			}
		}
	}, [macpInsp]);
	const validIds = selectValidAssetIds();

	const headers = [
		{
			cell: t('Pipe No.'),
			dataCy: 'col-Pipe No.',
			key: 'Pipe No.',
			padding: '0 10px',
		},
		{
			cell: t('Clock position'),
			dataCy: 'col-Clock position',
			key: 'Clock position',
			padding: '0 10px',
		},
		{
			cell: t('Rim to Invert'),
			dataCy: 'col-Rim to Invert',
			key: 'Rim to Invert',
			padding: '0 10px',
		},
		{
			cell: t('Material'),
			dataCy: 'col-Material',
			key: 'Material',
			padding: '0 10px',
		},
		{
			cell: t('Shape'),
			dataCy: 'col-Shape',
			key: 'Shape',
			padding: '0 10px',
		},
		{
			cell: t('Height'),
			dataCy: 'col-Height',
			key: 'Height',
			padding: '0 10px',
		},
		{
			cell: t('Width'),
			dataCy: 'col-Width',
			key: 'Width',
			padding: '0 10px',
		},
		{
			cell: t('Condition'),
			dataCy: 'col-Condition',
			key: 'Condition',
			padding: '0 10px',
		},
		{
			cell: t('Seal Cond.'),
			dataCy: 'col-Seal Cond.',
			key: 'Seal Cond.',
			padding: '0 10px',
		},
		{
			cell: t('Type'),
			dataCy: 'col-Type',
			key: 'Type',
			padding: '0 10px',
		},
		{
			cell: t('Struct. Id'),
			dataCy: 'col-Struct. Id',
			key: 'Struct. Id',
			padding: '0 10px',
		},
		{
			cell: t('Comments'),
			dataCy: 'col-Comments',
			key: 'Comments',
			padding: '0 10px',
		},
	];

	const getStructureIdLink = (structureId: string) => {
		if (
			validIds &&
			validIds?.length > 0 &&
			validIds.find(id => id == structureId) != undefined
		) {
			return (
				<Link
					underline="hover"
					style={{
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						cursor: 'pointer',
					}}
					title={structureId}
					onClick={() => {
						if (pipeIdClick) pipeIdClick(structureId);
					}}>
					{structureId}
				</Link>
			);
		} else {
			return structureId;
		}
	};

	const incomingRows =
		macpInsp.connections != undefined
			? macpInsp.connections
					.filter(
						connection =>
							connection.direction?.toUpperCase() == 'I',
					)
					.map(connection => ({
						cells: [
							connection.pipeNumber,
							connection.clockPosition,
							<UnitDisplay
								key="rimToInvert"
								value={`${connection.rimToInvert.toString()} m`}
							/>,
							connection.material,
							connection.shape,
							connection.height,
							connection.width,
							connection.pipeCondition,
							connection.pipeSealCondition,
							connection.pipeType,
							getStructureIdLink(connection.structureId),
							connection.pipeComments,
						],
						dataCy: 'row' + connection.connectionId,
						key: connection.connectionId,
					}))
			: [];

	const outgoingRows =
		macpInsp.connections != undefined
			? macpInsp.connections
					.filter(
						connection =>
							connection.direction?.toUpperCase() == 'O',
					)
					.map(connection => ({
						cells: [
							connection.pipeNumber,
							connection.clockPosition,
							<UnitDisplay
								key="rimToInvert"
								value={`${connection.rimToInvert.toString()} m`}
							/>,
							connection.material,
							connection.shape,
							connection.height,
							connection.width,
							connection.pipeCondition,
							connection.pipeSealCondition,
							connection.pipeType,
							getStructureIdLink(connection.structureId),
							connection.pipeComments,
						],
						dataCy: 'row' + connection.connectionId,
						key: connection.connectionId,
					}))
			: [];

	const incoming = (
		<InfoCard title={t('Incoming')} applyBodyPadding={false}>
			<div>
				<StylovyzeTable
					className="ScrollTable"
					dataCy="defect-table"
					headers={headers}
					rows={incomingRows}
					useSkeleton={{ rowCount: 10 }}
				/>
			</div>
		</InfoCard>
	);

	const outgoing = (
		<InfoCard title={t('Outgoing')} applyBodyPadding={false}>
			<div>
				<StylovyzeTable
					className="ScrollTable"
					dataCy="defect-table"
					headers={headers}
					rows={outgoingRows}
					useSkeleton={{ rowCount: 10 }}
				/>
			</div>
		</InfoCard>
	);

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				{incoming}
			</Grid>
			<Grid item xs={12}>
				{outgoing}
			</Grid>
		</Grid>
	);
};

export default MACPPipesTab;
