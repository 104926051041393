import styled from 'styled-components';

export const Root = styled.div`
  align-items: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  width: 100%;
`;

export const Instance = styled.div`
  width: 100%;
  height: 100%;
`;
