import { ExtractTrans, e } from '@Translations/extraction';

import { Property } from '@innovyze/stylovyze';

interface PropMapping {
	prop: string;
	label: ExtractTrans;
	type?: Property['type'];
	unit?: string;
}

interface PropRegistry {
	[key: string]: Omit<PropMapping, 'prop'>;
}

export default class AssetPropertyRegistry {
	private static props = {
		pipeType: {
			label: e('Type', { context: 'Asset properties' }),
		},
		yearLaid: {
			label: e('Install Date', { context: 'Asset properties' }),
			type: 'dateonlyUTC',
		},
		usNodeId: {
			label: e('Upstream Mh', { context: 'Asset properties' }),
		},
		dsNodeId: {
			label: e('Downstream Mh', { context: 'Asset properties' }),
		},
		usInvert: {
			label: e('Upstream Invert', { context: 'Asset properties' }),
			unit: 'm',
		},
		dsInvert: {
			label: e('Downstream Invert', { context: 'Asset properties' }),
			unit: 'm',
		},
		status: {
			label: e('Status', { context: 'Asset properties' }),
		},
		location: {
			label: e('Location', { context: 'Asset properties' }),
		},
		coverLevel: {
			label: e('Cover Level', { context: 'Asset properties' }),
			unit: 'm',
		},
		groundLevel: {
			label: e('Ground Level', { context: 'Asset properties' }),
			unit: 'm',
		},
		chamberFloorDepth: {
			label: e('Depth', { context: 'Asset properties' }),
			unit: 'm',
		},
		street: {
			label: e('Street', { context: 'Asset properties' }),
		},
		owner: {
			label: e('Owner', { context: 'Asset properties' }),
		},
		valveType: {
			label: e('Valve Type', { context: 'Asset properties' }),
		},
		material: {
			label: e('Material', { context: 'Material' }),
		},
	};

	private static assetProps: {
		[key: string]: (keyof typeof AssetPropertyRegistry.props)[];
	} = {
		pipe: [
			'pipeType',
			'yearLaid',
			'usNodeId',
			'dsNodeId',
			'usInvert',
			'dsInvert',
		],
		wwPipe: [
			'pipeType',
			'yearLaid',
			'usNodeId',
			'dsNodeId',
			'usInvert',
			'dsInvert',
		],
		manhole: [
			'yearLaid',
			'status',
			'location',
			'coverLevel',
			'groundLevel',
			'chamberFloorDepth',
			'street',
			'owner',
			'material',
		],
		wwManhole: [
			'yearLaid',
			'status',
			'location',
			'coverLevel',
			'groundLevel',
			'chamberFloorDepth',
			'street',
			'owner',
			'material',
		],
		control_valve: ['valveType', 'status'],
	};

	private static mapProp(prop: string): PropMapping {
		return { ...(AssetPropertyRegistry.props as PropRegistry)[prop], prop };
	}

	public static getMappings(assetType: string): PropMapping[] {
		return (this.assetProps[assetType] || []).map(this.mapProp);
	}
}
