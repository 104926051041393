import { addNamespace } from '@Utils/actions';
import { createAction } from '@reduxjs/toolkit';
import { GetConfigurationParams, Status, YAMLMapping } from '@Types';

export const getConfigurationWithMapping = createAction<GetConfigurationParams>(
	addNamespace('configuration/getConfigurationWithMapping'),
);
export const getConfigurationWithUploadId =
	createAction<GetConfigurationParams>(
		addNamespace('configuration/getConfigurationWithUploadId'),
	);
export const getConfigurationResolved = createAction<{
	configuration: YAMLMapping[];
	key: string;
}>(addNamespace('configuration/getConfiguration/resolved'));
export const getConfigurationRejected = createAction<Status>(
	addNamespace('configuration/getConfiguration/rejected'),
);
