import {
	ConfigEdits,
	ThemeList,
	ThemeListItem,
	ThemeListResponse,
	ThemeProperties,
} from '@Map/types';
import ThemeService, { appId } from './ThemeService';
import { defaultThemeProps, fallbackDefaultThemeId } from './ThemeDefaults';

import Events from '@Map/events/Events';
import RequestService from './RequestService';

export const THEME_NOT_EXIST = 'theme-not-exist';

export default class ThemeManager extends RequestService {
	private _token: string | undefined;
	private _themes: Record<string, ThemeService> = {};
	private _themeId: string;
	private _creatingTheme = false;
	private _localStoragePrefix: string | null;
	private _themeList: ThemeList = [];
	private _themeResponse: Promise<ThemeList> | undefined;
	private _eventController = new Events();
	private _defaultThemeId: string;

	constructor(
		url: string,
		token?: string,
		localStoragePrefix: string | null = null,
		defaultThemeId = fallbackDefaultThemeId,
	) {
		super(url, token);
		this._token = token;
		this._localStoragePrefix = localStoragePrefix;
		this._defaultThemeId = defaultThemeId;
		this._themeId = this._getSavedThemeId();
		this._getThemeList();
	}

	set themeId(themeId: string) {
		this._themeId = themeId;
		this._saveThemeId(themeId);
	}

	on(eventName: string, callback: () => void) {
		this._eventController.on(eventName, callback);
	}

	useDefaultTheme() {
		this.themeId = this._defaultThemeId;
	}

	getSelectedThemeId() {
		return this._creatingTheme ? 'new' : this._themeId;
	}

	async getById(themeId: string): Promise<ThemeProperties> {
		const themeService = this._getThemeServiceById(themeId, true);
		return (await themeService?.getTheme()) ?? defaultThemeProps;
	}

	createTheme() {
		this._creatingTheme = true;
		this._addNewThemeToList();
	}

	getTheme() {
		return this.getById(this._themeId);
	}

	async saveTheme(
		edits: ConfigEdits,
		name: ThemeProperties['name'],
		isUserTheme: ThemeProperties['isUserTheme'] = true,
	) {
		if (this._creatingTheme) {
			const newThemeService = new ThemeService(
				this._baseUrl,
				undefined,
				this._token,
			);
			const themeId = await newThemeService.createTheme(
				edits,
				name,
				isUserTheme,
			);
			if (themeId) {
				this._themes[themeId] = newThemeService;
				this.themeId = themeId;
				this._creatingTheme = false;
				this._updateThemeInList(themeId, name ?? '', isUserTheme);
			}
		} else {
			const themeService = this._getThemeServiceById(this._themeId);
			await themeService?.saveTheme(edits, name, isUserTheme);
			this._updateThemeInList(this._themeId, name ?? '', isUserTheme);
		}
	}

	cancelCreating() {
		this._creatingTheme = false;
		this._removeThemeFromList();
	}

	resetTheme() {
		if (this._creatingTheme) return;
		const themeService = this._getThemeServiceById(this._themeId);
		themeService?.resetTheme();
	}

	deleteTheme() {
		if (!this.isDeletable()) return;
		const themeService = this._getThemeServiceById(this._themeId);
		themeService?.deleteTheme();
		this._removeThemeFromList(this._themeId);
	}

	isDeletable() {
		if (this._themeId === this._defaultThemeId || this._creatingTheme)
			return false;
		return true;
	}

	async getThemeList() {
		if (this._themeList.length) return this._themeList;
		return await this._themeResponse;
	}

	private _getThemeList() {
		this.setRequest('getThemeList', {
			url: this._getUrl(),
		});
		this._themeResponse = new Promise(async resolve => {
			const data = await this.getRequest<ThemeList>(
				'getThemeList',
				[],
				'map_exception_themeList',
				'Failed to get theme list',
				{
					responseParser: this.responseParser,
				},
			);
			if (data.length) {
				this._themeList = data;
			}
			// reset to the default theme if the saved theme does not exist
			const savedThemeExists = this._themeList.find(
				theme => theme.id === this._themeId,
			);
			if (!savedThemeExists) {
				this._eventController.fire(THEME_NOT_EXIST);
			}
			resolve(this._themeList);
		});
	}

	private _getThemeServiceById(
		themeId: string,
		create = false,
	): ThemeService | undefined {
		if (!this._themes[themeId]) {
			if (!create) return;
			this._themes[themeId] = new ThemeService(
				this._baseUrl,
				themeId,
				this._token,
				themeId === this._defaultThemeId,
			);
		}
		return this._themes[themeId];
	}

	private _getThemeKey() {
		return this._localStoragePrefix
			? `map.${this._localStoragePrefix}.themeId`
			: 'map.themeId';
	}

	private _getSavedThemeId() {
		return (
			localStorage.getItem(this._getThemeKey()) ?? this._defaultThemeId
		);
	}

	private _saveThemeId(value: string) {
		localStorage.setItem(this._getThemeKey(), value);
	}

	destroy() {
		Object.values(this._themes).map(theme => theme.destroy());
		this._themes = {};
	}

	responseParser = (data: unknown): ThemeList => {
		const typedData = data as ThemeListResponse;
		const returnData: ThemeList = [];
		if (typedData.length) {
			typedData.forEach(({ id, name, userId }) => {
				returnData.push({
					id: `${id}`,
					name: name ?? '',
					// a user theme has the associated userId
					isUserTheme: !!userId,
				});
			});
		}
		return returnData;
	};

	private _getUrl(themeId?: string) {
		const postfix = themeId ? `/${themeId}` : '';
		return `${this._baseUrl}/v2/themes${postfix}?appId=${appId}`;
	}

	private _addNewThemeToList() {
		this._removeThemeFromList();
		this._themeList.push({
			name: '[New theme]',
			id: 'new',
			isUserTheme: true,
		});
	}

	private _updateThemeInList(
		themeId: ThemeListItem['id'],
		themeName: ThemeListItem['name'],
		isUserTheme: ThemeListItem['isUserTheme'],
	) {
		this._themeList = this._themeList.map(theme => {
			if (theme.id === 'new' || theme.id === themeId) {
				return {
					...theme,
					id: themeId,
					name: themeName,
					isUserTheme,
				};
			}
			return theme;
		});
	}

	private _removeThemeFromList(themeId = 'new') {
		this._themeList = this._themeList.filter(theme => theme.id !== themeId);
	}
}
