/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { forwardRef, ReactElement } from 'react';
import { ChartingErrorBoundary } from '../../atoms';
import { InsightGoalChart } from '../../../_next/presets/insight-goal-chart';
import useGoal from '../../../core/hooks/useGoal';
import {
  GoalChartProps,
  GoalChartRoot,
} from '../../../_next/modules/goal-chart';

export type ConnectedGoalChartProps = {
  goalId?: string;
  customGoal?: GoalChartProps;
  displayOptions?: {
    showBenchmarkProgress?: boolean;
    showGoalProgress?: boolean;
    /* Default to true, refreshes every 3 seconds */
    isLiveMode?: boolean;
    isDummyUI?: boolean;
    isLimit?: boolean;
  };
};

const ConnectedGoalChart = (
  props: ConnectedGoalChartProps,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref?: any
): ReactElement => {
  const goal = useGoal(props.goalId ?? '');

  return (
    <ChartingErrorBoundary chartProps={props}>
      {props.customGoal ? (
        <GoalChartRoot {...props.customGoal} />
      ) : (
        <InsightGoalChart
          ref={ref}
          goal={goal}
          displayOptions={props.displayOptions}
        />
      )}
    </ChartingErrorBoundary>
  );
};

export default forwardRef(ConnectedGoalChart);
