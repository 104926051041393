import Events from './events/Events';
import { IControl } from 'mapbox-gl';
import MapboxUtils from './helpers/mapbox';
import { SERVICES_ALL_LOADED } from './services/constants';

export const PITCH_CONTROL_ON = 'pitch-control-on';
export const PITCH_CONTROL_OFF = 'pitch-control-off';

export default class PitchControl extends Events implements IControl {
	private _container: HTMLElement | null = null;
	private _mapboxUtils: MapboxUtils | null = null;
	private _map: mapboxgl.Map | null = null;
	private _3dActive = false;
	private _disabled = true;

	constructor() {
		super();
		this.attachOutboundEvents();
	}

	get buttonTitle(): string {
		return 'Toggle 3D mode';
	}

	/**
	 * Function called by mapbox when it adds the control to the map
	 * @param map mpabox instance
	 * @returns controls container element
	 */
	onAdd(map: mapboxgl.Map): HTMLElement {
		this._map = map;
		this._container = this.createControl();
		this._mapboxUtils = new MapboxUtils(this._map);
		this.attachEventListeners();
		return this._container;
	}

	/**
	 * Function called by mapbox when it removes the control from the map
	 */
	onRemove(): void {
		this._container?.remove();
		this._map = null;
	}

	/**
	 * Creates the button which controls the layer panel component being shown/hidden
	 * @returns element containing the control button
	 */
	createControl(): HTMLElement {
		const container = document.createElement('div');
		container.classList.add('mapboxgl-ctrl');
		container.classList.add('mapboxgl-ctrl-group');

		container.innerHTML = this.createButton();

		return container;
	}

	/**
	 * html for button
	 * @returns button html
	 */
	createButton(): string {
		return /* HTML */ `
			<button
				class="mapboxgl-3d-toggle disabled"
				disabled="true"
				title="${this.buttonTitle}"
				aria-label="${this.buttonTitle}"
				aria-pressed="false"
				data-cy="3d-toggle"
			></button>
		`;
	}

	/**
	 * Attach event listeners to the toggle button
	 */
	attachEventListeners(): void {
		const menuButton = this._container?.getElementsByClassName(
			'mapboxgl-3d-toggle',
		)[0];

		menuButton?.addEventListener('click', this.togglePitch);
	}

	/**
	 * Toggle between 2d/3d mode (pitch 0 and custom pitch)
	 */
	togglePitch = (): void => {
		if (this._3dActive) {
			this.turnOff();
		} else {
			this.turnOn();
		}
	};

	turnOn() {
		if (this._disabled) return;
		const menuButton = this._container?.getElementsByClassName(
			'mapboxgl-3d-toggle',
		)[0];
		this._mapboxUtils?.addSource('mapbox-dem', {
			type: 'raster-dem',
			url: 'mapbox://mapbox.mapbox-terrain-dem-v1',
			tileSize: 512,
			maxzoom: 14,
		});
		this._mapboxUtils?.setTerrain({
			source: 'mapbox-dem',
			exaggeration: 1.5,
		});
		this._mapboxUtils?.easeTo(
			{ pitch: 80, duration: 5000 },
			'pitchControl:easeTo',
		);
		this._mapboxUtils?.enableDragRotate();
		this._3dActive = true;
		menuButton?.classList.add('active');
		this.fire(PITCH_CONTROL_ON);
	}

	turnOff() {
		if (this._disabled) return;
		const menuButton = this._container?.getElementsByClassName(
			'mapboxgl-3d-toggle',
		)[0];
		this._mapboxUtils?.removeSource('mapbox-dem');
		this._mapboxUtils?.setTerrain(undefined);
		this._mapboxUtils?.easeTo(
			{ pitch: 0, bearing: 0, duration: 5000 },
			'pitchControl:easeTo',
		);
		this._mapboxUtils?.disableDragRotate();
		this._3dActive = false;
		menuButton?.classList.remove('active');
		this.fire(PITCH_CONTROL_OFF);
	}

	attachOutboundEvents(): void {
		this.on(SERVICES_ALL_LOADED, this._onAllServicesLoaded);
	}

	/**
	 * Event triggered when all services have loaded to remove the
	 * disabled class and attribute from the toggle button
	 */
	private _onAllServicesLoaded(): void {
		const menuButton = this._container?.getElementsByClassName(
			'mapboxgl-3d-toggle',
		)[0];

		menuButton?.classList.remove('disabled');
		menuButton?.removeAttribute('disabled');
		this._disabled = false;
	}
}
