import * as React from 'react';
import type {
  OHLCData,
  Reading,
  ReadingOption,
  SensorData,
  SensorDataMap,
  TimeSeriesData,
  TimeSeriesDataMap,
} from './types';
import { useGlobalization } from '../../../i18n/useGlobalization';

export function useReading(): {
  options: ReadingOption[];
  at: (index: number) => Reading | undefined;
  getLabels: () => string[];
  getOption: (value: Reading) => ReadingOption | undefined;
  getProcessedData: (
    rawSensorData: SensorData,
    reading: Reading | 'OHLC',
    dataOverride?: TimeSeriesData
  ) => TimeSeriesData | OHLCData;
  getValues: () => Reading[];
  isValid: (reading: string) => reading is Reading | 'OHLC';
} {
  const { t } = useGlobalization();

  const options = React.useMemo((): ReadingOption[] => {
    return [
      { value: 'Close', label: t('Close') },
      { value: 'Average', label: t('Average') },
      { value: 'High', label: t('High') },
      { value: 'Low', label: t('Low') },
      { value: 'Open', label: t('Open') },
      { value: 'Sum', label: t('Sum') },
    ];
  }, [t]);

  const _lookupTableByValue = React.useMemo((): Map<Reading, number> => {
    const map = new Map<Reading, number>();
    for (let index = 0; index < options.length; index++) {
      map.set(options[index].value, index);
    }
    return map;
  }, [options]);

  const getValues = React.useCallback((): Reading[] => {
    return options.map((option) => option.value);
  }, [options]);

  const getLabels = React.useCallback((): string[] => {
    return options.map((option) => option.label);
  }, [options]);

  const getOption = React.useCallback(
    (value: Reading): ReadingOption | undefined => {
      const entry = _lookupTableByValue.get(value);
      if (entry === undefined) return undefined;
      return options[entry];
    },
    [_lookupTableByValue, options]
  );

  const isValid = React.useCallback(
    (reading: string): reading is Reading | 'OHLC' => {
      return (
        reading === 'OHLC' || !!_lookupTableByValue.has(reading as Reading)
      );
    },
    [_lookupTableByValue]
  );

  const at = React.useCallback((index: number): Reading | undefined => {
    return readingsOrder[index];
  }, []);

  const getProcessedData = React.useCallback(
    (
      rawSensorData: SensorData | SensorDataMap,
      reading: Reading | 'OHLC',
      dataOverride?: TimeSeriesData
    ): TimeSeriesData | OHLCData => {
      let dataOverrideMap: TimeSeriesDataMap | undefined = undefined;

      if (dataOverride && dataOverride.length > 0) {
        dataOverrideMap = new Map();
        for (let i = 0; i < dataOverride.length; i++) {
          const [timestamp, value] = dataOverride[i];
          dataOverrideMap.set(timestamp, value);
        }
      }

      return processingFunctions[reading](rawSensorData, dataOverrideMap);
    },
    []
  );

  return {
    options,
    at,
    getLabels,
    getOption,
    getValues,
    isValid,
    getProcessedData,
  };
}

const readingsOrder: Reading[] = [
  'Open',
  'High',
  'Low',
  'Close',
  'Average',
  'Sum',
];

// This can look like messy / repeated code but trying to simplify it will
// impact performance, either by consuming more memory than needed or slowing
// down the iterations
const processingFunctions: Record<
  Reading | 'OHLC',
  (
    rawSensorData: SensorData | SensorDataMap,
    dataOverrideMap?: TimeSeriesDataMap
  ) => TimeSeriesData | OHLCData
> = {
  Open: (rawSensorData, dataOverrideMap) => {
    if (rawSensorData instanceof Map) {
      return Array.from(rawSensorData.entries()).reduce(
        (processedData, [timestamp, measures]) => {
          const value =
            dataOverrideMap?.get(timestamp) ?? measures.get('Open') ?? null;

          processedData.push([timestamp, value]);
          return processedData;
        },
        [] as TimeSeriesData
      );
    } else {
      return rawSensorData.map((d) => {
        const timestamp = d[0];
        const value = dataOverrideMap?.get(timestamp) ?? d[1] ?? null;
        return [timestamp, value];
      });
    }
  },
  High: (rawSensorData, dataOverrideMap) => {
    if (rawSensorData instanceof Map) {
      return Array.from(rawSensorData.entries()).reduce(
        (processedData, [timestamp, measures]) => {
          const value =
            dataOverrideMap?.get(timestamp) ?? measures.get('High') ?? null;

          processedData.push([timestamp, value]);
          return processedData;
        },
        [] as TimeSeriesData
      );
    } else {
      return rawSensorData.map((d) => {
        const timestamp = d[0];
        const value = dataOverrideMap?.get(timestamp) ?? d[2] ?? null;
        return [timestamp, value];
      });
    }
  },
  Low: (rawSensorData, dataOverrideMap) => {
    if (rawSensorData instanceof Map) {
      return Array.from(rawSensorData.entries()).reduce(
        (processedData, [timestamp, measures]) => {
          const value =
            dataOverrideMap?.get(timestamp) ?? measures.get('Low') ?? null;

          processedData.push([timestamp, value]);
          return processedData;
        },
        [] as TimeSeriesData
      );
    } else {
      return rawSensorData.map((d) => {
        const timestamp = d[0];
        const value = dataOverrideMap?.get(timestamp) ?? d[3] ?? null;
        return [timestamp, value];
      });
    }
  },
  Close: (rawSensorData, dataOverrideMap) => {
    if (rawSensorData instanceof Map) {
      return Array.from(rawSensorData.entries()).reduce(
        (processedData, [timestamp, measures]) => {
          const value =
            dataOverrideMap?.get(timestamp) ?? measures.get('Close') ?? null;

          processedData.push([timestamp, value]);
          return processedData;
        },
        [] as TimeSeriesData
      );
    } else {
      return rawSensorData.map((d) => {
        const timestamp = d[0];
        const value = dataOverrideMap?.get(timestamp) ?? d[4] ?? null;
        return [timestamp, value];
      });
    }
  },
  Average: (rawSensorData, dataOverrideMap) => {
    if (rawSensorData instanceof Map) {
      return Array.from(rawSensorData.entries()).reduce(
        (processedData, [timestamp, measures]) => {
          const value =
            dataOverrideMap?.get(timestamp) ?? measures.get('Average') ?? null;

          processedData.push([timestamp, value]);
          return processedData;
        },
        [] as TimeSeriesData
      );
    } else {
      return rawSensorData.map((d) => {
        const timestamp = d[0];
        const value = dataOverrideMap?.get(timestamp) ?? d[5] ?? null;
        return [timestamp, value];
      });
    }
  },
  Sum: (rawSensorData, dataOverrideMap) => {
    if (rawSensorData instanceof Map) {
      return Array.from(rawSensorData.entries()).reduce(
        (processedData, [timestamp, measures]) => {
          const value =
            dataOverrideMap?.get(timestamp) ?? measures.get('Sum') ?? null;

          processedData.push([timestamp, value]);
          return processedData;
        },
        [] as TimeSeriesData
      );
    } else {
      return rawSensorData.map((d) => {
        const timestamp = d[0];
        const value = dataOverrideMap?.get(timestamp) ?? d[6] ?? null;
        return [timestamp, value];
      });
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  OHLC: (rawSensorData, _dataOverrideMap) => {
    if (rawSensorData instanceof Map) {
      return Array.from(rawSensorData.entries()).reduce(
        (processedData, [timestamp, measures]) => {
          processedData.push([
            timestamp,
            measures.get('Open') ?? null,
            measures.get('High') ?? null,
            measures.get('Low') ?? null,
            measures.get('Close') ?? null,
          ]);
          return processedData;
        },
        [] as OHLCData
      );
    } else {
      return rawSensorData.map((d) => [d[0], d[1], d[2], d[3], d[4]]);
    }
  },
};
