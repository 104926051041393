import styled, { css } from 'styled-components';

import { rem } from '@Components/styles';

export const ControlButtonWrapper = styled.div`
	background: #fff;
	border-radius: ${rem(4)};
	color: #43575d;
	&:not(:empty) {
		box-shadow: 0 0 0 ${rem(2)} rgba(0, 0, 0, 0.1);
	}
`;

export const Button = styled.button<{ $active: boolean }>`
	background-color: transparent;
	border: 0;
	box-sizing: border-box;
	color: inherit;
	cursor: pointer;
	display: block;
	height: ${rem(29)};
	outline: none;
	overflow: hidden;
	padding: 0;
	width: ${rem(29)};
	${({ $active }) =>
		$active &&
		css`
			color: #00abd1;
		`}
	svg {
		display: block;
		margin: 0 auto;
		width: ${rem(20)};
		height: ${rem(20)};
	}
`;
