import { useTranslation } from 'react-i18next';
import { ns } from './settings.json';

/**
 * Translate text with react-i18next useTranslate with
 * predefined namespace for the mini-app
 * @param i18nNs overide namespace
 * @returns string
 */
export const useGlobalization = (i18nNs = ns) => {
	const { t, ...other } = useTranslation(i18nNs);
	return { t, ...other };
};
