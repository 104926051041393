import { FlattenSimpleInterpolation, css } from 'styled-components';
export const rem = (px: number, basePx = 16): string => `${px / basePx}rem`;

export type StyledPropsExpression =
	| false
	| FlattenSimpleInterpolation
	| undefined;

export const fakeFocusBackground = css`
	&:before {
		content: '';
		position: absolute;
		display: block;
		background: #eaf4f9;
		width: calc(200%);
		height: 100%;
		left: -50%;
		top: 0;
	}
`;

export const fakeBorderSharedStyles = css`
	content: '';
	position: absolute;
	display: block;
	width: calc(200%);
	height: 1px;
	left: -50%;
	bottom: 0;
	background: #e5e5e5;
`;

export const fakeBorderBottom = css`
	&:after {
		${fakeBorderSharedStyles}
		bottom: 0;
	}
`;

export const fakeBorderTop = css`
	&:before {
		${fakeBorderSharedStyles}
		top: 0;
	}
`;

export const printPreviewStyles = (
	content: string | FlattenSimpleInterpolation,
) => {
	return css`
		@media print {
			& {
				${content};
			}
		}
		.print-preview & {
			& {
				${content};
			}
		}
	`;
};
