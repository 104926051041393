import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import {
  enforcedNavigatorOptions,
  enforcedRangeSelectorOptions,
  enforcedStackedSeriesOptions,
  findStackOptions,
} from './StackedChart.utils';
import Chart from '../Chart';
import Highcharts from 'highcharts';
import LoadingData from '../LoadingData';
import Styled from './StackedChart.styles';
import useHighchartsOptions from '../../hooks/useHighchartsOptions';
import useSyncEvents from '../../hooks/useSyncEvents';
import useTooltipEvents from '../../hooks/useTooltipEvents';

import type { ChartProps, ChartRef } from '../Chart';
import type { ReactElement, Ref, RefObject } from 'react';
import type { StackOptions } from './StackedChart.types';
import type { SensorDataRecords } from '../../types/data.types';
import { useGlobalization } from '../../../i18n/useGlobalization';

export type OnStackDestroyedHandler = (
  charts: Highcharts.Chart[],
  containerRef: RefObject<HTMLDivElement | null>
) => void;

export interface StackedChartProps
  extends Omit<ChartProps, 'onChartDestroyed' | 'withoutTooltipEvents'> {
  data: SensorDataRecords;
  onStackDestroyed?: OnStackDestroyedHandler;
  stackOptions?: StackOptions[];
}

export interface StackedChartRef {
  charts: Highcharts.Chart[];
  containerRef: RefObject<HTMLDivElement>;
}

const StackedChart = (
  props: StackedChartProps,
  ref?: Ref<StackedChartRef>
): ReactElement => {
  const { t } = useGlobalization();
  const chartsRef = useRef<Highcharts.Chart[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);

  /***
   * This manages showing / hidding the tooltips
   * as well as the markers once the mouse leaves
   * the container for all the charts in the stack.
   */
  useTooltipEvents(containerRef, chartsRef);

  /**
   * This will store the options for each series, it includes all the options
   * passed as props.options. Then it'll apply some override options to remove
   * some unwanted configs (like range selectors and navigators).
   *
   * We'll iterate over these and create a single chart for each props.options.series,
   * so we'll take the series index for each one of the individual charts and
   * assigned as the options.series[0] value to show only 1 series per chart.
   *
   * Then, it'll override the series config with those provided in the stack options.
   */
  const [seriesOptions, setSeriesOptions] = useState<
    Highcharts.Options[] | undefined
  >();

  /**
   * Here we synchronize all the individual chart extremes to make
   * them work as if they were just one native Highchart's chart.
   * */
  const syncExtremes = useSyncEvents(containerRef, chartsRef);

  /**
   * We can't use Highchart's merge to extend the functionality on
   * props.options.xAxis.events.setExtremes because it'll replace
   * the value instead of calling both handlers.
   *
   * So we have to manually replace the event with a function that
   * calls both the syncExtremes and whatever was set on the parent's
   * options.xAxis.events.setExtremes.
   */
  const syncExtremesOptions = useHighchartsOptions(() => {
    return {
      xAxis: {
        events: {
          setExtremes: function (
            this: Highcharts.Axis,
            event: Highcharts.AxisSetExtremesEventObject
          ) {
            syncExtremes.call(this, event);

            const xAxis = Array.isArray(props.options?.xAxis)
              ? props.options?.xAxis[0]
              : props.options?.xAxis;

            xAxis?.events?.setExtremes?.call(this, event);
          },
        },
      },
    };
  }, [props.options, syncExtremes]);

  /**
   * For the range selector, we copy all the config set on props.options
   * and we apply some overrides to remove unwanted configs. We also add the
   * syncExtremes options.
   */
  const rangeSelectorOptions = useHighchartsOptions(() => {
    const seriesOptions =
      props.options.series?.map<Highcharts.SeriesOptionsType>(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore Highchart's bad type definitions
        (seriesOptions, seriesIndex) => {
          const stackOptions = findStackOptions(
            seriesIndex,
            seriesOptions,
            props.stackOptions
          );

          return Highcharts.merge<Highcharts.SeriesOptionsType>(
            false,
            seriesOptions,
            stackOptions.series?.[0],
            { dataLabels: { enabled: false } }
          );
        }
      );

    return [
      props.options,
      seriesOptions ? { series: seriesOptions } : undefined,
      enforcedRangeSelectorOptions,
      syncExtremesOptions,
    ];
  }, [props.options, props.stackOptions, syncExtremesOptions]);

  /**
   * For the navigator, we copy all the config set on props.options
   * and we apply some overrides to remove unwanted configs. We also add the
   * syncExtremes options.
   */
  const navigatorOptions = useHighchartsOptions(() => {
    const seriesOptions =
      props.options.series?.map<Highcharts.SeriesOptionsType>(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore Highchart's bad type definitions
        (seriesOptions, seriesIndex) => {
          const stackOptions = findStackOptions(
            seriesIndex,
            seriesOptions,
            props.stackOptions
          );

          return Highcharts.merge<Highcharts.SeriesOptionsType>(
            false,
            seriesOptions,
            stackOptions.series?.[0],
            { dataLabels: { enabled: false } }
          );
        }
      );

    return [
      props.options,
      seriesOptions ? { series: seriesOptions } : undefined,
      enforcedNavigatorOptions,
      syncExtremesOptions,
    ];
  }, [props.options, props.stackOptions, syncExtremesOptions]);

  /**
   * For each series, we copy all the config set on props.options
   * and we apply some overrides to remove unwanted configs. We also add the
   * syncExtremes options.
   *
   * Then we merge the series config with the overrides provided for the stack.
   * We also make sure the color is reset. Otherwise, highcharts will set the last
   * color used whenever we render a new chart (hidding / showing a series).
   */
  useLayoutEffect(() => {
    const seriesOptions = props.options.series?.map<Highcharts.Options>(
      (seriesOptions, seriesIndex) => {
        const stackOptions = findStackOptions(
          seriesIndex,
          seriesOptions,
          props.stackOptions
        );

        const { series: stackSeriesOptions, ...otherStackOptions } =
          stackOptions;

        const mergedSeriesOptions =
          Highcharts.merge<Highcharts.SeriesOptionsType>(
            false,
            seriesOptions,
            stackSeriesOptions?.[0],
            {
              color: seriesOptions.color,
              id: seriesOptions.id ?? seriesIndex,
            }
          );

        return Highcharts.merge<Highcharts.Options>(
          false,
          props.options,
          otherStackOptions,
          mergedSeriesOptions ? { series: [mergedSeriesOptions] } : undefined,
          enforcedStackedSeriesOptions,
          syncExtremesOptions
        );
      }
    );

    setSeriesOptions(seriesOptions);
  }, [props.options, props.stackOptions, syncExtremesOptions]);

  /**
   * Instead of passing along the ref object, we pass a function
   * that sets the internal refs to each chart and we add them
   * if they're not there already.
   * */
  const addChartRef = useCallback((ref: ChartRef | null) => {
    if (ref?.chart) {
      const isAlreadyStored = chartsRef.current.includes(ref.chart);
      if (!isAlreadyStored) {
        chartsRef.current.push(ref?.chart);
      }
    }
  }, []);

  /**
   * When a chart is unmounted, we remove them from the charts
   * references so that we don't keep increasing an infinite
   * array of nulls. Keep in mind that we use this array to
   * iterate over the current charts and do stuff with
   * their API. So if we don't clean it up, we'll have BA~D!
   * performance.
   * */
  const removeChartRef = useCallback((removedChart?: Highcharts.Chart) => {
    chartsRef.current = chartsRef.current.filter(
      (chart) => removedChart !== chart
    );
  }, []);

  /**
   * With this, we control the passed ref to the component to
   * expose the charts' API as well as the container ref to the parent.
   */
  useImperativeHandle(
    ref,
    () => ({
      containerRef,
      get charts() {
        return chartsRef?.current;
      },
    }),
    []
  );

  return (
    <Styled.Stack ref={containerRef}>
      {!!rangeSelectorOptions.rangeSelector?.enabled && (
        <Styled.RangeSelector>
          <Chart
            constructorType={props.constructorType}
            cy={props.cy ? `${props.cy}-range-selector` : undefined}
            highcharts={props.highcharts}
            onChartDestroyed={removeChartRef}
            options={rangeSelectorOptions}
            ref={addChartRef}
            withoutTooltipEvents
          />
        </Styled.RangeSelector>
      )}
      <Styled.Scroll>
        {seriesOptions?.map((options, seriesIndex) => {
          const series = options.series?.[0];

          if (
            !series?.custom ||
            !series.custom.sensorId ||
            !series.custom.resolution
          ) {
            throw new Error(
              'Please, make sure to include the sensorId and resolution on the custom prop of the series'
            );
          }

          const { sensorId, resolution } = series.custom;
          const isSeriesVisible = series?.visible;

          let data = props.data[sensorId]?.[resolution];

          if (!data) {
            return null;
          }

          const isSeriesresolvedOrRejected = ['resolved', 'rejected'].includes(
            data.status
          );

          if (isSeriesresolvedOrRejected && isSeriesVisible === false) {
            return null;
          }

          if (data.status === 'resolved' && data.data.timestamps.length === 0) {
            data = {
              status: 'rejected',
              data: null,
              message: t('No data in time period'),
            };
          }

          return (
            <Styled.Flex key={options.series?.[0]?.id ?? seriesIndex}>
              <LoadingData data={data} options={{ yAxis: options.yAxis }}>
                <Chart
                  constructorType={props.constructorType}
                  cy={
                    props.cy ? `${props.cy}-series-${seriesIndex}` : undefined
                  }
                  highcharts={props.highcharts}
                  onChartDestroyed={removeChartRef}
                  options={options}
                  ref={addChartRef}
                  withoutTooltipEvents
                />
              </LoadingData>
            </Styled.Flex>
          );
        })}
      </Styled.Scroll>
      {!!navigatorOptions.navigator?.enabled && (
        <Styled.Navigator>
          <Chart
            constructorType={props.constructorType}
            cy={props.cy ? `${props.cy}-navigator` : undefined}
            highcharts={props.highcharts}
            onChartDestroyed={removeChartRef}
            options={navigatorOptions}
            ref={addChartRef}
            withoutTooltipEvents
          />
        </Styled.Navigator>
      )}
    </Styled.Stack>
  );
};

export default forwardRef<StackedChartRef, StackedChartProps>(StackedChart);
