import {
	MACPObservation,
	MSCCObservation,
	Observation,
	PACPObservation,
	WSA05Observation,
} from '@Types';
import { DateTime } from 'luxon';

/** Convert seconds to a HHMMSS string
 */
export const secondsToTime = (seconds: number | undefined) => {
	if (seconds === undefined) seconds = 0;
	const date = new Date(seconds * 1000);
	return `${date.getUTCHours().toString().padStart(2, '0')}${date
		.getUTCMinutes()
		.toString()
		.padStart(2, '0')}${date.getUTCSeconds().toString().padStart(2, '0')}`;
};

/** Convert HHMMSS to seconds
 */
export const timeToSeconds = (time: string) => {
	let result = 0;

	if (time && (time.length == 6 || time.length == 8)) {
		try {
			time = time.replace(/[:]/g, '');
			const hour = time.substring(0, 2);
			const min = time.substring(2, 4);
			const sec = time.substring(4, 6);
			result = Number(hour) * 360 + Number(min) * 60 + Number(sec);
		} catch (ex) {
			const error = ex as Error;
			console.error(error.message);
		}
	}

	return result;
};

/** Format HHMMSS to HH:MM:SS for display
 */
export const formatDisplayTime = (label: string) => {
	if (label && label.length == 6) {
		return (
			label.substring(0, 2) +
			':' +
			label.substring(2, 4) +
			':' +
			label.substring(4, 6)
		);
	}
	return label;
};

export const fixupCounterAndTime = (observation: Observation) => {
	if ('VCRTime' in observation) {
		const obs = observation as PACPObservation;
		// Make sure we have both counter and time
		if (obs.counter && obs.VCRTime !== '') {
			obs.counter = timeToSeconds(obs.VCRTime);
		} else if (obs.VCRTime === '' && obs.counter) {
			obs.VCRTime = secondsToTime(Number(obs.counter));
		}
	} else if ('vcrTime' in observation) {
		const obs = observation as MACPObservation;
		// Make sure we have both counter and time
		if (obs.counter && obs.vcrTime !== '') {
			obs.counter = timeToSeconds(obs.vcrTime);
		} else if (obs.vcrTime === '' && obs.counter) {
			obs.vcrTime = secondsToTime(Number(obs.counter));
		}
	} else if ('videoRef' in observation) {
		const obs = observation as MSCCObservation;
		// MSCC does not have a counter so we want to fix that from the time in videoRef
		if (obs.videoRef !== '') {
			obs.counter = timeToSeconds(obs.videoRef);
		}
	} else if ('videoPosition' in observation) {
		const obs = observation as WSA05Observation;
		// WSA05 does not have a counter so we want to fix that from the time in videoRef
		if (obs.videoPosition !== '') {
			obs.counter = timeToSeconds(obs.videoPosition);
		}
	}
	return observation;
};

export const fixupObservationsCounterAndTime = (
	observations: Observation[],
): Observation[] => {
	return observations.map((observation: Observation) =>
		fixupCounterAndTime(observation),
	);
};

export const dateTimeForPicker = (dateTime: string): string => {
	//Date picker can only work with local datetime. This adjustment is
	//neded to keep the date true and prevent from the selected date
	//from jumping backward and foward
	const jsDate = new Date(dateTime);
	let dt = DateTime.fromJSDate(jsDate);
	dt = dt.plus({ minutes: dt.offset });
	return dt.toUTC().toISO() ?? '';
};

export const dateTimeFromPicker = (dateTime: string): string => {
	//Date picker can only work with local datetime. This adjustment is
	//neded to keep the date true and prevent from the selected date
	//from jumping backward and foward
	const jsDate = new Date(dateTime);
	let dt = DateTime.fromJSDate(jsDate);
	dt = dt.plus({ minutes: dt.offset });
	return dt.toUTC().toISO() ?? '';
};
