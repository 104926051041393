import * as React from 'react';
import { nanoid } from 'nanoid';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Stable Callback Hook
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type StableCallback<T extends (...args: any[]) => any> =
  React.MutableRefObject<T | undefined>;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useStableCallback<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends (...args: any[]) => any,
>(callback: T | undefined): StableCallback<T> {
  const stableCallback = React.useRef(callback);
  stableCallback.current = callback;
  return stableCallback;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Controlled Render Hook
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useControlledRender() {
  const [id, setId] = React.useState<string>(nanoid());

  const trigger = React.useCallback(() => {
    setId(nanoid());
  }, [setId]);

  return { id, trigger };
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Controllable State Hook
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export function useControllableState<S>(
  initialUncontrolledState: S,
  controlledState?: S,
  onChangeHandler?: (value: S) => void
): [S, React.Dispatch<React.SetStateAction<S>>] {
  const prevUncontrolledState = React.useRef(initialUncontrolledState);
  const [uncontrolledState, setUncontrolledState] = React.useState(
    initialUncontrolledState
  );

  const _onChangeHandler = useStableCallback(onChangeHandler);
  const state = controlledState ?? uncontrolledState;

  const setState: typeof setUncontrolledState = React.useCallback(
    (nextState) => {
      if (controlledState !== undefined) {
        const setter = nextState as (prevState?: S) => S;
        const value =
          typeof nextState === 'function' ? setter(controlledState) : nextState;

        if (value !== controlledState) _onChangeHandler.current?.(value as S);
      } else {
        setUncontrolledState(nextState);
      }
    },
    [_onChangeHandler, controlledState]
  );

  React.useEffect(() => {
    if (prevUncontrolledState.current !== uncontrolledState) {
      _onChangeHandler.current?.(uncontrolledState as S);
      prevUncontrolledState.current = uncontrolledState;
    }
  }, [_onChangeHandler, prevUncontrolledState, uncontrolledState]);

  return [state, setState];
}
