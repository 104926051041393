import { Assignment, Check, LibraryAdd, Search } from '@mui/icons-material';
import {
	Incident,
	Property,
	PropertyCard,
	addAlertToIncident,
	getIncidentByAlertId,
	selectActiveIncidents,
	updateAlertStatus,
} from '@innovyze/stylovyze';
import { useDispatch, useSelector } from 'react-redux';

import { AddToIncidentDialog } from '@Components/AddToIncidentDialog';
import { FeatureItem } from '@Utils/types';
import React from 'react';
import { useGlobalization } from '@Translations/useGlobalization';
import { useHistory } from 'react-router-dom';

interface AlertPropertiesProps {
	item: FeatureItem;
	onAlertResolved: (alertId: string) => void;
}

export const AlertProperties = ({
	item,
	onAlertResolved,
}: AlertPropertiesProps): JSX.Element => {
	const { t } = useGlobalization();
	const dispatch = useDispatch();
	const history = useHistory();

	const properties: Property[] = [
		{
			title: t('Sensor ID', { context: 'Alert properties' }),
			value: `${item.sensorId}`,
			ellipsis: true,
			cy: 'sensorId',
		},
		{
			title: t('Priority', { context: 'Alert properties' }),
			value: `${item.priority}`,
			type: 'priority',
			cy: 'priority',
		},
		{
			title: t('Date/Time', { context: 'Alert properties' }),
			value: `${item.createDate}`,
			type: 'date',
			cy: 'createDate',
		},
	];

	const [addToIncidentDialog, setAddToIncidentDialog] = React.useState<{
		open: boolean;
		alertId?: string;
	}>({ open: false, alertId: undefined });
	const activeIncidents = useSelector(selectActiveIncidents);

	const onClickViewDetails = (mapAlertId: string) => {
		const alertId = mapAlertId.split('-')[0];
		history.push('/alerts-management/alert/' + alertId);
	};

	const onClickResolve = (mapAlertId: string) => {
		const alertId = mapAlertId.split('-')[0];
		dispatch(
			updateAlertStatus({
				id: alertId,
				status: 'Resolved',
				detailId: alertId,
			}),
		);
		onAlertResolved(alertId);
	};

	const onClickCreateIncident = (mapAlertId: string) => {
		const alertId = mapAlertId.split('-')[0];
		history.push('/incident-management/create-incident/' + alertId);
	};

	const onClickAddToIncident = (mapAlertId: string) => {
		const alertId = mapAlertId.split('-')[0];
		dispatch(
			getIncidentByAlertId({
				limit: 50,
				status: 'open',
				alertId: alertId,
			}),
		);
		setAddToIncidentDialog({
			open: true,
			alertId: alertId,
		});
	};

	const closeAddToIncidentDialog = () => {
		setAddToIncidentDialog({ open: false, alertId: undefined });
	};

	const addAlert = (incident: Incident) => {
		closeAddToIncidentDialog();
		if (incident && addToIncidentDialog.alertId) {
			dispatch(
				addAlertToIncident({
					incident: incident,
					alertId: addToIncidentDialog.alertId,
				}),
			);
		}
	};

	const menuItems = [
		{
			text: t('View Details', { context: 'Alert properties menu item' }),
			onClick: () => onClickViewDetails(item.id),
			icon: <Search />,
		},
		{
			text: t('Resolve Alert', { context: 'Alert properties menu item' }),
			onClick: () => onClickResolve(item.id),
			icon: <Check />,
		},
		{
			text: t('Create Incident', {
				context: 'Alert properties menu item',
			}),
			onClick: () => onClickCreateIncident(item.id),
			icon: <Assignment />,
		},
		{
			text: t('Add To Incident', {
				context: 'Alert properties menu item',
			}),
			onClick: () => onClickAddToIncident(item.id),
			icon: <LibraryAdd />,
		},
	];

	return (
		<>
			<PropertyCard
				title={item.name}
				properties={properties}
				menuItems={menuItems}
				marginBottom
			/>
			<AddToIncidentDialog
				open={addToIncidentDialog.open}
				linkedIncidents={activeIncidents ?? []}
				onCancel={closeAddToIncidentDialog}
				onConfirm={addAlert}
			/>
		</>
	);
};
