import { Layout } from '@Types/map.types';
import { addNamespace } from '@Utils/actions';
import { createAction } from 'redux-actions';

export const getLayout = createAction(addNamespace('map/getLayout'));
export const getLayoutResolved = createAction<Layout>(
	addNamespace('map/getLayout/resolved'),
);
export const getLayoutRejected = createAction(
	addNamespace('map/getLayout/rejected'),
);
export const clearMap = createAction(addNamespace('map/clearMap'));
