import { UserContextStoreState } from '@innovyze/stylovyze';
import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

const parseNamespace = (state: UserContextStoreState) => state['userContext'];

const userContextSelector = createSelector(parseNamespace, state => state);

export const selectUserContext = () => useSelector(userContextSelector);

export const selectUserInitial = () => {
	const userContext = useSelector(userContextSelector);
	const name = userContext?.name;
	if (!name || name.length <= 0) return '';
	let components: string[];

	// Name could be an email with @, but not necessarily, so
	// If @ present treat as email and use initials of . seperated words before @
	// If missing treat as sentence and use initials of space seperated words
	//   notably multiple spaces in a row

	if (name.indexOf('@') >= 0) {
		components = name.substr(0, name.indexOf('@')).split('.');
	} else {
		components = name.split(' ');
	}

	const initials = components
		.map(m => m.trim())
		.filter(f => f.length > 0)
		.map(m => m[0].toUpperCase());

	if (initials.length == 1) {
		return initials[0];
	} else {
		// 1st and last
		return initials[0] + initials[initials.length - 1];
	}
};

export default {
	selectUserContext,
	selectUserInitial,
};
