import {
	InspectionProperties,
	InspectionPropertiesProps,
	TaskProperties,
	TaskPropertiesProps,
	EventProperties, 
	EventPropertiesProps
} from '@Components/InspectionProperties';
import {
	MenuItem,
	Property,
	PropertyCard,
	useIsFeatureEnabled,
} from '@innovyze/stylovyze';
import React, { useEffect } from 'react';
import { extractValue, getAssetType } from '@Utils/helpers';
import { useDispatch, useSelector } from 'react-redux';

import AssetPropertyRegistry from './propertyMapping';
import { AssetTypeInfo } from '@Types/map.types';
import { Divider } from '@mui/material';
import { FeatureItem } from '@Utils/types';
import { NamespacedStoreState } from '@Types/store.types';
import { SpatialProperty } from '@Types/risk.types';
import { TFunc } from '@Translations/types';
import { getLayout } from '@Actions/map';
import { selectMapLayoutForPropertyPane } from '@Selectors/map';
import { useGlobalization } from '@Translations/useGlobalization';
import { useMap } from '../../context/Map';

export interface AssetPropertiesProps {
	/** feature item properties */
	item: FeatureItem;
	/** function for view details button in menu, button is only shown if function passed through */
	viewDetails?: (
		id: string,
		layerId?: string,
		assetId?: string,
		assetType?: string,
	) => void;
	/** asset types to show view details button for */
	viewDetailsForAssetTypes?: string[];
	/** function for view details on inspection, button is only shown if function passed through */
	viewInspection?: InspectionPropertiesProps['viewInspection'];
	/** function for view details on task, button is only shown if function passed through */
	viewTask?: TaskPropertiesProps['viewTask'];
	/** function for view details on event, button is only shown if function passed through */
	viewEvent?: EventPropertiesProps['viewEvent'];
	/** show inspection properties */
	showInspections?: boolean;
}

const segmentProperties = (t: TFunc, assetType: string) => {
	if (assetType == 'pipe' || assetType == 'wwPipe') {
		return [
			{
				prop: 'width',
				label: t('Width', { context: 'Asset properties' }),
				unit: 'mm',
			},
			{
				prop: 'material',
				label: t('Material', { context: 'Asset properties' }),
			},
		];
	} else return [];
};

const getValue = (
	item: FeatureItem | FeatureItem['risk'],
	prop: string,
	type?: string,
	unit?: string,
): string | undefined => {
	const itemValue =
		(item?.segments as FeatureItem[])?.[0][prop] || item?.[prop];
	const value = extractValue(itemValue as SpatialProperty);
	if (value == null) return undefined;

	// If its a date then make sure it's valid to avoid timezone issues since asset data is always imported as UTC dates
	if (type === 'dateonlyUTC') {
		try {
			// Try and load the date into a date object to get a valid ISO string
			const dateISO = new Date(value).toISOString();
			return dateISO;
		} catch {
			return value;
		}
	} else {
		return value + (unit ? unit : '');
	}
};

const getType = (type?: string, unit?: string): Property['type'] => {
	if (unit) return 'unit';
	if (type) {
		if (type === 'string') return 'text';
		if (type === 'date') return 'dateonlyUTC';
		return type as Property['type'];
	}
	return 'text';
};

const formatSystemType = (t: TFunc, systemType: string) => {
	switch (systemType) {
		case 'SanitarySewer':
			return t('Sanitary Sewer');
		case 'WaterDistribution':
			return t('Water Distribution');
		case '':
			return '-';
		default:
			return t(systemType);
	}
};

export const AssetProperties = ({
	item,
	viewDetails,
	viewInspection,
	viewTask,
	viewEvent,
	viewDetailsForAssetTypes,
	showInspections,
}: AssetPropertiesProps): JSX.Element => {
	const assetTrace = useIsFeatureEnabled('info-360-map-asset-trace');
	const { t } = useGlobalization();
	const { traceFromAsset } = useMap();
	const dispatch = useDispatch();

	const showEvents = useIsFeatureEnabled('info-360-am-events');

	const assetType = getAssetType(item);

	// Keep the static wwPipe and wwManhole for the moment
	const dynamicProps =
		useIsFeatureEnabled('info-360-dynamic-props') &&
		assetType !== 'wwPipe' &&
		assetType !== 'wwManhole';

	const layout = useSelector<NamespacedStoreState, AssetTypeInfo | undefined>(
		state => selectMapLayoutForPropertyPane(state, assetType),
	);
	const assetMappings = AssetPropertyRegistry.getMappings(assetType);

	const showViewDetails =
		viewDetails &&
		(viewDetailsForAssetTypes?.includes(assetType) ||
			!viewDetailsForAssetTypes?.length);

	const properties: Property[] = [
		...[
			{
				title: t('System Type', { context: 'Asset properties' }),
				value: `${formatSystemType(t, item.layerSystemType ?? '')}`,
				ellipsis: true,
				cy: 'systemType',
				span: 4,
			},
			{
				title: t('Asset Type', { context: 'Asset properties' }),
				value: `${t(item.displayType ?? '')}`,
				ellipsis: true,
				cy: 'assetType',
				span: 4,
			},
		],
		...((!dynamicProps && [
			{
				title: t('Asset ID', { context: 'Asset properties' }),
				value: `${item.assetId}`,
				ellipsis: true,
				cy: 'assetId',
				span: 4,
			},
		]) ||
			[]),
		...((!dynamicProps &&
			assetMappings.map(({ prop, label, type, unit }) => ({
				title: t(label),
				value: getValue(item, prop, type, unit),
				cy: prop,
				type: getType(type, unit),
				span: 4,
				ellipsis: true,
			}))) ||
			[]),
		...((!dynamicProps &&
			segmentProperties(t, assetType).map(({ prop, label, unit }) => ({
				title: label,
				value:
					item.segments && item.segments.length > 0
						? getValue(item.segments[0], prop, undefined, unit)
						: undefined,
				cy: prop,
				type: getType(undefined, unit),
				span: 4,
				ellipsis: true,
			}))) ||
			[]),
		...((dynamicProps &&
			layout?.fields.map(
				({ name, fieldType, fieldKey, units, precision }) => ({
					title: t(name, { context: 'Asset Property' }),
					value: getValue(item, fieldKey, fieldType, units),
					cy: fieldKey,
					type: getType(fieldType, units),
					span: 4,
					decimalPlaces: precision,
					ellipsis: true,
				}),
			)) ||
			[]),
	];

	let menuItems: MenuItem[] = [];
	if (showViewDetails) {
		menuItems = [
			...menuItems,
			{
				text: t('View Details', {
					context: 'Asset properties menu item - view more details',
				}),
				onClick: () =>
					viewDetails?.(
						item.id,
						item.layerId,
						item.assetId,
						item.assetType,
					),
			},
		];
	}
	if (assetTrace && (item.dsNodeId || item.usNodeId)) {
		menuItems = [
			...menuItems,
			{
				text: t('Upstream Selection', {
					context:
						'Asset properties menu item - perform upstream trace',
				}),
				onClick: () => traceFromAsset?.(item.id),
			},
			{
				text: t('Downstream Selection', {
					context:
						'Asset properties menu item - perform downstream trace',
				}),
				onClick: () => traceFromAsset?.(item.id, true),
			},
		];
	}

	useEffect(() => {
		dispatch(getLayout());
	}, []);

	return (
		<PropertyCard
			title={item.assetId || item.id}
			properties={properties}
			menuItems={menuItems}
			columns={12}
			marginBottom
			additionalContent={
				showInspections && item.inspections?.length ? (
					<>
						<Divider />
						{item.inspections.map((inspection, i) => {
							if (inspection.task) {
								return (
									<TaskProperties
										key={i}
										inspection={inspection}
										viewTask={viewTask}
									/>
								);
							} else if (showEvents && inspection.event) {
								return (
									<EventProperties
										key={i}
										inspection={inspection}
										viewEvent={viewEvent}
									/>
								);
							} else {
								return (
									<InspectionProperties
										key={i}
										inspection={inspection}
										viewInspection={viewInspection}
									/>
								);
							}
						})}
					</>
				) : (
					undefined
				)
			}
		/>
	);
};
