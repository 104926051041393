import { NamespacedStoreState } from '@Types/store.types';
import { createSelector } from '@reduxjs/toolkit';
import { moduleNamespace } from '@Store/config.store';
import { useSelector } from 'react-redux';

const parseNamespace = (state: NamespacedStoreState) => state[moduleNamespace];

const assetRiskSelector = createSelector(
	parseNamespace,
	state => state.assetRisk?.assetRisk,
);

const assetRiskSummarySelector = createSelector(
	parseNamespace,
	state => state.assetRisk?.riskSummary,
);

const assetRiskReadySelector = createSelector(
	parseNamespace,
	state => state.assetRisk?.isReady,
);

const assetRiskRejectedSelector = createSelector(
	parseNamespace,
	state => state.assetRisk?.isReady,
);

const assetRiskIsWaitingSelector = createSelector(
	parseNamespace,
	state => state.assetRisk?.isWaiting,
);

const assetRiskSummaryIsLoadingSelector = createSelector(
	parseNamespace,
	state => state.assetRisk?.riskSummaryIsLoading,
);

export const selectAssetRiskIsWaiting = () =>
	useSelector(assetRiskIsWaitingSelector);
export const selectAssetRiskIsRejected = () =>
	useSelector(assetRiskRejectedSelector);
export const selectAssetRiskIsReady = () => useSelector(assetRiskReadySelector);
export const selectAssetRisk = () => useSelector(assetRiskSelector);
export const selectRiskSummay = () => useSelector(assetRiskSummarySelector);
export const selectRiskSummayIsLoading = () =>
	useSelector(assetRiskSummaryIsLoadingSelector);
export default {
	selectAssetRiskIsWaiting,
	selectAssetRiskIsRejected,
	selectAssetRiskIsReady,
	selectAssetRisk,
};
