/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	getRehabResult,
	getRehabResultRejected,
	getRehabResultResolved,
	getRehabTrees,
	getRehabTreesRejected,
	getRehabTreesResolved,
	toggleRehabTreePublishState,
	toggleRehabTreePublishStateResolved,
	toggleRehabTreePublishStateRejected,
} from '@Actions/Rehab.action';
import { put, retry, takeLatest } from '@redux-saga/core/effects';
import {
	getRehabResultApi,
	getRehabTreesApi,
	toggleRehabTreePublishStateApi,
} from '@Services/Rehab.servies';
import { ResolvedResponse, Row } from '@Types/pagination.types';
import {
	ReactFlowDecisionTree,
	ReactFlowSchema,
	RehabDecisionTree,
} from '@Types/rehab.types';
import { convertTreeToReactFlowObject } from '@Utils/treeUtils';
import { AxiosResponse } from 'axios';
import { AnyAction } from 'redux';
import { takeEvery } from 'redux-saga/effects';
import { error, success } from '@innovyze/stylovyze';
import { t } from 'i18next';

function* getRehabTreesSaga() {
	try {
		const payload: AxiosResponse<RehabDecisionTree[]> = yield retry(
			5,
			15000,
			getRehabTreesApi,
		);
		let reactFlowDecisionTrees: ReactFlowDecisionTree[] = [];
		payload.data.map(decisionTree => {
			const treeNodes: ReactFlowSchema[] = convertTreeToReactFlowObject(
				decisionTree.treeNodes,
			);
			const reactFlowDecisionTree: ReactFlowDecisionTree = {
				_id: decisionTree._id,
				name: decisionTree.name,
				thumbnail: decisionTree.thumbnail,
				nodes: treeNodes,
				status: decisionTree.status,
				runBy: decisionTree.runBy,
				lastRun: decisionTree.lastRun,
				assetType: decisionTree.assetType,
				assetCount: decisionTree.assetCount,
				nodeCount: decisionTree.nodeCount,
				publishState: decisionTree.publishState,
			};
			reactFlowDecisionTrees = [
				...reactFlowDecisionTrees,
				reactFlowDecisionTree,
			];
		});

		yield put(getRehabTreesResolved(reactFlowDecisionTrees));
		//yield put(success('CoF configuration saved'));
	} catch (e) {
		yield put(getRehabTreesRejected());
	}
}
function* watchGetRehabTrees() {
	yield takeLatest(getRehabTrees, getRehabTreesSaga);
}

function* getRehabResultSaga(action: AnyAction) {
	try {
		const query = `?sort=LAST_RUN&sortDescending=true&filters=AllHistory%257Ctrue,ASSET_ID%257C${action.payload.assetId}`;
		const treeId: string = action.payload.treeId;
		// quick tmp fix - will do via custom header eventually
		const payload: AxiosResponse<ResolvedResponse> = yield retry(
			5,
			15000,
			getRehabResultApi,
			treeId,
			query,
		);
		const rows = payload.data.data as Row[];
		const data = {
			...payload.data,
			treeId: action.payload.treeId,
			data: rows.map(rehabResult => {
				if (rehabResult.ASSET_ID.indexOf('#') > 0) {
					return {
						...rehabResult,
						ASSET_ID: rehabResult.ASSET_ID.split('#')[1],
					};
				} else {
					return rehabResult;
				}
			}),
		};
		yield put(getRehabResultResolved(data));
	} catch (e) {
		const { response }: any = e;
		yield put(
			getRehabResultRejected({
				code: response?.status ?? -1,
				text: response?.statusText ?? 'No additional information',
			}),
		);
	}
}
function* watchGetRehabResult() {
	yield takeEvery([getRehabResult], getRehabResultSaga);
}

function* toggleRehabTreePublishStateSaga(action: AnyAction) {
	try {
		const { treeId, newPublishState, cb } = action.payload;
		yield retry(
			5,
			15000,
			toggleRehabTreePublishStateApi,
			treeId,
			newPublishState,
		);
		yield put(toggleRehabTreePublishStateResolved());
		if (cb && typeof cb === 'function') {
			cb();
		}
	} catch (err) {
		const { response }: any = err;
		yield put(
			toggleRehabTreePublishStateRejected({
				code: response?.status ?? -1,
				text: response?.statusText ?? 'No additional information',
			}),
		);
		yield put(error(t('Failed to update Rehab Tree state.')));
	}
}

function* watchToggleRehabTreePublishState() {
	yield takeEvery(
		toggleRehabTreePublishState,
		toggleRehabTreePublishStateSaga,
	);
}

export default [
	watchGetRehabTrees(),
	watchGetRehabResult(),
	watchToggleRehabTreePublishState(),
];
