import {
	Circle,
	CircleOutline,
	DisplayTypeWrapper,
	Line,
	Polygon,
	SymbolWrapper,
} from './DisplayType.styles';
import React, { VFC } from 'react';

export type IconType = 'line' | 'polygon' | 'circle' | 'image' | 'none';

type Props = {
	type: IconType;
	color: string | undefined;
	gradient: string | undefined;
	pattern: string | undefined;
	lineDashArray: number[] | undefined;
	iconImage: string | undefined;
	outlineColor: string | undefined;
	fillOpacity: number | undefined;
};

export const DisplayType: VFC<Props> = ({
	type,
	color,
	gradient,
	pattern,
	lineDashArray,
	iconImage,
	outlineColor,
	fillOpacity,
}) => (
	<DisplayTypeWrapper data-cy="display-type">
		{type === 'line' && (
			<Line
				color={color}
				gradient={gradient}
				pattern={pattern}
				lineDashArray={lineDashArray}
				withIcon={!!iconImage}
				data-cy="layer-color">
				<span />
				<span className="break" />
				<span />
				<span className="break" />
				<span />
			</Line>
		)}
		{type === 'polygon' && (
			<Polygon
				color={color}
				gradient={gradient}
				borderColor={outlineColor}
				fillOpacity={fillOpacity}
				pattern={pattern}
				data-cy="layer-color"
			/>
		)}
		{color && type === 'circle' && !iconImage && (
			<Circle color={color} gradient={gradient} data-cy="layer-color" />
		)}
		{!color && type === 'circle' && !iconImage && (
			<CircleOutline data-cy="layer-color" />
		)}
		{iconImage && (
			<SymbolWrapper data-cy="layer-icon" color={color}>
				<img src={iconImage} />
			</SymbolWrapper>
		)}
	</DisplayTypeWrapper>
);
