import { CompositeLayerProps, Filter } from '../types';

import LayerBase from './LayerBase';

export default class MainLayer extends LayerBase {
	protected _selectedItems: string[] = [];

	constructor(layerInfo: CompositeLayerProps, map: mapboxgl.Map) {
		super(layerInfo, map, { zIndexesPerType: { line: -4, fill: -5 } });
	}

	set selectedItems(items: string[]) {
		const removed = this._selectedItems.filter(
			item => !items.includes(item),
		);
		this._stateDeselect(removed);
		this._selectedItems = items;
		this._stateSelect();
	}

	static getFilter(
		compositeProps: CompositeLayerProps['properties'],
		hasClustering: boolean,
		filter: Filter | undefined,
	): Filter | undefined {
		if (filter) return filter;
		const properties = Object.entries(compositeProps ?? {});
		if (properties.length) {
			const propsFilter: Filter[] = ['all'];
			properties.forEach(([key, value]) => {
				propsFilter.push(MainLayer._mapValueToFilter(key, value));
			});
			if (hasClustering) {
				return ['all', ['!', ['has', 'point_count']], propsFilter];
			}
			return propsFilter;
		} else if (hasClustering) {
			return ['!', ['has', 'point_count']];
		}
		return undefined;
	}

	static _mapValueToFilter(
		key: string,
		value: string | string[] | [number, number],
	): Filter {
		if (Array.isArray(value)) {
			if (typeof value[0] === 'number' && typeof value[1] === 'number') {
				// property would be between two values
				const min = ['>=', ['get', key], value[0]];
				const max = ['<=', ['get', key], value[1]];
				return ['all', min, max];
			} else {
				// property would be in array
				return ['in', key, ...value];
			}
		} else {
			// property would exactly match value
			return ['==', ['get', key], value];
		}
	}

	protected _getFilter = (): Filter | undefined => {
		return MainLayer.getFilter(
			this.properties,
			this.hasClustering,
			this.filter,
		);
	};

	protected _resetState = (): void => {
		this._stateSelect();
	};

	protected _stateSelect(): void {
		this._selectedItems.forEach(item => {
			this.setState(item, { selected: true });
		});
	}

	protected _stateDeselect(items: string[]): void {
		items.forEach(item => {
			this.setState(item, { selected: false });
		});
	}

	protected setState(id: string, state = {}): void {
		this._mapboxUtils.setFeatureState(
			{
				source: this.source,
				sourceLayer: this.sourceLayer,
				id,
			},
			{
				...state,
			},
		);
	}
}
