import { MapSelectedIds, selectedPosition } from '@Utils/mapSelections.utils';
import { getMapSelected, getMapSelectedIds } from './map.selectors';

import { zoomSelectionDefault, zoomSelectionPoint } from '@Utils';

import { MapSelectedAssets } from '@innovyze/inno-map';
import { NamespacedStoreState } from '@Types/store.types';
import { createSelector } from '@reduxjs/toolkit';
import { moduleNamespace } from '@Store/config.store';
import { useSelector } from 'react-redux';
import { AssetLocation } from '@Types';

const parseNamespace = (state: NamespacedStoreState) => state[moduleNamespace];

const assetDetailSelector = createSelector(
	parseNamespace,
	state => state.assetDetails,
);

const assetCountsSelector = createSelector(
	assetDetailSelector,
	state => state?.assetCounts,
);

const isAssetCountsReadySelector = createSelector(
	assetDetailSelector,
	state => state?.isAssetCountsReady,
);

const assetDetailIsWaitingSelector = createSelector(
	parseNamespace,
	state => state.assetDetails?.isWaiting,
);

const assetDetailNotesIsWaitingSelector = createSelector(
	parseNamespace,
	state => state.assetDetails?.notesIsWaiting,
);

const getAssetDetailDetailSelector = createSelector(
	parseNamespace,
	state => state.assetDetails?.assetDetails,
);

const getDetailId = createSelector(
	assetDetailSelector,
	details =>
		details?.assetDetails?.find(a => a.FieldName === '_id')?.FieldValue ||
		undefined,
);

const getDetailType = createSelector(
	assetDetailSelector,
	details =>
		details?.assetDetails?.find(a => a.FieldName === 'assetType')
			?.FieldValue || undefined,
);

const getDetailAssetId = createSelector(
	assetDetailSelector,
	details =>
		details?.assetDetails?.find(a => a.FieldName === 'assetId')
			?.FieldValue || undefined,
);

const getDetailSelectedMapIds = createSelector(
	getDetailId,
	getMapSelectedIds,
	(detailId: string | undefined, mapIds: MapSelectedIds[]) =>
		mapIds.filter(m => m.id === detailId),
);

const getDetailMapZoom = createSelector(getDetailType, detailType =>
	detailType === 'wwManhole' ? zoomSelectionPoint : zoomSelectionDefault,
);

const getMapSelectedPosition = createSelector(
	getDetailMapZoom,
	getMapSelected,
	(zoom: number, selected: MapSelectedAssets) =>
		selectedPosition(selected, zoom),
);

const ValidAssetIdSelector = createSelector(
	assetDetailSelector,
	state => state?.validAssetIdsList,
);

const ValidatingAssetsSelector = createSelector(
	assetDetailSelector,
	state => state?.validatingAssets,
);

export const SelectedAssetLocation = createSelector(
	assetDetailSelector,
	state => state?.assetLocation,
);

export const SelectedAssetLocationIsWaiting = createSelector(
	assetDetailSelector,
	state => state?.assetLocationIsWaiting,
);

export const selectAssetDetail = () => useSelector(assetDetailSelector);
export const selectAssetDetailDetail = () =>
	useSelector(getAssetDetailDetailSelector);
export const selectAssetDetailIsWaiting = () =>
	useSelector(assetDetailIsWaitingSelector);
export const selectAssetCounts = () => useSelector(assetCountsSelector);
export const selectIsAssetCountsReady = () =>
	useSelector(isAssetCountsReadySelector);
export const selectAssetDetailNotesIsWaiting = () =>
	useSelector(assetDetailNotesIsWaitingSelector);
export const selectAssetDetailId = () => useSelector(getDetailId);
export const selectAssetDetailType = () => useSelector(getDetailType);
export const selectAssetDetailAssetId = () => useSelector(getDetailAssetId);
export const selectDetailSelectedMapIds = () =>
	useSelector(getDetailSelectedMapIds);
export const selectDetailMapZoom = () => useSelector(getDetailMapZoom);
export const selectMapSelectedPosition = () =>
	useSelector(getMapSelectedPosition);

export const selectValidAssetIds = () => useSelector(ValidAssetIdSelector);

export const selectValidatingAssets = () =>
	useSelector(ValidatingAssetsSelector);

export const useSelectAssetLocation = (): AssetLocation | undefined =>
	useSelector(SelectedAssetLocation);

export const useSelectAssetLocationIsWaiting = (): boolean | undefined =>
	useSelector(SelectedAssetLocationIsWaiting);

export default {
	selectAssetDetail,
	selectAssetDetailDetail,
	selectAssetDetailIsWaiting,
	selectAssetDetailNotesIsWaiting,
	selectAssetDetailId,
	selectAssetDetailType,
	selectDetailSelectedMapIds,
	selectDetailMapZoom,
	selectMapSelectedPosition,
	selectValidAssetIds,
	selectValidatingAssets,
	useSelectAssetLocation,
	useSelectAssetLocationIsWaiting,
};
