import {
	Autocomplete,
	AutocompleteRenderInputParams,
	TextField,
} from '@mui/material';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { StylovyzeDialog, Workspace } from '@innovyze/stylovyze';

import { StyledSubText } from '@Components/AddToIncidentDialog/AddToIncidentDialog.styles';
import { useGlobalization } from '@Translations/useGlobalization';

export interface AddToWorkspaceDialogProps {
	open: boolean;
	workspaces: Workspace[];
	onCancel: () => void;
	onConfirm: (workspace: Workspace) => void;
	currentWorkspace?: Workspace;
}

export const AddToWorkspaceDialog = (
	props: AddToWorkspaceDialogProps,
): JSX.Element => {
	const { t } = useGlobalization();
	const { workspaces } = props;

	const [
		workspaceSelected,
		setWorkspaceSelected,
	] = useState<Workspace | null>(props.currentWorkspace ?? null);

	const onDialogConfirm = () => {
		if (workspaceSelected) {
			props.onConfirm(workspaceSelected);
		}
		setWorkspaceSelected(props.currentWorkspace ?? null);
	};

	const onDialogCancel = () => {
		setWorkspaceSelected(props.currentWorkspace ?? null);
		props.onCancel();
	};

	const autoCompleteChanged = (
		_event: SyntheticEvent<Element, Event>,
		values: Workspace | null,
	) => {
		setWorkspaceSelected(values);
	};

	useEffect(() => {
		if (!workspaceSelected && props.currentWorkspace) {
			setWorkspaceSelected(props.currentWorkspace);
		}
	}, [props.currentWorkspace]);

	return (
		<StylovyzeDialog
			data-cy="add-chart-to-workspace-dialog"
			title={t('Add chart to Workspace', {
				context: 'Add chart to workspace dialog title',
			})}
			open={props.open}
			cancelText={t('Cancel', {
				context: 'Add chart to workspace dialog cancel button',
			})}
			onCancel={onDialogCancel}
			confirmText={t('Add to Workspace', {
				context: 'Add chart to workspace dialog confirm button',
			})}
			onConfirm={onDialogConfirm}
			confirmButtonProps={{
				disabled: !workspaceSelected,
			}}>
			<div>
				<Autocomplete
					id="workspace"
					data-cy="workspace"
					color={'primary'}
					options={workspaces}
					value={workspaceSelected ?? null}
					getOptionLabel={(workspace: Workspace) =>
						workspace.name ?? ''
					}
					isOptionEqualToValue={(option, value) =>
						option.name === value.name
					}
					renderInput={(params: AutocompleteRenderInputParams) => {
						return (
							<div>
								<TextField
									{...params}
									color={'primary'}
									name={'workspace-option'}
									label={t('Select a Workspace', {
										context:
											'Add chart to workspace dialog search box label',
									})}
									margin={'normal'}
									variant="outlined"
								/>
							</div>
						);
					}}
					fullWidth
					onChange={autoCompleteChanged}
				/>
				<StyledSubText>
					{t('The chart will be added to the selected workspace.', {
						context: 'Add chart to workspace dialog action outcome',
					})}
				</StyledSubText>
			</div>
		</StylovyzeDialog>
	);
};

AddToWorkspaceDialog.defaultProps = {};

export default AddToWorkspaceDialog;
