import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = (
	height: number,
	fullHeight: number,
	isExpanded: boolean,
	needShowMore: boolean,
) => {
	const additionalPadding = needShowMore ? 50 : 0;

	return makeStyles(() =>
		createStyles({
			root: {
				height:
					isExpanded || !needShowMore
						? `${fullHeight + additionalPadding}px`
						: `${height}px`,
				width: '100%',
				overflow: 'hidden',
				position: 'relative',
				transition: 'height 378ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
			},
			whiteGradient: {
				position: 'absolute',
				bottom: '32px',
				width: '100%',
				height: '100px',
				background:
					'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 111.75%, rgba(196, 196, 196, 0) 111.76%, rgba(255, 255, 255, 0) 111.76%)',
				transform: 'rotate(180deg)',
			},
			buttonDiv: {
				width: '100%',
				background: 'white',
				position: 'absolute',
				bottom: 0,
			},
			showMoreButton: {
				lineHeight: '20px',
				// position: 'absolute',
				// bottom: 0,
				// right: 0,
				// background: 'white',
				// '&:hover': {
				// 	background: 'white',
				// },
			},
			displayNone: {
				display: 'none',
			},
		}),
	);
};
