import * as Chart from './chart';
import * as React from 'react';

export interface RootProps {
  children: React.ReactNode;
}

export function Root(props: RootProps): React.ReactElement {
  return <Chart.Root>{props.children}</Chart.Root>;
}
