import * as React from 'react';
import { createChart } from '../../core/_summaryze-chart';
import Highcharts from 'highcharts';
import Highstock from 'highcharts/highstock';
import type { ChartInstanceRef } from '../../core/_summaryze-chart';
import { useGlobalization } from '../../../i18n/useGlobalization';
import { useSettings, getBenchmarkProgress } from '@innovyze/stylovyze';
import { goalChartInstanceInitialOptions } from './goal-chart-options';
import { GoalChartOptions } from './goal-chart-types';
import {
  DateTimePreferences,
  calculateGoalChartProgressBar,
  createDownArrow,
  createRectangle,
  createTooltip,
  createUpArrow,
  makeGoalChartBottomSection,
  makeGoalChartHeaderSection,
  updateTooltipText,
  getKPIProgress,
} from './goal-chart.utils';
import _ from 'lodash';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Chart
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const Chart = createChart('GoalChart');

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Root Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

type GoalChartProps = GoalChartOptions;

const GoalChartRoot = React.forwardRef<ChartInstanceRef, GoalChartProps>(
  (props, ref): React.ReactElement => {
    return (
      <Chart.ChartRoot>
        <Chart.ChartInstance
          ref={ref}
          initialOptions={goalChartInstanceInitialOptions}
          constructorFunction={Highstock.stockChart}>
          <GoalChart {...props} />
        </Chart.ChartInstance>
      </Chart.ChartRoot>
    );
  }
);

GoalChartRoot.displayName = 'GoalChartRoot';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Chart Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const GoalChart = (props: GoalChartProps): null => {
  const { t } = useGlobalization();
  const { companySettings } = useSettings();

  const dateTimePreferences: DateTimePreferences = React.useMemo(() => {
    const timeZone = companySettings.timeZoneIANA ?? 'Etc/UTC';
    const _dateFormat = companySettings.dateFormat ?? 'MM/DD/YYYY';
    const _hourCycle12 = companySettings.hourCycle12 ?? true;
    const dateFormat = _dateFormat.replace('DD', 'dd').replace('YYYY', 'yyyy');
    const timeFormat = _hourCycle12 ? 'hh:mm a' : 'HH:mm';
    return {
      timeZone: timeZone,
      dateTimeFormat: dateFormat + ' ' + timeFormat,
      dateFormat: dateFormat,
    };
  }, [
    companySettings.dateFormat,
    companySettings.hourCycle12,
    companySettings.timeZoneIANA,
  ]);

  //Bar colors
  const primaryColor = '#73B9D8';
  const secondaryColor = '#CCCCCC';

  //Chart and series
  const instance = Chart.useInstance('GoalChart');
  const sensorSeries = Chart.useSeries(props, 'GoalChartSensorSeries');
  const goalSeries = Chart.useSeries(props, 'GoalChartGoalSeries');

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets progress bar pointers position and size after resize
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  const [graphWidth, setGraphWidth] = React.useState(
    instance.current?.plotWidth ?? 400
  );

  /*******************************Goal Pointer***********************************/

  const goalPointer = React.useMemo(() => {
    let arrow: Highcharts.SVGElement | undefined;
    let rectangle: Highcharts.SVGElement | undefined;
    let tooltip: Highcharts.SVGElement | undefined;
    if (instance.current) {
      tooltip = createTooltip(instance.current, t('Limit') + ' value u');
      arrow = createDownArrow(
        instance.current,
        () => tooltip?.show(),
        () => tooltip?.hide()
      );
      rectangle = createRectangle(
        instance.current,
        () => tooltip?.show(),
        () => tooltip?.hide()
      );
    }
    return { arrow, rectangle, tooltip };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance.current]);

  const benchmarkPointer = React.useMemo(() => {
    let arrow: Highcharts.SVGElement | undefined;
    let rectangle: Highcharts.SVGElement | undefined;
    let tooltip: Highcharts.SVGElement | undefined;
    if (instance.current) {
      tooltip = createTooltip(instance.current, t('Benchmark') + ' value u');
      arrow = createUpArrow(
        instance.current,
        () => tooltip?.show(),
        () => tooltip?.hide()
      );
      rectangle = createRectangle(
        instance.current,
        () => tooltip?.show(),
        () => tooltip?.hide()
      );
    }
    return { arrow, rectangle, tooltip };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance.current]);

  const redraw = React.useCallback(() => {
    const { goalProgress, benchmarkProgress } = calculateGoalChartProgressBar(
      props.series.sensor?.value,
      props.series.goal?.value,
      props.series.benchmark?.value
    );

    /***Capture chart new coords ****/
    const plotWidth = instance.current?.plotWidth ?? 0;
    const plotHeight = instance.current?.plotHeight ?? 0;
    const plotTopOffset = instance.current?.plotTop ?? 0;
    const barHeight =
      (sensorSeries.current as unknown as { barW: number })?.barW ?? 30;
    setGraphWidth(plotWidth);

    /***X Coords ****/
    const leftOffset = 5;
    const xPositionGoal = plotWidth * ((goalProgress ?? 0) / 100);
    const xPositionBenchmark = plotWidth * ((benchmarkProgress ?? 0) / 100);

    /***Y Coords ****/
    const yPosition = plotTopOffset + (plotHeight + barHeight) / 2 - 48;
    const yPositionDownArrow = plotTopOffset + (plotHeight - barHeight) / 2 - 5;
    const yPositionUpArrow = plotTopOffset + (plotHeight + barHeight) / 2 - 48;

    /***Update Pointers Position after resize ****/
    goalPointer.arrow
      ?.translate(xPositionGoal - 4.85, yPositionDownArrow)
      .add();
    goalPointer.rectangle
      ?.translate(leftOffset + xPositionGoal, yPosition)
      .attr({ width: barHeight, rotation: -90 })
      .add();
    goalPointer.tooltip
      ?.attr({
        x: leftOffset + xPositionGoal - 35,
        y: yPosition - barHeight,
      })
      .add()
      .hide();

    const goalLabel =
      props.series.goal.goalType === 'reach-or-above' ? t('Goal') : t('Limit');
    const goalTooltipText =
      goalLabel + ' ' + props.series.goal.value + ' ' + props.series.goal.unit;
    updateTooltipText(goalPointer.tooltip, goalTooltipText);

    benchmarkPointer.arrow
      ?.translate(leftOffset + xPositionBenchmark, yPositionUpArrow)
      .add();
    benchmarkPointer.rectangle
      ?.translate(leftOffset + xPositionBenchmark, yPosition)
      .attr({ width: barHeight, rotation: -90 })
      .add();
    benchmarkPointer.tooltip
      ?.attr({
        x: leftOffset + xPositionBenchmark - 55,
        y: yPosition + 50,
      })
      .add()
      .hide();

    const benchmarkLabel = t('Benchmark');
    const benchmarkTooltipText =
      benchmarkLabel +
      ' ' +
      props.series.benchmark?.value +
      ' ' +
      props.series.benchmark?.unit;
    updateTooltipText(benchmarkPointer.tooltip, benchmarkTooltipText);

    if (props.series.benchmark?.value && !props.root.isDummy) {
      benchmarkPointer.arrow?.show();
      benchmarkPointer.rectangle?.show();
    } else {
      benchmarkPointer.arrow?.hide();
      benchmarkPointer.rectangle?.hide();
    }
  }, [
    props.series.sensor?.value,
    props.series.goal.value,
    props.series.goal.goalType,
    props.series.goal.unit,
    props.series.benchmark?.value,
    props.series.benchmark?.unit,
    props.root.isDummy,
    instance,
    sensorSeries,
    goalPointer.arrow,
    goalPointer.rectangle,
    goalPointer.tooltip,
    t,
    benchmarkPointer.arrow,
    benchmarkPointer.rectangle,
    benchmarkPointer.tooltip,
  ]);

  const [isResizing, setIsResizing] = React.useState(false);
  const [isInitialized, setIsInitialized] = React.useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceResizeEvent = React.useCallback(
    _.debounce(() => {
      setIsResizing(true);
    }, 100),
    []
  );

  React.useEffect(() => {
    window.addEventListener('resize', () => {
      debounceResizeEvent();
    });
  }, [debounceResizeEvent]);

  React.useEffect(() => {
    if (isResizing) {
      redraw();
      setIsResizing(false);
    }
  }, [isResizing, redraw]);

  React.useEffect(() => {
    if (props.series.benchmark === undefined) {
      benchmarkPointer.arrow?.hide();
      benchmarkPointer.rectangle?.hide();
    } else {
      benchmarkPointer.arrow?.show();
      benchmarkPointer.rectangle?.show();
    }
  }, [
    benchmarkPointer.arrow,
    benchmarkPointer.rectangle,
    props.series.benchmark,
  ]);

  React.useEffect(() => {
    if (
      props.root.status !== 'resolved' ||
      props.root.isDummy ||
      (props.series.benchmark?.value === undefined &&
        props.series.goal.value === undefined)
    ) {
      goalPointer.arrow?.hide();
      goalPointer.rectangle?.hide();
      benchmarkPointer.arrow?.hide();
      benchmarkPointer.rectangle?.hide();
    } else {
      goalPointer.arrow?.show();
      goalPointer.rectangle?.show();
      if (props.series.benchmark) {
        benchmarkPointer.arrow?.show();
        benchmarkPointer.rectangle?.show();
      }
    }
  }, [
    benchmarkPointer.arrow,
    benchmarkPointer.rectangle,
    goalPointer.arrow,
    goalPointer.rectangle,
    props.root.status,
    props.root.isDummy,
    props.series.benchmark,
    props.series.goal,
  ]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets Goal Chart header section
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    if (props.root.status !== 'resolved') {
      instance.current?.update({ title: undefined });
      return;
    }
    const { value, date, unit, displayOnlyDate } = props.series.sensor;
    instance.current?.update({
      title: makeGoalChartHeaderSection(
        {
          label: t('Last Value'),
          value: value === undefined ? '--' : value + ' ' + unit,
          displayOnlyDate: displayOnlyDate,
          date: props.root.isDummy
            ? t('Last value timestamp')
            : date ?? new Date(),
        },
        dateTimePreferences
      ),
    });
  }, [
    instance,
    props.root.status,
    props.root.isDummy,
    props.series.sensor,
    t,
    dateTimePreferences,
    props.series.goal,
  ]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets Goal Chart bottom section
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    if (props.root.status !== 'resolved') {
      instance.current?.update({ subtitle: undefined });
      return;
    }
    const { goal, benchmark, sensor } = props.series;
    const showDummyGoalPlaceholder =
      props.root.isDummy || goal.percentage === undefined;
    const showDummyBenchmarkPlaceholder =
      props.root.isDummy || benchmark?.percentage === undefined;

    const dateRange = props.root.isDummy
      ? {
          startDate: t('Time range start'),
          endDate: t('Time range end'),
        }
      : props.root.dateRange;

    let benchmarkValue = t('N/A');
    if (benchmark !== undefined) {
      benchmarkValue = '--';
      if (benchmark.value !== undefined && benchmark.value !== null) {
        benchmarkValue = benchmark.value + ' ' + benchmark.unit;
      }
    }
    instance.current?.update({
      subtitle: makeGoalChartBottomSection(
        graphWidth + 'px',
        dateRange,
        dateTimePreferences,
        {
          label: props.root.isLimit ? t('Limit Value') : t('Goal Value'),
          value: goal.value !== undefined ? goal.value + ' ' + goal.unit : '--',
          progressLabel: t('Progress'),
          progressValue: getKPIProgress({
            input: sensor.value,
            kpiValue: goal.value,
            t,
            isLimit: props.root.isLimit,
            showDummy: props.root.isDummy,
          }),
          isProgressHidden: props.root.showGoalProgress === false,
          isDummy: showDummyGoalPlaceholder,
        },
        {
          label: t('Benchmark Value'),
          value: benchmarkValue,
          progressLabel: t('Performance vs benchmark'),
          progressValue: getBenchmarkProgress({
            input: sensor.value,
            benchmark: benchmark?.value,
            t,
            showDummy: props.root.isDummy,
          }),
          isNA: benchmark === undefined,
          isProgressHidden: props.root.showBenchmarkProgress === false,
          isDummy: showDummyBenchmarkPlaceholder,
        }
      ),
    });
  }, [instance, graphWidth, props.series, props.root, dateTimePreferences, t]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets progress bar series data
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    goalSeries.current?.update({
      name: 'Goal',
      data: [100],
      color: secondaryColor,
      grouping: false,
      zIndex: 0,
    } as Highcharts.SeriesOptionsType);

    if (!props.root.isDummy) {
      const { sensorProgress } = calculateGoalChartProgressBar(
        props.series.sensor.value,
        props.series.goal.value,
        props.series.benchmark?.value
      );

      sensorSeries.current?.update({
        name: 'Sensor',
        data: [sensorProgress],
        color: primaryColor,
        grouping: false,
        zIndex: 1,
      } as Highcharts.SeriesOptionsType);
    }
  }, [
    sensorSeries,
    goalSeries,
    props.series.sensor.value,
    props.series.goal.value,
    props.series.benchmark?.value,
    props.root.isDummy,
  ]);

  React.useEffect(() => {
    if (props.root.status === 'resolved' && !isInitialized) {
      redraw();
      setIsInitialized(true);
    }
  }, [redraw, props.root.status, isInitialized]);

  React.useEffect(() => {
    if (props.root.status === 'resolved') {
      redraw();
    }
  }, [redraw, props.root.status]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets status
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    if (props.root.status === 'loading') {
      instance.current?.showLoading();
    } else if (props.root.status === 'rejected') {
      instance.current?.showLoading(t('No Data'));
    } else {
      instance.current?.hideLoading();
    }
  }, [instance, props.root.status, t]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  return null;
};

export { GoalChartRoot };

export type { GoalChartProps, ChartInstanceRef };
