import { Lock, LockOpen } from '@mui/icons-material';
import { CircularProgress, Grid } from '@mui/material';
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	HTMLInputElementExtended,
	HTMLTextAreaElementExtended,
	InfoCard,
	InputText,
	InputUnit,
	StylovyzeForm,
	useCompanyDateTime,
	useIsFeatureEnabled,
	useSettings,
	useUnits,
} from '@innovyze/stylovyze';
import React, { ChangeEvent, useEffect } from 'react';
import { ReactFlowDecisionTree, ReactFlowSchema } from '@Types/rehab.types';
import { Tab, TabPanel, TabWrapper, Tabs } from '@Components/Tabs';
import { getCostCalculation, getCurrency } from '@Utils/currency';
import { getRehabResult, getRehabTrees } from '@Actions/Rehab.action';
import {
	selectHasValidTree,
	selectIsTreesLoading,
	selectRehabResults,
	selectRehabTrees,
} from '@Selectors/rehab.selectors';

import { DecisionPath } from './DecisionPath';
import { NoDetailsRehab } from './NoDetailsRehab.component';
import { PreviouslyRun } from './PreviouslyRun/PerviouslyRun.component';
import { RehabSummary } from './RehabSummary';
import { Row } from '@Types/pagination.types';
import { useDispatch } from 'react-redux';
import { useGlobalization } from '@Translations';
import { formatDateTimeWithFormatDate } from '@Utils';

const findPath = (treeNodes: ReactFlowSchema[], leafNodeId: string) => {
	const leafNode = treeNodes.find(node => node.id == leafNodeId);
	if (leafNode == undefined) return [];

	const path: ReactFlowSchema[] = [leafNode];
	do {
		const parentNode = treeNodes.find(
			node => node.id == path[path.length - 1].parentId,
		);
		if (parentNode == undefined) break;
		path.push(parentNode);
	} while (path[path.length - 1].parentId != null);

	const pathObj = [];
	if (path.length > 0) {
		for (let i = 0; i < path.length; i++) {
			pathObj.push({
				node: i + 1,
				nodeName: path[path.length - 1 - i].data?.label,
				nodeType:
					path[path.length - 1 - i].type == 'innoInput'
						? 'Query'
						: 'Action',
				result:
					path[path.length - 1 - i].type == 'innoInput'
						? path[path.length - 2 - i].connectorCondition
						: '',
			});
		}
	}
	return pathObj;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const DetailsRehab = ({
	adminFullAccess,
	assetId,
}: {
	adminFullAccess: boolean;
	assetId: string;
}): JSX.Element => {
	const { t } = useGlobalization();
	const dispatch = useDispatch();
	const { formatDate } = useCompanyDateTime();
	const { companySettings } = useSettings();
	const isRehabPublishTreeFeatureEnabled = useIsFeatureEnabled(
		'info360-publish-rehab-trees',
	);

	useEffect(() => {
		dispatch(getRehabTrees());
	}, []);

	const { system } = useUnits();
	const rehabTrees = selectRehabTrees();
	const isTreeLoading = selectIsTreesLoading();
	const hasValidTree = selectHasValidTree();

	const filterPublishedRehabTrees = (value: ReactFlowDecisionTree) => {
		if (isRehabPublishTreeFeatureEnabled) {
			return value.publishState === 'published';
		}
		return true;
	};

	useEffect(() => {
		if (rehabTrees != undefined && rehabTrees.length > 0) {
			rehabTrees?.filter(filterPublishedRehabTrees).map(tree => {
				if (tree._id && tree.lastRun != undefined) {
					dispatch(
						getRehabResult({
							treeId: tree._id,
							assetId: assetId,
						}),
					);
				}
			});
		}
	}, [rehabTrees]);

	const rehabResults = selectRehabResults();

	const rehabActionFields: { name: string; alias: string }[] = [
		{ name: 'name', alias: t('Decision Tree Name') },
		{ name: 'runBy', alias: t('Run By') },
		{ name: 'runCompleted', alias: t('Run Completed') },
		{ name: 'finalAction', alias: t('Final Action') },
		{ name: 'draftAction', alias: t('Draft Action') },
		{ name: 'actionOverrideBy', alias: t('Action Override By') },
		{ name: 'totalCost', alias: t('Total Cost') },
		{ name: 'costCalculation', alias: t('Cost Calculation') },
		{ name: 'defectCodes', alias: t('Defect Codes') },
	];

	// TODO: NOT SURE IF THIS IS CORRECT YET
	const hardCodeUnitFields: { [key: string]: string } = {
		// totalCost: '$',
	};

	const [activeTab, setActiveTab] = React.useState(0);
	const handleChange = (event: unknown, newTab: number) => {
		setActiveTab(newTab);
	};

	const rehabTabs = (rehab: ReactFlowDecisionTree, index: number) => {
		return (
			<Tab
				key={rehab._id ?? rehab.name ?? index}
				label={
					<Grid container>
						<Grid
							item
							xs={11}
							style={{
								textAlign: 'start',
								alignItems: 'center',
							}}>
							{rehab.name}
						</Grid>
						<Grid item xs={1}>
							{rehabResults[rehab._id].rows[0].LOCKED != '0' ? (
								<Lock style={{ color: '#768D95' }} />
							) : (
								<LockOpen style={{ color: '#768D95' }} />
							)}
						</Grid>
					</Grid>
				}
				id={`vertical-tab-${index}`}
				aria-controls={`vertical-tabpanel-${index}`}
			/>
		);
	};

	const getHistory = (rehab: Row[], tree: ReactFlowDecisionTree) => {
		return rehab.map(run => ({
			LAST_MODIFIED: run.LAST_MODIFIED,
			LOCKED: run.LOCKED,
			event: run.EVENT, //'Decision Tree Complete',
			user: run.MODIFIED_BY ?? tree.runBy,
			action: run.FINAL_RRHAB_ACTION,
			totalCost: getCurrency(+run.TOTAL_COST, companySettings),
			calculation:
				system == 'Metric'
					? getCostCalculation(
							run.COST_CALCULATION_METER,
							companySettings,
					  )
					: getCostCalculation(
							run.COST_CALCULATION_FT,
							companySettings,
					  ),
		}));
	};

	const getGrids = (fields: { name: string; alias: string }[]) => {
		if (fields && fields.length > 0) {
			return fields.map(field => {
				if (hardCodeUnitFields[field.name]) {
					return (
						<Grid item xs={4} key={field.name}>
							<InputUnit
								fieldAttrs={{ name: field.name }}
								unitField={{
									label: field.alias,
									unit: hardCodeUnitFields[field.name],
									defaultValue: '',
									onChange: (
										e: ChangeEvent<
											| HTMLInputElementExtended
											| HTMLTextAreaElementExtended
										>,
									) => {
										console.log('onChange', e.target.value);
									},
								}}
								invalidDisplayValue={'-'}
							/>
						</Grid>
					);
				} else {
					return (
						<Grid item xs={4} key={field.name}>
							<InputText
								fieldAttrs={{ name: field.name }}
								textField={{
									label: field.alias,
								}}
							/>
						</Grid>
					);
				}
			});
		}

		return <></>;
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const rehabAction = (rehab: any) => {
		const rehabResult =
			rehabResults[rehab._id] && rehabResults[rehab._id].rows.length > 0
				? rehabResults[rehab._id].rows[0]
				: null;
		if (rehabResult == null) return <CircularProgress />;

		const action = {
			name: rehab.name ?? '',
			runBy: rehab.runBy ?? '',
			runCompleted: formatDateTimeWithFormatDate(
				rehab.lastRun ?? '',
				formatDate,
			),
			finalAction: rehabResult.FINAL_RRHAB_ACTION,
			draftAction: rehabResult.DRAFT_RRHAB_ACTION,
			actionOverrideBy: rehabResult.MODIFIED_BY,
			totalCost: getCurrency(+rehabResult.TOTAL_COST, companySettings),
			costCalculation:
				system == 'Metric'
					? getCostCalculation(
							rehabResult.COST_CALCULATION_METER,
							companySettings,
					  )
					: getCostCalculation(
							rehabResult.COST_CALCULATION_FT,
							companySettings,
					  ),
			defectCodes: rehabResult.DEFECT_CODES,
		};

		return (
			<StylovyzeForm
				mode="view"
				initialValues={action}
				onSubmit={data => {
					console.log('should update the data', data);
				}}>
				{() => {
					return <Grid container>{getGrids(rehabActionFields)}</Grid>;
				}}
			</StylovyzeForm>
		);
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const rehabTab = (rehab: any) => {
		// const rehabResult =
		// 	rehabResults[rehab._id] && rehabResults[rehab._id].rows.length > 0
		// 		? rehabResults[rehab._id].rows[0]
		// 		: null;
		return (
			<>
				<InfoCard fullHeight title={t('Rehab Action')}>
					{rehabAction(rehab)}
				</InfoCard>
				<DecisionPath
					rehabDecision={findPath(
						rehab.nodes,
						rehabResults[rehab._id].rows[0].ACTION_NODE_ID,
					)}
				/>
				{rehabResults[rehab._id].rows ? (
					<PreviouslyRun
						rehab={getHistory(rehabResults[rehab._id].rows, rehab)}
					/>
				) : (
					<></>
				)}
			</>
		);
	};

	const rehabTabPanels = (rehab: ReactFlowDecisionTree, index: number) => {
		return (
			<TabPanel
				key={rehab._id ?? rehab.name ?? index}
				value={activeTab}
				index={index}>
				{rehabTab(rehab)}
			</TabPanel>
		);
	};

	return (
		<Grid container spacing={3}>
			{isTreeLoading ? (
				<Grid item xs={12}>
					<InfoCard title={t('Rehab Details')} fullHeight>
						<CircularProgress />
					</InfoCard>
				</Grid>
			) : hasValidTree ? (
				<>
					<Grid item xs={12}>
						<InfoCard title={t('Rehab Details')} fullHeight>
							<Grid container>
								<Grid item xs={12}>
									<TabWrapper>
										<Tabs
											orientation="vertical"
											variant="scrollable"
											value={activeTab}
											onChange={handleChange}
											aria-label="rehab-tabs">
											{rehabTrees
												.filter(
													filterPublishedRehabTrees,
												)
												.filter(
													tree =>
														rehabResults[
															tree._id
														] &&
														rehabResults[tree._id]
															.rows.length > 0,
												)
												.map((tree, index) =>
													rehabTabs(tree, index),
												)}
										</Tabs>
										{rehabTrees
											.filter(filterPublishedRehabTrees)
											.filter(
												tree =>
													rehabResults[tree._id] &&
													rehabResults[tree._id].rows
														.length > 0,
											)
											.map((tree, index) =>
												rehabTabPanels(tree, index),
											)}
									</TabWrapper>
								</Grid>
							</Grid>
						</InfoCard>
					</Grid>
					<Grid item xs={12}>
						<RehabSummary
							rehabTrees={rehabTrees}
							rehabResults={rehabResults}
						/>
					</Grid>
				</>
			) : (
				<Grid item xs={12}>
					<InfoCard title={t('Rehab Details')} fullHeight>
						<NoDetailsRehab adminFullAccess={adminFullAccess} />
					</InfoCard>
				</Grid>
			)}
		</Grid>
	);
};
