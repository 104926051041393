import { addNamespace } from '@Utils/actions';
import { createAction } from '@reduxjs/toolkit';
import {
	GetObservationsParams,
	GetObservationsResolvedResponse,
	CreateObservationParams,
} from '@Types/observation.types';
import { EditObservationParams } from '@Types';

export const getObservations = createAction<GetObservationsParams>(
	addNamespace('observations/getObservations'),
);

export const getObservationsResolved =
	createAction<GetObservationsResolvedResponse>(
		addNamespace('observations/getObservationsResolved'),
	);

export const getObservationsRejected = createAction(
	addNamespace('observations/getObservationsRejected'),
);

export const clearObservations = createAction(
	addNamespace('observations/clearObservations'),
);

export const createObservations = createAction<{
	params: CreateObservationParams;
	history: unknown;
}>(addNamespace('observations/createObservations'));

export const createObservationsResolved = createAction(
	addNamespace('observations/createObservationsResolved'),
);

export const createObservationsRejected = createAction(
	addNamespace('observations/createObservationsRejected'),
);

export const editObservations = createAction<{
	params: EditObservationParams;
	history: unknown;
}>(addNamespace('observations/editObservation'));

export const editObservationsResolved = createAction<EditObservationParams>(
	addNamespace('observations/editObservationResolved'),
);

export const editObservationsRejected = createAction(
	addNamespace('observations/editObservationRejected'),
);

export const deleteObservations = createAction<{
	inspectionId: string;
	observationIds: number[];
}>(addNamespace('observations/deleteObservations'));

export const deleteObservationsResolved = createAction<{
	observationIds: number[];
}>(addNamespace('observations/deleteObservationsResolved'));

export const deleteObservationsRejected = createAction(
	addNamespace('observations/deleteObservationsRejected'),
);

export const setJointOnly = createAction<boolean>(
	addNamespace('observations/setJointOnly'),
);

export const setContinuousOnly = createAction<boolean>(
	addNamespace('observations/setContinuousOnly'),
);

export const setStepOnly = createAction<boolean>(
	addNamespace('observations/setStepOnly'),
);

export const setGradeFilters = createAction<string[]>(
	addNamespace('observations/setGradeFilters'),
);

export const setTypeFilters = createAction<string[]>(
	addNamespace('observations/setTypeFilters'),
);

export const clearFilters = createAction(
	addNamespace('observations/clearFilters'),
);
