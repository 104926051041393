import { all } from 'redux-saga/effects';
import { kpiValueSagas } from './kpiValue.sagas';
import { measureDataSagas } from './measureData';
import { patternMeasureDataSagas } from './PatternMeasureData';
import sensorDataSagas from './sensorData.sagas';

export const sagas = function* rootSaga() {
  yield all([
    ...sensorDataSagas,
    ...measureDataSagas,
    ...patternMeasureDataSagas,
    ...kpiValueSagas,
  ]);
};

export default sagas;
