import {
  DataGridDensity,
  GlobalPassthroughs,
  Readings,
  TableDashletItem,
  TableSortState,
} from '../../../types';

import { DataGridProps } from '@innovyze/stylovyze';
import { StyledDataGrid } from './DataGrid.styles';
import { TFunc, useGlobalization } from '../../../i18n';
import { useCompanyDateTimeFormat } from '../../../hooks';

import React, { useEffect, useMemo, useState } from 'react';
import {
  GridCellSelectionModel,
  GridSortModel,
} from '@mui/x-data-grid-premium';

const dateTimeSeparator = ' - ';
const defaultReadings = [Readings.Close];

const translateReading = (reading: Readings, t: TFunc) => {
  switch (reading) {
    case Readings.Average:
      return t('Average');
    case Readings.Close:
      return t('Close');
    case Readings.High:
      return t('High');
    case Readings.Low:
      return t('Low');
    case Readings.Open:
      return t('Open');
    case Readings.Sum:
      return t('Sum');
    default:
      return reading;
  }
};

export interface DataGridDashletProps extends GlobalPassthroughs {
  hasMultipleSensors: boolean;
  readings?: Readings[];
  loading?: boolean;
  items: TableDashletItem[];
  page?: number;
  onPageChange?: (page: number) => void;
  sort?: TableSortState;
  onSortChange?: (sort?: TableSortState) => void;
  showUnits?: boolean;
  showDateTime?: boolean;
  density?: DataGridDensity;
  conditionalFormatting?: DataGridProps['conditionalFormatting'];
  onSelectionChange?: (model: GridCellSelectionModel) => void;
  columnConditionalFormatting?: DataGridProps['columnFormatting'];
  onColumnSelectedChange?: (column: string) => void;
  showSelection?: boolean;
  showColumnSelection?: boolean;
  showSensorAlias?: boolean;
  clearSelection?: boolean;
}

const DataGridDashlet = (props: DataGridDashletProps): JSX.Element => {
  const { readings = defaultReadings, density, showSelection = false } = props;

  const { t } = useGlobalization();
  const formatDateTime = useCompanyDateTimeFormat({ dateTimeSeparator });

  const [cellSelectionModel, setCellSelectionModel] =
    useState<GridCellSelectionModel>({});
  const [selectedColumn, setSelectedColumn] = React.useState('');

  const handleCellSelectionModelChange = React.useCallback(
    (newModel: GridCellSelectionModel) => {
      setSelectedColumn('');
      setCellSelectionModel(newModel);
      props.onSelectionChange?.(newModel);
    },
    [props.onSelectionChange]
  );

  const handleColumnSelectionModelChange = React.useCallback(
    (column: string) => {
      setCellSelectionModel({});
      setSelectedColumn(column);
      props.onColumnSelectedChange?.(column);
    },
    [props.onColumnSelectedChange]
  );

  const onSortChange = (model: GridSortModel) => {
    if (props.onSortChange) {
      const defaultName = props.showDateTime ? 'date' : readings[0];
      const sort = {
        name: model?.[0]?.field ?? defaultName,
        order: model?.[0]?.sort ?? 'desc',
      };
      props.onSortChange(sort);
    }
  };

  useEffect(() => {
    if (!props.showColumnSelection) {
      setSelectedColumn('');
    }
  }, [props.showColumnSelection]);

  useEffect(() => {
    if (!showSelection) {
      setCellSelectionModel({});
    }
  }, [showSelection]);

  useEffect(() => {
    setCellSelectionModel({});
    setSelectedColumn('');
  }, [props.clearSelection]);

  const headers = useMemo(() => {
    const headers: DataGridProps['columns'] = readings.map((reading) => ({
      field: reading,
      headerName: translateReading(reading, t),
      align: 'left',
      flex: 1,
      sortable: true,
    }));

    if (props.showSensorAlias || props.hasMultipleSensors) {
      // TODO: Check this error
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      headers.unshift({
        field: 'sensorName',
        headerName: t('Sensor Name'),
        align: 'left',
        flex: 1,
        sortable: true,
        disableColumnMenu: true,
      });
    }

    if (props.showDateTime) {
      // TODO: Check this error
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      headers.unshift({
        field: 'date',
        headerName: t('Date/Time'),
        align: 'left',
        flex: 1,
        sortable: true,
        type: 'datetime',
        disableColumnMenu: true,
        renderCell(params) {
          return formatDateTime(params.row.date);
        },
      });
    }

    if (props.showUnits) {
      // TODO: Check this error
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      headers.push({
        field: 'unit',
        headerName: t('Unit'),
        align: 'left',
        flex: 1,
        sortable: true,
        disableColumnMenu: true,
      });
    }

    return headers;
  }, [
    props.hasMultipleSensors,
    props.showUnits,
    props.showDateTime,
    readings,
    props.showSensorAlias,
  ]);

  const initialState = useMemo(() => {
    const defaultSort: TableSortState = {
      name: props.showDateTime ? 'date' : readings[0],
      order: 'desc',
    };
    const { name, order } = props.sort ?? defaultSort;
    return {
      sorting: {
        sortModel: [{ field: name, sort: order }],
      },
      pagination: {
        paginationModel: {
          page: (props.page ?? 1) - 1,
        },
      },
    };
  }, [
    props.sort?.order,
    props.sort?.name,
    props.page,
    props.showDateTime,
    props.readings,
  ]);

  const tableDashletRows = useMemo(() => {
    const items = props.items ?? [];
    const rows = [];

    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      const cells = readings.reduce<{
        [key: string]: string | number | undefined;
      }>((obj, reading) => {
        obj[reading] = `${item[reading]}`;
        return obj;
      }, {});

      if (props.showSensorAlias || props.hasMultipleSensors) {
        cells['sensorName'] = item.sensorName;
      }

      if (props.showDateTime) {
        cells['date'] = item.timestamp;
      }

      if (props.showUnits) {
        cells['unit'] = item.unit;
      }

      rows.push({
        id: `${item.sensorName}:${item.timestamp}:${item.Close}`,
        ...cells,
      });
    }
    //Cell selection fails if selecting last row, haven't found a solution for this weird behavior so I tricked it
    if (items.length)
      rows.push({
        id: 'failsafe',
      });

    return rows;
  }, [
    props.hasMultipleSensors,
    props.showUnits,
    props.showDateTime,
    props.showSensorAlias,
    readings,
    props.items,
  ]);

  return (
    <StyledDataGrid
      height="100%"
      initialState={initialState}
      sortingOrder={['desc', 'asc']}
      columns={headers}
      rows={tableDashletRows}
      autoRowSizing
      pagination
      density={density}
      dataCy={props.dataCy || ''}
      onPaginationModelChange={(model) => props.onPageChange?.(model.page)}
      onSortModelChange={(model) => onSortChange(model)}
      rowSelection={false}
      disableColumnMenu={!props.showColumnSelection}
      unstable_cellSelection={showSelection}
      unstable_cellSelectionModel={
        tableDashletRows.length ? cellSelectionModel : {}
      }
      unstable_onCellSelectionModelChange={handleCellSelectionModelChange}
      conditionalFormatting={props.conditionalFormatting}
      columnFormatting={props.columnConditionalFormatting}
      loading={props.loading}
      selectedColumn={selectedColumn}
      customColumnMenu={{
        menuItems: [
          {
            title: 'Select Column',
            onClick: (_e, c) =>
              handleColumnSelectionModelChange(c ? c.field : ''),
          },
        ],
      }}
      getRowClassName={(params) =>
        params.id === 'failsafe' ? 'failsafe-style' : ''
      }
    />
  );
};

export default DataGridDashlet;
