import inspectionApi from '@Apis/inspection.api';
import riskApi from '@Apis/risks.api';

export const getInspectionOverviewChartsApi = (standard: string) => {
	return inspectionApi.get<unknown>(
		`inspections/charts?standard=${standard}`,
	);
};

export const getInspectionAssetChartsQueryApi = (
	standard: string,
	query: string,
	assetFields: string,
) => {
	return inspectionApi.get<unknown>(
		`inspections/charts?standard=${standard}&query=${encodeURIComponent(
			query,
		)}&assetFields=${encodeURIComponent(assetFields)}`,
	);
};

export const getInspectionMonthChartsQueryApi = (
	standard: string,
	query: string,
	month: string,
) => {
	return inspectionApi.get<unknown>(
		`inspections/charts?standard=${standard}&query=${encodeURIComponent(
			query,
		)}&month=${encodeURIComponent(month)}`,
	);
};

export const getRiskChartsApi = (configId: string) => {
	return riskApi.get<unknown>(`charts/${configId}`);
};

// Not sure what fields will be need for the query... putting in as a place holder
export const getRiskChartsQueryApi = (
	configId: string,
	query: string,
	assetFields: string,
) => {
	return inspectionApi.get<unknown>(
		`charts/${configId}?query=${encodeURIComponent(
			query,
		)}&assetFields=${encodeURIComponent(assetFields)}`,
	);
};
