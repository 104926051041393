import { FormControl, Slider } from '@mui/material';
import { StyledPropsExpression, rem } from '@Components/styles';
import styled, { css } from 'styled-components';

export const StyledFormControl = styled(FormControl)`
	padding: ${rem(15)} !important;
`;

const reset = css`
	transform: none;
	color: #fff;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: none;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const StyledSlider = styled(Slider)`
	color: #007ca0 !important;
	&.MuiSlider-root {
		color: #007ca0 !important;
	}
	.MuiSlider-rail {
		color: #d8d8d8;
		height: ${rem(2)};
	}
	.MuiSlider-track {
		height: ${rem(4)};
	}
	.MuiSlider-thumb {
		width: ${rem(25)};
		height: ${rem(25)};
		.MuiSlider-valueLabel {
			${reset}
			* {
				${reset}
			}
			font-size: ${rem(12)} !important;
		}
	}
	.MuiSlider-mark {
		display: none;
	}
	.MuiSlider-markLabel {
		font-size: ${rem(10)} !important;
		font-weight: bold;
		color: ${({ theme }): StyledPropsExpression =>
			theme.palette.secondary.main};
	}
`;
