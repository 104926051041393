import { ExpectedStringType, parseServerObjectToFormattedString } from '@Utils';
import {
	getAssetManhole,
	getAssetManholeRejected,
	getAssetManholeResolved,
} from '@Actions/assetManhole.actions';
import { put, retry, takeLatest } from 'redux-saga/effects';

import { AnyAction } from 'redux';
import { AssetManholeSummaryResolvedResponse } from '@Types/asset.types';
import { AxiosResponse } from 'axios';
import { getSummaryApi } from '@Services';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* getAssetManholeSaga(action: AnyAction) {
	try {
		const payload: AxiosResponse<AssetManholeSummaryResolvedResponse> =
			yield retry(5, 15000, getSummaryApi, action.payload);

		if (payload.data?.asset) {
			const formatDateUTC = action.payload.formatDateUTC;
			if (payload.data.asset['yearLaid']) {
				payload.data.asset['yearLaid'] =
					parseServerObjectToFormattedString(
						payload.data.asset['yearLaid'],
						ExpectedStringType.Date,
						formatDateUTC,
					);
			}
		}

		yield put(getAssetManholeResolved(payload.data));
	} catch (e) {
		yield put(getAssetManholeRejected());
	}
}

function* watchGetAssetManhole() {
	yield takeLatest(getAssetManhole, getAssetManholeSaga);
}

export default [watchGetAssetManhole()];
