/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as Chart from '../../core/chart';
import {
  ChartDataError,
  UnknownError,
} from '../../core/chart-data/chart-data.utils';

import type { MutableRefObject } from 'react';
import type { RootProps, SeriesProps } from './comparison-chart.types';
import type { Axis, DashStyleValue, Series } from 'highcharts';
import { InstanceGetter } from '../../core/chart';
import { getTheme } from '../../core/utils/theme-utils';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Series
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const useSeriesCreateRemove = (
  id: MutableRefObject<string>,
  props: SeriesProps
): void => {
  Chart.useInstanceEffect((instances) => {
    instances.get().forEach((instance) => {
      instance.addSeries({
        id: id.current,
        type: props.type === 'line' ? 'scatter' : props.type,
        name: props.name,
        color: props.color,
        visible: !props.hidden,
        events:
          props.type === 'line'
            ? {
                show: function () {
                  this.chart.yAxis[0].addPlotLine({
                    value: props.referece,
                    color: props.color,
                    width: 2,
                    id: `plot-line-${id.current}`,
                    dashStyle: props.style as DashStyleValue,
                  });
                },
                hide: function () {
                  this.chart.yAxis[0].removePlotLine(`plot-line-${id.current}`);
                },
              }
            : undefined,
      });
      if (props.type === 'line')
        instance.yAxis[0].addPlotLine({
          value: props.referece,
          color: props.color,
          dashStyle: props.style as DashStyleValue,
          width: 2,
          id: `plot-line-${id.current}`,
        });
    });
  }, []);

  Chart.useInstanceLayoutEffect((instances) => {
    return () => {
      instances.get().forEach((instance) => {
        const series = instance.get(id.current) as Series | undefined;
        series?.remove();
      });
    };
  }, []);
};

export const useSeriesData = (
  id: MutableRefObject<string>,
  props: SeriesProps
): void => {
  Chart.useInstanceEffect(
    (instances) => {
      instances.get().forEach((instance) => {
        const series = instance.get(id.current) as Series | undefined;
        const data = props.data ?? [];

        series?.update({
          type: props.type === 'line' ? 'scatter' : props.type,
          data,
        });
      });
    },
    [id, props.type, props.data]
  );
};

export const useSeriesColor = (
  id: MutableRefObject<string>,
  props: SeriesProps
): void => {
  Chart.useInstanceEffect(
    (instances) => {
      const theme = getTheme(props.selectedTheme ?? 'Default')
      instances.get().forEach((instance) => {
        const series = instance.get(id.current) as Series | undefined;
        series?.update({
          type: props.type === 'line' ? 'scatter' : props.type,
          color: props.color ?? theme.colors?.[(props.index % theme.colors.length)],
        });
      });
    },
    [id, props.type, props.index, props.color, props.selectedTheme]
  );
};

export const useSeriesName = (
  id: MutableRefObject<string>,
  props: SeriesProps
): void => {
  Chart.useInstanceEffect(
    (instances) => {
      instances.get().forEach((instance) => {
        const series = instance.get(id.current) as Series | undefined;
        series?.update({
          type: props.type === 'line' ? 'scatter' : props.type,
          name: props.name,
        });
      });
    },
    [id, props.type, props.name]
  );
};

export const useSeriesVisibility = (
  id: MutableRefObject<string>,
  props: SeriesProps
): void => {
  Chart.useInstanceEffect(
    (instances) => {
      const visible = !props.hidden;

      instances.get().forEach((instance) => {
        const series = instance.get(id.current) as Series | undefined;
        series?.update({
          type: props.type === 'line' ? 'scatter' : props.type,
          visible,
        });
      });

      instances.get(`stacked-series-${props.index}`).forEach((instance) => {
        const height = visible ? undefined : 0;

        instance.update({
          xAxis: { height, visible },
          yAxis: { height, visible },
        });

        const parent = instance.container.parentElement?.parentElement;

        if (parent) {
          parent.style.position = visible ? 'relative' : 'absolute';
          parent.style.left = visible ? '0' : '-99999px';
          window.dispatchEvent(new Event('resize'));
        }
      });
    },
    [id, props.type, props.hidden]
  );
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Title
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const useTitle = (props: RootProps): void => {
  Chart.useInstanceEffect(
    (instances) => {
      instances.get(['overlay']).forEach((instance) => {
        instance.update({ title: { text: props.title } });
      });
    },
    [props.title]
  );
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Categories
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const useCategories = (props: RootProps): void => {
  Chart.useInstanceEffect(
    (instances) => {
      instances.get(['overlay']).forEach((instance) => {
        instance.update({ xAxis: { categories: props.categories } });
      });
    },
    [props.title]
  );
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * GridLines
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const useXAxisGridlines = (props: RootProps): void => {
  Chart.useInstanceEffect(
    (instances) => {
      instances.get(['overlay']).forEach((instance) => {
        instance.update({
          xAxis: {
            gridLineWidth: props.xAxis?.enableGridlines ? 1 : 0,
          },
        });
      });
    },
    [props.xAxis?.enableGridlines]
  );
};

export const useYAxisGridlines = (props: RootProps): void => {
  Chart.useInstanceEffect(
    (instances) => {
      instances.get(['overlay']).forEach((instance) => {
        instance.update({
          yAxis: {
            gridLineWidth: props.yAxis?.enableGridlines ? 1 : 0,
          },
        });
      });
    },
    [props.yAxis?.enableGridlines]
  );
};

export const useStatus = (props: RootProps): void => {
	Chart.useInstanceEffect(
		instances => {
			instances.get('overlay').forEach(instance => {
				if (props.status === 'loading') {
					instance.showLoading('Loading');
				} else if (props.status === 'resolved') {
					const noData = instance.series.every(
						s => s.data === undefined || s.data.length === 0,
					);
					if (noData) {
						instance.showLoading('No Data');
					} else {
						instance.hideLoading();
					}
				} else if (props.status === 'rejected') {
					const _error =
						props.error instanceof ChartDataError
							? props.error
							: new UnknownError();

          instance.showLoading(_error.message);
        }
      });
    },
    [props.status, props.error]
  );
};


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Theme
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const useTheme = (props: RootProps): void => {
  Chart.useInstanceEffect(
    (instances) => {
      const themeOptions = getTheme(props.selectedTheme ?? 'Default');
      instances.get(['overlay']).forEach((instance) => {
        instance.update({ ...themeOptions });
      });
    },
    [props.selectedTheme]
  );
};