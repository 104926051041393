import { Grid, Typography } from '@mui/material';
import { useGlobalization } from '@Translations';
import React from 'react';
// TEMP ICON
import AssignmentLateOutlinedIcon from '@mui/icons-material/AssignmentLateOutlined';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const NoDetailsEvents = () => {
	const { t } = useGlobalization();
	return (
		<Grid container style={{ paddingTop: '40px', paddingBottom: '40px' }}>
			<Grid container item xs={12} justifyContent="center">
				<AssignmentLateOutlinedIcon
					style={{
						height: '72px',
						width: '72px',
					}}
				/>
			</Grid>
			<Grid container item xs={12} justifyContent="center">
				<Typography variant="h6">{t('No Events to show')}</Typography>
			</Grid>
			<Grid container item xs={12} justifyContent="center">
				<Typography variant="caption">
					{t('Once events have been added they will show up here.')}
				</Typography>
			</Grid>
		</Grid>
	);
};
