import {
  generateHistoricalOptions,
  generateHistoricalStackOptions,
} from './HistoricalChart.utils';
import StackableChart, {
  StackableChartRef,
} from '../../../core/components/StackableChart';
import { RangeSelection, HiddenSeries, Resolutions } from '../../../types';
import { isFeatureEnabled } from '@innovyze/stylovyze';
import Highcharts, { Point } from 'highcharts/highstock';
import React, { forwardRef, useRef } from 'react';
import useHighchartsOptions from '../../../core/hooks/useHighchartsOptions';
import type {
  HistoricalDisplayOptions,
  HistoricalSeries,
} from '../../../core/types/historical.types';
import type { ChartProps } from '../../../core/types/chart.types';
import useRangeSelection from '../../../core/hooks/useRangeSelection';
import useHiddenSeries from '../../../core/hooks/useHiddenSeries';
import useStackOptions from '../../../core/hooks/useStackOptions';
import useClickPoint from '../../../core/hooks/useClickPoint';
import { Container } from './HistoricalChart.styles';

import ResolutionDropdown from './ResolutionDropdown';
import type { ResolutionDropdownProps } from './ResolutionDropdown';

export interface HistoricalChartProps
  extends ChartProps<HistoricalSeries>,
    ResolutionDropdownProps {
  dataRangeSelection?: RangeSelection;
  displayOptions?: HistoricalDisplayOptions;
  hiddenSeries?: HiddenSeries;
  onClickPoint?: (point: Point) => void;
  onHiddenSeriesChange?: (hiddenSeries: HiddenSeries) => void;
  onRangeSelectionChange?: (rangeSelection: RangeSelection) => void;
  rangeSelection?: RangeSelection;
  selectedResolution?: Resolutions;
}

const HistoricalChart = (
  props: HistoricalChartProps,
  ref?: React.Ref<StackableChartRef>
) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const isResolutionDropdownEnabled = !!isFeatureEnabled(
    'info-360-analytics-historic-resolution-dropdown'
  );

  const isBoostModuleEnabled = !!isFeatureEnabled(
    'info-360-analytics-boost-module'
  );

  const { rangeSelectionOptions } = useRangeSelection(
    props.rangeSelection,
    props.dataRangeSelection,
    props.onRangeSelectionChange
  );

  const { hiddenSeries, hiddenSeriesOptions } = useHiddenSeries(
    props.hiddenSeries,
    props.onHiddenSeriesChange
  );

  const { clickPointOptions } = useClickPoint(props.onClickPoint);

  const options = useHighchartsOptions(() => {
    return [
      generateHistoricalOptions(
        props.data,
        isBoostModuleEnabled,
        props.series,
        hiddenSeries,
        isResolutionDropdownEnabled,
        props.resolutionOptions && props.resolutionOptions.length > 0,
        props.displayOptions
      ),
      hiddenSeriesOptions,
      rangeSelectionOptions,
      clickPointOptions,
    ];
  }, [
    hiddenSeries,
    hiddenSeriesOptions,
    isBoostModuleEnabled,
    isResolutionDropdownEnabled,
    props.data,
    props.displayOptions,
    props.displayOptions?.yAxis,
    props.series,
    props.resolutionOptions,
    rangeSelectionOptions,
    clickPointOptions,
  ]);

  const stackOptions = useStackOptions(() => {
    return {
      enabled: !(props.displayOptions?.overlay ?? false),
      options: generateHistoricalStackOptions(
        props.data,
        props.series,
        props.displayOptions
      ),
    };
  }, [props.data, props.series, props.displayOptions]);

  return (
    <Container ref={containerRef}>
      <ResolutionDropdown
        onResolutionChange={props.onResolutionChange}
        resolutionOptions={props.resolutionOptions}
        selectedResolution={props.selectedResolution}
      />
      <StackableChart
        constructorType="stockChart"
        data={props.data}
        highcharts={Highcharts}
        options={options}
        ref={ref}
        stack={stackOptions}
      />
    </Container>
  );
};

export default forwardRef<StackableChartRef, HistoricalChartProps>(
  HistoricalChart
);
