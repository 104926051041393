import { IconSet, ServiceLayer } from '../../services/types';

import { BackgroundTypes } from '@Map/types';

export type UniqueLayerProps = Partial<Pick<ServiceLayer, 'paint' | 'layout'>>;

export default abstract class MapboxLayer {
	static SELECTED_COLOR = '#aa0000';
	static SELECTED_COLOR_FILL = '#007ca0';
	static SELECTED_LIGHT_COLOR = '#f1f4f4';
	static UNSELECTED_COLOR = '#4a6067';
	static UNSELECTED_COLOR_FILL = '#768d95';
	static UNSELECTED_COLOR_OUTLINE = '#768d95';
	static HIGHLIGHT_COLOR = '#ffe54d';
	static FILL_OPACITY = 0.4;
	static SCALE = 3;
	protected _id: NonNullable<ServiceLayer['id']>;
	protected _source: ServiceLayer['source'];
	protected _filter: ServiceLayer['filter'];
	protected _selected: ServiceLayer['selected'];
	protected _highlight: ServiceLayer['highlight'];
	protected _sourceLayer: ServiceLayer['sourceLayer'];
	protected _zIndex: ServiceLayer['zIndex'];
	protected _icon: ServiceLayer['icon'];
	protected _selectedIcon: ServiceLayer['selectedIcon'];
	protected _iconBackground: ServiceLayer['iconBackground'];
	protected _minZoom: ServiceLayer['minZoom'];
	protected _maxZoom: ServiceLayer['maxZoom'];
	protected _type: ServiceLayer['type'] = 'line';
	protected _iconSet: IconSet = {};
	protected _color: ServiceLayer['color'];
	protected _selectedColor: ServiceLayer['selectedColor'];
	protected _highlightColor: ServiceLayer['highlightColor'];
	protected _labelColor: ServiceLayer['labelColor'];
	protected _fillOpacity: ServiceLayer['fillOpacity'];
	protected _pattern: ServiceLayer['pattern'];
	protected _lineWidth: ServiceLayer['lineWidth'];
	protected _lineDashArray: ServiceLayer['lineDashArray'];
	protected _background: ServiceLayer['background'];
	protected _displayName: ServiceLayer['displayName'];
	protected _colorLookup: ServiceLayer['colorLookup'];
	protected _selectedColorLookup: ServiceLayer['selectedColorLookup'];
	protected _radius: ServiceLayer['radius'];
	protected _sort: ServiceLayer['sort'];
	protected _scale: ServiceLayer['scale'];
	protected _assetFilter: boolean | string[] = false;

	constructor({
		id,
		source,
		filter,
		selected = false,
		highlight = false,
		sourceLayer = undefined,
		zIndex = 0,
		icon,
		iconBackground = true,
		minZoom,
		maxZoom,
		color,
		selectedColor,
		highlightColor,
		labelColor,
		fillOpacity,
		pattern,
		lineWidth,
		lineDashArray,
		background = BackgroundTypes.Streets,
		displayName,
		colorLookup,
		selectedColorLookup,
		radius,
		sort,
		scale,
		assetFilter = false,
	}: ServiceLayer) {
		this._id = id ?? '';
		this._source = source;
		this._filter = filter;
		this._selected = selected;
		this._highlight = highlight;
		this._sourceLayer = sourceLayer;
		this._zIndex = zIndex;
		this._icon = icon;
		this._iconBackground = iconBackground;
		this._minZoom = minZoom;
		this._maxZoom = maxZoom;
		this._color = color;
		this._selectedColor = selectedColor;
		this._highlightColor = highlightColor;
		this._fillOpacity = fillOpacity;
		this._labelColor = labelColor;
		this._pattern = pattern;
		this._lineWidth = lineWidth;
		this._lineDashArray = lineDashArray;
		this._background = background;
		this._displayName = displayName;
		this._colorLookup = colorLookup;
		this._selectedColorLookup = selectedColorLookup;
		this._radius = radius;
		this._sort = sort;
		this._scale = scale;
		this._assetFilter = assetFilter;
	}

	get id(): NonNullable<ServiceLayer['id']> {
		return this._id;
	}

	set iconSet(iconSet: IconSet) {
		this._iconSet = iconSet;
	}

	get zIndex(): number {
		if (typeof this._zIndex === 'number') {
			return this._zIndex;
		}
		return 0;
	}

	get displayName(): string {
		return this._displayName ?? '';
	}

	build(): ServiceLayer {
		const layerProps = this.layerProperties();
		layerProps.paint = {
			...layerProps.paint,
		};
		return {
			id: this._id,
			...(this._filter && { filter: this._filter }),
			type: this._type,
			source: this._source,
			...(this._sourceLayer && { 'source-layer': this._sourceLayer }),
			...layerProps,
			metadata: {
				zIndex: this.zIndex,
				displayName: this.displayName,
			},
			...(this._minZoom && { minzoom: this._minZoom }),
			...(this._maxZoom && { maxzoom: this._maxZoom }),
		};
	}

	abstract layerProperties(): UniqueLayerProps;
}
