import { fixCollectionInterval } from '../../core/time-series-data/utils';
import { InsightHistoricalChartSeriesProps } from './insight-historical-chart';
import { Resolution } from '../../core/_insight-chart';
import { SensorV2, useSelectSensors } from '@innovyze/stylovyze';
import * as React from 'react';
import * as SeriesData from '../../core/series-data';
import * as TimeSeriesData from '../../core/time-series-data';

// # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #
// # # # Utilities
// # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #

const NO_SOURCES_MEMO_ARRAY = [];

export function stringifySource(
  source: TimeSeriesData.Source | Omit<TimeSeriesData.Source, 'key'>
): string {
  if ('key' in source) return source.key;

  let s = `${source.sensorId}`;

  if (source.analytic) {
    if (typeof source.analytic.interval === 'string') {
      s += `:${source.analytic.interval}`;
    } else {
      Object.entries(source.analytic.interval).forEach(([k, v]) => {
        s += `:${k}:${v}`;
      });
    }

    source.analytic.functions.forEach((f) => {
      Object.entries(f).forEach(([k, v]) => {
        s += `:${k}:${v}`;
      });
    });
  }

  return s;
}

// # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #
// # # # Use sources hook
// # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #

export function useDataSources(
  options: {
    series: InsightHistoricalChartSeriesProps[];
    getAutoResolution: (sensor: SensorV2) => Resolution;
  }, // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deps?: any[]
) {
  const { sensors, initialized: sensorsInitialized } = useSelectSensors();
  const getAutoResolutionRef = React.useRef(options.getAutoResolution);
  getAutoResolutionRef.current = options.getAutoResolution;

  const prevPlotDataSourcesRef = React.useRef<TimeSeriesData.Source[]>();
  const [plotDataSources] = SeriesData.useSources<TimeSeriesData.Source>(() => {
    if (!options.series?.length || !sensorsInitialized)
      return NO_SOURCES_MEMO_ARRAY;

    const sources = options.series.flatMap((series) => {
      const { resolution: r, analytic: a, trendlinePeriods = 0 } = series;
      const { referenceDataSource, enableTrendline } = series;
      const { type, customData } = series;
      const sensor = sensors.find((s) => s.sensorId === series.sensorId);
      const ss: Omit<TimeSeriesData.Source, 'key'>[] = [];
      const seconds = fixCollectionInterval(sensor?.collectionInterval);

      const resolution =
        r === 'AUTO' ? getAutoResolutionRef.current(sensor) : r;

      const analytic = TimeSeriesData.makeAnalytic(
        resolution,
        type === 'candlestick' ? 'OHLC' : (a ?? 'Close')
      );

      if (customData) return [];

      if (referenceDataSource) {
        ss.push({
          analytic,
          collectionInterval: { seconds },
          sensorId: series.sensorId,
        });
      } else {
        ss.push({
          analytic,
          collectionInterval: { seconds },
          sensorId: series.sensorId,
        });

        if (enableTrendline) {
          ss.push({
            analytic,
            collectionInterval: { seconds },
            sensorId: series.sensorId,
            trendline: trendlinePeriods,
          } as TimeSeriesData.SourceTrendline);
        }
      }

      return ss;
    });

    return sources.map((s) => ({ key: stringifySource(s), ...s }));
  }, [options.series, sensors, sensorsInitialized, ...(deps ?? [])]);
  const plotDataSourcesRef = React.useRef(plotDataSources);
  plotDataSourcesRef.current = plotDataSources;

  const prevNavigatorDataSourcesRef = React.useRef<TimeSeriesData.Source[]>();
  const [navigatorDataSources] =
    SeriesData.useSources<TimeSeriesData.Source>(() => {
      if (!options.series?.length || !sensorsInitialized)
        return NO_SOURCES_MEMO_ARRAY;

      const sources = options.series.flatMap((series) => {
        const { analytic: a, trendlinePeriods = 0 } = series;
        const { referenceDataSource, enableTrendline } = series;
        const { type, customData } = series;
        const sensor = sensors.find((s) => s.sensorId === series.sensorId);
        const ss: Omit<TimeSeriesData.Source, 'key'>[] = [];
        const seconds = fixCollectionInterval(sensor?.collectionInterval);

        const resolution = 'DAILY';
        const analytic = TimeSeriesData.makeAnalytic(
          resolution,
          type === 'candlestick' ? 'OHLC' : (a ?? 'Close')
        );

        if (customData) return [];

        if (referenceDataSource) {
          ss.push({
            analytic,
            collectionInterval: { seconds },
            sensorId: series.sensorId,
          });
        } else {
          ss.push({
            analytic,
            collectionInterval: { seconds },
            sensorId: series.sensorId,
          });

          if (enableTrendline) {
            ss.push({
              analytic,
              collectionInterval: { seconds },
              sensorId: series.sensorId,
              trendline: trendlinePeriods,
            } as TimeSeriesData.SourceTrendline);
          }
        }

        return ss;
      });

      return sources.map((s) => ({ key: stringifySource(s), ...s }));
    }, [options.series, sensors, sensorsInitialized]);
  const navigatorDataSourcesRef = React.useRef(navigatorDataSources);
  navigatorDataSourcesRef.current = navigatorDataSources;

  const plotDataSourcesChanged = !prevPlotDataSourcesRef.current
    ? false
    : plotDataSources !== prevPlotDataSourcesRef.current;

  const navigatorDataSourcesChanged = !prevNavigatorDataSourcesRef.current
    ? false
    : navigatorDataSources !== prevNavigatorDataSourcesRef.current;

  React.useEffect(() => () => {
    prevPlotDataSourcesRef.current = plotDataSources;
    prevNavigatorDataSourcesRef.current = navigatorDataSources;
  });

  return {
    plotDataSources,
    plotDataSourcesRef,
    plotDataSourcesChanged,
    navigatorDataSources,
    navigatorDataSourcesRef,
    navigatorDataSourcesChanged,
  };
}
