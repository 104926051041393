import { createSelector } from '@reduxjs/toolkit';

import type { AsyncData } from '../types/async.types';
import type { CustomAnalytic } from '../types/analytic.types';
import type { StoreState } from '../../store/store';

export const selectCustomAnalyticsFromWorkspace = (state: StoreState) => {
  return state.workspaces?.externalResources?.customAnalytics;
};

export const makeSelectCustomAnalyticByIdFromWorkspace = () => {
  return createSelector(
    selectCustomAnalyticsFromWorkspace,
    (_: unknown, customAnalyticId: string) => customAnalyticId,
    (customAnalytics, customAnalyticId): AsyncData<CustomAnalytic> => {
      if (customAnalytics === undefined) {
        return { status: 'rejected', data: null };
      }

      const storedCustomAnalytic = customAnalytics.find(
        (customAnalytic) => customAnalytic._id === customAnalyticId
      );

      if (!storedCustomAnalytic) {
        return { status: 'rejected', data: null };
      }

      return { status: 'resolved', data: storedCustomAnalytic };
    }
  );
};
