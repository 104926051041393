import { CircularProgress, Grid } from '@mui/material';
import {
	HTMLInputElementExtended,
	HTMLTextAreaElementExtended,
	InfoCard,
	InputText,
	InputUnit,
	StylovyzeForm,
} from '@innovyze/stylovyze';
import { Tab, TabPanel, TabWrapper, Tabs } from '@Components/Tabs';
import { a11yProps, fixupGrid, getAssetValues } from '@Utils';
import {
	selectAssetDetailDetail,
	selectAssetDetailIsWaiting,
} from '@Selectors/assetDetail.selectors';

import React, { ChangeEvent } from 'react';
import { TFunc } from '@Translations/types';
import { useGlobalization } from '@Translations';
import { CustomTables } from '@Components/AssetDetails/CustomTables';
import { selectAssetManholeAsset } from '@Selectors/assetManhole.selectors';

const generalFields = (t: TFunc): { name: string; alias: string }[] => [
	{ name: 'assetId', alias: t('Asset ID') },
	{ name: 'gasType', alias: t('Gas Type') },
	{ name: 'groundLevel', alias: t('Ground Level') },
	{ name: 'material', alias: t('Material') },
	{ name: 'criticality', alias: t('Criticality') },
	{ name: 'owner', alias: t('Owner') },
	{ name: 'security', alias: t('Security') },
	{ name: 'status', alias: t('Status') },
	{ name: 'systemType', alias: t('System Type') },
	{ name: 'toxicAtmosphere', alias: t('Toxic Atmosphere') },
];

const accessFields = (t: TFunc): { name: string; alias: string }[] => [
	{ name: 'accessAction', alias: t('Access Action') },
	{ name: 'accessTime', alias: t('Access Time') },
	{ name: 'numLadders', alias: t('Number of Ladders') },
	{ name: 'numLandings', alias: t('Number of Landings') },
	{ name: 'numRegulating', alias: t('Number of Regulating Courses') },
	{ name: 'numSteps', alias: t('Number of Steps') },
	{ name: 'stepMaterial', alias: t('Step Material') },
];

const dateFields = (t: TFunc): { name: string; alias: string }[] => [
	{ name: 'yearLaid', alias: t('Install Date') },
	{ name: 'yearRenewed', alias: t('Renewal Date') },
];

const coverFields = (t: TFunc): { name: string; alias: string }[] => [
	{ name: 'coverDim', alias: t('Cover Dimension') },
	{ name: 'coverDim2', alias: t('Cover Dimension 2') },
	{ name: 'coverLevel', alias: t('Cover Level') },
	{ name: 'coverShape', alias: t('Cover Shape') },
	{ name: 'coverDuty', alias: t('Cover Duty') },
	{ name: 'coverLocked', alias: t('Cover Locked') },
];

const internalFields = (t: TFunc): { name: string; alias: string }[] => [
	{ name: 'channelType', alias: t('Channel Type') },
	{ name: 'chamberDim', alias: t('Chamber Dimension') },
	{ name: 'chamberDim2', alias: t('Chamber Dimension 2') },
	{ name: 'shaftDim', alias: t('Shaft Dimension') },
	{ name: 'shaftDim2', alias: t('Shaft Dimension 2') },
	{ name: 'coneType', alias: t('Cone Type') },
	{ name: 'inletType', alias: t('Inlet Type') },
	{ name: 'sideEntry', alias: t('Side Entry') },
];

const depthFields = (t: TFunc): { name: string; alias: string }[] => [
	{ name: 'chamberFloorDepth', alias: t('Chamber Floor Depth from Cover') },
	{ name: 'coneDepth', alias: t('Cone Depth') },
	{ name: 'gradeToInvert', alias: t('Grade to Invert') },
	{ name: 'rimToGrade', alias: t('Rim to Grade') },
	{ name: 'rimToInvert', alias: t('Rim to Invert') },
	{ name: 'shaftDepth', alias: t('Shaft Depth') },
	{ name: 'chimneyDepth', alias: t('Chimney Depth') },
];

const locationFields = (t: TFunc): { name: string; alias: string }[] => [
	{ name: 'location', alias: t('Location') },
	{ name: 'locationCode', alias: t('Location Code') },
	{ name: 'locationDetails', alias: t('Location Details') },
	{ name: 'drainageArea', alias: t('Drainage Area') },
	{ name: 'street', alias: t('Street') },
];

const hardCodeUnitFields: { [key: string]: string } = {
	coverDim: 'm',
	coverDim2: 'm',
	coverLevel: 'm',
	chamberFloorDepth: 'm',
	coneDepth: 'm',
	gradeToInvert: 'm',
	rimToGrade: 'm',
	rimToInvert: 'm',
	shaftDepth: 'm',
	chimneyDepth: 'm',
	groundLevel: 'm',
	chamberDim: 'm',
	chamberDim2: 'm',
	shaftDim: 'm',
	shaftDim2: 'm',
};

const getDomainValues = (t: TFunc, fieldName: string) => {
	let domainValueDic: { [key: string]: string } = {};
	switch (fieldName.toLowerCase()) {
		case 'accessAction'.toLowerCase():
			domainValueDic = {
				POL: t('Police Assistance'),
				PARK: t('No Parking'),
				KEYS: t('Keys Needed'),
				O: t('Other'),
			};
			break;
		case 'accessTime'.toLowerCase():
			domainValueDic = {
				A: t('Outside Working Hours'),
				B: t('Outside Normal Hours'),
				C: t('Outside Weekend Hours'),
				D: t('Other'),
			};
			break;
		case 'coverDuty'.toLowerCase():
			domainValueDic = {
				L: t('Light'),
				M: t('Medium'),
				H: t('Heavy'),
				U: t('Unspecified'),
			};
			break;
		case 'coverShape'.toLowerCase():
			domainValueDic = {
				S: t('Square'),
				R: t('Rectangular'),
				T: t('Triangular'),
				D: t('Double Triangle'),
				C: t('Circular'),
				O: t('Oval'),
				L: t('Clover Leaf'),
				HX: t('Hexagonal'),
				OC: t('Octagonal'),
				U: t('Unspecified'),
				Z: t('Other'),
			};
			break;
		case 'gasType'.toLowerCase():
			domainValueDic = {
				A: t('CH4'),
				B: t('H2S'),
				C: t('Explosive'),
				D: t('Other'),
			};
			break;
		case 'coneType'.toLowerCase():
			domainValueDic = {
				CC: t('Conical Centered'),
				CO: t('Conical Off Centered'),
				FT: t('Flat Top'),
				N: t('Not Present'),
				ZZ: t('Other'),
			};
			break;
		case 'inletType'.toLowerCase():
			domainValueDic = {
				ContCO: t('Continuous Curb-Opening'),
				ContG: t('Continuous Grate'),
				SagC: t('Sag Combination'),
				SagCO: t('Sag Curb-Opening'),
				SagG: t('Sag Grate'),
			};
			break;
		case 'locationCode'.toLowerCase():
			domainValueDic = {
				M: t('Continuous Curb-Opening'),
				H: t('Continuous Grate'),
				J: t('Sag Combination'),
				K: t('Sag Curb-Opening'),
				O: t('Sag Grate'),
				I: t('Ditch'),
				D: t('Easement/Right of Way'),
				P: t('Levee Pump Station'),
				N: t('Levee/Floodwall'),
				C: t('Local Rural Streets'),
				Z: t('Other'),
				G: t('Parking Lot'),
				A: t('Primary Major Arterial Roads'),
				L: t('Railway'),
				B: t('Secondary Roads'),
				F: t('Sidewalk'),
				E: t('Woods'),
				Y: t('Yard'),
			};
			break;
		case 'status'.toLowerCase():
			domainValueDic = {
				PU: t('Public'),
				PR: t('Private'),
				24: t('Section 24'),
				HD: t('Highway Drain'),
				WC: t('Watercourse'),
				TR: t('Trunk'),
				17: t('Section 17'),
				18: t('Section 18'),
				AB: t('Abandoned'),
				TC: t('To Be Constructed'),
				HC: t('Housing Committee'),
				U: t('Unspecified'),
			};
			break;
		case 'stepMaterial'.toLowerCase():
			domainValueDic = {
				AL: t('Aluminium'),
				GI: t('Galvanised Iron'),
				IR: t('Iron/Steel'),
				PL: t('Plastic'),
				PM: t('Metal Encapsulated in Plastic'),
				SS: t('Stainless Steel'),
				Z: t('Other'),
			};
			break;
		case 'owner'.toLowerCase():
			domainValueDic = {
				PR: t('Private'),
				PU: t('Public'),
				X: t('Not Known'),
			};
			break;
		case 'security'.toLowerCase():
			domainValueDic = {
				A: t('Vulnerable'),
				B: t('Moderate Risk'),
				C: t('Secure'),
			};
			break;
		case 'systemType'.toLowerCase():
			domainValueDic = {
				CB: t('Combined Pipe'),
				DP: t('Dam Pipe'),
				FM: t('Force Main'),
				LG: t('Levee Gravity Pipe'),
				LP: t('Levee Pressure Pipe'),
				PR: t('Process Pipe'),
				SS: t('Sanitary Sewage Pipe'),
				SW: t('Stormwater Pipe'),
				XX: t('Not Known'),
				ZZ: t('Other'),
				F: t('Foul'),
				S: t('Surface'),
				C: t('Combined'),
				T: t('Trade Effluent'),
				O: t('Overflow'),
				W: t('Culverted Watercourse'),
				LD: t('Subsoil or Field Drainage'),
				U: t('Unspecified'),
				Z: t('Other'),
			};
			break;
		default:
			break;
	}

	return domainValueDic;
};

export const ManholeAttributes = (): JSX.Element => {
	const { t } = useGlobalization();
	const [value, setValue] = React.useState(0);
	const isLoading = selectAssetDetailIsWaiting();
	const asset = selectAssetManholeAsset();
	const details = getAssetValues(
		t,
		selectAssetDetailDetail(),
		getDomainValues,
	);

	const handleChange = (event: unknown, newValue: number) => {
		setValue(newValue);
	};

	const getGrids = (fields: { name: string; alias: string }[]) => {
		if (fields && fields.length > 0) {
			return fields.map(field => {
				// Fix up any missing asset fields so it displays '-'
				fixupGrid(fields, details);

				if (hardCodeUnitFields[field.name]) {
					return (
						<Grid item xs={3} key={field.name}>
							<InputUnit
								fieldAttrs={{ name: field.name }}
								unitField={{
									label: field.alias,
									unit: hardCodeUnitFields[field.name],
									defaultValue: '',
									onChange: (
										e: ChangeEvent<
											| HTMLInputElementExtended
											| HTMLTextAreaElementExtended
										>,
									) => {
										console.log('onChange', e.target.value);
									},
								}}
								invalidDisplayValue={'-'}
							/>
						</Grid>
					);
				} else {
					return (
						<Grid item xs={3} key={field.name}>
							<InputText
								fieldAttrs={{ name: field.name }}
								textField={{
									label: field.alias,
								}}
							/>
						</Grid>
					);
				}
			});
		}

		return <></>;
	};

	const general = (
		<StylovyzeForm
			mode="view"
			initialValues={details}
			onSubmit={data => {
				console.log('should update the data', data);
			}}>
			{() => {
				return (
					<>
						<InfoCard fullHeight title={t('General')}>
							<Grid container>{getGrids(generalFields(t))}</Grid>
						</InfoCard>
						<InfoCard fullHeight title={t('Access')}>
							<Grid container>{getGrids(accessFields(t))}</Grid>
						</InfoCard>
						<InfoCard fullHeight title={t('Dates')}>
							<Grid container>{getGrids(dateFields(t))}</Grid>
						</InfoCard>
					</>
				);
			}}
		</StylovyzeForm>
	);

	const cover = (
		<StylovyzeForm
			mode="view"
			initialValues={details}
			onSubmit={data => {
				console.log('should update the data', data);
			}}>
			{() => {
				return (
					<>
						<InfoCard fullHeight title={t('Cover')}>
							<Grid container>{getGrids(coverFields(t))}</Grid>
						</InfoCard>
						<InfoCard fullHeight title={t('Internal')}>
							<Grid container>{getGrids(internalFields(t))}</Grid>
						</InfoCard>
					</>
				);
			}}
		</StylovyzeForm>
	);

	const depths = (
		<StylovyzeForm
			mode="view"
			initialValues={details}
			onSubmit={data => {
				console.log('should update the data', data);
			}}>
			{() => {
				return (
					<>
						<InfoCard fullHeight title={t('Depths')}>
							<Grid container>{getGrids(depthFields(t))}</Grid>
						</InfoCard>
					</>
				);
			}}
		</StylovyzeForm>
	);

	const location = (
		<StylovyzeForm
			mode="view"
			initialValues={details}
			onSubmit={data => {
				console.log('should update the data', data);
			}}>
			{() => {
				return (
					<>
						<InfoCard fullHeight title={t('Location')}>
							<Grid container>{getGrids(locationFields(t))}</Grid>
						</InfoCard>
					</>
				);
			}}
		</StylovyzeForm>
	);

	return (
		<>
			{isLoading ? (
				<CircularProgress />
			) : (
				<TabWrapper $height={683}>
					<Tabs
						orientation="vertical"
						variant="scrollable"
						value={value}
						onChange={handleChange}
						aria-label="manhole-attributes">
						<Tab
							label={t('General, Access and Dates')}
							{...a11yProps(0)}
						/>
						<Tab
							label={t('Cover and Internal')}
							{...a11yProps(1)}
						/>
						<Tab label={t('Depths')} {...a11yProps(2)} />
						<Tab label={t('Location')} {...a11yProps(3)} />
						<Tab label={t('Custom Tables')} {...a11yProps(4)} />
					</Tabs>
					<TabPanel value={value} index={0}>
						{general}
					</TabPanel>
					<TabPanel value={value} index={1}>
						{cover}
					</TabPanel>
					<TabPanel value={value} index={2}>
						{depths}
					</TabPanel>
					<TabPanel value={value} index={3}>
						{location}
					</TabPanel>
					<TabPanel value={value} index={4}>
						<CustomTables
							userDefinedFields={asset?.userDefined}
							userDefinedFieldUnits={asset?.userDefinedUnits}
						/>
					</TabPanel>
				</TabWrapper>
			)}
		</>
	);
};

export default ManholeAttributes;
