import { Field, FieldType } from '@Types/map.types';
import {
	excludedFilterColumn,
	spatialFilterColumn,
} from '@Components/AssetGrid/columnMapping';

import { NamespacedStoreState } from '@Types/store.types';
import { createSelector } from '@reduxjs/toolkit';
import { getValue } from './shared';
import { isValidFieldType } from '@Utils/helpers';
import { selectSpatialLayerMeta } from './risk';

export const selectMapBase = createSelector(
	[getValue],
	moduleState => moduleState.map,
);

export const selectMapLayout = createSelector(
	[selectMapBase],
	baseState => baseState.layout,
);

const passInAssetType = (_: NamespacedStoreState, assetType: string): string =>
	assetType;

export const selectMapLayoutByAssetType = createSelector(
	[selectMapLayout, passInAssetType],
	(layout, assetType) => layout && layout[assetType],
);

export const selectMapLayoutForPropertyPane = createSelector(
	[selectMapLayoutByAssetType],
	assetTypeLayout =>
		assetTypeLayout &&
		assetTypeLayout.find(({ showInPanel }) => showInPanel),
);

export const selectMapLayoutForAssetDetails = createSelector(
	[selectMapLayoutByAssetType],
	assetTypeLayout =>
		assetTypeLayout &&
		assetTypeLayout.filter(({ showInPanel }) => !showInPanel),
);

export const selectMapLayoutForAssetGrid = createSelector(
	[selectMapLayoutForPropertyPane, selectMapLayoutForAssetDetails],
	(toShow, additional) => {
		const fieldKeys = toShow?.fields.map(({ fieldKey }) => fieldKey);
		const additionalFields = additional?.flatMap(({ fields }) => fields);
		const columns = [
			...(toShow?.fields || []).map(data => ({ ...data, visible: true })),
			spatialFilterColumn,
			excludedFilterColumn,
			...(additionalFields || [])?.filter(
				({ fieldKey }) => !fieldKeys?.includes(fieldKey),
			),
		];

		return { columns, visibility: calculateVisibility(columns) };
	},
);

const correctFieldType = (fieldType: string): FieldType => {
	if (fieldType === 'datetime' || fieldType === 'time') {
		return FieldType.Date;
	}
	if (isValidFieldType(fieldType)) {
		return fieldType as FieldType;
	}
	return FieldType.String;
};

export const selectSpatialDataColumns = createSelector(
	[selectSpatialLayerMeta],
	layerMeta => {
		const columns = [
			...(layerMeta || [])?.map(({ property, type }) => ({
				fieldKey: property,
				fieldType: correctFieldType(type),
				name: property,
				visible: true,
				columnWidth: 150,
			})),
			spatialFilterColumn,
			excludedFilterColumn,
		];

		return {
			columns,
			visibility: calculateVisibility(columns),
		};
	},
);

function calculateVisibility(columns: Pick<Field, 'fieldKey' | 'visible'>[]) {
	return columns.reduce((visibility, column) => {
		visibility[column.fieldKey] = column.visible ?? false;
		return visibility;
	}, {} as Record<string, boolean>);
}
