import styled from 'styled-components';

export const ScrollArea = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  overflow: auto;
  width: 100%;
`;

export const Header = styled.div<{ withTitle?: boolean }>`
  height: ${(props) => (props.withTitle ? '80px' : '40px')};
  width: 100%;
`;

export const Footer = styled.div`
  height: 120px;
  width: 100%;
`;

export const Series = styled.div`
  flex: 1 1 0;
  min-height: 160px;
`;

export const Label = styled('label')`
  color: #4a6067;
  font-family: 'Lucida Grande', sans-serif;
  font-size: 12px;
  font-weight: 400;
  margin-right: 6px;
`;

export const Helper = styled('span')`
  position: absolute;
  top: 0;
  left: -99999px;
`;
