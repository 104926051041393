import {
	GeoJSONFeature,
	GeoJsonProperties,
	SelectedAsset,
	SelectedLayerIds,
	SelectedSourceIds,
} from './types';
import { notUndefined, partition } from '@Map/utils';

import ExternalFeature from './ExternalFeature';
import Feature from './Feature';

export type SortFunction = (a: Feature, b: Feature) => number;

interface SelectionChanges {
	added: number;
	deleted: number;
}

type HighlightFeature = Feature | ExternalFeature;

export default class SelectionManager {
	private _selectedFeatures: Feature[] = [];
	private _externalSelectedAssets: ExternalFeature[] = [];
	private _highlightFeatures: HighlightFeature[] = [];
	private _sort = this._defaultSort;

	set sortFunction(sort: SortFunction) {
		this._sort = sort;
	}

	get featuresSorted(): Feature[] {
		return this._selectedFeatures.sort(this._sort);
	}

	get assetIds(): string[] {
		return this.featuresSorted.map(({ id }) => id);
	}

	get assetProperties(): GeoJsonProperties[] {
		return this.featuresSorted.map(({ properties }) => properties);
	}

	get assetsPerLayer(): SelectedLayerIds {
		return this.featuresSorted
			.map(({ id, layerId }) => ({
				id,
				layerId,
			}))
			.reduce(
				(previous, { id, layerId }) => ({
					...previous,
					[layerId]: [...(previous[layerId] || []), id],
				}),
				{} as SelectedLayerIds,
			);
	}

	get assetsPerSource(): SelectedSourceIds {
		return this.featuresSorted
			.map(({ id, sourceId }) => ({
				id,
				sourceId,
			}))
			.reduce(
				(previous, { id, sourceId }) => ({
					...previous,
					[sourceId]: [...(previous[sourceId] || []), id],
				}),
				{} as SelectedSourceIds,
			);
	}

	set externalSelectedAssets(assets: SelectedAsset[]) {
		this._externalSelectedAssets = assets.map(
			asset => new ExternalFeature(asset),
		);
	}

	/**
	 * check if there is a difference between the external selected assets and the
	 * internally stored selected assets
	 */
	get externalAssetsDiff(): boolean {
		return (
			// external selected assets haven't been selected
			!!this._externalSelectedAssets.filter(
				({ id }) => !this.assetIds.includes(id),
			).length ||
			// there are external selected assets and there are less than that have been selected
			!!(
				this._externalSelectedAssets.length &&
				this._externalSelectedAssets.length < this.assetIds.length
			)
		);
	}

	get externalAssetsToFind(): ExternalFeature[] {
		return this._externalSelectedAssets;
	}

	get highlightedAssets(): HighlightFeature[] {
		return this._highlightFeatures;
	}

	get highlightedPerLayer(): SelectedLayerIds {
		return this._highlightFeatures
			.map(({ id, layerId }) => ({
				id,
				layerId,
			}))
			.reduce(
				(previous, { id, layerId }) => ({
					...previous,
					[layerId]: [...(previous[layerId] || []), id],
				}),
				{} as SelectedLayerIds,
			);
	}

	getAssetsToFindInLayer(
		layerIdToFind: string,
		sourceLayer?: string,
	): GeoJSONFeature[] {
		return this.externalAssetsToFind
			.filter(({ layerId }) => layerId === layerIdToFind)
			.map(asset => asset.asGeojsonFeature(sourceLayer));
	}

	getAssetById(assetId: string): Feature | undefined {
		return this.featuresSorted.find(asset => asset.id === assetId);
	}

	getHighlightedAssetById(assetId: string): HighlightFeature | undefined {
		return this.highlightedAssets.find(asset => asset.id === assetId);
	}

	addOne(featureData: GeoJSONFeature): Feature | undefined {
		const feature = new Feature(featureData);
		if (!this.assetIds.includes(feature.id)) {
			this._selectedFeatures.push(feature);
			return feature;
		}
	}

	addMany(features: GeoJSONFeature[] | undefined): SelectionChanges {
		if (!features?.length) {
			return { added: 0, deleted: 0 };
		}
		const newFeatures = features.map(feature => this.addOne(feature));
		return { added: newFeatures.filter(Boolean).length, deleted: 0 };
	}

	replaceAll(features: GeoJSONFeature[] | undefined): SelectionChanges {
		if (!features?.length) {
			const featureCount = this._selectedFeatures.length;
			this.deleteAll();
			return { added: 0, deleted: featureCount };
		}

		const newFeatures = features.map(feature => this.addOne(feature));
		const deleted = this._deleteNotFound(features);

		return { added: newFeatures.filter(Boolean).length, deleted };
	}

	invertMany(features: GeoJSONFeature[] | undefined): SelectionChanges {
		if (!features) return { added: 0, deleted: 0 };
		const [currentFeatures, newFeatures] = partition(features, feature => {
			const id = new Feature(feature).id;
			return this.assetIds.includes(id);
		});
		this.deleteMany(currentFeatures);
		this.addMany(newFeatures);

		return { added: newFeatures.length, deleted: currentFeatures.length };
	}

	deleteOne(assetId: string): void {
		this._selectedFeatures = this._selectedFeatures.filter(
			({ id }) => id !== assetId,
		);
	}

	deleteMany(
		assets: GeoJSONFeature[] | string[] | undefined,
	): SelectionChanges {
		if (!assets) return { added: 0, deleted: 0 };
		const assetIds = assets.map((feature: GeoJSONFeature | string) =>
			typeof feature === 'string' ? feature : new Feature(feature).id,
		);
		const originalTotal = this._selectedFeatures.length;
		this._selectedFeatures = this._selectedFeatures.filter(
			({ id }) => !assetIds.includes(id),
		);
		return {
			added: 0,
			deleted: originalTotal - this._selectedFeatures.length,
		};
	}

	deleteAll(): void {
		this._selectedFeatures = [];
	}

	private _deleteNotFound(features: GeoJSONFeature[]): number {
		const featureIds = features.map(feature => new Feature(feature).id);
		const featuresFound = this._selectedFeatures.filter(({ id }) =>
			featureIds.includes(id),
		);
		const deleted = this._selectedFeatures.length - featuresFound.length;
		this._selectedFeatures = featuresFound;
		return deleted;
	}

	updateAssetProperties(assetId: string, data: GeoJsonProperties): void {
		const feature = this._selectedFeatures.find(({ id }) => id === assetId);
		if (feature) {
			feature.updateProperties(data);
		}
	}

	highlightAssets(assets: SelectedAsset[]): void {
		this._highlightFeatures = assets
			.map(
				asset =>
					this.getAssetById(asset.id) ?? new ExternalFeature(asset),
			)
			.filter(notUndefined);
	}

	private _defaultSort(a: Feature, b: Feature): number {
		if (a.id < b.id) return -1;
		if (a.id > b.id) return 1;
		return 0;
	}
}
