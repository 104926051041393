import React from 'react';
import { EmptyThemeHelperWrapper } from './EmptyThemeHelper.styles';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ReactComponent as ThemesEmptyStateImage } from '../LayerPanel/assets/ThemesEmptyStateImage.svg';
import { useGlobalization } from '@Translations/useGlobalization';

const EmptyThemeHelper = () => {
	const { t } = useGlobalization();
	return (
		<EmptyThemeHelperWrapper>
			<div>
				<p>
					{t('Select a sublayer to customize colors, icons, etc.', {
						context: 'Empty theme helper',
					})}
				</p>
				<ThemesEmptyStateImage />
			</div>
		</EmptyThemeHelperWrapper>
	);
};

export { EmptyThemeHelper };
