import {
	Environments,
	getApiEnvironment,
	getService,
} from '@innovyze/stylovyze';

import axios from 'axios';
import config from './config.api';
import cookies from 'browser-cookies';
import { formatUrlTrailing } from '@Utils/urlFormatting';

const apiEnv =
	(localStorage.getItem('apiEnv') as keyof typeof Environments) || undefined;

export const riskBaseUrl = formatUrlTrailing(
	getService('amRisk', config, apiEnv),
);

const options = {
	baseURL: getService('amRisk', config, getApiEnvironment()),
	timeout: 30000,
};

const riskService = axios.create(options);

riskService.interceptors.request.use(
	conf => {
		conf.headers.set(
			'Authorization',
			'Bearer ' + cookies.get('auth0.user.token'),
		);
		return conf;
	},
	error => {
		Promise.reject(error);
	},
);

export default riskService;
