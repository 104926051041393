import {
	CONTROL_CURRENT_TIME,
	CONTROL_FORWARDS,
	CONTROL_PAUSE,
	CONTROL_PLAY,
	CONTROL_REWIND,
	TIMELINE_CURRENT_TIME,
	TIMELINE_TIMINGS,
} from './constants';
import { ControlCurrentTime, Timings } from './types';
import { SERVICES_ALL_LOADED, SERVICE_LOADED } from '../services/constants';

import Events from '../events/Events';
import { LAYERS_CREATED } from '@Map/layers/constants';
import { ServiceLoaded } from '../services/types';

export default class Timeline extends Events {
	private _timings: Timings[] = [];
	private _startTime: number | undefined = undefined;
	private _endTime: number | undefined = undefined;
	private _currentTime: number | undefined = undefined;
	private _step = 10; // 10 mins
	private _jumpStep = 30;
	private _intervalTimer: number | undefined = undefined;
	private _interval = 1000;

	constructor() {
		super();
		this.attachEventListeners();
	}

	/**
	 * Listen to events from EventBridge
	 */
	attachEventListeners(): void {
		this.on(SERVICE_LOADED, this.storeTimings);
		this.on(SERVICES_ALL_LOADED, this.buildTimeline);
		this.on(LAYERS_CREATED, this.buildTimeline);
		this.on(CONTROL_CURRENT_TIME, this.jumpTo);
		this.on(CONTROL_PLAY, this.play);
		this.on(CONTROL_PAUSE, this.stop);
		this.on(CONTROL_REWIND, this.rewind);
		this.on(CONTROL_FORWARDS, this.forwards);
	}

	setStep(step: number, jump?: number): void {
		if (step <= 0) return;
		this._step = step;
		this._jumpStep = jump || step * 3;
	}

	/**
	 * Store animation timings from loaded service
	 */
	storeTimings({ serviceId, animated, timings }: ServiceLoaded): void {
		if (!animated) return;
		let updated = false;
		this._timings = this._timings.map(times => {
			if (times.serviceId === serviceId) {
				updated = true;
				return {
					...timings,
					serviceId,
				};
			}
			return times;
		});
		const { step, jump } = timings ?? {};
		if (step) {
			this.setStep(step, jump);
		}
		if (!updated) {
			this._timings.push({ ...timings, serviceId });
		}
	}

	/**
	 * Create the timeline for animation
	 * Notify the animation control component of start, end and current time
	 */
	buildTimeline(): void {
		this._startTime = undefined;
		this._endTime = undefined;
		this._timings.forEach(({ start, end }) => {
			if (!this._startTime || (start && start < this._startTime)) {
				this._startTime = start;
			}
			if (!this._endTime || (end && end > this._endTime)) {
				this._endTime = end;
			}
		});
		this._currentTime = this._startTime;
		this.fire(TIMELINE_TIMINGS, {
			start: this._startTime,
			end: this._endTime,
		});
		this.fire(TIMELINE_CURRENT_TIME, {
			currentTime: this._currentTime,
			timings: this._timings,
			isPlaying: this._isPlaying(),
		});
	}

	/**
	 * Play animation
	 * Loop to beginning if triggered play at the end
	 */
	play(): void {
		this.stop();
		this._intervalTimer = window.setInterval(() => {
			this._nextStep();
		}, this._interval);
		// loop back if pressing play at the end
		let loopedBack = false;
		if (this._startTime && this._endTime) {
			if (this._currentTime === this._endTime) {
				loopedBack = true;
				this._currentTime = this._startTime;
				this._emitCurrentTime();
			}
		}
		// if looped back then don't show next step straight
		// away to prevent missing the beginning
		if (!loopedBack) this._nextStep();
	}

	/**
	 * Stop animation
	 */
	stop(): void {
		if (this._intervalTimer) {
			clearInterval(this._intervalTimer);
			this._intervalTimer = undefined;
			this._emitCurrentTime();
		}
	}

	/**
	 * Jump to specific time in animation
	 */
	jumpTo({ currentTime }: ControlCurrentTime): void {
		this.stop();
		this._currentTime = currentTime;
		this._emitCurrentTime();
	}

	/**
	 * Rewind animation by time step
	 */
	rewind(): void {
		if (!this._currentTime) return;
		const rewindTo = this._currentTime - this._jumpStep * 60000;
		const currentTime = this._startTime
			? Math.max(rewindTo, this._startTime)
			: rewindTo;
		this.jumpTo({ currentTime });
	}

	/**
	 * Fast forward animation by time step
	 */
	forwards(): void {
		if (!this._currentTime) return;
		const forwardsTo = this._currentTime + this._jumpStep * 60000;
		const currentTime = this._endTime
			? Math.min(forwardsTo, this._endTime)
			: forwardsTo;
		this.jumpTo({ currentTime });
	}

	/**
	 * Go to next step in animation sequence
	 */
	private _nextStep(): void {
		if (!this._currentTime) return;
		this._currentTime += this._step * 60000; // convert mins to ms
		if (this._endTime && this._currentTime > this._endTime) {
			this._currentTime = this._endTime;
			this.stop();
		}
		this._emitCurrentTime();
	}

	/**
	 * Emit current time event
	 */
	private _emitCurrentTime(): void {
		this.fire(TIMELINE_CURRENT_TIME, {
			currentTime: this._currentTime,
			timings: this._timings,
			isPlaying: this._isPlaying(),
		});
	}

	/**
	 * Check if animation is playing
	 */
	private _isPlaying(): boolean {
		return !!this._intervalTimer;
	}
}
