import { getValue, passInId } from './shared';

import { createSelector } from '@reduxjs/toolkit';

export const selectInspectionBase = createSelector(
	[getValue],
	moduleState => moduleState.inspection,
);

export const selectInspections = createSelector(
	[selectInspectionBase],
	baseState => baseState.inspectionAttributes,
);

export const selectInspectionsByAssetId = createSelector(
	[selectInspections, passInId],
	(inspections, selectedItem) => selectedItem && inspections[selectedItem],
);
