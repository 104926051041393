import {
	getSpatialLayers,
	getSpatialLayersRejected,
	getSpatialLayersResolved,
} from '@Actions';
import { put, retry, takeLatest } from '@redux-saga/core/effects';
import { getSpatialLayersApi } from '@Services';
import { SpatialLayer } from '@Types';
import { AxiosResponse, AxiosError } from 'axios';
import { t } from 'i18next';

function* getSpatialLayersSaga() {
	try {
		const payload: AxiosResponse<SpatialLayer[]> = yield retry(
			5,
			30000,
			getSpatialLayersApi,
		);

		yield put(getSpatialLayersResolved(payload.data));
	} catch (e) {
		const err = e as AxiosError;
		const rejection = {
			code: err?.response?.status ?? -1,
			text:
				err?.response?.statusText ??
				t('No additional information') ??
				'',
		};

		yield put(getSpatialLayersRejected(rejection));
	}
}

function* watchGetSpatialLayers() {
	yield takeLatest(getSpatialLayers, getSpatialLayersSaga);
}

export default [watchGetSpatialLayers()];
