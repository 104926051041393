export interface ObservationNotesProps {
	Notes: Note[] | undefined;
	dataCy?: string;
	readOnly?: boolean;
	isWaiting?: boolean;
	keepHeightWhenWaiting?: boolean;
	bodyHeight?: string;
	entryHeight?: string;
	waitingHeight?: string;
	onSubmit?: (newNotes: string, userinitial: string) => void;
}

export enum NoteType {
	SYSTEM = 'system',
	USER = 'user',
}

export interface Note {
	_id: string;
	userId: string;
	note: string;
	userName: string;
	userInitial: string;
	dateTime: string;
	noteType: NoteType;
}

export interface NotesStoreState {
	notes: Note[];
}

export interface GetNotesParams {
	_id: string;
}

export interface GetNotesResolvedParams {
	notes: Note[];
}

export interface AddNoteParams {
	_id: string;
	note: string | string[];
	name: string;
	initial: string;
	noteType?: NoteType;
}
