import { put, retry, takeLatest } from '@redux-saga/core/effects';
import { getRiskConfigurationsApi } from '@Services';
import { AssetType, RiskConfigList } from '@Types';
import { AxiosResponse, AxiosError } from 'axios';
import { t } from 'i18next';
import {
	getRiskConfigsList,
	getRiskConfigsListRejected,
	getRiskConfigsListResolved,
	setRiskConfigsIsBusy,
} from '@Actions/riskConfigs.actions';
import { AnyAction } from 'redux';

function* getRiskConfigsListSaga(action: AnyAction) {
	try {
		yield put(setRiskConfigsIsBusy(true));

		const assetType: AssetType = action.payload.assetType;
		const payload: AxiosResponse<RiskConfigList[]> = yield retry(
			5,
			30000,
			getRiskConfigurationsApi,
			`?assetType=${assetType}&listAll=true`,
		);

		yield put(getRiskConfigsListResolved(payload.data));
		yield put(setRiskConfigsIsBusy(false));
	} catch (e) {
		const err = e as AxiosError;
		const rejection = {
			code: err?.response?.status ?? -1,
			text:
				err?.response?.statusText ??
				t('No additional information') ??
				'',
		};

		yield put(getRiskConfigsListRejected(rejection));
		yield put(setRiskConfigsIsBusy(false));
	}
}

function* watchGetRiskConfigsList() {
	yield takeLatest([getRiskConfigsList], getRiskConfigsListSaga);
}

export default [watchGetRiskConfigsList()];
