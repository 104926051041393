import { GridFilterModel } from '@mui/x-data-grid';

export type Layout = {
	[key: string]: AssetTypeInfo[];
};

export interface AssetTypeInfo {
	section: string;
	showInPanel?: boolean;
	fields: Field[];
}

export interface Field {
	name: string;
	fieldType: string | FieldType;
	fieldKey: string;
	columnWidth: number;
	precision?: number;
	units?: string;
	multiline?: boolean;
	visible?: boolean;
}

export enum FieldType {
	Boolean = 'boolean',
	Date = 'date',
	Number = 'number',
	String = 'string',
}

export interface MapStoreState {
	layout: Layout | undefined;
}

export interface MapGridFilterModel extends GridFilterModel {
	systemType: string;
	assetType: string;
}
