import { printPreviewStyles, rem } from '@Components/styles';
import styled, { css } from 'styled-components';

import { IconButton } from '@mui/material';
import { MaterialDesignContent } from 'notistack';
import MuiCloseIcon from '@mui/icons-material/Close';

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	overflow: hidden;
	position: relative;
	.notistack-SnackbarContainer {
		position: absolute;
	}
`;

export const StyledMaterialDesignContent = styled(MaterialDesignContent)(p => ({
	'&.notistack-MuiContent': {
		minWidth: `${rem(330)} !important`,
		flexWrap: 'nowrap',
	},
	'&.notistack-MuiContent-success': {
		backgroundColor: p.theme.palette.success.dark,
	},
	'&.notistack-MuiContent-error': {
		backgroundColor: p.theme.palette.error.dark,
	},
	'&.notistack-MuiContent-warning': {
		backgroundColor: p.theme.palette.warning.dark,
	},
	'&.notistack-MuiContent-info': {
		backgroundColor: p.theme.palette.secondary.dark,
	},
	'&.notistack-MuiContent-default': {
		backgroundColor: p.theme.palette.secondary.dark,
	},
}));

export const CloseIcon = styled(MuiCloseIcon)`
	width: 1em;
	height: 1em;
`;

export const CloseButton = styled(IconButton).attrs({
	size: 'small',
})`
	float: right;
`;

export const AddressInfo = styled.div`
	font-size: ${rem(14)};
`;

export const PlaceName = styled.div`
	margin-bottom: ${rem(10)};
`;

export const LongLatLocation = styled.div`
	font-size: ${rem(12)};
`;

export const SearchContainer = styled.div`
	position: absolute;
	top: ${rem(8)};
	left: ${rem(14)};
	z-index: 10;
	width: ${rem(312)};
	.MuiInputBase-root {
		background: #fff;
	}
	${printPreviewStyles`
		display: none;
	`}
`;

export const PreviewContainer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
	display: none;
	${printPreviewStyles`
		display: block;
	`}
`;

export const AssetGridWrapper = styled.div<{ show: boolean }>`
	position: absolute;
	height: 50%;
	background: #fff;
	bottom: 0;
	width: 100%;
	z-index: 2;
	transform: translate(0, 100%);
	transition: transform 0.5s;
	${({ show }) =>
		show &&
		css`
			transform: translate(0, 0);
		`};
`;
