import { DependencyList, EffectCallback, useEffect } from 'react';

export const useShallowEqlEffect = (
	callback: EffectCallback,
	dependencies: DependencyList,
): ReturnType<typeof useEffect> => {
	return useEffect(
		callback,
		dependencies.map(dependency => JSON.stringify(dependency)),
	);
};
