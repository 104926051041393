import styled from 'styled-components';

export const StyledDataGrid = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  & .MuiBox-root {
    height: 100%;
  }
  & .unit--cell {
    background-color: #f7f7f7 !important;
    padding: 0 10px !important;
  }
  & .type--cell {
    font-weight: bold;
    background-color: #f7f7f7 !important;
  }
`;

export const StyledCellContainer = styled.div<{ $cellHeight?: number }>`
  line-height: normal;
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
  min-height: ${(props) => props.$cellHeight}px !important;
`;
