/* istanbul ignore file */
/* ignoring file as a lot of it relies on mapbox-gl and it's hard to test */

import { IControl, MapEvent, MapEventType } from 'mapbox-gl';
import React, { useEffect, useState } from 'react';

import ControlButton from '@Components/ControlsContainer/ControlButton';
import { GeoJSONFeature } from '@Map/features/types';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { VectorSqaureIcon } from '@Components/icons/VectorSquare';
import { polygonTheme } from '@Map/styles/drawThemes';
import { useMapboxContext } from '../../context/MapboxProvider';

interface PolygonControlProps {
	onDrawingComplete?: (polygon: GeoJSONFeature) => void;
}

const PolygonControl: React.FC<PolygonControlProps> = ({
	onDrawingComplete,
}) => {
	const [control, setControl] = useState<MapboxDraw | null>(null);
	const [active, setActive] = useState(false);
	const { map, setMapState } = useMapboxContext();

	const setDrawMode = (mode: MapboxDraw.DrawMode, updateControl = true) => {
		updateControl && control?.changeMode(mode as string);
		if (mode === 'simple_select') {
			setTimeout(() => {
				setMapState?.('drawing', false);
				setActive(false);
			}, 0);
		} else {
			setMapState?.('drawing', true);
			setActive(true);
		}
	};

	const onDrawPolygon = () => {
		if (!active) {
			setDrawMode('draw_polygon');
			return;
		}
		setDrawMode('simple_select');
	};

	const onDrawCreate = ({
		features = [],
	}: MapEvent & { features?: GeoJSONFeature[] }) => {
		const currentMode = control?.getMode();

		if (currentMode === 'draw_polygon' && features.length > 0) {
			const polygon = features[0];
			onDrawingComplete?.(polygon);
		}
		control?.deleteAll();
	};

	const onDrawModeChange = ({
		mode,
	}: MapEvent & { mode?: MapboxDraw.DrawMode }) => {
		setDrawMode(mode ?? 'simple_select', false);
	};

	useEffect(() => {
		const control = new MapboxDraw({
			displayControlsDefault: false,
			styles: [...polygonTheme],
		});
		map?.addControl(control as IControl);
		setControl(control);
		return () => {
			setDrawMode('simple_select');
			map?.removeControl(control as IControl);
		};
	}, [map]);

	useEffect(() => {
		map?.on('draw.create' as MapEventType, onDrawCreate);
		map?.on('draw.modechange' as MapEventType, onDrawModeChange);
		return () => {
			map?.off('draw.create' as MapEventType, onDrawCreate);
			map?.off('draw.modechange' as MapEventType, onDrawModeChange);
		};
	}, [map, control]);

	return (
		<ControlButton
			actions={[
				{
					id: 'draw-polygon',
					label: 'Draw Polygon',
					icon: <VectorSqaureIcon />,
					onClick: onDrawPolygon,
					dataCy: 'draw-polygon',
				},
			]}
			active={active}
			dataCy="polygon-control"
		/>
	);
};

export default PolygonControl;
