import React from 'react';

export const WorkOrderIcon = () => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M14.3573 13.007L14.7494 8.41995C15.7912 8.8167 16.7425 9.43155 17.5174 10.2088C18.9304 11.6218 19.8167 13.5708 19.8167 15.7169V16.9072H4.18097V15.7169C4.18097 12.3944 6.29466 9.53828 9.23898 8.42227H9.24362L9.63805 13.0093M9.64037 13.007L9.24826 8.30394C9.24826 7.62877 9.80742 7.0928 10.4826 7.0928H13.5174C14.1926 7.0928 14.7517 7.62877 14.7517 8.30394L14.3596 13.0139M2 16.9072H22"
			stroke="#768D95"
			strokeWidth="1.5"
			strokeMiterlimit="10"
		/>
	</svg>
);
