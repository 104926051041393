import { CompositeLayerProps, Filter, MapboxLayerType } from '../types';
import LayerBase, { Config } from './LayerBase';

import LineSymbolLayer from '../mapbox/LineSymbolLayer';
import MainLayer from './MainLayer';
import SelectedLayer from './SelectedLayer';

export default class LineSymboCreatorlLayer extends LayerBase {
	private _selectedLayer: MapboxLayerType;
	private _selectedItems: string[] = [];
	protected _filter: string[] | undefined = undefined;

	constructor(
		layerInfo: CompositeLayerProps,
		map: mapboxgl.Map,
		config: Config = {},
	) {
		super(layerInfo, map, {
			idPostfix: '-line-symbol',
			zIndexesPerType: { base: 1 },
			...config,
		});
		this._layer = new LineSymbolLayer({
			...layerInfo,
			filter: this._getFilter(),
			id: this.id,
			zIndex: this._getZIndex(layerInfo.zIndex, -3),
			minZoom: this.minZoom,
			maxZoom: this.maxZoom,
			icon: this.icon,
		});
		this._selectedLayer = new LineSymbolLayer({
			...layerInfo,
			filter: this._getSelectedFilter(),
			id: this.selectedId,
			selected: true,
			zIndex: this._getZIndex(layerInfo.zIndex, 999, 'selected'),
			minZoom: this.minZoom,
			maxZoom: this.maxZoom,
			icon: this.icon,
		});
	}

	get minZoom(): CompositeLayerProps['iconMinZoom'] {
		return this.layerInfo.iconMinZoom;
	}

	get maxZoom(): CompositeLayerProps['iconMaxZoom'] {
		return this.layerInfo.iconMaxZoom;
	}

	get selectedItems(): string[] {
		return this._selectedItems;
	}

	set selectedItems(items: string[]) {
		this._selectedItems = items;
		this._resetSelectedFilterOnMap();
	}

	get selectedId(): CompositeLayerProps['id'] {
		return this.layerInfo.id + '-line-symbol-selected';
	}

	get icon(): CompositeLayerProps['icon'] {
		return this.layerInfo.icon;
	}

	draw(): void {
		super.draw();
		this._mapboxUtils.addLayer(this._selectedLayer);
		this._resetSelectedFilterOnMap();
	}

	show(): void {
		super.show();
		this._mapboxUtils.showLayer(this.selectedId);
	}

	hide(): void {
		super.hide();
		this._mapboxUtils.hideLayer(this.selectedId);
	}

	delete(): void {
		super.delete();
		this._mapboxUtils.removeLayer(this.selectedId);
	}

	protected _getFilter = (): Filter | undefined => {
		return MainLayer.getFilter(
			this.properties,
			this.hasClustering,
			this.filter,
		);
	};

	protected _getSelectedFilter = (): Filter | undefined => {
		return SelectedLayer.getFilter(this.selectedItems);
	};

	private _resetSelectedFilterOnMap(): void {
		this._mapboxUtils.setFilter(this.selectedId, this._getSelectedFilter());
	}
}
