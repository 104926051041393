import CancellableFetch, { CancellableFetchParams } from './CancellableFetch';

export interface PaginatedFetchParams extends CancellableFetchParams {
	startOffset?: number;
	startLimit?: number;
	paramLimit?: string;
	paramOffset?: string;
}

interface Pagination {
	current: number;
	next?: number;
	pages: number;
	previous: number;
	total: number;
}

type PaginatedData<T> = T & {
	pagination?: Pagination;
};

export default class PaginatedFetch {
	private url: PaginatedFetchParams['url'];
	private offset: NonNullable<PaginatedFetchParams['startOffset']>;
	private limit: NonNullable<PaginatedFetchParams['startLimit']>;
	private headers: PaginatedFetchParams['headers'];
	private request: CancellableFetch | undefined;
	private paramLimit = 'limit';
	private paramOffset = 'offset';

	constructor({
		url,
		startOffset = 1,
		startLimit = 1000,
		headers,
		paramLimit,
		paramOffset,
	}: PaginatedFetchParams) {
		this.url = url;
		this.offset = startOffset;
		this.limit = startLimit;
		this.headers = headers;
		if (paramLimit) this.paramLimit = paramLimit;
		if (paramOffset) this.paramOffset = paramOffset;
	}

	private generateUrl(): string {
		const url = new URL(this.url);
		const queryParams = new URLSearchParams(url.search);
		queryParams.append(this.paramLimit, this.limit.toString());
		queryParams.append(this.paramOffset, this.offset.toString());
		url.search = queryParams.toString();
		return url.toString();
	}

	private async getPage<T>(): Promise<T> {
		this.cancel();
		this.request = new CancellableFetch({
			url: this.generateUrl(),
			headers: this.headers,
		});
		return await this.request.getPage<T>();
	}

	private async getPageUsingPost<T, R>(body: T): Promise<R | null> {
		this.cancel();
		this.request = new CancellableFetch({
			url: this.url,
			headers: this.headers,
		});
		Object.assign(body as any, {
			pagination: { limit: this.limit, offset: this.offset },
		});
		return await this.request.postPage<T, R>({ body });
	}

	async fetchPages<T>(): Promise<T[]> {
		let keepFetching = true;
		const allData = [];

		while (keepFetching) {
			try {
				const page = await this.getPage<PaginatedData<T>>();

				const { pagination, ...other } = page;

				allData.push(other);

				if (
					!pagination ||
					!pagination.next ||
					pagination.next <= pagination.current
				) {
					keepFetching = false;
				} else {
					this.offset = pagination.next;
				}
			} catch (e) {
				throw e;
			}
		}

		return allData as T[];
	}

	async fethPagesUsingPost<T, R>(body: T): Promise<R[]> {
		let keepFetching = true;
		const allData = [];

		while (keepFetching) {
			try {
				const page = await this.getPageUsingPost<T, PaginatedData<R>>(
					body,
				);

				if (!page) {
					keepFetching = false;
					continue;
				}

				const { pagination, ...other } = page;
				allData.push(other);
				if (
					!pagination ||
					!pagination.next ||
					pagination.next <= pagination.current
				) {
					keepFetching = false;
				} else {
					this.offset = pagination.next;
				}
			} catch (e) {
				throw e;
			}
		}
		return allData as R[];
	}

	cancel(): void {
		if (this.request) {
			this.request.cancel();
			this.request = undefined;
		}
	}
}
