import { StringMap, TOptions, t as i18nextT } from 'i18next';

import settings from './settings.json';

const { ns } = settings;

export interface ExtractTrans {
	text: string;
	options?: TOptions<StringMap>;
}

/** From T, extend the properties K with the Extract type */
export type ExtractionKeys<T, K extends keyof T> = Omit<T, K> &
	{ [P in K]: T[P] | ExtractTrans };

/** for extracting text only, it doesn't translate, the translation would be done else where with a variable */
export const e = (
	text: ExtractTrans['text'],
	options?: ExtractTrans['options'],
): string | ExtractTrans => {
	if (!options) return text;
	return { text, options };
};

/** translate function that can convert special extraction */
export const t = (
	input: string | ExtractTrans,
	options?: ExtractTrans['options'],
): string => {
	if (typeof input === 'string')
		return i18nextT(input, { ...options, ns }) || input;
	else
		return (
			i18nextT(input.text, { ...input.options, ...options, ns }) ||
			input.text
		);
};

export const extractionAsText = (input: string | ExtractTrans): string => {
	if (typeof input === 'string') return input;
	return input.text;
};
