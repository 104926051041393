import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from '@mui/material';

import React from 'react';
import { useGlobalization } from '@Translations/useGlobalization';

interface ConfirmDelettionDialogProps {
	onConfirm: () => void;
	onCancel: () => void;
	open: boolean;
}

export const ConfirmDeletionDialog = ({
	onConfirm,
	onCancel,
	open,
}: ConfirmDelettionDialogProps) => {
	const { t } = useGlobalization();

	return (
		<Dialog
			open={open}
			PaperProps={{
				square: true,
			}}
			data-cy="delete-dialog">
			<DialogTitle>
				{t('Delete theme', {
					context: 'Style editor theme deletion dialog heading',
				})}
			</DialogTitle>
			<DialogContent>
				{t(
					'Do you really want to delete this theme for the map? This action cannot be undone.',
					{ context: 'Style editor theme deletion dialog content' },
				)}
			</DialogContent>
			<DialogActions>
				<Button
					data-cy="confirm-delete"
					variant="contained"
					color="error"
					onClick={onConfirm}>
					{t('Delete', {
						context:
							'Style editor theme deletion dialog confirm button',
					})}
				</Button>
				<Button
					data-cy="cancel-delete"
					variant="contained"
					color="secondary"
					onClick={onCancel}>
					{t('Cancel', {
						context:
							'Style editor theme deletion dialog cancel button',
					})}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
