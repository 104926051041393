import styled from 'styled-components';
import { DEFAULT_CHART_COLORS } from '../../../types';

export const StatusChartContainer = styled.div<{ size: number }>`
  box-sizing: border-box;
  height: 100%;
  padding: 1rem;
  width: 100%;

  ${(props) => {
    let colors = '';
    for (let i = 0; i < props.size; i++) {
      colors += `.highcharts-legend-item.highcharts-series-${i} .highcharts-point {
				fill: ${DEFAULT_CHART_COLORS[i % DEFAULT_CHART_COLORS.length]} !important;
			  }`;
    }
    return colors;
  }}
`;
