import { CompositeLayerProps, Filter } from '../types';

import LayerBase from './LayerBase';

export default class SelectedLayer extends LayerBase {
	protected _selectedItems: string[] = [];

	constructor(layerInfo: CompositeLayerProps, map: mapboxgl.Map) {
		super(layerInfo, map, {
			zIndexesPerType: { base: 1000, line: 998 },
			selected: true,
			idPostfix: '-selected',
		});
	}

	get selectedItems(): string[] {
		return this._selectedItems;
	}

	set selectedItems(items: string[]) {
		this._selectedItems = items;
		this._resetFilterOnMap();
	}

	static getFilter(selectedItems: string[]): Filter {
		return ['in', 'id', ...(selectedItems.length ? selectedItems : [''])];
	}

	protected _getFilter = (): Filter => {
		return SelectedLayer.getFilter(this.selectedItems);
	};
}
