import {
	AddAssetNoteParams,
	AssetCounts,
	AssetDetailMapInsertResolvedResponse,
	AssetDetailParams,
	AssetDetailResolvedResponse,
	AssetLocation,
	AssetLocationParams,
} from '@Types/asset.types';

import { CheckAssetIdsParams } from '@Types/asset.types';
import { addNamespace } from '@Utils/actions';
import { createAction } from '@reduxjs/toolkit';

export const getAssetCounts = createAction<string[]>('assets/getAssetCounts');
export const getAssetCountsResolved = createAction<AssetCounts>(
	'assets/getAssetCounts/resolved',
);
export const getAssetCountsRejected = createAction(
	'assets/getAssetCounts/rejected',
);

export const loadAssetDetails = createAction<AssetDetailParams>(
	'assets/loadAssetDetails',
);

export const getAssetDetail = createAction<AssetDetailParams>(
	'assets/getAssetDetail',
);
export const getAssetDetailResolved = createAction<AssetDetailResolvedResponse>(
	'assets/getAssetDetail/resolved',
);
export const getAssetDetailRejected = createAction(
	'assets/getAssetDetail/rejected',
);
export const getAssetDetailMapInsertResolved =
	createAction<AssetDetailMapInsertResolvedResponse>(
		'assets/getAssetDetailMapInsert/resolved',
	);
export const setAssetDetailIsWaiting = createAction<boolean>(
	'assets/getAssetDetail/isWaiting',
);
export const setAssetDetailNotesIsWaiting = createAction<boolean>(
	'assets/getAssetDetail/notesIsWaiting',
);

export const addAssetDetailNote = createAction<{
	assetDetailParams: AssetDetailParams;
	addNoteParams: AddAssetNoteParams;
}>('assets/addAssetDetailNote');

export const checkAssetIds = createAction<CheckAssetIdsParams>(
	addNamespace('assetDetail/checkAssetIds'),
);

export const checkAssetIdsResolved = createAction<string[]>(
	addNamespace('assetDetail/checkAssetIdsResolved'),
);

export const checkAssetIdsRejected = createAction(
	addNamespace('assetDetail/checkAssetIdsRejected'),
);

export const clearValidAssetIds = createAction(
	addNamespace('assetDetail/clear'),
);

export const setInspectionsValidatingAsset = createAction<boolean>(
	addNamespace('assetDetail/validatingAsset'),
);

export const setAssetLocation = createAction<AssetLocation | undefined>(
	addNamespace('assetDetail/setAssetLocation'),
);

export const setAssetLocationIsWaiting = createAction<boolean>(
	addNamespace('assetDetail/setAssetLocationIsWaiting'),
);

export const getAssetLocation = createAction<AssetLocationParams>(
	'assetDetail/getAssetLocation',
);
