import axios, { AxiosRequestConfig } from 'axios';
import axiosRetry from 'axios-retry';
import cookies from 'browser-cookies';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';

const useCustomKpi = (
  baseURL: string,
  url: string,
  path: string[],
  params?: {
    [param: string]: unknown;
  }
): {
  value: number | string;
  loading: boolean;
} => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const options: AxiosRequestConfig = {
        baseURL: baseURL,
        timeout: 5000,
      };

      const kpiApi = axios.create(options);
      axiosRetry(kpiApi, {
        retries: 3,
        retryDelay: (retryCount) => {
          return retryCount * 500;
        },
      });
      kpiApi.interceptors.request.use(
        (config) => {
          config.headers.Authorization =
            'Bearer ' + cookies.get('auth0.user.token');
          return config;
        },
        (error) => {
          Promise.reject(error);
        }
      );
      const response = await kpiApi.get(url, {
        params: params,
      });
      if (response.status === 200) {
        setData(response.data);
      }
    };
    setLoading(false);
    fetchData();
  }, []);

  return useMemo(
    () => ({ value: _.get(data, path, '0'), loading }),
    [baseURL, url, path, params]
  );
};

export default useCustomKpi;
