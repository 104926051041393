import './ObservationPhoto.style.css';
import {
	Grid,
	ImageList,
	ImageListItem,
	Modal,
	useMediaQuery,
} from '@mui/material';

import { VideoObservation } from '@Types';
import React, { useEffect, useState } from 'react';
import { getTimeCounter } from '@Utils';
import { innovyzeTheme } from '@innovyze/stylovyze';
import styled from 'styled-components';

const RootDiv = styled.div({
	display: 'flex',
	flexWrap: 'wrap',
	justifyContent: 'center',
	overflow: 'auto',
	backgroundColor: innovyzeTheme.palette.background.paper,
	'& .MuiGridListTile-root': {
		paddingLeft: '13px !important',
		paddingRight: '13px !important',
	},
	padding: '5px',
});

const ImagePopup = styled.div({
	position: 'absolute',
	width: 'fit-content',
	minWidth: '200px',
	backgroundColor: innovyzeTheme.palette.background.paper,
	border: '2px solid #000',
	boxShadow: innovyzeTheme.shadows[5],
	padding: innovyzeTheme.spacing(2, 4, 3),
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
});

export const ObservationPhoto = ({
	// observationId,
	time,
	forEdit,
	observations,
	getMediaInspectionId,
	noMinHeight,
}: {
	// observationId: number;
	time: number;
	forEdit: boolean;
	observations: VideoObservation[];
	getMediaInspectionId?: () => string;
	noMinHeight?: boolean;
}): JSX.Element => {
	const [imagePath, setImage] = useState<string>('');

	const [open, setOpen] = React.useState(false);
	const [observationId, setObservationId] = useState(-1);

	// This allows us to size the ImageList as if it was a grid
	const isMD = useMediaQuery(innovyzeTheme.breakpoints.up('md'));
	const isLG = useMediaQuery(innovyzeTheme.breakpoints.up('lg'));
	const isXL = useMediaQuery(innovyzeTheme.breakpoints.up('xl'));

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	let selected: {
		id: number;
		time: number;
		mediaInspectionId?: string;
	}[] = [];

	useEffect(() => {
		if (time >= 0) {
			selected = observations
				?.map((observation: VideoObservation) => {
					return {
						id: observation.conditionId,
						time: getTimeCounter(observation),
						mediaInspectionId: observation.mediaInspectionId,
					};
				})
				.filter(o => o.time <= time)
				.filter(o => {
					if (getMediaInspectionId && o.mediaInspectionId) {
						return getMediaInspectionId() === o.mediaInspectionId;
					}
					return true;
				})
				.sort((a, b) => b.time - a.time);
			if (
				selected &&
				selected.length > 0 &&
				selected[0].id != observationId
			)
				setObservationId(selected[0].id);
		}
	}, [time]);

	const body = () => {
		if (imagePath && imagePath?.trim().length > 0) {
			return (
				<ImagePopup>
					<Grid
						container
						item
						style={{
							justifyContent: 'center',
							position: 'relative',
						}}>
						<img
							src={imagePath}
							alt="observation"
							onError={e => {
								const target = e.target as HTMLImageElement;
								target.src = require('./missing.png');
							}}
						/>
					</Grid>
				</ImagePopup>
			);
		} else return <></>;
	};

	const columns = () => {
		if (isXL) {
			return forEdit ? 4 : 5;
		} else if (isLG) {
			return forEdit ? 3 : 4;
		} else if (isMD) {
			return forEdit ? 2 : 3;
		}
		return 1;
	};

	return (
		<RootDiv style={{ minHeight: noMinHeight ? '' : '250px' }}>
			<ImageList
				rowHeight={120}
				style={{ width: '98%', paddingTop: '12px', overflow: 'hidden' }}
				cols={columns()}>
				{observations
					.filter(
						observation =>
							observation.mediaConditions &&
							observation.mediaConditions.length > 0 &&
							observation.mediaConditions[0].imagePath &&
							observation.mediaConditions[0].imagePath.trim()
								.length > 0,
					)
					.sort((a, b) => {
						return a.distance - b.distance;
					})
					.map(observation => (
						<ImageListItem
							onClick={() => {
								setObservationId(observation.conditionId);
								setImage(
									observation.mediaConditions[0].imagePath,
								);
								handleOpen();
							}}
							key={observation.conditionId}
							cols={1}
							className={'observationPhoto'}>
							<div
								className={
									observation.conditionId == observationId
										? 'selected'
										: ''
								}
								style={{
									height: '100px',
									width: '100px',
									margin: '0 auto',
									background: 'center',
									backgroundSize: 'cover',
									backgroundImage: `url(${
										observation.mediaConditions[0]
											.imageThumnailPath ??
										observation.mediaConditions[0].imagePath
									}), url(${require('./missing.png')}`,
								}}
							/>
						</ImageListItem>
					))}
			</ImageList>

			<div>
				<Modal
					open={open}
					onClose={handleClose}
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description">
					{body()}
				</Modal>
			</div>
		</RootDiv>
	);
};

export default ObservationPhoto;
