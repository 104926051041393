import './InspDetails.component.style.css';

import { FullPage, FullPageWrapper } from '@Utils/styles';
import { Grid, Paper, Switch } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';

import {
	InfoCard,
	getSubscriptions,
	useIsFeatureEnabled,
	useCompanyDateTime,
	useTzDateTime,
} from '@innovyze/stylovyze';
import ReactPlayer from 'react-player';
import { useDispatch } from 'react-redux';
import { addNote, getFullInsp } from '@Actions/fullInspection.actions';
import {
	selectFullInspection,
	selectFullInspectionConditions,
	selectFullInspectionIsWaiting,
	selectMergedValidation,
	selectNotes,
	selectNotesIsWaiting,
} from '@Selectors/fullInspection.selectors';
import { Error } from '@mui/icons-material';
import {
	selectContinuousOnly,
	selectGradeFilters,
	selectJointOnly,
	selectObservations,
	selectStepOnly,
	selectTypeFilters,
} from '@Selectors/observations.selectors';
import { InspectionStandard, UploadStatus } from '@Types/inspection.types';
import {
	MACPObservation,
	MSCCObservation,
	Observation,
	PACPObservation,
	VideoObservation,
	WSA05Observation,
} from '@Types/observation.types';
import ObservationSearchBar from '@Components/ObservationSearchBar';
import {
	clearFilters,
	clearObservations,
	getObservations,
	setContinuousOnly,
	setGradeFilters,
	setJointOnly,
	setStepOnly,
	setTypeFilters,
} from '@Actions/observations.actions';
import InspectionProps from '@Components/PACPInspectionProps';
import MSCCInspectionProps from '@Components/MSCCInspectionProps';
import ObservationNotes from '@Components/ObservationNotes';
import ObservationPhoto from '@Components/ObservationPhoto';
import ObservationTable from '@Components/ObservationTable';
import { selectUserContext } from '@Selectors/user.selectors';
import { FilterSelect, MapInsertInspection } from '@Components';
import Ratings from '@Components/Ratings';
import { viewerAccess } from '@innovyze/shared-utils';
import { MACPFullInspection } from '@Types/fullInspection.types';
import MACPInspectionProps from '@Components/MACPInspectionProps';
import { MACPPipesTab } from './MACPPipesTab';
import ValidationTable from '@Components/ValidationTable';
import { MACPComponentConditions } from '@Components/MACPComponentConditions';
import { TFunc, useGlobalization } from '@Translations';
import {
	ColumnFilterValue,
	IdCaption,
	FilterObservationGradeType,
} from '@Types';
import WSA05InspectionProps from '@Components/WSA05InspectionProps';

export const mapInsertInspection = (
	standard: InspectionStandard,
	inspectionId: string,
	classes: string,
	t: TFunc,
): JSX.Element => {
	return (
		<Grid xs={6} item container style={{ paddingBottom: '0' }}>
			<Grid xs={12} item>
				<InfoCard title={t('Map')} applyBodyPadding={false}>
					<div className={classes}>
						<MapInsertInspection
							standard={standard}
							inspectionId={inspectionId}
						/>
					</div>
				</InfoCard>
			</Grid>
		</Grid>
	);
};
const useStyles = makeStyles(() =>
	createStyles({
		root: {
			flexGrow: 1,
			// padding: '21px' ,
		},
		subRoot: {
			'&::first': {
				marginLeft: 0,
			},
			flexGrow: 1,
			padding: '21px',
			margin: '1rem',
		},
		paper: {
			margin: '1rem',
			textAlign: 'center',
			backgroundColor: '#F1F4F4',
		},
		pipePaper: {
			margin: '1rem',
			textAlign: 'center',
		},
		carouselItem: {
			width: '80px',
			height: '80px',
			backgroundColor: '#F1F4F4',
		},
		switchText: {
			fontSize: '0.75rem',
		},
		map: {
			height: '685px',
			padding: '20px',
		},
		overflowAuto: {
			overflow: 'auto',
		},
	}),
);

// InspDetails is currently a subset of Details in app_am_inspection.
// What is here is used in other repos EXCEPT app_am_inspections.
// Idealy functionality should be moved from app_am_inspection:Details
// into here as it is needed in app_am_assets/app_am_risk etc.
//
// eslint-disable-next-line @typescript-eslint/naming-convention
export const InspDetails = ({
	back,
	standard,
	inspectionId,
	pipeIdClicked,
}: {
	back: { action: () => void; label: string };
	standard: InspectionStandard;
	inspectionId: string;
	pipeIdClicked: (pipeId: string) => void;
}): JSX.Element => {
	const { t } = useGlobalization();
	const dispatch = useDispatch();
	const { formatDate } = useCompanyDateTime();
	const formatDateUTC = useTzDateTime({ timeZone: 'UTC' }).formatDate;
	const enableMediaTranscode =
		useIsFeatureEnabled('info-360-media-convert') !== undefined;

	useEffect(() => {
		dispatch(
			getFullInsp({
				standard: standard,
				inspectionId: inspectionId,
				formatDate: formatDate,
				formatDateUTC: formatDateUTC,
				enableMediaTranscode: enableMediaTranscode,
			}),
		);
	}, [formatDate, dispatch, inspectionId, standard]);

	const notes = selectNotes();

	const [player, setPlayer] = useState<ReactPlayer>();
	const jointOnly = selectJointOnly();
	const continuousOnly = selectContinuousOnly();
	const stepOnly = selectStepOnly();
	const gradeFilters = selectGradeFilters();
	const typeFilters = selectTypeFilters();
	const [hasErrors /*setHasErrors*/] = useState<boolean>(false);
	const [readOnly, setReadOnly] = useState<boolean>(true);
	const isLoading = selectFullInspectionIsWaiting();
	const isNotesLoading = selectNotesIsWaiting();

	const mergedValidation = selectMergedValidation(standard);

	const subscriptions = getSubscriptions();
	const hasViewerAccess = viewerAccess(subscriptions);

	const [searchCode, setSearchCode] = useState('');
	const onSearchSubmit = (code: string) => {
		setSearchCode(code);
	};
	const [mediaInspection, setMediaInspection] = useState<{
		videoLocation: string;
		mediaInspectionId: string;
	}>();

	type videoTime = {
		played: number;
	};
	const [playerTime, setTime] = useState<videoTime>({ played: 0 });
	const [savedTime, setSavedTime] = useState(0);

	const onPlayer = (progress: {
		played: number;
		playedSeconds: number;
		loaded: number;
		loadedSeconds: number;
	}) => {
		if (player) {
			setTime({
				played: progress.playedSeconds,
			});
		}
	};

	const classes = useStyles();

	const getUniqueCodes = () => {
		const observations = selectObservations(standard);
		const codes = observations?.map(observation => {
			switch (standard) {
				case InspectionStandard.MACP:
					return (observation as MACPObservation).macpCode;
				case InspectionStandard.MSCC:
					return (observation as MSCCObservation).code;
				case InspectionStandard.WSA05:
					return (observation as WSA05Observation).code;
				case InspectionStandard.PACP:
				default:
					return (observation as PACPObservation).PACPCode;
			}
		});

		if (codes) return Array.from(new Set(codes));
		else return [];
	};

	const videoSeekTo = (
		standard: InspectionStandard,
		defect: VideoObservation,
	) => {
		let sTime = '';
		switch (standard) {
			case InspectionStandard.MACP:
				sTime = (defect as MACPObservation).vcrTime;
				break;
			case InspectionStandard.MSCC:
				sTime = (defect as MSCCObservation).videoRef;
				break;
			case InspectionStandard.WSA05:
				sTime = (defect as WSA05Observation).videoPosition;
				break;
			case InspectionStandard.PACP:
			default:
				sTime = (defect as PACPObservation).VCRTime;
				break;
		}
		if (sTime === null || sTime.length < 6) return;

		const sHour = sTime.substring(0, 2);
		const sMin = sTime.substring(2, 4);
		const sSec = sTime.substring(4, 6);

		const nSec = Number(sHour) * 360 + Number(sMin) * 60 + Number(sSec);
		setSavedTime(nSec);

		if (player) player.seekTo(nSec);
	};

	const onRowClick = (standard: InspectionStandard, defect: Observation) => {
		if (
			defect.mediaInspectionId &&
			defect.mediaInspectionId !== mediaInspection?.mediaInspectionId
		) {
			const newMediaInspection = inspection.mediaInspections?.find(
				(i: Record<string, unknown>) =>
					i.mediaId === defect.mediaInspectionId,
			);
			setMediaInspection({
				videoLocation: newMediaInspection?.videoLocation ?? '',
				mediaInspectionId: newMediaInspection?.mediaId ?? '',
			});
		}
		videoSeekTo(standard, defect as VideoObservation);
	};

	const inspection = selectFullInspection();

	useEffect(() => {
		if (inspection) {
			setReadOnly(
				isLoading ||
					inspection.inspectionStatus == UploadStatus.Approved,
			);
			if (
				inspection.mediaInspections &&
				inspection.mediaInspections.length > 0
			) {
				setMediaInspection({
					videoLocation: inspection?.mediaInspections[0]
						?.videoLocation as string,
					mediaInspectionId: inspection?.mediaInspections[0]
						?.mediaId as string,
				});
			}
		}
	}, [inspection, isLoading]);

	const getGradeTypes = (standard: InspectionStandard): IdCaption[] => {
		switch (standard) {
			case InspectionStandard.MSCC:
			case InspectionStandard.WSA05:
				{
					return [
						{
							id: FilterObservationGradeType.Struct,
							caption: t('Struct.'),
						},
						{
							id: FilterObservationGradeType.Service,
							caption: t('Service'),
						},
					];
				}
				break;
			case InspectionStandard.MACP:
			case InspectionStandard.PACP:
			default:
				{
					return [
						{
							id: FilterObservationGradeType.Struct,
							caption: t('Struct.'),
						},
						{
							id: FilterObservationGradeType.OM,
							caption: t('O&M'),
						},
					];
				}
				break;
		}
	};

	const gradeFilterChange = (value: ColumnFilterValue) => {
		dispatch(setGradeFilters(value.value as string[]));
	};

	const gradeTypeFilterChange = (value: ColumnFilterValue) => {
		dispatch(setTypeFilters(value.value as string[]));
	};

	let filterWidth = 3;
	if (standard == InspectionStandard.MACP) filterWidth = 2;
	else if (standard == InspectionStandard.WSA05) filterWidth = 4;

	const observationsTab = (
		<FullPage
			style={{ marginTop: '-20px', overflow: 'hidden' }}
			className={isLoading ? 'wait' : ''}>
			<Paper className={classes.root}>
				<Grid container spacing={4}>
					<Grid xs={7} item>
						<Grid
							xs={12}
							item
							container
							style={{ paddingTop: '15px' }}>
							<Grid xs={4} item>
								<Paper
									id="observationSearchBar"
									elevation={0}
									style={{ height: '50px' }}>
									<ObservationSearchBar
										label={t('Search Code')}
										codes={getUniqueCodes()}
										handleSearchSubmit={onSearchSubmit}
									/>
								</Paper>
							</Grid>
							<Grid
								xs={8}
								item
								container
								justifyContent="space-between">
								{standard != InspectionStandard.WSA05 ? (
									<Grid
										xs={filterWidth}
										item
										paddingRight={'10px'}>
										<FilterSelect
											column={{
												id: 'grade',
												caption: t('Grade'),
											}}
											value={gradeFilters}
											options={[
												{
													id: '1',
													caption: t('Grade 1'),
												},
												{
													id: '2',
													caption: t('Grade 2'),
												},
												{
													id: '3',
													caption: t('Grade 3'),
												},
												{
													id: '4',
													caption: t('Grade 4'),
												},
												{
													id: '5',
													caption: t('Grade 5'),
												},
											]}
											onFilterChange={gradeFilterChange}
											fullWidth={true}
											multiple={true}
											dataCy={'gradeFilter'}
										/>
									</Grid>
								) : (
									[]
								)}
								<Grid
									xs={filterWidth}
									item
									paddingRight={'15px'}>
									<FilterSelect
										column={{
											id: 'type',
											caption: t('Type'),
										}}
										value={typeFilters}
										options={getGradeTypes(standard)}
										onFilterChange={gradeTypeFilterChange}
										fullWidth={true}
										multiple={true}
										dataCy={'gradeTypeFilter'}
									/>
								</Grid>
								<Grid
									xs={filterWidth}
									item
									container
									textAlign={'center'}>
									<Grid
										xs={12}
										item
										className={classes.switchText}>
										{t('Only Joint')}
									</Grid>
									<Grid xs={12} item>
										<Switch
											checked={jointOnly}
											onChange={() => {
												dispatch(
													setJointOnly(!jointOnly),
												);
											}}
											color="primary"
											name="checkedB"
											inputProps={{
												'aria-label':
													'primary checkbox',
											}}
											size="small"
										/>
									</Grid>
								</Grid>
								{standard == InspectionStandard.MACP ? (
									<Grid
										xs={filterWidth}
										item
										container
										textAlign={'center'}>
										<Grid
											xs={12}
											item
											className={classes.switchText}>
											{t('Only Step')}
										</Grid>
										<Grid xs={12} item>
											<Switch
												checked={stepOnly}
												onChange={() => {
													dispatch(
														setStepOnly(!stepOnly),
													);
												}}
												color="primary"
												name="checkedB"
												inputProps={{
													'aria-label':
														'primary checkbox',
												}}
												size="small"
											/>
										</Grid>
									</Grid>
								) : (
									[]
								)}
								<Grid
									xs={filterWidth}
									item
									container
									textAlign={'center'}>
									<Grid
										xs={12}
										item
										className={classes.switchText}>
										{t('Only Continuous')}
									</Grid>
									<Grid xs={12} item>
										<Switch
											checked={continuousOnly}
											onChange={() => {
												dispatch(
													setContinuousOnly(
														!continuousOnly,
													),
												);
											}}
											color="primary"
											name="checkedB"
											inputProps={{
												'aria-label':
													'primary checkbox',
											}}
											size="small"
										/>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						<Grid xs={12} item>
							<Paper
								id="ObservationTable"
								elevation={0}
								className={classes.paper}>
								<ObservationTable
									standard={standard}
									observationsSelector={selectObservations}
									getObservationAction={getObservations}
									inspectionId={inspectionId}
									onObservationRowClicked={onRowClick}
									getMediaInspectionId={() =>
										mediaInspection?.mediaInspectionId ?? ''
									}
									playerTime={playerTime.played}
									searchCode={searchCode}
									jointOnly={jointOnly}
									continuousOnly={continuousOnly}
									gradeFilters={gradeFilters}
									typeFilters={typeFilters}
									readOnly={readOnly}
									isLoading={isLoading}
								/>
							</Paper>
						</Grid>
					</Grid>
					<Grid xs={5} item container>
						<Grid xs={12} direction="row">
							<Grid item>
								<Paper
									id="VideoPlayer"
									elevation={1}
									className={classes.paper}
									style={{ height: '380px' }}>
									<ReactPlayer
										ref={(PlayerInput: ReactPlayer) => {
											setPlayer(PlayerInput);
										}}
										height="100%"
										width="100%"
										controls={true}
										onReady={() => {
											player?.seekTo(savedTime);
										}}
										url={mediaInspection?.videoLocation}
										onProgress={onPlayer}
									/>
								</Paper>
							</Grid>
							<Grid xs={12} item>
								<ObservationPhoto
									forEdit={false}
									observations={
										(selectFullInspectionConditions() as VideoObservation[]) ??
										[]
									}
									time={playerTime.played}
									getMediaInspectionId={() =>
										mediaInspection?.mediaInspectionId ?? ''
									}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Paper>
		</FullPage>
	);

	const userContext = selectUserContext();
	const onSubmitNote = (newNotes: string, userInitial: string) => {
		dispatch(
			addNote({
				_id: inspectionId,
				note: newNotes,
				name: userContext.name,
				initial: userInitial,
			}),
		);
	};

	const pacpDetailsTab = (
		<div className={isLoading || isNotesLoading ? 'wait' : ''}>
			<Grid container spacing={4} style={{ marginTop: '-35px' }}>
				{useIsFeatureEnabled('info-360-am-dashboard-map')
					? mapInsertInspection(
							standard,
							inspectionId ?? '',
							classes.map,
							t,
					  )
					: []}
				<Grid
					xs={
						useIsFeatureEnabled('info-360-am-dashboard-map')
							? 6
							: 12
					}
					item
					container
					style={{ paddingBottom: '0' }}>
					<Grid xs={12} item container>
						<Grid xs={12} item>
							<InfoCard
								title={t('Ratings')}
								applyBodyPadding={false}>
								<Ratings
									standard={standard}
									ratings={inspection.ratings}
									mainScoreCardHeight={116}
								/>
							</InfoCard>
						</Grid>
						<Grid xs={12} item style={{ paddingTop: '15px' }}>
							<InfoCard title={t('Notes')}>
								<ObservationNotes
									Notes={notes}
									onSubmit={onSubmitNote}
									readOnly={hasViewerAccess}
									isWaiting={isLoading}
									keepHeightWhenWaiting={true}
									waitingHeight={'314px'}
									bodyHeight={
										hasViewerAccess ? '314px' : '216px'
									}
									entryHeight={
										hasViewerAccess ? '0px' : '90px'
									}
								/>
							</InfoCard>
						</Grid>
					</Grid>
				</Grid>
				<Grid xs={12} item>
					<InfoCard
						title={t('Properties')}
						applyBodyPadding={isLoading}>
						<InspectionProps />
					</InfoCard>
				</Grid>
			</Grid>
		</div>
	);

	const macpDetailsTab = (
		<div className={isLoading || isNotesLoading ? 'wait' : ''}>
			<Grid container spacing={4} style={{ marginTop: '-35px' }}>
				{useIsFeatureEnabled('info-360-am-dashboard-map')
					? mapInsertInspection(
							standard,
							inspectionId ?? '',
							classes.map,
							t,
					  )
					: []}
				<Grid
					xs={
						useIsFeatureEnabled('info-360-am-dashboard-map')
							? 6
							: 12
					}
					item
					container
					style={{ paddingBottom: '0' }}>
					<Grid xs={12} item container>
						<Grid xs={12} item>
							<InfoCard
								title={t('Component Conditions')}
								applyBodyPadding={false}>
								<MACPComponentConditions
									fullInsp={inspection as MACPFullInspection}
								/>
							</InfoCard>
						</Grid>
					</Grid>
				</Grid>
				<Grid xs={6} item style={{ paddingBottom: '0' }}>
					<InfoCard title={t('Notes')}>
						<ObservationNotes
							Notes={notes}
							onSubmit={onSubmitNote}
							readOnly={hasViewerAccess}
							keepHeightWhenWaiting={true}
							waitingHeight={'221px'}
							bodyHeight={hasViewerAccess ? '221px' : '139px'}
							entryHeight={hasViewerAccess ? '0px' : '90px'}
							isWaiting={isLoading}
						/>
					</InfoCard>
				</Grid>
				<Grid xs={6} item style={{ paddingBottom: '0' }}>
					<InfoCard title={t('Ratings')} applyBodyPadding={false}>
						<Ratings
							standard={standard}
							ratings={inspection.ratings}
							mainScoreCardHeight={116}
						/>
					</InfoCard>
				</Grid>
				<Grid xs={12} item>
					<InfoCard
						title={t('Properties')}
						applyBodyPadding={isLoading}>
						<MACPInspectionProps />
					</InfoCard>
				</Grid>
			</Grid>
		</div>
	);

	const msccDetailsTab = (
		<div className={isLoading || isNotesLoading ? 'wait' : ''}>
			<Grid container spacing={4} style={{ marginTop: '-35px' }}>
				{useIsFeatureEnabled('info-360-am-dashboard-map')
					? mapInsertInspection(
							standard,
							inspectionId ?? '',
							classes.map,
							t,
					  )
					: []}
				<Grid
					xs={
						useIsFeatureEnabled('info-360-am-dashboard-map')
							? 6
							: 12
					}
					item
					container
					style={{ paddingBottom: '0' }}>
					<Grid xs={12} item container>
						<Grid xs={12} item>
							<InfoCard
								title={t('Ratings')}
								applyBodyPadding={false}>
								<Ratings
									standard={standard}
									ratings={inspection.ratings}
									mainScoreCardHeight={116}
								/>
							</InfoCard>
						</Grid>
						<Grid xs={12} item style={{ paddingTop: '15px' }}>
							<InfoCard title={t('Notes')}>
								<ObservationNotes
									Notes={notes}
									onSubmit={onSubmitNote}
									readOnly={hasViewerAccess}
									isWaiting={isLoading}
									keepHeightWhenWaiting={true}
									waitingHeight={'417px'}
									bodyHeight={
										hasViewerAccess ? '417px' : '335px'
									}
									entryHeight={
										hasViewerAccess ? '0px' : '90px'
									}
								/>
							</InfoCard>
						</Grid>
					</Grid>
				</Grid>
				<Grid xs={12} item>
					<InfoCard
						title={t('Properties')}
						applyBodyPadding={isLoading}>
						<MSCCInspectionProps />
					</InfoCard>
				</Grid>
			</Grid>
		</div>
	);

	const wsa05DetailsTab = (
		<div className={isLoading || isNotesLoading ? 'wait' : ''}>
			<Grid container spacing={4} style={{ marginTop: '-35px' }}>
				{useIsFeatureEnabled('info-360-am-dashboard-map')
					? mapInsertInspection(
							standard,
							inspectionId ?? '',
							classes.map,
							t,
					  )
					: []}
				<Grid
					xs={
						useIsFeatureEnabled('info-360-am-dashboard-map')
							? 6
							: 12
					}
					item
					container
					style={{ paddingBottom: '0' }}>
					<Grid xs={12} item container>
						<Grid xs={12} item>
							<InfoCard
								title={t('Ratings')}
								applyBodyPadding={false}>
								<Ratings
									standard={standard}
									ratings={inspection.ratings}
									mainScoreCardHeight={116}
								/>
							</InfoCard>
						</Grid>
						<Grid xs={12} item style={{ paddingTop: '15px' }}>
							<InfoCard title={t('Notes')}>
								<ObservationNotes
									Notes={notes}
									onSubmit={onSubmitNote}
									readOnly={hasViewerAccess}
									keepHeightWhenWaiting={true}
									waitingHeight={'417px'}
									bodyHeight={
										hasViewerAccess ? '417px' : '335px'
									}
									entryHeight={
										hasViewerAccess ? '0px' : '90px'
									}
									isWaiting={isLoading}
								/>
							</InfoCard>
						</Grid>
					</Grid>
				</Grid>
				<Grid xs={12} item>
					<InfoCard
						title={t('Properties')}
						applyBodyPadding={isLoading}>
						<WSA05InspectionProps />
					</InfoCard>
				</Grid>
			</Grid>
		</div>
	);

	const validationTab = (
		<FullPage
			style={{ marginTop: '-20px', overflow: 'hidden' }}
			className={isLoading ? 'wait' : ''}>
			<Grid container spacing={2}>
				<Grid xs={12} item>
					<ValidationTable
						dataCy="validation-table"
						validationData={mergedValidation}
					/>
				</Grid>
			</Grid>
		</FullPage>
	);

	const getTabs = () => {
		let tabs = [];

		let content = null;
		switch (standard) {
			case InspectionStandard.MACP:
				content = macpDetailsTab;
				break;
			case InspectionStandard.MSCC:
				content = msccDetailsTab;
				break;
			case InspectionStandard.WSA05:
				content = wsa05DetailsTab;
				break;
			case InspectionStandard.PACP:
			default:
				content = pacpDetailsTab;
				break;
		}

		tabs = [
			{ content: observationsTab, title: t('Observations') },
			{
				content: content,
				title: t('Details'),
			},
			{
				content: validationTab,
				title: t('Validation'),
				disabled:
					mergedValidation === undefined ||
					mergedValidation.length === 0,
				icon: hasErrors ? <Error /> : [],
			},
		];
		if (standard == InspectionStandard.MACP)
			tabs.push({
				content: (
					<MACPPipesTab
						macpInsp={inspection as MACPFullInspection}
						pipeIdClick={pipeIdClicked}
					/>
				),
				title: t('Pipes'),
			});
		return tabs;
	};

	const goBack = () => {
		dispatch(clearFilters());
		dispatch(clearObservations());
		back.action();
	};

	return (
		<FullPageWrapper
			className={classes.overflowAuto}
			back={{
				action: goBack,
				label: back.label,
			}}
			title={t('Inspection: {{ID}}', { ID: inspection.inspectionId })}
			applyPadding={true}
			headerUnderline
			secondaryNav={{
				items: getTabs(),
			}}></FullPageWrapper>
	);
};

export default InspDetails;
