import { DataServiceType, TileJson } from './types';

import DataService from './DataService';
import { LngLatBounds } from 'mapbox-gl';
import MapboxDataSource from './MapboxDataSource';
import { SERVICE_RELOADED } from './constants';
import { Timings } from '@Map/timeline/types';

export default class TileService extends DataService<TileJson> {
	protected _animationSupported = true;
	protected _bounds: number[] | undefined;
	protected _token: string | undefined;
	protected _vectorLayers: TileJson['vector_layers'] | undefined;

	constructor(dataService: DataServiceType<TileJson>) {
		super(dataService);
		if (!this.endpoint) return;

		this._token = dataService.token;
		this._requestHeaders = [
			['Authorization', `Bearer ${dataService.token}`],
			['Accept', 'application/json'],
		];

		this._propRequestHeaders = this._requestHeaders;
	}

	get requiresCredentials(): boolean {
		return !!this._token;
	}

	get baseUrl(): string | null {
		return new URL(`${this.endpoint}`).origin;
	}

	createDataSources(data: TileJson): void {
		const { startTime } = this.getAnimationProps();
		const tileUrl = this._replaceTimestamp(data.tiles, startTime);

		const dataSources = new MapboxDataSource({
			type: data.type ?? 'vector',
			id: this.id,
			minZoom: this._parseZoom(data.minzoom),
			maxZoom: this._parseZoom(data.maxzoom),
			tileUrl,
		});

		this._bounds = data.bounds;
		this._vectorLayers = data.vector_layers;

		this._dataSources = [dataSources];
	}

	validData(): boolean {
		return true;
	}

	get bounds(): LngLatBounds | undefined {
		if (this._bounds) {
			const [swLng, swLat, neLng, neLat] = this._bounds;
			return new LngLatBounds([
				[swLng, swLat],
				[neLng, neLat],
			]);
		}
	}

	private _parseZoom(zoom: string | number | undefined) {
		if (!zoom) return;
		return +zoom;
	}

	getTileSource(): string | undefined {
		return this.id;
	}

	getTileLayerName(regex: RegExp): string | undefined {
		const sources = this._vectorLayers;
		if (!sources || (sources && !sources.length)) return;
		return sources.find(({ id }) => id && regex.test(id))?.id;
	}

	animationTimings(): Timings | undefined {
		if (!this._animate) return;
		const {
			startTime,
			endTime,
			stepDuration,
			jumpDuration,
		} = this.getAnimationProps();
		return {
			start: startTime,
			end: endTime,
			step: stepDuration,
			jump: jumpDuration,
		};
	}

	setTimelineCurrentTime(currentTime: number): void {
		const tilesUrl = this._replaceTimestamp(this.data?.tiles, currentTime);
		if (this._dataSources?.[0] && tilesUrl) {
			this._dataSources[0].tileUrl = tilesUrl;
		}
		this.fire(SERVICE_RELOADED);
	}

	private getAnimationProps() {
		const defaultAnimateProps = TileService.defaultAnimateProps;
		const animateProps =
			typeof this._animate === 'object' ? this._animate : {};
		return {
			...defaultAnimateProps,
			...animateProps,
		};
	}

	private _replaceTimestamp(
		tilesUrl: string[] | undefined,
		currentTime: number | undefined,
	) {
		if (!tilesUrl) return tilesUrl;
		const formattedDate = currentTime
			? new Date(currentTime).toISOString()
			: '';
		return tilesUrl.map(url => url.replace(/\{timestamp\}/, formattedDate));
	}

	update(dataService: DataServiceType<TileJson>): boolean {
		const changed = super.update(dataService);

		if (!this.endpoint) {
			throw new Error('Required tiles url missing.');
		}
		this._token = dataService.token;
		this._requestHeaders = [
			['Authorization', `Bearer ${dataService.token}`],
			['Accept', 'application/json'],
		];

		this._propRequestHeaders = this._requestHeaders;

		return changed;
	}
}
