import { createSelector } from '@reduxjs/toolkit';

import type { AsyncData } from '../types/async.types';
import type { MassBalance } from '../types/massBalance.types';
import type { StoreState } from '../../store/store';

export const selectMassBalancesFromWorkspace = (state: StoreState) => {
  return state.workspaces?.externalResources?.massBalances;
};

export const makeSelectMassBalanceByIdFromWorkspace = () => {
  return createSelector(
    selectMassBalancesFromWorkspace,
    (_: unknown, massBalanceId: string) => massBalanceId,
    (massBalances, massBalanceId): AsyncData<MassBalance> => {
      if (massBalances === undefined) {
        return { status: 'rejected', data: null };
      }

      const storedMassBalance = massBalances.find(
        (massBalance) => massBalance.massBalanceId === massBalanceId
      );

      if (!storedMassBalance) {
        return { status: 'rejected', data: null };
      }

      return { status: 'resolved', data: storedMassBalance };
    }
  );
};
