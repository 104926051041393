import { NamespacedStoreState } from '@Types';
import { createSelector } from '@reduxjs/toolkit';
import { moduleNamespace } from '@Store/config.store';
import { useSelector } from 'react-redux';
import { rowsPerPage } from '@Reducers/paginationUtils';
import { RiskConfigCard } from '@Types/riskConfig.types';

const parseNamespace = (state: NamespacedStoreState) => state[moduleNamespace];

const riskConfigsBaseSelector = createSelector(
	parseNamespace,
	state => state.riskConfigs,
);

export const selectorRiskConfigsPagination = createSelector(
	parseNamespace,
	state => state.riskConfigs.pagination,
);

export const selectorRiskConfigsPaginationQuery = createSelector(
	parseNamespace,
	state => state.riskConfigs.query,
);

export const riskConfigsSelector = createSelector(
	riskConfigsBaseSelector,
	baseState => baseState.cards as RiskConfigCard[],
);

const selectorCanReload = createSelector(
	parseNamespace,
	state => state.riskConfigs.pagination.total > 0,
);
export const selectCanReload = () => useSelector(selectorCanReload);

export const selectorPaginationQuery = createSelector(
	parseNamespace,
	state => state.riskConfigs.query,
);

export const riskConfigsStatusSelector = createSelector(
	riskConfigsBaseSelector,
	baseState => baseState.status,
);

export const riskConfigsIsWaitingSelector = createSelector(
	riskConfigsBaseSelector,
	baseState => baseState.isWaiting,
);

const selectorRiskConfigsPage = () => {
	const pagination = selectorRiskConfigsPagination;
	const cards = riskConfigsSelector;

	return createSelector(pagination, cards, (pagination, cards) => ({
		total: pagination.total,
		pages: pagination.pages,
		page: pagination.page,
		cards: cards,
		sortKey: pagination.sortKey,
		sortDescending: pagination.sortDescending,
	}));
};

const selectorRiskConfigsSlice = createSelector(
	parseNamespace,
	state => state.riskConfigs.pagination.slice,
);

const selectorRiskConfigIsLoading = createSelector(
	parseNamespace,
	state => state.riskConfigs.isLoading,
);

const selectorRiskConfigIsWaiting = createSelector(
	parseNamespace,
	state => state.riskConfigs.isWaiting,
);

export const selectRiskConfigs = () => useSelector(riskConfigsSelector);
export const selectRiskConfigsStatus = () =>
	useSelector(riskConfigsStatusSelector);

export const selectorRiskConfigsPerPage = (
	dispatchSliceFn: (slice: number) => void,
) =>
	createSelector(selectorRiskConfigsSlice, slice =>
		rowsPerPage(slice || 10, dispatchSliceFn),
	);

export const selectRiskConfigsPerPage = (
	dispatchSliceFn: (slice: number) => void,
) => useSelector(selectorRiskConfigsPerPage(dispatchSliceFn));

export const selectRiskConfigsPage = () =>
	useSelector(selectorRiskConfigsPage());
export const selectRiskConfigsIsLoading = () =>
	useSelector(selectorRiskConfigIsLoading);
export const selectRiskConfigsIsWaiting = () =>
	useSelector(selectorRiskConfigIsWaiting);
export const selectRiskConfigsPagination = () =>
	useSelector(selectorRiskConfigsPagination);

export default selectRiskConfigs;
