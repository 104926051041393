import { rem } from '@Components/styles';
import styled from 'styled-components';

type ControlsWrapperProps = {
	top: number;
	layerPanelWidth: number;
	layerPanelOpen: boolean;
	layerPanelEditorOpen: boolean;
};

function transform(
	layerPanelWidth: number,
	open: boolean,
	editorOpen: boolean,
) {
	if (open && editorOpen) {
		return `translateX(-${rem(layerPanelWidth)})`;
	} else if (open) {
		return `translateX(-${rem(layerPanelWidth / 2)})`; // This assumes the layer panel translation stays on 50% when editor is closed
	}
	return 'translateX(0)';
}

export const ControlsWrapper = styled.div.attrs<ControlsWrapperProps>(
	props => ({
		style: {
			top: `${props.top}px`,
			transform: transform(
				props.layerPanelWidth,
				props.layerPanelOpen,
				props.layerPanelEditorOpen,
			),
		},
		id: 'map-controls-container',
	}),
)<ControlsWrapperProps>`
	transition: transform 0.5s;
	position: absolute;
	top: 0;
	right: 0;
	display: flex;
	flex-direction: column;
	padding: ${rem(10)};
	z-index: 1;
`;
