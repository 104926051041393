import {
	DISMISS_MESSAGE,
	MESSAGE_CLOSED,
	MessageEvent,
	NEW_MESSAGE,
} from '@Map/notifications/Messages';
import React, {
	MutableRefObject,
	forwardRef,
	useCallback,
	useState,
} from 'react';
import { SnackbarKey, useSnackbar } from 'notistack';
import { useDebouncedCallback } from '@Hooks/useDebouncedCallback';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { dispatchTargetedEvent } from '@Map/utils';
import { useEventListener } from '@Hooks/useEventListener';
import { useGlobalization } from '@Translations/useGlobalization';

export const Messages = forwardRef<
	HTMLDivElement | null,
	{ onRefCallback: (element: HTMLDivElement) => void }
>(function Messages(props, ref) {
	const [lastKey, setLastKey] = useState('');
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	// Moved this here from MessagesSnackbarContext.tsx because it caused
	// a startup error in the app_am_assets repo
	const { t } = useGlobalization();

	const onDismiss = useCallback(
		(key: SnackbarKey | undefined) => () => {
			closeSnackbar(key);
			dispatchTargetedEvent(
				(ref as MutableRefObject<HTMLDivElement>)?.current,
				MESSAGE_CLOSED,
				{ key },
			);
		},
		[closeSnackbar, ref],
	);

	const action = useCallback(
		(key: SnackbarKey | undefined) => (
			<IconButton type="button" onClick={onDismiss(key)}>
				<CloseIcon htmlColor="white" />
			</IconButton>
		),
		[onDismiss],
	);

	const updateMessage = useDebouncedCallback(e => {
		const result = e.detail as MessageEvent;
		if (result.key === lastKey) {
			return;
		}
		closeSnackbar(lastKey);
		enqueueSnackbar(t(result.message.text, result.message.options), {
			persist: result.persist,
			key: result.key,
			variant: result.variant,
			action,
		});
		setLastKey(result.key);
	}, 100);

	useEventListener(
		NEW_MESSAGE,
		updateMessage,
		ref as MutableRefObject<HTMLDivElement>,
	);

	const dismissMessage = useCallback(
		e => {
			const key = e.detail.key;
			closeSnackbar(key);
		},
		[closeSnackbar],
	);

	useEventListener(
		DISMISS_MESSAGE,
		dismissMessage,
		ref as MutableRefObject<HTMLDivElement>,
	);

	const refCallback = useCallback(
		(element: HTMLDivElement) => {
			(ref as MutableRefObject<HTMLDivElement>).current = element;
			props.onRefCallback(element);
		},
		[props, ref],
	);

	return <div ref={refCallback} />;
});
