import {
  gaugeInstanceInitialOptions,
  lineInstanceInitialOptions,
} from './gauge-chart-options';
import { Status, createChart, createSlots } from '../../core/_summaryze-chart';
import { DateTime } from 'luxon';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material/';
import { useGlobalization } from '../../../i18n/useGlobalization';
import * as Collapsible from '../../core/collapsible';
import * as React from 'react';
import Highcharts from 'highcharts';
import Highstock from 'highcharts/highstock';
import styled from 'styled-components';
import _ from 'lodash';
import { useSettings } from '@innovyze/stylovyze';
import highchartsMore from 'highcharts/highcharts-more';

highchartsMore(Highcharts);

import type { ChartInstanceRef } from '../../core/_summaryze-chart';
import {
  GaugeChartEventHandlers,
  GaugeChartOptions,
  GaugeChartSeriesOptions,
  GaugeChartSeriesSourceOptions,
} from './gauge-chart-types';
import {
  GaugeSeriesTitleArea,
  getChartThresholds,
  getTitleDisplay,
  isValueBetweenThresholds,
  makeGaugeInstanceTitle,
  updateGaugeChartDialDashStyle,
  updateGaugeChartDialDisplay,
} from './gauge-chart.utils';
import { Slider } from '@mui/material';
import { getTheme, Theme } from '../../core/utils/theme-utils';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Chart
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const Chart = createChart<'gauge-instance' | 'line-instance'>('GaugeChart');

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Slots
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const Slots = createSlots<'series-group' | 'gauge-series' | 'line-series'>(
  'GaugeChart'
);

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Root Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

type GaugeChartRootProps = GaugeChartOptions &
  GaugeChartEventHandlers & {
    children: React.ReactNode;
  };

const GaugeChartRoot = React.forwardRef<ChartInstanceRef, GaugeChartRootProps>(
  (props, ref): React.ReactElement => {
    const theme = getTheme(props.selectedTheme);
    const themeBg = theme.chart.backgroundColor
    //@ts-ignore
    const background =  typeof themeBg!== 'string' ? themeBg?.stops?.[0]?.[1] : themeBg;
    return (
      <Slots.Slottable slottableChildren={props.children}>
        {(slots) => {
          return (
            <Chart.ChartRoot>
              <StyledGaugeChartRoot.GaugeInstanceContainer>
                <Chart.ChartInstance
                  id="gauge-instance"
                  initialOptions={gaugeInstanceInitialOptions}
                  constructorFunction={Highcharts.chart}>
                  {slots.getSlot('series-group')}
                </Chart.ChartInstance>
              </StyledGaugeChartRoot.GaugeInstanceContainer>
              {props.showForecastPeriodSlider && (
                <ForecastPeriodSlider {...props} />
              )}
              <StyledGaugeChartRoot.LineInstanceContainer bgColor={background ?? '#ffffff'}>
                <Collapsible.CollapsibleRoot
                  open={props.openSnapshot}
                  onOpenChange={props.onOpenSnapshotChange}>
                  <Collapsible.CollapsibleTrigger>
                    <CollapsibleTrigger selectedTheme={props.selectedTheme} />
                  </Collapsible.CollapsibleTrigger>
                  <Collapsible.CollapsibleContent>
                    <Chart.ChartInstance
                      ref={ref}
                      id="line-instance"
                      initialOptions={lineInstanceInitialOptions}
                      constructorFunction={Highstock.stockChart}>
                      {slots.getSlot('line-series')}
                    </Chart.ChartInstance>
                  </Collapsible.CollapsibleContent>
                </Collapsible.CollapsibleRoot>
              </StyledGaugeChartRoot.LineInstanceContainer>
            </Chart.ChartRoot>
          );
        }}
      </Slots.Slottable>
    );
  }
);

GaugeChartRoot.displayName = 'GaugeChartRoot';

const StyledGaugeChartRoot = {
  GaugeInstanceContainer: styled.div`
    flex: 1 1 auto;
    min-height: 0;
  `,
  LineInstanceContainer: styled.div<{bgColor: string}>`
    background-color: ${({bgColor})=> bgColor};
    padding-top: 15px;
  `,
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Series Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

type GaugeChartSeriesProps = GaugeChartSeriesOptions;

const GaugeChartSeries = (props: GaugeChartSeriesProps): React.ReactElement => {
  const { t } = useGlobalization();
  const theme = getTheme(props.selectedTheme ?? 'Default');
  const primaryColor =
    props.selectedTheme === 'Default' ? '#3CA6C8' : theme.colors[0];
  const outOfRangeColor = '#AA0000';

  const { companySettings } = useSettings();

  const sourcesOptions: SourceOptions[] = React.useMemo(() => {
    if (props.customData) {
      return props.customData.map((source) => {
        const [timestamp, value] = source.data?.at(-1) ?? [];
        const color = isValueBetweenThresholds(
          value,
          props.thresholds?.min,
          props.thresholds?.max
        )
          ? source.color ?? primaryColor
          : outOfRangeColor;
        let titleArea;
        const sourceLabel = source.label;
        const { title, subtitle } = getTitleDisplay(
          t,
          value,
          timestamp,
          companySettings.timeZoneIANA,
          source.unit
        );
        titleArea = {
          label: sourceLabel,
          title: title,
          subtitle: subtitle,
          color: color,
        };
        return {
          color: color,
          dash: source.dashStyle || 'Solid',
          timestamp: timestamp,
          value: value,
          titleArea: titleArea,
          status: source.status,
        };
      });
    } else {
      return props.sources.map((source) => {
        const [timestamp, value] = source.data?.at(-1) ?? [];
        const color = isValueBetweenThresholds(
          value,
          props.thresholds?.min,
          props.thresholds?.max
        )
          ? source.color || primaryColor
          : outOfRangeColor;
        let titleArea;
        const sourceLabel = source.label;
        if (source.status === 'loading') {
          titleArea = {
            label: sourceLabel,
            title: t('Loading'),
            color: primaryColor,
          };
        } else {
          if (source.status === 'rejected') {
            titleArea = {
              label: sourceLabel,
              title: t('No Data'),
              color: outOfRangeColor,
            };
          } else {
            const { title, subtitle } = getTitleDisplay(
              t,
              value,
              timestamp,
              companySettings.timeZoneIANA,
              source.unit
            );
            titleArea = {
              label: sourceLabel,
              title: title,
              subtitle: subtitle,
              color: color,
            };
          }
        }
        return {
          color: color,
          dash: source.dashStyle || 'Solid',
          timestamp: timestamp,
          value: value,
          titleArea: titleArea,
          status: source.status,
        };
      });
    }
  }, [
    t,
    props.sources,
    props.thresholds?.min,
    props.thresholds?.max,
    props.selectedTheme,
    companySettings.timeZoneIANA,
  ]);

  const seriesTitleArea = sourcesOptions.map((source) => source.titleArea);

  return (
    <>
      <Slots.Slot id="series-group">
        <GaugeChartGaugeConfiguration
          {...props}
          seriesTitleArea={seriesTitleArea}
        />
        {sourcesOptions.map((source, index) => (
          <GaugeChartGaugeSeries
            key={index}
            source={source}
            thresholds={props.thresholds}
            selectedTheme={props.selectedTheme}
          />
        ))}
      </Slots.Slot>
      <Slots.Slot id="line-series">
        {props.sources.length > 0 && (
          <GaugeChartLineSeries
            {...props.sources[0]}
            selectedTheme={props.selectedTheme}
          />
        )}
      </Slots.Slot>
    </>
  );
};

interface SourceOptions {
  color: string;
  dash: Highcharts.DashStyleValue;
  timestamp?: number;
  value?: number | null;
  titleArea: GaugeSeriesTitleArea;
  status?: Status;
}

const GaugeChartGaugeConfiguration = (
  props: GaugeChartSeriesProps & { seriesTitleArea: GaugeSeriesTitleArea[] }
): null => {
  const instance = Chart.useInstance('GaugeChartGaugeSeries');

  const chartStatus = React.useMemo(() => {
    if (props.customData) return 'resolved';
    const sourcesStatus = props.sources.map((source) => source.status);
    for (const status of sourcesStatus) {
      if (status === 'resolved' || status === undefined) return status;
    }
    return sourcesStatus[0];
  }, [props.sources, props.customData]);

  const [minChartValue, maxChartValue] = React.useMemo(() => {
    let min: number | undefined = undefined;
    let max: number | undefined = undefined;

    const allSourcesData = [];
    for (const source of props.sources) {
      allSourcesData.push(...(source.data ?? []));
    }

    for (const dataPoint of allSourcesData) {
      const [, value] = dataPoint;
      if (value !== null) {
        if (min === undefined || value < min) min = value;
        if (max === undefined || value > max) max = value;
      }
    }

    return [min, max];
  }, [props.sources]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets chart configurations
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    const firstSource = props.seriesTitleArea[0];
    const secondSource = props.seriesTitleArea[1];
    const instanceTitle = makeGaugeInstanceTitle(firstSource, secondSource);
    const showValues =
      props.showValues === true || props.showValues === undefined;
    const { minThreshold, maxThreshold } = getChartThresholds(
      props.thresholds?.min,
      props.thresholds?.max,
      minChartValue,
      maxChartValue
    );
    const yAxisOptions: Highcharts.YAxisOptions = {
      min: minChartValue,
      max: maxChartValue,
      labels: { enabled: showValues },
      plotBands: [
        {
          from: minThreshold,
          to: maxThreshold,
          color: '#99DDF0',
          thickness: '40%',
        },
      ],
    };
    const displayYAxisOptions =
      chartStatus === undefined || chartStatus === 'resolved';
    instance.current?.update({
      title: instanceTitle,
      yAxis: displayYAxisOptions ? yAxisOptions : undefined,
    });
  }, [
    instance,
    chartStatus,
    minChartValue,
    maxChartValue,
    props.thresholds?.min,
    props.thresholds?.max,
    props.showValues,
    props.seriesTitleArea,
  ]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets Theme
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    const theme = props.selectedTheme;
    const themeOptions = getTheme(theme ?? 'Default');
    instance.current?.update({ ...themeOptions });
  }, [instance, props.selectedTheme]);

  return null;
};

const GaugeChartGaugeSeries = (props: {
  source: SourceOptions;
  thresholds: GaugeChartSeriesProps['thresholds'];
  selectedTheme: Theme;
}): null => {
  const series = Chart.useSeries(props, 'GaugeChartGaugeSeries');
  const [isResized, setIsResized] = React.useState(false);
  const debounceUpdateChartWidth = _.debounce(() => {
    setIsResized(true);
  }, 200);
  window.addEventListener('resize', () => debounceUpdateChartWidth());

  React.useEffect(() => {
    const source = props.source;
    const currentSeries = series.current;
    if (source.status === undefined || source.status === 'resolved') {
      const data = typeof source.value === 'number' ? [source.value] : [];
      currentSeries?.update({
        data: data,
        dial: {
          backgroundColor: source.color,
        },
        pivot: { backgroundColor: source.color },
        type: 'gauge',
      } as Highcharts.SeriesGaugeOptions);
      updateGaugeChartDialDisplay(currentSeries, source.color, source.dash);
    } else if (source.status === 'loading' || source.status === 'rejected') {
      currentSeries?.update({ data: [], type: 'gauge' });
    }
  }, [series, props.source]);

  React.useEffect(() => {
    const status = props.source.status;
    if ((status === undefined || status === 'resolved') && isResized) {
      updateGaugeChartDialDashStyle(series.current);
      setTimeout(() => setIsResized(false), 200);
    }
  }, [series, props.source.status, isResized]);

  return null;
};

const GaugeChartLineSeries = (props: GaugeChartSeriesSourceOptions): null => {
  const { t } = useGlobalization();
  const instance = Chart.useInstance('GaugeChartGaugeSeries');
  const series = Chart.useSeries(props, 'GaugeChartLineSeries');

  const data = props.data;

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets series data
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    if (data === undefined || data.length === 0) {
      // Series type is always "gauge", we can ignore this error
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      series.current?.update({ data: [] });
    } else {
      const [timestamp] = data.at(-1) ?? [];
      const _data = [] as typeof data;

      if (timestamp !== undefined) {
        const startTimestamp = DateTime.fromMillis(timestamp)
          .minus({ hours: 24 })
          .toMillis();

        let index = data.length - 1;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        let timestampAtIndex = data.at(index)![0];

        while (timestampAtIndex > startTimestamp && index >= 0) {
          _data.unshift(data[index]);
          timestampAtIndex = data[index][0];
          index -= 1;
        }

        // Series type is always "gauge", we can ignore this error
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        series.current?.update({ data: _data });
      }
    }
  }, [series, data]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets status
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    const mainSource = props;
    if (mainSource?.status === 'loading') {
      instance.current?.showLoading();
    } else if (mainSource?.status === 'rejected') {
      instance.current?.showLoading(t('No Data'));
    } else {
      instance.current?.hideLoading();
    }
  }, [instance, props, t]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets Theme
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    const theme = props.selectedTheme;
    const themeOptions = getTheme(theme ?? 'Default');
    instance.current?.update({ ...themeOptions });
  }, [instance, props.selectedTheme]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  return null;
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Collapsible Trigger
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const CollapsibleTrigger = (props: {
  selectedTheme: Theme;
}): React.ReactElement => {
  const { t } = useGlobalization();
  const context = Collapsible.useCollapsibleContext('CollapsibleTrigger');
  const icon = context.open ? <KeyboardArrowDown /> : <KeyboardArrowUp />;
  const text = context.open ? t('Collapse') : t('Last 24 Hours');
  const theme = getTheme(props.selectedTheme);
  const color = props.selectedTheme === 'Default' ? '#3ca6c8' : theme.colors[0];
  

  return (
    <StyledCollapsibleTrigger.Root>
      <StyledCollapsibleTrigger.Icon primaryColor={color}>
        {icon}
      </StyledCollapsibleTrigger.Icon>
      <StyledCollapsibleTrigger.Text primaryColor={color}>
        {text}
      </StyledCollapsibleTrigger.Text>
    </StyledCollapsibleTrigger.Root>
  );
};

const StyledCollapsibleTrigger = {
  Root: styled.div`
    align-items: center;
    display: flex;
    gap: 15px;
  `,
  Icon: styled.div<{ primaryColor: string; }>`
    align-items: center;
    background-color: ${(props) => props.primaryColor};
    border-radius: 0.2rem;
    color: #ffffff;
    display: flex;
    height: 1.5rem;
    justify-content: center;
    width: 1.5rem;
  `,
  Text: styled.div<{ primaryColor: string }>`
    color: ${(props) => props.primaryColor};
    font-family:
      Roboto,
      Helvetica Neue,
      San Francisco,
      Segoe UI,
      sans-serif;
    font-size: 0.8rem;
    font-weight: 500;
    text-transform: uppercase;
  `,
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Forecast Period Slider
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const ForecastPeriodSlider = (
  props: GaugeChartRootProps
): React.ReactElement => {
  const { t } = useGlobalization();

  return (
    <>
      <StyledForecastPeriodSlider.Label>
        {t('Forecast Period')}
      </StyledForecastPeriodSlider.Label>
      <Slider
        value={props.forecastPeriod || 16}
        color="secondary"
        sx={{
          '& .MuiSlider-thumb': {
            borderRadius: '1px',
            width: '10px',
          },
        }}
        min={1}
        step={1}
        max={96}
        onChange={(e) => {
          if (e.target && props.onForecastPeriodChange) {
            props.onForecastPeriodChange(
              (
                e.target as unknown as {
                  value: number;
                }
              ).value
            );
          }
        }}
        valueLabelDisplay="off"
        aria-labelledby="forecast-period-slider"
      />
    </>
  );
};

const StyledForecastPeriodSlider = {
  Label: styled.div`
    color: #3c3c3c;
    font-family:
      Roboto,
      Helvetica Neue,
      San Francisco,
      Segoe UI,
      sans-serif;
    font-size: 0.9rem;
    font-weight: 500;
  `,
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export { GaugeChartRoot, GaugeChartSeries };

export type { GaugeChartRootProps, GaugeChartSeriesProps, ChartInstanceRef };
