import { AssetLink, AssetLinkProps } from './AssetLink';
import { AssetTypeInfo, Field, FieldType } from '@Types/map.types';
import { DataGridProps, GridAlignment, GridColDef } from '@mui/x-data-grid';
import { ShowInMenu, getActions } from './getActions';
import { valueFormatter, valueGetter } from './gridValueUtils';

import Checkbox from '@mui/material/Checkbox';
import { CustomSelection } from '@Hooks/useCustomSelection';
import React from 'react';
import SpatialFilterInput from './SpatialFilterInput';
import { TFunc } from '@Translations/types';
import { renderCell } from './renderCell';

export const spatialFilterColumn: Field = {
	name: 'Spatial',
	fieldKey: 'spatial',
	fieldType: FieldType.Boolean,
	columnWidth: 80,
	visible: false,
};

export const excludedFilterColumn: Field = {
	name: 'Excluded',
	fieldKey: 'excluded',
	fieldType: FieldType.Boolean,
	columnWidth: 80,
	visible: false,
};

export const createSelectColumn = (
	customSelectionModel: CustomSelection,
): GridColDef => {
	const {
		customSelection,
		handleCustomSelection,
		handleAllSelected,
		isSelected,
		isAllSelected,
	} = customSelectionModel;
	return {
		field: 'custom_selected',
		headerName: 'Selected',
		sortable: false,
		filterable: false,
		type: 'boolean',
		width: 80,
		hideable: false,
		disableColumnMenu: true,
		renderCell: function SelectedCell({
			row,
		}: {
			row: { _id: string; custom_selected: boolean };
		}) {
			return (
				<Checkbox
					data-cy="select-checkbox"
					checked={isSelected(row._id)}
					onChange={event => {
						row.custom_selected = event.target.checked;
						handleCustomSelection(row);
					}}
				/>
			);
		},
		renderHeader: function SelectedHeader() {
			return (
				<Checkbox
					data-cy="select-all-checkbox"
					checked={isAllSelected()}
					indeterminate={
						isAllSelected() === false &&
						customSelection.selection.length > 0
					}
					onChange={event => {
						handleAllSelected(event.target.checked);
					}}
				/>
			);
		},
	};
};

export const generateSpatialColumns = (
	fields: AssetTypeInfo['fields'],
	t: TFunc,
	gridSelectFeature: boolean,
	customSelection: CustomSelection,
	showInMenu: ShowInMenu,
): DataGridProps['columns'] => {
	return [
		...(gridSelectFeature ? [createSelectColumn(customSelection)] : []),
		createSpatialColumn(t),
		createExcludedColumn(t),
		...createFieldColumns(
			fields,
			['_id', 'geometry', 'excluded', 'spatial'],
			t,
		),
		...createMenu(showInMenu),
	];
};

export const generateColumns = (
	fields: AssetTypeInfo['fields'],
	layerName: string,
	dataType: string,
	t: TFunc,
	onViewDetails: AssetLinkProps['onViewDetails'],
	showInMenu: ShowInMenu,
	gridSelectFeature: boolean,
	customSelection: CustomSelection,
): DataGridProps['columns'] => {
	return [
		...(gridSelectFeature ? [createSelectColumn(customSelection)] : []),
		{
			field: 'assetId',
			// using the context of existing translations, so is slighly different to context used in rest of file
			headerName: t('Asset ID', { context: 'Asset properties' }),
			minWidth: 160,
			flex: 1,
			hideable: false,
			renderCell: function AssetIdCell({ value }) {
				return (
					<AssetLink
						assetId={value}
						assetType={layerName}
						systemType={dataType}
						onViewDetails={onViewDetails}
					/>
				);
			},
		},
		createSpatialColumn(t),
		createExcludedColumn(t),
		...createFieldColumns(fields, ['assetId', 'spatial', 'excluded'], t),
		...createMenu(showInMenu),
	];
};

function createFieldColumns(fields: Field[], ignoreFields: string[], t: TFunc) {
	return fields
		?.filter(({ fieldKey }) => !ignoreFields.includes(fieldKey))
		.map(({ name, fieldType, fieldKey, units }) => ({
			field: fieldKey,
			headerName: t(name, { context: 'Asset Property' }),
			flex: 1,
			type: fieldType,
			align: 'left' as GridAlignment,
			headerAlign: 'left' as GridAlignment,
			valueGetter: valueGetter(fieldType as FieldType),
			valueFormatter: valueFormatter(fieldType as FieldType, units),
			renderCell: renderCell(fieldType as FieldType, units),
		}));
}

function createExcludedColumn(t: TFunc): GridColDef {
	return {
		field: excludedFilterColumn.fieldKey,
		headerName: t(excludedFilterColumn.name, {
			context: 'Asset Property',
		}),
		type: excludedFilterColumn.fieldType,
		valueGetter: ({ row }) => row.excluded,
		renderCell: renderCell(excludedFilterColumn.fieldType as FieldType),
	};
}

function createSpatialColumn(t: TFunc): GridColDef {
	return {
		field: spatialFilterColumn.fieldKey,
		headerName: t(spatialFilterColumn.name, {
			context: 'Asset Property',
		}),
		type: spatialFilterColumn.fieldType,
		valueGetter: ({ row }) => row.isSpatialSelected,
		renderCell: renderCell(spatialFilterColumn.fieldType as FieldType),
		filterOperators: [
			{
				label: 'Is inside',
				value: 'isInside',
				getApplyFilterFn: () => {
					return null;
				},
				InputComponent: SpatialFilterInput,
			},
		],
	};
}

function createMenu(showInMenu: ShowInMenu) {
	return showInMenu.length > 0
		? ([
				{
					field: 'actions',
					type: 'actions',
					getActions: getActions(showInMenu),
					width: 50,
				},
		  ] as GridColDef[])
		: [];
}
