import { getValue, passInId, passInSystemType } from './shared';

import { createSelector } from '@reduxjs/toolkit';
import { selectInspectionsByAssetId } from './inspections';
import {
	selectRiskBase,
	selectRiskScoreByAssetId,
	selectSpatialLayers,
} from './risk';
import { NamespacedStoreState } from '@Types/store.types';
import { GridRowId } from '@innovyze/stylovyze';

export const selectAssetBase = createSelector(
	[getValue],
	moduleState => moduleState.asset,
);

export const selectAssets = createSelector(
	[selectAssetBase],
	baseState => baseState.assets,
);

export const selectAssetById = createSelector(
	[selectAssets, passInId],
	(assets, selectedItem) => assets.find(({ _id }) => _id === selectedItem),
);

export const selectSensors = createSelector(
	[selectAssetBase],
	baseState => baseState.sensors,
);

export const selectSensorById = createSelector(
	[selectSensors, passInId],
	(assets, selectedItem) => assets.find(({ _id }) => _id === selectedItem),
);

export const selectExtendedAssetById = createSelector(
	[selectAssetById, selectRiskScoreByAssetId, selectInspectionsByAssetId],
	(asset, risk, inspections) => {
		if (!asset) return undefined;
		return {
			...asset,
			risk,
			inspections,
		};
	},
);

export const selectPaginatedAssets = createSelector(
	[selectAssetBase],
	baseState => baseState.assetGrid,
);

export const selectGridLoading = createSelector(
	[selectPaginatedAssets],
	paginatedAssets => paginatedAssets.loading,
);

export const selectGridItems = createSelector(
	[selectPaginatedAssets],
	paginatedAssets => paginatedAssets.items,
);

export const selectGridExcludeList = createSelector(
	[selectAssetBase],
	baseState => baseState.excludedList,
);

export const selectGridRows = createSelector(
	[selectGridItems, selectGridExcludeList],
	(items, excludedList) =>
		items.map(({ segments, ...properties }) => ({
			...properties,
			...segments?.[0],
			excluded: excludedList.includes(properties._id),
		})),
);

const passInSelectionAndGridView = (
	_: NamespacedStoreState,
	selectedItems: GridRowId[],
	{ dataType, layerName }: { dataType?: string; layerName?: string },
) => ({ selectedItems, dataType, layerName });

export const selectSelectedAssets = createSelector(
	[selectGridRows, passInSelectionAndGridView],
	(rows, { selectedItems, dataType, layerName }) =>
		rows
			.filter(({ _id }) => selectedItems.includes(_id))
			.map(({ _id, assetId, assetType, layerSystemType, geometry }) => ({
				_id,
				assetId,
				assetType: assetType ?? layerName,
				systemType: layerSystemType ?? dataType,
				geometry,
			})),
);

export const selectGridPagination = createSelector(
	[selectPaginatedAssets],
	paginatedAssets => paginatedAssets.pagination,
);

export const selectGridSort = createSelector(
	[selectPaginatedAssets],
	({ sort, sortDescending }) => ({ sort, sortDescending }),
);

export const selectSystemAssets = createSelector(
	[selectAssetBase],
	baseState => baseState.systemAssets,
);
export const selectSystemTypes = createSelector(
	[selectSystemAssets],
	systemAssets =>
		Object.keys(systemAssets).filter(systemType => systemType !== 'null'),
);

export const selectAssetTypesInSystem = createSelector(
	[selectSystemAssets, passInSystemType],
	(systemAssets, systemType) =>
		systemType ? systemAssets?.[systemType] : undefined,
);

export const selectDataTypes = createSelector(
	[selectSystemTypes, selectSpatialLayers],
	(systemTypes, spatialLayers) =>
		spatialLayers.length > 0
			? [...systemTypes, 'SpatialData']
			: systemTypes,
);

const passInDataType = (
	_: NamespacedStoreState,
	systemType: string | null,
): string | null => systemType;

export const selectLayersNamesInDataType = createSelector(
	[selectSystemAssets, selectSpatialLayers, passInDataType],
	(assetTypes, spatialLayers, dataType) => {
		if (dataType === 'SpatialData')
			return spatialLayers.map(({ LAYER_NAME }) => LAYER_NAME);
		if (dataType) return assetTypes?.[dataType];
		return undefined;
	},
);

const passInIsSpatialDataEnabled = (
	_: NamespacedStoreState,
	isEnabled: boolean,
): boolean => isEnabled;

export const selectAreDataTypesLoaded = createSelector(
	[selectAssetBase, selectRiskBase, passInIsSpatialDataEnabled],
	(assetBase, riskBase, isSpatialDataEnabled) =>
		assetBase.systemAssetsStatus === 'success' &&
		(!isSpatialDataEnabled || riskBase.spatialLayersStatus === 'success'),
);
