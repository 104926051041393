export enum Reading {
  'Close' = 'Close',
  'Open' = 'Open',
  'High' = 'High',
  'Low' = 'Low',
  'Average' = 'Average',
  'Sum' = 'Sum',
}

export type ReadingType = keyof typeof Reading;
