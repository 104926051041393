import * as React from 'react';
import { nanoid } from 'nanoid';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Stable Callback Hook
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const useControlledRender = (): [renderKey: string, render: () => void] => {
  const [renderKey, setRenderKey] = React.useState(nanoid());

  const render = React.useCallback(() => {
    setRenderKey(nanoid());
  }, [setRenderKey]);

  return [renderKey, render];
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Module Exports
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export { useControlledRender };
