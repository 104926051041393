import { Resolution } from './resolution.types';

import type { AsyncData } from './async.types';
import { Resolutions } from '../../types';
import { ResponseDataEntry } from '../../_next/core/time-series-data-old';

export type DataSource =
  | SensorDataSource
  | Record<string, SensorDataSource | null | undefined>
  | ConstantDataSource;

// eslint-disable-next-line @typescript-eslint/ban-types
export type SensorDataFile = {};

/**
 * This is the data that comes from the `data.json`
 * files, reshaped to easly search the data.
 *
 * Instead of storing the measurements as an array,
 * they are converted into a record where the keys are
 * timestamps, and the values are array of measurements
 * for each one of the following readings:
 *
 * [Open, Close, Low, High, Average, Sum]
 *
 * @example
 *
 * ```json
 * {
 * 	1653006588: [1, 2, 1, 2.5, 1.75, 2],
 * 	1653006588: [2, 3, 2, 3.5, 2.75, 3],
 * 	1653006588: [3, 4, 3, 4.5, 3.75, 4]
 * }
 * ```
 * */
export type SensorMeasureData = Record<
  number,
  [number, number, number, number, number, number]
>;

export type AsyncSensorData = AsyncData<{
  measurements: SensorMeasureData;
  timestamps: number[];
  unit?: string;
}>;

export type SensorDataRecords = Record<string, Record<string, AsyncSensorData>>;

export interface SensorDataStore {
  records: SensorDataRecords;
  sources: SensorDataSource[];
}

export enum DataSourceType {
  'SensorDataSource' = 'SensorDataSource',
  'ConstantDataSource' = 'ConstantDataSource',
}

export type SensorDataSource = {
  resolution: Resolution | Resolutions;
  sensorId: string;
  type?: DataSourceType.SensorDataSource;
  customData?: ResponseDataEntry;
};

export type ConstantDataSource = {
  value: number;
  type: DataSourceType.ConstantDataSource;
};

export const isSensorDataSource = (
  dataSoure: DataSource
): dataSoure is SensorDataSource => {
  return dataSoure.type === DataSourceType.SensorDataSource;
};

export const isConstantDataSource = (
  dataSoure: DataSource
): dataSoure is ConstantDataSource => {
  return dataSoure.type === DataSourceType.ConstantDataSource;
};
