/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { BoostOptions, Options, XAxisOptions, YAxisOptions } from 'highcharts';
import { SeriesOptionsType } from 'highcharts/highstock';
import { analyticFunctionMap } from '../../../utils';

import {
  dataGroupingFunctions,
  CustomAnalyticDisplayOptions,
  DEFAULT_CHART_COLORS,
  HiddenSeries,
} from '../../../types';
import { SensorDataRecords } from '../../../core/types/data.types';
import { CustomAnalyticSeries } from '../../../core/types/analytic.types';
import { isSeriesVisible } from '../../../core/utils/hiddenSeries.utils';
import { Reading } from '../../../core/types/reading.types';
import { StackOptions } from '../../../core/components/StackedChart';

export const generateCustomAnalyticOptions = (
  sensorDataRecords: SensorDataRecords,
  isBoostModuleEnabled: boolean,
  analyticSeries: CustomAnalyticSeries[],
  hiddenSeries: HiddenSeries,
  displayOptions?: CustomAnalyticDisplayOptions
): Options => {
  const boostOptions: BoostOptions = {
    enabled: isBoostModuleEnabled,
    useGPUTranslations: isBoostModuleEnabled,
  };

  const xAxisOptions: XAxisOptions = {
    gridLineWidth: displayOptions?.showXGrid ? 1 : 0,
    type: 'datetime',
  };

  const yAxisOptions: YAxisOptions[] = [
    {
      opposite: false,
      gridLineWidth: displayOptions?.showYGrid ? 1 : 0,
    },
  ];

  const units: (string | undefined)[] = [];

  const seriesOptions: SeriesOptionsType[] = [];

  analyticSeries.forEach((series, seriesIndex) => {
    // TODO: Check this error
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { sensorId, resolution } = series.dataSource;
    const dataRecord = sensorDataRecords?.[sensorId]?.[resolution];

    units.push(dataRecord?.data?.unit);

    seriesOptions.push({
      type: 'line',
      id: series.id,
      name: series.node.name,
      color: DEFAULT_CHART_COLORS[seriesIndex % DEFAULT_CHART_COLORS.length],
      custom: {
        resolution,
        sensorId,
        seriesIndex,
      },
      data: analyticFunctionMap[series.node.func ?? Reading.Close](
        dataRecord.data?.timestamps ?? [],
        dataRecord.data?.measurements ?? {},
        series.node.params
      ),
      dataGrouping: {
        enabled: !isBoostModuleEnabled,
        approximation: dataGroupingFunctions[Reading.Close],
      },
      visible: isSeriesVisible(
        hiddenSeries,
        seriesIndex,
        series.id,
        series.node.name
      ),
    });
  });

  yAxisOptions[0].title = {
    text: Array.from(new Set(units))
      .filter((unit) => !!unit)
      .join(', '),
  };

  return {
    legend: { enabled: true },
    navigator: { enabled: true },
    rangeSelector: { enabled: true },
    boost: boostOptions,
    series: seriesOptions,
    xAxis: xAxisOptions,
    yAxis: yAxisOptions,
  };
};

export const generateCustomAnalyticStackOptions = (
  sensorDataRecords: SensorDataRecords,
  analyticSeries: CustomAnalyticSeries[],
  displayOptions?: CustomAnalyticDisplayOptions
): StackOptions[] => {
  return analyticSeries.map((series) => {
    // TODO: Check this error
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { sensorId, resolution } = series.dataSource;
    const dataRecord = sensorDataRecords?.[sensorId]?.[resolution];

    return {
      seriesId: series.id,
      options: {
        yAxis: [
          {
            opposite: false,
            gridLineWidth: displayOptions?.showYGrid ? 1 : 0,
            title: { text: dataRecord?.data?.unit },
          },
        ],
      },
    };
  });
};
