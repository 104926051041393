import imageApi from '@Apis/image.api';

export const getImageKeyService = async (
	imageId: string,
): Promise<string | undefined> => {
	try {
		const address = `/image/${imageId}`;
		const response = await imageApi.get<{ imageKey: string }>(address);
		return response.data.imageKey;
	} catch {
		return undefined;
	}
};

export const getImageDownloadUrlService = async (
	imageKey: string,
): Promise<string | undefined> => {
	try {
		const address = `/download/${imageKey}`;
		const response = await imageApi.get<{ location: string }>(address);
		return response.data.location;
	} catch {
		return undefined;
	}
};
