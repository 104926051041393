import { initialState as confiurationInitialState } from '../reducers/configuration.reducer';
import { ConfigurationMapping, NamespacedStoreState } from '@Types';
import { createSelector } from '@reduxjs/toolkit';
import { moduleNamespace } from '@Store/config.store';
import { useSelector } from 'react-redux';

const parseNamespace = (state: NamespacedStoreState) => state[moduleNamespace];

const ConfigurationStoreSelector = createSelector(
	parseNamespace,
	state => state?.configuration ?? confiurationInitialState,
);

const ConfigurationsSelector = createSelector(
	ConfigurationStoreSelector,
	state => state.configurations,
);

const IsLoadingSelector = createSelector(
	ConfigurationStoreSelector,
	state =>
		(state?.isLoadingConfiguration ?? false) ||
		(state?.isLoadingConfiguration ?? false),
);

const selectorConfigurationState = (key: string) => {
	return createSelector(ConfigurationsSelector, state => {
		if (state && state[key]) {
			return state[key]?.mapping;
		}
	});
};

const selectorConfigurationDetailsState = (key: string) => {
	return createSelector(ConfigurationsSelector, state => {
		if (state && state[key]) {
			return state[key]?.details;
		}
	});
};

export const useSelectConfiguration = (
	key: string,
): ConfigurationMapping[] | undefined =>
	useSelector(selectorConfigurationState(key));

export const useSelectConfigurationDetails = (
	key: string,
): { [key: string]: string | unknown } | undefined =>
	useSelector(selectorConfigurationDetailsState(key));

export const useSelectConfigurationIsLoading = (): boolean =>
	useSelector(IsLoadingSelector);
