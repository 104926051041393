import * as Chart from './chart';
import * as Highcharts from 'highcharts';
import * as React from 'react';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Plot Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export interface PlotProps {
  /** A list of options for each one of the series. */
  series: Omit<_SeriesManagerProps, 'index'>[];
  /**
   * If set, the value will be used to add spacing between the plotted bars or
   * columns. Otherwise, no gap will be added.
   * */
  gap?: 'none' | 'small' | 'medium' | 'big';
  /**
   * If `true`, there will be grid lines rendered across the `Y axis` if the
   * prop `orientation` is set to `vertical`. Otherwise, the grid lines will be
   * rendered across the `X axis`. By default, no grid lines are shown.
   * */
  mainAxisGridLines?: boolean;
  /**
   * If set, the value will be used as the title for the `Y axis` if the prop
   * `orientation` is set to `vertical`. Otherwise, it'll be used as the title
   * for the `X axis`. By default, no title is shown.
   * */
  mainAxisTitle?: string;
  /**
   * If set as `vertical`, the data will be plotted with vertical columns.
   * Otherwise, the data will be plotted using `horizontal` bars.
   * */
  orientation?: 'horizontal' | 'vertical';
  /**
   * If `true`, interactive elements like `legend` and events like `onClick`
   * will be turned off. Otherwise all interactions will be enabled.
   * */
  readOnly?: boolean;
  /**
   * If set, the value will be used to control the visibility of loading and
   * error messages. Otherwise, no message will be shown on the chart.
   * */
  status?: 'idle' | 'loading' | 'resolved' | 'rejected';
}

/**
 * Displays a chart where data is presented as vertical columns or horizontal
 * bars, allowing different series to be compared alongside one another.
 *
 * When the `orientation` prop is set to `vertical`, the `Y axis` will be used
 * as main axis. Otherwise, the `X axis` will be used.
 *
 * A category will be automatically added on the main axis for each series,
 * and the series `name` will be used as the category `label`.
 */
export function Plot(props: PlotProps): React.ReactElement {
  return (
    <Chart.Instance
      initialOptions={makeInitialOptions()}
      factoryFunction={Highcharts.chart}>
      <_PlotManager {...props} />
    </Chart.Instance>
  );
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Plot Manager Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

type _PlotManagerProps = PlotProps;

/**
 * This is an internal component that manages chart updates and
 * renders `_SeriesManager` components.
 */
function _PlotManager(props: _PlotManagerProps): React.ReactElement {
  // Highcharts will automatically switch main and secondary axis
  // based on the `type` option. By default, highcharts expects
  // the configuration as if you were building a `column` chart.
  // (yAxis is main and xAxis is secondary)
  const instance = Chart.useInstance();

  // Set the orientation.
  React.useEffect(() => {
    const type = props.orientation === 'vertical' ? 'column' : 'bar';
    instance.update({ chart: { type } });
  }, [instance.update, props.orientation]);

  // Set categories on secondary axis.
  const categories = props.series.map((series) => series.name);
  React.useEffect(() => {
    instance.update({ xAxis: { categories } });
  }, [instance.update, categories.join()]);

  // Set grid lines on main axis.
  React.useEffect(() => {
    const gridLineWidth = props.mainAxisGridLines === true ? 1 : 0;
    instance.update({ yAxis: { gridLineWidth } });
  }, [instance.update, props.mainAxisGridLines]);

  // Set title on main axis.
  React.useEffect(() => {
    instance.update({ yAxis: { title: { text: props.mainAxisTitle } } });
  }, [instance.update, props.mainAxisTitle]);

  // Set gap between bars or columns.
  React.useEffect(() => {
    const variants = { small: 0.05, medium: 0.1, big: 0.2 };
    const size = variants[props.gap as keyof typeof variants] ?? 0;
    instance.update({
      plotOptions: {
        bar: { pointPadding: size },
        column: { pointPadding: size },
      },
    });
  }, [instance.update, props.gap]);

  // Disable all interactive elements on read only mode.
  React.useEffect(() => {
    const enabled = props.readOnly ? false : true;
    instance.update({ legend: { enabled } });
  }, [props.readOnly]);

  // Set loading status.
  React.useEffect(() => {
    instance.setStatus(props.status);
  }, [instance.setStatus, props.status]);

  return (
    <>
      {props.series.map((seriesProps, index) => (
        <_SeriesManager
          {...seriesProps}
          key={seriesProps.id || index}
          index={index}
        />
      ))}
    </>
  );
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Series Manager - Internal
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export interface _SeriesManagerProps {
  /**
   * If set, the value will be used as the series color. Otherwise,
   * a color is assigned based on the index.
   * */
  color?: string;
  /**
   * If set as `number`, the value will be plotted on the corresponding
   * category as a horizontal bar or a vertical column depending on the value
   * of the `orientation` prop. Otherwise, the category will remain blank.
   * */
  data?: number | null;
  /**
   * If set, the value can be used to get and control the series. Otherwise,
   * a random string is assigned and you won't have access to it.
   * */
  id?: string;
  /** The index of the series */
  index: number;
  /**
   * The name that identifies the series. It is recommended to use a unique
   * string even though it is not enforced.
   * */
  name: string;
  /** The unit of the data. */
  unit?: string | null;
}

/**
 * This is an internal component that manages series updates.
 */
function _SeriesManager(props: _SeriesManagerProps): null {
  const series = Chart.useSeries();

  // Create and destroy series
  React.useEffect(() => {
    series.create();
    return series.destroy;
  }, [series.create, series.destroy]);

  // Set name of the series.
  React.useEffect(() => {
    series.update({ name: props.name });
  }, [series.update, props.name]);

  // Set the data of the series.
  React.useEffect(() => {
    series.update({ data: [{ x: props.index, y: props.data }] });
  }, [series.update, props.index, props.data]);

  // Set unit
  React.useEffect(() => {
    const valueSuffix = props.unit ? ` (${props.unit})` : undefined;
    series.update({ tooltip: { valueSuffix } });
  }, [series.update, props.unit]);

  return null;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Utils
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

function makeInitialOptions(): Highcharts.Options {
  return {
    chart: { type: 'bar' },
    credits: { enabled: false },
    exporting: { enabled: false },
    title: { text: '' },
    plotOptions: {
      bar: {
        borderWidth: 0,
        centerInCategory: true,
        grouping: false,
        groupPadding: 0,
      },
      column: {
        borderWidth: 0,
        centerInCategory: true,
        grouping: false,
        groupPadding: 0,
      },
    },
  };
}
