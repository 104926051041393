import { TFunc } from '../../../i18n';
/**
 * Default options for all charts.
 *
 * DON'T SET ANY DEFAULTS FOR series, xAxis NOR yAxis.
 *
 * Merging options with those props with Highcharts.merge will replace
 * the values if all the options use arrays, it'll deeply merge
 * if they're objects and won't do anything if there's a mismatch
 * in types. So it's not consistent and it can lead to bugs.
 *
 * ALWAYS DEFINE THOSE PROPERTIES AT THE PARENT LEVEL.
 * **/
export const defaultChartOptions = (t: TFunc): Highcharts.Options => ({
  chart: {
    height: null,
    zoomType: 'x',
  },
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  title: {
    text: '',
  },
  scrollbar: {
    buttonArrowColor: 'rgba(1, 1, 1, 0)',
    enabled: true,
    height: 0,
  },
  boost: {
    enabled: false,
    useGPUTranslations: false,
  },
  plotOptions: {
    series: {
      dataGrouping: {
        enabled: true,
      },
      showInLegend: true,
      showInNavigator: true,
      states: {
        hover: {
          lineWidth: undefined,
          lineWidthPlus: 0,
        },
        inactive: {
          opacity: 1,
        },
      },
      turboThreshold: 10,
    },
  },
  navigator: {
    handles: {
      backgroundColor: '#F9FAFC',
      borderColor: '#768D95',
      height: 20,
    },
    maskFill: 'rgba(255, 255, 255, 0.7)',
    maskInside: false,
    outlineColor: 'transparent',
    xAxis: {
      gridLineWidth: 0,
    },
    yAxis: {
      gridLineWidth: 0,
    },
  },
  rangeSelector: {
    allButtonsEnabled: true,
    buttons: [
      {
        count: 1,
        text: t('1d', {
          context: 'label to select date range limit by a day',
        }),
        type: 'day',
      },
      {
        count: 1,
        text: t('1w', {
          context: 'label to select date range limit by a week',
        }),
        type: 'week',
      },
      {
        count: 1,
        text: t('1m', {
          context: 'label to select date range limit by a month',
        }),
        type: 'month',
      },
      {
        count: 3,
        text: t('3m', {
          context: 'label to select date range limit by 3 months',
        }),
        type: 'month',
      },
      {
        count: 6,
        text: t('6m', {
          context: 'label to select date range limit by 3 months',
        }),
        type: 'month',
      },
      {
        count: 1,
        text: t('1y', {
          context: 'label to select date range limit by a year',
        }),
        type: 'year',
      },
      {
        text: t('YTD', {
          context: 'label to select date range a year to date',
        }),
        type: 'ytd',
      },
      {
        text: t('All', { context: 'all date range' }),
        type: 'all',
      },
    ],
    selected: 7,
    //inputDateFormat: '%b %e, %Y %H:%M',
  },
});
