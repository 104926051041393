/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { BoostOptions, Options, XAxisOptions, YAxisOptions } from 'highcharts';
import { SeriesOptionsType } from 'highcharts/highstock';
import { IliSensor, dataGroupingFunctions, HiddenSeries } from '../../../types';
import { StackOptions } from '../../../core/components/StackedChart';
import { Reading } from '../../../core/types/reading.types';

import { SensorDataRecords } from '../../../core/types/data.types';
import { IliDisplayOptions, IliSeries } from '../../../core/types/ili.types';
import { isSeriesVisible } from '../../../core/utils/hiddenSeries.utils';
import { readingIndex } from '../../../core/utils/data.utils';
import { TFunc } from '../../../i18n/types';

export const iliChartFont =
  'Roboto, Helvetica Neue, San Francisco, Segoe UI, sans-serif';

export const iliChartPalette = {
  text: '#282930',
  ili: '#00ABD1',
  mnf: '#758D95',
  massBalance: '#E56C43',
};

export const iliChannelToYAxisLabel = (
  t: TFunc
): Record<IliSensor['channel'], string> => ({
  ili: t('Infrastructure Leakage Index (ILI)'),
  mnf: t('Minimum Nightly Flow'),
  massBalance: t('Mass Balance - Usage Volume'),
});

const iliChannelToLegend = (t: TFunc): Record<IliSeries['id'], string> => ({
  ili: t('ILI', { context: 'Tech term' }),
  mnf: t('Minimum Nightly Flow'),
  massBalance: t('Mass Balance - Usage'),
});

export const generateIliOptions = (
  sensorDataRecords: SensorDataRecords,
  isBoostModuleEnabled: boolean,
  iliSeries: IliSeries[],
  hiddenSeries: HiddenSeries,
  t: TFunc,
  displayOptions?: IliDisplayOptions
): Options => {
  const yAxisOptions: YAxisOptions[] = [
    {
      gridLineWidth: displayOptions?.showYGrid ? 1 : 0,
      opposite: false,
      title: { text: t('Infrastructure Leakage Index (ILI)') },
    },
  ];

  const xAxisOptions: XAxisOptions = {
    gridLineWidth: displayOptions?.showXGrid ? 1 : 0,
    type: 'datetime',
  };

  const boostOptions: BoostOptions = {
    enabled: isBoostModuleEnabled,
    useGPUTranslations: isBoostModuleEnabled,
  };

  const seriesOptions: SeriesOptionsType[] = [];

  iliSeries.forEach((series, seriesIndex) => {
    const { sensorId, resolution } = series.dataSource;

    const timestamps =
      sensorDataRecords?.[sensorId]?.[resolution]?.data?.timestamps ?? [];

    const measurements =
      sensorDataRecords?.[sensorId]?.[resolution]?.data?.measurements ?? {};

    seriesOptions.push({
      type: 'line',
      id: series.id,
      name: iliChannelToLegend(t)[series.id],
      color: iliChartPalette[series.id],
      custom: {
        resolution,
        sensorId,
        seriesIndex,
      },
      visible: isSeriesVisible(
        hiddenSeries,
        seriesIndex,
        series.id,
        iliChannelToLegend(t)[series.id]
      ),
      data: timestamps.map((timestamp) => [
        timestamp,
        measurements[timestamp]?.[readingIndex[Reading['Close']]] ?? null,
      ]),
      dataGrouping: {
        enabled: !isBoostModuleEnabled,
        approximation: dataGroupingFunctions[Reading['Close']],
      },
    });
  });

  return {
    legend: { enabled: true },
    navigator: { enabled: true },
    rangeSelector: { enabled: true },
    boost: boostOptions,
    series: seriesOptions,
    xAxis: xAxisOptions,
    yAxis: yAxisOptions,
  };
};

export const generateIliStackOptions = (
  sensorDataRecords: SensorDataRecords,
  iliSeries: IliSeries[],
  t: TFunc,
  displayOptions?: IliDisplayOptions
): StackOptions[] => {
  return iliSeries.map((series) => {
    const dataRecord =
      sensorDataRecords[series.dataSource.sensorId]?.[
        series.dataSource.resolution
      ];

    const axisLabel = iliChannelToYAxisLabel(t)[series.id];
    const unit = dataRecord?.data?.unit ? ` (${dataRecord?.data?.unit})` : '';

    return {
      seriesId: series.id,
      options: {
        yAxis: [
          {
            opposite: false,
            gridLineWidth: displayOptions?.showYGrid ? 1 : 0,
            title: { text: `${axisLabel}${unit}` },
          },
        ],
      },
    };
  });
};
