import { PaginationState } from './pagination.types';

export enum CostUnit {
	each = 'each',
	length = 'length',
}

export interface Rules {
	id: string;
	recordType?: string;
	field?: string;
	operator?: string;
	values?: [];
	value1?: [] | string | number;
	value2?: [] | string | number;
	unit?: string;
	ruleCondition?: string;
	order?: number;
	result?: string;
	groupedRules?: Rules[];
}

export interface AssetsCount {
	currentNode?: number | null;
	truePath?: number | null;
	falsePath?: number | null;
	actionId?: string;
	treeId?: string;
	unitCost?: number;
	costUnit?: string;
	unit?: CostUnit;
}
export interface NodeDate {
	title?: string;
	filterCondition?: string;
	rules?: Rules[];
	actionDefectCodes?: string[];
	actionId?: string;
	treeId?: string;
	unitCost?: number;
	costUnit?: string;
	unit?: CostUnit;
	assetsCount?: AssetsCount;
}

export interface Position {
	x: number;
	y: number;
}

export interface ReactFlowSchema {
	id: number | string | null;
	parentId?: string | number | null;
	type?: string;
	position?: Position;
	data?: {
		label: string;
		nodeData: NodeDate;
	};
	label?: string;
	source?: number | string | null;
	target?: number | string | null;
	connectorCondition?: string;
}

export type RehabTreePublishStateType = 'published' | 'unpublished';

export interface ReactFlowDecisionTree {
	_id: string;
	name: string;
	thumbnail: string;
	nodes: ReactFlowSchema[];
	status?: string;
	runBy?: string;
	lastRun?: string;
	assetType?: string;
	assetCount?: number;
	nodeCount?: number;
	publishState?: RehabTreePublishStateType;
}

export interface RehabDecisionTree {
	_id: string;
	name: string;
	thumbnail: string;
	treeNodes: NodeSchema;
	status?: string;
	runBy?: string;
	lastRun?: string;
	assetType?: string;
	systemType?: string;
	assetCount?: number;
	nodeCount?: number;
	publishState?: RehabTreePublishStateType;
	actions?: RehabAction[];
}

export interface RehabAction {
	_id: string;
	actionId: string;
}

export interface RehabActionsResponse {
	treeId: string;
	data: RehabAction[];
}

export interface NodeSchema {
	id: string | number | null;
	parentId?: string | number | null;
	left: NodeSchema | null;
	right: NodeSchema | null;
	position?: Position;
	title?: string;
	connectorCondition?: string;
	type?: string;
	filterCondition?: string;
	rules?: Rules[];
	actionDefectCodes?: string[];
	assetsCount?: AssetsCount;
	actionId?: string;
	treeId?: string;
	unitCost?: number;
	costUnit?: string;
	unit?: CostUnit;
}

export interface RehabStoreState {
	isDetailLoading: boolean;
	rehabDetails: RehabDetail;
	rehabTrees: ReactFlowDecisionTree[];
}

export interface RehabDetail {
	[treeId: string]: PaginationState;
}
