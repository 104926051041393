import LayerManager from '@Map/layers/LayerManager';

export default class MapboxUtilsBase {
	protected _map?: mapboxgl.Map;
	protected _layerManager: LayerManager | null = null;

	constructor(map: mapboxgl.Map, layerManager: LayerManager | null = null) {
		this._map = map;
		this._layerManager = layerManager;
	}
}
