import {
  fixRangeSelection,
  stringifyRangeSelection,
} from '../../utils/rangeSelection.utils';
import { MutableRefObject, useLayoutEffect, useRef, useState } from 'react';
import useHighchartsOptions from '../useHighchartsOptions';

import type { RangeSelection } from '../../../types/chartState';

const useRangeSelection = (
  initialRangeSelection?: RangeSelection,
  dataRangeSelection?: RangeSelection,
  onRangeSelectionChange?: (rangeSelection: RangeSelection) => void
) => {
  /**
   * We store here the range selection so that can do calculations
   * based on the current value.
   *
   * Instead of allowing the folks using this component to control
   * the range selection, we only take the value provided as an
   * initial value for the internal state.
   *
   * TODO: Needs more investigation of why the navigator starts
   * doing weird stuff when the min/max change.
   *
   * Making the range selection a controlled value introduces
   * problems with the navigator.
   *
   * fixRangeSelection will merge both the initial and the
   * data ranges so that we get the intersection of both.
   *
   * Example:
   *
   * Initial: |------------------------|
   * Data:                 |----------------|
   * Fixed:                |-----------|
   */
  const [rangeSelection, setRangeSelection] = useState<
    RangeSelection | undefined
  >(fixRangeSelection(initialRangeSelection, dataRangeSelection));

  useLayoutEffect(() => {
    fixRangeSelection(initialRangeSelection, dataRangeSelection);
  }, [
    stringifyRangeSelection(initialRangeSelection),
    stringifyRangeSelection(dataRangeSelection),
  ]);

  /**
   * Usually people don't memo their callbacks, so...
   * we store the onRangeSelectionChange callback on a ref,
   * so that we don't regenerate options when the
   * function changes it's referential equality...
   * otherwise, the chart will have REALLY BA~D!! performance
   * as there'll be an infinite loop of renders.
   */
  const onChangeCallbackRef = useRef(onRangeSelectionChange);

  useLayoutEffect(() => {
    onChangeCallbackRef.current = onRangeSelectionChange;
  }, [onRangeSelectionChange]);

  /**
   * If we control the range selection or regenerate options
   * based on range selection change, the navigator gets
   * messed up. But we need to restore latest value on
   * stack / overlay mode change.
   *
   * To do that, we store again the range changes in a
   * ref...when the chart's load event fires, we take
   * the latest value without regenerating options.
   *
   * Same for the callback, we read the latest callback
   * state when the setExtremes event gets fired.
   * */
  const rangeSelectionRef = useRef(rangeSelection);

  useLayoutEffect(() => {
    rangeSelectionRef.current = rangeSelection;
  }, [rangeSelection]);

  const rangeSelectionOptions = useHighchartsOptions(() => {
    return generateRangeSelectionOptions(
      rangeSelectionRef,
      (rangeSelection: RangeSelection) => {
        setRangeSelection(rangeSelection);
        onChangeCallbackRef.current?.(rangeSelection);
      }
    );
  }, []);

  return {
    rangeSelection,
    rangeSelectionOptions,
  };
};

const generateRangeSelectionOptions = (
  rangeSelectionRef: MutableRefObject<RangeSelection | undefined>,
  onChange: (rangeSelection: RangeSelection) => void
): Highcharts.Options => {
  return {
    chart: {
      events: {
        load: function (this: Highcharts.Chart) {
          this.xAxis[0].setExtremes(
            rangeSelectionRef.current?.min,
            rangeSelectionRef.current?.max,
            true,
            false,
            {
              trigger: 'syncExtremes',
            }
          );
        },
      },
    },
    xAxis: {
      events: {
        setExtremes: function (this: Highcharts.Axis, event) {
          if (event.trigger !== 'syncExtremes') {
            onChange?.({
              min: event.min,
              max: event.max,
            });
          }
        },
      },
    },
  };
};

export default useRangeSelection;
