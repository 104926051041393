import Chart from '../Chart';
import Highcharts from 'highcharts';
import LoadingData from '../LoadingData';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import StackedChart from '../StackedChart';

import type {
  StackedChartProps,
  StackedChartRef,
  StackOptions,
} from '../StackedChart';
import type { ChartRef } from '../Chart';
import type { MutableRefObject, ReactElement, Ref } from 'react';
import { getUnifiedDataRecord } from '../../utils/data.utils';
export interface StackableChartProps extends StackedChartProps {
  stack?: {
    enabled: boolean;
    options?: StackOptions[];
  };
}

export interface StackableChartRef {
  charts: Highcharts.Chart[];
  containerRef: MutableRefObject<HTMLDivElement | null>;
}

const emptyChartsRef: Highcharts.Chart[] = [];
const nullContainerRef: MutableRefObject<null> = { current: null };

const StackableChart = (
  props: StackableChartProps,
  ref?: Ref<StackableChartRef>
): ReactElement => {
  const singleChartRef = useRef<ChartRef | null>(null);
  const stackedChartRef = useRef<StackedChartRef | null>(null);

  /**
   * With this, we control the passed ref to the component to
   * expose the charts' API as well as the container ref to the parent.
   */
  useImperativeHandle(
    ref,
    () => ({
      get containerRef() {
        if (!props.stack?.enabled) {
          return singleChartRef.current?.containerRef ?? nullContainerRef;
        }

        return stackedChartRef.current?.containerRef ?? nullContainerRef;
      },
      get charts() {
        if (!props.stack?.enabled) {
          if (singleChartRef.current?.chart) {
            return [singleChartRef.current?.chart];
          }

          return emptyChartsRef;
        }

        return stackedChartRef?.current?.charts ?? emptyChartsRef;
      },
    }),
    [props.stack?.enabled]
  );

  if (props.stack === undefined || props.stack.enabled === false) {
    return (
      <LoadingData
        data={getUnifiedDataRecord(props.data)}
        key="single-chart-loader">
        <Chart
          key="single-chart"
          constructorType={props.constructorType}
          cy={props.cy}
          highcharts={props.highcharts}
          options={props.options}
          ref={singleChartRef}
        />
      </LoadingData>
    );
  }

  return (
    <StackedChart
      constructorType={props.constructorType}
      cy={props.cy}
      data={props.data}
      highcharts={props.highcharts}
      options={props.options}
      ref={stackedChartRef}
      stackOptions={props.stack?.options}
    />
  );
};

export default forwardRef<StackableChartRef, StackableChartProps>(
  StackableChart
);
