import { Bbox, FlyTo, GeocoderResult, SearchEndpoint } from './types';
import {
	TextFieldProps as MuiTextFieldProps,
	StyledEngineProvider,
	ThemeProvider,
} from '@mui/material';
import {
	RESTRICT_SEARCH,
	SEARCH_CHANGED,
	SEARCH_FLYTO,
	SEARCH_RESULT,
	SEARCH_SELECT,
	SET_DEFAULT_SEARCH,
	STYLE_SEARCH,
} from './constants';
import React, { Suspense } from 'react';

import { DefaultTheme } from '@mui/styles';
import Events from '@Map/events/Events';
import MapboxUtils from '@Map/helpers/mapbox';
import { Marker } from 'mapbox-gl';
import ReactDOM from 'react-dom';
import { Search } from '@Components/Search';
import SearchService from './SearchService';
import { dispatchTargetedEvent } from '@Map/utils';

export default class SearchControl extends Events {
	private _map: mapboxgl.Map;
	private _geocoder: SearchService;
	private _searchMarker = new Marker({ color: '#4668f2' });
	private _searchElement: HTMLDivElement | null = null;
	private _searchServices: SearchService[] = [];
	private _searchLimit = 3;
	private _flyTo: FlyTo = FlyTo.always;
	private _restrictToMapBounds;
	private _bounds: Bbox | undefined;
	// used to determine if search was set by default search term or not
	private _defaultSearchFired = false;
	private _searchTerm = '';
	private _geocoderId: string | null;
	private _enabled: boolean;
	private _searchProps: MuiTextFieldProps = {};
	private _dispatchEvent;
	private _theme: Partial<DefaultTheme> = {};
	private _mapboxUtils: MapboxUtils;

	constructor(
		map: mapboxgl.Map,
		accessToken: string,
		enabled: boolean,
		geocoderId: string | null,
		dispatchEvent: <T>(eventName: string, detail: T) => void,
		mapboxUtils: MapboxUtils,
		restrictSearchToBounds = true,
	) {
		super();
		this._map = map;
		this._geocoder = new SearchService(
			`https://api.mapbox.com/geocoding/v5/mapbox.places/{{query}}.json?access_token=${accessToken}&proximity={{lnglat}}&bbox={{bbox}}&language={{language}}`,
		);
		this._enabled = enabled;
		this._geocoderId = geocoderId;
		this._dispatchEvent = dispatchEvent;
		this._separatedEventListeners();
		this._restrictToMapBounds = restrictSearchToBounds;
		this._mapboxUtils = mapboxUtils;
	}

	set searchElement(element: HTMLDivElement | null) {
		this._searchElement = element;
		this.createControl();
	}

	set bounds(bbox: Bbox) {
		this._bounds = bbox;
	}

	createControl(): void {
		const container = this.getSearchContainer();
		if (!container) return;
		ReactDOM.render(
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={this._theme}>
					<Suspense fallback="">
						<Search
							defaultSearch={this._searchTerm}
							onInputChange={this.onInputChange}
							onSelect={this.onSelect}
							hidePlaceholder={!!this._geocoderId}
							TextFieldProps={{
								size: this._geocoderId ? 'medium' : 'small',
								margin: this._geocoderId ? 'normal' : 'none',
								...this._searchProps,
							}}
						/>
					</Suspense>
				</ThemeProvider>
			</StyledEngineProvider>,
			container,
		);
	}

	removeSearchMarker(): void {
		this._searchMarker.remove();
	}

	setSearch(search: string): void {
		this._searchTerm = search;
		this.createControl();
	}

	setTheme(theme: DefaultTheme): void {
		this._theme = theme;
		this.createControl();
	}

	onInputChange = async (search: string): Promise<GeocoderResult[]> => {
		const isCoord = search.match(
			/^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i,
		);
		if (isCoord) {
			const lat = Number(isCoord[1]);
			const lng = Number(isCoord[2]);

			// check that lat and lng are within valid range
			if (Math.abs(lat) > 90 || Math.abs(lng) > 180) return [];

			return [
				{
					id: search,
					center: [lng, lat],
					geometry: {
						type: 'Point',
						coordinates: [lng, lat],
					},
					place_name: search,
					place_type: ['coordinate'],
					text: search,
					type: 'Feature',
				},
			];
		}
		return this.getSearchResults(search);
	};

	onSelect = (result: GeocoderResult | null): void => {
		this.removeSearchMarker();
		this._emitSearch(result);
		if (!result) {
			// clear the default search so can re-select the same address again after clearing it
			this.setSearch('');
			return;
		}
		const center = (result.center ||
			(result.geometry.type === 'Point' &&
				result.geometry?.coordinates)) as [number, number];
		const zoom = 16;
		if (center) {
			this._searchMarker.setLngLat(center).addTo(this._map);
			if (this._shouldFlyToCenter()) {
				const padding = this._mapboxUtils.getCameraPadding();
				MapboxUtils.flyTo(
					this._map,
					{ center, zoom, padding },
					'Geocoder:selectFeature',
				);
			}
		}
		this._defaultSearchFired = false;
		this.fire(SEARCH_SELECT, {
			id: result.id,
			serviceId: result.serviceId,
		});
	};

	setSearchEndpoints(searchEndpoints: SearchEndpoint[]): void {
		this._searchServices = [];
		searchEndpoints.forEach(({ url, token, dataServiceId }) => {
			this._searchServices.push(
				new SearchService(url, token, dataServiceId),
			);
		});
	}

	async getSearchResults(query: string): Promise<GeocoderResult[]> {
		return Promise.allSettled(
			[...this._searchServices, this._geocoder].map(search => {
				const results = search.fetchData(
					query,
					this._getLngLat(),
					this._getBbox(),
				);
				return results;
			}),
		).then(results => results.map(this._mapToLimit).flat());
	}

	_mapToLimit = (
		result: PromiseSettledResult<GeocoderResult[]>,
	): GeocoderResult[] => {
		return 'value' in result
			? result.value.slice(0, this._searchLimit)
			: [];
	};

	private getSearchContainer(): HTMLDivElement | HTMLElement | null {
		if (this._geocoderId) {
			return document.getElementById(this._geocoderId);
		}
		if (!this._enabled) return null;
		return this._searchElement;
	}

	private _separatedEventListeners() {
		if (!this._geocoderId) return;
		const container = this.getSearchContainer() as HTMLElement;
		if (!container) return;
		container.addEventListener(SET_DEFAULT_SEARCH, this._setSearch);
		container.addEventListener(STYLE_SEARCH, this._textFieldProps);

		container.addEventListener(SEARCH_FLYTO, this._setFlyTo);

		container.addEventListener(RESTRICT_SEARCH, this._restrictToBounds);
	}

	_setSearch = (e: CustomEvent): void => {
		const search = e.detail;
		this._defaultSearchFired = true;
		this.setSearch(search);
	};

	_setFlyTo = (e: CustomEvent): void => {
		const flyTo = e.detail;
		if (flyTo in FlyTo) {
			this._flyTo = flyTo;
		}
	};

	_restrictToBounds = (e: CustomEvent): void => {
		const restrict = e.detail;
		this._restrictToMapBounds = restrict ?? true;
	};

	_textFieldProps = (e: CustomEvent): void => {
		this._searchProps = e.detail;
		this.createControl();
	};

	private _emitSearch(result: GeocoderResult | null) {
		this._dispatchEvent(SEARCH_RESULT, result);
		const container = this.getSearchContainer() as HTMLElement;
		if (!container) return;
		dispatchTargetedEvent(container, SEARCH_CHANGED, result);
	}

	private _shouldFlyToCenter() {
		if (this._flyTo === FlyTo.always) return true;
		// If default search hasn't been fired, then search was selected and fly to mode is on selection only
		if (!this._defaultSearchFired && this._flyTo === FlyTo.onSelect) {
			return true;
		}
		// If default search has been fired and fly to mode is default search only
		if (this._defaultSearchFired && this._flyTo === FlyTo.defaultSearch) {
			return true;
		}
		return false;
	}

	private _getLngLat(): string {
		return this._map
			.getCenter()
			.toArray()
			.join(',');
	}

	private _getBbox(): Bbox | undefined {
		if (this._restrictToMapBounds) {
			return this._bounds;
		}
	}
}
