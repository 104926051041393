import React, { useEffect, useMemo } from 'react';
import { getSensorGroupName, getWorkspaceType } from '@Utils/facility';
import {
	getSensorGroups,
	useSelectPaginatedSensorGroups,
} from '@innovyze/stylovyze';
import { useDispatch, useSelector } from 'react-redux';

import { FacilityPropertiesDetails } from './FacilityPropertiesDetails';
import { FeatureItem } from '@Utils/types';
import { NamespacedStoreState } from '@Types/store.types';
import { getFacility } from '@Actions/facility';
import { selectFacilityById, selectFacilitySensors } from '@Selectors/facility';

interface FacilityPropertiesProps {
	item: FeatureItem;
}

export const FacilityProperties = ({
	item,
}: FacilityPropertiesProps): JSX.Element => {
	const dispatch = useDispatch();
	const facilityId = item.id;
	const facilityName = item.name ?? facilityId;

	const facility = useSelector<NamespacedStoreState>(state =>
		selectFacilityById(state, facilityId),
	) as ReturnType<typeof selectFacilityById>;

	const sensors = useSelector<NamespacedStoreState>(state =>
		selectFacilitySensors(state, facilityId),
	) as ReturnType<typeof selectFacilitySensors>;

	const {
		status: sensorGroupsInitialized,
		sensorGroups,
	} = useSelectPaginatedSensorGroups();

	useEffect(() => {
		if (!facility) dispatch(getFacility(facilityId));
	}, [facility]);

	useEffect(() => {
		if (sensorGroupsInitialized !== 'success')
			dispatch(
				getSensorGroups({
					limit: 1000,
					includeSensorGroupPairs: 'true',
				}),
			);
	}, []);

	const namedSensorGroupList = useMemo(() => {
		if (facility) {
			if (sensorGroups)
				return facility.sensorGroups.map(sensorGroup =>
					getSensorGroupName(sensorGroups, sensorGroup),
				);
			else return facility.sensorGroups;
		}
		return [];
	}, [sensorGroups, facility]);

	const isFacilityPropertiesInialized = !!facility;

	const workspaceType = useMemo(() => {
		return getWorkspaceType(item.facilityType);
	}, [item.facilityType]);

	const imageSrc = facility ? facility.imageSrc : undefined;

	return (
		<FacilityPropertiesDetails
			initialized={isFacilityPropertiesInialized}
			facilityName={facilityName}
			workspaceType={workspaceType}
			workspaceId={item.workspaceId}
			imageSrc={imageSrc}
			sensorGroups={namedSensorGroupList}
			sensors={facility ? sensors : []}
		/>
	);
};
