import LayerManager from '@Map/layers/LayerManager';
import { MapboxUtilsCamera } from './camera';
import MapboxUtilsFeatures from './features';
import MapboxUtilsLayers from './layers';
import MapboxUtilsSources from './sources';
import MapboxUtilsStyle from './style';
import applyMixins from '../applyMixins';
export * from './camera';

interface MapboxUtils
	extends MapboxUtilsSources,
		MapboxUtilsLayers,
		MapboxUtilsCamera,
		MapboxUtilsFeatures,
		MapboxUtilsStyle {
	//
}

class MapboxUtils {
	protected _map?: mapboxgl.Map;

	constructor(map: mapboxgl.Map, layerManager: LayerManager | null = null) {
		this._map = map;
		this._layerManager = layerManager;
	}

	//TODO: find a better way to do static mixins
	static flyTo(
		map: mapboxgl.Map,
		...args: Parameters<MapboxUtils['flyTo']>
	): void {
		new MapboxUtils(map).flyTo(...args);
	}

	static jumpTo(
		map: mapboxgl.Map,
		...args: Parameters<MapboxUtils['jumpTo']>
	): void {
		new MapboxUtils(map).jumpTo(...args);
	}
}

applyMixins(MapboxUtils, [
	MapboxUtilsSources,
	MapboxUtilsLayers,
	MapboxUtilsCamera,
	MapboxUtilsFeatures,
	MapboxUtilsStyle,
]);

export default MapboxUtils;
