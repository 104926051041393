import * as Contexts from './chart.contexts';
import * as React from 'react';
import * as LibUtils from '../lib-utils';

import type { Chart } from 'highcharts';
import type { DependencyList } from 'react';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Shared Types
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export type InstanceGetter = (
  ids?: (string | RegExp) | (string | RegExp)[]
) => Chart[];

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Context Retrieval Hooks
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useRootContainerElementRef = () => {
  const containerElementRef = React.useContext(
    Contexts.RootContainerElementRef
  );
  return containerElementRef;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useInstance = () => {
  const closestInstanceRef = React.useContext(Contexts.InstanceRef);
  const rootInstanceRecordRef = React.useContext(
    Contexts.RootInstanceRecordRef
  );

  const get: InstanceGetter = React.useCallback(
    (ids) => {
      const instances = new Map<string, Chart>();
      const recordIds = Object.keys(rootInstanceRecordRef.current);

      if (ids === undefined) {
        const instance = closestInstanceRef.current;
        if (instance !== undefined) {
          instances.set('closest', instance);
        }
      } else {
        const _ids = Array.isArray(ids) ? ids : [ids];
        for (const id of _ids) {
          let matches: string[] = [];

          if (typeof id === 'string') {
            matches = [id];
          } else {
            matches = recordIds.filter((recordId) => id.test(recordId));
          }

          for (const match of matches) {
            const instance = rootInstanceRecordRef.current[match];
            if (instance !== undefined && !instances.has(match)) {
              instances.set(match, instance);
            }
          }
        }
      }

      return Array.from(instances.values());
    },
    [closestInstanceRef, rootInstanceRecordRef]
  );

  return { get };
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Instance Effect Hooks
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

/**
 * Runs an `effect` for each one of the provided instance ids.
 * If no id is passed, it'll run on the closest instance available in
 * the parent tree. If no instance is found in the parent tree, the
 * `effect` won't run.
 *
 * **Important**
 *
 * - Changing the value or length of `ids` at runtime must be avoided.
 * - The `effect` will run **after** the instance is initialized.
 * - The effect's `destructor` function will run **after** the instance is destroyed.
 *
 * If you need to run a `destructor` function **before** the instance is destroyed use
 * `useInstanceLayoutEffect` instead.
 */
export const useInstanceEffect = (
  effect: (instance: { get: InstanceGetter }) => void | (() => void),
  deps: DependencyList
): void => {
  const { get } = useInstance();
  const effectRef = LibUtils.useStableRef(effect);

  React.useEffect(() => {
    return effectRef.current({ get });
  }, deps);
};

/**
 * Runs an `effect` for each one of the provided instance ids.
 * If no id is passed, it'll run on the closest instance available in
 * the parent tree. If no instance is found in the parent tree, the
 * `effect` won't run.
 *
 * **Important**
 *
 * - Changing the value or length of `ids` at runtime must be avoided.
 * - The `effect` will run **before** the instance is initialized.
 * - The effect's `destructor` function will run **before** the instance is destroyed.
 *
 * Prefer using `useInstanceEffect` unless you have to run a `destructor` function **before**
 * the instance is destroyed.
 */
export const useInstanceLayoutEffect = (
  effect: (instance: { get: InstanceGetter }) => void | (() => void),
  deps: DependencyList
): void => {
  const { get } = useInstance();
  const effectRef = LibUtils.useStableRef(effect);

  React.useLayoutEffect(() => {
    return effectRef.current({ get });
  }, deps);
};

export const useSyncDateTimeRangePicker = () => {
  const context = React.useContext(Contexts.RootTimeRange);
  return context.syncDateTimeRangePicker;
};
