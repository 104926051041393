import { Environments, getService } from '@innovyze/stylovyze';

import axios, { AxiosRequestHeaders } from 'axios';
import axiosRetry from 'axios-retry';
import config from './config.api';
import cookies from 'browser-cookies';
import { formatUrlTrailing } from '@Utils/urlFormatting';

const apiEnv =
	(localStorage.getItem('apiEnv') as keyof typeof Environments) || undefined;

export const facilityBaseUrl = formatUrlTrailing(
	getService('liftStations', config, apiEnv),
);

const options = {
	baseURL: facilityBaseUrl,
	timeout: 10000,
};

const service = axios.create(options);

service.interceptors.request.use(
	requestConfig => {
		requestConfig.headers = {
			...(requestConfig.headers || {}),
			Authorization: 'Bearer ' + cookies.get('auth0.user.token'),
		} as AxiosRequestHeaders;
		return requestConfig;
	},
	error => {
		Promise.reject(error);
	},
);

axiosRetry(service, {
	retries: 3,
	retryDelay: retryCount => {
		return retryCount * 1000;
	},
});

export default service;
