import { CompositeLayerProps } from '../types';
import HighlightBackgroundLayer from './HighlightBackgroundLayer';

export default class HighlightForegroundLayer extends HighlightBackgroundLayer {
	constructor(layerInfo: CompositeLayerProps, map: mapboxgl.Map) {
		super(layerInfo, map, {
			zIndexesPerType: { base: 2002, line: 2002, symbol: 2002 },
			idPostfix: '-highlight-foreground',
			highlightForeground: true,
		});
	}
}
