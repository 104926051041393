import { Readings } from './sensor.types';
/**
 * Individual row from measure data table/s3 bucket.
 * Order of the tuple goes [timestamp (in ms), open, close, low, high, average, sum]
 */
export type RawMeasureDatum = [
  number,
  number,
  number,
  number,
  number,
  number,
  number,
];

export type RawMeasureData = RawMeasureDatum[];

export const RawMeasureDataMap: Record<Readings, number> = {
  [Readings.Open]: 1,
  [Readings.Close]: 2,
  [Readings.Low]: 3,
  [Readings.High]: 4,
  [Readings.Average]: 5,
  [Readings.Sum]: 6,
};

export interface MeasureDataResponse {
  data: RawMeasureData;
  sensor_id: string;
  type: string;
  unit: string;
}

export interface MeasureDataMap {
  [id: string]: {
    data: RawMeasureData;
    unit: string;
  };
}
