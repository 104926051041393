import { AnyAction, createReducer } from '@reduxjs/toolkit';
import { clearMap, getLayoutResolved } from '@Actions/map';

import { MapStoreState } from '@Types';

export const initialState: MapStoreState = {
	layout: undefined,
};

interface MapReducer {
	[x: string]: (
		state: MapStoreState,
		action: AnyAction,
	) => MapStoreState | undefined;
}

const reducer: MapReducer = {
	[getLayoutResolved.toString()]: (state, action) => ({
		...state,
		layout: action.payload,
	}),
	[clearMap.toString()]: () => ({
		...initialState,
	}),
};

export default createReducer(initialState, reducer);
