import {
	StylovyzeTable,
	StylovyzeTableHeaderProps,
	StylovyzeTableRow,
} from '@innovyze/stylovyze';
import { Grid } from '@mui/material';
import { useGlobalization } from '@Translations';
import { MACPFullInspection } from '@Types';
import { InspectionScoredAttributes } from '@Types/inspectionAttributes.types';
import React from 'react';

const splitCamalCase = (input: string) => {
	const temp = input.replace(/([A-Z])/g, ' $1');
	return temp.charAt(0).toUpperCase() + temp.slice(1);
};

const MACPComponentConditions = ({
	fullInsp,
}: {
	fullInsp: MACPFullInspection | InspectionScoredAttributes | undefined;
}) => {
	const { t } = useGlobalization();
	const headers: StylovyzeTableHeaderProps[] = [
		{
			key: 'desc-header',
			cell: t('Description'),
		},
		{ key: 'structural-header', cell: 'Structural Grade' },
		{
			key: 'OM-header',
			cell: t('O&M Grade'),
		},
	];

	// {
	// 	key: 'hole-number',
	// 	cells: ['Hole Number', '0', '0'],
	// }
	const observationRows: StylovyzeTableRow[] = [];
	const componentCond: { [key: string]: string[] } = {
		holeNumber: [t('Hole Number'), '', ''],
		coverFrameFit: [t('Cover/Frame Fit'), '', ''],
		coverCondition: [t('Cover Condition'), '', ''],
		coverInsertCondition: [t('Cover Insert Condition'), '', ''],
		adjustmentRingCondition: [t('Adjustment Ring Condition'), '', ''],
		frameCondition: [t('Frame Condition'), '', ''],
		sealCondition: [t('Seal Condition'), '', ''],
		frameOffsetDistance: [t('Frame Offset Distance'), '', ''],
		frameSealInflow: [t('Frame Seal Inflow'), '', ''],
		chimney: [t('Chimney'), '', ''],
		pipeCondition: [t('Pipe Condition'), '', ''],
	};
	if (
		fullInsp?.componentConditions != undefined &&
		fullInsp.componentConditions.length > 0
	) {
		fullInsp.componentConditions.map(condition => {
			let service = componentCond[condition.name]
				? componentCond[condition.name][2]
				: '';
			let structure = componentCond[condition.name]
				? componentCond[condition.name][1]
				: '';
			if (condition.serviceScore != 0) {
				service = condition.value;
			}
			if (condition.structuralScore != 0) {
				structure = condition.value;
			}
			componentCond[condition.name] = [
				splitCamalCase(condition.name),
				structure,
				service,
			];
		});
	}
	Object.keys(componentCond).map(name => {
		observationRows.push({
			key: name,
			cells: componentCond[name],
		});
	});

	return (
		<Grid container style={{ height: '685px' }}>
			<Grid item xs={12}>
				<StylovyzeTable
					className="ScrollTable"
					dataCy="defect-table"
					headers={headers}
					rows={observationRows}
					// initialized={!isLoading}
					useSkeleton={{ rowCount: 10 }}
				/>
			</Grid>
		</Grid>
	);
};

export default MACPComponentConditions;
