import {
	CircularProgress,
	InputAdornment,
	TextFieldProps as MuiTextFieldProps,
	TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { SearchIcon, SearchWrapper } from './Search.styles';

import { Autocomplete } from '@mui/material';
import { GeocoderResult } from '@Root';
import { useDebounce } from '@Hooks/useDebounce';
import { useGlobalization } from '@Translations/useGlobalization';
import { usePrevious } from '@Hooks/usePrevious';

export interface SearchProps {
	defaultSearch?: string;
	onInputChange?: (inputValue: string) => Promise<GeocoderResult[]>;
	onSelect?: (selected: GeocoderResult | null) => void;
	TextFieldProps?: MuiTextFieldProps;
	hidePlaceholder?: boolean;
}

export const Search = ({
	defaultSearch = '',
	onInputChange,
	onSelect,
	TextFieldProps,
	hidePlaceholder,
}: SearchProps): JSX.Element => {
	const { t } = useGlobalization();
	const [open, setOpen] = useState(false);
	const [value, setValue] = useState<GeocoderResult | null>(null);
	const [options, setOptions] = useState<GeocoderResult[]>([]);
	const [inputValue, setInputValue] = useState(defaultSearch);
	const [loading, setLoading] = useState(false);
	const debouncedInputValue = useDebounce(inputValue, 300);
	const previousValue = usePrevious(value);

	useEffect(() => {
		if (!debouncedInputValue || debouncedInputValue.length < 3) {
			setOptions([]);
			return;
		}
		const getResponse = async () => {
			setLoading(true);
			const response = await onInputChange?.(debouncedInputValue);
			if (response) {
				setOptions(response);
				if (defaultSearch === inputValue) {
					setValue(response[0]);
				}
			} else {
				setOptions([]);
			}
			setLoading(false);
		};
		getResponse();
	}, [debouncedInputValue]);

	useEffect(() => {
		// prevent search emitting null unless value was previously set
		if (previousValue === undefined && !value) return;
		onSelect?.(value);
	}, [value]);

	useEffect(() => {
		setInputValue(defaultSearch);
	}, [defaultSearch]);

	return (
		<SearchWrapper data-cy="search-control">
			<Autocomplete
				open={open}
				onOpen={() => setOpen(true)}
				onClose={() => setOpen(false)}
				value={value}
				onChange={(_event, newValue) => setValue(newValue)}
				onInputChange={(_event, newInputValue) =>
					setInputValue(newInputValue)
				}
				options={options}
				getOptionLabel={option => option.place_name}
				isOptionEqualToValue={(option, currentValue) =>
					option.place_name === currentValue.place_name
				}
				loading={loading}
				loadingText={t('Loading...', {
					context: 'Map search loading text',
				})}
				noOptionsText={t('No options', {
					context: 'Map search no options',
				})}
				openText={t('Open', {
					context: 'Map search open popup icon button',
				})}
				closeText={t('Close', {
					context: 'Map search close popup icon button',
				})}
				clearText={t('Clear', {
					context: 'Map search clear icon button',
				})}
				renderInput={params => (
					<TextField
						{...params}
						placeholder={
							!hidePlaceholder
								? t('Search', {
										context:
											'Placeholder text for map search',
								  })
								: undefined
						}
						variant="outlined"
						fullWidth
						size="small"
						{...TextFieldProps}
						InputProps={{
							...params.InputProps,
							...TextFieldProps?.InputProps,
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon />
								</InputAdornment>
							),
							endAdornment: (
								<>
									{loading ? (
										<CircularProgress
											color="inherit"
											size={20}
										/>
									) : null}
									{params.InputProps.endAdornment}
								</>
							),
						}}
					/>
				)}
			/>
		</SearchWrapper>
	);
};
