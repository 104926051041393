import { CircularProgress, Grid } from '@mui/material';
import {
	StylovyzeFormV2,
	useIsFeatureEnabled,
	DateTimeInputMode,
	useUnits,
	useCompanyDateTime,
	useTzDateTime,
	useSettings,
} from '@innovyze/stylovyze';
import { Tab, TabPanel, TabWrapper, Tabs } from '@Components/Tabs';
import {
	a11yProps,
	fixupDateTimeForInputDateTime,
	getJustValues,
} from '@Utils';
import {
	refreshInsp,
	selectFullInspection,
	selectFullInspectionIsWaiting,
} from '@Selectors/fullInspection.selectors';

import React, { useEffect, useMemo } from 'react';
import { TFunc } from '@Translations/types';
import { useGlobalization } from '@Translations';
import { useDispatch } from 'react-redux';
import {
	refreshInspection,
	updateInspection,
} from '@Actions/inspection.actions';
import ModifiedInfoCard from '@Components/ModifiedInfoCard/ModifiedInfoCard';
import {
	UnitProps,
	DateTimeProps,
	getChangedFormikFields,
	getDetailGrid,
	getDomainItems,
	NumericProps,
	processFormSubmit,
} from '@Utils/common.utils';
import { FormikValues, Form } from 'formik';
import { InspectionStandard } from '@Types/inspection.types';

const EDITABLE_FIELDS = [
	'direction',
	'pipelineRef',
	'upstreamManholeId',
	'downstreamManholeId',
];

const NON_EDITABLE_FIELDS = ['uploadedBy', 'uploadDateTime'];

const isEditableField = (fieldName: string, fullEdit: boolean) => {
	return fullEdit
		? !NON_EDITABLE_FIELDS.includes(fieldName)
		: EDITABLE_FIELDS.includes(fieldName);
};

const HIDDEN_FIELDS = ['uploadDateTime', 'scheduledDate'];

const isHiddenField = (fieldName: string, hideFields: boolean) => {
	return hideFields ? HIDDEN_FIELDS.includes(fieldName) : false;
};

const generalFields = (t: TFunc): { name: string; alias: string }[] => [
	{ name: 'uploadedBy', alias: t('Uploaded By') },
	{ name: 'uploadDateTime', alias: t('Upload Date') },
	{ name: 'scheduledDate', alias: t('Scheduled Date') },
	{ name: 'surveyedBy', alias: t('Surveyed By') },
	{ name: 'certificateNumber', alias: t('Certificate No') },
	{
		name: 'reviewerCertificateNumber',
		alias: t('Reviewer Certificate No.'),
	},
	{ name: 'owner', alias: t('Owner') },
	{ name: 'poNumber', alias: t('P/O Number') },
	{ name: 'workOrder', alias: t('Work Order Number') },
	{ name: 'mediaLabel', alias: t('Media Label') },
	{ name: 'project', alias: t('Project') },
	{ name: 'customer', alias: t('Customer') },
	{ name: 'inspectionDate', alias: t('Date') },
	{ name: 'inspectionTime', alias: t('Time') },
	{ name: 'sheetNumber', alias: t('Sheet Number') },
	{ name: 'weather', alias: t('Weather') },
	{ name: 'preCleaning', alias: t('Pre-Cleaning') },
	{ name: 'dateCleaned', alias: t('Date Cleaned') },
	{ name: 'flowControl', alias: t('Flow Control') },
	{ name: 'purpose', alias: t('Purpose of Survey') },
	{ name: 'direction', alias: t('Direction of Survey') },
	{ name: 'reverseSetup', alias: t('Reverse Setup') },
	{
		name: 'inspectionTechnologyUsedCCTV',
		alias: t('Inspection Technology Used CCTV'),
	},
	{
		name: 'inspectionTechnologyUsedLaser',
		alias: t('Inspection Technology Used Laser'),
	},
	{
		name: 'inspectionTechnologyUsedSonar',
		alias: t('Inspection Technology Used Sonar'),
	},
	{
		name: 'inspectionTechnologyUsedSidewall',
		alias: t('Inspection Technology Used Sidewall'),
	},
	{
		name: 'inspectionTechnologyUsedZoom',
		alias: t('Inspection Technology Used Zoom'),
	},
	{
		name: 'inspectionTechnologyUsedOther',
		alias: t('Inspection Technology Used Other'),
	},
	{ name: 'status', alias: t('Status of Inspection') },
	{
		name: 'consequenceOfFailure',
		alias: t('Consequence of Failure'),
	},
	{ name: 'pressureValue', alias: t('Pressure Value') },
	{ name: 'sewerCategory', alias: t('Sewer Category') },
	{ name: 'additionalInfo', alias: t('Additional Info') },
	{ name: 'isImperial', alias: t('Is Imperial') },
];

const locationFields = (t: TFunc): { name: string; alias: string }[] => [
	{ name: 'drainageArea', alias: t('Drainage Area') },
	{ name: 'pipelineRef', alias: t('Pipe Segment Reference') },
	{ name: 'street', alias: t('Street Name and Number') },
	{ name: 'city', alias: t('City') },
	{ name: 'locationCode', alias: t('Location Code') },
	{ name: 'locationDetails', alias: t('Location Details') },
];

const pipeFields = (t: TFunc): { name: string; alias: string }[] => [
	{ name: 'pipeUse', alias: t('Pipe Use') },
	{ name: 'height', alias: t('Height') },
	{ name: 'width', alias: t('Width') },
	{ name: 'shape', alias: t('Shape') },
	{ name: 'material', alias: t('Material') },
	{ name: 'liningMethod', alias: t('Lining Method') },
	{ name: 'coatingMethod', alias: t('Coating Method') },
	{ name: 'pipeJointLength', alias: t('Pipe Joint Length') },
	{ name: 'totalLength', alias: t('Total Length') },
	{
		name: 'lengthSurveyed',
		alias: t('Length of Pipe Surveyed'),
	},
	{ name: 'yearConstructed', alias: t('Year Constructed') },
	{ name: 'yearRenewed', alias: t('Year Renewed') },
];

const manholesFields = (t: TFunc): { name: string; alias: string }[] => [
	{
		name: 'upstreamManholeId',
		alias: t('Upstream Manhole Number'),
	},
	{
		name: 'upRimToInvert',
		alias: t('Upstream Rim to Invert'),
	},
	{
		name: 'upRimToGrade',
		alias: t('Upstream Rim to Grade'),
	},
	{
		name: 'upGradeToInvert',
		alias: t('Upstream Grade to Invert'),
	},
	{
		name: 'upNorthing',
		alias: t('Upstream Manhole Northing'),
	},
	{
		name: 'upEasting',
		alias: t('Upstream Manhole Easting'),
	},
	{
		name: 'upElevation',
		alias: t('Upstream Manhole Elevation'),
	},
	{
		name: 'downstreamManholeId',
		alias: t('Downstream Manhole Number'),
	},
	{
		name: 'downRimToInvert',
		alias: t('Downstream Rim to Invert'),
	},
	{
		name: 'downRimToGrade',
		alias: t('Downstream Rim to Grade'),
	},
	{
		name: 'downGradeToInvert',
		alias: t('Downstream Grade to Invert'),
	},
	{
		name: 'downNorthing',
		alias: t('Downstream Manhole Northing'),
	},
	{
		name: 'downEasting',
		alias: t('Downstream Manhole Easting'),
	},
	{
		name: 'downElevation',
		alias: t('Downstream Manhole Elevation'),
	},
	{
		name: 'mhCoordinateSystem',
		alias: t('Coordinate System'),
	},
	{ name: 'verticalDatum', alias: t('Vertical Datum') },
	{ name: 'gpsAccuracy', alias: t('GPS Accuracy') },
];

const hardCodeUnitFields: { [key: string]: UnitProps } = {
	height: { unit: 'mm', min: 0, step: '1' },
	width: { unit: 'mm', min: 0, step: '1' },
	pipeJointLength: { unit: 'm', min: 0, step: '1' },
	totalLength: { unit: 'm', min: 0, step: '1' },
	lengthSurveyed: { unit: 'm', min: 0, step: '1' },
	upRimToInvert: { unit: 'm', step: '1' },
	upRimToGrade: { unit: 'm', step: '1' },
	upGradeToInvert: { unit: 'm', step: '1' },
	upElevation: { unit: 'm', step: '1' },
	downRimToInvert: { unit: 'm', step: '1' },
	downRimToGrade: { unit: 'm', step: '1' },
	downGradeToInvert: { unit: 'm', step: '1' },
	downElevation: { unit: 'm', step: '1' },
};

const hardCodeDateTimeFields: {
	[key: string]: DateTimeProps;
} = {
	uploadDateTime: {
		mode: DateTimeInputMode.DATE_TIME,
		field: 'uploadDateTime',
		useCompanyDateTime: true,
	},
	scheduledDate: {
		mode: DateTimeInputMode.DATE_TIME,
		field: 'scheduledDate',
	},
	inspectionDate: { mode: DateTimeInputMode.DATE, field: 'surveyDateTime' },
	inspectionTime: { mode: DateTimeInputMode.TIME, field: 'surveyDateTime' },
	dateCleaned: { mode: DateTimeInputMode.DATE, field: 'dateCleaned' },
};

const hardCodeCurrencyFields: {
	[key: string]: NumericProps;
} = {};

const hardCodeNumericFields: {
	[key: string]: NumericProps;
} = {
	yearConstructed: { min: 0 },
	yearRenewed: { min: 0 },
	upNorthing: { step: 0.1 },
	upEasting: { step: 0.1 },
	downNorthing: { step: 0.1 },
	downEasting: { step: 0.1 },
};

const hardCodeBooleanFields: {
	[key: string]: boolean;
} = {
	inspectionTechnologyUsedCCTV: true,
	inspectionTechnologyUsedLaser: true,
	inspectionTechnologyUsedSonar: true,
	inspectionTechnologyUsedSidewall: true,
	inspectionTechnologyUsedZoom: true,
	inspectionTechnologyUsedOther: true,
	reverseSetup: true,
	isImperial: true,
};

export const getDomainValues = (t: TFunc, fieldName: string) => {
	let domainValueDic: { [key: string]: string } = {};
	switch (fieldName.toLowerCase()) {
		case 'coatingMethod'.toLowerCase():
			domainValueDic = {
				CM: t('Cement Mortar'),
				CT: t('Coal Tar or Bituminous'),
				EP: t('Epoxy'),
				PE: t('Polyethylene'),
				PO: t('Polyurethane'),
				PU: t('Polyurea'),
				PVC: t('Polyvinyl Chloride'),
				XX: t('Not Known'),
				ZZ: t('Other'),
			};
			break;
		case 'direction'.toLowerCase():
			domainValueDic = {
				D: t('Downstream'),
				U: t('Upstream'),
			};
			break;
		case 'flowControl'.toLowerCase():
			domainValueDic = {
				B: t('Bypassed'),
				D: t('De-watered Using Jetter'),
				L: t('Lift Station'),
				N: t('Not Controlled'),
				P: t('Plugged'),
			};
			break;
		case 'gpsAccuracy'.toLowerCase():
			domainValueDic = {
				L: t('Survey Level'),
				M: t('Sub-Meter'),
				N: t('Nearest Meter'),
			};
			break;
		case 'locationCode'.toLowerCase():
			domainValueDic = {
				A: t('Primary Major Arterial Roads'),
				B: t('Secondary Roads'),
				C: t('Local Rural Streets'),
				D: t('Easement/Right of way'),
				E: t('Woods'),
				F: t('Sidewalk'),
				G: t('Parking lot'),
				H: t('Alley'),
				I: t('Ditch'),
				J: t('Building'),
				K: t('Creek'),
				L: t('Railway'),
				M: t('Airport'),
				N: t('Levee/Floodwall'),
				O: t('Dam'),
				P: t('Levee Pump Station'),
				Y: t('Yard'),
				Z: t('Other'),
			};
			break;
		case 'liningMethod'.toLowerCase():
			domainValueDic = {
				CIP: t('Cured-In-Place Pipe'),
				FF: t('Fold and Form or Deform/Reform'),
				FP: t('Formed-In-Place Liner'),
				GP: t('Grout-In-Place Liner'),
				GRC: t('Glass Reinforced Cement'),
				N: t('None'),
				SC: t('Continuous Slip Liner'),
				SE: t('Sectional Slip Liner'),
				SL: t('Spray Liner'),
				SN: t('Segmented Panel'),
				SP: t('Segmented Pipe'),
				SW: t('Spiral Wound'),
				XX: t('Not Known'),
				ZZ: t('Other'),
			};
			break;
		case 'material'.toLowerCase():
			domainValueDic = {
				ABS: t('Acrylonitrile Butadiene Styrene'),
				AC: t('Asbestos Cement'),
				BR: t('Brick'),
				CAS: t('Cast Iron'),
				CLC: t('Clay-lined Concrete Pipe'),
				CMP: t('Corrugated Metal Pipe'),
				CP: t('Concrete Pipe (non-reinforced)'),
				CSB: t('Concrete Segments (bolted)'),
				CSU: t('Concrete Segments (unbolted)'),
				CT: t('Clay Tile (not vitrified clay)'),
				DIP: t('Ductile Iron Pipe'),
				FRP: t('Fiberglass Reinforced Pipe'),
				OB: t('Pitch Fiber (Orangeburg)'),
				PCP: t('Polymer Concrete Pipe'),
				PCCP: t('Pre-stressed Concrete Cylinder Pipe'),
				PE: t('Polyethylene'),
				PP: t('Polypropylene'),
				PSC: t('Plastic/Steel Composite'),
				PVC: t('Polyvinyl Chloride'),
				RCP: t('Reinforced Concrete Pipe'),
				RPM: t('Reinforced Plastic Pipe (Truss Pipe)'),
				SB: t('Segmented Block'),
				SP: t('Steel Pipe'),
				VCP: t('Vitrified Clay Pipe'),
				WD: t('Wood'),
				XXX: t('Not Known'),
				ZZZ: t('Other'),
			};
			break;
		case 'shape'.toLowerCase():
			{
				domainValueDic = {
					A: t('Arched'),
					B: t('Barrel'),
					C: t('Circular'),
					E: t('Egg-Shaped'),
					H: t('Horseshoe'),
					O: t('Oval (Elliptical)'),
					R: t('Rectangular'),
					S: t('Square'),
					T: t('Trapezoidal'),
					U: t('U-Shaped with Flat Top (straight walls)'),
					Z: t('Other'),
				};
			}
			break;
		case 'pipeUse'.toLowerCase():
			domainValueDic = {
				CB: t('Combined Pipe'),
				DP: t('Dam Pipe'),
				FM: t('Force Main'),
				LG: t('Levee Gravity Pipe'),
				LP: t('Levee Pressure Pipe'),
				PR: t('Process Pipe'),
				SS: t('Sanitary Sewage Pipe'),
				SW: t('Stormwater Pipe'),
				XX: t('Not Known'),
				ZZ: t('Other'),
			};
			break;
		case 'purpose'.toLowerCase():
			domainValueDic = {
				A: t('Maintenance Related'),
				B: t('Infiltration/Inflow Investigation'),
				C: t('Post Rehabilitation Survey'),
				D: t('Pre-Rehabilitation Survey'),
				E: t('Pre-Acceptance New Sewers'),
				F: t('Routine Assessment'),
				G: t('Capital Improvement Program Assessment'),
				H: t('Resurvey For Any Reason'),
				I: t('Sewer System Evaluation Survey (SSES)'),
				R: t('Pre-Existing Video'),
				X: t('Not Known'),
			};
			break;
		case 'preCleaning'.toLowerCase():
			domainValueDic = {
				H: t('Heavy Cleaning'),
				L: t('Light Cleaning'),
				N: t('No Pre-Cleaning'),
				X: t('Not Known'),
				Z: t('Other'),
			};
			break;
		case 'status'.toLowerCase():
			domainValueDic = {
				CI: t('Complete Inspection'),
				BM: t('Buried and Marked'),
				NA: t('No Access'),
				NE: t('Does Not Exist'),
				NF: t('Not Found'),
				NI: t('Traffic'),
				NO: t('Not Opened'),
				SD: t('Surcharged/Debris'),
			};
			break;
		case 'weather'.toLowerCase():
			domainValueDic = {
				1: t('Dry - No precipitation during survey'),
				2: t('Heavy Rain'),
				3: t('Light Rain'),
				4: t('Snow'),
				5: t('Dry Weather/Wet Ground'),
			};
			break;
		case 'inspectionTechnologyUsedCCTV'.toLowerCase():
		case 'inspectionTechnologyUsedLaser'.toLowerCase():
		case 'inspectionTechnologyUsedSonar'.toLowerCase():
		case 'inspectionTechnologyUsedSidewall'.toLowerCase():
		case 'inspectionTechnologyUsedZoom'.toLowerCase():
		case 'inspectionTechnologyUsedOther'.toLowerCase():
			domainValueDic = {
				No: '',
				Yes: '',
			};
			break;
		default:
			break;
	}

	return domainValueDic;
};

interface Props {
	onReRender?: () => void;
}

export const PACPInspectionProps = ({ onReRender }: Props): JSX.Element => {
	const { t } = useGlobalization();
	const { getSystemValueFormatted } = useUnits();
	const dispatch = useDispatch();
	const { currentDateTime } = useCompanyDateTime();
	const formatDateUTC = useTzDateTime({ timeZone: 'UTC' }).formatDate;
	const { companySettings } = useSettings();

	const [tabValue, setTabValue] = React.useState(0);
	const isLoading = selectFullInspectionIsWaiting();
	const [editHeaderDetails, setEditHeaderDetails] = React.useState(false);
	const [formSubmitted, setFormSubmitted] = React.useState(false);
	const inspection = selectFullInspection();

	const origDetails = useMemo(() => {
		setFormSubmitted(false);

		const values = getJustValues(inspection, false);

		const keys = Object.keys(values);
		keys.forEach(key => {
			// Adjust any date time fields for timezone/dst offsets caused by the datetime control
			const field = Object.entries(hardCodeDateTimeFields).find(
				value => value[1].field === key,
			);
			if (field) {
				values[key] = fixupDateTimeForInputDateTime(
					values[key],
					field[1].useCompanyDateTime ? currentDateTime() : undefined,
				);
			}
		});

		return values;
	}, [inspection]);

	const viewDetails = useMemo(() => {
		const values = getJustValues(inspection, true);

		const keys = Object.keys(values);
		keys.forEach(key => {
			// Adjust any date time fields for timezone/dst offsets caused by the datetime control
			const field = Object.entries(hardCodeDateTimeFields).find(
				value => value[1].field === key,
			);
			if (field) {
				values[key] = fixupDateTimeForInputDateTime(
					values[key],
					field[1].useCompanyDateTime ? currentDateTime() : undefined,
				);
			}
		});

		return values;
	}, [inspection]);

	const inspUpdatestatus = refreshInsp();

	//feature to be behind a LaunchDarkley flag
	const isEditAllowedFlag = useIsFeatureEnabled(
		'info-360-edit-inspections-header',
	)
		? true
		: false;

	const isFullEditAllowedFlag = useIsFeatureEnabled(
		'info-360-edit-full-inspections',
	)
		? true
		: false;

	const isCreateAllowedFlag = useIsFeatureEnabled(
		'info-360-create-inspections',
	);

	const handleChangeTab = (event: unknown, newValue: number) => {
		setTabValue(newValue);
	};

	const handleFormSubmit = (data: FormikValues) => {
		// We should really used the touched state of a field but I cant get the Formik stuff to work properly
		const changes = getChangedFormikFields(origDetails, data);

		if (changes && Object.entries(changes).length !== 0) {
			setFormSubmitted(true);

			const allFields: { name: string; alias: string }[] = [
				...generalFields(t),
				...locationFields(t),
				...pipeFields(t),
				...manholesFields(t),
			];

			const editedFieldsList = processFormSubmit(
				t,
				InspectionStandard.PACP,
				origDetails,
				changes,
				allFields,
				hardCodeDateTimeFields,
				hardCodeUnitFields,
				hardCodeCurrencyFields,
				hardCodeNumericFields,
				hardCodeBooleanFields,
				getSystemValueFormatted,
				formatDateUTC,
				companySettings,
				t('Date/Time of inspection'),
				{ date: 'date', time: 'time' },
			);

			dispatch(refreshInspection({ status: -1 })); //reset update flag and hide the controls
			dispatch(updateInspection(editedFieldsList)); //trigger update
		}
	};

	// //when inspection has been updated, page needs to be refreshed to display latest details
	useEffect(() => {
		if (inspUpdatestatus === 200 && onReRender) onReRender();
	}, [inspUpdatestatus]);

	const general = (
		<ModifiedInfoCard
			title={t('General')}
			dataCy="modified-info-card-general"
			showEditBtn={
				origDetails['inspectionStatus'] !== 'approved' &&
				onReRender !== undefined
			}
			launchDarkleyFlag={isEditAllowedFlag}
			onEditClick={() => {
				setEditHeaderDetails(true);
			}}
			onCancelClick={() => {
				setEditHeaderDetails(false);
			}}
			inEditMode={editHeaderDetails}
			disabled={inspUpdatestatus === -1}>
			<Grid container>
				{getDetailGrid(
					t,
					generalFields(t),
					hardCodeUnitFields,
					hardCodeDateTimeFields,
					hardCodeCurrencyFields,
					hardCodeNumericFields,
					hardCodeBooleanFields,
					isEditableField,
					isFullEditAllowedFlag,
					isHiddenField,
					!isCreateAllowedFlag,
					getDomainValues,
					getDomainItems,
				)}
			</Grid>
		</ModifiedInfoCard>
	);

	const location = (
		<ModifiedInfoCard
			title={t('Location')}
			dataCy="modified-info-card-location"
			showEditBtn={
				origDetails['inspectionStatus'] !== 'approved' &&
				onReRender !== undefined
			}
			launchDarkleyFlag={isEditAllowedFlag}
			onEditClick={() => {
				setEditHeaderDetails(true);
			}}
			onCancelClick={() => {
				setEditHeaderDetails(false);
			}}
			inEditMode={editHeaderDetails}
			disabled={inspUpdatestatus === -1}>
			<Grid container>
				{getDetailGrid(
					t,
					locationFields(t),
					hardCodeUnitFields,
					hardCodeDateTimeFields,
					hardCodeCurrencyFields,
					hardCodeNumericFields,
					hardCodeBooleanFields,
					isEditableField,
					isFullEditAllowedFlag,
					undefined,
					undefined,
					getDomainValues,
					getDomainItems,
				)}
			</Grid>
		</ModifiedInfoCard>
	);

	const pipe = (
		<ModifiedInfoCard
			title={t('Construction')}
			dataCy="modified-info-card-construction"
			showEditBtn={
				origDetails['inspectionStatus'] !== 'approved' &&
				onReRender !== undefined
			}
			launchDarkleyFlag={isEditAllowedFlag}
			onEditClick={() => {
				setEditHeaderDetails(true);
			}}
			onCancelClick={() => {
				setEditHeaderDetails(false);
			}}
			inEditMode={editHeaderDetails}
			disabled={inspUpdatestatus === -1}>
			<Grid container>
				{getDetailGrid(
					t,
					pipeFields(t),
					hardCodeUnitFields,
					hardCodeDateTimeFields,
					hardCodeCurrencyFields,
					hardCodeNumericFields,
					hardCodeBooleanFields,
					isEditableField,
					isFullEditAllowedFlag,
					undefined,
					undefined,
					getDomainValues,
					getDomainItems,
				)}
			</Grid>
		</ModifiedInfoCard>
	);

	const manholes = (
		<ModifiedInfoCard
			title={t('Manholes')}
			dataCy="modified-info-card-manholes"
			showEditBtn={
				origDetails['inspectionStatus'] !== 'approved' &&
				onReRender !== undefined
			}
			launchDarkleyFlag={isEditAllowedFlag}
			onEditClick={() => {
				setEditHeaderDetails(true);
			}}
			onCancelClick={() => {
				setEditHeaderDetails(false);
			}}
			inEditMode={editHeaderDetails}
			disabled={inspUpdatestatus === -1}>
			<Grid container>
				{getDetailGrid(
					t,
					manholesFields(t),
					hardCodeUnitFields,
					hardCodeDateTimeFields,
					hardCodeCurrencyFields,
					hardCodeNumericFields,
					hardCodeBooleanFields,
					isEditableField,
					isFullEditAllowedFlag,
					isHiddenField,
					!isCreateAllowedFlag,
					getDomainValues,
					getDomainItems,
				)}
			</Grid>
		</ModifiedInfoCard>
	);

	if (!isLoading && origDetails && !formSubmitted) {
		return (
			<StylovyzeFormV2
				mode={editHeaderDetails ? 'edit' : 'view'}
				initialValues={editHeaderDetails ? origDetails : viewDetails}
				enableReinitialize
				onSubmit={(data: FormikValues) => {
					setEditHeaderDetails(false);
					handleFormSubmit(data);
				}}>
				{({ handleSubmit }) => {
					return (
						<form onSubmit={handleSubmit}>
							<TabWrapper $height={683}>
								<Tabs
									orientation="vertical"
									variant="scrollable"
									value={tabValue}
									onChange={handleChangeTab}
									aria-label="manhole-attributes">
									<Tab
										label={t('General')}
										{...a11yProps(0)}
									/>
									<Tab
										label={t('Location')}
										{...a11yProps(1)}
									/>
									<Tab label={t('Pipe')} {...a11yProps(2)} />
									<Tab
										label={t('Manholes')}
										{...a11yProps(3)}
									/>
								</Tabs>
								<TabPanel value={tabValue} index={0}>
									{general}
								</TabPanel>
								<TabPanel value={tabValue} index={1}>
									{location}
								</TabPanel>
								<TabPanel value={tabValue} index={2}>
									{pipe}
								</TabPanel>
								<TabPanel value={tabValue} index={3}>
									{manholes}
								</TabPanel>
							</TabWrapper>
						</form>
					);
				}}
			</StylovyzeFormV2>
		);
	} else {
		return (
			<div style={{ padding: '25px' }}>
				<CircularProgress />
			</div>
		);
	}
};

export default PACPInspectionProps;
