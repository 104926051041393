export const isValidLocation = ({
	latitude,
	longitude,
}: {
	latitude: number;
	longitude: number;
}): boolean => {
	if (isNaN(latitude) || isNaN(longitude)) return false;
	if (
		latitude >= -90 &&
		latitude <= 90 &&
		longitude >= -180 &&
		longitude <= 180
	)
		return true;
	return false;
};
