import { useLayoutEffect, useState } from 'react';
import Highcharts from 'highcharts';

import type { DependencyList } from 'react';

const mergeOptions = (
  options: Highcharts.Options | (Highcharts.Options | undefined)[]
): Highcharts.Options => {
  if (Array.isArray(options)) {
    const [firstOptions, ...restOptions] = options;
    return Highcharts.merge<Highcharts.Options>(
      false,
      firstOptions,
      ...restOptions
    );
  } else {
    return options;
  }
};

const useHighchartsOptions = (
  optionsGenerator: () =>
    | Highcharts.Options
    | (Highcharts.Options | undefined)[],
  deps?: DependencyList
): Highcharts.Options => {
  const [options, setOptions] = useState<Highcharts.Options>(
    mergeOptions(optionsGenerator())
  );

  useLayoutEffect(() => {
    setOptions(mergeOptions(optionsGenerator()));
  }, deps);

  return options;
};

export default useHighchartsOptions;
