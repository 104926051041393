import { createContext, RefObject, useContext } from 'react';
import { MapGlobalProps, ReactMapProps } from '..';
import { LayerDebugInfo } from '../map/layers/LayerManager';

interface DebugContextValues {
	bounds?: ReactMapProps['defaultBounds'];
	setBounds?: ReactMapProps['onBoundsChange'];
	position?: ReactMapProps['position'];
	setPosition?: ReactMapProps['onPositionChange'];
	background?: ReactMapProps['background'];
	setBackground?: ReactMapProps['onBackgroundChanged'];
	hiddenLayers?: ReactMapProps['hiddenLayers'];
	setHiddenLayers?: ReactMapProps['onHiddenLayersChange'];
	selectedAssets?: ReactMapProps['selectedAssets'];
	setSelectedAssets?: ReactMapProps['onSelectedAssetsChange'];
	layersDebug?: LayerDebugInfo[];
	setLayersDebug?: MapGlobalProps['onLayersDebug'];
	logs?: { name: string; data: unknown }[];
	addLog?: (name: string) => (data: unknown) => void;
	fullscreen?: boolean;
	toggleFullscreen?: () => void;
	wrapperRef: RefObject<HTMLDivElement>;
}
export const DebugContext = createContext<DebugContextValues>({
	wrapperRef: { current: null },
});

export const useDebug = (): DebugContextValues => useContext(DebugContext);
