import { CompositeLayerProps } from '../types';
import LineSymboCreatorlLayer from './LineSymbolCreatorLayer';

export default class FlowCreatorLayer extends LineSymboCreatorlLayer {
	constructor(layerInfo: CompositeLayerProps, map: mapboxgl.Map) {
		super(layerInfo, map, {
			idPostfix: '-flow',
			zIndexesPerType: { base: 1 },
		});
	}

	get minZoom(): CompositeLayerProps['flowMinZoom'] {
		return this.layerInfo.flowMinZoom;
	}

	get maxZoom(): undefined {
		return undefined;
	}

	get selectedId(): CompositeLayerProps['id'] {
		return this.layerInfo.id + '-flow-selected';
	}

	get icon(): CompositeLayerProps['icon'] {
		return 'flow-arrow';
	}
}
