import { UnitSystem } from '@Components/Inputs/units';
import { DistanceUnit } from '@Map/types';
import { useMapGlobals } from './MapGlobals';
import { ReactMapConfig } from './types';

const DEFAULT_CONFIG: ReactMapConfig = {
	arcGISBasemapStylesToken: undefined,
	basemapOrigin: undefined,
	bearerToken: undefined,
	colorByMaxValues: undefined,
	defaultBounds: [],
	defaultThemeId: undefined,
	distanceUnit: DistanceUnit.metric,
	localStoragePrefix: '',
	mapKey: '',
	searchRadius: undefined,
	showMultiThemes: undefined,
	unitSystem: UnitSystem.Metric,
	themeEndpoint: undefined,
};

export const useMapConfig = (config: ReactMapConfig): ReactMapConfig => {
	const globalValues = useMapGlobals();

	return {
		...DEFAULT_CONFIG,
		...globalValues,
		...config,
	};
};
