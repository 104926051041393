import { AssetStoreState } from './asset.types';
import { RiskStoreState } from './risk.types';
import { moduleNamespace } from '../store.config';

import { InspectionStoreState } from './inspection.types';
import { FacilityStoreState } from './facility.types';
import { MapStoreState } from './map.types';
// plop - add type import here

export interface StoreState {
	risk: RiskStoreState;
	asset: AssetStoreState;
	inspection: InspectionStoreState;
	facility: FacilityStoreState;
	map: MapStoreState;
	// plop - add type reducer here
}

export interface NamespacedStoreState {
	[moduleNamespace]: StoreState;
}
