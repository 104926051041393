import {
	Button,
	ButtonProps,
	DialogActions as MuiDialogActions,
	DialogContent as MuiDialogContent,
} from '@mui/material';
import styled, { css } from 'styled-components';

import { FC } from 'react';
import MuiEditIcon from '@mui/icons-material/Edit';
import MuiRestoreIcon from '@mui/icons-material/Restore';
import { rem } from '@Components/styles';

const iconProps = css`
	width: 1em;
	height: 1em;
	color: #768d95;
`;

export const EditIcon = styled(MuiEditIcon)`
	${iconProps};
`;

export const StyleEditorButton = styled(Button)`
	color: #4a6067 !important;
	text-transform: unset !important;
`;

export const RestoreIcon = styled(MuiRestoreIcon)`
	${iconProps};
`;

export const DialogContent = styled(MuiDialogContent)`
	padding: ${rem(28)} ${rem(24)};
	border-top: ${rem(1)} solid #f1f4f4;
	border-bottom: ${rem(1)} solid #f1f4f4;
	button {
		text-transform: unset !important;
	}
`;

export const DialogActions = styled(MuiDialogActions)`
	padding: ${rem(20)};
	> :not(:first-child) {
		margin-left: ${rem(12)};
	}
	button {
		text-transform: unset !important;
	}
`;

export const ResetButton = styled(Button)`
	&,
	&:hover {
		background-color: ${({ theme }) => theme.palette.error.dark};
		color: #fff;
	}
	button {
		text-transform: unset !important;
	}
`;

export const ActionButtons = styled.div`
	margin-left: auto;
	button {
		text-transform: unset !important;
	}
`;

export const SaveButton: FC<ButtonProps & { $disabled: boolean }> = styled(
	Button,
)<{ $disabled: boolean }>`
	background-color: #007ca0 !important;
	color: #ffffff !important;
	${({ $disabled }) =>
		$disabled &&
		css`
			opacity: 0.5;
			cursor: default;
		`}
`;

export const CancelButton = styled(Button)`
	background-color: #f1f4f4 !important;
	color: #4a6067 !important;
`;
