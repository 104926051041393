import { InfoCard } from '@innovyze/stylovyze';
import { CircularProgress, Grid } from '@mui/material';
import {
	selectAssetRisk,
	selectAssetRiskIsReady,
} from '@Selectors/assetRisk.selectors';
import { useGlobalization } from '@Translations';
import React from 'react';
import { CLOFScore } from '../CLOFScore';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const RiskComponents = (): JSX.Element => {
	const { t } = useGlobalization();

	const isRiskReady = selectAssetRiskIsReady();
	const assetRisk = selectAssetRisk();

	return (
		<InfoCard fullHeight title={t('Components')}>
			{isRiskReady ? (
				assetRisk ? (
					<Grid container spacing={3}>
						<Grid item xs={6}>
							<CLOFScore clof="LoF" />
						</Grid>
						<Grid item xs={6}>
							<CLOFScore clof="CoF" />
						</Grid>
					</Grid>
				) : (
					<div />
				)
			) : (
				<CircularProgress />
			)}
		</InfoCard>
	);
};
