import { NamespacedStoreState } from '@Types/store.types';
import { createSelector } from '@reduxjs/toolkit';
import { moduleNamespace } from '@Store/config.store';
import { useSelector } from 'react-redux';

const parseNamespace = (state: NamespacedStoreState) => state[moduleNamespace];

const assetRiskHistorySelector = createSelector(
	parseNamespace,
	state => state.assetRiskHistory?.assetRiskHistory,
);

const assetRiskHistoryReadySelector = createSelector(
	parseNamespace,
	state => state.assetRiskHistory?.isReady,
);

export const selectAssetRiskHistoryIsReady = () =>
	useSelector(assetRiskHistoryReadySelector);
export const selectAssetRiskHistory = () =>
	useSelector(assetRiskHistorySelector);

export default { selectAssetRiskHistoryIsReady, selectAssetRiskHistory };
