/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  AnalyticFunctionToReading,
  dataGroupingFunctions,
  DEFAULT_CHART_COLORS,
  HiddenSeries,
  Resolutions,
} from '../../../types';
import { analyticFunctionMap } from '../../../utils/analyticFunctions';
import Highcharts from 'highcharts';
import { StackOptions } from '../../../core/components/StackedChart';
import {
  HistoricalDisplayOptions,
  HistoricalSeries,
} from '../../../core/types/historical.types';
import { Reading } from '../../../core/types/reading.types';

import {
  AsyncSensorData,
  SensorDataRecords,
  SensorMeasureData,
} from '../../../core/types/data.types';
import { isSeriesVisible } from '../../../core/utils/hiddenSeries.utils';
import { readingIndex } from '../../../core/utils/data.utils';
import { palette } from '@innovyze/stylovyze';
import { Resolution } from '../../../core/types/resolution.types';

const stackingMode = 'normal';
const dataLabelPadding = 0.1;

const yAxisSideToValue: Record<string, boolean> = {
  left: false,
  right: true,
};

const overrideData = (
  data: [number, number][],
  dataOverride?: [number, number][]
): [number, number][] => {
  let overrideIndex = 0;

  if (!dataOverride || dataOverride.length === 0) {
    return data;
  }

  return data.map((datum) => {
    if (
      dataOverride.length !== overrideIndex &&
      datum[0] === dataOverride[overrideIndex][0]
    ) {
      overrideIndex++;
      return dataOverride[overrideIndex - 1];
    } else {
      return datum;
    }
  });
};

/**
 * Turns out workspaces sends these values as strings rather than numbers.
 *
 * This is because the values come directly from formik and input values get returned as strings. It can be fixed on workspaces side, but just to make sure.
 */
const makeValidRangeType = (range?: {
  min?: number | string;
  max?: number | string;
}): [min: number | undefined, max: number | undefined] => {
  let min = undefined;
  let max = undefined;

  if (range !== undefined) {
    if (range.min !== undefined) {
      if (typeof range.min === 'number') {
        min = range.min;
      } else if (range.min !== '') {
        min = Number(range.min);
      }
    }

    if (range.max !== undefined) {
      if (typeof range.max === 'number') {
        max = range.max;
      } else if (range.max !== '') {
        max = Number(range.max);
      }
    }
  }

  return [min, max];
};

const generateChartTypeOptions = (
  series: HistoricalSeries,
  seriesColor: string,
  isBoostModuleEnabled: boolean,
  dataRecord: AsyncSensorData,
  displayOptions?: HistoricalDisplayOptions
): Highcharts.SeriesOptionsType => {
  const chartType = series.displayOptions?.chartType ?? 'line';
  const timestamps = dataRecord?.data?.timestamps ?? [];
  const measurements = dataRecord?.data?.measurements ?? {};

  const dataLabelsOptions = {
    padding: 7,
    backgroundColor: 'rgba(0, 0, 0, 0)',
    borderWidth: 0,
    color: palette.secondary.dark,
    crop: false,
    enabled: series.displayOptions?.showDataLabels ?? false,
    format: '{point.y:.2f}',
    overflow: 'allow',
  };

  /**
   * Remember that Highcharts will only UPDATE the options returned here.
   * So, MAKE SURE TO RESET all options for each one of the series, even
   * if they don't need them. Otherwise, you can get in an invalid state
   * when changing chart types on the UI.
   *
   * Example:
   * If you don't set dataGrouping.aproximation as undefined, the user
   * won't see anything if the chart changes from any other type to
   * candlestick, as in the previous state, dataGrouping.aproximation's
   * value was dataGroupingFunctions[series.func].
   * */
  switch (chartType) {
    case 'candlestick':
      // TODO: Check this error
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return {
        color: seriesColor,
        data: timestamps.map((timestamp) => [
          timestamp,
          measurements[timestamp]?.[readingIndex[Reading.Open]],
          measurements[timestamp]?.[readingIndex[Reading.High]],
          measurements[timestamp]?.[readingIndex[Reading.Low]],
          measurements[timestamp]?.[readingIndex[Reading.Close]],
        ]),
        dataGrouping: {
          approximation: undefined,
          enabled: !isBoostModuleEnabled,
        },
        dataLabels:
          dataLabelsOptions as Highcharts.PlotCandlestickDataLabelsOptions,
        lineColor: seriesColor,
        lineWidth: 2,
        marker: {
          enabled: false,
          radius: 4,
        },
        states: {
          hover: {
            lineWidth: undefined,
            lineWidthPlus: 0,
          },
        },
        type: 'candlestick',
      };
    case 'scatter':
      return {
        color: seriesColor,
        data: overrideData(
          analyticFunctionMap[series.func](
            timestamps,
            measurements,
            series.params
          ),
          series.dataOverride
        ),
        dataGrouping: {
          approximation: dataGroupingFunctions[series.func],
          enabled: !isBoostModuleEnabled,
        },
        dataLabels: dataLabelsOptions as Highcharts.DataLabelsOptions,
        lineWidth: 0,
        marker: {
          enabled: true,
          radius: 4,
        },
        states: {
          hover: {
            lineWidth: 0,
            lineWidthPlus: 0,
          },
        },
        type: 'line',
      };
    default:
      // @ts-ignore
      return {
        stacking:
          chartType === 'column' && displayOptions?.stacked
            ? stackingMode
            : undefined,
        color: seriesColor,
        dashStyle:
          chartType === 'line' || chartType === 'spline'
            ? series.displayOptions?.dashStyle
            : undefined,
        data: overrideData(
          analyticFunctionMap[series.func](
            timestamps,
            measurements,
            series.params
          ),
          series.dataOverride
        ),
        dataGrouping: {
          approximation: dataGroupingFunctions[series.func],
          enabled: !isBoostModuleEnabled,
        },
        // @ts-ignore
        dataLabels: dataLabelsOptions,
        lineWidth: 2,
        marker: {
          enabled: false,
          radius: 4,
        },
        states: {
          hover: {
            lineWidth: undefined,
            lineWidthPlus: 0,
          },
        },
        type: chartType,
      };
  }
};

export const generateHistoricalOptions = (
  sensorDataRecords: SensorDataRecords,
  isBoostModuleEnabled: boolean,
  historicalSeries: HistoricalSeries[],
  hiddenSeries: HiddenSeries,
  isResolutionDropdownEnabled: boolean,
  resolutionOptions?: boolean,
  displayOptions?: HistoricalDisplayOptions
): Highcharts.Options => {
  const seriesOptions: Highcharts.SeriesOptionsType[] = [];

  const boostOptions: Highcharts.BoostOptions = {
    enabled: isBoostModuleEnabled,
    useGPUTranslations: isBoostModuleEnabled,
  };

  const xAxisOptions: Highcharts.XAxisOptions = {
    gridLineWidth: displayOptions?.showXGrid ? 1 : 0,
    type: 'datetime',
    plotLines: [],
  };

  const yAxisOptions: Highcharts.YAxisOptions[] = [
    {
      gridLineWidth: displayOptions?.showYGrid ? 1 : 0,
      max: undefined,
      min: undefined,
      opposite: false,
      maxPadding: historicalSeries[0].displayOptions?.showDataLabels
        ? dataLabelPadding
        : undefined,
    },
  ];

  const sharedYAxisUnits: string[] = [];
  let seriesIndex = 0;

  historicalSeries.forEach((series) => {
    let seriesYAxis = 0;

    const { sensorId, resolution } = series.dataSource;
    const seriesColor =
      series.displayOptions?.color ?? DEFAULT_CHART_COLORS[seriesIndex];

    const dataRecord = sensorDataRecords?.[sensorId]?.[resolution];

    const [min, max] = makeValidRangeType(series.displayOptions?.yRange);

    if (
      series.displayOptions?.yAxis ||
      min !== undefined ||
      max !== undefined
    ) {
      seriesYAxis = yAxisOptions.length;

      yAxisOptions.push({
        gridLineWidth: 0,
        labels: { style: { color: seriesColor } },
        endOnTick: !series.displayOptions?.yRange?.max,
        max,
        min,
        startOnTick: !series.displayOptions?.yRange?.min,
        maxPadding: series.displayOptions?.showDataLabels
          ? dataLabelPadding
          : undefined,
        opposite:
          yAxisSideToValue[series.displayOptions?.yAxis?.side ?? 'left'],
        title: {
          style: { color: seriesColor },
          text: `${
            series.displayOptions?.yAxis?.label || dataRecord?.data?.unit
          }`,
        },
      });
    } else {
      if (seriesIndex === 0) {
        const [min, max] = makeValidRangeType(displayOptions?.yRange);
        yAxisOptions[0].min = min;
        yAxisOptions[0].max = max;
      }

      if (series.displayOptions?.showDataLabels) {
        yAxisOptions[0].maxPadding = dataLabelPadding;
      }

      sharedYAxisUnits.push(dataRecord?.data?.unit ?? '');
    }

    // @ts-ignore
    seriesOptions.push({
      name: series.alias,
      yAxis: seriesYAxis,
      visible: isSeriesVisible(
        hiddenSeries,
        seriesIndex,
        series.id,
        series.alias
      ),
      custom: {
        resolution,
        sensorId,
        seriesIndex,
      },
      ...generateChartTypeOptions(
        series,
        seriesColor,
        isBoostModuleEnabled,
        dataRecord,
        displayOptions
      ),
    });

    seriesIndex++;

    //Series had a trendline
    if (series.displayOptions?.trendline?.periods) {
      const measurements = { ...dataRecord.data?.measurements };
      const { periods, forecastPeriods } = series.displayOptions.trendline;
      const lastPoints = dataRecord.data?.timestamps.filter(
        (_, timeIndex) =>
          timeIndex >= dataRecord.data.timestamps.length - periods
      );

      if (lastPoints && lastPoints?.length >= 2) {
        const trendReading =
          series.displayOptions.chartType !== 'candlestick'
            ? AnalyticFunctionToReading[series.func]
            : Reading.Average;
        const trendlineData = calculateTrendline(
          lastPoints || [],
          lastPoints?.map((timestamp) =>
            measurements
              ? measurements[timestamp]?.[readingIndex[trendReading]]
              : 0
          ) || [],
          forecastPeriods,
          series.dataSource.resolution
        );

        const newData = {
          measurements: trendlineData.measurements,
          timestamps: trendlineData.xPoints,
          unit: dataRecord.data?.unit,
        };

        sensorDataRecords[`${series.alias} Trendline`] = {};
        sensorDataRecords[`${series.alias} Trendline`][resolution] = {
          data: newData,
          status: 'resolved',
        };

        const trendlineColor =
          series.displayOptions.trendline.color || seriesColor;

        if (
          !displayOptions?.overlay &&
          (series.displayOptions?.yAxis ||
            min !== undefined ||
            max !== undefined)
        ) {
          seriesYAxis++;
        }

        //@ts-ignore
        seriesOptions.push({
          name: `${series.alias} Trendline`,
          yAxis: seriesYAxis,
          visible: isSeriesVisible(
            hiddenSeries,
            seriesIndex,
            series.id,
            series.alias
          ),
          custom: {
            seriesIndex,
            resolution,
            sensorId: `${sensorId} Trendline`,
          },
          ...generateChartTypeOptions(
            {
              ...series,
              displayOptions: {
                ...series.displayOptions,
                chartType: 'line',
                dashStyle:
                  series.displayOptions?.trendline?.dashStyle || 'Dash',
              },
            },
            trendlineColor,
            isBoostModuleEnabled,
            {
              status: 'resolved',
              data: newData,
            },
            displayOptions
          ),
        });
        seriesIndex++;
      }
    }
  });

  yAxisOptions[0].title = {
    text: Array.from(new Set(sharedYAxisUnits))
      .filter((unit) => !!unit)
      .join(', '),
  };

  if (displayOptions?.yAxis?.label || displayOptions?.yAxis?.side) {
    const primaryColor =
      historicalSeries[0].displayOptions?.color ?? DEFAULT_CHART_COLORS[0];
    const opposite = yAxisSideToValue[displayOptions?.yAxis?.side] ?? false;
    yAxisOptions[0].opposite = opposite;
    yAxisOptions[0].labels = { style: { color: primaryColor } };
    yAxisOptions[0].offset = opposite ? 35 : 0;
    yAxisOptions[0].title = {
      style: { color: primaryColor },
      text: `${
        displayOptions?.yAxis?.label ||
        Array.from(new Set(sharedYAxisUnits))
          .filter((unit) => !!unit)
          .join(', ')
      }`,
    };
  }

  return {
    legend: { enabled: true },
    navigator: { enabled: true },
    rangeSelector: {
      enabled: true,
      inputPosition: {
        align:
          isResolutionDropdownEnabled && resolutionOptions ? 'center' : 'right',
      },
    },
    boost: boostOptions,
    series: seriesOptions,
    xAxis: xAxisOptions,
    yAxis: yAxisOptions,
  };
};

export const generateHistoricalStackOptions = (
  sensorDataRecords: SensorDataRecords,
  historicalSeries: HistoricalSeries[],
  displayOptions?: HistoricalDisplayOptions
): StackOptions[] => {
  let seriesIndex = 0;
  const stackOptions: StackOptions[] = [];
  // @ts-ignore
  historicalSeries.forEach((series) => {
    const { sensorId, resolution } = series.dataSource;
    const dataRecord = sensorDataRecords?.[sensorId]?.[resolution];

    const yAxis: Highcharts.YAxisOptions = {
      gridLineWidth: displayOptions?.showYGrid ? 1 : 0,
      max: undefined,
      min: undefined,
      opposite: false,
      maxPadding: series.displayOptions?.showDataLabels
        ? dataLabelPadding
        : undefined,
      title: {
        text: series.displayOptions?.yAxis?.label || dataRecord?.data?.unit,
      },
    };

    if (
      seriesIndex === 0 &&
      (displayOptions?.yAxis?.label || displayOptions?.yAxis?.side)
    ) {
      const primaryColor =
        historicalSeries[0].displayOptions?.color ?? DEFAULT_CHART_COLORS[0];
      yAxis.labels = { style: { color: primaryColor } };
      yAxis.title = {
        style: { color: primaryColor },
        text: `${displayOptions?.yAxis?.label || dataRecord?.data?.unit}`,
      };
    }

    if (seriesIndex === 0) {
      const [min, max] = makeValidRangeType(displayOptions?.yRange);

      yAxis.min = min;
      yAxis.max = max;
    } else {
      const [min, max] = makeValidRangeType(series.displayOptions?.yRange);
      yAxis.min = min;
      yAxis.max = max;
    }

    stackOptions.push({
      seriesIndex: seriesIndex,
      options: {
        //@ts-ignore
        series: [{ yAxis: 0 }],
        yAxis: [yAxis],
      },
    });
    seriesIndex++;

    if (series.displayOptions?.trendline?.periods) {
      const {
        displayOptions: { trendline },
      } = series;
      const primaryColor =
        series.displayOptions?.color ?? DEFAULT_CHART_COLORS[seriesIndex - 1];
      const trendlineYAxis = { ...yAxis };
      trendlineYAxis.labels = {
        style: { color: trendline.color || primaryColor },
      };
      trendlineYAxis.title = {
        style: { color: trendline.color || primaryColor },
        text: `${
          series.displayOptions?.yAxis?.label || dataRecord?.data?.unit
        } Trendline`,
      };

      stackOptions.push({
        seriesIndex: seriesIndex,
        options: {
          //@ts-ignore
          series: [{ yAxis: 0 }],
          yAxis: [trendlineYAxis],
        },
      });
      seriesIndex++;
    }
  });

  return stackOptions;
};

const calculateTrendline = (
  x: number[],
  y: number[],
  forecastPeriods: number | undefined,
  resolution: Resolution | Resolutions
) => {
  const xAverage = x.reduce((sum, value) => sum + value, 0) / x.length;
  const yAverage = y.reduce((sum, value) => sum + value, 0) / y.length;

  const sumXY = x.reduce((sum, xVal, index) => {
    sum += (xVal - xAverage) * (y[index] - yAverage);
    return sum;
  }, 0);

  const sumXX = x.reduce((sum, xVal) => {
    sum += (xVal - xAverage) * (xVal - xAverage);
    return sum;
  }, 0);

  const slope = sumXY / sumXX;
  const intercept = yAverage - slope * xAverage;

  let newX = x;

  if (forecastPeriods) {
    const period =
      resolution !== Resolution.Raw && x.length > 2
        ? x[x.length - 1] - x[x.length - 2]
        : 900000;
    const forecastX = [x[x.length - 1] + period];
    for (let i = 0; i < forecastPeriods - 1; i++) {
      forecastX.push(forecastX[i] + period);
    }
    newX = [...x, ...forecastX];
  }

  return {
    xPoints: newX,
    measurements: newX.reduce<SensorMeasureData>((acc, xVal) => {
      const yVal = slope * xVal + intercept;
      acc[xVal] = [yVal, yVal, yVal, yVal, yVal, yVal];
      return acc;
    }, {}),
  };
};
