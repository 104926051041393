import { Autocomplete, AutocompleteRenderInputParams, TextField } from '@mui/material';
import {
	Incident,
	StylovyzeDialog,
	getIncidents,
	selectOpenIncidents,
} from '@innovyze/stylovyze';
import React, { SyntheticEvent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { StyledSubText } from './AddToIncidentDialog.styles';
import { useGlobalization } from '@Translations/useGlobalization';

export interface AddToIncidentDialogProps {
	open: boolean;
	linkedIncidents: Incident[];
	onCancel: () => void;
	onConfirm: (incident: Incident) => void;
}

export const AddToIncidentDialog = (
	props: AddToIncidentDialogProps,
): JSX.Element => {
	const { t } = useGlobalization();
	const dispatch = useDispatch();

	const [
		incidentSelected,
		setIncidentSelected,
	] = React.useState<Incident | null>();

	useEffect(() => {
		dispatch(getIncidents({ limit: 100, status: 'open' }));
	}, []);
	const openIncidents = useSelector(selectOpenIncidents);

	const onDialogConfirm = () => {
		if (incidentSelected) {
			props.onConfirm(incidentSelected);
		}
		setIncidentSelected(null);
	};

	const onDialogCancel = () => {
		setIncidentSelected(null);
		props.onCancel();
	};

	const autoCompleteChanged = (
		_event: SyntheticEvent<Element, Event>,
		values: Incident | null,
	) => {
		setIncidentSelected(values);
	};

	// filter out already linked incidents to prevent duplicate adds
	const validOptions = openIncidents
		? openIncidents.filter(function(incident: Incident) {
				return (
					props.linkedIncidents.findIndex(
						el => el?.id === incident?.id,
					) < 0
				);
		  })
		: [];

	return (
		<StylovyzeDialog
			data-cy="add-alert-to-incident-dialog"
			title={t('Add Alert to Incident', {
				context: 'Add to incident dialog title',
			})}
			open={props.open}
			cancelText={t('Cancel', {
				context: 'Add to incident dialog cancel button',
			})}
			onCancel={onDialogCancel}
			confirmText={t('Add to Incident', {
				context: 'Add to incident dialog confirm button',
			})}
			onConfirm={onDialogConfirm}
			confirmButtonProps={{
				disabled: !incidentSelected,
			}}>
			<div>
				<Autocomplete
					id="incident"
					data-cy="incident"
					color={'primary'}
					options={validOptions}
					value={incidentSelected ?? null}
					getOptionLabel={(incident: Incident) => incident.name ?? ''}
					isOptionEqualToValue={(option, value) =>
						option.name === value.name
					}
					renderInput={(params: AutocompleteRenderInputParams) => {
						return (
							<div>
								<TextField
									{...params}
									color={'primary'}
									name={'incident-option'}
									label={t('Select an Incident', {
										context:
											'Add to incident dialog search box label',
									})}
									margin={'normal'}
									variant="outlined"
								/>
							</div>
						);
					}}
					fullWidth
					onChange={autoCompleteChanged}
				/>
				<StyledSubText>
					{t(
						// eslint-disable-next-line quotes
						"The alert will be added to the selected incident's timeline.",
						{ context: 'Add to incident dialog action outcome' },
					)}
				</StyledSubText>
			</div>
		</StylovyzeDialog>
	);
};

AddToIncidentDialog.defaultProps = {};

export default AddToIncidentDialog;
