import React from 'react';

export const ObservationSummary = ({ height = 241 }: { height?: number }) => {
	return (
		<div>
			<img
				alt="summary"
				src={require('./summary.jpg')}
				style={{ height: `${height}px` }}
			/>
		</div>
	);
};

export default ObservationSummary;
