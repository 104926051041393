import React, { useEffect } from 'react';
import {
	ConfigurationMapping,
	InspectionStandard,
	TaskInspection,
	TaskType,
} from '@Types';
import { GridContainer, GridItem, useStyle } from '../AssetDetails.styles';
import {
	FormatDateOptions,
	getSubscriptions,
	InfoCard,
	Property,
	useIsFeatureEnabled,
	useCompanyDateTime,
	useTzDateTime,
} from '@innovyze/stylovyze';
import { MapInsertAsset, ObservationNotes } from '../..';
import {
	selectFullInspection,
	selectFullInspectionIsWaiting,
	selectNotes,
	selectUserContext,
	useSelectConfigurationDetails,
} from '@Selectors';
import {
	formatDateTimeWithFormatDate,
	formatDateWithFormatDate,
	formatTimeWithFormatDate,
	unitMapping,
	UNITS,
} from '@Utils';
import { FullPageWrapper } from '@Utils/styles';
import { useDispatch } from 'react-redux';
import { useGlobalization } from '@Translations';
import { addNote, getConfigurationWithUploadId, getFullInsp } from '@Actions';
import { viewerAccess } from '@innovyze/shared-utils';
import { Grid, IconButton } from '@mui/material';
import TaskDetailsProps from './TaskDetailsProps.component';
import { Refresh } from '@mui/icons-material';

export interface TaskDetailsProps {
	back: { action: () => void; label: string };
	taskType: string;
	taskId: string;
	onReRender?: () => void;
	taskTypeName?: string;
	type?: TaskType;
}

export const getConfigurationBasedValue = (
	value: string,
	formatDateUTC: (options: FormatDateOptions) => string,
	mapping?: ConfigurationMapping,
): {
	displayValue: string;
	displayType: Property['type'];
} => {
	let displayValue = value;
	let displayType: Property['type'] = 'text';

	if (value.length !== 0 && mapping) {
		switch (mapping.unit) {
			// Seems currency will not work as expected
			// case 'currency':
			// 	displayType = 'currency';
			// 	break;
			case 'integer':
			case 'float':
				if (mapping.usage.length == 0) {
					displayType = 'number';
					break;
				} else {
					const displayUnit = unitMapping(mapping.usage);
					switch (displayUnit) {
						case UNITS.AMPS:
						case UNITS.REVOLUTIONS_PER_MIN:
							displayValue = `${value} ${displayUnit}`;
							break;
						case UNITS.NONE:
							break;
						default:
							displayValue = `${value} ${displayUnit}`;
							displayType = 'unit';
							break;
					}
				}
				break;
			case 'date':
				// Options are time, dateyear, datetime(import version which we dont care about) and date(import version which we dont care about)
				if (mapping.incomingUnit == 'time') {
					// Convert the value
					displayValue =
						formatTimeWithFormatDate(
							value
								? // This is to work around the filtering for null dates
								  value.replace(
										'1111-',
										new Date().getFullYear() + '-',
								  )
								: '',
							formatDateUTC,
						) ?? '';
				} else if (mapping.incomingUnit == 'dateyear') {
					// Extact the year
					if (
						value.trim().length > 4 &&
						!value.startsWith('1111-11-11')
					) {
						displayValue = value.substring(0, 4);
					} else {
						displayValue = '';
					}
					displayType = 'number';
				} else if (mapping.incomingUnit.startsWith('datetime')) {
					// Convert the value to give the correct time
					displayValue =
						formatDateTimeWithFormatDate(value, formatDateUTC) ??
						'';
				} else {
					// Convert the value to give the correct date
					displayValue =
						formatDateWithFormatDate(value, formatDateUTC) ?? '';
				}
				break;
		}
	} else {
		displayValue = '-';
	}
	return { displayValue, displayType };
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const TaskDetails = ({
	back,
	taskType,
	taskId,
	onReRender,
	taskTypeName,
	type,
}: TaskDetailsProps): JSX.Element => {
	const { t } = useGlobalization();
	const dispatch = useDispatch();
	const { formatDate } = useCompanyDateTime();
	const formatDateUTC = useTzDateTime({ timeZone: 'UTC' }).formatDate;
	const classes = useStyle();
	const isLoading = selectFullInspectionIsWaiting();
	const userContext = selectUserContext();

	const isEditAllowedFlag = useIsFeatureEnabled('info-360-edit-tasks')
		? true
		: false;

	const subscriptions = getSubscriptions();
	const hasViewerAccess = viewerAccess(subscriptions);

	const config = useSelectConfigurationDetails(taskType);

	useEffect(() => {
		dispatch(
			getFullInsp({
				standard: taskType as InspectionStandard,
				inspectionId: taskId,
				formatDate: formatDate,
				formatDateUTC: formatDateUTC,
				enableMediaTranscode: false,
			}),
		);
	}, [formatDate, taskId, taskType]);

	useEffect(() => {
		if (taskType) {
			let configType = 'tasks';
			if (type === TaskType.Event) configType = 'events';

			if (config === undefined) {
				dispatch(
					getConfigurationWithUploadId({
						type: configType,
						uploadId: taskType,
					}),
				);
			}
		}
	}, [taskType]);

	const inspection = selectFullInspection() as TaskInspection;
	const notes = selectNotes();

	const onSubmitNote = (newNotes: string, userInitial: string) => {
		dispatch(
			addNote({
				_id: taskId,
				note: newNotes,
				name: userContext.name,
				initial: userInitial,
			}),
		);
	};

	const secondaryButton = () => {
		if (isEditAllowedFlag && inspection && !isLoading) {
			return (
				<Grid
					item
					container
					alignItems="center"
					justifyContent="flex-end"
					data-cy="grid-actionbutton"
					wrap="nowrap">
					<IconButton
						title="Refresh"
						data-cy="refreshButton"
						onClick={onReRender}
						size="large">
						<Refresh />
					</IconButton>
				</Grid>
			);
		}
	};

	const getTitle = () => {
		let typeName = taskTypeName;
		if (typeName === undefined && config !== undefined) {
			typeName = config.userDataType as string;
		}
		if (type === TaskType.Event) {
			return t('{{Name}}: {{ID}}', {
				Name: typeName ?? t('Event'),
				ID: inspection.eventId,
			});
		} else {
			return t('{{Name}}: {{ID}}', {
				Name: typeName ?? t('Task'),
				ID: inspection.taskId,
			});
		}
	};

	return (
		<FullPageWrapper
			className={isLoading ? classes.rootWait : classes.root}
			applyPadding
			title={getTitle()}
			back={back}
			headerUnderline
			secondary={secondaryButton()}>
			<GridContainer>
				<GridItem xs={6} rows={2}>
					<InfoCard title={t('Map')}>
						<div style={{ height: '637px' }}>
							<MapInsertAsset />
						</div>
					</InfoCard>
				</GridItem>
				<GridItem xs={6}>
					<InfoCard title={t('Notes')}>
						<ObservationNotes
							Notes={notes}
							onSubmit={onSubmitNote}
							readOnly={hasViewerAccess}
							isWaiting={isLoading}
							keepHeightWhenWaiting={true}
							waitingHeight={'637px'}
							bodyHeight={hasViewerAccess ? '637px' : '555px'}
							entryHeight={hasViewerAccess ? '0px' : '90px'}
						/>
					</InfoCard>
				</GridItem>
				<GridItem xs={12}>
					<InfoCard title={t('Properties')}>
						<TaskDetailsProps
							taskType={taskType}
							readonly={!isEditAllowedFlag || hasViewerAccess}
							onReRender={onReRender}
							type={type ?? TaskType.Task}
						/>
					</InfoCard>
				</GridItem>
			</GridContainer>
		</FullPageWrapper>
	);
};
