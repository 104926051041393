import { CircularProgress, InputLabel } from '@mui/material';

import { rem } from '../styles';
import styled from 'styled-components';

export const SelectLabel = styled(InputLabel)`
	background: #fff;
	padding: 0 5px;
	margin: 0 -5px;
`;

export const Progress = styled(CircularProgress)`
	position: absolute;
	top: 50%;
	right: ${rem(20)};
	margin-top: ${rem(-10)};
`;
