import LayerBase, { Config } from './LayerBase';
import MapboxLayer, { UniqueLayerProps } from '../mapbox/MapboxLayer';

import { BackgroundTypes } from '@Map/types';
import { CompositeLayerProps } from '../types';
import { ServiceLayer } from '@Map/services/types';

class LabelLayer extends MapboxLayer {
	protected _type: ServiceLayer['type'] = 'symbol';

	layerProperties(): UniqueLayerProps {
		return {
			layout: {
				'text-allow-overlap': false,
				'text-ignore-placement': false,
				'text-field': ['get', 'label'],
				'text-rotation-alignment': 'viewport',
				'text-anchor': 'top',
				'text-radial-offset': 1,
				'text-size': 12,
				'text-justify': 'auto',
				'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
			},
			paint: this.getLabelPaint(),
		};
	}

	private getLabelPaint(): UniqueLayerProps['paint'] {
		if (this._labelColor) return { 'text-color': this._labelColor };

		if (this._background === BackgroundTypes.Satellite) {
			return {
				'text-color': '#ffffff',
				'text-halo-color': '#000000',
				'text-halo-width': 1,
			};
		}

		if (this._background === BackgroundTypes.Dark) {
			return {
				'text-color': '#b2b2b2',
				'text-halo-color': '#000000',
				'text-halo-width': 1,
			};
		}

		return {
			'text-color': '#000000',
			'text-halo-color': '#ffffff',
			'text-halo-width': 1,
		};
	}
}

export class AssetFocusLabelLayer extends LayerBase {
	constructor(
		layerInfo: CompositeLayerProps,
		map: mapboxgl.Map,
		config: Config = {},
	) {
		super(
			{
				...layerInfo,
				source: 'focused-assets-label',
				sourceLayer: undefined,
				type: 'symbol',
			},
			map,
			{
				idPostfix: '-asset-focus-label',
				...config,
			},
		);
		const layerProps: ServiceLayer = {
			visible: layerInfo.visible,
			source: this.source,
			id: this.id,
			zIndex: 3000,
			type: 'symbol',
		};

		this._layer = new LabelLayer(layerProps);
	}
}
