/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { createContext } from '../react-context';
import { useSettings } from '@innovyze/stylovyze';
import { useGlobalization } from '../../../i18n';
import * as React from 'react';
import Highcharts from 'highcharts';
import styled from 'styled-components';
import moment from 'moment-timezone';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Create Stackable Chart
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const createChart = (chartComponentName: string) => {
  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Chart Root Context
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  const [_RootProvider, _useRoot] = createContext<{
    // containerElementRef: React.RefObject<HTMLDivElement>;
    instanceMapRef: React.MutableRefObject<Map<string, Highcharts.Chart>>;
  }>(chartComponentName);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Chart Instance Context
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  const [_InstanceInternalsProvider, _useInstanceInternals] = createContext<{
    instanceRef: React.MutableRefObject<Highcharts.Chart | undefined>;
  }>(chartComponentName);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Chart Root Component
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  const Root = (props: { children: React.ReactNode }): React.ReactElement => {
    const containerElementRef = React.useRef<HTMLDivElement>(null);
    const instanceMapRef = React.useRef<Map<string, Highcharts.Chart>>(
      new Map()
    );

    React.useLayoutEffect(() => {
      const mouseLeaveEventHandler = () => {
        instanceMapRef.current.forEach((instance) => {
          if (instance) {
            if (instance?.options && instance?.pointer) {
              instance.series.forEach(function (series) {
                instance?.tooltip?.hide?.();
                instance?.xAxis[0]?.hideCrosshair?.();
                series.setState('normal');
                series?.points?.forEach((point) => {
                  point.setState?.('normal');
                });
              });
            }
          }
        });
      };

      containerElementRef.current?.addEventListener(
        'mouseleave',
        mouseLeaveEventHandler
      );

      return () => {
        containerElementRef.current?.removeEventListener(
          'mouseleave',
          mouseLeaveEventHandler
        );
      };
    }, []);

    React.useLayoutEffect(() => {
      if (containerElementRef.current) {
        const resizeObserver = new ResizeObserver(
          (entries: ResizeObserverEntry[]) => {
            window.requestAnimationFrame((): void | undefined => {
              if (!Array.isArray(entries) || !entries.length) {
                return;
              }
              window.dispatchEvent(new Event('resize'));
            });
          }
        );

        resizeObserver.observe(containerElementRef.current);

        return () => {
          resizeObserver.disconnect();
        };
      }
    }, [containerElementRef]);

    return (
      <_RootProvider instanceMapRef={instanceMapRef}>
        <_RootLayout.Root ref={containerElementRef}>
          {props.children}
        </_RootLayout.Root>
      </_RootProvider>
    );
  };

  const _RootLayout = {
    Root: styled.div`
      align-items: stretch;
      background-color: #fff;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
      overflow: hidden;
      padding: 15px;
      position: relative;
      width: 100%;

      & * {
        box-sizing: border-box;
      }
    `,
  };

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Chart Instance Component
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  const Instance = React.forwardRef<
    { chart: Highcharts.Chart | undefined },
    {
      children: React.ReactNode;
      id: string;
      initialOptions: Highcharts.Options;
      chartConstructor: typeof Highcharts.chart;
    }
  >((props, ref) => {
    const root = _useRoot(chartComponentName);
    const chartConstructorRef = React.useRef(props.chartConstructor);
    const containerElementRef = React.useRef<HTMLDivElement>(null);
    const initialOptionsRef = React.useRef(props.initialOptions);
    const instanceRef = React.useRef<Highcharts.Chart>();
    const { companySettings } = useSettings();
    const { t } = useGlobalization();

    /**
     * This MUST be a layout effect as we need the instance to be ready BEFORE
     * any other effect runs that may potentially run an instance update.
     **/
    React.useLayoutEffect(() => {
      if (containerElementRef.current) {
        instanceRef.current = chartConstructorRef.current(
          containerElementRef.current,
          Highcharts.merge(
            {
              time: {
                getTimezoneOffset: function (timestamp) {
                  return (
                    moment
                      .tz(timestamp, companySettings.timeZoneIANA)
                      .utcOffset() * -1
                  );
                },
              },
            } as Highcharts.Options,
            initialOptionsRef.current
          )
        );

        root.instanceMapRef.current.set(props.id, instanceRef.current);
      }
    }, [
      props.id,
      root.instanceMapRef,
      instanceRef,
      containerElementRef,
      initialOptionsRef,
      companySettings.timeZoneIANA,
    ]);

    React.useLayoutEffect(() => {
      instanceRef.current?.update({
        lang: {
          weekdays: [
            t('Sunday'),
            t('Monday'),
            t('Tuesday'),
            t('Wednesday'),
            t('Thursday'),
            t('Friday'),
            t('Saturday'),
          ],
          months: [
            t('January'),
            t('February'),
            t('March'),
            t('April'),
            t('May'),
            t('June'),
            t('July'),
            t('August'),
            t('September'),
            t('October'),
            t('November'),
            t('December'),
          ],
          shortMonths: [
            t('Jan'),
            t('Feb'),
            t('Mar'),
            t('Apr'),
            t('May'),
            t('Jun'),
            t('Jul'),
            t('Aug'),
            t('Sep'),
            t('Oct'),
            t('Nov'),
            t('Dec'),
          ],
          loading: t('Loading....'),
          resetZoom: t('Reset zoom'),
          rangeSelectorZoom: t('Zoom'),
        },
      });
    }, []);

    /**
     * Contrary to the creation operation, destroying the chart MUST be done
     * as the last step. Otherwise, if a hook tries to perform an action on
     * a chart that is destroyed, Highcharts will error out.
     *
     * This happens when we have to destroy series when switching from stack
     * to overlay.
     **/
    React.useEffect(() => {
      return () => {
        instanceRef.current?.destroy();
        instanceRef.current = undefined;

        root.instanceMapRef.current.delete(props.id);
      };
    }, [props.id, root.instanceMapRef, instanceRef]);

    React.useImperativeHandle(
      ref,
      () => ({
        get chart() {
          return instanceRef.current;
        },
      }),
      []
    );

    return (
      <_InstanceInternalsProvider instanceRef={instanceRef}>
        <_InstanceLayout.Root ref={containerElementRef} />
        {props.children}
      </_InstanceInternalsProvider>
    );
  });

  Instance.displayName = 'Instance';

  const _InstanceLayout = {
    Root: styled.div`
      height: 100%;
      width: 100%;
    `,
  };

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Instance Hooks
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  const useInstance = (consumerName: string) => {
    const root = _useRoot(consumerName);
    const instanceInternals = _useInstanceInternals(consumerName);

    const where = React.useCallback(
      (instanceIds?: RegExp | (string | RegExp)[]) => {
        if (instanceIds === undefined) {
          return [...root.instanceMapRef.current.values()];
        }

        const _instanceIds = Array.isArray(instanceIds)
          ? instanceIds
          : [instanceIds];

        const matchingInstances: Highcharts.Chart[] = [];
        const instanceKeys = Object.keys(root.instanceMapRef.current);

        for (const instanceId of _instanceIds) {
          if (typeof instanceId === 'string') {
            if (root.instanceMapRef.current.has(instanceId)) {
              matchingInstances.push(
                root.instanceMapRef.current.get(instanceId)!
              );
            }
          } else {
            const matchingKeys = instanceKeys.filter((key) =>
              instanceId.test(key)
            );
            for (const matchingKey of matchingKeys) {
              matchingInstances.push(
                root.instanceMapRef.current.get(matchingKey)!
              );
            }
          }
        }

        return matchingInstances;
      },
      []
    );

    const get = React.useCallback((instanceId?: string) => {
      if (instanceId === undefined) {
        return instanceInternals.instanceRef.current;
      }

      return root.instanceMapRef.current.get(instanceId);
    }, []);

    return {
      get,
      where,
    };
  };

  return {
    Root,
    Instance,
    useInstance,
  } as const;
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Module Exports
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export { createChart };
