import type * as Highcharts from 'highcharts';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Goal Chart
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const goalChartInstanceInitialOptions: Highcharts.Options = {
  chart: { type: 'bar' },
  title: { text: '', margin: 0, align: 'left' },
  subtitle: { text: '', verticalAlign: 'bottom', align: 'left' },
  credits: { enabled: false },
  exporting: { enabled: false },
  legend: { enabled: false },
  navigator: { enabled: false },
  rangeSelector: { enabled: false },
  scrollbar: { enabled: false },
  plotOptions: {
    bar: {
      borderWidth: 0,
      borderRadius: 3,
      enableMouseTracking: false,
      maxPointWidth: 100,
    },
  },
  xAxis: {
    visible: false,
  },
  yAxis: {
    visible: false,
    max: 100,
  },
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export { goalChartInstanceInitialOptions };
