import Events from '../events/Events';
import {
	CONTROL_CURRENT_TIME,
	CONTROL_FORWARDS,
	CONTROL_PAUSE,
	CONTROL_PLAY,
	CONTROL_REWIND,
	TIMELINE_CURRENT_TIME,
	TIMELINE_TIMINGS,
} from './constants';

export default class AnimationControlBridge extends Events {
	private _container: HTMLElement;
	constructor(container: HTMLElement) {
		super();
		this._container = container;
		this.attachOutboundEvents();
		this.attachInboundEvents();
	}

	/**
	 * Forward events from EventBridge to animation control
	 */
	attachOutboundEvents(): void {
		this._forwardToTarget(this._container, [
			TIMELINE_TIMINGS,
			TIMELINE_CURRENT_TIME,
		]);
	}

	/**
	 * Listen to events from animation control and pass to EventBridge
	 */
	attachInboundEvents(): void {
		this._container.addEventListener(CONTROL_CURRENT_TIME, (e: Event) => {
			const currentTime = (e as CustomEvent).detail;
			this.fire(CONTROL_CURRENT_TIME, { currentTime });
		});
		this._container.addEventListener(CONTROL_PLAY, () => {
			this.fire(CONTROL_PLAY);
		});
		this._container.addEventListener(CONTROL_PAUSE, () => {
			this.fire(CONTROL_PAUSE);
		});
		this._container.addEventListener(CONTROL_REWIND, () => {
			this.fire(CONTROL_REWIND);
		});
		this._container.addEventListener(CONTROL_FORWARDS, () => {
			this.fire(CONTROL_FORWARDS);
		});
	}
}
