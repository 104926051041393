import { createContext } from './create-context';
import { nanoid } from 'nanoid';
import { useHighlightReset } from './use-highlight-reset';
import { useResizeEvent } from './use-resize-event';
import * as Highcharts from 'highcharts';
import * as React from 'react';
import { produce } from 'immer';
import styled from 'styled-components';
import { useIsFeatureEnabled, useSettings } from '@innovyze/stylovyze';
import moment from 'moment-timezone';
import {
  DateTimeRangePicker,
  DateTimeRangePickerSlotOptions,
  singleInputDateTimeRangePickerSlotOptions,
} from '../../inputs/date-time-range-picker';
import {
  DateRange,
  LocalizationProvider,
  SingleInputDateRangeFieldProps,
} from '@mui/x-date-pickers-pro';
import { AdapterLuxon } from '@mui/x-date-pickers-pro/AdapterLuxon';
import {
  Box,
  Button,
  TextField,
  Tooltip,
  Typography,
  useForkRef,
} from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import { DateTime } from 'luxon';
import { useGlobalization } from '../../../i18n';
import { Help as HelpIcon } from '@mui/icons-material';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Types
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

type ChartInstanceRef = { chart: Highcharts.Chart | undefined };

type InstanceMap<InstanceId = string> = Map<InstanceId, Highcharts.Chart>;

type SeriesMap<SeriesId = string> = Map<SeriesId, Highcharts.Series>;

type SeriesPropsMap<SeriesId = string> = Map<SeriesId, Record<string, unknown>>;

type Status = 'idle' | 'loading' | 'resolved' | 'rejected';

type DateRangeMillis = [number | null, number | null];

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const createChart = <InstanceId extends string = string>(
  chartName: string,
  headerTop = 26,
  enablePanning = false
) => {
  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Root Context
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  const [RootContextProvider, useRootContext] = createContext<{
    containerElementRef: React.RefObject<HTMLDivElement>;
    instanceMapRef: React.MutableRefObject<InstanceMap>;
    syncedDateTimeRange: DateRangeMillis;
    syncDateTimeRangePicker: (dateRange: DateRangeMillis) => void;
    chartWidth: number | null;
  }>(chartName + '.ChartRoot');

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Root Component
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  const ChartRoot = React.forwardRef<
    HTMLDivElement,
    { children: React.ReactNode }
  >((props, ref) => {
    const containerElementRef = React.useRef<HTMLDivElement>(null);
    const instanceMapRef = React.useRef<InstanceMap>(new Map());
    const [syncedDateTimeRange, setSyncedDateTimeRange] =
      React.useState<DateRangeMillis>([null, null]);
    const [chartWidth, setChartWidth] = React.useState<number | null>(null);

    useResizeEvent(containerElementRef);
    useHighlightReset(containerElementRef, instanceMapRef);

    React.useLayoutEffect(() => {
      const resizeObserver = new ResizeObserver((entries) => {
        const width = entries.at(0)?.borderBoxSize.at(0)?.inlineSize;
        setChartWidth(typeof width !== 'undefined' ? width : null);
      });

      resizeObserver.observe(containerElementRef.current);

      return () => {
        resizeObserver.disconnect();
      };
    }, []);

    // TODO: ref is not used right now, but it can be merged with
    // containerElementRef using useImperativeHandler so that parent
    // component can have access / control it.

    return (
      <RootContextProvider
        chartWidth={chartWidth}
        syncedDateTimeRange={syncedDateTimeRange}
        syncDateTimeRangePicker={setSyncedDateTimeRange}
        containerElementRef={containerElementRef}
        instanceMapRef={instanceMapRef}>
        <ChartRootContainer ref={containerElementRef}>
          {props.children}
        </ChartRootContainer>
      </RootContextProvider>
    );
  });

  ChartRoot.displayName = 'ChartRoot';

  const ChartRootContainer = styled.div`
    align-items: stretch;
    background-color: #fff;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    overflow: hidden;
    padding: 15px;
    position: relative;
    width: 100%;

    & * {
      box-sizing: border-box;
    }
  `;

  // eslint-disable-next-line @typescript-eslint/ban-types
  const withRoot = <P extends {}>(WrappedComponent: React.ComponentType<P>) => {
    const Hoc = React.forwardRef<ChartInstanceRef, P>((props, ref) => {
      return (
        <ChartRoot>
          <WrappedComponent {...props} ref={ref} />
        </ChartRoot>
      );
    });

    Hoc.displayName = `Wrapped${
      WrappedComponent.displayName || WrappedComponent.name || `${chartName}`
    }`;

    return Hoc;
  };

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Header Component
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  const ChartHeaderContainer = styled.div`
    position: absolute;
    top: ${headerTop}px;
    right: 24px;
    color: rgb(74, 96, 103);
    display: flex;
    align-items: center;
  `;

  const CustomTextField = muiStyled(TextField)(() => ({
    width: 280,
    '& .MuiInputBase-root': {
      fontSize: '0.8rem',
      padding: '0 !important',
    },
    '& .MuiInputBase-input': {
      textAlign: 'right',
      padding: '0 !important',
      color: 'rgb(74, 96, 103)',
    },
    '& fieldset': {
      border: 'none',
    },
  }));

  const CustomButton = muiStyled(Button)(() => ({
    width: 'auto',
    fontSize: '12px',
    fontWeight: '700',
    padding: '2px 6px',
    border: 'none',
    backgroundColor: 'rgb(230, 235, 245)',
    color: 'rgb(74, 96, 103);',
    transform: 'translateY(-3px)',
    '&:hover': {
      border: 'none',
      backgroundColor: '#e1e4ea',
    },
  }));

  const DateTimeRangeToggle = React.forwardRef<
    HTMLElement,
    SingleInputDateRangeFieldProps<DateTime> & {
      setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    }
  >((props, ref) => {
    const { t } = useGlobalization();
    const {
      setOpen,
      id,
      disabled,
      InputProps: { ref: containerRef } = {},
      inputProps: { 'aria-label': ariaLabel } = {},
    } = props;

    const handleRef = useForkRef(ref, containerRef);

    return (
      <CustomButton
        variant="outlined"
        id={id}
        disabled={disabled}
        ref={handleRef}
        aria-label={ariaLabel}
        onClick={() => setOpen?.((prev) => !prev)}>
        {t('Time Range')}
      </CustomButton>
    );
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  DateTimeRangeToggle.fieldType = 'single-input';
  DateTimeRangeToggle.displayName = 'DateTimeRangeToggle';

  const slotOptions: (options: {
    asButton: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  }) => DateTimeRangePickerSlotOptions = (options) =>
    options.asButton
      ? {
          slots: { field: DateTimeRangeToggle },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          slotProps: { field: { setOpen: options.setOpen } as any },
        }
      : {
          slots: {
            ...singleInputDateTimeRangePickerSlotOptions.slots,
            textField: CustomTextField,
          },
          slotProps: {
            ...singleInputDateTimeRangePickerSlotOptions.slotProps,

            textField: {
              ...singleInputDateTimeRangePickerSlotOptions.slotProps.textField,
              hiddenLabel: true,
              size: 'small',
              placeholder: '-',
            },
          },
        };

  const ChartHeader = (): React.ReactElement => {
    const instance = useInstance('ChartHeader');
    const { chartWidth, syncedDateTimeRange } = useRootContext('ChartHeader');
    const [open, setOpen] = React.useState(false);
    const asButton = chartWidth !== null && chartWidth < 600;

    const panningEnabled =
      useIsFeatureEnabled('info-360-analytics-chart-panning') && enablePanning;

    const value = React.useMemo(() => {
      const dateRange = [null, null] as DateRange<DateTime>;
      if (
        syncedDateTimeRange[0] !== null &&
        syncedDateTimeRange[0] !== undefined
      ) {
        dateRange[0] = DateTime.fromMillis(syncedDateTimeRange[0]);
      }

      if (
        syncedDateTimeRange[1] !== null &&
        syncedDateTimeRange[1] !== undefined
      ) {
        dateRange[1] = DateTime.fromMillis(syncedDateTimeRange[1]);
      }

      return dateRange;
    }, [syncedDateTimeRange]);

    return (
      <ChartHeaderContainer>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <DateTimeRangePicker
            {...slotOptions({
              setOpen,
              asButton,
            })}
            calendars={2}
            value={value}
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            onAccept={(value) => {
              const min = value[0].isValid ? value[0].toMillis() : null;
              const max = value[1].isValid ? value[1].toMillis() : null;

              instance.current.xAxis[0]?.setExtremes(
                min,
                max,
                true,
                undefined,
                {
                  trigger: 'rangeSelectorInput',
                }
              );
            }}
          />
        </LocalizationProvider>
        {panningEnabled ? (
          <Tooltip
            placement="bottom-end"
            title={
              <Box component="div">
                <Typography variant="caption">
                  To navigate through the data you can:
                </Typography>
                <Box
                  component="ul"
                  sx={{ padding: 0, margin: 0, paddingLeft: '2em' }}>
                  <Typography variant="caption" component="li">
                    Drag the plot area to the left or right.
                  </Typography>
                  <Typography variant="caption" component="li">
                    Scroll or use your trackpad over the plot area while holding{' '}
                    <Typography
                      variant="caption"
                      component="mark"
                      sx={{
                        padding: '0.1em 0.2em',
                        color: 'inherit',
                        backgroundColor: 'rgba(0, 0, 0, 0.4)',
                        border: '1px solid rgba(255, 255, 255, 0.4)',
                        borderRadius: '0.2em',
                      }}>
                      shift
                    </Typography>
                    .
                  </Typography>
                </Box>
              </Box>
            }>
            <HelpIcon
              fontSize="small"
              sx={{
                fill: 'currentcolor',
                marginLeft: '10px',
              }}
            />
          </Tooltip>
        ) : null}
      </ChartHeaderContainer>
    );
  };

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Instance Component
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  const [InstanceContextProvider, useInstanceContext] = createContext<{
    instanceRef: React.MutableRefObject<Highcharts.Chart | undefined>;
  }>(chartName + '.ChartInstance');

  const [InstanceSeriesContextProvider, useInstanceSeriesContext] =
    createContext<{
      seriesMapRef: React.MutableRefObject<SeriesMap>;
      seriesPropsMap: SeriesPropsMap;
      setSeriesProps: (
        seriesId: string,
        props: Record<string, unknown>
      ) => void;
      removeSeriesProps: (seriesId: string) => void;
    }>(chartName + '.ChartInstance');

  const ChartInstance = React.forwardRef<
    ChartInstanceRef,
    {
      id?: InstanceId;
      children: React.ReactNode;
      initialOptions: Highcharts.Options;
      constructorFunction: typeof Highcharts.chart;
    }
  >((props, ref) => {
    const { companySettings } = useSettings();
    const instanceId = React.useMemo(() => props.id || nanoid(), [props.id]);
    const instanceRef = React.useRef<Highcharts.Chart>();
    const containerElementRef = React.useRef<HTMLDivElement>(null);
    const constructorFunctionRef = React.useRef(props.constructorFunction);
    const initialOptionsRef = React.useRef(props.initialOptions);
    const chartRootContext = useRootContext('ChartInstance');
    const seriesMapRef = React.useRef<SeriesMap>(new Map());
    const [seriesPropsMap, setSeriesPropsMap] = React.useState<SeriesPropsMap>(
      new Map()
    );

    const setSeriesProps = React.useCallback(
      (seriesId: string, props: Record<string, unknown>) => {
        setSeriesPropsMap(
          produce((_seriesPropsMap) => {
            _seriesPropsMap.set(seriesId, props);
          })
        );
      },
      [seriesMapRef, setSeriesPropsMap]
    );

    const removeSeriesProps = React.useCallback(
      (seriesId: string) => {
        setSeriesPropsMap(
          produce((_seriesPropsMap) => {
            _seriesPropsMap.delete(seriesId);
          })
        );
      },
      [seriesMapRef, setSeriesPropsMap]
    );

    React.useLayoutEffect(() => {
      if (containerElementRef.current) {
        instanceRef.current = constructorFunctionRef.current(
          containerElementRef.current,
          Highcharts.merge(
            {
              time: {
                getTimezoneOffset: function (timestamp) {
                  return (
                    moment
                      .tz(timestamp, companySettings.timeZoneIANA)
                      .utcOffset() * -1
                  );
                },
              },
            } as Highcharts.Options,
            initialOptionsRef.current
          )
        );

        chartRootContext.instanceMapRef.current.set(
          instanceId,
          instanceRef.current
        );
      }

      return () => {
        try {
          instanceRef.current?.destroy();
          instanceRef.current = undefined;
          chartRootContext.instanceMapRef.current.delete(instanceId);
        } catch (error) {
          console.log(error);
        }
      };
    }, [
      instanceId,
      containerElementRef,
      instanceRef,
      chartRootContext.instanceMapRef,
      companySettings.timeZoneIANA,
    ]);

    React.useImperativeHandle(ref, () => ({
      get chart() {
        return instanceRef.current;
      },
    }));

    React.useEffect(() => {
      instanceRef.current?.update({
        xAxis: props.initialOptions.xAxis,
      });
    }, [instanceRef, props.initialOptions.xAxis]);

    React.useLayoutEffect(() => {
      const container = containerElementRef.current;

      function handleWheel(this: HTMLDivElement, ev: WheelEvent) {
        if (ev.shiftKey) {
          if (!instanceRef.current) return;
          ev.preventDefault();

          const axis = instanceRef.current.xAxis[0];
          if (!axis) return;

          const { dataMin, dataMax } = axis.getExtremes();
          const extremes = axis.getExtremes();
          if (!extremes) return;

          const extremesDiff = (extremes.max - extremes.min) * 0.01;
          const wheelDelta = (ev.deltaY + ev.deltaX) * 0.05;
          const movement = extremesDiff * wheelDelta;

          let min = extremes.min + movement;
          let max = extremes.max + movement;

          axis.hideCrosshair?.();
          instanceRef.current.tooltip?.hide?.(0);
          instanceRef.current.series?.forEach(function (series) {
            series.setState('normal');
            series.points?.forEach((point) => {
              point.setState?.('normal');
            });
          });

          if (min < dataMin) {
            min = dataMin;
            max = max - movement;
          }

          if (max > dataMax) {
            min = min - movement;
            max = dataMax;
          }

          instanceRef.current?.xAxis[0].setExtremes(
            min,
            max,
            undefined,
            false,
            {
              trigger: 'pan',
            }
          );
        }
      }

      container.addEventListener('wheel', handleWheel, { passive: false });

      return () => {
        container.removeEventListener('wheel', handleWheel);
      };
    }, []);

    return (
      <InstanceContextProvider instanceRef={instanceRef}>
        <InstanceSeriesContextProvider
          seriesMapRef={seriesMapRef}
          seriesPropsMap={seriesPropsMap}
          setSeriesProps={setSeriesProps}
          removeSeriesProps={removeSeriesProps}>
          <ChartInstanceContainer ref={containerElementRef} />
          {props.children}
        </InstanceSeriesContextProvider>
      </InstanceContextProvider>
    );
  });

  ChartInstance.displayName = 'ChartInstance';

  const ChartInstanceContainer = styled.div`
    height: 100%;
    width: 100%;
  `;

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Instance Hook
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  const useInstance = (consumerName: string) => {
    const instanceContext = useInstanceContext(consumerName);
    return instanceContext.instanceRef;
  };

  const useInstanceById = (consumerName: string, instanceId: InstanceId) => {
    const rootContext = useRootContext(consumerName);
    return rootContext.instanceMapRef.current.get(instanceId);
  };

  const useInstanceSeries = (consumerName: string) => {
    const instanceSeriesContext = useInstanceSeriesContext(consumerName);
    const instanceSeriesRef = React.useRef<Highcharts.Series[]>([]);

    React.useEffect(() => {
      instanceSeriesRef.current = Array.from(
        instanceSeriesContext.seriesMapRef.current.values()
      );
    }, [instanceSeriesContext.seriesMapRef]);

    return instanceSeriesRef;
  };

  const useInstanceSeriesProps = <P,>(consumerName: string) => {
    const instanceSeriesContext = useInstanceSeriesContext(consumerName);

    return React.useMemo(
      () => Array.from(instanceSeriesContext.seriesPropsMap.values()) as P[],
      [instanceSeriesContext.seriesPropsMap]
    );
  };

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Series Hook
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const useSeries = <P extends Record<string, any>>(
    props: P,
    consumerName: string
  ) => {
    const seriesId = React.useMemo(() => props.id || nanoid(), [props.id]);
    const seriesRef = React.useRef<Highcharts.Series>();
    const instanceContext = useInstanceContext(consumerName);
    const instanceSeriesContext = useInstanceSeriesContext(consumerName);

    /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
     * Manages series creation / removal
     * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

    React.useEffect(() => {
      seriesRef.current = instanceContext.instanceRef.current?.addSeries({
        id: seriesId,
      } as Highcharts.SeriesOptionsType);

      instanceSeriesContext.seriesMapRef.current.set(
        seriesId,
        seriesRef.current as Highcharts.Series
      );

      return () => {
        try {
          instanceSeriesContext.seriesMapRef.current.delete(seriesId);
          instanceContext.instanceRef.current?.get(seriesId)?.remove();
          seriesRef.current = undefined;
        } catch (error) {
          console.log(error);
        }
      };
    }, [
      seriesId,
      seriesRef,
      instanceContext.instanceRef,
      instanceSeriesContext.seriesMapRef,
    ]);

    /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
     * Sets series props in context
     * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

    React.useEffect(() => {
      instanceSeriesContext.setSeriesProps(
        seriesId,
        props as Record<string, unknown>
      );

      return () => {
        instanceSeriesContext.removeSeriesProps(seriesId);
      };
    }, [
      seriesId,
      instanceSeriesContext.setSeriesProps,
      instanceSeriesContext.removeSeriesProps,
      ...Object.values(props),
    ]);

    return seriesRef;
  };

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  return {
    ChartRoot,
    ChartInstance,
    ChartHeader,
    useInstance,
    useInstanceById,
    useSeries,
    useInstanceSeries,
    useInstanceSeriesProps,
    useRootContext,
    withRoot,
  };
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export { createChart };

export type { ChartInstanceRef, InstanceMap, Status };
