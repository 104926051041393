/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Get Delta
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export function diff(a: number, b: number): number;
export function diff(a: number, b: null | undefined): undefined;
export function diff(a: null | undefined, b: number): undefined;
export function diff(
  a: number | null | undefined,
  b: number | null | undefined
): number | undefined {
  if (a === undefined || a === null) return undefined;
  if (b === undefined || b === null) return undefined;
  return a - b;
}

export function integer(x: number): number;
export function integer(x: undefined | null): undefined;
export function integer(x: number | null | undefined): number | undefined {
  if (x === undefined || x === null) return undefined;
  return Math.trunc(x);
}

export function take(
  type: 'min' | 'max',
  a: number | null | undefined,
  b: number | null | undefined
): number | undefined {
  if (typeof a === 'undefined' || a === null) return b;
  if (typeof b === 'undefined' || b === null) return a;
  return Math[type](a, b);
}
