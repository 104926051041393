import { AssetType, Note, RiskPostProcessing, SAASObject } from '.';
import { FormatDateOptions } from '@innovyze/stylovyze';
import { Geometry } from '@innovyze/shared-utils';

export enum RiskByGrade {
	Negligible = 'Negligible',
	Low = 'Low',
	Median = 'Median',
	High = 'High',
	Extreme = 'Extreme',
}

export type SystemType =
	| 'CombinedSewer'
	| 'SanitarySewer'
	| 'Stormwater'
	| 'WaterDistribution'
	| 'WaterTransmission'
	| 'Rivers'
	| 'Canals'
	| 'WaterTreatment'
	| 'WastewaterTreatment'
	| 'Unknown';

export enum PipeType {
	GravityMain = 'Gravity Main',
	PressurizedMain = 'Pressurized Main',
}

export interface AssetCount {
	count: number;
	assetType: AssetType;
	systemType: SystemType;
}

export type AssetCounts = AssetCount[];

export interface AssetRiskAndCondition {
	ASSET_ID: string;
	CURRENT_SERVICE: number;
	CURRENT_STRUCTURAL: number;
	CURRENT_LOF?: number;
	CURRENT_COF?: number;
	CURRENT_RISK?: number;
	GRADE?: number;
	RISK_PERCENTILE: number;
	LOF_CONFIG: CLofCategory[];
	COF_CONFIG: CLofCategory[];
	WEIGHTED_SCORES: {
		[key: string]: number;
	};
	RISK_CONFIG: {
		COFWeighting: number;
		LOFWeighting: number;
		LastRun: string;
		RiskMethod: string;
		Grades: {
			[key: string]: number;
		};
	}[];
	postProcessing?: RiskPostProcessing[];
}

export interface CLofCategory {
	name: string;
	overrideAll: boolean;
	useHighestComponentScore: boolean;
	weight: number;
	components: CLofComponent[];
}

export interface CLofComponent {
	field: string;
	name: string;
	weight: number;
}

export interface AssetRiskHistory {
	ASSET_ID: string;
	RISK: number;
	MODIFIED_AT: string;
}

export interface AssetRiskHistoryStoreState {
	assetRiskHistory?: AssetRiskHistory[];
	isReady: boolean;
}

export interface AssetRiskStoreState {
	assetRisk?: AssetRiskAndCondition;
	riskSummary?: RiskSummary[];
	isReady: boolean;
	isRejected: boolean;
	isWaiting: boolean;
	riskSummaryIsLoading: boolean;
}

export interface AssetRiskParams {
	assetId: string;
	assetType: string;
	configId?: string;
}

export interface AssetRiskResolvedResponse {
	assetRisk: AssetRiskAndCondition;
}

export interface AssetAttrbuteField {
	FieldName: string;
	FieldTitle: string;
	FieldValue: string;
}

export interface AssetDetailStoreState {
	assetDetails?: AssetAttrbuteField[];
	assetCounts?: AssetCounts;
	isAssetCountsReady?: boolean;
	notes?: Note[];
	isReady: boolean;
	validAssetIdsList?: string[];
	validatingAssets?: boolean;
	isWaiting: boolean;
	notesIsWaiting: boolean;
	assetLocationIsWaiting: boolean;
	assetLocation?: AssetLocation;
}

export interface AssetDetailParams {
	assetId: string;
	assetType: string;
	formatDateUTC: (options: FormatDateOptions) => string;
	forNotes?: boolean;
	configId?: string;
	layerId?: string;
	systemType?: string;
}

export interface AssetDetailResolvedResponse {
	assetDetails: AssetAttrbuteField[];
	notes: Note[];
}

export interface AssetDetailMapInsertResolvedResponse {
	geometry: Geometry;
	layerId: string;
}

export interface AssetPipeInfoResolvedResponse {
	asset: { [k: string]: unknown; segments: { [key: string]: string }[] };
}

export interface AssetPipeSummaryResolvedResponse {
	asset: AssetPipeSummary;
}

export interface AssetPipeSummary {
	[key: string]:
		| string
		| number
		| Geometry
		| SegmentInfo[]
		| UserDefined
		| undefined
		| null;

	assetId: string;
	pipeType: string;
	yearLaid: string;
	diameter: number;
	usNodeId: string;
	dsNodeId: string;
	material: string;
	usInvert: number;
	dsInvert: number;
	geometry?: Geometry;
	segments?: SegmentInfo[];
	userDefined?: UserDefined;
	userDefinedUnits?: UserDefined;
	layerSystemType?: string;
}

export interface SegmentInfo {
	[key: string]: string | number;
}

export interface UserDefinedTable {
	[key: string]: string | SAASObject;
}

export interface UserDefined {
	[key: string]: UserDefinedTable;
}

export interface AssetPipeStoreState {
	asset?: AssetPipeSummary;
	isReady: boolean;
}

export interface AssetPipeParams {
	assetId: string;
	assetType: string;
	formatDateUTC: (options: FormatDateOptions) => string;
}

// export interface AssetPipeResolvedResponse {
// 	assetPipe: AssetPipeSummary;
// }

export interface AssetManholeSummaryResolvedResponse {
	asset: AssetManholeSummary;
}

export interface AssetManholeSummary {
	assetId: string;
	yearLaid: string;
	coverLevel?: number;
	groundLevel?: number;
	chamberFloorDepth?: number;
	layerSystemType: string;
	location: string;
	street: string;
	assetType: string;
	owner: string;
	status: string;
	systemType: string;
	geometry?: Geometry;
	userDefined?: UserDefined;
	userDefinedUnits?: UserDefined;
}

export interface AssetManholeStoreState {
	asset?: AssetManholeSummary;
	isReady: boolean;
}

export interface AssetManholeResolvedResponse {
	asset: AssetManholeSummary;
}

export type AssetDetailsSummary = AssetPipeSummary & AssetManholeSummary;

export interface AddAssetNoteParams {
	userName: string;
	userInitial: string;
	note: string;
	dateTime: string;
}

// common with app_am_risk - needs moving to app_am_common
export interface Grades {
	Low: number;
	Medium: number;
	High: number;
	Extreme: number;
	Max: number;
}

// common with app_am_risk - needs moving to app_am_common
export interface RiskSetting {
	RiskMethod: 'added' | 'multiplied';
	LOFWeighting: number;
	COFWeighting: number;
	Grades: Grades;
	LastRun: string;
}

export interface RiskSettingState extends RiskSetting {
	rejected: boolean;
}

export interface CheckAssetIdsParams {
	assetType: 'wwPipe' | 'wwManhole';
	assetId: string[];
}

export interface RiskSummary {
	ASSET_ID?: string;
	CONFIG_NAME?: string;
	CURRENT_RISK?: number;
	RISK_CHANGE?: number;
	RANK?: number;
	GRADE?: number;
	CURRENT_COF?: number;
	CURRENT_LOF?: number;
	MODIFIED_AT?: string;
	COFWEIGHTING?: number;
	LOFWEIGHTING?: number;
	RISKMETHOD?: string;
	RUN_BY?: string;
	postProcessing?: RiskPostProcessing[];
}

export interface AssetLocationParams {
	assetId: string;
	assetType: AssetType;
}

export interface AssetLocation {
	assetId: string;
	position: GeoJSON.Position;
}

export interface Segment {
	height: string;
	length: number;
	liningMaterial: string;
	liningType: string;
	material: string;
	segmentId: string;
	startDistance: number;
	width: number;
}

export interface Asset {
	_id: string;
	assetId: string;
	assetType: string;
	sensorType?: string;
	sensorId?: string;
	status?: string;
	segments?: Segment[];
	layerSystemType?: string;
	systemType?: string;
	geometry?: GeoJSON.Geometry;
}

export interface GetAssetLocationResponse {
	asset: Asset;
}
