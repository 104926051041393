/* eslint-disable prettier/prettier */
import {
    InfoCard,
    LoadingPage,
    rem,
} from '@innovyze/stylovyze';
import React, { useMemo } from 'react';
import { useGlobalization } from '@Translations/useGlobalization';
import { useHistory } from 'react-router-dom';
import { Divider } from '@mui/material';
import { StyledContainer } from './FacilityPropertiesDetails.styles';
import { ImageViewer } from '@Components/ImageViewer';
import { ItemListWrapper } from '@Components/ItemList';

interface FacilityPropertiesDetailsProps {
    initialized: boolean;
    facilityName: string;
    sensors: string[];
    sensorGroups: string[];
    workspaceType?: 'Facility' | 'Pump Station';
    workspaceId?: string;
    imageSrc?: string;
}

export const FacilityPropertiesDetails = ({
    initialized = true,
    facilityName,
    sensors,
    sensorGroups,
    workspaceType,
    workspaceId,
    imageSrc,
}: FacilityPropertiesDetailsProps): JSX.Element => {

    const { t } = useGlobalization();
    const history = useHistory();

    const menuItems = useMemo(() => {
        if (workspaceId) {
            if (workspaceType === 'Facility')
                return [
                    {
                        text: t('Open Facility', {
                            context: 'Facility properties facility workspace',
                        }),
                        cy: 'openWorkspaceButton',
                        onClick: () =>
                            history.push(`/facilities/${workspaceId}`),
                    },
                ];
            else
                return [
                    {
                        text: t('Open Pump Station', {
                            context:
                                'Facility properties pump-station workspace',
                        }),
                        cy: 'openWorkspaceButton',
                        onClick: () =>
                            history.push(`/pump-station/${workspaceId}`),
                    },
                ];
        }
        return [];
    }, [workspaceId, workspaceType]);

    return (
        <>
            <InfoCard
                title={facilityName}
                applyBodyPadding={false}
                menuItems={menuItems}
                buttonStyle={{
                    variant: 'outlined',
                    color: 'primary',
                }}>
                {initialized && (
                    <>
                        {imageSrc && <ImageViewer imageSrc={imageSrc} cy='facilityImage' />}
                        <Divider className="ContentCard--footer--divider" />
                        <ItemListWrapper
                            title={t('Groups', { context: 'Facility properties sensor groups', })}
                            items={sensorGroups}
                            cy='facilitySensorGroups'
                        />
                        <ItemListWrapper
                            title={t('Sensors', { context: 'Facility properties sensors', })}
                            items={sensors}
                            cy='facilitySensors'
                        />
                    </>
                )}
                {!initialized && (
                    <StyledContainer>
                        <LoadingPage />
                    </StyledContainer>
                )}
            </InfoCard>
            <Divider style={{ height: `${rem(3)}` }} />
        </>
    );
};
