import { Button, FormLabel, Radio } from '@mui/material';
import styled, { css } from 'styled-components';

import AccessibilityIcon from '@mui/icons-material/Accessibility';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Wrapper as LayerPanelWrapper } from '../LayerPanel/LayerPanel.styles';
import { rem } from '../styles';

export const Wrapper = styled.div`
	font-size: ${rem(14)};
	box-sizing: border-box;
	overflow-x: hidden;
	height: 100%;
	padding: ${rem(16)};
	width: ${rem(350)};
	color: #43575d;
	* {
		box-sizing: border-box;
	}
	.MuiInputBase-input {
		box-sizing: content-box;
	}
	h3 {
		font-size: ${rem(18)};
		font-weight: 600;
	}
	button {
		text-transform: none;
	}
	${LayerPanelWrapper} & {
		border-left: 2px solid rgba(0, 0, 0, 0.14);
		color: inherit;
	}
`;

export const ThemePropsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: calc(100% - ${rem(25)});
`;

export const SelectLayerWrapper = styled.div`
	text-align: center;
	margin-top: ${rem(115)};
`;

export const Caption = styled.p`
	font-size: ${rem(12)};
	color: #768d95;
	margin-top: ${rem(-10)};
`;

export const SmallFormLegend = styled(FormLabel).attrs({ component: 'legend' })`
	transform: translate(${rem(14)}, 0) scale(0.75);
	transform-origin: left;
`;

export const HumanIcon = styled(AccessibilityIcon)`
	width: ${rem(16)};
	height: ${rem(16)};
	vertical-align: text-top;
	margin-right: 4px;
`;

export const StyledRadioButton = styled(Radio)`
	&.Mui-checked {
		color: #768d95 !important;
	}
`;

export const Breadcrumb = styled.div`
	font-size: ${rem(14)};
	display: flex;
	margin-bottom: ${rem(5)};
`;

export const Crumb = styled.div<{
	$selected?: boolean;
	$isClickable?: boolean;
}>`
	${({ $selected }) =>
		$selected &&
		css`
			font-weight: bold;
		`}
	${({ $isClickable }) =>
		$isClickable &&
		css`
			cursor: pointer;
			color: ${({ theme }) => theme.palette.primary.main};
		`}
	&:after {
		content: '/';
		margin: 0 ${rem(8)};
	}
`;

export const HelpIcon = styled(HelpOutlineIcon)`
	margin-left: ${rem(5)};
	font-size: ${rem(16)};
`;

export const DeleteButton = styled(Button)`
	pointer-events: all !important;
`;
