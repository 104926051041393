import { IControl } from 'mapbox-gl';
import Events from './events/Events';

export const FIT_TO_EXTENTS = 'fit-to-extents';

export default class ExtentsControl extends Events implements IControl {
	private _container: HTMLElement | null = null;
	private _map: mapboxgl.Map | null = null;

	get buttonTitle(): string {
		return 'Fit to extents';
	}

	/**
	 * Function called by mapbox when it adds the control to the map
	 * @param map mpabox instance
	 * @returns controls container element
	 */
	onAdd(map: mapboxgl.Map): HTMLElement {
		this._map = map;
		this._container = this.createControl();
		this.attachEventListeners();
		return this._container;
	}

	/**
	 * Function called by mapbox when it removes the control from the map
	 */
	onRemove(): void {
		this._container?.remove();
		this._map = null;
	}

	/**
	 * Creates the button which controls the layer panel component being shown/hidden
	 * @returns element containing the control button
	 */
	createControl(): HTMLElement {
		const container = document.createElement('div');
		container.classList.add('mapboxgl-ctrl');
		container.classList.add('mapboxgl-ctrl-group');

		container.innerHTML = this.createButton();

		return container;
	}

	/**
	 * html for button
	 * @returns button html
	 */
	createButton(): string {
		return /* HTML */ `
			<button
				class="mapboxgl-extents"
				title="${this.buttonTitle}"
				aria-label="${this.buttonTitle}"
				aria-pressed="false"
				data-cy="fit-to-extents"
			></button>
		`;
	}

	attachEventListeners(): void {
		const menuButton = this._container?.getElementsByClassName(
			'mapboxgl-extents',
		)[0];

		menuButton?.addEventListener('click', this.fitToExtents);
	}

	fitToExtents = (): void => {
		this.fire(FIT_TO_EXTENTS);
	};
}
