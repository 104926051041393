import {
	ButtonContainer,
	InputGrid,
	TexFieldGrid,
	Wrapper,
} from './ColorSelector.styles';
import React, { ChangeEvent, useState } from 'react';
import { RgbaColor, RgbaColorPicker } from 'react-colorful';

import { Button } from '@mui/material';
import { useGlobalization } from '@Translations/useGlobalization';

const componentToHex = (c: number) => {
	const hex = c.toString(16);
	return hex.length == 1 ? '0' + hex : hex;
};

const rgbaToHex = (color: RgbaColor): string => {
	return (
		'#' +
		componentToHex(color.r) +
		componentToHex(color.g) +
		componentToHex(color.b)
	);
};

const hexToRgba = (hex: string): RgbaColor => {
	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	return result
		? {
				r: parseInt(result[1], 16),
				g: parseInt(result[2], 16),
				b: parseInt(result[3], 16),
				a: 1,
		  }
		: { r: 0, g: 0, b: 0, a: 1 };
};

const rgbaToString = (rgba: RgbaColor) =>
	`rgba(${rgba.r},${rgba.g},${rgba.b},${rgba.a})`;

interface CustomColor {
	rgba: RgbaColor;
	hex: string;
}

interface ColorSelectorProps {
	onSave?: (color: string) => void;
	onCancel?: () => void;
}

const ColorSelector = ({
	onSave,
	onCancel,
}: ColorSelectorProps): JSX.Element => {
	const { t } = useGlobalization();
	const [customColor, setCustomColor] = useState<CustomColor>({
		rgba: {
			r: 0,
			g: 0,
			b: 0,
			a: 1,
		},
		hex: '#000000',
	});

	const changeRgbaCustomColor = (key: string) => (
		e: ChangeEvent<HTMLInputElement>,
	) => {
		let inputValue = e.target.value ? parseInt(e.target.value) : 0;
		if (key !== 'a' && (inputValue < 0 || inputValue > 255)) {
			//if not a valid element for rgb
			inputValue = 0;
		}
		if (key === 'a' && (inputValue <= 0 || inputValue > 100)) {
			//if not a valid element for a
			inputValue = 100;
		}
		setCustomColor({
			hex: rgbaToHex({
				...customColor.rgba,
				[key]: key !== 'a' ? inputValue : inputValue / 100,
			}),
			rgba: {
				...customColor.rgba,
				[key]: key !== 'a' ? inputValue : inputValue / 100,
			},
		});
	};

	const changeHexCustomColor = (e: ChangeEvent<HTMLInputElement>) => {
		const hexValue = e.target.value ? e.target.value : '#000000';

		setCustomColor({
			rgba: hexToRgba(hexValue),
			hex: hexValue,
		});
	};

	const addCustomColor = () => {
		const color =
			customColor.rgba.a === 1
				? customColor.hex
				: rgbaToString(customColor.rgba);
		onSave?.(color);
	};

	return (
		<Wrapper>
			<ButtonContainer>
				{onCancel && (
					<Button color="secondary" onClick={onCancel}>
						{t('Cancel', { context: 'Action button' })}
					</Button>
				)}
				{onSave && (
					<Button color="primary" onClick={addCustomColor}>
						{t('OK', { context: 'Action button' })}
					</Button>
				)}
			</ButtonContainer>
			<RgbaColorPicker
				color={customColor.rgba}
				onChange={newColor =>
					setCustomColor({
						rgba: newColor,
						hex: rgbaToHex(newColor),
					})
				}
			/>
			<InputGrid>
				<TexFieldGrid
					variant="outlined"
					size="small"
					value={customColor.hex}
					type="hex"
					onChange={changeHexCustomColor}
					helperText="HEX"
				/>
				<TexFieldGrid
					variant="outlined"
					size="small"
					onChange={changeRgbaCustomColor('r')}
					value={customColor.rgba.r}
					type="rgb"
					helperText="R"
				/>
				<TexFieldGrid
					variant="outlined"
					size="small"
					onChange={changeRgbaCustomColor('g')}
					value={customColor.rgba.g}
					type="rgb"
					helperText="G"
				/>
				<TexFieldGrid
					variant="outlined"
					size="small"
					onChange={changeRgbaCustomColor('b')}
					value={customColor.rgba.b}
					type="rgb"
					helperText="B"
				/>
				<TexFieldGrid
					variant="outlined"
					size="small"
					value={customColor.rgba.a * 100}
					onChange={changeRgbaCustomColor('a')}
					type="rgb"
					helperText="A"
				/>
			</InputGrid>
		</Wrapper>
	);
};

export default ColorSelector;
