import { getIliData } from '../../../services/iliData.service';
import { makeSelectIliByIdFromWorkspace } from '../../selectors/ili.selectors';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import type { AsyncData } from '../../types/async.types';
import type { Ili } from '../../types/ili.types';
import type { StoreState } from '../../../store/store';
import { t } from 'i18next';

const useIli = (iliId: string): AsyncData<Ili> => {
  /* This will store the exposed mass balance */
  const [asyncIli, setAsyncIli] = useState<AsyncData<Ili>>({
    status: 'idle',
    data: null,
  });

  /**
   * This creates a memoized copy of the parametrized selector.
   *
   * See: https://react-redux.js.org/api/hooks#using-memoizing-selectors
   */
  const selectIliByIdFromWorkspace = useMemo(
    makeSelectIliByIdFromWorkspace,
    []
  );

  const storedAsyncIli = useSelector((state: StoreState) => {
    return selectIliByIdFromWorkspace(state, iliId);
  });

  /**
   * Here we take whatever is in the store and set it as the exposed value
   * or we fetch the ILI if it's no there.
   */
  useEffect(() => {
    const fetch = async () => {
      setAsyncIli({
        data: null,
        status: 'loading',
        message: t('Loading ILI'),
      });
      try {
        const response = await getIliData(iliId);

        setAsyncIli({
          data: response.data,
          status: 'resolved',
        });
      } catch (error) {
        setAsyncIli({
          data: null,
          status: 'rejected',
          message: t('Failed to retrieve ILI'),
        });
      }
    };

    if(iliId && iliId !== '') fetch();

    // Stale data in redux cache is causing unnecessary double render / api calls on Charts V2 making the chart even slower

    /* if (storedAsyncIli.status === 'rejected') {
			fetch();
		} else {
			setAsyncIli(storedAsyncIli);
		} */
  }, [iliId]);

  return asyncIli;
};

export default useIli;
