/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, {
  forwardRef,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
} from 'react';
import BoostModule from 'highcharts/modules/boost';
import * as Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import ExportData from 'highcharts/modules/export-data';
import OfflineExporting from 'highcharts/modules/offline-exporting';
import { SyncEvents } from '../../../utils/synchronizedEvents';
import {
  multiDefaultConfig,
  multiNavigatorDefaultConfig,
  defaultStockChartConfig,
  multiRangeSelectorDefaultConfig,
  defaultExportingOptions,
  exportDataToCsv,
  exportDataToXls,
  useTimeZone,
  getHiddenSeries,
  isSeriesHidden,
} from '../../../utils';
import {
  GlobalPassthroughs,
  HiddenSeries,
  RangeSelection,
} from '../../../types';
import createNavigatorColors from '../../../utils/createNavigatorColorsFromSeries';

import * as Styled from './Chart.styles';
import {
  addSyncEventListeners,
  addTooltipEvents,
  syncExtremes,
} from './highcharts.utils';
import { useGlobalization } from '../../../i18n';

Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
  },
});

export interface ChartProps extends GlobalPassthroughs {
  options?: Highcharts.Options;
  stackOptions?: Record<string, Highcharts.Options>;
  loading?: boolean;
  overlay?: boolean;
  onClickPoint?: (point: Highcharts.Point) => void;
  series: Array<Highcharts.SeriesOptionsType>;
  dataRangeSelection?: RangeSelection;
  rangeSelection?: RangeSelection;
  hiddenSeries?: HiddenSeries;
  onRangeSelectionChange?: (rangeSelection: RangeSelection) => void;
  onHiddenSeriesChange?: (hiddenSeries: HiddenSeries) => void;
  minHeight?: string;
}

const takeMin = (a: number | undefined, b: number | undefined) => {
  if (a === undefined) return b;
  else if (b === undefined) return a;

  return a < b ? a : b;
};

const takeMax = (a: number | undefined, b: number | undefined) => {
  if (a === undefined) return b;
  else if (b === undefined) return a;

  return a > b ? a : b;
};

/**
 * To override options on a particular chart when overlay prop is false:
 *
 * Set the id property on the series object(s) that you want to override.
 * Then use the stackOptions prop and use the same id(s) as key, and set the override options for that particular chart as value. This will only run when the overlay prop is false.
 */
const Chart = (
  props: ChartProps,
  chartControllerRef: React.Ref<unknown> | undefined
): JSX.Element => {
  useTimeZone(Highcharts);
  BoostModule(Highcharts);
  const { t } = useGlobalization();

  const chartsContainerRef = useRef<HTMLDivElement | null>(null);
  const chartsRef = useRef<(Highcharts.Chart | null)[]>([]);

  useLayoutEffect(() => {
    const initializeSingleChart = (): (() => void) => {
      const chartContainerElement = chartsContainerRef.current?.querySelector(
        '[data-summaryze-chart]'
      );

      if (!chartContainerElement) {
        throw new Error('Stock chart container element not found.');
      }

      chartsRef.current[0] = Highcharts.chart(
        chartContainerElement as HTMLElement,
        Highcharts.merge(defaultStockChartConfig(t), props.options, {
          exporting: defaultExportingOptions,
          series: createNavigatorColors(
            props.series.map((serie, index) => {
              const isHidden = isSeriesHidden(serie, index, props.hiddenSeries);

              return {
                ...serie,
                visible: !isHidden,
              };
            })
          ),
          plotOptions: {
            series: {
              point: {
                events: {
                  click: function (this: Highcharts.Point) {
                    props.onClickPoint?.(this);
                  },
                },
              },
              events: {
                show: function (this: Highcharts.Series) {
                  props.onHiddenSeriesChange?.(
                    getHiddenSeries(this.chart.series)
                  );
                },
                hide: function (this: Highcharts.Series) {
                  props.onHiddenSeriesChange?.(
                    getHiddenSeries(this.chart.series)
                  );
                },
              },
            },
          },
          xAxis: {
            events: {
              setExtremes: function (
                event: Highcharts.AxisSetExtremesEventObject
              ) {
                if (event.trigger !== 'syncExtremes') {
                  props.onRangeSelectionChange?.({
                    min: event.min,
                    max: event.max,
                  });
                }
              },
            },
          },
          ...(props.isReadOnly && {
            rangeSelector: { enabled: false },
            navigator: { enabled: false },
          }),
        })
      );

      const removeTooltipEvents = addTooltipEvents(
        chartsContainerRef.current,
        chartsRef.current
      );

      return () => {
        removeTooltipEvents();
      };
    };

    const initializeMultipleCharts = (): (() => void) => {
      const chartContainerElements =
        chartsContainerRef.current?.querySelectorAll('[data-summaryze-chart]');

      if (!chartContainerElements) {
        throw new Error('Stock chart container elements not found.');
      }

      const rangeSelectorContainerElement = chartContainerElements[0];

      const navigatorContainerElement =
        chartContainerElements[chartContainerElements.length - 1];

      const boundSyncExtremes = syncExtremes(chartsRef.current);

      chartsRef.current[0] = Highcharts.chart(
        rangeSelectorContainerElement as HTMLElement,
        Highcharts.merge(multiRangeSelectorDefaultConfig, {
          exporting: { enabled: false },
          series: props.series.map((serie, index) => {
            return {
              data: (serie as Highcharts.SeriesLineOptions).data,
              name: serie.name,
              visible: !isSeriesHidden(serie, index, props.hiddenSeries),
            };
          }),
          xAxis: {
            events: {
              setExtremes: boundSyncExtremes,
            },
          },
        })
      );

      chartsRef.current[chartContainerElements.length - 1] = Highcharts.chart(
        navigatorContainerElement as HTMLElement,
        Highcharts.merge(multiNavigatorDefaultConfig, {
          exporting: { enabled: false },
          series: props.series.map((serie, index) => {
            return {
              data: (serie as Highcharts.SeriesLineOptions).data,
              color: serie.color,
              name: serie.name,
              visible: !isSeriesHidden(serie, index, props.hiddenSeries),
            };
          }),
          xAxis: {
            events: {
              setExtremes: function (
                this: Highcharts.Axis,
                event: Highcharts.AxisSetExtremesEventObject
              ) {
                boundSyncExtremes.call(this, event);
                if (event.trigger !== 'syncExtremes') {
                  props.onRangeSelectionChange?.({
                    min: event.min,
                    max: event.max,
                  });
                }
              },
            },
          },
          plotOptions: {
            series: {
              events: {
                show: function (this: Highcharts.Series) {
                  props.onHiddenSeriesChange?.(
                    getHiddenSeries(this.chart.series)
                  );
                },
                hide: function (this: Highcharts.Series) {
                  props.onHiddenSeriesChange?.(
                    getHiddenSeries(this.chart.series)
                  );
                },
              },
            },
          },
        })
      );

      for (let i = 1; i < chartContainerElements.length - 1; i++) {
        const seriesIndex = i - 1;
        const chartContainerElement = chartContainerElements[i];

        const series = props.series[seriesIndex];
        const isHidden = isSeriesHidden(
          series,
          seriesIndex,
          props.hiddenSeries
        );

        if (isHidden) {
          series.visible = false;
        } else {
          series.visible = true;
        }

        let overrideOptions = undefined;

        if (series.id) {
          overrideOptions = props.stackOptions?.[series.id] ?? undefined;
        }

        chartsRef.current[i] = Highcharts.chart(
          chartContainerElement as HTMLElement,
          Highcharts.merge(
            multiDefaultConfig,
            props.options,
            {
              exporting: { enabled: false },
              series: [series],
              xAxis: {
                events: {
                  setExtremes: boundSyncExtremes,
                },
              },
              plotOptions: {
                series: {
                  point: {
                    events: {
                      click: function (this: Highcharts.Point) {
                        props.onClickPoint?.(this);
                      },
                    },
                  },
                },
              },
            },
            overrideOptions
          )
        );

        const flexWrapper = chartContainerElement?.parentElement;

        if (isHidden) {
          flexWrapper?.setAttribute(
            'style',
            'min-height:0;height:0;position:absolute;top:0;left:0;width:0;'
          );
        } else {
          flexWrapper?.removeAttribute('style');
        }

        window.dispatchEvent(new Event('resize'));
      }

      const removeSyncEvents = addSyncEventListeners(
        chartsContainerRef.current,
        chartsRef.current
      );

      const removeTooltipEvents = addTooltipEvents(
        chartsContainerRef.current,
        chartsRef.current
      );

      return () => {
        removeSyncEvents();
        removeTooltipEvents();
      };
    };

    const destroyCharts = (): void => {
      if (chartsRef.current) {
        for (let i = 0; i < chartsRef.current.length; i++) {
          chartsRef.current[i]?.destroy();
        }
        chartsRef.current = [];
      }
    };

    let initializationCleanup: () => void;

    if (props.overlay ?? true) {
      initializationCleanup = initializeSingleChart();
    } else {
      initializationCleanup = initializeMultipleCharts();
    }

    return () => {
      destroyCharts();
      initializationCleanup();
    };
  }, [props.overlay, props.series.length]);

  useLayoutEffect(() => {
    const updateSingleChart = (): void => {
      chartsRef.current?.[0]?.update(
        Highcharts.merge(props.options, {
          series: createNavigatorColors(props.series),
        }),
        true,
        true
      );
    };

    const updateMultipleCharts = (): void => {
      const rangeSelectorAndNavigatorSeriesOptions =
        props.series.map<Highcharts.SeriesLineOptions>((serie, index) => {
          return {
            type: 'line',
            data: (serie as Highcharts.SeriesLineOptions).data,
            name: serie.name,
            visible: !isSeriesHidden(serie, index, props.hiddenSeries),
          };
        });

      chartsRef.current?.[0]?.update({
        series: rangeSelectorAndNavigatorSeriesOptions,
      });

      chartsRef.current?.[chartsRef.current.length - 1]?.update({
        series: rangeSelectorAndNavigatorSeriesOptions,
      });

      for (let i = 1; i < chartsRef.current.length - 1; i++) {
        const series = props.series[i - 1];

        let overrideOptions = undefined;

        if (series.id) {
          overrideOptions = props.stackOptions?.[series.id] ?? undefined;
        }

        chartsRef.current?.[i]?.update(
          Highcharts.merge(props.options, overrideOptions, {
            series: [series],
          })
        );
      }
    };

    if (props.overlay ?? true) {
      updateSingleChart();
    } else {
      updateMultipleCharts();
    }
  }, [props.series, props.options, props.stackOptions]);

  useLayoutEffect(() => {
    const updateSingleChart = (): void => {
      chartsRef.current?.[0]?.update(
        {
          plotOptions: {
            series: {
              point: {
                events: {
                  click: function () {
                    props.onClickPoint?.(this as unknown as Highcharts.Point);
                  },
                },
              },
            },
          },
        },
        true,
        true
      );
    };

    const updateMultipleCharts = (): void => {
      for (let i = 1; i < chartsRef.current.length - 1; i++) {
        chartsRef.current?.[i]?.update(
          {
            plotOptions: {
              series: {
                point: {
                  events: {
                    click: function () {
                      props.onClickPoint?.(this as unknown as Highcharts.Point);
                    },
                  },
                },
              },
            },
          },
          true,
          true
        );
      }
    };

    if (props.overlay ?? true) {
      updateSingleChart();
    } else {
      updateMultipleCharts();
    }
  }, [props.onClickPoint]);

  useLayoutEffect(() => {
    const updateSeriesVisibility = (series: Highcharts.SeriesOptionsType[]) => {
      return series.map((serie, index) => {
        return {
          ...serie,
          visible: !isSeriesHidden(serie, index, props.hiddenSeries),
        };
      });
    };

    const updateSingleChart = (): void => {
      chartsRef.current?.[0]?.update(
        {
          series: updateSeriesVisibility(props.series),
        },
        true,
        true
      );
    };

    const updateMultipleCharts = (): void => {
      const chartContainerElements =
        chartsContainerRef.current?.querySelectorAll('[data-summaryze-chart]');

      if (!chartContainerElements) {
        throw new Error('Stock chart container elements not found.');
      }

      chartsRef.current?.[0]?.update(
        {
          series: updateSeriesVisibility(props.series),
        },
        true,
        true
      );

      chartsRef.current?.[chartsRef.current.length - 1]?.update(
        {
          series: updateSeriesVisibility(props.series),
        },
        true,
        true
      );

      for (let i = 1; i < chartsRef.current.length - 1; i++) {
        const seriesIndex = i - 1;
        const series = props.series?.[seriesIndex];

        if (series) {
          const chartContainer = chartContainerElements[i];
          const flexWrapper = chartContainer?.parentElement;

          const isHidden = isSeriesHidden(
            series,
            seriesIndex,
            props.hiddenSeries
          );

          chartsRef.current?.[i]?.update(
            {
              series: [
                {
                  ...series,
                  visible: !isHidden,
                },
              ],
            },
            true,
            true
          );

          if (isHidden) {
            flexWrapper?.setAttribute(
              'style',
              'min-height:0;height:0;position:absolute;top:0;left:0;width:0;'
            );
          } else {
            flexWrapper?.removeAttribute('style');
          }

          window.dispatchEvent(new Event('resize'));
        }
      }
    };

    if (props.overlay ?? true) {
      updateSingleChart();
    } else {
      updateMultipleCharts();
    }
  }, [props.hiddenSeries]);

  useLayoutEffect(() => {
    let min = props.dataRangeSelection?.min;
    let max = props.dataRangeSelection?.max;

    if (props.rangeSelection) {
      min = takeMax(min, props.rangeSelection?.min);
      max = takeMin(max, props.rangeSelection?.max);
    }

    if (min !== undefined && max !== undefined) {
      if (min > max) {
        const temp = min;
        min = max;
        max = temp;
      }
    }

    chartsRef.current?.[0]?.xAxis[0]?.setExtremes(min, max, true, false, {
      trigger: 'syncExtremes',
    });
  }, [
    props.dataRangeSelection?.min,
    props.dataRangeSelection?.max,
    props.rangeSelection?.min,
    props.rangeSelection?.max,
  ]);

  useImperativeHandle(chartControllerRef, () => ({
    exportToCsv: () => {
      exportDataToCsv(chartsRef.current?.[0]);
    },
    exportToXls: () => {
      exportDataToXls(chartsRef.current?.[0]);
    },
  }));

  return props.overlay ?? true ? (
    <Styled.SingleChartContainer
      className={props.className}
      data-cy={props.cy || props.dataCy}
      ref={chartsContainerRef}
      minHeight={props.minHeight}>
      <Styled.SingleChartSeries>
        <div data-summaryze-chart />
      </Styled.SingleChartSeries>
    </Styled.SingleChartContainer>
  ) : (
    <Styled.MultiChartContainer
      className={props.className}
      data-cy={props.cy || props.dataCy}
      ref={chartsContainerRef}>
      <Styled.MultiChartRangeSelector>
        <div data-summaryze-chart />
      </Styled.MultiChartRangeSelector>
      {props.series.map((series, index) => (
        <Styled.MultiChartSeries key={`${series.name}-${index}`}>
          <div data-summaryze-chart />
        </Styled.MultiChartSeries>
      ))}
      <Styled.MultiChartNavigator>
        <div data-summaryze-chart />
      </Styled.MultiChartNavigator>
    </Styled.MultiChartContainer>
  );
};

SyncEvents(Highcharts);
Exporting(Highcharts);
ExportData(Highcharts);
OfflineExporting(Highcharts);

export default forwardRef(Chart);
