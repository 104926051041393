import {
  debounceFetchSensorDataAction,
  fetchCachedSensorDataAction,
  fetchSensorDataAction,
  getMissingSensorDataAction,
  setSensorDataRecordsAction,
  setSensorDataSourcesAction,
} from '../actions/sensorData.actions';
import {
  call,
  delay,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { measureDataService } from '../services';
import { selectSensorData } from '../selectors/sensorData.selectors';
import { selectUserContext, UserContext } from '@innovyze/stylovyze';

import type {
  AsyncSensorData,
  SensorDataStore,
} from '../core/types/data.types';
import type { AxiosResponse } from 'axios';
import type { MeasureDataResponse } from '../types/measureData.types';

const sensorDataSagas = [
  (function* fetchCachedSensorDataSaga() {
    yield takeEvery(fetchCachedSensorDataAction, function* (action) {
      yield put(setSensorDataSourcesAction(action.payload));
      yield put(debounceFetchSensorDataAction());
    });
  })(),
  (function* debounceExecuteFetchSensorDataSaga() {
    yield takeLatest(debounceFetchSensorDataAction, function* () {
      yield delay(150);
      yield put(getMissingSensorDataAction());
    });
  })(),
  (function* getMissingSensorDataSaga() {
    yield takeEvery(getMissingSensorDataAction, function* () {
      const { sources, records }: SensorDataStore =
        yield select(selectSensorData);

      const dataSourcesWithoutRecords = sources.filter((source) => {
        return !records[source.sensorId]?.[source.resolution];
      });

      if (dataSourcesWithoutRecords) {
        for (const source of dataSourcesWithoutRecords) {
          yield put(fetchSensorDataAction(source));
        }
      }
    });
  })(),
  (function* fetchSensorDataSaga() {
    yield takeEvery(fetchSensorDataAction, function* (action) {
      const userContext: UserContext = yield select(selectUserContext);

      yield put(
        setSensorDataRecordsAction({
          source: action.payload,
          record: {
            data: null,
            status: 'loading',
          },
        })
      );

      try {
        const response: AxiosResponse<MeasureDataResponse> = yield call(
          measureDataService.getMeasureDatum,
          userContext.defaultSite,
          action.payload.sensorId,
          action.payload.resolution
        );

        const { status, data: axiosData } = response;

        if (status < 200 || status >= 300 || typeof axiosData !== 'object') {
          throw response;
        }

        const { unit, data: fileData } = axiosData;

        const data: Exclude<AsyncSensorData['data'], null> = {
          measurements: {},
          timestamps: [],
          unit,
        };

        if (fileData !== undefined && Array.isArray(fileData)) {
          for (let i = 0; i < fileData.length; i++) {
            const [timestamp, ...measurements] = fileData[i];

            data.measurements[timestamp] = measurements;
            data.timestamps.push(timestamp);
          }
        }

        yield put(
          setSensorDataRecordsAction({
            source: action.payload,
            record: {
              data,
              status: 'resolved',
            },
          })
        );
      } catch (error) {
        let CORRUPTED_RESPONSE = false;
        let NON_OK_RESPONSE = true;

        if (
          typeof error === 'object' &&
          error !== null &&
          ('status' in error || 'data' in error)
        ) {
          const { status, data } = error as AxiosResponse<MeasureDataResponse>;

          NON_OK_RESPONSE = status < 200 || status >= 300;
          CORRUPTED_RESPONSE = typeof data !== 'object';
        }

        console.error(
          `Something failed when retrieving data for sensor "${action.payload.sensorId}" and resolution "${action.payload.resolution}".`,
          { NON_OK_RESPONSE, CORRUPTED_RESPONSE }
        );

        yield put(
          setSensorDataRecordsAction({
            source: action.payload,
            record: {
              data: null,
              status: 'rejected',
              message: CORRUPTED_RESPONSE
                ? 'Failed to retrieve data'
                : undefined,
            },
          })
        );
      }
    });
  })(),
];

export default sensorDataSagas;
