import { CircularProgress, Grid } from '@mui/material';
import {
	DateTimeInputMode,
	StylovyzeFormV2,
	useIsFeatureEnabled,
	useCompanyDateTime,
	useUnits,
	useSettings,
} from '@innovyze/stylovyze';
import { Tab, TabPanel, TabWrapper, Tabs } from '@Components/Tabs';
import {
	a11yProps,
	fixupDateTimeForInputDateTime,
	getJustValues,
} from '@Utils';
import {
	refreshInsp,
	selectFullInspection,
	selectFullInspectionIsWaiting,
} from '@Selectors/fullInspection.selectors';
import React, { useEffect, useMemo } from 'react';
import { TFunc } from '@Translations/types';
import { useGlobalization } from '@Translations';
import ModifiedInfoCard from '@Components/ModifiedInfoCard/ModifiedInfoCard';
import {
	DateTimeProps,
	getChangedFormikFields,
	getDetailGrid,
	getDomainItems,
	NumericProps,
	processFormSubmit,
	UnitProps,
} from '@Utils/common.utils';
import {
	refreshInspection,
	updateInspection,
} from '@Actions/inspection.actions';
import { useDispatch } from 'react-redux';
import { InspectionStandard } from '@Types';
import { FormikValues } from 'formik';

const EDITABLE_FIELDS = [
	'pipelineRef',
	'startNodeRef',
	'finishNodeRef',
	'direction',
];

const NON_EDITABLE_FIELDS = ['uploadedBy', 'uploadDateTime'];

const isEditableField = (fieldName: string, fullEdit: boolean) => {
	return fullEdit
		? !NON_EDITABLE_FIELDS.includes(fieldName)
		: EDITABLE_FIELDS.includes(fieldName);
};

const HIDDEN_FIELDS = ['uploadDateTime', 'scheduledDate'];

const isHiddenField = (fieldName: string, hideFields: boolean) => {
	return hideFields ? HIDDEN_FIELDS.includes(fieldName) : false;
};

const detailsFields = (t: TFunc): { name: string; alias: string }[] => [
	{ name: 'uploadedBy', alias: t('Uploaded By') },
	{ name: 'uploadDateTime', alias: t('Upload Date') },
	{ name: 'scheduledDate', alias: t('Scheduled Date') },
	{ name: 'standard', alias: t('ABA - Standard') },
	{
		name: 'originalCodingStandard',
		alias: t('ABB - Original coding standard'),
	},
	{
		name: 'longitudinalReferencePoint',
		alias: t('ABC - Longitudinal reference point'),
	},
	{
		name: 'conduitLocationRiskFactorObservedByOperator',
		alias: t('ABD - Conduit location risk factor observed by operator'),
	},
	{ name: 'methodOfInspection', alias: t('ABE - Method of inspection') },
	{ name: 'inspectionDate', alias: t('ABF - Date of inspection') },
	{ name: 'inspectionTime', alias: t('ABG - Time of inspection') },
	{ name: 'surveyedBy', alias: t('ABH - Operator') },
	{
		name: 'companyJobRef',
		alias: t('ABI - Inspection companys job reference'),
	},
	{ name: 'assetOwnerJobRef', alias: t('ABJ - Asset owners job reference') },
	{ name: 'coderAssessor', alias: t('ABL - Coder/Assessor') },
	{
		name: 'videoImageLocationSystem',
		alias: t('ABM - Video image location system'),
	},
	{
		name: 'evidenceOfSurchargeInStartNode',
		alias: t('ABN - Evidence of surcharge in start node'),
	},
	{ name: 'purposeOfInspection', alias: t('ABP - Purpose of inspection') },
	{
		name: 'anticipatedLength',
		alias: t('ABQ - Anticipated length of inspection'),
	},
	{ name: 'actualLength', alias: t('ABR - Actual inspection length') },
	{
		name: 'nameOfCompanyResponsibleForInspection',
		alias: t('ABS - Name of company responsible for inspection'),
	},
];

const locationFields = (t: TFunc): { name: string; alias: string }[] => [
	{ name: 'pipelineRef', alias: t('AAA - Conduit reference') },
	{ name: 'startNodeRef', alias: t('AAB - Start node reference') },
	{ name: 'startNodeCoordinates', alias: t('AAC - Start node coordinates') },
	{ name: 'finishNodeRef', alias: t('AAF - Finish node reference') },
	{
		name: 'finishNodeCoordinates',
		alias: t('AAG - Finish Node Coordinates'),
	},
	{
		name: 'longLocStartLat',
		alias: t('AAH - Longitudinal location of start of lateral'),
	},
	{
		name: 'circLocStartLat',
		alias: t('AAI - Circumferential location of start of lateral'),
	},
	{ name: 'location', alias: t('AAJ - Location') },
	{ name: 'direction', alias: t('AAK - Direction of inspection') },
	{ name: 'locationTypeCode', alias: t('AAL - Location Type') },
	{
		name: 'assetOwnerOrEngagingAgency',
		alias: t('AAM - Asset owner or engaging agency'),
	},
	{ name: 'locationTown', alias: t('AAN - Town or suburb') },
	{ name: 'divisionDistrict', alias: t('AAO - District') },
	{ name: 'nameOfConduitSystem', alias: t('AAP - Name of conduit system') },
	{ name: 'landOwner', alias: t('AAQ - Land ownership') },
	{
		name: 'mappingGridDatumSystem',
		alias: t('AAR - Mapping grid datum system'),
	},
	{ name: 'mappingGridZone', alias: t('AAS - Mapping grid zone') },
];

const pipeFields = (t: TFunc): { name: string; alias: string }[] => [
	{ name: 'shape', alias: t('ACA - Shape') },
	{ name: 'height', alias: t('ACB - Height') },
	{ name: 'width', alias: t('ACC - Width') },
	{ name: 'material', alias: t('ACD - Material') },
	{ name: 'liningType', alias: t('ACE - Lining Type') },
	{ name: 'liningMaterial', alias: t('ACF - Lining material') },
	{ name: 'conduitUnitLength', alias: t('ACG - Conduit unit length') },
	{ name: 'depthAtStartNode', alias: t('ACH - Depth at start node') },
	{ name: 'depthAtFinishNode', alias: t('ACI - Depth at finish node') },
	{ name: 'operationOfConduit', alias: t('ACJ - Operation of conduit') },
	{ name: 'useOfConduit', alias: t('ACK - Use of conduit') },
	{ name: 'criticality', alias: t('ACL - Criticality') },
	{ name: 'cleaning', alias: t('ACM - Cleaning') },
	{
		name: 'yearCameIntoOperation',
		alias: t('ACN - Year came into operation'),
	},
	{ name: 'jointingMethod', alias: t('ACO - Jointing method') },
];

const miscellaneousFields = (t: TFunc): { name: string; alias: string }[] => [
	{ name: 'precipitation', alias: t('ADA - Precipitation') },
	{ name: 'temperature', alias: t('ADB - Temperature') },
	{ name: 'flowControlMeasures', alias: t('ADC - Flow control measures') },
	{
		name: 'tidalInfluence',
		alias: t('ADD - Tidal influence (where applicable)'),
	},
	{ name: 'generalComment', alias: t('ADE - General comment') },
];

const userDefinedFields = (t: TFunc): { name: string; alias: string }[] => [
	{ name: 'userDefined1', alias: t('AEA') },
	{ name: 'userDefined2', alias: t('AEB') },
	{ name: 'userDefined3', alias: t('AEC') },
	{ name: 'userDefined4', alias: t('AED') },
	{ name: 'userDefined5', alias: t('AEE') },
];

const hardCodeUnitFields: {
	[key: string]: UnitProps;
} = {
	longLocStartLat: { unit: 'm', step: '1' },
	evidenceOfSurchargeInStartNode: { unit: 'm', min: 0, step: '1' },
	anticipatedLength: { unit: 'm', min: 0, step: '1' },
	actualLength: { unit: 'm', min: 0, step: '1' },
	height: { unit: 'mm', min: 0, step: '1' },
	width: { unit: 'mm', min: 0, step: '1' },
	conduitUnitLength: { unit: 'm', min: 0, step: '1' },
	depthAtStartNode: { unit: 'm', step: '1' },
	depthAtFinishNode: { unit: 'm', step: '1' },
};

const hardCodeDateTimeFields: {
	[key: string]: DateTimeProps;
} = {
	uploadDateTime: {
		mode: DateTimeInputMode.DATE_TIME,
		field: 'uploadDateTime',
		useCompanyDateTime: true,
	},
	scheduledDate: {
		mode: DateTimeInputMode.DATE_TIME,
		field: 'scheduledDate',
	},
	inspectionDate: { mode: DateTimeInputMode.DATE, field: 'surveyDateTime' },
	inspectionTime: { mode: DateTimeInputMode.TIME, field: 'surveyDateTime' },
};

const hardCodeCurrencyFields: {
	[key: string]: NumericProps;
} = {};

const hardCodeNumericFields: {
	[key: string]: NumericProps;
} = {
	yearCameIntoOperation: { min: 0 },
};

const hardCodeBooleanFields: {
	[key: string]: boolean;
} = {};

export const getDomainValues = (
	t: TFunc,
	fieldName: string,
): { [key: string]: string } => {
	let domainValueDic: { [key: string]: string } = {};
	switch (fieldName.toLowerCase()) {
		case 'direction'.toLowerCase():
			domainValueDic = {
				D: t('Downstream'),
				U: t('Upstream'),
				Q: t('Unknown'),
			};
			break;
		case 'landOwner'.toLowerCase():
			domainValueDic = {
				C: t('Public land'),
				Q: t('Not Know'),
				T: t('Private land'),
			};
			break;
		case 'locationTypeCode'.toLowerCase():
			domainValueDic = {
				B: t('Bushland/parkland'),
				BO: t('Under a permanent building (Built over)'),
				C: t('Under a waterway (Creek) or body of water'),
				D: t('On property with buildings (Developed)'),
				E: t('Easement'),
				F: t('In a footway beside road'),
				G: t('Gardens'),
				M: t('In other pedestrian area (Mall)'),
				MR: t('Motorway or operational railway'),
				NS: t('In verge beside a road (Nature Strip)'),
				P: t('In a field (Paddock)'),
				R: t('In a road carriageway'),
				RE: t('Road to easement'),
				W: t('Water foreshore'),
				Z: t('Other'),
			};
			break;
		case 'longitudinalReferencePoint'.toLowerCase():
			domainValueDic = {
				C: t('Centre of the starting MH, MS or IS'),
				IP: t('Intersection of incoming and outgoing conduits'),
				P: t('Soffit of the end of the conduit in start node'),
				SA: t('Adjacent surface area'),
				W: t(
					'Inside face of the starting node (MH, MS, IS or headwall etc.)',
				),
				Z: t('Other'),
			};
			break;
		case 'conduitLocationRiskFactorObservedByOperator'.toLowerCase():
			domainValueDic = {
				M: t('Motorway'),
				B: t('Under a building'),
				R: t('Under a railway'),
				T: t('Under a tramway'),
				S: t('Under a school Playground'),
				H: t('Under high value properties'),
				O: t('Other'),
			};
			break;
		case 'methodOfInspection'.toLowerCase():
			domainValueDic = {
				FZ: t('Fixed position zoom pipeline camera'),
				LP: t('Laser profiler'),
				M: t('Person walking through the conduit'),
				PR: t('Push rod'),
				PS: t('3D optical pipeline scanner'),
				SS: t('Sonar scanner'),
				TV: t('Television camera'),
				Z: t('Other'),
			};
			break;
		case 'videoImageLocationSystem'.toLowerCase():
			domainValueDic = {
				HM: t('Elapsed time in hours and minutes'),
				LP: t('Longitudinal location from the reference point'),
				MC: t('Machine dependent numeric counter'),
				Z: t('Other'),
			};
			break;
		case 'purposeOfInspection'.toLowerCase():
			domainValueDic = {
				B: t('Before and after building works over/near conduit'),
				C: t('Completion of abandoned inspection'),
				IE: t('Suspected infiltration problem'),
				IP: t('Investment planning'),
				LP: t('Locate conduit, connection or a maintenance structure'),
				NC: t('Final control of new construction'),
				OE: t('Suspected operational problem'),
				R: t('Routine inspection of condition'),
				RC: t('Final renovation/repair control'),
				S: t('Sample inspection'),
				SE: t('Suspected structural problem'),
				T: t('Transfer of ownership'),
				W: t('End of warranty period'),
				Z: t('Other'),
			};
			break;
		case 'shape'.toLowerCase():
			domainValueDic = {
				A: t('Arch'),
				C: t('Cicular'),
				E: t('Oviform (egg shaped)'),
				O: t('Oval'),
				R: t('Rectangular'),
				U: t('U shape - circular'),
				X: t('Specified by asset owner'),
				Z: t('Other'),
			};
			break;
		case 'material'.toLowerCase():
			domainValueDic = {
				ABS: t('Acrylonitrile butadiene styrene '),
				AL: t('Aluminium alloy'),
				AC: t('Asbestos cement'),
				AM: t('Cast iron (type unknown)'),
				VC: t('Clay—vitrified'),
				RC: t('Concrete reinforced'),
				CS: t('Concrete segments'),
				GU: t('Concrete sprayed'),
				CP: t('Concrete unreinforced'),
				DI: t('Ductile cast iron'),
				FC: t('Fibre reinforced cement'),
				FRP: t('Fibre reinforced plastics'),
				GI: t('Galvanised iron or steel'),
				GRP: t('Glass reinforced plastics'),
				CI: t('Grey cast iron'),
				MBC: t('Masonry - brick (coursed)'),
				MBSC: t('Masonry - bluestone/basalt (coursed)'),
				MOSC: t('Masonry - other stone (coursed)'),
				MBSU: t('Masonry - bluestone/basalt (uncoursed)'),
				MOSU: t('Masonry - other stone (uncoursed)'),
				MCON: t('Masonry-concrete composite'),
				PF: t('Pitch fibre'),
				P: t('Plastics (type unknown)'),
				PE: t('Polyethylene'),
				PP: t('Polypropylene'),
				PVC: t('Polyvinylchloride'),
				SS: t('Stainless steel'),
				S: t('Steel'),
				AG: t('Unidentified type of concrete or cement mortar'),
				AQ: t('Unidentified type of steel'),
				AY: t('Unidentified type of plastics'),
				ZZ: t('Unidentified material'),
				Z: t('Other'),
			};
			break;
		case 'liningType'.toLowerCase():
			domainValueDic = {
				CFL: t('Close fit lining'),
				CIP: t('Cured in place lining'),
				LCP: t('Continuous conduit - slip lining'),
				LDP: t(
					'Discrete pipe units transported into and joined in host conduit',
				),
				MFL: t(
					'Lining inserted during manufacture (Manufacturer’s lining)',
				),
				SEG: t('Segmental linings'),
				SPL: t('Sprayed lining'),
				SWL: t('Spirally wound lining'),
				Z: t('Other'),
			};
			break;
		case 'liningMaterial'.toLowerCase():
			domainValueDic = {
				CL: t('Cement (mortar) lining'),
				E: t('Epoxy'),
				FRP: t('Fibre reinforced plastics'),
				GRC: t('Glass fibre reinforced cement'),
				GRP: t('Glass fibre reinforced plastics'),
				PE: t('Polyethylene'),
				PIS: t(
					'Plastics (thermosetting) impregnated feld or woven sock',
				),
				PU: t('Polyurethane'),
				PUA: t('Polyurea'),
				PVC: t('Polyvinylchloride'),
				Z: t('Other'),
			};
			break;
		case 'operationOfConduit'.toLowerCase():
			domainValueDic = {
				G: t('Gravity'),
				O: t('Other e.g., duct'),
				P: t('Pressure'),
			};
			break;
		case 'useOfConduit'.toLowerCase():
			domainValueDic = {
				COM: t('Combined'),
				CUL: t('Culverted'),
				D: t('Drain'),
				S: t('Sanitary'),
				TW: t('Trade'),
				Z: t('Other'),
			};
			break;
		case 'cleaning'.toLowerCase():
			domainValueDic = {
				C: t('Cleaned prior to inspection'),
				NC: t('Not cleaned prior to inspection'),
			};
			break;
		case 'jointingMethod'.toLowerCase():
			domainValueDic = {
				A: t('Sections abutted with no jointing'),
				BF: t('Butt fusion welded'),
				BFD: t('Butt fusion welded with reinforcement or debeading'),
				CMJ: t('Cement mortar jointed'),
				EF: t('Electrofusion coupling weld (PE only)'),
				F: t('Flange jointed – steel, cast or ductile iron'),
				L: t('Lap fillet weld (steel only)'),
				MC: t('Mechanical coupling'),
				RRJ: t('Rubber ring'),
				SCJ: t('Solvent cement jointed'),
				Z: t('Other'),
			};
			break;
		case 'precipitation'.toLowerCase():
			domainValueDic = {
				N: t('No precipitation'),
				R: t('Precipitation (rain)'),
				S: t('Melting snow or ice'),
			};
			break;
		case 'flowControlMeasures'.toLowerCase():
			domainValueDic = {
				B: t('Flows have been blocked or diverted upstream'),
				N: t('No measures taken'),
				P: t('Flows partially blocked or diverted upstream'),
				Z: t('Other'),
			};
			break;
		case 'tidalInfluence'.toLowerCase():
			domainValueDic = {
				A: t('Conduit is at or above the high tide level'),
				B: t('Conduit is below the high tide level'),
			};
			break;
		default:
			break;
	}

	return domainValueDic;
};

interface Props {
	onReRender?: () => void;
}

export const WSA05InspectionProps = ({ onReRender }: Props): JSX.Element => {
	const { t } = useGlobalization();
	const { getSystemValueFormatted } = useUnits();
	const dispatch = useDispatch();
	const { currentDateTime } = useCompanyDateTime();
	const { formatDate } = useCompanyDateTime();
	const { companySettings } = useSettings();

	const [tabValue, setTabValue] = React.useState(0);
	const isLoading = selectFullInspectionIsWaiting();
	const [editHeaderDetails, setEditHeaderDetails] = React.useState(false);
	const [formSubmitted, setFormSubmitted] = React.useState(false);
	const inspection = selectFullInspection();

	const origDetails = useMemo(() => {
		setFormSubmitted(false);

		const values = getJustValues(inspection, false);

		const keys = Object.keys(values);
		keys.forEach(key => {
			// Adjust any date time fields for timezone/dst offsets caused by the datetime control
			const field = Object.entries(hardCodeDateTimeFields).find(
				value => value[1].field === key,
			);
			if (field) {
				values[key] = fixupDateTimeForInputDateTime(
					values[key],
					field[1].useCompanyDateTime ? currentDateTime() : undefined,
				);
			}
		});

		return values;
	}, [inspection]);

	const viewDetails = useMemo(() => {
		const values = getJustValues(inspection, true);

		const keys = Object.keys(values);
		keys.forEach(key => {
			// Adjust any date time fields for timezone/dst offsets caused by the datetime control
			const field = Object.entries(hardCodeDateTimeFields).find(
				value => value[1].field === key,
			);
			if (field) {
				values[key] = fixupDateTimeForInputDateTime(
					values[key],
					field[1].useCompanyDateTime ? currentDateTime() : undefined,
				);
			}
		});

		return values;
	}, [inspection]);

	const inspUpdatestatus = refreshInsp();

	//feature to be behind a LaunchDarkley flag
	const isEditAllowedFlag = useIsFeatureEnabled(
		'info-360-edit-inspections-header',
	)
		? true
		: false;

	const isFullEditAllowedFlag = useIsFeatureEnabled(
		'info-360-edit-full-inspections',
	)
		? true
		: false;

	const isCreateAllowedFlag = useIsFeatureEnabled(
		'info-360-create-inspections',
	);

	const handleChangeTab = (event: unknown, newValue: number) => {
		setTabValue(newValue);
	};

	const handleFormSubmit = (data: FormikValues) => {
		// We should really used the touched state of a field but I cant get the Formik stuff to work properly
		const changes = getChangedFormikFields(origDetails, data);

		if (changes && Object.entries(changes).length !== 0) {
			setFormSubmitted(true);

			const allFields: { name: string; alias: string }[] = [
				...detailsFields(t),
				...locationFields(t),
				...pipeFields(t),
				...miscellaneousFields(t),
				...userDefinedFields(t),
			];

			const editedFieldsList = processFormSubmit(
				t,
				InspectionStandard.PACP,
				origDetails,
				changes,
				allFields,
				hardCodeDateTimeFields,
				hardCodeUnitFields,
				hardCodeCurrencyFields,
				hardCodeNumericFields,
				hardCodeBooleanFields,
				getSystemValueFormatted,
				formatDate,
				companySettings,
				t('ABF/ABG Date/Time of inspection'),
				{ date: 'date', time: 'time' },
			);

			dispatch(refreshInspection({ status: -1 })); //reset update flag and hide the controls
			dispatch(updateInspection(editedFieldsList)); //trigger update
		}
	};

	//when inspection has been updated, page needs to be refreshed to display latest details
	useEffect(() => {
		if (inspUpdatestatus === 200 && onReRender) onReRender();
	}, [inspUpdatestatus]);

	const general = (
		<ModifiedInfoCard
			title={t('Details')}
			dataCy="modified-info-card-details"
			showEditBtn={
				origDetails['inspectionStatus'] !== 'approved' &&
				onReRender !== undefined
			}
			launchDarkleyFlag={isEditAllowedFlag}
			onEditClick={() => {
				setEditHeaderDetails(true);
			}}
			onCancelClick={() => {
				setEditHeaderDetails(false);
			}}
			inEditMode={editHeaderDetails}
			disabled={inspUpdatestatus === -1}>
			<Grid container>
				{getDetailGrid(
					t,
					detailsFields(t),
					hardCodeUnitFields,
					hardCodeDateTimeFields,
					hardCodeCurrencyFields,
					hardCodeNumericFields,
					hardCodeBooleanFields,
					isEditableField,
					isFullEditAllowedFlag,
					isHiddenField,
					!isCreateAllowedFlag,
					getDomainValues,
					getDomainItems,
				)}
			</Grid>
		</ModifiedInfoCard>
	);

	const location = (
		<ModifiedInfoCard
			title={t('Location')}
			dataCy="modified-info-card"
			showEditBtn={
				origDetails['inspectionStatus'] !== 'approved' &&
				onReRender !== undefined
			}
			launchDarkleyFlag={isEditAllowedFlag}
			onEditClick={() => {
				setEditHeaderDetails(true);
			}}
			onCancelClick={() => {
				setEditHeaderDetails(false);
			}}
			inEditMode={editHeaderDetails}
			disabled={inspUpdatestatus === -1}>
			<Grid container>
				{getDetailGrid(
					t,
					locationFields(t),
					hardCodeUnitFields,
					hardCodeDateTimeFields,
					hardCodeCurrencyFields,
					hardCodeNumericFields,
					hardCodeBooleanFields,
					isEditableField,
					isFullEditAllowedFlag,
					undefined,
					undefined,
					getDomainValues,
					getDomainItems,
				)}
			</Grid>
		</ModifiedInfoCard>
	);

	const pipe = (
		<ModifiedInfoCard
			title={t('Pipe')}
			dataCy="modified-info-card-pipe"
			showEditBtn={
				origDetails['inspectionStatus'] !== 'approved' &&
				onReRender !== undefined
			}
			launchDarkleyFlag={isEditAllowedFlag}
			onEditClick={() => {
				setEditHeaderDetails(true);
			}}
			onCancelClick={() => {
				setEditHeaderDetails(false);
			}}
			inEditMode={editHeaderDetails}
			disabled={inspUpdatestatus === -1}>
			<Grid container>
				{getDetailGrid(
					t,
					pipeFields(t),
					hardCodeUnitFields,
					hardCodeDateTimeFields,
					hardCodeCurrencyFields,
					hardCodeNumericFields,
					hardCodeBooleanFields,
					isEditableField,
					isFullEditAllowedFlag,
					undefined,
					undefined,
					getDomainValues,
					getDomainItems,
				)}
			</Grid>
		</ModifiedInfoCard>
	);

	const miscellaneous = (
		<ModifiedInfoCard
			title={t('Miscellaneous')}
			dataCy="modified-info-card-miscellaneous"
			showEditBtn={
				origDetails['inspectionStatus'] !== 'approved' &&
				onReRender !== undefined
			}
			launchDarkleyFlag={isEditAllowedFlag}
			onEditClick={() => {
				setEditHeaderDetails(true);
			}}
			onCancelClick={() => {
				setEditHeaderDetails(false);
			}}
			inEditMode={editHeaderDetails}
			disabled={inspUpdatestatus === -1}>
			<Grid container>
				{getDetailGrid(
					t,
					miscellaneousFields(t),
					hardCodeUnitFields,
					hardCodeDateTimeFields,
					hardCodeCurrencyFields,
					hardCodeNumericFields,
					hardCodeBooleanFields,
					isEditableField,
					isFullEditAllowedFlag,
					undefined,
					undefined,
					getDomainValues,
					getDomainItems,
				)}
			</Grid>
		</ModifiedInfoCard>
	);

	const userDefined = (
		<ModifiedInfoCard
			title={t('User Defined')}
			dataCy="modified-info-card-userdefined"
			showEditBtn={origDetails['inspectionStatus'] !== 'approved'}
			launchDarkleyFlag={isEditAllowedFlag}
			onEditClick={() => {
				setEditHeaderDetails(true);
			}}
			onCancelClick={() => {
				setEditHeaderDetails(false);
			}}
			inEditMode={editHeaderDetails}
			disabled={inspUpdatestatus === -1}>
			<Grid container>
				{getDetailGrid(
					t,
					userDefinedFields(t),
					hardCodeUnitFields,
					hardCodeDateTimeFields,
					hardCodeCurrencyFields,
					hardCodeNumericFields,
					hardCodeBooleanFields,
					isEditableField,
					isFullEditAllowedFlag,
					undefined,
					undefined,
					getDomainValues,
					getDomainItems,
				)}
			</Grid>
		</ModifiedInfoCard>
	);

	if (!isLoading && origDetails && !formSubmitted) {
		return (
			<StylovyzeFormV2
				mode={editHeaderDetails ? 'edit' : 'view'}
				initialValues={editHeaderDetails ? origDetails : viewDetails}
				enableReinitialize
				onSubmit={(data: FormikValues) => {
					setEditHeaderDetails(false);
					handleFormSubmit(data);
				}}>
				{({ handleSubmit }) => {
					return (
						<form onSubmit={handleSubmit}>
							<TabWrapper $height={683}>
								<Tabs
									orientation="vertical"
									variant="scrollable"
									value={tabValue}
									onChange={handleChangeTab}
									aria-label="manhole-attributes">
									<Tab
										label={t('Details')}
										{...a11yProps(0)}
									/>
									<Tab
										label={t('Location')}
										{...a11yProps(1)}
									/>
									<Tab label={t('Pipe')} {...a11yProps(2)} />
									<Tab
										label={t('Miscellaneous')}
										{...a11yProps(3)}
									/>
									<Tab
										label={t('User Defined')}
										{...a11yProps(4)}
									/>
								</Tabs>
								<TabPanel value={tabValue} index={0}>
									{general}
								</TabPanel>
								<TabPanel value={tabValue} index={1}>
									{location}
								</TabPanel>
								<TabPanel value={tabValue} index={2}>
									{pipe}
								</TabPanel>
								<TabPanel value={tabValue} index={3}>
									{miscellaneous}
								</TabPanel>
								<TabPanel value={tabValue} index={4}>
									{userDefined}
								</TabPanel>
							</TabWrapper>
						</form>
					);
				}}
			</StylovyzeFormV2>
		);
	} else {
		return (
			<div style={{ padding: '25px' }}>
				<CircularProgress />
			</div>
		);
	}
};

export default WSA05InspectionProps;
