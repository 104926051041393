import {
  Axis,
  AxisSetExtremesEventObject,
  StockChart,
} from 'highcharts/highstock';

export type SyncCharts = (StockChart | null)[];

export type SyncEvents = PointerEvent | MouseEvent | TouchEvent;

export type SyncEventTypes =
  | 'mousemove'
  | 'touchmove'
  | 'touchstart'
  | 'mouseleave';

export const hideTooltips = (charts: SyncCharts) => {
  return (e: SyncEvents): void => {
    for (const chart of charts) {
      if (chart?.options && chart?.pointer) {
        const event = chart?.pointer?.normalize(e);

        chart.series.forEach(function (series) {
          const point = series?.searchPoint?.(event, true);

          point?.setState?.('');
          chart?.tooltip?.hide?.();
          chart?.xAxis[0]?.hideCrosshair?.();
        });
      }
    }
  };
};

export const syncPoints = (charts: SyncCharts) => {
  return (e: SyncEvents): void => {
    for (const chart of charts) {
      if (chart?.options && chart?.pointer) {
        const event = chart?.pointer?.normalize(e);
        const point = chart?.series[0]?.searchPoint?.(event, true);

        point?.highlight?.(e);
      }
    }
  };
};

export const syncExtremes = (charts: SyncCharts) => {
  return function (this: Axis, e: AxisSetExtremesEventObject): void {
    if (e.trigger !== 'syncExtremes') {
      charts.forEach((chart: StockChart | null) => {
        if (chart !== this.chart && chart) {
          chart.xAxis[0]?.setExtremes?.(e.min, e.max, true, false, {
            trigger: 'syncExtremes',
          });
        }
      });
    }
  };
};

export const addSyncEventListeners = (
  container: HTMLDivElement | null,
  charts: SyncCharts
): (() => void) => {
  const boundSyncPoints = syncPoints(charts);

  (['mousemove', 'touchmove', 'touchstart'] as SyncEventTypes[]).forEach(
    (syncEvent) => {
      container?.addEventListener(syncEvent, boundSyncPoints);
    }
  );

  return () => {
    (['mousemove', 'touchmove', 'touchstart'] as SyncEventTypes[]).forEach(
      (syncEvent) => {
        container?.removeEventListener(syncEvent, boundSyncPoints);
      }
    );
  };
};

export const addTooltipEvents = (
  container: HTMLDivElement | null,
  charts: SyncCharts
): (() => void) => {
  const boundHideTooltips = hideTooltips(charts);

  (['mouseleave'] as SyncEventTypes[]).forEach(function (syncEvent) {
    container?.addEventListener(syncEvent, boundHideTooltips);
  });

  return () => {
    (['mouseleave'] as SyncEventTypes[]).forEach(function (syncEvent) {
      container?.removeEventListener(syncEvent, boundHideTooltips);
    });
  };
};
