import config, { apiEnv } from './config.api';

import axios from 'axios';
import cookies from 'browser-cookies';
import { formatUrlTrailing } from '@Utils/urlFormatting';
import { getService } from '@innovyze/stylovyze';

const options = {
	baseURL: getService('amUpload', config, apiEnv),
	timeout: 15000,
};

const uploadService = axios.create(options);

uploadService.interceptors.request.use(
	conf => {
		conf.headers.set(
			'Authorization',
			'Bearer ' + cookies.get('auth0.user.token'),
		);
		return conf;
	},
	error => {
		Promise.reject(error);
	},
);

export default uploadService;

export const uploadBaseUrl = formatUrlTrailing(
	getService('amUpload', config, apiEnv),
);
