/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { forwardRef, ReactElement, useMemo } from 'react';
import {
  GlobalPassthroughs,
  HiddenElements,
  RangeSelection,
  GaugeChartDisplayOptions,
} from '../../../types';

import GaugeChart from '../../molecules/GaugeChart';
import { ChartingErrorBoundary } from '../../atoms';
import {
  ConnectedGaugeSeries,
  GaugeSeries,
} from '../../../core/types/gauge.types';
import useSensorData from '../../../core/hooks/useSensorData';
import { ChartRef } from '../../../core/components/Chart';
import { generateGaugeSeries } from './connectedGaugeChart.utils';
import { isFeatureEnabled } from '@innovyze/stylovyze';
import {
  InsightGaugeChart,
  InsightGaugeChartProps,
} from '../../../_next/presets/insight-gauge-chart';
import { TimeRangeSelection } from '../../../_next/core/_insight-chart';
import { GaugeChartSeriesSourceOptions } from '../../../_next/modules/gauge-chart';
import { Theme } from '../../../_next/core/utils/theme-utils';

export interface ConnectedGaugeChartProps extends GlobalPassthroughs {
  series: ConnectedGaugeSeries;
  customData: GaugeChartSeriesSourceOptions[];
  secondarySeries?: ConnectedGaugeSeries[];
  displayOptions?: GaugeChartDisplayOptions;
  dataRangeSelection?: RangeSelection;
  hiddenElements?: HiddenElements;
  onHiddenElementsChange?: (hiddenElements: HiddenElements) => void;
  timeRangeSelection?: TimeRangeSelection;
  forecastPeriod?: number;
  showForecastPeriodSlider?: boolean;
  showValues?: boolean;
  onForecastPeriodChange?: (value: number) => void;
  selectedTheme?: Theme;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Version Switch
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const ConnectedGaugeChart = (
  props: ConnectedGaugeChartProps,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref?: any
): React.ReactElement => {
  const isV2Enabled = isFeatureEnabled('info-360-analytics-hp2-charts');

  if (!isV2Enabled) {
    return <GaugeChartV1 {...props} ref={ref} />;
  }

  return <GaugeChartV2 {...props} ref={ref} />;
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * New Chart
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const GaugeChartV2 = React.forwardRef<
  { chart: Highcharts.Chart | undefined },
  ConnectedGaugeChartProps
>((props, ref): React.ReactElement => {
  const sources: InsightGaugeChartProps['series']['sources'] =
    React.useMemo(() => {
      const allSources: InsightGaugeChartProps['series']['sources'] = [];
      if (props.customData) return allSources;
      allSources.push({
        sensorId: props.series.sensor.id,
        name: props.series.sensor.alias,
        resolution: props.series.resolution,
        reading: props.series.reading,
        color: props.series.color,
        dashStyle: props.series.dashStyle,
        label: props.series.label,
        lastTimestamp: props.series.lastTimestamp,
      });
      if (props.secondarySeries) {
        for (const series of props.secondarySeries) {
          allSources.push({
            sensorId: series.sensor.id,
            name: series.sensor.alias,
            resolution: series.resolution,
            reading: series.reading,
            color: series.color,
            dashStyle: series.dashStyle,
            label: series.label,
            lastTimestamp: series.lastTimestamp,
          });
        }
      }
      return allSources;
    }, [props.series, props.secondarySeries, props.customData]);

  const series = React.useMemo(() => {
    return {
      sources: sources,
      showValues: props.showValues,
      thresholds: {
        min: props.series.thresholds?.lower,
        max: props.series.thresholds?.upper,
      },
    } as InsightGaugeChartProps['series'];
  }, [props.series, props.showValues, sources]);

  return (
    <ChartingErrorBoundary chartProps={props}>
      <InsightGaugeChart
        ref={ref}
        series={series}
        customData={props.customData}
        timeRangeSelection={props.timeRangeSelection}
        openSnapshot={
          props.hiddenElements === undefined
            ? undefined
            : !props.hiddenElements.includes('snapshot')
        }
        onOpenSnapshotChange={(open) => {
          if (open) {
            props.onHiddenElementsChange?.([]);
          } else {
            props.onHiddenElementsChange?.(['snapshot']);
          }
        }}
        showForecastPeriodSlider={props.showForecastPeriodSlider}
        forecastPeriod={props.forecastPeriod}
        onForecastPeriodChange={props.onForecastPeriodChange}
        selectedTheme={props.selectedTheme}
      />
    </ChartingErrorBoundary>
  );
});

GaugeChartV2.displayName = 'GaugeChartV2';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Old Chart
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

/**
 * Fetches the sensor data for the given sensor and generates
 * a Gauge chart.
 */
const GaugeChartV1 = forwardRef<ChartRef, ConnectedGaugeChartProps>(
  (props, chartControllerRef): ReactElement | null => {
    const {
      series: connectedSeries,
      displayOptions,
      dataRangeSelection,
      ...passThroughProps
    } = props;

    const gaugeSeries = useMemo<GaugeSeries>(() => {
      return generateGaugeSeries(connectedSeries);
    }, [connectedSeries]);

    const dataSources = useMemo(() => {
      return [gaugeSeries.dataSource];
    }, [gaugeSeries]);

    const sensorDataRecords = useSensorData(
      dataSources,
      props.dataRangeSelection
    );

    return (
      <ChartingErrorBoundary chartProps={props}>
        <GaugeChart
          {...passThroughProps}
          data={sensorDataRecords}
          displayOptions={displayOptions}
          ref={chartControllerRef}
          series={[gaugeSeries]}
        />
      </ChartingErrorBoundary>
    );
  }
);

GaugeChartV1.displayName = 'GaugeChartV1';

export default forwardRef<ChartRef, ConnectedGaugeChartProps>(
  ConnectedGaugeChart
);
