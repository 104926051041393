export enum Kpis {
  'HighAlerts' = 'HighAlerts',
  'NewAlerts' = 'NewAlerts',
  'Incidents' = 'Incidents',
}

export type KpiType = keyof typeof Kpis;

export const kpiPalette = {
  HighAlerts: '#dc360c',
  NewAlerts: '#efb304',
  Incidents: '#83bc40',
};
