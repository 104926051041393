import * as InsightChart from '../../core/_insight-chart';
import * as TimeSeriesData from '../../core/time-series-data-old';

import type {
  ColumnSeriesData,
  BarSeriesData,
  LineSeriesData,
  SeriesOptions,
} from '../../modules/comparison-chart';

export const makeSeriesData = (
  seriesType: SeriesOptions['type'],
  data: TimeSeriesData.TimeSeriesData | undefined,
  seriesIndex: number,
  totalSeries: number,
  reference?: number
): BarSeriesData | ColumnSeriesData | LineSeriesData => {
  if (seriesType === 'column' || seriesType === 'bar') {
    if (data.length === 0) return [];
    const seriesData: ColumnSeriesData = new Array(totalSeries).fill('');
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_timestamp, value] = data[data.length - 1];
    seriesData[seriesIndex] = value ?? 0;

    return seriesData;
  } else {
    const seriesData: LineSeriesData = new Array(totalSeries).fill(
      reference ?? 0
    );

    return seriesData;
  }
};
