import axios, { AxiosRequestConfig } from 'axios';
import cookies from 'browser-cookies';
import { getApiEnvironment, getService } from '@innovyze/stylovyze';
import { apiConfig } from './config.api';

const options: AxiosRequestConfig = {
  baseURL: getService('alertsManager', apiConfig, getApiEnvironment()),
  timeout: 10000,
};

const alertsApi = axios.create(options);

alertsApi.interceptors.request.use(
  (config) => {
    config.headers.Authorization = 'Bearer ' + cookies.get('auth0.user.token');
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export default alertsApi;
