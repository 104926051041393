import { InsightPumpPerformanceChartSeriesProps } from "./insight-pump-performance-chart";
import * as TimeSeriesDataOld from '../../core/time-series-data-old';
import * as InsightChart from '../../core/_insight-chart';


export function makeSources(
  series: InsightPumpPerformanceChartSeriesProps[],
  isEdgeSource?: boolean
) {
  const sources: (TimeSeriesDataOld.Source & {
    series?: InsightPumpPerformanceChartSeriesProps;
    reading?: InsightChart.Reading;
    category?: string;
  })[] = [];

  for (const s of series) {
    if (s.type === 'scatter') {
      const { baseSource, downstreamSource, upstreamSource } = s;

      if (baseSource && !baseSource.customData) {
        sources.push({
          series: isEdgeSource ? s : undefined,
          category: isEdgeSource ? 'Base' : undefined,
          reading: isEdgeSource ? baseSource.reading : undefined,
          sensorId: baseSource.sensorId,
          resolution: baseSource.resolution,
        });
      }
      if (downstreamSource && !downstreamSource.customData) {
        sources.push({
          series: isEdgeSource ? s : undefined,
          category: isEdgeSource ? 'Down' : undefined,
          reading: isEdgeSource ? baseSource.reading : undefined,
          sensorId: downstreamSource.sensorId,
          resolution: downstreamSource.resolution,
        });
      }
      if (upstreamSource && !upstreamSource.customData) {
        sources.push({
          series: isEdgeSource ? s : undefined,
          category: isEdgeSource ? 'Up' : undefined,
          reading: isEdgeSource ? baseSource.reading : undefined,
          sensorId: upstreamSource.sensorId,
          resolution: upstreamSource.resolution,
        });
      }
    } else if (s.type === 'pump-filter') {
      let reading = isEdgeSource ? s.reading : undefined;
      if (isEdgeSource && (reading === 'Average' || reading === 'Sum'))
        reading = 'Close';
      sources.push({
        series: isEdgeSource ? s : undefined,
        category: isEdgeSource ? 'Filter' : undefined,
        reading: reading,
        sensorId: s.sensorId,
        resolution: s.resolution,
      });
    }
  }
  return sources;
}
