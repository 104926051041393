import { Button } from '@innovyze/stylovyze';
import { Grid } from '@mui/material';
import React, {
	ReactNode,
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';
import { useStyles } from './ShowMoreOverlay.styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import clsx from 'clsx';
import { ExpandLess } from '@mui/icons-material';
import { useGlobalization } from '@Translations';

// eslint-disable-next-line @typescript-eslint/naming-convention
const ShowMoreOverlay = ({
	className,
	children,
	height = 200,
}: {
	className?: string;
	children?: ReactNode;
	height?: number;
}) => {
	const { t } = useGlobalization();

	const [isExpanded, setIsExpanded] = useState(false);

	const showMoreClicked = () => {
		setIsExpanded(!isExpanded);
	};

	const squareRef = useRef<HTMLDivElement>(null);
	const [size, setSize] = useState<{
		width: number;
		height: number;
	}>({
		width: 0,
		height: 0,
	});

	const updateSize = useCallback(() => {
		const node: HTMLDivElement | null = squareRef?.current;
		if (node) {
			setSize({
				width: node.offsetWidth || 0,
				height: node.offsetHeight || 0,
			});
		}
	}, [squareRef]);

	useEffect(() => {
		updateSize();
	}, [children]);

	const needShowMore = size.height > height;
	const classes = useStyles(height, size.height, isExpanded, needShowMore)();

	const showMoreText = isExpanded ? (
		<>
			{t('Show Less')}
			<ExpandLess />
		</>
	) : (
		<>
			{t('Show More')}
			<ExpandMoreIcon />
		</>
	);

	return (
		<div className={clsx(className, classes.root)}>
			<div ref={squareRef}>{children}</div>
			{needShowMore ? (
				<>
					<div
						className={clsx(
							classes.whiteGradient,
							isExpanded && classes.displayNone,
						)}
					/>
					<Grid
						container
						className={classes.buttonDiv}
						justifyContent="flex-end">
						<Button
							className={classes.showMoreButton}
							onClick={showMoreClicked}>
							{showMoreText}
						</Button>
					</Grid>
				</>
			) : null}
		</div>
	);
};

export default ShowMoreOverlay;
