import {
	InfoCard,
	riskGradePalette,
	useSettings,
	useCompanyDateTime,
} from '@innovyze/stylovyze';
import {
	selectAssetRiskHistory,
	selectAssetRiskHistoryIsReady,
} from '@Selectors/assetRiskHistory.selectors';
import React, { useEffect, useMemo, useState } from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { AssetRiskHistory } from '@Types/asset.types';
import { CircularProgress, Grid, Paper, Typography } from '@mui/material';
import { NoteIcon } from './NoteIcon.component';
import { ShowMoreOverlay } from '@Components';
import { formatDateTimeWithFormatDate } from '@Utils';
import { getTimeDifferenceText } from '@Utilsluxon';
import { useGlobalization } from '@Translations';
import TimelineOppositeContent, {
	timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import { Chart, ChartingErrorBoundary } from '@innovyze/summaryze';
import Highcharts from 'highcharts';
import { ChartContainer } from './Trend.styles';
import { selectAssetRisk } from '@Selectors/assetRisk.selectors';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const Trend = (): JSX.Element => {
	const { t } = useGlobalization();
	const { formatDate } = useCompanyDateTime();

	const { companySettings } = useSettings();

	const riskAndCondition = selectAssetRisk();
	const riskHistory = selectAssetRiskHistory();
	const isReady = selectAssetRiskHistoryIsReady();

	const sortedRiskHistory = riskHistory
		?.slice()
		.sort((x, y) => +new Date(y.MODIFIED_AT) - +new Date(x.MODIFIED_AT));

	const ChangedValueText = (value1: number, value2: number) => {
		if (value2 > value1)
			return (
				<p>
					<span>{t('Risk value changed from ')}</span>
					<b style={{ color: 'red' }}>
						{value1 + t(' to ') + value2}
					</b>
					<span>{t(' based on latest model run')}</span>
				</p>
			);
		else if (value2 === value1)
			return <p>{t('Risk value is {{value}}', { value: value1 })}</p>;
		else
			return (
				<p>
					<span>{t('Risk value changed from ')}</span>
					<b style={{ color: 'green' }}>
						{value1 + t(' to ') + value2}
					</b>
					<span>{t(' based on latest model run')}</span>
				</p>
			);
	};

	const [riskTrendSeries, setRiskTrendSeries] = useState<
		Highcharts.SeriesOptionsType[]
	>([]);
	const [maxGrade, setMaxGrade] = useState<number>(25);
	const [grades, setGrades] = useState<number[]>([2, 4, 6, 8]);

	useEffect(() => {
		if (sortedRiskHistory) {
			// This is incase they have adjusted the grade calculation in the past so all data is displayed
			let overflowMax = maxGrade;

			const data = sortedRiskHistory.map((item: AssetRiskHistory) => {
				if (item.RISK > overflowMax) overflowMax = item.RISK;

				const date = new Date(item.MODIFIED_AT);
				return [date.getTime(), item.RISK];
			});

			if (overflowMax != maxGrade) setMaxGrade(overflowMax);

			setRiskTrendSeries([
				{
					name: t('Risk Trend'),
					data: data,
					type: 'line',
					zoneAxis: 'y',
					zones: [
						{
							value: grades[0],
							color: riskGradePalette.negligible.dark,
						},
						{ value: grades[1], color: riskGradePalette.low.dark },
						{
							value: grades[2],
							color: riskGradePalette.medium.main,
						},
						{ value: grades[3], color: riskGradePalette.high.main },
						{
							color: riskGradePalette.extreme.dark,
						},
					],
				},
			]);
		}
	}, [riskHistory, grades]);

	useEffect(() => {
		if (
			riskAndCondition &&
			riskAndCondition.RISK_CONFIG &&
			riskAndCondition.RISK_CONFIG.length > 0
		) {
			let max;

			if (riskAndCondition.RISK_CONFIG[0].RiskMethod == 'multiplied') {
				max =
					Math.round(
						riskAndCondition.RISK_CONFIG[0].LOFWeighting *
							riskAndCondition.RISK_CONFIG[0].COFWeighting,
					) / 100;
			} else {
				max =
					Math.round(
						riskAndCondition.RISK_CONFIG[0].LOFWeighting +
							riskAndCondition.RISK_CONFIG[0].COFWeighting,
					) / 10;
			}

			setMaxGrade(max);

			const grades = [
				riskAndCondition.RISK_CONFIG[0].Grades.Low,
				riskAndCondition.RISK_CONFIG[0].Grades.Medium,
				riskAndCondition.RISK_CONFIG[0].Grades.High,
				riskAndCondition.RISK_CONFIG[0].Grades.Extreme,
			];

			setGrades(grades);
		}
	}, [riskAndCondition]);

	const optionsRiskTrend: Highcharts.Options = useMemo(() => {
		return {
			title: {
				text: '',
			},
			xAxis: [
				{
					type: 'datetime',
				},
			],
			yAxis: [
				{
					min: 0,
					max: maxGrade,
					title: {
						text: t('Score'),
					},
				},
			],
			plotOptions: {
				series: {
					marker: {
						enabled: true,
						radius: 4,
					},
				},
			},
			legend: { enabled: false },
			tooltip: {
				formatter: function () {
					const date = formatDateTimeWithFormatDate(
						new Date(this.x).toISOString(),
						formatDate,
					);

					return t('{{date}}</br>Risk value <b>{{risk}}</b>', {
						date: date,
						risk: this.y,
					});
				},
				split: false,
			},
			chart: {
				reflow: true,
				zoomType: 'x',
			},
		};
	}, [riskTrendSeries, maxGrade]);

	const trend =
		sortedRiskHistory && sortedRiskHistory.length > 0 ? (
			<Grid container>
				<Grid item xs={6}>
					<ShowMoreOverlay height={400}>
						<Timeline
							sx={{
								[`& .${timelineOppositeContentClasses.root}`]: {
									flex: 0.2,
								},
							}}
							style={{
								paddingLeft: '11px',
								paddingBottom: '25px',
							}}>
							{sortedRiskHistory?.map(
								(risk: AssetRiskHistory, index) => {
									return (
										<TimelineItem
											key={index}
											style={{ paddingLeft: 0 }}>
											<TimelineOppositeContent
												style={{
													flex: 0.2,
													maxWidth: '200px',
													paddingLeft: 0,
													paddingTop: '28px',
												}}>
												<Typography
													variant="body1"
													color="textPrimary">
													{formatDateTimeWithFormatDate(
														risk.MODIFIED_AT,
														formatDate,
													)}
												</Typography>
												<Typography
													style={{
														fontSize: '12px',
														lineHeight: '16px',
													}}
													color="textSecondary">
													{getTimeDifferenceText(
														new Date(
															risk.MODIFIED_AT,
														),
														companySettings,
													)}
												</Typography>
											</TimelineOppositeContent>
											<TimelineSeparator
												style={
													index === 0
														? {
																paddingTop:
																	'27px',
														  }
														: index ===
														  sortedRiskHistory.length -
																1
														? {
																paddingBottom:
																	'27px',
														  }
														: {}
												}>
												{index === 0 ? null : (
													<TimelineConnector />
												)}
												<TimelineDot variant="outlined" />

												{index ===
												sortedRiskHistory.length -
													1 ? null : (
													<TimelineConnector />
												)}
											</TimelineSeparator>
											<TimelineContent>
												<Paper
													square
													style={{
														// borderLeft:
														// 	'6px solid #0071a4',
														padding: '0px 10px',
														height: '70px',
													}}>
													<Grid
														container
														style={{
															height: '100%',
														}}>
														<Grid
															item
															style={{
																margin: '0 12px 0 2px',
															}}>
															<Grid
																container
																direction="column"
																justifyContent="center"
																alignItems="center"
																style={{
																	height: '100%',
																}}>
																<NoteIcon />
															</Grid>
														</Grid>
														<Grid
															xs={10}
															item
															container
															alignItems="center">
															{index ===
																sortedRiskHistory.length -
																	1 ||
															sortedRiskHistory.length ===
																1
																? t(
																		'Risk value is {{value}}',
																		{
																			value: risk.RISK,
																		},
																  )
																: ChangedValueText(
																		sortedRiskHistory[
																			index +
																				1
																		].RISK,
																		risk.RISK,
																  )}
														</Grid>
													</Grid>
												</Paper>
											</TimelineContent>
										</TimelineItem>
									);
								},
							)}
						</Timeline>
					</ShowMoreOverlay>
				</Grid>
				<Grid item xs={6}>
					<ChartingErrorBoundary chartProps={optionsRiskTrend}>
						<ChartContainer>
							<Chart
								cy={'risk-trend'}
								dataCy={'risk-trend'}
								series={riskTrendSeries}
								options={optionsRiskTrend}
							/>
						</ChartContainer>
					</ChartingErrorBoundary>
				</Grid>
			</Grid>
		) : (
			<div />
		);

	return (
		<InfoCard fullHeight title={t('Trend')} applyBodyPadding={!isReady}>
			{isReady ? trend : <CircularProgress />}
		</InfoCard>
	);
};
