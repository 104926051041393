import React from 'react';
import {
	FormatDateOptions,
	InfoCard,
	InputText,
	InputUnit,
	StylovyzeForm,
	useTzDateTime,
} from '@innovyze/stylovyze';
import { Grid } from '@mui/material';
import { UserDefined, UserDefinedTable } from '@Types/index';
import {
	formatDateTimeWithFormatDate,
	formatDateWithFormatDate,
	formatTimeWithFormatDate,
} from '@Utils';
import { noop } from 'lodash';

interface Props {
	userDefinedFields?: UserDefined;
	userDefinedFieldUnits?: UserDefined;
}

const SYSTEM_FIELD_NAMES = ['uploadId', 'tableName'];

const convertDataForForm = (
	userDefinedFields: UserDefinedTable,
	formatDate: (options: FormatDateOptions) => string,
): UserDefinedTable => {
	let updated = {};
	Object.keys(userDefinedFields).forEach(fieldName => {
		let value;
		const field = userDefinedFields[fieldName];

		if (typeof field === 'object') {
			if (field.type === 'date') {
				// Years are also just marked as date :(
				if (field.displayValue.length === 4) value = field.displayValue;
				else
					value = formatDateWithFormatDate(
						field.value.toString(),
						formatDate,
					);
			} else if (field.type === 'datetime') {
				value = formatDateTimeWithFormatDate(
					field.value.toString(),
					formatDate,
				);
			} else if (field.type === 'time') {
				value = formatTimeWithFormatDate(
					field.value.toString(),
					formatDate,
				);
			} else {
				value = field.displayValue;
			}
		} else {
			value = field;
		}

		updated = {
			...updated,
			[fieldName]: value,
		};
	});
	return updated;
};

export const CustomTables = ({
	userDefinedFields,
	userDefinedFieldUnits,
}: Props): JSX.Element => {
	const formatDateUTC = useTzDateTime({ timeZone: 'UTC' }).formatDate;

	if (!userDefinedFields) {
		return <></>;
	}

	const renderSectionFields = (
		importName: string,
		fields: UserDefinedTable,
	) => {
		return Object.keys(fields)
			.filter(fieldName => !SYSTEM_FIELD_NAMES.includes(fieldName))
			.map(fieldName => {
				const unit = userDefinedFieldUnits?.[importName]?.[fieldName];

				if (unit) {
					return (
						<Grid item xs={3} key={fieldName}>
							<InputUnit
								fieldAttrs={{ name: fieldName }}
								unitField={{
									label: fieldName,
									unit: unit as string,
									defaultValue: '',
									onChange: noop,
								}}
								invalidDisplayValue={'-'}
							/>
						</Grid>
					);
				}

				return (
					<Grid item xs={3} key={fieldName}>
						<InputText
							fieldAttrs={{ name: fieldName }}
							textField={{ label: fieldName }}
						/>
					</Grid>
				);
			});
	};

	const renderSection = (importName: string, index: number) => {
		const table = convertDataForForm(
			userDefinedFields[importName],
			formatDateUTC,
		);

		return (
			<StylovyzeForm
				key={index}
				mode="view"
				initialValues={table}
				onSubmit={noop}>
				{() => {
					return (
						<InfoCard fullHeight title={importName}>
							<Grid container>
								{renderSectionFields(importName, table)}
							</Grid>
						</InfoCard>
					);
				}}
			</StylovyzeForm>
		);
	};

	const sections = Object.keys(userDefinedFields).map(renderSection);

	return <>{sections}</>;
};
