import {
  ConnectedGaugeSeries,
  GaugeSeries,
} from '../../../core/types/gauge.types';

export const generateGaugeSeries = (
  gaugeSeries: ConnectedGaugeSeries
): GaugeSeries => {
  const { sensor, resolution } = gaugeSeries;
  const series: GaugeSeries = {
    ...gaugeSeries,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dataSource: { sensorId: sensor.id, resolution },
  };

  return series;
};
