import styled from 'styled-components';
import { StylovyzeTable } from '@innovyze/stylovyze';

export const TableWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const TableContainer = styled.div`
  flex: 1;
  min-height: 0;
  overflow: hidden;
`;

export const DashletTable = styled(StylovyzeTable)`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
