import { MeasureDataReducerChart, Resolutions } from '..';

export interface abstractSeries {
  [prop: string]: unknown;
  id: string;
  resolution: Resolutions;
}

export const mapReduce = (
  series: abstractSeries[],
  fullSensorMap: MeasureDataReducerChart
): MeasureDataReducerChart => {
  const sensorMap = series.reduce<MeasureDataReducerChart>((acc, sensor) => {
    if (fullSensorMap[sensor.id]?.[sensor.resolution] !== undefined) {
      const sensorValue = fullSensorMap[sensor.id];
      return {
        ...acc,
        [sensor.id]: {
          ...sensorValue,
          [sensor.resolution]: sensorValue[sensor.resolution],
        },
      };
    }
    return { ...acc };
  }, {});

  return sensorMap;
};
