import { FieldType } from '@Types/map.types';
import { GridColDef } from '@mui/x-data-grid';

export const valueGetter = (
	fieldType: FieldType,
): GridColDef['valueGetter'] => {
	if (fieldType === FieldType.Date) {
		return ({ value }) => {
			if (value instanceof Date || typeof value === 'string') {
				return new Date(value);
			}

			if (typeof value === 'object' && value !== null) {
				return value.value && new Date(value.value);
			}

			return null;
		};
	}
};

export const valueFormatter = (
	fieldType: FieldType,
	unit?: string,
): GridColDef['valueFormatter'] => {
	if (unit || fieldType === FieldType.Date) {
		return ({ value }) => value;
	}

	return ({ value }) => value ?? '-';
};
