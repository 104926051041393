/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  useIsFeatureEnabled,
  useSelectSensors,
  useSettings,
} from '@innovyze/stylovyze';
import * as InsightChart from '../../core/_insight-chart';
import * as math from 'mathjs';
import * as PumpPerformanceChart from '../../modules/pump-performance-chart';
import * as React from 'react';
import * as SeriesData from '../../core/series-data';
import * as TimeSeriesData from '../../core/time-series-data';
import * as TimeSeriesDataOld from '../../core/time-series-data-old';
import { fixCollectionInterval } from '../../core/time-series-data/utils';
import { makeSources } from './insight-pump-performance-chart.sources';

/**
 * Some of the units we use in the system are not supported by math
 * and cannot be directly aliased with `createUnit` since they contain
 * special characters. This map is used to manually pick a valid alias
 * name.
 */
const UNIT_MAP: Record<string, string> = {
  'ft3/m': 'ft3m',
  'L/m': 'Lmin',
  'kg/cm2': 'kgcm2',
  ft: 'ftOfHead',
  m: 'mOfHead',
};

type EdgeSource = {
  sensorId: string;
  category: string;
  resolution: TimeSeriesDataOld.Resolution;
  reading: TimeSeriesDataOld.Reading;
};

function edgeSourceStringifier(source: EdgeSource): string {
  return `${source.category}:${source.sensorId}:${source.resolution}:${source.reading}`;
}

function limit(value: string | null | undefined, defaultValue = 500): number {
  if (typeof value === 'string' || value === null) return defaultValue;
  if (isNaN(Number(value))) return defaultValue;
  return Number(value);
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Chart Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

interface InsightPumpPerformanceChartProps
  extends Omit<PumpPerformanceChart.PumpPerformanceChartRootProps, 'children'> {
  series: InsightPumpPerformanceChartSeriesProps[];
  timeRangeSelection?: InsightChart.TimeRangeSelection;
}

const InsightPumpPerformanceChart = React.forwardRef<
  { chart: Highcharts.Chart | undefined },
  InsightPumpPerformanceChartProps
>((props, ref): React.ReactElement => {
  const { companySettings } = useSettings();
  const { sensors, initialized: sensorsInitialized } = useSelectSensors();
  const dataLimit = useIsFeatureEnabled(
    'info-360-analytics-hp2-charts-ppc-limit'
  );

  const sources = React.useMemo(
    () => makeSources(props.series),
    [props.series]
  );

  const relevantSeries = props.series.filter(
    (series): series is InsightPumpPerformanceChartScatterSeriesProps =>
      series.type === 'scatter'
  );

  const targetXSeries = relevantSeries[0];

  const targetYPressureSeries = relevantSeries.find((series) =>
    ['pressure', 'pressure-head'].includes(series.subType)
  );

  const targetYHeadSeries = relevantSeries.find((series) =>
    ['head'].includes(series.subType)
  );

  const targetYPowerSeries = relevantSeries.find(
    (series) => series.subType === 'power'
  );

  const seriesData = TimeSeriesDataOld.useRetriever(sources);

  const [edgeSources] = SeriesData.useSources<EdgeSource>(() => {
    if (!props.series) return;
    const _sources = makeSources(props.series, true);
    return _sources.map((s) => ({
      sensorId: s.sensorId,
      resolution: s.resolution ?? '15-MINUTE',
      reading: s.reading ?? 'Close',
      category: s.category,
    }));
  }, [props.series]);

  const [edgeData, edgeStatus, retrieveEdgeData] = SeriesData.useRetriever<
    Map<string, { data: Map<number, number>; unit: string | null }>,
    { timeSelection?: TimeSeriesData.PartialTimeSelection }
  >(
    async (signal, params) => {
      if (!edgeSources?.length || !sensorsInitialized) return;

      const timeSelection = {
        from: params.timeSelection?.from ?? 'oldest',
        to: params.timeSelection?.to ?? 'latest',
      } as TimeSeriesData.TimeSelection;

      const response = await TimeSeriesData.retrieve(signal, {
        order: 'asc',
        timeSelection,
        limit: limit(dataLimit),
        timeZone: companySettings.timeZoneIANA,
        data_version: 'v3.0',
        snapping: 'oldest',
        sources: edgeSources.map((s) => {
          const _sensor = sensors.find((_s) => _s.sensorId === s.sensorId);
          const seconds = fixCollectionInterval(_sensor?.collectionInterval);

          return {
            key: edgeSourceStringifier(s),
            sensorId: s.sensorId,
            collectionInterval: { seconds },
            analytic: TimeSeriesData.makeAnalytic(
              s.resolution,
              s.reading ?? 'Close'
            ),
          };
        }),
      });

      const dataMap: Map<
        string,
        { data: Map<number, number>; unit: string | null }
      > = new Map();

      for (const s of edgeSources) {
        const key = edgeSourceStringifier(s);
        const result = response?.data?.results[key];
        const m = new Map(result?.data.map(([t, v]) => [t, v]));
        dataMap.set(key, { data: m, unit: result.unit });
      }

      return dataMap;
    },
    [
      companySettings.timeZoneIANA,
      dataLimit,
      edgeSources,
      sensors,
      sensorsInitialized,
    ]
  );

  // Handles data fetching with edge analytics' API
  React.useEffect(() => {
    if (!sensorsInitialized) return;

    retrieveEdgeData({
      timeSelection: {
        from: props.timeRangeSelection?.min,
        to: props.timeRangeSelection?.max,
      },
    });
  }, [
    sensorsInitialized,
    props.timeRangeSelection?.max,
    props.timeRangeSelection?.min,
    retrieveEdgeData,
  ]);

  const seriesDataStatus = React.useMemo(() => {
    for (const series of props.series) {
      if (series.type === 'scatter' && series.baseSource.customData) {
        return 'resolved';
      }
    }
    return edgeStatus;
  }, [edgeStatus, props.series]);

  return (
    <PumpPerformanceChart.PumpPerformanceChartRoot {...props} ref={ref}>
      <PumpPerformanceChart.PumpPerformanceChartSeriesGroup
        status={seriesDataStatus}>
        {props.series.map((seriesProps, seriesIndex) => {
          if (seriesProps.type === 'manufacturer-curve') {
            return (
              <InsightPumpPerformanceChartManufacturerCurveSeries
                {...seriesProps}
                key={'manufacturer-curve-' + seriesIndex}
                status={seriesDataStatus}
                seriesData={seriesData}
                edgeData={edgeData}
                targetXSeries={targetXSeries}
                targetYPressureSeries={targetYPressureSeries}
                targetYPowerSeries={targetYPowerSeries}
              />
            );
          } else if (seriesProps.type === 'scatter') {
            return (
              <InsightPumpPerformanceChartScatterSeries
                {...seriesProps}
                key={'scatter-' + seriesIndex}
                seriesData={seriesData}
                edgeData={edgeData}
                series={props.series}
              />
            );
          } else if (seriesProps.type === 'design-point') {
            return (
              <InsightPumpPerformanceChartDesignPointSeries
                {...seriesProps}
                key={'design-point-' + seriesIndex}
                edgeData={edgeData}
                targetXSeries={targetXSeries}
                targetYPressureSeries={targetYPressureSeries}
                targetYHeadSeries={targetYHeadSeries}
              />
            );
          }
        })}
      </PumpPerformanceChart.PumpPerformanceChartSeriesGroup>
    </PumpPerformanceChart.PumpPerformanceChartRoot>
  );
});

InsightPumpPerformanceChart.displayName = 'InsightPumpPerformanceChart';

type InsightPumpPerformanceChartSeriesProps =
  | InsightPumpPerformanceChartScatterSeriesProps
  | InsightPumpPerformanceChartManufacturerCurveSeriesProps
  | InsightPumpPerformanceChartStatusFilter
  | InsightPumpPerformanceChartDesignPoint;

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Scatter Series Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

type InsightPumpPerformanceChartScatterSeriesProps =
  PumpPerformanceChart.PumpPerformanceChartScatterSeriesProps & {
    edge?: boolean;
    seriesData?: ReturnType<typeof TimeSeriesDataOld.useRetriever>;
    edgeData?: Map<string, { data: Map<number, number>; unit: string | null }>;
    series: InsightPumpPerformanceChartSeriesProps[];
    downstreamCorrection?: number;
    upstreamCorrection?: number;
    baseSource: {
      sensorId: string;
      resolution: InsightChart.Resolution;
      reading?: InsightChart.Reading;
      customData?: TimeSeriesDataOld.ResponseDataEntry;
    };
    downstreamSource: {
      sensorId: string;
      resolution: InsightChart.Resolution;
      reading?: InsightChart.Reading;
      customData?: TimeSeriesDataOld.ResponseDataEntry;
    };
    upstreamSource?: {
      sensorId: string;
      resolution: InsightChart.Resolution;
      reading?: InsightChart.Reading;
      customData?: TimeSeriesDataOld.ResponseDataEntry;
    };
    pumpFiltersDataEntry?: (InsightChart.SensorDataEntry & {
      filterStatus: boolean;
    })[];
  };

const InsightPumpPerformanceChartScatterSeries = (
  props: InsightPumpPerformanceChartScatterSeriesProps
): React.ReactElement => {
  const makeMap = (
    data: [timestamp: number, ...values: number[]][]
  ): TimeSeriesDataOld.TimeSeriesDataMap => {
    const map = new Map();
    data.forEach((item) => {
      map.set(item[0], item[1]);
    });
    return map;
  };

  const [baseDataMap, baseUnit] = React.useMemo((): [
    TimeSeriesDataOld.TimeSeriesDataMap | undefined,
    string | null | undefined,
  ] => {
    const { sensorId, resolution, customData } = props.baseSource;
    const { reading = 'Close' } = props.baseSource;
    if (customData) {
      return [makeMap(customData.data), null];
    } else {
      const s = { category: 'Base', sensorId, resolution, reading };
      const k = edgeSourceStringifier(s);
      const e = props.edgeData?.get(k);
      return [e?.data, e?.unit];
    }
  }, [props.baseSource, props.edgeData]);

  const [downstreamDataMap, downstreamUnit] = React.useMemo((): [
    TimeSeriesDataOld.TimeSeriesDataMap | undefined,
    string | null | undefined,
  ] => {
    const { sensorId, resolution, customData } = props.downstreamSource;
    const { reading = 'Close' } = props.downstreamSource;
    if (customData) {
      return [makeMap(customData.data), null];
    } else {
      const s = { category: 'Down', sensorId, resolution, reading };
      const k = edgeSourceStringifier(s);
      const e = props.edgeData?.get(k);
      return [e?.data, e?.unit];
    }
  }, [props.downstreamSource, props.edgeData]);

  const upstreamDataMap = React.useMemo(():
    | TimeSeriesDataOld.TimeSeriesDataMap
    | undefined => {
    if (!props.upstreamSource) return;

    const { sensorId, resolution, customData } = props.upstreamSource;
    const { reading = 'Close' } = props.upstreamSource;
    if (customData) {
      return makeMap(customData.data);
    } else {
      const s = { category: 'Up', sensorId, resolution, reading };
      const k = edgeSourceStringifier(s);
      return props.edgeData?.get(k)?.data;
    }
  }, [props.edgeData, props.upstreamSource]);

  const filterSeries = React.useMemo(() => {
    return props.series.filter(
      (s): s is InsightPumpPerformanceChartStatusFilter => {
        return s.type === 'pump-filter';
      }
    );
  }, [props.series]);

  const filtersDataMap = React.useMemo(() => {
    const map: WeakMap<
      InsightPumpPerformanceChartStatusFilter,
      TimeSeriesDataOld.TimeSeriesDataMap
    > = new WeakMap();

    if (filterSeries === undefined || filterSeries.length === 0) {
      return undefined;
    }

    for (const filter of filterSeries) {
      const { sensorId, resolution, reading } = filter;

      const s = { category: 'Filter', sensorId, resolution, reading };
      const k = edgeSourceStringifier(s);
      const d = props.edgeData?.get(k)?.data;
      if (d) map.set(filter, d);
    }

    return map;
  }, [filterSeries, props.edgeData]);

  const processedData = React.useMemo(() => {
    const d: PumpPerformanceChart.PumpPerformanceChartScatterSeriesData = [];
    if (
      baseDataMap !== undefined &&
      baseDataMap.size > 0 &&
      downstreamDataMap !== undefined &&
      downstreamDataMap.size > 0
    ) {
      for (const [baseTimestamp, baseValue] of baseDataMap) {
        const downstreamValue = downstreamDataMap.get(baseTimestamp);
        const upstreamValue = upstreamDataMap?.get(baseTimestamp);

        if (
          baseValue !== null &&
          downstreamValue !== undefined &&
          downstreamValue !== null
        ) {
          let y = downstreamValue - (props.downstreamCorrection || 0);

          if (
            props.subType !== 'power' &&
            upstreamValue !== undefined &&
            upstreamValue !== null
          ) {
            y -= upstreamValue - (props.upstreamCorrection || 0);
          }

          const isVisible: boolean[] = [];
          if (filterSeries !== undefined && filterSeries.length > 0) {
            for (const filter of filterSeries) {
              const filterValue = filtersDataMap
                ?.get(filter)
                ?.get(baseTimestamp);
              if (filterValue !== undefined && filterValue !== null) {
                // if filter is ON/TRUE should show 1's and hide 0's
                // if filter is OFF/FALSE should show 0's and hide 1's
                const visibleOnCurrent =
                  filter.status === !!filterValue ? true : false;
                isVisible.push(visibleOnCurrent);
              } else {
                //if no value then point should not be visible
                isVisible.push(false);
              }
            }
          }

          if (
            isVisible.length === 0 ||
            //makes an AND to all the showable and no showable filter  values
            isVisible.every((val) => val === true)
          ) {
            d.push({ timestamp: baseTimestamp, x: baseValue, y });
          }
        }
      }
    }

    return d;
  }, [
    props.subType,
    props.downstreamCorrection,
    props.upstreamCorrection,
    filterSeries,
    filtersDataMap,
    baseDataMap,
    downstreamDataMap,
    upstreamDataMap,
  ]);

  return (
    <PumpPerformanceChart.PumpPerformanceChartSeries
      {...props}
      type="scatter"
      data={processedData}
      xAxisUnit={baseUnit}
      yAxisUnit={downstreamUnit}
    />
  );
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Manufacturer Curve Series Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

type InsightPumpPerformanceChartManufacturerCurveSeriesProps =
  PumpPerformanceChart.PumpPerformanceChartManufacturerCurveSeriesProps & {
    edgeData?: Map<
      string,
      {
        data: Map<number, number>;
        unit: string | null;
      }
    >;
    seriesData?: ReturnType<typeof TimeSeriesDataOld.useRetriever>;
    targetXSeries?:
      | InsightPumpPerformanceChartScatterSeriesProps
      | InsightPumpPerformanceChartManufacturerCurveSeriesProps;
    targetYPressureSeries?:
      | InsightPumpPerformanceChartScatterSeriesProps
      | InsightPumpPerformanceChartManufacturerCurveSeriesProps;
    targetYPowerSeries?:
      | InsightPumpPerformanceChartScatterSeriesProps
      | InsightPumpPerformanceChartManufacturerCurveSeriesProps;
  };

const InsightPumpPerformanceChartManufacturerCurveSeries = (
  props: InsightPumpPerformanceChartManufacturerCurveSeriesProps
): React.ReactElement => {
  const curveXUnit = props.xSeriesUnit;
  const curveYUnit = props.ySeriesUnit;

  const targetXUnit = React.useMemo(() => {
    let unit: string | undefined = undefined;
    const target = props.targetXSeries;

    if (target?.type === 'manufacturer-curve') {
      unit = target.xSeriesUnit || undefined;
    } else if (target?.type === 'scatter') {
      const { sensorId, resolution, reading = 'Close' } = target.baseSource;
      const s = { category: 'Base', sensorId, resolution, reading };
      const k = edgeSourceStringifier(s);
      const e = props.edgeData?.get(k);
      unit = e?.unit || undefined;
    }
    return unit;
  }, [props.targetXSeries, props.edgeData]);

  const targetYUnit = React.useMemo(() => {
    let unit: string | undefined = undefined;
    const target =
      props.subType === 'pressure'
        ? props.targetYPressureSeries
        : props.targetYPowerSeries;

    if (target?.type === 'manufacturer-curve') {
      unit = target.ySeriesUnit || undefined;
    } else if (target?.type === 'scatter') {
      const {
        sensorId,
        resolution,
        reading = 'Close',
      } = target.downstreamSource;
      const s = { category: 'Down', sensorId, resolution, reading };
      const k = edgeSourceStringifier(s);
      const e = props.edgeData?.get(k);
      unit = e?.unit || undefined;
    }
    return unit;
  }, [
    props.subType,
    props.targetYPressureSeries,
    props.targetYPowerSeries,
    props.edgeData,
  ]);

  const shouldConvertXUnit = !!(
    targetXUnit &&
    curveXUnit &&
    curveXUnit !== targetXUnit
  );

  const shouldConvertYUnit = !!(
    targetYUnit &&
    curveYUnit &&
    curveYUnit !== targetYUnit
  );

  const data = React.useMemo(() => {
    if (props.status === 'resolved') {
      if (shouldConvertXUnit || shouldConvertYUnit) {
        return props.data?.map(
          (d: { x: number; y: number | null } | [number, number | null]) => {
            let _x = Array.isArray(d) ? d[0] : d.x;
            let _y = Array.isArray(d) ? d[1] : d.y;

            if (shouldConvertXUnit) {
              try {
                const _curveXUnit = UNIT_MAP[curveXUnit]
                  ? UNIT_MAP[curveXUnit]
                  : curveXUnit;

                const _targetXUnit = UNIT_MAP[targetXUnit]
                  ? UNIT_MAP[targetXUnit]
                  : targetXUnit;

                _x = math.unit(_x, _curveXUnit).toNumber(_targetXUnit);
              } catch (error) {
                console.error('Failed to convert units.', error);
              }
            }

            if (
              props.subType !== 'efficiency' &&
              props.subType !== 'head' &&
              shouldConvertYUnit &&
              _y !== undefined &&
              _y !== null
            ) {
              try {
                const _curveYUnit = UNIT_MAP[curveYUnit]
                  ? UNIT_MAP[curveYUnit]
                  : curveYUnit;

                const _targetYUnit = UNIT_MAP[targetYUnit]
                  ? UNIT_MAP[targetYUnit]
                  : targetYUnit;

                _y = math.unit(_y, _curveYUnit).toNumber(_targetYUnit);
              } catch (error) {
                console.error('Failed to convert units.', error);
              }
            }
            return [_x, _y] as [number, number | null];
          }
        );
      }

      return props.data;
    }
  }, [
    curveXUnit,
    curveYUnit,
    props.data,
    props.status,
    props.subType,
    shouldConvertXUnit,
    shouldConvertYUnit,
    targetXUnit,
    targetYUnit,
  ]);

  return (
    <PumpPerformanceChart.PumpPerformanceChartSeries
      {...props}
      type="manufacturer-curve"
      data={data}
      xSeriesUnit={
        props.status === 'resolved'
          ? shouldConvertXUnit
            ? targetXUnit
            : curveXUnit
          : undefined
      }
      ySeriesUnit={
        props.status === 'resolved'
          ? shouldConvertYUnit
            ? targetYUnit
            : curveYUnit
          : undefined
      }
    />
  );
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Design Point Series Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

type InsightPumpPerformanceChartDesignPoint = {
  type: 'design-point';
  flowRate?: number;
  flowRateUnit?: string;
  pressure?: number;
  pressureUnit?: string;
  efficiency?: number;
  efficiencyUnit?: string;
  brakeHP?: number;
  brakeHPUnit?: string;
  primarySeries: InsightPumpPerformanceChartScatterSeriesProps;
  edgeData?: Map<
    string,
    {
      data: Map<number, number>;
      unit: string | null;
    }
  >;
  targetXSeries?: InsightPumpPerformanceChartScatterSeriesProps;
  targetYPressureSeries?: InsightPumpPerformanceChartScatterSeriesProps;
  targetYHeadSeries?: InsightPumpPerformanceChartScatterSeriesProps;
};

const InsightPumpPerformanceChartDesignPointSeries = (
  props: InsightPumpPerformanceChartDesignPoint
): React.ReactElement => {
  const designPointXUnit = props.flowRateUnit;
  const designPointYUnit = props.pressureUnit;

  const chartXUnit = React.useMemo(() => {
    const {
      sensorId,
      resolution,
      reading = 'Close',
    } = props.targetXSeries.baseSource;
    const s = { category: 'Base', sensorId, resolution, reading };
    const k = edgeSourceStringifier(s);
    const e = props.edgeData?.get(k);
    const unit = e?.unit || designPointXUnit;
    return unit;
  }, [props.targetXSeries, props.edgeData, designPointXUnit]);

  const targetYSeries = props.targetYPressureSeries ?? props.targetYHeadSeries; //when user selects "Display as: Head", the targetYPressureSeries is undefined

  const chartYUnit = React.useMemo(() => {
    const downstreamSource = targetYSeries.downstreamSource;
    const { sensorId, resolution, reading = 'Close' } = downstreamSource;
    const s = { category: 'Down', sensorId, resolution, reading };
    const k = edgeSourceStringifier(s);
    const e = props.edgeData?.get(k);
    const unit = e?.unit || designPointYUnit;
    return unit;
  }, [targetYSeries, props.edgeData, designPointYUnit]);

  const chartXValue = React.useMemo(() => {
    let value = props.flowRate;
    const shouldConvertXUnit = designPointXUnit !== chartXUnit;
    if (shouldConvertXUnit) {
      try {
        const _designPointXUnit =
          UNIT_MAP[designPointXUnit] ?? designPointXUnit;
        const _chartXUnit = UNIT_MAP[chartXUnit] ?? chartXUnit;
        value = math.unit(value, _designPointXUnit).toNumber(_chartXUnit);
      } catch (error) {
        console.error('Failed to convert units.', error);
      }
    }
    return value;
  }, [designPointXUnit, props.flowRate, chartXUnit]);

  const chartYValue = React.useMemo(() => {
    let value = props.pressure;
    const shouldConvertYUnit = designPointYUnit !== chartYUnit;
    if (shouldConvertYUnit) {
      try {
        const _designPointYUnit =
          UNIT_MAP[designPointYUnit] ?? designPointYUnit;
        const _chartYUnit = UNIT_MAP[chartYUnit] ?? chartYUnit;
        value = math.unit(value, _designPointYUnit).toNumber(_chartYUnit);
      } catch (error) {
        console.error('Failed to convert units.', error);
      }
    }
    return value;
  }, [designPointYUnit, props.pressure, chartYUnit]);

  return (
    <PumpPerformanceChart.PumpPerformanceChartSeries
      {...props}
      chartXValue={chartXValue}
      chartXUnit={chartXUnit}
      chartYValue={chartYValue}
      chartYUnit={chartYUnit}
    />
  );
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

type InsightPumpPerformanceChartStatusFilter = {
  type: 'pump-filter';
  resolution: InsightChart.Resolution;
  reading?: InsightChart.Reading;
  data?: InsightChart.SensorDataEntry;
  error?: unknown;
  sensorId: string;
  status: boolean;
};

export { InsightPumpPerformanceChart };

export type {
  InsightPumpPerformanceChartProps,
  InsightPumpPerformanceChartScatterSeriesProps,
  InsightPumpPerformanceChartManufacturerCurveSeriesProps,
  InsightPumpPerformanceChartSeriesProps,
  InsightPumpPerformanceChartStatusFilter,
  InsightPumpPerformanceChartDesignPoint,
};
