import {
	Environments,
	getApiEnvironment,
	getService,
} from '@innovyze/stylovyze';

import axios from 'axios';
import config from './config.api';
import cookies from 'browser-cookies';
import { formatUrlTrailing } from '@Utils/urlFormatting';

export const baseTilesUrl = getService('tiles', config, getApiEnvironment());

const options = {
	baseURL: baseTilesUrl,
	timeout: 3000,
};

const tiles = axios.create(options);

tiles.interceptors.request.use(
	conf => {
		conf.headers.set(
			'Authorization',
			'Bearer ' + cookies.get('auth0.user.token'),
		);
		return conf;
	},
	error => {
		Promise.reject(error);
	},
);

export default tiles;

const apiEnv =
	(localStorage.getItem('apiEnv') as keyof typeof Environments) || undefined;

export const tilesBaseUrl = formatUrlTrailing(
	getService('tiles', config, apiEnv),
);
