import { AnyAction, createReducer } from '@reduxjs/toolkit';
import { clearInspections, getInspectionsResolved } from '@Actions/inspection';

import { InspectionStoreState } from '@Types';

export const initialState: InspectionStoreState = {
	inspectionAttributes: {},
};

interface InspectionReducer {
	[x: string]: (
		state: InspectionStoreState,
		action: AnyAction,
	) => InspectionStoreState | undefined;
}

const reducer: InspectionReducer = {
	[getInspectionsResolved.toString()]: (state, action) => ({
		...state,
		inspectionAttributes: {
			...state.inspectionAttributes,
			[action.payload.assetId]: action.payload.inspections,
		},
	}),
	[clearInspections.toString()]: () => ({
		...initialState,
	}),
};

export default createReducer(initialState, reducer);
