import { InspectionResolved } from '@Types/inspection.types';
import { addNamespace } from '@Utils/actions';
import { createAction } from 'redux-actions';

export const getInspections = createAction(
	addNamespace('inspection/getInspections'),
);
export const getInspectionsResolved = createAction<InspectionResolved>(
	addNamespace('inspection/getInspections/resolved'),
);
export const getInspectionsRejected = createAction(
	addNamespace('inspection/getInspections/rejected'),
);

export const clearInspections = createAction(
	addNamespace('inspection/clearInspections'),
);
