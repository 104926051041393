import { NamespacedStoreState } from '@Types';
import { moduleNamespace } from '@Store/config.store';
import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

const parseNamespace = (state: NamespacedStoreState) => state[moduleNamespace];

const rehabStoreSelector = createSelector(
	parseNamespace,
	state => state.RehabStore,
);

const treeSelector = createSelector(
	rehabStoreSelector,
	state => state.rehabTrees,
);

const rehabResultSelector = createSelector(
	rehabStoreSelector,
	state => state.rehabDetails,
);

const isTreeLoadingSelector = createSelector(rehabStoreSelector, state => {
	if (state.isDetailLoading) return true;

	const trees = state.rehabTrees;
	if (trees == undefined || trees.length == 0) return false;

	const ranTree = trees.filter(tree => tree.lastRun != undefined);
	if (ranTree.length == 0) return false;

	const results = state.rehabDetails;
	const loadingResults = ranTree.filter(tree => {
		if (results[tree._id]) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const result: any = results[tree._id];
			if (result?.loading) return true;
		} else return false;
	});
	if (loadingResults.length > 0) return true;

	return false;
});

const hasValidTreeSelector = createSelector(rehabStoreSelector, state => {
	if (state.isDetailLoading) return false;

	const trees = state.rehabTrees;
	if (trees == undefined || trees.length == 0) return false;

	const ranTree = trees.filter(tree => tree.lastRun != undefined);
	if (ranTree.length == 0) return false;

	const results = state.rehabDetails;
	const validResults = ranTree.filter(tree => {
		if (results[tree._id]) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const result: any = results[tree._id];
			if (result?.loading != undefined && result.loading == false) {
				if (result.rows.length > 0) return true;
			}
			return false;
		}
	});
	if (validResults.length > 0) return true;

	return false;
});
export const selectIsTreesLoading = () => useSelector(isTreeLoadingSelector);
export const selectRehabTrees = () => useSelector(treeSelector);

export const selectRehabResults = () => useSelector(rehabResultSelector);
export const selectHasValidTree = () => useSelector(hasValidTreeSelector);

export default [selectRehabTrees, selectRehabResults];
