import { RangeSelection, HiddenSeries, GroupByValue } from '../../../types';
import Highcharts, { Point } from 'highcharts/highstock';
import React, { forwardRef } from 'react';
import type { ChartProps } from '../../../core/types/chart.types';

import {
  ComparisonDisplayOptions,
  ComparisonReferenceSeries,
  ComparisonSeries,
} from '../../../core/types/comparison.types';
import useRangeSelection from '../../../core/hooks/useRangeSelection';
import StackableChart, {
  StackableChartRef,
} from '../../../core/components/StackableChart';
import useHiddenSeries from '../../../core/hooks/useHiddenSeries';
import useClickPoint from '../../../core/hooks/useClickPoint';
import { isFeatureEnabled } from '@innovyze/stylovyze';
import useHighchartsOptions from '../../../core/hooks/useHighchartsOptions';
import { generateComparisonOptions } from './ComparisonChart.utils';

export interface ComparisonChartProps extends ChartProps<ComparisonSeries> {
  referenceSeries?: ComparisonReferenceSeries[];
  dataRangeSelection?: RangeSelection;
  displayOptions?: ComparisonDisplayOptions;
  groupBy?: GroupByValue;
  hiddenSeries?: HiddenSeries;
  onClickPoint?: (point: Point) => void;
  onHiddenSeriesChange?: (hiddenSeries: HiddenSeries) => void;
}

const ComparisonChart = (
  props: ComparisonChartProps,
  ref?: React.Ref<StackableChartRef>
) => {
  const isBoostModuleEnabled = isFeatureEnabled(
    'info-360-analytics-boost-module'
  );

  const { rangeSelectionOptions } = useRangeSelection(
    undefined,
    props.dataRangeSelection
  );

  const { hiddenSeries, hiddenSeriesOptions } = useHiddenSeries(
    props.hiddenSeries,
    props.onHiddenSeriesChange
  );

  const { clickPointOptions } = useClickPoint(props.onClickPoint);

  const options = useHighchartsOptions(() => {
    return [
      generateComparisonOptions(
        props.data,
        !!isBoostModuleEnabled,
        props.series,
        hiddenSeries,
        props.referenceSeries,
        props.displayOptions,
        props.groupBy
      ),
      hiddenSeriesOptions,
      rangeSelectionOptions,
      clickPointOptions,
    ];
  }, [
    hiddenSeries,
    hiddenSeriesOptions,
    isBoostModuleEnabled,
    props.data,
    props.displayOptions,
    props.groupBy,
    props.series,
    props.referenceSeries,
    rangeSelectionOptions,
    clickPointOptions,
  ]);

  return (
    <StackableChart
      constructorType="stockChart"
      data={props.data}
      highcharts={Highcharts}
      options={options}
      ref={ref}
    />
  );
};

export default forwardRef<StackableChartRef, ComparisonChartProps>(
  ComparisonChart
);
