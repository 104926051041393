import type * as Highcharts from 'highcharts';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const instanceInitialOptions: Highcharts.Options = {
  chart: { height: null, zoomType: 'x' },
  title: { text: '' },
  credits: { enabled: false },
  exporting: { enabled: false },
  legend: { enabled: true },
  navigator: { enabled: false },
  rangeSelector: { enabled: false },
  scrollbar: { enabled: false },
  xAxis: { type: 'datetime', gridLineWidth: 1 },
  yAxis: {
    opposite: false,
    gridLineWidth: 1,
    title: { text: '' },
  },
  plotOptions: {
    series: { dataGrouping: { enabled: false } },
  },
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export { instanceInitialOptions };
