import {
	rehabReducer as RehabStore,
	initState as rehabStoreState,
} from './rehab.reducer';
import assetDetails, {
	initState as assetDetailState,
} from './assetDetail.reducer';
import assetManhole, {
	initState as assetManholeState,
} from './assetManhole.reducer';
import assetPipe, { initState as assetPipeState } from './assetPipe.reducer';
import assetRisk, { initState as assetRiskState } from './assetRisk.reducer';
import assetRiskHistory, {
	initState as assetRiskHistoryState,
} from './assetRiskHistory.reducer';
import charts, { initialState as chartsState } from './charts.reducer';
import configuration, {
	initialState as configurationState,
} from './configuration.reducer';
import {
	FullInspReducer as fullInspection,
	initialState as fullInspectionState,
} from './fullInspection.reducer';
import {
	initState as initialRiskSettingState,
	riskSettingReducer as riskSetting,
} from './riskSetting.reducer';
import inspections, {
	initState as inspectionsState,
} from './inspection.reducer';
import map, { initialState as initialMap } from './map.reducer';
import {
	initState as observationState,
	observationReducer as observations,
} from './observations.reducer';
import {
	riskConfigsListReducer as riskConfigsList,
	initialState as riskConfigsListState,
} from './riskConfigsList.reducer';
import {
	spatialReducer as spatial,
	spatialInitialState,
} from './spatial.reducer';

import { StoreState } from '@Types';
import { combineReducers } from 'redux';
import { moduleNamespace } from '@Store/config.store';
import { paginationInitialState } from './paginationUtils';
import { riskConfigsReducer as riskConfigs } from './riskConfigs.reducer';

export const initialState: StoreState = {
	assetRisk: assetRiskState,
	assetDetails: assetDetailState,
	assetPipe: assetPipeState,
	assetManhole: assetManholeState,
	inspections: inspectionsState,
	assetRiskHistory: assetRiskHistoryState,
	map: initialMap,
	riskSetting: initialRiskSettingState,
	riskConfigs: paginationInitialState,
	riskConfigsList: riskConfigsListState,
	fullInspection: fullInspectionState,
	observations: observationState,
	spatial: spatialInitialState,
	RehabStore: rehabStoreState,
	charts: chartsState,
	configuration: configurationState,
};

export default combineReducers({
	inspections,
	assetDetails,
	assetRisk,
	assetPipe,
	assetManhole,
	observations,
	fullInspection,
	assetRiskHistory,
	map,
	riskSetting,
	riskConfigs,
	riskConfigsList,
	spatial,
	RehabStore,
	charts,
	configuration,
});

export const reducerKey = moduleNamespace;

export * from './paginationUtils';
export * from './filterUtils';

export const reducerWhiteList = [`${moduleNamespace}.map.main`];
