/* eslint-disable no-case-declarations */
import { palette } from '@innovyze/stylovyze';
import { SeriesOptionsType } from 'highcharts/highstock';
import {
  RangeSelection,
  RawMeasureData,
  RawMeasureDataMap,
  RawMeasureDatum,
  Readings,
  Resolutions,
} from '../types';
import { PieSeries } from '../types/pieChart.types';
import { filterDataOutOfRange } from './filterDataOutOfRange';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const Rainbow = require('rainbowvis.js');

export const generatePieStockSeries = async (
  primarySeries: PieSeries[],
  reading: Readings,
  offset: Resolutions,
  dataRangeSelection?: RangeSelection
): Promise<SeriesOptionsType[]> => {
  const stockSeries = primarySeries.map((series, index) => {
    const dataInRange = series.data
      ? filterDataOutOfRange(series.data, dataRangeSelection)
      : [];

    const limit = getLimit(offset, dataInRange);

    const filterData = dataInRange.filter((measureData) => {
      const stampToDate = new Date(measureData[0]);
      if (stampToDate > limit) return measureData;
    });

    const value = pieProcessData(filterData, reading);

    return {
      name: series.alias,
      y: value,
    };
  });

  return [
    {
      name: '',
      type: 'pie',
      data: stockSeries,
    },
  ] as SeriesOptionsType[];
};

const pieProcessData = (data: RawMeasureDatum[], reading: Readings): number => {
  switch (reading) {
    case Readings.Average:
      const sumAverage = data.reduce(
        (acc, values) => acc + values[RawMeasureDataMap[Readings.Average]],
        0
      );
      return sumAverage ? sumAverage / data.length : sumAverage;
    case Readings.Close:
      if (!data.length) return 0;
      return data[data.length - 1][RawMeasureDataMap[Readings.Close]] || 0;
    case Readings.Open:
      return data[0][RawMeasureDataMap[Readings.Open]] || 0;
    case Readings.High:
      return Math.max(
        ...data.map((values) => values[RawMeasureDataMap[Readings.High]])
      );
    case Readings.Low:
      return Math.min(
        ...data.map((values) => values[RawMeasureDataMap[Readings.Low]])
      );
    case Readings.Sum:
      const sum = data.reduce(
        (acc, values) => acc + values[RawMeasureDataMap[Readings.Average]],
        0
      );
      return sum;
  }
};

export const generateColorsPieSeries = (
  primarySeries: PieSeries[]
): string[] => {
  if (primarySeries.length <= 1) return [palette.primary.main];
  const rainbow = new Rainbow();
  rainbow.setSpectrum(
    palette.primary.main,
    palette.secondary.light,
    palette.secondary.dark
  );
  rainbow.setNumberRange(0, primarySeries.length - 1);

  return primarySeries.map((_, index) => `#${rainbow.colourAt(index)}`);
};

const getLimit = (offset: Resolutions, data?: RawMeasureData): Date => {
  if (!data || !data.length) return new Date();

  const limit = new Date(data[data.length - 1][0]);
  switch (offset) {
    case Resolutions['15 Minutes']:
      limit.setMinutes(limit.getMinutes() - 15);
      break;
    case Resolutions['30 Minutes']:
      limit.setMinutes(limit.getMinutes() - 30);
      break;
    case Resolutions.Hourly:
      limit.setMinutes(limit.getMinutes() - 60);
      break;
    case Resolutions.Daily:
      limit.setDate(limit.getDate() - 1);
      break;
    case Resolutions.Weekly:
      limit.setDate(limit.getDate() - 7);
      break;
    case Resolutions.Monthly:
      limit.setDate(limit.getDate() - 30);
      break;
  }
  return limit;
};
