import * as assetActions from '@Actions/asset';

import {
	GetAssetResponse,
	GetSensorResponse,
	getAsset,
	getAssetsPaginated,
	getSensor,
	getSystemAssetTypes,
} from '@Services/asset';
import {
	Asset,
	PaginatedAssets,
	GetGridDataAction,
	PaginatedAssetsResponse,
	PaginatedResponse,
} from '@Utils/types';
import { put, retry, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
	selectAssetById,
	selectSensorById,
	selectSystemAssets,
} from '../selectors/asset';

import { AxiosResponse } from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { getAssetIdAndType } from '@Utils/helpers';
import { getInspections } from '@Actions/inspection';
import { getRiskScore } from '@Actions/risk';
import { getSpatialDataPaginated } from '@Services/risk';
import { SpatialRecord } from '@Types/index';

function* getAssetSaga(action: PayloadAction<string>) {
	// check if asset information has already been retrieved before requesting it
	const asset: ReturnType<typeof selectAssetById> = yield select(state =>
		selectAssetById(state, action.payload),
	);
	if (asset) return;
	const { getAssetResolved, getAssetRejected } = assetActions;
	const { assetType, assetId } = getAssetIdAndType(action.payload);
	try {
		const payload: AxiosResponse<GetAssetResponse> = yield retry(
			5,
			1500,
			getAsset,
			assetId,
			assetType,
		);
		if (payload.data.asset) {
			yield put(getAssetResolved(payload.data.asset));
		} else {
			yield put(getAssetRejected());
		}
	} catch (e) {
		yield put(getAssetRejected());
	}
}

function* getAssetWithExtendedDataSaga(action: PayloadAction<string>) {
	const { getAsset, getAssetWithExtendedDataResolved } = assetActions;
	const { assetType } = getAssetIdAndType(action.payload);
	yield put(getAsset(action.payload));
	if (
		assetType === 'wwPipe' ||
		assetType === 'wwManhole' ||
		assetType === 'pipe'
	) {
		yield put(getRiskScore(action.payload));
		yield put(getInspections(action.payload));
	}
	yield put(getAssetWithExtendedDataResolved());
}

function* getSensorSaga(action: PayloadAction<string>) {
	// check if asset information has already been retrieved before requesting it
	const sensor: ReturnType<typeof selectSensorById> = yield select(state =>
		selectSensorById(state, action.payload),
	);
	if (sensor) return;
	const { getSensorResolved, getSensorRejected } = assetActions;
	const { assetId } = getAssetIdAndType(action.payload);
	try {
		const payload: AxiosResponse<GetSensorResponse> = yield retry(
			5,
			1500,
			getSensor,
			assetId,
		);
		if (payload.data.sensor) {
			yield put(getSensorResolved(payload.data.sensor));
		} else {
			yield put(getSensorRejected());
		}
	} catch (e) {
		yield put(getSensorRejected());
	}
}

function* getGridDataSaga(action: PayloadAction<GetGridDataAction>) {
	const { getGridDataResolved, getGridDataRejected } = assetActions;
	const {
		assetType,
		systemType,
		limit,
		offset,
		sort = 'assetId',
		sortDescending,
		filterModel,
		exclude,
	} = action.payload;
	try {
		const payload: AxiosResponse<PaginatedResponse<Asset | SpatialRecord>> =
			systemType === 'SpatialData'
				? yield* getSpatialData(
						assetType,
						limit,
						offset,
						sort,
						sortDescending,
						filterModel,
						exclude,
				  )
				: yield retry(
						5,
						1500,
						getAssetsPaginated,
						systemType,
						assetType,
						limit,
						offset,
						sort,
						sortDescending,
						filterModel,
						exclude,
				  );

		if (payload.data) {
			yield put(
				getGridDataResolved({
					...payload.data,
					pagination: { ...payload.data.pagination, limit },
					sort,
					sortDescending,
					filterModel,
				}),
			);
		} else {
			yield put(getGridDataRejected());
		}
	} catch (e) {
		yield put(getGridDataRejected());
	}
}

function* getSpatialData(
	layerName: string,
	limit: number,
	offset: number | undefined,
	sort: string,
	sortDescending: boolean | undefined,
	filterModel: PaginatedAssets['filterModel'],
	exclude: PaginatedAssets['exclude'],
) {
	if (!layerName) {
		throw new Error('Layer ID not found');
	}

	const result: AxiosResponse<PaginatedResponse<SpatialRecord>> = yield retry(
		3,
		1500,
		getSpatialDataPaginated,
		layerName,
		limit,
		offset,
		sort,
		sortDescending,
		filterModel,
		exclude,
	);

	if (!result.data) {
		return result;
	}

	return {
		data: {
			...result.data,
			items: result.data.items.map(({ id, ...item }) => ({
				_id: id,
				...item,
			})),
		},
	};
}

function* getSystemAssetTypesSaga() {
	const systemAssets: ReturnType<typeof selectSystemAssets> = yield select(
		selectSystemAssets,
	);
	if (Object.keys(systemAssets).length) return;
	const {
		getSystemAssetTypesResolved,
		getSystemAssetTypesRejected,
	} = assetActions;
	try {
		const payload: AxiosResponse<PaginatedAssetsResponse> = yield retry(
			5,
			1500,
			getSystemAssetTypes,
		);
		if (payload.data) {
			yield put(
				getSystemAssetTypesResolved({
					...payload.data,
				}),
			);
		} else {
			yield put(getSystemAssetTypesRejected());
		}
	} catch (e) {
		yield put(getSystemAssetTypesRejected());
	}
}

function* watchGetAsset() {
	yield takeEvery(assetActions.getAsset, getAssetSaga);
}

function* watchGetAssetWithExtendedData() {
	yield takeLatest(
		assetActions.getAssetWithExtendedData,
		getAssetWithExtendedDataSaga,
	);
}

function* watchGetSensor() {
	yield takeEvery(assetActions.getSensor, getSensorSaga);
}

function* watchGetGridData() {
	yield takeLatest(assetActions.getGridData, getGridDataSaga);
}

function* watchGetSystemAssetTypes() {
	yield takeLatest(assetActions.getSystemAssetTypes, getSystemAssetTypesSaga);
}

export default [
	watchGetAsset(),
	watchGetSensor(),
	watchGetAssetWithExtendedData(),
	watchGetGridData(),
	watchGetSystemAssetTypes(),
];
