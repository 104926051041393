import { RawMeasureData } from './measureData.types';

export enum ChannelTypes {
  Physical = 'Physical',
  Virtual = 'Virtual',
}

// What comes back from the api
export interface SensorGroupResponse {
  id: string;
  name: string;
  createDate: string;
  createBy: string;
  lastModifiedDate: string;
  lastModifiedBy: string;
  resolutions: ResolutionValues[];
  sensorType: string;
  sensorUnit: string;
  sensors: Pick<SensorListItem, 'id' | 'alias'>[];
}

/**
 * Minimum necessary pieces to use Sensor Group to fetch from S3.
 * Adding the sensors (minus the data) should allow the user to skip
 * fetching all the ids from the sensorGroup api
 */
export type ConnectedSensorGroup = Omit<
  SensorGroupResponse,
  | 'createDate'
  | 'createBy'
  | 'lastModifiedDate'
  | 'lastModifiedBy'
  | 'resolutions'
  | 'sensors'
> & {
  resolution: ResolutionValues;
  sensors?: Pick<SensorListItem, 'id' | 'alias'>[];
};

/**
 * The sensor group now that data has been fetched and applied to
 * the sensors
 */
export type SensorGroup = Omit<ConnectedSensorGroup, 'sensors'> & {
  sensors: (Pick<SensorListItem, 'id' | 'alias'> & {
    data: RawMeasureData;
  })[];
};

type ResolutionValues =
  | 'RAW'
  | '15-MINUTE'
  | '30-MINUTE'
  | 'HOURLY'
  | 'DAILY'
  | 'WEEKLY';

export interface SensorListItem {
  id: string;
  alias: string;
  unit: string;
  channelType: ChannelTypes;
  sensorType: string;
}

export enum Readings {
  'Close' = 'Close',
  'Open' = 'Open',
  'High' = 'High',
  'Low' = 'Low',
  'Average' = 'Average',
  'Sum' = 'Sum',
}

export enum Resolutions {
  'Raw' = 'RAW',
  '15 Minutes' = '15-MINUTE',
  '30 Minutes' = '30-MINUTE',
  'Hourly' = 'HOURLY',
  'Daily' = 'DAILY',
  'Weekly' = 'WEEKLY',
  'Monthly' = 'MONTHLY',
}
