export const polygonTheme = [
	// ACTIVE (being drawn)
	// line stroke
	{
		id: 'gl-draw-line',
		type: 'line',
		filter: [
			'all',
			['==', '$type', 'LineString'],
			['!=', 'mode', 'static'],
		],
		layout: {
			'line-cap': 'round',
			'line-join': 'round',
		},
		paint: {
			'line-color': '#aa0000',
			'line-dasharray': [0.2, 2],
			'line-width': 5,
		},
		metadata: {
			zIndex: 10000,
		},
	},
	// polygon fill
	{
		id: 'gl-draw-polygon-fill',
		type: 'fill',
		filter: ['all', ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
		paint: {
			'fill-color': '#aa0000',
			'fill-outline-color': '#aa0000',
			'fill-opacity': 0.3,
		},
		metadata: {
			zIndex: 10000,
		},
	},
	// polygon mid points
	{
		id: 'gl-draw-polygon-midpoint',
		type: 'circle',
		filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'midpoint']],
		paint: {
			'circle-radius': 6,
			'circle-color': '#fbb03b',
		},
		metadata: {
			zIndex: 10000,
		},
	},
	// polygon outline stroke
	// This doesn't style the first edge of the polygon, which uses the line stroke styling instead
	{
		id: 'gl-draw-polygon-stroke-active',
		type: 'line',
		filter: ['all', ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
		layout: {
			'line-cap': 'round',
			'line-join': 'round',
		},
		paint: {
			'line-color': '#aa0000',
			'line-dasharray': [0.2, 2],
			'line-width': 5,
		},
		metadata: {
			zIndex: 10000,
		},
	},
	// vertex point halos
	{
		id: 'gl-draw-polygon-and-line-vertex-halo-active',
		type: 'circle',
		filter: [
			'all',
			['==', 'meta', 'vertex'],
			['==', '$type', 'Point'],
			['!=', 'mode', 'static'],
		],
		paint: {
			'circle-radius': 8,
			'circle-color': '#FFF',
		},
		metadata: {
			zIndex: 10000,
		},
	},
	// vertex points
	{
		id: 'gl-draw-polygon-and-line-vertex-active',
		type: 'circle',
		filter: [
			'all',
			['==', 'meta', 'vertex'],
			['==', '$type', 'Point'],
			['!=', 'mode', 'static'],
		],
		paint: {
			'circle-radius': 6,
			'circle-color': '#aa0000',
		},
		metadata: {
			zIndex: 10000,
		},
	},
];
