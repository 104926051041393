import { AsyncData } from '../types/async.types';
import { AsyncSensorData, SensorDataRecords } from '../types/data.types';
import { Reading } from '../types/reading.types';
import { transformSensorDataRecordsToArray } from './sensorData.utils';
import { t } from 'i18next';

export const readingIndex: Record<Reading, number> = {
  [Reading.Open]: 0,
  [Reading.Close]: 1,
  [Reading.Low]: 2,
  [Reading.High]: 3,
  [Reading.Average]: 4,
  [Reading.Sum]: 5,
};

export const getUnifiedDataRecord = (
  data: SensorDataRecords,
  computedDataLengths?: number[]
): Omit<AsyncData<AsyncSensorData>, 'data'> => {
  const dataRecordsArray = transformSensorDataRecordsToArray(data);

  const dataIsStillLoading = dataRecordsArray.some(
    ({ status }) => status === 'loading' || status === 'idle'
  );

  const theresNoDataAtAll = dataRecordsArray.every(
    ({ status }) => status === 'rejected'
  );

  const theresNoDataInTimePeriod = dataRecordsArray.every(
    (asyncData) =>
      asyncData.status === 'rejected' ||
      (asyncData.status === 'resolved' &&
        asyncData.data.timestamps.length === 0)
  );

  if (!dataIsStillLoading && computedDataLengths) {
    if (computedDataLengths.every((length) => length === 0)) {
      return { status: 'rejected', message: t('No data available') };
    }
  }

  if (dataIsStillLoading) {
    return { status: 'loading' };
  } else if (theresNoDataAtAll) {
    return { status: 'rejected' };
  } else if (theresNoDataInTimePeriod) {
    return { status: 'rejected', message: t('No data in time period') };
  }

  return { status: 'resolved' };
};
