import {
	Alert,
	AlertsResponse,
	DataServiceType,
	Features,
	GeoJsonDataType,
	GeoJsonInputTypes,
	IconSet,
} from './types';

import AssetRegistry from './AssetRegistry';
import GeoJsonDataService from './GeoJsonDataService';
import { LOAD_ICON } from './constants';
import MapboxDataSource from './MapboxDataSource';
import PaginatedFetch from './PaginatedFetch';

export default class AlertsDataService extends GeoJsonDataService {
	constructor(dataService: DataServiceType<GeoJsonDataType>) {
		super(dataService);
		this._requestHeaders = [
			['Authorization', `Bearer ${dataService.token}`],
			['Accept', 'application/geo+json'],
		];
	}

	get iconSet(): IconSet {
		return {
			alert: 'sensor-alert',
		};
	}

	async fetchData(): Promise<GeoJsonInputTypes> {
		this.cancelPendingDataRequests();
		this._request = new PaginatedFetch({
			url: this.dataEndpoint as string,
			headers: this._requestHeaders,
		});

		const paginatedData = await this._request.fetchPages<AlertsResponse>();

		const allAlerts = paginatedData
			.map(({ alerts }) => alerts)
			.reduce((previous, current) => {
				return [...previous, ...current];
			}, []);
		const features = this.convertToFeatures(allAlerts);
		return {
			type: 'FeatureCollection',
			features,
		};
	}

	createDataSources(data: GeoJsonDataType): void {
		const dataSource = new MapboxDataSource({
			id: this.id,
			displayName: this.displayName,
			type: 'symbol',
			icon: AssetRegistry.getIcon('sensor-alert'),
			color: AssetRegistry.getColor('sensor-alert'),
			data,
			zIndex: this._zIndex,
		});

		this._dataSources = [dataSource];
	}

	convertToFeatures(alerts: Alert[]): Features[] {
		return alerts.flatMap(
			({ metadata: { sensors }, priority, createDate, id, name }) => {
				return sensors.map(({ sensorId, geometry }) => ({
					type: 'Feature',
					geometry,
					properties: {
						id: `${id}-${sensorId}`,
						name,
						srcType: 'alert',
						sensorId,
						type: 'alert',
						icon: 'sensor-alert',
						priority,
						createDate,
					},
				}));
			},
		);
	}

	/** overwrite function from GeoJsonDataService as not required */
	remapProperties(data: GeoJsonDataType | null): GeoJsonDataType | null {
		// just load the alert icon
		this.fire(LOAD_ICON, { id: 'sensor-alert' });
		return data;
	}
}
