import { rem } from '@Utils/styles';
import styled from 'styled-components';

export const BackLink = styled.div`
	font-size: ${rem(12)};
	padding: ${rem(10)} ${rem(10)} ${rem(10)} ${rem(24)};
	color: #3c3c3c;
	cursor: pointer;
`;

export const ItemInList = styled.div`
	padding: ${rem(3)} 0;
	line-height: ${rem(22)};
	cursor: pointer;
`;
