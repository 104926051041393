import { useLayoutEffect, useRef } from 'react';
import Highcharts from 'highcharts';
import useHighchartsOptions from '../useHighchartsOptions';

const useClickPoint = (onClickPoint?: (point: Highcharts.Point) => void) => {
  /**
   * Usually people don't memo their callbacks, so...
   * we store the onHiddenSeriesChange callback on a ref,
   * so that we don't regenerate options when the
   * function changes it's referential equality...
   * otherwise, the chart will have REALLY BA~D!! performance
   * as there'll be an infinite loop of renders.
   */
  const onClickCallbackRef = useRef(onClickPoint);

  useLayoutEffect(() => {
    onClickCallbackRef.current = onClickPoint;
  }, [onClickPoint]);

  /**
   * Here we set the options related to the hidden series
   * callback. Ideally we'd also set the visibility of each
   * series, but that requires making this hook dependent
   * on the options being generated already, which goes against
   * the general flow. So, instead... we expose the hiddenSeries
   * state and then we can use that on our options generator to
   * set the visibility of each series.
   *
   * We also use the callback through the ref so that we can
   * generate these options just once.
   */
  const clickPointOptions = useHighchartsOptions(() => {
    return generateOnClickPointOptions((point: Highcharts.Point) => {
      onClickCallbackRef.current?.(point);
    });
  }, []);

  return { clickPointOptions };
};

const generateOnClickPointOptions = (
  onClickPoint?: (point: Highcharts.Point) => void
): Highcharts.Options => {
  return {
    plotOptions: {
      series: {
        point: {
          events: {
            click: function (this: Highcharts.Point) {
              onClickPoint?.(this);
            },
          },
        },
      },
    },
  };
};

export default useClickPoint;
