/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactNode } from 'react';
import { useStyle } from './ModifiedInfoCard.styles';
import {
	Button,
	ContentCard,
	TextOverflow,
	WidgetErrorBoundary,
} from '@innovyze/stylovyze';
import { useGlobalization } from '@Translations';

interface ModifiedInfoCardProps {
	title: string;
	children: ReactNode;
	errorMessage?: string;
	dataCy?: string;
	className?: string;
	showEditBtn?: boolean;
	launchDarkleyFlag?: boolean;
	onEditClick(): any;
	onCancelClick(): any;
	onSaveClick?(): any;
	inEditMode: boolean;
	disabled: boolean;
}

export const ModifiedInfoCard = ({
	title,
	children,
	errorMessage,
	dataCy = 'modified-info-card',
	className,
	showEditBtn = false,
	launchDarkleyFlag = false,
	onEditClick = () => undefined,
	onCancelClick = () => undefined,
	onSaveClick = () => undefined,
	inEditMode = false,
	disabled = false,
}: ModifiedInfoCardProps): JSX.Element => {
	const { t } = useGlobalization();
	const classes = useStyle();

	const header = (
		<div className={classes.contentWrapper}>
			<TextOverflow>{title}</TextOverflow>
			{showEditBtn && launchDarkleyFlag && (
				<>
					{!inEditMode ? (
						<div>
							<Button
								variant="contained"
								color="primary"
								className={classes.editBtn}
								onClick={onEditClick}
								disabled={disabled}>
								{t('Edit')}
							</Button>
						</div>
					) : (
						<div>
							<Button
								variant="contained"
								color="secondary"
								type="reset"
								className={classes.cancelBtn}
								onClick={onCancelClick}>
								{t('Cancel')}
							</Button>
							<Button
								variant="contained"
								color="primary"
								className={classes.saveBtn}
								type="submit"
								onClick={onSaveClick}>
								{t('Submit')}
							</Button>
						</div>
					)}
				</>
			)}
		</div>
	);

	const body = (
		<WidgetErrorBoundary errorMessage={errorMessage}>
			{children}
		</WidgetErrorBoundary>
	);

	return (
		<ContentCard
			header={header}
			applyHeaderDivider={true}
			applyBodyPadding={true}
			fullHeight={true}
			applyMarginBottom={true}
			className={className}
			dataCy={dataCy}>
			{body}
		</ContentCard>
	);
};

export default ModifiedInfoCard;
