export enum StatusType {
	DEBUG = 'debug',
	INFO = 'info',
	WARN = 'warn',
	ERROR = 'error',
}

const STATUS_PRIORITIES = {
	[StatusType.DEBUG]: 0,
	[StatusType.INFO]: 1,
	[StatusType.WARN]: 2,
	[StatusType.ERROR]: 3,
};

export interface CustomLogger {
	log: (
		message: string,
		messageContext: Record<string, unknown>,
		status?: string,
	) => void;
	[key: string]: unknown;
}

interface Context {
	[key: string]: unknown;
}

export class Logger {
	private _logger = console;
	private _level = StatusType.WARN;
	private _customLogger: CustomLogger | null = null;
	private _context = {};

	get context(): Context {
		return {
			...this._context,
			urlSource: window.location.href,
			browser: window.navigator.userAgent,
			requestTime: new Date().toISOString(),
			callstack: new Error().stack,
		};
	}

	setCustom(logger: CustomLogger): void {
		this._customLogger = logger;
	}

	setContext(context: Context): void {
		this._context = context;
	}

	setLevel(level: StatusType): void {
		this._level = level;
	}

	log(message: string, messageContext = {}, status = StatusType.INFO): void {
		const context = { ...this.context, ...messageContext };
		if (this._customLogger) {
			if ('log' in this._customLogger) {
				this._customLogger.log(message, context, status);
			} else {
				console.warn('Custom logger does not have `log` method');
			}
			return;
		}
		if (STATUS_PRIORITIES[status] >= STATUS_PRIORITIES[this._level]) {
			this._logger.log(`${status}: `, message, context);
		}
	}

	debug(message: string, messageContext = {}): void {
		this.log(message, messageContext, StatusType.DEBUG);
	}

	info(message: string, messageContext = {}): void {
		this.log(message, messageContext, StatusType.INFO);
	}

	warn(message: string, messageContext = {}): void {
		this.log(message, messageContext, StatusType.WARN);
	}

	error(message: string, messageContext = {}): void {
		this.log(message, messageContext, StatusType.ERROR);
	}

	async logAsync<T>(
		message: string,
		messageContext = {},
		func: () => Promise<T>,
		status = StatusType.INFO,
	): Promise<T> {
		const start = new Date();
		const result = await func();

		const duration = new Date().getTime() - start.getTime();

		this.log(
			message,
			{
				...messageContext,
				requestTime: start.toISOString(),
				duration,
			},
			status,
		);

		return result;
	}
}

export default new Logger();
