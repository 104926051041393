export const sortByString = (
  a: string,
  b: string,
  order: 'asc' | 'desc'
): number => {
  const dir = order === 'asc' ? 1 : -1;
  return dir * a.trim().localeCompare(b.trim(), 'en', { sensitivity: 'base' });
};

export const sortByNumber = (
  a: number,
  b: number,
  order: 'asc' | 'desc'
): number => {
  if (order === 'asc') {
    return a - b;
  } else if (order === 'desc') {
    return b - a;
  }

  return 0;
};

export const sortByTimestamp = (
  a: number,
  b: number,
  order: 'asc' | 'desc'
): number => {
  if (order === 'asc') {
    return a - b;
  } else if (order === 'desc') {
    return b - a;
  }

  return 0;
};
