import React, {
  forwardRef,
  ReactElement,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import Highcharts, { Chart, Options, merge } from 'highcharts';
import More from 'highcharts/highcharts-more';
import Exporting from 'highcharts/modules/exporting';
import ExportData from 'highcharts/modules/export-data';
import OfflineExporting from 'highcharts/modules/offline-exporting';

import { v4 as uuidv4 } from 'uuid';

import * as Styled from './AtomicGaugeChart.styles';

import { GlobalPassthroughs } from '../../../types/global.types';
import { gaugeChartDefaultHighchartOptions } from '../../../utils/gaugeChart.utils';
import { exportDataToCsv, exportDataToXls } from '../../../utils/exporting';
import { useTimeZone } from '../../../utils';

Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
  },
});
export interface AtomicGaugeChartProps extends GlobalPassthroughs {
  options: Options;
}

const AtomicGaugeChart = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { options, isReadOnly, ...passThroughProps }: AtomicGaugeChartProps,
  chartControllerRef: React.Ref<unknown> | undefined
): ReactElement => {
  const chart = useRef<Chart>();
  const chartElementId = useRef<string>(uuidv4());

  useTimeZone(Highcharts);

  useEffect(() => {
    chart.current?.update(options);
  }, [options]);

  useEffect(() => {
    const mergedOptions: Options = merge(
      gaugeChartDefaultHighchartOptions,
      options
    );

    chart.current = Highcharts.chart(chartElementId.current, mergedOptions);
  }, []);

  useImperativeHandle(chartControllerRef, () => ({
    exportToCsv: () => {
      exportDataToCsv(chart.current);
    },
    exportToXls: () => {
      exportDataToXls(chart.current);
    },
  }));

  return (
    <Styled.AtomicGaugeChartWrapper
      {...passThroughProps}
      id={chartElementId.current}
    />
  );
};

More(Highcharts);
Exporting(Highcharts);
ExportData(Highcharts);
OfflineExporting(Highcharts);

export default forwardRef(AtomicGaugeChart);
