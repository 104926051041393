import { AxiosResponse } from 'axios';
import { InspectionResolvedResponse } from '@Types/inspection.types';
import inspectionApi from '@Apis/inspection.management.api';

export const getInspections = (
	assetId: string,
): Promise<AxiosResponse<InspectionResolvedResponse>> => {
	const address = `/inspections/${assetId}`;
	return inspectionApi.get(address);
};
