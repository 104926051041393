/* eslint-disable @typescript-eslint/no-non-null-assertion */
import Highcharts from 'highcharts';

import type { MassBalanceChartEventHandlers } from './mass-balance-chart';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Default Options
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const overlayInstanceInitialOptions: Highcharts.Options = {
  chart: { height: null, zoomType: 'x' },
  credits: { enabled: false },
  exporting: { enabled: false },
  legend: { enabled: true },
  rangeSelector: { inputEnabled: false, dropdown: 'always' },
  xAxis: { type: 'datetime', minRange: 3_600_000, ordinal: false },
  yAxis: { opposite: false },
  scrollbar: {
    buttonArrowColor: 'rgba(1, 1, 1, 0)',
    enabled: true,
    height: 12,
    liveRedraw: false,
  },
  navigator: {
    maskFill: 'rgba(255, 255, 255, 0.7)',
    maskInside: false,
    outlineColor: 'transparent',
    yAxis: { gridLineWidth: 0 },
    handles: {
      backgroundColor: '#F9FAFC',
      borderColor: '#768D95',
      height: 20,
    },
  },
  plotOptions: {
    series: {
      showInLegend: true,
      showInNavigator: true,
      dataGrouping: { enabled: false },
      dataLabels: { verticalAlign: 'top', format: '{y:.2f}' },
      navigatorOptions: { dataGrouping: { enabled: false } },
    },
  },
  colors: [
    '#00ABD1',
    '#758D95',
    '#60DDFF',
    '#E56C43',
    '#83BD41',
    '#497F03',
    '#EFB105',
  ],
};

const stackedHeaderInstanceInitialOptions =
  Highcharts.merge<Highcharts.Options>(
    { ...overlayInstanceInitialOptions },
    {
      synchronize: { enabled: true },
      legend: { enabled: false },
      navigator: { enabled: false },
      scrollbar: { enabled: false },
      tooltip: { enabled: false },
      xAxis: { height: 0, visible: false },
      yAxis: { height: 0, visible: false },
      plotOptions: {
        series: {
          marker: {
            enabled: false,
            radius: 0,
            enableMouseTracking: false,
            states: {
              hover: {
                enabled: false,
                radius: 0,
                radiusPlus: 0,
              },
            },
          },
        },
      },
    }
  );

const stackedSeriesInstanceInitialOptions =
  Highcharts.merge<Highcharts.Options>(
    { ...overlayInstanceInitialOptions },
    {
      synchronize: { enabled: true },
      legend: { enabled: false },
      navigator: { enabled: false },
      rangeSelector: { enabled: false },
      scrollbar: { height: 0 },
    }
  );

const stackedFooterInstanceInitialOptions =
  Highcharts.merge<Highcharts.Options>(
    { ...overlayInstanceInitialOptions },
    {
      synchronize: { enabled: true },
      rangeSelector: { enabled: false },
      scrollbar: { enabled: false },
      tooltip: { enabled: false },
      yAxis: { height: 0, visible: false },
      xAxis: { lineWidth: 0, tickWidth: 0, labels: { enabled: false } },
      plotOptions: {
        series: {
          marker: {
            enabled: false,
            radius: 0,
            enableMouseTracking: false,
            states: {
              hover: {
                enabled: false,
                radius: 0,
                radiusPlus: 0,
              },
            },
          },
        },
      },
    }
  );

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Options with event handlers
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const makeOptionsWithEventHandlers = (
  options: Highcharts.Options,
  eventHandlersRef: React.MutableRefObject<MassBalanceChartEventHandlers>
): Highcharts.Options => {
  return {
    ...options,
    xAxis: {
      ...(options.xAxis as Highcharts.XAxisOptions),
      events: {
        afterSetExtremes: function (event) {
          eventHandlersRef.current?.syncDateTimeRangePicker([
            event.min,
            event.max,
          ]);

          if (
            // This one is triggered on both modes
            event.trigger === 'navigator' ||
            event.trigger === 'zoom' ||
            // These are triggered on overlay mode
            event.trigger === 'rangeSelectorButton' ||
            event.trigger === 'rangeSelectorInput' ||
            // This one is triggered on stack mode
            event.trigger?.startsWith('sync')
          ) {
            // This are highcharts' internals. There's no public docs / types
            // for these but you can console.log(event).
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const scroller = event.target?.chart?.scroller as any;
            let endEdgeTouched: boolean | undefined = undefined;

            // If we can get the scroller data, and the navigator was moved to the
            // edges then we
            if (
              scroller?.zoomedMin !== undefined &&
              scroller?.zoomedMax !== undefined &&
              scroller?.size !== undefined
            ) {
              const endDiff = scroller.size - scroller.zoomedMax;
              const tolerance = scroller.size * 0.005;
              if (endDiff < tolerance) endEdgeTouched = true;
              else endEdgeTouched = false;
            }

            eventHandlersRef.current?.onXAxisExtremesChange?.(
              Math.trunc(event.min),
              Math.trunc(event.max),
              endEdgeTouched
            );
          }
        },
      },
    },
    plotOptions: {
      ...options.plotOptions,
      series: {
        ...options.plotOptions?.series,
        events: {
          ...options.plotOptions?.series?.events,
          show: function (event) {
            if (
              event.target &&
              'baseSeries' in event.target &&
              event.target.baseSeries === undefined &&
              'index' in event.target &&
              (event.type === 'show' || event.type === 'hide')
            ) {
              eventHandlersRef.current.onSeriesVisibilityChange?.call(
                this,
                (event.target as unknown as Highcharts.Series).options.id!,
                'show'
              );
            }

            options.plotOptions?.series?.events?.show?.call(this, event);
          },
          hide: function (event) {
            if (
              event.target &&
              'baseSeries' in event.target &&
              event.target.baseSeries === undefined &&
              'index' in event.target &&
              (event.type === 'show' || event.type === 'hide')
            ) {
              eventHandlersRef.current.onSeriesVisibilityChange?.call(
                this,
                (event.target as unknown as Highcharts.Series).options.id!,
                'hide'
              );
            }

            options.plotOptions?.series?.events?.hide?.call(this, event);
          },
        },
      },
    },
  };
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export {
  overlayInstanceInitialOptions,
  stackedHeaderInstanceInitialOptions,
  stackedSeriesInstanceInitialOptions,
  stackedFooterInstanceInitialOptions,
  makeOptionsWithEventHandlers,
};
