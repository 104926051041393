import * as React from 'react';
import * as Styled from './SensorChart.styles';

import {
  GlobalPassthroughs,
  HistoricalSeries,
  RangeSelection,
  HiddenSeries,
} from '../../../types';
import { processSensorChartData } from '../../../utils';

type SensorChartProps = {
  series: HistoricalSeries;
  listeningArray?: unknown[];
  rangeSelection?: RangeSelection;
  hiddenSeries?: HiddenSeries;
  onRangeSelectionChange?: (rangeSelection: RangeSelection) => void;
  onHiddenSeriesChange?: (hiddenSeries: HiddenSeries) => void;
} & GlobalPassthroughs;

/**
 * Add a description for your component here
 */
const SensorChart = ({
  series,
  listeningArray = [],
  ...rest
}: SensorChartProps): JSX.Element => {
  const processedSeries = React.useMemo(() => {
    return [processSensorChartData(series)];
  }, [series.data, ...listeningArray]);

  const seriesOptions = React.useMemo(
    () => ({
      navigator: {
        enabled: false,
      },
      chart: {
        zoomType: undefined,
      },
      rangeSelector: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      yAxis: {
        title: {
          text: series.unit || '',
        },
      },
    }),
    [series.unit]
  );

  return (
    <Styled.Chart {...rest} series={processedSeries} options={seriesOptions} />
  );
};

export default SensorChart;
