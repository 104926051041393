/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { nanoid } from 'nanoid';
import { useControlledRender } from '../utils';
import { useInstance } from './instance';
import * as Highcharts from 'highcharts';
import * as React from 'react';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Series Hook
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useSeries() {
  const instance = useInstance();
  const series = React.useRef<Highcharts.Series>();
  const seriesId = React.useRef<string>();
  const render = useControlledRender();

  const create = React.useCallback(
    (options?: Partial<Highcharts.SeriesOptionsType>) => {
      const _seriesId = options?.id ?? nanoid();
      const _options = (options ?? {
        id: _seriesId,
      }) as Highcharts.SeriesOptionsType;

      series.current = instance.ref.current?.addSeries(_options);
      seriesId.current = _seriesId;
      render.trigger();
    },
    [series, seriesId, instance.ref, render.trigger]
  );

  const update = React.useCallback(
    (options: Partial<Highcharts.SeriesOptionsType>) => {
      series.current?.update(options as Highcharts.SeriesOptionsType);
    },
    [series, render.id]
  );

  const destroy = React.useCallback(() => {
    series.current?.remove();
    series.current = undefined;
    render.trigger();
  }, [series, instance.ref, render.trigger]);

  return { create, destroy, update, id: seriesId, ref: series };
}
