/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as Chart from '../../core/chart';
import {
  ChartDataError,
  UnknownError,
} from '../../core/chart-data/chart-data.utils';

import type { MutableRefObject } from 'react';
import type { Series } from 'highcharts';
import { makeColorSchema } from './pie-chart.utils';
import React from 'react';
import type {
  PieChartRootProps,
  PieChartSeriesProps,
} from './pie-chart.components';
import { palette } from '@innovyze/stylovyze';
import { getTheme } from '../../core/utils/theme-utils';
import Highcharts from 'highcharts';

const type = 'pie';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Series
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const useSeriesCreateRemove = (
  id: MutableRefObject<string>,
  props: PieChartSeriesProps
): void => {
  Chart.useInstanceEffect((instances) => {
    instances.get().forEach((instance) => {
      instance.addSeries({
        id: id.current,
        type,
        color: props.color,
        visible: !props.hidden,
      });
    });
  }, []);

  Chart.useInstanceLayoutEffect((instances) => {
    return () => {
      instances.get().forEach((instance) => {
        const series = instance.get(id.current) as Series | undefined;
        series?.remove();
      });
    };
  }, []);
};

export const useSeriesData = (
  id: MutableRefObject<string>,
  props: PieChartSeriesProps
): void => {
  Chart.useInstanceEffect(
    (instances) => {
      instances.get().forEach((instance) => {
        const series = instance.get(id.current) as Series | undefined;
        const data = props.data ?? [];
        series?.update({
          type,
          data,
        });
      });
    },
    [id, props.data]
  );
};

export const useSeriesColor = (
  id: MutableRefObject<string>,
  props: PieChartSeriesProps
): void => {
  Chart.useInstanceEffect(
    (instances) => {
      instances.get(['pieInstance']).forEach((instance) => {
        const theme = getTheme(props.selectedTheme ?? 'Default');

        const colors = Highcharts.getOptions().colors.map((c, i) =>
          Highcharts.color(theme.colors[0])
            .brighten((i - 3) / 7)
            .get()
        );
        instance.update({
          plotOptions: {
            pie: {
              colors,
            },
          },
        });
      });
    },
    [id, props.data?.length, props?.selectedTheme]
  );
};

export const useSeriesVisibility = (
  id: MutableRefObject<string>,
  props: PieChartSeriesProps
): void => {
  Chart.useInstanceEffect(
    (instances) => {
      const visible = !props.hidden;

      instances.get().forEach((instance) => {
        const series = instance.get(id.current) as Series | undefined;
        series?.update({
          type,
          visible,
        });
      });
    },
    [id, props.hidden]
  );
};

export const useLegendAlign = (): void => {
  const rootContainerElementRef = Chart.useRootContainerElementRef();
  const instances = Chart.useInstance();

  React.useEffect(() => {
    const resizeEventHandler = (_event: Event) => {
      const width = rootContainerElementRef.current?.clientWidth;
      const height = rootContainerElementRef.current?.clientHeight;
      instances.get(['pieInstance']).forEach((instance) => {
        if (!!instance?.options && !!instance?.legend) {
          instance.update({
            title: {
              align: (width ?? 0) > (height ?? 0) ? 'right' : 'center',
            },
            legend:
              (width ?? 0) > (height ?? 0)
                ? {
                    align: 'right',
                    verticalAlign: 'middle',
                    layout: 'vertical',
                  }
                : {
                    align: 'center',
                    verticalAlign: 'bottom',
                    layout: 'horizontal',
                  },
          });
        }
      });
    };

    rootContainerElementRef.current?.addEventListener(
      'resize',
      resizeEventHandler
    );

    return () => {
      rootContainerElementRef.current?.removeEventListener(
        'resize',
        resizeEventHandler
      );
    };
  }, [rootContainerElementRef, instances.get]);
};

export const useStatus = (props: PieChartRootProps): void => {
  Chart.useInstanceEffect(
    (instances) => {
      instances.get('pieInstance').forEach((instance) => {
        if (props.status === 'loading') {
          instance.showLoading('Loading');
        } else if (props.status === 'resolved') {
          const noData = instance.series?.[0]?.data.every(
            (s) => s.total === undefined || s.total === 0
          );
          if (noData) {
            instance.showLoading('No Data');
          } else {
            instance.hideLoading();
          }
        } else if (props.status === 'rejected') {
          const _error =
            props.error instanceof ChartDataError
              ? props.error
              : new UnknownError();

          instance.showLoading(_error.message);
        }
      });
    },
    [props.status, props.error]
  );
};

export const useTheme = (props: PieChartRootProps): void => {
  Chart.useInstanceEffect(
    (instances) => {
      instances.get('pieInstance').forEach((instance) => {
        const theme = getTheme(props.selectedTheme ?? 'Default');
        instance.update({
          ...theme,
          title: {
            style: {
              fontSize: '12px',
            },
          },
        });
      });
    },
    [props.selectedTheme]
  );
};

export const useDataLabel = (
  id: MutableRefObject<string>,
  props: PieChartSeriesProps
): void => {
  Chart.useInstanceEffect(
    (instances) => {
      instances.get(['pieInstance']).forEach((instance) => {
        instance.update({
          plotOptions: {
            pie: {
              dataLabels: {
                enabled: props.showLabels ?? false,
              },
            },
          },
        });
      });
    },
    [id, props.showLabels]
  );
};

export const useTimeRangeLabel = (
  id: MutableRefObject<string>,
  props: PieChartSeriesProps
): void => {
  Chart.useInstanceEffect(
    (instances) => {
      instances.get(['pieInstance']).forEach((instance) => {
        instance.update({
          title: {
            text: props.timeRangeLabel ? props.timeRangeLabel : undefined,
            style: {
              color: palette.secondary.main,
              fontSize: '12px',
            },
          },
        });
      });
    },
    [id, props.timeRangeLabel]
  );
};
