import { nanoid } from 'nanoid';
import * as Chart from '../../core/chart';
import * as Hooks from './comparison-chart.hooks';
import * as React from 'react';
import * as LibUtils from '../../core/lib-utils';
import * as Utils from './comparison-chart.utils';

import type { MutableRefObject, ReactElement } from 'react';
import type { RootProps, SeriesProps } from './comparison-chart.types';
import type { EventHandlers } from './comparison-chart.utils';

const RootPropsContext = React.createContext<MutableRefObject<RootProps>>({
  current: { elements: { series: [] } },
});

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 *	Comparison Chart Root Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const Root = Chart.withRoot(
  // eslint-disable-next-line react/display-name
  React.forwardRef<{ chart: Highcharts.Chart | undefined }, RootProps>(
    (props, ref): ReactElement => {
      const eventHandlers = LibUtils.useStableRef<EventHandlers>(props.events);
      const propsRef = LibUtils.useStableRef(props);

      Hooks.useTitle(props);
      Hooks.useCategories(props);
      Hooks.useXAxisGridlines(props);
      Hooks.useYAxisGridlines(props);
      Hooks.useTheme(props);
      Hooks.useStatus(props);

      return (
        <RootPropsContext.Provider value={propsRef}>
          <Chart.Instance
            ref={ref}
            id="overlay"
            initialOptions={Utils.makeOptions(eventHandlers)}>
            {props.elements?.series}
          </Chart.Instance>
        </RootPropsContext.Provider>
      );
    }
  )
);

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Comparison Chart Series Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const Series = (props: SeriesProps): null => {
  const id = React.useRef(props.id ?? nanoid());

  Hooks.useSeriesCreateRemove(id, props);
  Hooks.useSeriesData(id, props);

  Hooks.useSeriesColor(id, props);
  Hooks.useSeriesName(id, props);
  Hooks.useSeriesVisibility(id, props);

  return null;
};
