import { DateTime } from 'luxon';
import * as Highcharts from 'highcharts';
import * as React from 'react';
import * as Utils from './utils';
import styled from 'styled-components';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Instance Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export type InstanceRef = {
  container: React.RefObject<HTMLDivElement>;
  instance: React.MutableRefObject<Highcharts.Chart | undefined>;
};

export type InstanceProps = {
  children: React.ReactNode;
  factoryFunction: typeof Highcharts.chart;
  initialOptions: Highcharts.Options;
  timeZoneIana?: string;
};

const InstanceContext = React.createContext<
  React.MutableRefObject<Highcharts.Chart | undefined>
>({ current: undefined });

export const Instance = React.forwardRef<InstanceRef, InstanceProps>(
  (props, ref) => {
    const container = React.useRef<HTMLDivElement>(null);
    const factoryFunction = React.useRef(props.factoryFunction);
    const initialOptions = React.useRef(props.initialOptions);
    const instance = React.useRef<Highcharts.Chart>();
    const timeZoneIana = React.useRef(props.timeZoneIana);

    React.useLayoutEffect(() => {
      if (container.current === null) {
        throw new Error('Missing DOM element for chart container.');
      }

      instance.current = factoryFunction.current(
        container.current,
        Highcharts.merge(
          {
            time: {
              useUTC: true,
              getTimezoneOffset: function (timestamp) {
                if (timeZoneIana.current === undefined) {
                  return -DateTime.fromMillis(timestamp).offset;
                }

                return -DateTime.fromMillis(timestamp, {
                  zone: timeZoneIana.current,
                }).offset;
              },
            },
          },
          initialOptions.current
        )
      );
    }, [instance, factoryFunction, container, initialOptions, timeZoneIana]);

    // Time options can't just be updated using instance.current.update
    // We need to redraw the chart when the IANA changes and read the
    // latest value from the ref.
    React.useEffect(() => {
      if (timeZoneIana.current !== props.timeZoneIana) {
        timeZoneIana.current = props.timeZoneIana;
        instance.current?.redraw();
      }
    }, [instance, props.timeZoneIana]);

    // TODO: Figure out a better way to do this without setTimeout.
    // We need to give some time to the children to run their clean-up
    // functions before destroying the instance.
    React.useEffect(() => {
      return () => {
        setTimeout(() => {
          instance.current?.destroy();
          instance.current = undefined;
        }, 250);
      };
    }, [instance]);

    React.useImperativeHandle(ref, () => ({ container, instance }), [
      container,
      instance,
    ]);

    return (
      <InstanceContext.Provider value={instance}>
        <Container ref={container} />
        <Hidden>{props.children}</Hidden>
      </InstanceContext.Provider>
    );
  }
);

Instance.displayName = 'Chart.Instance';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Instance Hook
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useInstance() {
  const instance = React.useContext(InstanceContext);

  const update = React.useCallback(
    (options: Partial<Highcharts.Options>) => {
      instance.current?.update(options as Highcharts.Options);
    },
    [instance]
  );

  const setExtremes = React.useCallback(
    (min: number | undefined, max: number | undefined, axisIndex = 0) => {
      const _min = Utils.getValidNumber(min);
      const _max = Utils.getValidNumber(max);

      instance.current?.xAxis[axisIndex]?.setExtremes(_min, _max);
    },
    [instance]
  );

  const setStatus = React.useCallback(
    (status?: 'idle' | 'loading' | 'resolved' | 'rejected') => {
      if (status === 'loading') {
        instance.current?.showLoading();
      } else if (status === 'rejected') {
        instance.current?.showLoading('Data Not Available');
      } else {
        instance.current?.hideLoading();
      }
    },
    [instance]
  );

  return {
    update,
    setStatus,
    setExtremes,
    ref: instance,
  };
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Styled Components
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const Container = styled.div`
  height: 100%;
  width: 100%;
`;

const Hidden = styled.div`
  display: none;
`;
