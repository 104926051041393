import { InputBase, NativeSelect } from '@mui/material';
import {
  isFeatureEnabled as useIsFeatureEnabled,
  Resolutions as StylovyzeResolutions,
} from '@innovyze/stylovyze';
import { Resolutions as SummaryzeResolutions } from '../../../types/sensor.types';
import { useGlobalization } from '../../../i18n';
import React, {
  ChangeEvent,
  ReactElement,
  useCallback,
  useLayoutEffect,
  useRef,
} from 'react';
import styled from 'styled-components';

const Dropdown = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: absolute;
  right: 16px;
  top: 26px;
  z-index: 10;
`;

const SelectInputBase = styled(InputBase).attrs(() => ({
  margin: 'none',
  size: 'small',
}))`
  background-color: #e6ebf5;
  border-radius: 2px;
  border: none;
  color: #4a6067 !important;
  cursor: pointer !important;
  font-family: 'Lucida Grande', sans-serif !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  padding: 4px 6px;
  padding-right: 12px;

  &:hover {
    background-color: #e1e4ea;
  }

  & .MuiNativeSelect-select {
    padding: 0 !important;
    background-color: transparent !important;
  }

  & .MuiSvgIcon-root {
    height: 0.8em !important;
    top: calc(50% - 0.4em) !important;
    width: 0.8em !important;
  }
`;

const Label = styled('label')`
  color: #4a6067;
  font-family: 'Lucida Grande', sans-serif;
  font-size: 12px;
  font-weight: 400;
  margin-right: 6px;
`;

const Helper = styled('span')`
  position: absolute;
  top: 0;
  left: -99999px;
`;

export type Resolutions = StylovyzeResolutions | SummaryzeResolutions;

export interface ResolutionDropdownProps<R = Resolutions> {
  onResolutionChange?: (resolution: R) => void;
  resolutionOptions?: { value: R; label: string }[];
  selectedResolution?: R;
}

export const ResolutionDropdown = (
  props: ResolutionDropdownProps
): ReactElement | null => {
  const { t } = useGlobalization();

  const selectRef = useRef<HTMLSelectElement>(null);
  const spanRef = useRef<HTMLSpanElement>(null);

  const isFeatureEnabled = useIsFeatureEnabled(
    'info-360-analytics-historic-resolution-dropdown'
  );

  /**
   * Native select element will make the width of the dropdown button equal to the largest option width.
   *
   * This will manually set the width to the option selected instead.
   */
  const resizeDropdown = useRef((value?: string) => {
    if (value && selectRef.current && spanRef.current) {
      const selectElement = selectRef.current.querySelector('select');
      if (selectElement) {
        let width = '100% !important';
        const optionElement = selectRef.current.querySelector(
          `option[value="${value}"]`
        );
        if (optionElement) {
          spanRef.current.innerHTML = (optionElement as HTMLElement).innerText;
          width = `${spanRef.current.offsetWidth}px !important`;
        }
        selectElement.setAttribute('style', `width: ${width}`);
      }
    }
  });

  const handleNativeSelectChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      props.onResolutionChange?.(event.target.value as Resolutions);
      resizeDropdown?.current(event.target.value);
    },
    [props.onResolutionChange]
  );

  useLayoutEffect(() => {
    resizeDropdown?.current(props.selectedResolution);
  }, [props.selectedResolution]);

  if (
    !isFeatureEnabled ||
    !props.resolutionOptions ||
    props.resolutionOptions.length === 0
  ) {
    return null;
  }

  return (
    <>
      <Helper ref={spanRef} aria-hidden="true" />
      <Dropdown>
        <Label>{t('Resolution')}</Label>
        <NativeSelect
          ref={selectRef}
          input={<SelectInputBase />}
          value={props.selectedResolution}
          onChange={handleNativeSelectChange}>
          {props.resolutionOptions.map((resolution) => (
            <option key={resolution.value} value={resolution.value}>
              {resolution.label}
            </option>
          ))}
        </NativeSelect>
      </Dropdown>
    </>
  );
};

export default ResolutionDropdown;
