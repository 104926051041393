import { CompanySettings } from '@innovyze/stylovyze';

const getCurrencySettings = (
	companySettings: CompanySettings,
): { shortCode: string; type: string } => {
	let currencyShortCode = 'en-US';
	let currencyType = 'USD';
	if (
		companySettings != undefined &&
		companySettings.currencyFormatting != undefined &&
		companySettings.currencyFormatting.shortCode != undefined &&
		companySettings.currencyFormatting.currency != undefined
	) {
		currencyShortCode = companySettings.currencyFormatting.shortCode;
		currencyType = companySettings.currencyFormatting.currency;
	}

	return {
		shortCode: currencyShortCode,
		type: currencyType,
	};
};

/**
 * Get a formatted currency string
 * @param number Value to format
 * @param companySettings Compay Settings object for formatting
 * @param currency Optional currency code, use companySettings if not supplied
 * @returns Formated string or the number if invalid
 */
export const getCurrency = (
	number: number,
	companySettings: CompanySettings,
	currency?: string,
): string => {
	if (number == undefined) number = 0;

	try {
		const { shortCode, type } = getCurrencySettings(companySettings);

		return Intl.NumberFormat(shortCode, {
			style: 'currency',
			currency: currency ?? type,
		}).format(number);
	} catch {
		return number.toString();
	}
};

/**
 * Get the currency symbol
 * @param companySettings Compay Settings object for formatting
 * @param currency Optional currency code, use companySettings if not supplied
 * @returns Currency symbol or undefined if invalid
 */
export const getCurrencySymbol = (
	companySettings: CompanySettings,
	currency?: string,
): string | undefined => {
	const { shortCode, type } = getCurrencySettings(companySettings);

	try {
		const parts = Intl.NumberFormat(shortCode, {
			style: 'currency',
			currency: currency ?? type,
		}).formatToParts(undefined);

		const currencySymbol = parts.find(x => x.type == 'currency');

		return currencySymbol ? currencySymbol.value : undefined;
	} catch {
		return undefined;
	}
};

export const getCostCalculation = (
	costCalculation: string,
	companySettings: CompanySettings,
): string => {
	if (costCalculation == undefined || costCalculation == '') return '';

	const { shortCode, type } = getCurrencySettings(companySettings);

	const regex = /\$\d*,?\d*,?\d*,?\d*,?\d*,?\d*\.?\d*/;
	return costCalculation.replace(regex, match => {
		return Intl.NumberFormat(shortCode, {
			style: 'currency',
			currency: type,
		}).format(+match.substring(1).replace(/,/g, ''));
	});
};
