export class ChartDataError extends Error {
  constructor() {
    super();
    this.name = 'ChartDataError';
  }
}

export class NoDataInRange extends Error {
  constructor() {
    super('No data in time period.');
    this.name = 'NoDataInRange';
  }
}

export class ServiceError extends Error {
  constructor() {
    super('Service unavailable.');
    this.name = 'ServiceError';
  }
}

export class UnknownError extends Error {
  constructor() {
    super('Data not available.');
    this.name = 'UnknownError';
  }
}
