import * as React from 'react';

export type UseControllableStateParams<T> = {
  prop?: T | undefined;
  defaultProp?: T | undefined;
  onChange?: (state: T) => void;
};

type SetStateFn<T> = (prevState?: T) => T;

function useControllableState<T>({
  prop,
  defaultProp,
  onChange,
}: UseControllableStateParams<T>) {
  const [uncontrolledProp, setUncontrolledProp] = useUncontrolledState({
    defaultProp,
    onChange,
  });
  const isControlled = prop !== undefined;
  const value = isControlled ? prop : uncontrolledProp;
  const propRef = React.useRef(prop);
  const onChangeRef = React.useRef(onChange);

  propRef.current = prop;
  onChangeRef.current = onChange;

  const setValue: React.Dispatch<React.SetStateAction<T | undefined>> =
    React.useCallback(
      (nextValue) => {
        if (isControlled) {
          const setter = nextValue as SetStateFn<T>;
          const value =
            typeof nextValue === 'function'
              ? setter(propRef.current)
              : nextValue;
          if (value !== propRef.current) onChangeRef.current(value as T);
        } else {
          setUncontrolledProp(nextValue);
        }
      },
      [isControlled, setUncontrolledProp]
    );

  return [value, setValue] as const;
}

function useUncontrolledState<T>({
  defaultProp,
  onChange,
}: Omit<UseControllableStateParams<T>, 'prop'>) {
  const uncontrolledState = React.useState<T | undefined>(defaultProp);
  const [value] = uncontrolledState;
  const prevValueRef = React.useRef(value);
  const onChangeRef = React.useRef(onChange);
  onChangeRef.current = onChange;

  React.useEffect(() => {
    if (prevValueRef.current !== value) {
      onChangeRef.current(value as T);
      prevValueRef.current = value;
    }
  }, [value]);

  return uncontrolledState;
}

export { useControllableState };
