import { TFunc } from '@Translations/types';
import { InspectionStandard } from '@Types/inspection.types';

export const getObservationCodes = (
	standard: InspectionStandard,
	t: TFunc,
): { [key: string]: string } => {
	switch (standard) {
		case InspectionStandard.MACP:
			return macpObservationCodes(t);
		case InspectionStandard.MSCC:
			return msccObservationCodes(t);
		case InspectionStandard.PACP:
			return pacpObservationCodes(t);
		case InspectionStandard.WSA05:
			return wsa05ObservationCodes(t);
		default:
			return {};
	}
};

export const msccObservationCodes = (t: TFunc): { [key: string]: string } => {
	return {
		B: t('Broken Pipe'),
		BN: t('Buchan Trap (start)'),
		BNF: t('Buchan Trap (finish)'),
		BR: t('Major connection without manhole (start)'),
		BRF: t('Major connection without manhole (finish)'),
		CC: t('Crack Circumferential'),
		CL: t('Crack Longitudinal'),
		CM: t('Crack Multiple'),
		CN: t('Connection'),
		CNC: t('Connection other than junction closed'),
		CP: t('Catchpit (start)'),
		CPF: t('Catchpit (finish)'),
		CR: t('Crack Radiating'),
		CS: t('Cracks Spiral'),
		CU: t('Loss of vision'),
		CUD: t('Loss of vision, silt'),
		CUS: t('Loss of vision, steam'),
		CUW: t('Loss of vision camera underwater'),
		CUZ: t('Loss of vision, other'),
		CX: t('Connection Defective'),
		CXB: t('Connection Defective - connecting pipe blocked'),
		CXBI: t(
			'Connection Defective - connecting pipe blocked with intrusion',
		),
		CXD: t('Connection Defective - connecting pipe damaged'),
		CXDI: t(
			'Connection Defective - connecting pipe damaged with intrusion',
		),
		CXI: t('Connection Intruding'),
		CXP: t('Connection Defective - position incorrect'),
		CXPI: t('Connection Defective - position incorrect with intrusion'),
		CXZ: t('Connection Defective - other'),
		D: t('Deformation'),
		DB: t('Displaced Bricks'),
		DEC: t('Settled deposits hard or compact'),
		DEE: t('Attached Deposits, encrustation'),
		DEF: t('Attached Deposits, fouling'),
		DEG: t('Attached Deposits, grease'),
		DER: t('Settled Deposits, coarse'),
		DES: t('Settled Deposits, fine'),
		DEX: t('Other settled Deposits'),
		DEZ: t('Other attached Deposits'),
		DH: t('Deformation Horizontal'),
		DI: t('Dropped Invert'),
		DV: t('Deformation Vertical'),
		EX: t('Exfiltration'),
		FC: t('Fracture Circumferential'),
		FL: t('Fracture Longitudinal'),
		FM: t('Fractures Multiple'),
		FR: t('Fracture Radiating'),
		FS: t('Fracture spiral'),
		FW: t('Flow in incoming pipe'),
		FWC: t('Clear flow in incoming pipe'),
		FWCS: t('Wrong clear flow in foul pipe'),
		FWT: t('Turbid flow in incoming pipe'),
		FWTF: t('Wrong turbid flow in s-w pipe'),
		GP: t('General Photograph'),
		GY: t('Gully (start)'),
		GYF: t('Gully (finish)'),
		GZ: t('Hazardous atmosphere, other'),
		H: t('Hole'),
		HS: t('Hydrogen Sulphide'),
		I: t('Infiltration'),
		IC: t('Inspection chamber (start)'),
		ICF: t('Inspection chamber (finish)'),
		ID: t('Infiltration Dripper'),
		IG: t('Infiltration Gusher'),
		ING: t('Ingress of soil'),
		INGF: t('Ingress of fine material'),
		INGG: t('Ingress of gravel'),
		INGP: t('Ingress of peat'),
		INGS: t('Ingress of sand'),
		INGZ: t('Ingress of soil, other'),
		IR: t('Infiltration Runner'),
		IS: t('Infiltration Seeper'),
		JD: t('Joint Displacement'),
		JDL: t('Joint Displacement Large'),
		JDM: t('Joint Displacement Medium'),
		JN: t('Junction'),
		JNC: t('Junction, closed'),
		JX: t('Junction Defective'),
		JXB: t('Jnct defect - conn pipe blocked'),
		JXD: t('Jnct defect - conn pipe damaged'),
		JXP: t('Jnct defect - position incorrect'),
		JXZ: t('Junction defective - other'),
		LC: t('Lining of drain changes'),
		'LC+': t('Lining of drain changes + material change'),
		LD: t('Line Deviates Down'),
		LH: t('Lamphole (start)'),
		LHF: t('Lamphole (finish)'),
		LL: t('Line Deviates Left'),
		LR: t('Line Deviates Right'),
		LU: t('Line Deviates Up'),
		LX: t('Lining Defective'),
		LXB: t('Defective lining - blistered'),
		LXC: t('Defective lining - discolouration'),
		LXCX: t('Defective lining - lining defect at connection'),
		LXD: t('Defective lining - detached'),
		LXE: t('Defective lining - defective end'),
		LXEB: t('Defective lining - external bulge'),
		LXES: t('Defective lining - end not sealed'),
		LXF: t('Defective lining - separated film'),
		LXH: t('Defective lining - hole in lining'),
		LXR: t('Defective lining - resin missing'),
		LXS: t('Defective lining - crack or split in lining or weld failure'),
		LXSC: t(
			'Defective lining - circumferential crack or split in lining or weld failure',
		),
		LXSF: t('Defective lining - lining appears soft'),
		LXSH: t(
			'Defective lining - spiral crack or split in lining or weld failure',
		),
		LXSL: t(
			'Defective lining - longitudinal crack or split in lining or weld failure',
		),
		LXSM: t(
			'Defective lining - complex crack or split in lining or weld failure',
		),
		LXSS: t('Defective lining - separated seam cover'),
		LXWC: t('Defective lining - circumferential wrinkled'),
		LXWH: t('Defective lining - spiral wrinkled'),
		LXWL: t('Defective lining - longitudinal wrinkled'),
		LXWM: t('Defective lining - complex (multiple) wrinkled'),
		LXZ: t('Defective lining - other defect'),
		MB: t('Missing Bricks'),
		MC: t('Material Change + Material change'),
		ME: t('Hazardous atmosphere, methane'),
		MH: t('Manhole (start)'),
		MHF: t('Manhole (finish)'),
		MM: t('Mortar missing slight (5 mm - 15 mm)'),
		MMM: t('Mortar missing medium (15 mm - 50 mm)'),
		MMS: t('Mortar missing slight (5 mm - 15 mm)'),
		MMT: t('Mortar missing total (50 mm - 100 mm)'),
		OB: t('Obstruction'),
		OBB: t('Brick or masonry in invert'),
		OBC: t('Obstacles through connection/junct'),
		OBI: t('Obstacles protuding through wall'),
		OBM: t('Pipe material in invert'),
		OBP: t('Obstacles, external pipe/cable'),
		OBS: t('Obstacles built into structure'),
		OBX: t('Other obstacles, other object'),
		OBZ: t('Other obstacles, other'),
		OC: t('Other special chamber (start)'),
		OCF: t('Other special chamber (finish)'),
		OD: t('Hazardous atmosphere, oxygen deficiency'),
		OF: t('Outfall (start)'),
		OFF: t('Outfall (finish)'),
		OJ: t('Open Joint'),
		OJL: t('Open Joint Large'),
		OJM: t('Open Joint Medium'),
		OS: t('Oil seperator (start)'),
		OSF: t('Oil seperator (finish)'),
		PC: t('Pipe Length Change'),
		PP: t('Pipe material is porous'),
		PVR: t('Photographic volume reference new volume'),
		RE: t('Rodding eye (start)'),
		REF: t('Rodding eye (finish)'),
		REM: t('General remark'),
		RF: t('Roots fine'),
		RG: t('Running trap (start)'),
		RGF: t('Running trap (finish)'),
		RM: t('Roots Mass'),
		RPC: t('Point repair - other repair to connection'),
		RPH: t('Point repair - hole repaired'),
		RPI: t('Point repair - injected mortar'),
		RPL: t('Point repair - localised lining'),
		RPR: t('Point repair - pipe replaced'),
		RPS: t('Point repair - other injected sealing material'),
		RPT: t('Point repair - localised lining of connection'),
		RPZ: t('Point repair - other trenchless method'),
		RT: t('Roots Tap'),
		RXC: t('Defective repair - crack in material'),
		RXH: t('Defective repair - hole in material'),
		RXM: t('Defective repair - part wall missing'),
		RXMR: t('Defective repair - missing repair material'),
		RXS: t('Defective repair - separation from host pipe'),
		RXXM: t('Defective repair - excessive material obstruction'),
		RXZ: t('Defective repair - other'),
		S: t('Surface damage'),
		SA: t('Survey abandoned'),
		SAP: t('Aggregate projecting frm surface'),
		SAV: t('Visible aggregate'),
		SB: t('Internal blister/bulge'),
		SC: t('Shape Change'),
		'SC+': t('Shape Change + shape code'),
		SCP: t('Corrosion products'),
		SK: t('Soakaway (start)'),
		SKF: t('Soakaway (finish)'),
		SO: t('Other sealant intruding'),
		SR: t('Sealing ring intruding'),
		SRB: t('Sealing ring intruding and broken'),
		SRC: t('Corroded reinforcement'),
		SRP: t('Reinforcement projecting'),
		SRV: t('Visible reinforcement'),
		SS: t('Spalling'),
		SV: t('Soil visible through defect'),
		SW: t('Increased roughness'),
		SZ: t('Other damage'),
		V: t('Vermin'),
		VR: t('Vermin, rat'),
		VRC: t('Vermin, rats observed in connection'),
		VRJ: t('Vermin, rats observed in open joint'),
		VRZ: t('Vermin, rats observed other'),
		VV: t('Void visible beyond defect'),
		VVR: t('Video volume reference new volume'),
		WL: t('Water level'),
		WLC: t('Clear water level'),
		WLT: t('Turbid water level'),
		WR: t('Winser trap (start)'),
		WRF: t('Winser trap (finish)'),
		WXC: t('Weld failure circumferential'),
		WXL: t('Weld failure longitudinal'),
		WXS: t('Weld failure spiral'),
		XB: t('Collapse brickwork or masonry'),
		XP: t('Collapsed drain/sewer'),
	};
};

export const wsa05ObservationCodes = (t: TFunc): { [key: string]: string } => {
	return {
		B: t('Breaking'),
		C: t('Cracking'),
		CG: t('Change of Cross Section'),
		CI: t('Intruding Connection'),
		CN: t('Connection'),
		CX: t('Defective connection'),
		D: t('Deformation'),
		DE: t('Deposits on Wall and Invert'),
		DI: t('Dropped Invert'),
		DJS: t('Defective Joint Seal'),
		DJW: t('Defective Joint Weld'),
		DMU: t('Displaced Masonry Units'),
		EX: t('Exfiltration'),
		FH: t('Finish Node'),
		GAS: t('Atmosphere in Conduit'),
		GC: t('General Comment'),
		GP: t('General Photograph'),
		I: t('Infiltration'),
		IF: t('Flow in Connecting Conduits'),
		ING: t('Ingress of Soil'),
		JD: t('Displaced Joint'),
		JN: t('Junction'),
		JX: t('Defective junction'),
		L: t('Line of Conduit Deviates'),
		LC: t('Change of Lining'),
		LD: t('Lining Defective (Structural)'),
		LH: t('Lifting Hole'),
		LOV: t('Loss of Vision'),
		MBC: t('Masonry (brick) cracking'),
		MC: t('Change of Conduit Material'),
		MDI: t('Masonry Dropped Invert'),
		MM: t('Missing Mortar'),
		MMU: t('Missing Masonry Units'),
		MS: t('Masonry Spalling'),
		MUS: t('Masonry Unit Separation'),
		OB: t('Obstruction'),
		PC: t('Change in Conduit Unit Length'),
		PP: t('Porous Conduit'),
		PR: t('Point Repair (Defective)'),
		R: t('Roots'),
		SA: t('Inspection Survey Abandoned'),
		SAC: t('Surface damage asbestos cement'),
		SC: t('Surface Damage Concrete'),
		SDC: t('Surface Damage Concrete'),
		SO: t('Surface damage other (VC, metals) '),
		ST: t('Start Node'),
		SV: t('Soil Visible'),
		V: t('Vermin'),
		VV: t('Void Visible'),
		WL: t('Water Level'),
		X: t('Collapse Conduit'),
		XM: t('Masonry Conduit Collapsed'),
	};
};

export const pacpObservationCodes = (t: TFunc): { [key: string]: string } => {
	return {
		ACB: t('Access Point Catch Basin'),
		ACOH: t('Access Point Cleanout House'),
		ACOM: t('Access Point Cleanout Mainline'),
		ACOP: t('Access Point Cleanout Propertyline'),
		ADP: t('Access Point Discharge Point'),
		AEP: t('Access Point End of Pipe'),
		AJB: t('Access Point Junction Box'),
		AM: t('Access Point Meter'),
		AMH: t('Manhole'),
		AOC: t('Access Point Special Chamber'),
		ATC: t('Access Point Tee Connection'),
		AWA: t('Access Point Wastewater Access Device'),
		AWW: t('Access Point Wet Well'),
		AZ: t('Access Point Other'),
		B: t('Broken'),
		BSV: t('Broken Soil Visible'),
		BVV: t('Broken Void Visible'),
		CC: t('Crack Circumferential'),
		CH2: t('Crack Longitudinal Hinge, 2'),
		CH3: t('Crack Longitudinal Hinge, 3'),
		CH4: t('Crack Longitudinal Hinge, 4'),
		CL: t('Crack Longitudinal'),
		CM: t('Crack Multiple'),
		CS: t('Crack Spiral'),
		D: t('Deformed'),
		DAE: t('Deposits Attached Encrustation'),
		DAGS: t('Deposits Attached Grease'),
		DAR: t('Deposits Attached Ragging'),
		DAZ: t('Deposits Attached Other'),
		DB: t('Displaced Brick'),
		DFBI: t('Deformed Flexible Bulging Inverse Curvature'),
		DFBR: t('Deformed Flexible Bulging Round'),
		DFC: t('Deformed Flexible Creasing'),
		DFE: t('Deformed Flexible Elliptical'),
		DH: t('Deformed Horizontal'),
		DI: t('Brickwork Dropped Invert'),
		DNF: t('Deposits Ingress Fine'),
		DNGV: t('Deposits Ingress Gravel'),
		DNZ: t('Deposits Ingress Other'),
		DR: t('Deformed Rigid'),
		DSC: t('Deposits Settled Hard/Compacted'),
		DSF: t('Deposits Settled Fine'),
		DSGV: t('Deposits Settled Gravel'),
		DSZ: t('Deposits Settled Other'),
		DTBI: t('Deformed Brick Bulging Inverse Curvature'),
		DTBR: t('Deformed Brick Bulging Round'),
		DV: t('Defomed Vertical'),
		FC: t('Fracture Circumferential'),
		FH2: t('Fracture Longitudinal Hinge, 2'),
		FH3: t('Fracture Longitudinal Hinge, 3'),
		FH4: t('Fracture Longitudinal Hinge, 4'),
		FL: t('Fracture Longitudinal'),
		FM: t('Fracture Multiple'),
		FS: t('Fracture Spiral'),
		GRT: t('Grout at a Location'),
		GTFJ: t('Grout Test Fail Joint'),
		GTFL: t('Grout Test Fail Lateral'),
		GTPJ: t('Grout Test Pass Joint'),
		GTPL: t('Grout Test Pass Lateral'),
		GTUJ: t('Grout Test Unable to Test Joint'),
		GTUL: t('Grout Test Unable to Test Lateral'),
		H: t('Hole'),
		HSV: t('Hole Soil Visible'),
		HVV: t('Hole Void Visible'),
		ID: t('Infiltration Dripper'),
		IDB: t('Infiltration Dripper Barrel'),
		IDC: t('Infiltration Dripper Connection'),
		IDJ: t('Infiltration Dripper Joint'),
		IDL: t('Infiltration Dripper Lateral'),
		IG: t('Infiltration Gusher'),
		IGB: t('Infiltration Gusher Barrel'),
		IGC: t('Infiltration Gusher Connection'),
		IGJ: t('Infiltration Gusher Joint'),
		IGL: t('Infiltration Gusher Lateral'),
		IR: t('Infiltration Runner'),
		IRB: t('Infiltration Runner Barrel'),
		IRC: t('Infiltration Runner Connection'),
		IRJ: t('Infiltration Runner Joint'),
		IRL: t('Infiltration Runner Lateral'),
		IS: t('Infitration Stain'),
		ISB: t('Infiltration Stain Barrel'),
		ISC: t('Infiltration Stain Connection'),
		ISGT: t('Intruding Sealing Material Grout'),
		ISJ: t('Infiltration Stain Joint'),
		ISL: t('Infiltration Stain Lateral'),
		ISSR: t('Intruding Sealing Material Sealing Ring'),
		ISSRB: t('Intruding Sealing Material Sealing Ring Broken'),
		ISSRH: t('Intruding Sealing Material Sealing Ring Hanging'),
		ISSRL: t(
			'Intruding Sealing Material Sealing Ring Loose/Poorly Fitting',
		),
		ISZ: t('Intruding Sealing Material Other'),
		IW: t('Infiltration Weeper'),
		IWB: t('Infiltration Weeper Barrel'),
		IWC: t('Infiltration Weeper Connection'),
		IWJ: t('Infiltration Weeper Joint'),
		IWL: t('Infiltration Weeper Lateral'),
		JAL: t('Joint Angular Large'),
		JAM: t('Joint Angular Medium'),
		JAS: t('Joint Angular Small'),
		JOL: t('Joint Offset Large'),
		JOLD: t('Joint Offset Large Defective'),
		JOM: t('Joint Offset Medium'),
		JOMD: t('Joint Offset Medium Defective'),
		JOS: t('Joint Offset Small'),
		JOSD: t('Joint Offset Small Defective'),
		JSL: t('Joint Separated Large'),
		JSM: t('Joint Separated Medium'),
		JSS: t('Joint Separated Small'),
		KD: t('Buckling Dimpling'),
		KI: t('Inverse Curvature'),
		KW: t('Buckling Wall'),
		LD: t('Line Down'),
		LFAC: t('Lining Feature Abandoned Connection'),
		LFAS: t('Lining Feature Annular Space'),
		LFB: t('Lining Feature Blistered'),
		LFBK: t('Lining Failure Buckled'),
		LFBU: t('Lining Failure Bulges'),
		LFCS: t('Lining Feature Service Cut Shifted'),
		LFD: t('Lining Feature Detached'),
		LFDC: t('Lining Feature Discoloration'),
		LFDE: t('Lining Feature Defective End'),
		LFDL: t('Lining Feature Delaminating'),
		LFOC: t('Lining Feature Overcut Service'),
		LFPH: t('Lining Failure Pinhole'),
		LFRS: t('Lining Feature Resin slug'),
		LFUC: t('Lining Feature Undercut Service'),
		LFW: t('Lining Feature Wrinkled'),
		LFZ: t('Lining Feature Other'),
		LL: t('Line Left'),
		LLD: t('Line Left Down'),
		LLU: t('Line Left Up'),
		LR: t('Line Right'),
		LRD: t('Line Right Down'),
		LRU: t('Line Right Up'),
		LU: t('Line Up'),
		MB: t('Missing Brick'),
		MCU: t('Miscellaneous Camera Underwater'),
		MGO: t('Miscellaneous General Observation'),
		MGP: t('Miscellaneous General Photograph'),
		MJL: t('Miscellaneous Pipe Joint Length Change'),
		MLC: t('Miscellaneous Lining Change'),
		MMC: t('Miscellaneous Material Change'),
		MML: t('Missing Mortar Large'),
		MMM: t('Missing Mortar Medium'),
		MMS: t('Missing Mortar Small'),
		MSA: t('Miscellaneous Survey Abandoned'),
		MSC: t('Miscellaneous Shape/Size Change'),
		MWL: t('Miscellaneous Water Level'),
		MWLS: t('Miscellaneous Water Level Sag'),
		MWM: t('Miscellaneous Water Mark'),
		MYN: t('Miscellaneous Dye Test Not Visible'),
		MYV: t('Miscellaneous Dye Test Visible'),
		OBB: t('Obstruction Brick or Masonry'),
		OBC: t('Obstruction Through Connection'),
		OBI: t('Obstruction Intruding Through Wall'),
		OBJ: t('Obstruction Wedged In The Joint'),
		OBM: t('Obstruction Pipe Material in Invert'),
		OBN: t('Obstruction Construction Debris'),
		OBP: t('Obstruction External Pipe or Cable'),
		OBR: t('Obstruction Rocks'),
		OBS: t('Obstruction Built Into Structure'),
		OBZ: t('Obstruction Other'),
		RBB: t('Roots Ball Barrel'),
		RBC: t('Roots Ball Connection'),
		RBJ: t('Roots Ball Joint'),
		RBL: t('Roots Ball Lateral'),
		RFB: t('Roots Fine Barrel'),
		RFC: t('Roots Fine Connection'),
		RFJ: t('Roots Fine Joint'),
		RFL: t('Roots Fine Lateral'),
		RMB: t('Roots Medium Barrel'),
		RMC: t('Roots Medium Connection'),
		RMJ: t('Roots Medium Joint'),
		RML: t('Roots Medium Lateral'),
		RPL: t('Point Repair Liner'),
		RPLD: t('Point Repair Liner Defective'),
		RPP: t('Point Repair Patch'),
		RPPD: t('Point Repair Patch Patch Defective'),
		RPR: t('Point Repair Replacement'),
		RPRD: t('Point Repair Replacement Defective'),
		RPZ: t('Point Repair Other'),
		RPZD: t('Point Repair Other Defective'),
		RTB: t('Roots Tap Barrel'),
		RTC: t('Roots Tap Connection'),
		RTJ: t('Roots Tap Joint'),
		RTL: t('Roots Tap Lateral'),
		SAM: t('Surface Damage Aggregate Missing'),
		SAMC: t('Surface Aggregate Missing Chemical'),
		SAMM: t('Surface Aggregate Missing Mechanical'),
		SAMZ: t('Surface Aggregate Missing Unknown'),
		SAP: t('Surface Damage Aggregate Projecting'),
		SAPC: t('Surface Aggregate Projecting Chemical'),
		SAPM: t('Surface Aggregate Projecting Mechanical'),
		SAPZ: t('Surface Aggregate Projecting Unknown'),
		SAV: t('Surface Damage Aggregate Visible'),
		SAVC: t('Surface Aggregate Visible Chemical'),
		SAVM: t('Surface Aggregate Visible Mechanical'),
		SAVZ: t('Surface Aggregate Visible Unknown'),
		SCP: t('Surface Damage Corrosion'),
		SMW: t('Surface Damage Missing Wall'),
		SMWC: t('Surface Missing Wall Chemical'),
		SMWM: t('Surface Missing Wall Mechanical'),
		SMWZ: t('Surface Missing Wall Unknown'),
		SRC: t('Surface Damage Reinforcement Corroded'),
		SRCC: t('Surface Reinforcement Corroded Chemical'),
		SRCM: t('Surface Reinforcement Corroded Mechanical'),
		SRCZ: t('Surface Reinforcement Corroded Unknown'),
		SRI: t('Surface Damage Roughness Increased'),
		SRIC: t('Surface Roughness Increased Chemical'),
		SRIM: t('Surface Roughness Increased Mechanical'),
		SRIZ: t('Surface Roughness Increased Unknown'),
		SRP: t('Surface Damage Reinforcement Projecting'),
		SRPC: t('Surface Reinforcement Projecting Chemical'),
		SRPM: t('Surface Reinforcement Projecting Mechanical'),
		SRPZ: t('Surface Reinforcement Projecting Unknown'),
		SRV: t('Surface Damage Reinforcement Visible'),
		SRVC: t('Surface Reinforcement Visible Chemical'),
		SRVM: t('Surface Reinforcement Visible Mechanical'),
		SRVZ: t('Surface Reinforcement Visible Unknown'),
		SSC: t('Surface Spalling of Damage Coating'),
		SSS: t('Surface Damage Surface Spalling'),
		SSSC: t('Surface Spalling Chemical'),
		SSSM: t('Surface Spalling Mechanical'),
		SSSZ: t('Surface Spalling Other'),
		SZ: t('Surface Damage Other'),
		SZC: t('Surface Other Chemical'),
		SZM: t('Surface Other Mechanical'),
		SZZ: t('Surface Other Unknown'),
		TB: t('Tap Break-in/Hammer'),
		TBA: t('Tap Break-in Activity'),
		TBB: t('Tap Break-in Abandoned'),
		TBC: t('Tap Break-in Capped'),
		TBD: t('Tap Break-in/Hammer Defective'),
		TBI: t('Tap Break-in Intruding'),
		TF: t('Tap Factory'),
		TFA: t('Tap Factory Activity'),
		TFB: t('Tap Factory Abandoned'),
		TFC: t('Tap Factory Capped'),
		TFD: t('Tap Factory Defective'),
		TFI: t('Tap Factory Intruding'),
		TR: t('Tap Rehabilitated'),
		TRA: t('Tap Rehabilitated Activity'),
		TRB: t('Tap Rehabilitated Abandoned'),
		TRC: t('Tap Rehabilitated Capped'),
		TRD: t('Tap Rehabilitated Defective'),
		TRI: t('Tap Rehabilitated Intruding'),
		TS: t('Tap Saddle'),
		TSA: t('Tap Saddle Activity'),
		TSB: t('Tap Saddle Abandoned'),
		TSC: t('Tap Saddle Capped'),
		TSD: t('Tap Saddle Defective'),
		TSI: t('Tap Saddle Intruding'),
		VC: t('Vermin Cockroach'),
		VR: t('Vermin Rat'),
		VZ: t('Vermin Other'),
		WFC: t('Weld Feature Circumferential'),
		WFL: t('Weld Feature Longitudinal'),
		WFM: t('Weld Feature Multiple'),
		WFS: t('Weld Feature Spiral'),
		WFZ: t('Weld Feature Other'),
		X: t('Collapse'),
		XB: t('Collapse Brick Sewer'),
		XP: t('Collapse Pipe Sewer'),
	};
};

export const macpObservationCodes = (t: TFunc): { [key: string]: string } => {
	return {
		B: t('Broken'),
		BSV: t('Broken Soil Visible'),
		BVV: t('Broken Void Visible'),
		CC: t('Crack Circumferential'),
		CL: t('Crack Longitudinal'),
		CM: t('Crack Multiple'),
		CS: t('Crack Spiral'),
		DAE: t('Deposits Attached Encrustation'),
		DAGS: t('Deposits Attached Grease'),
		DAR: t('Deposits Attached Ragging'),
		DAZ: t('Deposits Attached Other'),
		DB: t('Displaced Brick'),
		DFBI: t('Deformed Flexible Bulging Inverse Curvature'),
		DFBR: t('Deformed Flexible Bulging Round'),
		DFC: t('Deformed Flexible Creasing'),
		DFE: t('Deformed Flexible Elliptical'),
		DI: t('Brickwork Dropped Invert'),
		DNF: t('Deposits Ingressed Fine'),
		DNGV: t('Deposits Ingressed Gravel'),
		DNZ: t('Deposits Ingressed Other'),
		DR: t('Deformed Rigid'),
		DSC: t('Deposits Settled Compacted'),
		DSF: t('Deposits Settled Fine'),
		DSGV: t('Deposits Settled Gravel'),
		DSZ: t('Deposits Settled Other'),
		DTBI: t('Deformed Brick Bulging Inverse Curvature'),
		DTBR: t('Deformed Brick Bulging Round'),
		FC: t('Fracture Circumferential'),
		FL: t('Fracture Longitudinal'),
		FM: t('Fracture Multiple'),
		FS: t('Fracture Spiral'),
		GRT: t('Grout at a Location'),
		GTFJ: t('Grout Test Fail Joint'),
		GTFL: t('Grout Test Fail Lateral'),
		GTPJ: t('Grout Test Pass Joint'),
		GTPL: t('Grout Test Pass Lateral'),
		GTUJ: t('Grout Test Unable to Test Joint'),
		GTUL: t('Grout Test Unable to Test Lateral'),
		H: t('Hole'),
		HSV: t('Hole Soil Visible'),
		HVV: t('Hole Void Visible'),
		ID: t('Infiltration Dripper'),
		IDB: t('Infiltration Dripper Barrel'),
		IDC: t('Infiltration Dripper Connection'),
		IDJ: t('Infiltration Dripper Joint'),
		IDL: t('Infiltration Dripper Lateral'),
		IG: t('Infiltration Gusher'),
		IGB: t('Infiltration Gusher Barrel'),
		IGC: t('Infiltration Gusher Connection'),
		IGJ: t('Infiltration Gusher Joint'),
		IGL: t('Infiltration Gusher Lateral'),
		IR: t('Infiltration Runner'),
		IRB: t('Infiltration Runner Barrel'),
		IRC: t('Infiltration Runner Connection'),
		IRJ: t('Infiltration Runner Joint'),
		IRL: t('Infiltration Runner Lateral'),
		IS: t('Infiltration Stain'),
		ISB: t('Infiltration Stain Barrel'),
		ISC: t('Infiltration Stain Connection'),
		ISGT: t('Intruding Sealing Material Grout'),
		ISJ: t('Infiltration Stain Joint'),
		ISL: t('Infiltration Stain Lateral'),
		ISSR: t('Intruding Sealing Material Sealing Ring'),
		ISSRB: t('Intruding Sealing Material Sealing Ring Broken'),
		ISSRH: t('Intruding Sealing Material Sealing Ring Hanging'),
		ISSRL: t(
			'Intruding Sealing Material Sealing Ring Loose/Poorly Fitting',
		),
		ISZ: t('Intruding Sealing Material Other'),
		IW: t('Infiltration Weeper'),
		IWB: t('Infiltration Weeper Barrel'),
		IWC: t('Infiltration Weeper Connection'),
		IWJ: t('Infiltration Weeper Joint'),
		IWL: t('Infiltration Weeper Lateral'),
		JAL: t('Joint Angular Large'),
		JAM: t('Joint Angular Medium'),
		JOL: t('Joint Offset Large'),
		JOM: t('Joint Offset Medium'),
		JSL: t('Joint Separated Large'),
		JSM: t('Joint Separated Medium'),
		LFAC: t('Lining Feature Abandoned Connection'),
		LFAS: t('Lining Feature Annular Space'),
		LFB: t('Lining Feature Blistered'),
		LFCS: t('Lining Feature Service Cut Shifted'),
		LFD: t('Lining Feature Detached'),
		LFDC: t('Lining Feature Discoloration'),
		LFDE: t('Lining Feature Defective End'),
		LFDL: t('Lining Feature Delaminating'),
		LFOC: t('Lining Feature Overcut Service'),
		LFRS: t('Lining Feature Resin slug'),
		LFUC: t('Lining Feature Undercut Service'),
		LFW: t('Lining Feature Wrinkled'),
		LFZ: t('Lining Feature Other'),
		MB: t('Missing Brick'),
		MGO: t('Miscellaneous General Observation'),
		MGP: t('Miscellaneous General Photo'),
		MJL: t('Miscellaneous Pipe Joint Length Change'),
		MLC: t('Miscellaneous Lining Change'),
		MMC: t('Miscellaneous Material Change'),
		MML: t('Missing Mortar Large'),
		MMM: t('Missing Mortar Medium'),
		MMS: t('Missing Mortar Small'),
		MSA: t('Miscellaneous Survey Abandoned '),
		MSC: t('Miscellaneous Shape/Size Change'),
		MWM: t('Miscellaneous Water Mark'),
		MYN: t('Miscellaneous Dye Test Not Visible'),
		MYV: t('Miscellaneous Dye Test Visible'),
		OBB: t('Obstruction Brick or Masonry'),
		OBC: t('Obstruction Through Connection'),
		OBI: t('Obstruction Intruding Through Wall'),
		OBJ: t('Obstruction Wedged In The Joint'),
		OBM: t('Obstruction Pipe Material in Invert'),
		OBN: t('Obstruction Construction Debris'),
		OBP: t('Obstruction External Pipe or Cable'),
		OBR: t('Obstruction Rocks'),
		OBS: t('Obstruction Built Into Structure'),
		OBZ: t('Obstruction Other'),
		RBB: t('Roots Ball Barrel'),
		RBC: t('Roots Ball Connection'),
		RBJ: t('Roots Ball Joint'),
		RBL: t('Roots Ball Lateral'),
		RFB: t('Roots Fine Barrel'),
		RFC: t('Roots Fine Connection'),
		RFJ: t('Roots Fine Joint'),
		RFL: t('Roots Fine Lateral'),
		RMB: t('Roots Medium Barrel'),
		RMC: t('Roots Medium Connection'),
		RMJ: t('Roots Medium Joint'),
		RML: t('Roots Medium Lateral'),
		RPL: t('Point Repair Liner'),
		RPLD: t('Point Repair Liner Defective'),
		RPP: t('Point Repair Patch'),
		RPPD: t('Point Repair Patch Patch Defective'),
		RPR: t('Point Repair Replacement'),
		RPRD: t('Point Repair Replacement Defective'),
		RPZ: t('Point Repair Other'),
		RPZD: t('Point Repair Other Defective'),
		RTB: t('Roots Tap Barrel'),
		RTC: t('Roots Tap Connection'),
		RTJ: t('Roots Tap Joint'),
		RTL: t('Roots Tap Lateral'),
		SAM: t('Surface Damage Aggregate Missing'),
		SAP: t('Surface Damage Aggregate Projecting'),
		SAV: t('Surface Damage Aggregate Visible'),
		SCP: t('Surface Damage Corrosion'),
		SMW: t('Surface Damage Missing Wall'),
		SRC: t('Surface Damage Reinforcement Corroded'),
		SRI: t('Surface Damage Roughness Increased'),
		SRP: t('Surface Damage Reinforcement Projecting'),
		SRV: t('Surface Damage Reinforcement Visible'),
		SSC: t('Surface Spalling of Damage Coating'),
		SSS: t('Surface Spalling of Damage'),
		SZ: t('Surface Damage Other'),
		VC: t('Vermin Cockroach'),
		VR: t('Vermin Rat'),
		VZ: t('Vermin Other'),
		WFC: t('Weld Feature Circumferential'),
		WFL: t('Weld Feature Longitudinal'),
		WFM: t('Weld Feature Multiple'),
		WFS: t('Weld Feature Spiral'),
		WFZ: t('Weld Feature Other'),
		X: t('Collapse'),
	};
};
