import * as React from 'react';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Stable Callback Hook
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useStableCallback = <Callback extends (...args: any[]) => any>(
  callback: Callback | undefined
): Callback => {
  const callbackRef = React.useRef(callback);

  React.useEffect(() => {
    callbackRef.current = callback;
  });

  const stableCallback = React.useMemo(
    () => ((...args) => callbackRef.current?.(...args)) as Callback,
    []
  );

  return stableCallback;
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Module Exports
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export { useStableCallback };
