import {
  fetchCachedSensorDataAction,
  fetchSensorDataAction,
} from '../../../actions/sensorData.actions';
import type {
  SensorDataRecords,
  SensorDataSource,
} from '../../types/data.types';
import { makeSelectSensorData } from '../../../selectors/sensorData.selectors';
import { stringifyRangeSelection } from '../../utils/rangeSelection.utils';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import type { RangeSelection } from '../../../types';
import type { StoreState } from '../../../store/store';

const memoizedEmptyDataSources: SensorDataSource[] = [];

/**
 * A hook that handles fetching, caching and reading operations of sensor data.
 *
 * It automatically memoizes the data sources and range selection so that
 * the returned value only changes when needed.
 *
 * @param dataSources The sources that will be used to fetch data whenever the content changes.
 * @param dataRangeSelection Min and max values to specify a range selection that will be used to "slice" data.
 * @param alwaysFetchData If `true`, the hook will skip the cache and always fetch data when it's missing.
 * @returns A record of sensor data indexed by sensor id and resolution.
 */
const useSensorData = (
  dataSources: SensorDataSource[],
  dataRangeSelection?: RangeSelection,
  alwaysFetchData?: boolean
): SensorDataRecords => {
  const dispatch = useDispatch();

  /**
   * This creates a memoized copy of the parametrized selector.
   *
   * See: https://react-redux.js.org/api/hooks#using-memoizing-selectors
   */
  const selectSensorData = useMemo(makeSelectSensorData, []);

  /**
   * The selector will generate a slice of the data in redux,
   * that means that the returned object will always be different.
   *
   * Most likely, the ones using the lib won't memoize their
   * dataSources / rangeSelection objects... so we're doing it
   * to only generate a slice of the redux data when
   * the params content actually change.
   */
  const memoizedDataSources = useMemo(() => {
    return dataSources;
  }, [stringifySensorDataSources(dataSources)]);

  const memoizedRangeSelection = useMemo(() => {
    return dataRangeSelection;
  }, [stringifyRangeSelection(dataRangeSelection)]);

  const sensorDataRecords = useSelector((state: StoreState) => {
    return selectSensorData(
      state,
      memoizedDataSources ?? memoizedEmptyDataSources,
      memoizedRangeSelection
    );
  });

  useEffect(() => {
    if (alwaysFetchData) {
      dataSources.forEach((source) => {
        dispatch(fetchSensorDataAction(source));
      });
    } else {
      dispatch(fetchCachedSensorDataAction(dataSources));
    }
  }, [memoizedDataSources, alwaysFetchData]);

  return sensorDataRecords;
};

const stringifySensorDataSources = (
  dataSources: SensorDataSource[]
): string => {
  return dataSources
    .map((source) => `${source.sensorId}${source.resolution}`)
    .join('');
};

export default useSensorData;
