import { GridFilterModel, getDefaultGridFilterModel } from '@mui/x-data-grid';
import React, {
	ReactNode,
	createContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import {
	addExcludedFilter,
	addPolygonFilter,
	hasExcludedFilter,
	removeExcludedFilter,
	showExcluded,
} from '@Utils/filter';
import { useDispatch, useSelector } from 'react-redux';

import { ExcludeFilter } from '@Utils/types';
import { assetGridClearExcludedList } from '@Actions/asset';
import { selectGridExcludeList } from '@Selectors/asset';
import { usePrevious } from '@innovyze/stylovyze';

type ProcessFilterFunction = (filter: GridFilterModel) => GridFilterModel;

export interface FilterContextProps {
	rawFilter: GridFilterModel;
	setFilter: React.Dispatch<React.SetStateAction<GridFilterModel>>;
	setProcessFilterFunc: React.Dispatch<
		React.SetStateAction<ProcessFilterFunction>
	>;
	filter: GridFilterModel;
	setPolygonFilter: (
		filter: GeoJSON.Feature<GeoJSON.Polygon> | undefined,
	) => void;
	clearFilters: () => void;
	additionalFilterProps: Record<string, unknown>;
	setAdditionalFilterProps: React.Dispatch<
		React.SetStateAction<Record<string, unknown>>
	>;
	excludedFilter?: ExcludeFilter;
}

export const FilterContext = createContext<FilterContextProps | null>(null);

export const useFilter = (): FilterContextProps => {
	return (
		React.useContext(FilterContext) ?? {
			rawFilter: getDefaultGridFilterModel(),
			setFilter: () => undefined,
			setProcessFilterFunc: () => undefined,
			filter: getDefaultGridFilterModel(),
			setPolygonFilter: () => undefined,
			clearFilters: () => undefined,
			additionalFilterProps: {},
			setAdditionalFilterProps: () => undefined,
		}
	);
};

interface FilterProviderProps {
	children: ReactNode;
}

export const FilterProvider = ({
	children,
}: FilterProviderProps): JSX.Element => {
	const [rawFilter, setRawFilter] = useState<GridFilterModel>(
		getDefaultGridFilterModel(),
	);
	const previousFilter = usePrevious(rawFilter);
	const [processFilterFunc, setProcessFilterFunc] = useState<
		ProcessFilterFunction
	>(() => (filter: GridFilterModel) => filter);
	const [additionalFilterProps, setAdditionalFilterProps] = useState<
		Record<string, unknown>
	>({});

	const excludedList = useSelector(selectGridExcludeList);
	const dispatch = useDispatch();

	const setPolygonFilter: FilterContextProps['setPolygonFilter'] = polygonFilter => {
		setRawFilter(state => addPolygonFilter(state, polygonFilter));
	};

	const initExcludedFilter = () => {
		setRawFilter(state => addExcludedFilter(state));
	};

	const clearExcludedFilter = () => {
		setRawFilter(state => removeExcludedFilter(state));
	};

	const filter = useMemo(
		() => processFilterFunc(removeExcludedFilter(rawFilter)),
		[rawFilter, processFilterFunc],
	);

	const clearFilters = () => {
		setRawFilter(getDefaultGridFilterModel());
	};

	const excludedFilter = useMemo(() => {
		if (hasExcludedFilter(rawFilter)) {
			return {
				showExcludedOnly: showExcluded(rawFilter),
				excludedIds: excludedList,
			};
		}
	}, [rawFilter, excludedList]);

	useEffect(() => {
		if (excludedList.length === 0) {
			clearExcludedFilter();
		} else {
			initExcludedFilter();
		}
	}, [excludedList]);

	useEffect(() => {
		// clear the excluded list when the excluded filter is removed
		if (
			previousFilter &&
			!hasExcludedFilter(rawFilter) &&
			hasExcludedFilter(previousFilter)
		) {
			dispatch(assetGridClearExcludedList());
		}
	}, [rawFilter, previousFilter]);

	return (
		<FilterContext.Provider
			value={{
				rawFilter,
				setFilter: setRawFilter,
				setProcessFilterFunc,
				filter,
				setPolygonFilter,
				clearFilters,
				additionalFilterProps,
				setAdditionalFilterProps,
				excludedFilter,
			}}>
			{children}
		</FilterContext.Provider>
	);
};
