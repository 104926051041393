import { selectSettings } from '@innovyze/stylovyze';
import Highcharts from 'highcharts';
import React from 'react';

const useTimeZone = (highcharts: typeof Highcharts): void => {
  const {
    companySettings: { timeZoneIANA },
  } = selectSettings();

  React.useEffect(() => {
    highcharts.setOptions({
      time: { timezone: timeZoneIANA },
    });
  }, [timeZoneIANA]);
};

export default useTimeZone;
