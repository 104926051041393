import styled, { css } from 'styled-components';

import { createStyles, makeStyles } from '@mui/styles';
import { rem } from '@Utils/styles';

export const useStyle = makeStyles(() =>
	createStyles({
		root: {
			overflow: 'auto',
		},
		rootWait: {
			overflow: 'auto',
			cursor: 'wait !important',
		},
		fullHeight: {
			height: '100%',
		},
	}),
);

export const GridContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-gap: ${rem(24)};
	padding-bottom: ${rem(24)};
`;

export const GridItem = styled.div<{ xs?: number; rows?: number }>`
	grid-column: span ${({ xs }) => xs ?? 12};
	${({ rows }) =>
		rows &&
		css`
			grid-row: span ${rows};
		`}
`;
