import { StyledSearch, StyledTextField } from '../SearchBar/SearchBar.styles';
import { createStyles, makeStyles } from '@mui/styles';

import React from 'react';
import { Search } from '@mui/icons-material';
import { rem } from '@Utils/styles';

export interface SearchBarProps {
	label: string;
	defaultValue: string;
	handleSearchSubmit: (val: string) => void;
	topRem?: number;
	leftRem?: number;
}

const useStyle = makeStyles(() =>
	createStyles({
		search: (props: { topRem: number; leftRem: number }) => ({
			position: 'absolute',
			top: `${rem(props.topRem)}`,
			left: `${rem(props.leftRem)}`,
		}),
	}),
);

const SearchBar = ({
	topRem = 16,
	leftRem = 15,
	...props
}: SearchBarProps): JSX.Element => {
	const classes = useStyle({ topRem: topRem, leftRem: leftRem });
	const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (event.key === 'Enter') {
			const target = event?.target as HTMLInputElement;
			props.handleSearchSubmit(target?.value);
		}
	};

	return (
		<StyledSearch>
			<Search className={classes.search} />
			<StyledTextField
				label={props.label}
				defaultValue={props.defaultValue}
				margin="normal"
				variant="outlined"
				onKeyDown={handleKeyDown}
			/>
		</StyledSearch>
	);
};

export default SearchBar;
