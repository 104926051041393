import { generateMassBalanceSeries } from './ConnectedMassBalanceChart.utils';
import ChartingErrorBoundary from '../../atoms/ChartingErrorBoundary';
import React, { forwardRef, useMemo } from 'react';
import useMassBalance from '../../../core/hooks/useMassBalance';
import type { MassBalanceChartProps } from '../../molecules/MassBalanceChart';
import type { RangeSelection } from '../../../types/chartState';
import type { StackableChartRef } from '../../../core/components/StackableChart';
import {
  InsightMassBalanceChart,
  InsightMassBalanceChartSeriesProps,
} from '../../../_next/presets/insight-mass-balance';
import { TimeRangeSelection } from '../../../_next/core/_insight-chart';
import { Theme } from '../../../_next/core/utils/theme-utils';
export interface ConnectedMassBalanceChartProps
  extends Omit<MassBalanceChartProps, 'series' | 'data'> {
  alwaysFetchData?: boolean;
  dataRangeSelection?: RangeSelection;
  massBalanceId: string;
  customData?: {
    series?: (InsightMassBalanceChartSeriesProps & {
      customData: [number, number | null][];
    })[];
  };
  timeRangeSelection?: TimeRangeSelection;
  selectedTheme?: Theme;
  onTimeRangeSelectionChange?: (timeRangeSelection: TimeRangeSelection) => void;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Version Switch
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const ConnectedMassBalanceChart = (
  props: ConnectedMassBalanceChartProps,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref?: any
): React.ReactElement => {
  return <MassBalanceChartV2 {...props} ref={ref} />;
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * New Chart
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const MassBalanceChartV2 = React.forwardRef<
  { chart: Highcharts.Chart | undefined },
  ConnectedMassBalanceChartProps
>((props, ref): React.ReactElement => {
  const asyncMassBalance = useMassBalance(props.massBalanceId);

  const massBalanceSeries = useMemo(() => {
    if (props.customData) return [];

    const generatedSeries =
      asyncMassBalance.status === 'resolved'
        ? generateMassBalanceSeries(asyncMassBalance.data)
        : [];

    return generatedSeries.map((series) => {
      return {
        id: series.id,
        sensorId: series.dataSource.sensorId,
        resolution: series.dataSource.resolution,
        hidden: props.hiddenSeries?.includes(series.id),
      } as InsightMassBalanceChartSeriesProps;
    });
  }, [
    asyncMassBalance.status,
    asyncMassBalance.data,
    props.hiddenSeries?.join(''),
    props.customData,
  ]);

  const stacked = props.displayOptions?.overlay === false ? true : false;

  return (
    <ChartingErrorBoundary chartProps={props}>
      <InsightMassBalanceChart
        ref={ref}
        customData={props.customData}
        series={massBalanceSeries}
        enableChartStack={stacked}
        openSnapshot={!props.hiddenElements?.includes('snapshot')}
        enableHorizontalGrids={props.displayOptions?.showYGrid}
        enableVerticalGrids={props.displayOptions?.showXGrid}
        enableMarkers={props.displayOptions?.showMarkers}
        timeRangeSelection={props.timeRangeSelection}
        onTimeRangeSelectionChange={props.onTimeRangeSelectionChange}
        onOpenSnapshotChange={(open) => {
          const hiddenElements = props.hiddenElements
            ? [...props.hiddenElements]
            : [];

          const newHiddenElements = open
            ? hiddenElements.filter((series) => series !== 'snapshot')
            : [...hiddenElements, 'snapshot'];

          props.onHiddenElementsChange?.(newHiddenElements);
        }}
        onSeriesVisibilityChange={(seriesId, type) => {
          const hiddenSeries = props.hiddenSeries
            ? [...props.hiddenSeries]
            : [];

          const newHiddenSeries =
            type === 'hide'
              ? [...hiddenSeries, seriesId]
              : hiddenSeries.filter((series) => series !== seriesId);

          props.onHiddenSeriesChange?.(newHiddenSeries);
        }}
        selectedTheme={props.selectedTheme}
      />
    </ChartingErrorBoundary>
  );
});

MassBalanceChartV2.displayName = 'MassBalanceChartV2';

export default forwardRef<StackableChartRef, ConnectedMassBalanceChartProps>(
  ConnectedMassBalanceChart
);
