import { Property, PropertyCard } from '@innovyze/stylovyze';

import { FeatureItem } from '@Utils/types';
import React from 'react';
import { useGlobalization } from '@Translations/useGlobalization';
import { useHistory } from 'react-router-dom';

interface IncidentPropertiesProps {
	item: FeatureItem;
}

export const IncidentProperties = ({
	item,
}: IncidentPropertiesProps): JSX.Element => {
	const { t } = useGlobalization();
	const history = useHistory();

	const properties: Property[] = [
		{
			title: t('Incident ID', { context: 'Incident properties' }),
			/** sequence is the user friendly incident id */
			value: `${item.sequence}`,
			cy: 'incidentId',
		},
	];

	const goToIncident = () => {
		history.push(`/incident-management/incident/${item.id}`);
	};

	return (
		<PropertyCard
			title={item.sequence}
			properties={properties}
			menuItems={[
				{
					text: t('View Details', {
						context: 'Incident properties menu item',
					}),
					onClick: goToIncident,
				},
			]}
			marginBottom
		/>
	);
};
