import { MapPosition } from '@innovyze/inno-map';
import { DataServiceType } from '@innovyze/inno-map/map/services/types';

import { assetsBaseUrl } from '@Apis';
import { SourceOptions, GeneralOptions, MapOptions, MapLocation } from '@Types';
import { Extent } from '@innovyze/shared-utils';
import { propertyLookupRiskScore } from './mapDefinitions.utils';

const queryExtent = (
	opts: GeneralOptions,
	extent: Extent | undefined,
): string =>
	opts.useBBox && extent && extent.length === 4
		? `&bbox=${extent[0].toFixed(opts.precisionLonLat)}` +
		  `,${extent[1].toFixed(opts.precisionLonLat)}` +
		  `,${extent[2].toFixed(opts.precisionLonLat)}` +
		  `,${extent[3].toFixed(opts.precisionLonLat)}`
		: '';

const queryLLR = (
	opts: GeneralOptions,
	position: MapPosition | undefined,
	radius: number | undefined,
): string =>
	position && radius
		? `&lon=${position.center[0].toFixed(opts.precisionLonLat)}` +
		  `&lat=${position.center[1].toFixed(opts.precisionLonLat)}` +
		  `&rad=${radius.toFixed(opts.precisionRadius)}`
		: '';

const queryOthers = (opts: GeneralOptions): string =>
	opts.singlePage ? '&singlePage=1' : '';

const query = (opts: GeneralOptions, location: MapLocation): string =>
	queryLLR(opts, location.position, location.radius) +
	queryExtent(opts, location.extent) +
	queryOthers(opts);

const url = (
	source: SourceOptions,
	opts: GeneralOptions,
	location: MapLocation,
): string => `${source.url}?${query(opts, location).slice(1)}`;

export const dataServices = (
	token: string | undefined,
	options: MapOptions,
	location?: MapLocation,
): DataServiceType<unknown>[] => {
	if (!token) {
		return [];
	}

	const sources: DataServiceType<unknown>[] = [];

	if (options.tilesSource) {
		sources.push({
			displayName: options.tilesSource.layerName,
			id: options.tilesSource.dataServiceId,
			type: options.tilesSource.type,
			url: options.tilesSource.url,
			token: token,
			propsUrl: {
				any: `${assetsBaseUrl}assets/{{assetId}}?assetId={{assetId}}&assetType={{assetType}}`,
				sensor: `${assetsBaseUrl}sensors/{{assetId}}`,
			},
			propertyLookup: {
				risk: propertyLookupRiskScore(),
			},
		});
	}

	if (options.inspectionSources && location && location.extent) {
		options.inspectionSources.forEach(s => {
			sources.push({
				displayName: s.layerName,
				id: s.dataServiceId,
				type: s.type,
				url: url(s, options.generalOptions, location),
				token: token,
			});
		});
	}

	if (options.otherSource) {
		sources.push({
			displayName: options.otherSource.layerName,
			id: options.otherSource.dataServiceId,
			type: options.otherSource.type,
			url: options.otherSource.url,
			token: token,
		});
	}

	return sources;
};
