import { FeatureItem, ItemDetailsSelector } from '@Utils/types';

import { ItemInList } from './Tab.styles';
import React from 'react';
import { useItemDetails } from '@Hooks/useItemDetails';

interface TabListItemProps extends React.HTMLAttributes<HTMLDivElement> {
	item: FeatureItem;
	getItemName: (item: FeatureItem) => string;
	getItemDetailsSelector?: ItemDetailsSelector;
}

export const TabListItem = ({
	item,
	getItemName,
	getItemDetailsSelector,
	...other
}: TabListItemProps): JSX.Element => {
	const itemDetails = useItemDetails(item, getItemDetailsSelector);
	return (
		<ItemInList data-cy="item" {...other}>
			{getItemName(itemDetails)}
		</ItemInList>
	);
};
