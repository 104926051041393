import React from 'react';
import { StyledImage, StyledContainer } from './ImageViewer.styles';

interface ImageViewerProps {
	imageSrc: string;
	cy?: string;
}

export const ImageViewer = ({
	imageSrc,
	cy,
}: ImageViewerProps): JSX.Element => {
	return (
		<StyledContainer>
			<StyledImage src={imageSrc} data-cy={cy} />
		</StyledContainer>
	);
};
