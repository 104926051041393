import React, { useEffect, useRef } from 'react';
import Highcharts from 'highcharts';
import { StyledContainer } from './LineStylePreview.styles';

interface LineStylePreviewProps {
  dashStyle: string;
  width?: number;
  color?: string;
}

const LineStylePreview = ({
  dashStyle,
  width = 200,
  color = 'black',
}: LineStylePreviewProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderRef = useRef<any>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (renderRef.current !== null) {
      renderRef.current.destroy();
      renderRef.current = null;
    }

    renderRef.current = new Highcharts.Renderer(
      containerRef.current,
      width,
      20
    );

    renderRef.current
      .path(['M', 0, 10, 'L', width, 10])
      .attr({
        'stroke-width': 2,
        stroke: color,
        dashstyle: dashStyle,
      })
      .add();
  }, [dashStyle, width, color]);

  return <StyledContainer ref={containerRef}></StyledContainer>;
};

export default LineStylePreview;
