/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  fixEdgeResponseResults,
  getEdgeAutoResolution,
  getEdgeLowestResolution,
} from '../insight-historical-chart/insight-historical-chart.utils';
import {
  useIsFeatureEnabled,
  useSelectSensors,
  useSettings,
} from '@innovyze/stylovyze';
import * as IliChart from '../../modules/ili-chart';
import * as InsightChart from '../../core/_insight-chart';
import * as React from 'react';
import * as SeriesData from '../../core/series-data';
import * as TimeSeriesData from '../../core/time-series-data';
import * as TimeSeriesDataOld from '../../core/time-series-data-old';
import { fixCollectionInterval } from '../../core/time-series-data/utils';

type EdgeSource = {
  id: string;
  sensorId: string;
  resolution: TimeSeriesDataOld.Resolution;
  reading: TimeSeriesDataOld.Reading;
};

function edgeSourceStringifier(source: EdgeSource): string {
  return `${source.id}:${source.sensorId}:${source.resolution}:${source.reading}`;
}

function limit(value: string | null | undefined, defaultValue = 500): number {
  if (typeof value === 'string' || value === null) return defaultValue;
  if (isNaN(Number(value))) return defaultValue;
  return Number(value);
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Chart Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

interface InsightIliChartProps
  extends Omit<
    IliChart.IliChartRootProps,
    'children' | 'onXAxisExtremesChange' | 'syncDateTimeRangePicker'
  > {
  series: InsightIliChartSeriesProps[];
  customData?: {
    series?: (InsightIliChartSeriesProps & {
      customData: [number, number | null][];
    })[];
  };
  timeRangeSelection?: InsightChart.TimeRangeSelection;
  onTimeRangeSelectionChange?: (
    timeRangeSelection: InsightChart.TimeRangeSelection
  ) => void;
}

const InsightIliChart = React.forwardRef<
  { chart: Highcharts.Chart | undefined },
  InsightIliChartProps
>((props, ref): React.ReactElement => {
  const { companySettings } = useSettings();
  const { sensors, initialized: sensorsInitialized } = useSelectSensors();
  const dataLimit = useIsFeatureEnabled('info-360-analytics-hp2-charts-limit');

  const alreadyRetrieved = React.useRef<boolean>(false);
  const endEdgeTouched = React.useRef<boolean>();
  const limitFromRef = React.useRef<'start' | 'end'>();

  const [edgeSources, edgeSourceStrings] =
    SeriesData.useSources<EdgeSource>(() => {
      if (props.customData || !props.series?.length) return [];
      return props.series.map(({ id, sensorId, resolution, reading }) => ({
        id,
        sensorId,
        resolution,
        reading,
      }));
    }, [props.series, props.customData]);

  const [edgeData, edgeStatus, retrieveEdgeData] = SeriesData.useRetriever<
    TimeSeriesData.ResponseBodyWithSnapping,
    {
      timeSelection?: TimeSeriesData.PartialTimeSelection;
      snapping?: TimeSeriesData.Snapping;
    }
  >(
    async (signal, params) => {
      if (props.customData || !edgeSources?.length || !sensorsInitialized)
        return;

      const timeSelection = {
        from: params.timeSelection?.from ?? 'oldest',
        to: params.timeSelection?.to ?? 'latest',
      } as TimeSeriesData.TimeSelection;

      const response = await TimeSeriesData.retrieve(signal, {
        order: 'asc',
        timeSelection,
        limit: limit(dataLimit),
        snapping: params.snapping ?? 'oldest',
        timeZone: companySettings.timeZoneIANA,
        data_version: 'v3.0',
        sources: edgeSources.map((s) => {
          const _sensor = sensors.find((_s) => _s.sensorId === s.sensorId);
          const seconds = fixCollectionInterval(_sensor?.collectionInterval);
          const resolution =
            s.resolution === 'AUTO'
              ? getEdgeAutoResolution(_sensor?.resolutions, timeSelection)
              : s.resolution;

          return {
            key: edgeSourceStringifier(s),
            sensorId: s.sensorId,
            collectionInterval: { seconds },
            analytic: TimeSeriesData.makeAnalytic(
              resolution,
              s.reading ?? 'Close'
            ),
          };
        }),
      });

      const snapSelection = TimeSeriesData.getSnapSelection(response.data);
      const result = { ...response.data, snapSelection };
      result.results = fixEdgeResponseResults(edgeSources, result.results);

      return result;
    },
    [
      companySettings.timeZoneIANA,
      dataLimit,
      edgeSources,
      sensors,
      sensorsInitialized,
    ]
  );

  const [snapshotEdgeData, _snapshotEdgeStatus, retrieveSnapshotEdgeData] =
    SeriesData.useRetriever<TimeSeriesData.ResponseBody>(
      async (signal) => {
        if (props.customData || !edgeSources?.length || !sensorsInitialized)
          return;

        const response = await TimeSeriesData.retrieve(signal, {
          order: 'asc',
          timeZone: companySettings.timeZoneIANA,
          timeSelection: { from: 'oldest', to: 'latest' },
          data_version: 'v3.0',
          sources: edgeSources.map((s) => {
            const _sensor = sensors.find((_s) => _s.sensorId === s.sensorId);
            const seconds = fixCollectionInterval(_sensor?.collectionInterval);

            return {
              key: edgeSourceStringifier(s),
              sensorId: s.sensorId,
              collectionInterval: { seconds },
              analytic: TimeSeriesData.makeAnalytic(
                'DAILY',
                s.reading ?? 'Close'
              ),
            };
          }),
        });

        return response.data;
      },
      [
        companySettings.timeZoneIANA,
        edgeSources,
        props.customData,
        sensors,
        sensorsInitialized,
      ]
    );

  const [timeRangeSelection, setTimeRangeSelection] = React.useState({
    ...props.timeRangeSelection,
  });

  const edgeLowestResolution = React.useMemo(() => {
    if (!props.series?.length) return undefined;
    return getEdgeLowestResolution(props.series.map((s) => s.resolution));
  }, [props.series]);

  const snapSelectionRef = React.useRef(edgeData?.snapSelection);

  const sourceKeysRef = React.useRef(edgeSourceStrings);

  const onTimeRangeSelectionChangeRef = React.useRef(
    props.onTimeRangeSelectionChange
  );

  onTimeRangeSelectionChangeRef.current = props.onTimeRangeSelectionChange;

  React.useEffect(() => {
    setTimeRangeSelection({
      min: props.timeRangeSelection?.min,
      max: props.timeRangeSelection?.max,
    });
  }, [props.timeRangeSelection?.min, props.timeRangeSelection?.max]);

  React.useEffect(() => {
    snapSelectionRef.current = {
      end: edgeData?.snapSelection?.end,
      start: edgeData?.snapSelection?.start,
    };

    if (
      snapSelectionRef.current.start !== undefined &&
      snapSelectionRef.current.end !== undefined
    ) {
      setTimeRangeSelection({
        min: snapSelectionRef.current?.start,
        max: snapSelectionRef.current?.end,
      });

      onTimeRangeSelectionChangeRef.current?.({
        min: snapSelectionRef.current?.start,
        max: snapSelectionRef.current?.end,
      });
    }
  }, [edgeData?.snapSelection?.end, edgeData?.snapSelection?.start]);

  // Fetch data
  React.useEffect(() => {
    if (props.customData) return;
    if (!sensorsInitialized) return;
    if (!edgeSourceStrings?.length) return;

    const sourceKeysChanged =
      edgeSourceStrings?.join() !== sourceKeysRef.current?.join();

    let start = timeRangeSelection?.min;
    let end = timeRangeSelection?.max;

    if (sourceKeysChanged) {
      if (snapSelectionRef.current?.start !== undefined) {
        start = snapSelectionRef.current.start;
      }

      if (snapSelectionRef.current?.end !== undefined) {
        end = snapSelectionRef.current.end;
      }
    }

    if (
      !sourceKeysChanged &&
      alreadyRetrieved.current &&
      timeRangeSelection?.min === snapSelectionRef.current.start &&
      timeRangeSelection?.max === snapSelectionRef.current.end
    ) {
      return;
    }

    retrieveEdgeData({
      snapping: limitFromRef.current === 'start' ? 'latest' : 'oldest',
      timeSelection: { from: start, to: end },
    });

    if (
      endEdgeTouched.current === true ||
      alreadyRetrieved.current === false ||
      sourceKeysChanged
    ) {
      retrieveSnapshotEdgeData();
    }

    alreadyRetrieved.current = true;
    limitFromRef.current = undefined;
    endEdgeTouched.current = undefined;
    sourceKeysRef.current = edgeSourceStrings;
  }, [
    edgeSourceStrings,
    props.customData,
    retrieveEdgeData,
    retrieveSnapshotEdgeData,
    sensorsInitialized,
    timeRangeSelection?.max,
    timeRangeSelection?.min,
  ]);

  return (
    <IliChart.IliChartRoot
      {...props}
      ref={ref}
      defaultLimit={limit(dataLimit)}
      snapSelection={edgeData?.snapSelection}
      lowestResolution={edgeLowestResolution}
      onXAxisExtremesChange={(min, max, _endEdgeTouched) => {
        endEdgeTouched.current = _endEdgeTouched;

        limitFromRef.current = getLimitFrom(
          timeRangeSelection?.min,
          timeRangeSelection?.max,
          min,
          max
        );

        setTimeRangeSelection({ min, max });
        props.onTimeRangeSelectionChange?.({ min, max });
      }}
      status={props.customData?.series ? 'resolved' : edgeStatus}
      selectedTheme={props.selectedTheme}>
      <IliChart.IliChartSeriesGroup>
        {props.customData?.series
          ? props.customData.series.map((seriesProps, seriesIndex) => {
              return (
                <InsightIliChartSeries
                  {...seriesProps}
                  key={seriesIndex}
                  status={'resolved'}
                  customData={seriesProps.customData}
                />
              );
            })
          : props.series.map((seriesProps, seriesIndex) => {
              const { id, sensorId, resolution, reading } = seriesProps;
              const key = edgeSourceStringifier({
                id,
                sensorId,
                resolution,
                reading,
              });
              const edgeDataEntry = edgeData?.results?.[key];
              const snapshotEdgeDataEntry = snapshotEdgeData?.results?.[key];

              return (
                <InsightIliChartSeries
                  {...seriesProps}
                  key={seriesIndex}
                  status={edgeStatus}
                  edgeDataEntry={edgeDataEntry}
                  snapshotEdgeDataEntry={snapshotEdgeDataEntry}
                />
              );
            })}
      </IliChart.IliChartSeriesGroup>
    </IliChart.IliChartRoot>
  );
});

InsightIliChart.displayName = 'InsightIliChart';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Series Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

type InsightIliChartSeriesProps = Omit<
  IliChart.IliChartSeriesProps,
  'navigatorData'
> & {
  sensorId: string;
  resolution: InsightChart.Resolution;
  reading?: InsightChart.Reading;
  customData?: [number, number | null][];
  edgeDataEntry?: TimeSeriesData.ResponseResultEntry;
  snapshotEdgeDataEntry?: TimeSeriesData.ResponseResultEntry;
};

const InsightIliChartSeries = (
  props: InsightIliChartSeriesProps
): React.ReactElement => {
  const unit = React.useMemo(() => {
    return props.edgeDataEntry?.unit;
  }, [props.edgeDataEntry?.unit]);

  const processedData = React.useMemo(() => {
    if (props.customData) return props.customData;
    return props.edgeDataEntry?.data as TimeSeriesDataOld.TimeSeriesData;
  }, [props.customData, props.edgeDataEntry?.data]);

  const processedNavigatorData = React.useMemo(() => {
    if (props.customData) return props.customData;
    return props.snapshotEdgeDataEntry
      ?.data as TimeSeriesDataOld.TimeSeriesData;
  }, [props.customData, props.snapshotEdgeDataEntry?.data]);

  return (
    <IliChart.IliChartSeries
      {...props}
      data={processedData}
      navigatorData={processedNavigatorData}
      unit={unit}
    />
  );
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Utils
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const getDiff = (a: number, b: number): number =>
  Math.abs(a > b ? a - b : b - a);

const getLimitFrom = (
  prevMin: number | undefined,
  prevMax: number | undefined,
  min: number | undefined,
  max: number | undefined
) => {
  let limitFrom: 'start' | 'end' = 'end';

  if (
    prevMin !== undefined &&
    prevMax !== undefined &&
    min !== undefined &&
    max !== undefined
  ) {
    const minDiff = getDiff(prevMin, min);
    const maxDiff = getDiff(prevMax, max);

    if (minDiff > maxDiff) {
      limitFrom = 'start';
    }
  }

  return limitFrom;
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export { InsightIliChart };

export type { InsightIliChartProps, InsightIliChartSeriesProps };
