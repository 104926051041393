import { stringifySource } from './utils';
import * as React from 'react';
import type { RequestBodyType, Source } from './types';

export function useSources(
  sources: Source[],
  options?: { type?: RequestBodyType }
): { stable: Source[]; map: Map<string, Source>; keys: string[] } {
  const _keys = sources.map((source) => stringifySource(source, options));

  const keys = React.useMemo(() => _keys, [_keys.join()]);

  const stable = React.useMemo(
    () => sources.map((source, index) => ({ ...source, key: _keys[index] })),
    [_keys.join()]
  );

  const map = React.useMemo(() => {
    const map = new Map<string, Source>();
    for (const source of stable) {
      map.set(source.key, source);
    }
    return map;
  }, [stable]);

  return { stable, keys, map };
}
