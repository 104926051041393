import {
	ColumnFilterOptions,
	ColumnFilterValue,
	Filter,
	FilterDateTime,
	FilterOperator,
	FilterStoreState,
	SelectDict,
	SelectProps,
} from '@Types/filter.types';
import { IdCaption, Translations } from '@Types/general.types';

export const noFilters = (): IdCaption => ({ id: 'all', caption: 'All' });

export const filterDateOptions = (): IdCaption[] => [
	noFilters(),
	{ id: FilterDateTime.Today, caption: 'Today' },
	{ id: FilterDateTime.LastWeek, caption: 'Last Week' },
	{ id: FilterDateTime.LastMonth, caption: 'Last Month' },
	{ id: FilterDateTime.LastYear, caption: 'Last Year' },
];

const getDateGTE = (when: string): Date => {
	const now = new Date();
	switch (when) {
		case FilterDateTime.LastWeek:
			now.setHours(now.getHours() - 24 * 7);
			break;
		case FilterDateTime.LastMonth:
			now.setMonth(now.getMonth() - 1);
			break;
		case FilterDateTime.LastYear:
			now.setFullYear(now.getFullYear() - 1);
			break;
	}

	return new Date(now.getFullYear(), now.getMonth(), now.getDate());
};

export const filterByIdSelect = (
	option: ColumnFilterValue,
	idCaptions: IdCaption[],
): Filter[] => {
	if (option.value !== 'all') {
		const value = idCaptions.find(value => value.id === option.value);
		if (value) {
			return [
				{
					colKey: option.colKey,
					value: value.id,
				},
			];
		}
	}

	return [];
};

export const filterByMultipleIdSelect = (
	option: ColumnFilterValue,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	_idCaptions: IdCaption[],
): Filter[] => {
	if (option.value.length != 0) {
		return [
			{
				colKey: option.colKey,
				value: option.value,
			},
		];
	}

	return [];
};

export const filterByDatesSelect = (
	option: ColumnFilterValue,
	idCaptions: IdCaption[],
): Filter[] => {
	if (option.value !== 'all') {
		const value = idCaptions.find(value => value.id === option.value);
		if (value) {
			return [
				{
					colKey: option.colKey,
					value: getDateGTE(value.id).toISOString(),
					operator: FilterOperator.GreaterOrEqual,
				},
			];
		}
	}
	return [];
};

const queryParamFilter = (filter: Filter): string => {
	if (filter.value === noFilters().id) {
		return '';
	}

	let f = '';

	if ((filter?.colKey.length ?? 0) > 0) {
		if (filter?.multiple) {
			const values = filter.value as string[];
			f = `${filter.colKey}|${encodeURIComponent(values.join('|'))}`;
		} else {
			f = `${filter.colKey}|${encodeURIComponent(
				filter.value as string,
			)}`;
		}
	}

	if (
		f.length &&
		(filter?.operator ?? FilterOperator.Equal) !== FilterOperator.Equal
	) {
		f += '|' + encodeURIComponent(filter.operator ?? FilterOperator.Equal);
	}
	return f;
};

export const queryParamFilters = (filters: Filter[]): string => {
	const fs = filters.reduce((acc, cur) => {
		const q = queryParamFilter(cur);
		return q.length ? `${acc},${encodeURIComponent(q)}` : acc;
	}, '');

	return fs.length > 1 ? encodeURI(`&filters=${fs.substr(1)}`) : '';
};

export const translations = (translations: IdCaption[]): Translations =>
	Object.fromEntries(translations.map(t => [t.id, t.caption]));

export const translate = (
	idCaption: IdCaption,
	translations: Translations,
): IdCaption => {
	const caption = translations?.[idCaption.id] ?? idCaption.caption;
	return { id: idCaption.id, caption };
};

export const translateSelectProps = (
	props: SelectProps[],
	translations: Translations,
): SelectProps[] =>
	props.map(p => ({
		...p,
		column: translate(p.column, translations),
		options: p.options.map(o => translate(o, translations)),
	}));

export const updateChangeFilterSelect = (
	state: FilterStoreState,
	column: ColumnFilterValue,
): FilterStoreState => {
	if (!(column.colKey in state.selects)) {
		return { ...state };
	}

	const select = {
		...state.selects[column.colKey],
		value: column.value,
	};

	const filters = { ...state.filters };

	if (select.filterBy)
		filters[column.colKey] = select.filterBy(column, select.options);

	const all = Object.values(filters).flatMap(f => f);
	const query = queryParamFilters(all);

	const selects: SelectDict = {};

	for (const k in state.selects) {
		selects[k] = k === column.colKey ? select : state.selects[k];
	}

	return {
		...state,
		selects,
		query,
		filters,
	};
};

export const changeMultipleFiltersSelect = (
	oldState: FilterStoreState,
	columns: ColumnFilterValue[],
): FilterStoreState => {
	let newState = { ...oldState };
	columns?.forEach(column => {
		if (!(column.colKey in newState.selects)) return;

		const select = {
			...newState.selects[column.colKey],
			value: column.value,
		};

		const filters = { ...newState.filters };

		if (select.filterBy) {
			filters[column.colKey] = select.filterBy(column, select.options);
		}

		const all = Object.values(filters).flatMap(f => f);
		const query = queryParamFilters(all);

		const selects: SelectDict = {};

		for (const key in newState.selects) {
			selects[key] =
				key === column.colKey ? select : newState.selects[key];
		}

		newState = { ...newState, selects, query, filters };
	});

	return newState;
};

export const updateColumnFilterSelectOptions = (
	state: FilterStoreState,
	opts: ColumnFilterOptions,
): FilterStoreState => {
	const select = state.selects[opts.colKey];

	if (!select) {
		return { ...state };
	}

	const options: IdCaption[] = opts.options.length
		? opts.options
		: [noFilters()];

	const selects = { ...state.selects };
	selects[opts.colKey] = {
		...select,
		options,
	};

	return {
		...state,
		selects,
	};
};

export const updateTranslations = (
	state: FilterStoreState,
	translations: Translations,
): FilterStoreState => {
	const selects: SelectDict = {};
	translateSelectProps(Object.values(state.selects), translations).forEach(
		d => (selects[d.column.id] = d),
	);

	return {
		...state,
		selects,
	};
};
