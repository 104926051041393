import './DetailsCard.styles.css';

import React, { ReactNode } from 'react';
import { Grid, Paper } from '@mui/material';
import { Text } from '@innovyze/stylovyze';

interface Props {
	/** Highlight to the left of the card */
	highlight?: 'normal' | 'error' | 'warning' | 'information';
	/** The heading text or react node for more complex layouts */
	heading: string | ReactNode;
	/** Action react node to goto the right of the header card, if not specified heading will take the whole top  */
	action?: ReactNode | string;
	/** Body of the card as a string or react node */
	contents: string | ReactNode;
	/** Data-Cy of the card for testing*/
	dataCy: string;
}

const DetailsCard = ({
	highlight = 'normal',
	heading,
	action,
	contents,
	dataCy,
}: Props) => {
	const getHeading = (
		heading: string | ReactNode,
	): JSX.Element | ReactNode => {
		if (typeof heading === 'string') {
			return <Text variant="heading-medium-strong">{heading}</Text>;
		} else {
			return heading;
		}
	};

	const getAction = (action: string | ReactNode): JSX.Element | ReactNode => {
		if (typeof action === 'string') {
			return <Text variant="heading-medium-strong">{action}</Text>;
		} else {
			return action;
		}
	};

	return (
		<Paper className={`${highlight}Card`} data-cy={dataCy}>
			<Grid container>
				<Grid
					xs={12}
					container
					style={{
						justifyContent: 'space-between',
					}}>
					<Grid xs={action ? 8 : 12} data-cy={dataCy + '-Heading'}>
						{getHeading(heading)}
					</Grid>
					{action ? getAction(action) : <></>}
				</Grid>
				<Grid xs={12} container data-cy={dataCy + '-Contents'}>
					{contents}
				</Grid>
			</Grid>
		</Paper>
	);
};

DetailsCard.defaultProps = {
	highlight: 'normal',
};

export default DetailsCard;
