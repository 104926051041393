import type {
  AnalyticFunctions,
  Duration,
  ResponseBody,
  ResponseBodyWithSnapping,
  Source,
  TimeSelection,
} from './api';
import {
  isAnalyticFunction,
  type AnalyticFunction,
  type Reading,
  type Resolution,
} from '../_insight-chart';
import { take } from '../utils';

const readings: Record<Exclude<Reading, 'OHLC'>, AnalyticFunctions['type']> = {
  Close: 'close',
  Open: 'open',
  High: 'high',
  Low: 'low',
  Average: 'average',
  Sum: 'sum',
};

const analytics = {
  Constant: 'average',
  Last: 'average',
  Movingaverage: 'average',
  MovingSum: 'average',
  Sqrt: 'sqrt',
} as Record<AnalyticFunction['type'], AnalyticFunctions['type']>;

const frontEndAnalytics = {
  Constant: 'constant',
  Last: 'last',
  Movingaverage: 'movingaverage',
  MovingSum: 'movingsum',
} as Record<Partial<AnalyticFunction['type']>, AnalyticFunctions['type']>;

function makeAnalyticInterval(
  resolution: Resolution
): 'raw' | 'monthly' | Duration {
  const map: Record<string, Duration> = {
    '15-MINUTE': { minutes: 15 },
    '30-MINUTE': { minutes: 30 },
    HOURLY: { minutes: 60 },
    DAILY: { days: 1 },
    WEEKLY: { days: 7 },
  };

  if (resolution === 'RAW') return 'raw';
  if (resolution === 'MONTHLY') return 'monthly';
  if (resolution === 'AUTO') return { days: 1 };

  return map[resolution];
}

function makeAnalyticFunctions(
  analytic: Reading | Reading[] | AnalyticFunction | AnalyticFunction[]
): AnalyticFunctions[] {
  const analyticArray = Array.isArray(analytic) ? analytic : [analytic];

  let results = [] as AnalyticFunctions[];

  for (const a of analyticArray) {
    if (typeof a === 'string' && a === 'OHLC') {
      results = [
        ...results,
        { type: 'open' },
        { type: 'high' },
        { type: 'low' },
        { type: 'close' },
      ];
    } else if (isAnalyticFunction(a)) {
      results = [
        ...results,
        {
          type: analytics[a.type],
          frontEndAnalytic: frontEndAnalytics[a.type],
          ...a.params,
        },
      ];
    } else if (typeof a !== 'undefined') {
      results = [...results, { type: readings[a] }];
    } else {
      results = [...results, { type: 'close' }];
    }
  }

  return results;
}

export function makeAnalytic(
  resolution: Resolution,
  analytic:
    | Reading
    | Reading[]
    | AnalyticFunction
    | AnalyticFunction[]
    | undefined
): Source['analytic'] {
  return {
    interval: makeAnalyticInterval(resolution),
    functions: makeAnalyticFunctions(analytic),
  };
}

export function stringifyAnalytic(analytic: Reading | AnalyticFunction) {
  if (isAnalyticFunction(analytic)) {
    const params = Object.entries(analytic.params ?? {})
      .map(([k, v]) => `${k};${v}`)
      .join(':');
    return `${analytic.type}:${params}`;
  }
  return analytic;
}

export function getSnapSelection(
  response: ResponseBody
): ResponseBodyWithSnapping['snapSelection'] {
  let start: number | undefined = undefined;
  let end: number | undefined = undefined;

  if (response.results && Object.keys(response.results).length) {
    for (const v of Object.values(response.results)) {
      if (!v.data.length) continue;
      for (const d of v.data) {
        const [timestamp] = d;
        start = take('min', start, timestamp);
        end = take('max', end, timestamp);
      }
    }
  }

  return { start, end };
}

export type Extremes = {
  from: number;
  to: number;
};

export function getExtremes(response: ResponseBody): Extremes | undefined {
  if (response?.results && Object.keys(response.results).length) {
    let from: number | undefined = undefined;
    let to: number | undefined = undefined;

    for (const v of Object.values(response.results)) {
      if (!v.data.length) continue;
      for (const d of v.data) {
        const [timestamp] = d;
        from = take('min', from, timestamp);
        to = take('max', to, timestamp);
      }
    }

    return { from, to };
  } else {
    return undefined;
  }
}

export function fixCollectionInterval(
  value: number | string | null | undefined,
  defaultValue = 300
): number {
  const n = Number(value);
  return !Number.isNaN(n) && n > 0 ? n : defaultValue;
}

export const makeExtremesFromTimeSelection = (
  timeSelection: TimeSelection | undefined
): Extremes | undefined => {
  if (
    typeof timeSelection?.from !== 'number' ||
    typeof timeSelection?.to !== 'number'
  ) {
    return undefined;
  }

  return timeSelection as Extremes;
};
