import { AxiosError, AxiosResponse } from 'axios';
import { MapRiskLayer, PaginatedResponse, RehabDecisionTree } from '@Types';
import {
	getMapLayers,
	getMapRehabLayers,
	getMapRehabLayersRejected,
	getMapRehabLayersResolved,
	getMapRiskLayers,
	getMapRiskLayersRejected,
	getMapRiskLayersResolved,
} from '@Actions';
import { put, retry, takeLatest } from '@redux-saga/core/effects';

import { getMapRiskLayersApi } from '@Services';
import { getRehabTreesApi } from '@Services/Rehab.servies';

function* getMapRiskLayersSaga() {
	try {
		const payload: AxiosResponse<MapRiskLayer[]> = yield retry(
			5,
			15000,
			getMapRiskLayersApi,
		);

		yield put(getMapRiskLayersResolved(payload.data));
	} catch (e) {
		const err = e as AxiosError;
		const rejection = {
			code: err?.response?.status ?? -1,
			text: err?.response?.statusText ?? 'No additional information',
		};

		yield put(getMapRiskLayersRejected(rejection));
	}
}

function* getMapRehabLayersSaga() {
	try {
		const payload: AxiosResponse<PaginatedResponse<RehabDecisionTree[]>> =
			yield retry(5, 15000, getRehabTreesApi, {
				publishedOnly: true,
				listTreesAndActions: true,
				slice: 10,
			});

		yield put(getMapRehabLayersResolved(payload.data.data));
	} catch (e) {
		const err = e as AxiosError;
		const rejection = {
			code: err?.response?.status ?? -1,
			text: err?.response?.statusText ?? 'No additional information',
		};

		yield put(getMapRehabLayersRejected(rejection));
	}
}

function* getMapLayersSaga() {
	yield put(getMapRiskLayers());
	yield put(getMapRehabLayers());
}

function* watchGetMapLayers() {
	yield takeLatest(getMapLayers, getMapLayersSaga);
}

function* watchGetMapRiskLayers() {
	yield takeLatest(getMapRiskLayers, getMapRiskLayersSaga);
}

function* watchGetMapRehabLayers() {
	yield takeLatest(getMapRehabLayers, getMapRehabLayersSaga);
}

export default [
	watchGetMapRiskLayers(),
	watchGetMapRehabLayers(),
	watchGetMapLayers(),
];
