import * as Highcharts from 'highcharts/highstock';
import { TFunc } from '../../i18n';

export const defaultExportingOptions: Highcharts.ExportingOptions = {
  enabled: true,
  allowHTML: true,
  buttons: {
    contextButton: {
      enabled: false,
      menuItems: ['downloadCSV', 'downloadXLS'],
    },
  },
};

export const defaultStockChartConfig = (t: TFunc): Highcharts.Options => ({
  credits: { enabled: false },
  chart: {
    zoomType: 'x',
    height: null,
  },
  exporting: defaultExportingOptions,
  rangeSelector: {
    //inputDateFormat: '%b %e, %Y %H:%M',
    buttons: [
      {
        count: 1,
        text: t('1d', {
          context: 'label to select date range limit by a day',
        }),
        type: 'day',
      },
      {
        count: 1,
        text: t('1w', {
          context: 'label to select date range limit by a week',
        }),
        type: 'week',
      },
      {
        count: 1,
        text: t('1m', {
          context: 'label to select date range limit by a month',
        }),
        type: 'month',
      },
      {
        count: 3,
        text: t('3m', {
          context: 'label to select date range limit by 3 months',
        }),
        type: 'month',
      },
      {
        count: 6,
        text: t('6m', {
          context: 'label to select date range limit by 3 months',
        }),
        type: 'month',
      },
      {
        count: 1,
        text: t('1y', {
          context: 'label to select date range limit by a year',
        }),
        type: 'year',
      },
      {
        text: t('YTD', {
          context: 'label to select date range a year to date',
        }),
        type: 'ytd',
      },
      {
        text: t('All', { context: 'all date range' }),
        type: 'all',
      },
    ],
    selected: 7,
    allButtonsEnabled: true,
  },
  legend: {
    enabled: true,
  },
  xAxis: {
    type: 'datetime',
  },
  yAxis: [
    {
      lineWidth: 1,
      opposite: false,
    },
  ],
  tooltip: {
    // pointFormat:
    // 	'<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.change})<br/>',
    valueDecimals: 2,
    split: true,
    shared: true,
  },
  navigator: {
    handles: {
      height: 20,
      backgroundColor: '#F9FAFC',
      borderColor: '#768D95',
    },
    outlineColor: 'transparent',
    maskInside: false,
    maskFill: 'rgba(255, 255, 255, 0.7)',
    yAxis: {
      gridLineWidth: 1,
      startOnTick: false,
      endOnTick: false,
      minPadding: 0.1,
      maxPadding: 0.1,
      tickWidth: 1,
    },
  },
  scrollbar: {
    enabled: true,
    // Effectively hide the scrollbar, this lets us leverage live reload
    // while scrolling in the navigator without showing the
    // scrollbar
    height: 0,
    // alpha transparency of 0 effectively removes arrows from the "hidden" scrollbar
    buttonArrowColor: 'rgba(1, 1, 1, 0)',
  },
  plotOptions: {
    scatter: {
      dataGrouping: {
        // enabled: true,
        // forced: true,
        groupPixelWidth: 4,
      },
      tooltip: {
        followPointer: false,
      },
    },
    column: {
      dataGrouping: {
        approximation: 'average',
      },
    },
    series: {
      showInNavigator: true,
    },
  },
});
