import styled from 'styled-components';

export const TableDashletWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const TableContainer = styled.div`
  flex: 1 1 auto;
  min-height: 0;
  width: 100%;
`;
