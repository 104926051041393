import { getApiEnvironment, getService } from '@innovyze/stylovyze';
import axios, { AxiosRequestConfig } from 'axios';
import axiosRetry from 'axios-retry';

const options: AxiosRequestConfig = {
  baseURL: getService('sensorData', undefined, getApiEnvironment()),
  withCredentials: true,
  timeout: 10000,
  headers: {
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0',
  },
};

const measureData = axios.create(options);

axiosRetry(measureData, {
  retries: 5,
  shouldResetTimeout: true,
  retryDelay: (retryCount) => {
    return retryCount * 500;
  },
  retryCondition: (error) => {
    const is404 = error.response?.status === 404;
    return is404 ? false : true;
  },
});

export default measureData;
