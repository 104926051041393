export const noDataLegendProps = (
  chart: Highcharts.Chart,
  display: boolean
) => {
  const noDataLegendX = chart.plotLeft + chart.plotWidth / 2,
    noDataLegendY = chart.plotHeight / 2;
    //@ts-ignore
  const color = chart?.legend?.itemStyle?.color ?? '#333333';
  return {
    x: noDataLegendX-16,
    y: noDataLegendY,
    style: {
      opacity: 0.5,
      fontSize: '16px',
      fontWeight: 'bold',
      color: display ? color : 'transparent',
    },
  };
};
