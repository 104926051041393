import {
	Checkbox,
	FormControl,
	InputLabel,
	ListItemText,
	MenuItem,
	Select,
	SelectChangeEvent,
} from '@mui/material';
import { IdCaption, SelectProps } from '@Types';
import React, { ReactNode } from 'react';

const FilterSelect = (props: SelectProps): JSX.Element => {
	const idCaptions: IdCaption[] = props.options || [];

	const filterChange = (event: SelectChangeEvent<unknown>) => {
		if (props.onFilterChange) {
			if (props.multiple) {
				props.onFilterChange({
					colKey: props.column.id,
					value: event.target.value as string[],
				});
			} else {
				props.onFilterChange({
					colKey: props.column.id,
					value: event.target.value as string,
				});
			}
		}
	};

	const renderValue = (selectedValue: unknown): ReactNode => {
		if (props.multiple) {
			const values = selectedValue as string[];
			return (
				<>
					{values
						.map(id => {
							const text = idCaptions.find(
								value => value.id === id,
							);
							return text?.caption;
						})
						.join(', ')}
				</>
			);
		} else {
			const text = idCaptions.find(value => value.id === selectedValue);
			return <>{text?.caption}</>;
		}
	};

	const options = idCaptions.map((idCaption, index) => {
		if (props.multiple) {
			return (
				<MenuItem
					value={idCaption.id}
					key={index}
					style={{ padding: '0 10px 0 0' }}>
					<Checkbox
						checked={
							props.value !== undefined
								? props.value.indexOf(idCaption.id) > -1
								: false
						}
					/>
					<ListItemText primary={idCaption.caption} />
				</MenuItem>
			);
		} else {
			return (
				<MenuItem value={idCaption.id} key={index}>
					{idCaption.caption}
				</MenuItem>
			);
		}
	});

	return (
		<FormControl fullWidth={props.fullWidth}>
			<InputLabel> {props.column.caption} </InputLabel>
			<Select
				variant="standard"
				data-cy={props.dataCy}
				value={props.value}
				onChange={filterChange}
				multiple={props.multiple}
				renderValue={renderValue}>
				{options}
			</Select>
		</FormControl>
	);
};

export default FilterSelect;
