import {
	AttributeTypes,
	ColorByOptions,
	ConfigLayer,
	ConfigLayerInternal,
} from '@Map/types';
import {
	UnitSystem,
	generateDefaultValueForSystem,
} from '@Components/Inputs/units';

import AssetRegistry from '@Map/services/AssetRegistry';
import Gradient from './Gradient';
import MapboxLayer from '@Map/layers/mapbox/MapboxLayer';
import { RequiredWithPartial } from '@Map/typeUtils';
import { SystemTypes } from '@Map/services/Info360TileTypes';
import { Theme } from '@mui/material';
import TileConfigAdaptor from './TileConfigAdaptor';
import { reduceArrayToObject } from '@Map/utils';

export const defaultMainColor = (layerType: ConfigLayer['type']): string => {
	switch (layerType) {
		case 'fill':
		case 'polygon':
			return MapboxLayer.UNSELECTED_COLOR_FILL;
		default:
			return MapboxLayer.UNSELECTED_COLOR;
	}
};
export const defaultSelectedColor = (
	layerType: ConfigLayer['type'],
): string => {
	switch (layerType) {
		case 'fill':
		case 'polygon':
			return MapboxLayer.SELECTED_COLOR_FILL;
		default:
			return MapboxLayer.SELECTED_COLOR;
	}
};

export type DefaultProperties = RequiredWithPartial<
	ConfigLayer,
	| 'visible'
	| 'color'
	| 'outlineColor'
	| 'lineWidth'
	| 'fillOpacity'
	| 'minZoom'
	| 'maxZoom'
	| 'iconMinZoom'
	| 'iconMaxZoom'
	| 'iconBackground'
>;
export type ConfigLayerInternalWithDefaults = ConfigLayerInternal &
	DefaultProperties;

export const defaultColorRamp: GradientKey = 'rainbow';

export const defaultProperties = (
	layerType: ConfigLayer['type'],
): DefaultProperties => ({
	visible: true,
	color: defaultMainColor(layerType),
	selectedColor: defaultSelectedColor(layerType),
	outlineColor: MapboxLayer.UNSELECTED_COLOR_OUTLINE,
	lineWidth: 3,
	fillOpacity: MapboxLayer.FILL_OPACITY,
	minZoom: 2,
	// use maxZoom 23 to allow tiles to show at 22
	maxZoom: 23,
	iconMinZoom: 2,
	// use maxZoom 23 to allow tiles to show at 22
	iconMaxZoom: 23,
	iconBackground: true,
	colorBy: ColorByOptions.fixed,
	colorRamp: defaultColorRamp,
	radius: 30,
	scale: 3,
});

export const defaultPropertiesForAsset = (
	assetType: string,
): DefaultProperties | undefined => {
	const assetDefaults = AssetRegistry.getConfigDefaultsForAsset(assetType);
	if (assetDefaults) {
		return {
			...defaultProperties(assetDefaults.type),
			...assetDefaults,
		};
	}
};

type Generic = { [key: string]: unknown };

export const addDefaults = (
	layer: ConfigLayerInternal | undefined,
): ConfigLayerInternalWithDefaults => {
	const { systemType, assetType } = (layer ?? {}) as Generic;
	const defaults =
		defaultPropertiesForAsset(assetType as string) ||
		defaultProperties(layer?.type);

	const defaultColor =
		((systemType as string) &&
			TileConfigAdaptor.getSystemColour(systemType as SystemTypes)) ||
		defaults.color;

	return {
		...defaults,
		color: defaultColor,
		...layer,
	} as ConfigLayerInternalWithDefaults;
};

const gradientColours = {
	rainbow: new Gradient(
		[
			'#FF2204',
			'#FF36CD',
			'#0057FF',
			'#12F3FD',
			'#0FFA09',
			'#FEFD06',
			'#FD2502',
		],
		7,
	),
	heat: new Gradient(['#FEFFEF', '#FFAB0C', '#FD2506']),
	'ggplot default': new Gradient(['#57B1F0', '#112B42']),
	'brewer blues': new Gradient(['#F6FBFE', '#09316F']),
	'brewer yellow-green-blue': new Gradient(['#FEFDDD', '#48B7C1', '#0A1D58']),
	viridis: new Gradient(['#F7E926', '#25918D', '#430C56']),
	magma: new Gradient(['#FBFBBC', '#B13677', '#000007']),
	'brewer blue-purple': new Gradient(['#e0ecf4', '#9ebcda', '#8856a7']),
	'brewer yellow-green': new Gradient(['#f7fcb9', '#addd8e', '#31a354']),
	'diverging purple-green': new Gradient([
		'#7b3294',
		'#c2a5cf',
		'#f7f7f7',
		'#a6dba0',
		'#008837',
	]),
	'diverging pink-green': new Gradient([
		'#d01c8b',
		'#f1b6da',
		'#f7f7f7',
		'#b8e186',
		'#4dac26',
	]),
	'diverging red-blue': new Gradient([
		'#ca0020',
		'#f4a582',
		'#f7f7f7',
		'#92c5de',
		'#0571b0',
	]),
	'diverging brown-bluegreen': new Gradient([
		'#a6611a',
		'#dfc27d',
		'#f5f5f5',
		'#80cdc1',
		'#018571',
	]),
};

export type GradientKey = keyof typeof gradientColours;

export const gradients = Object.entries(gradientColours).map(
	([key, gradient]) => ({
		value: key,
		background: gradient.css(),
	}),
);

export const getGradientBackgroundForLayer = (
	layer: ConfigLayerInternal,
): string | undefined => {
	if (layer.colorByTheming === ColorByOptions.range && layer.colorRange) {
		const colors = layer.colorRange.map(({ color }) => color);
		return Gradient.create(colors)?.css();
	} else if (
		layer.colorByTheming === ColorByOptions.attribute &&
		layer.validColorBy
	) {
		const gradient = gradientColours[layer.colorRamp ?? defaultColorRamp];
		return gradient?.css();
	}
};

export const generateGradientSteps = (
	gradientKey: GradientKey,
	steps: number,
): string[] => {
	const gradient = gradientColours[gradientKey];
	if (gradient) {
		return gradient.colors(steps);
	}
	return [];
};

export const generateCustomGradientSteps = (
	colors: string[],
	steps: number,
): string[] => {
	return Gradient.create(colors).colors(steps);
};

export const generateSVGGradient = (
	gradient: Gradient | string[],
	name: string,
): string => {
	return Gradient.create(gradient).svg(name);
};

export const svgGradients = reduceArrayToObject(
	Object.entries(gradientColours).map(([key, gradient]) => ({
		[key]: generateSVGGradient(gradient, key),
	})),
);

interface ColorOption {
	value: string;
	backgroundColor: string;
}

const gradientColors = generateGradientSteps('viridis', 10);

export const defaultRangeValues = (
	unitSystem = UnitSystem.Metric,
	unit?: string,
) => [
	{
		value: 0,
		color: gradientColors[0],
	},
	{
		value: generateDefaultValueForSystem(10, unit, unitSystem),
		color: gradientColors[1],
	},
];

export const getColorOptions = (
	{ palette }: Theme,
	additionalColors?: (string | undefined)[],
): ColorOption[] => {
	const colors = [
		'#F49136',
		'#A4978B',
		'#DB5D16',
		'#4E9F39',
		'#3960C6',
		'#7BB9E5',
		palette.primary.main,
		palette.secondary.main,
		palette.error.main,
		palette.warning.main,
		'#83BC3F',
		'#886CE7',
		palette.primary.dark,
		palette.secondary.dark,
		palette.error.dark,
		palette.warning.dark,
		'#4B7E04',
		'#4C3DAD',
		palette.primary.light,
		palette.secondary.light,
		palette.error.light,
		palette.warning.light,
		'#B6EF70',
		'#BAA6FF',
	].map(hex => hex.toLowerCase());

	gradientColors?.forEach(color => {
		if (color && !colors.includes(color)) {
			colors.push(color);
		}
	});

	additionalColors?.forEach(color => {
		if (color && !colors.includes(color)) {
			colors.push(color);
		}
	});

	return colors.map(hex => ({
		value: hex,
		backgroundColor: hex,
	}));
};

export const getColorByTheming = (layer: ConfigLayerInternal) => {
	if (layer.colorBy === ColorByOptions.fixed) return ColorByOptions.fixed;
	if (
		layer.colorBy === ColorByOptions.range ||
		layer.attributeType === AttributeTypes.range
	)
		return ColorByOptions.range;
	return layer.colorBy ?? ColorByOptions.fixed;
};
