import React from 'react';
import { DropDownButton } from '@innovyze/stylovyze';
import { RowsPerPageProps } from '@Types/RowsPerPage.types';
import { StyledDiv, StyledText } from './RowsPerPage.component.style';
import { useGlobalization } from '@Translations';

function RowsPerPage({
	options,
	handleDropdownSwitch,
	width = 170,
	height = 26,
	...other
}: RowsPerPageProps): JSX.Element {
	// const rowsPerPageOptions, handleDropdownSwitch = ...props;
	const { t } = useGlobalization();
	const dropdownOptions = options.map(option => {
		return {
			name: option.toString(),
			onClick: () => {
				if (handleDropdownSwitch) {
					handleDropdownSwitch(option);
				}
			},
		};
	});

	return (
		<StyledDiv id={other.id}>
			<StyledText variant="text-large">{t('Rows per page:')}</StyledText>
			<DropDownButton
				arrow
				color="secondary"
				options={dropdownOptions}
				placement="bottom-start"
				variant="text"
				width={width}
				height={height}
			/>
		</StyledDiv>
	);
}

export default RowsPerPage;
