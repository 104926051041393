import { useIsFeatureEnabled, useSelectSettings } from '@innovyze/stylovyze';
import Color from 'color';
import Highcharts from 'highcharts';
import { nanoid } from 'nanoid';
import * as React from 'react';
import styled from 'styled-components';
import { useGlobalization } from '../../../i18n';
import {
  MARKER_SIZE_MAP,
  MarkerSizes,
  MarkerTypes,
} from '../../../types/chart.types';
import {
  ChartInstanceRef,
  createChart,
  createContext,
  Status,
  useStableEventHandlers,
} from '../../core/_summaryze-chart';
import { getTheme, getThemeColor, Theme } from '../../core/utils/theme-utils';
import * as Options from './pump-performance-chart-options';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Chart
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const Chart = createChart('PumpPerformanceChartRoot');

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Chart Root
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

interface PumpPerformanceChartRootProps {
  children: React.ReactNode;
  enableHorizontalGrids?: boolean;
  enableVerticalGrids?: boolean;
  xAxisMin?: number;
  xAxisMax?: number;
  xAxisLabel?: string;
  yAxisLabel?: string;
  yAxisMin?: number;
  yAxisMax?: number;
  selectedTheme?: Theme;
  axisMode?: 'pressure' | 'head' | 'pressure-head';
  onSeriesVisibilityChange?: (id: string, type: 'show' | 'hide') => void;
}

type PumpPerformanceChartYAxisId = 'pressure' | 'head' | 'power' | 'efficiency';

type PumpPerformanceChartContext = Pick<
  PumpPerformanceChartRootProps,
  | 'enableHorizontalGrids'
  | 'enableVerticalGrids'
  | 'xAxisLabel'
  | 'xAxisMin'
  | 'xAxisMax'
  | 'yAxisLabel'
  | 'yAxisMin'
  | 'yAxisMax'
  | 'selectedTheme'
  | 'axisMode'
>;

type PumpPerformanceChartEventHandlers = Pick<
  PumpPerformanceChartRootProps,
  'onSeriesVisibilityChange'
>;

const [RootContextProvider, useRootContext] =
  createContext<PumpPerformanceChartContext>('RootContextProvider');

const PumpPerformanceChartRoot = React.forwardRef<
  ChartInstanceRef,
  PumpPerformanceChartRootProps
>((props, ref): React.ReactElement => {
  const { t } = useGlobalization();
  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Event Handlers
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  const eventHandlersRef =
    useStableEventHandlers<PumpPerformanceChartEventHandlers>({
      onSeriesVisibilityChange: props.onSeriesVisibilityChange,
    });

  const initialOptions = React.useMemo(() => {
    return Options.makeOptionsWithEventHandlers(
      Options.initialOptions,
      eventHandlersRef
    );
  }, [eventHandlersRef]);
  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  return (
    <RootContextProvider
      enableHorizontalGrids={props.enableHorizontalGrids}
      enableVerticalGrids={props.enableVerticalGrids}
      xAxisLabel={props.xAxisLabel}
      xAxisMin={props.xAxisMin}
      xAxisMax={props.xAxisMax}
      yAxisLabel={props.yAxisLabel}
      yAxisMin={props.yAxisMin}
      yAxisMax={props.yAxisMax}
      selectedTheme={props.selectedTheme}
      axisMode={props.axisMode}>
      <Chart.ChartRoot>
        <Chart.ChartInstance
          ref={ref}
          initialOptions={initialOptions}
          constructorFunction={Highcharts.chart}>
          {props.children}
        </Chart.ChartInstance>
        <ColorLegend.Root>
          <ColorLegend.LegendText>{t('Latest')}</ColorLegend.LegendText>
          <ColorLegend.LegendGradient />
          <ColorLegend.LegendText>{t('Oldest')}</ColorLegend.LegendText>
        </ColorLegend.Root>
      </Chart.ChartRoot>
    </RootContextProvider>
  );
});

PumpPerformanceChartRoot.displayName = 'PumpPerformanceChartRoot';

const ColorLegend = {
  Root: styled.div`
    align-items: center;
    bottom: 6px;
    display: flex;
    position: absolute;
    right: 12px;
  `,
  LegendText: styled.div`
    font-size: 11px;
    color: #666666;
  `,
  LegendGradient: styled.div`
    background: linear-gradient(
      to right,
      rgba(196, 196, 196, 1),
      rgba(196, 196, 196, 0.1)
    );
    height: 8px;
    margin: 5px;
    width: 40px;
    border-radius: 4px;
  `,
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Series Group Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

interface PumpPerformanceChartSeriesGroupProps {
  children: React.ReactNode;
  status?: Status;
}

const PumpPerformanceChartSeriesGroup = (
  props: PumpPerformanceChartSeriesGroupProps
): React.ReactElement => {
  const { t } = useGlobalization();
  const { companySettings } = useSelectSettings();
  const rootContext = useRootContext('StackableInstanceWrapper');
  const instanceRef = Chart.useInstance('PumpPerformanceChartSeriesGroup');
  const instanceSeriesProps =
    Chart.useInstanceSeriesProps<PumpPerformanceChartSeriesProps>(
      'PumpPerformanceChartSeriesGroup'
    );

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Set X axes labels
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  const xAxisLabel = React.useMemo(() => {
    return t('Flow Rate');
  }, [t]);

  const xAxisUnit = React.useMemo(() => {
    const units: Set<string> = new Set();

    instanceSeriesProps.forEach((seriesProps) => {
      if (seriesProps.type === 'scatter' && seriesProps.xAxisUnit) {
        units.add(seriesProps.xAxisUnit);
      } else if (
        seriesProps.type === 'manufacturer-curve' &&
        seriesProps.xSeriesUnit
      ) {
        units.add(seriesProps.xSeriesUnit);
      }
    });

    return Array.from(units).join(',');
  }, [instanceSeriesProps]);

  React.useEffect(() => {
    let _xAxisLabel = rootContext.xAxisLabel || xAxisLabel;

    if (xAxisUnit) {
      _xAxisLabel += ` (${xAxisUnit})`;
    }
    instanceRef.current?.xAxis[0]?.update({ title: { text: _xAxisLabel } });
  }, [instanceRef, rootContext.xAxisLabel, xAxisLabel, xAxisUnit, t]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Set Y axes labels
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  const yAxisLabels = React.useMemo(() => {
    const labels: Record<PumpPerformanceChartYAxisId, string> = {
      pressure: t('Pressure'),
      head: t('Head'),
      power: t('Power'),
      efficiency: t('Efficiency'),
    };
    return labels;
  }, [t]);

  const yAxisUnits = React.useMemo(() => {
    const units: Record<PumpPerformanceChartYAxisId, Set<string>> = {
      pressure: new Set(),
      power: new Set(),
      head: new Set(),
      efficiency: new Set(['%']),
    };

    instanceSeriesProps.forEach((seriesProps) => {
      if (seriesProps.type === 'scatter' && seriesProps.yAxisUnit) {
        if (['pressure', 'power'].includes(seriesProps.subType)) {
          units.pressure.add(seriesProps.yAxisUnit);
        } else if (['head', 'pressure-head'].includes(seriesProps.subType)) {
          const u = companySettings.UOM === 'Imperial' ? 'ft' : 'm';
          units.pressure.add(seriesProps.yAxisUnit);
          units.head.add(u);
        }
      } else if (
        seriesProps.type === 'manufacturer-curve' &&
        seriesProps.ySeriesUnit
      ) {
        if (seriesProps.subType === 'pressure') {
          units.pressure.add(seriesProps.ySeriesUnit);
        } else if (seriesProps.subType === 'power') {
          units.power.add(seriesProps.ySeriesUnit);
        }
      }
    });

    return {
      pressure: Array.from(units.pressure).join(','),
      power: Array.from(units.power).join(','),
      head: Array.from(units.head).join(','),
      efficiency: Array.from(units.efficiency).join(','),
    };
  }, [instanceSeriesProps, companySettings.UOM]);

  React.useEffect(() => {
    instanceRef.current?.yAxis?.forEach((yAxis, yAxisIndex) => {
      const yAxisId = yAxis.options.id! as PumpPerformanceChartYAxisId;
      let yAxisLabel =
        rootContext.yAxisLabel && yAxisIndex === 0
          ? rootContext.yAxisLabel
          : yAxisLabels[yAxisId];

      if (yAxisUnits[yAxisId]) {
        yAxisLabel += ` (${yAxisUnits[yAxisId]})`;
      }

      yAxis.update({ title: { text: yAxisLabel } });
    });
  }, [instanceRef, rootContext.yAxisLabel, yAxisLabels, yAxisUnits, t]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets horizontal gridlines
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    instanceRef.current?.yAxis[0]?.update({
      gridLineWidth: rootContext.enableHorizontalGrids ? 1 : 0,
    });
  }, [instanceRef, rootContext.enableHorizontalGrids]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets vertical gridlines
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    instanceRef.current?.xAxis[0]?.update({
      gridLineWidth: rootContext.enableVerticalGrids ? 1 : 0,
    });
  }, [instanceRef, rootContext.enableVerticalGrids]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets X axis min and max
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    instanceRef.current?.xAxis[0]?.setExtremes(
      numberify(rootContext.xAxisMin),
      numberify(rootContext.xAxisMax)
    );
  }, [instanceRef, rootContext.xAxisMin, rootContext.xAxisMax]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets Y axis min and max
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    instanceRef.current?.yAxis?.forEach((yAxis) => {
      if (yAxis.options.id !== 'efficiency') {
        yAxis?.setExtremes(
          numberify(rootContext.yAxisMin),
          numberify(rootContext.yAxisMax)
        );
      }
    });
  }, [instanceRef, rootContext.yAxisMin, rootContext.yAxisMax]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets status
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    if (props.status === 'loading') {
      instanceRef.current?.showLoading(t('Loading'));
    } else if (props.status === 'rejected') {
      instanceRef.current?.showLoading(t('Failed to retrieve data'));
    } else {
      const scatterSeries = instanceSeriesProps.filter(
        (s): s is PumpPerformanceChartScatterSeriesProps => s.type === 'scatter'
      );

      const noData = scatterSeries.every(
        (s) => s.data === undefined || s.data.length === 0
      );

      if (noData) {
        instanceRef.current?.showLoading(t('No Data'));
      } else {
        instanceRef.current?.hideLoading();
      }
    }
  }, [props.status, instanceRef, instanceSeriesProps, t]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets Theme
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    const theme = rootContext.selectedTheme;
    const themeOptions = getTheme(theme ?? 'Default');
    instanceRef.current?.update({ ...themeOptions });
  }, [instanceRef, rootContext.selectedTheme]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  return <>{props.children}</>;
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Series Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

type PumpPerformanceChartSeriesProps =
  | PumpPerformanceChartScatterSeriesProps
  | PumpPerformanceChartManufacturerCurveSeriesProps
  | PumpPerformanceChartDesignPointProps;

const PumpPerformanceChartSeries = (
  props: PumpPerformanceChartSeriesProps
): React.ReactElement | null => {
  const isPpcNewFeaturesEnabled = !!useIsFeatureEnabled(
    'info-360-ppc-new-features'
  );
  switch (props.type) {
    case 'scatter':
      return <PumpPerformanceChartScatterSeries {...props} />;
    case 'manufacturer-curve':
      return <PumpPerformanceChartManufacturerCurveSeries {...props} />;
    case 'design-point':
      return isPpcNewFeaturesEnabled ? (
        <PumpPerformanceChartDesignPoint {...props} />
      ) : null;
    default:
      return null;
  }
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

interface PumpPerformanceChartScatterSeriesProps {
  type: 'scatter';
  subType: 'pressure' | 'head' | 'pressure-head' | 'power';
  color?: string;
  data?: PumpPerformanceChartScatterSeriesData;
  error?: unknown;
  hidden?: boolean;
  id?: string;
  name?: string;
  status?: Status;
  xAxisSourceName?: string;
  xAxisUnit?: string;
  yAxisUnit?: string;
  markerSize?: MarkerSizes | `${MarkerSizes}`;
  markerType?: MarkerTypes | `${MarkerTypes}`;
  zIndex?: number;
}

type PumpPerformanceChartScatterSeriesData = {
  timestamp: number;
  x: number;
  y: number | null;
}[];

const PumpPerformanceChartScatterSeries = (
  props: PumpPerformanceChartScatterSeriesProps
): null => {
  const { t } = useGlobalization();
  const { companySettings } = useSelectSettings();
  const seriesRef = Chart.useSeries(props, 'PumpPerformanceChartSeries');
  const extraSeriesRef = React.useRef<Highcharts.Series>();
  const rootContext = useRootContext('PumpPerformanceChartSeries');
  const instanceRef = Chart.useInstance('PumpPerformanceChartSeries');

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   *	Sets pressure-head series
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    if (props.subType === 'pressure-head') {
      const extraSeriesId = nanoid();
      extraSeriesRef.current = instanceRef.current?.addSeries({
        type: 'scatter',
        yAxis: 'head',
        id: extraSeriesId,
        enableMouseTracking: false,
        showInLegend: false,
        marker: {
          enabled: false,
          states: { hover: { enabled: false } },
        },
      });

      seriesRef.current?.update({
        type: 'scatter',
        yAxis: 'pressure',
        events: {
          show: function () {
            extraSeriesRef.current?.show();
          },
          hide: function () {
            extraSeriesRef.current?.hide();
          },
        },
      } as Highcharts.SeriesOptionsType);

      return () => {
        try {
          extraSeriesRef.current?.remove();
          extraSeriesRef.current = undefined;
        } catch (error) {
          console.error('Failed to remove extraSeriesRef', error);
        }
      };
    }
  }, [instanceRef, seriesRef, extraSeriesRef, props.subType]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets series Y axis
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    seriesRef.current?.update({
      yAxis: props.subType !== 'pressure-head' ? props.subType : 'pressure',
    } as Highcharts.SeriesOptionsType);
  }, [seriesRef, props.subType]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets series name
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    seriesRef.current?.update({
      name: props.name,
    } as Highcharts.SeriesOptionsType);
  }, [seriesRef, props.name]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets series color
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    let newColor = props.color;
    if (!newColor) {
      newColor = getThemeColor(
        rootContext.selectedTheme,
        seriesRef.current?.options?.index
      );
    }
    seriesRef.current?.update({
      color: newColor,
    } as Highcharts.SeriesOptionsType);
  }, [seriesRef, props.color, rootContext.selectedTheme]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets series marker type
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    seriesRef.current?.update({
      marker: { symbol: props.markerType },
    } as Highcharts.SeriesOptionsType);
  }, [seriesRef, props.markerType]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets series marker size
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    seriesRef.current?.update({
      marker: {
        radius: props.markerSize
          ? MARKER_SIZE_MAP[props.markerSize]
          : undefined,
      },
    } as Highcharts.SeriesOptionsType);
  }, [seriesRef, props.markerSize]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets series visibility
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    seriesRef.current?.update({
      visible: !props.hidden,
    } as Highcharts.SeriesOptionsType);
  }, [seriesRef, props.hidden]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets series data
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    let data: Highcharts.PointOptionsObject[] = [];

    if (props.data) {
      let colors: Map<number, string> | undefined = undefined;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const seriesColor =
        getThemeColor(
          rootContext.selectedTheme,
          seriesRef.current?.options?.index
        ) ?? (seriesRef.current?.options?.color as string | undefined);

      if (seriesColor) {
        const timestamps = props.data.map(({ timestamp }) => timestamp);
        const gradient = makeColorGradient(seriesColor, 10, 0.1);
        colors = mapGradientToTimestamps(gradient, timestamps);
      }

      data = props.data.map(({ x, y, timestamp }) => {
        const color = colors?.get(timestamp);
        const head =
          props.subType === 'pressure-head' || props.subType === 'head'
            ? convertPressureToHead(y, props.yAxisUnit)
            : undefined;

        // Timestamp in custom is used in tooltip
        return {
          color,
          custom: { timestamp, head },
          x,
          y: props.subType === 'head' ? head : y,
        };
      });
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    seriesRef.current?.update({
      data,
    } as Highcharts.SeriesOptionsType);

    if (props.subType === 'pressure-head') {
      const headData = data?.map((_data) => ({
        ..._data,
        y: _data.custom?.head,
      }));

      extraSeriesRef.current?.update({
        data: headData,
      } as Highcharts.SeriesScatterOptions);
    }
  }, [
    seriesRef,
    extraSeriesRef,
    props.color,
    props.subType,
    props.data,
    props.yAxisUnit,
    rootContext.selectedTheme,
  ]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets tooltip
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    const yAxisLabels: Partial<Record<PumpPerformanceChartYAxisId, string>> = {
      pressure: t('Pressure'),
      head: t('Head'),
      power: t('Power'),
    };

    const dateFormat = (companySettings.dateFormat as string)
      .replace('DD', '%e')
      .replace('MM', '%m')
      .replace('YYYY', '%Y');

    const timeFormat = (companySettings.hourCycle12 as boolean)
      ? '%I:%M %p'
      : '%H:%M';

    const xAxisSourceName = props.xAxisSourceName ?? props.name;
    const yAxisSourceName =
      props.subType === 'pressure-head'
        ? yAxisLabels.pressure
        : yAxisLabels[props.subType];

    const headUnit = companySettings.UOM === 'Imperial' ? 'ft' : 'm';

    seriesRef.current?.update({
      tooltip: {
        headerFormat: [
          '<span style="color:{point.color}">● </span>',
          `<b style="font-size: 10px">${props.name}</b><br />`,
        ].join(''),
        pointFormat: [
          `${xAxisSourceName}: {point.x:.2f} ${props.xAxisUnit}<br />`,
          `${yAxisSourceName}: {point.y:.2f} ${
            props.subType === 'head' ? headUnit : props.yAxisUnit
          }<br />`,
          ...(props.subType === 'pressure-head'
            ? [`${yAxisLabels.head}: {point.custom.head:.2f} ${headUnit}<br />`]
            : []),
          `{point.custom.timestamp:${dateFormat} - ${timeFormat}}`,
        ].join(''),
      },
    } as Highcharts.SeriesScatterOptions);
  }, [
    seriesRef,
    props.subType,
    props.name,
    props.xAxisSourceName,
    companySettings.hourCycle12,
    companySettings.dateFormat,
    companySettings.UOM,
    props.xAxisUnit,
    props.yAxisUnit,
    t,
  ]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  return null;
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

type PumpPerformanceChartManufacturerCurveSeriesProps = {
  type: 'manufacturer-curve';
  subType: 'pressure' | 'power' | 'efficiency' | 'head';
  color?: string;
  data?: PumpPerformanceChartManufacturerCurveSeriesData;
  error?: unknown;
  hidden?: boolean;
  id?: string;
  name?: string;
  status?: Status;
  zIndex?: number;
  xSeriesUnit?: string | null;
  ySeriesUnit?: string | null;
};

type PumpPerformanceChartManufacturerCurveSeriesData =
  | { x: number; y: number | null }[]
  | [x: number, y: number | null][];

const PumpPerformanceChartManufacturerCurveSeries = (
  props: PumpPerformanceChartManufacturerCurveSeriesProps
): null => {
  const { t } = useGlobalization();
  const seriesRef = Chart.useSeries(props, 'PumpPerformanceChartSeries');
  const extraSeriesRef = React.useRef<Highcharts.Series>();
  const instanceRef = Chart.useInstance('PumpPerformanceChartSeries');
  const rootContext = useRootContext('PumpPerformanceChartSeries');
  const { companySettings } = useSelectSettings();

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets manufacturer curve series
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    seriesRef.current?.update({
      type: 'line',
      yAxis: props.subType,
    });
  }, [props.subType, seriesRef]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   *	Sets manufacturer head series
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    if (rootContext.axisMode === 'pressure-head') {
      const extraSeriesId = nanoid();
      extraSeriesRef.current = instanceRef.current?.addSeries({
        type: 'scatter',
        yAxis: 'head',
        id: extraSeriesId,
        enableMouseTracking: false,
        showInLegend: false,
        marker: {
          enabled: false,
          states: { hover: { enabled: false } },
        },
      });

      seriesRef.current?.update({
        type: 'line',
        yAxis: props.subType,
        events: {
          show: function () {
            extraSeriesRef.current?.show();
          },
          hide: function () {
            extraSeriesRef.current?.hide();
          },
        },
      } as Highcharts.SeriesOptionsType);

      return () => {
        try {
          extraSeriesRef.current?.remove();
          extraSeriesRef.current = undefined;
        } catch (error) {
          console.log(error);
        }
      };
    }
  }, [
    instanceRef,
    rootContext.axisMode,
    seriesRef,
    extraSeriesRef,
    props.subType,
  ]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets manufacturer curve name
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    seriesRef.current?.update({
      name: props.name,
    } as Highcharts.SeriesLineOptions);
  }, [seriesRef, props.name]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets manufacturer curve color
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    const colors: Record<
      PumpPerformanceChartManufacturerCurveSeriesProps['subType'],
      string
    > = {
      head: '#00abd1',
      pressure: '#00abd1',
      power: '#AA0000',
      efficiency: '#4B7E03',
    };
    const color = props.color ?? colors[props.subType];
    seriesRef.current?.update({
      color,
    } as Highcharts.SeriesLineOptions);
  }, [seriesRef, props.color, props.subType]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets manufacturer curve data
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    try {
      let headData = props.data ?? [];
      if (rootContext.axisMode !== 'pressure') {
        headData = props.data?.map((d) => {
          const y = convertPressureToHead(d[1], props.ySeriesUnit);
          return [d[0], y];
        }) as PumpPerformanceChartManufacturerCurveSeriesData;

        extraSeriesRef.current?.update({
          data: headData,
        } as Highcharts.SeriesLineOptions);
      }
      const _data =
        rootContext.axisMode === 'pressure-head'
          ? props.data?.map((d, index) => {
              return {
                x: d[0],
                y: d[1],
                custom: { head: headData?.[index]?.[1] },
              };
            })
          : props.data;
      seriesRef.current?.update({
        yAxis: rootContext.axisMode === 'head' ? 'head' : props.subType,
        data: rootContext.axisMode === 'head' ? headData : (_data ?? []),
      } as Highcharts.SeriesLineOptions);
    } catch (error) {
      console.error('Failed to update manufacturer curve data', error);
    }
  }, [
    seriesRef,
    extraSeriesRef,
    props.data,
    rootContext.axisMode,
    props.ySeriesUnit,
    props.subType,
  ]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets tooltip
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    const yAxisLabels: Partial<Record<PumpPerformanceChartYAxisId, string>> = {
      pressure: t('Pressure'),
      head: t('Head'),
    };

    const headUnit = companySettings.UOM === 'Imperial' ? 'ft' : 'm';

    seriesRef.current?.update({
      tooltip: {
        headerFormat: [
          '<span style="font-size: 10px">{point.x:.2f}</span><br />',
          '<span style="color:{point.color}">● </span>',
          `<b style="font-size: 10px">${props.name}</b><br />`,
        ].join(''),
        pointFormat: [
          ...(rootContext.axisMode === 'pressure-head' ||
          rootContext.axisMode === 'pressure'
            ? [`Pressure: {point.y:.2f} ${props.ySeriesUnit}<br />`]
            : []),
          ...(rootContext.axisMode === 'pressure-head' ||
          rootContext.axisMode === 'head'
            ? [
                `Head: {point.${rootContext.axisMode === 'head' ? 'y' : 'custom.head'}:.2f} ${headUnit}<br />`,
              ]
            : []),
        ].join(''),
      },
    } as Highcharts.SeriesScatterOptions);
  }, [
    seriesRef,
    props.name,
    companySettings.UOM,
    props.ySeriesUnit,
    rootContext.axisMode,
    t,
  ]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets series visibility
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    seriesRef.current?.update({
      visible: !props.hidden,
    } as Highcharts.SeriesOptionsType);
  }, [seriesRef, props.hidden]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  return null;
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Generate color gradient
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const numberify = (something: unknown): number | undefined => {
  if (typeof something === 'number') return something;
  if (typeof something === 'string' && something.trim() !== '')
    return Number(something);

  return undefined;
};

const makeColorGradient = (color: string, shades: number, ratio = 0.8) => {
  return new Array<string>(shades)
    .fill(color)
    .map((_color, index) => new Color(_color).lighten(index * ratio).hex())
    .reverse();
};

const mapGradientToTimestamps = (gradient: string[], timestamps: number[]) => {
  const colors: Map<number, string> = new Map();

  timestamps.sort().forEach((timestamp, index) => {
    const colorIndex = Math.floor(
      (index * gradient.length) / timestamps.length
    );
    colors.set(timestamp, gradient[colorIndex]);
  });

  return colors;
};

const convertPressureToHead = (
  value: number | null,
  unit: string | undefined
): number | null => {
  const pressureToHead = {
    'kg/cm2': (value: number) => value * 10,
    bar: (value: number) => value * 10.2,
    kpa: (value: number) => value * 0.1019,
    m: (value: number) => value * 1,
    ft: (value: number) => value * 1,
    psi: (value: number) => value * 2.31,
  };

  if (unit !== undefined && unit in pressureToHead && value !== null) {
    return pressureToHead[unit.toLowerCase() as keyof typeof pressureToHead](
      value
    );
  }

  return value;
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Design Point
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

type PumpPerformanceChartDesignPointProps = {
  type: 'design-point';
  flowRate?: number;
  flowRateUnit?: string;
  pressure?: number;
  pressureUnit?: string;
  efficiency?: number;
  efficiencyUnit?: string;
  brakeHP?: number;
  brakeHPUnit?: string;
  chartXValue?: number;
  chartXUnit?: string;
  chartYValue?: number;
  chartYUnit?: string;
};

const PumpPerformanceChartDesignPoint = (
  props: PumpPerformanceChartDesignPointProps
): null => {
  const { t } = useGlobalization();
  const instanceRef = Chart.useInstance('PumpPerformanceChartSeries');
  const designPointRef = React.useRef<Highcharts.Series>();

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   *	Sets pressure-head series
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
  React.useEffect(() => {
    designPointRef.current = instanceRef.current?.addSeries({
      type: 'scatter',
      yAxis: 'pressure',
      id: nanoid(),
      name: t('Design Point'),
      showInLegend: false,
      marker: {
        radius: MARKER_SIZE_MAP['XX-Large'],
        symbol: MarkerTypes.Circle,
      },
      color: '#83bc40',
    });
    return () => {
      try {
        designPointRef.current?.remove();
        designPointRef.current = undefined;
      } catch (error) {
        console.error('Failed to remove designPointRef', error);
      }
    };
  }, [instanceRef, designPointRef, t]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets series data
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
  React.useEffect(() => {
    if (!props.chartXValue || !props.chartYValue) return;
    designPointRef.current?.update({
      data: [{ x: props.chartXValue, y: props.chartYValue }],
    } as Highcharts.SeriesScatterOptions);
  }, [designPointRef, props.chartXValue, props.chartYValue]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets tooltip
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
  React.useEffect(() => {
    const getPointFormat = () => {
      const pointFormatArray = [
        `${t('Flow Rate')}: {point.x:.2f} ${props.chartXUnit}`,
      ];
      if (props.flowRateUnit !== props.chartXUnit) {
        // display the original flow rate value and unit
        pointFormatArray.push(` (${props.flowRate} ${props.flowRateUnit})`);
      }
      pointFormatArray.push(
        '<br />',
        `${t('Pressure')}: {point.y:.2f} ${props.chartYUnit}`
      );
      if (props.pressureUnit !== props.chartYUnit) {
        // display the original pressure unit
        pointFormatArray.push(` (${props.pressure} ${props.pressureUnit})`);
      }
      if (props.efficiency && props.efficiencyUnit) {
        pointFormatArray.push(
          '<br />',
          `${t('Efficiency')}: ${props.efficiency?.toFixed(2)} ${props.efficiencyUnit}`
        );
      }
      if (props.brakeHP && props.brakeHPUnit) {
        pointFormatArray.push(
          '<br />',
          `${t('Brake HP')}: ${props.brakeHP?.toFixed(2)} ${props.brakeHPUnit}`
        );
      }
      return pointFormatArray.join('');
    };

    designPointRef.current?.update({
      tooltip: {
        headerFormat: [
          '<span style="color:{point.color}">● </span>',
          `<b style="font-size: 10px">${t('Design Point')}</b><br />`,
        ].join(''),
        pointFormat: getPointFormat(),
      },
    } as Highcharts.SeriesScatterOptions);
  }, [
    designPointRef,
    props.brakeHP,
    props.brakeHPUnit,
    props.chartXUnit,
    props.chartYUnit,
    props.efficiency,
    props.efficiencyUnit,
    props.flowRate,
    props.flowRateUnit,
    props.pressure,
    props.pressureUnit,
    t,
  ]);

  return null;
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export {
  PumpPerformanceChartRoot,
  PumpPerformanceChartSeries,
  PumpPerformanceChartSeriesGroup,
};

export type {
  PumpPerformanceChartContext,
  PumpPerformanceChartDesignPointProps,
  PumpPerformanceChartEventHandlers,
  PumpPerformanceChartManufacturerCurveSeriesData,
  PumpPerformanceChartManufacturerCurveSeriesProps,
  PumpPerformanceChartRootProps,
  PumpPerformanceChartScatterSeriesData,
  PumpPerformanceChartScatterSeriesProps,
  PumpPerformanceChartSeriesGroupProps,
  PumpPerformanceChartSeriesProps,
};
