import styled, { css } from 'styled-components';
import MapboxLayer from '@Map/layers/mapbox/MapboxLayer';
import { rem } from '../../styles';

export const DisplayTypeWrapper = styled.div`
	position: relative;
	width: ${rem(16)};
	height: ${rem(16)};
	display: inline-flex;
	justify-content: center;
	align-items: center;
	margin-right: ${rem(10)};
	flex-shrink: 0;
`;

export const SymbolWrapper = styled.div`
	width: ${rem(16)};
	height: ${rem(16)};
	display: inline-flex;
	justify-content: center;
	margin-right: ${rem(10)};
	position: absolute;
	left: 0;
	background: #fff;
	img {
		max-width: ${rem(24)};
		margin: ${rem(-5)};
	}
`;
interface LineProps {
	pattern?: string;
	lineDashArray?: number[];
	withIcon?: boolean;
	gradient?: string;
	color?: string;
}

export const Line = styled.div.attrs(
	({ pattern, gradient, color }: LineProps) => ({
		style: {
			background: pattern
				? `url("${pattern}")`
				: gradient ?? color ?? MapboxLayer.UNSELECTED_COLOR,
		},
	}),
)<LineProps>`
	width: ${rem(16)};
	height: ${({ pattern }) => (pattern ? rem(10) : rem(4))};
	margin-right: ${rem(10)};
	display: inline-flex;
	position: absolute;
	left: 0;
	${({ withIcon }) =>
		withIcon &&
		css`
			width: ${rem(24)};
			left: ${rem(-4)};
		`}
	span {
		display: block;
		width: 100%;
		height: 100%;
	}
	${({ lineDashArray }) =>
		lineDashArray &&
		css`
			span.break {
				background: #fff;
			}
		`}
`;

export const Polygon = styled.div<{
	borderColor?: string;
	pattern?: string;
	fillOpacity?: number;
	gradient?: string;
}>`
	width: ${rem(16)};
	height: ${rem(16)};
	position: relative;
	border: ${rem(2)} solid
		${({ borderColor }) =>
			borderColor ?? MapboxLayer.UNSELECTED_COLOR_OUTLINE};
	&:before {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		background: ${({ color, pattern, gradient }) =>
			pattern
				? `url("${pattern}")`
				: gradient ?? color ?? MapboxLayer.UNSELECTED_COLOR_FILL};
		background-size: contain;
		opacity: ${({ fillOpacity }) =>
			fillOpacity ?? MapboxLayer.FILL_OPACITY};
	}
`;

export const Circle = styled.div<{
	color: string;
	gradient?: string;
}>`
	width: ${rem(10)};
	height: ${rem(10)};
	border-radius: 100%;
	background: ${({ color, gradient }) => gradient ?? color};
`;

export const CircleOutline = styled.div`
	width: ${rem(10)};
	height: ${rem(10)};
	border-radius: 100%;
	border: 1px solid;
`;
