import { Button, ControlButtonWrapper } from './ControlButton.styles';

import React from 'react';

interface Actions {
	id: string;
	label: string;
	icon: React.ReactNode;
	onClick: () => void;
	dataCy?: string;
}

// Designing this control button to in the future be able to handle multiple actions
// and be able to switch between them, this would likely slide out when clicked
interface ControlButtonProps {
	actions: Actions[];
	active?: boolean;
	dataCy?: string;
}

const ControlButton: React.FC<ControlButtonProps> = ({
	actions,
	active = false,
	dataCy,
}) => {
	return (
		<ControlButtonWrapper data-cy={dataCy ?? 'control-button'}>
			{actions.map(action => (
				<Button
					key={action.id}
					onClick={action.onClick}
					title={action.label}
					$active={active}
					data-cy={action.dataCy ?? 'control-button-action'}>
					{action.icon}
				</Button>
			))}
		</ControlButtonWrapper>
	);
};

export default ControlButton;
