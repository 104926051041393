import { Asset } from '@Utils/types';
import Link from '@mui/material/Link';
import React from 'react';

export interface AssetLinkProps {
	assetId: string;
	assetType: string;
	systemType: string;
	onViewDetails?: ({
		assetId,
		assetType,
		systemType,
	}: Omit<Asset, '_id'>) => void;
}

export const AssetLink = ({
	assetId,
	assetType,
	systemType,
	onViewDetails,
}: AssetLinkProps): JSX.Element => {
	const onClick = () => {
		onViewDetails?.({ assetId, assetType, systemType });
	};

	return (
		<Link
			underline="hover"
			style={{
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				cursor: 'pointer',
			}}
			title={assetId}
			onClick={onClick}>
			{assetId}
		</Link>
	);
};
