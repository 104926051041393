import {
	AssetRiskAndCondition,
	RiskSetting,
	RiskSummary,
} from '@Types/asset.types';
import {
	getAssetRisk,
	getAssetRiskRejected,
	getAssetRiskResolved,
	getRiskSetting,
	getRiskSettingRejected,
	getRiskSettingResolved,
	getRiskSummary,
	getRiskSummaryRejected,
	getRiskSummaryResolved,
} from '@Actions/assetRisk.actions';
import { getRiskApi, getRiskSettingApi, getRiskSummaryApi } from '@Services';
import { put, retry, takeLatest } from 'redux-saga/effects';

import { AnyAction } from 'redux';
import { AxiosResponse } from 'axios';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* getAssetRiskSaga(action: AnyAction) {
	try {
		const payload: AxiosResponse<AssetRiskAndCondition[]> = yield retry(
			5,
			30000,
			getRiskApi,
			action.payload,
		);

		if (payload.data && payload.data.length > 0) {
			yield put(getAssetRiskResolved(payload.data[0]));
		} else {
			yield put(getAssetRiskRejected());
		}
	} catch (e) {
		yield put(getAssetRiskRejected());
	}
}

function* getRiskSettingSaga() {
	try {
		const payload: AxiosResponse<RiskSetting> = yield retry(
			5,
			30000,
			getRiskSettingApi,
		);

		yield put(getRiskSettingResolved(payload.data));
	} catch (e) {
		yield put(getRiskSettingRejected());
	}
}

function* watchGetAssetRisk() {
	yield takeLatest(getAssetRisk, getAssetRiskSaga);
}

function* watchGetRiskSetting() {
	yield takeLatest(getRiskSetting, getRiskSettingSaga);
}

function* getRiskSummarySaga(action: AnyAction) {
	try {
		const payload: AxiosResponse<{
			statusCode: number;
			payload: RiskSummary[];
		}> = yield retry(5, 30000, getRiskSummaryApi, action.payload);

		if (payload.data.statusCode != 200) {
			throw new Error('Failed to get data from endpoint');
		}
		yield put(getRiskSummaryResolved(payload.data.payload));
	} catch (e) {
		yield put(getRiskSummaryRejected());
	}
}

function* watchgetRiskSummary() {
	yield takeLatest(getRiskSummary, getRiskSummarySaga);
}

export default [
	watchGetAssetRisk(),
	watchGetRiskSetting(),
	watchgetRiskSummary(),
];
