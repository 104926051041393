import styled from 'styled-components';
import { rem } from '@innovyze/stylovyze';

export const StyledContainer = styled.div`
	width: 100%;
	height: ${rem(320)};
	position: relative;
	display: table;
`;

export const StyledImage = styled.img`
	max-width: 100%;
	max-height: 100%;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
`;
