import { ChartingErrorBoundary } from '../../atoms';
import { generateIliSeries } from './ConnectedIliChart.utils';
import { IliChartProps } from '../../molecules';
import { RangeSelection } from '../../../types/chartState';
import { StackableChartRef } from '../../../core/components/StackableChart';
import React, { forwardRef, ReactElement, useMemo } from 'react';
import useIli from '../../../core/hooks/useIli';
import {
  InsightIliChart,
  InsightIliChartSeriesProps,
} from '../../../_next/presets/insight-ili-chart';
import { TimeRangeSelection } from '../../../_next/core/_insight-chart';
import { Theme } from '../../../types';

export interface ConnectedIliChartProps
  extends Omit<IliChartProps, 'series' | 'data' | 'syncDateTimeRangePicker'> {
  alwaysFetchData?: boolean;
  dataRangeSelection?: RangeSelection;
  iliId: string;
  customData?: {
    series?: (InsightIliChartSeriesProps & {
      customData: [number, number | null][];
    })[];
  };
  timeRangeSelection?: TimeRangeSelection;
  onTimeRangeSelectionChange?: (timeRangeSelection: TimeRangeSelection) => void;
  selectedTheme?: Theme;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Version Switch
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const ConnectedIliChart = (
  props: ConnectedIliChartProps,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref?: any
): ReactElement => {
  return <IliChartV2 {...props} ref={ref} />;
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * New Chart
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const IliChartV2 = React.forwardRef<
  { chart: Highcharts.Chart | undefined },
  ConnectedIliChartProps
>((props, ref): React.ReactElement => {
  const asyncIli = useIli(props.iliId);

  const iliSeries = useMemo(() => {
    if (props.customData?.series) return props.customData.series;
    const generatedSeries =
      asyncIli.status === 'resolved' ? generateIliSeries(asyncIli.data) : [];

    return generatedSeries.map((series) => {
      return {
        id: series.id,
        sensorId: series.dataSource.sensorId,
        resolution: series.dataSource.resolution,
        hidden: props.hiddenSeries?.includes(series.id),
      } as InsightIliChartSeriesProps;
    });
  }, [
    props.customData?.series,
    props.hiddenSeries,
    asyncIli.status,
    asyncIli.data,
  ]);

  const stacked = props.displayOptions?.overlay === false ? true : false;

  return (
    <ChartingErrorBoundary chartProps={props}>
      <InsightIliChart
        ref={ref}
        series={iliSeries}
        enableChartStack={stacked}
        enableHorizontalGrids={props.displayOptions?.showYGrid}
        enableVerticalGrids={props.displayOptions?.showXGrid}
        enableMarkers={props.displayOptions?.showMarkers}
        customData={props.customData}
        timeRangeSelection={props.timeRangeSelection}
        onTimeRangeSelectionChange={props.onTimeRangeSelectionChange}
        onSeriesVisibilityChange={(seriesId, type) => {
          const hiddenSeries = props.hiddenSeries
            ? [...props.hiddenSeries]
            : [];

          const newHiddenSeries =
            type === 'hide'
              ? [...hiddenSeries, seriesId]
              : hiddenSeries.filter((series) => series !== seriesId);

          props.onHiddenSeriesChange?.(newHiddenSeries);
        }}
        selectedTheme={props.selectedTheme}
      />
    </ChartingErrorBoundary>
  );
});

IliChartV2.displayName = 'IliChartV2';

export default forwardRef<StackableChartRef, ConnectedIliChartProps>(
  ConnectedIliChart
);
