import { addNoteApi, getFullInspApi, getNotesApi } from '@Services';
import { put, retry, takeLatest } from 'redux-saga/effects';

import { AnyAction } from 'redux';
import { AxiosResponse } from 'axios';
import {
	addNote,
	addNoteRejected,
	getFullInsp,
	getFullInspRejected,
	getFullInspResolved,
	getNotes,
	getNotesRejected,
	getNotesResolved,
	setFullInspectionIsWaiting,
	setNotesIsWaiting,
} from '@Actions/fullInspection.actions';
import {
	GetFullInspResolvedParams,
	MACPFullInspection,
	MSCCFullInspection,
	PACPFullInspection,
	WSA05FullInspection,
} from '@Types/fullInspection.types';
import {
	formatDateWithFormatDate,
	formatTimeWithFormatDate,
} from '@Utils/date.utils';
import { GetNotesResolvedParams } from '@Types/ObservationNotes.types';
import { FormatDateOptions } from '@innovyze/stylovyze';
import { InspectionStandard, SAASObject } from '@Types';
import { fixupObservationsCounterAndTime } from '@Utils';
import { extractValue } from '@Components/AssetDetails/DetailsOverview/ValueMapping';

const defaultValue = (orig: string | number | undefined, out = '-') => {
	if (orig == null || orig == undefined || orig.toString().trim().length == 0)
		return out;
	else {
		const returnStr = orig.toString();
		if (returnStr.toLowerCase() == 'true') {
			return 'Yes';
		} else if (returnStr.toLowerCase() == 'false') {
			return 'No';
		}
		return returnStr;
	}
};

function parsePACPInspection(
	insp: PACPFullInspection,
	formatDateUTC: (options: FormatDateOptions) => string,
) {
	let scheduledDate = '';
	let inspectionDate = '';
	let inspectionTime = '';
	let dateCleaned = '';

	if (insp.scheduledDate) {
		scheduledDate = extractValue(insp.scheduledDate) ?? '';
	}

	if (insp.surveyDateTime && insp.surveyDateTime != '') {
		inspectionDate =
			formatDateWithFormatDate(
				insp.surveyDateTime.toString(),
				formatDateUTC,
			) ?? '';
		inspectionTime =
			formatTimeWithFormatDate(
				insp.surveyDateTime.toString(),
				formatDateUTC,
			) ?? '';
	}

	if (insp.dateCleaned) {
		dateCleaned = extractValue(insp.dateCleaned) ?? '';
	}

	const fullInsp: PACPFullInspection = {
		...insp,
		// Inspection generic
		_id: insp._id,
		inspectionId: insp.inspectionId,
		validationState: defaultValue(insp.validationState),
		uploadedBy: defaultValue(insp.uploadedBy),
		uploadDateTime: defaultValue(insp.uploadDateTime),
		scheduledDate: defaultValue(scheduledDate),
		missingMedia: insp.missingMedia,
		validationData: insp.validationData,
		mediaInspections: insp.mediaInspections,
		conditions: insp.conditions
			? fixupObservationsCounterAndTime(insp.conditions)
			: insp.conditions,
		isImperial: insp.isImperial,
		tenantId: defaultValue(insp.tenantId),
		inspectionStatus: defaultValue(insp.inspectionStatus),
		// PACP Specific
		surveyedBy: defaultValue(insp.surveyedBy),
		certificateNumber: defaultValue(insp.certificateNumber),
		owner: defaultValue(insp.owner),
		customer: defaultValue(insp.customer),
		drainageArea: defaultValue(insp.drainageArea),
		poNumber: defaultValue(insp.poNumber),
		pipelineRef: defaultValue(insp.pipelineRef),
		inspectionDate: defaultValue(inspectionDate),
		inspectionTime: defaultValue(inspectionTime),
		street: defaultValue(insp.street),
		city: defaultValue(insp.city),
		locationDetails: defaultValue(insp.locationDetails),
		upstreamManholeId: defaultValue(insp.upstreamManholeId),
		upRimToInvert: defaultValue(insp.upRimToInvert),
		upGradeToInvert: defaultValue(insp.upGradeToInvert),
		upRimToGrade: defaultValue(insp.upRimToGrade),
		downstreamManholeId: defaultValue(insp.downstreamManholeId),
		downRimToInvert: defaultValue(insp.downRimToInvert),
		downGradeToInvert: defaultValue(insp.downGradeToInvert),
		downRimToGrade: defaultValue(insp.downRimToGrade),
		sewerUse: defaultValue(insp.sewerUse),
		direction: defaultValue(insp.direction),
		// inspectionTechnologyUsedCCTV: defaultValue(
		// 	insp.inspectionTechnologyUsedCCTV,
		// ),
		// inspectionTechnologyUsedLaser: defaultValue(
		// 	insp.inspectionTechnologyUsedLaser,
		// ),
		// inspectionTechnologyUsedSonar: defaultValue(
		// 	insp.inspectionTechnologyUsedSonar,
		// ),
		// inspectionTechnologyUsedSidewall: defaultValue(
		// 	insp.inspectionTechnologyUsedSidewall,
		// ),
		// inspectionTechnologyUsedZoom: defaultValue(
		// 	insp.inspectionTechnologyUsedZoom,
		// ),
		// inspectionTechnologyUsedOther: defaultValue(
		// 	insp.inspectionTechnologyUsedOther,
		// ),
		flowControl: defaultValue(insp.flowControl),
		height: defaultValue(insp.height),
		width: defaultValue(insp.width),
		shape: defaultValue(insp.shape),
		material: defaultValue(insp.material),
		liningMethod: defaultValue(insp.liningMethod),
		coatingMethod: defaultValue(insp.coatingMethod),
		pipeJointLength: defaultValue(insp.pipeJointLength),
		totalLength: defaultValue(insp.totalLength),
		lengthSurveyed: defaultValue(insp.lengthSurveyed),
		yearConstructed: defaultValue(insp.yearConstructed),
		yearRenewed: defaultValue(insp.yearRenewed),
		mediaLabel: defaultValue(insp.mediaLabel),
		purpose: defaultValue(insp.purpose),
		sewerCategory: defaultValue(insp.sewerCategory),
		preCleaning: defaultValue(insp.preCleaning),
		dateCleaned: defaultValue(dateCleaned),
		weather: defaultValue(insp.weather),
		locationCode: defaultValue(insp.locationCode),
		additionalInfo: defaultValue(insp.additionalInfo),
		reverseSetup: defaultValue(insp.reverseSetup),
		sheetNumber: defaultValue(insp.sheetNumber),
		pressureValue: defaultValue(insp.pressureValue),
		workOrder: defaultValue(insp.workOrder),
		project: defaultValue(insp.project),
		upNorthing: defaultValue(insp.upNorthing),
		upEasting: defaultValue(insp.upEasting),
		upElevation: defaultValue(insp.upElevation),
		downNorthing: defaultValue(insp.downNorthing),
		downEasting: defaultValue(insp.downEasting),
		downElevation: defaultValue(insp.downElevation),
		mhCoordinateSystem: defaultValue(insp.mhCoordinateSystem),
		verticalDatum: defaultValue(insp.verticalDatum),
		gpsAccuracy: defaultValue(insp.gpsAccuracy),
		status: defaultValue(insp.status),
		exported: defaultValue(insp.exported),
		projectId: defaultValue(insp.projectId),
		surveyDateTime: insp.surveyDateTime,
		reviewedBy: defaultValue(insp.reviewedBy),
		reviewerCertificateNumber: defaultValue(insp.reviewerCertificateNumber),
		consequenceOfFailure: defaultValue(insp.consequenceOfFailure),
		coordinateSystem: defaultValue(insp.coordinateSystem),
		date: defaultValue(insp.date),
		easting: defaultValue(insp.easting),
		elevation: defaultValue(insp.elevation),
		northing: defaultValue(insp.northing),
		time: defaultValue(insp.time),
		yearLaid: defaultValue(insp.yearLaid),
	};

	return fullInsp;
}

function parseMACPInspection(
	insp: MACPFullInspection,
	formatDateUTC: (options: FormatDateOptions) => string,
) {
	let scheduledDate = '';
	let date = '';
	let time = '';
	let dateCleaned = '';

	if (insp.scheduledDate) {
		scheduledDate = extractValue(insp.scheduledDate) ?? '';
	}

	if (insp.surveyDateTime && insp.surveyDateTime != '') {
		date =
			formatDateWithFormatDate(
				insp.surveyDateTime.toString(),
				formatDateUTC,
			) ?? '';
		time =
			formatTimeWithFormatDate(
				insp.surveyDateTime.toString(),
				formatDateUTC,
			) ?? '';
	}

	if (insp.dateCleaned) {
		dateCleaned =
			formatDateWithFormatDate(
				extractValue(insp.dateCleaned as unknown as SAASObject) ?? '',
				formatDateUTC,
			) ?? '';
	}

	const fullInsp: MACPFullInspection = {
		...insp,
		// Inspection generic
		_id: insp._id,
		inspectionId: insp.inspectionId,
		validationState: defaultValue(insp.validationState),
		uploadedBy: defaultValue(insp.uploadedBy),
		uploadDateTime: defaultValue(insp.uploadDateTime),
		scheduledDate: defaultValue(scheduledDate),
		missingMedia: insp.missingMedia,
		validationData: insp.validationData,
		mediaInspections: insp.mediaInspections,
		conditions: insp.conditions
			? fixupObservationsCounterAndTime(insp.conditions)
			: insp.conditions,
		isImperial: insp.isImperial,
		tenantId: defaultValue(insp.tenantId),
		inspectionStatus: defaultValue(insp.inspectionStatus),
		// MACP Specific
		surveyedBy: defaultValue(insp.surveyedBy),
		certificateNumber: defaultValue(insp.certificateNumber),
		owner: defaultValue(insp.owner),
		customer: defaultValue(insp.customer),
		drainageArea: defaultValue(insp.drainageArea),
		sheetNumber: defaultValue(insp.sheetNumber),
		poNumber: defaultValue(insp.poNumber),
		date: date,
		time: time,
		street: defaultValue(insp.street),
		city: defaultValue(insp.city),
		locationDetails: defaultValue(insp.locationDetails),
		manholeNumber: defaultValue(insp.manholeNumber),
		rimToInvert: defaultValue(insp.rimToInvert),
		gradeToInvert: defaultValue(insp.gradeToInvert),
		rimToGrade: defaultValue(insp.rimToGrade),
		mhUse: defaultValue(insp.mhUse),
		yearBuilt: defaultValue(insp.yearBuilt),
		yearRenewed: defaultValue(insp.yearRenewed),
		mediaLabel: defaultValue(insp.mediaLabel),
		purpose: defaultValue(insp.purpose),
		category: defaultValue(insp.category),
		preCleaning: defaultValue(insp.preCleaning),
		dateCleaned: dateCleaned,
		weather: defaultValue(insp.weather),
		locationCode: defaultValue(insp.locationCode),
		additionalInfo: defaultValue(insp.additionalInfo),
		surfaceTypeAsphalt: defaultValue(insp.surfaceTypeAsphalt),
		surfaceTypeConcretePavement: defaultValue(
			insp.surfaceTypeConcretePavement,
		),
		surfaceTypeConcreteCollar: defaultValue(insp.surfaceTypeConcreteCollar),
		surfaceTypeGrassDirt: defaultValue(insp.surfaceTypeGrassDirt),
		surfaceTypeGravel: defaultValue(insp.surfaceTypeGravel),
		surfaceTypeOther: defaultValue(insp.surfaceTypeOther),
		potentialForRunoff: defaultValue(insp.potentialForRunoff),
		accessType: defaultValue(insp.accessType),
		northing: defaultValue(insp.northing),
		easting: defaultValue(insp.easting),
		elevation: defaultValue(insp.elevation),
		coordinateSystem: defaultValue(insp.coordinateSystem),
		gpsAccuracy: defaultValue(insp.gpsAccuracy),
		evidenceSurcharge: defaultValue(insp.evidenceSurcharge),
		coverShape: defaultValue(insp.coverShape),
		coverSize: defaultValue(insp.coverSize),
		coverSizeWidth: defaultValue(insp.coverSizeWidth),
		coverMaterial: defaultValue(insp.coverMaterial),
		coverTypeSolid: defaultValue(insp.coverTypeSolid),
		coverTypeVented: defaultValue(insp.coverTypeVented),
		coverTypeGasketed: defaultValue(insp.coverTypeGasketed),
		coverTypeBolted: defaultValue(insp.coverTypeBolted),
		coverTypeInnerCover: defaultValue(insp.coverTypeInnerCover),
		coverTypeLocking: defaultValue(insp.coverTypeLocking),
		coverTypeHatchSingle: defaultValue(insp.coverTypeHatchSingle),
		coverTypeHatchDouble: defaultValue(insp.coverTypeHatchDouble),
		coverTypeLamphole: defaultValue(insp.coverTypeLamphole),
		holeDiameter: defaultValue(insp.holeDiameter),
		holeNumber: defaultValue(insp.holeNumber),
		coverBearingSurfaceDia: defaultValue(insp.coverBearingSurfaceDia),
		coverBearingSurfaceDiaWidth: defaultValue(
			insp.coverBearingSurfaceDiaWidth,
		),
		coverFrameFit: defaultValue(insp.coverFrameFit),
		coverConditionSound: defaultValue(insp.coverConditionSound),
		coverConditionCracked: defaultValue(insp.coverConditionCracked),
		coverConditionBroken: defaultValue(insp.coverConditionBroken),
		coverConditionCorroded: defaultValue(insp.coverConditionCorroded),
		coverConditionBoltsMissing: defaultValue(
			insp.coverConditionBoltsMissing,
		),
		coverConditionMissing: defaultValue(insp.coverConditionMissing),
		coverConditionRestraintMissing: defaultValue(
			insp.coverConditionRestraintMissing,
		),
		coverConditionRestraintDefective: defaultValue(
			insp.coverConditionRestraintDefective,
		),
		coverInsertType: defaultValue(insp.coverInsertType),
		insertConditionSound: defaultValue(insp.insertConditionSound),
		insertConditionPoorlyFitting: defaultValue(
			insp.insertConditionPoorlyFitting,
		),
		insertConditionCracked: defaultValue(insp.insertConditionCracked),
		insertConditionLeaking: defaultValue(insp.insertConditionLeaking),
		insertConditionInsertFell: defaultValue(insp.insertConditionInsertFell),
		insertConditionCorroded: defaultValue(insp.insertConditionCorroded),
		adjustmentRingType: defaultValue(insp.adjustmentRingType),
		adjustmentRingMaterial: defaultValue(insp.adjustmentRingMaterial),
		ringConditionSound: defaultValue(insp.ringConditionSound),
		ringConditionCracked: defaultValue(insp.ringConditionCracked),
		ringConditionBroken: defaultValue(insp.ringConditionBroken),
		ringConditionCorroded: defaultValue(insp.ringConditionCorroded),
		ringConditionLeaking: defaultValue(insp.ringConditionLeaking),
		ringConditionPoorInstall: defaultValue(insp.ringConditionPoorInstall),
		adjustmentRingHeight: defaultValue(insp.adjustmentRingHeight),
		frameMaterial: defaultValue(insp.frameMaterial),
		frameBearingSurfaceWidth: defaultValue(insp.frameBearingSurfaceWidth),
		frameBearingSurfaceDepth: defaultValue(insp.frameBearingSurfaceDepth),
		frameClearOpenDiam: defaultValue(insp.frameClearOpenDiam),
		frameConditionSound: defaultValue(insp.frameConditionSound),
		frameConditionCracked: defaultValue(insp.frameConditionCracked),
		frameConditionBroken: defaultValue(insp.frameConditionBroken),
		frameConditionMissing: defaultValue(insp.frameConditionMissing),
		frameConditionCorroded: defaultValue(insp.frameConditionCorroded),
		frameConditionCoated: defaultValue(insp.frameConditionCoated),
		sealConditionSound: defaultValue(insp.frameConditionSound),
		sealConditionCracked: defaultValue(insp.frameConditionCracked),
		sealConditionLoose: defaultValue(insp.sealConditionLoose),
		sealConditionOffset: defaultValue(insp.sealConditionOffset),
		sealConditionMissing: defaultValue(insp.sealConditionMissing),
		frameOffsetDistance: defaultValue(insp.frameOffsetDistance),
		frameSealInflow: defaultValue(insp.frameSealInflow),
		frameDepth: defaultValue(insp.frameDepth),
		chimneyMaterial1: defaultValue(insp.chimneyMaterial1),
		chimneyMaterial2: defaultValue(insp.chimneyMaterial2),
		chimneyInI: defaultValue(insp.chimneyInI),
		chimneyClearOpening: defaultValue(insp.chimneyClearOpening),
		chimneyDepth: defaultValue(insp.chimneyDepth),
		chimneyLiningInterior: defaultValue(insp.chimneyLiningInterior),
		chimneyLiningExterior: defaultValue(insp.chimneyLiningExterior),
		coneType: defaultValue(insp.coneType),
		coneMaterial: defaultValue(insp.coneMaterial),
		coneDepth: defaultValue(insp.coneDepth),
		coneLiningInterior: defaultValue(insp.coneLiningInterior),
		coneLiningExterior: defaultValue(insp.coneLiningExterior),
		wallDiam: defaultValue(insp.wallDiam),
		wallBySize: defaultValue(insp.wallBySize),
		wallMaterial: defaultValue(insp.wallMaterial),
		wallDepth: defaultValue(insp.wallDepth),
		wallLiningInterior: defaultValue(insp.wallLiningInterior),
		wallLiningExterior: defaultValue(insp.wallLiningExterior),
		benchPresent: defaultValue(insp.benchPresent),
		benchMaterial: defaultValue(insp.benchMaterial),
		benchLining: defaultValue(insp.benchLining),
		channelInstalled: defaultValue(insp.channelInstalled),
		channelMaterial: defaultValue(insp.channelMaterial),
		channelType: defaultValue(insp.channelType),
		channelExposure: defaultValue(insp.channelExposure),
		stepNumber: defaultValue(insp.stepNumber),
		stepMaterial: defaultValue(insp.stepMaterial),
		workOrder: defaultValue(insp.workOrder),
		project: defaultValue(insp.project),
		pressureValue: defaultValue(insp.pressureValue),
		additionalComponentInfo: defaultValue(insp.additionalComponentInfo),
		inspectionLevel: defaultValue(insp.inspectionLevel),
		exported: defaultValue(insp.exported),
		status: defaultValue(insp.status),
		wallDiameter: defaultValue(insp.wallDiameter),
		frameClearOpenDiameter: defaultValue(insp.frameClearOpenDiameter),
		frameClearOpenWidth: defaultValue(insp.frameClearOpenWidth),
		rimToGradeExposed: defaultValue(insp.rimToGradeExposed),
		centerCoverSize: defaultValue(insp.centerCoverSize),
		consequenceOfFailure: defaultValue(insp.consequenceOfFailure),
		inflowPotentialFromRunoff: defaultValue(insp.inflowPotentialFromRunoff),
		yearConstructed: defaultValue(insp.yearConstructed),
		chimneyIni: defaultValue(insp.chimneyIni),
		verticalDatum: defaultValue(insp.verticalDatum),
		benchCondition: defaultValue(insp.benchCondition),
		coverTypeRemovableCenter: defaultValue(insp.coverTypeRemovableCenter),
		reviewedBy: defaultValue(insp.reviewedBy),
		reviewerCertificateNumber: defaultValue(insp.reviewerCertificateNumber),
	};

	return fullInsp;
}

function parseMSCCInspection(
	insp: MSCCFullInspection,
	formatDateUTC: (options: FormatDateOptions) => string,
) {
	let scheduledDate = '';
	let date = '';
	let time = '';

	if (insp.scheduledDate) {
		scheduledDate = extractValue(insp.scheduledDate) ?? '';
	}

	if (insp.surveyDateTime && insp.surveyDateTime != '') {
		date =
			formatDateWithFormatDate(
				insp.surveyDateTime.toString(),
				formatDateUTC,
			) ?? '';
		time =
			formatTimeWithFormatDate(
				insp.surveyDateTime.toString(),
				formatDateUTC,
			) ?? '';
	}

	const fullInsp: MSCCFullInspection = {
		...insp,
		// Inspection generic
		_id: insp._id,
		inspectionId: insp.inspectionId,
		validationState: defaultValue(insp.validationState),
		uploadedBy: defaultValue(insp.uploadedBy),
		uploadDateTime: defaultValue(insp.uploadDateTime),
		scheduledDate: defaultValue(scheduledDate),
		missingMedia: insp.missingMedia,
		validationData: insp.validationData,
		mediaInspections: insp.mediaInspections,
		conditions: insp.conditions
			? fixupObservationsCounterAndTime(insp.conditions)
			: insp.conditions,
		isImperial: insp.isImperial,
		tenantId: defaultValue(insp.tenantId),
		inspectionStatus: defaultValue(insp.inspectionStatus),
		// MSCC Specific
		client: defaultValue(insp.client),
		surveyedBy: defaultValue(insp.surveyedBy),
		clientsJobRef: defaultValue(insp.clientsJobRef),
		contractorsJobRef: defaultValue(insp.contractorsJobRef),
		date: defaultValue(date),
		time: defaultValue(time),
		useOfDrainSewer: defaultValue(insp.useOfDrainSewer),
		typeOfDrainSewer: defaultValue(insp.typeOfDrainSewer),
		direction: defaultValue(insp.direction),
		precleaned: defaultValue(insp.precleaned),
		criticalDrainSewer: defaultValue(insp.criticalDrainSewer),
		purposeOfInspection: defaultValue(insp.purposeOfInspection),
		inspectionStage: defaultValue(insp.inspectionStage),
		flowControlMeasures: defaultValue(insp.flowControlMeasures),
		weather: defaultValue(insp.weather),
		temperature: defaultValue(insp.temperature),
		expectedLength: defaultValue(insp.expectedLength),
		methodOfInspection: defaultValue(insp.methodOfInspection),
		standard: defaultValue(insp.standard),
		clientDefined1: defaultValue(insp.clientDefined1),
		clientDefined2: defaultValue(insp.clientDefined2),
		clientDefined3: defaultValue(insp.clientDefined3),
		clientDefined4: defaultValue(insp.clientDefined4),
		clientDefined5: defaultValue(insp.clientDefined5),
		clientDefined6: defaultValue(insp.clientDefined6),
		generalRemarks: defaultValue(insp.generalRemarks),
		drainageArea: defaultValue(insp.drainageArea),
		divisionDistrict: defaultValue(insp.divisionDistrict),
		locationStreet: defaultValue(insp.locationStreet),
		locationTown: defaultValue(insp.locationTown),
		locationTypeCode: defaultValue(insp.locationTypeCode),
		landOwner: defaultValue(insp.landOwner),
		upstreamManholeId: defaultValue(insp.upstreamManholeId),
		downstreamManholeId: defaultValue(insp.downstreamManholeId),
		pipelineLengthRef: defaultValue(insp.pipelineLengthRef),
		heightDiameter: defaultValue(insp.heightDiameter),
		width: defaultValue(insp.width),
		pipeUnitLength: defaultValue(insp.pipeUnitLength),
		shape: defaultValue(insp.shape),
		material: defaultValue(insp.material),
		liningMaterial: defaultValue(insp.liningMaterial),
		liningType: defaultValue(insp.liningType),
		yearConstructed: defaultValue(insp.yearConstructed),
		longLocStartLat: defaultValue(insp.longLocStartLat),
		circLocStartLat: defaultValue(insp.circLocStartLat),
		startNodeRef: defaultValue(insp.startNodeRef),
		depthAtStartNode: defaultValue(insp.depthAtStartNode),
		startNodeGridRefX: defaultValue(
			insp.startNodeGridRef?.startNodeGridRefX,
		),
		startNodeGridRefY: defaultValue(
			insp.startNodeGridRef?.startNodeGridRefY,
		),
		node1Ref: defaultValue(insp.node1Ref),
		node1GridRefX: defaultValue(insp.node1GridRef?.node1GridRefX),
		node1GridRefY: defaultValue(insp.node1GridRef?.node1GridRefY),
		finishNodeRef: defaultValue(insp.finishNodeRef),
		depthAtFinishNode: defaultValue(insp.depthAtFinishNode),
		node2GridRefX: defaultValue(insp.node2GridRef?.node2GridRefX),
		node2GridRefY: defaultValue(insp.node2GridRef?.node2GridRefY),
		node3Ref: defaultValue(insp.node3Ref),
		node3GridRefX: defaultValue(insp.node3GridRef?.node3GridRefX),
		node3GridRefY: defaultValue(insp.node3GridRef?.node3GridRefY),
		videoImageStorage: defaultValue(insp.videoImageStorage),
		videoImageLocationSystem: defaultValue(insp.videoImageLocationSystem),
		videoImageFormat: defaultValue(insp.videoImageFormat),
		videoImageFilename: defaultValue(insp.videoImageFilename),
		videoImageVolume: defaultValue(insp.videoImageVolume),
		photographicStorageFormat: defaultValue(insp.photographicStorageFormat),
		photographicStorage: defaultValue(insp.photographicStorage),
	};

	return fullInsp;
}

function parseWSA05Inspection(
	insp: WSA05FullInspection,
	formatDateUTC: (options: FormatDateOptions) => string,
) {
	let scheduledDate = '';
	let inspectionDate = '';
	let inspectionTime = '';

	if (insp.scheduledDate) {
		scheduledDate = extractValue(insp.scheduledDate) ?? '';
	}

	if (insp.surveyDateTime && insp.surveyDateTime != '') {
		inspectionDate =
			formatDateWithFormatDate(
				insp.surveyDateTime.toString(),
				formatDateUTC,
			) ?? '';
		inspectionTime =
			formatTimeWithFormatDate(
				insp.surveyDateTime.toString(),
				formatDateUTC,
			) ?? '';
	}

	const fullInsp: WSA05FullInspection = {
		...insp,
		// Inspection generic
		_id: insp._id,
		inspectionId: insp.inspectionId,
		validationState: defaultValue(insp.validationState),
		uploadedBy: defaultValue(insp.uploadedBy),
		uploadDateTime: defaultValue(insp.uploadDateTime),
		scheduledDate: defaultValue(scheduledDate),
		missingMedia: insp.missingMedia,
		validationData: insp.validationData,
		mediaInspections: insp.mediaInspections,
		conditions: insp.conditions
			? fixupObservationsCounterAndTime(insp.conditions)
			: insp.conditions,
		isImperial: insp.isImperial,
		tenantId: defaultValue(insp.tenantId),
		inspectionStatus: defaultValue(insp.inspectionStatus),
		// WSA Specific
		surveyDateTime: defaultValue(insp.surveyDateTime),
		inspectionDate: defaultValue(inspectionDate),
		inspectionTime: defaultValue(inspectionTime),
		pipelineRef: defaultValue(insp.pipelineRef),
		startNodeRef: defaultValue(insp.startNodeRef),
		startNodeCoordinates: defaultValue(insp.startNodeCoordinates),
		finishNodeRef: defaultValue(insp.finishNodeRef),
		finishNodeCoordinates: defaultValue(insp.finishNodeCoordinates),
		longLocStartLat: defaultValue(insp.longLocStartLat),
		circLocStartLat: defaultValue(insp.circLocStartLat),
		location: defaultValue(insp.location),
		direction: defaultValue(insp.direction),
		locationTypeCode: defaultValue(insp.locationTypeCode),
		assetOwnerOrEngagingAgency: defaultValue(
			insp.assetOwnerOrEngagingAgency,
		),
		locationTown: defaultValue(insp.locationTown),
		divisionDistrict: defaultValue(insp.divisionDistrict),
		nameOfConduitSystem: defaultValue(insp.nameOfConduitSystem),
		landOwner: defaultValue(insp.landOwner),
		mappingGridDatumSystem: defaultValue(insp.mappingGridDatumSystem),
		mappingGridZone: defaultValue(insp.mappingGridZone),
		standard: defaultValue(insp.standard),
		originalCodingStandard: defaultValue(insp.originalCodingStandard),
		longitudinalReferencePoint: defaultValue(
			insp.longitudinalReferencePoint,
		),
		conduitLocationRiskFactorObservedByOperator: defaultValue(
			insp.conduitLocationRiskFactorObservedByOperator,
		),
		methodOfInspection: defaultValue(insp.methodOfInspection),
		operator: defaultValue(insp.operator),
		companyJobRef: defaultValue(insp.companyJobRef),
		assetOwnerJobRef: defaultValue(insp.assetOwnerJobRef),
		coderAssessor: defaultValue(insp.coderAssessor),
		videoImageLocationSystem: defaultValue(insp.videoImageLocationSystem),
		evidenceOfSurchargeInStartNode: defaultValue(
			insp.evidenceOfSurchargeInStartNode,
		),
		purposeOfInspection: defaultValue(insp.purposeOfInspection),
		anticipatedLength: defaultValue(insp.anticipatedLength),
		actualLength: defaultValue(insp.actualLength),
		nameOfCompanyResponsibleForInspection: defaultValue(
			insp.nameOfCompanyResponsibleForInspection,
		),
		shape: defaultValue(insp.shape),
		height: defaultValue(insp.height),
		width: defaultValue(insp.width),
		material: defaultValue(insp.material),
		liningType: defaultValue(insp.liningType),
		liningMaterial: defaultValue(insp.liningMaterial),
		conduitUnitLength: defaultValue(insp.conduitUnitLength),
		depthAtStartNode: defaultValue(insp.depthAtStartNode),
		depthAtFinishNode: defaultValue(insp.depthAtFinishNode),
		operationOfConduit: defaultValue(insp.operationOfConduit),
		useOfConduit: defaultValue(insp.useOfConduit),
		criticality: defaultValue(insp.criticality),
		cleaning: defaultValue(insp.cleaning),
		yearCameIntoOperation: defaultValue(insp.yearCameIntoOperation),
		jointingMethod: defaultValue(insp.jointingMethod),
		precipitation: defaultValue(insp.precipitation),
		temperature: defaultValue(insp.temperature),
		flowControlMeasures: defaultValue(insp.flowControlMeasures),
		tidalInfluence: defaultValue(insp.tidalInfluence),
		generalComment: defaultValue(insp.generalComment),
		userDefined1: defaultValue(insp.userDefined1),
		userDefined2: defaultValue(insp.userDefined2),
		userDefined3: defaultValue(insp.userDefined3),
		userDefined4: defaultValue(insp.userDefined4),
		userDefined5: defaultValue(insp.userDefined5),
	};

	return fullInsp;
}

function* getFullInspSaga(action: AnyAction) {
	try {
		const payload: AxiosResponse<GetFullInspResolvedParams> = yield retry(
			5,
			15000,
			getFullInspApi,
			action.payload.inspectionId,
			action.payload.standard,
			action.payload.enableMediaTranscode,
		);
		if (payload?.data?.inspection && payload?.data?.inspection.length > 0) {
			switch (action.payload.standard) {
				case InspectionStandard.PACP:
					{
						const fullInsp = parsePACPInspection(
							payload.data.inspection[0] as PACPFullInspection,
							action.payload.formatDateUTC,
						);
						yield put(
							getFullInspResolved({
								inspection: [fullInsp],
								notes: payload.data.inspection[0].notes,
							}),
						);
					}
					break;
				case InspectionStandard.MACP:
					{
						const fullInsp = parseMACPInspection(
							payload.data.inspection[0] as MACPFullInspection,
							action.payload.formatDateUTC,
						);
						yield put(
							getFullInspResolved({
								inspection: [fullInsp],
								notes: payload.data.inspection[0].notes,
							}),
						);
					}
					break;
				case InspectionStandard.MSCC:
					{
						const fullInsp = parseMSCCInspection(
							payload.data.inspection[0] as MSCCFullInspection,
							action.payload.formatDateUTC,
						);
						yield put(
							getFullInspResolved({
								inspection: [fullInsp],
								notes: payload.data.inspection[0].notes,
							}),
						);
					}
					break;
				case InspectionStandard.WSA05:
					{
						const fullInsp = parseWSA05Inspection(
							payload.data.inspection[0] as WSA05FullInspection,
							action.payload.formatDateUTC,
						);
						yield put(
							getFullInspResolved({
								inspection: [fullInsp],
								notes: payload.data.inspection[0].notes,
							}),
						);
					}
					break;
				default:
					{
						// Tasks
						yield put(
							getFullInspResolved({
								inspection: [payload.data.inspection[0]],
								notes: payload.data.inspection[0].notes,
							}),
						);
					}
					break;
			}
		}
	} catch (e) {
		console.log(e);
		yield put(getFullInspRejected());
		yield put(setFullInspectionIsWaiting(false));
	}
}

function* watchGetFullInsp() {
	yield takeLatest(getFullInsp, getFullInspSaga);
}

function* getNotesSaga(action: AnyAction) {
	yield put(setNotesIsWaiting(true));
	try {
		const payload: AxiosResponse<GetNotesResolvedParams> = yield retry(
			5,
			15000,
			getNotesApi,
			action.payload._id,
		);
		yield put(getNotesResolved(payload.data));
		yield put(setNotesIsWaiting(false));
	} catch (e) {
		yield put(getNotesRejected());
		yield put(setNotesIsWaiting(false));
	}
}

function* watchGetNotes() {
	yield takeLatest(getNotes, getNotesSaga);
}

function* addNoteSaga(action: AnyAction) {
	yield put(setNotesIsWaiting(true));
	try {
		yield retry(5, 15000, addNoteApi, action.payload);
		yield put(setNotesIsWaiting(false));
		yield put(getNotes({ _id: action.payload._id }));
	} catch (e) {
		yield put(addNoteRejected());
		yield put(setNotesIsWaiting(false));
	}
}

function* watchAddNote() {
	yield takeLatest(addNote, addNoteSaga);
}

export default [watchGetFullInsp(), watchGetNotes(), watchAddNote()];
