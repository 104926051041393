import { getValue, passInId } from './shared';

import { createSelector } from '@reduxjs/toolkit';

export const selectFacilityBase = createSelector(
	[getValue],
	moduleState => moduleState.facility,
);

export const selectFacilities = createSelector(
	[selectFacilityBase],
	baseState => baseState.facilitiesList,
);

export const selectFacilityById = createSelector(
	[selectFacilities, passInId],
	(facilities, selectedItem) => selectedItem && facilities[selectedItem],
);

export const selectSensorIdsMap = createSelector(
	[selectFacilityBase],
	state => state.sensorIdsMap,
);

export const selectFacilitySensors = createSelector(
	[selectFacilityById, selectSensorIdsMap],
	(facility, sensorIdsMap) =>
		facility && facility.sensors &&
		facility.sensors.map(sensorId => sensorIdsMap[sensorId]?.sensorAlias || sensorId) || [],
);
