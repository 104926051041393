import { AnyAction, createReducer } from '@reduxjs/toolkit';
import { AssetRiskAndCondition, AssetRiskStoreState } from '@Types/asset.types';
import {
	getAssetRisk,
	getAssetRiskRejected,
	getAssetRiskResolved,
	getAssetRiskWaiting,
	getRiskSummary,
	getRiskSummaryRejected,
	getRiskSummaryResolved,
	setRiskSummaryIsLoading,
} from '@Actions/assetRisk.actions';

const initAssetRisk: AssetRiskAndCondition = {
	ASSET_ID: '',
	CURRENT_STRUCTURAL: 0,
	CURRENT_SERVICE: 0,
	COF_CONFIG: [],
	LOF_CONFIG: [],
	RISK_CONFIG: [],
	RISK_PERCENTILE: 0,
	WEIGHTED_SCORES: {},
};

export const initState: AssetRiskStoreState = {
	assetRisk: initAssetRisk,
	riskSummary: [],
	isReady: false,
	isRejected: false,
	isWaiting: false,
	riskSummaryIsLoading: false,
};

interface AssetRiskReducer {
	[x: string]: (
		state: AssetRiskStoreState,
		action: AnyAction,
	) => AssetRiskStoreState | undefined;
}

const reducer: AssetRiskReducer = {
	[getAssetRisk.toString()]: state => ({
		...state,
		assetRisk: initAssetRisk,
		isReady: false,
		isRejected: false,
		isWaiting: true,
	}),
	[getAssetRiskResolved.toString()]: (state, action) => ({
		...state,
		initialized: true,
		assetRisk: action.payload,
		isReady: true,
		isRejected: false,
		isWaiting: false,
	}),
	[getAssetRiskWaiting.toString()]: (state, action) => ({
		...state,
		isWaiting: action.payload,
	}),
	[getAssetRiskRejected.toString()]: state => ({
		...state,
		initialized: true,
		isReady: true,
		isRejected: true,
		isWaiting: false,
	}),

	[setRiskSummaryIsLoading.toString()]: (state, action) => ({
		...state,
		riskSummaryIsLoading: action.payload,
		riskSummary: action.payload === true ? [] : state.riskSummary,
	}),
	[getRiskSummary.toString()]: state => ({
		...state,
		riskSummary: [],
		riskSummaryIsLoading: true,
	}),
	[getRiskSummaryResolved.toString()]: (state, action) => ({
		...state,
		riskSummary: action.payload,
		riskSummaryIsLoading: false,
	}),
	[getRiskSummaryRejected.toString()]: state => ({
		...state,
		riskSummaryIsLoading: false,
	}),
};

export default createReducer(initState, reducer);
