import {
  generatePieStockSeries,
  generateColorsPieSeries,
} from '../../../utils/pieChart.utils';
import { PieChartType } from '../../../types';
import { SeriesOptionsType } from 'highcharts';
import { AtomicPieChart } from '../../atoms';
import * as Styled from './PieChart.styles';
import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import { GlobalPassthroughs } from '../../../types/global.types';

export type PieChartProps = GlobalPassthroughs & PieChartType;

const PieChart = (
  props: PieChartProps,
  chartControllerRef: React.Ref<unknown> | undefined
): JSX.Element | null => {
  const {
    primarySeries,
    reading,
    offset,
    dataRangeSelection,
    className,
    cy,
    dataCy,
  } = props;
  const [stockSeries, setStockSeries] = useState<SeriesOptionsType[]>();

  useEffect(() => {
    const runGenerateStockSeries = async () => {
      const stockSeries = await generatePieStockSeries(
        primarySeries,
        reading,
        offset,
        dataRangeSelection
      );
      setStockSeries([...stockSeries]);
    };
    runGenerateStockSeries();
  }, [primarySeries, reading, offset, dataRangeSelection]);

  const options = useMemo(() => {
    return {
      plotOptions: {
        pie: {
          colors: generateColorsPieSeries(primarySeries),
        },
      },
    };
  }, [primarySeries]);

  return stockSeries ? (
    <Styled.PieChartWrapper>
      <Styled.ChartContainer>
        <AtomicPieChart
          ref={chartControllerRef}
          className={className}
          cy={cy}
          dataCy={dataCy}
          series={stockSeries}
          hiddenSeries={props.hiddenSeries}
          onHiddenSeriesChange={props.onHiddenSeriesChange}
          options={options}
        />
      </Styled.ChartContainer>
    </Styled.PieChartWrapper>
  ) : null;
};

export default forwardRef(PieChart);
