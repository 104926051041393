import { FacilityResolved, SensorIdMap } from '@Types/facility.types';
import { addNamespace } from '@Utils/actions';
import { createAction } from 'redux-actions';

export const getFacility = createAction<string>(
	addNamespace('facility/getFacility'),
);
export const getFacilityResolved = createAction<FacilityResolved>(
	addNamespace('facility/getFacility/resolved'),
);
export const clearFacilityList = createAction(
	addNamespace('facility/facilityList/clear'),
);
export const setSensorIdsMap = createAction<SensorIdMap[]>(
	addNamespace('facility/setSensorIdsMap/resolved'),
);
export const clearSensorIdsMap = createAction(
	addNamespace('facility/clearSensorIdsMap/resolved'),
);
