import Highcharts from 'highcharts';

import type { StackOptions } from './StackedChart.types';

export const enforcedRangeSelectorOptions: Highcharts.Options = {
  legend: {
    enabled: false,
  },
  navigator: {
    enabled: false,
  },
  plotOptions: {
    series: {
      marker: {
        enabled: false,
      },
      states: {
        hover: {
          enabled: false,
        },
        inactive: {
          opacity: 1,
        },
      },
    },
  },
  title: {
    text: '',
  },
  tooltip: {
    enabled: false,
  },
  xAxis: {
    height: 0,
    visible: false,
  },
  yAxis: [
    {
      height: 0,
      visible: false,
    },
  ],
};

export const enforcedNavigatorOptions: Highcharts.Options = {
  legend: { enabled: true },
  plotOptions: {
    series: {
      marker: {
        enabled: false,
      },
      states: {
        hover: {
          enabled: false,
        },
      },
    },
  },
  rangeSelector: {
    enabled: false,
  },
  title: {
    text: '',
  },
  tooltip: {
    followPointer: false,
  },
  xAxis: {
    height: 0,
    visible: false,
  },
  yAxis: [
    {
      height: 0,
      visible: false,
    },
  ],
};

export const enforcedStackedSeriesOptions: Highcharts.Options = {
  chart: {
    spacing: [0, 0, 0, 0],
    resetZoomButton: {
      theme: {
        style: {
          display: 'none',
        },
      },
    },
  },
  legend: {
    enabled: false,
  },
  navigator: {
    enabled: false,
    height: 0,
  },
  rangeSelector: {
    enabled: false,
  },
  title: {
    text: '',
  },
};

export const findStackOptions = (
  seriesIndex: number,
  seriesOptions: Highcharts.SeriesOptionsType,
  stackOptions?: StackOptions[]
): Highcharts.Options => {
  return (
    stackOptions?.find((options) => {
      if (options.seriesId !== undefined) {
        return seriesOptions.id === options.seriesId;
      }
      return seriesIndex === options.seriesIndex;
    })?.options ?? {}
  );
};
