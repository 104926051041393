import { palette } from '@innovyze/stylovyze';
import styled from 'styled-components';

const StyledChart = {
  Container: styled.div`
    background-color: white;
    box-sizing: border-box;
    height: 100%;
    padding: 1rem;
    width: 100%;

    .highcharts-data-label text {
      font-weight: normal !important;
    }

    .highcharts-range-selector-buttons text {
      color: ${palette.secondary.dark} !important;
      fill: ${palette.secondary.dark} !important;
    }

    .highcharts-input-group text,
    .highcharts-axis-label text {
      color: ${palette.secondary.main} !important;
      fill: ${palette.secondary.main} !important;
    }
  `,
};

export default StyledChart;
