import React, { useEffect, useState } from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import {
	UnitSystem,
	defaultUnitSystem,
	getConvertedValue,
	getStandardValue,
	getSystemUnit,
} from './units';

import { rem } from '@Components/styles';
import styled from 'styled-components';
import { toDecimalPlaces } from '@innovyze/shared-utils/lib/utils/units';

type UnitInputProps = Omit<TextFieldProps, 'onChange'> & {
	value: number;
	unit?: string;
	system?: UnitSystem;
	onChange?: (value: number) => void;
};

const DisplayUnit = styled.div`
	font-size: ${rem(12)};
	opacity: 0.7;
`;

const GreaterThan = styled.div`
	font-size: ${rem(12)};
	opacity: 0.7;
	position: relative;
	left: ${rem(-5)};
`;

export const UnitInput = ({
	value,
	unit,
	system = defaultUnitSystem,
	onChange,
	...otherProps
}: UnitInputProps) => {
	const [internalValue, setInternalValue] = useState(
		getConvertedValue(value, unit, system),
	);
	const internalUnit = getSystemUnit(unit, system);

	const onTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const eventValue = event.target.value;
		const standardValue = getStandardValue(
			eventValue,
			internalUnit,
			unit,
			system,
		);
		setInternalValue(eventValue);
		if (!eventValue) {
			return;
		}
		onChange?.(+standardValue);
	};

	useEffect(() => {
		// check if value coming in matches internal value
		const convertedValue = getConvertedValue(value, unit, system);
		const convertedToDecimals = toDecimalPlaces(
			convertedValue,
			`${internalValue}`.split('.')[1]?.length ?? 0,
		);
		if (internalValue === convertedToDecimals) return;
		setInternalValue(convertedValue);
	}, [value]);

	return (
		<TextField
			value={internalValue}
			size="small"
			type="number"
			InputProps={{
				startAdornment: <GreaterThan>&gt;</GreaterThan>,
				endAdornment: internalUnit && (
					<DisplayUnit data-cy="unit">{internalUnit}</DisplayUnit>
				),
				style: {
					paddingRight: internalUnit && 10,
				},
			}}
			sx={{
				'& .MuiInputBase-input': {
					overflow: 'hidden',
					textOverflow: 'ellipsis',
				},
			}}
			onChange={onTextFieldChange}
			{...otherProps}
		/>
	);
};
