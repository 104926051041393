import { GridFilterModel, GridSortModel } from '@mui/x-data-grid';

import { Sort } from '@Utils/types';

export const convertSortModel = (sort: Sort): GridSortModel => {
	if (sort.sort) {
		return [
			{
				field: sort.sort,
				sort: sort.sortDescending ? 'desc' : 'asc',
			},
		];
	}
	return [];
};

export const removeInvalidEntries = (
	filterModel: GridFilterModel,
): GridFilterModel => {
	const items = filterModel?.items?.filter(
		item =>
			item.value ||
			((item.operator == 'isEmpty' || item.operator == 'isNotEmpty') &&
				!item.value),
	);
	return {
		...filterModel,
		items,
	};
};
