import { put, retry, takeLatest } from '@redux-saga/core/effects';
import { getRiskConfigurationsApi } from '@Services';
import { ResolvedResponse } from '@Types';
import { AxiosResponse, AxiosError } from 'axios';
import { t } from 'i18next';
import {
	changeRiskConfigsPage,
	changeRiskConfigsSlice,
	changeRiskConfigsSort,
	clearRiskConfigs,
	getRiskConfigs,
	getRiskConfigsRejected,
	getRiskConfigsResolved,
	reloadRiskConfigs,
	setRiskConfigsIsBusy,
} from '@Actions/riskConfigs.actions';
import { select } from 'redux-saga/effects';
import { selectorRiskConfigsPaginationQuery } from '@Selectors/riskConfigs.selectors';

function* getRiskConfigsSaga() {
	try {
		yield put(setRiskConfigsIsBusy(true));

		const query: string = yield select(selectorRiskConfigsPaginationQuery);

		const payload: AxiosResponse<ResolvedResponse> = yield retry(
			5,
			30000,
			getRiskConfigurationsApi,
			query,
		);

		yield put(
			getRiskConfigsResolved({
				pagination: payload.data.pagination,
				data: payload.data.data,
			}),
		);
		yield put(setRiskConfigsIsBusy(false));
	} catch (e) {
		const err = e as AxiosError;
		const rejection = {
			code: err?.response?.status ?? -1,
			text:
				err?.response?.statusText ??
				t('No additional information') ??
				'',
		};

		yield put(getRiskConfigsRejected(rejection));
		yield put(setRiskConfigsIsBusy(false));
	}
}

function* watchGetRiskConfigs() {
	yield takeLatest(
		[getRiskConfigs, clearRiskConfigs, reloadRiskConfigs],
		getRiskConfigsSaga,
	);
}

function* watchChangePagination() {
	yield takeLatest(
		[changeRiskConfigsPage, changeRiskConfigsSlice, changeRiskConfigsSort],
		getRiskConfigsSaga,
	);
}

export default [watchGetRiskConfigs(), watchChangePagination()];
