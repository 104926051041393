import {
	Property,
	PropertyCard,
	Workspace,
	addChartToWorkspace,
	getWorkspaces,
	selectWorkspaces,
} from '@innovyze/stylovyze';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/* eslint-disable no-console */
import AddToWorkspaceDialog from '@Components/AddToWorkspaceDialog/AddToWorkspaceDialog';
import AddToWorkspaceIcon from '@Components/PropertyPane/icons/AddToWorkspace';
import { FeatureItem } from '@Utils/types';
import HistoryMenuIcon from '@Components/PropertyPane/icons/Historical';
import { SensorHistoryDialog } from '@Components/SensorHistoryDialog';
import { SensorPropertiesWrapper } from './SensorProperties.styles';
import _ from 'lodash';
import { useGlobalization } from '@Translations/useGlobalization';

export interface SensorPropertiesProps {
	item: FeatureItem;
	addChartToCurrentWorkspace?: (sensorId: string) => void;
	currentWorkspaceId?: string;
}

export const SensorProperties = ({
	item,
	addChartToCurrentWorkspace,
	currentWorkspaceId,
}: SensorPropertiesProps): JSX.Element => {
	const { t } = useGlobalization();
	const properties: Property[] = [
		{
			title: t('Sensor ID', { context: 'Sensor properties' }),
			value: `${item.sensorId}`,
			ellipsis: true,
			cy: 'sensorId',
		},
		{
			title: t('Sensor Type', { context: 'Sensor properties' }),
			value: item.sensorType,
			ellipsis: true,
			cy: 'sensorType',
			loading: item.sensorType == null,
		},
	];

	// correct casing coming through from data
	const title = _.capitalize(`${item.alias || item.sensorId}`);

	const [historyModalOpen, setHistoryModalOpen] = useState<boolean>(false);
	const [workspaceModalOpen, setWorkspaceModalOpen] = useState<boolean>(
		false,
	);
	const closeHistoryModal = () => setHistoryModalOpen(false);
	const closeWorkspaceModal = () => setWorkspaceModalOpen(false);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getWorkspaces({}));
	}, []);
	const workspaces = useSelector(selectWorkspaces);
	const currentWorkspace = useMemo(
		() => workspaces?.find(({ id }) => id === currentWorkspaceId),
		[workspaces, currentWorkspaceId],
	);

	const addToWorkspace = (workspace: Workspace) => {
		const sensorId = item.id.split('#')[1];
		closeWorkspaceModal();
		if (currentWorkspaceId === workspace.id && addChartToCurrentWorkspace) {
			addChartToCurrentWorkspace(sensorId);
		} else {
			dispatch(
				addChartToWorkspace({
					workspaceId: workspace.id,
					sensorId,
				}),
			);
		}
	};

	// SEE BUG#71818 - Can't access sensor configuration page directly via URL
	// Needs to be fixed before this can work
	// const history = useHistory();
	// const goToSensorDetails = (sensorId: string) => {
	// 	history.push(`/admin/sensors/${sensorId}/edit`);
	// };

	return (
		<SensorPropertiesWrapper>
			<PropertyCard
				title={title}
				properties={properties}
				marginBottom
				menuItems={[
					{
						text: t('Historical Chart', {
							context: 'Sensor properties menu item',
						}),
						icon: <HistoryMenuIcon />,
						onClick: () => setHistoryModalOpen(true),
					},
					{
						text: t('Add chart to Workspace', {
							context: 'Sensor properties menu item',
						}),
						icon: <AddToWorkspaceIcon />,
						onClick: () => setWorkspaceModalOpen(true),
					},
					// SEE BUG#71818 - Can't access sensor configuration page directly via URL
					// Needs to be fixed before this can work
					// {
					// 	text: 'View Sensor Detail',
					// 	icon: <SensorMenuIcon />,

					// 	onClick: () =>
					// 		goToSensorDetails(item.sensorId || item.alias),
					// },
				]}
			/>
			<SensorHistoryDialog
				title={title}
				id={`${item.sensorId}`}
				alias={`${item.alias}`}
				modalOpen={historyModalOpen}
				closeModal={closeHistoryModal}
				setWorkspaceModalOpen={setWorkspaceModalOpen}
			/>
			<AddToWorkspaceDialog
				open={workspaceModalOpen}
				workspaces={workspaces}
				currentWorkspace={currentWorkspace}
				onCancel={closeWorkspaceModal}
				onConfirm={addToWorkspace}
			/>
		</SensorPropertiesWrapper>
	);
};
