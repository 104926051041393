import { AnyAction, createReducer } from '@reduxjs/toolkit';
import {
	AssetRiskHistory,
	AssetRiskHistoryStoreState,
} from '@Types/asset.types';
import {
	getAssetRiskHistory,
	getAssetRiskHistoryRejected,
	getAssetRiskHistoryResolved,
} from '@Actions/assetRisk.actions';

const initAssetRiskHistory: AssetRiskHistory[] = [];

export const initState: AssetRiskHistoryStoreState = {
	assetRiskHistory: initAssetRiskHistory,
	isReady: false,
};

interface AssetRiskHistoryReducer {
	[x: string]: (
		state: AssetRiskHistoryStoreState,
		action: AnyAction,
	) => AssetRiskHistoryStoreState | undefined;
}

const reducer: AssetRiskHistoryReducer = {
	[getAssetRiskHistory.toString()]: state => ({
		...state,
		assetRiskHistory: initAssetRiskHistory,
		isReady: false,
	}),
	[getAssetRiskHistoryResolved.toString()]: (state, action) => ({
		...state,
		initialized: true,
		assetRiskHistory: action.payload,
		isReady: true,
	}),
	[getAssetRiskHistoryRejected.toString()]: state => ({
		...state,
		initialized: true,
		isReady: false,
	}),
};

export default createReducer(initState, reducer);
