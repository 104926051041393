import { stringifyDataSource } from './use-data-sources';
import * as React from 'react';
import { produce } from 'immer';

import type { Draft } from 'immer';
import type { DataSource } from './use-data-sources';

type EntryKey = string;
type Status = 'idle' | 'loading' | 'resolved' | 'rejected';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Utils
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const initializeDataEntries = <D, S extends DataSource>(dataSources: S[]) => {
  const data = new Map<EntryKey, D | null>();
  dataSources.forEach((dataSource) =>
    data.set(stringifyDataSource(dataSource), null)
  );

  return data;
};

const initializeStatusEntries = <S extends DataSource>(dataSources: S[]) => {
  const statusEntries = new Map<
    EntryKey,
    { status: Status; error: Error | undefined }
  >();
  dataSources.forEach((dataSource) =>
    statusEntries.set(stringifyDataSource(dataSource), {
      status: 'idle',
      error: undefined,
    })
  );

  return statusEntries;
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Data Retriever Hook
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/ban-types
const useDataRetriever = <D, S extends DataSource = DataSource<{}>>(
  dataSources: S[]
) => {
  const [dataEntries, setDataEntries] = React.useState(
    initializeDataEntries<D, S>(dataSources)
  );
  const [statusEntries, setStatusEntries] = React.useState(
    initializeStatusEntries<S>(dataSources)
  );

  const globalStatus: { status: Status; error: Error | undefined } =
    React.useMemo(() => {
      const _statusEntries = Array.from(statusEntries.values());

      if (_statusEntries.some(({ status }) => status === 'loading')) {
        return { status: 'loading', error: undefined };
      }

      if (
        _statusEntries.every(({ status }) =>
          ['resolved', 'rejected'].includes(status)
        )
      ) {
        return { status: 'resolved', error: undefined };
      }

      if (_statusEntries.every(({ status }) => status === 'rejected')) {
        return {
          status: 'rejected',
          error: new Error(),
        };
      }

      return { status: 'idle', error: undefined };
    }, [
      Array.from(statusEntries.values())
        .map(({ status }) => status)
        .join(','),
    ]);

  const setLoadingState = React.useCallback(
    (entryKey: EntryKey) => {
      setStatusEntries(
        produce((_statusEntries) => {
          _statusEntries.set(entryKey, {
            status: 'loading',
            error: undefined,
          });
        })
      );
    },
    [setStatusEntries]
  );

  // React < 18 doesn't batch dispatchers when called from async functions.
  // So it'll trigger 2 renders instead of 1. Make sure to call setStatusEntries
  // AFTER setDataEntries to avoid any UI bugs between renders.
  const setResolvedState = React.useCallback(
    (entryKey: EntryKey, result: D) => {
      setDataEntries(
        produce((_dataEntries) => {
          _dataEntries.set(entryKey, result as Draft<D>);
        })
      );

      setStatusEntries(
        produce((_statusEntries) => {
          _statusEntries.set(entryKey, {
            status: 'resolved',
            error: undefined,
          });
        })
      );
    },
    [setDataEntries, setStatusEntries]
  );

  // React < 18 doesn't batch dispatchers when called from async functions.
  // So it'll trigger 2 renders instead of 1. Make sure to call setStatusEntries
  // AFTER setDataEntries to avoid any UI bugs between renders.
  const setRejectedState = React.useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (dataSource: S, error: any) => {
      const entryKey: EntryKey = stringifyDataSource(dataSource);

      setDataEntries(
        produce((_dataEntries) => {
          _dataEntries.set(entryKey, null);
        })
      );

      setStatusEntries(
        produce((_statusEntries) => {
          _statusEntries.set(entryKey, {
            status: 'rejected',
            error,
          });
        })
      );
    },
    [setDataEntries, setStatusEntries]
  );

  const retrieve = React.useCallback(
    (dataSource: S, retriever: () => Promise<D>) => {
      const entryKey: EntryKey = stringifyDataSource(dataSource);

      setLoadingState(entryKey);

      retriever()
        .then((result) => setResolvedState(entryKey, result))
        .catch((error) => setRejectedState(dataSource, error));
    },
    [setLoadingState, setResolvedState, setRejectedState]
  );

  return { dataEntries, statusEntries, globalStatus, retrieve };
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export { useDataRetriever };
