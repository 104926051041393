import {
  generateMassBalanceOptions,
  generateMassBalanceStackOptions,
  getMassBalanceStats,
} from './MassBalanceChart.utils';
import { AnimatePresence, motion } from 'framer-motion';
import { isFeatureEnabled } from '@innovyze/stylovyze';
import * as Styled from './MassBalanceChart.styles';
import Highcharts from 'highcharts/highstock';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import StackableChart from '../../../core/components/StackableChart';
import useHiddenSeries from '../../../core/hooks/useHiddenSeries';
import useHighchartsOptions from '../../../core/hooks/useHighchartsOptions';
import useRangeSelection from '../../../core/hooks/useRangeSelection';
import useStackOptions from '../../../core/hooks/useStackOptions';

import type {
  HiddenElements,
  HiddenSeries,
  RangeSelection,
} from '../../../types/chartState';
import type {
  MassBalanceDisplayOptions,
  MassBalanceSeries,
} from '../../../core/types/massBalance.types';
import type { ChartProps } from '../../../core/types/chart.types';
import type { StackableChartRef } from '../../../core/components/StackableChart';
import { useGlobalization } from '../../../i18n/useGlobalization';

export interface MassBalanceChartProps extends ChartProps<MassBalanceSeries> {
  dataRangeSelection?: RangeSelection;
  displayOptions?: MassBalanceDisplayOptions;
  hiddenElements?: HiddenElements;
  hiddenSeries?: HiddenSeries;
  onHiddenElementsChange?: (hiddenElements: HiddenElements) => void;
  onHiddenSeriesChange?: (hiddenSeries: HiddenSeries) => void;
  onRangeSelectionChange?: (rangeSelection: RangeSelection) => void;
  rangeSelection?: RangeSelection;
}

const MassBalanceChart = (
  props: MassBalanceChartProps,
  ref?: React.Ref<StackableChartRef>
): JSX.Element => {
  const { t } = useGlobalization();
  const isBoostModuleEnabled = isFeatureEnabled(
    'info-360-analytics-boost-module'
  );

  const shouldAnimateRef = useRef(true);

  const [isSnapshotVisible, setIsSnapshotVisible] = useState<boolean>(() => {
    return props.hiddenElements
      ? !props.hiddenElements.includes('snapshot')
      : true;
  });

  const { rangeSelection, rangeSelectionOptions } = useRangeSelection(
    props.rangeSelection,
    props.dataRangeSelection,
    props.onRangeSelectionChange
  );

  const { hiddenSeries, hiddenSeriesOptions } = useHiddenSeries(
    props.hiddenSeries,
    props.onHiddenSeriesChange
  );

  const options = useHighchartsOptions(() => {
    return [
      generateMassBalanceOptions(
        props.data,
        !!isBoostModuleEnabled,
        props.series,
        hiddenSeries,
        t,
        props.displayOptions
      ),
      hiddenSeriesOptions,
      rangeSelectionOptions,
    ];
  }, [
    hiddenSeries,
    hiddenSeriesOptions,
    isBoostModuleEnabled,
    props.data,
    props.displayOptions,
    props.series,
    rangeSelectionOptions,
  ]);

  const stackOptions = useStackOptions(() => {
    return {
      enabled: !(props.displayOptions?.overlay ?? false),
      options: generateMassBalanceStackOptions(
        props.data,
        props.series,
        t,
        props.displayOptions
      ),
    };
  }, [props.data, props.series, props.displayOptions]);

  useEffect(() => {
    if (isSnapshotVisible === false) {
      shouldAnimateRef.current = false;
    }
  }, [isSnapshotVisible]);

  return (
    <Styled.MassBalanceChartWrapper>
      <Styled.SnapshotContainer>
        <Styled.SnapshotToggle
          onClick={() => {
            const nextIsVisible = !isSnapshotVisible;
            let nextHiddenElements = props.hiddenElements
              ? [...props.hiddenElements]
              : [];

            if (!nextIsVisible) {
              nextHiddenElements.push('snapshot');
            } else {
              nextHiddenElements = nextHiddenElements.filter(
                (element) => element !== 'snapshot'
              );
            }

            props.onHiddenElementsChange?.(nextHiddenElements);
            setIsSnapshotVisible(nextIsVisible);
          }}>
          <div className="lctb-label">{t('Snapshot')}</div>
          <div className="lctb-icon">
            {isSnapshotVisible ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </div>
        </Styled.SnapshotToggle>
        <AnimatePresence>
          {isSnapshotVisible && (
            <motion.div
              key="snapshot"
              initial={shouldAnimateRef.current ? false : { height: 0 }}
              exit={{ height: 0 }}
              animate={{ height: 'auto' }}
              transition={{ ease: 'easeInOut' }}
              onAnimationComplete={() => {
                window.dispatchEvent(new Event('resize'));
              }}>
              <Styled.StatsContainer>
                {getMassBalanceStats(
                  props.series,
                  t,
                  props.data,
                  rangeSelection
                ).map(({ key, label, value, hasNoData }) => {
                  return (
                    <Styled.StatsItem key={key} hasNoData={hasNoData}>
                      <Styled.StatsLabel>{label}</Styled.StatsLabel>
                      <Styled.StatsValue>{value}</Styled.StatsValue>
                    </Styled.StatsItem>
                  );
                })}
              </Styled.StatsContainer>
            </motion.div>
          )}
        </AnimatePresence>
      </Styled.SnapshotContainer>
      <Styled.ChartContainer>
        <StackableChart
          constructorType="stockChart"
          data={props.data}
          highcharts={Highcharts}
          options={options}
          ref={ref}
          stack={stackOptions}
        />
      </Styled.ChartContainer>
    </Styled.MassBalanceChartWrapper>
  );
};

export default forwardRef(MassBalanceChart);
