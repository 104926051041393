/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, {
  forwardRef,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
  useMemo,
  useLayoutEffect,
} from 'react';
import * as Highcharts from 'highcharts';
import { v4 as uuidv4 } from 'uuid';

import { GlobalPassthroughs, HiddenSeries } from '../../../types';

import * as Styled from './AtomicPieChart.styles';
import {
  defaultPieChartConfig,
  exportDataToCsv,
  exportDataToXls,
  getHiddenSeries,
  isSeriesHidden,
  useTimeZone,
} from '../../../utils';

Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
  },
});

export interface AtomicPieChartProps extends GlobalPassthroughs {
  options?: Highcharts.Options;
  loading?: boolean;
  series: Array<Highcharts.SeriesOptionsType>;
  hiddenSeries?: HiddenSeries;
  onHiddenSeriesChange?: (hiddenSeries: HiddenSeries) => void;
}

/**
 * To override options on a particular chart when overlay prop is false:
 *
 * Set the id property on the series object(s) that you want to override.
 * Then use the stackOptions prop and use the same id(s) as key, and set the override options for that particular chart as value. This will only run when the overlay prop is false.
 */
const AtomicPieChart = (
  props: AtomicPieChartProps,
  chartControllerRef: React.Ref<unknown> | undefined
): JSX.Element => {
  useTimeZone(Highcharts);

  const {
    className,
    cy,
    dataCy,
    options,
    series,
    loading,
    hiddenSeries,
    onHiddenSeriesChange,
  } = props;

  const chart = useRef<Highcharts.Chart | undefined>(undefined);
  const container = useRef<HTMLDivElement | null>(null);
  const id = useRef<string>(uuidv4());

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const legendOptions = useMemo<Highcharts.Options>(() => {
    return {
      legend:
        dimensions.width > dimensions.height
          ? {
              align: 'right',
              verticalAlign: 'middle',
              layout: 'vertical',
            }
          : {
              align: 'center',
              verticalAlign: 'bottom',
              layout: 'horizontal',
            },
    };
  }, [dimensions]);

  const seriesOptions = useMemo<Highcharts.Options>(() => {
    return {
      series: series.map((serie, index) => {
        const isHidden = isSeriesHidden(serie, index, props.hiddenSeries);

        return {
          ...serie,
          visible: !isHidden,
        };
      }),
    };
  }, [series, hiddenSeries]);

  const eventsOptions = useMemo<Highcharts.Options>(() => {
    return {
      plotOptions: {
        series: {
          events: {
            show: function (this: Highcharts.Series) {
              onHiddenSeriesChange?.(getHiddenSeries(this.chart.series));
            },
            hide: function (this: Highcharts.Series) {
              onHiddenSeriesChange?.(getHiddenSeries(this.chart.series));
            },
          },
        },
      },
    };
  }, [onHiddenSeriesChange]);

  useEffect(() => {
    chart.current = Highcharts.chart(
      id.current,
      Highcharts.merge(
        defaultPieChartConfig,
        options,
        legendOptions,
        seriesOptions,
        eventsOptions
      )
    );

    return () => {
      chart.current?.destroy();
      chart.current = undefined;
    };
  }, []);

  useLayoutEffect(() => {
    chart.current?.update(legendOptions);
  }, [legendOptions]);

  useLayoutEffect(() => {
    chart.current?.update(seriesOptions);
  }, [seriesOptions]);

  useLayoutEffect(() => {
    chart.current?.update(eventsOptions);
  }, [eventsOptions]);

  useEffect(() => {
    if (loading) chart.current?.showLoading();
    else chart.current?.hideLoading();
  }, [chart, loading]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(
      (entries: ResizeObserverEntry[]) => {
        window.requestAnimationFrame((): void | undefined => {
          if (!Array.isArray(entries) || !entries.length) {
            return;
          }
          entries.forEach((entry) => {
            setDimensions({
              width: entry.contentRect.width,
              height: entry.contentRect.height,
            });
          });
        });
      }
    );

    if (container.current) {
      resizeObserver.observe(container.current);
    }

    return () => {
      if (container.current) {
        resizeObserver.unobserve(container.current);
      }
    };
  }, []);

  useImperativeHandle(chartControllerRef, () => ({
    exportToCsv: () => {
      exportDataToCsv(chart.current);
    },
    exportToXls: () => {
      exportDataToXls(chart.current);
    },
  }));

  return (
    <Styled.ChartContainer ref={container}>
      <Styled.ChartWrapper
        id={id.current}
        className={className}
        data-cy={cy || dataCy}
      />
    </Styled.ChartContainer>
  );
};

export default forwardRef(AtomicPieChart);
