(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("highcharts"), require("uuid"), require("styled-components"), require("@innovyze/stylovyze"), require("react-i18next"), require("luxon"), require("i18next"), require("@mui/icons-material/KeyboardArrowUp"), require("@mui/icons-material/KeyboardArrowDown"), require("framer-motion"), require("@mui/material"), require("color"), require("rainbowvis.js"), require("immer"), require("axios"), require("browser-cookies"), require("axios-retry"), require("@mui/icons-material/"), require("lodash"), require("nanoid"), require("moment-timezone"), require("@mui/x-date-pickers-pro"), require("@mui/x-date-pickers-pro/AdapterLuxon"), require("@mui/material/styles"), require("@mui/icons-material"), require("@reduxjs/toolkit"), require("react-redux"), require("mathjs"), require("redux"), require("redux-saga/effects"), require("@redux-saga/core/effects"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "highcharts", "uuid", "styled-components", "@innovyze/stylovyze", "react-i18next", "luxon", "i18next", "@mui/icons-material/KeyboardArrowUp", "@mui/icons-material/KeyboardArrowDown", "framer-motion", "@mui/material", "color", "rainbowvis.js", "immer", "axios", "browser-cookies", "axios-retry", "@mui/icons-material/", "lodash", "nanoid", "moment-timezone", "@mui/x-date-pickers-pro", "@mui/x-date-pickers-pro/AdapterLuxon", "@mui/material/styles", "@mui/icons-material", "@reduxjs/toolkit", "react-redux", "mathjs", "redux", "redux-saga/effects", "@redux-saga/core/effects"], factory);
	else if(typeof exports === 'object')
		exports["summaryze"] = factory(require("react"), require("highcharts"), require("uuid"), require("styled-components"), require("@innovyze/stylovyze"), require("react-i18next"), require("luxon"), require("i18next"), require("@mui/icons-material/KeyboardArrowUp"), require("@mui/icons-material/KeyboardArrowDown"), require("framer-motion"), require("@mui/material"), require("color"), require("rainbowvis.js"), require("immer"), require("axios"), require("browser-cookies"), require("axios-retry"), require("@mui/icons-material/"), require("lodash"), require("nanoid"), require("moment-timezone"), require("@mui/x-date-pickers-pro"), require("@mui/x-date-pickers-pro/AdapterLuxon"), require("@mui/material/styles"), require("@mui/icons-material"), require("@reduxjs/toolkit"), require("react-redux"), require("mathjs"), require("redux"), require("redux-saga/effects"), require("@redux-saga/core/effects"));
	else
		root["summaryze"] = factory(root["react"], root["highcharts"], root["uuid"], root["styled-components"], root["@innovyze/stylovyze"], root["react-i18next"], root["luxon"], root["i18next"], root["@mui/icons-material/KeyboardArrowUp"], root["@mui/icons-material/KeyboardArrowDown"], root["framer-motion"], root["@mui/material"], root["color"], root["rainbowvis.js"], root["immer"], root["axios"], root["browser-cookies"], root["axios-retry"], root["@mui/icons-material/"], root["lodash"], root["nanoid"], root["moment-timezone"], root["@mui/x-date-pickers-pro"], root["@mui/x-date-pickers-pro/AdapterLuxon"], root["@mui/material/styles"], root["@mui/icons-material"], root["@reduxjs/toolkit"], root["react-redux"], root["mathjs"], root["redux"], root["redux-saga/effects"], root["@redux-saga/core/effects"]);
})(this, (__WEBPACK_EXTERNAL_MODULE__155__, __WEBPACK_EXTERNAL_MODULE__713__, __WEBPACK_EXTERNAL_MODULE__315__, __WEBPACK_EXTERNAL_MODULE__694__, __WEBPACK_EXTERNAL_MODULE__836__, __WEBPACK_EXTERNAL_MODULE__953__, __WEBPACK_EXTERNAL_MODULE__796__, __WEBPACK_EXTERNAL_MODULE__575__, __WEBPACK_EXTERNAL_MODULE__386__, __WEBPACK_EXTERNAL_MODULE__503__, __WEBPACK_EXTERNAL_MODULE__286__, __WEBPACK_EXTERNAL_MODULE__823__, __WEBPACK_EXTERNAL_MODULE__75__, __WEBPACK_EXTERNAL_MODULE__133__, __WEBPACK_EXTERNAL_MODULE__470__, __WEBPACK_EXTERNAL_MODULE__742__, __WEBPACK_EXTERNAL_MODULE__326__, __WEBPACK_EXTERNAL_MODULE__633__, __WEBPACK_EXTERNAL_MODULE__351__, __WEBPACK_EXTERNAL_MODULE__773__, __WEBPACK_EXTERNAL_MODULE__199__, __WEBPACK_EXTERNAL_MODULE__528__, __WEBPACK_EXTERNAL_MODULE__321__, __WEBPACK_EXTERNAL_MODULE__979__, __WEBPACK_EXTERNAL_MODULE__586__, __WEBPACK_EXTERNAL_MODULE__410__, __WEBPACK_EXTERNAL_MODULE__96__, __WEBPACK_EXTERNAL_MODULE__148__, __WEBPACK_EXTERNAL_MODULE__961__, __WEBPACK_EXTERNAL_MODULE__954__, __WEBPACK_EXTERNAL_MODULE__883__, __WEBPACK_EXTERNAL_MODULE__756__) => {
return 