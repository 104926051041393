import { api } from './sensor-data';
import * as React from 'react';
import type { DateTime, DurationUnit } from 'luxon';
import type { Resolution, Reading } from './series';
import type { Status } from '../_chart';
import { Resolution as ResolutionValues } from '../../../core/types/resolution.types';

const readings: Reading[] = ['Open', 'High', 'Low', 'Close', 'Average', 'Sum'];

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Use Data Sources
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const useSources = <Series extends Source>(series: Series[]): Source[] => {
  const sourceKeys = series.map(makeSourceKey);
  return React.useMemo(() => {
    if (sourceKeys.join() === '') {
      return [];
    } else {
      return series.map((s) => ({
        sensorId: s.sensorId,
        resolution: s.resolution,
      }));
    }
  }, [sourceKeys.join()]);
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Use Smallest Data Sources Resolution
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const useSmallestResolution = <Series extends Source>(series: Series[]): Resolution => {
  const sourceKeys = series.map(makeSourceKey);
  return React.useMemo(() :Resolution => {
    if(series.length > 0){
      let resolution = series[0].resolution;
      for(const s of series){
        resolution = assignSmallestResolution(resolution as ResolutionValues , s.resolution as ResolutionValues);
      }
      return resolution as Resolution;
    }else{
      return 'RAW';
    }
  }, [sourceKeys.join()]);
};


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Use Data Hook
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useData = (sources: Source[], config?: { summary?: boolean }) => {
  const [status, setStatus] = React.useState<Status>('idle');
  const [error, setError] = React.useState<Error>();
  const [data, setData] = React.useState<{
    start?: number;
    end?: number;
    entries: Map<string, { data: Data; unit?: string }>;
    defaultLimit?: number;
  }>();

  const retrieve = React.useCallback(
    async (options?: {
      pagination?: Pagination;
      timeSelection?: TimeSelection;
    }) => {
      if (sources.length === 0) return;

      setStatus('loading');
      try {
        const results = await fetchData({
          sources,
          summary: config?.summary,
          ...options,
        });
        const entries: Map<string, { data: Data; unit?: string }> = new Map();

        for (const entryKey in results.data.entries) {
          const entry = results.data.entries[entryKey];
          const data: Data = [];

          for (const [timestamp, ...measures] of entry.data) {
            const measuresMap: Map<Reading, Measure> = new Map();

            for (let i = 0; i < readings.length; i++) {
              measuresMap.set(readings[i], measures[i]);
            }

            data.push([timestamp, measuresMap]);
          }

          entries.set(entryKey, { data, unit: entry.unit });
        }

        setData({
          start: results.data.start,
          end: results.data.end,
          entries,
          defaultLimit: results.data.defaultLimit,
        });
        setError(undefined);
        setStatus('resolved');
      } catch (error) {
        setError(new Error());
        setStatus('rejected');
      }
    },
    [sources, config?.summary]
  );

  const getEntry = React.useCallback(
    (sensorId: string, resolution: Resolution) => {
      return data?.entries.get(`${sensorId},${resolution}`);
    },
    [data?.entries]
  );

  return {
    retrieve,
    getEntry,
    status,
    error,
    ...data,
  };
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * API
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const fetchData = (options: {
  sources: Source[];
  summary?: boolean;
  pagination?: Pagination;
  timeSelection?: TimeSelection;
}) => {
  return api.post<{
    start?: number;
    end?: number;
    entries: Record<
      string,
      {
        unit?: string;
        data: [
          timestamp: number,
          open: Measure,
          high: Measure,
          low: Measure,
          close: Measure,
          average: Measure,
          sum: Measure,
        ][];
      }
    >;
    defaultLimit?: number;
  }>('/v2/data/search', options);
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Utils
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const makeSourceKey = (source: Source) =>
  `${source.sensorId}::${source.resolution}`;

const assignSmallestResolution = (resolutionA: ResolutionValues , resolutionB: ResolutionValues) : ResolutionValues =>{
  const resolutionIndex = {
    [ResolutionValues['15 Minutes']]: 0,
    [ResolutionValues['30 Minutes']]: 1,
    [ResolutionValues.Daily]: 2,
    [ResolutionValues.Hourly]: 3,
    [ResolutionValues.Raw]: 4,
    [ResolutionValues.Weekly]: 5,
  };
  const resolutionAIndex = resolutionIndex[resolutionA] ?? 0;
  const resolutionBIndex = resolutionIndex[resolutionB] ?? 0;
  if(resolutionAIndex <= resolutionBIndex)
    return resolutionA;
  else
    return resolutionB;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Types
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
type Measure = number | null;
type Data = [timestamp: number, measures: Map<Reading, Measure>][];

type DateLike = number | string | DateTime;
type Pagination = { limit?: number; limitFrom?: 'start' | 'end' };
type Source = { sensorId: string; resolution: Resolution | 'full' };
type TimeSelection =
  | {
      type: 'range';
      start?: DateLike;
      end?: DateLike;
      duration?: never;
      unit?: never;
    }
  | {
      type: 'offset';
      duration: number;
      unit?: DurationUnit;
      start?: never;
      end?: never;
    };

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Exports
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export { useData, useSources, useSmallestResolution };
export type { Data, Measure };
