import {
  ComparisonDisplayOptions,
  ComparisonReferenceSeries,
  ComparisonSeries,
} from '../../../core/types/comparison.types';
import { SensorDataRecords } from '../../../core/types/data.types';
import {
  DEFAULT_CHART_COLORS,
  GroupByValue,
  HiddenSeries,
} from '../../../types';
import Highcharts, {
  YAxisPlotLinesOptions,
  SeriesOptionsType,
} from 'highcharts';
import { readingIndex } from '../../../core/utils/data.utils';
import { isSeriesVisible } from '../../../core/utils/hiddenSeries.utils';
import { DateTime } from 'luxon';

interface optionSeriesProps {
  categories: string[];
  units: string[];
  seriesOptions: SeriesOptionsType[];
}

const generateOptionsSeries = (
  comparisonSeries: ComparisonSeries[],
  sensorDataRecords: SensorDataRecords,
  hiddenSeries: HiddenSeries,
  displayOptions?: ComparisonDisplayOptions,
  groupBy?: GroupByValue
): optionSeriesProps => {
  const categories: string[] = [];
  const units: string[] = [];
  const seriesOptions: SeriesOptionsType[] = [];

  comparisonSeries.forEach((series, index) => {
    const { sensorId, resolution } = series.dataSource;
    const dataRecord = sensorDataRecords?.[sensorId]?.[resolution];

    const timestamps = dataRecord?.data?.timestamps ?? [];
    const measurements = dataRecord?.data?.measurements ?? {};

    let data = [];

    const unit = dataRecord?.data?.unit ?? null;
    if (unit && !units.includes(unit)) units.push(unit);

    if (!groupBy || groupBy !== 'Month') {
      //regular data for comparison series
      data = Array(comparisonSeries.length).fill('');
      const lastElement = timestamps.length
        ? timestamps[timestamps.length - 1]
        : null;

      data[index] = lastElement
        ? measurements[lastElement][readingIndex[series.func]]
        : 0;

      categories.push(series.alias);
    } else {
      //If we need to group by month
      timestamps.slice(-12).forEach((timestamp) => {
        data.push(measurements[timestamp][readingIndex[series.func]]);
        const category = DateTime.fromMillis(timestamp).toFormat('LLL-yy');
        categories.push(category);
      });
    }

    seriesOptions.push({
      name: series.alias,
      type: displayOptions?.horizontalBar ? 'bar' : 'column',
      color: series.displayOptions?.color
        ? series.displayOptions.color
        : DEFAULT_CHART_COLORS[index % DEFAULT_CHART_COLORS.length],
      pointWidth: groupBy || groupBy === 'None' ? 10 : 50,
      visible: isSeriesVisible(hiddenSeries, index, series.id, series.alias),
      data,
    });
  });

  return {
    categories,
    units,
    seriesOptions,
  };
};

export const generateComparisonOptions = (
  sensorDataRecords: SensorDataRecords,
  isBoostModuleEnabled: boolean,
  comparisonSeries: ComparisonSeries[],
  hiddenSeries: HiddenSeries,
  referenceSeries?: ComparisonReferenceSeries[],
  displayOptions?: ComparisonDisplayOptions,
  groupBy?: GroupByValue
): Highcharts.Options => {
  const { categories, units, seriesOptions } = generateOptionsSeries(
    comparisonSeries,
    sensorDataRecords,
    hiddenSeries,
    displayOptions,
    groupBy
  );

  const plotLines: YAxisPlotLinesOptions[] = [];

  referenceSeries?.forEach((series, index) => {
    const data = comparisonSeries.map((s) => +series.value);
    const color = series.color
      ? series.color
      : DEFAULT_CHART_COLORS[index % DEFAULT_CHART_COLORS.length];
    const name = `Static Value(${series.value})`;

    seriesOptions.push({
      name,
      type: 'scatter',
      color: color,
      marker: {
        enabled: false,
      },
      events: {
        show: function () {
          this.chart.yAxis[0].addPlotLine({
            value: +series.value,
            color: color,
            width: 2,
            id: `plot-line-${index}`,
            dashStyle: series.style,
          });
        },
        hide: function () {
          this.chart.yAxis[0].removePlotLine(`plot-line-${index}`);
        },
      },
      visible: isSeriesVisible(hiddenSeries, index, series.id, name),
      data,
    });

    plotLines.push({
      value: +series.value,
      color: color,
      width: 2,
      id: `plot-line-${index}`,
      dashStyle: series.style,
    });
  });

  const boostOptions: Highcharts.BoostOptions = {
    enabled: isBoostModuleEnabled,
    useGPUTranslations: isBoostModuleEnabled,
  };

  const xAxisOptions: Highcharts.XAxisOptions = {
    gridLineWidth: displayOptions?.showXGrid ? 1 : 0,
    type: 'category',
    categories: categories,
    title: {
      text: '',
    },
  };

  const yAxisOptions: Highcharts.YAxisOptions[] = [
    {
      title: {
        text: units.join(', '),
      },
      opposite: false,
      plotLines: plotLines,
      gridLineWidth: displayOptions?.showYGrid ? 1 : 0,
    },
  ];

  return {
    title: {
      text: '',
    },
    navigator: {
      enabled: false,
    },
    rangeSelector: {
      enabled: false,
    },
    legend: {
      enabled: true,
    },
    boost: boostOptions,
    plotOptions: {
      column: {
        states: {
          inactive: {
            opacity: 1,
          },
        },
      },
      bar: {
        states: {
          inactive: {
            opacity: 1,
          },
        },
      },
    },
    yAxis: yAxisOptions,
    xAxis: xAxisOptions,
    series: seriesOptions,
  };
};
