import { createChart } from '../../core/_summaryze-chart';
import { instanceInitialOptions } from './sensor-chart-options';
import { useGlobalization } from '../../../i18n/useGlobalization';
import * as React from 'react';
import Highstock from 'highcharts/highstock';

import type {
  SensorChartEventHandlers,
  SensorChartOptions,
  SensorChartSeriesOptions,
} from './sensor-chart-types';
import type * as Highcharts from 'highcharts';
import type { ChartInstanceRef } from '../../core/_summaryze-chart';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Chart
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const Chart = createChart('SensorChart');

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Root Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

type SensorChartRootProps = SensorChartOptions &
  SensorChartEventHandlers & {
    children: React.ReactNode;
  };

const SensorChartRoot = React.forwardRef<
  ChartInstanceRef,
  SensorChartRootProps
>((props, ref): React.ReactElement => {
  return (
    <Chart.ChartRoot>
      <Chart.ChartInstance
        ref={ref}
        initialOptions={instanceInitialOptions}
        constructorFunction={Highstock.stockChart}>
        {props.children}
      </Chart.ChartInstance>
    </Chart.ChartRoot>
  );
});

SensorChartRoot.displayName = 'SensorChartRoot';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Series Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

type SensorChartSeriesProps = SensorChartSeriesOptions;

const SensorChartSeries = (props: SensorChartSeriesProps): null => {
  const { t } = useGlobalization();
  const instance = Chart.useInstance('SensorChartSensorSeries');
  const series = Chart.useSeries(props, 'SensorChartSensorSeries');

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets series name
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    series.current?.update({
      name: props.name,
    } as Highcharts.SeriesOptionsType);
  }, [series, props.name]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets series data
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    series.current?.update({
      data: props.data,
    } as Highcharts.SeriesOptionsType);
  }, [series, props.data, props.status, props.unit]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets Y axis label
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    instance.current?.update({ yAxis: { title: { text: props.unit } } });
  }, [instance, props.unit]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets status
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    if (props.status === 'loading') {
      instance.current?.showLoading();
    } else if (props.status === 'rejected') {
      instance.current?.showLoading(t('No Data'));
    } else {
      instance.current?.hideLoading();
    }
  }, [instance, props.status, t]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  return null;
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export { SensorChartRoot, SensorChartSeries };

export type { SensorChartRootProps, SensorChartSeriesProps, ChartInstanceRef };
