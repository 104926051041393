/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  GlobalPassthroughs,
  Readings,
  TableDashletItem,
  TableDashletRow,
  TableDensity,
  TableSortState,
} from '../../../types';
import {
  sortByTimestamp,
  sortByNumber,
  sortByString,
} from '../../../utils/sort';
import { StylovyzeTableFooter, StylovyzeTableProps } from '@innovyze/stylovyze';
import { TFunc, useGlobalization } from '../../../i18n';
import { useCompanyDateTimeFormat } from '../../../hooks';
import * as Styled from './AtomicTableDashlet.styles';
import React, { useState, useRef, useMemo, useLayoutEffect } from 'react';

const dateTimeSeparator = ' - ';
const defaultSort: TableSortState = { name: 'date', order: 'desc' };
const defaultReadings = [Readings.Close];

const translateReading = (reading: Readings, t: TFunc) => {
  switch (reading) {
    case Readings.Average:
      return t('Average');
    case Readings.Close:
      return t('Close');
    case Readings.High:
      return t('High');
    case Readings.Low:
      return t('Low');
    case Readings.Open:
      return t('Open');
    case Readings.Sum:
      return t('Sum');
    default:
      return reading;
  }
};

export interface AtomicTableDashletProps extends GlobalPassthroughs {
  hasMultipleSensors: boolean;
  readings?: Readings[];
  loading?: boolean;
  items: TableDashletItem[];
  page?: number;
  onPageChange?: (page: number) => void;
  sort?: TableSortState;
  onSortChange?: (sort?: TableSortState) => void;
  showUnits?: boolean;
  showDateTime?: boolean;
  density?: TableDensity;
}

// eslint-disable-next-line @typescript-eslint/ban-types
type OnPaginationChangeFunc = (event: object, page: number) => void;

const AtomicTableDashlet = (props: AtomicTableDashletProps): JSX.Element => {
  const { readings = defaultReadings, density } = props;

  const ref = useRef<HTMLDivElement>(null);

  const [height, setHeight] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(0);

  const [page, setPage] = useState(() => props.page ?? 1);
  const [sort, setSort] = useState(props.sort ?? defaultSort);

  const { t } = useGlobalization();
  const formatDateTime = useCompanyDateTimeFormat({ dateTimeSeparator });

  const headers = useMemo(() => {
    const headers: StylovyzeTableProps['headers'] = readings.map((reading) => ({
      key: reading,
      cell: translateReading(reading, t),
      align: 'left',
      width: 'auto',
      sortable: true,
    }));

    if (props.hasMultipleSensors) {
      headers.unshift({
        key: 'sensorName',
        cell: t('Sensor Name'),
        align: 'left',
        width: 'auto',
        sortable: true,
      });
    }

    if (props.showDateTime) {
      headers.unshift({
        key: 'date',
        cell: t('Date/Time'),
        align: 'left',
        width: 'auto',
        sortable: true,
      });
    }

    if (props.showUnits) {
      headers.push({
        key: 'unit',
        cell: t('Unit'),
        align: 'left',
        width: 'auto',
        sortable: true,
      });
    }

    return headers;
  }, [props.hasMultipleSensors, props.showUnits, props.showDateTime, readings]);

  const sortOptions = useMemo(
    () => ({
      ...sort,
      onRequestSort: (
        _: React.MouseEvent<unknown, MouseEvent>,
        name: string | number,
        order: 'desc' | 'asc'
      ) => {
        setSort({ name: `${name}`, order });
        props.onSortChange?.({ name: `${name}`, order });
      },
    }),
    [sort.order, sort.name, props.onSortChange]
  );

  const paginationOptions = useMemo(
    () => ({
      totalNumberOfPages:
        itemsPerPage === 0 ? 0 : Math.ceil(props.items.length / itemsPerPage),
      onChange: (_: unknown, page: number) => {
        setPage(page);
        props.onPageChange?.(page);
      },
    }),
    [props.items.length, itemsPerPage, props.onPageChange]
  );

  const sortedAndPaginatedItems = useMemo(() => {
    if (itemsPerPage === 0) return [];

    const startIndex = (page - 1) * itemsPerPage;
    const calculatedEndIndex = startIndex + itemsPerPage;
    const endIndex =
      calculatedEndIndex < props.items.length ? calculatedEndIndex : undefined;

    return props.items
      .sort((a, b) => {
        const key = sort.name;

        if (key === 'date') {
          return sortByTimestamp(a.timestamp, b.timestamp, sort.order);
        } else if (key === 'sensorName') {
          return sortByString(a.sensorName, b.sensorName, sort.order);
        } else if (key === 'unit') {
          return sortByString(a.unit, b.unit, sort.order);
        } else if (readings.includes(key as never)) {
          const reading = readings.find((r) => key === r)!;
          return sortByNumber(a[reading]!, b[reading]!, sort.order);
        }

        return 0;
      })
      .slice(startIndex, endIndex);
  }, [props.items, sort.name, sort.order, readings, page, itemsPerPage]);

  const tableDashletRows = useMemo(() => {
    const rows: TableDashletRow[] = [];

    for (let i = 0; i < sortedAndPaginatedItems.length; i++) {
      const item = sortedAndPaginatedItems[i];
      const cells: (string | number | undefined)[] = readings.map(
        (reading) => `${item[reading]}`
      );

      if (props.hasMultipleSensors) {
        cells.unshift(item.sensorName);
      }

      if (props.showDateTime) {
        cells.unshift(formatDateTime(item.timestamp));
      }

      if (props.showUnits) {
        cells.push(item.unit);
      }

      rows.push({
        key: `${i}:${item.sensorName}:${item.timestamp}:${item.Close}`,
        cells,
      });
    }

    return rows;
  }, [
    props.hasMultipleSensors,
    props.showUnits,
    props.showDateTime,
    readings,
    sortedAndPaginatedItems,
  ]);

  useLayoutEffect(() => {
    const rowHeight: number = density || TableDensity.regular;
    const _itemsPerPage = Math.ceil((height - rowHeight) / rowHeight);
    setItemsPerPage(_itemsPerPage >= 0 ? _itemsPerPage : 0);
  }, [height, props.density]);

  useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver(
      (entries: ResizeObserverEntry[]) => {
        window.requestAnimationFrame((): void | undefined => {
          if (!Array.isArray(entries) || !entries.length) {
            return;
          }
          entries.forEach((entry) => {
            setHeight(entry.contentRect.height);
          });
        });
      }
    );

    if (ref.current) {
      resizeObserver.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        resizeObserver.disconnect();
      }
    };
  }, []);

  return (
    <Styled.TableWrapper>
      <Styled.TableContainer ref={ref}>
        <Styled.DashletTable
          rowHeight={density || TableDensity.regular}
          initialized
          headers={headers}
          sort={sortOptions}
          rows={tableDashletRows}
          dataCy={props.dataCy || ''}
        />
      </Styled.TableContainer>
      <StylovyzeTableFooter pagination={paginationOptions} />
    </Styled.TableWrapper>
  );
};

export default AtomicTableDashlet;
