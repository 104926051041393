import CancellableFetch from './CancellableFetch';
import { DataServiceRequestError } from './DataServiceRequestError';
import { TraceResults } from './types';
import { extractErrorContext } from '@Map/utils';
import logger from '@Map/logger/Logger';

export default class TraceService {
	private _baseUrl: string;
	private _headers: [string, string][] | undefined;
	private _request: CancellableFetch | undefined;
	private _traceResults: TraceResults = { links: [], nodes: [] };

	constructor(url: string, token?: string) {
		this._baseUrl = url;
		this._headers = token
			? [['Authorization', `Bearer ${token}`]]
			: undefined;
	}

	async getTrace(id: string, dsTrace?: boolean): Promise<TraceResults> {
		const [assetType, assetId] = id.split('#');
		const baseUrl = this._baseUrl;
		const url = new URL(
			baseUrl
				.replace(/{{assetId}}/g, assetId)
				.replace(/{{assetType}}/g, assetType),
		);
		const queryParams = new URLSearchParams(url.search);
		if (dsTrace) {
			queryParams.append('dsTrace', `${dsTrace}`);
		}
		url.search = queryParams.toString();

		this.cancelRequest();
		this._request = new CancellableFetch({
			url: url.toString(),
			headers: this._headers,
		});
		this._traceResults = { links: [], nodes: [] };
		try {
			this._traceResults = await this._request.getPage<TraceResults>();
			//
		} catch (e) {
			this.errorCatch(
				e as DataServiceRequestError,
				'map_exception_trace',
				'Failed to get trace',
			);
		}
		return this._traceResults;
	}

	get linkIds(): string[] {
		return this._traceResults.links.map(link => link._id);
	}

	cancelRequest(): void {
		this._request?.cancel();
		this._request = undefined;
	}

	destroy(): void {
		this.cancelRequest();
	}

	private errorCatch(
		e: DataServiceRequestError,
		messageId: string,
		errorMessage: string,
	) {
		const context = {
			messageId,
			...extractErrorContext(e),
		};
		if (e.name !== 'AbortError') {
			logger.error(errorMessage, context);
		} else {
			logger.info('Data services request aborted', context);
		}
	}
}
