import { createReducer } from '@reduxjs/toolkit';

import {
  getPatternMeasureData,
  getPatternMeasureDataResolved,
  getPatternMeasureDataRejected,
} from '../actions/patternMeasureData.actions';

import { RawPatternMeasureData } from '../types/patternChart.types';

export interface PatternMeasureDataReducerChart {
  initialized: boolean;
  loading: boolean;
  data?: RawPatternMeasureData[];
  unit?: string;
}

export type PatternMeasureDataReducer = {
  [chartId: string]: PatternMeasureDataReducerChart;
};

export interface PatternMeasureDataStoreState {
  patternMeasureData: PatternMeasureDataReducer;
}

export const patternMeasureDataInitialState: PatternMeasureDataReducer = {};

export const patternMeasureDataReducer =
  createReducer<PatternMeasureDataReducer>(
    patternMeasureDataInitialState,
    (builder) => {
      builder
        .addCase(getPatternMeasureData, (state, { payload: { id } }) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          state[id] = state[id] || {};
          state[id].loading = true;
          state[id].initialized = false;
          state[id].data = undefined;
        })
        .addCase(
          getPatternMeasureDataResolved,
          (state, { payload: { id, data, unit } }) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            state[id] = state[id] || {};
            state[id].loading = false;
            state[id].initialized = true;
            state[id].data = data;
            state[id].unit = unit;
          }
        )
        .addCase(
          getPatternMeasureDataRejected,
          (state, { payload: { id } }) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            state[id] = state[id] || {};
            state[id].loading = false;
            state[id].initialized = true;
            state[id].data = undefined;
          }
        );
    }
  );
