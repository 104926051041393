export enum Resolution {
  'Raw' = 'RAW',
  '15 Minutes' = '15-MINUTE',
  '30 Minutes' = '30-MINUTE',
  'Hourly' = 'HOURLY',
  'Daily' = 'DAILY',
  'Weekly' = 'WEEKLY',
}

export type ResolutionType = keyof typeof Resolution;
