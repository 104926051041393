import {
  useIsFeatureEnabled,
  useSelectSensors,
  useSettings,
} from '@innovyze/stylovyze';
import * as ComparisonChart from '../../modules/comparison-chart';
import * as InsightChart from '../../core/_insight-chart';
import * as React from 'react';
import * as SeriesData from '../../core/series-data';
import * as TimeSeriesData from '../../core/time-series-data';
import * as TimeSeriesDataOld from '../../core/time-series-data-old';
import * as Utils from './insight-comparison-chart.utils';
import { fixEdgeResponseResults } from '../insight-historical-chart/insight-historical-chart.utils';
import { fixCollectionInterval } from '../../core/time-series-data/utils';

type EdgeSource = {
  sensorId: string;
  resolution: TimeSeriesDataOld.Resolution;
  reading: TimeSeriesDataOld.Reading;
};

function edgeSourceStringifier(source: EdgeSource): string {
  return `${source.sensorId}:${source.resolution}:${source.reading}`;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 *	Comparison Chart Preset Root Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export type RootProps = Omit<
  ComparisonChart.RootProps,
  'navigator' | 'series' | 'status'
> & {
  series: (Omit<InsightComparisonChartSeriesProps, 'index' | 'edge'> & {
    customData?: InsightChart.SensorDataEntry;
  })[];
  timeRangeSelection?: InsightChart.TimeRangeSelection;
};

function limit(value: string | null | undefined, defaultValue = 500): number {
  if (typeof value === 'string' || value === null) return defaultValue;
  if (isNaN(Number(value))) return defaultValue;
  return Number(value);
}

export const Root = React.forwardRef<
  { chart: Highcharts.Chart | undefined },
  RootProps
>((props, ref): React.ReactElement => {
  const { companySettings } = useSettings();
  const { sensors, initialized: sensorsInitialized } = useSelectSensors();
  const edge = useIsFeatureEnabled('info-360-edge-analytics-charts-comparison');
  const dataLimit = useIsFeatureEnabled('info-360-analytics-hp2-charts-limit');
  const useV3 = useIsFeatureEnabled('info-360-edge-analytics-parquet-files');

  const seriesData = TimeSeriesDataOld.useRetriever(
    props.series
      .filter((series) => series.type !== 'line')
      .filter((series) => !series.customData)
      .map(({ sensorId, resolution }) => ({
        sensorId,
        resolution: resolution,
      }))
  );

  const [edgeSources] = SeriesData.useSources<EdgeSource>(() => {
    const series = props.series
      ?.filter((series) => series.type !== 'line')
      ?.filter((s) => !s.customData && s.sensorId);
    if (!series?.length) return [];
    return series.map(({ sensorId, resolution, reading }) => ({
      sensorId,
      resolution,
      reading,
    }));
  }, [props.series]);

  const [edgeData, edgeStatus, retrieveEdgeData] = SeriesData.useRetriever<
    TimeSeriesData.ResponseBody,
    { timeSelection?: TimeSeriesData.PartialTimeSelection }
  >(
    async (signal, params) => {
      if (!edgeSources?.length || !sensorsInitialized) return;

      const timeSelection = {
        from: params.timeSelection?.from ?? 'oldest',
        to: params.timeSelection?.to ?? 'latest',
      } as TimeSeriesData.TimeSelection;

      const response = await TimeSeriesData.retrieve(signal, {
        ...params,
        order: 'asc',
        timeSelection,
        limit: limit(dataLimit),
        timeZone: companySettings.timeZoneIANA,
        data_version: useV3 ? 'v3.0' : 'v2',
        snapping: 'oldest',
        sources: edgeSources.map((s) => {
          const _sensor = sensors.find((_s) => _s.sensorId === s.sensorId);
          const seconds = fixCollectionInterval(_sensor?.collectionInterval);

          return {
            key: edgeSourceStringifier(s),
            sensorId: s.sensorId,
            collectionInterval: { seconds },
            analytic: TimeSeriesData.makeAnalytic(
              s.resolution ?? '15-MINUTE',
              s.reading ?? 'Close'
            ),
          };
        }),
      });

      const result = { ...response.data };
      result.results = fixEdgeResponseResults(edgeSources, result.results);

      return result;
    },
    [
      companySettings.timeZoneIANA,
      dataLimit,
      edgeSources,
      sensors,
      sensorsInitialized,
      useV3,
    ]
  );

  // Handles data fetching with node's API
  const { retrieve } = seriesData;
  React.useEffect(() => {
    if (edge) return;
    retrieve({
      limit: { from: 'end' },
      timeSelection: {
        start: props.timeRangeSelection?.min,
        end: props.timeRangeSelection?.max,
      },
    });
  }, [
    retrieve,
    edge,
    props.timeRangeSelection?.min,
    props.timeRangeSelection?.max,
  ]);

  // Handles data fetching with edge analytics' API
  React.useEffect(() => {
    if (!edge) return;
    if (!sensorsInitialized) return;
    retrieveEdgeData({
      timeSelection: {
        from: props.timeRangeSelection?.min,
        to: props.timeRangeSelection?.max,
      },
    });
  }, [
    edge,
    sensorsInitialized,
    props.timeRangeSelection?.max,
    props.timeRangeSelection?.min,
    retrieveEdgeData,
  ]);

  return (
    <ComparisonChart.Root
      {...props}
      ref={ref}
      elements={{
        series: props.series.map((series, index) => {
          const key = edgeSourceStringifier(series);
          const edgeDataEntry = edgeData?.results?.[key];

          return (
            <Series
              {...series}
              key={index}
              index={index}
              customData={series.customData}
              seriesData={seriesData}
              totalSeries={props.series.length}
              edge={!!edge}
              edgeDataEntry={edgeDataEntry}
              selectedTheme={props.selectedTheme}
            />
          );
        }),
      }}
      events={props.events}
      status={edge ? edgeStatus : seriesData.status}
      error={seriesData.error}
      title={props.title}
      xAxis={{
        enableGridlines: props.xAxis?.enableGridlines,
      }}
      yAxis={{
        enableGridlines: props.yAxis?.enableGridlines,
      }}
      categories={props.series.map((series) => series.name ?? '')}
    />
  );
});

Root.displayName = 'ComparisonChartRoot';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Series Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

type InsightComparisonChartSeriesProps = InsightComparisonChartSeries & {
  index: number;
  customData?: InsightChart.SensorDataEntry;
  seriesData?: ReturnType<typeof TimeSeriesDataOld.useRetriever>;
  edge?: boolean;
  edgeDataEntry?: TimeSeriesData.ResponseResultEntry;
};

type InsightComparisonChartSeries = InsightChart.InsightChartSeries<
  ComparisonChart.SeriesProps,
  // Comparison doesn't allow reading customization
  {
    sensorId: string;
    resolution: InsightChart.Resolution;
    reading: InsightChart.Reading;
    customData?: InsightChart.SensorDataEntry;
  }
>;

const Series = (
  props: InsightComparisonChartSeriesProps & { totalSeries: number }
): React.ReactElement => {
  const name = props.name || `${props.sensorId}`;

  const { getRawSensorDataMap } = props.seriesData ?? {};
  const data = React.useMemo(() => {
    let _data: TimeSeriesDataOld.TimeSeriesData | undefined = undefined;
    const reading = props.reading ?? 'Close';

    if (props.customData) {
      const d = props.customData.data?.size
        ? Array.from(props.customData.data.entries())
        : undefined;
      _data = InsightChart.processDataWithReading(d, reading);
    } else if (!props.edge) {
      const s = { sensorId: props.sensorId, resolution: props.resolution };
      const m = getRawSensorDataMap?.(s);
      const d = m?.size ? Array.from(m.entries()) : undefined;
      _data = InsightChart.processDataWithReading(d, reading);
    } else {
      _data = props.edgeDataEntry?.data as TimeSeriesDataOld.TimeSeriesData;
    }

    if (!_data?.length) return [];

    return Utils.makeSeriesData(
      props.type,
      _data,
      props.index,
      props.totalSeries,
      props.referece
    );
  }, [
    props.reading,
    props.customData,
    props.edge,
    props.type,
    props.index,
    props.totalSeries,
    props.referece,
    props.sensorId,
    props.resolution,
    props.edgeDataEntry,
    getRawSensorDataMap,
  ]);

  return <ComparisonChart.Series {...props} data={data} name={name} />;
};
