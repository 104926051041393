import { SvgIcon } from '@mui/material';
import React from 'react';

const AddToWorkspaceIcon = () => {
	return (
		<SvgIcon>
			<path
				d="M7.00001 10.6667V8.16666H5.33334V10.6667H2.83334V12.3333H5.33334V14.8333H7.00001V12.3333H9.50001V10.6667H7.00001ZM8.66668 0.666656H2.00001C1.55798 0.666656 1.13406 0.842251 0.821499 1.15481C0.508938 1.46737 0.333344 1.8913 0.333344 2.33332V15.6667C0.333344 16.1087 0.508938 16.5326 0.821499 16.8452C1.13406 17.1577 1.55798 17.3333 2.00001 17.3333H12C12.442 17.3333 12.866 17.1577 13.1785 16.8452C13.4911 16.5326 13.6667 16.1087 13.6667 15.6667V5.66666L8.66668 0.666656ZM12 15.6667H2.00001V2.33332H7.83334V6.49999H12"
				fill="#768D95"
			/>
		</SvgIcon>
	);
};
export default AddToWorkspaceIcon;
