import { Root } from './chart.components';
import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/explicit-module-boundary-types
export const withRoot = <P extends {}>(
  WrappedComponent: React.ComponentType<P>
) => {
  const Hoc = React.forwardRef<{ chart: Highcharts.Chart | undefined }, P>(
    (props, ref) => {
      return (
        <Root>
          <WrappedComponent {...props} ref={ref} />
        </Root>
      );
    }
  );

  Hoc.displayName = `Wrapped${
    WrappedComponent.displayName || WrappedComponent.name || 'Component'
  }`;

  return Hoc;
};
