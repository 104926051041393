import { DateTime } from 'luxon';
import { DateTimeRangePicker as MuiDateTimeRangePicker } from '@mui/x-date-pickers-pro';
import { defaultDateTimeRangePickerSlotOptions } from './slots';
import { useCompanyDateTime } from '../../../../hooks/useCompanyDateTime';
import * as React from 'react';
import type { DateTimeRangePickerProps as MuiDateTimeRangePickerProps } from '@mui/x-date-pickers-pro';

type OmittedDateTimePickerProps = Pick<
  MuiDateTimeRangePickerProps<DateTime>,
  'ampm' | 'timezone'
>;

export type DateTimeRangePickerProps = Omit<
  MuiDateTimeRangePickerProps<DateTime>,
  keyof OmittedDateTimePickerProps
> & {
  /**
   * If set, overrides the date format stored on redux.
   */
  dateFormat?: string | undefined;
  /**
   * If set, overrides the AM/PM flag stored on redux.
   */
  hourCycle12?: boolean | undefined;
  /**
   * If set, overrides the time zone IANA value stored on redux.
   */
  timeZoneIana?: string | undefined;
};

export function DateTimeRangePicker(
  props: DateTimeRangePickerProps
): React.ReactElement {
  const { dateFormat, hourCycle12, timeZoneIana, ...rest } = props;

  const companyDateTime = useCompanyDateTime({
    hourCycle12,
    dateFormat,
    timeZoneIana,
  });

  return (
    <MuiDateTimeRangePicker
      {...defaultDateTimeRangePickerSlotOptions}
      {...rest}
      ampm={companyDateTime.hourCycle12}
      format={`${companyDateTime.dateFormat} ${companyDateTime.timeFormat}`}
      timezone={companyDateTime.timeZoneIana}
    />
  );
}
