import { SeriesXrangeOptions } from 'highcharts';
import { Readings } from '../types/sensor.types';
import { MeasureDataReducerChart } from '../reducers/measureData.reducer';
import { RawMeasureDataMap } from '../types/measureData.types';
import {
  StatusChartSeries,
  StatusChartSeriesWithoutData,
} from '../types/statusChart.types';
import { DEFAULT_CHART_COLORS, RangeSelection } from '../types';
import { filterDataOutOfRange } from './filterDataOutOfRange';
import { DateTime } from 'luxon';

export const generateStatusChartSeries = async (
  seriesWithoutData: StatusChartSeriesWithoutData,
  sensorMap: MeasureDataReducerChart
): Promise<StatusChartSeries> => {
  const sensorId = seriesWithoutData.id;
  const data =
    sensorMap?.[sensorId]?.[seriesWithoutData.resolution]?.data ?? [];

  return {
    ...seriesWithoutData,
    data,
  };
};

export const generateStatusChartXrangeSeries = async (
  statusChartSeries: StatusChartSeries,
  index: number,
  timeRange: number,
  dataRangeSelection?: RangeSelection
): Promise<SeriesXrangeOptions> => {
  const options: SeriesXrangeOptions = {
    dataLabels: { enabled: true },
    name: statusChartSeries.alias || statusChartSeries.id,
    pointWidth: 20,
    type: 'xrange',
    data: [],
  };

  const dataInRange = statusChartSeries.data
    ? filterDataOutOfRange(statusChartSeries.data, dataRangeSelection)
    : [];

  let limit = DateTime.now().endOf('day').toMillis();

  if (dataInRange && dataInRange.length)
    limit = dataInRange[dataInRange.length - 1][0];

  const hoursInMilis = 3600000;
  limit -= timeRange * hoursInMilis;

  let latestRange: number[] | undefined = undefined;
  const ranges =
    dataInRange?.reduce<number[][]>((ranges, readings) => {
      const mutableRanges = [...ranges];
      const timestamp = readings[0];

      if (timestamp < limit) {
        return [...mutableRanges];
      }

      const reading = readings[RawMeasureDataMap[Readings.Close]];

      if (reading === 1) {
        if (latestRange) {
          const modifiedRange = mutableRanges.pop();
          latestRange = modifiedRange
            ? [modifiedRange[0], timestamp]
            : [timestamp, timestamp];
        } else {
          latestRange = [timestamp, timestamp];
        }
        return [...mutableRanges, latestRange];
      } else {
        latestRange = undefined;
        return [...mutableRanges];
      }
    }, []) ?? [];

  const color = DEFAULT_CHART_COLORS[index % DEFAULT_CHART_COLORS.length];

  for (const range of ranges) {
    options.data?.push({
      x: range[0],
      x2: range[1],
      y: index,
      color: color,
    });
  }

  return options;
};
