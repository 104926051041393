import { Button } from '@innovyze/stylovyze';
import { Grid, Typography } from '@mui/material';
import { useGlobalization } from '@Translations';
import React from 'react';
import { useHistory } from 'react-router-dom';
import RehabIcon from './RehabIcon';
// eslint-disable-next-line @typescript-eslint/naming-convention
export const NoDetailsRehab = ({
	adminFullAccess,
}: {
	adminFullAccess: boolean;
}): JSX.Element => {
	const history = useHistory();
	const rehabClicked = () => {
		history.push('/rehab');
	};
	const { t } = useGlobalization();

	return (
		<Grid container style={{ paddingTop: '40px', paddingBottom: '40px' }}>
			<Grid container item xs={12} justifyContent="center">
				<RehabIcon />
			</Grid>
			<Grid container item xs={12} justifyContent="center">
				<Typography variant="h6">
					{t('No rehab details to show')}
				</Typography>
			</Grid>
			{adminFullAccess ? (
				<Grid container item xs={12} justifyContent="center">
					<Grid container item xs={12} justifyContent="center">
						<Typography variant="caption">
							{t('Create a rehab tree to generate details.')}
						</Typography>
					</Grid>
					<Grid
						container
						item
						xs={12}
						justifyContent="center"
						style={{
							paddingTop: '20px',
							paddingBottom: '20px',
						}}>
						<Button
							variant="contained"
							color="primary"
							onClick={rehabClicked}>
							{t('Create Rehab Tree')}
						</Button>
					</Grid>
				</Grid>
			) : (
				[]
			)}
		</Grid>
	);
};
