import { Grid, Typography } from '@mui/material';
import { useGlobalization } from '@Translations';
import React from 'react';
import { WorkHistoryIcon } from './WorkHistoryIcon.component';
// eslint-disable-next-line @typescript-eslint/naming-convention
export const NoDetailsWorkHistory = () => {
	const { t } = useGlobalization();
	return (
		<Grid container style={{ paddingTop: '40px', paddingBottom: '40px' }}>
			<Grid container item xs={12} justifyContent="center">
				<WorkHistoryIcon />
			</Grid>
			<Grid container item xs={12} justifyContent="center">
				<Typography variant="h6">{t('No History to show')}</Typography>
			</Grid>
			<Grid container item xs={12} justifyContent="center">
				<Typography variant="caption">
					{t(
						'Once work orders are completed they will show up here.',
					)}
				</Typography>
			</Grid>
		</Grid>
	);
};
