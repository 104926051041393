import { createAction } from '@reduxjs/toolkit';
import { SensorData } from '..';

export interface GetMeasureDataParams {
  /** List of sensorIds that you intend to fetch */
  sensors: string[];
  /** Resolution of the sensors you're trying to pull */
  resolution: string;
  /** Overrides the resolution prop for specific sensors */
  overrideResolutions?: Record<string, string | undefined>;
  /** Forces refetch of data whenever the chart is rendered or after X amount of milliseconds have passed **/
  alwaysFetchData?: boolean;
}

export interface GetMeasureDataResolved {
  [sensor: string]: Record<string, SensorData>;
}

export interface GetMeasureDataRejected {
  /** Id of the chart being fetched for - can just be uuid */
  id: string;
}

export interface FetchMeasureDataParams {
  alwaysFetchData?: boolean;
  dataSources: {
    sensorId: string;
    resolution: string;
  }[];
}

export const getMeasureData = createAction<GetMeasureDataParams>(
  'summaryze/measureData/get'
);
export const setDebounceSensors = createAction<string[]>(
  'summaryze/measureData/debounce'
);
export const emptyDebounceSensors = createAction(
  'summaryze/measureData/debounce/empty'
);
export const fetchMeasureData = createAction<FetchMeasureDataParams>(
  'summaryze/measureData/fetch'
);
export const fetchMeasureDataResolved = createAction<GetMeasureDataResolved>(
  'summaryze/measureData/fetch/resolved'
);
export const fetchMeasureDataRejected = createAction<GetMeasureDataRejected>(
  'summaryze/measureData/get/rejected'
);
