import type { Reading } from './series';
import type { SensorDataMap } from './sensor-data';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Types
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

type AnalyticFunction =
  | AnalyticFunctionConstant
  | AnalyticFunctionLast
  | AnalyticFunctionSqrt
  | AnalyticFunctionMovingAverage
  | AnalyticFunctionMovingSum;

type AnalyticFunctionType = AnalyticFunction['type'];

type AnalyticFunctionLast = { type: 'Last' | 'last'; params?: undefined };

type AnalyticFunctionSqrt = { type: 'Sqrt' | 'sqrt'; params?: undefined };

type AnalyticFunctionMovingAverage = {
  type: 'Movingaverage' | 'movingaverage';
  params: { number_of_periods: number | string };
};

type AnalyticFunctionMovingSum = {
  type: 'MovingSum' | 'movingsum';
  params: { number_of_periods: number | string };
};

type AnalyticFunctionConstant = {
  type: 'Constant' | 'constant';
  params: { value: number | string };
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Analytic Functions
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

type SensorData<T> =
  T extends Map<infer K, infer V> ? [K, V][] | undefined : never;

type TimeSeriesData = [number, number | null][];

const analyticFunctions = {
  sqrt: (data: SensorData<SensorDataMap>): TimeSeriesData => {
    if (data === undefined || data.length === 0) return [];
    return data.map(([timestamp, measures]) => {
      const value = measures.get('Average') ?? null;
      return [timestamp, value === null ? null : Math.sqrt(value)];
    });
  },
  constant: (
    data: SensorData<SensorDataMap>,
    params: AnalyticFunctionConstant['params']
  ): TimeSeriesData => {
    if (data === undefined || data.length === 0) return [];
    return data.map(([timestamp]) => [timestamp, Number(params.value)]);
  },
  movingaverage: (
    data: SensorData<SensorDataMap>,
    params: AnalyticFunctionMovingAverage['params']
  ): TimeSeriesData => {
    if (data === undefined || data.length === 0) return [];
    const period = Number(params.number_of_periods);
    const processedData: TimeSeriesData = [];
    let sum = 0;

    for (let i = data.length - 1; i >= 0; i--) {
      if (i > period) {
        sum = 0;
        for (let j = 0; j < period; j++) {
          const [_, measures] = data[i - j];
          sum += measures.get('Average') ?? 0;
        }

        const [timestamp] = data[i];
        if (Number.isNaN(period) || period === 0) {
          processedData.unshift([timestamp, null]);
        } else {
          processedData.unshift([timestamp, sum / Number(period)]);
        }
      } else {
        sum = 0;
        for (let j = i; j >= 0; j--) {
          const [_, measures] = data[i - j];
          sum += measures.get('Average') ?? 0;
        }

        const [timestamp] = data[i];
        if (Number.isNaN(period) || i + 1 === 0) {
          processedData.unshift([timestamp, null]);
        } else {
          processedData.unshift([timestamp, sum / (i + 1)]);
        }
      }
    }
    return processedData;
  },
  movingsum: (
    data: SensorData<SensorDataMap>,
    params: AnalyticFunctionMovingSum['params']
  ): TimeSeriesData => {
    if (data === undefined || data.length === 0) return [];
    const period = Number(params.number_of_periods);
    const processedData: TimeSeriesData = [];
    let sum = 0;

    for (let i = data.length - 1; i >= 0; i--) {
      if (i > period) {
        sum = 0;
        for (let j = 0; j < period; j++) {
          const [_, measures] = data[i - j];
          sum += measures.get('Average') ?? 0;
        }

        const [timestamp] = data[i];
        processedData.unshift([timestamp, sum]);
      } else {
        sum = 0;
        for (let j = i; j >= 0; j--) {
          const [_, measures] = data[i - j];
          sum += measures.get('Average') ?? 0;
        }

        const [timestamp] = data[i];
        processedData.unshift([timestamp, sum]);
      }
    }

    return processedData;
  },
  last: (data: SensorData<SensorDataMap>): TimeSeriesData => {
    if (data === undefined || data.length === 0) return [];

    const firstValidIndex = data.findIndex(([_, measures]) =>
      measures.get('Average')
    );

    let last = data[firstValidIndex]?.[1].get('Average');

    return data.map(([timestamp, measures], index) => {
      const averageValue = measures.get('Average');

      if (
        index > firstValidIndex &&
        (averageValue === undefined || averageValue !== 0)
      ) {
        last = averageValue;
        return [timestamp, averageValue ?? null];
      } else {
        return [timestamp, last ?? null];
      }
    });
  },
} as const;

const isAnalyticFunction = (
  analytic?: Reading | AnalyticFunction
): analytic is AnalyticFunction => {
  if (typeof analytic === 'undefined') return false;
  return typeof analytic === 'string' ? false : true;
};

const getAnalyticType = (
  analytic: Reading | AnalyticFunction | undefined,
  defaultValue: Reading | AnalyticFunction['type']
): Reading | AnalyticFunction['type'] => {
  if (typeof analytic === 'undefined') return defaultValue;
  if (isAnalyticFunction(analytic)) return analytic.type;
  return analytic;
};

const processDataWithAnalyticFunction = (
  data: SensorData<SensorDataMap>,
  analytic: AnalyticFunction
): TimeSeriesData => {
  if (data === undefined || data.length === 0) return [];

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return (
    analyticFunctions[analytic.type.toLowerCase()]?.(data, analytic.params) ??
    []
  );
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export { processDataWithAnalyticFunction, isAnalyticFunction, getAnalyticType };

export type {
  AnalyticFunction,
  AnalyticFunctionType,
  AnalyticFunctionLast,
  AnalyticFunctionSqrt,
  AnalyticFunctionMovingAverage,
  AnalyticFunctionMovingSum,
  AnalyticFunctionConstant,
};
