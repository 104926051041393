import BrandDark from 'highcharts/es-modules/Extensions/Themes/BrandDark';
import BrandLight from 'highcharts/es-modules/Extensions/Themes/BrandLight';
import DarkUnica from 'highcharts/es-modules/Extensions/Themes/DarkUnica';
import SandSignika from 'highcharts/es-modules/Extensions/Themes/SandSignika';
import GridLight from 'highcharts/es-modules/Extensions/Themes/GridLight';
import Highcharts from 'highcharts';

export type Theme =
  | 'Default'
  | 'BrandDark'
  | 'BrandLight'
  | 'DarkUnica'
  | 'SandSignika'
  | 'GridLight';

const defaultColors = [
  '#00abd1',
  '#768D95',
  '#E66B45',
  '#EFB302',
  '#83BC3F',
  '#876CE7',
  '#017CA0',
  '#4A6067',
  '#AA0000',
  '#AA5D00',
  '#4B7E03',
  '#4C3DAD',
  '#61DDFF',
  '#A5BDC6',
  '#FF9C72',
  '#FFE54D',
  '#B6EF70',
  '#BAA6FF',
];

const defaultTheme = {
  colors: defaultColors,
  chart: {
    backgroundColor: '#fff',
    borderWidth: 0,
    // plotBackgroundColor: '#fff',
    plotShadow: false,
    plotBorderWidth: 0,
    style: {
      fontFamily: 'Arial, sans-serif',
    },
  },
  title: {
    style: {
      color: '#3E576F',
      fontSize: '16px',
    },
  },
  subtitle: {
    style: {
      color: '#4d759e',
    },
  },
  tooltip: {
    backgroundColor: 'rgba(255,255,255,0.8)',
    style: {
      color: '#333333',
    },
  },
  legend: {
    backgroundColor: '#ffffff',
    borderColor: 'transparent',
    itemStyle: {
      color: '#333333',
      fontSize: '12px',
    },
    itemHoverStyle: {
      color: '#000',
    },
    itemHiddenStyle: {
      color: '#CCC',
    },
  },
  rangeSelector: {
    buttonTheme: {
      fill: '#e6ebf5',
      stroke: '#e6ebf5',
      'stroke-width': 0,

      style: {
        color: '#4a6067',
        fontFamily: 'Arial, sans-serif',
        fontWeight: 700
      },
      states: {
        hover: {
          fill: '#CCCCCC',
          color: '#333333',
        },
      },
    },
    inputBoxBorderColor: 'transparent',
    inputStyle: {
      color: '#274b6d',
      fontFamily: 'Arial, sans-serif',
    },
  },
  xAxis: {
    labels: {
      style: {
        color: '#666',
        cursor: 'default',
        fontSize: '11px',
        lineHeight: '14px',
      },
    },
    title: {
      style: {
        color: '#4d759e',
        fontWeight: 'bold',
      },
    },
  },
  labels: {
    style: {
      color: '#3E576F',
    },
  },
};

const getMaskFillColor = (theme: Theme): string => {
  if (theme === 'BrandDark') return 'rgba(31, 24, 54, 0.5)';
  if (theme === 'DarkUnica') return 'rgba(62, 62, 64, 0.5)';

  return 'rgba(255, 255, 255, 0.7)';
};

export const getTheme = (theme: Theme) => {
  const themes = {
    BrandDark: BrandDark,
    BrandLight: BrandLight,
    DarkUnica: DarkUnica,
    SandSignika: {
      ...SandSignika,
      options: {
        ...SandSignika.options,
        legend: defaultTheme.legend,
        rangeSelector: defaultTheme.rangeSelector,
      },
    },
    GridLight: {
      ...GridLight,
      options: {
        ...GridLight.options,
        legend: defaultTheme.legend,
        rangeSelector: defaultTheme.rangeSelector,
      },
    },
  };
  const newTheme = (themes[theme]?.options ??
    defaultTheme) as Highcharts.Options;

  return {
    colors: newTheme?.colors,
    chart: newTheme?.chart,
    title: newTheme?.title,
    subtitle: newTheme?.subtitle,
    caption: newTheme?.caption,
    legend: newTheme?.legend,
    plotOptions: {
      ...newTheme?.plotOptions,
      series: { ...newTheme?.plotOptions?.series, shadow: false },
    },
    //@ts-ignore
    xAxis: { labels: newTheme?.xAxis?.labels, title: newTheme?.xAxis?.title },
    //@ts-ignore
    labels: newTheme?.labels,
    navigator: {
      maskFill: getMaskFillColor(theme),
      maskInside: false,
      outlineColor: 'transparent',
      yAxis: { gridLineWidth: 0 },
      handles: {
        height: 20,
      },
    },
    rangeSelector: {
      ...newTheme?.rangeSelector,
      inputBoxBorderColor: 'transparent',
    },
  };
};

export const getThemeColor = (theme: Theme, seriesIndex: number) => {
  const _theme = getTheme(theme);
  const colors = _theme.colors;
  const wrappedIndex = (seriesIndex ?? 0) % colors.length;
  return colors[wrappedIndex];
};
