import React, { useEffect } from 'react';
import { KpiDashlet } from '../../molecules';
import { useDispatch } from 'react-redux';
import { getKpiValue } from '../../../actions/kpiValue.actions';
import { selectKpiValue } from '../../../selectors/kpiValue.selectors';
import useCustomKpi from '../../../core/hooks/useCustomKpi';
import { StylovyzeCardProps } from '@innovyze/stylovyze';
import { kpiPalette, Kpis, KpiType } from '../../../types/kpi.types';

export interface ConnectedKpiDashletProps
  extends Omit<StylovyzeCardProps, 'countText'> {
  type?: KpiType;
  customKpi?: {
    baseUrl: string;
    url: string;
    path: string[];
    params?: {
      [param: string]: unknown;
    };
  };
}

const ConnectedKpiDashlet = (props: ConnectedKpiDashletProps): JSX.Element => {
  const dispatch = useDispatch();
  const { customKpi, ...cardProps } = props;
  const _kpi = selectKpiValue(props.type ?? Kpis.HighAlerts);

  useEffect(() => {
    const type = props.type;
    if (type) {
      dispatch(getKpiValue({ type }));
    }
  }, [props.type]);

  let customValue = null;

  if (customKpi) {
    const { baseUrl, url, path, params } = customKpi;
    customValue = useCustomKpi(baseUrl, url, path, params);
  }

  const kpi = customValue ? customValue : _kpi;

  const color = (): string => {
    if (props.topColor) {
      return props.topColor;
    }
    if (props.type) {
      return kpiPalette[props.type];
    }
    return 'gray';
  };

  return (
    <KpiDashlet
      {...cardProps}
      loading={kpi?.loading}
      countText={kpi?.value ?? 0}
      topColor={color()}
    />
  );
};

export default ConnectedKpiDashlet;
