import { LngLat } from 'mapbox-gl';
import { Geometry, Coordinates } from '../services/types';

export interface GeocoderResult {
	id: string;
	type: string;
	place_type?: string[];
	relevance?: number;
	properties?: {
		foursquare: string;
		landmark: boolean;
		address: string;
		category: string;
	};
	text: string;
	'text_en-GB'?: string;
	place_name: string;
	'place_name_en-GB'?: string;
	center: Coordinates;
	geometry: Geometry;
	context?: {
		id: string;
		text: string;
		'text_en-GB'?: string;
		'language_en-GB'?: string;
		wikidata?: string;
		language?: string;
		short_code?: string;
	}[];
	serviceId?: string;
	query?: (string | number)[];
	lngLat?: LngLat;
}

export interface SearchEndpoint {
	url: string;
	token?: string;
	dataServiceId?: string;
}

export interface CarmenGeoJson {
	type: string;
	query?: string[];
	features: GeocoderResult[];
}

export interface Asset {
	_id: string;
	assetType: string;
	assetId: string;
	sensorId?: string;
	geometry: Geometry;
}

export type AssetsSearchResponse = Asset[];

export interface Facility {
	_id: string;
	workspaceId?: string;
	name: string;
	type?: string;
	liftStationData?: {
		location?: {
			latitude: number;
			longitude: number;
		};
	};
}

export type FacilityResponse = {
	liftStations: Facility[];
};

export interface SelectedSearchResult {
	id: string;
	serviceId: string;
}

export enum FlyTo {
	never = 'never',
	always = 'always',
	onSelect = 'onSelect',
	defaultSearch = 'defaultSearch',
}

export type Bbox = [number, number, number, number];
