import React from 'react';
import {
	DateTimeInputMode,
	InputSelect,
	InputText,
	InputUnit,
	MenuItemType,
	InputDateTime,
	FormatDateOptions,
	InputCheckbox,
	InputCurrency,
	getCurrency,
	CompanySettings,
} from '@innovyze/stylovyze';
import { Grid } from '@mui/material';
import { TFunc } from '@Translations/types';
import { FormikValues } from 'formik';
import { UpdateInspectionParams } from '@Types/inspectionAttributes.types';
import {
	dateTimeFromPicker,
	formatDateWithFormatDate,
	formatDateTimeWithFormatDate,
	formatTimeWithFormatDate,
} from '.';
import { InspectionStandard } from '@Types/inspection.types';
import { SAASObject } from '@Types/general.types';

//compares two objects for only the items passed in the list, returns an object with fields which differ
export const getChangedFormikFields = (
	origList: { [key: string]: string },
	values: FormikValues,
): { [key: string]: string | SAASObject } => {
	const changes: { [key: string]: string } = {};
	const keys = Object.keys(values);
	keys.forEach(item => {
		if (origList[item] !== values[item]) {
			changes[item] = values[item];
		}
	});
	return changes;
};

export const getDomainItems = (
	t: TFunc,
	values: {
		[key: string]: string;
	},
): MenuItemType[] => {
	const keys = Object.keys(values);
	return keys.map(key => {
		if (values[key].length === 0)
			return { label: t(key), value: key, key: key };
		else return { label: `${key} - ${values[key]}`, value: key, key: key };
	});
};

export interface UnitProps {
	unit: string;
	defaultValue?: string;
	min?: number;
	max?: number;
	step?: string;
}

export interface DateTimeProps {
	mode: DateTimeInputMode;
	field: string;
	useCompanyDateTime?: boolean;
}

export interface NumericProps {
	min?: number;
	max?: number;
	step?: number;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getDetailGrid = (
	t: TFunc,
	fields: { name: string; alias: string }[],
	unitFields: {
		[key: string]: UnitProps;
	},
	dateTimeFields: {
		[key: string]: DateTimeProps;
	},
	currencyFields: {
		[key: string]: NumericProps;
	},
	numericFields: {
		[key: string]: NumericProps;
	},
	booleanFields: {
		[key: string]: boolean;
	},
	isEditableField: (fieldName: string, fullEdit: boolean) => boolean,
	fullEdit: boolean,
	isHiddenField?: (fieldName: string, hideFields: boolean) => boolean,
	hideFields?: boolean,
	getDomainValues?: (
		t: TFunc,
		fieldName: string,
	) => { [key: string]: string },
	getDomainItems?: (
		t: TFunc,
		values: { [key: string]: string },
	) => MenuItemType[],
) => {
	if (fields && fields.length > 0) {
		return fields.map(field => {
			if (
				isHiddenField == undefined ||
				!isHiddenField(field.name, hideFields ?? false)
			) {
				if (unitFields[field.name]) {
					return (
						<Grid
							item
							xs={3}
							key={`${field.name}${Date.now}`}
							style={{ paddingRight: '10px' }}>
							<InputUnit
								fieldAttrs={{ name: field.name }}
								unitField={{
									disabled: !isEditableField(
										field.name,
										fullEdit,
									),
									label: field.alias,
									unit: unitFields[field.name].unit,
									min: unitFields[field.name].min,
									max: unitFields[field.name].max,
									step: unitFields[field.name].step,
									defaultValue:
										unitFields[field.name].defaultValue ??
										'0',
									onChange: () => {
										// console.log('onChange', e.target.value);
									},
								}}
								invalidDisplayValue={'-'}
							/>
						</Grid>
					);
				} else if (currencyFields[field.name]) {
					return (
						<Grid
							item
							xs={3}
							key={`${field.name}${Date.now}`}
							style={{ paddingRight: '10px' }}>
							<InputCurrency
								fieldAttrs={{ name: field.name }}
								currencyField={{
									defaultValue: '0',
									label: field.alias,
									disabled: !isEditableField(
										field.name,
										fullEdit,
									),
									onChange: () => {
										// console.log('onChange', e.target.value);
									},
								}}
							/>
						</Grid>
					);
				} else if (numericFields[field.name]) {
					return (
						<Grid
							item
							xs={3}
							key={`${field.name}${Date.now}`}
							style={{ paddingRight: '10px' }}>
							<InputText
								fieldAttrs={{ name: field.name }}
								textField={{
									label: field.alias,
									type: 'number',
									inputProps: {
										min: numericFields[field.name].min,
										max: numericFields[field.name].max,
										step: numericFields[field.name].step,
									},
									disabled: !isEditableField(
										field.name,
										fullEdit,
									),
								}}
							/>
						</Grid>
					);
				} else if (dateTimeFields[field.name]) {
					return (
						<Grid
							item
							xs={3}
							key={`${field.name}${Date.now}`}
							style={{ paddingRight: '10px' }}>
							<InputDateTime
								fieldAttrs={{
									name: dateTimeFields[field.name].field,
								}}
								dateTime={{
									name: dateTimeFields[field.name].field,
									mode: dateTimeFields[field.name].mode,
									keepLocalTime:
										dateTimeFields[field.name]
											.useCompanyDateTime ?? false,
									label: field.alias,
									disabled: !isEditableField(
										field.name,
										fullEdit,
									),
								}}
								invalidDateTimeString="-"
							/>
						</Grid>
					);
				} else if (booleanFields[field.name]) {
					return (
						<Grid
							item
							xs={3}
							key={`${field.name}${Date.now}`}
							style={{
								paddingRight: '10px',
								alignSelf: 'center',
							}}>
							<InputCheckbox
								fieldAttrs={{
									name: field.name,
									disabled: !isEditableField(
										field.name,
										fullEdit,
									),
								}}
								trueText={t('Yes')}
								falseText={t('No')}
								label={field.alias}
								checkbox={{}}
							/>
						</Grid>
					);
				} else {
					const domainValues = getDomainValues
						? getDomainValues(t, field.name)
						: undefined;
					if (
						domainValues &&
						getDomainItems &&
						Object.keys(domainValues).length !== 0
					) {
						return (
							<Grid
								item
								xs={3}
								key={`${field.name}${Date.now}`}
								style={{ paddingRight: '10px' }}>
								<InputSelect
									fieldAttrs={{
										name: field.name,
									}}
									selectProps={{
										label: field.alias,
										disabled: !isEditableField(
											field.name,
											fullEdit,
										),
									}}
									menuItems={getDomainItems(t, domainValues)}
								/>
							</Grid>
						);
					} else {
						return (
							<Grid
								item
								xs={3}
								key={`${field.name}${Date.now}`}
								style={{ paddingRight: '10px' }}>
								<InputText
									fieldAttrs={{ name: field.name }}
									textField={{
										label: field.alias,
										disabled: !isEditableField(
											field.name,
											fullEdit,
										),
									}}
								/>
							</Grid>
						);
					}
				}
			}
		});
	}
	return <></>;
};

export const processFormSubmit = (
	t: TFunc,
	standard: InspectionStandard | string,
	origDetails: { [key: string]: string },
	changes: { [key: string]: string | boolean | SAASObject },
	allFields: { name: string; alias: string }[],
	dateTimeFields: {
		[key: string]: DateTimeProps;
	},
	unitFields: { [key: string]: UnitProps },
	currencyFields: {
		[key: string]: NumericProps;
	},
	numericFields: {
		[key: string]: NumericProps;
	},
	booleanFields: {
		[key: string]: boolean;
	},
	getSystemValueFormatted: (
		value: string,
		invalidValue?: string,
		decimalPlaces?: number,
	) => string | undefined,
	formatDateUTC: (options: FormatDateOptions) => string,
	companySettings: CompanySettings,
	surveyDateTimeName?: string,
	dateTimeKeys?: { date: string; time: string },
): UpdateInspectionParams => {
	const keys = Object.keys(changes);

	// Now sort the change log
	const log: string[] = [];

	keys.forEach(key => {
		let name = undefined;
		let fromValue = origDetails[key];
		let toValue = changes[key];

		// Since Date & Time are actually the one field we need to check for that differently
		if (key === 'surveyDateTime' && surveyDateTimeName) {
			name = surveyDateTimeName;
			// We need to adjust the value as well as translate the log values
			toValue = dateTimeFromPicker(toValue as string);
			changes[key] = toValue;

			if (dateTimeKeys) {
				if (toValue.length === 0) {
					changes[dateTimeKeys.date] = '';
					changes[dateTimeKeys.time] = '';
				} else {
					changes[dateTimeKeys.date] = {
						type: 'date',
						local: '',
						displayValue: toValue.substring(0, 10),
						value: `${toValue.substring(0, 10)}T00:00:00.000Z`,
					};
					changes[dateTimeKeys.time] = {
						type: 'time',
						local: '',
						displayValue: toValue.substring(11, 19),
						value: `1111-11-11T${toValue.substring(11, 19)}`,
					};
				}
			}

			// Get fomatted values for the log
			fromValue =
				formatDateTimeWithFormatDate(fromValue, formatDateUTC) ??
				fromValue;
			if (fromValue.length === 0 || fromValue === 'Invalid DateTime') {
				// This happens with an empty initial date/time
				fromValue = '';
			}

			toValue =
				formatDateTimeWithFormatDate(toValue, formatDateUTC) ?? toValue;
			if (toValue.length === 0 || toValue === 'Invalid DateTime') {
				// This happens with an empty date/time
				toValue = '';
			}
		} else {
			const field = allFields.find(field => field.name === key);
			if (field) {
				name = field.alias;

				// Do we need to adjust the values
				if (dateTimeFields[key]) {
					// User clearing the field ?
					if ((toValue as string).length === 0) {
						toValue = '';
						changes[key] = '';
					} else {
						toValue = dateTimeFromPicker(toValue as string);

						// They are clearing the value
						switch (dateTimeFields[key].mode) {
							case DateTimeInputMode.DATE:
								// We need to change the toValue into the SAASObject
								changes[key] = {
									type: 'date',
									local: '',
									displayValue: toValue.substring(0, 10),
									value: `${toValue.substring(
										0,
										10,
									)}T00:00:00.000Z`,
								};
								// Now format for the logs
								toValue =
									formatDateWithFormatDate(
										toValue,
										formatDateUTC,
									) ?? toValue;
								break;
							case DateTimeInputMode.TIME:
								// We need to change the toValue into the SAASObject
								changes[key] = {
									type: 'time',
									local: '',
									displayValue: toValue.substring(11, 19),
									value: `1111-11-11T${toValue.substring(
										11,
										18,
									)}`,
								};
								// Now format for the logs
								toValue =
									formatTimeWithFormatDate(
										toValue,
										formatDateUTC,
									) ?? toValue;
								break;
							case DateTimeInputMode.DATE_TIME:
								// We need to change the toValue into the SAASObject
								changes[key] = {
									type: 'datetime',
									local: '',
									displayValue: toValue.substring(0, 19),
									value: toValue,
								};
								// Now format for the logs
								toValue =
									formatDateTimeWithFormatDate(
										toValue,
										formatDateUTC,
									) ?? toValue;
								break;
						}

						// If we have invalid date then clear it... may not actually happen but make sure we dont push bad data
						if (
							toValue.length == 0 ||
							toValue === 'Invalid DateTime'
						) {
							toValue = '';
							changes[key] = '';
						}
					}

					// Now format for from time for the logs
					switch (dateTimeFields[key].mode) {
						case DateTimeInputMode.DATE:
							// Now format for the logs
							fromValue =
								formatDateWithFormatDate(
									fromValue,
									formatDateUTC,
								) ?? fromValue;
							break;
						case DateTimeInputMode.TIME:
							fromValue =
								formatTimeWithFormatDate(
									fromValue,
									formatDateUTC,
								) ?? fromValue;
							break;
						case DateTimeInputMode.DATE_TIME:
							fromValue =
								formatDateTimeWithFormatDate(
									fromValue,
									formatDateUTC,
								) ?? fromValue;
							break;
					}

					if (
						fromValue.length === 0 ||
						fromValue === 'Invalid DateTime'
					) {
						// This happens with an empty initial date/time
						fromValue = '';
					}
				} else if (currencyFields[key]) {
					// We need to change the toValue into the SAASObject
					changes[key] = {
						type: 'currency',
						local: 'USD', // FOR THE MOMENT EVERYTHING IS USD ON THE SERVER
						displayValue: '$' + toValue,
						value: +toValue,
					};

					fromValue = getCurrency(+fromValue, companySettings) ?? '-';
					toValue = getCurrency(+toValue, companySettings) ?? '-';
				} else if (unitFields[key]) {
					fromValue =
						getSystemValueFormatted(
							`${fromValue} ${unitFields[field.name].unit}`,
							'-',
						) ?? '-';
					toValue =
						getSystemValueFormatted(
							`${toValue} ${unitFields[field.name].unit}`,
							'-',
						) ?? '-';
				} else if (booleanFields[key]) {
					toValue = changes[key] ? t('Yes') : t('No');
					fromValue = fromValue ? t('Yes') : t('No');
				}
			}
		}

		if (name)
			log.push(
				t(
					// eslint-disable-next-line quotes
					"{{name}} Changed From '{{from}}' To '{{to}}'",
					{
						name: name,
						from: fromValue,
						to: toValue,
					},
				),
			);
	});
	return {
		inspectionID: origDetails['_id'],
		standard: standard,
		updateDetails: changes,
		notes: log,
	};
};
