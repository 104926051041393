import MuiSearchIcon from '@mui/icons-material/Search';
import { rem } from '@Components/styles';
import styled from 'styled-components';

export const SearchWrapper = styled.div``;

export const SearchIcon = styled(MuiSearchIcon)`
	width: ${rem(24)};
	height: ${rem(24)};
`;
