import * as React from 'react';

import type { InstanceMap } from './create-chart';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

/**
 * A hook for tooltip and series state management on `mouseleave` events.
 *
 * This hook resets the tooltip and state of series when the mouse leaves
 * the container element of a group of synchronized charts.
 *
 * @param elementRef - The container element for the charts
 * @param instanceMapRef - A map with the instances owned by the container
 */
const useHighlightReset = (
  elementRef: React.RefObject<HTMLDivElement>,
  instanceMapRef: React.MutableRefObject<InstanceMap>
): void => {
  React.useLayoutEffect(() => {
    const mouseLeaveEventHandler = () => {
      instanceMapRef.current.forEach((instance) => {
        if (instance?.options && instance?.pointer) {
          instance.tooltip?.hide?.(0);
          instance.xAxis[0]?.hideCrosshair?.();
          instance.series?.forEach(function (series) {
            series.setState('normal');
            series.points?.forEach((point) => {
              point.setState?.('normal');
            });
          });
        }
      });
    };

    elementRef.current?.addEventListener('mouseleave', mouseLeaveEventHandler);

    return () => {
      elementRef.current?.removeEventListener(
        'mouseleave',
        mouseLeaveEventHandler
      );
    };
  }, []);
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export { useHighlightReset };
