/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	InfoCard,
	StylovyzeTable,
	StylovyzeTableHeaderProps,
	useCompanyDateTime,
	useIsFeatureEnabled,
} from '@innovyze/stylovyze';
import { CircularProgress } from '@mui/material';
import {
	selectRiskSummay,
	selectRiskSummayIsLoading,
} from '@Selectors/assetRisk.selectors';
import { useGlobalization } from '@Translations';
import { RiskSummary as RiskSummaryObject } from '@Types';
import { formatDateTimeWithFormatDate } from '@Utils/date.utils';
import React from 'react';
import { riskCell } from '../Overall/Overall.component';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const RiskSummary = (): JSX.Element => {
	const { t } = useGlobalization();
	const { formatDate } = useCompanyDateTime();

	const riskSummary = selectRiskSummay();
	const isLoading = selectRiskSummayIsLoading();

	const riskNormalizeFlag = useIsFeatureEnabled(
		'info-360-asset-risk-normalize',
	);

	const defaultHeaders: StylovyzeTableHeaderProps[] = [
		{
			cell: t('Risk Model Name'),
			dataCy: 'col-Risk_Model_Name',
			key: 'Risk_Model_Name',
			width: 'auto',
			padding: '0 10px',
		},
		{
			cell: t('Total Score'),
			dataCy: 'col-Total_Score',
			key: 'Total_Score',
			width: 'auto',
			padding: '0 10px',
		},
		{
			cell: t('Trend'),
			dataCy: 'col-Trend',
			key: 'Trend',
			width: 'auto',
			padding: '0 10px',
		},
		{
			cell: t('Rank'),
			dataCy: 'col-Rank',
			key: 'Rank',
			width: 'auto',
			padding: '0 10px',
		},
		{
			cell: t('Risk Grade'),
			dataCy: 'col-Risk_Grade',
			key: 'Risk_Grade',
			width: 'auto',
			padding: '0 10px',
		},
		{
			cell: t('Weighted CoF'),
			dataCy: 'col-Weighted_CoF',
			key: 'Weighted_CoF',
			width: 'auto',
			padding: '0 10px',
		},
		{
			cell: t('Weighted LoF'),
			dataCy: 'col-Weighted_LoF',
			key: 'Weight_LoF',
			width: 'auto',
			padding: '0 10px',
		},
		{
			cell: t('Formula'),
			dataCy: 'col-Formula',
			key: 'Formula',
			width: 'auto',
			padding: '0 10px',
		},
		{
			cell: t('Last Run'),
			dataCy: 'col-Last_Run',
			key: 'Last_Run',
			width: 'auto',
			padding: '0 10px',
		},
		{
			cell: t('Run By'),
			dataCy: 'col-Run_By',
			key: 'Run_by',
			width: 'auto',
			padding: '0 10px',
		},
	];

	const formatRunBy = (runBy: string) => {
		if (runBy.indexOf('@') !== -1) {
			return runBy.substring(0, runBy.lastIndexOf('@'));
		}
		return runBy;
	};

	const riskFormula = (summary: RiskSummaryObject): string => {
		let formula = t('{{lof}}% LoF {{method}} {{cof}}% CoF', {
			lof: summary.LOFWEIGHTING,
			method: summary.RISKMETHOD == 'added' ? '+' : 'X',
			cof: summary.COFWEIGHTING,
		});

		if (riskNormalizeFlag && summary.postProcessing) {
			const process = summary.postProcessing.find(
				process => process.type === 'normalize',
			);
			if (process && process.params.useNormalize) {
				formula = `((${formula}) / ${
					process.params.maxRiskScore ?? t('HCRS')
				}) * ${process.params.normalizeValue ?? t('NV')}`;
			}
		}
		return formula;
	};

	const rows =
		riskSummary?.map((summary, index) => ({
			key: index,
			cells: [
				summary.CONFIG_NAME == undefined || summary.CONFIG_NAME == ''
					? t('Default Risk model')
					: summary.CONFIG_NAME,
				summary.CURRENT_RISK,
				summary.RISK_CHANGE,
				summary.RANK,
				riskCell(t, summary.GRADE),
				summary.CURRENT_COF,
				summary.CURRENT_LOF,
				riskFormula(summary),
				formatDateTimeWithFormatDate(
					summary.MODIFIED_AT ?? '',
					formatDate,
				),
				formatRunBy(summary.RUN_BY ?? ''),
			],
		})) ?? [];

	return (
		<>
			{!isLoading ? (
				<InfoCard
					fullHeight
					title={t('Risk Summary')}
					applyBodyPadding={false}>
					<StylovyzeTable
						initialized={true}
						className="ScrollTable"
						dataCy="defect-table"
						headers={defaultHeaders}
						rows={rows}
					/>
				</InfoCard>
			) : (
				<InfoCard fullHeight title={t('Risk Summary')}>
					<CircularProgress />
				</InfoCard>
			)}
		</>
	);
};

export default RiskSummary;
