import {
	CreateInspectionParams,
	InspectionParams,
	InspectionResolvedResponse,
	UpdateInspectionParams,
} from '@Types/inspectionAttributes.types';

import { createAction } from '@reduxjs/toolkit';

export const getInspectionAttributes = createAction<InspectionParams>(
	'inspections/getInspectionAttributes',
);
export const getInspectionAttributesWaiting = createAction<boolean>(
	'inspections/getInspectionAttributes/waiting',
);
export const getInspectionAttributesResolved =
	createAction<InspectionResolvedResponse>(
		'inspections/getInspectionAttributes/resolved',
	);
export const getInspectionAttributesRejected = createAction(
	'inspections/getInspectionAttributes/rejected',
);

export const updateInspection =
	createAction<UpdateInspectionParams>('inspections/update');
export interface InspDetailActions {
	onInspectionDetailClicked?: (
		videoUrl: string,
		InspectionID: string,
	) => void;
}

export const refreshInspection =
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	createAction<any>('refreshInspection');

export const createInspection =
	createAction<CreateInspectionParams>('inspections/create');

export const setCreateInspectionId = createAction<string | undefined>(
	'inspections/createInspectionId',
);

export const setCreatingInspection = createAction<boolean>(
	'inspections/creatingInspection',
);
