/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type {
  Axis,
  AxisSetExtremesEventObject,
  Options,
  SeriesOptionsType,
} from 'highcharts/highstock';
import type { MutableRefObject } from 'react';
import { RootProps } from './customAnalytic-chart.types';

/**
 * THIS FILE CAN BE IMPROVED BY:
 * - REMOVING DUPLICATED OPTIONS BETWEEN STACK AND OVERLAY MODE
 */

export type EventHandlers =
  | (RootProps['events'] & {
      syncDateTimeRangePicker: (
        dateRange: [number | null, number | null]
      ) => void;
      onSynchronizedExtremesChange?: (
        this: Axis,
        e: AxisSetExtremesEventObject
      ) => void;
    })
  | undefined;

const xAxisAfterSetExtremesTriggers = [
  'zoom',
  'rangeSelectorButton',
  'navigator',
  'sync',
  'snapAdjustment',
];

const getAxisMinMax = (event: AxisSetExtremesEventObject) => {
  const min = Number.isNaN(event.min) ? undefined : Math.round(event.min);
  const max = Number.isNaN(event.max) ? undefined : Math.round(event.max);

  return { min, max };
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Overlay Mode Options
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const makeOverlayOptions = (
  eventHandlers: MutableRefObject<EventHandlers>
): Options => {
  return {
    chart: {
      height: null,
      zoomType: 'x',
    },
    credits: { enabled: false },
    legend: { enabled: true },
    rangeSelector: { inputEnabled: false },
    yAxis: { opposite: false },
    exporting: { enabled: false },
    scrollbar: {
      buttonArrowColor: 'rgba(1, 1, 1, 0)',
      enabled: true,
      height: 0,
      liveRedraw: false,
    },
    navigator: {
      maskFill: 'rgba(255, 255, 255, 0.7)',
      maskInside: false,
      outlineColor: 'transparent',
      yAxis: { gridLineWidth: 0 },
      handles: {
        backgroundColor: '#F9FAFC',
        borderColor: '#768D95',
        height: 20,
      },
    },
    xAxis: {
      type: 'datetime',
      events: {
        afterSetExtremes: function (event) {
          eventHandlers.current?.syncDateTimeRangePicker([
            event.min,
            event.max,
          ]);

          if (xAxisAfterSetExtremesTriggers.includes(event.trigger)) {
            const { min, max } = getAxisMinMax(event);

            eventHandlers.current?.xAxis?.onExtremesChange?.(
              event.trigger,
              min,
              max
            );
          }
        },
      },
    },
    plotOptions: {
      series: {
        dataGrouping: { enabled: true },
        showInLegend: true,
        showInNavigator: true,
        navigatorOptions: {
          dataGrouping: { enabled: false },
        },
        states: {
          hover: {
            lineWidth: undefined,
            lineWidthPlus: 0,
          },
          inactive: { opacity: 1 },
        },
        events: {
          hide: function (event) {
            // TODO: Check this error
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const target = event.target as SeriesOptionsType | null;
            if (
              target &&
              'baseSeries' in target &&
              target.baseSeries === undefined
            ) {
              eventHandlers.current?.series?.onVisibilityChange?.(
                // Will need type refactoring to accept string, but string is the correct one
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                (event.target as unknown as Highcharts.Series).options.id!,
                event.type as 'hide' | 'show'
              );
            }
          },
          show: function (event) {
            // TODO: Check this error
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const target = event.target as SeriesOptionsType | null;
            if (
              target &&
              'baseSeries' in target &&
              target.baseSeries === undefined
            ) {
              eventHandlers.current?.series?.onVisibilityChange?.(
                // Will need type refactoring to accept string, but string is the correct one
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                (event.target as unknown as Highcharts.Series).options.id!,
                event.type as 'hide' | 'show'
              );
            }
          },
        },
      },
    },
  };
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Stack Mode Options
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const makeStackedSeriesOptions = (
  eventHandlers: MutableRefObject<EventHandlers>
): Options => {
  return {
    chart: { height: null, zoomType: 'x' },
    credits: { enabled: false },
    exporting: { enabled: false },
    navigator: { enabled: false },
    rangeSelector: { enabled: false },
    scrollbar: { enabled: false },
    title: { text: '' },
    yAxis: { opposite: false },
    xAxis: {
      events: {
        setExtremes: function (event) {
          eventHandlers.current?.onSynchronizedExtremesChange?.call(
            this,
            event
          );
        },
      },
    },
  };
};

export const makeStackedHeaderOptions = (
  eventHandlers: MutableRefObject<EventHandlers>
): Options => {
  return {
    chart: { height: null },
    credits: { enabled: false },
    exporting: { enabled: false },
    legend: { enabled: false },
    navigator: { enabled: false },
    rangeSelector: { inputEnabled: false },
    scrollbar: { enabled: false },
    title: { text: '' },
    tooltip: { enabled: false },
    xAxis: {
      height: 0,
      visible: false,
      events: {
        setExtremes: function (event) {
          eventHandlers.current?.onSynchronizedExtremesChange?.call(
            this,
            event
          );
        },
      },
    },
    yAxis: { height: 0, visible: false },
    plotOptions: {
      series: {
        marker: { enabled: false },
        states: { hover: { enabled: false }, inactive: { opacity: 1 } },
      },
    },
  };
};

export const makeStackedFooterOptions = (
  eventHandlers: MutableRefObject<EventHandlers>
): Options => {
  return {
    chart: { height: null },
    credits: { enabled: false },
    exporting: { enabled: false },
    legend: { enabled: true },
    rangeSelector: { enabled: false },
    title: { text: '' },
    tooltip: { followPointer: false },
    xAxis: {
      lineWidth: 0,
      tickWidth: 0,
      labels: { enabled: false },
      type: 'datetime',
      events: {
        setExtremes: function (event) {
          eventHandlers.current?.onSynchronizedExtremesChange?.call(
            this,
            event
          );
        },
        afterSetExtremes: function (event) {
          if (xAxisAfterSetExtremesTriggers.includes(event.trigger)) {
            const { min, max } = getAxisMinMax(event);

            eventHandlers.current?.xAxis?.onExtremesChange?.(
              event.trigger,
              min,
              max
            );
          }
        },
      },
    },
    yAxis: { height: 0, visible: false },
    scrollbar: {
      buttonArrowColor: 'rgba(1, 1, 1, 0)',
      enabled: true,
      height: 0,
      liveRedraw: false,
    },
    navigator: {
      maskFill: 'rgba(255, 255, 255, 0.7)',
      maskInside: false,
      outlineColor: 'transparent',
      xAxis: { gridLineWidth: 0 },
      yAxis: { gridLineWidth: 0 },
      handles: {
        backgroundColor: '#F9FAFC',
        borderColor: '#768D95',
        height: 20,
      },
    },
    plotOptions: {
      series: {
        dataGrouping: { enabled: false },
        marker: { enabled: false },
        showInLegend: true,
        showInNavigator: true,
        states: { hover: { enabled: false } },
        navigatorOptions: {
          dataGrouping: { enabled: false },
        },
        events: {
          hide: function (event) {
            // TODO: Check this error
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const target = event.target as SeriesOptionsType | null;
            if (
              target &&
              'baseSeries' in target &&
              target.baseSeries === undefined
            ) {
              eventHandlers.current?.series?.onVisibilityChange?.(
                // Will need type refactoring to accept string, but string is the correct one
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                (event.target as unknown as Highcharts.Series).options.id!,
                event.type as 'hide' | 'show'
              );
            }
          },
          show: function (event) {
            // TODO: Check this error
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const target = event.target as SeriesOptionsType | null;
            if (
              target &&
              'baseSeries' in target &&
              target.baseSeries === undefined
            ) {
              eventHandlers.current?.series?.onVisibilityChange?.(
                // Will need type refactoring to accept string, but string is the correct one
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                (event.target as unknown as Highcharts.Series).options.id!,
                event.type as 'hide' | 'show'
              );
            }
          },
        },
      },
    },
  };
};
