import { createReducer } from '@reduxjs/toolkit';

import {
  getKpiValue,
  getKpiValueResolved,
  getKpiValueRejected,
} from '../actions/kpiValue.actions';

export type KpiValueType = {
  [type: string]: {
    value: string | number;
    loading: boolean;
  };
};

export interface KpiValueStoreState {
  kpiValueMap: KpiValueType;
}

export const kpiInitialState: KpiValueType = {};

export const kpiValuesReducer = createReducer<KpiValueType>(
  kpiInitialState,
  (builder) => {
    builder
      .addCase(getKpiValue, (state, { payload: { type } }) => {
        state[type] = {
          value: 0,
          loading: true,
        };
      })
      .addCase(getKpiValueResolved, (state, { payload: { type, value } }) => {
        state[type] = {
          value: value || 0,
          loading: false,
        };
      })
      .addCase(getKpiValueRejected, (state, { payload: { type } }) => {
        state[type] = {
          value: state[type]?.value || 0,
          loading: false,
        };
      });
  }
);
