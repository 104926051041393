import * as React from 'react';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

/**
 *  A hook that dispatches a `resize` event when the element privided resizes.
 *
 * @param elementRef - The element that will trigger the `resize` event.
 */
const useResizeEvent = (elementRef: React.RefObject<HTMLElement>): void => {
  React.useLayoutEffect(() => {
    if (elementRef.current) {
      const resizeObserver = new ResizeObserver(
        (entries: ResizeObserverEntry[]) => {
          window.requestAnimationFrame((): void | undefined => {
            if (!Array.isArray(entries) || !entries.length) {
              return;
            }
            window.dispatchEvent(new Event('resize'));
          });
        }
      );

      resizeObserver.observe(elementRef.current);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [elementRef]);
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export { useResizeEvent };
