import { AxiosResponse } from 'axios';
import { alertsApi, incidentsApi } from '../apis';

const getDateRange = (days = 1): { fromDate: string; toDate: string } => {
  const curDT = new Date();
  const daily = new Date();
  daily.setDate(curDT.getDate() - days);
  return {
    fromDate: daily.toISOString(),
    toDate: curDT.toISOString(),
  };
};

export const getIncidents = async (): Promise<AxiosResponse> => {
  const dateRange = getDateRange();
  const params = {
    fromDate: dateRange.fromDate,
    toDate: dateRange.toDate,
  };
  return incidentsApi.get('/incidents', { params });
};

export const getAlerts = async (): Promise<AxiosResponse> => {
  const dateRange = getDateRange(365);
  const params = {
    fromCreatedDate: dateRange.fromDate,
    toCreatedDate: dateRange.toDate,
    status: 'Open',
    q: 'priority:High',
  };
  return alertsApi.get('/alerts', { params });
};

export const getNotifications = async (): Promise<AxiosResponse> => {
  const dateRange = getDateRange();
  const params = {
    fromCreatedDate: dateRange.fromDate,
    toCreatedDate: dateRange.toDate,
  };
  return alertsApi.get('/notifications', { params });
};
