import { Source, SourceSpecification } from 'mapbox-gl';

import MapboxUtilsBase from './base';
import logger from '@Map/logger/Logger';
import { shallowEqual } from '@Map/utils';

type SourceExtended = Source & {
	_options: unknown;
	load: () => void;
};

export default class MapboxUtilsSources extends MapboxUtilsBase {
	hasSource(id: string): boolean {
		return !!this._map?.getSource(id) ?? false;
	}

	addSource(id: string, source: SourceSpecification): void {
		if (!this.hasSource(id)) {
			this._map?.addSource(id, source);
		}
	}

	updateSource(id: string, source: SourceSpecification): void {
		const mapSource = this._map?.getSource(id) as SourceExtended;
		if (mapSource?.type === 'geojson' && 'data' in source && source.data) {
			mapSource.setData(source.data as GeoJSON.GeoJSON);
		} else {
			if (mapSource) {
				try {
					const options = mapSource._options;
					if (!shallowEqual(options, source)) {
						mapSource._options = source;
						mapSource.load();
					}
				} catch {
					logger.error(`Could not update source ${id}`);
				}
			}
			this.addSource(id, source);
		}
	}

	removeSource(id: string): void {
		if (this.hasSource(id)) {
			this._map?.removeSource(id);
		}
	}
}
