/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { forwardRef, ReactElement, Ref, useMemo } from 'react';
import { GlobalPassthroughs } from '../../../types/global.types';
import { CustomAnalyticChartConnected } from '../../../types/';
import { ChartingErrorBoundary } from '../../atoms';
import CustomAnalyticChart from '../../molecules/CustomAnalyticChart';
import useCustomAnalytic from '../../../core/hooks/useCustomAnalytic';
import { CustomAnalyticSeries } from '../../../core/types/analytic.types';
import { generateCustomAnalyticSeries } from './ConnectedCustomAnalyticChart.utils';
import useSensorData from '../../../core/hooks/useSensorData';
import LoadingData from '../../../core/components/LoadingData';
import { StackableChartRef } from '../../../core/components/StackableChart';
import { isFeatureEnabled } from '@innovyze/stylovyze';
import {
  InsightCustomAnalyticChart,
  InsightCustomAnalyticChartProps,
} from '../../../_next/presets/insight-customAnalytic-chart';
import type {
  Reading,
  AnalyticFunction,
  TimeRangeSelection,
} from '../../../_next/core/_insight-chart';
import {
  DataSourceType,
  isConstantDataSource,
  isSensorDataSource,
  SensorDataSource,
} from '../../../core/types/data.types';
import { Resolution } from '../../../core/types/resolution.types';

export type ConnectedCustomAnalyticChartProps = CustomAnalyticChartConnected &
  GlobalPassthroughs & {
    timeRangeSelection?: TimeRangeSelection;
    onTimeRangeSelectionChange?: (
      timeRangeSelection: TimeRangeSelection
    ) => void;
  };

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Version Switch
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const ConnectedCustomAnalyticChart = (
  props: ConnectedCustomAnalyticChartProps,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref?: any
): ReactElement => {
  const isV2Enabled = isFeatureEnabled('info-360-analytics-hp2-charts');

  if (!isV2Enabled) {
    return <CustomAnalyticChartV1 {...props} ref={ref} />;
  }

  return <CustomAnalyticChartV2 {...props} ref={ref} />;
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * New Chart
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const CustomAnalyticChartV2 = React.forwardRef<
  { chart: Highcharts.Chart | undefined },
  ConnectedCustomAnalyticChartProps
>((props, ref): React.ReactElement => {
  const customAnalytic = useCustomAnalytic(props.analyticId);

  // props.alwaysFetchData
  // props.displayOptions.stacked;
  // props.displayOptions.trendline;
  // props.displayOptions.xRange;
  // props.displayOptions.yRange;
  // props.displayOptions.yAxis?.side;
  // props.overrideNodes
  // props.dataRangeSelection

  const customAnalyticSeries = useMemo<
    InsightCustomAnalyticChartProps['series']
    // TODO: Check this error
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
  >(() => {
    const generatedSeries =
      customAnalytic.status === 'resolved'
        ? generateCustomAnalyticSeries(customAnalytic.data)
        : [];

    return generatedSeries.map((series) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      let analytic: Reading | AnalyticFunction = series.isAnalytic
        ? { type: series.node.func!, params: series.node.params }
        : series.node.func!;

      let sensorId, resolution, constantValue;
      if (isSensorDataSource(series.dataSource)) {
        sensorId = series.dataSource.sensorId;
        resolution = series.dataSource.resolution;
      }
      if (isConstantDataSource(series.dataSource)) {
        sensorId = '';
        resolution = Resolution.Raw;
        constantValue = series.dataSource.value;
        analytic = 'Close';
      }

      return {
        id: series.node.id,
        name: series.node.name,
        sensorId,
        resolution,
        constantValue,
        type: series.dataSource.type,
        analytic,
        hidden:
          props.hiddenSeries?.includes(series.node.id) ||
          props.hiddenNodes?.includes(series.node.name),
      };
    });
  }, [
    customAnalytic.status,
    customAnalytic.data,
    JSON.stringify(props.overrideNodes),
    props.hiddenSeries?.join(''),
    props.hiddenNodes?.join(''),
  ]);

  const customAnalyticCustomSeries = useMemo(() => {
    return (props.customNodes ?? []).map((node) => {
      return {
        id: node.id,
        name: node.name,
        customData: node.data,
        customUnit: node.unit,
        analytic: node.analytic ?? 'Close',
        sensorId: '',
        resolution: '',
        hidden:
          props.hiddenSeries?.includes(node.id) ||
          props.hiddenNodes?.includes(node.name),
      };
    });
  }, [
    props.customNodes,
    props.hiddenSeries?.join(''),
    props.hiddenNodes?.join(''),
  ]);

  const stacked = props.displayOptions?.overlay === false ? true : false;
  const series = customAnalyticCustomSeries.length
    ? customAnalyticCustomSeries
    : customAnalyticSeries;

  return (
    <ChartingErrorBoundary chartProps={props}>
      <InsightCustomAnalyticChart
        ref={ref}
        stacked={stacked}
        // TODO: Check this error
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        series={series}
        analyticStartDate={customAnalytic.data?.startDate}
        analyticEndDate={customAnalytic.data?.endDate}
        timeRangeSelection={props.timeRangeSelection}
        enableMarkers={props.displayOptions.showMarkers}
        onTimeRangeSelectionChange={props.onTimeRangeSelectionChange}
        onSeriesVisibilityChange={(seriesId, type) => {
          const hiddenSeries = props.hiddenSeries
            ? [...props.hiddenSeries]
            : [];

          const newHiddenSeries =
            type === 'hide'
              ? [...hiddenSeries, seriesId]
              : hiddenSeries.filter((series) => series !== seriesId);

          props.onHiddenSeriesChange?.(newHiddenSeries);
        }}
        xAxis={{
          min: props.rangeSelection?.min,
          max: props.rangeSelection?.max,
          enableGridlines: props.displayOptions?.showXGrid,
        }}
        yAxis={{
          enableGridlines: props.displayOptions?.showYGrid,
          label: props.displayOptions?.yAxis?.label,
        }}
        selectedTheme={props.selectedTheme}
      />
    </ChartingErrorBoundary>
  );
});

CustomAnalyticChartV2.displayName = 'CustomAnalyticChartV2';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Old Chart
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const CustomAnalyticChartV1 = React.forwardRef<
  StackableChartRef,
  ConnectedCustomAnalyticChartProps
>((props, ref): ReactElement => {
  const customAnalytic = useCustomAnalytic(props.analyticId);

  const customAnalyticSeries = useMemo<CustomAnalyticSeries[]>(() => {
    return customAnalytic.status === 'resolved'
      ? generateCustomAnalyticSeries(customAnalytic.data)
      : [];
  }, [customAnalytic.status, customAnalytic.data, props.overrideNodes]);

  /**
   * Avoid passing constant data sources to not to break chart v1
   */
  const dataSources = useMemo(() => {
    return customAnalyticSeries
      .filter(
        (series) => series.dataSource.type === DataSourceType.SensorDataSource
      )
      .map((series) => series.dataSource) as SensorDataSource[];
  }, [customAnalyticSeries]);

  const sensorDataRecords = useSensorData(
    dataSources,
    props.dataRangeSelection,
    props.alwaysFetchData
  );

  return (
    <ChartingErrorBoundary chartProps={props}>
      <LoadingData data={customAnalytic}>
        <CustomAnalyticChart
          className={props.className}
          cy={props.cy}
          data={sensorDataRecords}
          dataCy={props.dataCy}
          dataRangeSelection={props.dataRangeSelection}
          displayOptions={props.displayOptions}
          hiddenSeries={props.hiddenSeries ?? props.hiddenNodes}
          isReadOnly={props.isReadOnly}
          onHiddenSeriesChange={props.onHiddenSeriesChange}
          onRangeSelectionChange={props.onRangeSelectionChange}
          rangeSelection={props.rangeSelection}
          ref={ref}
          series={customAnalyticSeries}
        />
      </LoadingData>
    </ChartingErrorBoundary>
  );
});

CustomAnalyticChartV1.displayName = 'CustomAnalyticChartV1';

export default forwardRef<StackableChartRef, ConnectedCustomAnalyticChartProps>(
  ConnectedCustomAnalyticChart
);
