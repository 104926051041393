import * as Highcharts from 'highcharts/highstock';
import { palette } from '@innovyze/stylovyze';
import {
  RawMeasureDataMap,
  PumpPerformanceSeries,
  MARKER_SIZE_MAP,
  MarkerTypes,
  DEFAULT_CHART_COLORS,
  ScatterDataPoint,
  RawMeasureData,
} from '../../types';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-var-requires
const Rainbow = require('rainbowvis.js');
import _ from 'lodash';
import { hexColorToRGB, sortByTimeAscending } from '../../utils';
import { Readings } from '../../types/sensor.types';

/**
 * When trying to join two series with possibly mismatching time series using only
 * O(n), there are three relevant pieces to figure out: 1) whether one of the series needs
 * to be offset when joining the two series, 2) which series needs to be offset, and 3) where
 * should the for loop end.
 * The Offset returned represents 1, the Length returned represents 3 (how long should
 * to loop iterate), and isXFirst represents 2.
 * @param pumpSeries
 * @returns [Offset, Length, isXFirst] - Offset is how much to
 */
const getOffsetAndLength = (
  pumpSeries: PumpPerformanceSeries
): [number, number, boolean] | undefined => {
  const {
    xData: { data: xSeries },
    yData: { data: ySeries },
  } = pumpSeries;

  if (!xSeries || !ySeries) {
    console.error(
      'One of the series does not exist (x then y): ',
      !!xSeries,
      !!ySeries
    );
    return undefined;
  }

  const xSeriesFirst = xSeries[0][0];
  const ySeriesFirst = ySeries[0][0];
  const xSeriesLast = xSeries[xSeries.length - 1][0];
  const ySeriesLast = ySeries[ySeries.length - 1][0];

  const isXFirst = xSeriesFirst < ySeriesFirst;
  const doTimeSeriesOverlap = isXFirst
    ? xSeriesLast > ySeriesFirst
    : ySeriesLast > xSeriesFirst;

  if (!doTimeSeriesOverlap) {
    console.error(
      'Time series do not overlap (x then y, first then last): ',
      xSeriesFirst,
      xSeriesLast,
      ySeriesFirst,
      ySeriesLast
    );
    return undefined;
  }

  const doesFirstEndFirst = isXFirst
    ? xSeriesLast < ySeriesLast
    : ySeriesLast < xSeriesLast;

  const offset = isXFirst
    ? xSeries.findIndex((xDatum) => xDatum[0] === ySeries[0][0])
    : ySeries.findIndex((yDatum) => yDatum[0] === xSeries[0][0]);

  const loopLength =
    doesFirstEndFirst && isXFirst
      ? xSeries.length - 1 - offset
      : doesFirstEndFirst && !isXFirst
      ? ySeries.length - 1 - offset
      : !doesFirstEndFirst && isXFirst
      ? ySeries.length - 1
      : xSeries.length - 1;

  return [offset, loopLength, isXFirst];
};

/**
 * Creates getters for values out of the series depending on if series
 * needs to be offset or not
 */
const generateSeriesAccessors = (
  xSeries: RawMeasureData,
  ySeries: RawMeasureData,
  reading: Readings,
  isXFirst: boolean,
  offset: number
) => {
  const getSeriesValue =
    (series: 'x' | 'y', reading: number) => (index: number) =>
      series === 'x'
        ? xSeries?.[isXFirst ? index + offset : index]?.[reading]
        : ySeries?.[isXFirst ? index : index + offset]?.[reading];

  return {
    xSeriesTime: (index: number) => getSeriesValue('x', 0)(index),
    xSeriesReading: (index: number) =>
      getSeriesValue('x', RawMeasureDataMap[reading])(index),
    ySeriesTime: (index: number) => getSeriesValue('y', 0)(index),
    ySeriesReading: (index: number) =>
      getSeriesValue('y', RawMeasureDataMap[reading])(index),
  };
};

const emptyScatterConfig = (
  alias: string
): Highcharts.SeriesScatterOptions => ({
  type: 'scatter',
  name: alias,
  data: [],
});

export const processPumpPerformanceData = (
  series: PumpPerformanceSeries,
  index?: number
): Highcharts.SeriesScatterOptions => {
  try {
    const {
      xData: { data: xSeries = [], alias: xAlias, unit: xUnit, id: xId },
      yData: { data: ySeries = [], alias: yAlias, unit: yUnit, id: yId },
      displayOptions,
      reading,
    } = series;

    if (!(xSeries && xSeries.length > 0 && ySeries && ySeries.length > 0))
      return emptyScatterConfig(series.alias);

    const BASE_SCATTER_SERIES_CONFIG = {
      type: 'scatter',
      name: series.alias,
      states: {
        inactive: {
          opacity: 1,
        },
      },
      tooltip: {
        split: false,
        headerFormat: '<b>Pump Performance</b><br>',
        pointFormat: `${xAlias}: {point.x} ${xUnit}<br>${yAlias}: {point.y} ${yUnit}`,
      },
      marker: {
        symbol: displayOptions?.markerType
          ? displayOptions.markerType
          : MarkerTypes.Circle,
        radius: displayOptions?.markerSize
          ? MARKER_SIZE_MAP[displayOptions?.markerSize]
          : MARKER_SIZE_MAP['Medium'],
      },
    } as Highcharts.SeriesScatterOptions;

    const formattedData: ScatterDataPoint[] = [];

    const offsetAndLengthResults = getOffsetAndLength(series);

    const sortedXSeries = [...xSeries].sort(sortByTimeAscending);
    const sortedYSeries = [...ySeries].sort(sortByTimeAscending);

    // If undefined the function found an issue with the series passed to it, return empty

    if (!offsetAndLengthResults) return emptyScatterConfig(series.alias);

    const [offset, loopLength, isXFirst] = offsetAndLengthResults;
    let foundSample = false;
    let poorlyInterpolated = 0;

    const { xSeriesReading, xSeriesTime, ySeriesReading, ySeriesTime } =
      generateSeriesAccessors(
        sortedXSeries,
        sortedYSeries,
        reading,
        isXFirst,
        offset
      );

    for (let i = 0; i < loopLength; i++) {
      if (
        (isXFirst ? xSeries[i + offset] : ySeries[i + offset]) &&
        xSeriesTime(i) === ySeriesTime(i)
      ) {
        formattedData.push({
          x: xSeriesReading(i),
          y: ySeriesReading(i),
          date: xSeriesTime(i),
          color: '',
        });
      } else {
        poorlyInterpolated++;
        if (!foundSample) {
          const matchingIndex = isXFirst
            ? xSeries.findIndex((datum) => datum[0] === ySeries[i][0])
            : ySeries.findIndex((datum) => datum[0] === xSeries[i][0]);

          console.error(
            'Bad interpolation in PPC: Cannot match on time value. Make sure data has been interpolated to use. \n\nError Log: ',
            {
              'Related sensors': {
                x: xId,
                y: yId,
              },
              'Failure at index': i,
              'Index where they should match':
                matchingIndex !== -1 ? matchingIndex : 'no matching index',
              'Times of failed match': {
                x: xSeriesTime(i),
                y: ySeriesTime(i),
              },
              'Offset is': offset,
              'Offset should be': isXFirst
                ? xSeries.findIndex((datum) => datum[0] === ySeries[0][0])
                : ySeries.findIndex((datum) => datum[0] === xSeries[0][0]),
            }
          );
        }
        foundSample = true;
      }
    }
    if (foundSample)
      console.error(
        'Incorrect interpolation count on PPC: ',
        poorlyInterpolated
      );
    const uniqueData = _.uniqBy(
      formattedData,
      (datum) => `${datum.x}-${datum.y}`
    );

    if (uniqueData.length < 1) return emptyScatterConfig(series.alias);

    if (typeof index === 'undefined' && !displayOptions?.color) {
      const rainbow = new Rainbow();
      rainbow.setNumberRange(0, uniqueData.length);
      rainbow.setSpectrum(
        // palette.primary.main,
        palette.warning.light,
        palette.error.dark
      );
      return {
        ...BASE_SCATTER_SERIES_CONFIG,
        data: uniqueData.map((datum, index) => ({
          ...datum,
          color: hexColorToRGB(`#${rainbow.colourAt(index)}`, 0.7),
        })),
      };
    } else {
      let color = '';
      if (!displayOptions?.color)
        color = hexColorToRGB(
          DEFAULT_CHART_COLORS[index! % DEFAULT_CHART_COLORS.length]
        );
      return {
        ...BASE_SCATTER_SERIES_CONFIG,
        data: uniqueData.map((datum) => ({
          ...datum,
        })),
        color: displayOptions?.color ? displayOptions.color : color,
      };
    }
  } catch (e) {
    console.error(
      'Something unforseen completely broke the PPC processing function. \n\nError Log: ',
      series,
      e
    );
    return emptyScatterConfig(series.alias);
  }
};
