/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Utilitites
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export function getValidNumber(value: unknown): number | undefined {
  const _value = Number(value);
  return Number.isNaN(_value) ? undefined : _value;
}

export function getValidUnits(units?: (string | null | undefined)[]): string[] {
  if (units === undefined || units.length === 0) return [];
  return Array.from(new Set(units.filter(nonEmptyString)));
}

export function nonEmptyString(value: unknown): value is string {
  return typeof value === 'string' && value.trim().length > 0;
}
