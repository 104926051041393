import {
	BackgroundTypes,
	ConfigLayer,
	MapHiddenLayers,
	MapPosition,
	MapSelectedAssets,
	SelectedAsset,
	SourceType,
} from '@innovyze/inno-map';
import { InspectionStandard } from '.';

import { DataServiceType } from '@innovyze/inno-map/map/services/types';
import { Extent } from '@innovyze/shared-utils';
import { SpatialLayer } from './spatial.types';
import { Status } from '@Types';

export interface LayersOptions {
	showTiles: boolean;
	showInspections: boolean;
	showRisk: boolean;
	showOther: boolean;
}

// 2do deprecated, use extent in shared-utils
export type BBox = number[]; // to be compat with inno-map, prefer export type BBox = [number, number, number, number];

// This is a copy of map StatusType - would like to use directly via...
// import { StatusType } from '@innovyze/inno-map/map/logger/Logger';
// but the export appears broken so for now just replicate.
// So watch out for string changes in original StatusType
export enum MapStatusType {
	DEBUG = 'debug',
	INFO = 'info',
	WARN = 'warn',
	ERROR = 'error',
}

export interface SourceOptions {
	dataServiceId: string;
	layerName: string;
	type: SourceType;
	url: string;
	minZoom?: number;
	token?: string;
}

// Geojson layers need query params based on MapPosition so to only return minimum required
export interface GeneralOptions {
	radiusDefault: number; // m
	boundMargin: number; // %
	useBBox: boolean;
	singlePage: boolean;
	precisionLonLat: number;
	precisionRadius: number;
	noMinZoom: boolean;
}

// Need to keep this in sync with inno-map API
export type MapType = 'line' | 'symbol' | 'polygon' | 'fill' | 'heatmap';

export enum RiskLayersMask {
	None = 0,
	Published = 1,
	Unpublished = 1 << 1,
}

export interface MapOptions {
	generalOptions: GeneralOptions;
	showRisk: boolean;
	riskLayers?: MapRiskLayer[];
	riskLayersMask?: RiskLayersMask;
	tilesSource?: SourceOptions;
	inspectionSources?: SourceOptions[];
	otherSource?: SourceOptions;
	ranges?: [number, number][]; // deprecated for riskConfigs
	useSpecifiedSpatialLayer?: boolean;
	tilesSourceHide: boolean;
	rehabLayers?: MapRehabLayer[];
}

export interface MapLocation {
	position?: MapPosition;
	radius?: number;
	extent?: Extent;
}

export interface MapInsert {
	options: MapOptions;
	// background typically set from main map value
	background: BackgroundTypes;
	// sources and layers are in redux for debugging
	// actually will be created on fly as need valid token
	sources?: DataServiceType<unknown>[];
	layers?: ConfigLayer[];
	location?: MapLocation;
	selected?: SelectedAsset[];
	hiddenLayers?: MapHiddenLayers;
}

export interface MapRiskLayer {
	name: string;
	ConfigId: string;
	versionId: string;
	gradeOffset: number;
	createdAt: string;
	lastRun: string;
	modifiedAt: string;
	assetType: string;
	assetCount?: string;
	publishState?: string;
}

export interface MapRehabActionLayer {
	layerName: string;
	id: string;
}

export interface MapRehabLayer {
	layerName: string;
	id: string;
	lastRun: string;
	assetType: string;
	systemType: string;
	layers?: MapRehabActionLayer[];
}

// mapState is original am_assets state which covers values got from url routing
export interface MapState {
	position?: MapPosition;
	background: BackgroundTypes;
	selected: MapSelectedAssets;
	url: string;
	hiddenLayers: MapHiddenLayers;
	byTenant: { [key: string]: Omit<MapState, 'byTenant'> };
}

export interface MapMain extends MapState {
	insert: MapInsert;
}

export interface MapSpatialInsert {
	layer: SpatialLayer;
	insert: MapInsert;
}

export interface MapStoreState {
	main: MapMain;
	assetInsert: {
		assetId: string;
		assetType: AssetType;
		insert: MapInsert;
		layerId?: string;
	};
	inspectionInsert: {
		inspectionId: string;
		standard: InspectionStandard;
		insert: MapInsert;
	};
	spatialTilesSourceHide: boolean;
	spatialInserts: MapSpatialInsert[];
	risk: {
		layers: MapRiskLayer[];
		status: Status;
	};
	rehab: {
		layers: MapRehabLayer[];
		status: Status;
	};
}

export type PartialMapStoreState = Partial<MapStoreState>;

export const AssetTypePipe = 'wwPipe';
export const AssetTypeManhole = 'wwManhole';
export const AssetTypeWDPipe = 'pipe';
export const AssetTypeUnknown = 'assetTypeUnknown';
export type AssetType =
	| typeof AssetTypePipe
	| typeof AssetTypeManhole
	| typeof AssetTypeWDPipe
	| typeof AssetTypeUnknown;
