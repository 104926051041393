import { RangeSelection, HiddenSeries } from '../../../types';
import StackableChart, {
  StackableChartRef,
} from '../../../core/components/StackableChart';
import { generateIliOptions, generateIliStackOptions } from './IliChart.utils';
import Highcharts from 'highcharts/highstock';
import React, { forwardRef } from 'react';
import useHighchartsOptions from '../../../core/hooks/useHighchartsOptions';
import { isFeatureEnabled } from '@innovyze/stylovyze';
import { IliDisplayOptions, IliSeries } from '../../../core/types/ili.types';
import { ChartProps } from '../../../core/types/chart.types';
import useRangeSelection from '../../../core/hooks/useRangeSelection';
import useHiddenSeries from '../../../core/hooks/useHiddenSeries';
import useStackOptions from '../../../core/hooks/useStackOptions';
import { useGlobalization } from '../../../i18n/useGlobalization';

export interface IliChartProps extends ChartProps<IliSeries> {
  dataRangeSelection?: RangeSelection;
  displayOptions?: IliDisplayOptions;
  hiddenSeries?: HiddenSeries;
  onHiddenSeriesChange?: (hiddenSeries: HiddenSeries) => void;
  onRangeSelectionChange?: (rangeSelection: RangeSelection) => void;
  rangeSelection?: RangeSelection;
}

const IliChart = (
  props: IliChartProps,
  ref?: React.Ref<StackableChartRef>
): JSX.Element | null => {
  const { t } = useGlobalization();
  const isBoostModuleEnabled = isFeatureEnabled(
    'info-360-analytics-boost-module'
  );

  const { rangeSelectionOptions } = useRangeSelection(
    props.rangeSelection,
    props.dataRangeSelection,
    props.onRangeSelectionChange
  );

  const { hiddenSeries, hiddenSeriesOptions } = useHiddenSeries(
    props.hiddenSeries,
    props.onHiddenSeriesChange
  );

  const options = useHighchartsOptions(() => {
    return [
      generateIliOptions(
        props.data,
        !!isBoostModuleEnabled,
        props.series,
        hiddenSeries,
        t,
        props.displayOptions
      ),
      hiddenSeriesOptions,
      rangeSelectionOptions,
    ];
  }, [
    hiddenSeries,
    hiddenSeriesOptions,
    isBoostModuleEnabled,
    props.data,
    props.displayOptions,
    props.series,
    rangeSelectionOptions,
  ]);

  const stackOptions = useStackOptions(() => {
    return {
      enabled: !(props.displayOptions?.overlay ?? false),
      options: generateIliStackOptions(
        props.data,
        props.series,
        t,
        props.displayOptions
      ),
    };
  }, [props.data, props.series, props.displayOptions]);

  return (
    <StackableChart
      constructorType="stockChart"
      data={props.data}
      highcharts={Highcharts}
      options={options}
      ref={ref}
      stack={stackOptions}
    />
  );
};

export default forwardRef<StackableChartRef, IliChartProps>(IliChart);
