import { TileJson } from './types';

export interface LatLng {
	lat: number;
	lng: number;
}

export interface CenterPoint extends LatLng {
	zoom: number;
}

export interface Bounds {
	southWest: LatLng;
	northEast: LatLng;
}

export interface Layer {
	type: string;
	minzoom: number;
	maxzoom: number;
	clustered: boolean;
	assetCount: number;
	path: string;
}

export interface LayerV2 extends Layer {
	systemType: string;
	sourceType: string;
}

export interface Info360TileMetadata {
	tenant: string;
	centerPoint: CenterPoint;
	bounds: Bounds;
	layers: Layer[];
}

export type Info360TileMetadataV2 = Omit<Info360TileMetadata, 'layers'> & {
	systems: {
		[key: string]: LayerV2[] | TileJson;
	};
};

export enum SystemTypes {
	CombinedSewer = 'CombinedSewer',
	SanitarySewer = 'SanitarySewer',
	ForceMain = 'ForceMain',
	Stormwater = 'Stormwater',
	WaterDistribution = 'WaterDistribution',
	TreatmentPlant = 'TreatmentPlant',
	Sensors = 'Sensors',
}
