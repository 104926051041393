import { Status } from './general.types';

export enum ChartType {
	InspectionOverview = 1,
	InspectionAssetDrilldown = 2,
	InspectionMonthDrilldown = 3,
	RiskOverview = 4,
	RiskDrilldown = 5,
}

export interface ChartIdNumberData {
	_id: number | string;
	count: number;
}

export interface ChartStorage {
	chartId: string;
	data?: unknown;
	status: Status;
}

export interface ChartsStoreState {
	charts: ChartStorage[];
	isLoading: boolean;
}
