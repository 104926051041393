import { generateStatusChartSeries } from '../../../utils/statusChart.utils';
import { getMeasureData } from '../../../actions';
import { ChartingErrorBoundary, NoResults } from '../../atoms';
import { Resolutions } from '../../../types/sensor.types';
import { selectSensorData } from '../../../selectors/measureData.selectors';
import { StatusChart, StatusChartDisplayOptions } from '../../molecules';
import {
  StatusChartSeries,
  StatusChartSeriesWithoutData,
} from '../../../types/statusChart.types';
import { useDispatch } from 'react-redux';
import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import {
  GlobalPassthroughs,
  HiddenSeries,
  RangeSelection,
} from '../../../types';
import { mapReduce } from '../../../utils';
import { isFeatureEnabled } from '@innovyze/stylovyze';
import {
  InsightPumpStatusChart,
  InsightPumpStatusChartSeries,
} from '../../../_next/presets/insight-pump-status-chart';
import { TimeRangeSelection } from '../../../_next/core/_insight-chart';
import { Theme } from '../../../_next/core/utils/theme-utils';

export interface ConnectedStatusChartProps extends GlobalPassthroughs {
  id?: string;
  series: StatusChartSeriesWithoutData[];
  resolution?: Resolutions;
  timeRange: number;
  dataRangeSelection?: RangeSelection;
  displayOptions?: StatusChartDisplayOptions;
  hiddenSeries?: HiddenSeries;
  onHiddenSeriesChange?: (hiddenSeries: HiddenSeries) => void;
  timeRangeSelection?: TimeRangeSelection;
  selectedTheme?: Theme;
}

type AsyncState = 'idle' | 'loading' | 'resolved' | 'rejected';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Version Switch
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const ConnectedStatusChart = (
  props: ConnectedStatusChartProps,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref?: any
): React.ReactElement => {
  const isV2Enabled = isFeatureEnabled('info-360-analytics-hp2-charts');

  if (!isV2Enabled) {
    return <PumpStatusChartV1 {...props} ref={ref} />;
  }

  return <PumpStatusChartV2 {...props} ref={ref} />;
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * New Chart
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const PumpStatusChartV2 = React.forwardRef<
  { chart: Highcharts.Chart | undefined },
  ConnectedStatusChartProps
>((props, ref): React.ReactElement => {
  const {
    series,
    timeRange = 24,
    displayOptions,
    dataRangeSelection,
    hiddenSeries,
    ...rest
  } = props;

  const statusSeries = useMemo(() => {
    const _statusSeries = series.map<InsightPumpStatusChartSeries>(
      (_series, seriesIndex) => {
        return {
          name: _series.alias,
          sensorId: _series.id,
          resolution: _series.resolution,
          reading: 'Close',
          hidden: hiddenSeries?.includes(seriesIndex),
          yAxis: { category: _series.alias || _series.id },
          customData: _series.customData,
        };
      }
    );

    return _statusSeries;
  }, [series, hiddenSeries?.join('')]);

  const yAxisCategories = React.useMemo(() => {
    return series.map((_series) => _series.alias || _series.id);
  }, [series.map((_series) => `${_series.id}${_series.alias}`).join('')]);

  return (
    <ChartingErrorBoundary chartProps={props}>
      <InsightPumpStatusChart
        ref={ref}
        series={statusSeries}
        timeRangeSelection={props.timeRangeSelection}
        xAxis={[{}]}
        xAxisGroup={{ enableGridlines: displayOptions?.showXGrid }}
        yAxis={[{ categories: yAxisCategories }]}
        yAxisGroup={{ enableGridlines: displayOptions?.showYGrid }}
        onSeriesVisibilityChange={(index, type) => {
          let _hiddenSeries: (string | number)[] = props.hiddenSeries
            ? [...props.hiddenSeries]
            : [];

          if (type === 'show') {
            _hiddenSeries = _hiddenSeries.filter(
              (_series) => _series !== index
            );
          } else {
            _hiddenSeries.push(index);
          }

          props.onHiddenSeriesChange?.(_hiddenSeries);
        }}
        selectedTheme={props.selectedTheme}
      />
    </ChartingErrorBoundary>
  );
});

PumpStatusChartV2.displayName = 'PumpStatusChartV2';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Old Chart
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PumpStatusChartV1 = forwardRef<any, ConnectedStatusChartProps>(
  (props, ref): JSX.Element | null => {
    const {
      series,
      timeRange = 24,
      displayOptions,
      dataRangeSelection,
      ...rest
    } = props;
    const dispatch = useDispatch();

    const [asyncState, setAsyncState] = useState<AsyncState>('idle');
    const [statusChartSeries, setStatusChartSeries] =
      useState<StatusChartSeries[]>();

    const [loading, setLoading] = useState(true);
    const [initialized, setInit] = useState(false);
    const fullSensorMap = selectSensorData();

    const sensorMap = useMemo(() => {
      const map = mapReduce([...series], fullSensorMap);
      return map;
    }, [fullSensorMap, series]);

    const shouldRefetchData = React.useMemo(() => {
      return [
        timeRange,
        ...series.map((sensor) => `${sensor.id} ${sensor.resolution}`),
      ].join('');
    }, [series]);

    useEffect(() => {
      setAsyncState('loading');

      const requestMeasureDataAndGenerateSeriesWithoutData = async () => {
        try {
          dispatch(
            getMeasureData({
              sensors: series.map((series) => series.id),
              resolution: Resolutions.Daily,
              overrideResolutions: series.reduce(
                (overrides, sensor) => ({
                  ...overrides,
                  [sensor.id]: sensor.resolution,
                }),
                {}
              ),
            })
          );
          setInit(true);
        } catch (error) {
          setAsyncState('rejected');
        }
      };

      requestMeasureDataAndGenerateSeriesWithoutData();
    }, [shouldRefetchData]);

    useEffect(() => {
      const requestSeriesGeneration = async () => {
        try {
          if (!loading && (!sensorMap || Object.keys(sensorMap).length === 0)) {
            setLoading(false);
            setStatusChartSeries([]);
            throw new Error();
          } else if (series && sensorMap) {
            const seriesWithData = await Promise.all(
              series.map((swd) => generateStatusChartSeries(swd, sensorMap))
            );
            setStatusChartSeries(seriesWithData);
            setAsyncState('resolved');
            setLoading(false);
          }
        } catch (error) {
          setAsyncState('rejected');
        }
      };

      requestSeriesGeneration();
    }, [sensorMap]);

    if (
      ['rejected', 'resolved'].includes(asyncState) &&
      (!statusChartSeries || statusChartSeries.length === 0) &&
      !loading
    ) {
      return initialized ? <NoResults /> : <></>;
    } else if (asyncState === 'resolved' && statusChartSeries) {
      return (
        <ChartingErrorBoundary chartProps={props}>
          <StatusChart
            {...rest}
            ref={ref}
            series={statusChartSeries}
            timeRange={timeRange}
            displayOptions={displayOptions}
            dataRangeSelection={dataRangeSelection}
          />
        </ChartingErrorBoundary>
      );
    }

    return null;
  }
);

PumpStatusChartV1.displayName = 'PumpStatusChartV1';

export default forwardRef(ConnectedStatusChart);
