import { Asset, PaginatedAssets, PaginatedAssetsResponse } from '@Utils/types';

import { AxiosResponse } from 'axios';
import service from '@Apis/asset.management.api';

export interface GetAssetResponse {
	asset: Asset;
}

export interface GetSensorResponse {
	sensor: Asset;
}

export const getAsset = (
	assetId: string,
	assetType: string,
): Promise<AxiosResponse<GetAssetResponse>> => {
	return service.get(`/assets/${assetId}?assetType=${assetType}`);
};

export const getSensor = (
	sensorId: string,
): Promise<AxiosResponse<GetSensorResponse>> => {
	return service.get(`sensors/${sensorId}`);
};

export const getAssetsPaginated = (
	systemType: string,
	assetType: string,
	limit: number,
	offset?: number,
	sort?: string,
	sortDescending?: boolean,
	filterModel?: PaginatedAssets['filterModel'],
	exclude?: PaginatedAssets['exclude'],
): Promise<PaginatedAssetsResponse> => {
	const body = {
		systemType,
		assetType,
		pagination: {
			limit,
			offset,
		},
		sort: {
			field: sort,
			direction: sortDescending ? 'desc' : 'asc',
		},
		filter: filterModel,
		exclude,
	};

	return service.post('/assets', body);
};

export const getSystemAssetTypes = (): Promise<unknown> => {
	return service.get('assets/systemAssetTypes');
};
