import { Asset } from '../core/types/asset.types';
import { ManufacturerCurve } from '../types';

export const transformAssetToManufacturerCurve = (
  curves: string[],
  assets: Asset[]
): ManufacturerCurve[] =>
  assets
    .filter((asset) => curves.includes(asset.curveId))
    .map(
      (asset) =>
        ({
          id: asset.curveId,
          alias: asset.curveId,
          data: asset.depthArray.map((x, index) => [
            x,
            asset.volumeArray[index],
          ]),
        }) as unknown as ManufacturerCurve
    );
