// import ErrorPage from '@Components/ErrorPage';
import React, { Component, ErrorInfo, ReactNode } from 'react';
import { WidgetError } from '@innovyze/stylovyze';
import { t } from 'i18next';

export interface ChartingErrorBoundaryProps {
  /**
   * JSX content
   */
  children: ReactNode;
  /**
   * message to display when an error is caught instead
   * of actual error
   */
  errorMessage?: string;
  /**
   * message to display when no error information is
   * returned from caught error
   */
  errorFallbackMessage?: string;
  /**
   * Props attached to chart that failed
   */
  chartProps: unknown;
}

interface ChartingErrorBoundaryState {
  hasError: boolean;
  error: Error;
  errorInfo: ErrorInfo;
}

/**
 * Error boundary for page
 */
class ChartingErrorBoundary extends Component<
  ChartingErrorBoundaryProps,
  ChartingErrorBoundaryState
> {
  static defaultProps = {
    errorFallbackMessage: 'There was an error loading this page',
  };

  constructor(args: ChartingErrorBoundaryProps) {
    super(args);
    this.state = {
      hasError: false,
      error: new Error(),
      errorInfo: { componentStack: '' },
    };
  }

  static getDerivedStateFromError(error: Error) {
    return {
      hasError: true,
      error,
      errorInfo: { componentStack: '' },
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      hasError: true,
      error: error || new Error(this.props.errorFallbackMessage),
      errorInfo,
    });
    console.error(
      'Charting Error Code 4000 - Unknown Error Occurred.\n\n Here is a full list of props: ',
      this.props.chartProps
    );
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <WidgetError
          message={t('There was an error displaying this information')}
        />
      );
    }

    return children;
  }
}

export default ChartingErrorBoundary;
