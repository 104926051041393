import {
	getObservations,
	getObservationsResolved,
	setContinuousOnly,
	setJointOnly,
	setStepOnly,
	clearFilters,
	deleteObservationsResolved,
	setGradeFilters,
	setTypeFilters,
	clearObservations,
} from '@Actions/observations.actions';
import { createReducer } from '@reduxjs/toolkit';
import { ObservationStoreState } from '@Types/observation.types';
import { fixupObservationsCounterAndTime } from '@Utils';
import { AnyAction } from 'redux';

export const initState: ObservationStoreState = {
	observations: [],
	status: 'loading',
};

interface ObservationReducer {
	[x: string]: (
		state: ObservationStoreState,
		action: AnyAction,
	) => ObservationStoreState | undefined;
}

const reducer: ObservationReducer = {
	[getObservations.toString()]: (state, action) => ({
		...state,
		status: 'loading',
		jointOnly: action.payload.jointOnly,
		stepOnly: action.payload.stepOnly,
		continuousOnly: action.payload.continuousOnly,
		gradeFilters: action.payload.gradeFilters,
		typeFilters: action.payload.typeFilters,
		observations: [],
	}),
	[getObservationsResolved.toString()]: (state, action) => ({
		...state,
		initialized: true,
		observations: fixupObservationsCounterAndTime(
			action.payload.observations,
		),
	}),
	[clearObservations.toString()]: state => ({
		...state,
		initialized: true,
		observations: [],
	}),
	[deleteObservationsResolved.toString()]: (state, action) => ({
		...state,
		observations: state.observations?.filter(
			observation =>
				action.payload.findIndex(
					(id: number) => id == observation?.conditionId,
				) < 0,
		),
	}),
	[setJointOnly.toString()]: (state, action) => ({
		...state,
		initialized: true,
		jointOnly: action.payload,
	}),
	[setContinuousOnly.toString()]: (state, action) => ({
		...state,
		initialized: true,
		continuousOnly: action.payload,
	}),
	[setStepOnly.toString()]: (state, action) => ({
		...state,
		initialized: true,
		stepOnly: action.payload,
	}),
	[setGradeFilters.toString()]: (state, action) => ({
		...state,
		initialized: true,
		gradeFilters: action.payload,
	}),
	[setTypeFilters.toString()]: (state, action) => ({
		...state,
		initialized: true,
		typeFilters: action.payload,
	}),
	[clearFilters.toString()]: state => ({
		...state,
		initialized: true,
		jointOnly: false,
		stepOnly: false,
		continuousOnly: false,
		gradeFilters: [],
		typeFilters: [],
	}),
};
export const observationReducer = createReducer(initState, reducer);

export default createReducer(initState, reducer);
