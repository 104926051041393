import { createAction } from '@reduxjs/toolkit';

import type {
  AsyncSensorData,
  SensorDataSource,
} from '../core/types/data.types';

export const fetchCachedSensorDataAction = createAction<SensorDataSource[]>(
  'summaryze/charts/sensorData/fetch/cached'
);

export const setSensorDataSourcesAction = createAction<SensorDataSource[]>(
  'summaryze/charts/sensorData/sources/set'
);

export const debounceFetchSensorDataAction = createAction(
  'summaryze/charts/sensorData/fetch/debounce'
);

export const getMissingSensorDataAction = createAction(
  'summaryze/charts/sensorData/getMissing'
);

export const fetchSensorDataAction = createAction<SensorDataSource>(
  'summaryze/charts/sensorData/fetch/missing'
);

export const setSensorDataRecordsAction = createAction<{
  record: AsyncSensorData;
  source: SensorDataSource;
}>('summaryze/charts/sensorData/records/set');
