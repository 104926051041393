import { createReducer } from '@reduxjs/toolkit';

import {
  fetchMeasureData,
  fetchMeasureDataResolved,
  fetchMeasureDataRejected,
  setDebounceSensors,
  emptyDebounceSensors,
} from '../actions';

import { RawMeasureData } from '../types';

export interface SensorData {
  data: RawMeasureData;
  unit: string;
}

export interface MeasureDataReducerChart {
  [sensor: string]: Record<string, SensorData>;
}

export type MeasureDataReducer = {
  debounceCharts: string[];
  sensorData: MeasureDataReducerChart;
};

export interface MeasureDataStoreState {
  measureData: MeasureDataReducer;
}

export const measureDataInitialState: MeasureDataReducer = {
  debounceCharts: [],
  sensorData: {},
};

export const measureDataReducer = createReducer<MeasureDataReducer>(
  measureDataInitialState,
  (builder) => {
    builder
      .addCase(setDebounceSensors, (state, { payload }) => {
        return {
          ...state,
          debounceCharts: [...state.debounceCharts, ...payload],
        };
      })
      .addCase(emptyDebounceSensors, (state) => {
        return {
          ...state,
          debounceCharts: [],
        };
      })
      .addCase(fetchMeasureData, (state) => {
        //loading and initialize?
        return state;
      })
      .addCase(fetchMeasureDataResolved, (state, { payload }) => {
        if (!payload) return state;
        return {
          ...state,
          sensorData: payload,
        };
      })
      .addCase(fetchMeasureDataRejected, (state) => {
        //how to handle errors?
        return state;
      });
  }
);
