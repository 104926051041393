import { CircularProgress, Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { Tab, TabWrapper, Tabs } from '@Components/Tabs';
import selectRiskConfigsList, {
	selectRiskConfigsListIsLoading,
} from '@Selectors/riskConfigsList.selectors';
import { InfoCard } from '@innovyze/stylovyze';
import { NoDetailsRisk } from '.';
import { RiskModelTabPanel } from './RiskModelTabPanel/RiskModelTabPanel.component';
import { RiskSummary } from './RiskSummary';
import { getRiskConfigsList } from '@Actions/riskConfigs.actions';
import { useDispatch } from 'react-redux';
import { useGlobalization } from '@Translations';
import { RiskConfigList } from '@Types/riskConfig.types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const MultiRiskDetails = ({
	adminFullAccess,
	assetId,
	assetType,
}: {
	adminFullAccess: boolean;
	assetId: string;
	assetType: string;
}): JSX.Element => {
	const { t } = useGlobalization();
	const [activeTab, setActiveTab] = React.useState(0);
	const dispatch = useDispatch();
	const riskConfigsList = selectRiskConfigsList();
	const isLoading = selectRiskConfigsListIsLoading();

	useEffect(() => {
		dispatch(getRiskConfigsList({ assetType }));
	}, []);

	const handleChange = (event: unknown, newTab: number) => {
		setActiveTab(newTab);
	};

	const riskTabs = (model: RiskConfigList, index: number) => {
		return (
			<Tab
				key={model.configId ?? model.name ?? index}
				label={
					<Grid container>
						<Grid
							item
							xs={12}
							style={{
								textAlign: 'start',
								alignItems: 'center',
							}}>
							{model.name ?? t('Default Risk model')}
						</Grid>
					</Grid>
				}
				id={`vertical-tab-${index}`}
				aria-controls={`vertical-tabpanel-${index}`}
			/>
		);
	};

	return (
		<Grid container spacing={3}>
			{!isLoading ? (
				riskConfigsList && riskConfigsList.length != 0 ? (
					<>
						<Grid item xs={12}>
							<InfoCard title={t('Risk Details')} fullHeight>
								<Grid container spacing={3}>
									<Grid item xs={12}>
										<TabWrapper>
											<Tabs
												orientation="vertical"
												variant="scrollable"
												value={activeTab}
												onChange={handleChange}
												aria-label="rehab-tabs">
												{riskConfigsList?.map(
													(model, index) =>
														riskTabs(model, index),
												)}
											</Tabs>
											{riskConfigsList.map(
												(model, index) => (
													<RiskModelTabPanel
														key={
															model.configId ??
															model.name ??
															index
														}
														activeTab={activeTab}
														index={index}
														assetId={assetId}
														assetType={assetType}
														configId={
															model.configId
														}
													/>
												),
											)}
										</TabWrapper>
									</Grid>
								</Grid>
							</InfoCard>
						</Grid>
						<Grid item xs={12}>
							<RiskSummary />
						</Grid>
					</>
				) : (
					<Grid item xs={12}>
						<InfoCard title={t('Risk Details')} fullHeight>
							<NoDetailsRisk adminFullAccess={adminFullAccess} />
						</InfoCard>
					</Grid>
				)
			) : (
				<Grid item xs={12}>
					<InfoCard title={t('Risk Details')} fullHeight>
						<CircularProgress />
					</InfoCard>
				</Grid>
			)}
		</Grid>
	);
};
