import { ThemeList } from '@Map/panel/types';
import { useMemo } from 'react';
import { ExtractTrans } from '@Translations/extraction';

const getThemeNameError = (
	themeName: string | ExtractTrans | undefined,
	themes: ThemeList | undefined,
	selectedTheme: string | undefined,
) => {
	if (!themeName) {
		return 'missing_name';
	}

	if (
		themes?.some(
			({ id, name }) => id !== selectedTheme && themeName === name,
		)
	) {
		return 'duplicate_name';
	}

	return null;
};

export type ThemeErrors = {
	themeName: 'missing_name' | 'duplicate_name' | null;
};

export function useThemeErrors(
	themeName: string | ExtractTrans | undefined,
	themes: ThemeList | undefined,
	selectedTheme: string | undefined,
): ThemeErrors {
	return useMemo(
		() => ({
			themeName: getThemeNameError(themeName, themes, selectedTheme),
		}),
		[selectedTheme, themeName, themes],
	);
}
