/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as Chart from '../../core/chart';
import {
  ChartDataError,
  UnknownError,
} from '../../core/chart-data/chart-data.utils';

import type { Series } from 'highcharts';
import type { MutableRefObject } from 'react';
import type {
  PumpRuntimeChartRootProps,
  PumpRuntimeChartSeriesProps,
} from './pumpRuntime-chart.components';
import { useGlobalization } from '../../../i18n';
import { getTheme } from '../../core/utils/theme-utils';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Series
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const type = 'column';

export const useSeriesCreateRemove = (
  id: MutableRefObject<string>,
  props: PumpRuntimeChartSeriesProps
): void => {
  Chart.useInstanceEffect((instances) => {
    instances.get().forEach((instance) => {
      instance.addSeries({
        id: id.current,
        type: type,
        name: props.name,
        color: props.color,
        visible: !props.hidden,
      });
    });
  }, []);

  Chart.useInstanceLayoutEffect((instances) => {
    return () => {
      instances.get().forEach((instance) => {
        const series = instance.get(id.current) as Series | undefined;
        series?.remove();
      });
    };
  }, []);
};

export const useSeriesData = (
  id: MutableRefObject<string>,
  props: PumpRuntimeChartSeriesProps
): void => {
  Chart.useInstanceEffect(
    (instances) => {
      instances.get().forEach((instance) => {
        const series = instance.get(id.current) as Series | undefined;
        const data = props.data ?? [];

        series?.update({
          type: type,
          data,
        });
      });
    },
    [id, props.data]
  );
};

export const useSeriesColor = (
  id: MutableRefObject<string>,
  props: PumpRuntimeChartSeriesProps
): void => {
  Chart.useInstanceEffect(
    (instances) => {
      instances.get().forEach((instance) => {
        const series = instance.get(id.current) as Series | undefined;
        series?.update({
          type: type,
          color: props.color,
        });
      });
    },
    [id, props.color, props.selectedTheme]
  );
};

export const useSeriesName = (
  id: MutableRefObject<string>,
  props: PumpRuntimeChartSeriesProps
): void => {
  Chart.useInstanceEffect(
    (instances) => {
      instances.get().forEach((instance) => {
        const series = instance.get(id.current) as Series | undefined;
        series?.update({
          type: type,
          name: props.name,
        });
      });
    },
    [id, props.name]
  );
};

export const useSeriesVisibility = (
  id: MutableRefObject<string>,
  props: PumpRuntimeChartSeriesProps
): void => {
  Chart.useInstanceEffect(
    (instances) => {
      const visible = !props.hidden;

      instances.get().forEach((instance) => {
        const series = instance.get(id.current) as Series | undefined;
        series?.update({
          type: type,
          visible,
        });
      });

      instances.get(`stacked-series-${props.index}`).forEach((instance) => {
        const height = visible ? undefined : 0;

        instance.update({
          xAxis: { height, visible },
          yAxis: { height, visible },
        });

        const parent = instance.container.parentElement?.parentElement;

        if (parent) {
          parent.style.position = visible ? 'relative' : 'absolute';
          parent.style.left = visible ? '0' : '-99999px';
          window.dispatchEvent(new Event('resize'));
        }
      });
    },
    [id, props.hidden]
  );
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Categories
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const useCategories = (props: PumpRuntimeChartRootProps): void => {
  Chart.useInstanceEffect(
    (instances) => {
      instances.get(['overlay']).forEach((instance) => {
        instance.update({ xAxis: { categories: props.categories } });
      });
    },
    [props.categories?.join(',')]
  );
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Y AXis label
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const useYAxis = (props: PumpRuntimeChartRootProps): void => {
  const title = `${props.displayType
    ?.charAt(0)
    .toUpperCase()}${props.displayType?.slice(1)}`;

  const min = props.displayType === 'percentage' ? 0 : undefined;
  const max = props.displayType === 'percentage' ? 100 : undefined;

  Chart.useInstanceEffect(
    (instances) => {
      instances.get(['overlay']).forEach((instance) => {
        instance.update({
          yAxis: {
            title: { text: title },
            min,
            max,
          },
        });
      });
    },
    [props.displayType]
  );
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * GridLines
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const useXAxisGridlines = (props: PumpRuntimeChartRootProps): void => {
  Chart.useInstanceEffect(
    (instances) => {
      instances.get(['overlay', /stacked-series/]).forEach((instance) => {
        instance.update({
          xAxis: {
            gridLineWidth: props.xAxis?.enableGridlines ? 1 : 0,
          },
        });
      });
    },
    [props.xAxis?.enableGridlines]
  );
};

export const useYAxisGridlines = (props: PumpRuntimeChartRootProps): void => {
  Chart.useInstanceEffect(
    (instances) => {
      instances.get(['overlay', /stacked-series/]).forEach((instance) => {
        instance.update({
          yAxis: {
            gridLineWidth: props.yAxis?.enableGridlines ? 1 : 0,
          },
        });
      });
    },
    [props.yAxis?.enableGridlines]
  );
};

export const useStatus = (props: PumpRuntimeChartRootProps): void => {
  const { t } = useGlobalization();

  Chart.useInstanceEffect(
    (instances) => {
      instances.get('overlay').forEach((instance) => {
        if (props.status === 'loading') {
          instance.showLoading(t('Loading'));
        } else if (props.status === 'resolved') {
          instance.hideLoading();
        } else if (props.status === 'rejected') {
          instance.showLoading(t('No Data'));
        } else {
          instance.hideLoading();
        }
      });
    },
    [props.status, props.error, t]
  );
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Theme
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const useTheme = (props: PumpRuntimeChartRootProps): void => {
  Chart.useInstanceEffect(
    (instances) => {
      const theme = getTheme(props.selectedTheme ?? 'Default');
      instances.get(['overlay', /stacked-series/]).forEach((instance) => {
        instance.update({...theme});
      });
    },
    [props.selectedTheme]
  );
};
