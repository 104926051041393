import { getApiEnvironment, getService } from '@innovyze/stylovyze';
import axios, { AxiosRequestConfig } from 'axios';
import { apiConfig } from './config.api';
import cookies from 'browser-cookies';
import axiosRetry from 'axios-retry';

const options: AxiosRequestConfig = {
  baseURL: getService('massBalance', apiConfig, getApiEnvironment()),
  timeout: 10000,
};

const massBalanceApi = axios.create(options);

axiosRetry(massBalanceApi, {
  retries: 3,
  retryDelay: (retryCount) => {
    return retryCount * 500;
  },
});

massBalanceApi.interceptors.request.use(
  (config) => {
    config.headers.Authorization = 'Bearer ' + cookies.get('auth0.user.token');
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export default massBalanceApi;
