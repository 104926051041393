import { palette } from '@innovyze/stylovyze';
import Highcharts from 'highcharts';
import { ManufacturerCurve } from '../../types';

export const processManufacturerCurve = (
  curve: ManufacturerCurve
): Highcharts.SeriesOptionsType => ({
  type: 'line',
  data: curve.data,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  name: curve.alias,
  states: {
    inactive: {
      opacity: 1,
    },
  },
  tooltip: {
    // eslint-disable-next-line quotes
    headerFormat: "<b>Manufacturer's Curve</b><br>",
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    pointFormat: `${curve.alias} pressure: {point.x} <br>${curve.alias} flow: {point.y}`,
  },
  color: palette.secondary.main,
});
