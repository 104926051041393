import { AnyAction, createReducer } from '@reduxjs/toolkit';
import {
	AssetDetailMapInsertResolvedResponse,
	AssetManholeSummaryResolvedResponse,
	AssetPipeSummaryResolvedResponse,
	AssetType,
	AssetTypeManhole,
	AssetTypeUnknown,
	GeneralOptions,
	GetFullInspResolvedParams,
	InspectionStandard,
	MapInsert,
	MapLocation,
	MapSpatialInsert,
	RehabAction,
	RehabDecisionTree,
	SpatialLayer,
} from '@Types';
import { MapRiskLayer, MapStoreState } from '@Types/map.types';
import { BackgroundTypes, MapPosition } from '@innovyze/inno-map';
import {
	Extent,
	Geometry,
	centreExtent,
	fixLonLatExtent,
	geometriesExtent,
	haversine,
	PointType,
} from '@innovyze/shared-utils';
import {
	configLayers,
	dataServices,
	defaultInspectionSources,
	dummyToken,
	generalOptionsDefault,
	inspectionSources,
	layerNameToId,
	minPositionExtent,
	minPositionSearchRadius,
	otherOptions,
	tileOptions,
	toExtent,
	zoomSelectionPoint,
	zoomSelectionDefault,
	zoomShowInspections,
} from '@Utils';
import {
	getAssetManhole,
	getAssetManholeResolved,
} from '@Actions/assetManhole.actions';
import { getAssetPipe, getAssetPipeResolved } from '@Actions/assetPipe.actions';
import {
	getFullInsp,
	getFullInspResolved,
} from '@Actions/fullInspection.actions';
import {
	getMapRehabLayers,
	getMapRehabLayersRejected,
	getMapRehabLayersResolved,
	mapMain,
	mapStateUrl,
} from '@Actions/map.actions';
import {
	getAssetDetail,
	getAssetDetailMapInsertResolved,
	getMapRiskLayers,
	getMapRiskLayersRejected,
	getMapRiskLayersResolved,
	getSpatialLayersResolved,
	mapMainExtent,
	mapMainPosition,
	mapMainSelection,
	mapSpatialTilesSourceHide,
} from '@Actions';
import { status200, statusDefault, statusLoading } from './paginationUtils';

import { SelectedAsset } from '@innovyze/inno-map';
import { getMapLayerId } from '@innovyze/map-components';

// If the inspection geometry is missing the condition geometries might do the job.
const getAllGeometries = (params: GetFullInspResolvedParams): Geometry[] => {
	const geoms: Geometry[] = [];

	(params?.inspection ?? []).forEach(insp => {
		if ('geometry' in insp && insp.geometry) {
			geoms.push(insp.geometry);
		}

		if ('conditions' in insp) {
			insp.conditions?.forEach(c => {
				if ('geometry' in c && c.geometry) {
					geoms.push(c.geometry);
				}
			});
		}
	});

	return geoms;
};

const mapPosition = (
	extent: Extent | undefined,
	zoom: number,
): MapPosition | undefined => {
	const centre = centreExtent(extent);
	return centre
		? {
				center: centre,
				zoom: zoom,
		  }
		: undefined;
};

const mapLocation = (
	extent: Extent | undefined,
	zoom: number,
): MapLocation | undefined => {
	const ex = fixLonLatExtent(extent, minPositionExtent);
	return extent
		? {
				position: mapPosition(ex, zoom),
				radius: ex
					? Math.max(
							minPositionSearchRadius,
							haversine(ex[0], ex[1], ex[2], ex[3]),
					  )
					: undefined,
				extent: ex,
		  }
		: undefined;
};

const inspectionInsert = (
	standard: InspectionStandard,
	background: BackgroundTypes,
	params: GetFullInspResolvedParams,
): MapInsert => {
	const geoms = getAllGeometries(params);
	const extent = geometriesExtent(geoms);

	const location = mapLocation(extent, zoomShowInspections);

	const options = {
		generalOptions: { ...generalOptionsDefault, noMinZoom: true },
		tilesSource: tileOptions,
		showRisk: false,
		tilesSourceHide: false,
		inspectionSources: inspectionSources(standard),
	};

	return {
		options,
		background,
		location: location,
		// Following for diagnotics only, will be generated on fly in selectors
		// with valid token
		sources: dataServices(dummyToken, options, location),
		layers: configLayers(dummyToken, options),
	};
};

const assetMapId = (assetType: string, assetId: string): string => {
	return `${assetType}#${assetId}`;
};

const assetMapLayer = (
	assetId: string,
	assetType: string,
	systemType?: string,
	layerId?: string,
): SelectedAsset => {
	const layer = systemType ? getMapLayerId(assetType, systemType) : layerId;

	return {
		layerId: layer ?? '',
		id: assetMapId(assetType, assetId),
	};
};

const assetGeometry = (
	params:
		| AssetPipeSummaryResolvedResponse
		| AssetManholeSummaryResolvedResponse,
) => params?.asset?.geometry ?? undefined;

const assetInsert = (
	assetId: string,
	assetType: string,
	background: BackgroundTypes,
	params:
		| AssetPipeSummaryResolvedResponse
		| AssetManholeSummaryResolvedResponse,
): MapInsert => {
	const geom = assetGeometry(params);
	const extent = geometriesExtent(geom ? [geom] : []);

	const zoom =
		assetType === AssetTypeManhole
			? zoomSelectionPoint
			: zoomSelectionDefault; // default to pipe zoo

	const location = mapLocation(extent, zoom);

	const options = {
		generalOptions: generalOptionsDefault,
		tilesSource: tileOptions,
		showRisk: false,
		tilesSourceHide: false,
	};

	return {
		options,
		background,
		location: location,
		selected: [
			assetMapLayer(assetId, assetType, params.asset.layerSystemType),
		],
		// Following for diagnotics only, will be generated on fly in selectors
		// with valid token
		sources: dataServices(dummyToken, options, location),
		layers: configLayers(dummyToken, options),
	};
};

const assetInsertGeneric = (
	assetId: string,
	assetType: AssetType,
	background: BackgroundTypes,
	params: AssetDetailMapInsertResolvedResponse,
): MapInsert => {
	const extent = geometriesExtent(params.geometry ? [params.geometry] : []);

	const zoom =
		params.geometry.type === PointType
			? zoomSelectionPoint
			: zoomSelectionDefault;

	const location = mapLocation(extent, zoom);

	const options = {
		generalOptions: generalOptionsDefault,
		tilesSource: tileOptions,
		showRisk: false,
		tilesSourceHide: false,
	};

	return {
		options,
		background,
		location: location,
		selected: [
			assetMapLayer(assetId, assetType, undefined, params.layerId),
		],
		// Following for diagnotics only, will be generated on fly in selectors
		// with valid token
		sources: dataServices(dummyToken, options, location),
		layers: configLayers(dummyToken, options),
	};
};

const positionSpatial = (
	extent: Extent | undefined,
): MapPosition | undefined => {
	if (!extent || extent.length < 4) {
		return undefined;
	}

	const width = haversine(extent[0], extent[1], extent[2], extent[1]);

	// Would like to use known bounds on map but currently the bounds calculated in the
	// amSpatial tiling overrides any map API bounds setting.
	// So have to use Zoom and centre. Centre is trivial from the extent but
	// zoom is truely a can of worms.
	// Rather than be too clever have just chosen 2 explicit zoom values
	//   16 - max value for extent width < 1km
	//        catches small grouping and single points well
	//   12 - for extent width < 15km
	//   undefined - max value - defaults to 'amSpatial' tile endpoint bounds

	if (width > 15000) {
		return undefined;
	}

	const zoom = width < 1000 ? 16 : 12;

	const lon = 0.5 * (extent[0] + extent[2]);
	const lat = 0.5 * (extent[1] + extent[3]);

	return {
		center: [lon, lat],
		zoom: zoom,
	};
};

const spatialLayerInitial: SpatialLayer = {
	_ID: '',
	TENANT_ID: '',
	LAYER_NAME: '',
	EXTENT: undefined,
	GEOMETRY_TYPE: undefined,
};

export const findSpatialLayer = (
	layerName: string,
	layers: SpatialLayer[],
): SpatialLayer => {
	const layer = layers.filter(
		l => l.LAYER_NAME.toLowerCase() === layerName.toLowerCase(),
	);

	return layer.length > 0 ? layer[0] : spatialLayerInitial;
};

const hiddenLayers = (
	visibleLayer: SpatialLayer,
	layers: SpatialLayer[],
): string[] =>
	layers
		.filter(l => l.LAYER_NAME !== visibleLayer.LAYER_NAME)
		.map(l => `other/${layerNameToId(l.LAYER_NAME)}`);

const spatialInsert = (
	layer: SpatialLayer,
	background: BackgroundTypes,
	layers: SpatialLayer[],
	tilesSourceHide: boolean,
): MapInsert => {
	const extent = toExtent(layer?.EXTENT);
	const position = positionSpatial(extent);

	const location: MapLocation = {
		position,
		extent,
	};

	const options = {
		generalOptions: generalOptionsDefault,
		tilesSource: tilesSourceHide ? undefined : tileOptions,
		otherSource: otherOptions,
		showRisk: false,
		tilesSourceHide,
		useSpecifiedSpatialLayer: true,
	};

	return {
		options,
		background,
		location: location,
		// Following for diagnotics only, will be generated on fly in selectors
		// with valid token
		sources: dataServices(dummyToken, options, location),
		layers: configLayers(dummyToken, options, [layer]),
		hiddenLayers:
			options?.useSpecifiedSpatialLayer ?? false
				? undefined
				: hiddenLayers(layer, layers),
	};
};

const extentRadius = (options: GeneralOptions, extent: Extent): number => {
	let rad = options.radiusDefault;

	const lon1 = extent[0] + 0.5 * (extent[2] - extent[0]);
	const lat1 = extent[1] + 0.5 * (extent[3] - extent[1]);
	rad = haversine(lat1, lon1, extent[3], extent[2]);
	rad *= options.boundMargin;

	return Math.ceil(rad);
};

// seems the backend is returning 'pipe' by default for 'Sanitary Sewer' systems
// also some rehab model the asset type is returned as 'PIPE'
const correctAssetType = (assetType: string, systemType: string): string => {
	if (systemType === 'Sanitary Sewer' && assetType.match(/^(ww)?pipe$/i)) {
		return 'wwPipe';
	} else if (assetType.match(/^pipe$/i)) {
		return 'pipe';
	}
	return assetType;
};

const correctRehabDetails = (rehab: RehabDecisionTree) => {
	const systemType = rehab.systemType ?? 'Sanitary Sewer';
	const assetType = correctAssetType(rehab.assetType ?? 'wwPipe', systemType);
	return {
		layerName: rehab.name,
		id: rehab._id,
		lastRun: rehab.lastRun ?? '',
		assetType,
		systemType,
		layers: rehab.actions?.map((a: RehabAction) => ({
			layerName: a.actionId,
			id: a._id,
		})),
	};
};

export const mapInsertInitial: MapInsert = {
	options: {
		generalOptions: generalOptionsDefault,
		showRisk: false,
		tilesSourceHide: false,
	},
	background: BackgroundTypes.Streets,
};

export const initialState: MapStoreState = {
	main: {
		background: BackgroundTypes.Streets, // deprecated to insert
		selected: [], // deprecated to insert
		hiddenLayers: [], // deprecated to insert
		url: '',
		byTenant: {},
		insert: {
			options: {
				generalOptions: generalOptionsDefault,
				showRisk: true,
				tilesSourceHide: false,
				tilesSource: tileOptions,
				inspectionSources: defaultInspectionSources,
				otherSource: otherOptions,
			},
			background: BackgroundTypes.Streets,
		},
	},
	assetInsert: {
		assetId: '',
		assetType: AssetTypeUnknown,
		insert: { ...mapInsertInitial },
		layerId: '',
	},
	inspectionInsert: {
		inspectionId: '',
		standard: InspectionStandard.PACP,
		insert: { ...mapInsertInitial },
	},
	spatialTilesSourceHide: false,
	spatialInserts: [],
	risk: {
		layers: [],
		status: statusDefault(),
	},
	rehab: {
		layers: [],
		status: statusDefault(),
	},
};

interface MapReducer {
	[x: string]: (
		state: MapStoreState,
		action: AnyAction,
	) => MapStoreState | undefined;
}

const reducer: MapReducer = {
	[mapMain.toString()]: (state, action) => ({
		...state,
		main: {
			...action.payload,
		},
	}),
	[mapStateUrl.toString()]: (state, action) => ({
		...state,
		main: {
			...state.main,
			...action.payload,
			byTenant: {
				...state.main.byTenant,
				[action.payload.tenantId]: action.payload,
			},
		},
	}),
	[getFullInsp.toString()]: (state, action) => ({
		...state,
		inspectionInsert: {
			inspectionId: action.payload.inspectionId,
			standard: action.payload.standard,
			insert: { ...mapInsertInitial },
		},
	}),
	[getFullInspResolved.toString()]: (state, action) => ({
		...state,
		inspectionInsert: {
			...state.inspectionInsert,
			insert: {
				...inspectionInsert(
					state.inspectionInsert.standard,
					state.main.background,
					action.payload,
				),
			},
		},
	}),
	[getAssetPipe.toString()]: (state, action) => ({
		...state,
		assetInsert: {
			assetId: action.payload.assetId,
			assetType: action.payload.assetType,
			insert: { ...mapInsertInitial },
		},
	}),
	[getAssetPipeResolved.toString()]: (state, action) => ({
		...state,
		assetInsert: {
			...state.assetInsert,
			insert: {
				...assetInsert(
					state.assetInsert.assetId,
					state.assetInsert.assetType,
					state.main.background,
					action.payload,
				),
			},
		},
	}),
	[getAssetManhole.toString()]: (state, action) => ({
		...state,
		assetInsert: {
			assetId: action.payload.assetId,
			assetType: action.payload.assetType,
			insert: { ...mapInsertInitial },
		},
	}),
	[getAssetManholeResolved.toString()]: (state, action) => ({
		...state,
		assetInsert: {
			...state.assetInsert,
			insert: {
				...assetInsert(
					state.assetInsert.assetId,
					state.assetInsert.assetType,
					state.main.background,
					action.payload,
				),
			},
		},
	}),
	[getAssetDetail.toString()]: (state, action) => ({
		...state,
		assetInsert: {
			systemType: action.payload.systemType,
			assetId: action.payload.assetId,
			assetType: action.payload.assetType,
			insert: { ...mapInsertInitial },
		},
	}),
	[getAssetDetailMapInsertResolved.toString()]: (state, action) => ({
		...state,
		assetInsert: {
			...state.assetInsert,
			insert: {
				...assetInsertGeneric(
					state.assetInsert.assetId,
					state.assetInsert.assetType,
					state.main.background,
					action.payload,
				),
			},
		},
	}),
	[mapSpatialTilesSourceHide.toString()]: (state, action) => ({
		...state,
		spatialTilesSourceHide: action.payload,
		// this will need to be improved if we start calling action
		// getSpatialLayersResolved before mapSpatialTilesSourceHide
		spatialInserts: state.spatialInserts.map((s: MapSpatialInsert) => ({
			...s,
			insert: {
				...s.insert,
				tilesSourceHide: action.payload,
			},
		})),
	}),
	[getSpatialLayersResolved.toString()]: (state, action) => ({
		...state,
		spatialInserts: action.payload.map((s: SpatialLayer) => {
			const layer = findSpatialLayer(s.LAYER_NAME, action.payload);
			return {
				layer,
				insert: {
					...spatialInsert(
						layer,
						state.main.background,
						action.payload,
						state.spatialTilesSourceHide,
					),
				},
			};
		}),
	}),
	[mapMainPosition.toString()]: (state, action) => {
		const location = {
			...state.main.insert.location,
			position: action.payload,
		};
		return {
			...state,
			main: {
				...state.main,
				insert: {
					...state.main.insert,
					location,
					// Following for diagnotics only, will be generated on fly in selectors
					// with valid token
					sources: dataServices(
						dummyToken,
						state.main.insert.options,
						location,
					),
					layers: configLayers(dummyToken, state.main.insert.options),
				},
			},
		};
	},
	[mapMainExtent.toString()]: (state, action) => {
		const location = {
			...state.main.insert.location,
			extent: action.payload,
			radius: extentRadius(
				state.main.insert.options.generalOptions,
				action.payload,
			),
		};
		return {
			...state,
			main: {
				...state.main,
				insert: {
					...state.main.insert,
					location,
					// Following for diagnotics only, will be generated on fly in selectors
					// with valid token
					sources: dataServices(
						dummyToken,
						state.main.insert.options,
						location,
					),
					layers: configLayers(dummyToken, state.main.insert.options),
				},
			},
		};
	},
	[mapMainSelection.toString()]: (state, action) => ({
		...state,
		main: {
			...state.main,
			insert: {
				...state.main.insert,
				selected: action.payload,
			},
		},
	}),
	[getMapRiskLayers.toString()]: state => ({
		...state,
		risk: {
			layers: [],
			status: statusLoading(),
		},
	}),
	[getMapRiskLayersResolved.toString()]: (state, action) => ({
		...state,
		risk: {
			layers: [...action.payload].sort(
				(a: MapRiskLayer, b: MapRiskLayer) =>
					a.name.localeCompare(b.name),
			),
			status: status200(),
		},
	}),
	[getMapRiskLayersRejected.toString()]: (state, action) => ({
		...state,
		risk: {
			layers: [],
			status: action.payload,
		},
	}),
	[getMapRehabLayers.toString()]: state => ({
		...state,
		rehab: {
			layers: [],
			status: statusLoading(),
		},
	}),
	[getMapRehabLayersResolved.toString()]: (state, action) => ({
		...state,
		rehab: {
			layers: [...action.payload].map((r: RehabDecisionTree) =>
				correctRehabDetails(r),
			),
			status: status200(),
		},
	}),
	[getMapRehabLayersRejected.toString()]: (state, action) => ({
		...state,
		rehab: {
			layers: [],
			status: action.payload,
		},
	}),
};

export default createReducer(initialState, reducer);
