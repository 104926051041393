import * as globalActions from '@Actions/global';

import { put, takeLatest } from 'redux-saga/effects';

import { clearAssets } from '@Actions/asset';
import { clearInspections } from '@Actions/inspection';
import { clearMap } from '@Actions/map';
import { clearRisk } from '@Actions/risk';

function* clearAllSaga() {
	yield put(clearAssets());
	yield put(clearInspections());
	yield put(clearRisk());
	yield put(clearMap());
}

function* watchClearAll() {
	yield takeLatest(globalActions.clearAll, clearAllSaga);
}

export default [watchClearAll()];
