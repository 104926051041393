import styled from 'styled-components';
import { StyledStackedChart } from '../../../core/components/StackedChart';

import {
  massBalanceChartFont,
  massBalanceChartPalette,
} from './MassBalanceChart.utils';

export const MassBalanceChartWrapper = styled.div`
  background-color: ${massBalanceChartPalette.mainBackground};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1rem;
  width: 100%;
`;

export const SnapshotContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SnapshotToggle = styled.div`
  all: unset;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${massBalanceChartPalette.secondaryText};
  background-color: ${massBalanceChartPalette.statsBackground};
  padding: 10px 10px 10px 16px;
  align-self: flex-end;

  .lctb-icon {
    width: 1.5rem;
    height: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .lctb-label {
    font-family: ${massBalanceChartFont};
    font-size: 0.8rem;
    font-weight: 500;
    text-transform: uppercase;
    margin-right: 1rem;
  }
`;

export const StatsContainer = styled.div`
  align-items: stretch;
  background-color: ${massBalanceChartPalette.statsBackground};
  color: ${massBalanceChartPalette.text};
  display: flex;
  font-family: ${massBalanceChartFont};
  justify-content: space-between;
  margin-bottom: 5px;
  padding: 20px;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  box-sizing: border-box;
`;

export const StatsLabel = styled.div`
  font-weight: bolder;
  margin-bottom: 10px;
`;

export const StatsValue = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
`;

export const StatsItem = styled.div<{ hasNoData: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;

  &:not(:last-child) {
    margin-right: 20px;
  }

  ${StatsValue} {
    font-size: ${({ hasNoData }) => (hasNoData ? '12px' : '16px')};
  }
`;

export const ChartContainer = styled.div`
  flex: 1 1 auto;
  min-height: 0;
  width: 100%;
  margin-top: 20px;

  ${StyledStackedChart.Stack} {
    padding: 0 !important;
  }

  @media print {
    height: 100%;
  }
`;
