import { TimeRangeSelection } from '../_next/core/_insight-chart';
import { ResponseDataEntry } from '../_next/core/time-series-data-old';
import { HiddenSeries, RangeSelection, Readings, Resolutions } from './index';
import { SensorType } from '@innovyze/stylovyze';

export enum ComparisonSensorType {
  'Total chlorine' = 'Total chlorine',
  'Temperature' = 'Temperature',
  'pH' = 'pH',
  'TOC' = 'TOC',
  'Conductivity' = 'Conductivity',
  'UV 254' = 'UV 254',
  'Turbidity' = 'Turbidity',
}

export interface ComparisonTableDisplayOptions {
  showUnits?: boolean;
  swapTable?: boolean;
  showVerticalLines?: boolean;
}

export interface ComparisonTableSeriesConnected {
  sensorId: string;
  sensorGroupId: string;
  alias?: string;
  reading: Readings;
  type: SensorType;
  typeAlias?: string;
  customData?: ResponseDataEntry;
}

export interface ComparisonTableGroup {
  id: string;
  alias: string;
}

export interface ComparisonTableConnected {
  id?: string;
  series: ComparisonTableSeriesConnected[];
  sensorGroups: ComparisonTableGroup[];
  resolution: Resolutions;
  displayOptions?: Partial<ComparisonTableDisplayOptions>;
  timeRangeSelection?: TimeRangeSelection;
  hiddenSeries?: HiddenSeries;
  onRangeSelectionChange?: (rangeSelection: RangeSelection) => void;
  onHiddenSeriesChange?: (hiddenSeries: HiddenSeries) => void;
  onCellClick?: (
    row: ComparisonTableSeriesConnected & { resolution: Resolutions }
  ) => void;
}
