import { TileJson } from '@Map/services/types';
import { AdaptorTypes } from '@Map/types';
import { ConfigLayer } from '@Root';
import ExternalConfigAdaptor from './ExternalConfigAdaptor';

export default class TileJsonConfigAdaptor extends ExternalConfigAdaptor {
	static _type: AdaptorTypes = AdaptorTypes.tilesJson;

	converter = (data: TileJson): Partial<ConfigLayer> => {
		const layers: ConfigLayer['layers'] = data.vector_layers.map(layer => ({
			layerName: layer.description || this._layernameFromId(layer.id),
			id: layer.id,
			tileLayerName: layer.id,
			type: this._getTypeFromGeometry(layer.geometry),
		}));

		return {
			layers,
		};
	};

	private _getTypeFromGeometry(geometry: string) {
		switch (geometry) {
			case 'LineString':
				return 'line';
			case 'Polygon':
				return 'polygon';
			case 'Point':
			default:
				return 'symbol';
		}
	}

	private _layernameFromId(id: string) {
		return id.replaceAll(/([^a-z]+)/gi, ' ');
	}
}

export const tilesJsonConfigCreator = TileJsonConfigAdaptor.creator.bind(
	TileJsonConfigAdaptor,
);
