import * as Highcharts from 'highcharts/highstock';
import { defaultExportingOptions, defaultStockChartConfig } from '../utils';
import { t } from 'i18next';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
export function pad(number, length) {
  let str = '' + number;
  while (str.length < length) {
    str = '0' + str;
  }
  return str;
}

const defaultConfig = defaultStockChartConfig(t);

const fontFamily =
  'Open Sans, Noto Sans JP, Noto Sans CJK KR, Noto Sans Arabic UI, Noto Sans Devanagari UI, Noto Sans Hebrew, Noto Sans Thai UI, Helvetica, Arial, sans-serif, Noto Sans';

export const multiDefaultConfig: Highcharts.Options = {
  ...defaultConfig,
  chart: {
    height: null,
    spacingBottom: 0,
    plotBorderWidth: 1,
    zoomType: 'x',
    resetZoomButton: {
      theme: { style: { display: 'none' } },
    },
  },
  plotOptions: {
    ...defaultConfig.plotOptions,
    line: {
      marker: {
        enabled: false,
      },
    },
  },
  legend: {
    enabled: false,
  },
  yAxis: [
    {
      lineWidth: 1,
      opposite: false,
    },
  ],
  xAxis: {
    ...defaultConfig.xAxis,
    visible: true,
  },
  tooltip: {
    ...defaultConfig.tooltip,
    hideDelay: 0,
  },
  navigator: {
    enabled: false,
  },
  rangeSelector: {
    enabled: false,
    selected: 0,
  },
  credits: {
    enabled: false,
  },
};

export const multiRangeSelectorDefaultConfig: Highcharts.Options = {
  credits: { enabled: false },
  exporting: { enabled: false },
  legend: { enabled: false },
  navigator: { enabled: false, ...defaultConfig.navigator },
  xAxis: { visible: false },
  yAxis: { height: 0, visible: false },
  chart: { height: null },
  scrollbar: {
    enabled: true,
    height: 0,
    buttonArrowColor: 'rgba(1, 1, 1, 0)',
  },
  plotOptions: {
    series: {
      animation: false,
      opacity: 0,
    },
  },
  rangeSelector: {
    ...defaultConfig.rangeSelector,
  },
};

export const multiNavigatorDefaultConfig: Highcharts.Options = {
  credits: { enabled: false },
  legend: { enabled: true },
  rangeSelector: { enabled: false },
  xAxis: { visible: false },
  yAxis: { height: 0, visible: false },
  chart: { height: null },
  exporting: defaultExportingOptions,
  plotOptions: {
    ...defaultConfig.plotOptions,
    series: {
      animation: false,
      showInNavigator: true,
    },
  },
  navigator: {
    ...defaultConfig.navigator,
  },
  scrollbar: {
    enabled: true,
    height: 0,
    buttonArrowColor: 'rgba(1, 1, 1, 0)',
  },
};
