import { AttributeTypes, CBAttributes } from '@Map/types';
import {
	FormControl,
	FormHelperText,
	MenuItem,
	Select,
	SelectChangeEvent,
} from '@mui/material';
import { Progress, SelectLabel } from './AttributePicker.styles';

import React from 'react';
import { TFunction } from 'i18next';
import { useGlobalization } from '@Translations/useGlobalization';

interface AttributePickerProps {
	attributes?: CBAttributes[];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	selectedAttribute?: '' | any;
	selectedAttributeType?: AttributeTypes;
	onAttributeChange: (e: SelectChangeEvent<{ value: unknown }>) => void;
	attributeValuesCount?: number;
	colorByMaxValues?: number;
}

const getHelperText = (
	t: TFunction,
	selectedAttribute?: string,
	attributeValuesCount?: number,
	colorByMaxValues?: number,
): string | undefined => {
	if (!selectedAttribute) return;
	if (attributeValuesCount == null) {
		return t('Getting values for this attribute...', {
			context:
				'Helper text for attribute picker in layer properties editor form',
		});
	} else if (!attributeValuesCount) {
		return t('No values have been found for this attribute', {
			context:
				'Helper text for attribute picker in layer properties editor form',
		});
	} else if (colorByMaxValues && attributeValuesCount > colorByMaxValues) {
		return t(
			'Maximum number of {{colorByMaxValues}} values for this attribute has been exceeded, unable to provide styling',
			{
				context:
					'Helper text for attribute picker in layer properties editor form',
				colorByMaxValues,
			},
		);
	}
	return t('Found values for this attribute', {
		context:
			'Helper text for attribute picker in layer properties editor form',
	});
};

export const AttributePicker = ({
	attributes = [],
	selectedAttribute,
	selectedAttributeType,
	onAttributeChange,
	attributeValuesCount,
	colorByMaxValues,
}: AttributePickerProps): JSX.Element => {
	const { t } = useGlobalization();
	const helperText =
		selectedAttributeType !== AttributeTypes.range
			? getHelperText(
					t as TFunction,
					selectedAttribute,
					attributeValuesCount,
					colorByMaxValues,
			  )
			: undefined;
	return (
		<>
			<FormControl variant="outlined" margin="normal" fullWidth>
				<SelectLabel>
					{t('Attribute', {
						context: 'Layer properties editor form field',
					})}
				</SelectLabel>
				{!attributes.length && (
					<Progress size={20} thickness={5} color="inherit" />
				)}
				<Select
					disabled={!attributes.length}
					onChange={onAttributeChange}
					value={selectedAttribute}
					data-cy="attributes-picker">
					{attributes.map((attribute, i) => (
						<MenuItem key={i} value={attribute.id}>
							{attribute.id}
						</MenuItem>
					))}
				</Select>
				{helperText && (
					<FormHelperText data-cy="attributes-picker-helper-text">
						{helperText}
					</FormHelperText>
				)}
			</FormControl>
		</>
	);
};
