import { FormHelperText, IconButton } from '@mui/material';
import styled, { css } from 'styled-components';

import { DistributeVerticalIcon } from '@Components/icons/DistributeVertical';
import MuiDeleteIcon from '@mui/icons-material/Delete';
import MuiPaletteIcon from '@mui/icons-material/Palette';
import { rem } from '@Components/styles';

export const RangeWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(2, auto) ${rem(25)};
	grid-gap: ${rem(8)} ${rem(12)};
	margin: ${rem(10)} 0;
	button {
		text-transform: none;
	}
`;

export const GridItem = styled.div<{
	$span?: number;
}>`
	display: flex;
	align-items: center;
	${({ $span }) =>
		$span &&
		css`
			grid-column: span ${$span};
		`}
`;

export const DistributeIconButton = styled(IconButton)`
	margin-left: auto;
`;

export const DeleteIcon = styled(MuiDeleteIcon)`
	width: 1em;
	height: 1em;
`;

export const Heading = styled.span`
	font-size: ${rem(14)};
`;

export const RangeValueHelperText = styled(FormHelperText)`
	grid-column: span 3;
	padding-left: ${rem(10)};
	transform: translate(0, ${rem(-3)});
`;

export const RowBorder = styled.div`
	grid-column: span 3;
	border-bottom: 1px solid #e7e7ed;
`;

export const DistributeIcon = styled(DistributeVerticalIcon)`
	width: 1em;
	height: 1em;
`;

export const PaletteIcon = styled(MuiPaletteIcon)`
	width: 1em;
	height: 1em;
`;
