import { AxiosResponse } from 'axios';
import { retry, put } from '@redux-saga/core/effects';

import { getIncidents, getAlerts, getNotifications } from '../services';
import {
  getKpiValue,
  getKpiValueRejected,
  getKpiValueResolved,
} from '../actions/kpiValue.actions';
import { takeLatest } from 'redux-saga/effects';
import { Kpis } from '../types/kpi.types';

export interface PaginationInfo {
  total: number;
  previous: number;
  next: number;
  current: number;
  pages: number;
}

export interface PaginatedResults {
  [data: string]: unknown;
  pagination: PaginationInfo;
}

export const kpiValueSagas = [
  (function* getKpiValueSaga() {
    yield takeLatest(getKpiValue, function* (action) {
      try {
        let kpiService = getAlerts;
        switch (action.payload.type) {
          case Kpis.HighAlerts:
            kpiService = getAlerts;
            break;
          case Kpis.Incidents:
            kpiService = getIncidents;
            break;
          case Kpis.NewAlerts:
            kpiService = getNotifications;
            break;
        }

        const response: AxiosResponse<PaginatedResults> = yield retry(
          3,
          500,
          kpiService
        );

        let kpiValue: string | number = '';

        if (response.status >= 200 && response.status < 300) {
          kpiValue = response.data.pagination.total;
        }

        yield put(
          getKpiValueResolved({
            type: action.payload.type,
            value: kpiValue,
          })
        );
      } catch (error) {
        yield put(getKpiValueRejected({ type: action.payload.type }));
      }
    });
  })(),
];
