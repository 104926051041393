import type { RangeSelection } from '../../types/chartState';
import type { AsyncSensorData } from '../types/data.types';

export const reduceToDataInRange = (
  data: Exclude<AsyncSensorData['data'], null>,
  range?: RangeSelection
): Exclude<AsyncSensorData['data'], null> => {
  if (
    range === undefined ||
    (range.min === undefined && range.max === undefined)
  ) {
    return data;
  }

  const dataInRange: Exclude<AsyncSensorData['data'], null> = {
    measurements: {},
    timestamps: [],
    unit: data.unit,
  };

  for (let i = 0; i < data.timestamps.length; i++) {
    const timestamp = data.timestamps[i];
    const measurement = data.measurements[timestamp];

    if (
      (range.min === undefined &&
        range.max !== undefined &&
        timestamp <= range.max) ||
      (range.max === undefined &&
        range.min !== undefined &&
        timestamp >= range.min) ||
      (range.min !== undefined &&
        range.max !== undefined &&
        range.min <= timestamp &&
        range.max >= timestamp)
    ) {
      dataInRange.measurements[timestamp] = measurement;
      dataInRange.timestamps.push(timestamp);
    }
  }

  return dataInRange;
};

export const stringifyRangeSelection = (
  rangeSelection?: RangeSelection
): string => {
  if (rangeSelection === undefined) {
    return '[undefined,undefined]';
  }
  return `[${rangeSelection.min ?? 'undefined'},${
    rangeSelection.max ?? 'undefined'
  }]`;
};

export const takeMinRange = (a: number | undefined, b: number | undefined) => {
  if (a === undefined) return b;
  else if (b === undefined) return a;

  return a < b ? a : b;
};

export const takeMaxRange = (a: number | undefined, b: number | undefined) => {
  if (a === undefined) return b;
  else if (b === undefined) return a;

  return a > b ? a : b;
};

export const fixRangeSelection = (
  rangeSelection?: RangeSelection,
  dataRangeSelection?: RangeSelection
): RangeSelection => {
  let min = takeMaxRange(dataRangeSelection?.min, rangeSelection?.min);

  let max = takeMinRange(dataRangeSelection?.max, rangeSelection?.max);

  if (min !== undefined && max !== undefined && min > max) {
    const tempMin = min;
    min = max;
    max = tempMin;
  }

  return { min, max };
};
