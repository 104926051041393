import { CSSProperties } from 'react';
import { Point } from 'mapbox-gl';
import { rem } from '@Components/styles';

interface Size {
	width: number;
	height: number;
}

export enum Anchor {
	top,
	bottom,
	left,
	right,
}

interface Position {
	size: Size;
	containerSize?: Size;
	point: Point;
	offset: number;
}

const position = ({
	size,
	containerSize,
	point,
	offset,
}: Position): { left: number; top: number } => {
	const leftX = point.x - size.width - offset;
	const centerX = point.x - size.width / 2;
	const rightX = point.x + offset;
	const topY = point.y - size.height - offset;
	const bottomY = point.y + offset;

	if (!containerSize) {
		// if there is no container then can only position relative to point
		return { top: topY, left: centerX };
	}

	if (point.x < 0 || point.y < 0) {
		// if the point is off screen (nothing is being hovered on) position the popup off screen
		return { top: -size.height, left: -size.width };
	}

	const anchor = {
		top: false,
		bottom: false,
		left: false,
		right: false,
	};

	if (point.y > size.height + offset) {
		// if there is enough space above popup display on top
		anchor.top = true;
	} else if (point.y < containerSize.height - size.height - offset) {
		// if there is enough space below popup display below
		anchor.bottom = true;
	}

	if (point.x < size.width / 2 + offset) {
		// if there is not enough space to center and getting
		// close to the left, then display popup right
		anchor.right = true;
	} else if (point.x > containerSize.width - size.width / 2 - offset) {
		// if there is not enough space to center and getting
		// close to the right, then display popup left
		anchor.left = true;
	}

	if (anchor.top) {
		if (anchor.left) {
			return {
				top: topY,
				left: leftX,
			};
		} else if (anchor.right) {
			return {
				top: topY,
				left: rightX,
			};
		}
	} else if (anchor.bottom) {
		if (anchor.left) {
			return {
				top: bottomY,
				left: leftX,
			};
		} else if (anchor.right) {
			return {
				top: bottomY,
				left: rightX,
			};
		} else {
			return {
				top: bottomY,
				left: centerX,
			};
		}
	}

	return { top: topY, left: centerX };
};

const getAnchorPosition = (anchor: Anchor, offset: number) => {
	switch (anchor) {
		case Anchor.top:
			return {
				top: rem(offset),
				left: '50%',
				transform: 'translate(-50%, 0)',
			};
		case Anchor.left:
			return {
				top: '50%',
				left: rem(offset),
				transform: 'translate(0, -50%)',
			};
		case Anchor.right:
			return {
				top: '50%',
				left: `calc(100% - ${rem(offset)})`,
				transform: 'translate(-100%, -50%)',
			};
		case Anchor.bottom:
		default:
			return {
				top: `calc(100% - ${rem(offset)})`,
				left: '50%',
				transform: 'translate(-50%, -100%)',
			};
	}
};

interface UsePopup {
	size: Size;
	containerSize?: Size;
	point: Point;
	anchor?: Anchor;
	open: boolean;
	removeContentPadding: boolean;
}

export const usePopupPositioning = ({
	size,
	containerSize,
	point,
	anchor,
	open,
	removeContentPadding,
}: UsePopup): Pick<
	CSSProperties,
	'left' | 'top' | 'opacity' | 'pointerEvents'
> => {
	const offset = 18;
	const anchorPosition =
		anchor !== undefined && getAnchorPosition(anchor, offset);

	if (anchorPosition) {
		return { ...anchorPosition, opacity: 1 };
	}

	const pos = position({ size, containerSize, point, offset });
	const minHeight = removeContentPadding ? 0 : 40;
	const minWidth = removeContentPadding ? 0 : 32;

	const isVisible =
		pos.left > offset &&
		pos.top > offset &&
		size.height > minHeight &&
		size.width > minWidth;

	const opacity = (isVisible && open) || anchor ? 1 : 0;

	return {
		left: `${pos.left}px`,
		top: `${pos.top}px`,
		opacity,
		pointerEvents: opacity === 0 ? 'none' : undefined,
	};
};
