import {
	AssetDetailParams,
	AssetManholeSummaryResolvedResponse,
} from '@Types/asset.types';

import { createAction } from '@reduxjs/toolkit';

export const getAssetManhole = createAction<AssetDetailParams>(
	'assets/getAssetManhole',
);

export const getAssetManholeResolved =
	createAction<AssetManholeSummaryResolvedResponse>(
		'assets/getAssetManhole/resolved',
	);

export const getAssetManholeRejected = createAction(
	'assets/getAssetManhole/rejected',
);
