import { createChart } from '../../core/_chart';
import { createContext } from '../../core/react-context';
import { nanoid } from 'nanoid';
import { useGlobalization } from '../../../i18n';
import { useStableCallback } from '../../core/lib-utils';
import * as React from 'react';
import Highcharts from 'highcharts';
import HighchartsXRange from 'highcharts/modules/xrange';

import type { Status } from '../../core/_chart';
import { getTheme, Theme } from '../../core/utils/theme-utils';

HighchartsXRange(Highcharts);

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Chart
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const Chart = createChart('PumpStatusChartRoot');

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Chart Root
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

interface PumpStatusChartRootProps {
  children: React.ReactNode;
  /**
   * A callback function that gets triggered when the user hides or shows a
   * series using the chart legend.
   *
   * @param index The index of the series.
   * @param type The event type. Either `hide` or `show`.
   * @returns `void`
   */
  onSeriesVisibilityChange?: (index: number, type: 'hide' | 'show') => void;
  selectedTheme?: Theme;
}

const PumpStatusChartRoot = React.forwardRef<
  { chart: Highcharts.Chart | undefined },
  PumpStatusChartRootProps
>((props, ref): React.ReactElement => {
  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Event Handlers
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  const onSeriesVisibilityChange = useStableCallback(
    props.onSeriesVisibilityChange
  );

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  return (
    <Chart.Root>
      <Chart.Instance
        id="pump-status"
        ref={ref}
        chartConstructor={Highcharts.chart}
        initialOptions={makeInitialOptions({
          onSeriesVisibilityChange,
        })}>
        {props.children}
      </Chart.Instance>
    </Chart.Root>
  );
});

PumpStatusChartRoot.displayName = 'PumpStatusChartRoot';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Series Group
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const [SeriesGroupProvider, useSeriesGroup] = createContext<{
  status: Status | undefined;
}>('PumpStatusChartSeriesGroup');

interface PumpStatusChartSeriesGroupProps {
  children: React.ReactNode;
  /**
   * The loading status of the data.
   *
   * If set, individual `status` props for each series are ignored.
   * If the `status` prop is not defined in either the group or in
   * the series, `resolved` is used.
   *
   * Available values: `idle` | `loading` | `resolved` | `rejected`
   */
  status?: Status;
  /**
   * An `Error` object used when `status` is `rejected`.
   *
   * If set, individual `error` props for each series are ignored.
   */
  error?: Error;
  selectedTheme?: Theme;
}

const PumpStatusChartSeriesGroup = (
  props: PumpStatusChartSeriesGroupProps
): React.ReactElement => {
  const { t } = useGlobalization();
  const instance = Chart.useInstance('PumpStatusChartSeriesGroup');

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Set global status
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    if (props.status !== undefined) {
      if (props.status === 'loading') {
        instance.get()?.showLoading(t('Loading'));
      } else if (props.status === 'rejected') {
        instance.get()?.showLoading(t('No Data'));
      } else {
        const noData = instance
          .get()
          ?.series.every((s) => s.data === undefined || s.data.length === 0);
        if (noData) {
          instance.get()?.showLoading('No Data');
        } else {
          instance.get()?.hideLoading();
        }
      }
    }
  }, [props.status]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Theme
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    const theme= getTheme(props.selectedTheme ?? 'Default');
    instance.get().update({...theme});
  }, [props.selectedTheme]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  return (
    <SeriesGroupProvider status={props.status}>
      {props.children}
    </SeriesGroupProvider>
  );
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Series
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

type PumpStatusChartSeriesProps = {
  /**
   * The `id` of the series.
   *
   * If `undefined`, a random id is generated.
   */
  id?: string;
  /** Options for the series' X axis. */
  xAxis?: {
    /**
     * The X axis assigned for the series.
     *
     * If defined as `string`, it refers to the X axis `id`.
     * If set as `number`, it refers to the X axis `index`.
     * If `undefined`, defaults to X axis at index `0`.
     * */
    id?: string | number;
  };
  /** Options for the series' Y axis. */
  yAxis?: {
    /**
     * The Y axis assigned for the series.
     *
     * If defined as `string`, it refers to the X axis `id`.
     * If set as `number`, it refers to the X axis `index`.
     * If `undefined`, defaults to X axis at index `0`.
     * */
    id?: string | number;
    /**
     * The Y axis category assigned for the series.
     *
     * If `undefined`, the data will be placed in the first category defined.
     */
    category?: string;
  };
  /** The series name. */
  name?: string;
  /** The series color. */
  color?: string;
  /**
   * The series visibility status.
   *
   * If `true`, the series will be hidden. Otherwise, it'll be visible.
   */
  hidden?: boolean;
  /** The series data. */
  data?: PumpStatusChartSeriesData;
  /**
   * The series unit.
   *
   * @deprecated Pump status charts don't display units in either X or Y axis.
   * */
  unit?: string;
  /**
   * The loading status of the data.
   *
   * If series group `status` prop is set, this value is ignored.
   * If the `status` prop is not defined in either the group or in
   * the series, `resolved` is used.
   *
   * Available values: `idle` | `loading` | `resolved` | `rejected`
   */
  status?: Status;
  /**
   * An `Error` object used when `status` is `rejected`.
   *
   * If series group `error` prop is set, this value is ignored.
   */
  error?: Error;
  selectedTheme?: Theme;
};

/** The pump status series data. */
type PumpStatusChartSeriesData =
  | [start: number, end: number][]
  | { start: number; end: number }[];

const PumpStatusChartSeries = (
  props: PumpStatusChartSeriesProps
): React.ReactElement | null => {
  const seriesRef = React.useRef<Highcharts.Series>();
  const seriesId = React.useMemo(() => props.id || nanoid(), [props.id]);
  const seriesGroup = useSeriesGroup('PumpStatusChartSeries');
  const instance = Chart.useInstance('PumpStatusChartSeries');

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets series
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    seriesRef.current = instance
      .get()
      ?.addSeries({ id: seriesId, type: 'xrange' });

    return () => {
      instance.get()?.get(seriesId)?.remove();
    };
  }, [seriesId]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets series axes
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    seriesRef.current?.update({
      xAxis: props.xAxis?.id,
    } as Highcharts.SeriesXrangeOptions);
  }, [seriesRef, props.xAxis?.id]);

  React.useEffect(() => {
    seriesRef.current?.update({
      yAxis: props.yAxis?.id,
    } as Highcharts.SeriesXrangeOptions);
  }, [seriesRef, props.yAxis?.id]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets series data
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    const yAxisCategories = seriesRef.current?.yAxis?.categories;
    const yAxisCategoryIndex =
      Array.isArray(yAxisCategories) && props.yAxis?.category !== undefined
        ? yAxisCategories.findIndex((yAxisCategory) => {
            return yAxisCategory === props.yAxis?.category;
          })
        : undefined;

    const data =
      yAxisCategoryIndex !== undefined
        ? props.data?.map((data) => {
            const start = Array.isArray(data) ? data[0] : data.start;
            const end = Array.isArray(data) ? data[1] : data.end;
            return {
              x: start,
              x2: end,
              y: yAxisCategoryIndex,
              color: props.color,
            };
          })
        : [];

    seriesRef.current?.update({ data } as Highcharts.SeriesXrangeOptions);
  }, [seriesRef, props.data, props.color, props.yAxis?.category]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets series status
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    if (seriesGroup.status === undefined) {
      seriesRef.current?.update({
        name: props.name,
      } as Highcharts.SeriesXrangeOptions);
    }
  }, [seriesRef, props.status, seriesGroup.status]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets series name
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    seriesRef.current?.update({
      name: props.name,
    } as Highcharts.SeriesXrangeOptions);
  }, [seriesRef, props.name]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets series color
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    seriesRef.current?.update({
      color: props.color,
    } as Highcharts.SeriesXrangeOptions);
  }, [seriesRef, props.color, props.selectedTheme]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets series visibility
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    seriesRef.current?.update({
      visible: !props.hidden,
    } as Highcharts.SeriesXrangeOptions);
  }, [seriesRef, props.hidden]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  return null;
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * X axis group
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const [XAxisGroupProvider, useXAxisGroup] = createContext<{
  min: number | undefined;
  max: number | undefined;
  enableGridlines: boolean | undefined;
}>('PumpStatusChartXAxisGroup');

interface PumpStatusChartXAxisGroupProps {
  children: React.ReactNode;
  /**
   * The `min` value for the X axes.
   *
   * If this prop is set, the `min` and `max` props on individual
   * axes will be ignored.
   * */
  min?: number;
  /** The `max` value for the X axes.
   *
   * If this prop is set, the `min` and `max` props on individual
   * axes will be ignored.
   * */
  max?: number;
  /**
   * The lines extending the X axis ticks across the plot area.
   *
   * If `true`, vertical gridlines will be rendered. If multiple axes
   * exist, only the first one will render the lines.
   *
   * If this prop is set, the `enableGridlines` prop on individual
   * axes will be ignored.
   * */
  enableGridlines?: boolean;
}

const PumpStatusChartXAxisGroup = (
  props: PumpStatusChartXAxisGroupProps
): React.ReactElement => {
  const instance = Chart.useInstance('PumpStatusChartXAxisGroup');

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets global X axis min / max
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    if (props.min !== undefined || props.max !== undefined) {
      instance.get()?.xAxis.forEach((xAxis) => {
        xAxis.update({
          min: props.min,
          max: props.max,
        });
      });
    }
  }, [props.min, props.max]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets global X axis gridlines
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    if (props.enableGridlines !== undefined) {
      instance.get()?.xAxis[0].update({
        gridLineWidth: props.enableGridlines ? 1 : 0,
      });
    }
  }, [props.enableGridlines]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  return (
    <XAxisGroupProvider
      min={props.min}
      max={props.max}
      enableGridlines={props.enableGridlines}>
      {props.children}
    </XAxisGroupProvider>
  );
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * X axis
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

interface PumpStatusChartXAxisProps {
  /** The id for the axis. */
  id?: string;
  /**
   * The `min` value for the X axis.
   *
   * If either `min` or `max` prop was set in the axis group,
   * this prop's value will be ignored.
   * */
  min?: number;
  /** The `max` value for the X axis.
   *
   * If either `min` or `max` prop was set in the axis group,
   * this prop's value will be ignored.
   * */
  max?: number;
  /** The title for the axis. */
  label?: string;
  /**
   * The lines extending the X axis ticks across the plot area.
   *
   * If `true`, vertical gridlines will be rendered. If multiple axes
   * exist, only the first one will render the lines.
   *
   * If this prop is set, the `enableGridlines` prop on individual
   * axes will be ignored.
   * */
  enableGridlines?: boolean;
}

const PumpStatusChartXAxis = (props: PumpStatusChartXAxisProps): null => {
  const xAxisRef = React.useRef<Highcharts.Axis>();
  const xAxisId = React.useMemo(() => props.id || nanoid(), [props.id]);
  const xAxisGroup = useXAxisGroup('PumpStatusChartXAxis');
  const instance = Chart.useInstance('PumpStatusChartXAxis');

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets X axis
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    xAxisRef.current = instance
      .get()
      ?.addAxis({ id: xAxisId, type: 'datetime' }, true);

    return () => {
      instance.get()?.get(xAxisId)?.remove();
    };
  }, [xAxisId]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets X axis gridlines
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    if (xAxisGroup.enableGridlines === undefined) {
      xAxisRef.current?.update({
        gridLineWidth: props.enableGridlines ? 1 : 0,
      });
    }
  }, [xAxisRef, props.enableGridlines, xAxisGroup.enableGridlines]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets X axis label
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    xAxisRef.current?.update({ title: { text: props.label } });
  }, [xAxisRef, props.label]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets X axis min and max
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    if (xAxisGroup.min === undefined && xAxisGroup.max === undefined) {
      xAxisRef.current?.update({
        min: props.min,
        max: props.max,
      });
    }
  }, [xAxisRef, props.min, props.max, xAxisGroup.min, xAxisGroup.max]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  return null;
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Y axis group
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const [YAxisGroupProvider, useYAxisGroup] = createContext<{
  min: number | undefined;
  max: number | undefined;
  enableGridlines: boolean | undefined;
}>('PumpStatusChartYAxisGroup');

interface PumpStatusChartYAxisGroupProps {
  children: React.ReactNode;
  /**
   * The `min` value for the Y axes.
   *
   * If this prop is set, the `min` and `max` props on individual
   * axes will be ignored.
   * */
  min?: number;
  /** The `max` value for the Y axes.
   *
   * If this prop is set, the `min` and `max` props on individual
   * axes will be ignored.
   * */
  max?: number;
  /**
   * The lines extending the Y axis ticks across the plot area.
   *
   * If `true`, horizontal gridlines will be rendered. If multiple axes
   * exist, only the first one will render the lines.
   *
   * If this prop is set, the `enableGridlines` prop on individual
   * axes will be ignored.
   * */
  enableGridlines?: boolean;
}

const PumpStatusChartYAxisGroup = (
  props: PumpStatusChartYAxisGroupProps
): React.ReactElement => {
  const instance = Chart.useInstance('PumpStatusChartYAxisGroup');

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets global Y axis min / max
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    if (props.min !== undefined || props.max !== undefined) {
      instance.get()?.yAxis.forEach((yAxis) => {
        yAxis.update({
          min: props.min,
          max: props.max,
        });
      });
    }
  }, [props.min, props.max]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets global Y axis gridlines
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    if (props.enableGridlines !== undefined) {
      instance.get()?.yAxis[0].update({
        gridLineWidth: props.enableGridlines ? 1 : 0,
      });
    }
  }, [props.enableGridlines]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  return (
    <YAxisGroupProvider
      min={props.min}
      max={props.max}
      enableGridlines={props.enableGridlines}>
      {props.children}
    </YAxisGroupProvider>
  );
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Y axis
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

interface PumpStatusChartYAxisProps {
  /** The id for the axis. */
  id?: string;
  /**
   * Custom values for the Y axis labels.
   *
   * If `categories` is set, names are used instead of numbers.
   */
  categories: string[];
  /**
   * The `min` value for the Y axis.
   *
   * If either `min` or `max` prop was set in the axis group,
   * this prop's value will be ignored.
   * */
  min?: number;
  /** The `max` value for the Y axis.
   *
   * If either `min` or `max` prop was set in the axis group,
   * this prop's value will be ignored.
   * */
  max?: number;
  /** The title for the axis. */
  label?: string;
  /**
   * The lines extending the Y axis ticks across the plot area.
   *
   * If `true`, horizontal gridlines will be rendered. If multiple axes
   * exist, only the first one will render the lines.
   *
   * If this prop is set, the `enableGridlines` prop on individual
   * axes will be ignored.
   * */
  enableGridlines?: boolean;
}

const PumpStatusChartYAxis = (props: PumpStatusChartYAxisProps): null => {
  const yAxisRef = React.useRef<Highcharts.Axis>();
  const yAxisId = React.useMemo(() => props.id || nanoid(), [props.id]);
  const yAxisGroup = useYAxisGroup('PumpStatusChartYAxis');
  const instance = Chart.useInstance('PumpStatusChartYAxis');

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets Y axis
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    yAxisRef.current = instance.get()?.addAxis({ id: yAxisId });
    return () => {
      instance.get()?.get(yAxisId)?.remove();
    };
  }, [yAxisId]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets Y axis categories
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    yAxisRef.current?.update({ categories: props.categories });
  }, [yAxisRef, props.categories]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets Y axis gridlines
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    if (yAxisGroup.enableGridlines !== undefined) {
      yAxisRef.current?.chart.yAxis[0].update({
        gridLineWidth: yAxisGroup.enableGridlines ? 1 : 0,
      });
    } else {
      yAxisRef.current?.update({
        gridLineWidth: props.enableGridlines ? 1 : 0,
      });
    }
  }, [yAxisRef, props.enableGridlines, yAxisGroup.enableGridlines]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets Y axis label
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    yAxisRef.current?.update({ title: { text: props.label } });
  }, [yAxisRef, props.label]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets Y axis min and max
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    if (yAxisGroup.min === undefined && yAxisGroup.max === undefined) {
      yAxisRef.current?.update({
        min: props.min,
        max: props.max,
      });
    }
  }, [yAxisRef, props.min, props.max, yAxisGroup.min, yAxisGroup.max]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  return null;
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Initial options
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const hasBaseSeries = (series: Highcharts.Series): boolean => {
  return 'baseSeries' in series && series.baseSeries !== undefined;
};

const makeInitialOptions = (
  props: Pick<PumpStatusChartRootProps, 'onSeriesVisibilityChange'>
): Highcharts.Options => ({
  chart: { type: 'xrange', height: null },
  title: { text: '' },
  exporting: { enabled: false },
  credits: { enabled: false },
  series: [],
  xAxis: [],
  yAxis: [],
  plotOptions: {
    xrange: {
      pointWidth: undefined,
      grouping: false,
    },
    series: {
      events: {
        hide: function (event) {
          // TODO: Check this error
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const target = event.target as Highcharts.Series | null;
          if (target && !hasBaseSeries(target)) {
            props.onSeriesVisibilityChange?.(target.index, 'hide');
          }
        },
        show: function (event) {
          // TODO: Check this error
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const target = event.target as Highcharts.Series | null;
          if (target && !hasBaseSeries(target)) {
            props.onSeriesVisibilityChange?.(target.index, 'show');
          }
        },
      },
    },
  },
  colors: [
    '#00abd1',
    '#768D95',
    '#E66B45',
    '#EFB302',
    '#83BC3F',
    '#876CE7',
    '#017CA0',
    '#4A6067',
    '#AA0000',
    '#AA5D00',
    '#4B7E03',
    '#4C3DAD',
    '#61DDFF',
    '#A5BDC6',
    '#FF9C72',
    '#FFE54D',
    '#B6EF70',
    '#BAA6FF',
  ],
});

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export {
  PumpStatusChartRoot,
  PumpStatusChartSeriesGroup,
  PumpStatusChartSeries,
  PumpStatusChartXAxisGroup,
  PumpStatusChartXAxis,
  PumpStatusChartYAxisGroup,
  PumpStatusChartYAxis,
};

export type {
  PumpStatusChartRootProps,
  PumpStatusChartSeriesGroupProps,
  PumpStatusChartSeriesProps,
  PumpStatusChartSeriesData,
  PumpStatusChartXAxisGroupProps,
  PumpStatusChartXAxisProps,
  PumpStatusChartYAxisGroupProps,
  PumpStatusChartYAxisProps,
};
