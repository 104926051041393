import { nanoid } from 'nanoid';
import * as Chart from '../../core/chart';
import * as Hooks from './customAnalytic-chart.hooks';
import * as React from 'react';
import * as LibUtils from '../../core/lib-utils';
import * as Styled from './customAnalytic-chart.styled';
import * as Utils from './customAnalytic-chart.utils';

import type { MutableRefObject, ReactElement } from 'react';
import type { RootProps, SeriesProps } from './customAnalytic-chart.types';
import type { EventHandlers } from './customAnalytic-chart.utils';
import { useIsFeatureEnabled } from '@innovyze/stylovyze';

const RootPropsContext = React.createContext<MutableRefObject<RootProps>>({
  current: { elements: { series: [] } },
});

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 *	Custom Analytic Chart Root Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const Root = Chart.withRoot(
  // eslint-disable-next-line react/display-name
  React.forwardRef<{ chart: Highcharts.Chart | undefined }, RootProps>(
    (props, ref): ReactElement => {
      const propsRef = LibUtils.useStableRef(props);

      Hooks.useTitle(props);

      Hooks.useXAxisGridlines(props);
      Hooks.useYAxisGridlines(props);

      Hooks.useXAxisLabel(props);
      Hooks.useYAxisLabel(props);

      Hooks.useZoom(props);
      Hooks.useStatus(props);

      Hooks.useMarkers(props);
      Hooks.useTheme(props);

      /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
       * Sets X axis min and max
       * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

      Chart.useInstanceEffect(
        (instances) => {
          if (
            props.xAxis?.min !== undefined &&
            props.xAxis?.max !== undefined
          ) {
            instances.get(['overlay', 'stacked-footer']).forEach((instance) => {
              instance?.xAxis[0]?.setExtremes(
                props.xAxis?.min,
                props.xAxis?.max
              );
            });
          }
        },
        [props.xAxis?.min, props.xAxis?.max]
      );

      return (
        <RootPropsContext.Provider value={propsRef}>
          {props.stacked ? (
            <Stack {...props} />
          ) : (
            <Overlay ref={ref} {...props} />
          )}
          {props.stacked ? (
            <div className="ref-holder" style={{ display: 'none' }}>
              <Overlay ref={ref} {...props} />
            </div>
          ) : null}
        </RootPropsContext.Provider>
      );
    }
  )
);

const Stack = React.forwardRef<
  { chart: Highcharts.Chart | undefined },
  RootProps
>((props, ref): ReactElement => {
  const onSynchronizedExtremesChange = Hooks.useStackSynchronization();
  const enableNewHeader = useIsFeatureEnabled(
    'info-360-analytics-charts-date-time-picker'
  );

  const eventHandlers = LibUtils.useStableRef<EventHandlers>({
    ...props.events,
    onSynchronizedExtremesChange,
    syncDateTimeRangePicker: Chart.useSyncDateTimeRangePicker(),
  });

  return (
    <>
      <Styled.Header withTitle={!!props.title}>
        <Chart.Instance
          id="stacked-header"
          initialOptions={Utils.makeStackedHeaderOptions(eventHandlers)}>
          {enableNewHeader ? <Chart.ChartHeader /> : null}
          {props.elements.series}
        </Chart.Instance>
      </Styled.Header>
      <Styled.ScrollArea>
        {props.elements.series.map((child, index) => {
          return (
            <Styled.Series key={index}>
              <Chart.Instance
                id={`stacked-series-${index}`}
                initialOptions={Utils.makeStackedSeriesOptions(eventHandlers)}>
                {child}
              </Chart.Instance>
            </Styled.Series>
          );
        })}
      </Styled.ScrollArea>
      <Styled.Footer>
        <Chart.Instance
          ref={ref}
          id="stacked-footer"
          initialOptions={Utils.makeStackedFooterOptions(eventHandlers)}>
          {props.elements.series}
        </Chart.Instance>
      </Styled.Footer>
    </>
  );
});
Stack.displayName = 'Stack';

const Overlay = React.forwardRef<
  { chart: Highcharts.Chart | undefined },
  RootProps
>((props, ref): ReactElement => {
  const enableNewHeader = useIsFeatureEnabled(
    'info-360-analytics-charts-date-time-picker'
  );

  const eventHandlers = LibUtils.useStableRef<EventHandlers>({
    ...props.events,
    syncDateTimeRangePicker: Chart.useSyncDateTimeRangePicker(),
  });

  return (
    <Chart.Instance
      ref={ref}
      id="overlay"
      initialOptions={Utils.makeOverlayOptions(eventHandlers)}>
      {enableNewHeader ? <Chart.ChartHeader /> : null}
      {props.elements.series}
    </Chart.Instance>
  );
});
Overlay.displayName = 'Overlay';
/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Historical Chart Series Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const Series = (props: SeriesProps): null => {
  const id = React.useRef(props.id ?? nanoid());

  Hooks.useSeriesCreateRemove(id, props);

  Hooks.useSeriesData(id, props);
  Hooks.useSeriesNavigatorData(id, props);

  Hooks.useSeriesYAxis(id, props);
  Hooks.useSeriesColor(id, props);
  Hooks.useSeriesName(id, props);
  Hooks.useSeriesVisibility(id, props);

  return null;
};
