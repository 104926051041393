import { FeatureItem, ItemDetailsSelector } from '@Utils/types';
import {
	SensorProperties,
	SensorPropertiesProps,
} from '@Components/SensorProperties';

import React from 'react';
import { Tab } from '@Components/Tab';
import { getSensor } from '@Actions/asset';
import { selectSensorById } from '../../selectors/asset';
import { useGlobalization } from '@Translations/useGlobalization';

interface SensorsTabProps extends Omit<SensorPropertiesProps, 'item'> {
	items: FeatureItem[];
}

export const SensorsTab = ({
	items,
	...other
}: SensorsTabProps): JSX.Element => {
	const { t } = useGlobalization();

	return (
		<Tab
			items={items}
			tabName={t('Sensors', {
				context: 'Property pane tab heading',
			})}
			detailView={items => <SensorProperties item={items} {...other} />}
			getItemName={item => `${item.alias || item.sensorId}`}
			getItemDetailsAction={getSensor}
			getItemDetailsSelector={selectSensorById as ItemDetailsSelector}
			getItemDetailsOnList
		/>
	);
};
