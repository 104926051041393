/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type {
  Axis,
  AxisSetExtremesEventObject,
  Options,
  SeriesOptionsType,
} from 'highcharts/highstock';
import type { MutableRefObject } from 'react';
import { RootProps } from './comparison-chart.types';

/**
 * THIS FILE CAN BE IMPROVED BY:
 * - REMOVING DUPLICATED OPTIONS BETWEEN STACK AND OVERLAY MODE
 */

export type EventHandlers =
  | (RootProps['events'] & {
      onSynchronizedExtremesChange?: (
        this: Axis,
        e: AxisSetExtremesEventObject
      ) => void;
    })
  | undefined;

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Overlay Mode Options
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const makeOptions = (
  eventHandlers: MutableRefObject<EventHandlers>
): Options => {
  return {
    credits: { enabled: false },
    legend: { enabled: true },
    yAxis: { opposite: false },
    exporting: { enabled: false },
    rangeSelector: { enabled: false },
    navigator: { enabled: false },
    scrollbar: { enabled: false },
    plotOptions: {
      series: {
        marker: {
          enabled: false,
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        pointWidth: 50,
        states: {
          hover: {
            lineWidth: undefined,
            lineWidthPlus: 0,
          },
          inactive: { opacity: 1 },
        },
        events: {
          hide: function (event) {
            // TODO: Check this error
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const target = event.target as SeriesOptionsType | null;
            if (
              target &&
              'baseSeries' in target &&
              target.baseSeries === undefined
            ) {
              eventHandlers.current?.series?.onVisibilityChange?.(
                target.index!,
                event.type as 'hide' | 'show'
              );
            }
          },
          show: function (event) {
            // TODO: Check this error
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const target = event.target as SeriesOptionsType | null;
            if (
              target &&
              'baseSeries' in target &&
              target.baseSeries === undefined
            ) {
              eventHandlers.current?.series?.onVisibilityChange?.(
                target.index!,
                event.type as 'hide' | 'show'
              );
            }
          },
        },
      },
    },
  };
};
