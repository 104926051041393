import {
	NamespacedStoreState,
	FilterObservation,
	InspectionStandard,
	MACPObservation,
	MSCCObservation,
	PACPObservation,
	FilterObservationGradeType,
	WSA05Observation,
} from '@Types';
import { createSelector } from '@reduxjs/toolkit';
import { moduleNamespace } from '@Store/config.store';
import { useSelector } from 'react-redux';

const parseNamespace = (state: NamespacedStoreState) => state[moduleNamespace];

export const observationStoreSelector = createSelector(
	parseNamespace,
	state => state.observations,
);

export const observationSelector = (standard: InspectionStandard) => {
	return createSelector(parseNamespace, state => {
		if (state.observations && state.observations.observations) {
			return state.observations.observations
				.filter(observation => {
					const obs = observation as FilterObservation;
					if (
						state.observations.continuousOnly &&
						!obs.continuous?.toUpperCase().startsWith('S') &&
						!obs.continuous?.toUpperCase().startsWith('F')
					)
						return false;

					if (state.observations.jointOnly && !obs.joint)
						return false;

					if (
						state.observations.gradeFilters &&
						state.observations.gradeFilters.length !== 0
					) {
						switch (standard) {
							case InspectionStandard.WSA05:
								{
									const wsaObs =
										observation as WSA05Observation;

									let wsaResult = false;
									if (
										state.observations.typeFilters
											?.length == 0 ||
										state.observations.typeFilters?.includes(
											FilterObservationGradeType.Struct,
										)
									) {
										if (
											wsaObs.structuralScore !==
												undefined &&
											state.observations.gradeFilters.includes(
												wsaObs.structuralScore.toString(),
											)
										)
											wsaResult = true;
									}

									if (
										!wsaResult &&
										(state.observations.typeFilters
											?.length == 0 ||
											state.observations.typeFilters?.includes(
												FilterObservationGradeType.Service,
											))
									) {
										if (
											wsaObs.serviceScore !== undefined &&
											state.observations.gradeFilters.includes(
												wsaObs.serviceScore.toString(),
											)
										)
											wsaResult = true;
									}

									if (!wsaResult) return false;
								}
								break;
							case InspectionStandard.MSCC:
								{
									const msccObs =
										observation as MSCCObservation;

									let msccResult = false;
									if (
										state.observations.typeFilters
											?.length == 0 ||
										state.observations.typeFilters?.includes(
											FilterObservationGradeType.Struct,
										)
									) {
										if (
											msccObs.structuralScore !==
											undefined
										) {
											if (
												state.observations.gradeFilters.includes(
													'1',
												) &&
												msccObs.structuralScore < 10
											)
												msccResult = true;

											if (
												!msccResult &&
												state.observations.gradeFilters.includes(
													'2',
												) &&
												msccObs.structuralScore >= 10 &&
												msccObs.structuralScore <= 39
											)
												msccResult = true;

											if (
												!msccResult &&
												state.observations.gradeFilters.includes(
													'3',
												) &&
												msccObs.structuralScore >= 40 &&
												msccObs.structuralScore <= 79
											)
												msccResult = true;

											if (
												!msccResult &&
												state.observations.gradeFilters.includes(
													'4',
												) &&
												msccObs.structuralScore >= 80 &&
												msccObs.structuralScore <= 164
											)
												msccResult = true;

											if (
												!msccResult &&
												state.observations.gradeFilters.includes(
													'5',
												) &&
												msccObs.structuralScore >= 165
											)
												msccResult = true;
										}
									}

									if (
										!msccResult &&
										(state.observations.typeFilters
											?.length == 0 ||
											state.observations.typeFilters?.includes(
												FilterObservationGradeType.Service,
											))
									) {
										if (
											msccObs.serviceScore !== undefined
										) {
											if (
												state.observations.gradeFilters.includes(
													'1',
												) &&
												msccObs.serviceScore < 1
											)
												msccResult = true;

											if (
												!msccResult &&
												state.observations.gradeFilters.includes(
													'2',
												) &&
												msccObs.serviceScore >= 1 &&
												msccObs.serviceScore <= 1.9
											)
												msccResult = true;

											if (
												!msccResult &&
												state.observations.gradeFilters.includes(
													'3',
												) &&
												msccObs.serviceScore >= 2 &&
												msccObs.serviceScore <= 4.9
											)
												msccResult = true;

											if (
												!msccResult &&
												state.observations.gradeFilters.includes(
													'4',
												) &&
												msccObs.serviceScore >= 5 &&
												msccObs.serviceScore <= 9.9
											)
												msccResult = true;

											if (
												!msccResult &&
												state.observations.gradeFilters.includes(
													'5',
												) &&
												msccObs.serviceScore >= 10
											)
												msccResult = true;
										}
									}

									if (!msccResult) return false;
								}
								break;
							case InspectionStandard.MACP:
								{
									const macpObs =
										observation as MACPObservation;
									if (
										state.observations.stepOnly &&
										!macpObs.step
									)
										return false;

									let macpResult = false;
									if (
										state.observations.typeFilters
											?.length == 0 ||
										state.observations.typeFilters?.includes(
											FilterObservationGradeType.Struct,
										)
									) {
										if (
											macpObs.structuralScore !==
												undefined &&
											state.observations.gradeFilters.includes(
												macpObs.structuralScore.toString(),
											)
										)
											macpResult = true;
									}

									if (
										!macpResult &&
										(state.observations.typeFilters
											?.length == 0 ||
											state.observations.typeFilters?.includes(
												FilterObservationGradeType.OM,
											))
									) {
										if (
											macpObs.serviceScore !==
												undefined &&
											state.observations.gradeFilters.includes(
												macpObs.serviceScore.toString(),
											)
										)
											macpResult = true;
									}

									if (!macpResult) return false;
								}
								break;

							case InspectionStandard.PACP:
							default:
								{
									const pacpObs =
										observation as PACPObservation;

									let pacpResult = false;
									if (
										state.observations.typeFilters
											?.length == 0 ||
										state.observations.typeFilters?.includes(
											FilterObservationGradeType.Struct,
										)
									) {
										if (
											pacpObs.structuralScore !==
												undefined &&
											state.observations.gradeFilters.includes(
												pacpObs.structuralScore.toString(),
											)
										)
											pacpResult = true;
									}

									if (
										!pacpResult &&
										(state.observations.typeFilters
											?.length == 0 ||
											state.observations.typeFilters?.includes(
												FilterObservationGradeType.OM,
											))
									) {
										if (
											pacpObs.serviceScore !==
												undefined &&
											state.observations.gradeFilters.includes(
												pacpObs.serviceScore.toString(),
											)
										)
											pacpResult = true;
									}

									if (!pacpResult) return false;
								}
								break;
						}
					}
					return true;
				})
				.sort((a, b) => {
					return a.distance - b.distance;
				});
		} else {
			return [];
		}
	});
};

export const jointOnlySelector = createSelector(
	observationStoreSelector,
	state => state?.jointOnly,
);
export const stepOnlySelector = createSelector(
	observationStoreSelector,
	state => state?.stepOnly,
);
export const continuousOnlySelector = createSelector(
	observationStoreSelector,
	state => state?.continuousOnly,
);
export const gradeFiltersSelector = createSelector(
	observationStoreSelector,
	state => state?.gradeFilters ?? [],
);
export const typeFiltersSelector = createSelector(
	observationStoreSelector,
	state => state?.typeFilters ?? [],
);

export const selectObservations = (standard: InspectionStandard) =>
	useSelector(observationSelector(standard));
export const selectJointOnly = () => useSelector(jointOnlySelector);
export const selectStepOnly = () => useSelector(stepOnlySelector);
export const selectContinuousOnly = () => useSelector(continuousOnlySelector);
export const selectGradeFilters = () => useSelector(gradeFiltersSelector);
export const selectTypeFilters = () => useSelector(typeFiltersSelector);

export default {
	observationSelector,
	selectObservations,
	selectJointOnly,
	selectStepOnly,
	selectContinuousOnly,
	selectGradeFilters,
	selectTypeFilters,
};
