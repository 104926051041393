import styled from 'styled-components';
import OriginalStockChart from '../../atoms/StockChart';
import { SingleChartSeries } from '../../atoms/StockChart/StockChart.styles';
import { gaugeChartFont, gaugeChartPalette } from '../../../utils';

export const GaugeChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: ${gaugeChartPalette.background};
`;

export const AtomicChartWrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  padding: 1rem;
  flex: 1 1 auto;
  min-height: 0;
  z-index: 1;
`;

export const StockChartWrapper = styled.div<{ noPadding: boolean }>`
  box-sizing: border-box;
  padding: ${(props) => (props.noPadding ? '0' : '1rem')};
`;

export const StockChartToggleButton = styled.button`
  all: unset;
  display: flex;
  align-items: center;
  cursor: pointer;

  .lctb-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${gaugeChartPalette.background};
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 1rem;
    border-radius: 0.2rem;
    background-color: ${gaugeChartPalette.text};
  }

  .lctb-label {
    font-family: ${gaugeChartFont};
    font-size: 0.8rem;
    font-weight: 500;
    color: ${gaugeChartPalette.text};
    text-transform: uppercase;
  }
`;

export const StockChart = styled(OriginalStockChart)`
  ${SingleChartSeries} {
    min-height: 0 !important;
  }
`;
