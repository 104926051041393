import styled from 'styled-components';

export const PieChartWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1rem;
  width: 100%;
  background-color: white;
`;

export const ChartContainer = styled.div`
  flex: 1 1 auto;
  min-height: 0;
  width: 100%;
`;
