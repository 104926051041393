import * as Contexts from './chart.contexts';
import * as Modules from './chart.modules';
import * as React from 'react';
import * as Styled from './chart.styled';
import BoostModule from 'highcharts/modules/boost';
import Highcharts from 'highcharts/highstock';
import styled from 'styled-components';
import type { Chart, Options } from 'highcharts';
import type { InstanceRecord } from './chart.contexts';
import type { ReactElement, ReactNode } from 'react';
import moment from 'moment-timezone';
import { useSettings } from '@innovyze/stylovyze';
import {
  DateRange,
  LocalizationProvider,
  SingleInputDateRangeFieldProps,
} from '@mui/x-date-pickers-pro';
import { AdapterLuxon } from '@mui/x-date-pickers-pro/AdapterLuxon';
import { Button, TextField, useForkRef } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import { DateTime } from 'luxon';
import { useGlobalization } from '../../../i18n';
import {
  DateTimeRangePicker,
  DateTimeRangePickerSlotOptions,
  singleInputDateTimeRangePickerSlotOptions,
} from '../../inputs/date-time-range-picker';
import { useInstance } from './chart.hooks';

type DateRangeMillis = [number | null, number | null];

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Highcharts Modules
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

BoostModule(Highcharts);
Modules.Synchronized(Highcharts);

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 *	Chart Root Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export type RootProps = {
  children: ReactNode;
};

export const Root = (props: RootProps): ReactElement => {
  const instanceRecordRef = React.useRef<InstanceRecord>({});
  const containerElementRef = React.useRef<HTMLDivElement>(null);

  /**
   * Modules.Synchronized disables the native pointer reset.
   *
   * This effect implements the same feature: hides the tooltip and crosshair
   * when the mouse leaves the chart, but since we may have many synchronized
   * charts on the same tree, the action gets triggered when the mouse leaves
   * the root container.
   */
  React.useEffect(() => {
    const mouseLeaveEventHandler = () => {
      Object.values(instanceRecordRef.current).forEach((instance) => {
        if (instance !== undefined) {
          if (instance?.options && instance?.pointer) {
            instance.series.forEach(function (series) {
              instance?.tooltip?.hide?.();
              instance?.xAxis[0]?.hideCrosshair?.();
              series?.points?.forEach((point) => {
                point.setState?.('');
              });
            });
          }
        }
      });
    };

    containerElementRef.current?.addEventListener(
      'mouseleave',
      mouseLeaveEventHandler
    );

    return () => {
      containerElementRef.current?.removeEventListener(
        'mouseleave',
        mouseLeaveEventHandler
      );
    };
  }, []);

  /**
   * Triggers a resize event whenever the div element resizes.
   * This is needed to force highcharts to repaint the actual chart.
   */
  React.useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver(
      (entries: ResizeObserverEntry[]) => {
        window.requestAnimationFrame((): void | undefined => {
          if (!Array.isArray(entries) || !entries.length) {
            return;
          }
          window.dispatchEvent(new Event('resize'));
          containerElementRef?.current?.dispatchEvent(new Event('resize'));
        });
      }
    );

    if (containerElementRef.current) {
      resizeObserver.observe(containerElementRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const [syncedDateTimeRange, setSyncedDateTimeRange] =
    React.useState<DateRangeMillis>([null, null]);
  const [chartWidth, setChartWidth] = React.useState<number | null>(null);

  const rootTimeRangeContext = React.useMemo(
    () => ({
      chartWidth,
      syncedDateTimeRange,
      syncDateTimeRangePicker: setSyncedDateTimeRange,
    }),
    [chartWidth, syncedDateTimeRange]
  );

  React.useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      const width = entries.at(0)?.borderBoxSize.at(0)?.inlineSize;
      setChartWidth(typeof width !== 'undefined' ? width : null);
    });

    resizeObserver.observe(containerElementRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <Contexts.RootContainerElementRef.Provider value={containerElementRef}>
      <Contexts.RootInstanceRecordRef.Provider value={instanceRecordRef}>
        <Contexts.RootTimeRange.Provider value={rootTimeRangeContext}>
          <Styled.Root ref={containerElementRef}>{props.children}</Styled.Root>
        </Contexts.RootTimeRange.Provider>
      </Contexts.RootInstanceRecordRef.Provider>
    </Contexts.RootContainerElementRef.Provider>
  );
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Chart Instance Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export type InstanceProps = {
  children: ReactNode;
  id: string;
  initialOptions: Options;
};

export const Instance = React.forwardRef<
  { chart: Highcharts.Chart | undefined },
  InstanceProps
>((props, ref): ReactElement => {
  const rootInstanceRecord = React.useContext(Contexts.RootInstanceRecordRef);

  const containerElementRef = React.useRef<HTMLDivElement>(null);
  const initialOptionsRef = React.useRef(props.initialOptions);
  const instanceRef = React.useRef<Chart>();
  const { companySettings } = useSettings();

  React.useLayoutEffect(() => {
    const containerElement = containerElementRef.current;

    if (containerElement) {
      instanceRef.current = Highcharts.stockChart(
        containerElement,
        Highcharts.merge(
          {
            time: {
              getTimezoneOffset: function (timestamp) {
                return (
                  moment
                    .tz(timestamp, companySettings.timeZoneIANA)
                    .utcOffset() * -1
                );
              },
            },
          } as Highcharts.Options,
          initialOptionsRef.current
        )
      );

      rootInstanceRecord.current[props.id] = instanceRef.current;
    }
  }, [props.id, companySettings.timeZoneIANA]);

  React.useEffect(() => {
    return () => {
      instanceRef.current?.destroy();
      instanceRef.current = undefined;
      rootInstanceRecord.current[props.id] = undefined;
    };
  }, [props.id]);

  React.useImperativeHandle(
    ref,
    () => ({
      get chart() {
        return instanceRef.current;
      },
    }),
    []
  );

  return (
    <Contexts.InstanceRef.Provider value={instanceRef}>
      <Styled.Instance ref={containerElementRef} />
      {props.children}
    </Contexts.InstanceRef.Provider>
  );
});

Instance.displayName = 'Instance';

// # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #
// # # # Chart Header
// # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #

const ChartHeaderContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 24px;
`;

const CustomTextField = muiStyled(TextField)(() => ({
  width: 280,
  '& .MuiInputBase-root': {
    fontSize: '0.8rem',
    padding: '0 !important',
  },
  '& .MuiInputBase-input': {
    textAlign: 'right',
    padding: '0 !important',
    color: 'rgb(74, 96, 103);',
  },
  '& fieldset': {
    border: 'none',
  },
}));

const CustomButton = muiStyled(Button)(() => ({
  width: 'auto',
  fontSize: '12px',
  fontWeight: '700',
  padding: '2px 6px',
  border: 'none',
  backgroundColor: 'rgb(230, 235, 245)',
  color: 'rgb(74, 96, 103);',
  transform: 'translateY(-3px)',
  '&:hover': {
    border: 'none',
    backgroundColor: '#e1e4ea',
  },
}));

const DateTimeRangeToggle = React.forwardRef<
  HTMLElement,
  SingleInputDateRangeFieldProps<DateTime> & {
    setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  }
>((props, ref) => {
  const { t } = useGlobalization();
  const {
    setOpen,
    id,
    disabled,
    InputProps: { ref: containerRef } = {},
    inputProps: { 'aria-label': ariaLabel } = {},
  } = props;

  const handleRef = useForkRef(ref, containerRef);

  return (
    <CustomButton
      variant="outlined"
      id={id}
      disabled={disabled}
      ref={handleRef}
      aria-label={ariaLabel}
      onClick={() => setOpen?.((prev) => !prev)}>
      {t('Time Range')}
    </CustomButton>
  );
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
DateTimeRangeToggle.fieldType = 'single-input';
DateTimeRangeToggle.displayName = 'DateTimeRangeToggle';

const slotOptions: (options: {
  asButton: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => DateTimeRangePickerSlotOptions = (options) =>
  options.asButton
    ? {
        slots: { field: DateTimeRangeToggle },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        slotProps: { field: { setOpen: options.setOpen } as any },
      }
    : {
        slots: {
          ...singleInputDateTimeRangePickerSlotOptions.slots,
          textField: CustomTextField,
        },
        slotProps: {
          ...singleInputDateTimeRangePickerSlotOptions.slotProps,

          textField: {
            ...singleInputDateTimeRangePickerSlotOptions.slotProps.textField,
            hiddenLabel: true,
            size: 'small',
            placeholder: '-',
          },
        },
      };

export const ChartHeader = (): React.ReactElement => {
  const instance = useInstance();
  const { chartWidth, syncedDateTimeRange } = React.useContext(
    Contexts.RootTimeRange
  );
  const [open, setOpen] = React.useState(false);
  const asButton = chartWidth !== null && chartWidth < 600;

  const value = React.useMemo(() => {
    const dateRange = [null, null] as DateRange<DateTime>;
    if (
      syncedDateTimeRange[0] !== null &&
      syncedDateTimeRange[0] !== undefined
    ) {
      dateRange[0] = DateTime.fromMillis(syncedDateTimeRange[0]);
    }

    if (
      syncedDateTimeRange[1] !== null &&
      syncedDateTimeRange[1] !== undefined
    ) {
      dateRange[1] = DateTime.fromMillis(syncedDateTimeRange[1]);
    }

    return dateRange;
  }, [syncedDateTimeRange]);

  return (
    <ChartHeaderContainer>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <DateTimeRangePicker
          {...slotOptions({
            setOpen,
            asButton,
          })}
          calendars={2}
          value={value}
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          onAccept={(value) => {
            const min = value[0].isValid ? value[0].toMillis() : null;
            const max = value[1].isValid ? value[1].toMillis() : null;

            instance.get().forEach((i) => {
              i.xAxis[0]?.setExtremes(min, max, true, undefined, {
                trigger: 'rangeSelectorInput',
              });
            });
          }}
        />
      </LocalizationProvider>
    </ChartHeaderContainer>
  );
};
