import type * as Highcharts from 'highcharts';
import type { ScatterChartEventHandlers } from './scatter-chart';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Initial Options
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const initialOptions: Highcharts.Options = {
  chart: { type: 'scatter', height: null, zoomType: 'xy' },
  credits: { enabled: false },
  exporting: { enabled: false },
  title: { text: '' },
  plotOptions: {
    line: {
      marker: { enabled: false },
    },
  },
  colors: [
    '#00abd1',
    '#768D95',
    '#E66B45',
    '#EFB302',
    '#83BC3F',
    '#876CE7',
    '#017CA0',
    '#4A6067',
    '#AA0000',
    '#AA5D00',
    '#4B7E03',
    '#4C3DAD',
    '#61DDFF',
    '#A5BDC6',
    '#FF9C72',
    '#FFE54D',
    '#B6EF70',
    '#BAA6FF',
  ],
  yAxis: [
    {
      id: 'x',
      alignTicks: true,
      endOnTick: false,
      gridLineWidth: 0,
      opposite: false,
      showEmpty: false,
      startOnTick: false,
      title: { text: '', style: { color: '#00abd1' } },
    },
    {
      id: 'y',
      alignTicks: true,
      endOnTick: false,
      gridLineWidth: 0,
      opposite: false,
      showEmpty: false,
      startOnTick: false,
      title: { text: '', style: { color: '#00abd1' } },
    },
  ],
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Options with event handlers
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const makeOptionsWithEventHandlers = (
  options: Highcharts.Options,
  eventHandlersRef: React.MutableRefObject<ScatterChartEventHandlers>
): Highcharts.Options => {
  return {
    ...options,
    plotOptions: {
      ...options.plotOptions,
      series: {
        ...options.plotOptions?.series,
        events: {
          ...options.plotOptions?.series?.events,
          show: function (event) {
            if (
              event.target &&
              (!('baseSeries' in event.target) ||
                event.target.baseSeries === undefined) &&
              (event.type === 'show' || event.type === 'hide')
            ) {
              eventHandlersRef.current.onSeriesVisibilityChange?.call(
                this,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                event.target.options.id,
                'show'
              );
            }

            options.plotOptions?.series?.events?.show?.call(this, event);
          },
          hide: function (event) {
            if (
              event.target &&
              (!('baseSeries' in event.target) ||
                event.target.baseSeries === undefined) &&
              (event.type === 'show' || event.type === 'hide')
            ) {
              eventHandlersRef.current.onSeriesVisibilityChange?.call(
                this,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                event.target.options.id,
                'hide'
              );
            }

            options.plotOptions?.series?.events?.hide?.call(this, event);
          },
        },
      },
    },
  };
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export { initialOptions, makeOptionsWithEventHandlers };
