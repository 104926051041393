import { AnalyticFunctions } from './analyticFunction.types';
import { HistoricalChartDisplayOptions } from './historicalChart.types';
import { RangeSelection, HiddenSeries } from './chartState';
import { RawMeasureData } from './measureData.types';
import { Readings, Resolutions } from './sensor.types';
import * as InsightChart from '../_next/core/_insight-chart';
import { Theme } from '../_next/core/utils/theme-utils';

export interface CustomAnalyticSeries {
  id: string;
  name: string;
  unit?: string;
  data: RawMeasureData | undefined;
  channel: string;
  func: keyof typeof Readings | AnalyticFunctions;
  params?: Record<string, unknown>;
}

export enum intervalValues {
  'raw' = 'Raw',
  '15 minute' = '15 Minutes',
  '30 minute' = '30 Minutes',
  '1 hour' = 'Hourly',
  '1 day' = 'Daily',
  '1 week' = 'Weekly',
}

export enum funcValues {
  'average' = 'Average',
  'open' = 'Open',
  'high' = 'High',
  'low' = 'Low',
  'close' = 'Close',
}

export interface CustomAnalyticNode {
  id: string;
  name: string;
  channelId: string;
  interval: Resolutions;
  func?: keyof typeof Readings | AnalyticFunctions;
  params?: Record<string, unknown>;
}

export interface OverriddenNode {
  id: string;
  interval?: Resolutions;
  func?: keyof typeof Readings | AnalyticFunctions;
  params?: Record<string, unknown>;
}

export interface CustomAnalyticDisplayOptions
  extends HistoricalChartDisplayOptions {
  overlay: boolean;
  showMarkers?: boolean;
}

export interface CustomAnalyticChartConnected {
  id?: string;
  analyticId: string;
  hiddenNodes?: string[];
  alwaysFetchData?: boolean;
  displayOptions: CustomAnalyticDisplayOptions;
  dataRangeSelection?: RangeSelection;
  rangeSelection?: RangeSelection;
  hiddenSeries?: HiddenSeries;
  onRangeSelectionChange?: (rangeSelection: RangeSelection) => void;
  onHiddenSeriesChange?: (hiddenSeries: HiddenSeries) => void;
  overrideNodes?: OverriddenNode[];
  customNodes?: {
    id: string;
    name: string;
    unit?: string;
    analytic: string;
    data: InsightChart.Data;
  }[];
  selectedTheme?: Theme;
}

export interface customAnalyticsResponse {
  [prop: string]: unknown;
  name: string;
  configGraph: {
    [prop: string]: unknown;
    nodes: {
      [prop: string]: unknown;
      channelId: string;
      name: string;
      tenantId: string;
      interval: keyof typeof intervalValues;
      _id: string;
      seriesType?: keyof typeof funcValues;
    }[];
  };
}
