import React, { useCallback, useEffect, useRef } from 'react';
import { useEventListener } from '../hooks';
import { dispatchTargetedEvent } from '../map/utils';
import { FlyTo, GeocoderResult } from '../map/geocoder/types';
import { TextFieldProps as MuiTextFieldProps } from '@mui/material';
import {
	RESTRICT_SEARCH,
	SEARCH_CHANGED,
	SEARCH_FLYTO,
	SET_DEFAULT_SEARCH,
	STYLE_SEARCH,
} from '@Map/geocoder/constants';

export interface GeocoderProps {
	/** event fired when geocoder search result is selected */
	onSearchChange?: (result: GeocoderResult | null) => void;
	/** default search term for the geocoder */
	defaultSearch?: string;
	/** id used to link the geocoder with the map */
	id: string;
	/** class name
	 * @deprecated use TextFieldProps instead
	 */
	className?: string;
	/** input label */
	label?: string;
	/** Fly to search options: always, never, onSelect, defaultSearch
	 * @default FlyTo.always
	 */
	flyTo?: FlyTo;
	/** restrict to map bounds
	 * @default true
	 */
	restrictToBounds?: boolean;
	/** props to pass through to inner TextField component */
	TextFieldProps?: MuiTextFieldProps;
}

/**
 * Geocoder search for use with map,
 * this allows placing the geocoder in part of the app
 * and maintain the interaction with the map
 */
const Geocoder = ({
	onSearchChange,
	defaultSearch,
	id,
	label,
	flyTo,
	restrictToBounds,
	TextFieldProps = {},
}: GeocoderProps): JSX.Element => {
	const geocoder = useRef<HTMLDivElement>(null);

	useEffect(() => {
		dispatchTargetedEvent(geocoder.current, SEARCH_FLYTO, flyTo);
	}, [flyTo]);

	useEffect(() => {
		dispatchTargetedEvent(
			geocoder.current,
			RESTRICT_SEARCH,
			restrictToBounds,
		);
	}, [restrictToBounds]);

	const searchChanged = useCallback(
		e => {
			const result = e.detail;
			if (onSearchChange) onSearchChange(result);
		},
		[onSearchChange],
	);

	useEventListener(SEARCH_CHANGED, searchChanged, geocoder);

	useEffect(() => {
		if (!defaultSearch) return;
		dispatchTargetedEvent(
			geocoder.current,
			SET_DEFAULT_SEARCH,
			defaultSearch,
		);
	}, [defaultSearch]);

	useEffect(() => {
		dispatchTargetedEvent(geocoder.current, STYLE_SEARCH, {
			...TextFieldProps,
			label,
		});
	}, [TextFieldProps, label]);

	return <div id={id} ref={geocoder} data-cy="geocoder"></div>;
};

export default Geocoder;
