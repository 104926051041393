import { fakeBorderBottom, fakeFocusBackground, rem } from '@Components/styles';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TreeItem } from '@mui/x-tree-view';
import styled from 'styled-components';

export const StyledTreeItem = styled(TreeItem)`
	&:hover {
		& > .MuiTreeItem-content {
			${fakeFocusBackground}
		}
	}
	.MuiTreeItem-content {
		position: relative;
		${fakeBorderBottom}
	}
	.MuiTreeItem-label {
		background-color: transparent !important;
	}
	.MuiTreeItem-iconContainer {
		position: relative;
		margin-left: ${rem(15)};
		width: ${rem(20)};
		svg {
			font-size: ${rem(24)};
		}
	}
	.MuiTreeItem-content {
		&.Mui-selected {
			background: none;
			${fakeFocusBackground}
			&:before {
				background: #c4c4c430;
			}
		}
	}
`;

export const ExpandIcon = styled(ExpandMoreIcon)`
	width: 1em;
	height: 1em;
`;

export const CollapseIcon = styled(ExpandLessIcon)`
	width: 1em;
	height: 1em;
`;
