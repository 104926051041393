import { addNamespace } from '@Utils/actions';
import { createAction } from '@reduxjs/toolkit';
import {
	ReactFlowDecisionTree,
	RehabTreePublishStateType,
} from '@Types/rehab.types';
import { ResolvedResponse } from '@Types/pagination.types';
import { Status } from '@Types/general.types';

export const getRehabTrees = createAction(addNamespace('getRehabTrees'));
export const getRehabTreesRejected = createAction(
	addNamespace('getRehabTreesRejected'),
);
export const getRehabTreesResolved = createAction<ReactFlowDecisionTree[]>(
	addNamespace('getRehabTrees/resolved'),
);

export const getRehabResult = createAction<{ treeId: string; assetId: string }>(
	addNamespace('getRehabResult'),
);

export const getRehabResultResolved = createAction<ResolvedResponse>(
	addNamespace('getRehabResult/resolved'),
);

export const getRehabResultRejected = createAction<Status>(
	addNamespace('getRehabResult/rejected'),
);

export const toggleRehabTreePublishState = createAction<{
	treeId: string;
	newPublishState: RehabTreePublishStateType;
	cb?: () => void;
}>(addNamespace('toggleRehabTreePublishState'));

export const toggleRehabTreePublishStateResolved = createAction(
	addNamespace('toggleRehabTreePublishState/resolved'),
);

export const toggleRehabTreePublishStateRejected = createAction<Status>(
	addNamespace('toggleRehabTreePublishState/rejected'),
);
