/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  useIsFeatureEnabled,
  useSelectSensors,
  useSettings,
} from '@innovyze/stylovyze';
import * as InsightChart from '../../core/_insight-chart';
import * as PumpStatusChart from '../../modules/pump-status-chart';
import * as React from 'react';
import * as SeriesData from '../../core/series-data';
import * as TimeSeriesData from '../../core/time-series-data';
import * as TimeSeriesDataOld from '../../core/time-series-data-old';
import { fixCollectionInterval } from '../../core/time-series-data/utils';

type EdgeSource = {
  sensorId: string;
  resolution: TimeSeriesDataOld.Resolution;
  reading: TimeSeriesDataOld.Reading;
};

function edgeSourceStringifier(source: EdgeSource): string {
  return `${source.sensorId}:${source.resolution}:${source.reading}`;
}

function edgeSeriesStringifier(series: InsightPumpStatusChartSeries): string {
  return edgeSourceStringifier({
    sensorId: series.sensorId,
    resolution: series.resolution,
    reading: series.reading,
  });
}

function limit(value: string | null | undefined, defaultValue = 500): number {
  if (typeof value === 'string' || value === null) return defaultValue;
  if (isNaN(Number(value))) return defaultValue;
  return Number(value);
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Chart Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

interface InsightPumpStatusChartProps
  extends Omit<PumpStatusChart.PumpStatusChartRootProps, 'children'> {
  series: InsightPumpStatusChartSeries[];
  timeRangeSelection?: InsightChart.TimeRangeSelection;
  xAxisGroup: Omit<PumpStatusChart.PumpStatusChartXAxisGroupProps, 'children'>;
  xAxis: PumpStatusChart.PumpStatusChartXAxisProps[];
  yAxisGroup: Omit<PumpStatusChart.PumpStatusChartYAxisGroupProps, 'children'>;
  yAxis: PumpStatusChart.PumpStatusChartYAxisProps[];
}

const InsightPumpStatusChart = React.forwardRef<
  { chart: Highcharts.Chart | undefined },
  InsightPumpStatusChartProps
>((props, ref): React.ReactElement => {
  const { companySettings } = useSettings();
  const { sensors, initialized: sensorsInitialized } = useSelectSensors();
  const useV3 = useIsFeatureEnabled('info-360-edge-analytics-parquet-files');
  const dataLimit = useIsFeatureEnabled('info-360-analytics-hp2-charts-limit');
  const edge = useIsFeatureEnabled(
    'info-360-edge-analytics-charts-pump-status'
  );

  const seriesData = TimeSeriesDataOld.useRetriever(
    props.series.filter((series) => !series.customData && series.sensorId)
  );

  const [edgeSources] = SeriesData.useSources<EdgeSource>(() => {
    const series = props.series?.filter((s) => !s.customData && s.sensorId);
    if (!series?.length) return [];
    return series.map(({ sensorId, resolution, reading }) => ({
      sensorId,
      resolution,
      reading,
    }));
  }, [props.series]);

  const [edgeData, edgeStatus, retrieveEdgeData] = SeriesData.useRetriever<
    TimeSeriesData.ResponseBody,
    { timeSelection?: TimeSeriesData.PartialTimeSelection }
  >(
    async (signal, params) => {
      if (!edgeSources?.length || !sensorsInitialized) return;

      const timeSelection = {
        from: params.timeSelection?.from ?? 'oldest',
        to: params.timeSelection?.to ?? 'latest',
      } as TimeSeriesData.TimeSelection;

      const response = await TimeSeriesData.retrieve(signal, {
        order: 'asc',
        timeSelection,
        limit: limit(dataLimit),
        timeZone: companySettings.timeZoneIANA,
        data_version: useV3 ? 'v3.0' : 'v2',
        snapping: 'oldest',
        sources: edgeSources.map((s) => {
          const _sensor = sensors.find((_s) => _s.sensorId === s.sensorId);
          const seconds = fixCollectionInterval(_sensor?.collectionInterval);

          return {
            key: edgeSourceStringifier(s),
            sensorId: s.sensorId,
            collectionInterval: { seconds },
            analytic: TimeSeriesData.makeAnalytic(
              s.resolution ?? '15-MINUTE',
              s.reading ?? 'Close'
            ),
          };
        }),
      });

      return response.data;
    },
    [
      companySettings.timeZoneIANA,
      dataLimit,
      edgeSources,
      sensors,
      sensorsInitialized,
      useV3,
    ]
  );

  // Handles data fetching with node's API
  const { retrieve } = seriesData;
  React.useEffect(() => {
    if (edge) return;
    retrieve({
      limit: { from: 'end' },
      timeSelection: {
        start: props.timeRangeSelection?.min,
        end: props.timeRangeSelection?.max,
      },
    });
  }, [
    retrieve,
    edge,
    props.timeRangeSelection?.min,
    props.timeRangeSelection?.max,
  ]);

  // Handles data fetching with edge analytics' API
  React.useEffect(() => {
    if (!edge) return;
    if (!sensorsInitialized) return;

    retrieveEdgeData({
      timeSelection: {
        from: props.timeRangeSelection?.min,
        to: props.timeRangeSelection?.max,
      },
    });
  }, [
    edge,
    sensorsInitialized,
    props.timeRangeSelection?.max,
    props.timeRangeSelection?.min,
    retrieveEdgeData,
  ]);

  return (
    <PumpStatusChart.PumpStatusChartRoot
      ref={ref}
      onSeriesVisibilityChange={props.onSeriesVisibilityChange}>
      <PumpStatusChart.PumpStatusChartXAxisGroup {...props.xAxisGroup}>
        {props.xAxis.map((xAxisProps, xAxisIndex) => {
          return (
            <PumpStatusChart.PumpStatusChartXAxis
              {...xAxisProps}
              key={xAxisProps.id || xAxisIndex}
            />
          );
        })}
      </PumpStatusChart.PumpStatusChartXAxisGroup>
      <PumpStatusChart.PumpStatusChartYAxisGroup {...props.yAxisGroup}>
        {props.yAxis.map((yAxisProps, yAxisIndex) => {
          return (
            <PumpStatusChart.PumpStatusChartYAxis
              {...yAxisProps}
              key={yAxisProps.id || yAxisIndex}
            />
          );
        })}
      </PumpStatusChart.PumpStatusChartYAxisGroup>
      <PumpStatusChart.PumpStatusChartSeriesGroup
      selectedTheme={props.selectedTheme}
        status={edge ? edgeStatus : seriesData.status}
        error={seriesData.error}>
        {props.series.map((seriesProps, seriesIndex) => {
          const key = edgeSeriesStringifier(seriesProps);
          const edgeDataEntry = edgeData?.results?.[key];
          return (
            <InsightPumpStatusChartSeries
              {...seriesProps}
              key={seriesProps.id || seriesIndex}
              seriesData={seriesData}
              customData={seriesProps.customData}
              edge={!!edge}
              edgeDataEntry={edgeDataEntry}
              selectedTheme={props.selectedTheme}
            />
          );
        })}
      </PumpStatusChart.PumpStatusChartSeriesGroup>
    </PumpStatusChart.PumpStatusChartRoot>
  );
});

InsightPumpStatusChart.displayName = 'InsightPumpStatusChart';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 *  Series Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

type InsightPumpStatusChartSeries = InsightChart.InsightChartSeries<
  PumpStatusChart.PumpStatusChartSeriesProps,
  {
    sensorId: string;
    resolution: InsightChart.Resolution;
    reading?: InsightChart.Reading;
    customData?: InsightChart.SensorDataEntry;
  }
>;

type InsightPumpStatusChartSeriesProps = InsightPumpStatusChartSeries & {
  customData?: InsightChart.SensorDataEntry;
  seriesData?: ReturnType<typeof TimeSeriesDataOld.useRetriever>;
  edge?: boolean;
  edgeDataEntry?: TimeSeriesData.ResponseResultEntry;
};

const InsightPumpStatusChartSeries = (
  props: InsightPumpStatusChartSeriesProps
): React.ReactElement => {
  const { getRawSensorDataMap } = props.seriesData ?? {};

  const processedData: PumpStatusChart.PumpStatusChartSeriesData =
    React.useMemo(() => {
      let _data: TimeSeriesDataOld.TimeSeriesData | undefined = undefined;
      const reading = props.reading ?? 'Close';

      if (props.customData) {
        const d = props.customData.data?.size
          ? Array.from(props.customData.data.entries())
          : undefined;
        _data = InsightChart.processDataWithReading(d, reading);
      } else if (!props.edge) {
        const s = { sensorId: props.sensorId, resolution: props.resolution };
        const m = getRawSensorDataMap?.(s);
        const d = m?.size ? Array.from(m.entries()) : undefined;
        _data = InsightChart.processDataWithReading(d, reading);
      } else {
        _data = props.edgeDataEntry?.data as TimeSeriesDataOld.TimeSeriesData;
      }

      if (!_data?.length) return [];

      const rangesMap = new Map<number, number>();
      let rangeStart: number | undefined = undefined;

      for (const d of _data) {
        const [timestamp, value] = d;

        if (value) {
          if (rangeStart === undefined) {
            rangeStart = timestamp;
            rangesMap.set(timestamp, timestamp);
          } else {
            rangesMap.set(rangeStart, timestamp);
          }
        } else {
          rangeStart = undefined;
        }
      }

      return Array.from(rangesMap.entries());
    }, [
      getRawSensorDataMap,
      props.customData,
      props.edgeDataEntry,
      props.edge,
      props.reading,
      props.resolution,
      props.sensorId,
    ]);

  return (
    <PumpStatusChart.PumpStatusChartSeries {...props} data={processedData} />
  );
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export { InsightPumpStatusChart };

export type { InsightPumpStatusChartProps, InsightPumpStatusChartSeries };
