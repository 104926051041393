import { addNamespace } from '@Utils/actions';
import { createAction } from '@reduxjs/toolkit';
import { ChartType, InspectionStandard, Status } from '@Types';

export const getCharts = createAction<{
	chartType: ChartType;
	chartId: string;
	standard: InspectionStandard;
	query?: string;
	assetFields?: string;
	month?: string;
}>(addNamespace('charts'));

export const getChartsResolved = createAction<{
	chartId: string;
	result: unknown;
}>(addNamespace('charts/resolved'));

export const getChartsRejected = createAction<{
	chartId: string;
	result: Status;
}>(addNamespace('charts/rejected'));

export const clearChart = createAction<string>(addNamespace('chart/clear'));

export const clearCharts = createAction(addNamespace('charts/clear'));
