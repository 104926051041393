import {
  DateTimeRangePickerProps,
  MultiInputDateTimeRangeField,
  SingleInputDateTimeRangeField,
} from '@mui/x-date-pickers-pro';
import { DateTime } from 'luxon';

export type DateTimeRangePickerSlotOptions = Pick<
  DateTimeRangePickerProps<DateTime>,
  'slots' | 'slotProps'
>;

export const multiInputDateTimeRangePickerSlotOptions = {
  slots: { field: MultiInputDateTimeRangeField },
  slotProps: {
    textField: { variant: 'outlined', size: 'medium', fullWidth: true },
  },
} satisfies DateTimeRangePickerSlotOptions;

export const singleInputDateTimeRangePickerSlotOptions = {
  slots: { field: SingleInputDateTimeRangeField },
  slotProps: {
    textField: { variant: 'outlined', size: 'medium', fullWidth: true },
  },
} satisfies DateTimeRangePickerSlotOptions;

export const defaultDateTimeRangePickerSlotOptions =
  multiInputDateTimeRangePickerSlotOptions;
