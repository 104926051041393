import {
	Count,
	InnerWrapper,
	VisibilityButton,
	VisibilityOff,
	VisibilityOn,
	Wrapper,
} from './LayerItem.styles';
import React, { MouseEvent } from 'react';

import { ConfigLayerInternal } from '@Root';
import { TextOverflow } from '@Components/TextOverflow';
import { getGradientBackgroundForLayer } from '@Map/layers/settings';
import { DisplayType, IconType } from './LayerItem/DisplayType';

interface LayerItemProps {
	children: string;
	onToggleVisibility: (state: boolean) => void;
	layerId: string;
	config: ConfigLayerInternal;
	tabIndex?: number;
	layerOn?: boolean;
	color?: string;
	outlineColor?: string;
	fillOpacity?: number;
	pattern?: string;
	lineDashArray?: number[];
	displayType: IconType;
	count?: number;
	canToggle?: boolean;
	iconImage?: string;
}

const LayerItem = ({
	children: layerName,
	onToggleVisibility,
	config,
	tabIndex,
	layerOn = true,
	outlineColor,
	pattern,
	lineDashArray,
	displayType,
	count,
	canToggle = true,
	color,
	iconImage,
	fillOpacity,
}: LayerItemProps): JSX.Element => {
	const toggleVisibility = (e: MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		onToggleVisibility(!layerOn);
	};
	const ariaLabel =
		(layerOn ? 'Turn off' : 'Turn on') + ` ${layerName} layer`;

	const gradient = getGradientBackgroundForLayer(config);

	return (
		<Wrapper data-cy={`layer-name-${layerName}`}>
			<InnerWrapper>
				{displayType !== 'none' && (
					<DisplayType
						type={displayType}
						color={color}
						gradient={gradient}
						pattern={pattern}
						lineDashArray={lineDashArray}
						iconImage={iconImage}
						outlineColor={outlineColor}
						fillOpacity={fillOpacity}
					/>
				)}
				<TextOverflow>{layerName}</TextOverflow>
				{count && <Count>[{count}]</Count>}
				{canToggle && (
					<VisibilityButton
						onClick={toggleVisibility}
						$layerOn={layerOn}
						tabIndex={tabIndex}
						aria-label={ariaLabel}
						data-cy="visibility-toggle">
						{layerOn ? <VisibilityOn /> : <VisibilityOff />}
					</VisibilityButton>
				)}
			</InnerWrapper>
		</Wrapper>
	);
};

export default React.memo(LayerItem);
