import {
	Adaptor,
	AdaptorTypes,
	ConfigLayer,
	ConfigOverrides,
} from '@Map/types';

export default abstract class ExternalConfigAdaptor {
	static _type: AdaptorTypes;
	static _defaults: ConfigOverrides[] = [];
	static _endpointPathname = '';
	_overrides: ConfigOverrides[];

	constructor(_adaptor: Adaptor) {
		this._overrides = _adaptor.overrides;
	}

	/**
	 * Creates a special object for the configuration to process later
	 * @param config base configuration
	 * @param baseEndpoint external configuration base endpoint
	 * @param overrides configuration settings to override based on asset and system type match
	 * @returns configuration object for map layers configuration
	 */
	static creator(
		config: ConfigLayer,
		baseEndpoint: string,
		overrides: ConfigOverrides[] = [],
	): ConfigLayer {
		return {
			...config,
			layersEndpoint: `${baseEndpoint}${this._endpointPathname}`,
			_adaptor: {
				type: this._type,
				overrides,
			},
		};
	}

	abstract converter(data: unknown): Partial<ConfigLayer>;
}
