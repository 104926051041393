import React from 'react';
import { Readings } from '../../../types';
import * as InsightChart from '.';

/**
 * Subtract this value from endTimestamp if no _startTimestamp provided
 */
const defaultPeriod = 2628000; //1 month in unix

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useConstantData = () => {
  const getMinutesFromInterval = (interval: InsightChart.Resolution) => {
    switch (interval) {
      case 'RAW':
      case '15-MINUTE':
        return 15;
      case '30-MINUTE':
        return 30;
      case 'HOURLY':
        return 60;
      case 'DAILY':
        return 60 * 24;
      case 'WEEKLY':
        return 60 * 24 * 7;
      default:
        return 15;
    }
  };

  const generateConstantData = React.useCallback(
    (
      constantValue: number,
      _startTimestamp?: number,
      _endTimestamp?: number,
      _interval?: InsightChart.Resolution,
      _status?: 'idle' | 'loading' | 'rejected' | 'resolved'
    ): {
      data: InsightChart.Data;
      unit?: string | undefined;
    } => {
      const data: InsightChart.Data = [];

      // There may be some places where this function is used without passing _status.
      // To avoid breaking them, if _status is undefined we still generate constant values.
      if (_status === undefined || _status === 'resolved') {
        const endTimestamp = _endTimestamp ?? Date.now();
        const startTimestamp = _startTimestamp ?? endTimestamp - defaultPeriod;
        const interval = _interval ?? '15-MINUTE';

        const valueMap: Map<InsightChart.Reading, InsightChart.Measure> =
          new Map([
            [Readings.Average, constantValue],
            [Readings.Close, constantValue],
            [Readings.High, constantValue],
            [Readings.Low, constantValue],
            [Readings.Open, constantValue],
            [Readings.Sum, constantValue],
          ]);

        const intervalInMinutes = getMinutesFromInterval(interval);

        for (
          let currentTimestamp = startTimestamp;
          currentTimestamp < endTimestamp;
          currentTimestamp += intervalInMinutes * 60 * 1000
        ) {
          data.push([currentTimestamp, valueMap]);
        }

        data.push([endTimestamp, valueMap]);
      }

      return { data };
    },
    []
  );

  return {
    generateConstantData,
  };
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export { useConstantData };
