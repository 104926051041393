import {
	AssetPropertiesProps,
	AssetsTab,
	OtherTab,
	SensorPropertiesProps,
	SensorsTab,
} from '..';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import StyledPropertyPane, {
	Actions,
	StyledStylovyzeTabs,
	tabsMargin,
} from './PropertyPane.styles';

import { AlertProperties } from '@Components/AlertProperties';
import { Close } from '@mui/icons-material';
import { FacilityProperties } from '@Components/FacilityProperties';
import { FeatureItem } from '@Utils/types';
import { IconButton } from '@mui/material';
import { IncidentProperties } from '@Components/IncidentProperties';
import { MapContext } from '../../context/Map';
import { TabGroup } from '@innovyze/stylovyze';
import _ from 'lodash';
import { getLayout } from '@Actions/map';
import { notFalsy } from '@Utils/helpers';
import { useDispatch } from 'react-redux';
import { useGlobalization } from '@Translations/useGlobalization';
import { PropertyPaneProps } from './PropertyPaneProps';

export enum TabTypes {
	alert = 'alert',
	asset = 'asset',
	sensor = 'sensor',
	incident = 'incident',
	spatial = 'spatial',
	facility = 'facility',
}

const defaultTabs = Object.values(TabTypes);

export interface Props extends PropertyPaneProps {
	/** Tabs to show in the property pane */
	tabsToDisplay?: TabTypes[];
	/** show inspection properties */
	showInspections?: boolean;
	/** function for view details button in menu of asset properties, button is only shown if function passed through */
	viewAssetDetails?: AssetPropertiesProps['viewDetails'];
	/** asset types to show view details button for */
	viewDetailsForAssetTypes?: AssetPropertiesProps['viewDetailsForAssetTypes'];
	/** function for view details on inspection, button is only shown if function passed through */
	viewInspection?: AssetPropertiesProps['viewInspection'];
	/** function for view details on inspection, button is only shown if function passed through */
	viewTask?: AssetPropertiesProps['viewTask'];
	/** function for view details on inspection, button is only shown if function passed through */
	viewEvent?: AssetPropertiesProps['viewEvent'];
	/** function to call from workspaces to add chart to current workspace */
	addChartToCurrentWorkspace?: SensorPropertiesProps['addChartToCurrentWorkspace'];
	/** current workspace id */
	currentWorkspaceId?: SensorPropertiesProps['currentWorkspaceId'];
}

const filterForAlertId = (
	alerts: FeatureItem[],
	resolvedAlert: string,
): string[] => {
	return alerts
		.filter(alert => {
			// the id in the property pane includes the sensor id
			// so removing this to match actual alert id
			const [alertId] = alert.id.split('-');
			return alertId === resolvedAlert;
		})
		.map(alert => alert.id);
};

const defaultWidth = 465;

const PropertyPane = ({
	items,
	deselectAssets,
	traceFromAsset,
	highlightAssets,
	zoomToAssets,
	tabsToDisplay = defaultTabs,
	showInspections,
	viewAssetDetails,
	viewDetailsForAssetTypes,
	viewInspection,
	viewTask,
	viewEvent,
	addChartToCurrentWorkspace,
	currentWorkspaceId,
}: Props): JSX.Element => {
	const { t } = useGlobalization();
	const dispatch = useDispatch();
	const ref = useRef<HTMLDivElement | null>(null);
	const [width, setWidth] = useState(defaultWidth);

	const {
		alerts,
		sensors,
		assets,
		incidents,
		spatial,
		facilities,
	} = useMemo(() => {
		const { alert, asset, sensor, incident, spatial, unknown, facility } = {
			alert: [],
			asset: [],
			sensor: [],
			incident: [],
			spatial: [],
			unknown: [],
			facility: [],
			..._.groupBy(items, 'type'),
		};
		return {
			alerts: alert,
			assets: asset,
			sensors: sensor,
			incidents: incident,
			facilities: facility,
			spatial: [...spatial, ...unknown],
		};
	}, [items]);

	const onAlertResolved = (alertId: string) => {
		const assetsToRemove = filterForAlertId(alerts, alertId);
		deselectAssets?.(assetsToRemove);
	};

	const displayTab = (tabType: TabTypes): boolean => {
		return tabsToDisplay.includes(tabType);
	};

	const deslectAll = () => {
		deselectAssets?.(items?.map((item: FeatureItem) => item.id) ?? []);
	};

	const alertsTab = (
		<>
			{alerts.map(
				(asset: FeatureItem, i: React.Key | null | undefined) => (
					<AlertProperties
						key={i}
						item={asset}
						onAlertResolved={onAlertResolved}
					/>
				),
			)}
		</>
	);

	const incidentsTab = (
		<>
			{incidents.map((incident, i) => (
				<IncidentProperties key={i} item={incident} />
			))}
		</>
	);

	const facilitiesTab = (
		<>
			{facilities.map((facility, i) => (
				<FacilityProperties key={i} item={facility} />
			))}
		</>
	);

	const tabs: TabGroup[] = useMemo(
		() =>
			[
				displayTab(TabTypes.alert) && {
					title: `${t('Alerts', {
						context: 'Property pane tab heading',
					})} [${alerts.length}]`,
					content: alertsTab,
					disabled: !alerts.length,
				},
				displayTab(TabTypes.incident) && {
					title: `${t('Incidents', {
						context: 'Property pane tab heading',
					})} [${incidents.length}]`,
					content: incidentsTab,
					disabled: !incidents.length,
				},
				displayTab(TabTypes.facility) && {
					title: `${t('Facilities', {
						context: 'Property pane tab heading',
					})} [${facilities.length}]`,
					content: facilitiesTab,
					disabled: !facilities.length,
				},
				displayTab(TabTypes.sensor) && {
					title: `${t('Sensors', {
						context: 'Property pane tab heading',
					})} [${sensors.length}]`,
					content: (
						<SensorsTab
							items={sensors}
							addChartToCurrentWorkspace={
								addChartToCurrentWorkspace
							}
							currentWorkspaceId={currentWorkspaceId}
						/>
					),
					disabled: !sensors.length,
				},
				displayTab(TabTypes.asset) && {
					title: `${t('Assets', {
						context: 'Property pane tab heading',
					})} [${assets.length}]`,
					content: (
						<AssetsTab
							items={assets}
							showInspections={showInspections}
							viewDetails={viewAssetDetails}
							viewDetailsForAssetTypes={viewDetailsForAssetTypes}
							viewInspection={viewInspection}
							viewTask={viewTask}
							viewEvent={viewEvent}
						/>
					),
					disabled: !assets.length,
				},
				displayTab(TabTypes.spatial) && {
					title: `${t('Spatial', {
						context: 'Property pane tab heading',
					})} [${spatial.length}]`,
					content: <OtherTab items={spatial} />,
					disabled: !spatial.length,
				},
			].filter(notFalsy),
		[alerts, incidents, sensors, assets, spatial],
	);

	useEffect(() => {
		if (ref.current) {
			// calculate width of property pane based on content of tabs
			const tablist = ref.current.querySelector('[role="tablist"]');
			const scrollWidth = tablist?.scrollWidth;
			const clientWidth = tablist?.clientWidth ?? defaultWidth;
			if (
				scrollWidth &&
				scrollWidth > defaultWidth &&
				scrollWidth > clientWidth
			) {
				setWidth(scrollWidth + tabsMargin);
			}
		}
	}, [ref.current, items]);

	useEffect(() => {
		dispatch(getLayout());
	}, []);

	return (
		<MapContext.Provider
			value={{
				traceFromAsset,
				deselectAssets,
				highlightAssets,
				zoomToAssets,
			}}>
			<StyledPropertyPane
				data-testing-id="property-pane"
				open={!!items && items.length > 0}
				style={{ width }}>
				<Actions>
					<IconButton onClick={deslectAll}>
						<Close />
					</IconButton>
				</Actions>
				{items && items.length > 0 && (
					<StyledStylovyzeTabs
						tabs={tabs}
						baseId="property-pane"
						dataCy="tabs"
						TabsProps={{ ref }}
					/>
				)}
			</StyledPropertyPane>
		</MapContext.Provider>
	);
};

export default React.memo(PropertyPane);
