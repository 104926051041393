import * as Highcharts from 'highcharts/highstock';
import { analyticFunctionMapOld, sortByTimeAscending } from '../../utils';
import {
  DEFAULT_CHART_COLORS,
  HistoricalSeries,
  dataGroupingFunctions,
  RangeSelection,
} from '../../types';
import { filterDataOutOfRange } from '../filterDataOutOfRange';

const overrideData = (
  data: [number, number][],
  dataOverride: [number, number][]
): [number, number][] => {
  let overrideIndex = 0;
  return data.map((datum) => {
    if (
      dataOverride.length !== overrideIndex &&
      datum[0] === dataOverride[overrideIndex][0]
    ) {
      overrideIndex++;
      return dataOverride[overrideIndex - 1];
    } else {
      return datum;
    }
  });
};

export const processRawMeasureData = (
  series: HistoricalSeries,
  index = 0,
  rangeSelection?: RangeSelection
): Highcharts.SeriesOptionsType => {
  const { data, displayOptions, alias, params, dataOverride = [] } = series;
  // TODO this should be memoized

  const filteredData = filterDataOutOfRange(data, rangeSelection);

  const processedData = filteredData
    ? analyticFunctionMapOld[series.func](filteredData, params)
    : [];

  // This may need to happen before data processing eventually but
  // that seems unnecessary for current use cases and thougher to
  // also handle readings in this process
  const overriddenData =
    dataOverride.length > 0
      ? overrideData(processedData, dataOverride)
      : processedData;

  let seriesConfig = {
    data: overriddenData.sort(sortByTimeAscending),
    type: displayOptions.chartType,
    name: alias,
    findNearestPointBy: 'x',
    color:
      displayOptions.color ||
      DEFAULT_CHART_COLORS[index % DEFAULT_CHART_COLORS.length],
    dataGrouping: {
      forced: true,
      approximation: dataGroupingFunctions[series.func],
    },
  } as Highcharts.SeriesOptionsType;

  if (series.id) seriesConfig.id = series.id;

  if (displayOptions.chartType === 'scatter') {
    seriesConfig = {
      ...seriesConfig,
      type: 'line',
      lineWidth: 0,
      states: {
        hover: {
          lineWidth: 0,
          lineWidthPlus: 0,
        },
      },
      marker: {
        enabled: true,
        radius: 4,
      },
    } as Highcharts.SeriesOptionsType;
  } else if (displayOptions.chartType === 'line') {
    seriesConfig = {
      ...seriesConfig,
      type: 'line',
      lineWidth: 2,
      states: {
        hover: {
          lineWidth: undefined,
          lineWidthPlus: 1,
        },
      },
      marker: {
        enabled: undefined,
        radius: 2,
      },
    } as Highcharts.SeriesOptionsType;
  }

  return seriesConfig;
};
