/* eslint-disable @typescript-eslint/no-explicit-any */
import { getAssetRisk, getAssetRiskHistory } from '@Actions/assetRisk.actions';
import { Grid } from '@mui/material';
import { AssetType } from '@Types/index';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Overall } from '../Overall';
import { RiskComponents } from '../RiskComponents';
import { Trend } from '../Trend';
import { usePanelStyles } from './RiskModelTabPanel.styles';

export const RiskModelTabPanel = (props: {
	activeTab: number;
	index: number;
	assetId: string;
	assetType: string;
	configId: string;
}): JSX.Element => {
	const { activeTab, index, assetId, assetType, configId } = props;
	const classes = usePanelStyles();
	const dispatch = useDispatch();

	useEffect(() => {
		if (activeTab === index) {
			dispatch(
				getAssetRisk({
					assetId: assetId,
					assetType: assetType,
					configId: configId,
				}),
			);
			dispatch(
				getAssetRiskHistory({
					assetId: assetId,
					assetType: assetType,
					configId: configId,
				}),
			);
		}
	}, [activeTab]);

	return (
		<div
			className={classes.root}
			role="tabpanel"
			hidden={activeTab !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}>
			{activeTab === index && (
				<div>
					<Grid container>
						<Grid item xs={12}>
							<Overall orientation="horizontal" />
						</Grid>
						<Grid item xs={12}>
							<RiskComponents />
						</Grid>
						<Grid item xs={12}>
							<Trend />
						</Grid>
					</Grid>
				</div>
			)}
		</div>
	);
};
