import { MapPosition, MapSelectedAssets } from '@innovyze/inno-map';
import {
	Geometry,
	Extent,
	geometriesExtent,
	centreExtent,
} from '@innovyze/shared-utils/lib/geometry/geojson';

export const selectedExtent = (
	assets: MapSelectedAssets,
): Extent | undefined => {
	const geometries = assets
		.map(a => ('geometry' in a ? (a['geometry'] as Geometry) : undefined))
		.filter(a => a !== undefined) as Geometry[];

	const extent = geometriesExtent(geometries);

	if (!extent) {
		return undefined;
	}

	return extent;
};

export const selectedCentre = (
	assets: MapSelectedAssets,
): [number, number] | undefined => centreExtent(selectedExtent(assets));

export const selectedPosition = (
	assets: MapSelectedAssets,
	zoom: number,
): MapPosition | undefined => {
	const center = selectedCentre(assets);
	return center
		? {
				center,
				zoom,
		  }
		: undefined;
};

export interface MapSelectedIds {
	id: string;
	layerId: string;
}

export const selectedAssets = (assets: MapSelectedAssets): MapSelectedIds[] =>
	assets
		.map(a => ({ id: a.id, layerId: a.layerId }))
		.filter(s => s?.id.length && s?.layerId.length);
