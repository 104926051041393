import { SeriesOptionsType } from 'highcharts/highstock';
import {
  DEFAULT_CHART_COLORS,
  RawMeasureDataMap,
  Readings,
  RangeSelection,
} from '../types';
import { PumpRuntimeSeries } from '../types/pumpRuntimeChart.types';
import { DateTime } from 'luxon';
import { filterDataOutOfRange } from './filterDataOutOfRange';

export const generatePumpRuntimeStockSeries = async (
  series: PumpRuntimeSeries[],
  displayType: 'hours' | 'percentage',
  timeRange: RangeSelection
): Promise<SeriesOptionsType[]> => {
  const stockSeries = series.map((s, index) => {
    const filteredData = filterDataOutOfRange(s.data || [], timeRange);
    let i = 0,
      milis = 0,
      prevMeasure = null;

    while (i < filteredData.length) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      const measureData = filteredData[i];
      const reading = measureData[RawMeasureDataMap[Readings.Close]];

      if (prevMeasure) {
        if (reading !== 0) {
          const diff = diffInMillis(measureData[0], prevMeasure[0]);
          milis += diff;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          prevMeasure = measureData;
        } else {
          // no reading cuts the measurable chunks
          prevMeasure = null;
        }
      } else {
        //No previous data
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (reading !== 0) prevMeasure = measureData;
        else prevMeasure = null;
      }

      i++;
    }

    let dataValue = milis ? milis / 1000 / 60 / 60 : 0;

    if (displayType === 'percentage' && filteredData.length) {
      const totalTime = diffInMillis(
        filteredData[filteredData.length - 1][0],
        filteredData[0][0]
      );

      dataValue = (milis * 100) / totalTime;
    }

    const data = series.map<number | string>((_) => '');
    data[index] = dataValue || 0;

    return {
      name: s.alias,
      type: 'column',
      color: DEFAULT_CHART_COLORS[index % DEFAULT_CHART_COLORS.length],
      pointWidth: 50,
      data,
    } as SeriesOptionsType;
  });

  return stockSeries;
};

const diffInMillis = (stamp1: number, stamp2: number) => {
  const date1 = DateTime.fromMillis(stamp1);
  const date2 = DateTime.fromMillis(stamp2);
  return date1.diff(date2).toObject().milliseconds || 0;

  //const diffInHours = diffInMillis? diffInMillis / 1000 / 60 / 60 : 0;
};
