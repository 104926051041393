import {
	clearFullInsp,
	getFullInsp,
	getFullInspRejected,
	getFullInspResolved,
	getNotes,
	getNotesRejected,
	getNotesResolved,
	setFullInspectionIsWaiting,
	setNotesIsWaiting,
} from '@Actions/fullInspection.actions';

import { AnyAction } from 'redux';
import { createReducer } from '@reduxjs/toolkit';
import { FullInspectionStoreState } from '@Types/fullInspection.types';

export const initialState: FullInspectionStoreState = {
	fullInspection: {
		_id: '',
		inspectionId: '-',
		validationState: '-',
		uploadedBy: '-',
		uploadDateTime: '-',
		missingMedia: false,
		isImperial: false,
		tenantId: '',
		inspectionStatus: '',
	},
	isWaiting: true,
	notesIsWaiting: false,
};

interface FullInspReducer {
	[x: string]: (
		state: FullInspectionStoreState,
		action: AnyAction,
	) => FullInspectionStoreState | undefined;
}

const reducer: FullInspReducer = {
	[getFullInsp.toString()]: () => ({
		...initialState,
		isWaiting: true,
	}),
	[getFullInspResolved.toString()]: (state, action) => ({
		...state,
		initialized: true,
		fullInspection: action.payload.inspection[0],
		notes: action.payload.notes,
		isWaiting: false,
	}),
	[getFullInspRejected.toString()]: () => ({
		...initialState,
		isWaiting: false,
	}),
	[getNotes.toString()]: state => ({
		...state,
		notesIsWaiting: true,
	}),
	[clearFullInsp.toString()]: () => ({
		...initialState,
		isWaiting: false,
	}),
	[getNotesResolved.toString()]: (state, action) => ({
		...state,
		notes: action.payload,
		notesIsWaiting: false,
	}),
	[getNotesRejected.toString()]: state => ({
		...state,
		notes: [],
		notesIsWaiting: false,
	}),
	[setFullInspectionIsWaiting.toString()]: (state, action) => ({
		...state,
		isWaiting: action.payload,
	}),
	[setNotesIsWaiting.toString()]: (state, action) => ({
		...state,
		notesIsWaiting: action.payload,
	}),
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const FullInspReducer = createReducer(initialState, reducer);

export default createReducer(initialState, reducer);
