import React, {
  forwardRef,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
} from 'react';
import { defaultChartOptions } from './Chart.utils';
import BoostModule from 'highcharts/modules/boost';
import Highcharts from 'highcharts';
import Styled from './Chart.styles';
import SynchronizedEventsModule from '../../utils/synchronizedChartsModule';
import useHighchartsModules from '../../hooks/useHighchartsModules';
import useTimeZone from '../../../utils/useTimeZone';
import useTooltipEvents from '../../hooks/useTooltipEvents';

import type { Ref, RefObject, ReactElement } from 'react';
import useTranslations from '../../../utils/useTranslations';
import { useGlobalization } from '../../../i18n/useGlobalization';

export type ConstructorType = 'chart' | 'stockChart';

export type OnChartDestroyedHandler = (
  chart: Highcharts.Chart,
  containerRef: RefObject<HTMLDivElement | null>
) => void;

export interface ChartProps {
  constructorType: ConstructorType;
  cy?: string;
  highcharts: typeof Highcharts;
  onChartDestroyed?: OnChartDestroyedHandler;
  options: Highcharts.Options;
  withoutTooltipEvents?: boolean;
  forceResetButtonOnLoad?: boolean;
}

export interface ChartRef {
  chart: Highcharts.Chart | null;
  containerRef: RefObject<HTMLDivElement | null>;
}

const Chart = (props: ChartProps, ref?: Ref<ChartRef>): ReactElement => {
  const chartRef = useRef<Highcharts.Chart | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  /*Translations */
  const { t } = useGlobalization();
  useTranslations(props.highcharts, t);

  /**
   * These are the modules that we need for highcharts.
   * Because these modify the library's code and not an instance,
   * once loaded, all the charts will "share" the same modules,
   * even if they don't need them.
   */
  useHighchartsModules(props.highcharts, [
    SynchronizedEventsModule,
    BoostModule,
  ]);

  /* Sets the company's timezone into the library. */
  useTimeZone(props.highcharts);

  /***
   * As mentioned, once the SynchronizedEventsModule module is
   * loaded, we have to manage showing / hidding the tooltips
   * as well as the markers once the mouse leaves the container.
   *
   * For stacked charts, the container is the stack container and
   * the second argument is an array of chart references, so we can
   * pass a flag to avoid setting the events at this level.
   */
  useTooltipEvents(containerRef, chartRef, props.withoutTooltipEvents);

  /**
   * When this component unmounts, we have to recreate the chart
   * to avoid any broken states on the chart.
   */
  useLayoutEffect(() => {
    if (containerRef.current === null) {
      throw new Error('The container element for the chart was not found');
    }

    if (chartRef.current !== null) {
      chartRef.current.destroy();
      chartRef.current = null;
    }

    chartRef.current = props.highcharts[props.constructorType](
      containerRef.current,
      Highcharts.merge<Highcharts.Options>(
        false,
        defaultChartOptions(t),
        props.options
      )
    );

    if (props.forceResetButtonOnLoad) {
      chartRef.current.showResetZoom();
    }

    window.dispatchEvent(new Event('resize'));

    return () => {
      if (chartRef.current) {
        props.onChartDestroyed?.(chartRef.current, containerRef);

        chartRef.current.destroy();
        chartRef.current = null;
      }

      window.dispatchEvent(new Event('resize'));
    };
  }, []);

  /**
   * Whenever the options prop changes, we have to update the chart
   * with the new options. Things like loading state, range selection,
   * and data updates are managed through the chart's API which can be
   * accessed through the ref object on the parent.
   */
  useLayoutEffect(() => {
    chartRef.current?.update(props.options, true, true, false);
    window.dispatchEvent(new Event('resize'));
  }, [JSON.stringify(props.options)]);

  /**
   * With this, we control the passed ref to the component to
   * expose the chart's API as well as the container ref to the parent.
   */
  useImperativeHandle(
    ref,
    () => {
      return {
        containerRef,
        get chart() {
          return chartRef.current;
        },
      };
    },
    []
  );

  useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver(
      (entries: ResizeObserverEntry[]) => {
        window.requestAnimationFrame((): void | undefined => {
          if (!Array.isArray(entries) || !entries.length) {
            return;
          }
          window.dispatchEvent(new Event('resize'));
        });
      }
    );

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []);

  return <Styled.Container ref={containerRef} data-cy={props.cy} />;
};

export default forwardRef<ChartRef, ChartProps>(Chart);
