import { CircularProgress, Grid } from '@mui/material';
import {
	HTMLInputElementExtended,
	HTMLTextAreaElementExtended,
	InfoCard,
	InputText,
	InputUnit,
	StylovyzeForm,
} from '@innovyze/stylovyze';
import React, { ChangeEvent, useEffect } from 'react';
import { SegmentInfo } from '@Types';
import { Tab, TabPanel, TabWrapper, Tabs } from '@Components/Tabs';
import { fixupGrid, getAssetValues, getSegmentValues } from '@Utils';
import {
	selectAssetDetailDetail,
	selectAssetDetailIsWaiting,
} from '@Selectors/assetDetail.selectors';

import { TFunc } from '@Translations/types';
import { selectAssetPipeAsset } from '@Selectors/assetPipe.selectors';
import { useGlobalization } from '@Translations';
import { CustomTables } from '@Components/AssetDetails/CustomTables';

const generalFields = (t: TFunc): { name: string; alias: string }[] => [
	{ name: 'assetId', alias: t('Asset ID') },
	{ name: 'usNodeId', alias: t('Upstream Node ID') },
	{ name: 'dsNodeId', alias: t('Downstream Node ID') },
	{ name: 'criticalit', alias: t('Criticality') },
	{ name: 'location', alias: t('Location') },
	{ name: 'owner', alias: t('Owner') },
	{ name: 'installedBy', alias: t('Installed By') },
	{ name: 'systemType', alias: t('System Type') },
	{ name: 'pipeType', alias: t('Pipe Type') },
	{ name: 'drainageCode', alias: t('Drainage Code') },
];

const dateFields = (t: TFunc): { name: string; alias: string }[] => [
	{ name: 'yearLaid', alias: t('Install Date') },
];

const dimensionFields = (t: TFunc): { name: string; alias: string }[] => [
	{ name: 'usInvert', alias: t('Upstream Invert') },
	{ name: 'usDepthFromCover', alias: t('Upstream Depth from Cover') },
	{ name: 'dsInvert', alias: t('Downstream Invert') },
	{ name: 'dsDepthFromCover', alias: t('Downstream Depth from Cover') },
	{ name: 'length', alias: t('Length') },
	{ name: 'capacity', alias: t('Capacity') },
];

const constructionFields = (t: TFunc): { name: string; alias: string }[] => [
	{ name: 'width', alias: t('Width') },
	{ name: 'height', alias: t('Height') },
	{ name: 'calculatedGradient', alias: t('Calculated Gradient') },
	{ name: 'calculatedBuriedDepth', alias: t('Calculated Buried Depth') },
	{ name: 'material', alias: t('Material') },
	{ name: 'liningType', alias: t('Lining Type') },
	{ name: 'liningMaterial', alias: t('Lining Material') },
	{ name: 'pipeShape', alias: t('Pipe Shape') },
];

const segmentFields = (t: TFunc): { name: string; alias: string }[] => [
	{ name: 'length', alias: t('Length') },
	{ name: 'startDistance', alias: t('Start Distance') },
	{ name: 'width', alias: t('Width') },
	{ name: 'height', alias: t('Height') },
	{ name: 'gradient', alias: t('Gradient') },
	{ name: 'material', alias: t('Material') },
	{ name: 'liningType', alias: t('Lining Type') },
	{ name: 'liningMaterial', alias: t('Lining Material') },
];

const hardCodeUnitFields: { [key: string]: string } = {
	length: 'm',
	usInvert: 'm',
	dsInvert: 'm',
	width: 'mm',
	height: 'mm',
	coverLevel: 'm',
	calculatedBuriedDepth: 'm',
	usDepthFromCover: 'm',
	dsDepthFromCover: 'm',
	capacity: 'm3/s',
	groundLevel: 'm',
	chamberFloorDepth: 'm',
	startDistance: 'm',
};

const getDomainValues = (t: TFunc, fieldName: string) => {
	let domainValueDic: { [key: string]: string } = {};
	switch (fieldName.toLowerCase()) {
		case 'material'.toLowerCase():
		case 'liningMaterial'.toLowerCase():
			domainValueDic = {
				BL: t('Bitumen (lining)'),
				CL: t('Cement Mortar (lining)'),
				CO: t('Concrete'),
				CS: t('Concrete Segments'),
				DI: t('Ductile Iron'),
				EP: t('Epoxy'),
				FC: t('Fibre Cement'),
				GI: t('Grey Cast Iron'),
				MAC: t('Masonry - Regular Courses'),
				MAR: t('Masonry - Randomly Coursed'),
				MX: t('Mixed Material Construction'),
				PF: t('Pitch Fibre'),
				PS: t('Polyester'),
				RC: t('Reinforced Concrete'),
				SPC: t('Sprayed Concrete'),
				ST: t('Steel'),
				VC: t('Vitrified Clay'),
				X: t('Unidentified Material'),
				XI: t('Unidentified Type of Iron or Steel'),
				XP: t('Unidentified Type of Plastics'),
				Z: t('Other'),
				ABS: t('Acrylonitrile Butadiene Styrene'),
				AC: t('Asbestos Cement'),
				BR: t('Brick'),
				CAS: t('Cast Iron'),
				CLC: t('Clay-lined Concrete Pipe'),
				CMP: t('Corrugated Metal Pipe'),
				CP: t('Concrete Pipe (non-reinforced)'),
				CSB: t('Concrete Segments (bolted)'),
				CSU: t('Concrete Segments (unbolted)'),
				CT: t('Clay Tile (not vitrified clay)'),
				DIP: t('Ductile Iron Pipe'),
				FRP: t('Fiberglass Reinforced Pipe'),
				OB: t('Pitch Fiber (Orangeburg)'),
				PCP: t('Polymer Concrete Pipe'),
				PCCP: t('Pre-stressed Concrete Cylinder Pipe'),
				PE: t('Polyethylene'),
				PP: t('Polypropylene'),
				PSC: t('Plastic/Steel Composite'),
				PVC: t('Polyvinyl Chloride'),
				RCP: t('Reinforced Concrete Pipe'),
				RPM: t('Reinforced Plastic Pipe (Truss Pipe)'),
				SB: t('Segmented Block'),
				SP: t('Steel Pipe'),
				VCP: t('Vitrified Clay Pipe'),
				WD: t('Wood'),
				XXX: t('Not Known'),
				ZZZ: t('Other'),
			};
			break;
		case 'liningType'.toLowerCase():
			domainValueDic = {
				M: t('Lining Inserted During Manufacture'),
				SP: t('Sprayed Lining/Segmented Pipe'),
				SEG: t('Segmental Linings'),
				DP: t('Lining with Discrete Pipes'),
				CP: t('Lining with Continuous Pipes'),
				CF: t('Close Fit Lining'),
				Z: t('Other'),
				CIP: t('Cured-In-Place Pipe'),
				FF: t('Fold and Form or Deform/Reform'),
				FP: t('Formed-In-Place Liner'),
				GP: t('Grout-In-Place Liner'),
				GRC: t('Glass Reinforced Cement'),
				N: t('None'),
				SC: t('Continuous Slip Liner'),
				SE: t('Sectional Slip Liner'),
				SL: t('Spray Liner'),
				SN: t('Segmented Panel'),
				SW: t('Spiral Wound'),
				XX: t('Not Known'),
				ZZ: t('Other'),
			};
			break;
		case 'pipeShape'.toLowerCase():
			domainValueDic = {
				A: t('Arched'),
				B: t('Barrel'),
				C: t('Circular'),
				E: t('Egg-shaped'),
				H: t('Horseshoe'),
				O: t('Oval'),
				R: t('Rectangular'),
				T: t('Trapezoidal'),
				U: t('U-shaped with Flat Top'),
				K: t('Kerb Block'),
				CSC: t('Circular with Smaller Channel'),
				RSC: t('Rectangular with Smaller Channel'),
				Z: t('Other'),
				S: t('Square'),
			};
			break;
		case 'pipeType'.toLowerCase():
			domainValueDic = {
				FM: t('Force Main'),
				GR: t('Gravity Connection'),
				IL: t('Inside Drop Lower'),
				IU: t('Inside Drop Upper'),
				LB: t('Lateral to Building'),
				OL: t('Outside Drop Lower'),
				OU: t('Outside Drop Upper'),
				XX: t('Not Known'),
				ZZ: t('Other'),
				G: t('Gravity'),
				P: t('Pressure'),
				O: t('Other'),
				A: t('Gravity Drain/Sewer'),
				B: t('Rising Main'),
				V: t('Vacuum'),
				L: t('Lateral'),
			};
			break;
		case 'owner'.toLowerCase():
			domainValueDic = {
				PR: t('Private'),
				PU: t('Public'),
				X: t('Not Known'),
			};
			break;
		case 'systemType'.toLowerCase():
			domainValueDic = {
				CB: t('Combined Pipe'),
				DP: t('Dam Pipe'),
				FM: t('Force Main'),
				LG: t('Levee Gravity Pipe'),
				LP: t('Levee Pressure Pipe'),
				PR: t('Process Pipe'),
				SS: t('Sanitary Sewage Pipe'),
				SW: t('Stormwater Pipe'),
				XX: t('Not Known'),
				ZZ: t('Other'),
				F: t('Foul'),
				S: t('Surface'),
				C: t('Combined'),
				T: t('Trade Effluent'),
				O: t('Overflow'),
				W: t('Culverted Watercourse'),
				LD: t('Subsoil or Field Drainage'),
				U: t('Unspecified'),
				Z: t('Other'),
			};
			break;
		default:
			break;
	}

	return domainValueDic;
};

function a11yProps(index: number) {
	return {
		id: `vertical-tab-${index}`,
		'aria-controls': `vertical-tabpanel-${index}`,
	};
}

export const PipeAttributes = (): JSX.Element => {
	const { t } = useGlobalization();
	const [value, setValue] = React.useState(0);
	const isLoading = selectAssetDetailIsWaiting();
	const asset = selectAssetPipeAsset();
	const details = getAssetValues(
		t,
		selectAssetDetailDetail(),
		getDomainValues,
	);
	const segmentAssetDetails = selectAssetPipeAsset()?.segments;
	const [segmentDetails, setSegmentDetails] = React.useState<SegmentInfo[]>();

	useEffect(() => {
		if (segmentAssetDetails) {
			const newSegmentDetails: SegmentInfo[] = [];
			for (let i = 0; i < segmentAssetDetails.length; i++) {
				newSegmentDetails[i] = getSegmentValues(
					t,
					segmentAssetDetails[i],
					getDomainValues,
				);

				fixupGrid(segmentFields(t), newSegmentDetails[i]);
			}
			setSegmentDetails(newSegmentDetails);
		}
	}, [segmentAssetDetails]);

	const handleChange = (event: unknown, newValue: number) => {
		setValue(newValue);
	};

	const getGrids = (
		fields: { name: string; alias: string }[],
		segment: boolean,
	) => {
		if (fields && fields.length > 0) {
			if (!segment) {
				fixupGrid(fields, details);
			}

			return fields.map(field => {
				if (hardCodeUnitFields[field.name]) {
					return (
						<Grid item xs={3} key={field.name}>
							<InputUnit
								fieldAttrs={{ name: field.name }}
								unitField={{
									label: field.alias,
									unit: hardCodeUnitFields[field.name],
									defaultValue: '',
									onChange: (
										e: ChangeEvent<
											| HTMLInputElementExtended
											| HTMLTextAreaElementExtended
										>,
									) => {
										console.log('onChange', e.target.value);
									},
								}}
								invalidDisplayValue={'-'}
							/>
						</Grid>
					);
				} else {
					return (
						<Grid item xs={3} key={field.name}>
							<InputText
								fieldAttrs={{ name: field.name }}
								textField={{
									label: field.alias,
								}}
							/>
						</Grid>
					);
				}
			});
		}

		return <></>;
	};

	const general = (
		<StylovyzeForm
			mode="view"
			initialValues={details}
			onSubmit={data => {
				console.log('should update the data', data);
			}}>
			{() => {
				return (
					<>
						<InfoCard fullHeight title={t('Definition')}>
							<Grid container>
								{getGrids(generalFields(t), false)}
							</Grid>
						</InfoCard>
						<InfoCard fullHeight title={t('Dates')}>
							<Grid container>
								{getGrids(dateFields(t), false)}
							</Grid>
						</InfoCard>
					</>
				);
			}}
		</StylovyzeForm>
	);

	const dimensions = (
		<StylovyzeForm
			mode="view"
			initialValues={details}
			onSubmit={data => {
				console.log('should update the data', data);
			}}>
			{() => {
				return (
					<>
						<InfoCard fullHeight title={t('Dimensions')}>
							<Grid container>
								{getGrids(dimensionFields(t), false)}
							</Grid>
						</InfoCard>
					</>
				);
			}}
		</StylovyzeForm>
	);

	const construction = (
		<StylovyzeForm
			mode="view"
			initialValues={details}
			onSubmit={data => {
				console.log('should update the data', data);
			}}>
			{() => {
				return (
					<>
						<InfoCard fullHeight title={t('Construction')}>
							<Grid container>
								{getGrids(constructionFields(t), false)}
							</Grid>
						</InfoCard>
					</>
				);
			}}
		</StylovyzeForm>
	);

	const segments = () => {
		if (segmentDetails) {
			return segmentDetails?.map(segment => {
				return (
					<StylovyzeForm
						key={segment.segmentId}
						mode="view"
						initialValues={segment}
						onSubmit={data => {
							console.log('should update the data', data);
						}}>
						{() => {
							return (
								<InfoCard
									fullHeight
									title={`${t('Segment')}: ${
										segment.segmentId
									}`}>
									<Grid container>
										{getGrids(segmentFields(t), true)}
									</Grid>
								</InfoCard>
							);
						}}
					</StylovyzeForm>
				);
			});
		} else {
			return <></>;
		}
	};

	return (
		<>
			{isLoading ? (
				<CircularProgress />
			) : (
				<TabWrapper $height={683}>
					<Tabs
						orientation="vertical"
						variant="scrollable"
						value={value}
						onChange={handleChange}
						aria-label="manhole-attributes">
						<Tab label={t('General and Dates')} {...a11yProps(0)} />
						<Tab label={t('Dimensions')} {...a11yProps(1)} />
						<Tab label={t('Construction')} {...a11yProps(2)} />
						<Tab label={t('Segments')} {...a11yProps(3)} />
						<Tab label={t('Custom Tables')} {...a11yProps(4)} />
					</Tabs>
					<TabPanel value={value} index={0}>
						{general}
					</TabPanel>
					<TabPanel value={value} index={1}>
						{dimensions}
					</TabPanel>
					<TabPanel value={value} index={2}>
						{construction}
					</TabPanel>
					<TabPanel value={value} index={3}>
						{segments()}
					</TabPanel>
					<TabPanel value={value} index={4}>
						<CustomTables
							userDefinedFields={asset?.userDefined}
							userDefinedFieldUnits={asset?.userDefinedUnits}
						/>
					</TabPanel>
				</TabWrapper>
			)}
		</>
	);
};

export default PipeAttributes;
