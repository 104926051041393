import { rem } from '@Components/styles';
import { Card } from '@mui/material';
import styled from 'styled-components';

interface PopupWrapperProps {
	$height: number | 'auto';
	$width: number;
	$x?: number;
	$y?: number;
}

export const PopupWrapper = styled.div<PopupWrapperProps>`
	position: absolute;
	width: ${({ $width }) => rem($width)};
	height: ${({ $height }) => ($height === 'auto' ? 'auto' : rem($height))};
	z-index: 10;
`;

export const StyledCard = styled(Card)`
	width: 100%;
	height: 100%;
`;
