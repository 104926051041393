import CompositeLayer from './CompositeLayer';
import { ConfigLayer } from '@Map/panel/types';

export default class LayerAdaptor {
	private _layers: CompositeLayer[];

	constructor(layers: CompositeLayer[]) {
		this._layers = layers;
	}

	generateConfig(): ConfigLayer[] {
		return this._layers.map(
			({
				displayName,
				serviceId,
				id,
				sourceLayer,
				icon,
				color,
				active,
				type,
			}) => ({
				layerName: displayName,
				dataServiceId: serviceId,
				tileLayerName: sourceLayer,
				visible: active,
				id,
				icon,
				color,
				type: type as ConfigLayer['type'],
			}),
		);
	}
}
