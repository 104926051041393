import MapboxLayer, { UniqueLayerProps } from './MapboxLayer';

import { ServiceLayer } from '@Map/services/types';

export default class RasterLayer extends MapboxLayer {
	protected _type: ServiceLayer['type'] = 'raster';

	layerProperties(): UniqueLayerProps {
		return {
			paint: {
				'raster-opacity': this._fillOpacity ?? MapboxLayer.FILL_OPACITY,
			},
		};
	}
}
