export enum CurveType {
  PRESSURE_CURVE = 'pump_curve',
  HEAD_CURVE = 'head_curve',
  POWER_CURVE = 'power_curve',
  EFFICIENCY_CURVE = 'efficiency_curve',
  SYSTEM_CURVE = 'system_curve',
}

export interface Asset {
  _id: string;
  curveId: string;
  curveType: string;
  assetType: CurveType;
  depthArray: number[];
  volumeArray: number[];
}

export interface AssetResponse {
  assets: Asset[];
  pagination: unknown;
}

export interface ConfigurationParams {
  configType?: string;
  limit?: number;
  offset?: number;
  sensorId?: string;
}

export interface Configuration {
  sensorId: string;
  collectionInterval?: string;
  unit?: string;
  sensorUnitConversion?: string;
  hydraulicModelElement?: string;
  hydraulicModelElementType?: string;
  useAbsoluteValue?: boolean;
  isUpdatableSensor?: boolean;
  allowUpdatableSensorCleanup?: boolean;
  tagValue?: string;
  isPhysical?: boolean;

  // deprecated
  enableTelemetry?: boolean;
  connectionString?: string;

  // ? extras
  sensorAlias?: string;
  latitude?: number;
  longitude?: number;
  tankType?: 'cylindrical' | 'non-cylindrical';
  tankOffset?: number;
  tankDiameter?: number;

  sourceType?: string;
  dataSourceType?: string;
  lastValue?: number;
  lastValueTimestamp?: string;
  resolutions?: string[];
}
