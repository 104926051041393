import { Coordinates, GeometryPoint, LineString } from '@Map/services/types';
import SelectionManager, { SortFunction } from '@Map/features/SelectionManager';

import Feature from '@Map/features/Feature';
import { GeoJSONFeature } from '@Map/features/types';
import { MapMouseEvent } from 'mapbox-gl';
import { debounce } from '@Map/utils';
import distance from '@turf/distance';
import logger from '@Map/logger/Logger';
import pointToLineDistance from '@turf/point-to-line-distance';

const DEFAULT_DEBOUNCE_TIME = 200;

export default class HoverPopup {
	private _selection = new SelectionManager();
	private _lastSelection: string[] = [];
	private _map: mapboxgl.Map;
	private _dispatchEvent;
	private _debouncedHover;

	constructor(
		map: mapboxgl.Map,
		dispatchEvent: <T>(eventName: string, detail?: T) => void,
		debounceTime?: number | string | null,
	) {
		this._map = map;
		this._dispatchEvent = dispatchEvent;
		const duration = parseFloat(`${debounceTime}`);
		const wait = isNaN(duration) ? DEFAULT_DEBOUNCE_TIME : duration;

		this._debouncedHover = debounce(this.hover, wait);
	}

	debouncedHover(...args: Parameters<HoverPopup['hover']>) {
		this._debouncedHover(...args);
	}

	cancelPopupUpdate() {
		this._debouncedHover.clear();
	}

	hover(e: MapMouseEvent, features?: GeoJSONFeature[]): void {
		this._lastSelection = [...this._selection.assetIds];
		const changes = this._selection.replaceAll(features);

		// if there are no features currently hovered over and
		// this has not changed from last time then return
		if (
			!this._selection.assetIds.length &&
			!changes.deleted &&
			!changes.added
		) {
			return;
		}

		// if there are features
		if (this._selection.assetIds.length) {
			// sort the features based on proximity to mouse cursor
			const sortFunction = this._createSortFunction(e.lngLat.toArray());
			this._selection.sortFunction = sortFunction;
			// check if the features once sorted are in the same order as before
			const sortChanged = this._sortChanged(
				this._selection.assetIds,
				this._lastSelection,
			);
			// if the sort is the same then return
			if (!sortChanged) return;
			const firstFeature = this._selection.featuresSorted[0];
			// get the position of the feature so popup will be placed to feature
			// and not mouse cursor (unless geometry isn't found)
			const geometry = firstFeature.geometry;
			const coords =
				firstFeature.type === 'Point'
					? (geometry as GeometryPoint).coordinates
					: null;
			const point = coords
				? this._map?.project(coords as [number, number])
				: e.point;
			const detail = {
				point,
				features: this._selection.assetProperties,
			};
			logger.debug('features in hover', features);
			logger.debug('hoverPopup', detail);
			this._dispatchEvent('hoverPopup', detail);
			return;
		}

		logger.debug('hoverpopup no features');
		this._dispatchEvent('hoverPopup', { features: undefined });
	}

	clear(): void {
		logger.debug('hoverpopup clear features');
		this._lastSelection = [];
		this._dispatchEvent('hoverPopup', { features: undefined });
	}

	/**
	 * Creates a function to be used by the selection manager to sort
	 * the features closest to the point passed in, and based on
	 * the zIndex of the layer the feature sits (higher the better)
	 */
	private _createSortFunction(point: Coordinates): SortFunction {
		return (a: Feature, b: Feature) => {
			if (
				!('coordinates' in a.geometry) ||
				!('coordinates' in b.geometry)
			)
				return 0;

			const distA = this._distanceTo(point, a);
			const distB = this._distanceTo(point, b);

			let sort = 0;
			if (distA < distB) sort -= 1;
			else if (distA > distB) sort += 1;
			if (a.layerZIndex > b.layerZIndex) sort -= 2;
			else if (a.layerZIndex < b.layerZIndex) sort += 2;

			return sort;
		};
	}

	/**
	 * Get distance between two points
	 */
	private _distanceTo(startPoint: Coordinates, feature: Feature): number {
		if (feature.type === 'Point') {
			const endPoint = (feature.geometry as GeometryPoint).coordinates;
			return distance(startPoint, endPoint, { units: 'meters' });
		}

		if (feature.type === 'LineString') {
			return pointToLineDistance(
				startPoint,
				feature.geometry as LineString,
				{ units: 'meters' },
			);
		}

		return Infinity;
	}

	/**
	 * Check if the sort of two arrays has changed
	 */
	private _sortChanged(assetIds: string[], oldAssetIds: string[]) {
		return !assetIds.every((value, k) => oldAssetIds[k] === value);
	}
}
