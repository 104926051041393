import { Tab as MuiTab, Tabs as MuiTabs } from '@mui/material';
import styled, { css } from 'styled-components';

import { rem } from '@Utils/styles';

export const TabWrapper = styled.div<{ $height?: number }>`
	display: flex;
	flex-grow: 1;
	background-color: ${({ theme }) => theme.palette.background.paper};
	${({ $height }) =>
		$height &&
		css`
			height: ${rem($height)};
		`}
`;

export const Tabs = styled(MuiTabs)`
	border-right: 1px solid ${({ theme }) => theme.palette.divider};
	width: ${rem(350)};
`;

export const Tab = styled(MuiTab)`
	align-items: start;
	.MuiTab-wrapper {
		align-items: start;
	}
`;
