import { Resolution } from '../../../core/types/resolution.types';

import type {
  MassBalance,
  MassBalanceSeries,
} from '../../../core/types/massBalance.types';

type ChannelProp = keyof MassBalance['zones'][number];

const channelPropToId: Record<ChannelProp, MassBalanceSeries['id']> = {
  inflowChannelId: 'inflow',
  outflowChannelId: 'outflow',
  storageChannelId: 'storage',
  usageChannelId: 'usage',
  billingChannelId: 'billing',
  nrwChannelId: 'nrw',
  nrwRatioChannelId: 'nrwRatio',
};

export const massBalanceIntervalToResolution: Record<
  MassBalance['interval'],
  Resolution
> = {
  '15 minute': Resolution['15 Minutes'],
  '1 day': Resolution['Daily'],
  '1 hour': Resolution['Hourly'],
  '1 min': Resolution['Raw'],
};

export const generateMassBalanceSeries = (
  massBalance: MassBalance
): MassBalanceSeries[] => {
  const { interval, zones } = massBalance;
  const zone = zones[0];

  const massBalanceSeries: MassBalanceSeries[] = [];

  Object.keys(channelPropToId).forEach((channelProp) => {
    const sensorId = zone[channelProp as ChannelProp];

    if (sensorId) {
      massBalanceSeries.push({
        id: channelPropToId[channelProp as ChannelProp],
        dataSource: {
          resolution: massBalanceIntervalToResolution[interval],
          sensorId,
        },
      });
    }
  });

  return massBalanceSeries;
};
