import {
	StatusTag,
	StylovyzeTable,
	rem,
	StylovyzeCard,
	palette,
} from '@innovyze/stylovyze';
import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { StylovyzeHeader, ValidationData, ValidationStatus } from '@Types';
import { useGlobalization } from '@Translations';

interface Props {
	dataCy: string;
	validationData?: ValidationData[];
}

const ValidationTable = ({ dataCy, validationData }: Props): JSX.Element => {
	const { t } = useGlobalization();
	const [rows, setRows] = useState<ValidationData[]>([]);

	useEffect(() => {
		setRows(validationData ? validationData : []);
	}, [validationData]);

	const counts: number[] = [0, 0, 0];

	const statusCell = (status: string) => {
		switch (status.toLowerCase()) {
			case ValidationStatus.Error:
				return (
					<StatusTag
						text={t('Error')}
						color="danger"
						width={'100%'}
					/>
				);
			case ValidationStatus.Warning:
				return (
					<StatusTag
						text={t('Warning')}
						color="warning"
						width={'100%'}
					/>
				);
			case ValidationStatus.Information:
				return (
					<StatusTag
						text={t('Information')}
						color="standard"
						width={'100%'}
					/>
				);
		}
	};

	const headerReactNodes = (): StylovyzeHeader[] => {
		return [
			{
				key: 'severity',
				cell: t('Severity'),
				sortable: true,
				align: 'left',
				width: '130px',
			},
			{
				key: 'code',
				cell: t('Code'),
				sortable: true,
				align: 'left',
				width: '80px',
			},
			{
				key: 'target',
				cell: t('Target'),
				sortable: true,
				align: 'left',
				width: '100px',
			},
			{
				key: 'message',
				cell: t('Message'),
				sortable: true,
				align: 'left',
			},
		];
	};

	const rowReactNodes = (row: ValidationData): React.ReactNode[] => {
		const cells: React.ReactNode[] = [4];
		cells[0] = statusCell(row.type);
		cells[1] = row.errorCode;
		cells[2] = row.target;
		cells[3] = row.errorMessage;
		return cells;
	};

	validationData?.map(row => {
		switch (row.type.toLowerCase()) {
			case ValidationStatus.Error:
				counts[0]++;
				break;
			case ValidationStatus.Warning:
				counts[1]++;
				break;
			case ValidationStatus.Information:
				counts[2]++;
				break;
		}
	});
	const sortRows = (
		rows: ValidationData[],
		name: React.ReactText,
		order: string,
	) => {
		let newRows: ValidationData[] = [];

		if (rows?.length != 0) {
			if (name == '') {
				newRows = [...rows];
			} else {
				switch (name) {
					case 'severity':
						{
							if (order === 'asc') {
								newRows = rows
									.slice()
									.sort(
										(
											a: ValidationData,
											b: ValidationData,
										) => a.type.localeCompare(b.type),
									);
							} else {
								newRows = rows
									.slice()
									.sort(
										(
											a: ValidationData,
											b: ValidationData,
										) => b.type.localeCompare(a.type),
									);
							}
						}
						break;
					case 'code':
						{
							if (order === 'asc') {
								newRows = rows
									.slice()
									.sort(
										(
											a: ValidationData,
											b: ValidationData,
										) =>
											a.errorCode.localeCompare(
												b.errorCode,
											),
									);
							} else {
								newRows = rows
									.slice()
									.sort(
										(
											a: ValidationData,
											b: ValidationData,
										) =>
											b.errorCode.localeCompare(
												a.errorCode,
											),
									);
							}
						}
						break;
					case 'target':
						{
							if (order === 'asc') {
								newRows = rows
									.slice()
									.sort(
										(
											a: ValidationData,
											b: ValidationData,
										) =>
											a.errorCode.localeCompare(
												b.errorCode,
											),
									);
							} else {
								newRows = rows
									.slice()
									.sort(
										(
											a: ValidationData,
											b: ValidationData,
										) =>
											b.errorCode.localeCompare(
												a.errorCode,
											),
									);
							}
						}
						break;
					case 'message':
						{
							if (order === 'asc') {
								newRows = rows
									.slice()
									.sort(
										(
											a: ValidationData,
											b: ValidationData,
										) =>
											a.errorMessage.localeCompare(
												b.errorMessage,
											),
									);
							} else {
								newRows = rows
									.slice()
									.sort(
										(
											a: ValidationData,
											b: ValidationData,
										) =>
											b.errorMessage.localeCompare(
												a.errorMessage,
											),
									);
							}
						}
						break;
				}
			}
		}

		return newRows;
	};

	const onSort = (
		_e: React.MouseEvent<unknown, MouseEvent>,
		name: React.ReactText,
		order: string,
	) => {
		setRows(sortRows(rows, name, order));
	};

	return (
		<Grid container spacing={2}>
			<Grid xs={4} item>
				<StylovyzeCard
					countText={counts[0]}
					height={35}
					titlePosition="right"
					topText={t('Errors')}
					topColor={palette.error.dark}
				/>
			</Grid>
			<Grid xs={4} item>
				<StylovyzeCard
					countText={counts[1]}
					height={35}
					titlePosition="right"
					topText={t('Warnings')}
					topColor={palette.warning.dark}
				/>
			</Grid>
			<Grid xs={4} item>
				<StylovyzeCard
					countText={counts[2]}
					height={35}
					titlePosition="right"
					topText={t('Information')}
					topColor={palette.primary.dark}
				/>
			</Grid>
			<Grid xs={12} item>
				<StylovyzeTable
					className="ScrollTable"
					dataCy={dataCy}
					headers={headerReactNodes()}
					rows={
						rows.map((row: ValidationData, nRow: number) => ({
							key: nRow,
							cells: rowReactNodes(row),
						})) || []
					}
					rowHeight={rem(40)}
					sort={{
						onRequestSort: (_e, name, order) => {
							onSort(_e, name, order);
						},
					}}
				/>
			</Grid>
		</Grid>
	);
};

export default ValidationTable;
