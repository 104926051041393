import { DataServiceType, TileJson } from './types';
import {
	Info360TileMetadata,
	Info360TileMetadataV2,
	Layer,
	LayerV2,
} from './Info360TileTypes';
import { LngLatBounds, LngLatLike } from 'mapbox-gl';
import { convertCoordsToBounds, notUndefined } from '@Map/utils';

import Info360TileService from './Info360TileService';
import MapboxDataSource from './MapboxDataSource';

export default class Info360TileServiceV2 extends Info360TileService {
	protected _bounds: LngLatBounds | undefined;

	constructor(dataService: DataServiceType<Info360TileMetadataV2>) {
		// hacky but no nice way of getting correct types
		super(dataService as DataServiceType<Info360TileMetadata>);
	}

	get bounds(): LngLatBounds | undefined {
		return this._bounds;
	}

	get dataEndpoint(): string | null {
		return `${this.endpoint}/v2/tileset`;
	}

	parseResponse(data: Info360TileMetadataV2): Info360TileMetadata | null {
		const layers = Object.values(data.systems).flat() as Layer[];
		return {
			...data,
			layers,
		};
	}

	createDataSources(data: Info360TileMetadata & Info360TileMetadataV2): void {
		if (!data.systems) return;

		const dataSources = Object.entries(
			data.systems,
		).flatMap(([systemType, entry]) =>
			this.isTileJson(entry)
				? this._mapTileJson(entry as TileJson, systemType)
				: this._mapTileMetaLayers(entry as LayerV2[]),
		);

		if (data?.bounds?.southWest && data?.bounds?.northEast) {
			this._bounds = new LngLatBounds(
				data.bounds.southWest,
				data.bounds.northEast,
			);
		} else {
			const coords = Object.values(data.systems)
				.flatMap(entry => {
					if (this.isTileJson(entry)) {
						const [
							swLng,
							swLat,
							neLng,
							neLat,
						] = (entry as TileJson).bounds;
						return [
							[swLng, swLat],
							[neLng, neLat],
						];
					}
				})
				.filter(notUndefined) as LngLatLike[];
			this._bounds = convertCoordsToBounds(coords);
		}

		this._dataSources = dataSources;
	}

	protected isTileJson(entry: TileJson | LayerV2[]) {
		return !Array.isArray(entry);
	}

	protected _mapTileJson(tileJson: TileJson, systemType: string) {
		return new MapboxDataSource({
			type: tileJson.type ?? 'vector',
			id: systemType,
			minZoom: this._parseZoom(tileJson.minzoom),
			maxZoom: this._parseZoom(tileJson.maxzoom),
			tileUrl: tileJson.tiles,
		});
	}

	protected _mapTileMetaLayers(tileLayers: LayerV2[]) {
		return tileLayers.map(l => this.mapTileLayer(l));
	}

	protected getLayerId(layer: LayerV2): string {
		return `${layer.systemType}-${layer.type}`;
	}

	private _parseZoom(zoom: string | number | undefined) {
		if (!zoom) return;
		return +zoom;
	}
}
