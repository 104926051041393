import {
	CompanySettings,
	getDateTimeFormat,
	iso8601DateToTimeZone,
	iso8601DateToLocal,
	FormatDateOptions,
	GridFilterModel,
} from '@innovyze/stylovyze';
import { DateTime } from 'luxon';

/** @deprecated switch to using formatDateTimeWithFormatDate to use the useCompanyDateTime-FormatDate */
export const formatDateTime = (
	dateTime: string,
	companySettings: CompanySettings,
	applyTimezoneConversion?: boolean,
	returnEmpty?: boolean,
): string | null => {
	try {
		if (dateTime.trim().length === 0 || dateTime.startsWith('1111-11-11')) {
			return returnEmpty ? '-' : '';
		} else {
			if (dateTime.length > 10) {
				// This somewhat ugly but expediant until we change to
				// using useCompayDateTime()
				// If string is datetime could be missing T and Z formatters - so add them in
				dateTime = dateTime.trimEnd().replace(' ', 'T');
				dateTime = dateTime.replace('+00:00', '');

				if (!dateTime.endsWith('Z')) dateTime = dateTime + 'Z';
			}

			if (!dateTime) {
				return returnEmpty ? '-' : '';
			}

			const timezone = companySettings?.timeZoneIANA;

			const format = getDateTimeFormat(
				companySettings?.dateFormat,
				companySettings?.hourCycle12,
			);

			if (timezone && applyTimezoneConversion) {
				return iso8601DateToTimeZone(dateTime, timezone, format);
			}

			return iso8601DateToLocal(dateTime, format);
		}
	} catch {
		return returnEmpty ? '-' : '';
	}
};

/** @deprecated switch to using formatDateWithFormatDate to use the useCompanyDateTime-FormatDate */
export const formatDate = (
	dateTime: string,
	companySettings: CompanySettings,
	applyTimezoneConversion?: boolean,
	returnEmpty?: boolean,
): string | null => {
	try {
		if (dateTime.trim().length === 0 || dateTime.startsWith('1111-11-11')) {
			return returnEmpty ? '-' : '';
		} else {
			if (!dateTime) {
				return returnEmpty ? '-' : '';
			}

			// The format is (currently) fixed as 'date - time' so we can just split it out and get the correct date format
			const format = getDateTimeFormat(
				companySettings?.dateFormat,
				companySettings?.hourCycle12,
			);
			const formatParts = format.split(' - ');

			const timezone = companySettings?.timeZoneIANA;

			if (timezone && applyTimezoneConversion) {
				return iso8601DateToTimeZone(
					dateTime,
					timezone,
					formatParts[0],
				);
			}

			return iso8601DateToLocal(dateTime, formatParts[0]);
		}
	} catch {
		return returnEmpty ? '-' : '';
	}
};

/** @deprecated switch to using formatTimeWithFormatDate to use the useCompanyDateTime-FormatDate */
export const formatTime = (
	dateTime: string,
	companySettings: CompanySettings,
	applyTimezoneConversion?: boolean,
	returnEmpty?: boolean,
): string | null => {
	try {
		if (!dateTime) {
			return returnEmpty ? '-' : '';
		}

		if (dateTime.startsWith('1111-11-11')) {
			return returnEmpty ? '-' : '';
		} else {
			// The format is (currently) fixed as 'date - time' so we can just split it out and get the correct date format
			const format = getDateTimeFormat(
				companySettings?.dateFormat,
				companySettings?.hourCycle12,
			);
			const formatParts = format.split(' - ');

			const timezone = companySettings?.timeZoneIANA;

			if (timezone && applyTimezoneConversion) {
				return iso8601DateToTimeZone(
					dateTime,
					timezone,
					formatParts[formatParts.length - 1],
				);
			}

			return iso8601DateToLocal(
				dateTime,
				formatParts[formatParts.length - 1],
			);
		}
	} catch {
		return returnEmpty ? '-' : '';
	}
};

/**
 * Fixup date time strings for use in InputDateTime to allow for naive or system locale dates
 * @param dateTime the date time to adjust
 * @param currentDateTime optional if adjusting to system locale dates as supplied by useCompanyDateTime() -> currentDateTime()
 * @param returnEmpty true to return '-' for empty or invalid date times
 * @returns an adjusted datetime string
 */
export const fixupDateTimeForInputDateTime = (
	dateTime: string,
	currentDateTime?: DateTime,
	returnEmpty?: boolean,
): string => {
	try {
		if (
			dateTime === undefined ||
			dateTime.trim().length === 0 ||
			dateTime === '1111-11-11T00:00:00.000Z'
		) {
			return returnEmpty ? '-' : '';
		} else {
			if (dateTime.length > 10) {
				dateTime = dateTime.trimEnd().replace(' ', 'T');
				dateTime = dateTime.replace('+00:00', '');
				dateTime = dateTime.replace('Z', '');
			}

			// Adjust the date time time zone if needed
			if (currentDateTime !== undefined) {
				// Get the correct offset based on the actuall date time to account for BST
				let filterZone = DateTime.fromISO(dateTime + 'Z');
				filterZone = filterZone.setZone(currentDateTime.zone);
				const offset = filterZone.offset * 60000;
				if (offset !== 0) {
					// Add the offset to get to the currentDateTime time zone
					const filterDate = new Date(dateTime + 'Z');
					filterDate.setTime(filterDate.getTime() + offset);
					return filterDate.toISOString().substring(0, 16);
				} else {
					return dateTime;
				}
			} else {
				return dateTime;
			}
		}
	} catch {
		return returnEmpty ? '-' : '';
	}
};

/**
 * Format a dateTime string to a Date+Time according the the formatDate object
 * @param dateTime the date string to convert
 * @param formatDate the formatDate object from useCompanyDateTime() or useTzDateTime({ timeZone: 'UTC' }) for displaying naive dates
 * @param returnEmpty if the date is empty then true to return the empty '-', false to return ''
 * @param keepLocalTime the functionality of this will depend on where formatDate is from but probably not needed.
 * @returns a formatted date string or '-'/''
 */
export const formatDateTimeWithFormatDate = (
	dateTime: string,
	formatDate: (options: FormatDateOptions) => string,
	returnEmpty?: boolean,
	keepLocalTime?: boolean,
): string | null => {
	// Check for empty dates
	let fixup = fixupDateTimeForInputDateTime(dateTime);
	if (fixup !== '') {
		// Is it actually a year object?
		if (fixup.endsWith('11-11T11:11:11')) {
			return fixup.substring(0, 4);
		} else {
			// Add the Z back otherwise formatDate has odd behaviour with the keepLocalTime
			fixup += 'Z';

			return formatDate({
				date: fixup,
				format: 'date-time',
				zoneOptions: { keepLocalTime: keepLocalTime },
				dateTimeSeparator: ' - ',
			});
		}
	} else {
		return returnEmpty ? '-' : '';
	}
};

/**
 * Format a dateTime string to a Date according the the formatDate object
 * @param dateTime the date string to convert
 * @param formatDate the formatDate object from useCompanyDateTime() or useTzDateTime({ timeZone: 'UTC' }) for displaying naive dates
 * @param returnEmpty if the date is empty then true to return the empty '-', false to return ''
 * @param keepLocalTime the functionality of this will depend on where formatDate is from but probably not needed.
 * @returns a formatted date string or '-'/''
 */
export const formatDateWithFormatDate = (
	dateTime: string,
	formatDate: (options: FormatDateOptions) => string,
	returnEmpty?: boolean,
	keepLocalTime?: boolean,
): string | null => {
	// Check for empty dates
	let fixup = fixupDateTimeForInputDateTime(dateTime);
	if (fixup !== '') {
		// Add the Z back otherwise formatDate has odd behaviour with the keepLocalTime
		fixup += 'Z';

		return formatDate({
			date: fixup,
			format: 'date',
			zoneOptions: { keepLocalTime: keepLocalTime },
		});
	} else {
		return returnEmpty ? '-' : '';
	}
};

/**
 * Format a dateTime string to a Time according the the formatDate object
 * @param dateTime the date string to convert
 * @param formatDate the formatDate object from useCompanyDateTime() or useTzDateTime({ timeZone: 'UTC' }) for displaying naive dates
 * @param keepLocalTime the functionality of this will depend on where formatDate is from but probably not needed.
 * @param returnEmpty if the date is empty then true to return the empty '-', false to return ''
 * @returns a formatted date string or '-'/''
 */
export const formatTimeWithFormatDate = (
	dateTime: string,
	formatDate: (options: FormatDateOptions) => string,
	keepLocalTime?: boolean,
	returnEmpty?: boolean,
): string | null => {
	// Check for empty dates
	let fixup = fixupDateTimeForInputDateTime(dateTime);
	if (fixup !== '') {
		// Add the Z back otherwise formatDate has odd behaviour with the keepLocalTime
		fixup += 'Z';

		return formatDate({
			date: fixup,
			format: 'time',
			zoneOptions: { keepLocalTime: keepLocalTime },
		});
	} else {
		return returnEmpty ? '-' : '';
	}
};

/** Fixup the filter before sending it to the server to adjust the sent value for any LOCAL times as these are kept in zulu time on the server and the default DataGrid filter date editor just returns a non timezone datetime
 * Made even more akward as the timezone displayed is based on the I360 time zone setting
 * @param filterModel the filter from the datagrid
 * @param fields a list of the fields to adjust
 * @param currentDateTime the currentDateTime as supplied by useCompanyDateTime() -> currentDateTime() or useTzDateTime({ timeZone: 'UTC' }) -> currentDateTime() depending on normal or naive date
 */
export const fixupDateTimeForFilter = (
	filterModel: GridFilterModel,
	fields: string[],
	currentDateTime: DateTime,
): GridFilterModel => {
	if (filterModel.items.length !== 0) {
		// Adjust from mins to ms
		const offset = currentDateTime.offset * 60000;
		if (offset !== 0) {
			const items = filterModel.items.map(item => {
				if (fields.find(value => value === item.field)) {
					const filterDate = new Date(item.value);
					// Subtract the offset to get to the zulu time
					filterDate.setTime(filterDate.getTime() - offset);
					return {
						...item,
						value: fixupDateTimeForInputDateTime(
							filterDate.toISOString(),
						).substring(0, 16),
					};
				} else {
					return item;
				}
			});
			return { ...filterModel, items: items };
		}
	}

	return filterModel;
};
