import {
  HistoricalChartDisplayOptions,
  HistoricalSeries,
} from '../types/historicalChart.types';
import { DEFAULT_CHART_COLORS } from '../types/chart.types';
import * as Highcharts from 'highcharts/highstock';
import { Options } from 'highcharts';

export const createYAxisFromSeries = (
  primarySeries?: HistoricalSeries,
  referenceSeries?: HistoricalSeries[],
  displayOptions?: HistoricalChartDisplayOptions
): Array<Highcharts.YAxisOptions> => {
  const yRangesMins = [];
  referenceSeries?.forEach((s) => {
    if (!s.displayOptions.yAxis && s.displayOptions.yRange?.min) {
      //if there is no separate yAxis the yRange may affect the range of the default axis
      yRangesMins.push(s.displayOptions.yRange.min);
    }
  });
  if (displayOptions?.yRange?.min) yRangesMins.push(displayOptions?.yRange.min);

  const yRangesMax = [];
  referenceSeries?.forEach((s) => {
    if (!s.displayOptions.yAxis && s.displayOptions.yRange?.max) {
      yRangesMax.push(s.displayOptions.yRange.max);
    }
  });
  if (displayOptions?.yRange?.max) yRangesMax.push(displayOptions?.yRange.max);

  const defaultAxis: Highcharts.YAxisOptions[] = [
    {
      lineWidth: 1,
      opposite: false,
      title: {
        text: primarySeries?.unit || '',
      },
      gridLineWidth: displayOptions?.showYGrid ? 1 : 0,
      min: yRangesMins.length ? Math.min(...yRangesMins) : null,
      max: yRangesMax.length ? Math.max(...yRangesMax) : null,
    },
  ];

  const axis = referenceSeries?.reduce(
    (acc: Highcharts.YAxisOptions[], series, index) => {
      if (series.displayOptions.yAxis) {
        const sides = { left: false, right: true };
        const color =
          series.displayOptions.color ||
          DEFAULT_CHART_COLORS[index + (1 % DEFAULT_CHART_COLORS.length)];
        return [
          ...acc,
          {
            lineWidth: 1,
            opposite: sides[series.displayOptions.yAxis?.side],
            title: {
              text: series.displayOptions.yAxis?.label || series.unit,
              style: {
                color: color,
              },
            },
            labels: {
              style: {
                color: color,
              },
            },
            gridLineWidth: displayOptions?.showYGrid ? 1 : 0,
            max: series.displayOptions.yRange?.max || null,
            min: series.displayOptions.yRange?.min || null,
          },
        ];
      }
      return acc;
    },
    defaultAxis
  );

  return axis || defaultAxis;
};

export const generateHistoricalStackOverrideOptions = (
  referenceSeries?: HistoricalSeries[],
  displayOptions?: HistoricalChartDisplayOptions
): Record<string, Options> => {
  const options = referenceSeries
    ? referenceSeries.reduce((record, series) => {
        if (series.displayOptions.yAxis) {
          return {
            ...record,
            [series.id]: {
              yAxis: [
                {
                  id: series.id,
                  opposite: false,
                  gridLineWidth: displayOptions?.showYGrid ? 1 : 0,
                  title: {
                    text: series.displayOptions.yAxis?.label || series.unit,
                  },
                  max: series.displayOptions.yRange?.max || null,
                  min: series.displayOptions.yRange?.min || null,
                },
              ],
            },
          };
        } else {
          return {
            ...record,
          };
        }
      }, {})
    : {};

  return options;
};
