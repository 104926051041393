/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as Highcharts from 'highcharts';

export const SyncEvents = (H: typeof Highcharts): void => {
  H.Pointer.prototype.reset = function () {
    return undefined;
  };

  // @ts-ignore
  H.Point.prototype.highlight = function (event) {
    const highchartsEvent = this.series.chart.pointer.normalize(event);
    this.onMouseOver(); // Show the hover marker
    this.series.chart.tooltip.refresh(this); // Show the tooltip
    this.series.chart.xAxis[0].drawCrosshair(highchartsEvent, this); // Show the crosshair
  };
};
