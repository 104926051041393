import { getMassBalance } from '../../../services/massBalance.service';
import { makeSelectMassBalanceByIdFromWorkspace } from '../../selectors/massBalance.selectors';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import type { AsyncData } from '../../types/async.types';
import type { MassBalance } from '../../types/massBalance.types';
import type { StoreState } from '../../../store/store';
import { t } from 'i18next';

const useMassBalance = (massBalanceId: string): AsyncData<MassBalance> => {
  /* This will store the exposed mass balance */
  const [asyncMassBalance, setAsyncMassBalance] = useState<
    AsyncData<MassBalance>
  >({
    status: 'idle',
    data: null,
  });

  /**
   * This creates a memoized copy of the parametrized selector.
   *
   * See: https://react-redux.js.org/api/hooks#using-memoizing-selectors
   */
  const selectMassBalanceByIdFromWorkspace = useMemo(
    makeSelectMassBalanceByIdFromWorkspace,
    []
  );

  const storedAsyncMassBalance = useSelector((state: StoreState) => {
    return selectMassBalanceByIdFromWorkspace(state, massBalanceId);
  });

  /**
   * Here we take whatever is in the store and set it as the exposed value
   * or we fetch the mass balance if it's no there.
   */
  useEffect(() => {
    const fetch = async () => {
      setAsyncMassBalance({
        data: null,
        status: 'loading',
        message: t('Loading mass balance'),
      });
      try {
        const response = await getMassBalance(massBalanceId);
        setAsyncMassBalance({
          data: response.data,
          status: 'resolved',
        });
      } catch (error) {
        setAsyncMassBalance({
          data: null,
          status: 'rejected',
          message: t('Failed to retrieve mass balance'),
        });
      }
    };

    if (massBalanceId) fetch();

    // Stale data in redux cache is causing unnecessary double render / api calls on Charts V2 making the chart even slower

    /* if (storedAsyncMassBalance.status === 'rejected') {
			fetch();
		} else {
			setAsyncMassBalance(storedAsyncMassBalance);
		} */
  }, [massBalanceId]);

  return asyncMassBalance;
};

export default useMassBalance;
