import {
	AttributeTypes,
	CBAttributes,
	ColorByOptions,
	ColorRange,
	ConfigLayer,
	ConfigLayerInternal,
	ThemeProperties,
	ThemeSettings,
} from '@Map/panel/types';
import {
	Breadcrumb,
	Caption,
	Crumb,
	DeleteButton,
	HelpIcon,
	HumanIcon,
	SelectLayerWrapper,
	SmallFormLegend,
	ThemePropsWrapper,
	Wrapper,
} from './LayerProperties.styles';
import {
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	SelectChangeEvent,
	TextField,
	Tooltip,
	useTheme,
} from '@mui/material';
import {
	GradientKey,
	defaultRangeValues,
	getColorOptions,
	gradients,
} from '@Map/layers/settings';
import { Picker, Slider } from '@Components/Inputs';
import React, {
	ChangeEvent,
	ReactNode,
	useEffect,
	useMemo,
	useState,
} from 'react';

import { AttributePicker } from './AttributePicker';
import { ColorBySelector } from './ColorBySelector';
import { ConfirmDeletionDialog } from './ConfirmDeletionDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import { EmptyThemeHelper } from './EmptyThemeHelper';
import { LayersIcon } from './LayersIcon';
import { Ranges } from './Ranges';
import SymbolLoader from '@Map/symbols/SymbolLoader';
import { UnitSystem } from '@Components/Inputs/units';
import { svgs } from '@Map/symbols/icons';
import { useGlobalization } from '@Translations/useGlobalization';
import { useLayerProperties } from '@Hooks/useLayerProperties';
import { ThemeErrors } from '@Components/LayerPanel/useThemeErrors';

interface LayerPropertiesProps {
	showMultiThemes?: boolean;
	themeName?: ThemeProperties['name'];
	themeErrors?: ThemeErrors;
	onThemeNameEdit?: (name: string) => void;
	onShowThemeProps?: () => void;
	onDeleteTheme?: () => void;
	layer?: ConfigLayerInternal;
	onChange?: (layer: Partial<ConfigLayer>) => void;
	attributes?: CBAttributes[];
	showColorBy?: ColorByOptions;
	showHeatmap?: boolean;
	getAttributeValuesCount?: (
		layerId: string,
		attributeId: string,
	) => number | undefined;
	unsupported?: boolean;
	colorByMaxValues?: number;
	unitSystem?: UnitSystem;
	showThemeSettings?: boolean;
	themeSettings?: ThemeSettings;
	onThemeSettingsChange?: (themeSettings: ThemeSettings) => void;
	disableThemeSettings?: boolean;
	defaultTheme?: boolean;
}

const iconOptions = Object.entries(svgs)
	// sort icons in folders to show after default icons
	.sort(([a], [b]) => {
		if (a.match(/\//) && !b.match(/\//)) return 1;
		if (b.match(/\//) && !a.match(/\//)) return -1;
		return a < b ? -1 : 1;
	})
	.map(([key, Icon]) => ({
		value: key,
		label: <Icon />,
	}))
	.filter(({ value }) => {
		// filter out the special icons
		if (
			value.match(/arrow/) ||
			value.match(/^valve-/) ||
			value.match(/blank/)
		)
			return false;
		return true;
	});

const LayerProperties = ({
	showMultiThemes,
	themeName,
	themeErrors,
	onThemeNameEdit,
	onShowThemeProps,
	onDeleteTheme,
	layer,
	onChange,
	attributes,
	showColorBy,
	showHeatmap,
	getAttributeValuesCount,
	unsupported,
	colorByMaxValues,
	unitSystem,
	showThemeSettings,
	themeSettings,
	onThemeSettingsChange,
	defaultTheme,
}: LayerPropertiesProps): JSX.Element => {
	const { t } = useGlobalization();
	const {
		layerName,
		color,
		outlineColor,
		icon,
		lineWidth,
		iconBackground,
		colorBy,
		colorRamp,
		colorRange,
		attribute,
		attributeType,
		attributeUnit,
		fillOpacity,
		minZoom,
		maxZoom,
		iconMinZoom,
		iconMaxZoom,
		type,
		scale,
		setProp,
		setMultiProps,
	} = useLayerProperties(layer, onChange);
	const [name, setName] = useState(t(themeName) ?? '');
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
	const [settings, setSettings] = useState(themeSettings);

	const deleteDisabled = defaultTheme;
	const disableThemeSettings = defaultTheme;

	const theme = useTheme();

	const zoomLevel = useMemo(() => [minZoom, maxZoom], [minZoom, maxZoom]);
	const iconZoomLevel = useMemo(() => [iconMinZoom, iconMaxZoom], [
		iconMinZoom,
		iconMaxZoom,
	]);

	const hasAttributeValues =
		layer?.id && attribute
			? getAttributeValuesCount?.(layer?.id, attribute)
			: undefined;

	const colorOptions = useMemo(() => {
		return getColorOptions(theme, [color, outlineColor]);
	}, []);

	const onThemeNameChange = (event: ChangeEvent<HTMLInputElement>) => {
		setName(event.target.value);
		onThemeNameEdit?.(event.target.value);
	};

	const onLayerNameChange = (event: ChangeEvent<HTMLInputElement>) => {
		setProp('layerName', event.target.value);
	};

	const onColorChange = (newColor: string) => {
		setProp('color', newColor);
	};

	const onOutlineColorChange = (newColor: string) => {
		setProp('outlineColor', newColor);
	};

	const onIconChange = (newIcon: string) => {
		setProp('icon', newIcon);
	};

	const onLineWidthChange = (
		event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
	) => {
		setProp('lineWidth', +event.target.value);
	};

	const onFillOpacityChange = (_event: unknown, value: number | number[]) => {
		setProp('fillOpacity', (value as number) / 100);
	};

	const onZoomChange = (_event: unknown, value: number | number[]) => {
		const [min, max] = value as number[];
		setMultiProps({ minZoom: min, maxZoom: max });
	};

	const onTransparentChange = (_event: unknown, checked: boolean) => {
		setProp('iconBackground', !checked);
	};

	const onIconZoomChange = (_event: unknown, value: number | number[]) => {
		const [min, max] = value as number[];
		setMultiProps({ iconMinZoom: min, iconMaxZoom: max });
	};

	const onColorByChange = (event: ChangeEvent<HTMLInputElement>) => {
		const newColorBy = event.target.value as ColorByOptions;
		// Add default range values if we're changing to range and there's no range set
		const newColorRange =
			newColorBy === ColorByOptions.range && !colorRange
				? defaultRangeValues(unitSystem)
				: colorRange;
		// Only change type if set as bubble and we're changing from range to something else, as heatmap/bubble layer is only available for range color by
		const newType =
			newColorBy !== ColorByOptions.range && type === 'bubble'
				? 'symbol'
				: type;
		setMultiProps({
			colorBy: newColorBy,
			colorRange: newColorRange,
			type: newType,
		});
	};

	const onColorRangeChange = (ranges: ColorRange[]) => {
		setProp('colorRange', ranges);
	};

	const onColorRampChange = (newColor: string) => {
		setProp('colorRamp', newColor as GradientKey);
	};

	const onAttributeChange = (
		event: SelectChangeEvent<{ value: unknown }>,
	) => {
		const newAttribute = event.target.value as string;
		const { fieldType, units } = attributes?.find(
			({ id }) => id === newAttribute,
		) || { fieldType: AttributeTypes.discrete };
		const newColorRange =
			fieldType === AttributeTypes.range && !colorRange
				? defaultRangeValues(unitSystem, units)
				: colorRange;
		setMultiProps({
			attribute: newAttribute,
			attributeType: fieldType,
			attributeUnit: units,
			colorRange: newColorRange,
		});
	};

	const onHeatmapToggle = (_event: unknown, checked: boolean) => {
		setProp('type', checked ? 'bubble' : 'symbol');
	};

	const onScaleChange = (_event: unknown, value: number | number[]) => {
		setProp('scale', value as number);
	};

	const confirmDeletion = () => {
		setDeleteDialogOpen(true);
	};

	const onConfirmDelete = () => {
		setDeleteDialogOpen(false);
		onDeleteTheme?.();
	};

	const onCancelDelete = () => {
		setDeleteDialogOpen(false);
	};

	const onSettingSharedChange = (event: unknown, checked: boolean) => {
		setSettings(oldValue => ({ ...oldValue, shared: checked }));
		onThemeSettingsChange?.({ ...themeSettings, shared: checked });
	};

	const showForType = (
		layerTypes: ConfigLayerInternal['type'][],
		node: ReactNode,
	) => {
		if (!type || !layerTypes.includes(type)) return;
		return node;
	};

	const showForValidIcon = (node: ReactNode, lineOnly = false) => {
		if (!type || !(type === 'symbol' || type === 'line')) return;
		if (type === 'symbol' && !lineOnly) return node;
		if (type === 'line' && SymbolLoader.iconValid(layer?.icon)) return node;
	};

	const isColorByAttr = useMemo(
		() => showColorBy && colorBy === ColorByOptions.attribute,
		[showColorBy, colorBy],
	);

	const isColorByRange = useMemo(
		() =>
			(showColorBy && colorBy === ColorByOptions.range) ||
			(colorBy === ColorByOptions.attribute &&
				attributeType === AttributeTypes.range),
		[showColorBy, colorBy, attributeType],
	);

	const isHeatmapReady = useMemo(
		() =>
			showColorBy &&
			showHeatmap &&
			(type === 'symbol' || type === 'bubble') &&
			colorBy === ColorByOptions.range,
		[showColorBy, showHeatmap, type, colorBy],
	);

	const showPropsCrumb = layer || unsupported;

	useEffect(() => {
		setName(t(themeName) ?? '');
	}, [themeName]);

	useEffect(() => {
		setSettings(themeSettings);
	}, [themeSettings]);

	return (
		<Wrapper data-cy="layer-properties">
			{showMultiThemes && (
				<Breadcrumb data-cy="bread-crumb">
					<Crumb
						$selected={!showPropsCrumb}
						$isClickable={showPropsCrumb && !!onShowThemeProps}
						onClick={showPropsCrumb ? onShowThemeProps : undefined}>
						{t('Theme', {
							context:
								'Breadcrumb trail for when editing the properties of the layer',
						})}
					</Crumb>
					{showPropsCrumb && (
						<Crumb $selected>
							{t('Properties', {
								context:
									'Breadcrumb trail for when editing the properties of the layer',
							})}
						</Crumb>
					)}
				</Breadcrumb>
			)}
			{!showMultiThemes && (
				<h3>
					{t('Layer properties', {
						context:
							'Heading for when editing the properties of the layer',
					})}
				</h3>
			)}
			{!layer && !unsupported && !showMultiThemes && (
				<SelectLayerWrapper>
					<LayersIcon />
					<h3>{t('Select a layer to edit the properties')}</h3>
					<Caption>
						{t('All changes will affect everyone in this account.')}
					</Caption>
				</SelectLayerWrapper>
			)}
			{!layer && !unsupported && showMultiThemes && (
				<ThemePropsWrapper>
					{defaultTheme && (
						<p>
							{t(
								'This is the default theme, it cannot be unshared or deleted',
							)}
						</p>
					)}
					<TextField
						label={t('Theme name', {
							context: 'Layer properties editor form field',
						})}
						value={name}
						onChange={onThemeNameChange}
						variant="outlined"
						margin="normal"
						fullWidth
						data-cy="theme-name"
						error={themeErrors?.themeName !== null}
						helperText={
							(themeErrors?.themeName === 'missing_name' &&
								t('Please enter a theme name', {
									context:
										'Layer properties editor form field helper text',
								})) ||
							(themeErrors?.themeName === 'duplicate_name' &&
								t('Theme name already exists', {
									context:
										'Layer properties editor form field helper text',
								}))
						}
					/>
					{showThemeSettings && (
						<FormControl component="fieldset">
							<SmallFormLegend>
								{t('Theme settings', {
									context:
										'Layer properties editor form field label',
								})}
							</SmallFormLegend>
							<FormGroup>
								<FormControlLabel
									control={<Checkbox color="primary" />}
									label={
										<>
											{t('Share with company', {
												context:
													'Layer properties editor option for theme settings',
											})}
											<Tooltip
												title={t(
													'This will allow all in the company to view and those with the correct permissions to edit and delete this theme',
													{
														context:
															'Layer properties editor helper text for share with company field',
													},
												)}>
												<HelpIcon />
											</Tooltip>
										</>
									}
									checked={settings?.shared}
									disabled={disableThemeSettings}
									onChange={onSettingSharedChange}
									data-cy="theme-settings-share"
								/>
							</FormGroup>
						</FormControl>
					)}
					<EmptyThemeHelper />
					<DeleteButton
						color="error"
						variant="contained"
						fullWidth
						onClick={!deleteDisabled ? confirmDeletion : undefined}
						disabled={deleteDisabled}>
						<DeleteIcon /> {t('Delete Theme')}
					</DeleteButton>
					<ConfirmDeletionDialog
						open={deleteDialogOpen}
						onConfirm={onConfirmDelete}
						onCancel={onCancelDelete}
					/>
				</ThemePropsWrapper>
			)}
			{!layer && unsupported && (
				<SelectLayerWrapper>
					<LayersIcon />
					<h3>{t('Selected layer cannot be edited')}</h3>
					<Caption>
						{t('Select another layer to edit the properties')}
					</Caption>
				</SelectLayerWrapper>
			)}
			{layer && (
				<>
					<TextField
						label={t('Layer name', {
							context: 'Layer properties editor form field',
						})}
						value={layerName}
						onChange={onLayerNameChange}
						variant="outlined"
						margin="normal"
						fullWidth
						data-cy="layer-name"
					/>
					{!type && <EmptyThemeHelper />}
					{showColorBy && (
						<ColorBySelector
							value={colorBy as ColorByOptions}
							colorByOption={showColorBy}
							onChange={onColorByChange}
							attributeDisabled={!attributes?.length}
							attributesNotFound={
								showColorBy === ColorByOptions.attribute &&
								attributes?.length === 0
							}
						/>
					)}
					{isColorByAttr && (
						<>
							<AttributePicker
								attributes={attributes}
								selectedAttribute={attribute}
								selectedAttributeType={attributeType}
								onAttributeChange={onAttributeChange}
								attributeValuesCount={hasAttributeValues}
								colorByMaxValues={colorByMaxValues}
							/>
							{attributeType !== AttributeTypes.range && (
								<Picker
									label={t('Color ramp', {
										context:
											'Layer properties editor form field',
									})}
									options={gradients}
									value={colorRamp}
									onChange={onColorRampChange}
									data-cy="color-ramp"
								/>
							)}
						</>
					)}
					{isColorByRange && (
						<Ranges
							defaultRanges={colorRange}
							onRangeChange={onColorRangeChange}
							unit={attributeUnit}
							unitSystem={unitSystem}
						/>
					)}

					{!!type && (
						<Picker
							label={
								isColorByRange || isColorByAttr
									? t('Fallback color', {
											context:
												'Layer properties editor form field',
									  })
									: t('Color', {
											context:
												'Layer properties editor form field',
									  })
							}
							options={colorOptions}
							value={color}
							type="color"
							onChange={onColorChange}
							data-cy="color-picker"
							IconButtonProps={{
								'data-cy': 'color-picker-button',
							}}
							TextFieldProps={{
								helperText: isColorByRange
									? t(
											'Anything without/outside range values will fallback to this color',
									  )
									: isColorByAttr
									? t(
											'Anything without attribute values will fallback to this color',
									  )
									: undefined,
							}}
						/>
					)}
					{isHeatmapReady && (
						<FormControl component="fieldset">
							<FormGroup>
								<FormControlLabel
									control={<Checkbox color="primary" />}
									label={t('Show as a heatmap', {
										context:
											'Layer properties editor form field',
									})}
									checked={type === 'bubble'}
									onChange={onHeatmapToggle}
									data-cy="show-as-heatmap"
								/>
							</FormGroup>
						</FormControl>
					)}
					{showForType(
						['polygon'],
						<Picker
							label={t('Stroke Color', {
								context: 'Layer properties editor form field',
							})}
							options={colorOptions}
							value={outlineColor}
							type="color"
							onChange={onOutlineColorChange}
							data-cy="stroke-color-picker"
							IconButtonProps={{
								'data-cy': 'stroke-color-picker-butoon',
							}}
						/>,
					)}
					{showForType(
						['line', 'polygon'],
						<TextField
							label={t('Stroke width', {
								context: 'Layer properties editor form field',
							})}
							value={lineWidth}
							onChange={onLineWidthChange}
							type="number"
							variant="outlined"
							margin="normal"
							fullWidth
							data-cy="stroke-width"
							InputProps={{
								inputProps: {
									min: 1,
								},
							}}
						/>,
					)}
					{showForType(
						['polygon', 'fill', 'bubble'],
						<Slider
							label={t('Fill opacity', {
								context: 'Layer properties editor form field',
							})}
							value={fillOpacity * 100}
							onChange={onFillOpacityChange}
							min={0}
							max={100}
							step={10}
							data-cy="fill-opacity"
						/>,
					)}
					{showForType(
						['bubble'],
						<Slider
							label={t('Circle scale', {
								context: 'Layer properties editor form field',
							})}
							value={scale}
							onChange={onScaleChange}
							min={1}
							max={5}
							data-cy="cluster-radius"
						/>,
					)}
					{showForValidIcon(
						<Picker
							label={t('Icon', {
								context: 'Layer properties editor form field',
							})}
							options={iconOptions}
							value={icon == undefined ? 'unknown' : icon}
							onChange={onIconChange}
							data-cy="icon-picker"
							IconButtonProps={{
								style: { color },
								'data-cy': 'icon-picker-button',
							}}
							OptionProps={{
								style: { color },
							}}
						/>,
					)}
					{!!type && (
						<Slider
							label={t('Zoom level', {
								context: 'Layer properties editor form field',
							})}
							value={zoomLevel}
							onChange={onZoomChange}
							min={2}
							max={22}
							data-cy="zoom-level"
						/>
					)}
					{showForValidIcon(
						<Slider
							label={t('Icon zoom level', {
								context: 'Layer properties editor form field',
							})}
							value={iconZoomLevel}
							onChange={onIconZoomChange}
							min={2}
							max={22}
							data-cy="icon-zoom-level"
						/>,
						true,
					)}
					{showForValidIcon(
						<FormControl component="fieldset">
							<SmallFormLegend>
								{t('Icon background', {
									context:
										'Layer properties editor form field label',
								})}
							</SmallFormLegend>
							<FormGroup>
								<FormControlLabel
									control={<Checkbox color="primary" />}
									label={t('Transparent', {
										context:
											'Layer properties editor option for icon background field',
									})}
									checked={!iconBackground}
									onChange={onTransparentChange}
									data-cy="transparent-checkbox"
								/>
							</FormGroup>
							{!iconBackground && (
								<FormHelperText>
									<HumanIcon />
									{t('Some icons may be hard to view', {
										context:
											'Layer properties editor helper text for icon background field',
									})}
								</FormHelperText>
							)}
						</FormControl>,
					)}
				</>
			)}
		</Wrapper>
	);
};

export default LayerProperties;
