import { createAction } from '@reduxjs/toolkit';
import {
	Pagination,
	ResolvedResponse,
	Status,
	Sort2,
	riskConfigsPaginationDefault,
	RiskConfigList,
} from '@Types';
import { addNamespace } from '@Utils/actions';

export const getRiskConfigs = createAction<Pagination>(
	addNamespace('riskConfigs/getRiskConfigs'),
);

export const clearRiskConfigs = createAction<Pagination>(
	addNamespace('riskConfigs/clearRiskConfigs'),
);

export const getRiskConfigsClear = () =>
	clearRiskConfigs(riskConfigsPaginationDefault());

export const reloadRiskConfigs = createAction(
	addNamespace('riskConfigs/reloadRiskConfigs'),
);

export const getRiskConfigsResolved = createAction<ResolvedResponse>(
	addNamespace('riskConfigs/getRiskConfigs/resolved'),
);

export const getRiskConfigsRejected = createAction<Status>(
	addNamespace('riskConfigs/getRiskConfigs/rejected'),
);

export const changeRiskConfigsSort = createAction<Sort2>(
	addNamespace('riskConfigs/sort'),
);

export const changeRiskConfigsSlice = createAction<number>(
	addNamespace('riskConfigs/slice'),
);

export const changeRiskConfigsPage = createAction<number>(
	addNamespace('amCommon/page'),
);

export const currentRiskConfigsRow = createAction<number>(
	addNamespace('riskConfigs/current/row'),
);

export const setRiskConfigsIsBusy = createAction<boolean>(
	addNamespace('riskConfigs/isBusy'),
);

export const getRiskConfigsList = createAction<{ assetType: string }>(
	addNamespace('riskConfigs/getRiskConfigsList'),
);

export const getRiskConfigsListResolved = createAction<RiskConfigList[]>(
	addNamespace('riskConfigs/getRiskConfigsList/resolved'),
);

export const getRiskConfigsListRejected = createAction<Status>(
	addNamespace('riskConfigs/getRiskConfigsList/rejected'),
);
