/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { DateTime } from 'luxon';
import {
  useIsFeatureEnabled,
  useSelectSensors,
  useSettings,
} from '@innovyze/stylovyze';
import * as InsightChart from '../../core/_insight-chart';
import * as PumpRuntimeChart from '../../modules/pumpRuntime-chart';
import * as React from 'react';
import * as SeriesData from '../../core/series-data';
import * as TimeSeriesData from '../../core/time-series-data';
import * as TimeSeriesDataOld from '../../core/time-series-data-old';
import type { EventHandlers } from '../../core/_chart';
import { fixCollectionInterval } from '../../core/time-series-data/utils';

type EdgeSource = {
  sensorId: string;
  resolution: TimeSeriesDataOld.Resolution;
  reading: TimeSeriesDataOld.Reading;
};

function edgeSourceStringifier(source: EdgeSource): string {
  return `${source.sensorId}:${source.resolution}:${source.reading}`;
}

function limit(value: string | null | undefined, defaultValue = 500): number {
  if (typeof value === 'string' || value === null) return defaultValue;
  if (isNaN(Number(value))) return defaultValue;
  return Number(value);
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 *	pumpRuntime Chart Preset Root Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

type PumpRuntimeChartRootProps = Omit<
  PumpRuntimeChart.PumpRuntimeChartRootProps,
  'children' | 'elements' | 'events' | 'categories' | 'status' | 'error'
>;

interface InsightPumpRuntimeChartProps extends PumpRuntimeChartRootProps {
  series: InsightPumpRuntimeChartSeriesProps[];
  timeRangeSelection?: InsightChart.TimeRangeSelection;
  onSeriesVisibilityChange?: EventHandlers['onSeriesVisibilityChange'];
}

const InsightPumpRutimeChart = React.forwardRef<
  { chart: Highcharts.Chart | undefined },
  InsightPumpRuntimeChartProps
>((props, ref): React.ReactElement => {
  const { companySettings } = useSettings();
  const { sensors, initialized: sensorsInitialized } = useSelectSensors();
  const dataLimit = useIsFeatureEnabled('info-360-analytics-hp2-charts-limit');
  const useV3 = useIsFeatureEnabled('info-360-edge-analytics-parquet-files');
  const edge = useIsFeatureEnabled(
    'info-360-edge-analytics-charts-pump-runtime'
  );

  const seriesData = TimeSeriesDataOld.useRetriever(
    props.series.filter((series) => !series.customData && series.sensorId)
  );

  const [edgeSources] = SeriesData.useSources<EdgeSource>(() => {
    const series = props.series?.filter((s) => !s.customData && s.sensorId);
    if (!series?.length) return [];
    return series.map(({ sensorId, resolution, reading }) => ({
      sensorId,
      resolution,
      reading,
    }));
  }, [props.series]);

  const [edgeData, edgeStatus, retrieveEdgeData] = SeriesData.useRetriever<
    TimeSeriesData.ResponseBody,
    { timeSelection?: TimeSeriesData.PartialTimeSelection }
  >(
    async (signal, params) => {
      if (!edgeSources?.length || !sensorsInitialized) return;

      const timeSelection = {
        from: params.timeSelection?.from ?? 'oldest',
        to: params.timeSelection?.to ?? 'latest',
      } as TimeSeriesData.TimeSelection;

      const response = await TimeSeriesData.retrieve(signal, {
        order: 'asc',
        timeSelection,
        limit: limit(dataLimit),
        timeZone: companySettings.timeZoneIANA,
        data_version: useV3 ? 'v3.0' : 'v2',
        snapping: 'oldest',
        sources: edgeSources.map((s) => {
          const _sensor = sensors.find((_s) => _s.sensorId === s.sensorId);
          const seconds = fixCollectionInterval(_sensor?.collectionInterval);

          return {
            key: edgeSourceStringifier(s),
            sensorId: s.sensorId,
            collectionInterval: { seconds },
            analytic: TimeSeriesData.makeAnalytic(
              s.resolution ?? '15-MINUTE',
              s.reading ?? 'Close'
            ),
          };
        }),
      });

      return response.data;
    },
    [
      companySettings.timeZoneIANA,
      dataLimit,
      edgeSources,
      sensors,
      sensorsInitialized,
      useV3,
    ]
  );

  // Handles data fetching with node's API
  const { retrieve } = seriesData;
  React.useEffect(() => {
    if (edge) return;
    retrieve({
      limit: { from: 'end' },
      timeSelection: {
        start: props.timeRangeSelection?.min,
        end: props.timeRangeSelection?.max,
      },
    });
  }, [
    retrieve,
    edge,
    props.timeRangeSelection?.max,
    props.timeRangeSelection?.min,
  ]);

  // Handles data fetching with edge analytics' API
  React.useEffect(() => {
    if (!edge) return;
    if (!sensorsInitialized) return;

    retrieveEdgeData({
      timeSelection: {
        from: props.timeRangeSelection?.min,
        to: props.timeRangeSelection?.max,
      },
    });
  }, [
    edge,
    sensorsInitialized,
    props.timeRangeSelection?.max,
    props.timeRangeSelection?.min,
    retrieveEdgeData,
  ]);

  return (
    <PumpRuntimeChart.PumpRuntimeChartRoot
      {...props}
      ref={ref}
      status={edge ? edgeStatus : seriesData.status}
      error={seriesData.error}
      categories={props.series.map((series) => series.name ?? '')}
      displayType={props.displayType}
      xAxis={{
        enableGridlines: props.xAxis?.enableGridlines,
      }}
      yAxis={{
        enableGridlines: props.yAxis?.enableGridlines,
      }}
      events={{
        series: {
          onVisibilityChange: props.onSeriesVisibilityChange,
        },
      }}
      elements={{
        series: props.series.map((series, seriesIndex) => {
          const key = edgeSourceStringifier(series);
          const edgeDataEntry = edgeData?.results?.[key];
          return (
            <InsightPumpRuntimeChartSeries
              {...series}
              key={seriesIndex}
              index={seriesIndex}
              displayType={props.displayType}
              totalSeries={props.series.length}
              customData={series.customData}
              seriesData={seriesData}
              edge={!!edge}
              edgeDataEntry={edgeDataEntry}
              selectedTheme={props.selectedTheme}
            />
          );
        }),
      }}
    />
  );
});

InsightPumpRutimeChart.displayName = 'InsightPumpRutimeChart';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Series Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

type InsightPumpRuntimeChartSeriesProps = InsightChart.InsightChartSeries<
  PumpRuntimeChart.PumpRuntimeChartSeriesProps,
  {
    sensorId: string;
    resolution: InsightChart.Resolution;
    reading: InsightChart.Reading;
    customData?: InsightChart.SensorDataEntry;
  }
>;

type InsightPumpRuntimeChartSeriesInternalProps = {
  index: number;
  totalSeries: number;
  displayType: 'hours' | 'percentage';
  customData?: InsightChart.SensorDataEntry;
  seriesData?: ReturnType<typeof TimeSeriesDataOld.useRetriever>;
  edge?: boolean;
  edgeDataEntry?: TimeSeriesData.ResponseResultEntry;
};

const InsightPumpRuntimeChartSeries = (
  props: InsightPumpRuntimeChartSeriesProps &
    InsightPumpRuntimeChartSeriesInternalProps
): React.ReactElement => {
  const { getRawSensorDataMap } = props.seriesData ?? {};

  const data = React.useMemo(() => {
    let _data: TimeSeriesDataOld.TimeSeriesData | undefined = undefined;
    const reading = props.reading ?? 'Close';

    if (props.customData) {
      const d = props.customData.data?.size
        ? Array.from(props.customData.data.entries())
        : undefined;
      _data = InsightChart.processDataWithReading(d, reading);
    } else if (!props.edge) {
      const s = { sensorId: props.sensorId, resolution: props.resolution };
      const m = getRawSensorDataMap?.(s);
      const d = m?.size ? Array.from(m.entries()) : undefined;
      _data = InsightChart.processDataWithReading(d, reading);
    } else {
      _data = props.edgeDataEntry?.data as TimeSeriesDataOld.TimeSeriesData;
    }

    if (!_data?.length) return [];

    const d: (number | string)[] = new Array(props.totalSeries).fill('');

    let sumHours = 0;
    let index = 0;
    let prevMeasure = 0;

    while (index < _data.length) {
      const measure = _data[index][1];
      if (prevMeasure && measure && measure > 0) {
        const diff = DateTime.fromMillis(_data[index][0])
          .diff(DateTime.fromMillis(_data[index - 1][0]), 'hours')
          .toObject();
        sumHours += diff.hours ?? 0;
      } else {
        if (measure) prevMeasure = measure;
        else prevMeasure = 0;
      }
      index++;
    }

    let total = sumHours;

    if (props.displayType === 'percentage' && _data.length) {
      const totalTime = DateTime.fromMillis(_data[index - 1][0])
        .diff(DateTime.fromMillis(_data[0][0]), 'hours')
        .toObject();

      total = totalTime.hours ? (sumHours * 100) / totalTime.hours : 0;
    }

    d[props.index] = total;

    return d;
  }, [
    getRawSensorDataMap,
    props.customData,
    props.displayType,
    props.edgeDataEntry,
    props.index,
    props.edge,
    props.reading,
    props.resolution,
    props.sensorId,
    props.totalSeries,
  ]);

  return <PumpRuntimeChart.PumpRuntimeChartSeries {...props} data={data} />;
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export { InsightPumpRutimeChart };

export type {
  InsightPumpRuntimeChartProps,
  InsightPumpRuntimeChartSeriesProps,
};
