import { NamespacedStoreState } from '@Types';
import { createSelector } from '@reduxjs/toolkit';
import { moduleNamespace } from '@Store/config.store';
import { useSelector } from 'react-redux';

const parseNamespace = (state: NamespacedStoreState) => state[moduleNamespace];

const riskConfigsListBaseSelector = createSelector(
	parseNamespace,
	state => state.riskConfigsList,
);

const riskConfigsListSelector = createSelector(
	riskConfigsListBaseSelector,
	baseState => baseState.configs,
);

const riskConfigsIsLoadingSelector = createSelector(
	riskConfigsListBaseSelector,
	baseState => baseState.isLoading,
);

export const selectRiskConfigsList = () => useSelector(riskConfigsListSelector);
export const selectRiskConfigsListIsLoading = () =>
	useSelector(riskConfigsIsLoadingSelector);

export default selectRiskConfigsList;
