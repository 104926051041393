/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';
import { selectUserContext, UserContext } from '@innovyze/stylovyze';
import {
  takeEvery,
  call,
  put,
  select,
  takeLatest,
} from '@redux-saga/core/effects';

import { measureDataService } from '../services';
import { MeasureDataResponse } from '../types';
import {
  getMeasureData,
  fetchMeasureData,
  fetchMeasureDataRejected,
  fetchMeasureDataResolved,
  setDebounceSensors,
  emptyDebounceSensors,
} from '../actions';
import { all, delay } from 'redux-saga/effects';
import { SensorData } from '..';

interface sensorDataMap {
  [sensor: string]: Record<string, SensorData>;
}

interface DataSource {
  sensorId: string;
  resolution: string;
}

const allSettled = (effects: any[]): any =>
  all(
    effects.map((effect) =>
      call(function* settle(): any {
        try {
          return yield effect;
        } catch (err) {
          return err;
        }
      })
    )
  );

function* getMeasureDataSaga() {
  yield takeEvery(getMeasureData, function* ({ payload }) {
    const dataSources = payload.sensors.map<DataSource>((sensorId) => {
      const resolution =
        payload.overrideResolutions?.[sensorId] ?? payload.resolution;
      return { sensorId, resolution };
    });

    const debounceSensors = dataSources.map(
      (dataSource) => `${dataSource.sensorId}@#!${dataSource.resolution}`
    );

    yield put(setDebounceSensors(debounceSensors));
    yield put(
      fetchMeasureData({
        dataSources,
        alwaysFetchData: payload.alwaysFetchData,
      })
    );
  });
}

function* fetchMeasureDataSaga() {
  yield takeLatest(fetchMeasureData, function* (action) {
    yield delay(300);

    try {
      const userContext: UserContext = yield select(selectUserContext);
      const debounceContext: string[] = yield select(
        (state) => state.measureData.debounceCharts
      );

      let uniqSensors = Array.from(new Set(debounceContext));

      //check if we already have this data on the context
      const sensorContext: sensorDataMap = yield select(
        (state) => state.measureData.sensorData
      );

      uniqSensors = uniqSensors.filter((unique) => {
        const [sensorId, resolution] = unique.split('@#!');
        const mapValue = sensorContext[sensorId]?.[resolution];
        let shouldFetchData = false;

        if (action.payload.alwaysFetchData) {
          shouldFetchData = !!action.payload.dataSources.find((dataSource) => {
            const isSameSensorId = dataSource.sensorId === sensorId;
            const isSameResolution = dataSource.resolution === resolution;

            return isSameSensorId && isSameResolution;
          });
        }

        if (shouldFetchData || mapValue === undefined) {
          return true;
        }
      });

      if (uniqSensors.length > 0) {
        const measureDataPromises = uniqSensors.map((sensor) => {
          const [sensorId, resolution] = sensor.split('@#!');
          return call(
            measureDataService.getMeasureDatum,
            userContext.defaultSite,
            sensorId,
            resolution
          );
        });

        const responses: AxiosResponse<MeasureDataResponse>[] =
          yield allSettled(measureDataPromises);

        const sensorDataContext: sensorDataMap = yield select(
          (state) => state.measureData.sensorData
        );

        const sensorMap: sensorDataMap = { ...sensorDataContext };

        responses.forEach(({ data, status }, index) => {
          if (status >= 200 && status < 300) {
            if (data.sensor_id) {
              const resolution = uniqSensors[index].split('@#!')[1];
              sensorMap[data.sensor_id] = {
                ...sensorMap[data.sensor_id],
              };
              sensorMap[data.sensor_id][resolution] = {
                data: data.data,
                unit: data.unit || '',
              };
            } else {
              console.error('');
            }
          } else {
            // yield put(
            // 	error(
            // 		`something went wrong fetching data for ${sensors[index]}`,
            // 	),
            // );
          }
        });

        yield put(fetchMeasureDataResolved(sensorMap));
      }

      yield put(emptyDebounceSensors());
    } catch (error) {
      console.log(error);

      // 	yield put(fetchMeasureDataRejected());
    }
  });
}

export const measureDataSagas = [getMeasureDataSaga(), fetchMeasureDataSaga()];
