import { rem } from '@Components/styles';
import styled from 'styled-components';

export const EmptyThemeHelperWrapper = styled.div`
	height: 100%;
	div {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		p {
			margin: ${rem(30)};
			margin-top: ${rem(60)};
			text-align: center;
		}
		svg {
			margin: ${rem(60)};
			margin-top: 0;
			max-width: 127px;
		}
	}
`;
