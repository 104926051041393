import { palette } from '@innovyze/stylovyze';
import {
  dateFormat,
  numberFormat,
  SeriesGaugeOptions,
  TitleOptions,
  XAxisOptions,
  YAxisOptions,
  YAxisPlotBandsOptions,
} from 'highcharts';
import { SensorDataRecords } from '../../../core/types/data.types';
import { GaugeSeries } from '../../../core/types/gauge.types';
import { Reading } from '../../../core/types/reading.types';
import { readingIndex } from '../../../core/utils/data.utils';
import { TFunc } from '../../../i18n';
import {
  dataGroupingFunctions,
  DataPoint,
  GaugeChartData,
  GaugeChartDisplayOptions,
  GaugeChartThresholds,
  Readings,
} from '../../../types';
import { gaugeChartFont, gaugeChartPalette } from '../../../utils';

export const generateGaugeStockOptions = (
  series: GaugeSeries,
  sensorDataRecords: SensorDataRecords,
  isBoostModuleEnabled: boolean,
  t: TFunc,
  displayOptions?: GaugeChartDisplayOptions
): Highcharts.Options => {
  const { sensorId, resolution } = series.dataSource;

  const dataRecord = sensorDataRecords?.[sensorId]?.[resolution];

  const timestamps = dataRecord?.data?.timestamps ?? [];
  const measurements = dataRecord?.data?.measurements ?? {};

  const latestDataPoint = measurements[timestamps.length - 1];

  const latestDataPointTimestamp = latestDataPoint?.[0];

  const xAxisOptions: XAxisOptions = {
    min: undefined,
    gridLineWidth: displayOptions?.showXGrid ? 1 : 0,
  };

  if (latestDataPoint) {
    const last24HrsTimestamp = latestDataPointTimestamp - 86400000;
    xAxisOptions.min = last24HrsTimestamp;
  }

  const yAxisOptions: YAxisOptions = {
    gridLineWidth: displayOptions?.showYGrid ? 1 : 0,
    title: {
      text: '',
    },
  };

  const reading = series.reading || Reading.Close;

  const data = timestamps.map((timestamp) => [
    timestamp,
    measurements[timestamp]?.[readingIndex[Reading[reading]]] ?? null,
  ]);

  return {
    exporting: { enabled: false },
    chart: { height: 160 },
    legend: { enabled: false },
    navigator: { enabled: false },
    rangeSelector: { enabled: false },
    xAxis: xAxisOptions,
    yAxis: yAxisOptions,
    series: [
      {
        dataGrouping: {
          approximation: dataGroupingFunctions[reading],
          enabled: !isBoostModuleEnabled,
        },
        color: palette.primary.main,
        type: 'line',
        lineWidth: 2,
        marker: {
          enabled: false,
          radius: 4,
        },
        states: {
          hover: {
            lineWidth: undefined,
            lineWidthPlus: 0,
          },
        },
        data,
      },
    ],
  };
};

export const generateGaugeOptions = (
  series: GaugeSeries,
  sensorDataRecords: SensorDataRecords
): Highcharts.Options => {
  const data = processGaugeSeriesToGaugeData(series, sensorDataRecords);

  const { sensorId, resolution } = series.dataSource;
  const dataRecord = sensorDataRecords?.[sensorId]?.[resolution];

  const statusColor = data?.isWithinThresholds
    ? gaugeChartPalette.success
    : gaugeChartPalette.error;

  const seriesOptions: SeriesGaugeOptions = {
    type: 'gauge',
    data: data ? [data.latest[1]] : [],
    dial: {
      backgroundColor: statusColor,
    },
    pivot: {
      backgroundColor: statusColor,
    },
    dataLabels: {
      enabled: false,
    },
  };

  const titleOptions: TitleOptions = {
    useHTML: true,
    text: [
      `<div style="${[
        `font-family: ${gaugeChartFont}`,
        'font-size: 1rem',
        'text-align: center',
        'line-height: 1',
        'margin-bottom: 20px',
      ].join(';')}" />`,
      `<span style="${[
        `color: ${statusColor}`,
        'display: block',
        'font-size: 2em',
        'margin-bottom: 0.2em',
      ].join(';')}">${parseMeasure(
        data?.latest[1],
        dataRecord.data?.unit
      )}</span>`,
      `<span style="${[
        `color: ${gaugeChartPalette.label}`,
        'display: block',
        'font-size: 1em',
      ].join(';')}">${parseTimestamp(data?.latest?.[0])}</span>`,
      '</div>',
    ].join(''),
  };

  const yAxisOption: YAxisOptions = {
    min: data?.min,
    max: data?.max,
    plotBands: [],
  };

  if (
    series.thresholds !== undefined &&
    series.thresholds.lower !== undefined &&
    series.thresholds.upper !== undefined
  ) {
    const { min, max } = yAxisOption;
    const { lower, upper } = series.thresholds;
    const overflowsMin = lower < min!;
    const overflowsMax = upper > max!;

    let plotBands: YAxisPlotBandsOptions[] = [];

    if (overflowsMin && overflowsMax) {
      yAxisOption.min = lower;
      yAxisOption.max = upper;

      plotBands = [
        {
          from: lower!,
          to: min!,
          color: gaugeChartPalette.overflowedThreshold,
        },
        {
          from: min!,
          to: max!,
          color: gaugeChartPalette.threshold,
        },
        {
          from: max!,
          to: upper!,
          color: gaugeChartPalette.overflowedThreshold,
        },
      ];
    } else if (overflowsMin) {
      yAxisOption.min = lower;

      plotBands = [
        {
          from: lower!,
          to: min!,
          color: gaugeChartPalette.overflowedThreshold,
        },
        {
          from: min!,
          to: upper!,
          color: gaugeChartPalette.threshold,
        },
      ];
    } else if (overflowsMax) {
      yAxisOption.max = upper;

      plotBands = [
        {
          from: lower!,
          to: max!,
          color: gaugeChartPalette.threshold,
        },
        {
          from: max!,
          to: upper!,
          color: gaugeChartPalette.overflowedThreshold,
        },
      ];
    } else {
      plotBands = [
        {
          from: lower!,
          to: upper!,
          color: gaugeChartPalette.overflowedThreshold,
        },
      ];
    }

    yAxisOption.plotBands = plotBands.map((pb) => ({
      ...pb,
      thickness: '40%',
    }));
  }

  return {
    series: [seriesOptions],
    title: titleOptions,
    yAxis: yAxisOption,
  };
};

const parseMeasure = (measure?: number, unit?: string): string => {
  if (measure === undefined || measure === null) return '';
  const formattedMeasure = numberFormat(measure, 2);
  if (unit === undefined || unit === null) return `${formattedMeasure}`;
  return `${formattedMeasure} ${unit}`;
};

const parseTimestamp = (timestamp?: number): string => {
  if (!timestamp) return '';
  return dateFormat('%m/%d/%Y %H:%M:%S', timestamp);
};

const checkIfMeasureIsWithinThreasholds = (
  measure?: number,
  thresholds?: GaugeChartThresholds
): boolean => {
  if (
    !thresholds ||
    thresholds.lower === undefined ||
    thresholds.upper === undefined
  )
    return true;
  if (!measure) return false;

  const { lower, upper } = thresholds;
  const isWithinThresholds = measure >= lower && measure <= upper;

  return isWithinThresholds;
};

const processGaugeSeriesToGaugeData = (
  series: GaugeSeries,
  sensorDataRecords: SensorDataRecords
): GaugeChartData | undefined => {
  const { thresholds } = series;
  const { sensorId, resolution } = series.dataSource;

  const reading = series.reading || Readings.Close;

  const dataRecord = sensorDataRecords?.[sensorId]?.[resolution];

  const timestamps = dataRecord?.data?.timestamps ?? [];
  const measurements = dataRecord?.data?.measurements ?? {};

  const dataPoints: DataPoint[] = timestamps.map((timestamp) => [
    timestamp,
    measurements[timestamp]?.[readingIndex[Reading[reading]]] ?? null,
  ]);

  if (dataPoints.length === 0) {
    return undefined;
  }

  const latestDataPoint: DataPoint = dataPoints[dataPoints.length - 1];

  let minMeasure: number | undefined;
  let maxMeasure: number | undefined;

  [minMeasure, maxMeasure] = dataPoints.reduce<[number, number]>(
    ([min, max], [, measure]) => {
      const newMin = measure < min ? measure : min;
      const newMax = measure > max ? measure : max;
      return [newMin, newMax];
    },
    [dataPoints[0][1], dataPoints[0][1]]
  );

  /**
   * Generates a synthetic range when all data points have the same value
   * */
  if (minMeasure === maxMeasure) {
    const syntheticRangeFactor = 0.5;
    minMeasure = minMeasure - minMeasure * syntheticRangeFactor;
    maxMeasure = maxMeasure + maxMeasure * syntheticRangeFactor;
  }

  return {
    points: dataPoints,
    latest: latestDataPoint,
    min: minMeasure,
    max: maxMeasure,
    isWithinThresholds: checkIfMeasureIsWithinThreasholds(
      latestDataPoint[1],
      thresholds
    ),
  };
};
