import { Divider } from '@mui/material';
import React from 'react';
import { StyledListItem } from './ItemList.styles';

interface ItemListProps {
	value: string;
	cy?: string;
}

export const ItemList = ({ value, cy }: ItemListProps): JSX.Element => {
	return (
		<>
			<StyledListItem data-cy={cy}>{value}</StyledListItem>
			<Divider className="ContentCard--footer--divider" />
		</>
	);
};
