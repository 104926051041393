import { BackgroundTypes, ConfigLayerInternal } from '@Map/panel/types';
import { getLayerIdFromIdPath, reduceArrayToObject } from '@Map/utils';

import { MapHiddenLayers } from '@Map/layers/LayerManager';
import darkBackground from './assets/dark.jpg';
import outdoorsBackground from './assets/outdoors.jpg';
import satelliteBackground from './assets/satellite.jpg';
import streetsBackground from './assets/streets.jpg';
import osmStandardBackground from './assets/osmStandard.jpg';
import osmReliefBackground from './assets/osmRelief.jpg';
import osmStreetsBackground from './assets/osmStreets.jpg';
import osmStreetsReliefBackground from './assets/osmStreetsRelief.jpg';
import osmLightGrayBackground from './assets/osmLightGray.jpg';
import osmDarkGrayBackground from './assets/osmDarkGray.jpg';
import arcGISImageryBackground from './assets/arcGISImagery.jpg';
import arcGISImageryLabelsBackground from './assets/arcGISImageryLabels.jpg';

export interface Visibility {
	[key: string]: boolean;
}

/**
 * Maps config into visibility object array
 * @param config layer configuration
 * @returns visibility object array
 */
export const getVisibilityArray = (
	config: ConfigLayerInternal[],
): Visibility[] => {
	return config.flatMap(layer => {
		const processed = [
			{
				[`${layer.idPath}`]: layer.visible ?? true,
			},
		];
		const layersToDisplay = layer.layers || layer.virtualLayers;
		if (layersToDisplay?.length) {
			processed.push(...getVisibilityArray(layersToDisplay));
		}
		return processed;
	});
};

/**
 * Convert layer configuration into layer visibility object
 * @param config layer configuration
 * @param hiddenLayers layers to be hidden by default
 * @returns visibility object
 */
export const getDefaultVisibility = (
	config: ConfigLayerInternal[],
	hiddenLayers?: MapHiddenLayers,
): Visibility => {
	const defaultVisibility = reduceArrayToObject(getVisibilityArray(config));
	if (hiddenLayers) {
		const idPaths = Object.keys(defaultVisibility);
		return {
			...defaultVisibility,
			...reduceArrayToObject(
				hiddenLayers.map(layer => {
					const idPath = idPaths.find(
						anIdPath => getLayerIdFromIdPath(anIdPath) === layer,
					);
					return {
						[idPath ?? layer]: false,
					};
				}),
			),
		};
	}
	return defaultVisibility;
};

/**
 * Get the layers that are children of the current layer
 * @param visibility layer visibility object
 * @param idPath id path of the current layer
 * @returns array of layer id paths
 */
export const getChildLayers = (
	visibility: Visibility,
	idPath: string,
): string[] => {
	return Object.keys(visibility).filter(key => key.startsWith(`${idPath}/`));
};

/**
 * Get the layer id paths of the ancestor layers
 * @param idPath id path of the current layer
 * @returns array of id paths
 */
export const getAncestorLayers = (idPath: string): string[] => {
	const parts = idPath.split('/');
	const layers = [];
	for (let i = 0; i <= parts.length; i++) {
		parts.pop();
		layers.push(parts.join('/'));
	}
	return layers;
};

/**
 * Update the layer visibility of the current layer as well as
 * propogating changes to ancestors and children
 * @param idPath id path of current layer
 * @param visibility visibility object
 * @param state current value to set visibility
 * @returns visbility object
 */
export const updateVisibility = (
	idPath: string,
	visibility: Visibility,
	state: boolean,
): Visibility => {
	// Propgate state changes to all children
	const childLayers = getChildLayers(visibility, idPath).map(key => ({
		[key]: state,
	}));
	const updatedVisibility = {
		...visibility,
		...reduceArrayToObject(childLayers),
	};
	let ancestorLayers: Visibility[] = [];
	if (state) {
		// If state changes to true, then all ancestors to be set to true
		ancestorLayers = getAncestorLayers(idPath).map(key => ({
			[key]: state,
		}));
	} else {
		// If state changes to false, then check children of ancestors to set
		// visibility state to true if at least one child is true, otherwise false
		const ancestorIdPaths = getAncestorLayers(idPath);
		ancestorLayers = ancestorIdPaths.map(key => {
			const children = getChildLayers(updatedVisibility, key).filter(
				// filter out the current layer and its ancestors as the values have been changed
				childKey =>
					childKey !== idPath && !ancestorIdPaths.includes(childKey),
			);
			// ancestor layer is visible if at least one child is visible
			const visible = children
				.map(childKey => updatedVisibility[childKey])
				.some(value => value === true);
			return {
				[key]: visible,
			};
		});
	}
	return {
		...updatedVisibility,
		...reduceArrayToObject(ancestorLayers),
		[idPath]: state,
	};
};

/**
 * Converts the visibility shaped object into a hidden layers array
 * @param visibility visibility object
 * @returns hidden layers array
 */
export const convertVisibilityToHiddenLayers = (
	visibility: Visibility,
): MapHiddenLayers => {
	const allLayers = Object.entries(visibility)
		.map(([key, visible]) => !visible && key)
		.filter(Boolean) as MapHiddenLayers;
	return allLayers.filter(key => {
		return !getAncestorLayers(key).some(layer => allLayers.includes(layer));
	});
};

/**
 * Mapping of the background type to its image
 * @param backgroundKey backgorund type
 * @returns image url
 */
export const getBackgroundLayerImage = (
	backgroundKey: BackgroundTypes,
): string => {
	const mappings = {
		[BackgroundTypes.Streets]: streetsBackground,
		[BackgroundTypes.Satellite]: satelliteBackground,
		[BackgroundTypes.Outdoors]: outdoorsBackground,
		[BackgroundTypes.Dark]: darkBackground,
		[BackgroundTypes.OSMStandard]: osmStandardBackground,
		[BackgroundTypes.OSMStreetsRelief]: osmStreetsReliefBackground,
		[BackgroundTypes.OSMRelief]: osmReliefBackground,
		[BackgroundTypes.OSMStreets]: osmStreetsBackground,
		[BackgroundTypes.OSMLightGray]: osmLightGrayBackground,
		[BackgroundTypes.OSMDarkGray]: osmDarkGrayBackground,
		[BackgroundTypes.ArcGISImagery]: arcGISImageryBackground,
		[BackgroundTypes.ArcGISImageryLabels]: arcGISImageryLabelsBackground,
	};
	return mappings[backgroundKey];
};
