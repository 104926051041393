const applyMixins = (baseClass: any, extendedClasses: any[]) => {
	extendedClasses.forEach(extendedClass => {
		Object.getOwnPropertyNames(extendedClass.prototype).forEach(name => {
			Object.defineProperty(
				baseClass.prototype,
				name,
				Object.getOwnPropertyDescriptor(
					extendedClass.prototype,
					name,
				) || Object.create(null),
			);
		});
	});
};

export default applyMixins;
