import { Button } from '@innovyze/stylovyze';
import { Grid, Typography } from '@mui/material';
import { selectAssetRiskIsWaiting } from '@Selectors';
import { useGlobalization } from '@Translations';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { RiskIcon } from './RiskIcon.component';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const NoDetailsRisk = ({
	adminFullAccess,
}: {
	adminFullAccess: boolean;
}) => {
	const { t } = useGlobalization();
	const history = useHistory();
	const runModelClicked = () => {
		history.push('/risk', { pageIndex: 3 });
	};
	const isLoading = selectAssetRiskIsWaiting();

	return (
		<Grid container style={{ paddingTop: '40px', paddingBottom: '40px' }}>
			<Grid container item xs={12} justifyContent="center">
				<RiskIcon />
			</Grid>
			<Grid container item xs={12} justifyContent="center">
				<Typography variant="h6">
					{t('No risk details to show')}
				</Typography>
			</Grid>
			{adminFullAccess && !isLoading ? (
				<Grid container item xs={12} justifyContent="center">
					<Grid container item xs={12} justifyContent="center">
						<Typography variant="caption">
							{t(`You have not run your model, in order to see risk
							details please run your model.`)}
						</Typography>
					</Grid>
					<Grid
						container
						item
						xs={12}
						justifyContent="center"
						style={{
							paddingTop: '20px',
							paddingBottom: '20px',
						}}>
						<Button
							variant="contained"
							color="primary"
							onClick={runModelClicked}>
							{t('Run Model')}
						</Button>
					</Grid>
				</Grid>
			) : (
				[]
			)}
		</Grid>
	);
};
