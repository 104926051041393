import { EasingOptions, Map, MapEvent, PaddingOptions } from 'mapbox-gl';

import MapboxUtilsBase from './base';

export enum CameraAction {
	flyTo = 'flyTo',
	jumpTo = 'jumpTo',
	easeTo = 'easeTo',
}

export type MoveEndEvent = MapEvent &
	Partial<{
		trigger: string;
		action: CameraAction;
		options: EasingOptions;
		retry: number;
	}>;

type EventData = Parameters<Map['flyTo']>[1];

export class MapboxUtilsCamera extends MapboxUtilsBase {
	protected _getPadding: () => PaddingOptions = () => ({
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
	});

	flyTo(
		options: EasingOptions,
		triggerName: string,
		retry = 2,
		eventData?: EventData,
	): void {
		this._map?.flyTo(options, {
			...eventData,
			trigger: triggerName,
			retry,
			options,
			action: CameraAction.flyTo,
		});
	}

	static flyTo(
		map: mapboxgl.Map,
		...args: Parameters<MapboxUtilsCamera['flyTo']>
	): void {
		new MapboxUtilsCamera(map).flyTo(...args);
	}

	jumpTo(
		options: mapboxgl.CameraOptions,
		triggerName: string,
		retry = 2,
		eventData?: EventData,
	): void {
		this._map?.jumpTo(options, {
			...eventData,
			trigger: triggerName,
			retry,
			options,
			action: CameraAction.jumpTo,
		});
	}

	static jumpTo(
		map: mapboxgl.Map,
		...args: Parameters<MapboxUtilsCamera['jumpTo']>
	): void {
		new MapboxUtilsCamera(map).jumpTo(...args);
	}

	easeTo(
		options: EasingOptions,
		triggerName: string,
		retry = 2,
		eventData?: EventData,
	): void {
		this._map?.easeTo(options, {
			...eventData,
			trigger: triggerName,
			retry,
			options,
			action: CameraAction.easeTo,
		});
	}

	setPitch(pitch: number, eventData?: EventData) {
		this._map?.setPitch(pitch, eventData);
	}

	setBearing(bearing: number) {
		this._map?.setBearing(bearing);
	}

	enableDragRotate(): void {
		this._map?.['dragRotate'].enable();
	}

	disableDragRotate(): void {
		this._map?.['dragRotate'].disable();
	}

	setCameraPaddingFunction(paddingFunc: typeof this._getPadding): void {
		this._getPadding = paddingFunc;
	}

	getCameraPadding(): PaddingOptions {
		return this._getPadding();
	}
}
