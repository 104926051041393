import {
	ColorByOptions,
	ConfigLayer,
	ConfigLayerInternal,
	ConfigOverrides,
} from '@Map/types';
import { addDefaults, getColorByTheming } from './settings';
import { extractionAsText, t } from '@Translations/extraction';
import { notUndefined, reduceArrayToObject, santizeString } from '@Map/utils';

import { CompositeLayerProps } from './types';
import { RequireSome } from '@Map/typeUtils';
import ServiceManager from '@Map/services/ServiceManager';
import { SourceType } from '@Map/services/types';
import SymbolLoader from '@Map/symbols/SymbolLoader';

type ConfigLayerAsComposite = RequireSome<
	ConfigLayerInternal,
	'type' | 'properties' | 'color'
>;

type Generic = { [key: string]: unknown };

export default class ConfigAdaptorLayer {
	private _layerConfig: ConfigLayerInternal;
	private _serviceManager: ServiceManager;
	private _symbolLoader: SymbolLoader;
	private _changes: Partial<ConfigLayer> = {};
	private _validColorBy = false;

	constructor(
		layerConfig: ConfigLayer,
		serviceManager: ServiceManager,
		symbolLoader: SymbolLoader,
		parent?: ConfigLayerInternal,
	) {
		const configWithId = this._addInternalIds(layerConfig, parent);
		const configWithInherited = this._addInherited(configWithId, parent);
		this._layerConfig = addDefaults(configWithInherited);
		if (this._layerConfig.visible != null) {
			this.visible = this._layerConfig.visible;
		}
		this._serviceManager = serviceManager;
		this._symbolLoader = symbolLoader;
	}

	get id(): string {
		return this.configWithEdits.id;
	}

	get layerName(): string {
		return extractionAsText(this.configWithEdits.layerName);
	}

	get attributesLayerId(): string {
		const service = this._serviceManager.getById(this.dataServiceId);
		if (service?.type === SourceType.TILES) {
			return extractionAsText(this._layerConfig.layerName);
		}
		return (
			((this._layerConfig as unknown) as ConfigOverrides).assetType ||
			this.id
		);
	}

	get attributeForRange(): string | undefined {
		const service = this._serviceManager.getById(this.dataServiceId);
		return service?.attributeForRange;
	}

	get childLayerIds(): string[] | undefined {
		return this._layerConfig.layers?.map(({ id }) => id);
	}

	get type(): ConfigLayerInternal['type'] {
		return this.configWithEdits.type;
	}

	get dataServiceId(): string {
		return this.configWithEdits.dataServiceId ?? '';
	}

	get source(): string {
		return this.tileSource ?? this.tileLayerName ?? this.dataServiceId;
	}

	get tileSource(): string | undefined {
		if (
			typeof this.configWithEdits.tileSource === 'object' &&
			'test' in this.configWithEdits.tileSource
		) {
			return this._getTileSourceName(
				this.dataServiceId,
				this.configWithEdits.tileSource,
			);
		}
		return this.configWithEdits.tileSource;
	}

	get tileLayerName(): string | undefined {
		if (
			typeof this.configWithEdits.tileLayerName === 'object' &&
			'test' in this.configWithEdits.tileLayerName
		) {
			return this._getTileLayerName(
				this.dataServiceId,
				this.configWithEdits.tileLayerName,
			);
		}
		return this.configWithEdits.tileLayerName as string;
	}

	get visible(): boolean {
		return this._changes.visible ?? this._layerConfig.visible ?? true;
	}

	set visible(visibility: boolean | undefined) {
		// only save visibility if it is different from the default
		const saveVisibility =
			visibility != null &&
			visibility !== (this._layerConfig.visible ?? true);
		if (!saveVisibility) {
			delete this._changes.visible;
			return;
		}
		this._changes.visible = visibility;
	}

	get icon(): string | undefined {
		return SymbolLoader.iconValid(this.configWithEdits.icon)
			? this.configWithEdits.icon
			: undefined;
	}

	get iconColored(): string | undefined {
		if (this.icon) {
			return SymbolLoader.createSymbolId(
				this.icon,
				this.configWithEdits.color,
				undefined,
				undefined,
				!this.configWithEdits.iconBackground,
			);
		}
	}

	get iconColoredSelected(): string | undefined {
		if (this.icon) {
			return SymbolLoader.createSymbolId(
				this.icon,
				this.configWithEdits.color,
				true,
				this.configWithEdits.selectedColor,
				!this.configWithEdits.iconBackground,
			);
		}
	}

	set validColorBy(validColorBy: boolean) {
		this._validColorBy = validColorBy;
	}

	get validColorBy(): boolean {
		return this._validColorBy;
	}

	get colorByTheming(): ColorByOptions {
		return getColorByTheming(this.configWithEdits);
	}

	get iconImage(): string | undefined {
		if (this.icon) {
			if (
				this.colorByTheming === ColorByOptions.attribute &&
				this.colorRamp &&
				this.validColorBy
			) {
				return this._symbolLoader.getGradientSvg(
					this.configWithEdits.icon,
					this.colorRamp,
				);
			} else if (
				this.colorByTheming === ColorByOptions.range &&
				this.configWithEdits.colorRange &&
				this.configWithEdits.colorRange.length >= 2
			) {
				const gradientColors = this.configWithEdits.colorRange.map(
					({ color }) => color,
				);
				return this._symbolLoader.getCustomGradientSvg(
					this.configWithEdits.icon,
					gradientColors,
				);
			}
			return this._symbolLoader.getSvg(
				SymbolLoader.createSymbolId(
					this.icon,
					this.configWithEdits.color,
				),
			);
		}
	}

	get patternSvg(): string | undefined {
		if (this.configWithEdits.pattern) {
			return this._symbolLoader.getSvg(
				SymbolLoader.createPatternId(
					this.configWithEdits.pattern,
					this.configWithEdits.color,
				),
			);
		}
	}

	get showColorBy(): ColorByOptions | undefined {
		const attributeServiceType = this._serviceManager.attributeServiceType(
			this.dataServiceId,
		);
		return !!this.type &&
			(attributeServiceType === ColorByOptions.attribute ||
				attributeServiceType === ColorByOptions.range)
			? attributeServiceType
			: undefined;
	}

	get canHeatmap(): boolean {
		const attributeServiceType = this._serviceManager.attributeServiceType(
			this.dataServiceId,
		);
		return !!this.type && attributeServiceType === ColorByOptions.range;
	}

	get colorRamp(): string | undefined {
		return this.configWithEdits.colorRamp;
	}

	get properties(): ConfigLayerAsComposite['properties'] {
		return this._checkProperties(
			this.dataServiceId,
			this.configWithEdits.properties,
		);
	}

	get lookup(): unknown[] | undefined {
		const lookups = this._getLookups().map(
			({ lookup, min, max, value }) => {
				if (min != null && max != null) {
					const ids = lookup.findInRange(min, max);
					return ['in', ['get', 'id'], ['literal', ids]];
				} else if (typeof value === 'string') {
					const ids = lookup.findMatch(value);
					return ['in', ['get', 'id'], ['literal', ids]];
				}
			},
		);
		return lookups[0];
	}

	get sort(): unknown[] | undefined {
		const service = this._serviceManager.getById(this.dataServiceId);
		const lookups = Object.keys(this.configWithEdits.properties || {})
			.map(key => {
				const lookup = service?.getLookup(key);
				if (lookup && lookup.data && Object.keys(lookup.data).length) {
					const expression: unknown[] = ['case'];
					Object.entries(lookup.data).forEach(([id, data]) => {
						expression.push(['==', ['get', 'id'], id]);
						// the sort function needs to return a number, so
						// if the data is null, we return a number that is
						// always less than any other number
						expression.push(data ?? -99999);
					});
					expression.push(0);
					return expression;
				}
			})
			.filter(Boolean);
		return lookups[0];
	}

	get asCompositeLayer(): CompositeLayerProps | undefined {
		if (!this.type) return;
		return {
			...(this.configWithEdits as ConfigLayerAsComposite),
			...this._serviceStatus(this.dataServiceId),
			icon: this.icon,
			visible: this.visible,
			serviceId: this.dataServiceId,
			displayName: this.layerName,
			source: this.source,
			sourceLayer: this.tileLayerName,
			properties: this.properties,
			attributeLayerId: this.attributesLayerId,
		};
	}

	get layerConfig(): ConfigLayerInternal {
		return {
			...this.configWithEdits,
			visible: this.visible,
			icon: this.icon,
			iconImage: this.iconImage,
			patternSvg: this.patternSvg,
			showColorBy: this.showColorBy,
			canHeatmap: this.canHeatmap,
			validColorBy: this.validColorBy,
			colorByTheming: this.colorByTheming,
		};
	}

	set edits(edits: Partial<ConfigLayer> | undefined) {
		if (!edits) {
			this._changes = {};
			return;
		}
		this._changes = this.getDifferencesFromDefaults(edits);
	}

	get changes(): Partial<ConfigLayer> {
		return this._changes;
	}

	get configWithEdits(): ConfigLayerInternal {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { id, ...edits } = this._changes as ConfigLayerInternal;
		return {
			...this._layerConfig,
			...edits,
		};
	}

	private _addInherited(
		config: ConfigLayerInternal,
		parent?: ConfigLayerInternal,
	) {
		if (!parent) return config;
		const inheritedProps: (keyof ConfigLayerInternal)[] = [
			'selectable',
			'dataServiceId',
			'tileSource',
			'tileLayerName',
			'icon',
			'selectedIcon',
			'iconBackground',
			'color',
			'selectedColor',
			'highlightColor',
			'outlineColor',
			'lineWidth',
			'labelColor',
			'fillOpacity',
			'pattern',
			'selectedPattern',
			'zIndex',
			'visible',
		];
		const mergedConfig = { ...config };
		inheritedProps.forEach(prop => {
			if (mergedConfig[prop] == null && parent[prop] != null) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				//@ts-ignore
				mergedConfig[prop] = parent[prop];
			}
		});
		mergedConfig.properties = {
			...parent.properties,
			...config.properties,
		};
		return mergedConfig;
	}

	private _addInternalIds(
		configLayer: ConfigLayer,
		parent?: ConfigLayerInternal,
	): ConfigLayerInternal {
		const id =
			configLayer.id ??
			this._generateLayerId(
				extractionAsText(configLayer.layerName),
				parent,
			);
		const idPath = parent ? `${parent.idPath}/${id}` : id;
		return {
			...configLayer,
			id,
			idPath,
		} as ConfigLayerInternal;
	}

	/**
	 * Generates a unique id from the concatenation of the
	 * parent layer name (if exists) and layer name and turning
	 * it into snake_case
	 * @param layerName name of layer
	 * @param parent parent layer configuration
	 * @returns snake_case id
	 */
	private _generateLayerId(layerName: string, parent?: ConfigLayer) {
		return parent
			? santizeString(`${parent?.id}_${layerName}`).replace(/^_/, '')
			: santizeString(layerName);
	}

	private _getTileSourceName(
		serviceId: string,
		tileSource: RegExp,
	): string | undefined {
		const service = this._serviceManager.getById(serviceId);
		return service?.getTileSource(tileSource);
	}

	private _getTileLayerName(
		serviceId: string,
		tileLayerName: RegExp,
	): string | undefined {
		const service = this._serviceManager.getById(serviceId);
		return service?.getTileLayerName(tileLayerName);
	}

	/**
	 * Return service status loaded and errored
	 * @param serviceId service id
	 * @returns status for loaded and errored
	 */
	private _serviceStatus(
		serviceId: string,
	): Pick<CompositeLayerProps, 'loaded' | 'errored' | 'cluster'> {
		const service = this._serviceManager.getById(serviceId);
		return {
			cluster: service?.cluster ?? false,
			loaded: service?.loaded ?? false,
			errored: service?.errored ?? false,
		};
	}

	private _checkProperties(
		serviceId: string,
		properties: ConfigLayerInternal['properties'],
	): ConfigLayerAsComposite['properties'] {
		if (!properties) return {};
		const service = this._serviceManager.getById(serviceId);
		return reduceArrayToObject(
			Object.entries(properties).map(([key, value]) => {
				const lookup = service?.getLookup(key);
				if (lookup) {
					if (
						Array.isArray(value) &&
						typeof value[0] === 'number' &&
						typeof value[1] === 'number'
					) {
						const ids = lookup.findInRange(value[0], value[1]);
						if (ids.length) {
							return {
								id: ids,
							};
						}
					} else if (typeof value === 'string') {
						const ids = lookup.findMatch(value);
						if (ids.length) {
							return {
								id: ids,
							};
						}
					}
				}
				return { [key]: value };
			}),
		);
	}

	private getDifferencesFromDefaults = (
		layer: Partial<ConfigLayer> & Generic,
	): Partial<ConfigLayer> => {
		const differences: Partial<ConfigLayer> & Generic = { ...layer };
		const colorByTheming = getColorByTheming(
			differences as ConfigLayerInternal,
		);
		Object.entries(differences).map(([key, value]) => {
			if ((this._layerConfig as Generic)[key] === value) {
				delete differences[key];
			}
		});
		if (t(this._layerConfig.layerName) === differences.layerName) {
			delete differences.layerName;
		}
		if (!SymbolLoader.iconValid(differences.icon)) {
			delete differences.icon;
		}

		if (colorByTheming === ColorByOptions.fixed) {
			delete differences.attribute;
			delete differences.attributeType;
		}
		if (colorByTheming !== ColorByOptions.range) {
			delete differences.colorRange;
		}
		// remove unnecessary props
		delete differences.iconImage;
		delete differences.layers;
		delete differences.idPath;
		delete differences.validColorBy;
		delete differences.showColorBy;
		delete differences.canHeatmap;
		delete differences.colorByTheming;
		return differences;
	};

	private _getLookups() {
		const service = this._serviceManager.getById(this.dataServiceId);
		if (!service) return [];
		const lookups = Object.entries(this.configWithEdits.properties || {})
			.map(([key, value]) => {
				const lookup = service?.getLookup(key);
				if (lookup) {
					if (
						Array.isArray(value) &&
						typeof value[0] === 'number' &&
						typeof value[1] === 'number'
					) {
						return { lookup, min: value[0], max: value[1] };
					} else if (typeof value === 'string') {
						return { lookup, value };
					}
				}
			})
			.filter(notUndefined);
		return lookups;
	}
}
