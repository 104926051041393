import React from 'react';
interface IconProps {
	width?: string;
	height?: string;
}

function RehabIcon(props: IconProps) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={props.width ? props.width : '100'}
			height={props.height ? props.height : '101'}
			fill="none"
			viewBox="0 0 100 101">
			<path
				fill="#fff"
				d="M0 0H100V100H0z"
				transform="translate(0 .645)"></path>
			<path
				stroke="#768D95"
				strokeWidth="2"
				d="M23.622 24.645H42.888999999999996V43.912H23.622z"></path>
			<path
				stroke="#768D95"
				strokeWidth="2"
				d="M23.622 57.378H42.888999999999996V76.645H23.622z"></path>
			<path
				stroke="#768D95"
				strokeWidth="2"
				d="M57.112 57.343H76.379V76.61H57.112z"></path>
			<path fill="#768D95" d="M32.42 45.256H34.09V55.278H32.42z"></path>
			<path
				fill="#768D95"
				d="M45.122 67.846H46.792V77.86800000000001H45.122z"
				transform="rotate(-90 45.122 67.846)"></path>
			<path
				fill="#768D95"
				d="M31.32 69.636l-2.864-2.864-.975.969 3.838 3.838 8.241-8.24-.968-.968-7.272 7.265zM61.875 63.036l.965-.964 3.824 3.824 3.824-3.824.964.964-3.824 3.824 3.824 3.825-.964.964-3.824-3.824-3.824 3.824-.965-.965 3.824-3.824-3.824-3.824z"></path>
		</svg>
	);
}

export default RehabIcon;
