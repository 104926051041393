import styled, { css } from 'styled-components';
import {
	FormControl,
	Grid,
	Paper,
	Table,
	TableCell,
	TableHead,
	Typography,
} from '@mui/material';
import { PageWrapper } from '@innovyze/stylovyze';

export const em = (px: number, basePx = 16) => `${px / basePx}em`;
export const rem = (px: number, basePx = 16) => `${px / basePx}rem`;

export const FullPageWrapper = styled(PageWrapper)`
	display: flex;
	flex-direction: column;
	height: 100%;
	padding-bottom: 0px;
`;

export const FullPage = styled.div`
	height: 100%;
	overflow: auto;
`;

export const PaperStyled = styled(Paper)`
	display: flex;
	flex-direction: column;
`;

export const OuterGrid = styled(Grid)`
	flex-direction: column;
	flex-grow: 1;
`;

export const StyledGrid = styled(Grid)`
	padding: ${rem(12)} ${rem(21)};
`;

export const StyledTable = styled(Table)`
	table-layout: 'auto';
	overflow: hidden;
`;

export const TableHeader = styled(TableHead)`
	background: #f2f2f2;
`;

export const StyledFormControl = styled(FormControl)`
	width: 90%;
`;

export const Title = styled(Typography)`
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 24px;
	text-align: left;
	color: #272833;
	margin-bottom: 0;
`;

export const actionStyles = css`
	color: #768d95;
`;

export const StyledMoreVert = styled.div`
	${actionStyles}
	float: right;
`;

export const TableBodyCell = styled(TableCell)`
	cursor: default;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const TableBodyCellMenu = styled(TableBodyCell)`
	cursor: inherit;
	width: 5%;
`;
