import { makeStyles } from '@mui/styles';

export const usePanelStyles = makeStyles(() => ({
	root: {
		width: '100%',
		'& > div': {
			padding: '0px',
		},
	},
}));
