import { massBalanceIntervalToResolution } from '../ConnectedMassBalanceChart/ConnectedMassBalanceChart.utils';
import { Resolution } from '../../../core/types/resolution.types';

import type { Ili, IliSeries } from '../../../core/types/ili.types';

const channelPropToId: Record<keyof Ili, IliSeries['id']> = {
  iliChannelId: 'ili',
  mnfChannelId: 'mnf',
  massBalance: 'massBalance',
};

export const generateIliSeries = (ili: Ili): IliSeries[] => {
  const iliSeries: IliSeries[] = [];
  const massBalance = ili.massBalance;

  Object.keys(channelPropToId).forEach((channelProp) => {
    let sensorId = ili[channelProp] as string | undefined;
    let resolution = Resolution['Daily'];

    /**
     * There's some cases where the massBalance prop is not
     * present on the ILI, so we don't have a way to retrieve
     * sensorId for the mass balance usage -- I'm putting
     * .Unknown.MassBalance as default value to make it fail.
     */
    if (channelProp === 'massBalance' && sensorId) {
      sensorId = massBalance?.zones?.[0].usageChannelId;
      resolution =
        massBalanceIntervalToResolution[
          massBalance?.interval ?? Resolution['Daily']
        ];
    }

    if (sensorId) {
      iliSeries.push({
        id: channelPropToId[channelProp],
        dataSource: {
          resolution,
          sensorId,
        },
      });
    }
  });

  return iliSeries;
};
