import CancellableFetch, {
	CancellableFetchParams,
	GetPageParams,
	PostPageParams,
} from './CancellableFetch';

import { DataServiceRequestError } from './DataServiceRequestError';
import { extractErrorContext } from '@Map/utils';
import logger from '@Map/logger/Logger';

export default abstract class RequestService {
	protected _baseUrl: string;
	protected _headers: [string, string][] | undefined;
	private _requests: { [key: string]: CancellableFetch | undefined } = {};

	constructor(url: string, token?: string) {
		this._baseUrl = url;
		this._headers = token
			? [['Authorization', `Bearer ${token}`]]
			: undefined;
	}

	setRequest(name: string, params: CancellableFetchParams): void {
		this.cancel(name);
		this._requests[name] = new CancellableFetch({
			...params,
			headers: this._headers,
		});
	}

	async getRequest<T>(
		name: string,
		fallback: T,
		errorMessageId: string,
		errorMessage: string,
		params: GetPageParams<T> = {},
	): Promise<T> {
		const request = this._requests[name];
		if (!request) return fallback;
		try {
			return await request.getPage(params);
		} catch (e) {
			this.errorCatch(
				e as DataServiceRequestError,
				errorMessageId,
				errorMessage,
			);
		}
		return fallback;
	}

	async postRequest<T, R>(
		name: string,
		errorMessageId: string,
		errorMessage: string,
		params: PostPageParams<T, R>,
	): Promise<R | null> {
		const request = this._requests[name];
		if (!request) return null;
		try {
			return await request.postPage(params);
		} catch (e) {
			this.errorCatch(
				e as DataServiceRequestError,
				errorMessageId,
				errorMessage,
			);
		}
		return null;
	}

	async putRequest<T, R>(
		name: string,
		errorMessageId: string,
		errorMessage: string,
		params: PostPageParams<T, R>,
	): Promise<R | null> {
		const request = this._requests[name];
		if (!request) return null;
		try {
			return await request.putPage(params);
		} catch (e) {
			this.errorCatch(
				e as DataServiceRequestError,
				errorMessageId,
				errorMessage,
			);
		}
		return null;
	}

	async deleteRequest(
		name: string,
		errorMessageId: string,
		errorMessage: string,
	): Promise<void> {
		const request = this._requests[name];
		if (!request) return;
		try {
			await request.deletePage();
		} catch (e) {
			this.errorCatch(
				e as DataServiceRequestError,
				errorMessageId,
				errorMessage,
			);
		}
	}

	cancel(name: string): void {
		this._requests[name]?.cancel();
		delete this._requests[name];
	}

	destroy(): void {
		Object.keys(this._requests).forEach(key => {
			this.cancel(key);
		});
	}

	protected errorCatch(
		e: DataServiceRequestError,
		messageId: string,
		errorMessage: string,
	): void {
		const context = {
			messageId,
			...extractErrorContext(e),
		};
		if (e.name !== 'AbortError') {
			logger.error(errorMessage, context);
		} else {
			logger.info('Data services request aborted', context);
		}
	}
}
