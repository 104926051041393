import { AnyAction, createReducer } from '@reduxjs/toolkit';
import { FacilityStoreState, SensorIdMap } from '@Types';
import {
	clearFacilityList,
	clearSensorIdsMap,
	getFacilityResolved,
	setSensorIdsMap,
} from '@Actions/facility';

export const initialState: FacilityStoreState = {
	facilitiesList: {},
	sensorIdsMap: {},
};

interface FacilityReducer {
	[x: string]: (
		state: FacilityStoreState,
		action: AnyAction,
	) => FacilityStoreState | undefined;
}

const reducer: FacilityReducer = {
	[getFacilityResolved.toString()]: (state, action) => ({
		...state,
		facilitiesList: {
			...state.facilitiesList,
			[action.payload.facilityId]: action.payload.facilityItem,
		},
	}),
	[clearFacilityList.toString()]: state => ({
		...state,
		facilitiesList: {},
	}),
	[setSensorIdsMap.toString()]: (state, action) => ({
		...state,
		sensorIdsMap: action.payload.reduce(
			(map: Record<string, SensorIdMap>, item: SensorIdMap) => {
				const id = item.sensorId;
				map[id] = {
					sensorId: item.sensorId,
					sensorAlias: item.sensorAlias,
				};
				return map;
			},
			{},
		),
	}),
	[clearSensorIdsMap.toString()]: state => ({
		...state,
		sensorIdsMap: {},
	}),
};

export default createReducer(initialState, reducer);
