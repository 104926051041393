import { rem } from '@Utils/styles';
import styled from 'styled-components';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: calc(100% - ${rem(100)});
`;

export const Heading = styled.div`
	font-size: ${rem(24)};
	margin-bottom: ${rem(16)};
`;
