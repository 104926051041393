import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { getSpatialLayers, mapSpatialTilesSourceHide } from '@Actions';
import MapInsertSpatialComponent, {
	MapInsertSpatialProps,
} from './MapInsertSpatialComponent';

const MapInsertSpatial: React.FC<MapInsertSpatialProps> = props => {
	const dispatch = useDispatch();

	useEffect(() => {
		console.warn(
			'MapInsertSpatial is deprecated',
			'use MapInsertSpatialComponent instead and consume the dispatches directly',
			'We want to avoid duplicates calls to getSpatialLayers',
		);
	}, []);

	// Copy next useEffect to your parent component instead of using this component
	useEffect(() => {
		// import {	mapSpatialTilesSourceHide,	getSpatialLayers } from '@innovyze/lib_am_common/Actions';
		dispatch(mapSpatialTilesSourceHide(true));
		// map redux populated with getSpatialLayers response
		dispatch(getSpatialLayers());
	}, []);
	return <MapInsertSpatialComponent {...props} />;
};

export default MapInsertSpatial;
