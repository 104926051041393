/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from 'react';

type DataSource<T = Record<string, string>> = T;

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Utils
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

// eslint-disable-next-line @typescript-eslint/ban-types
const stringifyDataSource = (dataSource: DataSource<{}>): string => {
  return JSON.stringify(dataSource);
};

const makeDataSource = <S extends DataSource>(
  dataSourceObject: S,
  dataSourceKeys: (keyof S)[]
): S => {
  const dataSource = dataSourceKeys.reduce((_dataSource, key) => {
    return { ..._dataSource, [key]: dataSourceObject[key] };
  }, {} as S);

  return dataSource;
};

const makeDataSources = <S extends DataSource>(
  dataSourceObjects: S[],
  dataSourceKeys: (keyof S)[]
): S[] => {
  const dataSourceMap: Map<string, S> = new Map();

  for (const dataSourceObject of dataSourceObjects) {
    const dataSource = makeDataSource(dataSourceObject, dataSourceKeys);
    const stringifiedDataSource = stringifyDataSource(dataSource);

    if (!dataSourceMap.has(stringifiedDataSource)) {
      dataSourceMap.set(stringifiedDataSource, dataSource);
    }
  }

  return Array.from(dataSourceMap.values());
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Data Sources Hook
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useDataSources = <S extends DataSource>(
  dataSourceObjects: S[],
  dataSourceKeys: (keyof S)[]
) => {
  const _dataSources = makeDataSources(dataSourceObjects, dataSourceKeys);

  const dataSources = React.useMemo(
    () => _dataSources,
    [_dataSources.map(stringifyDataSource).join(',')]
  );

  const getDataSource = React.useCallback((dataSourceObject: S) => {
    return makeDataSource(dataSourceObject, dataSourceKeys);
  }, []);

  return { dataSources, getDataSource };
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Trendline
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const makeTrendlineDataSource = <
  S extends DataSource<{ trendline?: { periods?: number } }>,
>(
  dataSourceObject: S,
  dataSourceKeys: (keyof S)[]
): S => {
  const dataSource = dataSourceKeys.reduce((_dataSource, key) => {
    return {
      ..._dataSource,
      [key]: dataSourceObject[key],
      trendline: { ...dataSourceObject.trendline },
    };
  }, {} as S);

  return dataSource;
};

const makeTrendlineDataSources = <
  S extends DataSource<{ trendline?: { periods?: number } }>,
>(
  dataSourceObjects: S[],
  dataSourceKeys: (keyof S)[]
): S[] => {
  const dataSourceMap: Map<string, S> = new Map();

  for (const dataSourceObject of dataSourceObjects) {
    const dataSource = makeTrendlineDataSource(
      dataSourceObject,
      dataSourceKeys
    );

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const stringifiedDataSource = stringifyDataSource(dataSource);

    if (!dataSourceMap.has(stringifiedDataSource)) {
      dataSourceMap.set(stringifiedDataSource, dataSource);
    }
  }

  return Array.from(dataSourceMap.values());
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useTrendlineDataSources = <
  S extends DataSource<{ trendline?: { periods?: number } }>,
>(
  dataSourceObjects: S[],
  dataSourceKeys: (keyof S)[]
) => {
  const _dataSources = makeTrendlineDataSources(
    dataSourceObjects,
    dataSourceKeys
  );

  const dataSources = React.useMemo(
    () => _dataSources,
    [_dataSources.map(stringifyDataSource).join(',')]
  );

  const getDataSource = React.useCallback((dataSourceObject: S) => {
    return makeTrendlineDataSource(dataSourceObject, dataSourceKeys);
  }, []);

  return { dataSources, getDataSource };
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export { useDataSources, useTrendlineDataSources, stringifyDataSource };

export type { DataSource };
