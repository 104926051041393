import MapboxLayer, { UniqueLayerProps } from './MapboxLayer';
import { ServiceLayer } from '../../services/types';

export default class HeatmapLayer extends MapboxLayer {
	protected _type: ServiceLayer['type'] = 'heatmap';

	layerProperties(): UniqueLayerProps {
		return {
			paint: {
				// Increase the heatmap color weight weight by zoom level
				// heatmap-intensity is a multiplier on top of heatmap-weight
				'heatmap-intensity': [
					'interpolate',
					['linear'],
					['zoom'],
					0,
					1,
					9,
					3,
				],
				// Adjust the heatmap radius by zoom level
				'heatmap-radius': [
					'interpolate',
					['linear'],
					['zoom'],
					0,
					2,
					9,
					20,
				],
				// Transition from heatmap to circle layer by zoom level
				'heatmap-opacity': 0.5,
			},
		};
	}
}
