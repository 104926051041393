/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { DataSourceType } from '../../../core/types/data.types';
import {
  useIsFeatureEnabled,
  useSelectSensors,
  useSettings,
} from '@innovyze/stylovyze';
import * as CustomAnalyticChart from '../../modules/customAnalytic-chart';
import * as InsightChart from '../../core/_insight-chart';
import * as React from 'react';
import * as SeriesData from '../../core/series-data';
import * as TimeSeriesData from '../../core/time-series-data';
import * as TimeSeriesDataOld from '../../core/time-series-data-old';
import {
  fixEdgeResponseResults,
  getEdgeAutoResolution,
  getEdgeLowestResolution,
  resolutionToZoomButtons,
} from '../insight-historical-chart/insight-historical-chart.utils';
import { fixCollectionInterval } from '../../core/time-series-data/utils';
import { DateTime } from 'luxon';
import { Theme } from '../../core/utils/theme-utils';

type EdgeSource = {
  sensorId: string;
  resolution: InsightChart.Resolution;
  analytic: InsightChart.Reading | InsightChart.AnalyticFunction;
};

function edgeSourceStringifier(source: EdgeSource): string {
  const analyticString = TimeSeriesData.stringifyAnalytic(source.analytic);
  return `${source.sensorId}:${source.resolution}:${analyticString}`;
}

function limit(value: string | null | undefined, defaultValue = 500): number {
  if (typeof value === 'string' || value === null) return defaultValue;
  if (isNaN(Number(value))) return defaultValue;
  return Number(value);
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 *	Custom Analytic Chart Preset Root Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
type CustomAnalyticChartRootProps = Omit<
  CustomAnalyticChart.RootProps,
  'children' | 'elements' | 'events' | 'status' | 'error'
>;

export interface InsightCustomAnalyticChartProps
  extends CustomAnalyticChartRootProps {
  analyticStartDate?: string;
  analyticEndDate?: string;
  timeRangeSelection?: InsightChart.TimeRangeSelection;
  series: InsightCustomAnalyticChartSeriesProps[];
  onTimeRangeSelectionChange?: (
    timeRangeSelection: InsightChart.TimeRangeSelection
  ) => void;
  onSeriesVisibilityChange?:
    | ((seriesId: string, type: 'hide' | 'show') => void)
    | undefined;
}

export const Root = React.forwardRef<
  { chart: Highcharts.Chart | undefined },
  InsightCustomAnalyticChartProps
>((props, ref): React.ReactElement => {
  const { companySettings } = useSettings();
  const { sensors, initialized: sensorsInitialized } = useSelectSensors();
  const dataLimit = useIsFeatureEnabled('info-360-analytics-hp2-charts-limit');
  const useV3 = useIsFeatureEnabled('info-360-edge-analytics-parquet-files');
  const edge = useIsFeatureEnabled(
    'info-360-edge-analytics-charts-custom-analytics'
  );

  const sensorSeries = props.series.filter((source) => source.sensorId);
  const sources = InsightChart.useSources(sensorSeries);
  const smallestResolution = InsightChart.useSmallestResolution(sensorSeries);
  const seriesData = InsightChart.useData(sources);
  const navigatorData = InsightChart.useData(sources, { summary: true });
  const constantData = InsightChart.useConstantData();
  const timeRangeSelectionRef = React.useRef<InsightChart.TimeRangeSelection>({
    min: -1,
    max: -1,
  });

  const [edgeSources] = SeriesData.useSources<EdgeSource>(() => {
    if (!props.series?.length) return [];
    const series = props.series.filter((s) => !s.customData && s.sensorId);
    return series.map(({ sensorId, resolution, analytic }) => ({
      sensorId,
      resolution,
      analytic,
    }));
  }, [props.series]);

  const [edgeData, edgeStatus, retrieveEdgeData] = SeriesData.useRetriever<
    TimeSeriesData.ResponseBodyWithSnapping,
    {
      timeSelection?: TimeSeriesData.PartialTimeSelection;
      snapping?: TimeSeriesData.Snapping;
    }
  >(
    async (signal, params) => {
      if (!edgeSources?.length || !sensorsInitialized) return;

      let from: string | number = 'oldest';
      const _from =
        typeof props.analyticStartDate !== 'undefined'
          ? DateTime.fromISO(props.analyticStartDate)
          : DateTime.invalid('Missing start date');

      let to: string | number = 'latest';
      const _to =
        typeof props.analyticEndDate !== 'undefined'
          ? DateTime.fromISO(props.analyticEndDate)
          : DateTime.invalid('Missing end date');

      if (_from.isValid) from = _from.toMillis();
      if (_to.isValid) to = _to.toMillis();

      const timeSelection = {
        from: params.timeSelection?.from ?? from,
        to: params.timeSelection?.to ?? to,
      } as TimeSeriesData.TimeSelection;

      const response = await TimeSeriesData.retrieve(signal, {
        order: 'asc',
        timeSelection,
        limit: limit(dataLimit),
        snapping: params.snapping ?? 'oldest',
        timeZone: companySettings.timeZoneIANA,
        data_version: useV3 ? 'v3.0' : 'v2',
        sources: edgeSources.map((s) => {
          const _sensor = sensors.find((_s) => _s.sensorId === s.sensorId);
          const seconds = _sensor?.collectionInterval;
          const analytic = s.analytic ?? 'Close';
          const resolution =
            s.resolution === 'AUTO'
              ? getEdgeAutoResolution(_sensor?.resolutions, timeSelection)
              : s.resolution;

          return {
            key: edgeSourceStringifier(s),
            sensorId: s.sensorId,
            collectionInterval: { seconds: fixCollectionInterval(seconds) },
            analytic: TimeSeriesData.makeAnalytic(resolution, analytic),
          };
        }),
      });

      const snapSelection = TimeSeriesData.getSnapSelection(response.data);
      const result = { ...response.data, snapSelection };
      result.results = fixEdgeResponseResults(edgeSources, result.results);

      return result;
    },
    [
      props.analyticEndDate,
      props.analyticStartDate,
      companySettings.timeZoneIANA,
      dataLimit,
      edgeSources,
      sensors,
      sensorsInitialized,
      useV3,
    ]
  );

  const [snapshotEdgeData, _snapshotEdgeStatus, retrieveSnapshotEdgeData] =
    SeriesData.useRetriever<
      TimeSeriesData.ResponseBody & { start?: number; end?: number }
    >(
      async (signal) => {
        if (!edgeSources?.length || !sensorsInitialized) return;

        let from: string | number = 'oldest';
        const _from =
          typeof props.analyticStartDate !== 'undefined'
            ? DateTime.fromISO(props.analyticStartDate)
            : DateTime.invalid('Missing start date');

        let to: string | number = 'latest';
        const _to =
          typeof props.analyticEndDate !== 'undefined'
            ? DateTime.fromISO(props.analyticEndDate)
            : DateTime.invalid('Missing end date');

        if (_from.isValid) from = _from.toMillis();
        if (_to.isValid) to = _to.toMillis();

        const response = await TimeSeriesData.retrieve(signal, {
          order: 'asc',
          timeZone: companySettings.timeZoneIANA,
          timeSelection: { from, to },
          data_version: useV3 ? 'v3.0' : 'v2',
          sources: edgeSources.map((s) => {
            const _sensor = sensors.find((_s) => _s.sensorId === s.sensorId);
            const seconds = fixCollectionInterval(_sensor?.collectionInterval);
            const analytic = s.analytic ?? 'Close';

            return {
              key: edgeSourceStringifier(s),
              sensorId: s.sensorId,
              collectionInterval: { seconds },
              analytic: TimeSeriesData.makeAnalytic('DAILY', analytic),
            };
          }),
        });

        // In order to have constant values plotted in the navigator, we need
        //to know the start and end of all series.
        const [start, end] = Object.values(response.data.results)?.reduce(
          (t, d) => {
            // data.at(0) gives oldest data point
            t[0] = take('min', t[0], d.data?.at(0)?.[0]);
            // data.at(-1) gives latest data point
            t[1] = take('max', t[0], d.data?.at(-1)?.[0]);
            return t;
          },
          [undefined, undefined] as [number | undefined, number | undefined]
        ) ?? [undefined, undefined];

        return { ...response.data, start, end };
      },
      [
        props.analyticEndDate,
        props.analyticStartDate,
        companySettings.timeZoneIANA,
        dataLimit,
        edgeSources,
        sensors,
        sensorsInitialized,
        useV3,
      ]
    );

  const { retrieve } = seriesData;
  const { retrieve: retrieveNavigatorData } = navigatorData;

  React.useEffect(() => {
    if (!sensorsInitialized) return;

    const { min, max } = timeRangeSelectionRef.current;

    if (
      min !== props.timeRangeSelection?.min &&
      max !== props.timeRangeSelection?.max
    ) {
      if (edge) {
        retrieveEdgeData({
          timeSelection: {
            from: props.timeRangeSelection?.min,
            to: props.timeRangeSelection?.max,
          },
        });
      } else {
        retrieve({
          timeSelection: {
            type: 'range',
            start: props.timeRangeSelection?.min,
            end: props.timeRangeSelection?.max,
          },
        });
      }
    }
  }, [
    edge,
    sensorsInitialized,
    props.timeRangeSelection?.max,
    props.timeRangeSelection?.min,
    retrieve,
    retrieveEdgeData,
    timeRangeSelectionRef,
  ]);

  React.useEffect(() => {
    if (!sensorsInitialized) return;
    if (edge) {
      retrieveSnapshotEdgeData();
    } else {
      retrieveNavigatorData();
    }
  }, [
    edge,
    sensorsInitialized,
    retrieveNavigatorData,
    retrieveSnapshotEdgeData,
  ]);

  const getDataSourceType = (
    seriesProps: InsightCustomAnalyticChartSeriesProps
  ) => {
    if (seriesProps.customData) return 'customData';
    if (seriesProps.type === DataSourceType.ConstantDataSource)
      return 'constantData';
    return 'sensorData';
  };

  const lowestResolution = React.useMemo(() => {
    if (!props.series?.length) return undefined;
    return getEdgeLowestResolution(props.series.map((s) => s.resolution));
  }, [props.series]);

  const zoom = React.useMemo(
    () => resolutionToZoomButtons(lowestResolution, limit(dataLimit)),
    [dataLimit, lowestResolution]
  );

  return (
    <CustomAnalyticChart.Root
      status={edge ? edgeStatus : seriesData.status}
      error={seriesData.error}
      zoom={zoom}
      ref={ref}
      elements={{
        series: props.series.map((seriesProps, seriesIndex) => {
          let _seriesData:
            | TimeSeriesData.ResponseResultEntry
            | { data: InsightChart.Data; unit?: string }
            | undefined;

          let _navigatorData:
            | TimeSeriesData.ResponseResultEntry
            | { data: InsightChart.Data; unit?: string }
            | undefined;

          const dataSourceType = getDataSourceType(seriesProps);

          switch (dataSourceType) {
            case 'customData':
              _seriesData = {
                data: seriesProps.customData ?? [],
                unit: seriesProps.customUnit,
              };
              _navigatorData = {
                data: seriesProps.customData ?? [],
                unit: seriesProps.customUnit,
              };
              break;
            case 'constantData':
              _seriesData = constantData.generateConstantData(
                seriesProps.constantValue ?? 0,
                edge ? edgeData?.snapSelection?.start : seriesData?.start,
                edge ? edgeData?.snapSelection?.end : seriesData?.end,
                smallestResolution,
                edge ? edgeStatus : seriesData.status
              );

              _navigatorData = constantData.generateConstantData(
                seriesProps.constantValue ?? 0,
                edge ? snapshotEdgeData?.start : navigatorData?.start,
                edge ? snapshotEdgeData?.end : navigatorData?.end,
                'DAILY',
                edge ? edgeStatus : navigatorData.status
              );
              break;
            case 'sensorData':
            default:
              if (edge) {
                const key = edgeSourceStringifier(seriesProps);
                const edgeDataEntry = edgeData?.results?.[key];
                const snapshotEdgeDataEntry = snapshotEdgeData?.results?.[key];
                _seriesData = edgeDataEntry;
                _navigatorData = snapshotEdgeDataEntry;
              } else {
                _seriesData = seriesData.getEntry(
                  seriesProps.sensorId,
                  seriesProps.resolution
                );
                _navigatorData = navigatorData.getEntry(
                  seriesProps.sensorId,
                  seriesProps.resolution
                );
              }
          }

          return (
            <Series
              {...seriesProps}
              key={seriesIndex}
              index={seriesIndex}
              edge={!!edge}
              seriesData={_seriesData?.data}
              navigatorData={_navigatorData?.data}
            />
          );
        }),
      }}
      events={{
        series: {
          // Will need type refactoring to accept string, but string is the correct one
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onVisibilityChange: props.onSeriesVisibilityChange,
        },
        xAxis: {
          onExtremesChange: (_trigger, min, max) => {
            const snapS = edge
              ? edgeData?.snapSelection
              : { start: seriesData.start, end: seriesData.end };

            const limitFrom = getLimitFrom(snapS?.start, snapS?.end, min, max);

            timeRangeSelectionRef.current.min = min;
            timeRangeSelectionRef.current.max = max;

            if (edge) {
              retrieveEdgeData({
                snapping: limitFrom === 'start' ? 'latest' : 'oldest',
                timeSelection: {
                  from: min,
                  to: max,
                },
              });
            } else {
              seriesData.retrieve({
                pagination: { limitFrom },
                timeSelection: {
                  type: 'range',
                  start: min,
                  end: max,
                },
              });
            }

            props.onTimeRangeSelectionChange?.({ min, max });
          },
        },
      }}
      title={props.title}
      stacked={props.stacked}
      xAxis={{
        min: edge ? edgeData?.snapSelection?.start : seriesData.start,
        max: edge ? edgeData?.snapSelection?.end : seriesData.end,
        enableGridlines: props.xAxis?.enableGridlines,
        label: props.xAxis?.label,
      }}
      yAxis={{
        enableGridlines: props.yAxis?.enableGridlines,
        label: props.yAxis?.label,
      }}
      enableMarkers={props.enableMarkers}
      selectedTheme={props.selectedTheme}
    />
  );
});

Root.displayName = 'CustomAnalyticChartRoot';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Series Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

type InsightCustomAnalyticChartSeriesProps = Omit<
  CustomAnalyticChart.SeriesProps,
  'navigatorData'
> & {
  index: number;
  sensorId: string;
  resolution: InsightChart.Resolution;
  constantValue?: number;
  analytic: InsightChart.Reading | InsightChart.AnalyticFunction;
  edge?: boolean;
  seriesData?: InsightChart.Data | TimeSeriesData.ResponseData;
  navigatorData?: InsightChart.Data | TimeSeriesData.ResponseData;
  type: DataSourceType;
  customData?: InsightChart.Data;
  customUnit?: string;
  selectedTheme?: Theme;
};

const Series = (
  props: InsightCustomAnalyticChartSeriesProps
): React.ReactElement => {
  const data = React.useMemo(() => {
    if (
      props.type !== DataSourceType.ConstantDataSource &&
      !props.customData &&
      props.edge
    ) {
      if (!(props.seriesData as TimeSeriesData.ResponseData)?.length) return;
      return props.seriesData as TimeSeriesDataOld.TimeSeriesData;
    } else {
      if (!props.seriesData?.length) return;
      return InsightChart.isAnalyticFunction(props.analytic)
        ? InsightChart.processDataWithAnalyticFunction(
            props.seriesData as InsightChart.Data,
            props.analytic
          )
        : InsightChart.processDataWithReading(
            props.seriesData as InsightChart.Data,
            props.analytic
          );
    }
  }, [
    props.type,
    props.customData,
    props.edge,
    props.seriesData,
    props.analytic,
  ]);

  const navigatorData = React.useMemo(() => {
    if (
      props.type !== DataSourceType.ConstantDataSource &&
      !props.customData &&
      props.edge
    ) {
      if (!(props.navigatorData as TimeSeriesData.ResponseData)?.length) return;
      return props.navigatorData as TimeSeriesDataOld.TimeSeriesData;
    } else {
      if (!props.navigatorData?.length) return;

      return InsightChart.isAnalyticFunction(props.analytic)
        ? InsightChart.processDataWithAnalyticFunction(
            props.navigatorData as InsightChart.Data,
            props.analytic
          )
        : InsightChart.processDataWithReading(
            props.navigatorData as InsightChart.Data,
            props.analytic
          );
    }
  }, [
    props.type,
    props.customData,
    props.edge,
    props.navigatorData,
    props.analytic,
  ]);

  return (
    <CustomAnalyticChart.Series
      data={data}
      navigatorData={navigatorData}
      color={props.color}
      hidden={props.hidden}
      index={props.index}
      name={props.name}
      yAxis={props.yAxis}
      selectedTheme={props.selectedTheme}
    />
  );
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Utils
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const take = (
  value: 'min' | 'max',
  a: number | undefined,
  b: number | undefined
) => {
  if (a === undefined) return b;
  if (b === undefined) return a;
  return Math[value](a, b);
};

const getDiff = (a: number, b: number): number =>
  Math.abs(a > b ? a - b : b - a);

const getLimitFrom = (
  prevMin: number | undefined,
  prevMax: number | undefined,
  min: number | undefined,
  max: number | undefined
) => {
  let limitFrom: 'start' | 'end' = 'end';

  if (
    prevMin !== undefined &&
    prevMax !== undefined &&
    min !== undefined &&
    max !== undefined
  ) {
    const minDiff = getDiff(prevMin, min);
    const maxDiff = getDiff(prevMax, max);

    if (minDiff > maxDiff) {
      limitFrom = 'start';
    }
  }

  return limitFrom;
};
