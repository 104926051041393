import { NamespacedStoreState } from '@Types/store.types';
import { createSelector } from '@reduxjs/toolkit';
import { moduleNamespace } from '@Store/config.store';
import { useSelector } from 'react-redux';

const parseNamespace = (state: NamespacedStoreState) => state[moduleNamespace];

export const assetManholeSelector = createSelector(
	parseNamespace,
	state => state.assetManhole,
);

export const assetMaholeAssetSelector = createSelector(
	parseNamespace,
	state => state.assetManhole?.asset,
);

export const selectAssetManhole = () => useSelector(assetManholeSelector);
export const selectAssetManholeAsset = () =>
	useSelector(assetMaholeAssetSelector);

export default selectAssetManhole;
