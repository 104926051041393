type RootElement = HTMLElement | null;

export default class ShadowElement extends HTMLElement {
	private _shadow: ShadowRoot;
	private _rootEl: RootElement = null;

	constructor() {
		super();
		this._shadow = this.attachShadow({ mode: 'open' });
	}

	set rootElement(el: RootElement) {
		this._rootEl = el;
	}

	get rootElement(): RootElement {
		return this._rootEl;
	}

	attachElement(): void {
		if (this._shadow && this.rootElement)
			this._shadow.appendChild(this.rootElement);
	}

	addStyles(styles: string): void {
		if (this._shadow) {
			const el = document.createElement('style');
			el.innerHTML = styles;
			this._shadow.appendChild(el);
		}
	}
}
