import React from 'react';

export const NoteIcon = () => (
	<svg
		width="25"
		height="24"
		viewBox="0 0 25 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M8.46489 4.76925L8.46434 6.19712H7.16908V20.5721H17.4516V6.19712H16.156L16.1558 4.76925H18.8794V22H5.74121V4.76925H8.46489ZM8.17223 14.1076H16.4481V15.5053H8.17223V14.1076ZM8.17223 16.5881H16.4481V17.9857H8.17223V16.5881ZM8.91046 8.76239H15.7102V12.54H8.91046V8.76239ZM12.3103 2C13.2531 2 14.0207 2.76788 14.0207 3.71064C14.0207 4.6534 13.2531 5.42128 12.3103 5.42128C11.3676 5.42128 10.5997 4.6534 10.5997 3.71064C10.5997 2.76788 11.3676 2 12.3103 2ZM12.3103 2.7138C11.758 2.7138 11.3135 3.15827 11.3135 3.71064C11.3135 4.26301 11.758 4.70721 12.3103 4.70721C12.8627 4.70721 13.3069 4.26301 13.3069 3.71064C13.3069 3.15827 12.8627 2.7138 12.3103 2.7138ZM9.36812 4.07248H10.4561C10.628 4.95374 11.4066 5.59973 12.3103 5.59973C13.213 5.59973 13.9921 4.95594 14.1645 4.07248H15.2525C15.6514 4.07248 15.9776 4.3989 15.9776 4.7978V6.27618H8.64279V4.7978C8.64279 4.3989 8.96921 4.07248 9.36812 4.07248Z"
			fill="#768D95"
		/>
	</svg>
);
