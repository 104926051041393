import {
	RehabDecisionTree,
	RehabTreePublishStateType,
} from '@Types/rehab.types';

import { ResolvedResponse } from '@Types/pagination.types';
import rehabApi from '@Apis/rehab.api';

interface Params {
	listAllTrees?: boolean;
	listAll?: boolean;
	listTreesAndActions?: boolean;
	publishedOnly?: boolean;
	slice?: number;
}
export const getRehabTreesApi = (params: Params = { listAllTrees: true }) => {
	const query = new URLSearchParams([
		...Object.entries(params)
			.filter(([, value]) => !!value)
			.map(([key, value]) => [key, `${value}`]),
	]).toString();
	return rehabApi.get<RehabDecisionTree>(`/rehabTrees?${query}`);
};

export const getRehabResultApi = (treeId: string, query: string) => {
	const address = `/rehabResults/${treeId}`;
	return rehabApi.get<ResolvedResponse>(`${address}${query}`);
};

export const toggleRehabTreePublishStateApi = (
	treeId: string,
	newPublishState: RehabTreePublishStateType,
) => {
	const address = `/rehabTree/${treeId}`;
	return rehabApi.put<ResolvedResponse>(address, { newPublishState });
};

export const getRehabActions = (treeId: string) => {
	const address = `/rehabActions/${treeId}`;
	return rehabApi.get<ResolvedResponse>(address);
};
