import { ExtractionKeys, e, t } from '@Translations/extraction';

import { ConfigLayer } from '@Root';
import { ConfigOverrides } from '@Map/types';
import { IconSet } from './types';
import { notUndefined } from '@Map/utils';
import { reduceArrayToObject } from '@Map/utils';

interface AssetDetailType {
	displayName: string;
	layerName: string;
	icon?: string;
	color?: string;
	defaultMinZoom?: number;
	layerType?: ConfigLayer['type'];
}

type TranslateAssetDetailType = ExtractionKeys<
	AssetDetailType,
	'displayName' | 'layerName'
>;

export const assetUnknown: TranslateAssetDetailType = {
	displayName: e('Unknown'),
	layerName: e('Unknown'),
	icon: 'unknown',
	color: '#4A6067',
};

/**
 * An Onu specific asset registry responding to Onu asset type information.
 */
export default class AssetRegistry {
	private static assetTypes: { [index: string]: TranslateAssetDetailType } = {
		control_valve: {
			displayName: e('Control Valve', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Control Valves', {
				context: 'Asset registry - plural item',
			}),
			icon: 'control-valve',
			color: '#4A7E04',
		},
		customer: {
			displayName: e('Customer', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Customers', {
				context: 'Asset registry - plural item',
			}),
			icon: 'customer',
			color: '#4A6067',
			defaultMinZoom: 18,
		},
		facility: {
			displayName: e('Facility', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Facilities', {
				context: 'Asset registry - plural item',
			}),
			icon: 'facilities',
			color: '#AA5D00',
			defaultMinZoom: 18,
		},
		fitting: {
			displayName: e('Fitting', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Fittings', {
				context: 'Asset registry - plural item',
			}),
			icon: 'fitting',
			color: '#AA5D00',
			defaultMinZoom: 18,
		},
		float_valve: {
			displayName: e('Float Valve', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Float Valves', {
				context: 'Asset registry - plural item',
			}),
			icon: 'float-valve',
			color: '#4A7E04',
		},
		hydrant: {
			displayName: e('Hydrant', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Hydrants', {
				context: 'Asset registry - plural item',
			}),
			icon: 'hydrant',
			color: '#AA5D00',
		},
		flow_meter: {
			displayName: e('Flow Meter', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Flow Meters', {
				context: 'Asset registry - plural item',
			}),
			icon: 'flow-meter',
			color: '#AA0000',
		},
		non_return_valve: {
			displayName: e('Non-return Valve', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Non-return Valves', {
				context: 'Asset registry - plural item',
			}),
			icon: 'non-return-valve',
			color: '#4A7E04',
		},
		other_valve: {
			displayName: e('Other Valve', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Other Valves', {
				context: 'Asset registry - plural item',
			}),
			icon: 'other-valve',
			color: '#4A7E04',
		},
		meter: {
			displayName: e('Meter', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Meters', {
				context: 'Asset registry - plural item',
			}),
			icon: 'meter',
			color: '#AA0000',
		},
		pump: {
			displayName: e('Pump', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Pumps', {
				context: 'Asset registry - plural item',
			}),
			icon: 'pump',
			color: '#4C3DAD',
			defaultMinZoom: 11,
		},
		wwPump: {
			displayName: e('Pump', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Pumps', {
				context: 'Asset registry - plural item',
			}),
			icon: 'pump',
			layerType: 'line',
			color: '#4C3DAD',
			defaultMinZoom: 11,
		},
		pump_station: {
			displayName: e('Pump Station', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Pump Stations', {
				context: 'Asset registry - plural item',
			}),
			icon: 'pump-station',
			color: '#4C3DAD',
			defaultMinZoom: 11,
		},
		fixed_head: {
			displayName: e('Fixed Head', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Fixed Heads', {
				context: 'Asset registry - plural item',
			}),
			icon: 'fixed-head',
			color: '#007CA0',
		},
		sensor: {
			displayName: e('Sensor', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Sensors', {
				context: 'Asset registry - plural item',
			}),
			icon: 'sensor',
			color: '#AA0000',
			defaultMinZoom: 11,
		},
		tank: {
			displayName: e('Tank', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Tanks', {
				context: 'Asset registry - plural item',
			}),
			icon: 'tank',
			color: '#007CA0',
			defaultMinZoom: 11,
		},
		service_valve: {
			displayName: e('Service Valve', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Service Valves', {
				context: 'Asset registry - plural item',
			}),
			icon: 'service-valve',
			color: '#4A7E04',
		},
		well: {
			displayName: e('Well', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Wells', {
				context: 'Asset registry - plural item',
			}),
			icon: 'well',
			color: '#007CA0',
		},
		pipe: {
			displayName: e('Pipe', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Pipes', {
				context: 'Asset registry - plural item',
			}),
			color: '#007ca0',
			defaultMinZoom: 2,
			layerType: 'line',
		},
		wwPipe: {
			displayName: e('Pipe', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Pipes', {
				context: 'Asset registry - plural item',
			}),
			color: '#007ca0',
			defaultMinZoom: 2,
			layerType: 'line',
		},
		'sensor-alert': {
			displayName: e('Alert', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Alerts', {
				context: 'Asset registry - plural item',
			}),
			icon: 'sensor-alert',
			color: '#AA0000',
		},
		valve_open_inoperable: {
			displayName: e('Valve Open Inoperable', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Valves', {
				context: 'Asset registry - plural item',
			}),
			icon: 'valve-open-inoperable',
			color: '#007ca0',
		},
		valve_closed_inoperable: {
			displayName: e('Valve Closed Inoperable', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Valves', {
				context: 'Asset registry - plural item',
			}),
			icon: 'valve-closed-inoperable',
			color: '#007ca0',
		},
		valve_open: {
			displayName: e('Valve Open', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Valves', {
				context: 'Asset registry - plural item',
			}),
			icon: 'valve-open',
			color: '#007ca0',
		},
		valve_closed: {
			displayName: e('Valve Closed', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Valves', {
				context: 'Asset registry - plural item',
			}),
			icon: 'valve-closed',
			color: '#007ca0',
		},
		valve_unknown: {
			displayName: e('Valve Unknown', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Valves', {
				context: 'Asset registry - plural item',
			}),
			icon: 'valve-unknown',
			color: '#007ca0',
		},
		pipe_break: {
			displayName: e('Pipe Break', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Pipe Breaks', {
				context: 'Asset registry - plural item',
			}),
			icon: 'pipe-break',
			color: '#007ca0',
		},
		manhole: {
			displayName: e('Manhole', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Manholes', {
				context: 'Asset registry - plural item',
			}),
			icon: 'manhole',
			color: '#4a6067',
		},
		wwManhole: {
			displayName: e('Manhole', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Manholes', {
				context: 'Asset registry - plural item',
			}),
			icon: 'manhole',
			color: '#4a6067',
		},
		lamphole: {
			displayName: e('Lamphole', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Lampholes', {
				context: 'Asset registry - plural item',
			}),
			icon: 'lamphole',
			color: '#4a6067',
		},
		cleanout: {
			displayName: e('Cleanout', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Cleanouts', {
				context: 'Asset registry - plural item',
			}),
			icon: 'cleanout',
			color: '#4a6067',
		},
		defect: {
			displayName: e('Defect', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Defects', {
				context: 'Asset registry - plural item',
			}),
			icon: 'defect',
			color: '#4a6067',
		},
		wwConnectionNode: {
			displayName: e('Connection Node', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Connection Nodes', {
				context: 'Asset registry - plural item',
			}),
			icon: 'connection-node',
		},
		wwOutlet: {
			displayName: e('Outlet', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Outlets', {
				context: 'Asset registry - plural item',
			}),
			icon: 'outlet',
		},
		generator: {
			displayName: e('Generator', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Generators', {
				context: 'Asset registry - plural item',
			}),
			icon: 'generator',
		},
		wwGenerator: {
			displayName: e('Generator', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Generators', {
				context: 'Asset registry - plural item',
			}),
			icon: 'generator',
		},
		data_logger: {
			displayName: e('Data logger', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Data loggers', {
				context: 'Asset registry - plural item',
			}),
			icon: 'data-logger',
		},
		wwDataLogger: {
			displayName: e('Data logger', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Data loggers', {
				context: 'Asset registry - plural item',
			}),
			icon: 'data-logger',
		},
		generalAsset: {
			displayName: e('General Asset', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('General Assets', {
				context: 'Asset registry - plural item',
			}),
			icon: 'general-asset',
		},
		wwGeneralAsset: {
			displayName: e('General Asset', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('General Assets', {
				context: 'Asset registry - plural item',
			}),
			icon: 'general-asset',
		},
		wwConnectionPipe: {
			displayName: e('Connection Pipe', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Connection Pipes', {
				context: 'Asset registry - plural item',
			}),
			layerType: 'line',
		},
		wwWeir: {
			displayName: e('Weir', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Weirs', {
				context: 'Asset registry - plural item',
			}),
			icon: 'weir',
			layerType: 'line',
		},
		wwScreen: {
			displayName: e('Screen', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Screens', {
				context: 'Asset registry - plural item',
			}),
			icon: 'screen',
			layerType: 'line',
		},
		valve: {
			displayName: e('Valve', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Valves', {
				context: 'Asset registry - plural item',
			}),
			icon: 'valve',
			layerType: 'line',
		},
		wwValve: {
			displayName: e('Valve', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Valves', {
				context: 'Asset registry - plural item',
			}),
			icon: 'valve',
			layerType: 'line',
		},
		wwOrifice: {
			displayName: e('Orifice', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Orifices', {
				context: 'Asset registry - plural item',
			}),
			icon: 'orifice',
			layerType: 'line',
		},
		wwSluice: {
			displayName: e('Sluice', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Sluices', {
				context: 'Asset registry - plural item',
			}),
			icon: 'sluice',
			layerType: 'line',
		},
		wwFlume: {
			displayName: e('Flume', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Flumes', {
				context: 'Asset registry - plural item',
			}),
			icon: 'flume',
			layerType: 'line',
		},
		wwSiphon: {
			displayName: e('Siphon', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Siphons', {
				context: 'Asset registry - plural item',
			}),
			icon: 'siphon',
			layerType: 'line',
		},
		wwVortex: {
			displayName: e('Vortex', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Vortexes', {
				context: 'Asset registry - plural item',
			}),
			icon: 'vortex',
			layerType: 'line',
		},
		wwAncillary: {
			displayName: e('User ancillary', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('User ancillaries', {
				context: 'Asset registry - plural item',
			}),
			icon: 'ancillary',
			layerType: 'line',
		},
		wwChannel: {
			displayName: e('Channel', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Channels', {
				context: 'Asset registry - plural item',
			}),
			icon: 'channel',
			layerType: 'line',
		},
		wwDefenceStructure: {
			displayName: e('Defence Structure', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Defence Structures', {
				context: 'Asset registry - plural item',
			}),
			icon: 'defence-structure',
			layerType: 'line',
		},
		wwDefenceArea: {
			displayName: e('Defence Area', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Defence Areas', {
				context: 'Asset registry - plural item',
			}),
			icon: 'defence-area',
			layerType: 'polygon',
		},
		pumpStation: {
			displayName: e('Pump station', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Pump stations', {
				context: 'Asset registry - plural item',
			}),
			layerType: 'polygon',
		},
		wwPumpStation: {
			displayName: e('Pump station', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Pump stations', {
				context: 'Asset registry - plural item',
			}),
			layerType: 'polygon',
		},
		wwStorage: {
			displayName: e('Storage Area', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Storage Areas', {
				context: 'Asset registry - plural item',
			}),
			layerType: 'polygon',
		},
		wwWtw: {
			displayName: e('Treatment Works', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Treatment Works', {
				context: 'Asset registry - plural item',
			}),
			layerType: 'polygon',
		},
		wtw: {
			displayName: e('Treatment Works', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Treatment Works', {
				context: 'Asset registry - plural item',
			}),
			layerType: 'polygon',
		},
		wwJunction: {
			displayName: e('Junction', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Junctions', {
				context: 'Asset registry - plural item',
			}),
			icon: 'junction',
			color: '#4a6067',
		},
		wwLamphole: {
			displayName: e('Lamphole', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Lampholes', {
				context: 'Asset registry - plural item',
			}),
			icon: 'lamphole',
			color: '#4a6067',
		},
		wwHatchbox: {
			displayName: e('Hatchbox', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Hatchboxes', {
				context: 'Asset registry - plural item',
			}),
			icon: 'unknown',
			color: '#4a6067',
		},
		wwRoddingEye: {
			displayName: e('Rodding eye', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Rodding eyes', {
				context: 'Asset registry - plural item',
			}),
			icon: 'unknown',
			color: '#4a6067',
		},
		wwOutfall: {
			displayName: e('Outfall', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Outfalls', {
				context: 'Asset registry - plural item',
			}),
			icon: 'unknown',
			color: '#4a6067',
		},
		wwStormOverflow: {
			displayName: e('Storm overflow', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Storm overflows', {
				context: 'Asset registry - plural item',
			}),
			icon: 'unknown',
			color: '#4a6067',
		},
		wwPumpStationNode: {
			displayName: e('Pump station', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Pump stations', {
				context: 'Asset registry - plural item',
			}),
			icon: 'pump-station',
			color: '#4a6067',
		},
		wwSoakaway: {
			displayName: e('Soakaway', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Soakaways', {
				context: 'Asset registry - plural item',
			}),
			icon: 'unknown',
			color: '#4a6067',
		},
		wwDualFunction: {
			displayName: e('Dual function', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Dual functions', {
				context: 'Asset registry - plural item',
			}),
			icon: 'unknown',
			color: '#4a6067',
		},
		wwCascade: {
			displayName: e('Cascade', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Cascades', {
				context: 'Asset registry - plural item',
			}),
			icon: 'unknown',
			color: '#4a6067',
		},
		wwGully: {
			displayName: e('Gully', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Gullies', {
				context: 'Asset registry - plural item',
			}),
			icon: 'unknown',
			color: '#4a6067',
		},
		wwEjector: {
			displayName: e('Ejector', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Ejectors', {
				context: 'Asset registry - plural item',
			}),
			icon: 'unknown',
			color: '#4a6067',
		},
		wwOilInterceptor: {
			displayName: e('Oil interceptor', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Oil interceptors', {
				context: 'Asset registry - plural item',
			}),
			icon: 'unknown',
			color: '#4a6067',
		},
		wwInlet: {
			displayName: e('Inlet', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Inlets', {
				context: 'Asset registry - plural item',
			}),
			icon: 'unknown',
			color: '#4a6067',
		},
		wwHydrobrake: {
			displayName: e('Hydrobrake', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Hydrobrakes', {
				context: 'Asset registry - plural item',
			}),
			icon: 'unknown',
			color: '#4a6067',
		},
		wwVentColumn: {
			displayName: e('Vent column', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Vent columns', {
				context: 'Asset registry - plural item',
			}),
			icon: 'unknown',
			color: '#4a6067',
		},
		wwInspectionChamber: {
			displayName: e('Inspection chamber', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Inspection chambers', {
				context: 'Asset registry - plural item',
			}),
			icon: 'unknown',
			color: '#4a6067',
		},
		wwCatchpit: {
			displayName: e('Catchpit', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Catchpits', {
				context: 'Asset registry - plural item',
			}),
			icon: 'unknown',
			color: '#4a6067',
		},
		wwMajorConnectionWithoutManhole: {
			displayName: e('Major connection without manhole', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Major connections without manholes', {
				context: 'Asset registry - plural item',
			}),
			icon: 'unknown',
			color: '#4a6067',
		},
		wwRunningTrap: {
			displayName: e('Running trap', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Running trap', {
				context: 'Asset registry - plural item',
			}),
			icon: 'unknown',
			color: '#4a6067',
		},
		wwBuchanTrap: {
			displayName: e('Buchan trap', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Buchan traps', {
				context: 'Asset registry - plural item',
			}),
			icon: 'unknown',
			color: '#4a6067',
		},
		wwWinserTrap: {
			displayName: e('Winser trap', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Winser traps', {
				context: 'Asset registry - plural item',
			}),
			icon: 'unknown',
			color: '#4a6067',
		},
		wwOtherSpecialChamber: {
			displayName: e('Other special chamber', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Other special chambers', {
				context: 'Asset registry - plural item',
			}),
			icon: 'unknown',
			color: '#4a6067',
		},
		wwMeter: {
			displayName: e('Meter', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Meters', {
				context: 'Asset registry - plural item',
			}),
			icon: 'unknown',
			color: '#4a6067',
		},
		wwWastewaterAccess: {
			displayName: e('Wastewater access', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Wastewater access', {
				context: 'Asset registry - plural item',
			}),
			icon: 'unknown',
			color: '#4a6067',
		},
		wwWetWell: {
			displayName: e('Wet well', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Wet wells', {
				context: 'Asset registry - plural item',
			}),
			icon: 'unknown',
			color: '#4a6067',
		},
		wwUnspecified: {
			displayName: e('Unspecified', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Unspecified', {
				context: 'Asset registry - plural item',
			}),
			icon: 'unknown',
			color: '#4a6067',
		},
		wwOther: {
			displayName: e('Other', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Other', {
				context: 'Asset registry - plural item',
			}),
			icon: 'unknown',
			color: '#4a6067',
		},
	};

	public static getIcon(id: string): string | undefined {
		const assetType = AssetRegistry.assetTypes[id];
		return assetType?.icon;
	}

	public static getDisplayName(id: string): string {
		const assetType = AssetRegistry.assetTypes[id];
		return t(assetType?.displayName || assetUnknown.displayName);
	}

	public static getLayerName(id: string): string {
		const assetType = AssetRegistry.assetTypes[id];
		return t(assetType?.layerName || assetUnknown.layerName);
	}

	public static getColor(id: string): string {
		const assetType = AssetRegistry.assetTypes[id];
		return assetType?.color || assetUnknown.color || '#4A6067';
	}

	public static getIconSet(): IconSet {
		return reduceArrayToObject(
			Object.entries(AssetRegistry.assetTypes)
				.map(([assetType, detail]) =>
					detail.icon
						? {
								[assetType]: detail.icon,
						  }
						: undefined,
				)
				.filter(notUndefined),
		);
	}

	public static getLayerType(id: string): ConfigLayer['type'] {
		const assetType = AssetRegistry.assetTypes[id];
		return assetType?.layerType || 'symbol';
	}

	private static formatConfigDefaults(
		assetType: string,
		detail: TranslateAssetDetailType,
	) {
		return {
			assetType,
			systemType: 'WaterDistribution',
			icon: detail.icon,
			minZoom:
				detail.defaultMinZoom || (detail.layerType === 'line' ? 2 : 16),
			iconMinZoom: detail.layerType === 'line' && detail.icon ? 16 : 2,
			layerName: detail.layerName,
			type: detail.layerType || 'symbol',
		};
	}

	public static getConfigDefaults(): ConfigOverrides[] {
		return Object.entries(
			AssetRegistry.assetTypes,
		).map(([assetType, detail]) =>
			AssetRegistry.formatConfigDefaults(assetType, detail),
		);
	}

	public static getConfigDefaultsForAsset(
		id: string,
	): ConfigOverrides | undefined {
		const detail = AssetRegistry.assetTypes[id];
		if (!detail) return;
		return AssetRegistry.formatConfigDefaults(id, detail);
	}
}
