import { RiskScoreResolved, SpatialRecord } from '@Types';

import { SpatialLayer } from '@Services/risk';
import { addNamespace } from '@Utils/actions';
import { createAction } from 'redux-actions';

export const getSpatialLayers = createAction(
	addNamespace('risk/getSpatialLayers'),
);

export const getSpatialLayersResolved = createAction<SpatialLayer[]>(
	addNamespace('risk/getSpatialLayers/resolved'),
);

export const getSpatialLayersRejected = createAction(
	addNamespace('risk/getSpatialLayers/rejected'),
);

export const getSpatialRecord = createAction<string>(
	addNamespace('risk/getSpatialRecord'),
);
export const getSpatialRecordResolved = createAction<SpatialRecord>(
	addNamespace('risk/getSpatialRecord/resolved'),
);
export const getSpatialRecordRejected = createAction(
	addNamespace('risk/getSpatialRecord/rejected'),
);

export const getRiskScore = createAction(addNamespace('risk/getRiskScore'));
export const getRiskScoreResolved = createAction<RiskScoreResolved>(
	addNamespace('risk/getRiskScore/resolved'),
);
export const getRiskScoreRejected = createAction(
	addNamespace('risk/getRiskScore/rejected'),
);

export const clearRisk = createAction(addNamespace('risk/clearRisk'));

export const getSpatialMetadata = createAction(
	addNamespace('risk/getSpatialMetadata'),
);
export const getSpatialMetadataResolved = createAction(
	addNamespace('risk/getSpatialMetadata/resolved'),
);
export const getSpatialMetadataRejected = createAction(
	addNamespace('risk/getSpatialMetadata/rejected'),
);
