import {
	Pagination,
	PaginationState,
	ResolvedResponse,
	Row,
	Sort2,
} from '../types/pagination.types';

import { RiskConfigCard } from '@Types/riskConfig.types';
import { RowsPerPage } from '@innovyze/stylovyze';
import { Status } from '../types/general.types';

export const rowsPerPageOptions = (): number[] => [5, 10, 15, 25, 50, 100];
export const statusDefault = (): Status => ({ code: 800, text: 'Uncalled' });
export const statusLoading = (): Status => ({ code: 801, text: 'Loading' });
export const status200 = (): Status => ({ code: 200, text: '' });

const queryParamPage = (page: number | undefined): string => {
	return page && page > 1 ? `&page=${page}` : '';
};

const queryParamSlice = (slice: number | undefined): string => {
	return slice && slice !== 10 && slice > 0 ? `&slice=${slice}` : '';
};

const queryParamSort = (
	sortKey: string | undefined,
	sortDescending: boolean | undefined,
): string => {
	let q = '';
	if (sortKey?.length) {
		q += `&sort=${encodeURIComponent(sortKey)}`;

		if (sortDescending) {
			q += `&sortDescending=${encodeURIComponent(sortDescending)}`;
		}
	}
	return q;
};

// params that might change subtotal
const makeQueryReducers = (pagination: Pagination): string => {
	let q = queryParamPage(pagination.page);
	q += queryParamSlice(pagination.slice);
	q += queryParamSort(pagination?.sortKey, pagination?.sortDescending);
	return q;
};

export const paginationDefault = {
	total: 0,
	subtotal: 0,
	slice: 10,
	pages: 1,
	page: 1,
};

export const paginationInitialState: PaginationState = {
	query: '',
	status: statusDefault(),
	pagination: { ...paginationDefault },
	rows: [],
	cards: [],
	selectedDbIds: [],
	currentDbId: '',
	isLoading: false,
	isWaiting: false,
	filter: undefined,
	serverFilter: undefined,
};

export const makePaginationQuery = (pagination: Pagination): string => {
	const q = makeQueryReducers(pagination);
	return q.length ? '?' + q.substring(1) : '';
};

export const clearPagination = (
	state: PaginationState,
	pagination: Pagination,
): PaginationState => ({
	...paginationInitialState,
	pagination,
	query: makePaginationQuery(pagination),
});

export const updatePage = (
	state: PaginationState,
	page: number,
): PaginationState => {
	const pagination = {
		...state.pagination,
		page,
	};
	return {
		...state,
		status: statusLoading(),
		pagination,
		query: makePaginationQuery(pagination),
		isWaiting: true,
	};
};

export const updateSlice = (
	state: PaginationState,
	slice: number,
): PaginationState => {
	// If we change rows per page need to start again from page 1
	const pagination = {
		...state.pagination,
		slice,
		page: 1,
	};
	return {
		...state,
		status: statusLoading(),
		pagination,
		query: makePaginationQuery(pagination),
		isWaiting: true,
	};
};

export const updateSearch = (
	state: PaginationState,
	search: string,
): PaginationState => {
	// If we change search need to start again from page 1
	const pagination = {
		...state.pagination,
		search,
		page: 1,
	};
	return {
		...state,
		status: statusLoading(),
		pagination,
		query: makePaginationQuery(pagination),
		isWaiting: true,
	};
};

export const updatePage1 = (state: PaginationState): PaginationState => {
	const pagination = {
		...state.pagination,
		page: 1,
	};
	return {
		...state,
		status: statusLoading(),
		pagination,
		query: makePaginationQuery(pagination),
		isWaiting: true,
	};
};

export const updateSort = (
	state: PaginationState,
	sort: Sort2,
): PaginationState => {
	// If we change sort need to start again from page 1
	const pagination = {
		...state.pagination,
		sortKey: sort.key,
		sortDescending: sort.descending,
		page: 1,
	};
	return {
		...state,
		status: statusLoading(),
		pagination,
		query: makePaginationQuery(pagination),
		isWaiting: true,
	};
};

export const addSelected = (
	state: PaginationState,
	add: string[],
): PaginationState => {
	const s = new Set(state.selectedDbIds);
	add.forEach(a => s.add(a));
	return {
		...state,
		selectedDbIds: Array.from(s),
	};
};

export const removeSelected = (
	state: PaginationState,
	remove: string[],
): PaginationState => ({
	...state,
	selectedDbIds: state.selectedDbIds.filter(s => !remove.some(r => r === s)),
});

export const indexToDbId = (rows: Row[], index: number): string =>
	index in rows && '_id' in rows[index] ? rows[index]['_id'] : '';

export const setCurrentDbId = (
	state: PaginationState,
	rowIndex: number,
): PaginationState => ({
	...state,
	currentDbId: indexToDbId(state.rows, rowIndex),
});

export const setSelected = (
	state: PaginationState,
	selectedDbIds: string[],
): PaginationState => ({
	...state,
	selectedDbIds,
});

export const updateGet = (
	state: PaginationState,
	pagination: Pagination,
): PaginationState => ({
	...state,
	pagination,
	query: makePaginationQuery(pagination),
});

/**
 * Default update resolved for row based pagination
 * @param state current redux state
 * @param response response from the server
 * @returns the pagination object to put into redux
 */
export const updateResolved = (
	state: PaginationState,
	response: ResolvedResponse,
): PaginationState => {
	const rows = response.data as Row[];
	// reloads should retain current and selection if still valid
	const selectedDbIds = state.selectedDbIds.filter(id =>
		rows.some(row => '_id' in row && row._id === id),
	);

	const currentDbId = rows.some(
		row => '_id' in row && row._id === state.currentDbId,
	)
		? state.currentDbId
		: '';

	return {
		...paginationInitialState,
		status: status200(),
		pagination: { ...response.pagination },
		rows: response.data as Row[],
		scanId: response.scanId,
		query: makePaginationQuery(response.pagination),
		selectedDbIds,
		currentDbId,
		filter: state.filter,
		serverFilter: state.serverFilter,
	};
};

/**
 * Update resolved for cards
 * @param state current redux state
 * @param response response from the server
 * @returns the pagination object to put into redux
 */
export const updateResolvedCard = (
	state: PaginationState,
	response: ResolvedResponse,
): PaginationState => {
	return {
		...paginationInitialState,
		status: status200(),
		pagination: { ...response.pagination },
		cards: response.data,
		query: makePaginationQuery(response.pagination),
	};
};

/**
 * Update resolved for risk config cards
 * @param state current redux state
 * @param response response from the server
 * @returns the pagination object to put into redux
 */
export const updateResolvedRiskConfigCard = (
	state: PaginationState,
	response: ResolvedResponse,
): PaginationState => {
	return {
		...paginationInitialState,
		status: status200(),
		pagination: { ...response.pagination },
		cards: response.data as RiskConfigCard[],
		query: makePaginationQuery(response.pagination),
	};
};

export const updateRejected = (
	state: PaginationState,
	status: Status,
): PaginationState => {
	return {
		...paginationInitialState,
		status,
	};
};

export const updateIsLoading = (
	state: PaginationState,
	isLoading: boolean,
): PaginationState => ({
	...state,
	isLoading,
});

export const updateIsWaiting = (
	state: PaginationState,
	isWaiting: boolean,
): PaginationState => ({
	...state,
	isWaiting,
});

export const updateIsBusy = (
	state: PaginationState,
	isBusy: boolean,
): PaginationState => {
	// get the loading spinner if firstLoad else get wait cursor
	const firstLoad = state.rows === undefined || state.rows.length === 0;
	return {
		...state,
		isLoading: isBusy && firstLoad,
		isWaiting: isBusy && !firstLoad,
	};
};

export const updateIsBusyCards = (
	state: PaginationState,
	isBusy: boolean,
): PaginationState => {
	// get the loading spinner if firstLoad else get wait cursor
	const firstLoad = state.cards === undefined || state.cards.length === 0;
	return {
		...state,
		isLoading: isBusy && firstLoad,
		isWaiting: isBusy && !firstLoad,
	};
};

export type DispatchSliceFn = (slice: number) => void;
export const rowsPerPage = (
	slice: number,
	dispatchSliceFn: DispatchSliceFn,
): RowsPerPage => {
	const opts: number[] = rowsPerPageOptions();
	const options = opts.map((o, n) => {
		return {
			name: o.toString(),
			onClick: () => {
				dispatchSliceFn(o);
			},
			cy: 'rowsPerPage-' + n,
		};
	});

	const s = slice.toString();
	const selectedIndex = options.findIndex(o => o.name === s);

	return {
		options,
		selectedIndex,
	};
};

export const updateFilter = (
	state: PaginationState,
	filter: PaginationState['filter'],
): PaginationState => ({
	...state,
	filter,
});

export const updateServerFilter = (
	state: PaginationState,
	filter: PaginationState['filter'],
	serverFilter: PaginationState['filter'],
): PaginationState => ({
	...state,
	filter,
	serverFilter,
});
