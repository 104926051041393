import {
	Button,
	IconButton,
	InputAdornment,
	IconButtonProps as MuiIconButtonProps,
	PopperProps as MuiPopperProps,
	TextFieldProps as MuiTextFieldProps,
	TextField,
} from '@mui/material';
import {
	ColorDivider,
	ColorPickerLabel,
	Option,
	PickerGrid,
	PickerPaper,
	PlusIcon,
	Wrapper,
} from './Picker.styles';
import React, {
	ReactElement,
	ReactNode,
	ReactText,
	useEffect,
	useMemo,
	useReducer,
	useRef,
	useState,
} from 'react';

import ColorSelector from './ColorSelector';
import PopperClickAway from './PopperClickAway';
import { useGlobalization } from '@Translations/useGlobalization';

interface PickerOption {
	/** option value - returned on selection */
	value: string;
	/** option background color */
	backgroundColor?: string;
	/** option background image */
	backgroundImage?: string;
	/** option background */
	background?: string;
	/* option label */
	label?: ReactNode | ReactText;
	/** cypress id */
	dataCy?: string;
}

interface AdditionalProps {
	'data-cy': string;
}

export interface PickerProps
	extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> {
	/** label for input field */
	label?: string;
	/** Picker options */
	options: PickerOption[];
	/** current value */
	value?: string;
	/** type of picker
	 * @default none
	 */
	type?: 'none' | 'color';
	/** when value has changed event */
	onChange?:
		| ((value: string) => void)
		| ((value: string | undefined) => void);
	/** props for options */
	OptionProps?: Record<string, unknown>;
	/** props for icon button */
	IconButtonProps?: Partial<MuiIconButtonProps & AdditionalProps>;
	/** props for text field */
	TextFieldProps?: Partial<MuiTextFieldProps & AdditionalProps>;
	/** props for input in text field */
	InputProps?: Partial<MuiTextFieldProps['InputProps']>;
	/** props for popper */
	PopperProps?: Partial<MuiPopperProps & AdditionalProps>;
	/** custom element to attach picker to */
	children?: ReactElement;
	/** allow empty value for picker */
	allowEmpty?: boolean;
}

const customReducer = (state: PickerOption[], action: PickerOption) => {
	return [...state, action];
};

const mapOptions = (
	options: PickerOption[],
	handleSelect: (newValue: string) => () => void,
	OptionProps: PickerProps['OptionProps'],
	selected?: string,
) =>
	options.map(
		(
			{
				backgroundImage,
				backgroundColor,
				background,
				label,
				value,
				dataCy,
			},
			i,
		) => (
			<Option
				key={i}
				$backgroundImage={backgroundImage}
				$backgroundColor={backgroundColor}
				$background={background}
				$selected={value === selected}
				onClick={handleSelect(value)}
				title={value}
				data-cy={dataCy ?? `picker-option-${value}`}
				{...OptionProps}>
				{label}
			</Option>
		),
	);

const Picker = ({
	label,
	options,
	value,
	type = 'none',
	onChange,
	OptionProps,
	IconButtonProps,
	TextFieldProps,
	InputProps,
	PopperProps,
	children,
	allowEmpty,
	...props
}: PickerProps): JSX.Element => {
	const { t } = useGlobalization();
	const anchorRef = useRef<HTMLDivElement>(null);
	const [isOpen, setIsOpen] = useState(false);
	const defaultValue = allowEmpty ? undefined : options[0].value;
	const [selected, setSelected] = useState(value ?? defaultValue);
	const [custom, setCustom] = useReducer(customReducer, []);
	const [mode, setMode] = useState<'default' | 'add'>('default');

	const selectedOption = useMemo(() => {
		return [...options, ...custom].find(
			({ value: optionValue }) => optionValue === selected,
		);
	}, [selected, options, custom]);

	const customModeEnabled = useMemo(() => type === 'color', [type]);

	const handleOpen = () => {
		setIsOpen(true);
	};

	const handleClose = () => {
		setIsOpen(false);
	};

	const handleSelect = (newValue: string | undefined) => () => {
		setSelected(newValue);
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		onChange?.(newValue);
		handleClose();
	};

	const clearSelected = () => {
		handleSelect(undefined)();
	};

	useEffect(() => {
		if (value || allowEmpty) {
			const exists = [...options, ...custom].find(
				option => option.value === value,
			);
			if (!exists && value) {
				setCustom({
					value,
					backgroundColor: value,
				});
			}
			setSelected(value);
		}
	}, [value]);

	return (
		<Wrapper {...props}>
			{children &&
				React.cloneElement(children, {
					ref: anchorRef,
					onClick: handleOpen,
				})}
			{!children && (
				<TextField
					label={label}
					ref={anchorRef}
					variant="outlined"
					margin="normal"
					fullWidth
					value={selectedOption?.value}
					{...TextFieldProps}
					InputLabelProps={{
						shrink: true,
					}}
					InputProps={{
						...InputProps,
						endAdornment: (
							<InputAdornment
								position="end"
								style={{ marginLeft: 0 }}>
								<IconButton
									onClick={handleOpen}
									size="small"
									{...IconButtonProps}>
									<Option
										$backgroundColor={
											selectedOption?.backgroundColor
										}
										$backgroundImage={
											selectedOption?.backgroundImage
										}
										$background={
											selectedOption?.background
										}>
										{selectedOption?.label &&
											selectedOption.label}
									</Option>
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
			)}
			<PopperClickAway
				open={isOpen}
				anchorEl={anchorRef.current}
				onClickAway={handleClose}
				{...PopperProps}>
				<PickerPaper>
					{mode === 'default' && (
						<>
							<PickerGrid>
								{mapOptions(
									options,
									handleSelect,
									OptionProps,
									selected,
								)}
							</PickerGrid>
							{customModeEnabled && (
								<>
									<ColorDivider />
									<ColorPickerLabel>
										{t('Custom', {
											context:
												'Picker menu custom option heading',
										})}
									</ColorPickerLabel>
									<PickerGrid>
										{mapOptions(
											custom,
											handleSelect,
											OptionProps,
											selected,
										)}
										<IconButton
											size="small"
											onClick={() => {
												setMode('add');
											}}>
											<PlusIcon />
										</IconButton>
									</PickerGrid>
								</>
							)}
						</>
					)}
					{mode === 'add' && type === 'color' && (
						<ColorSelector
							onSave={color => {
								setCustom({
									value: color,
									backgroundColor: color,
								});
								setMode('default');
							}}
							onCancel={() => setMode('default')}
						/>
					)}
					{allowEmpty && selected && (
						<div>
							<ColorDivider />
							<Button onClick={clearSelected}>Clear</Button>
						</div>
					)}
				</PickerPaper>
			</PopperClickAway>
		</Wrapper>
	);
};

export default Picker;
