import {
	InfoCard,
	StylovyzeTable,
	StylovyzeTableHeaderProps,
} from '@innovyze/stylovyze';
import { useGlobalization } from '@Translations';
import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DecisionPath = ({ rehabDecision }: { rehabDecision: any[] }) => {
	const { t } = useGlobalization();
	const defaultHeaders: StylovyzeTableHeaderProps[] = [
		{
			cell: t('Node Number'),
			dataCy: 'col-Node_Number',
			key: 'Node_Number',
			width: '200px',
			padding: '0 10px',
		},
		{
			cell: t('Node Name'),
			dataCy: 'col-Node_Name',
			key: 'Node_name',
			width: 'auto',
			padding: '0 10px',
		},
		{
			cell: t('Node Type'),
			dataCy: 'col-Node_Type',
			key: 'Node_Type',
			width: '200px',
			padding: '0 10px',
		},
		{
			cell: t('Result'),
			dataCy: 'col-Result',
			key: 'Result',
			width: '100px',
			padding: '0 10px',
		},
	];
	return (
		<InfoCard
			fullHeight
			title={t('Decision Path')}
			applyBodyPadding={false}>
			<StylovyzeTable
				className="ScrollTable"
				dataCy="defect-table"
				headers={defaultHeaders}
				rows={
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					rehabDecision.map((row: any, index: number) => ({
						key: index,
						cells: [
							row.node,
							row.nodeName,
							row.nodeType,
							row.result,
						],
					})) || []
				}
			/>
		</InfoCard>
	);
};

export default DecisionPath;
