import {
	SliderProps as MuiSliderProps,
	FormControlProps as MuiFormControlProps,
	InputLabelProps,
	InputLabel,
} from '@mui/material';
import React, { useMemo } from 'react';
import { StyledFormControl, StyledSlider } from './Slider.styles';

interface SliderProps extends Omit<MuiSliderProps, 'onChange'> {
	/** label for slider */
	label?: string;
	/** whether to show min and max markers
	 * @default true
	 */
	showMinMax?: boolean;
	/** props to pass to wrapping FormControl */
	FormControlProps?: Partial<MuiFormControlProps>;
	/** props to pass down to Label */
	LabelProps?: Partial<InputLabelProps>;
	// work-around type extending incorrectly
	/** Event for slider change */
	onChange?: (event: Event, value: number | number[]) => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isNumber = (value: any) => !isNaN(parseFloat(value));

const Slider = ({
	label,
	// prop types come from interface that is extended, eslint doesn't like this
	// eslint-disable-next-line react/prop-types
	min,
	// eslint-disable-next-line react/prop-types
	max,
	showMinMax = true,
	FormControlProps,
	LabelProps,
	...props
}: SliderProps): JSX.Element => {
	const marks = useMemo(() => {
		if (!isNumber(min) || !isNumber(max) || !showMinMax) return undefined;
		return [
			{
				value: min ?? 0,
				label: `${min}`,
			},
			{
				value: max ?? 100,
				label: `${max}`,
			},
		];
	}, [min, max, showMinMax]);

	return (
		<StyledFormControl margin="normal" fullWidth {...FormControlProps}>
			{label && (
				<InputLabel shrink {...LabelProps}>
					{label}
				</InputLabel>
			)}
			<StyledSlider
				min={min}
				max={max}
				marks={marks}
				valueLabelDisplay="on"
				{...props}
			/>
		</StyledFormControl>
	);
};

export default Slider;
