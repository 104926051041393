import { RawMeasureData } from './measureData.types';
import { TimeRange } from './timeRange.types';
import { Series } from './series.types';
import { Readings } from './sensor.types';
import { RangeSelection } from './chartState';
import { GridCellSelectionModel } from '@mui/x-data-grid-premium';
import { DataGridProps } from '@innovyze/stylovyze';
import { TimeRangeSelection } from '../_next/core/_insight-chart';

export interface TableDashletRow {
  key: string;
  cells: (string | number | undefined)[];
}

export type TableDashletData = {
  data: RawMeasureData;
  reading: Readings;
  sensorName: string;
  unit: string;
}[];

export type TableDashletItem = {
  [column in Readings]?: number;
} & {
  sensorName: string;
  timestamp: number;
  unit: string;
};

export type TableDashletSingleSeries = Series;

export type TableDashletMultiSeries = TableDashletSingleSeries[];

export interface TableDashletConnected {
  series: TableDashletSingleSeries | TableDashletMultiSeries;
  readings?: Readings[];
  timeRange?: TimeRange;
  dataRangeSelection?: RangeSelection;
  timeRangeSelection?: TimeRangeSelection;
  page?: number;
  onPageChange?: (page: number) => void;
  sort?: TableSortState;
  onSortChange?: (sort?: TableSortState) => void;
  useMostRecentReading?: boolean;
  showUnits?: boolean;
  showDateTime?: boolean;
  showSelection?: boolean;
  showColumnSelection?: boolean;
  showSensorAlias?: boolean;
  clearSelection?: boolean;
  density?: TableDensity;
  onSelectionChange?: (model: GridCellSelectionModel) => void;
  conditionalFormatting?: DataGridProps['conditionalFormatting'];
  columnConditionalFormatting?: DataGridProps['columnFormatting'];
  onColumnSelectedChange?: (column: string) => void;
}

export interface TableSortState {
  name: string;
  order: 'desc' | 'asc';
}

export enum TableDensity {
  regular = 51,
  dense = 25,
}

export enum DataGridDensity {
  regular = 'standard',
  dense = 'compact',
  comfortable = 'comfortable',
}
