import {
	InspectionCard,
	InspectionId,
	SurveyDate,
	TaskWrapper,
	ViewDetails,
} from './InspectionProperties.styles';
import {
	ItemWithLabel,
	iso8601DateToLocal,
	useSettings,
} from '@innovyze/stylovyze';
import { InspectionScoredAttributes } from '@Types/inspection.types';
import React from 'react';
import { useGlobalization } from '@Translations/useGlobalization';
import { extractValue } from '@Utils/helpers';

export interface TaskPropertiesProps {
	inspection: InspectionScoredAttributes;
	viewTask?: (inpsectionAttributes: InspectionScoredAttributes) => void;
}

export const TaskProperties = ({
	inspection,
	viewTask,
}: TaskPropertiesProps): JSX.Element => {
	const { t } = useGlobalization();
	const { companySettings } = useSettings();

	const onTaskClick = () => {
		if (viewTask) viewTask(inspection);
	};

	return (
		<TaskWrapper data-cy="inspection-properties">
			<InspectionId viewDetails={!!viewTask}>
				{inspection.taskId}
			</InspectionId>
			<SurveyDate viewDetails={!!viewTask}>
				{iso8601DateToLocal(
					extractValue(inspection.completedDate) ?? '',
					companySettings.dateFormat,
				)}
			</SurveyDate>
			{viewTask && (
				<ViewDetails size="small" onClick={onTaskClick}>
					{t('View Details', {
						context: 'Inspection details view further details',
					})}
				</ViewDetails>
			)}
			<InspectionCard>
				<ItemWithLabel
					label={t('Task Type', {
						context: 'Inspection properties',
					})}
					value={inspection.taskTypeName}
				/>
				<ItemWithLabel
					label={t('Scheduled Date', {
						context: 'Inspection properties',
					})}
					value={iso8601DateToLocal(
						extractValue(inspection.scheduledDate) ?? '',
						companySettings.dateFormat,
					)}
				/>
			</InspectionCard>
		</TaskWrapper>
	);
};
