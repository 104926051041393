import { PartialServiceMap, Environments } from '@innovyze/stylovyze';

const config: PartialServiceMap = {
	// Sample config for overwritting service endpoints
	[Environments.LOCAL]: {
		assets: 'https://saas-asset-management.info360-dev.com/dev',
		amInspection: 'https://saas-inspection-management.info360-dev.com/dev',
		amRisk: 'https://saas-risk-management.info360-dev.com/dev',
		amUpload: 'https://saas-upload-service.info360-dev.com/dev',
		tiles: 'https://tiles-dev.info360-dev.com/dev',
		amRehab: 'https://saas-rehab-management.info360-dev.com/dev',
	},
	[Environments.DEV]: {
		assets: 'https://saas-asset-management.info360-dev.com/dev',
		amInspection: 'https://saas-inspection-management.info360-dev.com/dev',
		amRisk: 'https://saas-risk-management.info360-dev.com/dev',
		amUpload: 'https://saas-upload-service.info360-dev.com/dev',
		tiles: 'https://tiles-dev.info360-dev.com/dev',
		amRehab: 'https://saas-rehab-management.info360-dev.com/dev',
	},
};

export const apiEnv =
	(localStorage.getItem('apiEnv') as keyof typeof Environments) || undefined;

export default config;
