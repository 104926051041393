import Highcharts from 'highcharts';
import React from 'react';
import { TFunc } from '../i18n/types';

const useTranslations = (highcharts: typeof Highcharts, t: TFunc): void => {
  // const { companySettings } = useSettings();
  // const locale = companySettings?.language.slice(0, 2) || 'en';

  React.useEffect(() => {
    highcharts.setOptions({
      lang: {
        weekdays: [
          t('Sunday'),
          t('Monday'),
          t('Tuesday'),
          t('Wednesday'),
          t('Thursday'),
          t('Friday'),
          t('Saturday'),
        ],
        months: [
          t('January'),
          t('February'),
          t('March'),
          t('April'),
          t('May'),
          t('June'),
          t('July'),
          t('August'),
          t('September'),
          t('October'),
          t('November'),
          t('December'),
        ],
        shortMonths: [
          t('Jan'),
          t('Feb'),
          t('Mar'),
          t('Apr'),
          t('May'),
          t('Jun'),
          t('Jul'),
          t('Aug'),
          t('Sep'),
          t('Oct'),
          t('Nov'),
          t('Dec'),
        ],
        loading: t('Loading....'),
        resetZoom: t('Reset zoom'),
        rangeSelectorZoom: t('Zoom'),
      },
    });
  }, []);
};

export default useTranslations;
