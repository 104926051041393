import React, { ReactNode } from 'react';

import { Box } from '@mui/material';
import styled from 'styled-components';

const Panel = styled.div`
	width: 100%;
	overflow-y: auto;
	> div {
		padding: 0;
	}
`;

interface TabPanelProps {
	children: ReactNode;
	value: number;
	index: number;
}

export const TabPanel = ({
	children,
	value,
	index,
}: TabPanelProps): JSX.Element => {
	return (
		<Panel
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}>
			{value === index && <Box p={3}>{children}</Box>}
		</Panel>
	);
};
