import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import type { AsyncData, AsyncStatus } from '../../types/async.types';
import { t } from 'i18next';
import {
  GetGoalPastResultsPeriods,
  Goal,
  GoalPeriodInterval,
  GoalPointInTime,
  GoalPointInTimeRequestBody,
} from '../../types/goals.types';
import { getConfigurations } from '../../../services/asset.service';
import { Configuration } from '../../types/asset.types';
import { getGoalPointInTime } from '../../../services/edgeAnalytics.service';
import { useSettings } from '@innovyze/stylovyze';

export interface GoalSensors {
  inputSensor: Configuration;
  goalSensor?: Configuration;
  benchmarkSensor?: Configuration;
}

let interval: NodeJS.Timeout;

const useGoalData = (
  goal: Goal | null,
  isLiveMode = true
): {
  overallStatus: AsyncStatus;
  asyncSensorsConfig: AsyncData<GoalSensors>;
  asyncGoalPointInTime: AsyncData<GoalPointInTime>;
} => {
  const { companySettings } = useSettings();

  const [asyncSensorsConfig, setAsyncSensorsConfig] = useState<
    AsyncData<GoalSensors>
  >({
    status: 'idle',
    data: null,
  });

  const [asyncGoalPointInTime, setAsyncGoalPointInTime] = useState<
    AsyncData<GoalPointInTime>
  >({
    status: 'idle',
    data: null,
  });

  const isFirstDataCall = useRef(true);

  const overallStatus = useMemo(() => {
    if (asyncSensorsConfig.status === 'loading' || isFirstDataCall.current)
      return 'loading';
    else if (
      asyncSensorsConfig.status === 'rejected' ||
      asyncGoalPointInTime.status === 'rejected'
    )
      return 'rejected';
    else if (
      asyncSensorsConfig.status === 'resolved' &&
      asyncGoalPointInTime.status === 'resolved'
    )
      return 'resolved';
    else return 'idle';
  }, [asyncSensorsConfig.status, asyncGoalPointInTime.status]);

  const getLowestResolution = (resolutions: string[]) => {
    if (resolutions?.length === 0) return;

    if (resolutions.length === 1) return resolutions[0];

    if (resolutions[0] === 'RAW') return resolutions[1];
    else return resolutions[0];
  };

  const getGoalInterval = (
    periodInterval: string
  ): GetGoalPastResultsPeriods => {
    const result = { period: 1 } as GetGoalPastResultsPeriods;
    switch (periodInterval) {
      case 'Daily':
        result.periodInterval = GoalPeriodInterval.DAY;
        break;
      case 'Weekly':
        result.periodInterval = GoalPeriodInterval.DAY;
        result.period = 7;
        break;
      case 'Monthly':
        result.periodInterval = GoalPeriodInterval.MONTH;
        break;
      case 'Yearly':
        result.periodInterval = GoalPeriodInterval.YEAR;
        break;
      default:
        result.periodInterval = GoalPeriodInterval.DAY;
    }
    return result;
  };

  const fetchSensorsConfig = useCallback(async () => {
    setAsyncSensorsConfig({
      data: null,
      status: 'loading',
      message: t('Loading sensors configurations'),
    });
    try {
      if (goal?.inputSensorId) {
        const sensorIds = [
          goal.inputSensorId,
          ...(goal.goalSensorId ? [goal.goalSensorId] : []),
          ...(goal.benchmarkSensorId ? [goal.benchmarkSensorId] : []),
        ];

        const { data } = await getConfigurations({
          sensorId: sensorIds.join(','),
          configType: 'sensor',
          limit: 1000,
          offset: 1,
        });
        if (data?.sensors?.length) {
          const { sensors } = data;
          const goalSensors = {} as GoalSensors;

          sensorIds.forEach((id) => {
            const conf = sensors.find((c: Configuration) => c.sensorId === id);

            if (id === goal.inputSensorId) {
              goalSensors.inputSensor = conf;
            }

            if (id === goal.goalSensorId) {
              goalSensors.goalSensor = conf;
            }
            if (id === goal.benchmarkSensorId) {
              goalSensors.benchmarkSensor = conf;
            }
          });
          setAsyncSensorsConfig({
            data: goalSensors,
            status: 'resolved',
          });
        }
      } else {
        setAsyncSensorsConfig({
          data: null,
          status: 'idle',
        });
      }
    } catch (error) {
      setAsyncSensorsConfig({
        data: null,
        status: 'rejected',
        message: t('Failed to get sensors configurations'),
      });
    }
  }, [goal]);

  const fetchGoalPointInTime = useCallback(async () => {
    // Show loader only on first call (Specially for live mode where it updates every 3 seconds)
    if (isFirstDataCall.current && goal?.inputSensorId) {
      setAsyncGoalPointInTime({
        data: null,
        status: 'loading',
        message: t('Loading goal point in time data'),
      });
      isFirstDataCall.current = false;
    }
    try {
      if (goal?.inputSensorId && asyncSensorsConfig.data) {
        const { period, periodInterval } = getGoalInterval(goal.periodInterval);
        const body: GoalPointInTimeRequestBody = {
          companySettings,
          query: [
            {
              goalConfigId: goal._id,
              inputSensorId: goal.inputSensorId,
              inputSensorInterval: 'RAW',
              ...(goal.goalSensorId
                ? {
                    goalSensorId: goal.goalSensorId,
                    goalSensorInterval:
                      getLowestResolution(
                        asyncSensorsConfig.data.goalSensor?.resolutions ?? []
                      ) ?? 'RAW',
                  }
                : {}),
              ...(goal.benchmarkSensorId
                ? {
                    benchmarkSensorId: goal.benchmarkSensorId,
                    benchmarkSensorInterval:
                      getLowestResolution(
                        asyncSensorsConfig.data.benchmarkSensor?.resolutions ??
                          []
                      ) ?? 'RAW',
                  }
                : {}),
              goalConstant: goal.goalConstant ? +goal.goalConstant : undefined,
              benchmarkConstant: goal.benchmarkConstant
                ? +goal.benchmarkConstant
                : undefined,
              startDate: goal.startDate,
              period,
              periodInterval,
              recurring: goal.isRecurring,
            },
          ],
        };

        const response = await getGoalPointInTime(body);

        if (response.data?.query?.length > 0) {
          setAsyncGoalPointInTime({
            data: response.data.query[0],
            status: 'resolved',
          });
        } else {
          setAsyncGoalPointInTime({
            data: null,
            status: 'rejected',
            message: t('Empty results'),
          });
        }
      } else {
        setAsyncGoalPointInTime({
          data: null,
          status: 'idle',
        });
      }
    } catch (error) {
      setAsyncGoalPointInTime({
        data: null,
        status: 'rejected',
        message: t('Failed to get goal point in time data'),
      });
    }
  }, [goal, asyncSensorsConfig.data]);

  /**
   * Fetch the sensors Configurations to get the unit of each sensor
   * Fetch the goal points data in live mode or just once
   */
  useEffect(() => {
    fetchSensorsConfig();
  }, [goal]);

  useEffect(() => {
    if (asyncSensorsConfig.data && goal) {
      if (isLiveMode) {
        fetchGoalPointInTime();
        interval = setInterval(
          () => {
            fetchGoalPointInTime();
          },
          1000 * 60 * 5
        );
      } else {
        fetchGoalPointInTime();
      }
    }
  }, [goal, isLiveMode, asyncSensorsConfig.data]);

  useEffect(() => {
    return () => {
      if (interval) clearInterval(interval);
    };
  }, []);

  return {
    overallStatus,
    asyncSensorsConfig,
    asyncGoalPointInTime,
  };
};

export default useGoalData;
