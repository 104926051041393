import * as facilityActions from '@Actions/facility';

import { all, put, retry, select, takeEvery } from 'redux-saga/effects';
import {
	getConfigurations,
	getFacilityImage,
	getFacilitySensorGroups,
	getFacilitySensors,
	getSensorsById,
} from '@Services/facility';

import { FacilityItem, SensorIdMap } from '@Types/facility.types';
import { PayloadAction } from '@reduxjs/toolkit';
import { selectFacilityById } from '@Selectors/facility';
import { AxiosResponse } from 'axios';

function* getFacilitySaga(action: PayloadAction<string>) {
	const {
		getFacilityResolved,
		setSensorIdsMap,
		clearSensorIdsMap,
	} = facilityActions;
	yield put(clearSensorIdsMap());
	const facility: ReturnType<typeof selectFacilityById> = yield select(
		state => selectFacilityById(state, action.payload),
	);
	if (facility) return;
	try {
		const facilityItem: Omit<FacilityItem, 'initialized'> = yield all({
			sensors: retry(5, 1500, getFacilitySensors, action.payload),
			sensorGroups: retry(
				5,
				1500,
				getFacilitySensorGroups,
				action.payload,
			),
			imageSrc: retry(5, 1500, getFacilityImage, action.payload),
		});

		yield put(
			getFacilityResolved({
				facilityId: action.payload,
				facilityItem: { ...facilityItem, initialized: true },
			}),
		);

		const sensorIds = facilityItem.sensors.join(',');
		const configurations: AxiosResponse<{
			configurations: SensorIdMap[];
		}> = yield retry(
			5,
			1500,
			getConfigurations,
			encodeURIComponent(sensorIds),
		);
		const sensors: AxiosResponse<{ sensors: SensorIdMap[] }> = yield retry(
			5,
			1500,
			getSensorsById,
			sensorIds,
		);

		const facilitySensors: SensorIdMap[] = [];
		configurations?.data?.configurations.forEach(c => {
			facilitySensors.push({
				sensorId: c.sensorId,
				sensorAlias: c.sensorAlias,
			});
		});
		sensors?.data?.sensors.forEach(s => {
			facilitySensors.push({
				sensorId: s.sensorId,
				sensorAlias: s.sensorAlias,
			});
		});

		yield put(setSensorIdsMap(facilitySensors));
	} catch (e) {
		yield put(
			getFacilityResolved({
				facilityId: action.payload,
				facilityItem: {
					sensors: [],
					sensorGroups: [],
					imageSrc: undefined,
					initialized: true,
				},
			}),
		);
	}
}

function* watchGetFacility() {
	yield takeEvery(facilityActions.getFacility, getFacilitySaga);
}

export default [watchGetFacility()];
