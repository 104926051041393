import { GeoJSONFeature, SelectedAsset } from './types';

import AbstractFeature from './AbstractFeature';
import { Geometry } from '@Map/services/types';

export default class ExternalFeature implements AbstractFeature {
	private _asset: SelectedAsset;

	constructor(asset: SelectedAsset) {
		// copy object to fix issue where by cannot
		// define a property as its not extensible
		this._asset = { ...asset };
	}

	get id(): SelectedAsset['id'] {
		return this._asset.id;
	}

	get sourceId(): Required<SelectedAsset>['sourceId'] {
		// fallback to layerId if sourceId not set
		return this._asset.sourceId ?? this._asset.layerId;
	}

	get layerId(): SelectedAsset['layerId'] {
		return this._asset.layerId;
	}

	get layerName(): string {
		return this._asset.layerName ?? '';
	}

	get featureData(): GeoJSONFeature {
		return ({
			type: 'Feature',
			geometry: this.geometry,
			properties: {
				id: this.id,
			},
			source: this.sourceId,
			layer: {
				id: this.layerId,
				metadata: {
					displayName: this.layerName,
				},
			},
			state: {},
		} as unknown) as GeoJSONFeature;
	}

	get geometry(): Geometry {
		return (
			this._asset.geometry ?? {
				type: 'Point',
				coordinates: [0, 0],
			}
		);
	}

	asGeojsonFeature(sourceLayer?: string): GeoJSONFeature {
		return sourceLayer
			? { ...this.featureData, sourceLayer }
			: this.featureData;
	}

	updateSourceId(sourceId: SelectedAsset['sourceId']): void {
		this._asset.sourceId = sourceId;
	}

	updateDisplayName(displayName: string): void {
		this._asset.layerName = displayName;
	}
}
