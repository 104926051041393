import Highcharts from 'highcharts';

declare module 'highcharts' {
  interface Point {
    highlight?: (e: SynchronizedEvent) => void;
  }
}

export type SynchronizedChart = Highcharts.Chart | null;

export type SynchronizedEvent = PointerEvent | MouseEvent | TouchEvent;

export type SynchronizedEventType =
  | 'mousemove'
  | 'touchmove'
  | 'touchstart'
  | 'mouseleave';

const SynchronizedEventsModule = (H: typeof Highcharts): void => {
  H.Pointer.prototype.reset = function () {
    return undefined;
  };

  H.Point.prototype.highlight = function (event) {
    const highchartsEvent = this.series.chart.pointer.normalize(event);
    this.onMouseOver(); // Show the hover marker
    this.series.chart.tooltip.refresh(this); // Show the tooltip
    this.series.chart.xAxis[0].drawCrosshair(highchartsEvent, this); // Show the crosshair
  };
};

export default SynchronizedEventsModule;
