import React, { forwardRef, useMemo } from 'react';
import { TimeRangeSelection } from '../../../_next/core/_insight-chart';
import { Theme } from '../../../_next/core/utils/theme-utils';
import {
  InsightPumpPerformanceChart,
  InsightPumpPerformanceChartDesignPoint,
  InsightPumpPerformanceChartManufacturerCurveSeriesProps,
  InsightPumpPerformanceChartScatterSeriesProps,
  InsightPumpPerformanceChartSeriesProps,
  InsightPumpPerformanceChartStatusFilter,
} from '../../../_next/presets/insight-pump-performance-chart';
import useManufacturerCurves from '../../../core/hooks/useManufacturerCurves';
import { useGlobalization } from '../../../i18n';
import {
  GlobalPassthroughs,
  PumpPerformanceChartConnected,
} from '../../../types';
import { ChartingErrorBoundary } from '../../atoms';
import { generatePumpPerformanceSeries } from './ConnectedPumpPerformanceChart.utils';

export type ConnectedPumpPerformanceChartProps = PumpPerformanceChartConnected &
  GlobalPassthroughs & {
    alwaysFetchData?: boolean;
    timeRangeSelection?: TimeRangeSelection;
    selectedTheme?: Theme;
  };

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Version Switch
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const ConnectedPumpPerformanceChart = (
  props: ConnectedPumpPerformanceChartProps,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref?: any
): React.ReactElement => {
  return <PumpPerformanceChartV2 {...props} ref={ref} />;
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * New Chart
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const PumpPerformanceChartV2 = React.forwardRef<
  { chart: Highcharts.Chart | undefined },
  ConnectedPumpPerformanceChartProps
>((props, ref): React.ReactElement => {
  const { t } = useGlobalization();
  const curves = useManufacturerCurves(t, props.manufacturerCurves);

  const scatterSeries = useMemo(() => {
    const generatedSeries = generatePumpPerformanceSeries(
      [props.primarySeries, ...(props.referenceSeries ?? [])],
      props.resolution
    );

    return generatedSeries.map((series) => {
      const getSubType = (
        seriesType?: 'flow-pressure' | 'flow-power',
        displayOptionsMode?: 'pressure' | 'head' | 'pressure-head'
      ): 'pressure' | 'head' | 'pressure-head' | 'power' => {
        let subType: 'pressure' | 'head' | 'pressure-head' | 'power';
        if (seriesType === 'flow-power') {
          subType = 'power';
        } else {
          subType = displayOptionsMode ?? 'pressure';
        }
        return subType;
      };

      return {
        id: series.id,
        type: 'scatter',
        subType: getSubType(series.type, props.displayOptions.mode),
        name: series.name,
        color: series.displayOptions?.color,
        markerSize: series.displayOptions?.markerSize,
        markerType: series.displayOptions?.markerType,
        baseSource: {
          sensorId: series.dataSource.x.sensorId,
          resolution: series.dataSource.x.resolution,
          reading: series.reading,
          customData: series.dataSource.x.customData,
        },
        downstreamCorrection: series.downstreamCorrection,
        upstreamCorrection: series.upstreamCorrection,
        downstreamSource: {
          sensorId: series.dataSource.downstream?.sensorId ?? '',
          resolution: series.dataSource.downstream?.resolution ?? '15-MINUTE',
          reading: series.reading,
          customData: series.dataSource.downstream?.customData,
        },
        ...(series.dataSource.upstream && {
          upstreamSource: {
            sensorId: series.dataSource.upstream.sensorId,
            resolution: series.dataSource.upstream.resolution,
            reading: series.reading,
            customData: series.dataSource.upstream?.customData,
          },
        }),
        hidden: series.id ? props.hiddenSeries?.includes(series.id) : false,
      } as InsightPumpPerformanceChartScatterSeriesProps;
    });
  }, [
    props.displayOptions?.mode,
    props.hiddenSeries,
    props.primarySeries,
    props.referenceSeries,
    props.resolution,
  ]);

  const curveSeries = useMemo(() => {
    return curves.data?.map((curve) => {
      const curveProp = props.manufacturerCurves?.find(
        (c) => c.id === curve.curveId
      );

      const LEGACY_YAXIS_ID_MAP = {
        'flow-pressure': 'pressure',
        'flow-efficiency': 'efficiency',
        'flow-power': 'power',
      } as const;

      const YAXIS_ID_MAP = {
        Pressure: 'pressure',
        Efficiency: 'efficiency',
        Percent: 'efficiency',
        Power: 'power',
      } as const;

      let xSeriesUnit: string | undefined = undefined;
      let ySeriesUnit: string | undefined = undefined;
      let subType: InsightPumpPerformanceChartManufacturerCurveSeriesProps['subType'] =
        'pressure';

      // Support for legacy curves that passed the type from the app
      if (curveProp?.type && LEGACY_YAXIS_ID_MAP[curveProp.type]) {
        subType = LEGACY_YAXIS_ID_MAP[curveProp.type];
      }

      if (curveProp?.unit) {
        ySeriesUnit = curveProp?.unit;
      }

      // Use asset API data if available
      if (curve.ySeries && YAXIS_ID_MAP[curve.ySeries.type]) {
        subType = YAXIS_ID_MAP[curve.ySeries.type];
      }

      if (curve.xSeries?.unit) {
        xSeriesUnit = curve.xSeries?.unit;
      }

      if (curve.ySeries?.unit) {
        ySeriesUnit = curve.ySeries?.unit;
      }

      return {
        id: curve._id,
        type: 'manufacturer-curve',
        data: curve.data,
        name: curve.curveId,
        hidden: props.hiddenSeries?.includes(curve._id),
        subType,
        ySeriesUnit,
        xSeriesUnit,
      } as InsightPumpPerformanceChartManufacturerCurveSeriesProps;
    });
  }, [props.manufacturerCurves, props.hiddenSeries, curves.data]);

  const filterSeries = useMemo(() => {
    return props?.pumpStatusFilters?.map((pump) => {
      let reading = props.primarySeries.reading ?? 'Close';
      if (reading === 'Average' || reading === 'Sum') reading = 'Close';
      return {
        sensorId: pump.sensorId,
        status: pump.status,
        resolution: props.resolution,
        reading: reading,
        type: 'pump-filter',
      } as InsightPumpPerformanceChartStatusFilter;
    });
  }, [props.pumpStatusFilters, props.resolution, props.primarySeries.reading]);

  const designPoint: InsightPumpPerformanceChartDesignPoint | undefined =
    useMemo(() => {
      if (
        props.pumpProperties?.flowRate &&
        props.pumpProperties?.flowRateUnit &&
        props.pumpProperties?.pressure &&
        props.pumpProperties?.pressureUnit
      ) {
        const point: InsightPumpPerformanceChartDesignPoint = {
          type: 'design-point',
          flowRate: props.pumpProperties.flowRate,
          flowRateUnit: props.pumpProperties.flowRateUnit,
          pressure: props.pumpProperties.pressure,
          pressureUnit: props.pumpProperties.pressureUnit,
          efficiency: props.pumpProperties?.efficiency,
          efficiencyUnit: props.pumpProperties?.efficiencyUnit,
          brakeHP: props.pumpProperties?.brakeHP,
          brakeHPUnit: props.pumpProperties?.brakeHPUnit,
          primarySeries: scatterSeries[0],
        };
        return point;
      }
      return undefined;
    }, [props.pumpProperties, scatterSeries]);

  const series = useMemo(() => {
    const s: InsightPumpPerformanceChartSeriesProps[] = [];

    for (const _s of scatterSeries) s.push(_s);

    if (curveSeries) {
      for (const _s of curveSeries) s.push(_s);
    }

    if (filterSeries) {
      for (const _s of filterSeries) s.push(_s);
    }

    if (designPoint) {
      s.push(designPoint);
    }

    return s;
  }, [scatterSeries, curveSeries, filterSeries, designPoint]);

  return (
    <ChartingErrorBoundary chartProps={props}>
      <InsightPumpPerformanceChart
        ref={ref}
        series={series}
        enableHorizontalGrids={props.displayOptions?.showYGrid}
        enableVerticalGrids={props.displayOptions?.showXGrid}
        xAxisLabel={props.displayOptions?.xLabel}
        yAxisLabel={props.displayOptions?.yLabel}
        xAxisMax={props.displayOptions?.xRange?.max}
        xAxisMin={props.displayOptions?.xRange?.min}
        yAxisMax={props.displayOptions?.yRange?.max}
        yAxisMin={props.displayOptions?.yRange?.min}
        timeRangeSelection={props.timeRangeSelection}
        onSeriesVisibilityChange={(seriesId, type) => {
          const hiddenSeries = props.hiddenSeries
            ? [...props.hiddenSeries]
            : [];

          const newHiddenSeries =
            type === 'hide'
              ? [...hiddenSeries, seriesId]
              : hiddenSeries.filter((series) => series !== seriesId);

          props.onHiddenSeriesChange?.(newHiddenSeries);
        }}
        selectedTheme={props.selectedTheme}
        axisMode={props.displayOptions?.mode}
      />
    </ChartingErrorBoundary>
  );
});

PumpPerformanceChartV2.displayName = 'PumpPerformanceChartV2';

export default forwardRef(ConnectedPumpPerformanceChart);
