import { ChartingErrorBoundary } from '../../atoms';
import { GlobalPassthroughs, TableDashletConnected } from '../../../types';
import { TableDashlet } from '../../molecules';
import {
  useIsFeatureEnabled,
  useSelectSensors,
  useSettings,
} from '@innovyze/stylovyze';
import * as InsightChart from '../../../_next/core/_insight-chart';
import * as SeriesData from '../../../_next/core/series-data';
import * as TimeSeriesData from '../../../_next/core/time-series-data';
import React from 'react';
import useSensorData from '../../../core/hooks/useSensorData';
import {
  SensorDataRecords,
  SensorMeasureData,
} from '../../../core/types/data.types';
import { fixCollectionInterval } from '../../../_next/core/time-series-data/utils';

type EdgeData = [
  open: number | null,
  close: number | null,
  low: number | null,
  high: number | null,
  average: number | null,
  sum: number | null,
];

type EdgeSource = {
  sensorId: string;
  resolution: InsightChart.Resolution;
  readings: InsightChart.Reading[];
};

export type ConnectedTableDashletProps = TableDashletConnected &
  GlobalPassthroughs;

function edgeSourceStringifier(source: EdgeSource): string {
  return `${source.sensorId}:${source.resolution}:${source.readings.join(',')}`;
}

function limit(value: string | null | undefined, defaultValue = 500): number {
  if (typeof value === 'string' || value === null) return defaultValue;
  if (isNaN(Number(value))) return defaultValue;
  return Number(value);
}

const readingIndex = {
  open: 0,
  close: 1,
  low: 2,
  high: 3,
  average: 4,
  sum: 5,
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Version Switch
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const ConnectedTableDashlet = (
  props: ConnectedTableDashletProps
): React.ReactElement => {
  const isV2Enabled = useIsFeatureEnabled('info-360-analytics-hp2-charts');

  if (!isV2Enabled) {
    return <TableDashletV1 {...props} />;
  }

  return <TableDashletV2 {...props} />;
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * New Table
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const TableDashletV2 = (
  props: ConnectedTableDashletProps
): JSX.Element | null => {
  const { companySettings } = useSettings();
  const { sensors, initialized: sensorsInitialized } = useSelectSensors();
  const useV3 = useIsFeatureEnabled('info-360-edge-analytics-parquet-files');
  const dataLimit = useIsFeatureEnabled('info-360-analytics-hp2-charts-limit');
  const isEdgeEnabled = useIsFeatureEnabled('info-360-edge-analytics-table');
  const seriesArray = React.useMemo(
    () => (!Array.isArray(props.series) ? [props.series] : props.series),
    [props.series]
  );

  //

  const sensorsSeriesArray = React.useMemo(
    () => seriesArray.filter((series) => !series.customData),
    [seriesArray]
  );

  const seriesData = InsightChart.useSensorDataDisplay(sensorsSeriesArray);

  const { retrieve } = seriesData;

  //

  const [edgeSources] = SeriesData.useSources<EdgeSource>(() => {
    if (!seriesArray?.length) return;
    return seriesArray?.reduce((a, s) => {
      if (!s.customData) {
        a.push({
          sensorId: s.sensorId,
          resolution: s.resolution,
          readings: props.readings ?? ['Close'],
        });
      }
      return a;
    }, [] as EdgeSource[]);
  }, [props.series, props.readings]);

  const [edgeData, edgeStatus, retrieveEdgeData] = SeriesData.useRetriever<
    TimeSeriesData.ResponseBody,
    { timeSelection?: TimeSeriesData.PartialTimeSelection }
  >(
    async (signal, params) => {
      if (!edgeSources?.length || !sensorsInitialized) return;

      const timeSelection = {
        from: params.timeSelection?.from ?? 'oldest',
        to: params.timeSelection?.to ?? 'latest',
      } as TimeSeriesData.TimeSelection;

      const response = await TimeSeriesData.retrieve(signal, {
        order: 'asc',
        timeSelection,
        limit: limit(dataLimit),
        timeZone: companySettings.timeZoneIANA,
        data_version: useV3 ? 'v3.0' : 'v2',
        snapping: 'oldest',
        sources: edgeSources.map((s) => {
          const _sensor = sensors.find((_s) => _s.sensorId === s.sensorId);
          const seconds = fixCollectionInterval(_sensor?.collectionInterval);

          return {
            key: edgeSourceStringifier(s),
            sensorId: s.sensorId,
            collectionInterval: { seconds },
            analytic: TimeSeriesData.makeAnalytic(s.resolution, s.readings),
          };
        }),
      });

      return response.data;
    },
    [
      companySettings.timeZoneIANA,
      dataLimit,
      edgeSources,
      sensors,
      sensorsInitialized,
      useV3,
    ]
  );

  const sensorDataRecords = React.useMemo(() => {
    const dataRecords: SensorDataRecords = {};

    seriesArray.forEach((series) => {
      if (series.customData) return;

      const dataEntry = seriesData.getEntry({
        sensorId: series.sensorId,
        resolution: series.resolution,
      });

      if (dataRecords[series.sensorId] === undefined) {
        dataRecords[series.sensorId] = {};
      }

      if (dataRecords[series.sensorId]?.[series.resolution] === undefined) {
        const _data = dataEntry.data
          ? Array.from(dataEntry.data.entries())
          : [];

        const timestamps: number[] = [];
        const measurements: SensorMeasureData = {};

        for (const [timestamp, measures] of _data) {
          timestamps.push(timestamp);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          measurements[timestamp] = Array.from(measures.values());
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dataRecords[series.sensorId][series.resolution] = {
          status: dataEntry.status ?? 'idle',
          data:
            dataEntry.status === 'resolved'
              ? {
                  timestamps,
                  measurements,
                  unit: dataEntry.unit,
                }
              : null,
        };
      }
    });

    return dataRecords;
  }, [seriesArray, seriesData]);

  const edgeDataRecords = React.useMemo(() => {
    const r: SensorDataRecords = {};

    for (const s of edgeSources) {
      const key = edgeSourceStringifier(s);
      const entry = edgeData?.results?.[key];

      const unit = entry?.unit ?? null;
      const timestamps = [] as number[];
      const measurements = {} as SensorMeasureData;

      if (entry?.data?.length) {
        for (const [timestamp, ...values] of entry.data) {
          const m = new Array(6).fill(null) as EdgeData;
          timestamps.push(timestamp);
          for (let i = 0; i < s.readings.length; i++) {
            const r = s.readings[i];
            const ri = readingIndex[r.toLowerCase()];
            m[ri] = values[i];
          }
          measurements[timestamp] = m;
        }
      }

      if (!r[s.sensorId]) r[s.sensorId] = {};

      if (edgeStatus !== 'resolved') {
        r[s.sensorId][s.resolution] = {
          status: edgeStatus,
          data: null,
        };
      } else {
        r[s.sensorId][s.resolution] = {
          status: 'resolved',
          data: { timestamps, measurements, unit },
        };
      }
    }

    return r;
  }, [edgeData?.results, edgeSources, edgeStatus]);

  React.useEffect(() => {
    if (!isEdgeEnabled) return;
    if (!sensorsInitialized) return;
    retrieveEdgeData({
      timeSelection: {
        from: props.timeRangeSelection?.min,
        to: props.timeRangeSelection?.max,
      },
    });
  }, [
    isEdgeEnabled,
    sensorsInitialized,
    retrieveEdgeData,
    props.timeRangeSelection?.min,
    props.timeRangeSelection?.max,
  ]);

  React.useEffect(() => {
    if (isEdgeEnabled) return;
    retrieve({
      start: props.timeRangeSelection?.min,
      end: props.timeRangeSelection?.max,
      limitFrom: 'end',
    });
  }, [
    isEdgeEnabled,
    retrieve,
    props.timeRangeSelection?.min,
    props.timeRangeSelection?.max,
  ]);

  return (
    <ChartingErrorBoundary chartProps={props}>
      <TableDashlet
        data={isEdgeEnabled ? edgeDataRecords : sensorDataRecords}
        page={props.page}
        sort={props.sort}
        series={props.series}
        readings={props.readings}
        showUnits={props.showUnits}
        showDateTime={props.showDateTime}
        onPageChange={props.onPageChange}
        onSortChange={props.onSortChange}
        useMostRecentReading={props.useMostRecentReading}
        density={props.density}
        onSelectionChange={props.onSelectionChange}
        conditionalFormatting={props.conditionalFormatting}
        columnConditionalFormatting={props.columnConditionalFormatting}
        onColumnSelectedChange={props.onColumnSelectedChange}
        showSelection={props.showSelection}
        showColumnSelection={props.showColumnSelection}
        showSensorAlias={props.showSensorAlias}
        clearSelection={props.clearSelection}
      />
    </ChartingErrorBoundary>
  );
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Old Table
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const TableDashletV1 = (
  props: ConnectedTableDashletProps
): JSX.Element | null => {
  const seriesArray = !Array.isArray(props.series)
    ? [props.series]
    : props.series;

  const sensorDataRecords = useSensorData(
    seriesArray.map((series) => ({
      sensorId: series.sensorId,
      resolution: series.resolution,
    })),
    props.dataRangeSelection
  );

  return (
    <ChartingErrorBoundary chartProps={props}>
      <TableDashlet
        data={sensorDataRecords}
        page={props.page}
        sort={props.sort}
        series={props.series}
        readings={props.readings}
        showUnits={props.showUnits}
        showDateTime={props.showDateTime}
        onPageChange={props.onPageChange}
        onSortChange={props.onSortChange}
        useMostRecentReading={props.useMostRecentReading}
        density={props.density}
        showSelection={props.showSelection}
        showColumnSelection={props.showColumnSelection}
      />
    </ChartingErrorBoundary>
  );
};

export default ConnectedTableDashlet;
