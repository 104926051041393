import {
	MenuListButton,
	StylovyzeTable,
	StylovyzeTableHeaderProps,
	useUnits,
} from '@innovyze/stylovyze';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Delete, Edit } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';

import clsx from 'clsx';
import {
	ObservationTableProps,
	PACPObservation,
	Observation,
	MACPObservation,
	InspectionStandard,
	MSCCObservation,
	VideoObservation,
	WSA05Observation,
} from '@Types';
import { useGlobalization } from '@Translations';
import { getTimeCounter, wrapHeader } from '@Utils';

const editHeader: StylovyzeTableHeaderProps[] = [
	{
		cell: '',
		dataCy: 'col-Edit',
		key: 'Edit',
		width: '68px',
		align: 'center',
		padding: '0 10px',
	},
];

const useStyle = makeStyles(() => ({
	ObservationTable: {
		height: '100%',
		width: '100%',
		background: 'white',
		'& > div > div': {
			overflow: 'auto',
			height: '100%',
			width: '100%',
		},
		'& > div ': {
			overflow: 'auto',
			height: '100%',
			width: '100%',
		},
	},
}));

//change VCR time format
const formatVcrTime = (sLabel: string) => {
	if (sLabel && sLabel.length == 6) {
		return (
			sLabel.substring(0, 2) +
			':' +
			sLabel.substring(2, 4) +
			':' +
			sLabel.substring(4, 6)
		);
	}
	return sLabel;
};

export const ObservationTable = ({
	onObservationRowClicked,
	onEditObservationClick,
	onDeleteClicked,
	observationsSelector,
	standard,
	getObservationAction,
	inspectionId,
	playerTime,
	getMediaInspectionId,
	searchCode,
	readOnly = false,
	headers = [],
	jointOnly = false,
	continuousOnly = false,
	stepOnly = false,
	gradeFilters = [],
	typeFilters = [],
	isLoading = false,
	boundaryConditionId,
}: ObservationTableProps): JSX.Element => {
	const { t } = useGlobalization();
	const dispatch = useDispatch();
	const classes = useStyle();
	const { getSystemValueWithDecimalPlaces } = useUnits();
	const observations = observationsSelector(standard);
	const [firstMount, setFirstMount] = useState<boolean>(true);

	useEffect(() => {
		if (firstMount && standard !== undefined && observations.length == 0) {
			dispatch(
				getObservationAction({
					standard: standard,
					inspectionID: inspectionId,
					jointOnly: jointOnly,
					continuousOnly: continuousOnly,
					stepOnly: stepOnly,
					gradeFilters: gradeFilters,
					typeFilters: typeFilters,
				}),
			);
			setFirstMount(false);
		}
	}, [firstMount]);

	const [isHoverMenu, setHoverMenu] = useState<boolean>(false);
	const [observationId, setObservationId] = useState<number | undefined>(-1);

	const createHeader = (
		cell: string,
		dataCy: string,
		key: string,
		width: string,
		padding: string,
		dontWrapCellInT?: boolean,
	) => {
		return {
			cell: dontWrapCellInT ? cell : wrapHeader(t(cell)),
			dataCy: dataCy,
			key: key,
			width: width,
			padding: padding,
		};
	};

	const defaultPACPHeaders: StylovyzeTableHeaderProps[] = [
		createHeader('Distance', 'col-Distance', 'Distance', '100px', '0 10px'),
		createHeader(
			'Code',
			'col-Full_PACP_Code',
			'Full_PACP_Code',
			'50px',
			'0 10px',
		),
		createHeader('CD', 'col-CD', 'CD', '50px', '0 10px'),
		createHeader('Time', 'col-Timestamp', 'Timestamp', '100px', '0 10px'),
		createHeader('At/From', 'col-At/From', 'At/From', '50px', '0 10px'),
		createHeader('To', 'col-To', 'To', '50px', '0 10px'),
		createHeader('1st', 'col-1st', '1st', '50px', '0 10px'),
		createHeader('2nd', 'col-2nd', '2nd', '50px', '0 10px'),
		createHeader('%', 'col-%', '%', '50px', '0 10px', true),
		createHeader(
			'Struct.',
			'col-SctructuralScore',
			'SctructuralScore',
			'50px',
			'0 10px',
		),
		createHeader(
			'O&M',
			'col-ServiceScore',
			'ServiceScore',
			'50px',
			'0 10px',
		),
		createHeader('Remarks', 'col-Remarks', 'Remarks', '200px', '0 10px'),
	];

	const defaultMACPHeaders: StylovyzeTableHeaderProps[] = [
		createHeader('Distance', 'col-Distance', 'Distance', '100px', '0 10px'),
		createHeader(
			'Code',
			'col-Full_PACP_Code',
			'Full_PACP_Code',
			'50px',
			'0 10px',
		),
		createHeader('CD', 'col-CD', 'CD', '50px', '0 10px'),
		createHeader('Time', 'col-Time', 'Time', '100px', '0 10px'),
		createHeader('At/From', 'col-At', 'At/From', '50px', '0 10px'),
		createHeader('To', 'col-To', 'To', '50px', '0 10px'),
		createHeader('1st', 'col-Dimension1', 'Dim1', '50px', '0 10px'),
		createHeader('2nd', 'col-Dimension2', 'Dim2', '50px', '0 10px'),
		createHeader('%', 'col-%', '%', '50px', '0 10px', true),
		createHeader('Loc.', 'col-Loc', 'Loc', '50px', '0 10px'),
		createHeader('Struct.', 'col-Struct', 'Struct', '50px', '0 10px'),
		createHeader('O&M', 'col-OM', 'OM', '50px', '0 10px'),
		createHeader('Remarks', 'col-Remarks', 'Remarks', '200px', '0 10px'),
	];

	const defaultMSCCHeaders: StylovyzeTableHeaderProps[] = [
		createHeader('Distance', 'col-Distance', 'Distance', '100px', '0 10px'),
		createHeader(
			'Code',
			'col-Full_PACP_Code',
			'Full_PACP_Code',
			'50px',
			'0 10px',
		),
		createHeader('CD', 'col-CD', 'CD', '50px', '0 10px'),
		createHeader('Time', 'col-Timestamp', 'Timestamp', '100px', '0 10px'),
		createHeader('At / From', 'col-At/From', 'At/From', '40px', '0 10px'),
		createHeader('To', 'col-To', 'To', '40px', '0 10px'),
		createHeader('Dim 1', 'col-1st', '1st', '40px', '0 10px'),
		createHeader('Dim 2', 'col-2nd', '2nd', '40px', '0 10px'),
		createHeader('Band', 'col-Band', 'Band', '50px', '0 10px'),
		createHeader('Material', 'col-Material', 'Material', '50px', '0 10px'),
		createHeader('%', 'col-%', '%', '50px', '0 10px', true),
		createHeader(
			'Struct.',
			'col-SctructuralScore',
			'SctructuralScore',
			'50px',
			'0 10px',
		),
		createHeader(
			'Service',
			'col-ServiceScore',
			'ServiceScore',
			'50px',
			'0 10px',
		),
		createHeader('Remarks', 'col-Remarks', 'Remarks', '200px', '0 10px'),
	];

	const defaultWSA05Headers: StylovyzeTableHeaderProps[] = [
		createHeader('Distance', 'col-Distance', 'Distance', '100px', '0 10px'),
		createHeader(
			'Code',
			'col-Full_PACP_Code',
			'Full_PACP_Code',
			'50px',
			'0 10px',
		),
		createHeader('CD', 'col-CD', 'CD', '50px', '0 10px'),
		createHeader('Time', 'col-Timestamp', 'Timestamp', '100px', '0 10px'),
		createHeader('At/From', 'col-At/From', 'At/From', '50px', '0 10px'),
		createHeader('To', 'col-To', 'To', '50px', '0 10px'),
		createHeader('Char. 1', 'col-c1', '1st', '50px', '0 10px'),
		createHeader('Char. 2', 'col-c2', '2nd', '50px', '0 10px'),
		createHeader('Quant. 1', 'col-q1', 'quant1', '50px', '0 10px'),
		createHeader('Quant. 2', 'col-q2', 'quant2', '50px', '0 10px'),
		createHeader(
			'Struct.',
			'col-SctructuralScore',
			'SctructuralScore',
			'50px',
			'0 10px',
		),
		createHeader(
			'Service',
			'col-ServiceScore',
			'ServiceScore',
			'50px',
			'0 10px',
		),
		createHeader('Remarks', 'col-Remarks', 'Remarks', '200px', '0 10px'),
	];

	let selected: {
		id: number;
		time: number;
		mediaInspectionId?: string;
	}[] = [];

	useEffect(() => {
		if (playerTime >= 0) {
			selected = observations
				?.map(observation => {
					const obs = observation as VideoObservation;
					return {
						id: obs.conditionId,
						time: getTimeCounter(obs),
						mediaInspectionId: obs.mediaInspectionId,
					};
				})
				.sort((a, b) => b.time - a.time)
				.filter(o => o.time <= playerTime)
				.filter(o => {
					if (getMediaInspectionId && o.mediaInspectionId) {
						return getMediaInspectionId() === o.mediaInspectionId;
					}
					return true;
				});

			if (
				selected &&
				selected.length > 0 &&
				selected[0].id != observationId
			) {
				setObservationId(selected[0].id);
			} //end if selected
		} //end if playertime
	}, [playerTime]);

	//returns a banner to indicate merged inspection
	const MergedInspectionBanner = () => {
		return (
			<div
				style={{
					backgroundColor: '#007CA0',
					color: 'white',
					fontSize: '16px',
					fontStyle: 'italic',
					fontWeight: 600,
					marginLeft: '-11px',
					marginRight: '-11px',
					height: '32.8px',
					padding: '5px 10px 0px 0px',
					textAlign: 'center',
					flexBasis: '105%',
				}}>
				{t('Pipe section not inspected')}
			</div>
		);
	};
	const observationRows = observations
		?.filter((observation: Observation) => {
			if (searchCode && searchCode.trim().length > 0) {
				let code = '';
				switch (standard) {
					case InspectionStandard.MACP:
						code = (observation as MACPObservation).macpCode;
						break;
					case InspectionStandard.MSCC:
						code = (observation as WSA05Observation).code;
						break;
					case InspectionStandard.WSA05:
						code = (observation as MSCCObservation).code;
						break;
					case InspectionStandard.PACP:
					default:
						code = (observation as PACPObservation).PACPCode;
						break;
				}
				return code.toUpperCase() == searchCode.toUpperCase();
			} else {
				return true;
			}
		})
		.map((observation: Observation) => {
			switch (standard) {
				case InspectionStandard.MACP: {
					const macpObs = observation as MACPObservation;
					if (boundaryConditionId === observation.conditionId) {
						return {
							cells: [
								{
									content: MergedInspectionBanner(),
									props: {
										colSpan: 20,
									},
								},
							],
							dataCy: 'row' + observation.conditionId,
							key: observation.conditionId,
						};
					} else
						return {
							cells: [
								getSystemValueWithDecimalPlaces(
									`${macpObs.distance}m`,
									2,
								),
								macpObs.macpCode,
								macpObs.continuous,
								formatVcrTime(macpObs.vcrTime),
								macpObs.clockAtFrom,
								macpObs.clockTo,
								macpObs.value1stDimension,
								macpObs.value2ndDimension,
								macpObs.valuePercent != undefined
									? Math.round(macpObs.valuePercent)
									: macpObs.valuePercent,
								macpObs.mHComponentId,
								macpObs.structuralScore,
								macpObs.serviceScore,
								{
									content: macpObs.remarks,
									ellipsis: true,
								},
								readOnly ? (
									<></>
								) : (
									<div
										key={macpObs.conditionId}
										onMouseEnter={() => setHoverMenu(true)}
										onMouseLeave={() => setHoverMenu(false)}
										role="button"
										tabIndex={0}>
										<MenuListButton
											menuItems={[
												{
													text: 'Edit',
													icon: <Edit />,
													onClick: () => {
														if (
															onEditObservationClick
														)
															onEditObservationClick(
																standard,
																macpObs.conditionId,
															);
													},
												},
												{
													text: 'Delete',
													icon: <Delete />,
													onClick: () => {
														if (onDeleteClicked)
															onDeleteClicked(
																observation,
															);
													},
												},
											]}
											buttonCy="actions"
										/>
									</div>
								),
							],
							dataCy: 'row' + macpObs.conditionId,
							key: macpObs.conditionId,
							onClick: () => {
								if (!isHoverMenu && onObservationRowClicked) {
									setObservationId(macpObs.conditionId);
									onObservationRowClicked(
										standard,
										observation,
									);
								}
							},
							selected: observation.conditionId == observationId,
						};
				}
				case InspectionStandard.MSCC: {
					const msccObs = observation as MSCCObservation;
					if (boundaryConditionId === observation.conditionId) {
						return {
							cells: [
								{
									content: MergedInspectionBanner(),
									props: {
										colSpan: 20,
									},
								},
							],
							dataCy: 'row' + observation.conditionId,
							key: observation.conditionId,
						};
					} else {
						return {
							cells: [
								getSystemValueWithDecimalPlaces(
									`${observation.distance}m`,
									2,
								),
								msccObs.code,
								msccObs.continuous,
								formatVcrTime(msccObs.videoRef),
								msccObs.clockAtFrom,
								msccObs.clockTo,
								msccObs.value1stDimension,
								msccObs.value2ndDimension,
								msccObs.band,
								msccObs.material,
								msccObs.valuePercent,
								msccObs.structuralScore,
								msccObs.serviceScore,
								{
									content: msccObs.remarks,
									ellipsis: true,
								},
								readOnly ? (
									<></>
								) : (
									<div
										key={observation.conditionId}
										onMouseEnter={() => setHoverMenu(true)}
										onMouseLeave={() => setHoverMenu(false)}
										role="button"
										tabIndex={0}>
										<MenuListButton
											menuItems={[
												{
													text: t('Edit'),
													icon: <Edit />,
													onClick: () => {
														if (
															onEditObservationClick
														)
															onEditObservationClick(
																standard,
																observation.conditionId,
															);
													},
												},
												{
													text: t('Delete'),
													icon: <Delete />,
													onClick: () => {
														if (onDeleteClicked)
															onDeleteClicked(
																observation,
															);
													},
												},
											]}
											buttonCy="actions"
										/>
									</div>
								),
							],
							dataCy: 'row' + observation.conditionId,
							key: observation.conditionId,
							onClick: () => {
								if (!isHoverMenu && onObservationRowClicked) {
									setObservationId(msccObs.conditionId);
									onObservationRowClicked(
										standard,
										observation,
									);
								}
							},
							selected: observation.conditionId == observationId,
						};
					}
				}
				case InspectionStandard.WSA05: {
					const wsa05Obs = observation as WSA05Observation;
					if (boundaryConditionId === observation.conditionId) {
						return {
							cells: [
								{
									content: MergedInspectionBanner(),
									props: {
										colSpan: 20,
									},
								},
							],
							dataCy: 'row' + observation.conditionId,
							key: observation.conditionId,
						};
					} else
						return {
							cells: [
								wsa05Obs.distance || wsa05Obs.distance === 0
									? getSystemValueWithDecimalPlaces(
											`${wsa05Obs.distance}m`,
											2,
									  )
									: '',
								wsa05Obs.code,
								wsa05Obs.continuous,
								formatVcrTime(wsa05Obs.videoPosition),
								wsa05Obs.clockAtFrom,
								wsa05Obs.clockTo,
								wsa05Obs.char1,
								wsa05Obs.char2,
								wsa05Obs.quant1,
								wsa05Obs.quant2,
								wsa05Obs.structuralScore,
								wsa05Obs.serviceScore,
								{
									content: wsa05Obs.remarks,
									ellipsis: true,
								},
								readOnly ? (
									<></>
								) : (
									<div
										key={observation.conditionId}
										onMouseEnter={() => setHoverMenu(true)}
										onMouseLeave={() => setHoverMenu(false)}
										role="button"
										tabIndex={0}>
										<MenuListButton
											menuItems={[
												{
													text: t('Edit'),
													icon: <Edit />,
													onClick: () => {
														if (
															onEditObservationClick
														)
															onEditObservationClick(
																standard,
																observation.conditionId,
															);
													},
												},
												{
													text: t('Delete'),
													icon: <Delete />,
													onClick: () => {
														if (onDeleteClicked)
															onDeleteClicked(
																observation,
															);
													},
												},
											]}
											buttonCy="actions"
										/>
									</div>
								),
							],
							dataCy: 'row' + observation.conditionId,
							id: 'row' + observation.conditionId,
							key: observation.conditionId,
							onClick: () => {
								if (!isHoverMenu && onObservationRowClicked) {
									setObservationId(wsa05Obs.conditionId);
									onObservationRowClicked(
										standard,
										observation,
									);
								}
							},
							selected: observation.conditionId == observationId,
						};
				}
				case InspectionStandard.PACP:
				default: {
					const pacpObs = observation as PACPObservation;
					if (boundaryConditionId === observation.conditionId) {
						return {
							cells: [
								{
									content: MergedInspectionBanner(),
									props: {
										colSpan: 20,
									},
								},
							],
							dataCy: 'row' + observation.conditionId,
							key: observation.conditionId,
						};
					} else
						return {
							cells: [
								getSystemValueWithDecimalPlaces(
									`${observation.distance}m`,
									2,
								),
								pacpObs.PACPCode,
								pacpObs.continuous,
								formatVcrTime(pacpObs.VCRTime),
								pacpObs.clockAtFrom,
								pacpObs.clockTo,
								pacpObs.value1stDimension,
								pacpObs.value2ndDimension,
								pacpObs.valuePercent != undefined
									? Math.round(pacpObs.valuePercent)
									: pacpObs.valuePercent,
								pacpObs.structuralScore,
								pacpObs.serviceScore,
								{
									content: pacpObs.remarks,
									ellipsis: true,
								},
								readOnly ? (
									<></>
								) : (
									<div
										key={observation.conditionId}
										onMouseEnter={() => setHoverMenu(true)}
										onMouseLeave={() => setHoverMenu(false)}
										role="button"
										tabIndex={0}>
										<MenuListButton
											menuItems={[
												{
													text: t('Edit'),
													icon: <Edit />,
													onClick: () => {
														if (
															onEditObservationClick
														)
															onEditObservationClick(
																standard,
																observation.conditionId,
															);
													},
												},
												{
													text: t('Delete'),
													icon: <Delete />,
													onClick: () => {
														if (onDeleteClicked)
															onDeleteClicked(
																observation,
															);
													},
												},
											]}
											buttonCy="actions"
										/>
									</div>
								),
							],
							dataCy: 'row' + observation.conditionId,
							key: observation.conditionId,
							onClick: () => {
								if (!isHoverMenu && onObservationRowClicked) {
									setObservationId(pacpObs.conditionId);
									onObservationRowClicked(
										standard,
										observation,
									);
								}
							},
							selected: observation.conditionId == observationId,
						};
				}
			}
		});

	const getHeader = () => {
		if (headers == undefined || headers.length == 0) {
			if (readOnly) {
				switch (standard) {
					case InspectionStandard.MACP:
						return defaultMACPHeaders;
					case InspectionStandard.MSCC:
						return defaultMSCCHeaders;
					case InspectionStandard.WSA05:
						return defaultWSA05Headers;
					case InspectionStandard.PACP:
					default:
						return defaultPACPHeaders;
				}
			} else {
				switch (standard) {
					case InspectionStandard.MACP:
						return [...defaultMACPHeaders, ...editHeader];
					case InspectionStandard.MSCC:
						return [...defaultMSCCHeaders, ...editHeader];
					case InspectionStandard.WSA05:
						return [...defaultWSA05Headers, ...editHeader];
					case InspectionStandard.PACP:
					default:
						return [...defaultPACPHeaders, ...editHeader];
				}
			}
		} else return headers;
	};

	return (
		<div
			className={clsx(classes.ObservationTable)}
			style={{ minHeight: '300px' }}>
			{observations?.length >= 0 ? (
				<StylovyzeTable
					className="ScrollTable"
					dataCy="defect-table"
					headers={getHeader()}
					rows={observationRows}
					initialized={!isLoading}
					useSkeleton={{ rowCount: 10 }}
				/>
			) : (
				<CircularProgress />
			)}
		</div>
	);
};

export default ObservationTable;
