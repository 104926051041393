import { BackgroundTypes, MapModes, ReactMap } from '@innovyze/inno-map';
import {
	STYLOVYZE_FLAGS,
	useAuth0,
	useIsFeatureEnabled,
	useSettings,
} from '@innovyze/stylovyze';
import React from 'react';

import { useSelectMapInsertSpatial } from '@Selectors';
import { MapStatusType } from '@Types';
import { useReactMapConfig } from '@innovyze/stylovyze/lib/hooks/map/useReactMapConfig';

export interface MapInsertSpatialProps {
	layerName: string;
	showSystemTypeLayers?: boolean;
	logLevel?: MapStatusType;
}

const MapInsertSpatialComponent: React.FC<MapInsertSpatialProps> = ({
	layerName,
	showSystemTypeLayers = false,
	logLevel = MapStatusType.WARN,
}: MapInsertSpatialProps) => {
	const { token } = useAuth0();

	const inputs = useSelectMapInsertSpatial(
		token,
		layerName,
		showSystemTypeLayers,
	);
	const { companySettings } = useSettings();

	const showArcGISBasemap = !!useIsFeatureEnabled(
		STYLOVYZE_FLAGS.ARCGIS_BASEMAPS,
	);
	const basemapOrigin = showArcGISBasemap
		? companySettings.mapSettings?.basemapOrigin ?? 'mapbox'
		: 'mapbox';
	const background =
		basemapOrigin == 'arcgis'
			? BackgroundTypes.OSMStandard
			: BackgroundTypes.Streets;

	const reactMapConfig = useReactMapConfig();

	return (
		<ReactMap
			config={reactMapConfig}
			mode={MapModes.Navigation}
			dataServices={inputs.sources}
			layerConfig={inputs.layers}
			autoResize={false}
			position={inputs.location?.position ?? undefined}
			hiddenLayers={inputs.hiddenLayers}
			showMinZoomWarning={false}
			logLevel={logLevel ?? MapStatusType.WARN}
			background={background}
		/>
	);
};

export default MapInsertSpatialComponent;
