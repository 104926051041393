import { CanceledError } from 'axios';
import * as React from 'react';

export type Status = 'idle' | 'loading' | 'resolved' | 'rejected';

export function useRetriever<
  T,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  P extends Record<string, any> = undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  D extends any[] = any[],
>(
  retriever: (signal: AbortSignal, params?: P) => Promise<T | null | undefined>,
  deps: D
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): [
  data: T | undefined,
  status: Status,
  retrieve: P extends undefined ? () => void : (params: P) => void,
] {
  const [status, setStatus] = React.useState<Status>('idle');
  const [data, setData] = React.useState<T>();

  const abortControllerRef = React.useRef<AbortController>();
  const retrieverRef = useRef(retriever);

  const retrieve = React.useCallback(
    (params?: P) => {
      abortControllerRef.current?.abort();
      abortControllerRef.current = new AbortController();

      setStatus('loading');

      retrieverRef
        .current(abortControllerRef.current.signal, params)
        .then((response) => {
          if (response) setData(response);
          setStatus('resolved');
        })
        .catch((cause) => {
          if (cause instanceof CanceledError) return;
          console.error(cause);
          setStatus('rejected');
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps
  );

  React.useEffect(() => () => abortControllerRef.current?.abort(), []);

  return [
    data,
    status,
    retrieve as P extends undefined ? () => void : (params: P) => void,
  ];
}

// # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #

function useRef<V>(value: V): React.MutableRefObject<V> {
  const ref = React.useRef(value);
  ref.current = value;
  return ref;
}
