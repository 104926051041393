import {
	DataServiceType,
	Features,
	GeoJsonDataType,
	GeoJsonInputTypes,
	GeometryPoint,
	IconSet,
} from './types';
import { Facility, FacilityResponse } from '@Map/types';

import AssetRegistry from './AssetRegistry';
import GeoJsonDataService from './GeoJsonDataService';
import { LOAD_ICON } from './constants';
import MapboxDataSource from './MapboxDataSource';
import PaginatedFetch from './PaginatedFetch';
import { isValidLocation } from '@Map/facility/facility.utils';

export default class FacilityDataService extends GeoJsonDataService {
	constructor(dataService: DataServiceType<GeoJsonDataType>) {
		super(dataService);
		this._requestHeaders = [
			['Authorization', `Bearer ${dataService.token}`],
			['Accept', 'application/geo+json'],
		];
	}

	get iconSet(): IconSet {
		return {
			alert: 'sensor-alert',
		};
	}

	async fetchData(): Promise<GeoJsonInputTypes> {
		this.cancelPendingDataRequests();
		this._request = new PaginatedFetch({
			url: this.dataEndpoint as string,
			headers: this._requestHeaders,
			startOffset: 0,
		});

		const paginatedData = await this._request.fetchPages<
			FacilityResponse
		>();

		const allFacilities = paginatedData
			.map(({ liftStations }) => liftStations)
			.reduce((previous, current) => {
				return [...previous, ...current];
			}, []);
		const features = this.convertToFeatures(allFacilities);
		return {
			type: 'FeatureCollection',
			features,
		};
	}

	createDataSources(data: GeoJsonDataType): void {
		const dataSource = new MapboxDataSource({
			id: this.id,
			displayName: this.displayName,
			type: 'symbol',
			icon: AssetRegistry.getIcon('facility'),
			color: AssetRegistry.getColor('facility'),
			data,
			zIndex: this._zIndex,
		});

		this._dataSources = [dataSource];
	}

	convertToFeatures(facilities: Facility[]): Features[] {
		return facilities.flatMap(
			({ liftStationData, _id, name, type, workspaceId }) => {
				const location = liftStationData?.location;
				if (!location || !isValidLocation(location)) return [];
				const { latitude, longitude } = location;
				const geometry = {
					type: 'Point',
					coordinates: [longitude, latitude],
				} as GeometryPoint;
				return {
					type: 'Feature',
					geometry,
					properties: {
						id: _id,
						name,
						srcType: 'facility',
						type: 'facility',
						icon: 'facilities',
						facilityType: type,
						workspaceId: workspaceId,
					},
				};
			},
		);
	}

	/** overwrite function from GeoJsonDataService as not required */
	remapProperties(data: GeoJsonDataType | null): GeoJsonDataType | null {
		// just load the alert icon
		this.fire(LOAD_ICON, { id: 'sensor-alert' });
		return data;
	}
}
