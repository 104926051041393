import { palette } from '@innovyze/stylovyze';
import { Ref } from 'react';

export type ChartControllerRef = Ref<ChartController> | undefined;

export type ChartController = {
  exportToCsv: () => void;
  exportToXls: () => void;
};

export enum ChartTypes {
  Line = 'line',
  Bar = 'column',
  Area = 'area',
  Point = 'scatter',
  'Smooth Line' = 'spline',
  'Smooth Area' = 'areaspline',
  pie = 'pie',
}

export enum DashTypes {
  'Solid' = 'Solid',
  'Short Dash' = 'ShortDash',
  'Short Dot' = 'ShortDot',
  'Short Dash Dot' = 'ShortDashDot',
  'Short Dash Dot Dot' = 'ShortDashDotDot',
  'Dot' = 'Dot',
  'Dash' = 'Dash',
  'Long Dash' = 'LongDash',
  'Dash Dot' = 'DashDot',
  'Long Dash Dot' = 'LongDashDot',
  'Long Dash Dot Dot' = 'LongDashDotDot',
}

export enum MarkerTypes {
  'Circle' = 'circle',
  'Square' = 'square',
  'Diamond' = 'diamond',
  'Triangle' = 'triangle',
  'Triangle Down' = 'triangle-down',
}

export enum MarkerSizes {
  'XX-Small' = 'XX-Small',
  'X-Small' = 'X-Small',
  'Small' = 'Small',
  'Medium' = 'Medium',
  'Large' = 'Large',
  'X-Large' = 'X-Large',
  'XX-Large' = 'XX-Large',
}

export const MARKER_SIZE_MAP: Record<MarkerSizes, number> = {
  'XX-Small': 1,
  'X-Small': 2,
  Small: 3,
  Medium: 4,
  Large: 5,
  'X-Large': 6,
  'XX-Large': 7,
};

export const DEFAULT_CHART_COLORS = [
  palette.primary.main,
  palette.secondary.main,
  palette.error.main,
  palette.warning.main,
  '#83BC3F',
  '#886CE7',
  palette.primary.dark,
  palette.secondary.dark,
  palette.error.dark,
  palette.warning.dark,
  '#4B7E04',
  '#4C3DAD',
  palette.primary.light,
  palette.secondary.light,
  palette.error.light,
  palette.warning.light,
  '#B6EF70',
  '#BAA6FF',
] as const;

export type Theme =
  | 'Default'
  | 'BrandDark'
  | 'BrandLight'
  | 'DarkUnica'
  | 'SandSignika'
  | 'GridLight';
