import { createAction } from '@reduxjs/toolkit';
import { RawPatternMeasureData } from '../types/patternChart.types';

export interface GetPatternMeasureDataParams {
  /** Id of the chart being fetched for - can just be uuid */
  id: string;
  /** Pattern ID of the fetched chart */
  patternId: string;
}

export interface GetPatterMeasureDataResponse {
  /** Id of the chart being fetched for - can just be uuid */
  id: string;
  /** Data */
  data: RawPatternMeasureData[];

  unit: string;
}

export interface GetMeasureDataRejected {
  /** Id of the chart being fetched for - can just be uuid */
  id: string;
}

export const getPatternMeasureData = createAction<GetPatternMeasureDataParams>(
  'summaryze/patternMeasureData/get'
);
export const getPatternMeasureDataResolved =
  createAction<GetPatterMeasureDataResponse>(
    'summaryze/patternMeasureData/get/resolved'
  );
export const getPatternMeasureDataRejected =
  createAction<GetMeasureDataRejected>(
    'summaryze/patternMeasureData/get/rejected'
  );
