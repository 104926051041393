import styled from 'styled-components';
import StyledChart from '../Chart/Chart.styles';

const StyledStackedChart = {
  Stack: styled.div`
    background-color: white;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 400px;
    overflow: hidden;
    padding: 1rem;
    width: 100%;
  `,
  Scroll: styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
    gap: 20px;
    overflow: auto;
  `,
  Flex: styled.div`
    flex: 1 1 0;
    min-height: 160px;

    ${StyledChart.Container} {
      padding: 0 !important;
    }
  `,
  RangeSelector: styled.div`
    height: 40px;
    margin-bottom: 10px;

    ${StyledChart.Container} {
      padding: 0 !important;
    }
  `,
  Navigator: styled.div`
    height: 110px;

    ${StyledChart.Container} {
      padding: 0 !important;
    }
  `,
};

export default StyledStackedChart;
