import React, { forwardRef, useMemo } from 'react';
import {
  ScatterChartConnected,
  GlobalPassthroughs,
  Resolutions,
} from '../../../types';

import { ChartingErrorBoundary } from '../../atoms';
import {
  InsightScatterChart,
  InsightScatterChartScatterSeriesProps,
} from '../../../_next/presets/insight-scatter-chart';
import { TimeRangeSelection } from '../../../_next/core/_insight-chart';
import { Theme } from '../../../_next/core/utils/theme-utils';

export type ConnectedScatterChartProps = ScatterChartConnected &
  GlobalPassthroughs & {
    alwaysFetchData?: boolean;
    timeRangeSelection?: TimeRangeSelection;
    selectedTheme?: Theme;
  };

const ConnectedScatterChart = (
  props: ConnectedScatterChartProps,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref?: any
): React.ReactElement => {
  return <ScatterChart {...props} ref={ref} />;
};

const ScatterChart = React.forwardRef<
  { chart: Highcharts.Chart | undefined },
  ConnectedScatterChartProps
>((props, ref): React.ReactElement => {
  const series = useMemo(() => {
    const scatterSeries = props.series;

    return scatterSeries.map((series) => {
      return {
        id: series.id,
        type: 'scatter',
        name: series.alias,
        color: series.displayOptions?.color,
        markerSize: series.displayOptions?.markerSize,
        markerType: series.displayOptions?.markerType,
        xSource: {
          sensorId: series.xData.id,
          resolution: props.resolution || Resolutions['15 Minutes'],
          customData: series.xData.customData,
          reading: series.reading,
        },
        ySource: {
          sensorId: series.yData.id,
          resolution: props.resolution || Resolutions['15 Minutes'],
          customData: series.yData.customData,
          reading: series.reading,
        },
        yAxisLabel:
          series.displayOptions?.customYAxis?.yAxis?.label ??
          series.yData.alias,
        yAxisSide: series.displayOptions?.customYAxis?.yAxis?.side ?? 'left',
        yAxisMin: series.displayOptions?.customYAxis?.yRange?.min,
        yAxisMax: series.displayOptions?.customYAxis?.yRange?.max,
        enableHorizontal: props.displayOptions?.showYGrid,
        hidden: series.id ? props.hiddenSeries?.includes(series.id) : false,
      } as InsightScatterChartScatterSeriesProps;
    });
  }, [props.hiddenSeries, props.series, props.resolution]);

  return (
    <ChartingErrorBoundary chartProps={props}>
      <InsightScatterChart
        ref={ref}
        series={series}
        enableHorizontalGrids={props.displayOptions?.showYGrid}
        enableVerticalGrids={props.displayOptions?.showXGrid}
        xAxisLabel={props.displayOptions?.xLabel}
        xAxisMax={props.displayOptions?.xRange?.max}
        xAxisMin={props.displayOptions?.xRange?.min}
        timeRangeSelection={props.timeRangeSelection}
        onSeriesVisibilityChange={(seriesId, type) => {
          const hiddenSeries = props.hiddenSeries
            ? [...props.hiddenSeries]
            : [];

          const newHiddenSeries =
            type === 'hide'
              ? [...hiddenSeries, seriesId]
              : hiddenSeries.filter((series) => series !== seriesId);

          props.onHiddenSeriesChange?.(newHiddenSeries);
        }}
        selectedTheme={props.selectedTheme}
      />
    </ChartingErrorBoundary>
  );
});

ScatterChart.displayName = 'ScatterChart';

export default forwardRef(ConnectedScatterChart);
