// using webpack dependency-management to load all the files in the directory
// https://webpack.js.org/guides/dependency-management/#require-context
const importAll = require =>
	require.keys().reduce((acc, next) => {
		acc[
			next.replace('./', '').replace(/\.svg$/, '')
		] = require(next).default;
		return acc;
	}, {});

// arguments passed to require.context must be literals
const icons = importAll(require.context('.', true, /\.svg$/));

export default icons;

const importAllSvg = require =>
	require.keys().reduce((acc, next) => {
		acc[
			next.replace('./', '').replace(/\.svg$/, '')
		] = require(next).ReactComponent;
		return acc;
	}, {});

// arguments passed to require.context must be literals
export const svgs = importAllSvg(require.context('.', true, /\.svg$/));
