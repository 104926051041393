import Highcharts from 'highcharts';
import { useEffect } from 'react';

type HighchartsModule = (highcharts: typeof Highcharts) => void;

const useHighchartsModules = (
  highcharts: typeof Highcharts,
  modules: HighchartsModule[]
): void => {
  useEffect(() => {
    for (const module of modules) {
      module(highcharts);
    }
  }, []);
};

export default useHighchartsModules;
