import styled from 'styled-components';

export const SingleChartSeries = styled.div`
  width: 100%;
  & > div {
    width: 100%;
    height: 100%;
  }
`;

export const SingleChartContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  ${SingleChartSeries} {
    height: 100%;
    min-height: 400px;
  }
`;

export const MultiChartRangeSelector = styled.div`
  z-index: 2;
  width: 100%;
  & > div {
    width: 100%;
    height: 40px;
  }
`;

export const MultiChartNavigator = styled.div`
  width: 100%;
  & > div {
    width: 100%;
    height: 80px;
  }
`;

export const MultiChartSeries = styled.div`
  width: 100%;
  & > div {
    width: 100%;
    height: 100%;
  }
`;

export const MultiChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  ${MultiChartSeries} {
    flex: 1 1 0;
    min-height: 150px;
    margin-bottom: 10px;
  }
`;
