import styled from 'styled-components';

export const KpiDashletWrapper = styled.div``;

export const TitleContent = styled.label`
  display: flex;
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-transform: uppercase;
`;
