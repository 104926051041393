import * as React from 'react';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Context
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const Context = React.createContext<Record<string, unknown>>({});

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Context Provider
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export type ProviderProps = {
  children: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} & Record<string, any>;

export function Provider(props: ProviderProps): React.ReactElement {
  const { children, ...context } = props;

  const value = React.useMemo(
    () => {
      return context ?? {};
    },
    Object.values(context ?? {})
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

Provider.displayName = 'HistoricalChart.Provider';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Context Hook
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useContext<T extends { children?: React.ReactNode }>() {
  const context = React.useContext(Context);
  return context as Omit<T, 'children'>;
}
