/* eslint-disable @typescript-eslint/no-explicit-any */
import { NodeSchema, ReactFlowSchema } from '@Types/rehab.types';
import * as _ from 'lodash';

// convert tree to ReactFlow object

function printTopToBottomPath(
	curr: NodeSchema,
	parent: any,
): ReactFlowSchema[] {
	const stk = [];
	const connections: ReactFlowSchema[] = [];
	const connectionsWithEl: ReactFlowSchema[] = [];

	while (curr != null) {
		const reactFlowObj: ReactFlowSchema = {
			id: curr.id,
			parentId: curr.parentId ? curr.parentId : null,
			data: {
				label: curr.title ? curr.title : '',
				nodeData: {
					title: curr.title,
					filterCondition: curr.filterCondition,
					rules: curr.rules ? curr.rules : [],
					actionDefectCodes: curr.actionDefectCodes
						? curr.actionDefectCodes
						: [],
					assetsCount: curr.assetsCount,
					actionId: curr.actionId,
					treeId: curr.treeId,
					unitCost: curr.unitCost,
					costUnit: curr.costUnit,
					unit: curr.unit,
				},
			},
			connectorCondition: curr.connectorCondition,
			type: curr.type,
			position: {
				x: curr.position ? curr.position.x : 0,
				y: curr.position ? curr.position?.y : 0,
			},
		};

		connections.push(reactFlowObj);
		stk.push(curr);
		curr = parent.get(curr);
	}

	while (stk.length != 0) {
		curr = stk[stk.length - 1];
		stk.pop();
	}
	if (connections && connections.length > 0) {
		connections
			.reverse()
			.forEach((connection: ReactFlowSchema, index: number) => {
				connectionsWithEl.push(connection);
				if (index > 0) {
					let connectionLabel = connection.connectorCondition;
					if (
						connection.data?.nodeData.assetsCount?.currentNode ||
						connection.data?.nodeData.assetsCount?.currentNode === 0
					) {
						connectionLabel =
							connectionLabel +
							' (' +
							connection.data?.nodeData.assetsCount?.currentNode +
							')';
					}
					const connectionObj = {
						id: `e${connection.parentId}-${connection.id}`,
						source: connection.parentId,
						target: connection.id,
						label: connectionLabel,
					};
					connectionsWithEl.push(connectionObj);
				}
			});
	}

	return connectionsWithEl;
}
export function printRootToLeaf(root: NodeSchema): ReactFlowSchema[] {
	const reactFlowArr: ReactFlowSchema[] = [];
	// Corner Case
	if (root == null) return [];

	// Create an empty stack and push root to it
	const nodeStack = [];
	nodeStack.push(root);

	const parent = new Map();

	// parent of root is NULL
	parent.set(root, null);

	while (nodeStack.length != 0) {
		// pop the top item from stack
		const current: NodeSchema = nodeStack[nodeStack.length - 1];
		nodeStack.pop();

		if (current.left == null && current.right == null) {
			const x = printTopToBottomPath(current, parent);
			x.forEach((ele: ReactFlowSchema) => {
				if (
					!_.some(reactFlowArr, (reactFlowEle: any) =>
						_.isEqual(reactFlowEle, ele),
					)
				) {
					reactFlowArr.push(ele);
				}
			});
		}

		if (current.right != null) {
			parent.set(current.right, current);
			nodeStack.push(current.right);
		}
		if (current.left != null) {
			parent.set(current.left, current);
			nodeStack.push(current.left);
		}
	}
	return reactFlowArr;
}

export function convertTreeToReactFlowObject(
	treeNodes: NodeSchema,
): ReactFlowSchema[] {
	const newTreeNodes = { ...treeNodes };
	let reactFlow: ReactFlowSchema[] = [];
	if (treeNodes) reactFlow = printRootToLeaf(newTreeNodes);

	return reactFlow;
}
