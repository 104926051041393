import { Divider, Paper } from '@mui/material';
import styled, { css } from 'styled-components';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import { rem } from '@Components/styles';

export const Wrapper = styled.div``;

export const PickerPaper = styled(Paper)`
	padding: ${rem(10)};
	button {
		text-transform: none;
	}
`;

export const PickerGrid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
	gap: ${rem(30)} ${rem(20)};
	padding: ${rem(10)};
`;

export const Option = styled.div<{
	$backgroundImage?: string;
	$backgroundColor?: string;
	$background?: string;
	$selected?: boolean;
}>`
	width: ${rem(32)};
	height: ${rem(32)};
	border-radius: 100%;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	${({ $backgroundColor }) =>
		$backgroundColor &&
		css`
			background-color: ${$backgroundColor};
		`};
	${({ $backgroundImage }) =>
		$backgroundImage &&
		css`
			background-image: url("${$backgroundImage}");
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
		`};
	${({ $background }) =>
		$background &&
		css`
			background: ${$background};
		`}
	${({ $selected }) =>
		$selected &&
		css`
			width: ${rem(36)};
			height: ${rem(36)};
			border: 2px solid #fff;
			margin: -4px;
			box-shadow: 0px 0px 8px #333;
		`}
	svg {
		max-width: 100%;
	}
`;

export const ColorDivider = styled(Divider)`
	margin: 0.25rem;
`;

export const ColorPickerLabel = styled.p`
	margin: 0.75rem 0.25rem 0;
	font-size: 0.87rem;
	color: ${({ theme }) => theme.palette.secondary.main};
`;

export const PlusIcon = styled(AddCircleIcon)`
	color: #768d95;
`;
