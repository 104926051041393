import MapboxLayer, { UniqueLayerProps } from './MapboxLayer';
import { SymbolLayout, SymbolPaint } from './types';

import { BackgroundTypes } from '@Map/types';
import { ServiceLayer } from '../../services/types';
import SymbolLoader from '@Map/symbols/SymbolLoader';

export default class SymbolLayer extends MapboxLayer {
	protected _type: ServiceLayer['type'] = 'symbol';

	layerProperties(): UniqueLayerProps {
		return {
			layout: {
				'icon-image': this._getIcon(),
				'icon-size': [
					'interpolate',
					['linear'],
					['zoom'],
					// zoom 10 (or less)
					10,
					0.5,
					// zoom is 13 (or greater)
					13,
					1,
				],
				'icon-allow-overlap': true,
				'icon-ignore-placement': true,
				...this._getTextLayoutProps(),
				...this._getBackgroundBasedLayoutProps(),
			},
			paint: {
				// label is hidden until zoom is 20 or more
				'text-opacity': ['step', ['zoom'], 0, 20, 1],
				...this._getBackgroundBasedPaintProps(),
			},
		};
	}

	/**
	 * If an icon has been specified then return that
	 * Otherwise return the icon lookup match function that will parse
	 * the id to get the asset type and then an icon will be found from
	 * the lookup
	 * Fallback is to display unknown icon if none is found
	 * @returns icon to display
	 */
	private _getIcon(): SymbolLayout['icon-image'] {
		if (this._selectedColorLookup && this._selected) {
			return this._selectedColorLookup as SymbolLayout['icon-image'];
		} else if (this._colorLookup) {
			return this._colorLookup as SymbolLayout['icon-image'];
		} else if (this._icon) {
			return SymbolLoader.createSymbolId(
				this._icon,
				this._color,
				this._selected,
				this._selectedColor,
				this._selected ? undefined : !this._iconBackground,
			);
		}

		const iconProps: Parameters<typeof SymbolLoader.iconPostfix> = [
			this._color,
			this._selected,
			this._selectedColor,
			this._selected ? undefined : !this._iconBackground,
		];
		const iconPostfix = SymbolLoader.iconPostfix(...iconProps);
		const unknownIcon = SymbolLoader.createSymbolId(
			'unknown',
			...iconProps,
		);
		return [
			'case',
			['has', 'icon'],
			['concat', SymbolLoader.namespace, ['get', 'icon'], iconPostfix],
			unknownIcon,
		];
	}

	private _getTextRadialOffset(): SymbolLayout['text-radial-offset'] {
		// currently the defect icon is smaller than others so text needs to be closer
		return this._icon === 'defect' ? 1.2 : 2;
	}

	private _getTextLayoutProps(): Partial<SymbolLayout> {
		if (!this._selected) {
			return {
				'text-field': ['get', 'label'],
				'text-radial-offset': this._getTextRadialOffset(),
				'text-size': 12,
				'text-justify': 'auto',
				'text-variable-anchor': ['right', 'left'],
			};
		}
		return {};
	}

	private _getBackgroundBasedPaintProps(): Partial<SymbolPaint> {
		if (
			this._background === BackgroundTypes.Satellite ||
			this._background === BackgroundTypes.Dark
		) {
			return {
				'text-color': this._getLabelColor(),
				'text-halo-color': '#000000',
				'text-halo-width': 1,
			};
		}

		if (this._labelColor) {
			return {
				'text-color': this._labelColor,
			};
		}

		return {};
	}

	private _getBackgroundBasedLayoutProps(): Partial<SymbolLayout> {
		if (
			this._background === BackgroundTypes.Satellite ||
			this._background === BackgroundTypes.Dark
		) {
			return {
				'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
			};
		}

		return {};
	}

	private _getLabelColor() {
		if (this._labelColor) return this._labelColor;
		if (this._background === BackgroundTypes.Satellite) return '#ffffff';
		if (this._background === BackgroundTypes.Dark) return '#b2b2b2';
		return '#000000';
	}
}
