import { rem } from '@Utils/styles';
import styled from 'styled-components';

export const Wrapper = styled.div`
	padding: 0 ${rem(16)};
	height: 100%;
	box-sizing: border-box;
	* {
		box-sizing: border-box;
	}
`;

export const SystemAssetSelectors = styled.div`
	display: flex;
	align-items: center;
	.MuiFormControl-root {
		margin-right: ${rem(16)};
	}
	button {
		&:first-of-type {
			margin-left: auto;
		}
	}
`;
