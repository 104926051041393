/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';

function useRef<V>(value: V): React.MutableRefObject<V> {
  const ref = React.useRef(value);
  ref.current = value;
  return ref;
}

export function useSources<
  T extends Record<string, any>,
  const D extends any[] = any[],
>(
  generator: () => T[] | undefined,
  deps: D
): [sources: T[] | undefined, sourceStrings: string[] | undefined] {
  const generatorRef = useRef(generator);

  const unstableSources = React.useMemo(
    () => {
      return generatorRef.current();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps
  );

  const unstableSourceStrings = React.useMemo(() => {
    if (!unstableSources?.length) return;
    return unstableSources.map((s) =>
      'key' in s ? s['key'] : JSON.stringify(s)
    );
  }, [unstableSources]);

  const stableSources = React.useMemo(
    () => unstableSources,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [unstableSourceStrings?.join()]
  );

  const stableSourceStrings = React.useMemo(() => {
    if (!stableSources?.length) return;
    return stableSources.map((s) =>
      'key' in s ? s['key'] : JSON.stringify(s)
    );
  }, [stableSources]);

  return [stableSources, stableSourceStrings];
}
