import {
	getConfigurationWithMapping,
	getConfigurationRejected,
	getConfigurationResolved,
	getConfigurationWithUploadId,
} from '@Actions';
import { put, retry, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import {
	getConfigurationWithMappingApi,
	getConfigurationWithUploadIdApi,
} from '@Services';
import { AnyAction } from 'redux';
import { t } from 'i18next';
import { YAMLMapping } from '@Types';

function* getConfigurationWithMappingSaga(action: AnyAction) {
	try {
		const payload: AxiosResponse<unknown> = yield retry(
			5,
			1500,
			getConfigurationWithMappingApi,
			action.payload.mappingKey,
			action.payload.type,
		);

		yield put(
			getConfigurationResolved({
				configuration: payload.data as YAMLMapping[],
				key: action.payload.mappingKey,
			}),
		);
	} catch (e) {
		const { response } = e as { response: AxiosResponse };
		yield put(
			getConfigurationRejected({
				code: response?.status ?? -1,
				text: response?.statusText ?? t('No additional information'),
			}),
		);
	}
}

function* watchGetConfigurationMapping() {
	yield takeLatest(
		getConfigurationWithMapping,
		getConfigurationWithMappingSaga,
	);
}

function* getConfigurationWithUploadIdSaga(action: AnyAction) {
	try {
		const payload: AxiosResponse<unknown> = yield retry(
			5,
			1500,
			getConfigurationWithUploadIdApi,
			action.payload.uploadId,
			action.payload.type,
		);

		yield put(
			getConfigurationResolved({
				configuration: payload.data as YAMLMapping[],
				key: action.payload.uploadId,
			}),
		);
	} catch (e) {
		const { response } = e as { response: AxiosResponse };
		yield put(
			getConfigurationRejected({
				code: response?.status ?? -1,
				text: response?.statusText ?? t('No additional information'),
			}),
		);
	}
}

function* watchGetConfigurationUpload() {
	yield takeLatest(
		getConfigurationWithUploadId,
		getConfigurationWithUploadIdSaga,
	);
}

export default [watchGetConfigurationMapping(), watchGetConfigurationUpload()];
