import { NamespacedStoreState } from '@Types/store.types';
import { createSelector } from '@reduxjs/toolkit';
import { moduleNamespace } from '@Store/config.store';
import { useSelector } from 'react-redux';

const parseNamespace = (state: NamespacedStoreState) => state[moduleNamespace];

export const assetPipeSelector = createSelector(
	parseNamespace,
	state => state.assetPipe,
);

export const assetPipeAssetSelector = createSelector(
	parseNamespace,
	state => state.assetPipe?.asset,
);

export const selectAssetPipe = () => useSelector(assetPipeSelector);
export const selectAssetPipeAsset = () => useSelector(assetPipeAssetSelector);

export default selectAssetPipe;
