import {
	InspectionCard,
	InspectionId,
	SurveyDate,
	ViewDetails,
	Wrapper,
} from './InspectionProperties.styles';
import {
	ItemWithLabel,
	iso8601DateToLocal,
	useSettings,
} from '@innovyze/stylovyze';

import {
	InspectionScoredAttributes,
	InspectionStandard,
} from '@Types/inspection.types';
import React from 'react';
import { useGlobalization } from '@Translations/useGlobalization';
import { TFunc } from '@Translations/types';

export interface InspectionPropertiesProps {
	inspection: InspectionScoredAttributes;
	viewInspection?: (inpsectionAttributes: InspectionScoredAttributes) => void;
}

export const inspectionStandard = (
	inspectionVersion: string,
): InspectionStandard => {
	if (inspectionVersion.toUpperCase().startsWith('MACP'))
		return InspectionStandard.MACP;
	else if (inspectionVersion.toUpperCase().startsWith('MSCC'))
		return InspectionStandard.MSCC;
	else if (inspectionVersion.toUpperCase().startsWith('WSA05'))
		return InspectionStandard.WSA05;
	else return InspectionStandard.PACP;
};

const getDetails = (inspection: InspectionScoredAttributes, t: TFunc) => {
	const standard = inspectionStandard(inspection.inspectionVersion);

	switch (standard) {
		case InspectionStandard.MSCC:
		case InspectionStandard.WSA05:
			return (
				<>
					<ItemWithLabel
						label={t('Structural Grade', {
							context: 'Inspection properties',
						})}
						value={inspection.ratings?.structuralGradeRating}
					/>
					<ItemWithLabel
						label={t('Service Grade', {
							context: 'Inspection properties',
						})}
						value={inspection.ratings?.serviceGradeRating}
					/>
				</>
			);
		default:
			return (
				<>
					<ItemWithLabel
						label={t('Overall Rating', {
							context: 'Inspection properties',
						})}
						value={inspection.ratings?.overallRating}
					/>
					<ItemWithLabel
						label={t('Structural Rating', {
							context: 'Inspection properties',
						})}
						value={inspection.ratings?.structuralOverallRating}
					/>
					<ItemWithLabel
						label={t('O&M', {
							context: 'Inspection properties',
						})}
						value={inspection.ratings?.serviceOverallRating}
					/>
				</>
			);
	}
};

export const InspectionProperties = ({
	inspection,
	viewInspection,
}: InspectionPropertiesProps): JSX.Element => {
	const { t } = useGlobalization();
	const { companySettings } = useSettings();

	const onInspectionClick = () => {
		if (viewInspection) viewInspection(inspection);
	};

	return (
		<Wrapper data-cy="inspection-properties">
			<InspectionId viewDetails={!!viewInspection}>
				{inspection.inspectionId}
			</InspectionId>
			<SurveyDate viewDetails={!!viewInspection}>
				{iso8601DateToLocal(
					inspection.surveyDateTime,
					companySettings.dateFormat,
				)}
			</SurveyDate>
			{viewInspection && (
				<ViewDetails size="small" onClick={onInspectionClick}>
					{t('View Details', {
						context: 'Inspection details view further details',
					})}
				</ViewDetails>
			)}
			<InspectionCard>
				<ItemWithLabel
					label={t('Inspection Type', {
						context: 'Inspection properties',
					})}
					value={inspection.inspectionVersion}
				/>
				{getDetails(inspection, t)}
			</InspectionCard>
		</Wrapper>
	);
};
