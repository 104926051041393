import type { AnalyticFunction } from './analytic-function';
import type { Reading, Resolution } from './series';
import type { SensorDataMap, Measure } from './sensor-data';

export const readingAt: Reading[] = [
  'Close',
  'Open',
  'Low',
  'High',
  'Average',
  'Sum',
];

const analyticTrendlineMap = (
  analytic: Reading | AnalyticFunction
): Reading => {
  const _analytic = typeof analytic === 'string' ? analytic : analytic.type;

  switch (_analytic) {
    case 'Sqrt':
    case 'sqrt':
    case 'Movingaverage':
    case 'movingaverage':
    case 'Last':
    case 'last':
    case 'Constant':
    case 'constant':
      return 'Average';
    case 'MovingSum':
    case 'movingsum':
      return 'Sum';
    default:
      return _analytic;
  }
};

export const makeTrendlineData = (
  sensorDataMap: SensorDataMap | undefined,
  analytic: Reading | AnalyticFunction,
  resolution: Resolution,
  forecastPeriods?: number
): [number, number][] | undefined => {
  if (sensorDataMap === undefined) {
    return undefined;
  }

  const dataEntries = Array.from(sensorDataMap.entries());
  let data: [number, number][] = [];

  if (dataEntries.length) {
    const _analytic = analyticTrendlineMap(analytic);
    const xValues: number[] = [];
    const yValues: number[] = [];

    for (const dataEntry of dataEntries) {
      xValues.push(dataEntry[0]);
      yValues.push(dataEntry[1]?.get(_analytic) ?? 0);
    }

    data = calculateTrendline(xValues, yValues, forecastPeriods, resolution);
  }

  return data;
};

const calculateTrendline = (
  xValues: number[],
  yValues: number[],
  forecastPeriods: number | undefined,
  resolution: Resolution
): [number, number][] => {
  const data: [number, number][] = [];

  const xAverage =
    xValues.reduce((sum, value) => sum + value, 0) / xValues.length;
  const yAverage =
    yValues.reduce((sum, value) => sum + value, 0) / yValues.length;

  const sumXY = xValues.reduce((sum, xVal, index) => {
    sum += (xVal - xAverage) * (yValues[index] - yAverage);
    return sum;
  }, 0);

  const sumXX = xValues.reduce((sum, xVal) => {
    sum += (xVal - xAverage) * (xVal - xAverage);
    return sum;
  }, 0);

  const slope = sumXY / sumXX;
  const intercept = yAverage - slope * xAverage;

  let newX = xValues;

  if (forecastPeriods) {
    const period =
      resolution !== 'RAW' && xValues.length > 2
        ? xValues[xValues.length - 1] - xValues[xValues.length - 2]
        : 900000;
    const forecastX = [xValues[xValues.length - 1] + period];
    for (let i = 0; i < forecastPeriods - 1; i++) {
      forecastX.push(forecastX[i] + period);
    }
    newX = [...xValues, ...forecastX];
  }

  for (const x of newX) {
    const y = slope * x + intercept;
    data.push([x, y]);
  }

  return data;
};
